<template>
  <FormPanel
    :id="'visaAppointmentInformationInPerson'"
    :title="'Visa Appointment Information'"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <form
        id="visa-appointment-in-person-form"
        class="mb-6"
        action=""
        aria-label="Visa Appointment Information Form"
        @submit.prevent="submitForm()"
      >
        <div class="space-y-6 mb-6">
          <p>
            You are required to obtain a student visa in order to participate in
            your program. It is your responsibility as a participant in this
            program to obtain a student visa prior to your departure.Please
            schedule an in person appointment at your local consulate. We will
            need to provide you with the information for your visa appointment.
            Complete the details below so we can contact you with the
            information you will need for your appointment.
          </p>
          <p class="italic">
            Note: If the appointment is within 3 weeks, please contact us
            immediately.
          </p>
        </div>

        <Datepicker
          label-name="When is your scheduled visa appointment?"
          :date="v$.value.appointment.$model"
          :required="true"
          :min-year="1950"
          @updateDate="updateDate($event)"
        />
        <p class="my-6">
          Please make sure your appointment allows for enough time to get your
          passport back before the start of your program.
        </p>
        <label for="consulate" class="block text-sm mb-6">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Name of the consulate you will be attending:</span>
          </span>
          <input
            id="consulate"
            v-model.trim="v$.value.consulate.$model"
            type="text"
            class="form-input block w-full min-h-10"
            :class="{ 'bg-error-100': v$.value.consulate.$error }"
          />
          <div
            v-if="
              v$.value.consulate.required.$invalid && v$.value.consulate.$error
            "
            class="error text-error-900"
          >
            Field is required
          </div>
        </label>

        <ButtonWithSpinner
          ref="inPersonSubmit"
          type="submit"
          variant="primary"
          variant-type="block"
          :disabled="v$.$invalid"
        >
          <span>
            Submit
            <svg
              v-if="successSubmission && !v$.$invalid"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="inline feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </span>
        </ButtonWithSpinner>
        <div v-if="submitError" class="error text-error-900 mt-2">
          {{ submitError }}
        </div>
        <div v-if="successSubmission && !v$.$invalid" class="text-success-900">
          Submission Complete, thank you!
        </div>
      </form>
    </template>
  </FormPanel>
</template>

<script>
import FormPanel from "../SharedComponents/panel.vue";

import { required } from "@vuelidate/validators";
import Datepicker from "../SharedComponents/Datepicker.vue";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import { eventBus } from "../../../app";
import formIoApi from "../../../mixins/formIoApi.js";
import useVuelidate from "@vuelidate/core";

export default {
  name: "InPerson",
  components: {
    FormPanel,
    Datepicker,
    ButtonWithSpinner,
  },
  mixins: [formIoApi],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
    formioURL: {
      type: String,
      default: "",
    },
    formURL: {
      type: String,
      default: "",
    },
    submissionId: {
      type: String,
      default: "",
    },
    formioData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["dateChange", "updatedSubmission"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        appointment: "",
        consulate: "",
      },
      successSubmission: false,
      submitError: "",
    };
  },
  validations: {
    value: {
      appointment: { required },
      consulate: { required },
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.successSubmission = false;
      },
      deep: true,
    },
    formioData: {
      handler: function (val) {
        this.value.appointment = val.visaAppointmentDate ?? "";
        this.value.consulate = val.consulateName ?? "";
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    updateDate(date) {
      this.v$.value.appointment.$model = date;
    },
    submitForm() {
      this.submitError = "";
      this.successSubmission = false;

      this.v$.$touch();
      eventBus.$emit("dateChange");

      if (!this.v$.$invalid) {
        this.$refs.inPersonSubmit.startLoading();
        if (this.submissionId) {
          this.updateSubmission();
        } else {
          this.createSubmission();
        }
      }
    },
    updateSubmission() {
      const jsonData = [
        {
          op: "add",
          path: "/data/visaAppointmentDate",
          value: this.value.appointment,
        },
        {
          op: "add",
          path: "/data/consulateName",
          value: this.value.consulate,
        },
        {
          op: "add",
          path: "/data/inPersonSubmitted",
          value: true,
        },
      ];

      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.inPersonSubmit.stopLoading();
        });
    },
    createSubmission() {
      const formioObject = {
        data: {
          application_id: this.applicationId,
          visaAppointmentDate: this.value.appointment,
          consulateName: this.value.consulate,
          inPersonSubmitted: true,
        },
      };
      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.$emit("updatedSubmission", response);
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.inPersonSubmit.stopLoading();
        });
    },
  },
};
</script>
