<script setup>
import { computed, inject, onBeforeMount, ref, provide } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  V3_stepCanBeReturned,
  V3_stepShouldBeShown,
} from "@/composables/VersionHelper.js";
import PayDepositV3 from "@/components/ExperiencePage/Application/Steps/PayDepositV3.vue";
import InstitutionPaysV3 from "@/components/ExperiencePage/Application/Steps/InstitutionPaysV3.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import enrollmentService from "@/services/enrollment.js";
import billingAgreement from "@/composables/useBillings.js";
import {
  GENERAL_GROUP_VISA_SERVICE,
  SPAIN_GROUP_VISA_SERVICE,
} from "@/components/program-manager/sessions/constants.js";
import ordersService from "@/services/orders";
import { getApiClient } from "@/services/API";

// Injectors
const updateStepAndRedirect = inject("updateStepAndRedirect");
const { countries } = inject("initialOptionsData");
// Workaround for V3 - avoids running billing agreement logic on an old mixin made in vue 2 because it overwrites what we set here due to different logic
provide("blockV2BillingAgreements", true);

// Variables
const store = useStore();
const router = useRouter();
const isLoading = ref(true);
const form = ref(null); // Either studentPays or institutionPays
const contracts = ref([]);
const orderInProgress = ref(false);
const currentOrder = computed(() => store.state.configurator.currentOrder);
const payment_id = ref(null);
const readOnlyPaymentForm = ref(false);
const eligibleForGroupVisa = ref(false);

// Computed
const groupVisaStepData = computed(() =>
  store.state.stepsInV3.find((step) => step.step === "GroupVisa")
);
const acceptedGroupVisa = computed(
  () => groupVisaStepData.value?.data?.group_visa_accepted === true
);
const applicationId = computed(() => store.state.currentApplicationId);
const appliedAgreement = computed(() => store.state.appliedAgreement);
const sessionV3Data = computed(() => {
  return store.state.programSessionDataV3;
});

const profileData = computed(() => {
  return store.state.profileData;
});

const profileSelectedObjects = computed(() => {
  return store.state.profileSelectedObjects;
});

const canLoadFromState = computed(() => {
  return ["Participant", "Institution"].includes(
    appliedAgreement.value?.applicationFee
  );
});

const collegeId = computed(() => {
  return profileData.value?.colleges?.[0]?.college_id ?? null;
});

const hostInstitutionId = computed(() => {
  return sessionV3Data.value?.session_hosting_institutions?.[0]?.entity_id;
});

const opeId = computed(() => {
  return profileSelectedObjects.value.school?.ope_id
    ? String(profileSelectedObjects.value.school.ope_id).padStart(8, "0")
    : null;
});

const institutionPays = computed(() => {
  return store.state.institutionPaysApplicationFee;
});

const programSessionCountry = computed(() => {
  // TODO: What happens if session_travel_details > 1?
  return (
    sessionV3Data.value?.session_travel_details?.[0]?.arrival_city?.country
      ?.id ?? ""
  );
});

const programCountryIsSpain = computed(() => {
  return programSessionCountry.value === "ES";
});

const orderUpdatePayload = computed(() => {
  return {
    ...currentOrder.value,
    status: "Submitted",
    application_fee_payment_id: !institutionPays.value
      ? payment_id.value
      : null,
    billing_contract_id: appliedAgreement.value?.contract_number ?? null,
    ope_id: opeId.value,
    eligible_for_group_visa: eligibleForGroupVisa.value,
    host_institution_id: hostInstitutionId.value,
  };
});

const studentFormioPaymentSubmission = computed(() => {
  const targetFormPaths = ["paymentform", "institutionpayment"];
  return Object.entries(currentApplicationSubmissions.value).reduce(
    (acc, [key, item]) => {
      if (
        targetFormPaths.includes(key) &&
        item[0].data.application_id === applicationId.value
      ) {
        acc = item[0];
      }
      return acc;
    },
    {}
  );
});
const currentApplicationSubmissions = computed(() => {
  return store.state.currentApplicationSubmissions ?? [];
});

// Methods
function setForm(formType = "studentPays", keepLoading = false) {
  // Sets the name of the form to be displayed on template
  form.value = formType;
  isLoading.value = keepLoading;
}

async function canViewForm() {
  // Allow to return if step isn't completed or is next step
  let canViewForm = await V3_stepCanBeReturned("Checkout");
  if (!canViewForm) {
    // Go to About You
    router.push({
      name: "application-about-you",
      params: { applicationId: applicationId.value },
    });
  }
}
async function loadForm() {
  //For revisiting the form in readonly mode we need to figure out if a submission exist for institution pays or student pays deposit
  if (Object.keys(studentFormioPaymentSubmission.value).length > 0) {
    readOnlyPaymentForm.value = true;
    setForm(
      studentFormioPaymentSubmission.value.formName === "institutionPayment"
        ? "institutionPays"
        : "studentPays",
      false
    );
    return;
  }
  // Check which form to load if we have all info on state
  if (canLoadFromState.value) {
    setForm(
      appliedAgreement.value.applicationFee === "Institution"
        ? "institutionPays"
        : "studentPays",
      false
    );
    return;
  }

  // Check which form to load based on profile data
  if (collegeId.value) {
    // Get the entity ope_id to fetch billing agreements
    if (opeId.value) {
      // Fetching billing agreements
      contracts.value = await enrollmentService.loadContracts(opeId.value);
      if (Array.isArray(contracts.value)) {
        // Check if billing agreement can be applied
        const appliableContract = billingAgreement(
          contracts.value,
          sessionV3Data.value
        );

        store.commit("setInstitutionPaysValue", {
          field: "applicationFee",
          value: appliableContract?.applicationFee === "Institution",
        });

        store.commit(
          "setAppliedAgreement",
          appliableContract !== undefined ? appliableContract : {}
        );

        setForm(
          institutionPays.value ? "institutionPays" : "studentPays",
          false
        );
      }
      return;
    }
  }
  setForm("studentPays", false);
}

async function addGroupVisa() {
  if (acceptedGroupVisa.value && eligibleForGroupVisa.value) {
    // Add group visa (Spain or general one)
    store.commit(
      "configurator/ADD_ID_TO_MISC_PRODUCT_ID",
      programCountryIsSpain.value
        ? SPAIN_GROUP_VISA_SERVICE.id
        : GENERAL_GROUP_VISA_SERVICE.id
    );
  }
}

async function updateOrderStatus(paymentId = null) {
  payment_id.value = paymentId;
  // Safeguard: If updateOrder gets emitted multiple times we'll only process the 1st one
  if (orderInProgress.value) return;
  orderInProgress.value = true;

  // Mark step as complete
  store.commit("setStepCompletedV3", "Checkout");

  // Append items to order (Group Visa)
  await addGroupVisa();

  // update order in learning service
  await ordersService.updateOrder({
    order_id: currentOrder.value.id,
    payload: orderUpdatePayload.value,
    session: sessionV3Data?.value,
    callingLocation: "Checkout",
  });

  if (acceptedGroupVisa.value === true) {
    await sendMail();
  }

  //reset current order in vuex
  store.dispatch("configurator/resetCurrentOrder");

  // Redirect to next step
  updateStepAndRedirect("application-next-steps", applicationId.value);
}
const changeStep = () => {
  updateStepAndRedirect("application-next-steps", applicationId.value);
};

async function sendMail() {
  let studentFirstName = profileData.value.preferred_first_name;
  let studentLastName = profileData.value.last_name;
  let sessionName = sessionV3Data?.value?.name;

  const payload = {
    studentFirstName: studentFirstName,
    studentLastName: studentLastName,
    sessionName: sessionName,
  };

  return await getApiClient().post("/notify-visa-email", payload);
}

// Hooks
onBeforeMount(async () => {
  if (
    !store.state.userData ||
    !Object.prototype.hasOwnProperty.call(store.state.userData, "data")
  )
    await store.dispatch("cacheFormioUser");

  // Workaround for refresh - These ids will be added if user is still applying to group visa
  store.commit("configurator/REMOVE_GROUP_VISA_FROM_MISC_PRODUCT_IDS", [
    SPAIN_GROUP_VISA_SERVICE.id,
    GENERAL_GROUP_VISA_SERVICE.id,
  ]);

  eligibleForGroupVisa.value = await V3_stepShouldBeShown("GroupVisa");

  await store.dispatch("getCurrentApplicationSubmissions");
  await canViewForm();
  await loadForm();
});
</script>
<template>
  <div
    class="font-montserrat text-indigo-base text-left bg-white w-full p-14 pt-2 border-collapse md:mt-0 md:p-0"
  >
    <template v-if="isLoading">
      <spinner>
        <template #spinnercontent>
          <p class="text-gray-600">
            Loading checkout step, please wait.
          </p>
        </template>
      </spinner>
    </template>
    <template v-else>
      <PayDepositV3
        v-if="form === 'studentPays'"
        :existing-payment-data="studentFormioPaymentSubmission"
        :is-read-only="readOnlyPaymentForm"
        :initial-countries="countries"
        @update-order="updateOrderStatus"
        @next-step="changeStep"
      />
      <InstitutionPaysV3
        v-else-if="form === 'institutionPays'"
        :existing-payment-data="studentFormioPaymentSubmission"
        :is-read-only="readOnlyPaymentForm"
        @update-order="updateOrderStatus"
        @next-step="changeStep"
      />
    </template>
  </div>
</template>
