<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_144_2254"
      style="mask-type: alpha;"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_144_2254)"></g>
    <g clip-path="url(#clip0_144_2254)">
      <g clip-path="url(#clip1_144_2254)">
        <path
          d="M11.45 17.6L8.25 14.4L9.33 13.32L11.45 15.44L15.67 11.22L16.75 12.3L11.45 17.6ZM5.81 21.12C5.3 21.12 4.88 20.94 4.53 20.59C4.18 20.24 4 19.81 4 19.31V5.92C4 5.41 4.18 4.99 4.53 4.64C4.88 4.29 5.31 4.11 5.81 4.11H7.19V2H8.73V4.12H16.31V2H17.81V4.12H19.19C19.7 4.12 20.12 4.3 20.47 4.65C20.82 5 21 5.43 21 5.93V19.31C21 19.82 20.82 20.24 20.47 20.59C20.12 20.94 19.69 21.12 19.19 21.12H5.81ZM5.81 19.62H19.19C19.27 19.62 19.34 19.59 19.4 19.52C19.46 19.46 19.5 19.39 19.5 19.31V9.92H5.5V19.3C5.5 19.38 5.53 19.45 5.6 19.51C5.66 19.57 5.73 19.61 5.81 19.61V19.62ZM5.5 8.43H19.5V5.93C19.5 5.85 19.47 5.78 19.4 5.72C19.34 5.66 19.27 5.62 19.19 5.62H5.81C5.73 5.62 5.66 5.65 5.6 5.72C5.54 5.78 5.5 5.85 5.5 5.93V8.43Z"
          fill="#1E264C"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_144_2254">
        <rect
          width="17"
          height="19.12"
          fill="white"
          transform="translate(4 2)"
        />
      </clipPath>
      <clipPath id="clip1_144_2254">
        <rect
          width="17"
          height="19.12"
          fill="white"
          transform="translate(4 2)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
