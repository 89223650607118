<template>
  <section>
    <header>
      <h2 class="text-xl md:text-3xl">{{ APPLICATION_FEE_DATA.formName }}</h2>
      <p v-if="APPLICATION_FEE_DATA.flagActive">
        Your non-refundable application fee is due now.
      </p>
      <p v-else class="flex items-center mt-2 mb-8 text-gray-600">
        Your non-refundable application deposit of ${{ depositAmount }} will be
        applied toward your total program cost. If you submit a complete
        application by the deadline provided, and are not accepted into the
        program, your deposit will be refunded to you. If you do not complete
        the application process, or decide to withdraw your application at any
        time, the ${{ depositAmount }} remains non-refundable.
      </p>
      <p
        v-if="isCubaProgram"
        class="flex items-center mt-2 mb-8 text-gray-600 font-bold"
      >
        Please call 1-800-844-4124 to make your payment.
      </p>
    </header>
    <div
      class="grid grid-cols-2 gap-y-4 gap-x-6 pb-4 pt-2 border-b border-gray-200"
    >
      <div class="text-base text-gray-600">
        {{ usePascalCase(APPLICATION_FEE_DATA.typeLabel) }} Amount:
      </div>
      <div class="text-base text-gray-600 text-right">${{ depositAmount }}</div>
      <div class="text-base text-gray-600">Available Credit:</div>
      <div class="text-base text-gray-600 text-right">
        (${{ availableCredit }})
      </div>
    </div>
    <div class="grid grid-cols-2 gap-y-4 gap-x-6 pt-4 pb-4">
      <div class="text-xl" style="color: black;">Total:</div>
      <div class="text-xl text-right" style="color: black;">
        $<span id="totalFee" style="color: black;">{{ totalFee }} </span>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12">
      <BillingAddress
        ref="billingAddress"
        :billing-address="billingAddress"
        @update:billing-address="billingAddress = $event"
      />
      <CurrentAddress
        ref="currentAddress"
        :current-address="currentAddress"
        :billing-address="billingAddressToCurrent"
        @update:current-address="currentAddress = $event"
      />
      <PermanentAddress
        ref="permanentAddress"
        :permanent-address="permanentAddress"
        :current-address="currentAddressToPermanentAddress"
        @update:permanent-address="permanentAddress = $event"
      />
      <PromoCode
        ref="promoCode"
        :promo-code="promoCode"
        :expand="expandPromoCode"
        :required-discount="isCubaProgram"
        :is-cuba-program="isCubaProgram"
        @update:promo-code="promoCode = $event"
        @setDiscountCode="onValidCode"
      />
      <!-- Stripe form component -->
      <StripePayDepositForm
        v-if="
          stripePayDepositFormEnabled &&
          !hideCardInformation &&
          !paymentAlreadyExist &&
          !isCubaProgram
        "
        ref="stripePayDepositForm"
      />

      <!-- Stripe Errors -->
      <div
        v-if="
          stripePayDepositFormEnabled &&
          !hideCardInformation &&
          errorStripePayment
        "
        class="text-error-900"
      >
        <p>
          {{ errorStripePayment }}
        </p>
      </div>
      <!-- END Stripe Errors -->

      <!-- Stripe status message -->
      <div
        v-if="
          stripePayDepositFormEnabled &&
          !hideCardInformation &&
          stripeStatus &&
          !errorStripePayment
        "
        class="text-teal-900"
      >
        <p>
          {{ stripeStatus }}
        </p>
      </div>
      <!-- END Stripe status message -->
      <CardInformation
        v-else-if="!hideCardInformation && !stripePayDepositFormEnabled"
        ref="cardInformation"
        :card-information="cardInformation"
        @update:card-information="cardInformation = $event"
      />
    </div>
    <CancellationPolicyCheckbox
      ref="cancellationPolicyCheckbox"
      :cancellation-policy="cancellationPolicyCheckbox"
      data-cy="checkCancellationPolicyAgreement"
      :application-fee="depositAmount"
      @update:cancellation-policy="cancellationPolicyCheckbox = $event"
    />
    <div class="mt-10 block">
      <ButtonWithSpinner
        ref="paymentSubmitButton"
        data-cy="payDepositSubmit"
        variant="primary"
        variant-type="block"
        @click.prevent="submit()"
      >
        <span>{{ submitButtonCopy }}</span>
      </ButtonWithSpinner>
      <p v-if="hasErrors" class="text-error-900">
        Please complete the required fields correctly.
      </p>
      <div v-if="chargeErrors" class="text-error-900">
        <p v-for="error in chargeErrors" :key="error">
          {{ error }}
        </p>
      </div>
      <div v-if="formioErrors" class="text-error-900">
        <p v-for="error in formioErrors" :key="error">
          {{ error }}
        </p>
      </div>
      <div v-if="missingAppId" class="text-error-900">
        <p>
          No Application ID, please contact your API program manager to help you
          with your application.
        </p>
      </div>
      <div v-if="deadlineError" class="text-error-900">
        <p>
          The application deadline for this program has passed. Please contact
          {{ getManagerName }} at
          <a
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            :href="'mailto:' + getEmail"
          >
            {{ getEmail }}
          </a>
          to see if an exception can be made for a late application.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import CardInformation from "../MakePayment/CardInformation.vue";
import BillingAddress from "./BillingAddress";
import CurrentAddress from "./CurrentAddress";
import PermanentAddress from "./PermanentAddress";
import PromoCode from "./PromoCode";
import FormValidation from "../../../mixins/formValidation";
import { mapState } from "vuex";
import ProgramChangeMix from "../../../mixins/programChange";
import formIoApi from "../../../mixins/formIoApi";
import StripeChargeMixin from "../../../mixins/StripeCharge";
import paymentCharge from "../../../mixins/paymentCharge";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner";
import CancellationPolicyCheckbox from "./CancellationPolicyCheckbox";
import { eventBus } from "../../../app";
import AddressComponents from "../../../mixins/AddressComponents";
import StripePayDepositForm from "./StripePayDepositForm";
import * as Sentry from "@sentry/vue";
import { parse, format } from "date-fns";
import { getApiClient } from "@/services/API";
import AdvisorNotifications from "@/mixins/AdvisorNotifications.js";
import { APICompany, APPLICATION_FEE_DATA } from "@/constants";
import { usePascalCase } from "@/composables/stringManupulation";

export default {
  components: {
    CardInformation,
    BillingAddress,
    CurrentAddress,
    PermanentAddress,
    PromoCode,
    ButtonWithSpinner,
    CancellationPolicyCheckbox,
    StripePayDepositForm,
  },
  mixins: [
    paymentCharge,
    FormValidation,
    ProgramChangeMix,
    formIoApi,
    AddressComponents,
    StripeChargeMixin,
    AdvisorNotifications,
  ],
  data() {
    return {
      formIoForm: "paymentform",
      sectionsToValidate: [
        "cardInformation",
        "billingAddress",
        "currentAddress",
        "permanentAddress",
        "cancellationPolicyCheckbox",
        "promoCode",
      ],
      promoCode: "",
      hideCardInformation: false,
      validDiscountCode: false,
      cardInformation: {},
      cancellationPolicyCheckbox: {},
      creditTransactionId: "",
      transactionId: "",
      deadlineError: false,
      expandPromoCode: false,
    };
  },
  setup() {
    // We need to include APPLICATION_FEE_DATA to be able to use in ui
    return { APPLICATION_FEE_DATA };
  },
  computed: {
    ...mapState([
      "program",
      "currentApplicationId",
      "featureFlags",
      "userData",
      "appliedAgreement",
      "enrollmentApplications",
      "studentApplications",
      "customer_id",
      "studentFormioSubmissions",
      "profileData",
      "programSessionDataV3",
    ]),
    programSessionFinanceCode() {
      let application = this.studentApplications.filter(
        (app) => app.id === this.getApplicationId
      );
      return application.length > 0
        ? application[0].programSession.program_session_finance_code
        : null;
    },
    programType() {
      let application = this.enrollmentApplications.filter(
        (app) => app.application_id === this.getApplicationId
      );
      return application?.length > 0 ? application[0].program_type : null;
    },
    depositAmount() {
      return this.program.application_fee;
    },
    availableCredit() {
      return this.programHasChange
        ? this.previousApplication.applicationFee
        : this.validDiscountCode // Returns application_fee so the total is 0
        ? this.program.application_fee // this makes totalFee 0
        : 0; // this means there's no discount
    },
    hostUniversity() {
      return this.program?.program?.university?.name;
    },
    totalFee() {
      return this.depositAmount - this.availableCredit;
    },
    getApplicationId() {
      if (this.currentApplicationId) {
        return this.currentApplicationId;
      } else {
        return this.$route.params.applicationId;
      }
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          firstname: this.billingAddress.firstName,
          lastname: this.billingAddress.lastName,
          address: this.billingAddress.address,
          city: this.billingAddress.city,
          state: this.billingAddress.state,
          country: this.billingAddress.country,
          postCode: this.billingAddress.postCode,
          currentAddress: this.currentAddress.address,
          currentCity: this.currentAddress.city,
          currentState: this.currentAddress.state,
          currentCountry: this.currentAddress.country,
          currentPostCode: this.currentAddress.postCode,
          permanentAddress: this.permanentAddress.address,
          permanentCity: this.permanentAddress.city,
          permanentState: this.permanentAddress.state,
          permanentCountry: this.permanentAddress.country,
          permanentPostCode: this.permanentAddress.postCode,
          promotionCode: this.promoCode,
          expMonth: this.cardInformation.month,
          cancellationPolicy: this.cancellationPolicyCheckbox.policy,
          creditTransactionId: this.creditTransactionId,
          application_id: this.getApplicationId,
          transactionId: this.transactionId,
        },
      };
      return body;
    },
    getManagerName() {
      return this.program.program_manager
        ? this.program.program_manager
        : "API Enrollment Management Team";
    },
    getEmail() {
      return this.program.program_manager_email
        ? this.program.program_manager_email
        : `enrollment${APICompany.companyEmail}`;
    },
    isCubaProgram() {
      return this.program.site.country.toLowerCase() === "cuba";
    },
    submitButtonCopy() {
      let copy = "Continue Application";
      if (!this.isCubaProgram) copy = "Pay & " + copy;
      return copy;
    },
    birthDate() {
      return format(
        parse(this.userData.data.birthday, "MM/dd/yyyy", new Date()),
        "yyyy-MM-dd"
      );
    },
    year() {
      let year = format(
        parse(this.program.start_date, "yyyy-MM-dd", new Date()),
        "yyyy"
      );
      return year;
    },
    stripePayDepositFormEnabled() {
      return this.featureFlags["stripe-application-deposit"];
    },
  },
  watch: {
    transactionId: function () {
      this.saveToFormIoAfterCardCharge();
    },
    billingAddress: {
      handler() {
        if (this.currentAddress.sameAsBilling) {
          this.billingAddressToCurrent = {
            address: this.billingAddress.address,
            city: this.billingAddress.city,
            state: this.billingAddress.state,
            country: this.billingAddress.country,
            postCode: this.billingAddress.postCode,
          };
        }
        if (this.stripePayDepositFormEnabled) this.checkDataForStripe();
      },
      deep: true,
    },
    currentAddress: {
      handler() {
        if (this.permanentAddress.sameAsCurrentAddress) {
          this.currentAddressToPermanentAddress = {
            address: this.currentAddress.address,
            city: this.currentAddress.city,
            state: this.currentAddress.state,
            country: this.currentAddress.country,
            postCode: this.currentAddress.postCode,
          };
        }
        if (this.stripePayDepositFormEnabled) this.checkDataForStripe();
      },
      deep: true,
    },
    permanentAddress: {
      handler() {
        if (this.stripePayDepositFormEnabled) this.checkDataForStripe();
      },
      deep: true,
    },
    chargeErrors: {
      handler() {
        if (this.chargeErrors.length > 0) {
          this.$refs.paymentSubmitButton.stopLoading();
        }
      },
      deep: true,
    },
    isCubaProgram: {
      handler: function (newVal) {
        if (newVal) {
          //If the program is from Cuba remove card section and validation
          this.expandPromoCode = true;
          this.removeCardSection();
        }
      },
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch("getCustomerId");
    eventBus.$on("billingAddressToCurrent", (value) => {
      this.setCurrentAddressAsBilling(value);
    });
    eventBus.$on("passCurrentAddressToPermanentAddress", (value) => {
      this.setPermanentAddressAsCurrentAddress(value);
    });

    //check if a payment exists for the application and set state accordingly
    await this.setPaymentState();
  },

  methods: {
    usePascalCase,
    async saveToFormIoAfterCardCharge() {
      for (let i = 0; i <= 2; i++) {
        await this.submitToFormIo(
          this.formIoForm,
          this.createSubmissionDataForFormIo
        )
          .then(() => {
            i = 10;
            //move to next step
            eventBus.$emit("changeStep", this.getApplicationId);
          })
          .catch((error) => {
            if (i === 2) {
              Sentry.captureException(
                new Error("Save payment form to form.io failed"),
                {
                  tags: {
                    error: error,
                  },
                }
              );
              //TODO remove this alert once we know how we want to handle failures submitting to form.io
              alert(
                "Oops! There is an error but we have the payment you submitted for this application. Please enter the following promotion code to continue: pymntpg20"
              );
              this.$refs.paymentSubmitButton.stopLoading();
            }
          });
      }
    },
    async setPaymentState() {
      this.paymentDone = await this.checkTransactionExistsForApplicationId();
      //disable step 1 if we already have a payment for this application.
      if (this.paymentDone) {
        //check if payment exists in form.io if not save payment data from database to formio
        if (
          !(await this.formioSubmissionExists(
            this.formIoForm,
            this.getApplicationId
          ))
        ) {
          let response = await getApiClient().post("/payments", {
            applicationId: this.getApplicationId,
            formPath: this.formIoForm,
          });

          if (response.status === 200) {
            eventBus.$emit("paymentRecordOnDatabase", true);
            //move to next step
            eventBus.$emit("changeStep", this.getApplicationId);
          }
        } else {
          eventBus.$emit("paymentRecordOnDatabase", true);
          eventBus.$emit("showSpinner", false);
        }
      } else {
        eventBus.$emit("showSpinner", false);
      }
    },
    // Triggered when `validCode` event is emitted by the promocode component.
    onValidCode(value) {
      this.promoCode = value;
      this.validDiscountCode = true;
      //do not validate card information
      this.removeCardSection();
    },
    async submit() {
      this.$refs.paymentSubmitButton.startLoading();
      this.chargeErrors = [];
      //calling the startLoading method of the button, which enables the button to loading state
      try {
        this.validateDeadline();
        await this.checkForApplicationId();
        await this.validate();
        await this.formioValidateSubmission(
          this.formIoForm,
          this.createSubmissionDataForFormIo
        );
        if (!this.hasFormIoValidationErrors && !this.deadlineError) {
          // If valid discount code or total is 0, skip this
          if (this.validDiscountCode) {
            this.submitToFormIo(
              this.formIoForm,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                // send avisor notification
                this.sendNotifications("submit", this.program.salesforce_id);
                //move to next step
                if (this.stripePayDepositFormEnabled)
                  this.submitToPaymentService();
                eventBus.$emit("changeStep", this.getApplicationId);
              }
              this.$refs.paymentSubmitButton.stopLoading();
            });
            return;
          }
          if (this.stripePayDepositFormEnabled) {
            await this.handleSubmitToStripe();
            if (this.paymentSuccess) {
              this.sendNotifications("submit", this.program.salesforce_id);
            } else {
              this.$refs.paymentSubmitButton.stopLoading();
              return;
            }
          } else {
            this.processForm();
          }
        } else {
          this.$refs.paymentSubmitButton.stopLoading();
        }
      } catch {
        this.$refs.paymentSubmitButton.stopLoading();
      }
    },
    async processForm() {
      //No payment fee balance
      if (this.programHasChange && this.previousApplication.paidFee) {
        this.creditTransactionId = this.previousApplication.transactionId;
        if (this.totalFee <= 0) {
          this.submitToFormIo(
            this.formIoForm,
            this.createSubmissionDataForFormIo
          ).then((response) => {
            if (response) {
              //move to next step
              // send avisor notification
              this.sendNotifications("submit", this.program.salesforce_id);
              eventBus.$emit("changeStep", this.getApplicationId);
            }
            this.$refs.paymentSubmitButton.stopLoading();
          });
          return;
        }
      }
    },
    validateDeadline() {
      const deadline = this.program.application_extension_deadline
        ? this.program.application_extension_deadline
        : this.program.application_deadline;

      const currentDate = new Date();
      const deadlineDate = new Date(deadline + "T23:59:59");
      this.deadlineError = currentDate > deadlineDate;
    },
    removeCardSection() {
      this.hideCardInformation = true;
      const index = this.sectionsToValidate.indexOf("cardInformation");
      if (index > -1) this.sectionsToValidate.splice(index, 1);
    },
  },
};
</script>
