<script setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import ArrowDownIcon from "./ArrowDownIcon.vue";
import { computed } from "vue";
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  labelClass: {
    type: String,
    default: "",
  },
  dropDownOptions: {
    type: Object,
    default: () => {},
  },
});

const hasOptions = computed(() => {
  return !_.isEmpty(props.dropDownOptions);
});
</script>

<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton
      class="inline-flex items-center gap-x-2 outline-none focus:ring-0"
    >
      <span
        class="font-montserrat non-italic font-bold text-base leading-10 text-15p"
        :class="props.labelClass"
        >{{ props.label }}</span
      >
      <ArrowDownIcon
        v-if="hasOptions"
        custom-class="w-5 h-3"
        :class="{ 'rotate-180 transform': open }"
        aria-hidden="true"
      />
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <PopoverPanel
        v-if="hasOptions"
        class="md:absolute md:left-1/2 z-10 md:flex md:w-screen md:max-w-max md:-translate-x-1/2 md:px-4 outline-none focus:ring-0 font-montserrat md:ml-16"
      >
        <div
          class="w-screen max-w-max flex-auto bg-white text-15p leading-6 md:shadow-sm"
        >
          <div
            v-for="item in props.dropDownOptions"
            :key="item.name"
            class="relative p-4"
          >
            <a :href="item.href" class="font-medium text-indigo-base">
              {{ item.name }}
              <span class="absolute inset-0" />
            </a>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
