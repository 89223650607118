<script setup>
import { ref, computed } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogDescription,
  DialogTitle,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@headlessui/vue";
import { useVModel } from "@vueuse/core";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import GeneralInfoTab from "./views/GeneralInfoTabView.vue";
import LocationsTab from "./views/LocationTabView.vue";
import AvailabilityTab from "./views/AvailabilityTabView.vue";
import EventDetailsTab from "./views/EventDetailsTabView.vue";
import OccurrencesTab from "./views/OccurrencesTabView.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  showModal: Boolean,
});

const isVisible = computed(() => props.showModal);

const events = useVModel(props, "modelValue");
const emit = defineEmits(["update:showModal"]);

const selectedTabPreview = ref(0);
const changeTabPreview = (index) => {
  selectedTabPreview.value = index;
};

const closeModal = () => {
  emit("update:showModal", false);
};

const shouldShowTab = (tabName) => {
  return tabName.toLowerCase() === "occurrences" &&
    !!events?.value?.id === false
    ? false
    : true;
};

const getTabs = computed(() => {
  return [
    { name: "General Info", component: GeneralInfoTab },
    { name: "Location", component: LocationsTab },
    { name: "Availability", component: AvailabilityTab },
    { name: "Event Details", component: EventDetailsTab },
    { name: "Occurrences", component: OccurrencesTab },
  ];
});

const updateListBoxPreview = (newValue) => {
  selectedTabPreview.value = getTabs.value.findIndex(
    (val) => val.name === newValue
  );
};
</script>

<template>
  <div v-if="isVisible" class="relative z-50">
    <div
      class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
    />
    <Dialog
      v-if="isVisible"
      :open="isVisible"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6"
      @close-modal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="max-w-4xl mx-auto bg-white sm:px-16 sm:py-10 sm:rounded-lg sm:shadow z-20 relative"
      >
        <div class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r">
          <button
            class="h-6 w-6 flex items-center justify-center"
            @click="closeModal"
          >
            <span class="sr-only">Close</span>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <DialogPanel>
          <DialogTitle
            class="flex justify-center mt-6 mb-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            {{ events.title }}
          </DialogTitle>
          <DialogDescription class="mb-5">
            <div class="mt-2 mb-2 text-md text-gray-700">
              Status:
              <span class="font-semibold text-gray-700">{{
                events.status
              }}</span>
            </div>
            <TabGroup
              :selected-index="selectedTabPreview"
              @change="changeTabPreview"
            >
              <TabList class="hidden sm:flex space-x border-b border-gray-200">
                <template v-for="tab in getTabs" :key="tab.name">
                  <Tab
                    v-if="shouldShowTab(tab.name)"
                    v-slot="{ selected }"
                    as="template"
                  >
                    <button
                      :class="[
                        selected
                          ? `${primaryColorClassOutlined}`
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'flex-1	whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                      ]"
                      :aria-current="selected ? 'page' : undefined"
                    >
                      <span>&nbsp;{{ tab.name }}</span>
                    </button>
                  </Tab>
                </template>
              </TabList>
              <div class="sm:hidden">
                <Listbox
                  :default-value="getTabs[0].name"
                  :value="getTabs[selectedTabPreview].name"
                  as="div"
                  @update:model-value="updateListBoxPreview"
                >
                  <div class="relative mt-1">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    >
                      <span class="block truncate">{{ getTabs[0].name }}</span>
                      <span
                        class="h-8 w-6 pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        v-for="tab in getTabs"
                        :key="tab.name"
                        v-slot="{ selected }"
                        as="template"
                        :value="tab.name"
                      >
                        <li
                          :class="[
                            'relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ tab.name }}</span
                          >
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </div>
                </Listbox>
              </div>
              <TabPanels>
                <div v-for="tab in getTabs" :key="tab.name">
                  <TabPanel :unmount="false">
                    <component :is="tab.component" v-model="events"></component>
                  </TabPanel>
                </div>
              </TabPanels>
            </TabGroup>
          </DialogDescription>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>
