<template>
  <div class="flex flex-col" :class="showExtraSpace ? 'mb-7' : 'mb-2'">
    <router-link
      :to="{
        name: 'form-loader',
        params: { applicationId: $route.params.applicationId, form: url },
      }"
      class="flex flex-row justify-start items-center"
    >
      <CheckMarkIcon :completed="submitted" />
      <p
        class="text-sm font-semibold ml-4"
        :data-testid="url + '-link-text'"
        :class="submitted ? 'text-gray-650' : 'text-cyan-700'"
      >
        {{ formName }}
      </p>
    </router-link>
    <span
      v-if="formName === 'University Approval Request' && showPendingApproval"
      class="ml-9 text-xs italic text-gray-500"
    >
      Pending university approval
    </span>
  </div>
</template>

<script>
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
export default {
  components: {
    CheckMarkIcon,
  },
  props: {
    formName: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    submitted: {
      type: Boolean,
      default: false,
    },
    showPendingApproval: {
      type: Boolean,
      default: false,
    },
    showExtraSpace: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
