<script setup>
import { computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { TrashIcon } from "@heroicons/vue/24/outline";
import CloudinaryButton from "@/components/CloudinaryUploadWidget";
import { helpers, requiredIf } from "@vuelidate/validators";
import { useVModel } from "@vueuse/core";
import { getRidOfJSON } from "@/util/removeJson.js";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["update:modelValue"]);
const events = useVModel(props, "modelValue", emit);

const events_multimedia_path = "events";

const events_multimedia = computed(() => {
  return events?.value?.event_locations?.[0]?.image ?? [];
});

const parse_media = computed(() => {
  if (Array.isArray(events_multimedia.value)) {
    return events_multimedia.value || [];
  } else if (
    events_multimedia.value &&
    !Array.isArray(events_multimedia.value)
  ) {
    return getRidOfJSON(events_multimedia.value);
  } else {
    return [];
  }
});

const locationObjects = computed(() => {
  return events.value.event_locations ?? [];
});

const events_multimedia_only = computed(() =>
  parse_media.value?.filter((el) => {
    try {
      return new URL(el).pathname.indexOf(events_multimedia_path) > -1;
    } catch {
      return true;
    }
  })
);

const setEventsMultimedia = (payload) => {
  const cloudinary_media = payload.secure_url;
  const baseEventsmultimedia = parse_media.value || [];

  const modifiedLocations = locationObjects.value.map((locationObj, index) => {
    if (index === 0) {
      // Update 'image' property only for the first object since the image is only being stored here for now til this is migrated to events table
      return {
        ...locationObj,
        image: [cloudinary_media, ...baseEventsmultimedia],
      };
    }
    return locationObj;
  });
  const modifiedEventData = {
    ...events.value,
    event_locations: [...modifiedLocations],
    image: [cloudinary_media, ...baseEventsmultimedia],
  };

  events.value = modifiedEventData;
};

const removeEventsMultimediaItem = (element) => {
  const updatedEventMedia = parse_media.value.filter(
    (item) => item !== element
  );
  const modifiedLocations = locationObjects.value.map((locationObj, index) => {
    if (index === 0) {
      // Update 'image' property only for the first object since the image is only being stored here for now til this is migrated to events table
      return {
        ...locationObj,
        image: updatedEventMedia,
      };
    }
    return locationObj;
  });

  const modifiedEventData = {
    ...events.value,
    event_locations: [...modifiedLocations],
    image: updatedEventMedia,
  };
  events.value = modifiedEventData;
};

const eventId = computed(() => events.value.id);

const rules = computed(() => ({
  events_multimedia: {
    required: helpers.withMessage(
      "This field is required to Publish",
      requiredIf(function () {
        return (
          events.value.status === "Active" &&
          !events?.value.event_locations[0].image
        );
      })
    ),
  },
}));

const v$ = useVuelidate(
  rules,
  {
    events_multimedia,
  },
  { $registerAs: "EventsImageUpload", $lazy: true }
);
</script>

<template>
  <div class="flex-1">
    <CloudinaryButton
      :public-id-prefix="eventId"
      :folder="events_multimedia_path"
      @uploaded-media-data="setEventsMultimedia"
    />
    <div
      v-if="v$.events_multimedia.$errors.length"
      class="block text-sm error text-error-900"
    >
      <span v-for="(error, idx) in v$.events_multimedia.$errors" :key="idx">
        {{ error.$message }}
      </span>
    </div>
    <div class="flex flex-wrap">
      <div
        v-for="(src, idx) in events_multimedia_only"
        :key="idx"
        class="w-24 h-24 mr-10 relative flex-none mt-10 hover:shadow-2xl hover:scale-110 duration-300"
      >
        <button
          class="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full border border-gray-400 hover:text-red-100 hover:scale-125 duration-150"
          @click="removeEventsMultimediaItem(src)"
        >
          <TrashIcon class="w-5 h-5 mx-auto" />
        </button>
        <img :src="src" :alt="`Image ${idx}`" class="h-full w-full" />
      </div>
    </div>
  </div>
</template>
