<template>
  <div class="px-6 pt-10 pb-64 bg-white" role="region" aria-label="No Results">
    <div class="flex flex-col justify-center max-w-lg mx-auto">
      <img src="/images/no-records.svg" alt="no records" />
      <p class="text-base text-center md:text-lg">
        None of your students are currently in this stage. Please check back
        again later, or adjust your notification settings to be alerted of any
        updates!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoRecords",
};
</script>
