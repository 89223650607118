<template>
  <div v-if="props.items.length" class="text-indigo-base">
    <p class="font-bold text-base" :class="props.titleClass">
      {{ props.title }}
    </p>
    <ul class="list-disc ml-5 text-sm">
      <li
        v-for="item in items"
        :key="item"
        class="text-sm font-medium leading-6"
      >
        {{ item }}
      </li>
    </ul>
    <div
      v-if="props.items.length > props.maxItems"
      class="uppercase font-bold text-teal-900 pt-4 flex cursor-pointer"
      @click="openModal(props)"
    >
      <span class="inline-block">See more</span>
      <div class="inline-block ml-3 py-1">
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -7.77409e-07L-7.14641e-07 1.71183L5.38866 7.00874Z"
            fill="#007F80"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, inject } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  items: {
    type: Array,
    default: () => [],
  },
  maxItems: {
    type: Number,
    default: 4,
  },
  titleClass: {
    type: String,
    default: "",
  },
});
const openModal = inject("openModal");

const items = computed(() => props.items.slice(0, props.maxItems));
</script>
