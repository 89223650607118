<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps, computed } from "vue";
import { useVModel } from "@vueuse/core";
import { useStore } from "vuex";
import {
  COMMUNICATION,
  MOBILITY,
  SENSORY,
  DEFAULT_EXCURSION_IMAGE,
} from "@/constants";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const events = useVModel(props, "modelValue");
const store = useStore();

const getLocations = computed(() => {
  return store.getters["events/getLocations"];
});

const locations = computed(() => {
  return events?.value?.id
    ? getLocations.value.filter(
        (location) => location?.event_id === events?.value?.id
      )
    : [];
});

const defaultImage = DEFAULT_EXCURSION_IMAGE;
</script>
<template>
  <div class="relative mt-4">
    <div class="mt-4 text-base text-gray-700">
      Getting to the Event:
      <span v-if="events.Address" class="font-semibold text-gray-700">{{
        events.Address
      }}</span>
    </div>
    <div v-for="(location, index) in locations" :key="index" class="relative">
      <div class="font-semibold text-gray-700 mt-4">
        Location {{ index + 1 }}
      </div>
      <div class="text-base mb-4 font-medium text-gray-700">
        Address:
        <span v-if="location.address" class="font-semibold"
          >{{ location.address?.number }}
          {{ location.address?.street_address_1 }},
          {{ location.address?.city }}, {{ location.address?.state }},
          {{ location.address?.country }}</span
        >
      </div>
      <div class="text-base mb-4 font-medium text-gray-700">
        City:
        <span v-if="location.city" class="font-semibold"
          >{{ location.city?.city_ascii }},
          {{ location.city?.country?.name }}</span
        >
      </div>
      <div class="mt-4">
        Image(s):
        <div v-if="events.image">
          <img
            v-for="(image, ind) in JSON.parse(events.image)"
            :key="ind"
            :src="image ?? DEFAULT_EXCURSION_IMAGE"
            class="mt-4"
          />
        </div>
        <div v-else>
          <img :src="defaultImage" class="mt-4" />
        </div>
      </div>
      <div class="mt-4">
        Accessibility:
        <div class="mt-4">
          Mobility:
          <span v-if="location?.mobility" class="font-semibold">{{
            MOBILITY.filter((item) =>
              JSON.parse(location.mobility).includes(item.id)
            )
              .map((item) => item.name)
              .join(", ") || ""
          }}</span>
        </div>
        <div class="mt-4">
          Communication:
          <span v-if="location?.communication" class="font-semibold">{{
            COMMUNICATION.filter((item) =>
              JSON.parse(location.communication).includes(item.id)
            )
              .map((item) => item.name)
              .join(", ") || ""
          }}</span>
        </div>
        <div class="mt-4">
          Sensory:
          <span v-if="location?.sensory" class="font-semibold">{{
            SENSORY.filter((item) =>
              JSON.parse(location.sensory).includes(item.id)
            )
              .map((item) => item.name)
              .join(", ") || ""
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
