<template>
  <div>
    <Teleport v-if="parentMounted" to="#portal-title">
      <h2
        id="formtitle"
        class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
      >
        {{ titleText }}
      </h2>
      <p class="text-gray-600">
        We will send you an email with a link to {{ subTitleText }} your
        password
      </p>
    </Teleport>
    <form
      id="forgot-password"
      class="grid grid-cols-1 gap-4 md:grid-cols-2 sm:gap-6 md:place-items-center"
      action=""
      aria-label="Reset Password"
      @submit.prevent="submitForm()"
    >
      <label for="email" class="block text-sm md:col-span-2 md:w-382px">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Email</span>
        </span>
        <input
          id="email"
          v-model.trim="v$.email.$model"
          data-cy="email"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.email.$error }"
          @input="email = $event.target.value.toLowerCase()"
        />
        <span
          v-if="v$.email.required.$invalid && v$.email.$error"
          class="block error text-error-900"
        >
          Field is required
        </span>
        <span v-if="v$.email.email.$invalid" class="block error text-error-900">
          Email must be a valid email
        </span>
        <span
          v-if="v$.email.maxLength.$invalid"
          class="block error text-error-900"
        >
          Email must have no more than
          {{ v$.email.maxLength.$params.max }} characters.
        </span>
      </label>
      <div class="mt-6 md:col-span-2 text-center">
        <button
          id="submit"
          data-cy="password-reset-submit-button"
          type="submit"
          :disabled="v$.$invalid || disabledButton"
          :class="[
            tertiaryColor
              ? `${tertiaryColorClass}`
              : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
          ]"
          class="m-auto block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-gray-700 md:w-186px min-h-50px"
        >
          <span>Submit</span>
        </button>
        <div
          v-if="sendSubmissionError"
          data-cy="submission-errors"
          class="error text-error-900 mt-2"
        >
          {{ sendSubmissionError }}
        </div>
        <div
          v-if="emailError"
          data-cy="email-error"
          class="error text-error-900 mt-2"
        >
          We could not find an account for this email. If you are a student, you
          can create an account
          <router-link :to="{ path: '/create-account' }">
            here.
          </router-link>
          If you are an advisor, please contact
          <a
            :class="[
              secondaryColor
                ? `${secondaryColorClass}`
                : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
            ]"
            class="font-semibold focus:underline"
            :href="`mailto:productsupport${APICompany.companyEmail}`"
          >
            productsupport@apiexperience.com.
          </a>
        </div>
      </div>
    </form>
    <div class="grid grid-cols-1 gap-4 sm:gap-6">
      <div class="mt-6 text-center text-gray-600">
        <AlreadyHaveAccount />
      </div>
    </div>
  </div>
</template>

<script>
import AlreadyHaveAccount from "./AlreadyHaveAccount.vue";

import { email, maxLength, required } from "@vuelidate/validators";
import { isEmployeeEmail } from "@/mixins/helpers";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { APICompany } from "../../constants";

export default {
  components: {
    AlreadyHaveAccount,
  },

  props: {
    componentName: {
      type: String,
      default: "set-form",
    },
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["changeComponent", "setEmail", "setPassword"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      emailError: false,
      email: "",
      disabledButton: false,
      sendSubmissionError: "",
      APICompany,
    };
  },
  validations: {
    email: {
      required,
      maxLength: maxLength(120),
      email,
    },
  },
  computed: {
    titleText() {
      return this.componentName === "set-form"
        ? "Setting your password"
        : "Forgot your password";
    },
    subTitleText() {
      return this.componentName === "set-form" ? "set" : "reset";
    },
  },
  watch: {
    email: function () {
      this.disabledButton = false;
      this.sendSubmissionError = "";
      this.emailError = false;
    },
  },
  created() {
    this.$emit("setPassword", false);
  },

  methods: {
    async checkUserAccountExists(email) {
      const url = `/api/user/exists/${email}`;
      return axios.get(url);
    },
    async submitForm() {
      this.sendSubmissionError = "";
      this.v$.$touch();
      this.disabledButton = true;

      if (isEmployeeEmail(this.email)) {
        this.sendSubmission();
      } else {
        this.checkUserAccountExists(this.email.toLowerCase())
          .then((result) => {
            if (result.status === 400) {
              this.emailError = true;
            }
            this.sendSubmission();
          })
          .catch((error) => {
            console.log(error);
            this.emailError = true;
          });
      }
    },
    async sendSubmission() {
      try {
        await axios.post("/forgot-password", {
          email: this.email.toLowerCase(),
        });
        this.$emit("setEmail", this.email.toLowerCase());
        this.$emit("changeComponent", "reset-confirmation");
      } catch (e) {
        this.disabledButton = false;
        if (e.response.status === 500) {
          this.emailError = true;
        } else {
          Object.values(e.response.data.errors).forEach((field) => {
            field.forEach((error) => {
              this.sendSubmissionError += error + "\n";
            });
          });
        }
      }
    },
  },
};
</script>
