<template>
  <div>
    <div class="relative pt-6 sm:p-6 my-1">
      <div class="flex justify-between items-center">
        <h1 class="text-xl font-semibold leading-6 text-gray-900">
          Units
        </h1>
        <RequiredToSavePublish />
      </div>
      <div
        v-if="v$.getCurrentEditingUnits.$errors.length"
        class="block text-sm error text-error-900"
      >
        <span
          v-for="(error, idx) in v$.getCurrentEditingUnits.$errors"
          :key="idx"
        >
          {{ error.$message }}
        </span>
      </div>
      <div class="mt-8">
        <UnitSection
          :units-props="getCurrentEditingUnits"
          @addUnit="addUnitInternal"
          @deleteUnit="deleteUnitInternal"
          @addUnitRoom="addUnitRoomInternal"
          @deleteUnitRoom="deleteUnitRoomInternal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { mapActions, mapGetters } from "vuex";
import UnitSection from "../UnitSection/index.vue";
import RequiredToSavePublish from "../RequiredToSavePublish/index.vue";
import { helpers } from "@vuelidate/validators";

export default {
  name: "UnitsTab",
  components: { UnitSection, RequiredToSavePublish },
  setup() {
    return {
      v$: useVuelidate({
        $registerAs: "UnitsTab",
        $lazy: true,
        $autoDirty: true,
      }),
    };
  },
  validations: {
    getCurrentEditingUnits: {
      required: helpers.withMessage(
        "One Unit must be active to Publish",
        function () {
          return (
            this.getCurrentHousingStatus !== "Active" ||
            (this.getCurrentHousingStatus === "Active" &&
              !!this.getCurrentEditingUnits?.find(
                (unit) => unit.status === "Active"
              ))
          );
        }
      ),
    },
  },
  computed: {
    ...mapGetters("programManager", [
      "getCurrentHousingType",
      "getGenericListing",
      "getLoading",
      "getCurrentEditingUnits",
      "getCurrentHousingStatus",
    ]),
  },
  methods: {
    addUnitInternal(unit) {
      this.addUnit(unit);
    },
    deleteUnitInternal(unitIndex) {
      this.deleteUnit(unitIndex);
    },
    addUnitRoomInternal(unitIndex, room) {
      this.addUnitRoom({ unitIndex, room: room });
    },
    deleteUnitRoomInternal(unitIndex, roomIndex) {
      this.deleteUnitRoom({ unitIndex, roomIndex });
    },
    ...mapActions("programManager", [
      "addUnit",
      "deleteUnit",
      "addUnitRoom",
      "deleteUnitRoom",
    ]),
  },
};
</script>
