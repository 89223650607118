// JUAN-396 temporary set of id mappings to make legacy search links work

const programTypes = {
  "1": "Study",
  "2": "Internship",
  "3": "Fellowship",
  "4": "Faculty Led",
  "5": "Career Expedition",
  "6": "Research",
  "7": "Virtual",
  "8": "Volunteer",
  "9": "Teaching English"
};


const courseLevel = {
  "b0bb9878-b14a-4805-a8aa-fbda4a266ae1": "Undergraduate",
  "f11894e2-abb0-491e-aa2b-d75ca94b0cf2": "High School",
  "536eac19-e976-42d2-bb3f-b64d29b20f0a": "Continuing Education",
  "da4b9dc5-4d27-4977-84ee-750b851b7f9e": "Not Enrolled",
  "2b9e92c0-4e8d-4c9c-8e67-fa70bd301923": "Professional"
};

const timeframe = {
  "4f93aacb-f4b8-46ef-b889-4aca9ef1aed0": "Fall",
  "a7c12af0-3a30-4178-9d5e-925023453d0a": "Spring",
  "357e6b9f-ed7a-426f-8841-748a64cdd795": "Summer 1",
  "7dd07bf8-e2b2-456b-a063-37cf68a29676": "Summer 2",
  "9021a330-7807-4057-9276-ee7818a8a007": "Summer 3",
  "55b88ec4-0bdb-4f00-b52b-a9d4b9b7443e": "Summer",
  "996e9614-af09-4fcf-878c-1b84e6ad6b53": "Fall Quarter",
  "1e720dbf-0690-4152-bf46-8434bc7e84b6": "Winter Quarter",
  "6e4f1e4d-82ae-4ffc-a35a-2953eefa8d64": "Jan Term",
  "9f91a5ae-1781-4c43-9f4b-bf790737a505": "Maymester",
  "5d7c74a7-cea8-4717-88fd-37cf67e9f610": "Virtual",
  "ce5ea321-0106-4319-bc69-9e142a3b45b6": "Fall Early Start",
  "39f4643c-9ab9-4e91-95f2-67111a510981": "Spring Early Start",
  "63a35e7b-190e-4a41-9f2b-167234b27e6b": "Fall Break",
  "e9b37baa-2ad8-4348-94bf-e9f9a1b5292d": "Fall Extended",
  "fd6940c0-d5fa-4189-b4c6-df1a0b0eef81": "Spring Break",
  "ceeb9241-f7f0-47c7-9566-f13d45ae2474": "Spring Extended",
  "9d89c32b-ff06-4ee7-9d2b-df64a1f570bc": "Spring Quarter",
  "678a6065-cd88-413f-98ed-5e39be538d95": "Academic Year"
};

const countries = {
  "AE": "United Arab Emirates",
  "AR": "Argentina",
  "AU": "Australia",
  "BT": "Bhutan",
  "CL": "Chile",
  "CN": "China",
  "CR": "Costa Rica",
  "CU": "Cuba",
  "CZ": "Czech Republic",
  "ES": "Spain",
  "FR": "France",
  "GB": "United Kingdom",
  "GR": "Greece",
  "HR": "Croatia",
  "HU": "Hungary",
  "IE": "Ireland",
  "IS": "Iceland",
  "IT": "Italy",
  "JP": "Japan",
  "KR": "Korea, Republic of",
  "NZ": "New Zealand",
  "PL": "Poland",
  "PT": "Portugal",
  "US": "United States",
  "VT": "Virtual"
};

const cities = {
  "2": "Virtual",
  "1032433516": "Mendoza",
  "1032717330": "Buenos Aires",
  "1036074917": "Sydney",
  "1036153217": "Gold Coast",
  "1036174167": "Mount Gravatt",
  "1036192929": "Brisbane",
  "1036237009": "Kensington",
  "1036417513": "Southport",
  "1064010361": "Thimphu",
  "1152337970": "Valparaiso",
  "1152554349": "Santiago",
  "1152898644": "Vina del Mar",
  "1156040259": "Shanghai",
  "1156073548": "Shanghai",
  "1156208074": "Shanghai",
  "1156281216": "Shanghai",
  "1156343886": "Shanghai",
  "1188000396": "Flores",
  "1188001047": "San Jose",
  "1188308310": "San Joaquin",
  "1188999754": "Flores",
  "1188999755": "Flores",
  "1191004286": "Dubrovnik",
  "1191233290": "Zagreb",
  "1192752771": "Havana",
  "1203744823": "Prague",
  "1250001272": "Caen",
  "1250003308": "Cannes",
  "1250015082": "Paris",
  "1250071647": "Grenoble",
  "1250487828": "Cannes",
  "1276451290": "Berlin",
  "1300715560": "Athens",
  "1348611435": "Budapest",
  "1352327190": "Reykjavik",
  "1372126011": "Limerick",
  "1372438535": "Maynooth",
  "1372499968": "Galway",
  "1372595407": "Dublin",
  "1372927438": "Dublin",
  "1372955192": "Limerick",
  "1380071816": "Syracuse",
  "1380335471": "Tuscania",
  "1380382862": "Rome",
  "1380682007": "Milano",
  "1380724377": "Milan",
  "1380726561": "Florence",
  "1380790975": "Taormina",
  "1380832728": "Bolognana",
  "1392685764": "Tokyo",
  "1410836482": "Seoul",
  "1422784985": "Aadchit",
  "1554435911": "Auckland",
  "1554475997": "Dunedin",
  "1554772152": "Wellington",
  "1591672475": "Panama City",
  "1616172264": "Krakow",
  "1620619017": "Lisbon",
  "1724153231": "Valencia",
  "1724247413": "Santander",
  "1724331267": "Bilbao",
  "1724502822": "Cadiz",
  "1724594040": "Barcelona",
  "1724616994": "Madrid",
  "1724929320": "Granada",
  "1724960825": "Salamanca",
  "1724981666": "Valencia",
  "1724991838": "Sevilla",
  "1784152993": "Sharjah",
  "1826289852": "Sheffield",
  "1826343963": "Leeds",
  "1826426576": "Leeds",
  "1826458841": "Alnwick",
  "1826492520": "Edinburgh",
  "1826517007": "Stirling",
  "1826645935": "London",
  "1840000137": "Portland",
  "1840000327": "Portland",
  "1840000640": "Amherst",
  "1840000978": "Portland",
  "1840002324": "Amherst",
  "1840003073": "Portland",
  "1840004915": "Portland",
  "1840006373": "Amherst",
  "1840010332": "Amherst",
  "1840014175": "Portland",
  "1840014418": "Portland",
  "1840014841": "Portland",
  "1840019274": "Amherst",
  "1840019941": "Portland",
  "1840020428": "Oklahoma City",
  "1840021006": "Portland",
  "1840024052": "Portland",
  "1840024283": "Amherst",
  "1840024696": "Portland",
  "1840028762": "Portland",
  "1840030100": "Portland",
  "1840032092": "Portland",
  "1840034016": "New York",
  "1840035856": "Amherst",
  "1840042947": "Portland",
  "1840050012": "Portland",
  "1840052322": "Amherst",
  "1840052690": "Portland",
  "1840053454": "Amherst",
  "1840054510": "Amherst",
  "1840057194": "Amherst",
  "1840058415": "Portland",
  "1840062097": "Portland",
  "1840071589": "Amherst",
  "1840077889": "Amherst",
  "1840089350": "Amherst",
  "1840093980": "Portland",
  "1840124019": "Portland"
};

const hostInstitution = {
  "0016e00003H7TkKAAV": "Virtual Classes - US Institutions",
  "0015A00002DC2OfQAL": "London School of Economics and Political Science",
  "0015A00002DC2PLQA1": "Universidad de Granada",
  "0015A00002DC2PZQA1": "Universidade Nova de Lisboa (NOVA)",
  "0016e00003H6ZD5AAN": "Parsons Cannes - ESRA Film School",
  "0015A00002DC2OrQAL": "Universidad Torcuato Di Tella",
  "0015A00002DC2OuQAL": "University of Leeds",
  "001Ql00000DVGtyIAH": "University of Massachusetts, Amherst in Costa Rica",
  "0015A00002DC2OsQAL": "University College Dublin",
  "0015A00002DC2PQQA1": "Université Grenoble Alpes (CUEF)",
  "0015A00002DC2NNQA1": "Cours de Civilisation Française de la Sorbonne",
  "0015A00002Huo2qQAB": "Charles University",
  "0016e00003AfulCAAR": "London College of Fashion",
  "0015A00002DC2NDQA1": "Jagiellonian University of Kraków",
  "0015A00002DC2ONQA1": "Instituto Universitario de Lisboa (ISCTE)",
  "0016e00003N7cRVAAZ": "Berklee College of Music",
  "0015A00002DC2PdQAL": "Universidad del Salvador",
  "0015A00002DC2PKQA1": "Universitat Pompeu Fabra",
  "0015A00002GBMIeQAP": "VSE University of Economics",
  "0016e00002akWC2AAM": "Kingston University London",
  "0015A00002DC2PaQAL": "Universidad Adolfo Ibañez: Santiago",
  "0015A00002DC2OXQA1": "Instituto San Joaquín de Flores",
  "0015A00002DC2OQQA1": "John Cabot University",
  "0015A00002DC2P4QAL": "Victoria University of Wellington",
  "0015A00002DC2P3QAL": "AUT University",
  "0015A00002DC2PEQA1": "Universidad del País Vasco",
  "0015A00002DC2PNQA1": "ESADE Business School",
  "0015A00002DC2PMQA1": "Universidad de Deusto",
  "0015A00002DC2P5QAL": "Griffith University - Brisbane",
  "0015A00002DC2PIQA1": "Universidad de Sevilla",
  "0015A00002DC2P7QAL": "Macquarie University",
  "0015A00002DC2PSQA1": "Reykjavík University",
  "0015A00002DC2PDQA1": "Universitat Autònoma de Barcelona",
  "0016e00003H7GTtAAN": "UMass Amherst Croatia",
  "001Ql0000047ZI5IAM": "Parsons Paris - The New School, Barcelona",
  "001Ql000003XT6MIAW": "Marino Institute of Education",
  "001Ql00000AAzpHIAT": "College of Charleston - Buenos Aires",
  "001Ql000003tsCZIAY": "Madison Area Technical College",
  "0015A00002DC2N1QAL": "Université Paris Dauphine",
  "0015A00002DC2OYQA1": "American University of Sharjah",
  "0015A00002DC2OlQAL": "Universidad de la Habana",
  "0015A00002DC2P1QAL": "Universidad Alberto Hurtado",
  "0015A00002GBMDvQAP": "International Center for Development Studies (ICDS)",
  "0016e00003H7GTKAA3": "Southern Oregon University - Costa Rica",
  "0016e00003H7GU3AAN": "Fort Hayes State University - Alnwick, England",
  "0015A00002DC2MoQAL": "Lorenzo de’Medici: The Italian International Institute, Florence",
  "0015A00002DC2OoQAL": "Lorenzo de’Medici: The Italian International Institute, Tuscania",
  "0015A00002OMgovQAD": "American University of Rome",
  "0015A00002DC2N9QAL": "University of Galway",
  "0015A00002DC2PgQAL": "Massey University - Wellington",
  "0015A00002L1xz1QAB": "Parsons Paris - The New School",
  "0015A00002DC2PRQA1": "Elisava",
  "0015A00002DC2P2QAL": "University of Otago",
  "0015A00002DC2N7QAL": "Suffolk University",
  "0015A00002DC2PcQAL": "Paris School of Business",
  "0015A00002DC2PfQAL": "University of Auckland",
  "0015A00002DC2PhQAL": "Universidad CEU San Pablo",
  "0015A00002DC2PjQAL": "Parsons Paris & Polimoda",
  "0016e00003H7ySvAAJ": "Korea University",
  "0015A00002GBLJuQAP": "FSU London Study Centre",
  "0016e00003H724UAAR": "Universidad Adolfo Ibáñez: Viña del Mar",
  "0016e00003H7pK1AAJ": "Academic Programs International - Remote",
  "0016e00003H7pK2AAJ": "Academic Programs International - Reykjavik",
  "0015A00002DC2PFQA1": "Universidad Complutense",
  "0015A00002DC2OvQAL": "University of Stirling",
  "0015A00002DC2OjQAL": "Trinity College Dublin",
  "0015A00002DC2MzQAL": "Grenoble École de Management",
  "0015A00002DC2PJQA1": "Universitat de Barcelona",
  "0015A00002DC2NAQA1": "University of Limerick",
  "0015A00002DC2OWQA1": "Universidad Veritas",
  "0016e00003H82nDAAR": "Hellenic American University",
  "0015A00002DC2NEQA1": "The University of Westminster",
  "0016e00003H71EzAAJ": "Griffith University - Gold Coast",
  "0015A00002DC2N8QAL": "Institut Catholique de Paris (ICP)",
  "0016e00002qalEPAAY": "University of Dubrovnik",
  "0015A00002DC2OtQAL": "University College London",
  "0015A00002DC2P9QAL": "University of Edinburgh",
  "0015A00002DC2PGQA1": "Universidad de Salamanca",
  "0015A00002DC2PPQA1": "Universidad Nebrija",
  "0015A00002E7PwxQAF": "Sciences Po Grenoble",
  "0016e00002zgJlSAAU": "UNSW",
  "0015A00002DC2NGQA1": "Universidad de Belgrano",
  "0015A00002DC2OgQAL": "Maynooth University",
  "0015A00002DC2OiQAL": "Royal Thimphu College",
  "0015A00002DC2OAQA1": "Universidad de Congreso",
  "0015A00002DC2OSQA1": "Pontificia Universidad Católica de Valparaíso",
  "0015A00002DC2OcQAL": "Heriot-Watt University",
  "0015A00002DC2PCQA1": "Universidad Pablo de Olavide",
  "0015A00002DC2POQA1": "East China Normal University",
  "0015A00002DC2PeQAL": "University of the Arts London",
  "0016e00002zhlaLAAQ": "FSU Valencia Study Center",
  "0015A00002HubijQAB": "Syracuse Academy",
  "0016e00002i9R0QAAU": "Richmond American University London",
  "0016e00003AfYgCAAV": "CETT Barcelona School of Tourism, Hospitality, and Gastronomy",
  "0015A00002DC2NCQA1": "Corvinus University of Budapest",
  "0016e00003AfjtKAAR": "Lakeland University Japan",
  "0015A00002DC2N0QAL": "Université Paris Diderot",
  "0016e00003H71EfAAJ": "Università Cattolica del Sacro Cuore, Rome",
  "0016e00003AeeaNAAR": "Nuova Accademia di Belle Arti",
  "0016e00002ZG8BeAAL": "Università Cattolica del Sacro Cuore, Milan",
  "0016e00003H7pJhAAJ": "Academic Programs International - Dublin",
  "0015A00002DC2XLQA1": "Syracuse University"
};

const language = {
  "6741d907-e50c-47e2-8c51-bc51095b4222": "English",
  "01450428-ccbe-4b9c-9730-bef673958378": "Spanish",
  "88637048-253b-4ca3-bcad-ab4301347d84": "French",
  "d08f570d-806c-40b5-bef8-be2120da2c34": "Italian",
  "60bbb227-9dac-437d-9dd6-793d2090e876": "Arabic",
  "4dcd09a3-be35-46da-9aee-7841a30d2c9e": "Polish",
  "9f0a84ec-34ec-48d7-8fbc-61ceadbaa4bb": "Catalan",
  "0ca7cc27-1873-41da-a952-72badfb904db": "Portuguese",
  "2d4b08c2-06f4-43ec-a39b-637f07fbddf5": "Latin",
  "7b233cbb-7847-4f01-b716-bc215b70bc6a": "Dzongkha",
  "4591c0bf-4c9b-4d16-8253-750c2ff5155e": "Chinese",
  "b0c77279-8633-40ce-bb5e-9f1396f4992d": "Czech",
  "79dca12a-96a0-44d5-ae42-5619a5078075": "German",
  "4ecc843e-c652-4ed9-bda3-66d478db7ad2": "Croatian",
  "5f42a58e-9ac9-4cbd-9cff-7a36af767be8": "Hungarian",
  "dbaf7cf6-b793-48ca-9556-8d5300c47a5b": "Korean",
  "c332d108-f70b-4bd4-a80b-a103051ae792": "Greek",
  "2efd2a9c-7b33-408a-97b1-ecf02d7d416a": "Japanese",
  "03de388b-d71c-4a1b-afd9-a4c2d09244bf": "Russian",
  "11346a3e-c0e1-4265-8d3f-9cd2fd374f7f": "Irish"
};


const areaToTimeframe = {
  "55d50f6b-6165-482d-b68d-d47410ea77e4": ['Academic Year'],
  "5ff71653-60fd-4e02-9e74-1a6191d0f58e": ['Spring', 'Spring Early Start', 'Fall', 'Fall Early Start'],
  "7a0247fb-b479-42d7-8ed3-003dba17e06e": ['Jan Term', 'Maymester'],
  "e76873aa-b355-4875-b7a8-875ac31fb8b5": ['Fall Quarter', 'Spring Quarter', 'Winter Quarter'],
}

const paramToURLIdMap = {
  programTypes,
  courseLevel,
  timeframe,
  cities,
  countries,
  hostInstitution,
  language,
};

export const checkForLegacyMapping = (paramName, value) => {
  if(paramToURLIdMap[paramName] && paramToURLIdMap[paramName][value]) {
    return paramToURLIdMap[paramName][value];
  }
  return value;
};


export const checkForProgramLengthLegacyMapping = (value) => {
  if(areaToTimeframe[value]) {
    return areaToTimeframe[value];
  }
  return [];
};

