<template>
  <svg
    width="163"
    height="163"
    viewBox="0 0 163 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="81.5" cy="81.5" r="81.5" fill="#E3F8F8" fill-opacity="0.5" />
    <path
      d="M81.9996 79.0781L55.0781 125.231H108.923L81.9996 79.0781Z"
      stroke="#007F80"
      stroke-width="5.12827"
      stroke-miterlimit="10"
    />
    <path
      d="M82 119.461V102.152"
      stroke="#007F80"
      stroke-width="5.12827"
      stroke-miterlimit="10"
    />
    <path
      d="M82 98.3087V94.4609"
      stroke="#007F80"
      stroke-width="5.12827"
      stroke-miterlimit="10"
    />
    <path
      d="M58.9816 104.049C58.6457 104.069 58.3058 104.077 57.9621 104.077C46.8096 104.077 37.7695 95.0377 37.7695 83.8843C37.7695 76.478 41.7579 70.0053 47.6993 66.4894C47.6993 66.4894 46.9688 61.0128 48.1133 57.7784C50.0587 52.2745 54.9767 48.204 60.6798 47.6415C65.9378 47.122 70.6996 49.3837 73.7269 53.1533C75.7504 43.7313 84.0043 36.6883 93.8714 36.7702C104.465 36.8562 113.497 45.575 114.129 56.3762C114.329 59.8059 113.704 63.06 112.454 65.9741C120.184 66.7241 126.231 73.3609 126.231 81.439C126.231 88.8766 124.309 104.049 104.84 104.049"
      stroke="#007F80"
      stroke-width="5.12827"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  name: "MaintenanceIcon",
};
</script>
