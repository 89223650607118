<script setup>
import Modal from "../Modal.vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import PlusIcon from "@/components/shared/icons/PlusIcon.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import { useVModel } from "@vueuse/core";
import { defineProps, ref, watch, computed, defineEmits, reactive } from "vue";
import BaseToggle from "../../forms/SharedComponents/BaseToggle.vue";
import { debounce, uniqBy } from "lodash";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import { useGetCityOptions } from "@/components/program-manager/sessions/composable.js";
import AddressComponent from "../../forms/AddressInputComponent/AddressComponent.vue";
import CalendarInput from "@/components/shared/CalendarInput.vue";
import ProfileLeaderSelect from "@/components/forms/ProfileLeaders/ProfileLeaderSelect.vue";
import {
  listOfFormattedTimezones,
  dateTimeIsBeforeToday,
  endDateTimeIsBeforeStartDate,
  dateIsBeforeToday,
  endDateIsBeforeStartDate,
} from "../../../util/date";
import { format } from "date-fns";
import { EVENTS_STATUS, LIBRARY_ID } from "@/constants.js";
import eventsService from "@/services/events.js";
import profileService from "@/services/profile.js";
import productPriceService from "@/services/productPrices";
import { useToast } from "vue-toast-notification";
import Spinner from "@/components/helpers/Spinner.vue";
import { useStore } from "vuex";
import { handleOperationFailure } from "@/components/modals/utils.js";

const store = useStore();

const props = defineProps({
  edit: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  eventName: {
    type: String,
    default: "Event Name",
  },
  location: {
    type: String,
    default: "Event Location",
  },
  defaultTimeZone: {
    type: Number,
    default: 0,
  },
  modelValue: {
    type: Object,
    default: () => {},
  },
  isOnline: {
    type: Boolean,
    default: false,
  },
  enableModal: {
    type: Boolean,
    default: true,
  },
});

const title = computed(() => `${!props.edit ? "Create" : "Edit"} Occurrence`);
const emit = defineEmits([
  "update:modelValue",
  "closeModal",
  "openModal",
  "validator",
]);
const occurrence = useVModel(props, "modelValue", emit);

const validateTab = async () => {
  return v$.value.$validate().then(() => {
    return !v$.value.$invalid;
  });
};

emit("validator", validateTab);

const productPrice = ref({});
const saveSuccessful = ref(false);
const toast = useToast();
const disableSave = ref(false);
const priceStudent = ref(occurrence.value.price_student / 100 || 0);
const hasMaxCapacity = ref(false);

const startDateAndTimeIsFinal = ref(false);
const endDateAndTimeIsFinal = ref(false);

const isLoading = ref(false);
const activeDateTimeBeforeToday = ref(false);

function openModal() {
  if (props.enableModal) emit("openModal");
}

function updateStartDateAndTimeIsFinal() {
  occurrence.value.start_date_time_is_final = startDateAndTimeIsFinal.value;
}

function updateEndDateAndTimeIsFinal() {
  occurrence.value.end_date_time_is_final = endDateAndTimeIsFinal.value;
}
watch(
  () => props.open,
  () => {
    if (props.open) {
      setStatus();
      startDateAndTimeIsFinal.value =
        occurrence.value?.start_date_time_is_final ?? false;
      endDateAndTimeIsFinal.value =
        occurrence.value?.end_date_time_is_final ?? false;
      setInitialDatesTimes();
      setInitialLeaders();
      hasMaxCapacity.value = occurrence.value?.max_capacity ? true : false;
      priceStudent.value = occurrence.value.price_student / 100 || 0;
    }
  }
);

function closeModal() {
  startDateTIme.date = "";
  startDateTIme.time = "";
  endDateTIme.date = "";
  endDateTIme.time = "";
  allDay.value = false;
  activeDateTimeBeforeToday.value = false;
  productPrice.value = {};
  v$.value.$reset();
  emit("closeModal");
}

async function saveDraft() {
  updateDateTimes();
  removeEmptyLeaders();
  if (props.edit) {
    await updateOccurrence();
  } else {
    await createOccurrence();
  }
  verifySaveAndClose();
}

async function publish() {
  occurrence.value.occurrence_status = EVENTS_STATUS.active;
  occurrence.value.status_id = EVENTS_STATUS.active.id;
  v$.value.$touch();
  const isValid = await v$.value.$validate();
  if (isValid) {
    disableSave.value = false;
    updateDateTimes();
    removeEmptyLeaders();
    if (props.edit) {
      await setProductPrice();
      await updateOccurrence();
      isLoading.value = false;
    } else {
      await createOccurrence();
      isLoading.value = false;
    }
    await createOrUpdateProductPrice();
    verifySaveAndClose();
  }
}

function verifySaveAndClose() {
  if (saveSuccessful.value) {
    closeModal();
    toast.open({
      message: "Occurrence Saved Successfully",
      type: "success",
      position: "bottom",
      duration: 5000,
    });
  } else {
    toast.open({
      message: "Failed to save Occurrence",
      type: "info",
      position: "bottom",
      duration: 5000,
    });
  }
  disableSave.value = false;
}

async function createOccurrence() {
  occurrence.value.price_student = priceStudent.value * 100;
  isLoading.value = true;

  try {
    const createResponse = await eventsService.createOccurrence(
      occurrence.value
    );

    if (createResponse.status === 200 && createResponse.data.data) {
      isLoading.value = false;
      saveSuccessful.value = true;
      Object.assign(occurrence.value, createResponse.data.data);
    }
  } catch (error) {
    isLoading.value = false;
    handleOperationFailure("Failed to create occurrence", error);
  }
}

async function updateOccurrence() {
  occurrence.value.price_student = priceStudent.value * 100;
  isLoading.value = true;
  try {
    const updateResponse = await eventsService.updateOccurrence(
      occurrence.value.id,
      occurrence.value
    );
    if (updateResponse.status === 200 && updateResponse.data.data) {
      isLoading.value = false;
      saveSuccessful.value = true;
      Object.assign(occurrence.value, updateResponse.data.data);
    }
  } catch {
    isLoading.value = false;
    toast.open({
      message: "Failed to update",
      type: "info",
      position: "bottom",
      duration: 5000,
    });
  }
}

async function createOrUpdateProductPrice() {
  if (!saveSuccessful.value) return;

  if (!!productPrice.value?.id && occurrence.value.price_student) {
    await updateProductPrice().catch((error) => {
      handleOperationFailure("Failed to update product price", error);
    });
  } else {
    await createProductPrice().catch((error) => {
      handleOperationFailure("Failed to create product price", error);
    });
  }
}

async function createProductPrice() {
  try {
    const response = await productPriceService.createProductPrice({
      product_id: occurrence.value.id,
      libraries_id: LIBRARY_ID,
      price_in_cents: occurrence.value.price_student,
    });
    if (response.status === 200) {
      saveSuccessful.value = true;
    } else {
      throw new Error("Product price creation failed");
    }
  } catch (error) {
    saveSuccessful.value = false;
    throw new Error("Product price creation failed");
  }
}

async function updateProductPrice() {
  try {
    const response = await productPriceService.updateProductPrice(
      productPrice.value.id,
      {
        id: productPrice.value.id,
        product_id: occurrence.value.id,
        libraries_id: LIBRARY_ID,
        price_in_cents: occurrence.value.price_student,
      }
    );
    if (response.status === 200) {
      saveSuccessful.value = true;
    } else {
      throw new Error("Product price update failed");
    }
  } catch (error) {
    saveSuccessful.value = false;
    throw new Error("Product price update failed");
  }
}

async function setProductPrice() {
  try {
    const {
      data: {
        data: { items },
      },
    } = await productPriceService.getProductPrice(occurrence.value.id);
    productPrice.value = items[0];
  } catch (e) {
    // No Product price, do nothing.
  }
}

const startDateTIme = reactive({
  date: "",
  time: "",
});
const endDateTIme = reactive({
  date: "",
  time: "",
});
const showEndDate = ref(!!occurrence.value?.end_date_time);
const allDay = ref(false);
const timeZone = ref(undefined);
const listOfTimeZones = listOfFormattedTimezones();

// city
const searchCity = ref("");
const city = ref("");
const cityExtraInformation = ref(city.value ? city.value : {});

const getSavedCity = computed(() => {
  return occurrence.value?.departure_city
    ? [occurrence.value?.departure_city]
    : [];
});

watch(searchCity, async () => {
  await fetchSearchableCitiesOptions(searchCity.value);
});

const setCityExtraInformation = (val) => {
  cityExtraInformation.value = val;
};

const {
  execute: executeFetchCityOptions,
  isLoading: cityLoading,
  state: cityOptions,
  isReady: cityReady,
} = useGetCityOptions(
  {
    immediate: true,
    throwError: true,
  },
  {
    city_ascii: city.value?.city_ascii || "chi",
  }
);

const fetchSearchableCitiesOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    if (loading) loading(true);
    try {
      occurrence.value.departure_city.value = "";
      cityExtraInformation.value = {};
      await executeFetchCityOptions(0, {
        city_ascii: search,
        cancel: true,
      });
      if (loading) loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        if (loading) loading(false);
      }
    }
  }
}, 500);
//end city
//Address

const getInitialAddressInfo = computed(() => {
  return {
    street_address_1:
      occurrence.value?.occurrence_address?.street_address_1 || undefined,
    street_address_2:
      occurrence.value?.occurrence_address?.street_address_2 || undefined,
    city: occurrence.value?.occurrence_address?.city || undefined,
    state: occurrence.value?.occurrence_address?.state || undefined,
    iso: occurrence.value?.occurrence_address?.iso || undefined,
    number: occurrence.value?.occurrence_address?.number || undefined,
    country: occurrence.value?.occurrence_address?.country || undefined,
    postal_code: occurrence.value?.occurrence_address?.postal_code || undefined,
    utc_offset_minutes:
      occurrence.value?.occurrence_address?.utc_offset_minutes || undefined,
  };
});

const updateAddressInfo = (address) => {
  const { lat, long } = address;
  occurrence.value.occurrence_address = address;
  occurrence.value.occurrence_address.event_lat_lng = `${lat || ""}, ${
    long || ""
  }`;
  setTimeZone(address.utc_offset_minutes, "offset");
};

// end address
// date stuff
function updateDateTimes() {
  if (showEndDate.value && endDateTIme.time && endDateTIme.date) {
    const utcEndDate = new Date(`${endDateTIme.date}T${endDateTIme.time}`);
    occurrence.value.end_date_time = format(utcEndDate, "yyyy-MM-dd HH:mm");
  }
  if (startDateTIme.time && startDateTIme.date) {
    const utcStartDate = new Date(
      `${startDateTIme.date}T${startDateTIme.time}`
    );
    occurrence.value.start_date_time = format(utcStartDate, "yyyy-MM-dd HH:mm");
  }
  occurrence.value.time_zone = timeZone.value?.timeZone;
}

function setInitialDatesTimes() {
  if (occurrence.value.start_date_time) {
    const splitDateTime = occurrence.value.start_date_time.split("T");
    startDateTIme.date = splitDateTime[0];
    startDateTIme.time = splitDateTime[1];
  }

  if (occurrence.value.end_date_time) {
    showEndDate.value = true;
    const splitDateTime = occurrence.value.end_date_time.split("T");
    endDateTIme.date = splitDateTime[0];
    endDateTIme.time = splitDateTime[1];
  }

  if (occurrence.value?.time_zone) setTimeZone(occurrence.value.time_zone);

  if (startDateTIme?.time == "00:00" && endDateTIme?.time == "23:59") {
    allDay.value = true;
  }
}

function setTimeZone(timeZoneAttr, fromAttr) {
  if ("offset" == fromAttr) {
    timeZone.value = getFormattedTimeZoneFromOffset(timeZoneAttr);
  } else {
    timeZone.value = getFormattedTimeZone(timeZoneAttr);
  }
}

function getFormattedTimeZone(timeZone) {
  return listOfFormattedTimezones().find(
    (obj) => obj.timeZone == timeZone.replace("\\", "")
  );
}

function getFormattedTimeZoneFromOffset(offSetInMinutes) {
  return listOfFormattedTimezones().find(
    (obj) => obj.offSetInMinutes == offSetInMinutes
  );
}

watch(
  () => props.defaultTimeZone,
  () => {
    if (!timeZone.value) setTimeZone(props.defaultTimeZone, "offset");
  }
);

watch(allDay, (isAllDay) => {
  if (isAllDay) {
    startDateTIme.time = "00:00";
    endDateTIme.time = "23:59";
  } else {
    startDateTIme.time = "";
    endDateTIme.time = "";
  }
});
// end date stuff
const numOfAllowedGuests = ref();

watch(hasMaxCapacity, (maxCap) => {
  if (!maxCap && !!occurrence.value?.max_capacity) {
    occurrence.value.max_capacity = null;
  }

  if (!occurrence.value?.guests_allowed) {
    numOfAllowedGuests.value = null;
  }
});

// Start OccurrenceProfiles
const occurrenceProfilesLoading = ref(false);

function addLeaderName() {
  occurrence.value.occurrence_profiles.push({
    legal_first_name: "",
    preferred_first_name: "",
    id: "",
    last_name: "",
  });
}

async function setInitialLeaders() {
  if (
    !occurrence.value?.occurrence_profiles ||
    occurrence.value.occurrence_profiles.length === 0
  ) {
    occurrence.value.occurrence_profiles = [
      {
        legal_first_name: "",
        preferred_first_name: "",
        id: "",
        last_name: "",
      },
    ];
  } else {
    occurrenceProfilesLoading.value = true;
    const profileIds = occurrence.value.occurrence_profiles.map(
      ({ profile_id }) => profile_id
    );
    profileService
      .getProfiles({ extraParams: { profile_ids: profileIds } })
      .then((response) => {
        occurrence.value.occurrence_profiles = response.data.items;
        occurrenceProfilesLoading.value = false;
      });
  }
}

const deleteProfileLeader = (index) => {
  occurrence.value?.occurrence_profiles?.splice(index, 1);
};

function removeEmptyLeaders() {
  occurrence.value.occurrence_profiles = occurrence.value.occurrence_profiles.filter(
    (obj) => !!obj.id
  );
}

// End OccurrenceProfiles

function setStatus() {
  if (!occurrence.value.status_id) {
    occurrence.value.occurrence_status = EVENTS_STATUS.draft;
    occurrence.value.status_id = EVENTS_STATUS.draft.id;
  }
}
// vuelidate

const dateNotInPast = helpers.withMessage(
  "Please enter a date in the future",
  () => {
    if (!startDateTIme.date && !startDateTIme.time) return true;
    const today = format(new Date(), "yyyy-MM-dd");

    if (startDateTIme.date && !startDateTIme.time) {
      return startDateTIme.date >= today;
    }
    const format_date = format(
      new Date(startDateTIme.date + "T" + startDateTIme.time),
      "yyyy-MM-dd"
    );
    return format_date >= today;
  }
);

const dateTimeNotInPast = helpers.withMessage(
  "Please enter a time in the future",
  () => {
    if (!startDateTIme.time) return true;
    const now = new Date();
    let today;
    if (timeZone?.value?.offSetInMinutes) {
      const getOffset = now.getTimezoneOffset();
      const offsetDifference = timeZone?.value?.offSetInMinutes + getOffset;
      const timeInMilliseconds = now.getTime() + offsetDifference * 60000;
      const adjustedDate = new Date(timeInMilliseconds);
      today = format(adjustedDate, "yyyy-MM-dd HH:mm");
    } else {
      today = format(now, "yyyy-MM-dd HH:mm");
    }
    const date_time = format(
      new Date(startDateTIme.date + "T" + startDateTIme.time),
      "yyyy-MM-dd HH:mm"
    );
    return date_time >= today;
  }
);

const endDateNotBeforeStartDate = helpers.withMessage(
  "End date cannot be before the start date",
  () => {
    if (!endDateTIme.date) return true;
    return endDateTIme.date >= startDateTIme.date;
  }
);

const endDateTimeNotBeforeStartDate = helpers.withMessage(
  "End date time cannot be before the start date",
  () => {
    if (!endDateTIme.date) return true;
    if (startDateTIme.time && endDateTIme.time) {
      return (
        new Date(endDateTIme.date + "T" + endDateTIme.time) >=
        new Date(startDateTIme.date + "T" + startDateTIme.time)
      );
    }
    return new Date(endDateTIme.date) >= new Date(startDateTIme.date);
  }
);

const rules = computed(() => ({
  occurrence: {
    price_student: {
      required: helpers.withMessage(
        "Price is required",
        requiredIf(function () {
          return (
            occurrence.value.status_id === EVENTS_STATUS.active.id &&
            !priceStudent.value
          );
        })
      ),
    },
    start_date_time: {
      dateNotInPast,
    },
    start_time: {
      dateTimeNotInPast,
    },
    end_date_time: {
      endDateNotBeforeStartDate,
    },
    end_time: {
      endDateTimeNotBeforeStartDate,
    },
  },
}));

const v$ = useVuelidate(rules, occurrence, {
  $registerAs: "OccurrenceModal",
  $lazy: true,
});

const showMarketplaceToggle = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["tenant-libraries"];
});
</script>
<template>
  <div v-if="!isLoading">
    <Modal
      :title="title"
      :open="props.open && !isLoading"
      :show-logo="false"
      :close-on-outside-click="false"
      @close-modal="closeModal"
    >
      <template #modal-content>
        <div class="flex flex-col">
          <div class="mt-3">
            <label class="block text-sm font-semibold text-gray-700">
              Event
            </label>
            <p>{{ eventName }}</p>
          </div>
          <div class="mt-3">
            <label class="block text-sm font-semibold text-gray-700">
              Location
            </label>
            <p>{{ location }}</p>
          </div>
          <div id="startDate" class="mt-3">
            <label class="block text-sm font-semibold text-gray-700">
              Start Date & Time
            </label>
            <div class="flex flex-row">
              <CalendarInput
                name="date"
                :model-value="startDateTIme.date"
                :class="[
                  {
                    'bg-error-100':
                      v$.occurrence.start_date_time.$errors?.length,
                  },
                ]"
                @on-change="
                  (date) =>
                    (startDateTIme.date =
                      date && date[0]
                        ? format(new Date(date[0]), 'yyyy-MM-dd')
                        : '')
                "
                @blur="v$.occurrence.start_date_time.$touch"
              />
              <div
                v-if="v$.occurrence.start_date_time.$errors?.length"
                class="flex text-sm error text-error-900 justify-center items-center pl-2"
              >
                <span
                  v-for="(error, idx) in v$.occurrence.start_date_time.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
              <input
                v-model="startDateTIme.time"
                class="form-input ml-3"
                type="time"
                :hidden="allDay"
                :class="[
                  {
                    'bg-error-100': v$.occurrence.start_time.$errors?.length,
                  },
                ]"
                @blur="v$.occurrence.start_time.$touch"
              />
              <div
                v-if="v$.occurrence.start_time.$errors?.length"
                class="flex text-sm error text-error-900 justify-center items-center pl-2"
              >
                <span
                  v-for="(error, idx) in v$.occurrence.start_time.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
              <div class="flex flex-row items-center ml-3 gap-4">
                <input
                  v-model="allDay"
                  class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                  type="checkbox"
                />
                <label class="block text-sm font-semibold text-gray-700 ml-2"
                  >All day</label
                >
                <div class="flex flex-row items-center">
                  <input
                    v-model="startDateAndTimeIsFinal"
                    @change="updateStartDateAndTimeIsFinal"
                    name="startDateAndTimeIsFinal"
                    class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                    type="checkbox"
                  />
                  <label
                    for="startDateAndTimeIsfinal"
                    class="block text-sm font-semibold text-gray-700 ml-2"
                    >Start Date & Time is Final</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="showEndDate" id="endDate" class="mt-3">
            <label class="block text-sm font-semibold text-gray-700">
              End Date & Time
            </label>
            <div class="flex flex-row">
              <CalendarInput
                name="date"
                :model-value="endDateTIme.date"
                :class="[
                  {
                    'bg-error-100': v$.occurrence.end_date_time.$errors?.length,
                  },
                ]"
                @on-change="
                  (date) =>
                    (endDateTIme.date =
                      date && date[0]
                        ? format(new Date(date[0]), 'yyyy-MM-dd')
                        : '')
                "
                @blur="v$.occurrence.end_date_time.$touch"
              />
              <div
                v-if="v$.occurrence.end_date_time.$errors?.length"
                class="flex text-sm error text-error-900 justify-center items-center pl-2"
              >
                <span
                  v-for="(error, idx) in v$.occurrence.end_date_time.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
              <input
                v-model="endDateTIme.time"
                class="form-input ml-3"
                type="time"
                :hidden="allDay"
                :class="[
                  {
                    'bg-error-100': v$.occurrence.end_time.$errors?.length,
                  },
                ]"
                @blur="v$.occurrence.end_time.$touch"
              />
              <div class="flex flex-row items-center ml-2">
                <input
                  v-model="endDateAndTimeIsFinal"
                  name="endDateAndTimeIsFinal"
                  class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                  type="checkbox"
                  @change="updateEndDateAndTimeIsFinal"
                />
                <label
                  for="endDateAndTimeIsFinal"
                  class="block text-sm font-semibold text-gray-700 ml-2"
                  >End Date & Time is Final</label
                >
              </div>
              <div
                v-if="v$.occurrence.end_time.$errors?.length"
                class="flex text-sm error text-error-900 justify-center items-center pl-2"
              >
                <span
                  v-for="(error, idx) in v$.occurrence.end_time.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="!showEndDate"
            class="cursor-pointer mt-3 text-teal-600"
            @click="() => (showEndDate = true)"
          >
            <p>Add end time (optional)</p>
          </div>
          <div class="flex flex-row items-center gap-4">
            <v-select
              v-model="timeZone"
              :options="listOfTimeZones"
              label="formatted"
              class="w-[400px] mt-3"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"
                  ><i
                    class="fa fa-caret-down text-gray-500"
                    v-bind="attributes"
                  ></i
                ></span>
              </template>
              <template #option="option">
                <span>{{ option.formatted }}</span>
              </template>
            </v-select>
          </div>
          <div v-if="!props.isOnline" class="mt-3">
            <label class="block text-sm font-semibold text-gray-700">
              Departure City
            </label>
            <SearchableSelect
              v-model="occurrence.departure_city"
              :options="
                uniqBy((cityOptions?.items || []).concat(getSavedCity), 'id') ||
                getSavedCity
              "
              :loading="cityLoading || !cityReady"
              :reduce="(val) => val.id"
              :deselect-from-dropdown="true"
              :close-on-select="true"
              :filterable="false"
              :clear-search-on-blur="() => false"
              label="city_ascii"
              @search="fetchSearchableCitiesOptions"
              @option:selected="(val) => setCityExtraInformation(val)"
            >
              <template #option="{ city_ascii, country, admin_name_ascii }">
                <div class="text-sm">
                  <div class="font-semibold">{{ city_ascii }}</div>
                  <div>
                    {{ country?.name }}
                    <span v-if="country?.name && admin_name_ascii">-</span>
                    {{ admin_name_ascii }}
                  </div>
                </div>
              </template>
            </SearchableSelect>
          </div>
          <div v-if="!props.isOnline" class="mt-3">
            <label class="block text-sm font-semibold text-gray-700">
              Meeting Point
            </label>
            <AddressComponent
              id="occurrence.occurrence_address"
              class="ring-gray-300 focus:ring-gray-300 rounded"
              template-name="v3"
              :initial-address-info="
                occurrence.occurrence_address
                  ? getInitialAddressInfo
                  : undefined
              "
              @update-address-info="(address) => updateAddressInfo(address)"
            />
          </div>
          <div v-if="showMarketplaceToggle" class="flex flex-row mt-4">
            <BaseToggle
              v-model="occurrence.marketplace"
              label-after="true"
              width="w-10"
            />
            <label class="block text-sm font-semibold text-gray-700 ml-2"
              >Available in Marketplace</label
            >
            <img
              v-if="occurrence.marketplace"
              src="/images/icon-marketplace.svg"
              class="mb-6 ml-2"
            />
          </div>
          <div class="flex flex-row mt-4">
            <BaseToggle
              v-model="occurrence.collect_rsvp"
              label-after="true"
              width="w-10"
            />
            <label class="block text-sm font-semibold text-gray-700 ml-2"
              >Collect RSVPs</label
            >
          </div>
          <div class="flex flex-col mt-3">
            <div class="flex flex-row">
              <BaseToggle v-model="hasMaxCapacity" width="w-10" />
              <label class="block text-sm font-semibold text-gray-700 ml-2"
                >Set Max Capacity</label
              >
            </div>
            <div class="flex flex-row items-center">
              <small
                >Cap the number of RSVPs. Guests won't be able to RSVP after
                capacity is reached.</small
              >
              <input
                v-model="occurrence.max_capacity"
                class="form-input ml-3 max-w-[50px]"
                type="text"
                :disabled="!hasMaxCapacity"
              />
            </div>
          </div>
          <div class="flex flex-col mt-3">
            <div class="flex flex-row">
              <BaseToggle v-model="occurrence.guests_allowed" width="w-10" />
              <label class="block text-sm font-semibold text-gray-700 ml-2"
                >Allow guests to bring additional guests</label
              >
            </div>
            <div v-if="false" class="flex flex-row items-center">
              <small>This number includes the recipient.</small>
              <v-select
                v-model="numOfAllowedGuests"
                :options="[1, 2, 3, 4, 5, 6, 7, 8]"
                class="md:max-w-[300px] w-24 ml-2"
                :disabled="!occurrence.guests_allowed"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><i
                      class="fa fa-caret-down text-gray-500"
                      v-bind="attributes"
                    ></i
                  ></span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="flex flex-row mt-3">
            <BaseToggle v-model="occurrence.attendance_required" width="w-10" />
            <label class="block text-sm font-semibold text-gray-700 ml-2"
              >Attendence Required</label
            >
          </div>
          <div class="flex flex-col mt-3">
            <label class="block text-sm font-semibold text-gray-700">
              Event Leader Name
            </label>
            <div
              class="mb-3 w-full flex flex-row"
              v-for="(profile, index) in occurrence.occurrence_profiles"
              :key="profile.id"
            >
              <ProfileLeaderSelect
                v-model="occurrence.occurrence_profiles[index]"
                @delete-item="deleteProfileLeader(index)"
                :has-role="false"
                :display-label="false"
                :parent-loading="occurrenceProfilesLoading"
              />
            </div>
          </div>
          <div class="mt-1 cursor-pointer text-teal-600" @click="addLeaderName">
            <p>Add another leader</p>
          </div>
          <div class="mt-3">
            <label class="block text-sm font-semibold text-gray-700">
              Price per Student
            </label>
            <div class="flex flex-row items-center mt-3">
              <p class="block text-sm font-semibold text-gray-700">$ (USD)</p>
              <input
                v-model="priceStudent"
                class="form-input ml-3"
                type="number"
                :class="[
                  {
                    'bg-error-100': v$.occurrence.price_student.$errors?.length,
                  },
                ]"
                @blur="v$.occurrence.price_student.$touch"
              />
              <div
                v-if="v$.occurrence.price_student.$errors?.length"
                class="flex text-sm error text-error-900 justify-center items-center pl-2"
              >
                <span
                  v-for="(error, idx) in v$.occurrence.price_student.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <div class="flex align-center justify-center h-10 flex-0">
          <BaseButton outlined class="px-7" @click="closeModal"
            >Cancel</BaseButton
          >
          <BaseButton :disabled="disableSave" @click="saveDraft"
            >Save as Draft</BaseButton
          >
          <BaseButton :disabled="disableSave" @click="publish"
            >Publish Occurrence</BaseButton
          >
        </div>
      </template>
    </Modal>
    <div
      class="flex flex-row items-center text-center text-teal-600"
      :class="`${
        props.enableModal
          ? 'text-gray-600 cursor-pointer'
          : 'text-gray-300 cursor-default'
      }`"
      :aria-disabled="props.enableModal"
      @click="openModal"
    >
      <PlusIcon class="mr-1 w-6 h-6" />
      <small>NEW OCCURRENCE</small>
    </div>
  </div>
  <Spinner
    v-show="isLoading"
    class="m-auto w-full h-full !fixed opacity-75 bg-blue-100 !z-0"
  />
</template>
