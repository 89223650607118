<script setup>
import ActionButtonsCard from "@/components/shared/Card/ActionButtonsCard.vue";
import TitleCard from "@/components/shared/Card/TitleCard.vue";
import { computed, toRefs, unref } from "vue";
import { useStore } from "vuex";
import {
  getFormattedInternshipDuration,
  getIndustries,
  hidePrice,
  getIsPastAppDeadline,
  getPriceInDollars,
  getCareerInterests,
  getInternshipCompensationTag,
} from "../utils";
import {
  getInternshipRedirectUrl
} from "./utils";
import InternshipCardContent from "./InternshipCardContent.vue";
import IndustriesCard from "@/components/shared/Card/IndustriesCard.vue";
import CareerInterestsCard from "@/components/shared/Card/CareerInterestsCard.vue";

defineEmits(["bookmarkClicked", "viewClicked", "applyClicked"]);

const store = useStore();

const billingRules = computed(() => store.state.billingRules);
const homeInstitutionId = computed(
    () => store.state.profileData?.colleges?.[0]?.college_id ?? ""
);

const props = defineProps({
  hit: {
    type: Object,
    default() {
      return {};
    },
  },
  showViewLink: {
    type: Boolean,
    default: true,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  randomTags: {
    type: Boolean,
    default: false,
  },
  showBookmark: {
    type: Boolean,
    default: false,
  },
  showImage: {
    type: Boolean,
    default: false,
  },
  uppercaseName: {
    type: Boolean,
    default: false,
  },
  eventCategory: {
    type: String,
    default: "",
  },
  imageUrl: {
    type: String,
    default: "",
  },
});

const { hit } = toRefs(props);

const session = computed(() => unref(hit)?.session);
const sessionId = computed(() => unref(session)?.id);

const programPage = computed(() => unref(hit)?.program_page);
const programPageId = computed(() => unref(programPage)?.id);

const internship = computed(() => unref(hit)?.internship);

const showPriceComponent = computed(
  () => !hidePrice(billingRules, unref(session))
);

const priceInDollars = computed(() => getPriceInDollars(unref(hit), unref(homeInstitutionId)));
const redirectUrl = computed(() => getInternshipRedirectUrl(unref(hit)));
const isPastAppDeadline = computed(() => getIsPastAppDeadline(unref(hit)));
const title = computed(() =>
  getInternshipCompensationTag(unref(internship), unref(session))
);

const industries = computed(() => getIndustries(unref(internship)));
const careerInterests = computed(() => getCareerInterests(unref(internship)));
</script>

<template>
  <div
    id="internship-card"
    class="program-card-base h-full flex flex-col justify-between"
    @click="$emit('viewClicked', programPageId, sessionId)"
  >
    <div class="flex flex-col">
      <TitleCard
        :title="title"
        :action-id="sessionId"
        :show-bookmark="showBookmark"
        @bookmark-clicked="(id) => $emit('bookmarkClicked', id)"
      />
    </div>
    <div class="px-4 flex flex-col h-full">
      <InternshipCardContent
        v-if="!horizontal || (horizontal && showImage)"
        :internship="internship"
        :session="session"
        :program-page="programPage"
        :horizontal="horizontal"
        :random-tags="randomTags"
        :uppercase-name="uppercaseName"
        :perks="internship?.perks"
        :company-size="internship?.company?.company_size"
        :placement-types="internship?.placement_types"
      />
      <div class="flex flex-col flex-grow justify-end gap-y-11">
        <div id="api-card-description">
          <div id="api-card-description">
            <div
              v-if="priceInDollars && showPriceComponent"
              data-testid="program-price"
              class="font-bold uppercase text-base lg:mt-8 mb-4"
              :class="
                secondaryColor ? `${secondaryColorClass}` : `text-university-secondary`
              "
            >
              Prices starting at {{ priceInDollars }}
            </div>
            <IndustriesCard v-if="industries" :industries="industries" />
            <CareerInterestsCard
              v-if="careerInterests"
              :career-interests="careerInterests"
              :session-id="sessionId"
            />
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row sm:justify-around justify-center items-center self-end w-full gap-4 mb-6"
        >
          <ActionButtonsCard
            :show-view-link="showViewLink"
            :redirect-url="redirectUrl"
            :program="programPage"
            custom-apply-class="program-card-apply-button-base"
            :is-past-app-deadline="isPastAppDeadline"
            :event-category="eventCategory"
            @apply-clicked="(program) => $emit('applyClicked', program)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
