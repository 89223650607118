<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { connectStats } from 'instantsearch.js/es/connectors';
import { HITS_PER_PAGE } from "./constants";

export default {
  mixins: [createWidgetMixin({ connector: connectStats })],
  computed: {
    getLastIndex() {
      let count = 0
      if(this.state) {
        if(this.state?.nbPages <= 1) {
          count = this.state?.nbHits;
        } else if(this.state?.nbPages > 0) {
          count = ((this.state?.page || 0) + 1) * (this.state?.hitsPerPage || HITS_PER_PAGE);
        }
      }
      return count > this?.state?.nbHits ? this.state.nbHits : count;
    },
  },
};
</script>

<script setup>
defineProps({
  containerClass: {
    type: String,
    default: null,
  },
  elementClass: {
    type: String,
    default: null,
  },
  cypressId: {
    type: String,
    default: "pagination-header",
  },
});
</script>
<template>
  <div :data-cy="cypressId + '-container'" :class="containerClass">
    <p
      v-if="state?.nbHits > 0"
      id="test"
      :data-cy="cypressId"
      class="font-montserrat font-normal font-medium text-sm text-indigo-base leading-4.5"
      :class="elementClass"
    >
      Showing {{ `1 - ${ getLastIndex }` }} of {{ state?.nbHits }} results
    </p>
    <p v-else>Showing {{ state?.nbHits }} results</p>
  </div>
</template>
