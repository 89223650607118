<template>
  <div>
    <Modal
      :title="title"
      :open="isOpen"
      :show-logo="false"
      @closeModal="closeModal"
    >
      <template #modal-content>
        <div>
          <div class="flex w-full mt-6 justify-center">
            <img :src="multimedia" alt="Multimedia" class="w-fit m-6 !inline" />
          </div>
          <div class="flex w-full mt-6 justify-center">
            <slot></slot>
          </div>
          <div class="flex mt-2 justify-center">
            <button
              class="py-2 px-6 mr-5 inline-flex items-center rounded-md border border-teal-900 bg-white text-sm font-medium text-teal-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus: focus:ring-offset-2"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Modal from "./Modal.vue";
import ImagePlaceholder from "@images/img-placeholder.png";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  multimedia: {
    type: String,
    default: ImagePlaceholder,
  },
});

const emit = defineEmits(["update:isOpen"]);

// let open = ref(props.isOpen);
let title = "Preview - Lead Multimedia";

const closeModal = () => {
  open.value = false;
  emit("update:isOpen", false);
};
</script>
