<script setup>
import { defineProps, computed, toRefs, ref, watch } from "vue";
import { useVModel } from "@vueuse/core";
import { debounce, uniqBy } from "lodash";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import { useGetCityOptions } from "@/components/program-manager/sessions/composable.js";
import { convertLatLongDDtoDMS } from "@/components/program-manager/sessions/utils.js";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: false,
    default: "",
  },
  city: {
    type: Object,
    required: false,
    default: () => {},
  },
  searchCity: {
    type: String,
    required: false,
    default: "",
  },
  showLatLngField: {
    type: Boolean,
    required: false,
    default: true,
  },
  vuelidateInstance: {
    type: Object,
    required: false,
    default: () => {},
  },
  cityRequired: {
    type: Boolean,
    required: false,
    default: true,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue", "selectedCity"]);
const cityId = useVModel(props, "modelValue", emit);
const {
  city,
  showLatLngField,
  searchCity,
  cityRequired,
  editable,
  vuelidateInstance,
} = toRefs(props);
const cityExtraInformation = ref(city.value ? city.value : {});

const getSavedCity = computed(() => {
  return city.value ? [city.value] : [];
});

watch(searchCity, async () => {
  await fetchSearchableCitiesOptions(searchCity.value);
});

const setCityExtraInformation = (val) => {
  cityExtraInformation.value = val;
  emit("selectedCity", val);
};

const {
  execute: executeFetchCityOptions,
  isLoading: cityLoading,
  state: cityOptions,
  isReady: cityReady,
} = useGetCityOptions(
  {
    immediate: true,
    throwError: true,
  },
  {
    city_ascii: city.value?.city_ascii || "chi",
  }
);

const fetchSearchableCitiesOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    if (loading) loading(true);
    try {
      cityId.value = "";
      cityExtraInformation.value = {};
      await executeFetchCityOptions(0, {
        city_ascii: search,
        cancel: true,
      });
      if (loading) loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        if (loading) loading(false);
      }
    }
  }
}, 500);
</script>

<template>
  <div class="flex flex-col justify-start md:max-w-[300px] mb-4">
    <div class="mt-1">
      <label class="block text-sm font-semibold text-gray-700"
        ><span v-if="cityRequired" class="text-red-100 pr-2 align-sub">*</span
        >City</label
      >
      <SearchableSelect
        v-if="editable"
        v-model="cityId"
        :options="
          uniqBy((cityOptions?.items || []).concat(getSavedCity), 'id') ||
          getSavedCity
        "
        :loading="cityLoading || !cityReady"
        :reduce="(val) => val.id"
        :deselect-from-dropdown="true"
        :close-on-select="true"
        :filterable="false"
        :clear-search-on-blur="() => false"
        :vuelidate-instance="vuelidateInstance"
        placeholder="City"
        label="city_ascii"
        @search="fetchSearchableCitiesOptions"
        @option:selected="(val) => setCityExtraInformation(val)"
      >
        <template #option="{ city_ascii, country, admin_name_ascii }">
          <div class="text-sm">
            <div class="font-semibold">{{ city_ascii }}</div>
            <div>
              {{ country?.name }}
              <span v-if="country?.name && admin_name_ascii">-</span>
              {{ admin_name_ascii }}
            </div>
          </div>
        </template>
      </SearchableSelect>
      <div v-else>
        <div class="mt-3">
          <span>{{ cityExtraInformation?.city_ascii || "" }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-start md:max-w-[300px] mb-4">
    <div class="mt-1">
      <label class="block text-sm font-semibold text-gray-700">Country</label>
      <div class="mt-3">
        <span>{{ cityExtraInformation?.country?.name || "" }}</span>
      </div>
    </div>
  </div>
  <div
    v-if="showLatLngField"
    class="flex flex-col justify-start md:max-w-[300px] mb-4"
  >
    <div class="mt-1">
      <label class="block text-sm font-semibold text-gray-700">Lat/Long</label>
      <div class="mt-3">
        <span>{{ convertLatLongDDtoDMS(cityExtraInformation?.lat_lng) }}</span>
      </div>
    </div>
  </div>
</template>
