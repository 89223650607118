<template>
  <div
    v-if="showCard && uiVersion === 'v2'"
    class="flex items-start py-4 border-b border-gray-200 md:items-center text-gray-650 sm:px-4 sm:py-2 ml-12"
  >
    <div
      v-if="financialAidFormSubmitted"
      class="flex-1 md:gap-x-6 md:grid-cols-16 md:grid md:items-center"
    >
      <div
        class="text-base md:text-sm font-semibold text-gray-600 md:col-span-4 lg:col-span-3"
      >
        Financial Aid (Optional)
      </div>
      <div class="mt-1 text-sm md:col-span-2 md:mt-0 lg:col-span-2"></div>
      <div
        class="flex mt-1 text-sm text-gray-600 md:mt-0"
        :class="
          displayToDo
            ? 'md:col-span-6 lg:col-span-7'
            : 'md:col-span-4 lg:col-span-5'
        "
      >
        <div class="items-center lg:flex">
          <div class="lg:col-span-4">
            <FormComplete />
          </div>
          <OptInToggler
            :toggle-selected="toggleSelected"
            @toggle-value="toggle"
          />
        </div>
      </div>
      <VerifyUpdateButton
        v-if="!hasSubmittedToProfileService"
        :financial-aid-form-completed="financialAidFormSubmitted"
        :toggle-selected="toggleSelected"
        :loading="loading"
        :financial-aid-selected="financialAidSelected"
        :display-to-do="displayToDo"
      />
    </div>
    <div
      v-else
      class="flex-1 md:gap-x-6 md:grid-cols-16 md:grid md:items-center"
    >
      <div
        class="text-base md:text-sm font-semibold text-gray-600 md:col-span-4 lg:col-span-3"
      >
        Financial Aid (Optional)
      </div>
      <div
        v-if="loading"
        class="mt-1 text-sm md:col-span-2 md:mt-0 lg:col-span-2"
      >
        <SkeletonLoader
          :min-width="50"
          class="font-semibold text-gray-650"
          :max-width="60"
        />
      </div>
      <div
        v-else
        class="mt-1 text-sm md:col-span-2 md:mt-0 lg:col-span-2"
      ></div>
      <div
        class="flex mt-1 text-sm text-gray-600 md:mt-0"
        :class="
          displayToDo
            ? 'md:col-span-6 lg:col-span-7'
            : 'md:col-span-4 lg:col-span-5'
        "
      >
        <SkeletonLoader
          v-if="loading"
          :min-width="50"
          class="font-semibold text-gray-650"
          :max-width="60"
        />
        <div
          v-else-if="
            (financialAidSelected && !loading) ||
            hasTurnedOnFinancialAidOnProfileService ||
            hasSubmittedFinancialAidToFormIo
          "
          class="text-gray-600 leading-tight flex space-x-1"
        >
          <p>
            You indicated you would like to use Financial Aid as part of your
            application.
          </p>
          <OptInToggler
            v-if="hasSubmittedToProfileService && !loading"
            :toggle-selected="hasTurnedOnFinancialAidOnProfileService"
            @toggle-value="toggle"
          />
        </div>
        <div v-else class="items-center lg:flex">
          <div class="leading-tight text-gray-600 lg:col-span-4">
            You did not indicate that you want to use Financial Aid. It's ok if
            you've changed your mind! You can opt-in here.
          </div>
          <OptInToggler
            :toggle-selected="
              financialAidSelected || hasTurnedOnFinancialAidOnProfileService
            "
            @toggle-value="toggle"
          />
        </div>
      </div>
      <VerifyUpdateButton
        v-if="
          (!hasSubmittedToProfileService && financialAidSelected) ||
          hasSubmittedFinancialAidToFormIo
        "
        :financial-aid-form-completed="financialAidFormSubmitted"
        :toggle-selected="toggleSelected"
        :loading="loading"
        :financial-aid-selected="financialAidSelected"
        :display-to-do="displayToDo"
      />
    </div>
  </div>
  <div
    v-else-if="uiVersion === 'v3'"
    class="flex flex-col md:flex-row w-full gap-4 mt-4 justify-between"
  >
    <div class="flex flex-row gap-1 flex-none items-center">
      <OptInToggler
        class="mb-0"
        :hide-label="uiVersion === 'v3'"
        :toggle-selected="toggleSelected"
        @toggle-value="toggle"
      />
      <span class="italic font-bold text-sm mt-1.5 md:mt-0">Financial Aid</span>
    </div>

    <p class="italic text-sm break-words normal-case">
      API accepts all forms of financial aid, including scholarships, grants,
      private loans, etc. Let API know if you plan to use financial aid so we
      can push your payment deadline back accordingly.
    </p>
    <SimplePopup
      :open="openFinancialPopUpNotification"
      @close="openFinancialPopUpNotification = false"
    >
      <template #title>Financial Aid Notification</template>
      <template #content>
        Thank you for letting us know you plan to use financial aid! We have
        alerted our financial aid team and they will be in touch via email
        within 5 business days. Please look out for this email. If you are not
        responsive, your final invoice will be due two weeks after your
        confirmation payment is made.
      </template>
    </SimplePopup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SkeletonLoader from "./../helpers/SkeletonLoader";
import OptInToggler from "./OptInToggler.vue";
import FormComplete from "./FormCompletedIcon.vue";
import VerifyUpdateButton from "./VerifyUpdateButton.vue";
import formIoApi from "../../mixins/formIoApi";
import formService from "@/services/form";
import profileService from "@/services/profile.js";
import { getApiClient } from "@/services/API.js";
import SimplePopup from "@/components/shared/SimplePopup.vue";
import { format } from "date-fns";
import { ALLOW_FINANCIAL_AID_FOR } from "@/constants.js";

export default {
  name: "FinancialAidCard",
  components: {
    SkeletonLoader: SkeletonLoader,
    FormComplete,
    OptInToggler,
    VerifyUpdateButton,
    SimplePopup,
  },
  mixins: [formIoApi],
  inject: {
    learningServiceProgramSession: {
      from: "session",
    },
  },
  props: {
    displayToDo: {
      type: Boolean,
      default: false,
    },
    usingFinancialAid: {
      type: Boolean,
      default: false,
    },
    financialAid: {
      type: Object,
      default: false,
    },
  },
  data() {
    return {
      toggleSelected: false,
      loading: false,
      formURL: "programfeespaymentoptions",
      currentProfile: null,
      hasSubmittedToProfileService: false,
      hasTurnedOnFinancialAidOnProfileService: false,
      hasSubmittedFinancialAidToFormIo: false,
      openFinancialPopUpNotification: false,
    };
  },
  computed: {
    ...mapState([
      "formioToken",
      "userData",
      "currentApplicationSubmissions",
      "program",
      "uiVersion",
      "featureFlags",
      "currentUser",
    ]),
    financialAidFormSubmitted() {
      return Object.hasOwn(
        this.currentApplicationSubmissions,
        "financialaidverification"
      );
    },
    submissionId() {
      // Sets submission id for form at build time
      if (!Object.hasOwn(this.currentApplicationSubmissions, this.formURL)) {
        return "";
      }
      const submissions = this.currentApplicationSubmissions[this.formURL];
      return (
        submissions.filter((submission) => !!submission?.data)[0]?._id ?? ""
      );
    },
    financialAidSelected() {
      if (!Object.hasOwn(this.currentApplicationSubmissions, this.formURL)) {
        return false;
      }
      const submissions = this.currentApplicationSubmissions[this.formURL];
      return submissions.some((submission) => !!submission?.data?.financialAid);
    },
    enrollmentApplicationId() {
      return this.$route.params.applicationId;
    },
    showCard() {
      return ALLOW_FINANCIAL_AID_FOR.includes(this.userData.data.student_type);
    },
  },
  watch: {
    usingFinancialAid(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.toggleSelected = newVal;
        if (this.toggleSelected && !this.financialAid) {
          //prevent duplicate email to zendesk if financial aid already active
          this.openZendeskTicket();
        }
        if (this.financialAid) {
          this.toggleFinancialAidOnProfileService(this.toggleSelected);
        } else {
          this.createFinancialAidOnProfileService(this.toggleSelected, true);
          this.hasTurnedOnFinancialAidOnProfileService = true;
        }
        //update the financial aid status of the current application in the loaded profile state

        return this.toggleSelected;
      }
    },
  },
  async created() {
    await this.getProfileInfo();
    this.toggleSelected = this.usingFinancialAid;
    this.hasSubmittedFinancialAidToFormIo = !!this.toggleSelected;
  },
  methods: {
    toggle() {
      this.toggleSelected = !this.toggleSelected;
      this.openFinancialPopUpNotification = this.toggleSelected;
      // Assign submission id based on computed or mixin property
      // So that the form is properly updated
      const submissionId = this.submissionId
        ? this.submissionId
        : this.submission_id
        ? this.submission_id
        : "";

      // This logic continues here for old users
      if (submissionId) {
        this.updateFinancialAid(this.toggleSelected, submissionId);
      } else {
        this.loading = true;
        if (this.uiVersion === "v2") {
          this.createFinancialAid(this.toggleSelected);
          return;
        }

        // Profile service logic
        if (!this.financialAid) {
          this.createFinancialAidOnProfileService(this.toggleSelected);
          this.hasSubmittedToProfileService = true;
        } else {
          // Includes logic to update financial aid
          this.toggleFinancialAidOnProfileService(this.toggleSelected);
          this.hasSubmittedToProfileService = true;
          if (!this.toggleSelected) {
            this.hasTurnedOnFinancialAidOnProfileService = false;
          } else {
            this.hasTurnedOnFinancialAidOnProfileService = true;
            this.openZendeskTicket();
          }
        }
      }
    },
    updateFinancialAid(check, submissionId) {
      const updateInstructions = [
        { op: "replace", path: "/data/financialAid", value: check },
      ];
      formService
        .partiallyUpdateSubmission(
          this.formURL,
          submissionId,
          updateInstructions
        )
        .catch(() => {
          this.toggleSelected = !check;
        });
    },
    createFinancialAid(check) {
      const formioObject = {
        data: {
          application_id: this.enrollmentApplicationId,
          financialAid: check,
        },
      };
      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.loading = false;
          this.submission_id = response;
          this.hasSubmittedFinancialAidToFormIo = true;
        })
        .catch(() => {
          this.toggleSelected = !check;
        });
    },
    toggleFinancialAidOnProfileService(toggle) {
      profileService
        .toggleFinancialAid(this.enrollmentApplicationId, {
          application_id: this.enrollmentApplicationId,
          using_financial_aid: toggle,
          profile_id: this.currentUser?.participantId,
          certified_fafsa_eligible: this.currentlyFinancialAid?.certified_fafsa_eligible ?? false,
          considered_for_scholarships: this.currentlyFinancialAid?.considered_for_scholarships ?? false,
        })
        .then(() => {
          this.loading = false;
          return true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getProfileInfo() {
      if (this.uiVersion === "v2") return;
      profileService
        .getProfile(this.currentUser?.participantId)
        .then(({ data }) => {
          this.currentProfile = data.data;
        });
    },
    async openZendeskTicket() {
      const payload = {
        program_name:
          this.learningServiceProgramSession?.name ?? this.program.program.name,
        student_name: `${this.userData.data.firstname} ${this.userData.data.lastname}`,
        student_email: this.userData.data.email,
      };
      await getApiClient().post("/zendesk-ticket", payload);
    },
    createFinancialAidOnProfileService(toggle, skipEmail = false) {
      return profileService
        .createFinancialAid({
          profile_id: this.currentUser?.participantId,
          application_id: this.enrollmentApplicationId,
          using_financial_aid: toggle,
        })
        .then(async ({ data }) => {
          this.loading = false;
          this.hasSubmittedToProfileService = true;
          if (toggle) {
            this.hasTurnedOnFinancialAidOnProfileService = true;
            this.hasSubmittedToProfileService = true;
            this.toggleSelected = true;
          } else {
            this.hasTurnedOnFinancialAidOnProfileService = false;
          }
          if (!skipEmail) await this.openZendeskTicket();
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    formatDate(date) {
      return format(new Date(date), "MM/dd/yyyy");
    },
  },
};
</script>
