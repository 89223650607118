<template>
  <div>
    <template v-if="loading">
      <spinner>
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications/landing',
              params: { applicationId: applicationId },
            }"
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            class="underline hover:no-underline"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
    </template>
    <div
      v-if="!loading"
      class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8"
    >
      <div class="my-4">
        <router-link
          :to="{
            name: 'applications/landing',
            params: { applicationId: applicationId },
          }"
          class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left w-4 h-4"
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
          <span class="ml-1">Back</span>
        </router-link>
      </div>
      <div
        class="max-w-4xl px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
      >
        <h1 class="mb-2 text-xl text-gray-650 md:text-2xl">
          Financial Aid Verification Form (FAVF)
        </h1>
        <h2 class="uppercase font-bold mt-2">
          Planning to use financial aid to pay api?
        </h2>
        <p class="text-gray-600 md:text-base">
          If you receive federal, state, or institutional funding (financial
          aid), you may be able to use some or all of it to pay for the cost of
          your API Program. This includes but is not limited to scholarships,
          grants, and loans.
        </p>
        <p class="text-gray-600 md:text-base italic mb-2">
          <strong>Note:</strong> API does not award or process financial aid.
        </p>

        <hr />

        <h2 class="uppercase font-bold mt-2">
          What is the purpose of the favf?
        </h2>
        <p class="text-gray-600 md:text-base">
          API requires the FAVF in order to push back the due date for any
          amount of your final payment that will be covered by your finalized
          financial aid.
        </p>
        <h2 class="uppercase font-bold mt-2">
          Why is the completed FAVF required?
        </h2>
        <p class="text-gray-600 md:text-base">
          API can’t push back your payment due date until your completed FAVF is
          sent to us.
        </p>

        <h2 class="uppercase font-bold mt-2">
          How does the favf get completed?
        </h2>
        <p class="text-gray-600 md:text-base">
          Complete this form with the guidance of the home institution financial
          aid advisor to ensure accuracy.
        </p>

        <h2 class="uppercase font-bold mt-2">
          WHO IS RESPONSIBLE FOR ENSURING THAT THE FAVF IS COMPLETED AND SENT TO
          API ON TIME?
        </h2>
        <p class="text-gray-600 md:text-base">
          You, the student, are responsible for ensuring that your completed
          FAVF is sent to API on time.
        </p>

        <h2 class="uppercase font-bold mt-2">
          When is the completed FAVF due to API?
        </h2>
        <p class="text-gray-600 md:text-base">
          The completed FAVF with your finalized* aid details are due to API no
          later than your final payment due date.
        </p>

        <p class="text-sm text-gray-600 md:text-sm italic mt-3">
          *When aid is finalized, it means that the aid has been awarded and you
          have completed your school’s requirements to ensure the release of the
          funding. If your aid won’t be finalized by your final payment due
          date, you should complete your FAVF with an estimate of your financial
          aid.
        </p>

        <hr />

        <h2 class="uppercase font-bold mt-4">
          COMPLETE THESE STEPS IF YOU PLAN TO USE FINANCIAL AID FOR YOUR API
          PAYMENTS:
        </h2>

        <ul class="list-inside list-disc">
          <li>
            Make an appointment to meet with your school’s financial aid
            advisor.
          </li>
          <li>
            Submit the form below by your final payment deadline.
          </li>
          <li>
            Look out for an adjusted payment schedule.
          </li>
          <li>
            Ensure you have submitted payment for any payments that are not
            covered by your financial aid.
          </li>
          <li>
            Notice how your financial aid funds will be disbursed and when. Use
            this information to make arrangements to get payment to API on time.
            Your financial aid funds are due to API within 14 days of your
            disbursement date originally provided on the Financial Aid
            Verification Form.
          </li>
        </ul>

        <section class="mt-4">
          <div id="favf-form">
            <form aria-label="Financial Aid Form">
              <div class="text-gray-800">
                <StudentNameInput
                  ref="StudentNameInput"
                  :name="studentName"
                  :submitted-name="form.studentname"
                  @update:student-name="studentName = $event"
                />

                <AwardsInputs
                  ref="AwardsInputs"
                  :awards="awards"
                  :submitted-data="form"
                  @update:awards="awards = $event"
                />

                <Questionnaire
                  ref="Questionnaire"
                  :questions="questions"
                  :submitted-data="{
                    additionalInformation: form.additionalInformation,
                    completedAllRequirements: form.completedAllRequirements,
                    enrollmentVerification: form.enrollmentVerification,
                    enrollmentVerificationSent: form.enrollmentVerificationSent,
                    financialAidReleasedTo: form.financialAidReleasedTo,
                    requirementsLeft: form.requirementsLeft,
                  }"
                  @update:questionnaire="questions = $event"
                />
                <Signature
                  ref="Signature"
                  :signature="signature"
                  :submitted-data="{
                    acknowledge: form.acknowledgement === 'true',
                    advisorEmail: form.advisorEmail,
                    advisorName: form.advisorName,
                    studentSignatureText: form.studentSignatureText,
                  }"
                  @update:signature="signature = $event"
                />
              </div>
              <ButtonWithSpinner
                ref="financialAidSubmit"
                type="button"
                class="mt-6"
                variant="primary"
                variant-type="block"
                :disabled="submitButtonDisabled"
                @click.prevent="submit()"
              >
                <span
                  >Submit
                  <svg
                    v-if="formSubmitted && !hasErrors"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="inline feather feather-check"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </span>
              </ButtonWithSpinner>
              <div v-if="formSubmitted && !hasErrors" class="text-success-900">
                <p>
                  Submission successful. Please watch your email for any
                  necessary corrections from your Program Coordinator.
                </p>
              </div>
              <p v-if="hasErrors" class="text-error-900">
                Please complete the required fields correctly.
              </p>
              <div v-if="formioErrors" class="text-error-900">
                <p v-for="error in formioErrors" :key="error">
                  {{ error }}
                </p>
              </div>
              <div v-if="missingAppId" class="text-error-900">
                <p>
                  No Application ID, please contact your API program manager to
                  help you with your application.
                </p>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "../helpers/Spinner";
import { mapState } from "vuex";
import formIoApi from "../../mixins/formIoApi";
import formioHelper from "../../mixins/formioHelpers";
import formValidation from "../../mixins/formValidation";
import forms from "../../mixins/forms";
import ButtonWithSpinner from "../forms/SharedComponents/ButtonWithSpinner.vue";
import StudentNameInput from "./StudentNameInput.vue";
import AwardsInputs from "./AwardsInputs.vue";
import Questionnaire from "./Questionnaire.vue";
import Signature from "./Signature.vue";
import { isValid, parse, format } from "date-fns";

export default {
  name: "FinancialAidVerification",
  components: {
    Spinner,
    ButtonWithSpinner,
    StudentNameInput,
    AwardsInputs,
    Questionnaire,
    Signature,
  },
  mixins: [formIoApi, formValidation, forms, formioHelper],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formPath: "financialaidverification",
      sectionsToValidate: [
        "StudentNameInput",
        "AwardsInputs",
        "Questionnaire",
        "Signature",
      ],
      loading: true,
      form: {},
      formSubmitted: false,
      message: "Getting Student application",
      studentName: {},
      awards: {},
      questions: {},
      signature: {},
      submitButtonDisabled: false,
    };
  },
  computed: {
    ...mapState(["formioToken"]),
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          application_id: this.setApplicationId,
          studentname: this.studentName.studentName,
          acknowledgement: this.signature.acknowledge
            ? this.signature.acknowledge.toString()
            : "",
          advisorEmail: this.signature.advisorEmail,
          advisorName: this.signature.advisorName,
          studentSignatureText: this.signature.studentSignature,
          requirementsLeft: this.questions.requirementsLeft,
          enrollmentVerificationSent: this.questions.enrollmentVerificationSent,
          financialAidReleasedTo: this.questions.financialAidReleasedTo,
          completedAllRequirements: this.questions.completedAllRequirements,
          enrollmentVerification: this.questions.enrollmentVerification,
          additionalInformation: this.questions.additionalInfo,
        },
      };

      //add the awards rows
      if (
        Object.keys(this.awards).length !== 0 &&
        this.awards.constructor === Object
      ) {
        body.data.universityFees = this.awards.universityFee;
        body.data.loanFees = this.awards.loanFee;
        body.data.totalFinancialAid = this.awards.totalFee;
        for (let x = 0; x < 5; x++) {
          body.data["awardType" + (x + 1)] = this.awards.awards[x].award;
          body.data["awardAmount" + (x + 1)] = this.awards.awards[x].amount;
          body.data["disbursementDate" + (x + 1)] = this.formatDate(
            this.awards.awards[x].date
          );
        }
      }

      return body;
    },
  },
  watch: {
    createSubmissionDataForFormIo: function () {
      if (this.formSubmitted && !this.hasErrors) {
        this.submitButtonDisabled = false;
      }
    },
  },

  created() {
    //Make sure I user owns the application ID
    if (!this.isApplicationOwner(this.applicationId)) {
      //redirect to my applications
      this.$router.push({
        name: "applications",
      });
    }
    /**
     * Prefetch forms data from database
     */
    this.prefetchFormData();
  },
  methods: {
    formatDate(dateValue) {
      if (isValid(parse(dateValue, "yyyy-MM-ddd", new Date()))) {
        return format(parse(dateValue, "yyyy-MM-dd", new Date()), "MM/dd/yyyy");
      } else {
        return "";
      }
    },
    prefetchFormData() {
      /**
       * If the rules allow us to show the Health&Wellness form
       * lets check if any data exist on the database to pre fill the form
       */
      this.formioSubmissionExists(this.formPath, this.applicationId).then(
        (formSubmissionId) => {
          if (formSubmissionId) {
            this.getFormioSubmission(this.formPath, formSubmissionId).then(
              (response) => {
                this.form = response;
                this.loading = false;
              }
            );
          } else {
            this.loading = false;
          }
        }
      );
    },
    async submit() {
      this.submitted = false;
      //calling the startLoading method of the button, which enables the button to loading state.
      this.$refs.financialAidSubmit.startLoading();
      try {
        await this.checkForApplicationId();
        await this.validate();
        await this.formioValidateSubmission(
          this.formPath,
          this.createSubmissionDataForFormIo
        );
        if (!this.hasFormIoValidationErrors) {
          if (this.submission_id) {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo,
              "put",
              this.submission_id
            ).then((response) => {
              if (response) {
                this.formSubmitted = true;
                this.submitButtonDisabled = true;
              }
              this.$refs.financialAidSubmit.stopLoading();
            });
          } else {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                this.submission_id = response;
                this.formSubmitted = true;
                this.submitButtonDisabled = true;
              }
              this.$refs.financialAidSubmit.stopLoading();
            });
          }
          this.$refs.financialAidSubmit.stopLoading();
        } else {
          this.$refs.financialAidSubmit.stopLoading();
        }
      } catch {
        this.$refs.financialAidSubmit.stopLoading();
      }
    },
  },
};
</script>
