export const convertBedsFilterCriteria = ({
  text = undefined,
  extra = {
    allowed_program_types: undefined,
    cities: undefined,
    types: undefined,
    tiers: undefined,
    arrival_date: undefined,
    departure_date: undefined,
    room_types: undefined,
  },
}) => ({
  status: "Active",
  q: text?.length >= 3 ? text : undefined,
  allowed_program_types: extra?.allowed_program_types?.map((val) => val?.label),
  city_ids: extra?.cities?.map((val) => val?.id),
  housing_types: extra?.types?.map((val) => val?.type),
  housing_tiers: extra?.tiers?.map((val) => val?.id),
  booking_start_date: extra?.arrival_date,
  booking_end_date: extra?.departure_date,
  room_types: extra?.room_types?.map((val) => val?.label),
});
