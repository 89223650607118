<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M480-371.539q13.923 0 23.115-9.192 9.192-9.193 9.192-23.115 0-13.923-9.192-23.115-9.192-9.193-23.115-9.193-13.923 0-23.115 9.193-9.192 9.192-9.192 23.115 0 13.922 9.192 23.115 9.192 9.192 23.115 9.192Zm-29.999-139.23h59.998v-241.538h-59.998v241.538Zm-350 392.305v-669.227q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v455.382q0 30.308-21 51.308t-51.308 21H241.539L100.001-118.464Zm116-201.536h571.69q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-455.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H172.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v523.076L216.001-320ZM160-320v-480V-320Z"
    />
  </svg>
</template>
