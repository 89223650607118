import { uuid } from "vue-uuid";
import { readCookie } from "@/mixins/helpers";

export default {
  computed: {
    albumNameKey() {
      const album =
        {
          production: "",
          local: "staging",
        }[process.env.MIX_APP_ENV] ?? process.env.MIX_APP_ENV;
      return album === "" ? "" : encodeURIComponent(album) + "/";
    },
  },
  methods: {
    async s3Upload(file, newName) {
      const fileKey = this.albumNameKey + newName;

      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileKey", fileKey);

      return fetch("/api/s3-upload", {
        method: "POST",
        headers: {
          "X-XSRF-Token": readCookie("XSRF-TOKEN"),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((s3) => {
          if (s3.result.Location) {
            return {
              Location: s3.result.Location,
              Bucket: s3.result.Bucket,
              key: fileKey,
            };
          } else {
            throw new Error("Upload connection failed");
          }
        });
    },
    createUUIDName(name) {
      const lastIndex = name.lastIndexOf(".");
      const fileExtension = name.substring(lastIndex);

      return uuid.v4() + fileExtension;
    },
    fileName(name) {
      let nameParts = name.split(".");
      const extension = nameParts.pop();

      const maxFileNameLength = 79 - extension.length;
      const fileName = nameParts.join(".").substring(0, maxFileNameLength);

      return [fileName, extension].join(".");
    },
    createFileName(originalFile, formName, firstName = null, lastName = null) {
      // Desired format: Last Name_First Name_Form Name_UniqueString
      this.validateFormName(formName);

      const fileExtension = this.extractFileExtension(originalFile);
      const uniqueString = this.generateUniqueString();

      const nameComponents = [
        this.sanitizeNameComponent(lastName),
        this.sanitizeNameComponent(firstName),
        this.sanitizeNameComponent(formName),
      ];

      // Filter out null or empty components before joining
      const filteredNameComponents = nameComponents.filter(
        (component) => component !== null && component !== ""
      );

      const fileName = filteredNameComponents.join("_");

      const fileNameWithRandomString = this.limitFileNameLength(
        `${fileName}_${uniqueString}`
      );
      const finalFileName = `${fileNameWithRandomString}${fileExtension}`;

      return finalFileName;
    },

    validateFormName(formName) {
      if (!formName) {
        throw new Error("Form Name is required.");
      }
    },

    extractFileExtension(originalFile) {
      const lastIndex = originalFile.lastIndexOf(".");
      return originalFile.substring(lastIndex);
    },

    generateUniqueString() {
      return uuid.v4().slice(0, 5);
    },

    sanitizeNameComponent(string) {
      if (!string) return "";
      return string.replace(/[\s-]/g, "_");
    },

    limitFileNameLength(fileName) {
      return fileName.length > 80 ? fileName.slice(0, 80) : fileName;
    },
  },
};
