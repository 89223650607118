<script setup>
import { defineProps, defineEmits, useAttrs, computed } from "vue";
import { intersectionBy, uniqBy } from "lodash";
import { createPopper } from "@popperjs/core";
import VSelect from "vue-select";
import Magnifier from "@/components/shared/icons/Magnifier";
import CloseIcon from "@/components/shared/icons/CloseIcon";
import ErrorMessage from "@/components/shared/ErrorMessage.vue";

// Emits
const emit = defineEmits(["update:modelValue"]);

// Props
const props = defineProps({
  id: {
    type: String,
    default: "id",
  },
  label: {
    type: String,
    default: "title",
  },
  options: {
    type: Array,
  },
  fieldLabel: {
    type: String,
  },
  fieldLabelClasses: {
    type: String,
    default: "common-label-text",
  },
  placement: {
    required: false,
    type: String,
    default: "top",
  },
  errorClasses: {
    type: String,
  },
  vuelidateInstance: {},
});

const {
  id,
  label,
  fieldLabel,
  fieldLabelClasses,
  placement,
  errorClasses,
  vuelidateInstance,
} = props;

const attrs = useAttrs();

const options = computed(() =>
  props.options.map((item) => {
    item.checked = false;
    return item;
  })
);

const getModelValue = computed(() => attrs.modelValue || attrs["model-value"]);

// Methods
const getId = (item) => (item ? item[id] || undefined : undefined);

const getLabel = (item) => item[label] || "";

const setSelectedItems = (item) => {
  const selectedOptions = options.value.filter((option) => option.checked);
  emit(
    "update:modelValue",
    uniqBy(
      intersectionBy(getModelValue.value, selectedOptions, id).concat(
        item.checked ? item : []
      ),
      id
    )
  );
};

const checkOptions = (itemId, checked) => {
  for (const option of options.value) {
    if (getId(option) === itemId) {
      option.checked = checked;
      break;
    }
  }
};

const reduceItem = (item) => {
  if (getModelValue.value) {
    for (const selected of getModelValue.value) {
      if (getId(selected) == getId(item)) {
        item.checked = true;
        break;
      }
    }
  }
  return item[id];
};

const onSearch = (internal_options, value) => {
  if (!value) {
    return options;
  }
  const lookup = value.toLocaleLowerCase();
  const filtered = options.value.filter(
    (option) =>
      option.category || option[label].toLocaleLowerCase().includes(lookup)
  );
  return filtered;
};

const onKeyDown = (map, vm) => ({
  ...map,
  8: (e) => {
    // block backspace
    if (vm.search.length <= 0) e.preventDefault();
  },
});

const onMainItemSelect = (items) => {
  for (const item of items) {
    if (item?.category) continue;
    checkOptions(getId(item), true);
    setSelectedItems(item);
  }
};

const onItemSelect = (item) => {
  if (item?.category) return;
  checkOptions(getId(item), !item.checked);
  setSelectedItems(item);
};

const onItemUnSelect = (item) => {
  const itemId = getId(item);
  item = options.value.find((option) => getId(option) == itemId);
  checkOptions(itemId, false);
  setSelectedItems(item);
};

const withPopper = (dropdownList, component, { width }) => {
  /**
   * We need to explicitly define the dropdown width since
   * it is usually inherited from the parent with CSS.
   */
  dropdownList.style.width = width;
  dropdownList.className += " tag-input-component-style";

  /**
   * Here we position the dropdownList relative to the $refs.toggle Element.
   *
   * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
   * the dropdownList overlap by 1 pixel.
   *
   * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
   * wrapper so that we can set some styles for when the dropdown is placed
   * above.
   */
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  /**
   * To prevent memory leaks Popper needs to be destroyed.
   * If you return function, it will be called just before dropdown is removed from DOM.
   */
  return () => popper.destroy();
};
</script>

<template>
  <div>
    <slot name="fieldLabel">
      <label
        v-show="fieldLabel"
        :name="fieldLabel"
        :class="fieldLabelClasses"
        >{{ fieldLabel }}</label
      >
    </slot>
    <VSelect
      v-bind="$attrs"
      class="md:w-full tag-input-component-style"
      multiple
      :options="options"
      :reduce="(item) => reduceItem(item)"
      :label="label"
      :clear-search-on-blur="
        ({ clearSearchOnSelect, multiple }) => multiple ?? false
      "
      :selectable="(option) => !option?.category"
      :map-keydown="onKeyDown"
      :components="{ OpenIndicator: Magnifier }"
      :filter="onSearch"
      append-to-body
      :calculate-position="withPopper"
      @option:selected="onMainItemSelect"
    >
      <template #option="option">
        <div v-if="option.category" @click.stop.prevent @keypress.enter.stop>
          <div class="tag-input-component-style-li-child my-2">
            <span class="text-sm text-gray-600 font-semibold uppercase">{{
              getLabel(option)
            }}</span>
          </div>
        </div>
        <div
          v-else
          class="tag-input-component-style-li-child-selectable px-0.5 py-0.5"
          @click.stop.prevent="onItemSelect(option)"
        >
          <div
            class="tag-input-component-style-li-child my-2 flex flex-1 items-center space-x-2"
          >
            <div class="flex items-center">
              <input
                type="checkbox"
                :checked="option.checked"
                :value="option.name"
                class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                @click.stop
                @change.stop.prevent="onItemSelect(option)"
              />
            </div>
            <label class="text-sm">
              {{ getLabel(option) }}
            </label>
          </div>
        </div>
      </template>

      <template #selected-option-container="{ option }">
        <span
          class="inline-flex items-center rounded-full bg-blue-300 mb-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium text-university-primary"
        >
          {{ getLabel(option) }}
          <div
            class="ml-2 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full hover:text-blue-500 focus:bg-indigo-500 focus:text-white focus:outline-none cursor-pointer"
            @keypress.enter.prevent="onItemUnSelect(option)"
            @mousedown.stop.prevent="onItemUnSelect(option)"
          >
            <span class="sr-only">x</span>
            <CloseIcon />
          </div>
        </span>
      </template>
    </VSelect>
    <ErrorMessage
      :error-classes="errorClasses"
      :vuelidate-instance="vuelidateInstance"
    />
  </div>
</template>

<style scoped>
.tag-input-component-style :deep(.vs__actions) {
  padding: 0;
  cursor: pointer;
}
.tag-input-component-style :deep(.vs__search) {
  font-size: 0.875rem;
}
.tag-input-component-style :deep(.vs__open-indicator) {
  transform: none;
}
.tag-input-component-style :deep(.vs__dropdown-toggle),
:deep(.vs__selected-options) {
  overflow: visible;
  max-height: inherit;
}
</style>

<style>
ul.tag-input-component-style > .vs__dropdown-option--highlight {
  background: #edf9f8 !important;
  color: black;
  cursor: auto;
}
ul.tag-input-component-style > .vs__dropdown-option {
  padding: 0;
}
div.tag-input-component-style-li-child {
  padding: var(--vs-dropdown-option-padding);
}
div.tag-input-component-style-li-child > label {
  cursor: pointer;
}
div.tag-input-component-style-li-child > div > input[type="checkbox"] {
  cursor: pointer;
}
.vs__dropdown-option--selected
  div.tag-input-component-style-li-child-selectable:hover {
  background-color: #c8d0ec !important;
}
div.tag-input-component-style-li-child-selectable:hover {
  background-color: #edf9f8 !important;
  cursor: pointer;
  color: black;
}
</style>
