<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-6 pb-6">
    <div class="flex flex-row md:col-span-2 justify-between">
      <p class="text-lg font-bold">Request {{ roommateIndex + 1 }}</p>
      <button
        id="removeRoommateButton"
        aria-label="Remove Roommate"
        :disabled="props.disabled"
        :class="{ 'opacity-33 cursor-not-allowed': props.disabled }"
        @click="handleRemoveRoommate"
      >
        <svg
          width="28"
          height="32"
          viewBox="0 0 28 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group">
            <path
              id="Vector"
              d="M1.99231 7.48561H3.73605C3.73605 7.48936 3.73355 7.49436 3.7323 7.49811C3.7298 7.5931 3.7198 20.4655 3.7198 20.5906C3.7198 21.1131 3.71355 21.6256 3.7073 22.1256C3.66855 25.3858 3.6348 28.2005 5.31981 29.9054C6.26855 30.8667 7.62855 31.3342 9.47597 31.3342H18.4961C20.3474 31.3342 21.7112 30.8667 22.6635 29.9029C24.3597 28.1891 24.3285 25.3528 24.2922 22.0693C24.2872 21.5881 24.2822 21.0943 24.2822 20.5931C24.2822 20.4655 24.2722 7.59561 24.2697 7.49193C24.2697 7.48943 24.2685 7.48818 24.2685 7.48568H26.0085C26.561 7.48568 27.0085 7.03819 27.0085 6.48568C27.0085 5.93317 26.561 5.48568 26.0085 5.48568H19.731L19.7298 4.55819C19.7298 2.4132 17.9748 0.666992 15.8184 0.666992H12.181C10.0247 0.666992 8.26963 2.41198 8.26963 4.55819V5.48568H1.99219C1.43968 5.48568 0.992188 5.93317 0.992188 6.48568C0.992188 7.03694 1.44104 7.48561 1.99231 7.48561ZM22.2822 20.5918C22.2822 21.1006 22.2872 21.6018 22.2935 22.0906C22.3235 24.9269 22.351 27.3757 21.2422 28.4957C20.6835 29.0595 19.786 29.3345 18.496 29.3345H9.47581C8.18957 29.3345 7.29581 29.062 6.74205 28.502C5.6458 27.3919 5.67456 24.9631 5.70829 22.1506C5.71454 21.6419 5.72079 21.1219 5.72079 20.5918C5.72079 20.5106 5.73079 7.83825 5.73329 7.53681C5.73329 7.51806 5.72329 7.50306 5.72329 7.48556H22.2795C22.2782 7.50431 22.2695 7.51931 22.2695 7.53805C22.272 7.81305 22.2822 20.5118 22.2822 20.5918ZM10.2697 4.55825C10.2697 3.51575 11.1272 2.66698 12.181 2.66698H15.8185C16.8722 2.66698 17.7297 3.51572 17.7297 4.55825V5.48573H10.2699L10.2697 4.55825Z"
              :fill="[secondaryColor ? `${secondaryColor}` : '#007F80']"
            />
            <path
              id="Vector_2"
              d="M14 10.7295C13.4475 10.7295 13 11.177 13 11.7295V25.5807C13 26.1332 13.4475 26.5807 14 26.5807C14.5525 26.5807 15 26.1332 15 25.5807V11.7295C15 11.1782 14.5525 10.7295 14 10.7295Z"
              :fill="[secondaryColor ? `${secondaryColor}` : '#007F80']"
            />
            <path
              id="Vector_3"
              d="M9.63281 10.7295C9.0803 10.7295 8.63281 11.177 8.63281 11.7295V25.5807C8.63281 26.1332 9.0803 26.5807 9.63281 26.5807C10.1853 26.5807 10.6328 26.1332 10.6328 25.5807V11.7295C10.6328 11.1782 10.1853 10.7295 9.63281 10.7295Z"
              :fill="[secondaryColor ? `${secondaryColor}` : '#007F80']"
            />
            <path
              id="Vector_4"
              d="M18.3672 10.7295C17.8147 10.7295 17.3672 11.177 17.3672 11.7295V25.5807C17.3672 26.1332 17.8147 26.5807 18.3672 26.5807C18.9197 26.5807 19.3672 26.1332 19.3672 25.5807V11.7295C19.3672 11.1782 18.9185 10.7295 18.3672 10.7295Z"
              :fill="[secondaryColor ? `${secondaryColor}` : '#007F80']"
            />
          </g>
        </svg>
      </button>
    </div>

    <TextField
      :id="'roommateFirstName' + roommateIndex"
      v-model.trim="form.firstName"
      label="First Name"
      placeholder="First Name"
      :vuelidate-field="v$.firstName"
      :show-errors="true"
      :disabled="props.disabled"
    />

    <TextField
      :id="'roommateLastName' + roommateIndex"
      v-model.trim="form.lastName"
      label="Last Name"
      placeholder="Last Name"
      :vuelidate-field="v$.lastName"
      :show-errors="true"
      :disabled="props.disabled"
    />

    <TextField
      :id="'roommateEmail' + roommateIndex"
      v-lowercase
      v-model.trim="form.email"
      label="Email"
      placeholder="Email"
      :vuelidate-field="v$.email"
      :show-errors="true"
      :disabled="props.disabled"
    />

    <div>
      <label
        :for="'roommateHomeUniversity' + roommateIndex"
        class="block text-sm font-medium text-indigo-base"
      >
        <span class="font-medium flex justify-between mb-1">
          Home University
        </span>
        <SkeletonLoader v-if="universities.loading" rounded />
        <VSelect
          v-else
          :id="'roommateHomeUniversity' + roommateIndex"
          v-model="v$.homeUniversity.$model"
          :value="v$.homeUniversity.$model"
          :options="universities.list"
          :data-cy="'roommateHomeUniversity' + roommateIndex + 'Select'"
          :get-option-label="(option) => option.name"
          class="custom-select"
          placeholder="Home University"
          :class="{
            'custom-select-error': v$.homeUniversity.$error,
          }"
          :disabled="props.disabled"
          @search="search"
          @search:blur="v$.homeUniversity.$touch"
        />
      </label>
      <p
        v-for="error of v$.homeUniversity.$errors"
        :key="error.$uid"
        class="error text-error-900 text-sm"
      >
        {{ error.$message }}
      </p>
    </div>
    <div class="md:col-span-2">
      <PhoneField
        :id="'phone' + roommateIndex"
        :ref="'phone' + roommateIndex"
        v-model.trim="phoneInternationalFormat"
        label="Phone Number"
        :vuelidate-field="v$.phone"
        :disabled="props.disabled"
        @update-phone="updatePhone"
      >
      </PhoneField>
    </div>
  </div>
</template>

<script setup>
import TextField from "@/components/forms/SharedComponents/DarkBlueInputs/TextField.vue";
import SkeletonLoader from "@/components/shared/loaders/BaseSkeletonLoader.vue";
import useVuelidate from "@vuelidate/core";
import { email, helpers, requiredIf } from "@vuelidate/validators";
import { defineEmits, onMounted, reactive, watch, ref } from "vue";
import PhoneField from "@/components/forms/SharedComponents/DarkBlueInputs/PhoneField.vue";

const props = defineProps({
  roommateIndex: {
    type: Number,
    default: 0,
  },
  roommateRequest: {
    type: String,
    default: "",
  },
  roommate: {
    type: Object,
    default: () => {},
  },
  universities: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["update-roommate", "search", "remove-roommate"]);

const requiredCopy = "This field is required.";
const requiredIfYes = requiredIf(() => props.roommateRequest === "Yes");
const requiredHomeUniversity = requiredIf(() => false);
const phoneValidationRule = () => requiredIfYes && form.isValidPhoneNumber;
const phoneInternationalFormat = ref("");

const handleRemoveRoommate = () => {
  emits("remove-roommate", props.roommateIndex);
};

const updatePhone = (data) => {
  form.isValidPhoneNumber = data.isValid;
  form.phone = data?.nationalNumber ?? "";
  form.phoneCode = data?.countryCallingCode ?? "";
  phoneInternationalFormat.value = data?.e164 ?? "";
};

const form = reactive({
  firstName: null,
  lastName: null,
  email: null,
  homeUniversity: null,
  phone: "",
  phoneCode: "",
  isValidPhoneNumber: false,
});
const rules = {
  firstName: {
    required: helpers.withMessage(requiredCopy, requiredIfYes),
  },
  lastName: {
    required: helpers.withMessage(requiredCopy, requiredIfYes),
  },
  email: {
    required: helpers.withMessage(requiredCopy, requiredIfYes),
    email,
  },
  phone: {
    required: helpers.withMessage(
      "Must be a valid phone number.",
      phoneValidationRule
    ),
  },
  phoneCode: {
    required: helpers.withMessage(requiredCopy, requiredIfYes),
  },
  homeUniversity: {
    required: helpers.withMessage(requiredCopy, requiredHomeUniversity),
  },
};
const v$ = useVuelidate(rules, form);

const loadPrevious = () => {
  if (props.roommate.firstName) {
    form.firstName = props.roommate.firstName;
    form.lastName = props.roommate.lastName;
    form.email = props.roommate.email;
    form.homeUniversity = props.roommate.homeUniversity;
    form.phone = props.roommate.phone;
    form.phoneCode = props.roommate.phoneCode;
    phoneInternationalFormat.value = props.roommate?.phoneCode
      ? `+${props.roommate.phoneCode}${props.roommate.phone}`
      : "";
  }
};

const search = (search, loading) => {
  emits("search", { search, loading });
};

watch(
  () => form,
  () => {
    emits("update-roommate", form);
  },
  {
    deep: true,
  }
);

watch(
  () => props.roommate,
  (newRoommate) => {
    if (newRoommate) {
      form.firstName = newRoommate.firstName;
      form.lastName = newRoommate.lastName;
      form.email = newRoommate.email;
      form.homeUniversity = newRoommate.homeUniversity;
      form.phone = newRoommate.phone;
      form.phoneCode = newRoommate.phoneCode;
    }
  },
  { deep: true }
);

onMounted(() => {
  loadPrevious();
});
</script>
<style>
.custom-phone .m-input-wrapper,
.custom-phone .maz-border-info,
.m-select-input .m-phone-number-input__select .m-input-input,
.maz-rounded {
  @apply rounded-none outline-none p-[1px] w-full border-0 ring-1 ring-inset focus:ring-2 focus:ring-inset ring-indigo-base focus:ring-indigo-base;
}

.custom-phone .maz-rounded-lg {
  @apply rounded-none;
}
</style>
