<template>
  <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="7brk6x64wa">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.499740 0 0 0 0 0.501982 0 0 0 1.000000 0"/>
      </filter>
    </defs>
    <g transform="translate(-469 -8)" filter="url(#7brk6x64wa)" fill="none" fill-rule="evenodd">
      <g fill="#414456">
        <path d="M474.267 16.954a2.69 2.69 0 0 0-1.814-1.51V8.658a.658.658 0 1 0-1.317 0v6.782c-.157.04-.311.093-.46.157a2.712 2.712 0 0 0 .46 5.103v2.443a.659.659 0 0 0 1.317 0v-2.44a2.691 2.691 0 0 0 1.884-1.671 2.691 2.691 0 0 0-.07-2.078zm-1.162 1.613v.001a1.4 1.4 0 1 1-.036-1.07c.157.336.172.722.042 1.07l-.006-.001zM486.449 16.954a2.69 2.69 0 0 0-1.815-1.51V8.658a.658.658 0 1 0-1.317 0v6.782c-.157.04-.311.093-.46.157a2.712 2.712 0 0 0 .46 5.103v2.443a.659.659 0 0 0 1.317 0v-2.44a2.691 2.691 0 0 0 1.884-1.671 2.691 2.691 0 0 0-.07-2.078zm-1.163 1.613v.001a1.4 1.4 0 1 1-2.62-.987 1.4 1.4 0 0 1 2.62.987v-.001zM478.843 11.292a3.503 3.503 0 0 0-.3-.092V8.658a.658.658 0 1 0-1.317 0v2.552a2.72 2.72 0 0 0-1.813 3.752 2.695 2.695 0 0 0 1.813 1.515v6.667a.659.659 0 0 0 1.317 0v-6.67a2.716 2.716 0 0 0 2.072-2.724 2.712 2.712 0 0 0-1.772-2.458zm.363 3.045a1.398 1.398 0 1 1-2.485-1.252 1.398 1.398 0 0 1 1.668-.552c.345.133.621.398.77.734.159.336.175.722.047 1.07z"/>
      </g>
    </g>
  </svg>
</template>
