<template>
  <div id="pagination" class="flex align-center justify-center">
    <ul class="flex">
      <li
        :class="[
          calculateClasses,
          page === 1 ? 'text-black text-opacity-50' : `${secondaryColorClass}`,
        ]"
      >
        <button
          :disabled="page === 1"
          aria-label="Go to first page"
          class="font-medium"
          @click="firstPage"
        >
          First
        </button>
      </li>
      <li
        :class="[
          'font-medium',
          calculateClasses,
          page === 1 ? 'text-black' : `${secondaryColorClass}`,
        ]"
      >
        <button
          :disabled="page === 1"
          aria-label="Go to previous page"
          @click="previousPage"
        >
          <ArrowLeft custom-class="h-4 w-4" />
        </button>
      </li>
      <li
        v-for="item in pagesArray"
        :key="item.id"
        :class="[
          item.name === page ? `${calculateColor}` : `${secondaryColorClass}`,
          calculateClasses,
        ]"
      >
        <button class="font-medium" @click="item.clickable && goTo(item)">
          {{ item.name }}
        </button>
      </li>
      <li
        :class="[
          page === pageCount ? 'text-black' : `${secondaryColorClass}`,
          calculateClasses,
        ]"
      >
        <button
          class="font-medium"
          :disabled="page === pageCount"
          aria-label="Go to next page"
          @click="nextPage"
        >
          <ArrowRight custom-class="h-4 w-4" />
        </button>
      </li>
      <li
        :class="[
          page === pageCount
            ? 'text-black text-opacity-50'
            : `${secondaryColorClass}`,
          calculateClasses,
        ]"
      >
        <button
          class="font-medium"
          :disabled="page === pageCount"
          aria-label="Go to last page"
          @click="lastPage"
        >
          Last
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import ArrowLeft from "@/components/shared/icons/ArrowLeft.vue";

const { pageCount, page } = defineProps({
  pageCount: {
    type: Number,
    default: () => 0,
  },
  page: {
    type: Number,
    default: () => 0,
  },
});

const emit = defineEmits(["onPageChange"]);

const calculateClasses = computed(() => {
  return "flex justify-center items-center w-8 h-8 font-montserrat text-sm leading-5 non-italic";
});
const calculateColor = computed(() => {
  return "text-black";
});
const pagesArray = computed(() => {
  const firstIndex = 1;
  const pages = [];
  if (pageCount === 1) {
    pages.push({ id: 1, name: firstIndex, clickable: false });
  } else if (pageCount <= 7) {
    for (let pageIndex = 1; pageIndex <= pageCount; pageIndex++) {
      pages.push({ id: pageIndex, name: pageIndex, clickable: true });
    }
    return pages;
  } else if (page <= 3) {
    pages.push(
      { id: 1, name: firstIndex, clickable: true },
      { id: 2, name: firstIndex + 1, clickable: true },
      { id: 3, name: firstIndex + 2, clickable: true },
      { id: 4, name: "...", clickable: false },
      { id: 5, name: pageCount - 2, clickable: true },
      { id: 6, name: pageCount - 1, clickable: true },
      { id: 7, name: pageCount, clickable: true }
    );
  } else if (page >= pageCount - 2) {
    pages.push(
      { id: 1, name: firstIndex, clickable: true },
      { id: 2, name: firstIndex + 1, clickable: true },
      { id: 3, name: firstIndex + 2, clickable: true },
      { id: 4, name: "...", clickable: false },
      { id: 5, name: pageCount - 2, clickable: true },
      { id: 6, name: pageCount - 1, clickable: true },
      { id: 7, name: pageCount, clickable: true }
    );
  } else {
    pages.push(
      { id: 1, name: firstIndex, clickable: true },
      { id: 2, name: "...", clickable: false },
      { id: 3, name: page - 1, clickable: true },
      { id: 4, name: page, clickable: true },
      { id: 5, name: page + 1, clickable: true },
      { id: 6, name: "...", clickable: false },
      { id: 7, name: pageCount, clickable: true }
    );
  }
  return pages;
});

const previousPage = () => {
  emit("onPageChange", page - 1);
};
const nextPage = () => {
  emit("onPageChange", page + 1);
};
const firstPage = () => {
  emit("onPageChange", 1);
};
const lastPage = () => {
  emit("onPageChange", pageCount);
};
const goTo = (item) => {
  emit("onPageChange", item.name);
};
</script>
<style></style>
