<template>
  <div>
    <label class="block text-sm">
      <span class="font-semibold text-gray-600 flex justify-between mb-2">
        <span>{{ labelName }}</span>
      </span>
    </label>
    <div class="grid grid-cols-3 gap-x-4">
      <label :for="inputPrefix + 'Month'" class="block text-sm">
        <select
          :id="inputPrefix + 'Month'"
          v-model="month"
          :class="{ 'bg-error-100': hasErrors }"
          class="form-select block w-full text-sm min-h-10"
          @change="dateChange()"
        >
          <option value="" selected disabled />
          <option
            v-for="(monthOption, index) in MONTHS"
            :key="monthOption + '-' + index"
            :value="index"
          >
            {{ monthOption }}
          </option>
        </select>
        <span class="text-gray-600 flex justify-between mt-1">
          <span>Month</span>
        </span>
      </label>
      <label :for="inputPrefix + 'Day'" class="block text-sm">
        <input
          :id="inputPrefix + 'Day'"
          v-model="day"
          type="number"
          maxlength="2"
          min="1"
          :max="maxDay"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': hasErrors }"
          @change="dateChange()"
        />
        <span class="text-gray-600 flex justify-between mt-1">
          <span>Day</span>
        </span>
      </label>
      <label :for="inputPrefix + 'Year'" class="block text-sm">
        <input
          :id="inputPrefix + 'Year'"
          v-model="year"
          type="number"
          minlength="4"
          :min="minYear"
          :max="maxYear"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': hasErrors }"
          @change="dateChange()"
        />
        <span class="text-gray-600 flex justify-between mt-1">
          <span>Year</span>
        </span>
      </label>
      <div
        v-for="(error, index) in customErrors"
        :key="index"
        class="error text-error-900 col-span-3 text-sm"
      >
        {{ error }}
      </div>
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="error text-error-900 col-span-3 text-sm"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
import { MONTHS } from "../../../constants";
import { eventBus } from "../../../app";

export default {
  name: "Datepicker",
  props: {
    labelName: {
      type: String,
      default: "Date",
    },
    required: {
      type: Boolean,
      default: false,
    },
    minYear: {
      type: Number,
      default: 1900,
    },
    maxYear: {
      type: Number,
      default: 2049,
    },
    date: {
      type: String,
      default: "",
    },
    customErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    inputPrefix: {
      type: String,
      default: "date",
    },
  },
  emits: ["updateDate"],
  data() {
    return {
      day: undefined,
      month: "",
      year: undefined,
      errors: [],
    };
  },
  computed: {
    maxDay() {
      const thirtyDayMonths = [3, 5, 8, 10];
      if (this.month === 1 && this.leapYear) return 29;
      if (this.month === 1 && !this.leapYear) return 28;
      if (thirtyDayMonths.includes(this.month)) return 30;
      return 31;
    },
    leapYear() {
      let year = this.year ? this.year : new Date().getFullYear();
      return (year & 3) === 0 && (year % 25 !== 0 || (year & 15) === 0);
    },
    hasErrors() {
      return this.errors.length || this.customErrors.length;
    },
  },
  watch: {
    date: {
      handler: function (newValue) {
        this.setDate(newValue);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.MONTHS = MONTHS;
    eventBus.$on("dateChange", () => {
      this.dateChange();
    });
  },
  methods: {
    dateChange() {
      this.errors = [];
      this.day = this.day > this.maxDay ? this.maxDay : this.day;
      //Validate date
      this.dateValidations();
      this.createDate();
    },
    dateValidations() {
      if (this.required || this.year || this.month !== "" || this.day) {
        if (!this.year || this.month === "" || !this.day)
          this.errors.push("Field is required");
        if (this.year) {
          if (this.year > this.maxYear) {
            this.errors.push(
              "Field should not contain year more than " + this.maxYear
            );
          }
          if (this.year < this.minYear) {
            this.errors.push(
              "Field should not contain year less than " + this.minYear
            );
          }
          if (this.year.length < 4)
            this.errors.push("Year should have 4 digits");
        }
      }
    },
    createDate() {
      let dateString = "";
      if (!this.errors.length) {
        dateString =
          this.formatNumber(this.month + 1) +
          "/" +
          this.formatNumber(this.day) +
          "/" +
          this.year;
      }
      this.$emit("updateDate", dateString);
    },
    formatNumber(number) {
      return number <= 9 ? "0" + parseInt(number, 10) : number;
    },
    setDate(date) {
      if (date) {
        this.month = parseInt(date.substring(0, 2)) - 1;
        this.day = date.substring(3, 5);
        this.year = date.substring(6, 10);
      }
    },
  },
};
</script>
