<script setup>
import { toRefs, ref, computed } from "vue";
import orders from "@/services/orders";
import { useToast } from "vue-toast-notification";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner";
import VSelectCaret from "@/components/shared/select/VSelectCaret";
import SimpleTextarea from "@/components/shared/SimpleTextarea";
import CloseIcon from "@/components/svg-icons/CloseIcon";
import {
  CANCELLATION_REASONS_OTHER,
  CANCELLATION_REASONS,
  ERROR_TIMEOUT, SUCCESS_TIMEOUT,
  MAX_NOTE_TEXTAREA_LIMIT,
} from "@/constants";
import { watchOnce } from "@vueuse/core";
import { ERROR_MESSAGE } from "@/components/StudentFulfillment/components/modals/HousingFulfillmentModal/constants";

const toast = useToast();

const props = defineProps({
  modalIsOpen: {
    type: Boolean,
    default: true,
  },
  orderId: {
    type: String,
    default: "",
  },
  lineItem: {
    type: Object,
    default: () => { },
  },
  profileName: {
    type: String,
    default: "",
  },
  statusOptions: {
    type: Array,
    default: () => [],
  },
});

const { orderId, modalIsOpen, lineItem, statusOptions, profileName } = toRefs(props);

const isSaving = ref(false);

const emit = defineEmits(["closeModal"]);

const checkOtherCancellationReason = (cancellationReason) =>
  cancellationReason && CANCELLATION_REASONS.findIndex(({ id }) => id === cancellationReason) < 0;

const defaultCancellationReason = ref();
const customCancellationReasonText = ref();

const isCanceledSelected = computed(() => lineItem.value?.status === "Canceled");

const handleCloseModal = (orderData) => {
  emit("closeModal", orderData, false);
};

const updateItemStatus = async () => {
  isSaving.value = true;
  try {
    const cancellation_reason =
      defaultCancellationReason.value === CANCELLATION_REASONS_OTHER ?
        customCancellationReasonText.value :
        defaultCancellationReason.value;

    const orderData = await orders.updateOrderLineItem({
      orderId: orderId.value,
      productId: lineItem.value.id,
      payload: {
        new_product_id: lineItem.value.id,
        status: lineItem.value.status,
        quantity: lineItem.value.quantity,
        cancellation_reason
      },
      skipErrorBus: true,
    });
    toast.open({
      message: "Order item status updated successfully",
      type: "success",
      position: "bottom",
      duration: SUCCESS_TIMEOUT,
    });
    handleCloseModal(orderData.data);
  } catch (exception) {
    toast.open({
      message: `${ERROR_MESSAGE}`,
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
    handleCloseModal();
  } finally {
    isSaving.value = false;
  }
};

watchOnce(lineItem, (item) => {
  defaultCancellationReason.value =
    checkOtherCancellationReason(item?.cancellation_reason) ?
      CANCELLATION_REASONS_OTHER :
      item?.cancellation_reason;
  customCancellationReasonText.value =
    checkOtherCancellationReason(item?.cancellation_reason) ?
      item?.cancellation_reason :
      undefined;
});
</script>

<template>
  <div>
    <div v-if="modalIsOpen" class="relative z-50">
      <div class="fixed inset-0 z-40 hidden bg-university-primary opacity-50 sm:block"></div>
      <Dialog :open="modalIsOpen"
        class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
        @close-modal="handleCloseModal" @click.self="handleCloseModal">
        <div class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-50 relative">
          <div class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r">
            <button class="h-6 w-6 flex items-center justify-center" @click="handleCloseModal">
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <DialogPanel>
            <DialogTitle
              class="flex justify-center mt-6 mb-12 text-xl leading-tight text-center sm:text-2xl md:text-3xl">
              {{ profileName ? `${profileName}'s ` : "" }}Insurance
            </DialogTitle>
            <div class="w-full">
              <div class="flex justify-between">
                <label for="insurance-modal-status" class="w-6/12 mr-2">Status</label>
                <VSelectCaret id="insurance-modal-status" v-model="lineItem.status"
                  outer-classes="w-80 checkbox-placeholder" scroll-input="auto" :options="statusOptions"
                  :disabled="isSaving"></VSelectCaret>
              </div>
              <div v-if="isCanceledSelected" class="flex justify-between items-center mt-5">
                <label for="insurance-modal-cancelation-reason" class="common-label-text w-6/12 mr-2">Reason for
                  Cancellation</label>
                <VSelectCaret id="insurance-modal-cancelation-reason" v-model="defaultCancellationReason"
                  outer-classes="w-full checkbox-placeholder" scroll-input="auto" :reduce="(val) => val.id"
                  :options="CANCELLATION_REASONS" :clearable="false" :disabled="isSaving">
                </VSelectCaret>
              </div>
              <SimpleTextarea v-if="isCanceledSelected && defaultCancellationReason === CANCELLATION_REASONS_OTHER"
                id="insurance-modal-custom-reason" v-model="customCancellationReasonText" class="mt-5"
                label-name="Cancellation Reason" :max-length="MAX_NOTE_TEXTAREA_LIMIT" :disabled="isSaving" />
            </div>
            <div class="mt-8 flex justify-center font-semibold">
              <ButtonWithSpinner id="cancel-btn" ref="cancelBtn" data-cy="cancel-btn" variant="secondary-outlined"
                custom-class="mr-5 w-107px" @click="handleCloseModal">
                <span>Cancel </span>
              </ButtonWithSpinner>
              <ButtonWithSpinner :prop-loading="isSaving" id="save-btn" ref="saveBtn" data-cy="save-order-room-button"
                variant="secondary" variant-type="normal" custom-class="w-107px" @click="updateItemStatus">
                <span>Save</span>
              </ButtonWithSpinner>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  </div>
</template>