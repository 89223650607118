<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { createPopper } from "@popperjs/core";
import ErrorMessage from "../ErrorMessage.vue";
import Tooltip from "@/components/forms/SharedComponents/Tooltip.vue";
import Caret from "@/components/shared/icons/Caret.vue";
import { defineProps, useAttrs, ref, watch } from "vue";
import _ from "lodash";

const {
  placement,
  fieldLabel,
  fieldLabelClasses,
  inputClasses,
  errorClasses,
  vuelidateInstance,
  optionKey,
  getOptionLabel,
  outerClasses,
  scrollInput,
  enableSelectAllCheckbox,
  tooltip,
} = defineProps({
  placement: {
    required: false,
    type: String,
  },
  fieldLabel: {
    type: String,
  },
  fieldLabelClasses: {
    type: String,
    default: "common-label-text",
  },
  outerClasses: {
    type: String,
  },
  inputClasses: {
    type: String,
  },
  errorClasses: {
    type: String,
  },
  optionClasses: {
    type: String,
    default: "block text-sm text-gray-700 capitalize",
  },
  vuelidateInstance: {},
  optionKey: {},
  getOptionLabel: {},
  scrollInput: {
    type: String,
    required: false,
    default: "visible",
  },
  inputMaxHeight: {
    type: String,
    required: false,
    default: "inherit",
  },
  enableSelectAllCheckbox: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    required: false,
  },
});

const attrs = useAttrs();

const isChecked = (option = {}) => {
  if (allSelected()) return true;
  return (
    attrs.modelValue &&
    attrs.modelValue.findIndex((val) => {
      if (optionKey) {
        return typeof val === "object"
          ? val[optionKey] === option[optionKey]
          : val === option[optionKey];
      }
      return typeof val === "object"
        ? val.id === option?.id
        : val === option?.id;
    }) >= 0
  );
};

const allSelected = () => {
  if (!attrs?.modelValue || !enableSelectAllCheckbox) {
    return false;
  } else if (Array.isArray(attrs.modelValue)) {
    return attrs?.modelValue?.some((item) => item?.name === "All");
  } else if (
    typeof attrs?.modelValue === "object" &&
    attrs?.modelValue !== null
  ) {
    return attrs?.modelValue?.name === "All";
  }
  return false;
};

const getId = (option) => {
  if (optionKey) {
    return option[optionKey];
  } else {
    return typeof option === "object" ? option?.id : option;
  }
};

const selectAll = (option) => {
  if (!enableSelectAllCheckbox) return false;
  return option?.name === "All";
};

const getLabel = (item) => {
  const labelKey = attrs.label || "label";
  return item[labelKey] || "";
};

const getLabelFromOption = (option = {}) => {
  const labelKey = attrs.label || "label";
  return option[labelKey] || "";
};

const vueSelect = ref(null);

const withPopper = (dropdownList, component, { width }) => {
  /**
   * We need to explicitly define the dropdown width since
   * it is usually inherited from the parent with CSS.
   */
  dropdownList.style.width = width;
  dropdownList.className += " checkbox_select_override";

  /**
   * Here we position the dropdownList relative to the $refs.toggle Element.
   *
   * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
   * the dropdownList overlap by 1 pixel.
   *
   * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
   * wrapper so that we can set some styles for when the dropdown is placed
   * above.
   */
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: this.placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  /**
   * To prevent memory leaks Popper needs to be destroyed.
   * If you return function, it will be called just before dropdown is removed from DOM.
   */
  return () => popper.destroy();
};

watch(
  () => attrs.modelValue,
  (newVal, oldValue) => {
    // if(newVal !== oldValue) {
    //   vueSelect.value.updateValue(newVal);
    // }
    if (vueSelect?.value?._value) {
      const key = optionKey ? optionKey : "id";
      const diff = _.differenceBy(
        vueSelect.value._value,
        newVal,
        optionKey ? optionKey : key
      );
      if (diff.length > 0) {
        vueSelect.value.setInternalValueFromOptions(newVal);
      }
      // diff.forEach((val) => vueSelect.value.deselect(val));
    }
  },
  { deep: true }
);
</script>

<template>
  <div :class="outerClasses">
    <slot name="fieldLabel">
      <div class="flex">
        <label
          v-show="fieldLabel"
          :name="fieldLabel"
          :class="fieldLabelClasses"
          >{{ fieldLabel }}</label
        >
        <Tooltip class="ml-1 leading-4" v-if="tooltip" :tooltip="tooltip" />
      </div>
    </slot>
    <div>
      <v-select
        ref="vueSelect"
        v-bind="attrs"
        :class="[
          'checkbox_select_override',
          {
            'forced-error-background': !!vuelidateInstance?.$errors?.length,
          },
          inputClasses,
        ]"
        :append-to-body="!!placement"
        :calculate-position="placement && withPopper"
        :get-option-label="getOptionLabel"
        @update:modelValue="vuelidateInstance?.$touch"
      >
        <template
          #selected-option-container="{ option, deselect, multiple, disabled }"
        >
          <span
            class="inline-flex items-center rounded-full bg-blue-300 mb-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium text-university-primary"
          >
            {{ getOptionLabel ? getOptionLabel(option) : getLabel(option) }}
            <div
              class="ml-2 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-university-primary hover:text-blue-500 focus:bg-indigo-500 focus:text-white focus:outline-none cursor-pointer"
              @keypress.enter.prevent="deselect(option)"
              @mousedown.prevent="deselect(option)"
            >
              <span class="sr-only">Remove large option</span>
              <svg
                class="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  stroke-linecap="round"
                  stroke-width="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </div>
          </span>
        </template>
        <template #option="option">
          <div class="flex items-start my-2 focus:bg-teal-100">
            <div class="flex h-5 items-center" v-if="!selectAll(option)">
              <input
                :id="getId(option)"
                aria-describedby="comments-description"
                :name="getId(option)"
                type="checkbox"
                :checked="isChecked(option)"
                class="h-4 w-4 form-checkbox border-gray-300 cursor-pointer"
                @click.stop="() => vueSelect.select(option)"
              />
            </div>
            <div class="text-sm" :class="{ 'ml-3': !selectAll(option) }">
              <div v-if="!!$slots.customLabel">
                <slot name="customLabel" v-bind="option"></slot>
              </div>
              <div
                v-else
                :for="getId(option)"
                :class="[
                  optionClasses,
                  selectAll(option) ? '!text-teal-900 font-semibold' : '',
                ]"
              >
                {{
                  selectAll(option)
                    ? "Select All"
                    : getOptionLabel
                    ? getOptionLabel(option)
                    : getLabelFromOption(option)
                }}
              </div>
            </div>
          </div>
        </template>
        <template
          v-if="!attrs?.components?.OpenIndicator"
          #open-indicator="{ attributes }"
        >
          <span v-bind="attributes" class="mb-[8px] inline-block">
            <Caret width="12px" height="12px" />
          </span>
        </template>
      </v-select>
    </div>
    <ErrorMessage
      :error-classes="errorClasses"
      :vuelidate-instance="vuelidateInstance"
    />
  </div>
</template>

<style scoped>
.forced-select-box-grow :deep(.vs__dropdown-toggle),
:deep(.vs__selected-options) {
  overflow: v-bind(scrollInput);
  max-height: v-bind(inputMaxHeight);
}

.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}

.default-chevron :deep(.vs__search) {
  margin: 0;
  padding: 2px 0 2px;
}

.default-chevron :deep(input::placeholder) {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 38, 76, var(--tw-placeholder-opacity));
}

.default-chevron :deep(.vs__actions) {
  cursor: default;
}
</style>

<style>
.checkbox_select_override .vs__dropdown-toggle,
.vs__selected-options {
  max-height: none;
}

.checkbox_select_override .vs__dropdown-option--highlight {
  background-color: #edf9f8 !important;
}

.checkbox_select_override .vs__dropdown-option--deselect {
  background-color: #c8d0ec !important;
}

.checkbox_select_override .vs__dropdown-option--highlight {
  color: var(--vs-dropdown-option-color);
}

.checkbox_select_override:not(.default-chevron) .vs__open-indicator {
  transform: none !important;
}
</style>
