<template>
  <form-panel
    :id="'promotion-code'"
    :title="'Apply Promotion'"
    :initially-expanded="expand"
    panel-content-class="grid grid-cols-1 gap-4 pb-6 mb-6 border-b border-gray-200 md:grid-cols-2 sm:gap-6"
  >
    <template #content>
      <p
        v-if="isCubaProgram"
        :class="`${primaryColorClass}`"
        class="col-span-2"
      >
        Please call 1-800-844-4124 to make your payment and you will be given a
        promotion code to submit your {{ APPLICATION_FEE_DATA.label }}
      </p>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Promotion code to waive {{ APPLICATION_FEE_DATA.label }}</span>
        </span>
        <input
          id="promo-code"
          v-model="discountInput"
          data-cy="payDepositPromoCode"
          type="text"
          :disabled="validDiscountCode === true ? true : false"
          class="form-input block w-full min-h-10"
          :class="
            validDiscountCode === false || v$.validDiscountCode.$error
              ? 'bg-error-100'
              : validDiscountCode === true
              ? 'bg-success-100 cursor-not-allowed'
              : ''
          "
        />
      </label>
      <div class="flex justify-center items-end">
        <ButtonWithSpinner
          v-if="validDiscountCode !== true"
          ref="promoCodeButton"
          variant="secondary"
          variant-type="block"
          data-cy="payDepositPromoCodeApply"
          :disabled="promoCodeBtnIsDisable"
          @click="applyDiscountCode"
        >
          Apply Code
        </ButtonWithSpinner>
      </div>
      <div
        v-if="v$.validDiscountCode.required.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.validDiscountCode.$error }"
      >
        A valid code is required
      </div>
      <div v-if="validDiscountCode === false" class="error text-error-900">
        Invalid Code
      </div>
      <div v-if="validDiscountCode === true" class="success text-success-900">
        Code applied successfully
      </div>
    </template>
  </form-panel>
</template>

<script>
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner";
import formPanel from "../SharedComponents/panel";
import { APPLICATION_FEE_DATA } from "@/constants";
import { mapState } from "vuex";

import { requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "PromoCode",
  components: { ButtonWithSpinner, formPanel },
  props: {
    promoCode: {
      type: String,
      default: "",
    },
    expand: {
      type: Boolean,
      default: false,
    },
    requiredDiscount: {
      type: Boolean,
      default: false,
    },
    isCubaProgram: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["setDiscountCode", "update:promo-code"],
  setup() {
    // We need to include APPLICATION_FEE_DATA to be able to use in ui
    return { v$: useVuelidate(), APPLICATION_FEE_DATA };
  },
  data() {
    return {
      discountInput: "",
      validDiscountCode: "",
    };
  },
  validations: {
    validDiscountCode: {
      required: requiredIf(function () {
        return this.requiredDiscount;
      }),
    },
  },
  computed: {
    ...mapState(["formioToken", "userData"]),
    promoCodeBtnIsDisable() {
      if (this.discountInput.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    discountInput: {
      handler: function (val) {
        this.$emit("update:promo-code", val);
      },
    },
    promoCodeBtnIsDisable: function (val) {
      if (val) {
        this.validDiscountCode = "";
      }
    },
  },

  methods: {
    applyDiscountCode() {
      //calling the startLoading method of the button, which enables the button to loading state
      this.$refs.promoCodeButton.startLoading();
      const body = {
        user: this.userData,
      };

      fetch("/discounts/" + this.discountInput, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json",
        },
        mode: "cors",
      })
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(() => {
          this.validDiscountCode = true;
          this.$emit("setDiscountCode", this.discountInput);
          this.$refs.promoCodeButton.stopLoading();
        })
        .catch(() => {
          this.validDiscountCode = false;
          this.$refs.promoCodeButton.stopLoading();
        });
    },
  },
};
</script>
