<script setup>
import { ref, watch, defineProps, defineEmits, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";

const props = defineProps({
  totalPages: {
    type: Number,
    required: true,
    default: () => {},
  },
  currentPage: {
    type: Number,
    required: true,
    default: () => {},
  },
  isPages: {
    type: Boolean,
    required: true,
    default: () => {},
  },
  urlParamName: {
    type: String,
    required: true,
  },
});

const { totalPages, currentPage, isPages, urlParamName } = toRefs(props);
const route = useRoute();
const router = useRouter();
const page = ref(0);
const showBool = ref(false);

watch(isPages, () => {
  showBool.value = isPages.value;
});

const emit = defineEmits(["page-change", "show-bool", "show-less"]);

const emitPageChange = (page) => {
  emit("page-change", page.value);
};
const emitShowLess = (page) => {
  emit("show-less", page.value);
};
const emitBoolChange = (bool) => {
  emit("show-bool", bool.value);
};

const loadMore = async () => {
  page.value = currentPage.value;
  showBool.value = isPages.value;
  page.value++;
  showBool.value = page.value >= totalPages.value ? true : false;
  const query = { ...route.query }; // Clone the existing query object
  query[urlParamName.value] = page.value; // Set the query parameter to the prop value
  await router.push({ query });
  emitPageChange(page);
  emitBoolChange(showBool);
};
const collapse = async () => {
  page.value = 1;
  const query = { ...route.query }; // Clone the existing query object
  query[urlParamName.value] = page.value; // Set the query parameter to the prop value
  await router.push({ query });
  showBool.value = page.value >= totalPages.value ? true : false;
  emitShowLess(page);
  emitBoolChange(showBool);
};
</script>

<template>
  <div class="flex gap-2 justify-center">
    <button
      class="text-md font-semibold text-blue-900"
      :disabled="currentPage <= 1"
      @click="collapse"
    >
      Show Less
    </button>
    <button
      class="text-md font-semibold text-blue-900"
      :disabled="showBool"
      @click="loadMore"
    >
      Show More
    </button>
  </div>
</template>
