<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <h1 class="mb-2 text-xl text-gray-700 md:text-2xl">
        Housing Accommodations
      </h1>
      <p class="text-gray-600 mb-6">
        Accommodations available vary by our program sites, and we encourage our
        participants to choose the accommodation that best suits their needs and
        preferences. Accommodations are reserved on a first-come, first-serve
        basis - in most cases, participants receive their housing accommodations
        approximately two weeks prior to the program start date. If you are
        under 18, you will be required to abide by a curfew and might be
        required to live with a host family.
      </p>
      <AccommodationStylePreference
        v-if="
          hosingRules.accommodationStylePreference &&
          filterAccommodationOptions.length > 0
        "
        ref="accommodationStylePreference"
        :preference="preference"
        :preference-options="filterAccommodationOptions"
        :disabled-panel="disabledPanel"
        :formio-data="formData.preference"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:preference="preference = $event"
      />
      <RankingAccommodation
        ref="rankingAccommodation"
        :ranking="ranking"
        :ranking-options="filterAccommodationOptions"
        :disabled-panel="disabledPanel"
        :preference-option="preference.option"
        :formio-data="formData.ranking"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:ranking="ranking = $event"
      />
      <IndependentHousing
        v-if="hosingRules.independentHousing"
        ref="independentHousing"
        :indepent-housing="independentHousing"
        :formio-data="formData.independent"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:indepent-housing="independentHousing = $event"
      />
      <SingleRoom
        v-if="hosingRules.singleRoomPreference"
        ref="singleRoomPreference"
        :single-room="singleRoom"
        :disabled-panel="disabledPanel"
        :formio-data="formData.singleRoom"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:single-room="singleRoom = $event"
      />
      <RoommateHousemate
        v-if="hosingRules.requestingaRoommate"
        :id="'requestingARoommate'"
        ref="requestingaRoommate"
        :roomates="requestingRoommate"
        :title="'Requesting a Roommate'"
        :disabled-panel="disabledPanel"
        :formio-data="formData.roommateRequest"
        formio-prefix="roommateRequest"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:roomate="requestingRoommate = $event"
      >
        <template #header>
          <p>
            Would you like to request a roommate? We do our best to honor mutual
            requests. Coordinate with your prospective roommate on your request
            and share their first and last name(s). Keep in mind that your
            roommate request will take precedence over your accommodation style
            preference.
          </p>
        </template>
      </RoommateHousemate>
      <RoommateHousemate
        v-if="hosingRules.decliningaRoommate"
        :id="'decliningARoommate'"
        ref="decliningaRoommate"
        :roomates="decliningRoommate"
        :title="'Declining a Roommate'"
        :disabled-panel="disabledPanel"
        :formio-data="formData.roommateDecline"
        formio-prefix="roommateDecline"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:roomate="decliningRoommate = $event"
      >
        <template #header>
          <p>
            Would you like to decline a roommate? We do our best to honor mutual
            requests. Share their first and last name(s) you don’t want to be
            roommates with. Your request will be confidential. Keep in mind that
            your roommate request will take precedence over your accommodation
            style preference.
          </p>
        </template>
      </RoommateHousemate>
      <RoommateHousemate
        v-if="hosingRules.requestingaHousemate"
        :id="'requestingAHousemate'"
        ref="requestingaHousemate"
        :roomates="requestingHousemate"
        :title="'Requesting a Housemate'"
        :disabled-panel="disabledPanel"
        :formio-data="formData.housemateRequest"
        formio-prefix="housemateRequest"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:roomate="requestingHousemate = $event"
      >
        <template #header>
          <p>
            Would you like to request a housemate? A housemate will live in your
            apartment or residencia, but is not your direct roommate. We do our
            best to honor mutual requests. Coordinate with your prospective
            housemate on your request and share their first and last name(s).
          </p>
        </template>
      </RoommateHousemate>
      <OrderOfImportance
        v-if="hosingRules.rankingYourHousingPreference"
        ref="rankingYourHousingPreference"
        :order-of-importance="orderImportance"
        :disabled-panel="disabledPanel"
        :formio-data="formData.order"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:order-of-importance="orderImportance = $event"
      />
      <HousingQuestionnaire
        v-if="hosingRules.housingQuestionnaire"
        ref="housingQuestionnaire"
        :questionnaire="housingQuestionnaire"
        :disabled-panel="disabledPanel"
        :formio-data="formData.questionnaire"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update-questionnaire="housingQuestionnaire = $event"
      />
      <HostFamily
        v-if="hosingRules.hostFamily"
        ref="hostFamily"
        :host-family="hostFamily"
        :disabled-panel="disabledPanel"
        :formio-data="formData.hostFamily"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:host-family="hostFamily = $event"
      />

      <ButtonWithSpinner
        ref="housingSubmit"
        type="submit"
        variant="primary"
        variant-type="block"
        class="mt-6"
        @click="submit"
      >
        <span>
          Submit
          <svg
            v-if="successSubmission && !hasErrors"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="inline feather feather-check"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </ButtonWithSpinner>
      <div v-if="submitError" class="error text-error-900 mt-2">
        {{ submitError }}
      </div>
      <div v-if="successSubmission && !hasErrors" class="text-success-900">
        Submission Complete, thank you!
      </div>
      <p v-if="hasErrors" class="text-error-900">
        Please complete the required fields correctly.
      </p>
    </template>
  </PageStyle>
</template>

<script>
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import FormValidation from "../../../mixins/formValidation";
import AccommodationStylePreference from "./AccommodationStylePreference.vue";
import RankingAccommodation from "./RankingAccommodation.vue";
import IndependentHousing from "./IndependentHousing.vue";
import SingleRoom from "./SingleRoom.vue";
import RoommateHousemate from "./RoommateHousemate.vue";
import OrderOfImportance from "./OrderOfImportance.vue";
import HousingQuestionnaire from "./HousingQuestionnaire.vue";
import HostFamily from "./HostFamily.vue";
import { mapState } from "vuex";
import forms from "../../../mixins/forms.js";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import formIoApi from "../../../mixins/formIoApi.js";

export default {
  name: "Housing",
  components: {
    PageStyle,
    AccommodationStylePreference,
    RankingAccommodation,
    IndependentHousing,
    SingleRoom,
    RoommateHousemate,
    OrderOfImportance,
    HousingQuestionnaire,
    HostFamily,
    ButtonWithSpinner,
  },
  mixins: [FormValidation, forms, formIoApi],
  data() {
    return {
      sectionsToValidate: [
        "accommodationStylePreference",
        "rankingAccommodation",
        "independentHousing",
        "singleRoomPreference",
        "requestingaRoommate",
        "decliningaRoommate",
        "requestingaHousemate",
        "rankingYourHousingPreference",
        "housingQuestionnaire",
        "hostFamily",
      ],
      accommodationOptions: [
        {
          label: "University Housing",
          value: "universityHousing",
          description:
            "Our host institution offers housing to API participants enrolled in their academic programs. Select this option to live with other US, international, and / or local students attending the university.",
          greatFor: "peer relationships, university experience",
          icon: "lg-icon-university-housing",
          pillLabel: "",
          show: false,
        },
        {
          label: "Apartment",
          value: "apartment",
          description:
            "We arrange for our participants to live in apartment-style housing for their time abroad. Apartments are located throughout the city within local residential buildings. Select this option to live with other API participants.",
          greatFor: "peer relationships, do-it-yourself",
          icon: "lg-icon-apartment",
          pillLabel: "",
          show: false,
        },
        {
          label: "Host Family",
          value: "hostFamily",
          description:
            "We match our participants with host families for their time abroad. Participants may / may not be hosted with other participants.",
          greatFor: "home away from home, local vibes, language learners",
          icon: "lg-icon-host-family",
          pillLabel: "",
          show: false,
        },
        {
          label: "Residencia",
          value: "residencia",
          description:
            "Our participants may live in a unique, boarding style housing option with a local family or small business for their time abroad. Participants may / may not be hosted with other participants.",
          greatFor: "local vibes, language learners",
          icon: "lg-icon-residencia",
          pillLabel: "",
          show: false,
        },
        {
          label: "Hotel",
          value: "hotel",
          description:
            "We arrange for our participants to live in hotel-style housing for their time abroad.",
          greatFor: "peer relationships, local vibes",
          icon: "lg-icon-hotel",
          pillLabel: "",
          show: false,
        },
        {
          label: "Residence Hall",
          value: "residenceHall",
          description:
            "Our participants may live in a residence hall option. This option is appropriate for students who wish to live in a student environment. Participants may/ may not be hosted with other API participants.",
          greatFor: "local vibes, language learners",
          icon: "lg-icon-residencia",
          pillLabel: "",
          show: false,
        },
      ],
      preference: {},
      ranking: {},
      independentHousing: {},
      singleRoom: {},
      requestingRoommate: {},
      decliningRoommate: {},
      requestingHousemate: {},
      orderImportance: {},
      housingQuestionnaire: {},
      hostFamily: {},
      disabledPanel: false,
      hosingRules: {
        accommodationStylePreference: false,
        independentHousing: false,
        singleRoomPreference: false,
        requestingaRoommate: false,
        decliningaRoommate: false,
        requestingaHousemate: false,
        rankingYourHousingPreference: false,
        housingQuestionnaire: false,
        hostFamily: false,
      },
      ruleType: "",
      successSubmission: false,
      submitError: "",
      formData: {},
      formURL: "housing",
      submissionId: "",
    };
  },
  computed: {
    ...mapState(["program"]),
    applicationId() {
      return this.$route.params.applicationId;
    },
    filterAccommodationOptions() {
      return this.accommodationOptions.filter((item) => item.show);
    },
  },
  watch: {
    independentHousing: {
      handler: function (val) {
        if (Object.prototype.hasOwnProperty.call(val, "independentHousing"))
          this.disabledPanel = val.independentHousing;

        if (val.independentHousing) {
          this.sectionsToValidate = ["independentHousing"];
        } else {
          for (const ref in this.$refs) {
            if (
              ref !== "independentHousing" &&
              ref !== "hostFamily" &&
              ref !== "housingSubmit"
            ) {
              const index = this.sectionsToValidate.indexOf(ref);
              if (index === -1) this.sectionsToValidate.push(ref);
            }
          }
        }
      },
      deep: true,
    },
    preference: {
      handler: function (val) {
        const index = this.sectionsToValidate.indexOf("hostFamily");

        if (val.option === "hostFamily") {
          this.hosingRules.hostFamily = true;
          if (index === -1) this.sectionsToValidate.push("hostFamily");
        } else {
          this.hosingRules.hostFamily = false;
          this.hostFamily = {};
          if (index > -1) this.sectionsToValidate.splice(index, 1);
        }
      },
      deep: true,
    },
  },

  async created() {
    const valid = await this.isValidApplicationAndIsOnRules(
      this.applicationId,
      "Housing Accommodations"
    );
    this.ruleType = valid.ruleType;

    if (valid.valid) {
      this.getFormioData();
      this.setHousingFormRules();
      this.setHousingRules();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    async submit() {
      this.submitError = "";
      this.successSubmission = false;
      this.$refs.housingSubmit.startLoading();

      try {
        await this.validate();

        if (!this.hasErrors) {
          if (this.submissionId) {
            this.updateSubmission();
          } else {
            this.createSubmission();
          }
        }
      } catch {
        this.$refs.housingSubmit.stopLoading();
      }
    },
    setHousingFormRules() {
      const rules = [
        "Accommodation Style Preference",
        "Independent Housing",
        "Single Room Preference",
        "Requesting a Roommate",
        "Declining a Roommate",
        "Requesting a Housemate",
        "Ranking Your Housing Preference",
        "Housing Questionnaire",
      ];

      rules.forEach((rule) => {
        let ruleName = rule.replace(/\s/g, "");
        ruleName = ruleName.charAt(0).toLowerCase() + ruleName.slice(1);

        if (rule === "Independent Housing") {
          this.hosingRules[ruleName] = this.program.housing_rules.some((item) =>
            item.includes(rule)
          );
        } else {
          this.hosingRules[ruleName] = this.program[this.ruleType].includes(
            rule
          );
        }
      });

      //Delete from sectionsToValidate if section doesnt appear on rules
      for (const prop in this.hosingRules) {
        if (!this.hosingRules[prop]) {
          const index = this.sectionsToValidate.indexOf(prop);
          if (index > -1) {
            this.sectionsToValidate.splice(index, 1);
          }
        }
      }
    },
    setHousingRules() {
      this.accommodationOptions.forEach((item) => {
        item.show = this.program.housing_rules.includes(item.label);
      });
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;

          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                let formioObject = {};
                formioObject.preference = {
                  option: response.accommodationStylePreference ?? "",
                };
                formioObject.ranking = {
                  universityHousing: response.universityHousing ?? "",
                  apartment: response.apartment ?? "",
                  hostFamily: response.hostFamily ?? "",
                  residencia: response.residencia ?? "",
                  hotel: response.hotel ?? "",
                  residenceHall: response.residenceHall ?? "",
                };
                formioObject.independent = {
                  independentHousing: response.independentHousing ?? false,
                  waiverFile: response.waiverFile ?? [],
                };
                formioObject.singleRoom = {
                  singleRoom: response.singleRoom ?? "",
                  singleRoomType: response.ifSingleRoom ?? "",
                };
                formioObject.roommateRequest = {
                  mates: response.roommateRequestGrid ?? [],
                };
                formioObject.roommateDecline = {
                  mates: response.roommateDeclineGrid ?? [],
                };
                formioObject.housemateRequest = {
                  mates: response.housemateRequestGrid ?? [],
                };
                formioObject.order = {
                  accommodationStyle: response.accommodationStyle ?? {},
                  singleRoom: response.accommodationSingleRoom ?? {},
                  roommateRequest: response.accommodationRoommateRequest ?? {},
                };
                formioObject.questionnaire = {
                  morningRoutine: response["morning-routine"] ?? "",
                  nighttimeRoutine: response["evening-routine"] ?? "",
                  noisePreference: response["noise-pref"] ?? "",
                  cleanliness: response.cleanliness ?? "",
                  socialize: response["social-life"] ?? "",
                  smoke: response.smoking ?? "",
                  singleGenderHousing:
                    response.singleGenderHousingArrangements ?? false,
                  mixedGenderCoEdHousing:
                    response.mixedGenderCoEdHousing ?? false,
                  genderInclusiveHousing:
                    response.genderInclusiveHousingArrangements ?? false,
                  abroadBefore: response.abroadBefore ?? "",
                  abroadBeforeText: response.abroadBeforeText ?? "",
                  specialRequests: response.specialRequests ?? "",
                };
                formioObject.hostFamily = {
                  familyHasChildren: response.familyHasChildren ?? "",
                  noEnglish: response.noEnglish ?? "",
                  integratedIntoFamily: response.integratedIntoFamily ?? "",
                  noPets: response.noPets ?? "",
                  mealArrangements: response.mealArrangements ?? "",
                };
                this.formData = formioObject;
              }
            );
          }
        }
      );
    },
    getJsonData() {
      return {
        data: {
          application_id: this.applicationId,
          accommodationStylePreference: this.preference.option ?? "",
          universityHousing: this.ranking.universityHousing ?? "",
          apartment: this.ranking.apartment ?? "",
          hostFamily: this.ranking.hostFamily ?? "",
          residencia: this.ranking.residencia ?? "",
          hotel: this.ranking.hotel ?? "",
          residenceHall: this.ranking.residenceHall ?? "",
          independentHousing:
            this.independentHousing.independentHousing ?? false,
          waiverFile: this.independentHousing.waiverFile ?? [],
          singleRoom: this.singleRoom.singleRoom ?? "",
          ifSingleRoom: this.singleRoom.singleRoomType ?? "",
          roommateRequestGrid: this.mapMates(
            this.requestingRoommate.mates,
            "roommateRequest"
          ),
          roommateDeclineGrid: this.mapMates(
            this.decliningRoommate.mates,
            "roommateDecline"
          ),
          housemateRequestGrid: this.mapMates(
            this.requestingHousemate.mates,
            "housemateRequest"
          ),
          accommodationStyle: this.orderImportance.accommodationStyle ?? {},
          accommodationSingleRoom: this.orderImportance.singleRoom ?? {},
          accommodationRoommateRequest:
            this.orderImportance.roommateRequest ?? {},
          "morning-routine": this.housingQuestionnaire.morningRoutine ?? "",
          "evening-routine": this.housingQuestionnaire.nighttimeRoutine ?? "",
          "noise-pref": this.housingQuestionnaire.noisePreference ?? "",
          cleanliness: this.housingQuestionnaire.cleanliness ?? "",
          "social-life": this.housingQuestionnaire.socialize ?? "",
          smoking: this.housingQuestionnaire.smoke ?? "",
          singleGenderHousingArrangements:
            this.housingQuestionnaire.singleGenderHousing ?? false,
          mixedGenderCoEdHousing:
            this.housingQuestionnaire.mixedGenderCoEdHousing ?? false,
          genderInclusiveHousingArrangements:
            this.housingQuestionnaire.genderInclusiveHousing ?? false,
          abroadBefore: this.housingQuestionnaire.abroadBefore ?? "",
          abroadBeforeText: this.housingQuestionnaire.abroadBeforeText ?? "",
          specialRequests: this.housingQuestionnaire.specialRequests ?? "",
          familyHasChildren: this.hostFamily.familyHasChildren ?? "",
          noEnglish: this.hostFamily.noEnglish ?? "",
          integratedIntoFamily: this.hostFamily.integratedIntoFamily ?? "",
          noPets: this.hostFamily.noPets ?? "",
          mealArrangements: this.hostFamily.mealArrangements ?? "",
          submitted: true,
        },
      };
    },
    mapMates(array, formioPrefix) {
      return array
        ? array.map((mate) => ({
            [formioPrefix + "FirstName"]: mate.firstName,
            [formioPrefix + "LastName"]: mate.lastName,
            [formioPrefix + "Email"]: mate.email,
          }))
        : [];
    },
    updateSubmission() {
      this.submitToFormIo(
        this.formURL,
        this.getJsonData(),
        "PUT",
        this.submissionId
      )
        .then(() => {
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.housingSubmit.stopLoading();
        });
    },
    createSubmission() {
      this.submitToFormIo(this.formURL, this.getJsonData())
        .then((response) => {
          this.submissionId = response;
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.housingSubmit.stopLoading();
        });
    },
    changeSuccessSubmission() {
      this.successSubmission = false;
    },
  },
};
</script>
