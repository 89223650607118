<script setup>
import { MOBILE_APP_LINKS } from "@/constants.js";

const openLink = (url) => {
  window.open(url, "_blank");
};
</script>

<template>
  <div
    class="font-montserrat text-sm text-indigo-base font-medium mt-4"
    data-testid="mobile-app-download-todos-banner"
  >
    <p>
      <span class="hidden md:inline">Scan to download</span>
      <span class="md:hidden">Click to download</span>
      the mobile app to complete important information.
    </p>
    <div class="flex justify-center items-center">
      <img
        class="hidden md:block h-24 w-24"
        src="/images/mobile-app/qr-code.png"
      />
    </div>
    <div class="md:hidden flex justify-between mt-3">
      <div class="cursor-pointer" @click="openLink(MOBILE_APP_LINKS.APP_STORE)">
        <img src="/images/mobile-app/app-store.png" />
      </div>
      <div
        class="cursor-pointer"
        @click="openLink(MOBILE_APP_LINKS.GOOGLE_PLAY)"
      >
        <img src="/images/mobile-app/google-play.png" />
      </div>
    </div>
  </div>
</template>
