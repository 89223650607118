<script setup>
import { computed, ref, defineProps, watch } from "vue";
import SessionCard from "@/components/ExperiencePage/Sessions/Components/SessionCard";
import { parseSessionStartDate } from "@/mixins/helpers";
import { scrollToTarget } from "@/util/scrollToTarget";
import ShowMore from "../../ShowMore/ShowMore.vue";
import _ from "lodash";
import { useRouter, useRoute } from "vue-router";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import SkeletonLoader from "@/components/shared/loaders/BaseSkeletonLoader.vue";
import { useStore } from "vuex";
import SessionGridRow from "@/components/ExperiencePage/Sessions/Components/SessionGridRow.vue"
import { isValid } from "date-fns";

const emit = defineEmits(["applyClicked", "programdates-set-session-id"]);
const store = useStore();
const router = useRouter();
const route = useRoute();
const today = new Date();
const selectedYear = ref(today.getFullYear());
const items = ref([]);
const isPages = ref(false);

const mainCardDiv = ref(null);
const scrollDuration = 600;

const handleShowLess = (eventData) => {
  showLessData(eventData);
  scrollToTarget(mainCardDiv, scrollDuration);
};

const pageParam = ref(router?.currentRoute?.value?.query?.page ?? 1);
const urlName = "page";

const chunkedSessions = ref([]);

const displayNewDesigns = computed(() => {
  return store.state?.featureFlags?.['date-card-redesign'] === true;
});

const loading = computed(() => {
  return props.sessions.length === 0;
});
const props = defineProps({
  sessions: {
    type: Object,
    default() {
      return [];
    },
  },
  travel_details: {
    type: Object,
    default() {
      return [];
    },
  },
  isLoading: {
    type: Boolean,
    default: true,
  },
  sessionId: {
    type: String,
    default: "",
  },
});
function onApplyClicked(sessionId) {
  emit("applyClicked", sessionId);
}

function showMoreData(page) {
  pageParam.value = page;
  let newItems = chunkedSessions.value[page - 1];
  items.value = [...items.value, ...newItems];
}

function showLessData(page) {
  pageParam.value = page;
  let newItems = chunkedSessions.value[page - 1];
  items.value = [...newItems];
}

function updateShowMore(bool) {
  isPages.value = bool;
}

const listOfYearFilters = computed(() => {
  if (!props.sessions) {
    return [];
  }
  let listOfYears = [];
  for (let session of props.sessions) {
    if (session?.session_travel_details?.length > 0) {
      const startDate = new parseSessionStartDate(
        session?.session_travel_details
      );
      if (isValid(startDate) && listOfYears.indexOf(startDate.getUTCFullYear()) === -1) listOfYears.push(startDate.getUTCFullYear());
    }
  }
  return listOfYears.sort();
});

const filteredSessions = computed(() => {
  const userSelectedSessions = props.sessions.filter((session) =>
    applyUserSelectionSessionFilter(session, selectedYear.value)
  );
  const sessionsNotPastDeadline = userSelectedSessions.filter((sessionObj) => {
    if (!pastSessionDeadline(sessionObj)) return sessionObj;
  });
  return sessionsNotPastDeadline;
});

const newDesignFilteredSessions = computed(() => {
  const newSessions = props.sessions.filter((session) =>
    applyUserSelectionSessionFilter(session, selectedYear.value)
  )
  return newSessions;
});

watch(filteredSessions, async () => {
  chunkedSessions.value = _.chunk(filteredSessions.value, 3);
  if (pageParam.value > chunkedSessions.value.length) {
    pageParam.value = 1;
    await router.push({ query: { ...route.query, page: pageParam.value } });
  }
  if (isNaN(pageParam.value)) {
    pageParam.value = 1;
    await router.push({ query: { ...route.query, page: pageParam.value } });
  }
  isPages.value =
    pageParam.value >= chunkedSessions.value.length ? true : false;
  items.value = _.flatten(_.slice(chunkedSessions.value, 0, pageParam.value));
});

const noSessionsMessage = computed(() => {
  if (loading.value) return "Loading available sessions...";
  else if (!listOfYearFilters.value) return "There are no available sessions.";
  else return "No sessions available for selected year.";
});


const arrivalYear = ref();

watch(() => props?.travel_details, (newTravelDetails) => {
  const startDate = parseSessionStartDate(newTravelDetails);
  if (startDate) {
    arrivalYear.value = Number(startDate?.toISOString().substring(0, 4));
  }
}, {
  deep: true,
  immediate: true
});

watch(listOfYearFilters, async () => {
  if (arrivalYear.value && listOfYearFilters.value.includes(arrivalYear.value))
    selectedYear.value = arrivalYear.value;
  else if (listOfYearFilters.value)
    selectedYear.value = listOfYearFilters.value[0];
});

function onClickYearFilter(event) {
  selectedYear.value = new Date(event.target.innerText, 1).getFullYear();
}

function applyUserSelectionSessionFilter(session, userSelectedYear) {
  const applicationDeadline = new Date(
    parseSessionStartDate(session?.session_travel_details)
  );
  return userSelectedYear === applicationDeadline.getUTCFullYear();
}
</script>
<template>
  <div class="md:pt-20 px-4 md:px-8">
    <div ref="mainCardDiv" class="flex flex-row items-end">
      <h3 class="text-4xl font-bold text-indigo-base">
        <div class="text-2xl">Program</div>
        <div>DATES</div>
      </h3>
      <SkeletonLoader v-if="loading" :height="'h-13'" class="mb-4 ml-14" rounded />
      <div v-if="!loading" class="hidden lg:flex flex-row ml-auto">
        <div v-for="year in listOfYearFilters" :id="`div-${year}`" :key="`key-${year}`" :onClick="onClickYearFilter"
          class="box-border m-2 border-2 px-10 py-3 cursor-pointer font-bold text-base" :class="year === selectedYear
        ? (secondaryColor ?
          `${primaryColorClassBG} text-white` :
          'bg-indigo-base text-white hover:bg-indigo-base hover:text-white'
        )
        : (secondaryColor ?
          `${primaryColorClassOutlined}` :
          'text-indigo-base hover:bg-indigo-base hover:text-white'
        )
        ">
          <p :id="`${year}-paragraph`">
            {{ year }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="displayNewDesigns" class="flex-row mt-4 mb-4">
      <p class="text-lg font-montserrat text-university-primary">
        Pick a date below to see more information about your program.
        When you make a selection, the information on the page will update automatically
        to reflect your choice.
      </p>
    </div>
    <div v-if="!loading" class="lg:hidden flex flex-col mt-6">
      <template v-if="displayNewDesigns">
        <div class="flex flex-row flex-wrap justify-start ml-auto">
          <div v-for="year in listOfYearFilters" :id="`div-${year}-mobile`" :key="`key-${year}-mobile`"
            :onClick="onClickYearFilter" class="box-border m-2 border-2 px-10 py-3 cursor-pointer font-bold text-base"
            :class="year === selectedYear
        ? (secondaryColor ?
          `${primaryColorClassBG} text-white` :
          'bg-indigo-base text-white hover:bg-indigo-base hover:text-white'
        )
        : (secondaryColor ?
          `${primaryColorClassOutlined}` :
          'text-indigo-base hover:bg-indigo-base hover:text-white'
        )
        ">
            <p :id="`${year}-mobile`">
              {{ year }}
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <select v-model="selectedYear" class="form-select w-full font-bold text-base text-indigo-base">
          <option v-for="year in listOfYearFilters" :id="`option-${year}`" :key="`key-${year}`" :value="year">
            {{ year }}
          </option>
        </select>
      </template>
    </div>
    <template v-if="displayNewDesigns">
      <SessionGridRow v-for="(session, index) in newDesignFilteredSessions" :key="session.id" :index-number="index"
        :session="session" :selected-session-id="props.sessionId ?? ''" :oddrow="index % 2 === 0"
        @dategridrow-set-session-id="(sessionId) => emit('programdates-set-session-id', sessionId)"
        @applyBtnClicked="onApplyClicked(session.id)" />
    </template>
    <template v-else>
      <div v-if="filteredSessions.length > 0 || loading"
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-12 pb-18 mt-6">
        <template v-if="isLoading">
          <SkeletonLoader v-for="n in 3" :key="`skeleton-loader-${n}`" :height="'h-64'" :width="'w-96'" rounded />
        </template>
        <template v-if="!loading && filteredSessions.length > 0">
          <SessionCard v-for="session in filteredSessions" :key="session.id" :session="session"
            @applyBtnClicked="onApplyClicked(session.id)" />
        </template>
      </div>
      <ShowMore v-if="chunkedSessions" :total-pages="chunkedSessions.length" :current-page="pageParam"
        :is-pages="isPages" :url-param-name="urlName" @page-change="showMoreData" @show-less="handleShowLess"
        @show-bool="updateShowMore" />
      <div v-else class="w-100 mx-6">
        <p>
          {{ noSessionsMessage }}
        </p>
      </div>
    </template>
    <hr class="my-1 md:mt-20 border-b-3 border-gray-200" />
  </div>
</template>
