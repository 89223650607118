<template>
  <div class="sm:flex sm:justify-between block">
    <div class="flex justify-between text-blue-900 p-6">
      <span class="text-md text-[#1e264c] font-bold mr-6">
        {{ activeFilter.title }}
      </span>
      <span
        class="text-xs inline-block py-2 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-200 rounded-full grow-0"
        >{{ activeFilter.count }}</span
      >
    </div>
    <BaseButton
      class="w-full sm:m-4 sm:max-w-32"
      type="button"
      :disabled="!hasItemsRead"
      :on-click="redirectToViewMorePage"
    >
      <p>VIEW MORE</p>
    </BaseButton>
  </div>
</template>
<script>
import { PERMISSIONS } from "@/constants";
import { mapGetters } from "vuex";

import BaseButton from "../Button/BaseButton.vue";

export default {
  components: {
    BaseButton,
  },
  props: {
    sortingOptions: {
      type: Array,
      default: () => [],
    },
    activeFilter: {
      type: Object,
      default: () => {},
    },
    viewMoreRouteName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentSortedColumn:
        this.sortingOptions &&
        this.sortingOptions[0] &&
        this.sortingOptions[0]?.name
          ? this.sortingOptions[0]?.name
          : undefined,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "getPermissions",
    }),
    hasItemsRead() {
      return (
        this.permissions?.includes(PERMISSIONS.ITEMS_READ) ||
        this.permissions?.includes(PERMISSIONS.ITEMS_READ_LITE) ||
        this.permissions?.includes(PERMISSIONS.PRGMS_READ) ||
        this.permissions?.includes(PERMISSIONS.PRGMS_READ_LITE)
      );
    },
  },
  watch: {
    sortingOptions() {
      this.currentSortedColumn = this.sortingOptions[0].name;
    },
  },
  methods: {
    onSortingChange(option) {
      this.currentSortedColumn = option;
      this.$emit("sortTable", option);
    },
    redirectToViewMorePage() {
      this.$router.push({ name: this.viewMoreRouteName });
    },
  },
};
</script>
