<template>
  <div
    class="flex justify-end items-center"
    :class="{ hidden: !props.programDatesFinalized }"
  >
    <div :id="iconId">
      <svg
        width="16"
        height="14"
        viewBox="0 0 23 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9302 3.052C12.1608 1.70509 10.2188 1.70509 9.44943 3.052L2.60339 15.0331C1.8399 16.3702 2.80503 18.0323 4.34382 18.0323H18.0359C19.5747 18.0323 20.5398 16.3702 19.7763 15.0331L12.9302 3.052ZM7.70901 2.05747C9.24776 -0.636344 13.1317 -0.636344 14.6708 2.05747L21.5169 14.0385C23.0459 16.7108 21.1155 20.0371 18.0361 20.0371H4.34397C1.2645 20.0371 -0.665822 16.711 0.863161 14.0385L7.70901 2.05747ZM11.1898 5.00187C11.7439 5.00187 12.1922 5.45019 12.1922 6.00422V11.5171C12.1922 12.0712 11.7438 12.5195 11.1898 12.5195C10.6358 12.5195 10.1875 12.0712 10.1875 11.5171V6.00422C10.1875 5.45017 10.6358 5.00187 11.1898 5.00187ZM11.1898 15.7225C11.7986 15.7225 12.292 15.2291 12.292 14.6203C12.292 14.0115 11.7987 13.5181 11.1898 13.5181C10.581 13.5181 10.0876 14.0115 10.0876 14.6203C10.0876 15.2292 10.581 15.7225 11.1898 15.7225Z"
          :fill="[secondaryColor ? `${secondaryColor}` : '#007F80']"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { computed, onMounted } from "vue";

const props = defineProps({
  iconId: {
    type: String,
    default: "default-id",
    required: true,
  },
  programDatesFinalized: {
    type: Boolean,
    default: true,
  },
});

const svgId = computed(() => {
  return document.getElementById(props.iconId);
});
onMounted(() => {
  tippy(svgId.value, {
    content: "Dates are not finalized",
    theme: "light",
    role: "tooltip",
    placement: "top",
    maxWidth: 318,
  });
});
</script>
