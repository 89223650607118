<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps, ref } from "vue";
import { useVModel } from "@vueuse/core";

const props = defineProps({
  modelValue: {
    type: Object,
  },
});

const events = useVModel(props, "modelValue");

const radius = ref(events.value["Radius of allowed participants"]);
const availability = ref(events.value["Seasonal Availability"]);
const themes = ref(events.value["Relevant Academic Themes"]);
const isAgeRequirement = ref(events.value["Age Requirement"]);
const audience = ref(events.value["This event is attended by"]);

const ageRequirement = ref(
  isAgeRequirement.value === false ? "All ages" : "Must be over 18"
);
</script>
<template>
  <div class="relative">
    <div class="grid xs:grid-cols-2 sm:grid-cols-2 mt-4 gap-4">
      <div class="text-base font-medium text-gray-700">
        Radius of allowed Participants:
        <span v-if="radius" class="font-semibold">{{ radius }} miles</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Seasonal Availability:
        <span v-if="availability" class="font-semibold">{{
          availability?.map((item) => item.name).join(", ") || ""
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Relevant Academic Themes:
        <span v-if="themes" class="font-semibold">{{
          themes?.map((item) => item.soc_title).join(", ") || ""
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Age Requirement:
        <span class="font-semibold">{{ ageRequirement }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        The event is attended by:
        <span v-if="audience" class="font-semibold">{{
          audience.map((item) => item).join(", ") || ""
        }}</span>
      </div>
    </div>
  </div>
</template>
