import axios from "axios";
import { dollarsToCents } from "@/mixins/helpers";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_ERRORS, APPLICATION_FEE_DATA } from "@/constants";
import { format } from "date-fns";
import { getApiClient } from "@/services/API";
import { uuid } from "vue-uuid";
import { mapState } from "vuex";
import { useCapitalizeWords } from "@/util/string";

export default {
  data() {
    return {
      errorStripePayment: false,
      stripeClient: null,
      stripeElements: null,
      stripeStatus: null,
      paymentSuccess: false,
      paymentStatus: false,
      paymentAlreadyExist: false,
      ipedsId: null,
      userData: null,
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    applicationId() {
      return this.$route.params.applicationId;
    },
    clientSecret() {
      return localStorage.getItem("payment-intent") ?? null;
    },
    currentUI() {
      // If id property exists then it's v3
      return typeof this.programSessionDataV3?.id === "string" ? "v3" : "";
    },
    ownerId() {
      const { formioStudentId, formioAdvisorId } = this.currentUser;
      return formioStudentId || formioAdvisorId;
    },
  },
  async created() {
    this.loadDataFromLocalStorage();
    this.ipedsId = await this.setIpedsId();
    this.checkIfPaymentExists();
    this.stripeClient = await this.getStripeClient();
  },
  methods: {
    async checkIfPaymentExists() {
      await axios
        .get(`/api/stripe-charge/${this.applicationId}`)
        .then((r) => r.data.data)
        .then((data) => {
          this.paymentAlreadyExist = true;
          this.paymentStatus = data.status ?? false;
          this.paymentSuccess = data.status === "succeeded";
          localStorage.setItem("payment-intent", data.stripe_clientsecret);
          this.checkStatusStripe();
        })
        .catch((e) => console.log(e));
    },
    cleanPaymentIntentFromStorage() {
      localStorage.removeItem("payment-intent");
    },
    async checkStatusStripe() {
      if (!this.clientSecret || this.clientSecret === "null") {
        return;
      }
      let stripe = await this.getStripeClient();
      const { paymentIntent } = await stripe.retrievePaymentIntent(
        this.clientSecret
      );

      if (
        this.paymentStatus &&
        this.paymentStatus !== paymentIntent.status &&
        this.paymentAlreadyExist
      ) {
        this.updateStatus(paymentIntent.status);
      }

      this.paymentStatus = paymentIntent.status;
      switch (paymentIntent.status) {
        case "succeeded":
          this.errorStripePayment = false;
          this.stripeStatus =
            "Payment succeeded. Please check your email for your receipt.";
          this.$refs.stripePayDepositForm?.hideButton(true);
          this.paymentSuccess = true;
          if (!this.paymentAlreadyExist) this.savePaymentCharge();
          this.transactionId = this.clientSecret;
          this.cleanPaymentIntentFromStorage();
          break;
        case "processing":
          this.stripeStatus = "Your payment is processing.";
          if (!this.paymentAlreadyExist) this.savePaymentCharge();
          break;
        case "requires_action":
          this.stripeStatus = "Your payment requires additional actions.";
          break;
        default:
          break;
      }
    },
    async handleSubmitToStripe() {
      await this.checkIfPaymentExists();
      if (this.paymentAlreadyExist) return;
      let elements = this.stripeElements;
      const { error } = await this.stripeClient.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
          receipt_email: document.getElementById("email")?.value,
        },
        redirect: "if_required",
      });

      if (
        error?.type === "card_error" ||
        error?.type === "validation_error" ||
        error?.type === "card_declined"
      ) {
        //https://stripe.com/docs/declines/codes
        try {
          this.errorStripePayment = error.decline_code
            ? STRIPE_ERRORS[error.decline_code]
            : STRIPE_ERRORS[error.code];
        } catch {
          this.errorStripePayment = STRIPE_ERRORS["default"];
        }
      } else if (
        error?.type === "api_error" ||
        error?.type === "idempotency_error" ||
        error?.type === "invalid_request_error"
      ) {
        this.errorStripePayment = STRIPE_ERRORS["api_error"];
      } else if (error) {
        this.errorStripePayment = STRIPE_ERRORS["default"];
      }
      await this.checkStatusStripe();
    },
    async getStripeClient() {
      let client = await loadStripe(
        process.env.MIX_STRIPE_PUBLISHABLE_API.toString()
      );
      return client;
    },
    async initialize() {
      if (this.paymentAlreadyExist) return;
      let clientSecret = null;
      let appearance = {};
      let options = {};
      await axios
        .post("/api/payment-intent", this.getPayloadDataForStripeIntent())
        .then((r) => r.data)
        .then((data) => {
          clientSecret = data.clientSecret;
          localStorage.setItem("payment-intent", clientSecret);
        })
        .catch((e) => console.log(e));
      if (this.currentUI === "v3") {
        appearance = {
          variables: {
            fontFamily: "Montserrat",
            fontSizeSm: "14px",
            fontSmooth: "auto",
            fontWeightNormal: "500",
            colorPrimary: "rgb(30, 38, 76)",
            colorText: "rgb(30, 38, 76)",
            borderRadius: "0px",
          },
        };
        options = {
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap",
            },
          ],
        };
      }
      this.stripeElements = this.stripeClient.elements({
        clientSecret,
        appearance,
        ...options,
      });
      const paymentElement = this.stripeElements.create("payment");
      paymentElement.mount("#payment-element");
    },
    getDataForStripeChargeEndpoint() {
      const { price } = this.mapFieldsBasedOnVersion();
      return {
        applicationId: this.applicationId,

        ownerId: this.ownerId,
        firstname: this.billingAddress.firstName,
        email: this.userData.data.email,
        lastname: this.billingAddress.lastName,

        address: this.billingAddress.address,
        city: this.billingAddress.city,
        state: this.billingAddress.state,
        postcode: this.billingAddress.postCode,
        country: this.billingAddress.country,

        currentAddress: this.currentAddress.address,
        currentCity: this.currentAddress.city,
        currentState: this.currentAddress.state,
        currentPostcode: this.currentAddress.postCode,
        currentCountry: this.currentAddress.country,

        permanentAddress: this.permanentAddress.address,
        permanentCity: this.permanentAddress.city,
        permanentState: this.permanentAddress.state,
        permanentPostcode: this.permanentAddress.postCode,
        permanentCountry: this.permanentAddress.country,
        stripeClientSecret: this.clientSecret,
        status: this.paymentStatus,

        amount: dollarsToCents(price),
      };
    },
    async savePaymentCharge() {
      await axios
        .post("/api/stripe-charge", this.getDataForStripeChargeEndpoint())
        .catch((e) => {
          console.log(e);
        });
    },
    checkDataForStripe() {
      this.$refs.stripePayDepositForm?.showButton(false);
      let payload = this.getPayloadDataForStripeIntent();
      let startStripe =
        (payload.billing_street &&
          payload.billing_state_province &&
          payload.billing_city &&
          payload.billing_zip_postal_code &&
          payload.first_name &&
          payload.last_name &&
          payload.permanent_street &&
          payload.permanent_city &&
          payload.permanent_state_province &&
          payload.permanent_zip_postal_code &&
          !this.isCubaProgram) ??
        false;
      if (startStripe) {
        this.initialize();
        setTimeout(() => {
          this.$refs.stripePayDepositForm?.showButton(true);
        }, 2000);
      }
    },
    mapFieldsBasedOnVersion() {
      let program_id,
        is_customized,
        price,
        program_session_id,
        program_name,
        program_session,
        start_date,
        end_date,
        start_date_is_final,
        end_date_is_final,
        program_city,
        program_country;
      switch (this.currentUI) {
        case "v3": {
          program_id = this.programSessionDataV3?.parent?.salesforce_id ?? "";
          price = APPLICATION_FEE_DATA.amount;
          is_customized = this.programSessionDataV3.channel_id === 2;
          program_session_id = this.programSessionDataV3.salesforce_id;
          program_name = this.programSessionDataV3.name;
          // TODO: What happens if terms > 1?
          program_session =
            this.programSessionDataV3.terms?.[0]?.session ?? null; // session as in Fall, Spring, etc
          // TODO: What happens if session_travel_details > 1?
          start_date =
            this.programSessionDataV3?.session_travel_details?.[0]
              ?.arrival_date ?? this.programSessionDataV3?.academic_start_date;
          // TODO: What happens if session_travel_details > 1?
          end_date =
            this.programSessionDataV3?.session_travel_details?.[0]
              ?.departure_date ?? this.programSessionDataV3?.academic_end_date;
          // TODO: What happens if session_travel_details > 1?
          start_date_is_final =
            this.programSessionDataV3?.session_travel_details?.[0]
              ?.arrival_date_is_final ?? false;
          // TODO: What happens if session_travel_details > 1?
          end_date_is_final =
            this.programSessionDataV3?.session_travel_details?.[0]
              ?.departure_date_is_final ?? false;
          // TODO: What happens if session_travel_details > 1?
          program_city =
            this.programSessionDataV3.session_travel_details?.[0]?.arrival_city
              ?.city ?? null;
          // TODO: What happens if session_travel_details > 1?
          program_country =
            this.programSessionDataV3.session_travel_details?.[0]?.arrival_city
              ?.country?.name ?? null;
          break;
        }
        default: {
          // Covers v2 and below
          program_id = this.program?.program?.salesforce_id;
          is_customized = this.program?.program?.customized ?? false;
          price = this.program.application_fee;
          program_session_id = this.program.salesforce_id;
          program_name = this.program?.program?.name;
          program_session = this.program?.session;
          start_date = this.program?.start_date;
          start_date_is_final = this.program?.start_date_is_final ?? false;
          end_date = this.program?.end_date;
          end_date_is_final = this.end_date_is_final ?? false;
          program_city = this.program?.site?.city;
          program_country = this.program?.site?.country;
        }
      }
      return {
        program_id,
        is_customized,
        price,
        program_session_id,
        program_name,
        program_session,
        start_date,
        end_date,
        start_date_is_final,
        end_date_is_final,
        program_city,
        program_country,
      };
    },
    loadDataFromLocalStorage() {
      try {
        const storedUserData = JSON.parse(localStorage.getItem("formioUser"));
        if (storedUserData) {
          this.userData = storedUserData;
        }
      } catch (error) {
        console.error("Error parsing userData from localStorage:", error);
      }
    },
    getPayloadDataForStripeIntent() {
      const {
        program_id,
        is_customized,
        price,
        program_session_id,
        program_name,
        program_session,
        start_date,
        end_date,
        start_date_is_final,
        end_date_is_final,
        program_city,
        program_country,
      } = this.mapFieldsBasedOnVersion();
      return {
        first_name: this.userData.data.firstname,
        last_name: this.userData.data.lastname,
        email: this.userData.data.email,
        phone: this.userData.data.phone,
        birth_date: this.birthDate,
        participant_type: this.userData.data.student_type,
        home_university_name: this.userData.data.schoolname.label ?? "",
        home_institution_ipeds: this.ipedsId,
        application_id: this.getApplicationId,
        legacy_participant_account_id: this.customer_id,

        billing_street: this.billingAddress.address,
        billing_city: this.billingAddress.city,
        billing_state_province: this.billingAddress.state,
        billing_zip_postal_code: this.billingAddress.postCode,
        billing_country: this.billingAddress.country,

        permanent_street: this.permanentAddress.address,
        permanent_city: this.permanentAddress.city,
        permanent_state_province: this.permanentAddress.state,
        permanent_zip_postal_code: this.permanentAddress.postCode,

        netsuite_product_sku: APPLICATION_FEE_DATA.netsuiteSku,
        product_name: useCapitalizeWords(APPLICATION_FEE_DATA.label),
        price: dollarsToCents(price),

        program_id: program_id,
        program_session_id: program_session_id,
        program_name: program_name,
        session: program_session,
        year: this.year,
        start_date: start_date,
        start_date_is_final: start_date_is_final,
        end_date: end_date,
        end_date_is_final: end_date_is_final,
        program_city: program_city,
        program_country: program_country,
        host_institution: this.hostUniversity,
        program_session_finance_code: this.programSessionFinanceCode,
        contract_number: this.appliedAgreement.contract_number ?? null,
        promo_code: this.promoCode,
        is_application_fee: true,
        is_institution_pays: false,
        is_customized: is_customized,
        participant_id: this.currentUser?.participantId ?? "",
        version: this.currentUI || "v2",
      };
    },
    getDataForPaymentService() {
      const {
        program_id,
        is_customized,
        price,
        program_session_id,
        program_name,
        program_session,
        start_date,
        end_date,
        start_date_is_final,
        end_date_is_final,
        program_city,
        program_country,
      } = this.mapFieldsBasedOnVersion();
      return {
        event_id: "evt_" + uuid.v4(),
        program_session_finance_code: this.programSessionFinanceCode,
        legacy_participant_account_id: this.customer_id,
        program_type: this.programType,
        program_name: program_name,
        program_session: program_session,
        session_country: program_country,
        session_year: this.year,
        source_type: "connect",
        amount_in_cents: dollarsToCents(price),
        application_id:
          this.getApplicationId ?? this.$route?.params?.applicationId,
        resource_id: this.userData._id,
        event_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        event_data: {
          first_name: this.userData.data.firstname,
          last_name: this.userData.data.lastname,
          email: this.userData.data.email,
          phone: this.userData.data.phone,
          participant_type: this.userData.data.student_type,
          birth_date: this.userData.data.birthday,
          home_university_name: this.userData.data?.schoolname?.label,
          home_institution_ipeds: this.ipedsId,
          program_city: program_city,
          program_country: program_country,
          program_id: program_id,
          program_session_id: program_session_id,
          year: this.year,
          start_date: start_date,
          end_date: end_date,
          end_date_is_final: end_date_is_final,
          start_date_is_final: start_date_is_final,
          host_institution: this.hostUniversity,
          current_address: this.currentAddress.address,
          current_city: this.currentAddress.city,
          current_state: this.currentAddress.state,
          current_country: this.currentAddress.country,
          current_post_code: this.currentAddress.postCode,
          permanent_street: this.permanentAddress.address,
          permanent_city: this.permanentAddress.city,
          permanent_state_province: this.permanentAddress.state,
          permanent_country: this.permanentAddress.country,
          permanent_zip_postal_code: this.permanentAddress.postCode,
          cancellation_policy: this.cancellationPolicyCheckbox.policy,
          contract_number: this.appliedAgreement?.contract_number ?? null,
          product_name: useCapitalizeWords(APPLICATION_FEE_DATA.label),
          price: price,
          session: program_session,
          promo_code: this.promoCode,
          is_application_fee: true,
          is_institution_pays: false,
          legacy_participant_account_id: this.customer_id,
          is_customized: is_customized,
          participant_id: this.currentUser?.participantId ?? "",
          application_id:
            this.getApplicationId ?? this.$route?.params?.applicationId,
          billing_state_province: this.billingAddress?.state ?? null,
          billing_city: this.billingAddress?.city ?? null,
          billing_street: this.billingAddress?.address ?? null,
          billing_zip_postal_code: this.billingAddress?.postCode ?? null,
          version: this.currentUI || "v2",
        },
      };
    },
    async updateStatus(newStatus) {
      await axios
        .put(`/api/stripe-charge/${this.applicationId}`, {
          newStatus: newStatus,
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async submitToPaymentService() {
      axios
        .post("/api/submit-to-paymentservice", this.getDataForPaymentService())
        .catch((error) => {
          console.log(error);
        });
    },
    async setIpedsId() {
      // Add zeros at the beginning until the university id is 8 characters long
      // Some universities from Learning service have missing zeros
      const opeId =
        String(this.userData.data?.schoolname?.value).padStart(8, 0) ?? "";

      return getApiClient()
        .get(`/home-university/ipeds/${opeId}`)
        .then((response) => {
          return response.data?.ipeds ?? null;
        })
        .catch(() => {
          return null;
        });
    },
  },
};
