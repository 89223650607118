import { useAsyncState } from "@vueuse/core";
import profileService from "@/services/profile";
import axios from "axios";

export const useGetProfileComments = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return profileService
        .getProfileComments(params)
        .then((response) => response?.data);
    },
    { count: 0, data: [] },
    asyncStateOptions
  );
};
