import { cloneDeep } from "lodash";

export const baseHousingUnitDetail = {
  is_not_valid: false,
  id: undefined,
  housing_id: undefined,
  name: "",
  quantity: undefined,
  sqm: undefined,
  full_bath_count: undefined,
  half_bath_count: undefined,
  water_closet_count: undefined,
  product_line_id: undefined,
  website_url: undefined,
  description: "",
  status: "Draft",
  floor_plan: undefined,
  kitchen_type: undefined,
  unit_amenities: [],
  rooms: [],
  supporting_media: undefined,
};

export const baseHousingUnitRoomDetail = {
  is_not_valid: false,
  is_not_valid_amenities: { Amenities: false, Safety: false },
  id: undefined,
  type: undefined,
  status: "Draft",
  bathroom_type: undefined,
  capacity: 1,
  product_prices: [],
  cost_in_cents: undefined,
  room_amenities: [],
};

export const buildBaseHousingUnitDetail = (housing_id, room_amenities) => {
  let unit = cloneDeep(baseHousingUnitDetail);
  unit.housing_id = housing_id;
  unit.rooms.push(buildBaseHousingUnitRoomDetail(room_amenities));
  return unit;
};

export const buildBaseHousingUnitRoomDetail = (room_amenities) => {
  let room = cloneDeep(baseHousingUnitRoomDetail);
  room.room_amenities = room_amenities;
  room.product_prices = [buildBaseHousingUnitRoomPrice()];
  return room;
};

export const buildBaseHousingUnitRoomPrice = () => {
  return {
    id: null,
    price_in_cents: null,
    effective_start_date: null,
    effective_end_date: null,
  };
};
