import { learningServiceRequest, skip } from "@/services/utils";
import { DEFAULT_LIMIT_PER_PAGE } from "../constants";
import { getApiClient } from "./API";
import _ from "lodash";
import { datadogLogs } from "@datadog/browser-logs";

const clientSideCache = {
  pricing: {},
};

const pricingCacheKey = (payload = {}) => {
  // normalizing payloads so that order of product IDs in arrays does not matter
  let normalized = structuredClone(payload);
  Object.values(normalized).forEach((value) => {
    if (Array.isArray(value)) {
      value.sort();
    }
  });

  return JSON.stringify(normalized);
};

const ordersService = {
  async createOrder({ payload = {} }) {
    // TODO: JUAN-400: Adding logging around order updates, Remove when we know whats wrong
    if (
      payload?.status !== "Draft" &&
      (!payload?.room_ids?.length || payload?.room_ids?.length <= 0)
    ) {
      try {
        datadogLogs.logger.warn(
          "Attempting to create none draft order with no room_ids",
          {
            location: "Create",
            order: payload,
          }
        );
      } catch (e) {
        /* empty */
      }
    }

    return await learningServiceRequest({
      method: "post",
      url: "/orders",
      payload,
    });
  },

  async updateOrder({
    order_id = null,
    payload = {},
    session = false,
    callingLocation = "unknown",
  }) {
    if (!_.isEmpty(payload) && order_id) {
      // TODO: JAUN-400: Adding logging around order creates, Remove when we know whats wrong
      if (payload?.status !== "Draft") {
        try {
          if (!payload?.room_ids?.length || payload?.room_ids?.length <= 0) {
            datadogLogs.logger.warn(
              "Attempting to update room with no room_ids",
              {
                location: callingLocation,
                order: payload,
                session,
              }
            );
          } else if (
            session?.session_events?.length > 0 &&
            (!payload?.event_occurrence_ids?.length ||
              payload?.event_occurrence_ids?.length <= 0)
          ) {
            datadogLogs.logger.warn(
              "Attempting to update order with no excursions",
              {
                location: callingLocation,
                order: payload,
                session,
              }
            );
          }
        } catch (e) {
          /* empty */
        }
      }

      return await learningServiceRequest({
        method: "put",
        url: `/orders/${order_id}`,
        payload,
      });
    }
    return;
  },

  async updateOrderLineItem({
    orderId,
    productId,
    payload = {},
    source,
    skipErrorBus,
  }) {
    if (orderId && productId && !_.isEmpty(payload)) {
      return await learningServiceRequest({
        method: "put",
        url: `/orders/${orderId}/products/${productId}`,
        payload,
        source,
        skipErrorBus,
      });
    }
    return;
  },

  async updateOrderContract({ orderId, payload = {}, source, skipErrorBus }) {
    if (orderId && !_.isEmpty(payload)) {
      return await learningServiceRequest({
        method: "put",
        url: `/orders/${orderId}/billing_contract/`,
        payload,
        source,
        skipErrorBus,
      });
    }
    return;
  },

  async createOrderLineItem({ orderId, payload = {}, source, skipErrorBus }) {
    if (orderId && !_.isEmpty(payload)) {
      return await learningServiceRequest({
        method: "post",
        url: `/orders/${orderId}/products/`,
        payload,
        source,
        skipErrorBus,
      });
    }
    return;
  },

  async list(params = {}) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/orders",
      params,
      failureMessage: "Error loading orders",
    });
    return response?.data?.items ?? [];
  },

  async getOne({ order_id = null, payload = {}, params = {} }) {
    if (order_id) {
      return await learningServiceRequest({
        method: "get",
        url: `/orders/${order_id}`,
        params,
        payload,
      });
    }
    return;
  },

  async confirmOrder({
    orderId = "",
    paymentIntentId = "",
    billingContractId = "",
    usingFinancialAid = false,
  }) {
    await getApiClient().post("/orders/confirm", {
      order_id: orderId,
      payment_intent_id: paymentIntentId,
      billing_contract_id: billingContractId,
      using_financial_aid: usingFinancialAid,
    });
  },

  async getOrderOptionsPricing({ payload = {}, source }) {
    const serviceResponse = await learningServiceRequest({
      method: "post",
      url: "/programming/sessions/pricing",
      payload,
      source,
    });
    return serviceResponse;
  },

  async getOrdersPricing({ payload = {}, source, skipCache = false }) {
    const cacheKey = pricingCacheKey(payload);
    const cacheValue = clientSideCache.pricing[cacheKey];
    if (cacheValue && !skipCache) {
      return cacheValue;
    }
    const serviceResponse = await learningServiceRequest({
      method: "post",
      url: "/orders/pricing",
      payload,
      source,
    });
    clientSideCache.pricing[cacheKey] = serviceResponse;
    const oneHour = 1000 * 60 * 60; // milliseconds
    setInterval(() => {
      delete clientSideCache.pricing[cacheKey];
    }, oneHour);
    return serviceResponse;
  },

  async retrieveOrder(applicationId) {
    //fetch order by application ID proxy thru connect
    return await getApiClient().get(
      `/v3-applications/application/${applicationId}`
    );
  },

  async getOrders({
    limit = DEFAULT_LIMIT_PER_PAGE,
    page = 1,
    q = "",
    extraParams = {},
    failureMessage = "Error loading Orders data",
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/orders",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      source,
      failureMessage,
    });
  },

  async getOrdersLite({
    limit = DEFAULT_LIMIT_PER_PAGE,
    page = 1,
    q = "",
    extraParams = {},
    failureMessage = "Error loading Orders data",
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/orders/lite/fulfillment",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      source,
      failureMessage,
    });
  },
};

export default ordersService;
