<script setup>
import { computed, defineEmits, defineProps } from "vue";
import { PROGRAM_SESSION_TERMS_DISPLAY_MAP } from "@/components/program-manager/sessions/constants.js/";
import {
  formatDate,
  buildStringDateRangeSessionTravel,
  parseSessionStartDate,
  parseSessionEndDate,
} from "@/mixins/helpers";
import { useStore } from "vuex";
import useAppliableContract from "@/composables/useBillings";
import ApplyButton from "@/components/shared/Button/ApplyButton.vue";
import ProgramDates from "@/components/ProgramDates";
import { hideSessionDeadline } from "@/mixins/helpers";
import { formattedPriceFromCentsWithoutCents as formattedPrice } from "@/util/formatter";

const emit = defineEmits(["applyBtnClicked"]);

function onApplyBtnClicked() {
  emit("applyBtnClicked");
}

const props = defineProps({
  session: {
    type: Object,
    default() {
      return {};
    },
  },
  disabledApply: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const billingRules = computed(() => store.state.billingRules);

const hidePrice = computed(() => {
  const contracts = billingRules.value?.agreements ?? [];
  if (contracts.length < 1) {
    return false;
  }

  const applicableContract = useAppliableContract(contracts, props.session);
  return (
    Boolean(applicableContract) &&
    applicableContract?.direct_billing_type !== "Pass through"
  );
});

const sessionType = computed(() => {
  const sessionTypes = props.session?.session_types;
  if (Array.isArray(sessionTypes)) {
    return sessionTypes.map((t) => t.name).join(", ");
  }
  return "Study Abroad";
});

const sessionTerms = computed(() => {
  return props.session?.terms?.map((term) => term.name) ?? [];
});

const getDisplayNameForTerms = computed(() => {
  const normalizedTerms = [...sessionTerms.value].sort();
  const termKey = normalizedTerms.join(" + ");
  return (
    PROGRAM_SESSION_TERMS_DISPLAY_MAP[termKey] || normalizedTerms.join(", ")
  );
});

const customApplyClass = computed(() => {
  const border = " border-university-secondary";
  let classes =
    "box-border flex flex-row justify-center items-center w-auto h-auto max-h-60p max-w-260p border-2 py-3 text-sm";
  classes += props.disabledApply
    ? " cursor-not-allowed bg-gray-275 text-gray-485 border-gray-485"
    : " bg-white text-university-secondary" + border;
  return classes;
});

const startDateIsFinal = computed(() => {
  const startDate = parseSessionStartDate(
    props.session?.session_travel_details
  );
  return !!props.session?.session_travel_details.find(
    (obj) => obj.arrival_date === startDate.toISOString().substring(0, 10)
  )?.departure_date_is_final;
});

const endDateIsFinal = computed(() => {
  const endDate = parseSessionEndDate(props.session?.session_travel_details);
  return !!props.session?.session_travel_details.find(
    (obj) => obj.departure_date === endDate.toISOString().substring(0, 10)
  )?.departure_date_is_final;
});

const pastDeadline = computed(() => {
  const newDate = props.session?.application_deadline + "T00:00";
  const deadline = new Date(newDate);
  const today = new Date();

  deadline.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  return deadline < today;
});
</script>
<template>
  <div
    v-if="!pastDeadline"
    class="font-montserrat not-italic leading-5 bg-white shadow-md vertical-card min-h-[338px]"
    data-testid="program-date-card"
  >
    <div class="flex flex-col max-w-410p">
      <div
        class="flex h-52p max-h-52px justify-between items-center px-6 flex-auto"
        :class="[
          secondaryColor ? `bg-gray-275` : `bg-university-accentPrimary`,
        ]"
      >
        <div
          :id="`card-title-${session?.id}`"
          class="font-bold text-sm text-indigo-base uppercase"
        >
          {{ sessionType }}
        </div>
      </div>
      <div
        id="api-card-sub-header"
        class="flex flex-col bg-gray-100 justify-start px-6"
      >
        <p
          v-if="sessionTerms"
          :id="`card-session-term-${session?.id}`"
          class="font-montserrat font-medium text-sm text-indigo-base text-opacity-70 mt-4"
        >
          {{ getDisplayNameForTerms }}
        </p>
        <p
          v-if="session?.session_travel_details"
          :id="`api-session-dates${session?.id}`"
          :class="sessionTerms ? '' : 'mt-4'"
        >
          <ProgramDates
            :id="`card-session-dates-${props.session?.id}`"
            :key="`card-session-dates-${props.session?.id}`"
            :dates="
              buildStringDateRangeSessionTravel(
                session?.session_travel_details,
                {
                  academic_start_date: session?.academic_start_date,
                  academic_end_date: session?.academic_end_date,
                }
              )
            "
            :start-is-final="startDateIsFinal"
            :end-is-final="endDateIsFinal"
            tag="p"
            class="text-gray-600"
            :class="session.base_price_in_cents ? '' : 'mb-10'"
            reverse-icon-order
            experience-card-icon
            text-class="font-montserrat uppercase font-bold text-indigo-base text-base"
          />
        </p>
        <p
          v-if="session.base_price_in_cents && !hidePrice"
          :id="`card-program-price-${session?.id}`"
          class="font-bold uppercase text-base my-4"
          :class="[
            secondaryColor ? `${secondaryColorClass}` : `text-university-secondary`,
          ]"
        >
          Starting at {{ formattedPrice(session?.base_price_in_cents) }}
        </p>
      </div>
    </div>
    <div
      class="flex flex-col justify-between !text-indigo-base !font-montserrat"
    >
      <div id="api-card-description" class="pt-3.5 px-6">
        <div
          v-if="session?.application_deadline && !hideSessionDeadline(session)"
          :id="`api-application-deadline-${session?.id}`"
          class="flex justify-start items-center mb-4"
        >
          <img
            src="/images/icon-calendar.svg"
            class="object-contain"
            alt="calendar icon"
            width="24"
            height="24"
          />
          <div class="flex flex-col pl-2">
            <span class="text-sm">Application Deadline:</span>
            <span class="font-bold text-base text-indigo-base uppercase">
              {{ formatDate(session.application_deadline) }}
            </span>
          </div>
        </div>
        <div
          :id="`api-application-deadline-${session?.id}`"
          class="flex justify-start items-center"
        >
          <img
            src="/images/icon-free_cancellation_calendar.svg"
            class="object-contain"
            alt="payment calendar icon"
            width="24"
            height="24"
          />
          <div class="flex flex-col pl-2">
            <span class="text-sm">Payment Deadline:</span>
            <span class="italic text-sm text-indigo-base">
              Final payment is due two weeks after confirming your attendance.
            </span>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center w-full mt-10 mb-6">
        <ApplyButton
          :id="`card-apply-btn-${session?.id}`"
          type="button"
          :outlined="true"
          class="py-5 text-sm"
          :class="customApplyClass"
          :disabled="disabledApply"
          event-category="Program Page Personalize CTA"
          @click="onApplyBtnClicked"
        />
      </div>
    </div>
  </div>
</template>
