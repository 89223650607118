<template>
  <svg
    width="47"
    height="47"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_5035_47201)">
      <ellipse
        cx="23.345"
        cy="23.7996"
        rx="19.3425"
        ry="19.2004"
        transform="rotate(-180 23.345 23.7996)"
        fill="#1E264C"
      />
      <path
        d="M4.75257 23.7996C4.75257 13.6149 13.0715 5.34922 23.345 5.34922C33.6186 5.34922 41.9375 13.6149 41.9375 23.7996C41.9375 33.9843 33.6186 42.25 23.345 42.25C13.0715 42.25 4.75257 33.9843 4.75257 23.7996Z"
        stroke="#1E264C"
        stroke-width="1.5"
      />
    </g>
    <path
      d="M27.2148 13.9502L18.5107 23.6751L27.2148 33.4"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <filter
        id="filter0_d_5035_47201"
        x="0.00390625"
        y="0.599609"
        width="46.6836"
        height="46.4004"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5035_47201"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5035_47201"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
