<template>
  <Spinner v-if="state.loading" class="relative h-65vh bg-blue-100" />
  <component
    :is="state.currentComponent"
    :excursions="ruleFilteredEvents"
    :selected-excursions="selectedExcursions"
    :detail-excursion="state.detailExcursion"
    :disabled="disabled"
    :default-excursion="default_excursion"
  />
</template>

<script setup>
import {
  computed,
  inject,
  markRaw,
  provide,
  reactive,
  readonly,
  watch,
} from "vue";
import ExcursionStepListing from "./ExcursionStepListing.vue";
import ExcursionStepDetail from "./ExcursionStepDetail.vue";
import Spinner from "@/components/helpers/Spinner.vue";

const { program } = inject("program");
const {
  excursions,
  addRegistration,
  removeRegistration,
  addMultipleRegistrations,
} = inject("excursions");
const excursionRules = inject("excursionRules");

const state = reactive({
  loading: false,
  excursions: [],
  currentComponent: markRaw(ExcursionStepListing),
  detailExcursion: undefined,
});

const selectedExcursions = computed(() => excursions.eventInstanceIds);

const defaultSelections = computed(() => {
  const { andIds, defaultIds } = excursionRules.value;
  return andIds.concat(defaultIds);
});

const viewDetails = (excursion) => {
  state.detailExcursion = excursion;
  state.currentComponent = markRaw(ExcursionStepDetail);
};

const selectExcursion = (id) => {
  if (selectedExcursions.value.includes(id)) {
    removeRegistration(id);
  } else {
    addRegistration(id);
  }
};

const toSelectEvents = () => {
  state.currentComponent = markRaw(ExcursionStepListing);
};

provide("viewDetails", viewDetails);
provide("selectExcursion", selectExcursion);
provide("toSelectEvents", toSelectEvents);

const ruleFilteredEvents = computed(() => {
  const and = state.excursions.filter((item) =>
    excursionRules.value.andIds?.includes(item.id)
  );
  const or = state.excursions.filter((item) =>
    excursionRules.value.orIds?.includes(item.id)
  );
  const setFilter = new Set();
  return [...and, ...or].filter((item) => {
    const duplicatedItem = setFilter.has(item.id);
    setFilter.add(item.id);
    return !duplicatedItem;
  });
});

const disabled = computed(() => {
  let allRequired = true;
  let oneRequired = true;

  if (excursionRules.value.andIds.length)
    allRequired = excursionRules.value.andIds.every((id) =>
      selectedExcursions.value.includes(id)
    );

  if (excursionRules.value.orIds.length)
    oneRequired = selectedExcursions.value.some((id) =>
      excursionRules.value.orIds.includes(id)
    );
  return Boolean(!allRequired || !oneRequired);
});

const default_event_id = computed(() => {
  // MVP: Use the first selection rule found
  return program.programSession?.session_event_selection_rules?.[0]
    ?.default_event_id;
  // Post - MVP: there could be multiple default ids
});

const default_excursion = computed(() => {
  // MVP: Only one event is default
  if ([null, undefined, "undefined"].includes(default_event_id.value))
    return undefined;
  return (
    state?.excursions?.find(
      (excursion) => excursion.id === default_event_id.value
    )?.event ?? undefined
  );
  // Post-MVP: There could be multiple default events
});

watch(
  () => excursions.loading,
  () => {
    state.loading = excursions.loading;
    state.excursions = excursions.preloaded;
  },
  {
    immediate: true,
    deep: true,
  }
);
watch(
  excursionRules,
  () => {
    addMultipleRegistrations(defaultSelections.value);
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>
