export const getInternshipRedirectUrl = (program) => {
  const programPageId = program?.program_page?.id;
  const sessionId = program?.session?.id;
  const internshipId = program?.internship?.id;
  return {
    path: "/results/internships",
    query: {
      "internship-id": internshipId,
      "program-id": programPageId,
      "session-id": sessionId,
    },
  };
};
