<template>
  <div>
    <div>
      <Teleport to="title">
        <h2
          class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
        >
          Start your experience of a lifetime by completing the details below.
        </h2>
      </Teleport>
      <div class="grid grid-cols-1 gap-4 sm:gap-6">
        <form
          id="create-lead"
          class="grid grid-cols-1 gap-4 account-creation-form md:grid-cols-2 sm:gap-6"
          action=""
          aria-label="Create Account"
          @submit.prevent="submitLead()"
        >
          <label for="firstName" class="block text-sm">
            <span class="flex justify-between mb-2 font-semibold text-gray-600">
              <span>First name</span>
            </span>
            <input
              id="firstName"
              v-model.trim="v$.value.firstName.$model"
              type="text"
              class="block w-full form-input min-h-10"
              :class="{ 'bg-error-100': v$.value.firstName.$error }"
            />
            <span
              v-if="
                v$.value.firstName.required.$invalid &&
                v$.value.firstName.$error
              "
              class="block error text-error-900"
            >
              Field is required
            </span>
            <span
              v-if="v$.value.firstName.minLength.$invalid"
              class="block error text-error-900"
            >
              First name must have at least
              {{ v$.value.firstName.minLength.$params.min }} characters.
            </span>
            <span
              v-if="v$.value.firstName.maxLength.$invalid"
              class="block error text-error-900"
            >
              First name must have no more than
              {{ v$.value.firstName.maxLength.$params.max }} characters.
            </span>
            <span
              v-if="v$.value.firstName.nameRegex.$invalid"
              class="block error text-error-900"
            >
              Field does not match the pattern
            </span>
          </label>
          <label for="lastName" class="block text-sm">
            <span class="flex justify-between mb-2 font-semibold text-gray-600">
              <span>Last name</span>
            </span>
            <input
              id="lastName"
              v-model.trim="v$.value.lastName.$model"
              type="text"
              class="block w-full form-input min-h-10"
              :class="{ 'bg-error-100': v$.value.lastName.$error }"
            />
            <span
              v-if="
                v$.value.lastName.required.$invalid && v$.value.lastName.$error
              "
              class="block error text-error-900"
            >
              Field is required
            </span>
            <span
              v-if="v$.value.lastName.minLength.$invalid"
              class="block error text-error-900"
            >
              Last name must have at least
              {{ v$.value.lastName.minLength.$params.min }} characters.
            </span>
            <span
              v-if="v$.value.lastName.maxLength.$invalid"
              class="block error text-error-900"
            >
              Last name must have no more than
              {{ v$.value.lastName.maxLength.$params.max }} characters.
            </span>
            <span
              v-if="v$.value.lastName.nameRegex.$invalid"
              class="block error text-error-900"
            >
              Field does not match the pattern
            </span>
          </label>
          <label for="email" class="block text-sm md:col-span-2">
            <span class="flex justify-between mb-2 font-semibold text-gray-600">
              <span>Email</span>
            </span>
            <input
              id="email"
              v-model.trim="v$.value.email.$model"
              type="text"
              class="block w-full lowercase form-input min-h-10"
              :class="{ 'bg-error-100': v$.value.email.$error }"
            />
            <span
              v-if="v$.value.email.required.$invalid && v$.value.email.$error"
              class="block error text-error-900"
            >
              Field is required
            </span>
            <span
              v-if="v$.value.email.email.$invalid"
              class="block error text-error-900"
            >
              Email must be a valid email
            </span>
            <span
              v-if="v$.value.email.maxLength.$invalid"
              class="block error text-error-900"
            >
              Email must have no more than
              {{ v$.value.email.maxLength.$params.max }} characters.
            </span>
          </label>
          <div class="grid grid-cols-3 gap-x-4 gap-y-2">
            <label for="phoneCode" class="block text-sm">
              <span class="inline-flex justify-between mb-2 text-gray-600">
                <span class="mr-1 font-semibold">Phone</span>
              </span>
              <input
                id="phoneCode"
                v-model.trim="v$.value.phoneCode.$model"
                type="text"
                class="block w-full form-input min-h-10"
                :class="{ 'bg-error-100': v$.value.phoneCode.$error }"
              />
              <span
                v-if="
                  v$.value.phoneCode.required.$invalid &&
                  v$.value.phoneCode.$error
                "
                class="block error text-error-900"
              >
                Field is required
              </span>
              <span
                v-if="v$.value.phoneCode.minLength.$invalid"
                class="block error text-error-900"
              >
                Country code must have at least
                {{ v$.value.phoneCode.minLength.$params.min }} characters.
              </span>
              <span
                v-if="v$.value.phoneCode.maxLength.$invalid"
                class="block error text-error-900"
              >
                Country code must have no more than
                {{ v$.value.phoneCode.maxLength.$params.max }} characters.
              </span>
              <span
                v-if="v$.value.phoneCode.numeric.$invalid"
                class="block error text-error-900"
              >
                Only numbers allowed
              </span>
            </label>
            <label for="phone" class="block col-span-2 text-sm pt-7">
              <input
                id="phone"
                v-model.trim="v$.value.phone.$model"
                type="text"
                class="block w-full form-input min-h-10"
                :class="{ 'bg-error-100': v$.value.phone.$error }"
              />
              <span
                v-if="v$.value.phone.required.$invalid && v$.value.phone.$error"
                class="block error text-error-900"
              >
                Field is required
              </span>
              <span
                v-if="v$.value.phone.maxLength.$invalid"
                class="block error text-error-900"
              >
                Phone must have no more than
                {{ v$.value.phone.maxLength.$params.max }} characters.
              </span>
              <span
                v-if="v$.value.phone.numeric.$invalid"
                class="block error text-error-900"
              >
                Only numbers allowed
              </span>
            </label>
            <span class="col-span-3 text-xs text-gray-600">
              Phone includes country code (not including "+") and number
            </span>
          </div>
          <Datepicker
            label-name="Birthday"
            :date="v$.value.birthday.$model"
            :required="true"
            :min-year="1950"
            @updateDate="updateDate($event)"
          />
          <label for="citizenship" class="block text-sm">
            <span class="inline-flex justify-between mb-2 text-gray-600">
              <span class="mr-1 font-semibold">Citizenship</span>
            </span>
            <VSelect
              id="citizenship"
              v-model="v$.value.citizenship.$model"
              :value="v$.value.citizenship.$model"
              :reduce="(country) => country.value"
              :options="countries"
              aria-label="Country"
              :class="{ 'bg-error-100': v$.value.citizenship.$error }"
              @search="fetchCountries"
            ></VSelect>
            <span
              v-if="
                v$.value.citizenship.required.$invalid &&
                v$.value.citizenship.$error
              "
              class="block error text-error-900"
            >
              Field is required
            </span>
          </label>
          <label for="gender" class="block text-sm">
            <span class="flex justify-between mb-2 font-semibold text-gray-600">
              <span>Gender</span>
            </span>
            <select
              id="gender"
              v-model.trim="v$.value.gender.$model"
              class="block w-full text-sm form-select min-h-10"
              :class="{ 'bg-error-100': v$.value.gender.$error }"
            >
              <option value="" selected disabled />
              <option
                v-for="gender in GENDERS"
                :key="gender.value"
                :value="gender.value"
              >
                {{ gender.label }}
              </option>
            </select>
            <span
              v-if="v$.value.gender.required.$invalid && v$.value.gender.$error"
              class="block error text-error-900"
            >
              Field is required
            </span>
          </label>
          <label for="major" class="block text-sm">
            <span class="flex justify-between mb-2 font-semibold text-gray-600">
              <span>Major</span>
            </span>
            <VSelect
              id="major"
              v-model="v$.value.major.$model"
              :value="v$.value.major.$model"
              :options="MAJORS"
              :get-option-label="(option) => option.label"
              :reduce="(major) => major.value"
              aria-label="Majors"
              :class="{ 'bg-error-100': v$.value.major.$error }"
              :filter="searchMajor"
            ></VSelect>
          </label>
          <fieldset class="md:col-span-2">
            <legend class="font-semibold text-gray-600">Program Type</legend>
            <div class="mt-2 md:flex">
              <label
                v-for="programType in PROGRAM_TYPES"
                :key="programType.value"
                class="flex mb-4 md:mb-0 md:flex-auto"
                :for="programType.value"
              >
                <input
                  :id="programType.value"
                  v-model="v$.value.programSelection.experienceType.$model"
                  type="radio"
                  :value="programType.value"
                  class="mt-1 form-radio"
                  :class="[
                    {
                      'bg-error-100':
                        v$.value.programSelection.experienceType.$error,
                    },
                    `${primaryColorClass}`,
                  ]"
                />
                <span class="ml-2">{{ programType.label }}</span>
              </label>
            </div>
            <div
              v-if="
                v$.value.programSelection.experienceType.required.$invalid &&
                v$.value.programSelection.experienceType.$error
              "
              class="text-sm error text-error-900"
            >
              Field is required
            </div>
          </fieldset>
          <label
            v-show="value.programSelection.experienceType == 'abroad'"
            for="location"
            class="block text-sm"
          >
            <span class="flex justify-between mb-2 font-semibold text-gray-600">
              <span>Where would the student like to go?</span>
            </span>
            <VSelect
              id="location"
              v-model="v$.value.programSelection.location.$model"
              :options="sites"
              :get-option-label="(option) => option.label"
              aria-label="Location"
              :class="{
                'bg-error-100': v$.value.programSelection.location.$error,
              }"
              @search="searchSites"
            ></VSelect>
          </label>
          <label
            v-show="value.programSelection.experienceType"
            for="semester"
            class="block text-sm"
          >
            <span class="flex justify-between mb-2 font-semibold text-gray-600">
              <span>When would the student like to study?</span>
            </span>
            <VSelect
              id="semester"
              v-model="v$.value.programSelection.semester.$model"
              :value="v$.value.programSelection.semester.$model"
              :options="semesters"
              :get-option-label="(option) => option.label"
              :reduce="(semester) => semester.value"
              aria-label="Semester"
              :class="{
                'bg-error-100': v$.value.programSelection.semester.$error,
              }"
              @search="searchSemesters"
            ></VSelect>
          </label>
          <fieldset
            v-show="value.programSelection.semester"
            class="md:col-span-2"
          >
            <legend class="font-semibold text-gray-600">Program Session</legend>
            <div class="mt-2 md:flex flex-col">
              <label
                v-for="program in availablePrograms"
                :key="program.value"
                class="flex mb-4 md:mb-0 md:flex-auto"
                :for="program.value"
              >
                <input
                  :id="program.value"
                  v-model="v$.value.programSelection.programSession.$model"
                  type="radio"
                  :value="program.value"
                  class="mt-1 form-radio"
                  :class="[
                    {
                      'bg-error-100':
                        v$.value.programSelection.programSession.$error,
                    },
                    `${primaryColorClass}`,
                  ]"
                  data-cy="programSession"
                />
                <span class="ml-2">{{ program.label }}</span>
              </label>
            </div>
            <div
              v-if="
                v$.value.programSelection.programSession.required.$invalid &&
                v$.value.programSelection.programSession.$error
              "
              class="text-sm error text-error-900"
            >
              Field is required
            </div>
          </fieldset>

          <div class="mt-6 md:col-span-2">
            <button
              id="submit"
              type="submit"
              :disabled="v$.$invalid || disabledButton"
              :class="[
                tertiaryColor
                  ? `${tertiaryColorClass}`
                  : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
              ]"
              class="block w-full px-4 font-semibold text-center text-gray-700 border-2 border-transparent rounded min-h-10"
            >
              <span>Add Student</span>
            </button>
            <div v-if="sendSubmissionError" class="mt-2 error text-error-900">
              {{ sendSubmissionError }}
            </div>
            <div v-if="salesforceError" class="mt-2 error text-error-900">
              An account already exists with the information you provided.
              Please try again. If you continue to see an issue, please contact
              <a
                :class="[
                  secondaryColor
                    ? `${secondaryColorClass}`
                    : `${secondaryColorClass} focus:text-teal-900 hover:text-teal-900`,
                ]"
                class="font-semibold focus:underline"
                :href="`mailto:productsupport${APICompany.companyEmail}`"
              >
                productsupport@apiexperience.com
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  email,
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
} from "@vuelidate/validators";
import Datepicker from "@/components/forms/SharedComponents/Datepicker.vue";
import { GENDERS } from "@/constants.js";
import countryListApi from "@/mixins/countryListApi.js";
import sitesListApi from "@/mixins/sitesListApi";
import semestersListApi from "@/mixins/semestersListApi";
import majorsList from "@/mixins/majorsList";
import formioApi from "@/mixins/formIoApi";
import { eventBus } from "@/app";
import { tokenValidationMixin } from "@/mixins/tokenValidationMixin.js";
import useVuelidate from "@vuelidate/core";
import { APICompany } from "../../../constants.js";
import { mapState } from "vuex";

const generator = require("generate-password");
const nameRegex = helpers.regex(/^[A-z-' ]*$/);

export default {
  name: "ProspectiveStudent",
  components: {
    Datepicker,
  },
  mixins: [
    sitesListApi,
    semestersListApi,
    countryListApi,
    majorsList,
    formioApi,
    tokenValidationMixin,
  ],
  props: {
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["dateChange", "close"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      location: {},
      value: {
        firstName: "",
        lastName: "",
        email: "",
        phoneCode: "",
        phone: "",
        birthday: "",
        citizenship: "United States of America",
        gender: "",
        university: null,
        major: "",
        programSelection: {
          application_id: "",
          location: {},
          semester: "",
          programSession: "",
          experienceType: "",
          uiVersion: "v3",
        },
      },
      sendSubmissionError: "",
      salesforceError: false,
      disabledButton: false,
      GENDERS: [],
      PROGRAM_TYPES: [
        {
          value: "virtual",
          label: "Virtual",
        },
        {
          value: "abroad",
          label: "Abroad",
        },
      ],
      availablePrograms: [],
      APICompany,
    };
  },
  validations: {
    value: {
      firstName: {
        required,
        nameRegex,
        minLength: minLength(3),
        maxLength: maxLength(32),
      },
      lastName: {
        required,
        nameRegex,
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
      email: {
        required,
        maxLength: maxLength(120),
        email,
      },
      phoneCode: {
        required,
        numeric,
        minLength: minLength(1),
        maxLength: maxLength(10),
      },
      phone: {
        required,
        numeric,
        maxLength: maxLength(20),
      },
      birthday: { required },
      citizenship: { required },
      gender: { required },
      major: {},
      programSelection: {
        application_id: "",
        location: {},
        semester: "",
        programSession: {
          required,
        },
        experienceType: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapState(["featureFlags"]),
    randomPassword() {
      return generator.generate({
        length: 16,
        numbers: true,
        strict: true,
      });
    },
    school() {
      return {
        value: this.$store.state.university.userDetails.universityId,
        label: this.$store.state.university.userDetails.university,
      };
    },
    studentURL() {
      return "student";
    },
    selectedExperienceType() {
      return this.value.programSelection.experienceType;
    },
    selectedLocation() {
      return this.value.programSelection.location.value;
    },
    selectedSemester() {
      return this.value.programSelection.semester;
    },
    selectedProgram() {
      return this.value.programSelection.programSession;
    },
  },
  watch: {
    selectedExperienceType: function (newType) {
      if (newType === "virtual") {
        this.value.programSelection.location = this.getVirtualLocationId();
      } else {
        this.value.programSelection.location = {};
      }
      this.availablePrograms = [];
    },
    selectedLocation: function (newLocation) {
      this.location = this.value.programSelection.location;
      this.value.programSelection.semester = "";
      if (newLocation) {
        this.initSemestersList();
      } else {
        // If no location is selected, clear the semesters list.
        this.semesters = [];
      }
      this.availablePrograms = [];
    },
    selectedSemester: function (newSemester) {
      this.value.programSelection.programSession = "";
      if (newSemester) {
        this.handleSemesterChange(newSemester);
      } else {
        this.availablePrograms = [];
      }
    },
    selectedProgram: function () {
      this.value.programSelection.application_id = this.getApplicationId();
    },
  },
  async mounted() {
    let validToken = await this.isTokenValid();
    if (!validToken) this.logout();
  },
  created() {
    this.GENDERS = GENDERS;
  },
  methods: {
    submitLead() {
      this.sendSubmissionError = "";
      this.salesforceError = false;
      this.v$.$touch();
      eventBus.$emit("dateChange");

      if (!this.v$.$invalid) {
        this.salesforceValidations().then((exists) => {
          this.salesforceError = exists;
          if (!exists) this.submitForm();
        });
      }
    },
    async salesforceValidations() {
      const nameFilter =
        "&data.firstname=" +
        encodeURIComponent(this.value.firstName) +
        "&data.lastname=" +
        encodeURIComponent(this.value.lastName);
      let exists = false;
      let validations = [
        "data.email=" + encodeURIComponent(this.value.email.toLowerCase()),
        "data.email=" +
          encodeURIComponent(this.value.email.toLowerCase()) +
          nameFilter,
        "data.birthday=" + encodeURIComponent(this.value.birthday) + nameFilter,
        "data.phone=" +
          encodeURIComponent(this.value.phoneCode + this.value.phone) +
          nameFilter,
      ];

      for (const validation of validations) {
        let existsByQuery = await this.formioSubmissionExistsWithCustomParams(
          this.studentURL,
          validation
        );

        if (existsByQuery) {
          exists = true;
          break;
        }
      }

      return exists;
    },
    async submitForm() {
      this.disabledButton = true;
      try {
        const response = await fetch("/register-prospective", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.head.querySelector(
              "[name=csrf-token][content]"
            ).content,
          },
          mode: "cors",
          body: JSON.stringify(this.getFormioObject()),
        });

        if (response.status === 201) {
          this.$emit("close");
        } else {
          this.disabledButton = false;
          this.sendSubmissionError =
            "There was an error creating the account. Please try again, or contact support.";
        }
      } catch (e) {
        console.log(e);
        let defaultError =
          "There was an error creating the account. Please try again, or contact support.";
        this.disabledButton = false;
        if (e.response) {
          if (e.response.status === 500) {
            this.sendSubmissionError = defaultError;
          } else {
            Object.values(e.response.data.errors).forEach((field) => {
              field.forEach((error) => {
                this.sendSubmissionError += error + "\n";
              });
            });
          }
        } else {
          this.sendSubmissionError = defaultError;
        }
      }
      this.disabledButton = false;
    },
    getFormioObject() {
      return {
        email: this.value.email.toLowerCase(),
        password: this.randomPassword,
        first_name: this.value.firstName,
        last_name: this.value.lastName,
        countryCode: this.value.phoneCode,
        phone: this.value.phoneCode + this.value.phone,
        birthdate: this.value.birthday,
        citizenship: this.value.citizenship,
        dual_citizenship: false,
        student_type: "college",
        gender: this.value.gender,
        school_name: this.school,
        major: this.value.major,
        programSelection: this.value.programSelection,
      };
    },
    getApplicationId() {
      if (!this.value.programSelection.programSession) {
        return "";
      }
      let appId =
        Date.now() +
        this.value.programSelection.programSession +
        Math.random().toString().substring(3);
      appId = appId.substring(0, 32);
      return appId;
    },
    getVirtualLocationId() {
      return this.sites.find(
        (location) => location.label === "Virtual, Virtual"
      );
    },
    handleLocationChange(location) {
      let searchLocation =
        typeof location === "object" ? location.value : location;

      this.selectedLocation = searchLocation;

      if (searchLocation) {
        fetch(
          this.trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) +
            "terms/" +
            searchLocation,
          {
            headers: { "content-type": "application/json" },
            mode: "cors",
          }
        ).then((response) => {
          response.json().then((result) => {
            console.log(result);
          });
        });
      }
    },
    handleSemesterChange(semester) {
      fetch(
        this.trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) +
          "programs/" +
          this.selectedLocation +
          "/" +
          semester.toLowerCase(),
        {
          headers: { "content-type": "application/json" },
          mode: "cors",
        }
      ).then((response) => {
        response.json().then((result) => {
          let programs = [];
          for (let key in result) {
            programs.push({
              label: result[key]["name"],
              value: result[key]["id"],
              shortcut: "",
            });
          }
          this.availablePrograms = programs;
        });
      });
    },
    updateDate(date) {
      this.v$.value.birthday.$model = date;
    },
  },
};
</script>
<style scoped>
"lg:grid-cols-12" {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.col-span-6 {
  grid-column: span 6 / span 6;
}

"lg:mt-6" {
  margin-top: 1.5rem;
}
</style>
