export const formSettings = {
  capacity: {
    required: true,
    type: "number",
    copy: "How many positions are open for this internship?",
    name: "capacity",
    depends_on: ["whenStarts"],
  },
  status: {
    required: true,
    type: "single_select",
    labelInput: "value",
    options: [
      {
        name: "Active",
        value: "Active",
      },
      {
        name: "Inactive",
        value: "Inactive",
      },
      {
        name: "Archived",
        value: "Archived",
      },
      {
        name: "Draft",
        value: "Draft",
      },
    ],
    copy: "Status",
    name: "status",
    active: true,
  },
  industries: {
    required: true,
    type: "searchable_select",
    labelInput: "career_pathway",
    options: [],
    copy:
      "What industry or industries does this company most closely align to?",
    name: "industries_name",
    active: true,
  },
  company: {
    required: true,
    type: "single_select",
    labelInput: "name",
    options: [],
    copy: "Company Name",
    name: "company_name",
    active: true,
  },
  projectTitle: {
    required: true,
    type: "text",
    copy: "Position/Project Title",
    name: "project_title",
    active: true,
  },
  aboutCompany: {
    required: false,
    type: "long_text",
    editable: false,
    copy: "About the Company",
    active: true,
    name: "about_company",
    depends_on: ["companyName"],
  },
  companySize: {
    required: false,
    type: "text",
    editable: false,
    copy: "Company Size",
    active: true,
    name: "company_size",
    depends_on: ["companyName"],
  },
  placementType: {
    required: true,
    type: "searchable_select",
    copy: "Placement Type",
    active: true,
    name: "placement_type",
    labelInput: "value",
    options: [],
  },
  headquarterLocation: {
    required: false,
    type: "text",
    editable: false,
    copy: "Headquarter Location",
    active: true,
    name: "headquarter_location",
    depends_on: ["companyName"],
  },
  workLocationDescription: {
    required: true,
    type: "searchable_select",
    labelInput: "value",
    options: [],
    copy: "What best describes the work location?",
    active: true,
    name: "work_location_description",
  },
  workLocationStreetAddress: {
    required: true,
    type: "text",
    depends_on: ["workLocationDescription"],
    copy: "What is the street address for this work location?",
    active: true,
    name: "work_location_street_address",
  },
  positionTitle: {
    required: true,
    type: "text",
    copy: "Position Title",
    active: true,
    name: "position_title",
  },
  positionDescription: {
    required: true,
    type: "text",
    copy: "Position Responsibilities/Description",
    active: true,
    name: "position_description",
  },
  whenStarts: {
    required: true,
    type: "searchable_select",
    options: [],
    copy: "Terms",
    active: true,
    name: "terms",
    labelInput: "name_desc",
    selectClass: "border border-indigo-base bg-white rounded-none",
  },
  scheduleType: {
    required: true,
    type: "radio",
    options: ["Weekly Recurring", "Other"],
    copy: "What is the schedule for this internship?",
    name: "schedule_type",
    active: true,
    labelInput: "value",
  },
  ifOtherWhatSchedule: {
    required: true,
    type: "text",
    copy:
      "If Other is selected for schedule type, please describe the schedule.",
    name: "if_other_what_schedule",
    active: false,
    depends_on: ["scheduleType"],
  },
  academicLevel: {
    required: true,
    type: "searchable_select",
    labelInput: "academic_year",
    options: [],
    copy: "What academic level(s) would be a good fit for this internship?",
    name: "academic_level",
    active: true,
  },
  otherLanguageRequirement: {
    required: true,
    type: "radio",
    options: ["Yes", "No"],
    copy: "Is the student required to speak a language other than English?",
    active: true,
    name: "other_language_requirements",
  },
  languagesRequired: {
    required: true, //required if
    type: "searchable_select",
    options: [],
    labelInput: "value",
    copy: "What language(s) is the student required to speak?",
    active: true,
    name: "languages_required",
    depends_on: ["otherLanguageRequirement"],
  },
  languageAndProficiency: {
    required: true, //required if
    active: true,
    type: "single_select",
    labelInput: "value",
    options: [
      {
        id: "b8dcb08b-bc0b-414f-b904-7bcb601925c4",
        value: "Native",
      },
      {
        id: "1c487d7e-9139-41df-bf83-a2f0cd2a1ef5",
        value: "Advanced",
      },
      {
        id: "8508fa0c-403f-4f10-beb3-9f267e2b04bb",
        value: "Intermediate",
      },
      {
        id: "a6db9e92-6dc0-4164-aa7b-19f75726fb87",
        value: "Beginner",
      },
      {
        id: "e242f827-8564-45dc-94e9-abfbe91e0aae",
        value: "No Background",
      },
    ],
    name: "language_and_proficiency",
    depends_on: ["otherLanguageRequirement"],
  },
  careerAreas: {
    required: true, //required if
    type: "searchable_select",
    options: [],
    copy:
      "What career area(s) would be a good fit for a candidate interested in this position?",
    active: true,
    labelInput: "soc_title",
    name: "career_areas",
    selectClass: "border border-indigo-base bg-white rounded-none",
  },
  countries: {
    type: "searchable_select",
    labelInput: "label",
    labelId: "value",
    options: [],
    active: true,
    proxy: "/api/search-filter/countries",
    selectClass: "border border-indigo-base bg-white rounded-none",
  },
  cities: {
    type: "searchable_select",
    options: [],
    active: true,
    labelInput: "city",
    labelId: "id",
    name: "cities",
    selectClass: "border border-indigo-base bg-white rounded-none",
  },
  majorFit: {
    required: false,
    type: "searchable_select",
    options: [],
    labelInput: "cip_title",
    copy: "What major(s) are a good fit for this internship?",
    active: true,
    name: "major_fit",
    labelId: "cip_code",
    selectClass: "border border-indigo-base bg-white rounded-none",
  },
  skillSubcategories: {
    required: true,
    type: "searchable_select",
    options: [],
    labelInput: "value",
    copy:
      "What types of skills are associated with this position? (Select up to 5)",
    active: true,
    name: "skill_subcategories",
    maxSelected: 5,
    labelId: "id",
  },
  requiredSkills: {
    required: true,
    type: "searchable_select",
    options: [],
    labelInput: "value",
    copy: "What are required skills for this position?",
    active: true,
    name: "required_skills",
  },
  enhancedSkills: {
    required: true,
    type: "searchable_select",
    options: [],
    labelInput: "value",
    copy: "This position enhances the following skills:",
    active: true,
    name: "enhanced_skills",
  },
  perksAssociated: {
    required: false,
    type: "searchable_select",
    options: [],
    labelInput: "value",
    copy: "Are there any perks associated with this position?",
    active: true,
    name: "perks_associated",
  },
  marketplace: {
    required: false,
    type: "checkbox",
    copy: "is this internship global?",
    active: true,
    name: "marketplace",
  },
  internshipPayed: {
    required: true,
    type: "radio",
    options: ["Paid", "Unpaid"],
    copy: "Is this internship paid or unpaid?",
    active: true,
    name: "internship_payed",
  },

  interviewRequired: {
    required: true,
    type: "radio",
    options: ["Yes", "No"],
    copy: "Is an interview required?",
    active: true,
    name: "interview_required",
  },
  duration: {
    required: true,
    type: "single_select",
    copy: "How long will this internship be?",
    name: "duration",
    labelInput: "name",
    options: [...Array(53).keys()].slice(1).map((number) => {
      return {
        name: `${number}`,
        value: number,
      };
    }),
    depends_on: ["whenStarts"],
  },
  minimumAge: {
    active: true,
    required: false,
    type: "number",
    copy: "Min:",
    name: "minimum_age",
  },
  maximumAge: {
    active: true,
    required: false,
    type: "number",
    copy: "Max:",
    name: "maximum_age",
  },
  scheduleDuration: {
    required: true,
    type: "radio",
    options: ["Full time (30-40/week)", "Part time (less than 30 hours/week)"],
    copy: "Specify the schedule as Full or Part time",
    active: true,
    name: "schedule_duration",
    depends_on: ["scheduleType"],
  },
  eligibleProgramType: {
    required: true,
    type: "searchable_select",
    options: [],
    copy: "What program types are eligible?",
    active: true,
    name: "eligible_program_type",
    labelInput: "name",
  },
  termSession: {
    required: true,
    copy: "When will this internship be?"
  }
};
