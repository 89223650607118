<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 -960 960 960"
    width="48"
    fill="#1E264C"
  >
    <path
      d="M88.847-140.001q-12.259 0-20.552-8.294Q60-156.588 60-168.848v-622.304q0-12.26 8.294-20.553 8.293-8.294 20.552-8.294h463.844q12.26 0 20.553 8.294 8.293 8.293 8.293 20.553v622.304q0 12.26-8.293 20.553-8.293 8.294-20.553 8.294H381.77V-305.77H260.384v165.769H88.847Zm16.538-45.384H215v-165.769h212.154v165.769h109v-589.23H105.385v589.23ZM215-453.462h54.615v-54.615H215v54.615Zm0-156.923h54.615V-665H215v54.615Zm156.923 156.923h54.615v-54.615h-54.615v54.615Zm0-156.923h54.615V-665h-54.615v54.615ZM215-185.385v-165.769h212.154v165.769-165.769H215v165.769Zm429.616-294.811q0-9.727 6.524-16.111 6.524-6.385 16.167-6.385h146.155L769.154-548q-6.077-6.693-6.577-16t6.092-15.899q6.792-6.793 15.971-6.793 9.18 0 15.744 6.692l79.768 79.769q8.616 8.686 8.616 20.266 0 11.58-8.616 20.196L800.384-380q-6.596 6.564-15.76 6.628-9.163.064-15.855-6.628-7.077-6.693-6.385-16 .693-9.307 6.77-16l44.308-45.308H667.307q-9.643 0-16.167-6.58-6.524-6.581-6.524-16.308Z"
    />
  </svg>
</template>
