<script setup>
// Displays a list of radio buttons
import { defineProps, defineEmits, computed, ref, watch } from "vue";

defineEmits(["optionChoosed"]);
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  containerClass: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
    // It should contain an array of objects with the following format:
    // { label: <Any String>, value: <Any String> }
    // If the label/value come from another property customize this component to reference it
  },
  groupName: {
    type: String,
    default: "radio",
    // Referenced in the name attribute, all radio buttons must share the same name
    // More at https://www.w3schools.com/tags/att_input_type_radio.asp
  },
  disableGroup: {
    type: Boolean,
    default: false,
  },
});

const selectedOption = ref("");
// Computed
const prefix = computed(() => {
  // Text that can be used as prefix in an html attribute.
  return "radio-" + props.groupName;
});

watch(
  () => props.preSelectedOption,
  (newVal) => {
    selectedOption.value = newVal;
  }
);
</script>

<template>
  <div :class="props.containerClass">
    <label
      v-for="(option, index) in props.options"
      :key="index"
      :for="prefix + index"
      class="flex text-sm font-medium mb-4"
    >
      <input
        :id="prefix + index"
        :data-cy="prefix + index + 'Radio'"
        type="radio"
        class="custom-checkbox h-5 w-5 border-gray-300 text-indigo-base focus:ring-indigo-base"
        :name="props.groupName"
        :value="option.value"
        :disabled="props.disableGroup"
        :checked="modelValue === option.value"
        @change="$emit('optionChoosed', $event.target.value)"
      />
      <span class="ml-4">
        {{ option.label }}
        <slot name="label"></slot>
      </span>
    </label>
  </div>
</template>

<style scoped>
.custom-checkbox:checked {
  accent-color: #007f80;
}
</style>
