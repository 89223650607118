<template>
  <div>
    <p class="font-semibold mb-5">
      <span class="text-red-100">**</span>
      {{ SAFETY_AMENITIES_TEXT }}
    </p>
    <div
      v-for="{ id, title } in safetySecurityAmenities"
      :key="id"
      class="flex justify-between w-fit items-center mb-3 font-semibold"
    >
      <input
        :id="id"
        :checked="stateCheckbox(id)"
        type="checkbox"
        class="h-4 w-4 form-checkbox border-gray-300 mr-3"
        @input="updateHousingAmenitiesValue(id, $event)"
      />
      <label :for="id">{{ title }}</label>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import { AMENITIES_CATEGORIES, AMENITIES_GRANULARITIES } from "@/constants";

const SAFETY_AMENITIES_TEXT =
  "Select all the safety features that applies to this building/housing options";

const store = useStore();

const amenitiesList = store.getters["programManager/getAllAmenities"];

const safetySecurityAmenities = amenitiesList.filter(
  ({ granularity_level, category }) =>
    granularity_level === AMENITIES_GRANULARITIES.HOUSING_LEVEL &&
    category === AMENITIES_CATEGORIES.SAFETY
);

const currentEditingHousingAmenities = computed(
  () => store.getters["programManager/getCurrentEditingHousingLevelAmenities"]
);

const stateCheckbox = (id) => {
  return currentEditingHousingAmenities.value.some(
    (el) => el.amenities_id === id
  );
};

const updateHousingAmenitiesValue = (id, event) => {
  const payload = event.target.checked
    ? [...currentEditingHousingAmenities.value, { amenities_id: id }]
    : currentEditingHousingAmenities.value.filter(
        (el) => el.amenities_id !== id
      );
  store.commit("programManager/setCurrentEditingHousingAmenities", payload);
};
</script>
