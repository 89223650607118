<template>
  <div class="flex mb-6 max-w-351p">
    <label
      v-if="props.label"
      :for="getId"
      class="ml-3 block text-sm text-gray-700 w-full max-w-64 mr-4"
      >{{ props.label }}
    </label>
    <div
      class="relative inline-block mr-2 align-middle select-none transition duration-200 ease-in"
      :class="props.width"
    >
      <input
        :id="getId"
        :checked="modelValue"
        v-bind="{
          ...$attrs,
        }"
        class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border border-indigo-base appearance-none cursor-pointer"
        type="checkbox"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
      <div
        class="toggle-div block overflow-hidden h-6 rounded-full border"
        :class="toggleBackground"
      ></div>
    </div>
  </div>
</template>
<script setup>
import { uuid } from "vue-uuid";
import { computed, getCurrentInstance } from "vue";

const instance = getCurrentInstance();
const attrs = instance.attrs;

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "w-10",
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const getId = computed(() => {
  return attrs.id ? attrs.id : uuid.v4();
});

const toggleBackground = computed(() => {
  return props.modelValue ? 'bg-indigo-base border-indigo-base' : 'bg-gray-300	border-gray-300	';
});
</script>
