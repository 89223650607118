<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <spinner v-if="loading">
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications/landing',
              params: { applicationId: applicationId },
            }"
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            class="underline hover:no-underline"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
      <FormPanel
        :id="'CoverLetterForm'"
        :title="'API INTERN STATEMENT OF PURPOSE INSTRUCTIONS'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <div id="cover-letter" class="mb-6 text-gray-600 space-y-6">
            <p>
              The statement of purpose replaces a standard cover letter and
              serves two important objectives:
            </p>
            <p></p>
            <ol>
              <li>
                It should communicate the type of internship you are seeking
              </li>
              <li>
                Showcase your skills and experience, detailing why you would be
                a good candidate
              </li>
            </ol>
            <p>
              API is already advocating for you with potential employers, a
              strong statement of purpose will make it easy for us to get you
              into employer interviews, paving the way for you to get the
              internship that is right for you!
            </p>
            <p>
              <a
                href="/files/API_Intern_Statement_of_Purpose_Example.pdf"
                target="_blank"
                :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
                >SEE A SAMPLE STATEMENT OF PURPOSE HERE</a
              >
            </p>
            <p class="font-bold">Statement of Purpose Guidelines</p>
            <ul class="list-disc pl-4">
              <li>Files must be submitted to API as a Word doc or PDF</li>
              <li>
                Use a similar header design in your statement of purpose and
                resume to share your name and contact details (e.g., email,
                phone, etc.). Be sure to keep the font and font size the same as
                well.
              </li>
              <li>
                Use 10-or 12-point font for the body of the statement of
                purpose.
              </li>
              <li>Begin the letter with “Dear Intern Selection Committee:”</li>
              <li>
                In your statement of purpose, be sure to convey to the reader:
                <ul class="list-disc pl-4">
                  <li>
                    Your home university or college, area of study, and minor
                    (if you have one)
                  </li>
                  <li>
                    Specify why you are seeking to participate in an internship
                    and why you want to intern abroad.
                  </li>
                  <li>
                    Give specific examples of areas in which you would like to
                    gain experience. The more examples you give, the better
                    chance for us to match you with an organization.
                  </li>
                  <li>
                    Highlight at least 3-4 skills or attributes that you will
                    bring to the internship; provide a bulleted list of your
                    accomplishments in each of these skill areas.
                  </li>
                  <li>
                    Thank the reader for their time in reviewing your
                    application.
                  </li>
                  <li>
                    Check your document for spelling and grammar. We suggest you
                    ask another person to proofread your statement of purpose
                    before you submit it.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <FileUpload
            :name="'coverLetterUpload'"
            :file-types="['.pdf', '.docx']"
            :formio-files="files"
            @updateProgress="updateProgress"
            @updateFiles="updateFiles"
          />
          <ButtonWithSpinner
            ref="SubmitForm"
            type="submit"
            variant="primary"
            variant-type="block"
            :disabled="!allowSubmit"
            @click.prevent="submit()"
          >
            <span
              >Submit
              <svg
                v-if="formSubmitted && !hasErrors"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="inline feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline></svg
            ></span>
          </ButtonWithSpinner>
          <p v-if="hasErrors" class="text-error-900">
            Please complete the required fields correctly.
          </p>
          <div v-if="formioErrors" class="text-error-900">
            <p v-for="error in formioErrors" :key="error">
              {{ error }}
            </p>
          </div>
          <div v-if="missingAppId" class="text-error-900">
            <p>
              No Application ID, please contact your API program manager to help
              you with your application.
            </p>
          </div>
          <div v-if="formSubmitted && !hasErrors" class="text-success-900">
            <p>
              Submission Complete, thank you!
            </p>
          </div>
        </template>
      </FormPanel>
    </div>
  </div>
</template>
<script>
import FileUpload from "../SharedComponents/FileUpload.vue";
import forms from "../../../mixins/forms";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import FormPanel from "../SharedComponents/panel.vue";
import { mapState } from "vuex";
import formioHelpers from "../../../mixins/formioHelpers";
import fileUpload from "../../../mixins/fileUpload";
import formIoApi from "../../../mixins/formIoApi";
import Spinner from "../../helpers/Spinner.vue";
import formValidation from "../../../mixins/formValidation";
export default {
  name: "StatementOfPurposeForm",
  components: { FileUpload, FormPanel, ButtonWithSpinner, Spinner },
  mixins: [forms, formioHelpers, fileUpload, formIoApi, formValidation],
  data() {
    return {
      applicationId: "",
      formSubmitted: false,
      formPath: "coverletter",
      loading: true,
      message: "Getting Student applications",
      redirect: false,
    };
  },
  computed: {
    ...mapState(["formioToken", "program"]),
    allowSubmit() {
      if (
        !this.filesOnProgress &&
        !this.fileIputHasErrors &&
        this.files.length > 0
      ) {
        return true;
      }
      return false;
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          application_id: this.applicationId,
          panel2Coverletterupload: this.files,
          coverLetterSubmitted: true,
        },
      };
      return body;
    },
    patchSubmissionDataForFormIo() {
      const body = [
        {
          op: "add",
          path: "/data/panel2Coverletterupload",
          value: this.files,
        },
        {
          op: "add",
          path: "/data/coverLetterSubmitted",
          value: true,
        },
      ];
      return body;
    },
  },
  watch: {
    files: {
      deep: true,
      handler() {
        //reset the submitted status
        this.formSubmitted = false;
      },
    },
    redirect: function (newVal, oldVal) {
      if (newVal) {
        //redirect to my applications
        this.$router.push({
          name: "applications",
        });
      }
    },
  },
  async created() {
    //set the application ID
    this.applicationId = this.setApplicationId;
    this.renderForm();
  },

  methods: {
    async renderForm() {
      //Make sure I user owns the application ID and if form is allowed in form rules
      if (
        !(await this.isValidApplication(this.applicationId, "Cover Letter"))
      ) {
        this.redirect = true;
        return;
      }

      //fetch already submitted data if it exist and load the form
      this.prefetchFormData();
    },
    prefetchFormData() {
      this.formioSubmissionExists(this.formPath, this.applicationId).then(
        (formSubmissionId) => {
          if (formSubmissionId) {
            this.getFormioSubmission(this.formPath, formSubmissionId).then(
              (response) => {
                if (response.panel2Coverletterupload) {
                  this.files = response.panel2Coverletterupload;
                }
                this.loading = false;
              }
            );
          } else {
            this.loading = false;
          }
        }
      );
    },
    async submit() {
      this.submitted = false;
      //calling the startLoading method of the button, which enables the button to loading state
      this.$refs.SubmitForm.startLoading();
      try {
        await this.checkForApplicationId();
        if (this.allowSubmit) {
          if (this.submission_id) {
            this.submitToFormIo(
              this.formPath,
              this.patchSubmissionDataForFormIo,
              "PATCH",
              this.submission_id
            ).then((response) => {
              if (response) {
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          } else {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                this.submission_id = response;
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          }
        } else {
          this.$refs.SubmitForm.stopLoading();
        }
      } catch {
        this.$refs.SubmitForm.stopLoading();
      }
    },
  },
};
</script>
