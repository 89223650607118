<template>
  <div
    class="bg-white rounded shadow-sm border border-gray-300"
    :class="[{ 'flex-row-reverse': !!flexReverse }]"
  >
    <div
      class="flex-none pointer-events-none flex items-center"
      :class="[flexReverse ? 'pr-3' : 'pl-3']"
    >
      <img src="/images/icon-search.svg" class="m-auto h-4" alt="" />
    </div>
    <input
      id="search-bar"
      v-model="searchText"
      type="text"
      name="search"
      class="flex-grow w-full px-6 rounded focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm min-h-8"
      :placeholder="placeholder"
      aria-describedby="search"
    />
    <slot name="advancedFilters" v-if="!hideAdvancedFilters">
      <div
        class="flex-none flex items-center pr-3 cursor-pointer hover:opacity-80"
        @click="openCustomFilters"
      >
        <img src="/images/icon-filter.svg" class="m-auto" alt="" />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    inputFromParent: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    hideAdvancedFilters: {
      type: Boolean,
      default: false,
    },
    flexReverse: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handleSearch", "handleCustomFilter"],
  data() {
    return {
      searchText: "",
    };
  },
  watch: {
    searchText: {
      handler: function (val) {
        /*
          *****IMPORTANT***** Create handleSearch method on the parent component.
          This will return a String with the search content.
        */
        this.$emit("handleSearch", val);
      },
    },
  },
  created() {
    this.searchText = this.inputFromParent;
  },
  methods: {
    openCustomFilters() {
      this.$emit("handleCustomFilter");
    },
  },
};
</script>
