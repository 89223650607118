<template>
  <div class="flex flex-col items-center px-6 pb-6 text-lg md:px-24 md:py-12">
    <h1
      id="confgrt_final_msg"
      class="text-2xl font-bold text-center font-montserrat md:text-5.5xl md:uppercase text-indigo-base"
    >
      Click "SAVE & CONTINUE" to keep your preferences
    </h1>
    <h2
      class="mt-4 md:mt-6 font-montserrat md:text-xl mb-6 max-w-[1010px] text-center text-indigo-base"
    >
      Great job on personalizing your experience!
    </h2>
    <div class="p-2">
      <div class="w-full md:flex md:justify-between">
        <ButtonWithSpinner
          id="continue-button"
          ref="continueButton"
          data-testid="save-and-continue-button"
          type="button"
          variant="secondary"
          :grey-disabled-class="true"
          :custom-class="'w-full p-5 border-transparent uppercase font-bold bg-teal-900 text-white'"
          @click="continueApplication()"
        >
          <div class="flex gap-2 justify-center items-center">
            <span>Save & Continue</span>
            <svg
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -7.77409e-07L-7.14641e-07 1.71183L5.38866 7.00874Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </ButtonWithSpinner>
      </div>
      <p v-if="hasError" class="error text-error-900">
        {{ hasError }}
      </p>
    </div>
  </div>
</template>

<script setup>
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import { useProgramSelectionData } from "@/composables/useProgramSelectionData.js";
import { ERROR_TIMEOUT } from "@/constants.js";
import formService from "@/services/form";
import ordersService from "@/services/orders.js";
import { trackEvent } from "@/util/eventTracker.js";
import * as Sentry from "@sentry/vue";
import { computed, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import { useHousingQuestionnaire } from "@/composables/useHousingQuestionnaire";
import { useNotifications } from "@/composables/useNotifications";
import { useOrder } from "@/composables/useOrder";
import applicationService from "@/services/application.js";

const { order } = inject("currentOrder");
const { program } = inject("program");
const { housing } = inject("housing");
const { duplicateApplication, validateApplication } = inject("application");

const { sendNotifications } = useNotifications();
const { createSubmissionDataForFormIo } = useProgramSelectionData();
const { prepOrderData } = useOrder();
const { createOrUpdatedHousingQuestionnaire } = useHousingQuestionnaire();

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const continueButton = ref(null);
const hasError = ref(null);

const submittedProgramSelectionFormId = ref(undefined);
const submittedOrderId = ref(null);
const programSelectionId = computed(() => {
  const application = store.state.studentApplications?.find(
    (app) => app.id === order.value.application_id
  );
  const programSelectionSubmission = application?.submissions?.find(
    (submission) => submission.formPath === "programselection"
  );
  return (
    programSelectionSubmission?._id ?? submittedProgramSelectionFormId.value
  );
});

const persistprogramSessionSalesForceId = (programSessionSalesForceId) => {
  // Stores Salesforce ID to check for duplicates in case student authenticates after completing configurator.
  // Gets cleared when student authenticates or logs out
  localStorage.setItem(
    "crossCheckingProgramSession",
    JSON.stringify(programSessionSalesForceId)
  );
};

const continueApplication = async () => {
  trackEvent(
    "click",
    "Continue to Application",
    program?.programSession?.id,
    store.getters?.getCurrentUser?.participantId
  );
  continueButton.value.startLoading();
  await store.dispatch("configurator/setCurrentProgram", _.cloneDeep(program));
  if (store?.getters?.isLoggedIn) {
    submit();
  } else {
    //save current configurator order to vuex module
    await store.dispatch("configurator/setCurrentOrder", order.value);
    //save housing questionnaire to vuex module
    await store.dispatch(
      "configurator/setHousingQuestionnaire",
      housing.questionnaire
    );
    if (program?.programSession?.salesforce_id)
      persistprogramSessionSalesForceId(program.programSession.salesforce_id);
    router.push(destination.value);
  }
};

const submit = async () => {
  try {
    continueButton.value.startLoading();

    await validateApplication();
    if (duplicateApplication.value) {
      continueButton.value.stopLoading();
      return;
    }

    const { isUserEmployee } = store.getters;
    if (isUserEmployee) {
      toast.open({
        message: "Application creation is not allowed for your account type.",
        type: "error",
        position: "bottom",
        duration: ERROR_TIMEOUT,
      });
      continueButton.value.stopLoading();
      return;
    }

    //create data for formio program selection form
    let formioData = createSubmissionDataForFormIo(
      program?.programSession,
      order.value.application_id
    );

    await createOrUpdateOrder(formioData);

    router.push({
      ...destination.value,
      params: {
        ...destination.value.params,
        applicationId: formioData.data.application_id,
      },
    });
  } catch (error) {
    hasError.value =
      "Please try again. If this problem persists, please contact productsupport@apiexperience.com.";
    continueButton.value.stopLoading();
    Sentry.captureException(new Error("Final step in configurator error."), {
      tags: {
        error: error.message,
      },
    });
  }
};

const destination = computed(() => {
  const orderApplicationId = order.value?.application_id;
  if (orderApplicationId) {
    return {
      name: "application-resume",
      params: { applicationId: orderApplicationId },
    };
  }

  const routeName = store?.getters?.isLoggedIn
    ? "application-about-you"
    : "new-account-creation";
  return {
    name: routeName,
    params: { sessionId: order.value.session_id },
  };
});

const participantId = computed(
  () => store.state.currentUser?.participantId ?? ""
);

const createOrUpdateOrder = async (formioData) => {
  const application_id = formioData.data.application_id;
  let orderResponse = null;

  //bail if we dont have the required data
  if (!application_id || !order.value.program_page_id) {
    throw "Missing data for order creation.";
  }
  //create draft order
  const orderData = await prepOrderData(
    application_id,
    program.programSession,
    order.value
  );

  if (order.value.orderId || submittedOrderId.value) {
    orderResponse = await ordersService.updateOrder({
      order_id: order.value.orderId || submittedOrderId.value,
      payload: orderData,
      session: program?.programSession,
      callingLocation: "StepsCompleted",
    });
  } else {
    orderResponse = await ordersService.createOrder({
      payload: orderData,
    });
  }

  const orderId = orderResponse?.data?.data?.order?.id ?? "";

  if (!orderId) throw "Missing order id for application creation";
  submittedOrderId.value = orderId;

  // Program selection from submission
  const formSubmission = await formService.createOrUpdateSubmission(
    "programselection",
    formioData,
    programSelectionId.value
  );

  if (formSubmission) {
    submittedProgramSelectionFormId.value = formSubmission._id;
    if (!order.value?.id) {
      // send advisor notification
      sendNotifications("start", formSubmission.data.programSession);
    }
  } else {
    throw "Missing program selection data for application creation";
  }

  await store.dispatch(
    "configurator/setCurrentOrder",
    orderResponse.data.data.order
  );

  const applicationData = {
    application_id: application_id,
    order_id: orderId,
    program_id: order.value.program_page_id,
    configurator_completed: true,
  };

  //save order ID to connect db
  try {
    if (order.value.orderId) {
      await applicationService.updateV3Application(
        application_id,
        applicationData
      );
    } else {
      await applicationService.createV3Application(applicationData);
    }
  } catch (error) {
    Sentry.captureException(
      new Error("Final step in configurator error (Connect Application)."),
      {
        tags: {
          error: error.message,
          order_id: orderId,
        },
      }
    );
  }

  //Send housing questionnaire to profile service
  try {
    await createOrUpdatedHousingQuestionnaire(
      participantId.value,
      orderId,
      application_id,
      housing?.questionnaire,
      housing?.questionnaireId
    );
  } catch (error) {
    Sentry.captureException(
      new Error("Final step in configurator error (Housing Questionnaire)."),
      {
        tags: {
          error: error.message,
          orderId: orderId,
        },
      }
    );
  }

  //clear housing in vuex
  await store.dispatch("configurator/resetHousingQuestionnaire");

  // inserting order ID into browser history so that if the user
  // returns here via back button the configurator won't think
  // it's a brand-new order
  await router.replace({
    name: "configurator",
    params: {
      ...route.params,
      orderId,
    },

    query: { ...route.query },
  });
};
</script>
