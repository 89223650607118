import {
  EVENT_SUBCATEGORIES,
  OCCURRENCES_ACTIVE_STATUS_ID,
} from "@/components/program-manager/sessions/constants";
import DOMPurify from "dompurify";
import { convertCentsToDolarAsCurrency } from "@/services/utils";

export const convertEventsToOccurrences = (events) => {
  const eventOccurrences = events
    .filter((val) => val?.status === "Active")
    .reduce((acc, event) => {
      const location = event?.event_locations
        ?.map((val) => {
          const address = val?.address
            ? `${val?.address?.city || ""}, ${val?.address?.state || ""}`
            : "";
          return address;
        })
        .join("\\<br\\>");
      const convertedOccurrences = event?.event_occurrences?.map(
        (occurrence) => {
          return {
            title: event?.title || "",
            category: EVENT_SUBCATEGORIES[event.subcategory_id],
            category_name:
              occurrence?.associated_event?.event_category?.name || "",
            type: occurrence?.associated_event?.type,
            location,
            host: event?.host || "",
            price_student: occurrence.price_student || 0,
            id: occurrence.id,
            status_id: occurrence.status_id,
          };
        }
      );
      const filteredOccurrences = convertedOccurrences.filter(
        (val) => val?.status_id === OCCURRENCES_ACTIVE_STATUS_ID
      );
      return [...acc, ...filteredOccurrences];
    }, []);
  return eventOccurrences.sort((left, right) =>
    left.title.localeCompare(right.title)
  );
};

export const transformOccurrences = (occurrences) => {
  return (
    occurrences
      ?.reduce((acc, occurrence) => {
        if (occurrence?.status_id === OCCURRENCES_ACTIVE_STATUS_ID) {
          const occ = {
            title: occurrence?.associated_event?.title || "",
            category: EVENT_SUBCATEGORIES[occurrence.subcategory_id],
            category_name:
              occurrence?.associated_event?.event_category?.name || "",
            location: formatSanitizeLocations(
              occurrence?.associated_event?.event_locations
            ),
            type: occurrence?.associated_event?.type,
            host: occurrence?.associated_event?.host || "",
            price_student:
              convertCentsToDolarAsCurrency(occurrence.price_student) || 0,
            product_price:
              convertCentsToDolarAsCurrency(occurrence.product_price) || 0,
            id: occurrence.id,
            status_id: occurrence?.status_id,
            event_id: occurrence?.event_id,
            duration: formatDuration(occurrence?.associated_event),
            availability: formatTerms(occurrence?.terms_available),
          };

          return [...acc, occ];
        }
        return [...acc];
      }, [])
      ?.sort((left, right) => left.title.localeCompare(right.title)) || []
  );
};

const formatSanitizeLocations = (locations) => {
  const location = locations
    ?.map((val) => {
      const address = val?.city
        ? `${val?.city?.city || ""}, ${val?.city?.country?.iso || ""}`
        : "";
      return address;
    })
    .filter(Boolean) // Remove empty strings if any
    .reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    ) // Remove duplicates cities, iso
    .join("<br>");
  return DOMPurify.sanitize(location);
};
const formatDuration = (event_data) => {
  return event_data?.duration && event_data?.duration_units
    ? `${event_data?.duration} ${event_data?.duration_units}`
    : "";
};

const formatTerms = (terms) => {
  if (!terms) {
    return "n/a";
  }
  return terms
    ?.map((val) => {
      return val.name;
    })
    .join(", ");
};
