<template>
  <div>
    <p class="mb-4">
      <b>Please share your current COVID-19 Vaccination Status:</b>
    </p>
    <p class="mb-4">
      API strongly recommends and/or mandates that all participants have
      received a COVID-19 vaccine and booster prior to their travel, as directed
      by the CDC and/or a personal physician. Additionally, we also strongly
      recommend that participants ensure they are up to date with all routine
      vaccinations and any that are recommended for their particular host
      country:
      <a
        :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
        target="_blank"
        href="https://wwwnc.cdc.gov/travel/page/routine-vaccines"
        >https://wwwnc.cdc.gov/travel/page/routine-vaccines</a
      >
    </p>
    <p class="mb-4">
      API will also defer to and enforce any vaccination mandates issued by (a)
      State and local authorities, (b) colleges and universities in API’s global
      network, (c) the jurisdictions of the host countries, and (d) any other
      applicable authority. Due to the myriad of potential applicable mandates,
      the unpredictable future course of COVID-19, and the changing mandates, as
      well as the consequences of the imposition of certain restrictions while
      studying abroad for those participants who are not vaccinated, API’s
      participants should be fully vaccinated, absent the existence of an
      expressly permitted jurisdictional exemption (primarily for medical or
      religious reasons).
    </p>
    <p class="mb-4">
      Some countries may require vaccination or testing prior to entry, and some
      may have a health pass system requiring proof of vaccination, boosters
      and/or negative test results in order to have access to local facilities
      such as museums and restaurants. API will endeavor to provide participants
      with the latest requirements for travel to the given country as well as
      conditions within the country; however, participants are required to
      monitor current conditions and restrictions independently. Each country’s
      specific vaccination and booster requirements can be found
      <a
        :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
        target="_blank"
        href="https://downloads.apiabroad.com/documents/Booster_Vaccination_Requirements.pdf"
        >here</a
      >.
    </p>
    <fieldset>
      <RadioButton
        name="covidStatus"
        aria-controls="covid-status-date"
        :options="[
          'I have been fully vaccinated',
          'I have a documented medical or religious exemption and will not receive the vaccine',
          'Other',
        ]"
        :inline-options="false"
        :capitalized-options="false"
        :selected-option="selectedOption"
        :validation-errors="v$.value.selectedRadio"
        @input="onFieldChange('selectedRadio', $event)"
      />
      <div
        v-if="value.selectedRadio === 'I have been fully vaccinated'"
        id="covid-status-date"
        :aria-expanded="true"
      >
        <div class="grid grid-cols-1">
          <CalendarInput
            name="vaccinatedDate"
            label-before-input="Date of final vaccination dose"
            :value-from-database="calendarInputDefaultValue"
            :validation-errors="v$.value.vaccinatedDate"
            @input="onFieldChange('vaccinatedDate', $event)"
          />
          <CalendarInput
            class="mt-4"
            name="boosterDate"
            label-before-input="Date of booster shot"
            :value-from-database="boosterInputDefaultValue"
            :validation-errors="v$.value.boosterDate"
            @input="onFieldChange('boosterDate', $event)"
          />
          <TextField
            id="vaccine-type "
            name="vaccine-type "
            label="Vaccine type "
            :max-length="80"
            place-holder="ex. Moderna, Pfizer, Johnson & Johnson"
            :value-from-database="vaccineTypeFromDatabase"
            :validation-errors="v$.value.vaccineType"
            @input="onFieldChange('vaccineType', $event)"
          />
        </div>
      </div>
      <div
        v-if="value.selectedRadio === 'Other'"
        id="covid-status-date"
        :aria-expanded="true"
      >
        <div class="grid grid-cols-1 gap-y-8">
          <ApiTextArea
            id="other-covid-status"
            name="other-covid-status"
            label="Please indicate your reason for not being vaccinated below"
            :max-length="500"
            :typed-text="otherCovidStatusFromDatabase"
            :validation-errors="v$.value.otherCovidStatus"
            @textUpdate="onFieldChange('otherCovidStatus', $event)"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import formInputs from "../../mixins/formInputs";
import RadioButton from "../forms/SharedComponents/RadioButton";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea.vue";
import { maxLength, required, requiredIf } from "@vuelidate/validators";
import {
  minDateValidator,
  validatorDateFormat,
} from "@/mixins/customValidators";
import validationMessages from "../../mixins/validationMessages";
import { format, parse } from "date-fns";
import CalendarInput from "../forms/SharedComponents/CalendarInput.vue";
import TextField from "../forms/SharedComponents/TextField.vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: "CovidVaccinatedStatus",
  components: { RadioButton, CalendarInput, ApiTextArea, TextField },
  mixins: [formInputs, validationMessages],
  props: {
    covidInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    vaccinatedDateFromDatabase: {
      type: String,
      default: "",
    },
    otherCovidStatusFromDatabase: {
      type: String,
      default: "",
    },
    vaccineTypeFromDatabase: {
      type: String,
      default: "",
    },
    boosterDateFromDatabase: {
      type: String,
      default: "",
    },
  },
  emits: ["update:covid-info"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        selectedRadio: "",
        vaccinatedDate: "",
        otherCovidStatus: "",
        vaccineType: "",
        boosterDate: "",
      },
      calendarInputDefaultValue: "",
      boosterInputDefaultValue: "",
    };
  },
  computed: {
    covidStatus() {
      return this.value.selectedRadio;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:covid-info", this.value);
      },
      deep: true,
      immediate: true,
    },
    covidStatus(newVal) {
      if (newVal !== this.selectedOption) {
        this.value.vaccinatedDate = "";
        this.value.vaccineType = "";
        this.value.boosterDate = "";
      } else {
        this.value.vaccineType = this.vaccineTypeFromDatabase;
        if (this.vaccinatedDateFromDatabase) {
          this.calendarInputDefaultValue = format(
            parse(this.vaccinatedDateFromDatabase, "MM/dd/yyyy", new Date()),
            "yyyy-MM-dd"
          );
        }

        if (this.boosterDateFromDatabase) {
          this.boosterInputDefaultValue = format(
            parse(this.boosterDateFromDatabase, "MM/dd/yyyy", new Date()),
            "yyyy-MM-dd"
          );
        }
      }
    },
  },
  created() {},

  validations: {
    value: {
      selectedRadio: {
        required,
      },
      vaccinatedDate: {
        required: requiredIf(function () {
          return this.value.selectedRadio === "I have been fully vaccinated";
        }),
        isValidDate: validatorDateFormat("yyyy-mm-dd"),
        isValidMinDate: minDateValidator("2020"),
      },
      vaccineType: {
        required: requiredIf(function () {
          return this.value.selectedRadio === "I have been fully vaccinated";
        }),
        maxLength: maxLength(80),
      },
      otherCovidStatus: {
        required: requiredIf(function () {
          return this.value.selectedRadio === "Other";
        }),
      },
      boosterDate: {
        isValidDate: validatorDateFormat("yyyy-mm-dd"),
      },
    },
  },
};
</script>
