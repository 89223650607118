<template>
  <Menu as="div" class="relative inline-block">
    <div>
      <MenuButton
        class="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900"
      >
        <IconElips />
      </MenuButton>
    </div>

    <MenuItems
      class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      <div>
        <router-link
          v-for="route in routes"
          :key="route.name"
          :to="generateRoutes(route)"
          class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200"
        >
          {{ route.description }}
        </router-link>
      </div>
    </MenuItems>
  </Menu>
</template>

<script setup>
import { defineProps } from "vue";
import IconElips from "@/components/shared/icons/IconElips.vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";

defineProps({
  routes: {
    type: Array,
    default: () => [],
  },
});
const generateRoutes = (route) => {
  return route?.params && Object.keys(route.params).length > 0
    ? { name: route.name, params: route.params }
    : route?.name;
};
</script>
