import gql from "graphql-tag";

export default {
  methods: {
    async getProgramSessionAndDates(payload) {
      return await payload.apolloClient.query({
        query: gql`
          query programSessionBySalesforceId($salesforce_id: String!) {
            programSessionBySalesforceId(salesforce_id: $salesforce_id) {
              session
              start_date
              end_date
              program {
                internship
              }
            }
          }
        `,
        variables: {
          salesforce_id: payload.program_id,
        },
      });
    },
  },
};
