import { helpers } from "@vuelidate/validators";
import { getYear, isValid, parse } from "date-fns";

export const validatorDateFormat = (param) => (value) => {
  if (helpers.req(value) && !isValid(parse(value, param, new Date()))) {
    //invalid date
    return false;
  } else {
    //date valid
    return true;
  }
};

export const minDateValidator = (param) => (value) => {
  if (
    helpers.req(value) &&
    getYear(parse(value, "yyyy-mm-dd", new Date())) < param
  ) {
    //invalid date
    return false;
  } else {
    //date valid
    return true;
  }
};

export const mustNotHaveHyphen = (value) => {
  if (value.indexOf("-") > -1) {
    return false;
  } else {
    return true;
  }
};

export const validEmailAddress = (value) => {
  return !endsWithAny([".cmo", ".edi", ".eud", ".edua"], value);
};

const endsWithAny = (suffixes, string) => {
  return suffixes.some((suffix) => string.endsWith(suffix));
};

export const validPhoneNumber = (value, vm) => {
  return value ? vm.isValidPhoneNumber : true;
};

/*
  A-Za-z matches the characters A-Za-z literally (case sensitive)
  A matches the character A with index 6510 (4116 or 1018) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  Z matches the character Z with index 9010 (5A16 or 1328) literally (case sensitive)
  a matches the character a with index 9710 (6116 or 1418) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  z matches the character z with index 12210 (7A16 or 1728) literally (case sensitive)
  \u00C0 matches the character À with index C016 (19210 or 3008) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  \u00D6 matches the character Ö with index D616 (21410 or 3268) literally (case sensitive)
  \u00D8 matches the character Ø with index D816 (21610 or 3308) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  \u00F6 matches the character ö with index F616 (24610 or 3668) literally (case sensitive)
  \u00F8 matches the character ø with index F816 (24810 or 3708) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  \u00ff matches the character ÿ with index FF16 (25510 or 3778) literally (case sensitive)
*/

export const nameRegex = helpers.regex(
  /^[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00ff ]+$/
);
