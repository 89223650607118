<template>
  <form-panel
    id="cities-edit-overview"
    title="Overview"
    panel-content-class="pb-6 mb-6"
    panel-button-text-class="tracking-normal"
  >
    <template #content>
      <div
        class="flex w-full flex-wrap justify-between justify-items-center rounded-sm border border-gray-300 bg-white px-8 py-4 text-base"
      >
        <div class="mt-3 w-full xs:w-6/12 md:w-4/12">
          <h4 class="font-semibold text-gray-700">Books (Semester)</h4>
          <span class="mt-2" data-cy="sidebar-city-name-label">$&emsp;</span>
          <input
            id="books"
            v-model="data.average_semester_books"
            min="0"
            step="0.1"
            type="number"
            class="form-input"
          />
        </div>
        <div class="mt-3 w-full xs:w-6/12 md:w-4/12">
          <h4 class="font-semibold text-gray-700">Transportation (Daily)</h4>
          <span class="mt-2" data-cy="sidebar-city-name-label">$&emsp;</span>
          <input
            id="transport"
            v-model="data.average_daily_local_transport"
            min="0"
            step="0.1"
            type="number"
            class="form-input"
          />
        </div>
        <div class="mt-3 w-full xs:w-6/12 md:w-4/12 lg:w-80">
          <h4 class="font-semibold text-gray-700">Expenses (Daily)</h4>
          <span class="mt-2" data-cy="sidebar-city-name-label">$&emsp;</span>
          <input
            id="expenses"
            v-model="data.average_daily_personal_expenses"
            min="0"
            step="0.1"
            type="number"
            class="form-input"
          />
        </div>
        <div class="mt-3 w-full xs:w-6/12 md:w-4/12">
          <h4 class="font-semibold text-gray-700">Meal (Daily)</h4>
          <span class="mt-2" data-cy="sidebar-city-name-label">$&emsp;</span>
          <input
            id="meal"
            v-model="data.average_daily_meals"
            min="0"
            step="0.1"
            type="number"
            class="form-input"
          />
        </div>
      </div>
    </template>
  </form-panel>
</template>

<script setup>
import formPanel from "@/components/forms/SharedComponents/panel.vue";
import { useVModel } from "@vueuse/core";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);
const data = useVModel(props, "modelValue", emit);
</script>
