<template>
  <div
    class="flex w-30 bg-white justify-center items-center rounded-md ring-1 ring-gray-300 border-gray-300 py-2 px-1 h-10 focus:border-teal-900 focus:outline-none focus:ring-teal-900 text-base sm:text-md"
  >
    <v-select
      v-model="response.hour"
      :options="hours"
      :clearable="false"
      class="styled-v-select"
    ></v-select>
    <span class="text-md mx-1">:</span>
    <v-select
      v-model="response.minute"
      :options="minutes"
      :clearable="false"
      class="styled-v-select"
    ></v-select>
    <v-select
      v-if="!is24Hours"
      v-model="response.ampm"
      :options="ampm"
      :clearable="false"
      class="styled-v-select"
    ></v-select>
  </div>
</template>

<script>
import { parse, isValid, format } from "date-fns";
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

export default {
  name: "TimeInput",
  props: {
    is24Hours: {
      type: Boolean,
      default: false,
    },
    minutesInterval: {
      type: Number,
      default: 15,
    },
    initialHours: {
      type: String,
      default: "08:00",
    },
  },
  emits: ["handleTimeInput"],
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      response: {
        hour: "08",
        minute: "00",
        ampm: undefined,
      },
      hours: [],
      minutes: [],
      ampm: ["AM", "PM"],
    };
  },
  validations: {
    response: {
      hour: {
        required: requiredIf(() => false),
      },
      minute: {
        required: requiredIf(() => false),
      },
      ampm: {
        required: requiredIf(() => false),
      },
    },
  },
  watch: {
    response: {
      handler() {
        const { hour, minute, ampm } = this.response;
        const validSuffix = this.is24Hours ? "" : ` ${ampm}`;
        const stringTime = `${hour}:${minute}${validSuffix}`;
        this.$emit("handleTimeInput", stringTime);
      },
      deep: true,
    },
  },
  created() {
    const { hours, minutes, ampm } = this.convertTimeInput();
    this.response.hour = hours;
    this.response.minute = minutes;
    this.response.ampm = ampm.toUpperCase();
    this.$emit("handleTimeInput", this.initialHours);

    const maxHour = this.is24Hours ? 24 : 12;
    const maxMinute = 60;
    this.hours = Array.from(Array(maxHour), (_, index) => {
      const hour = this.is24Hours ? `${index}` : `${index + 1}`;
      return hour.padStart(2, "0");
    });
    this.minutes = Array.from(
      Array(maxMinute / this.minutesInterval),
      (_, index) => `${index * this.minutesInterval}`.padStart(2, "0")
    );
  },
  methods: {
    convertTimeInput() {
      const strToDate = this.checkFormat(this.initialHours.trim());
      const ampm = format(strToDate, "a").toLowerCase();
      let hours = strToDate.getHours();
      hours = ampm === "am" && hours === 0 ? hours + 12 : hours;
      hours = ampm === "pm" && hours !== 12 ? hours - 12 : hours;

      return {
        hours: `${hours}`.padStart(2, "0"),
        minutes: `${strToDate.getMinutes()}`.padStart(2, "0"),
        ampm: ampm,
      };
    },
    checkFormat(strTime) {
      const formats = [
        "h:m a",
        "hh:mm a",
        "h : m a",
        "hh : mm a",
        "H:m",
        "HH:mm",
        "H : m",
        "HH : mm",
      ];
      for (let fmt of formats) {
        const converted = parse(strTime, fmt, new Date());
        if (isValid(converted)) return converted;
      }
      return parse("05:00 PM", "hh:mm a", new Date());
    },
  },
};
</script>

<style scoped>
.styled-v-select :deep(.vs__actions) {
  display: none;
}
.styled-v-select :deep(.vs__dropdown-toggle) {
  border: 0;
  padding: 0;
  padding-top: 0.375rem;
  width: 35px;
}
</style>
