<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <text
      x="50%"
      y="50%"
      text-anchor="middle"
      dy=".5ex"
      font-family="Segoe UI, Frutiger, Dejavu Sans, Helvetica Neue, Arial, sans-serif"
      font-size="15"
      font-weight="bold"
    >
      B
    </text>
  </svg>
</template>

<script>
export default {
  name: "IconBold",
};
</script>
