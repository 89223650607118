import { formattedPriceWithoutCents } from "../../../../util/formatter";
import { reLabel } from "../utils";
import { countriesTranslationMap } from "../constants";
export const PRICE_INTERVAL = 10;
export const PRICE_MIN = 0;
export const PRICE_MAX = 5500000;
export const DEFAULT_PRICE_RANGE = [0, 1500000];

export const INITIAL_FILTERS = {
  areaOfStudy: [],
  career: [],
  cities: [],
  classTitle: "",
  countries: [],
  courseLevel: [],
  endDate: undefined,
  hostInstitution: [],
  keyword: "",
  language: [],
  pastPrograms: false,
  price: [],
  programLength: [],
  programTypes: [],
  session_id: [],
  startDate: undefined,
  timeframe: [],
  sessionId: undefined,
  sessionsDomainRestrictedId: undefined,
  programsDomainRestrictedId: undefined,
  programsPageOwnerEntityIdFilter: undefined,
  sessionsOwnerEntityIdFilter: undefined,
};

export const badgeConvertMap = {
  price: {
    converter: (value) => {
      const leftPrice = formattedPriceWithoutCents(value?.[0] / 100);
      const rightPrice = formattedPriceWithoutCents(value?.[1] / 100);
      return `Price: ${leftPrice}~ ${rightPrice}`;
    },
    singleEntry: true,
  },
  startDate: {
    converter: (value) => {
      const timeString = value.join("|");
      return `Start Date: ${timeString}`;
    },
    singleEntry: true,
  },
  endDate: {
    converter: (value) => {
      const timeString = value.join("|");
      return `End Date: ${timeString}`;
    },
    singleEntry: true,
  },
  pastPrograms: { converter: () => "Past Programs", singleEntry: true },
  keyword: { singleEntry: true },
  sessionId: { converter: (value) => value?.[0] || "", singleEntry: true },
  timeframe: {
    converter: (value) => value || "",
    singleEntry: false,
  },
  areaOfStudy: {
    converter: (value) => value?.split(" > ")?.[1] || "",
    singleEntry: false,
  },
  countries: {
    converter: (value) => reLabel({ label: value }, countriesTranslationMap),
    singleEntry: false,
  },
  sessionsDomainRestrictedId: { exclude: true },
  programsDomainRestrictedId: { exclude: true },
  programsPageOwnerEntityIdFilter: { exclude: true },
  sessionsOwnerEntityIdFilter: { exclude: true },
};

// Probably overkill breaking these into a lookup map but you never know
export const FILTER_NAMES = {
  keyword: "keyword",
  classTitle: "classTitle",
};
