<template>
  <div v-if="!hasPendingApplicationCreation && !hasCrossCheckingToDo" />
  <spinner v-else>
    <template #spinnercontent>
      <div v-if="orderFailed">
        <p>
          Please refresh your browser to try again. If this issue persists,
          please contact productsupport@apiexperience.com.
        </p>
      </div>

      <p v-else class="text-gray-600">
        Loading, Please wait.
      </p>
    </template>
  </spinner>
</template>

<script>
import { PERMISSIONS } from "../constants";
import formService from "@/services/form";
import profileService from "@/services/profile.js";
import * as Sentry from "@sentry/vue";
import { mapGetters } from "vuex";
import Spinner from "@/components/helpers/Spinner";
import { useProgramSelectionData } from "@/composables/useProgramSelectionData.js";
import { useOrder } from "@/composables/useOrder.js";
import { useHousingQuestionnaire } from "@/composables/useHousingQuestionnaire";
import { useNotifications } from "@/composables/useNotifications";
import ordersService from "@/services/orders.js";
import { applicationAlreadyExists } from "@/components/ExperiencePage/utils.js";
import { ref } from "vue";
import applicationService from "@/services/application.js";
import { trackEvent } from "../util/eventTracker";

export default {
  name: "LandingPage",
  components: {
    Spinner,
  },
  setup() {
    const { createSubmissionDataForFormIo } = useProgramSelectionData();
    const { prepOrderData } = useOrder();
    const { createOrUpdatedHousingQuestionnaire } = useHousingQuestionnaire();
    const { sendNotifications } = useNotifications();
    const hasCrossCheckingToDo = ref(false);
    return {
      createSubmissionDataForFormIo,
      prepOrderData,
      createOrUpdatedHousingQuestionnaire,
      sendNotifications,
      hasCrossCheckingToDo,
    };
  },
  data() {
    return {
      orderFailed: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "getPermissions",
      isLoggedIn: "isLoggedIn",
      currentUser: "getCurrentUser",
      currentOrder: "configurator/getCurrentOrder",
      programFromOrder: "configurator/getCurrentProgram",
      housingQuestionnaire: "configurator/getHousingQuestionnaire",
      profileData: "getProfileData",
      redirectSessionData: "getRedirectSessionData",
      configuratorIncomplete: "configurator/getConfiguratorIncomplete",
      entityIDs: "getEntityIds",
    }),
    hasPendingApplicationCreation() {
      // V3 - If user completed configurator but opted to sign-in
      // Order with app id might cause duplicates
      return (
        Boolean(this.currentOrder?.session_id) &&
        !this.currentOrder?.application_id
      );
    },
    hasStudentManagementPermission() {
      return this.permissions.includes(PERMISSIONS.STUDENT_MANAGEMENT);
    },
    hasStudentViewPermission() {
      return this.permissions.includes(PERMISSIONS.API_EMPLOYEE_STUDENT_VIEW);
    },
    hasRedirectData() {
      return (
        this.redirectSessionData.programId && this.redirectSessionData.sessionId
      );
    },
    isParticipant() {
      return this.permissions.includes(PERMISSIONS.PARTICIPANTS);
    },
  },
  async created() {
    if (!this.isLoggedIn) {
      await this.$router.push("/sign-in");
      return;
    }
    trackEvent(
      "log_in",
      "FormSubmission",
      "SubmitButton",
      this.$store.getters?.getCurrentUser?.participantId
    );

    const oktaId = this.$store.state?.currentUser?.participantId ?? "";
    if (oktaId) {
      await this.$store.dispatch("getProfile", oktaId);
      this.logUtmTracking(oktaId);
    }

    if (this.isParticipant) {
      await this.$store.dispatch("initialSetUp");
      const crossCheckingProgramSessionSalesforceId = JSON.parse(
        localStorage.getItem("crossCheckingProgramSession")
      );
      localStorage.removeItem("crossCheckingProgramSession");
      if (crossCheckingProgramSessionSalesforceId) {
        this.hasCrossCheckingToDo = true;
        await this.$store.dispatch("getStudentApplications");
        const studentApplications = this.$store?.state?.studentApplications;
        const exists = await applicationAlreadyExists({
          programSession: {
            salesforce_id: crossCheckingProgramSessionSalesforceId,
          },
          studentApplications: studentApplications,
          skipRequest: true,
        });
        if (exists) {
          await this.$router.push("/application-exists");
          return;
        }
      }
    } else if (this.hasStudentManagementPermission) {
      const formioUser = await formService.currentFormServiceUser();

      if (this.hasStudentManagementPermission) {
        await this.$store.dispatch(
          "university/updateUniversity",
          formioUser.data.university.value
        );

        this.$store.commit("university/setUserDetails", {
          email: formioUser.data.email,
          name: formioUser.data.firstname + " " + formioUser.data.lastname,
          university: formioUser.data.university.label,
          universityId: formioUser.data.university.value,
          loggedIn: true,
          dynamicUniversity: formioUser.data.dynamicUniversity,
        });
      }
    }

    if (
      !this.permissions.includes(PERMISSIONS.PARTICIPANTS) &&
      !this.permissions.includes(PERMISSIONS.STUDENT_MANAGEMENT) &&
      !this.currentUser?.email?.includes("apiabroad.com") &&
      this.permissions.includes(PERMISSIONS.EVERYONE)
    ) {
      // Send error message to Sentry
      Sentry.captureMessage(
        `User ${this.currentUser.email} does not have the participant permission`
      );
    }

    if (this.hasStudentViewPermission) {
      await this.$router.push("/students");
      return;
    }

    if (this.hasStudentManagementPermission) {
      await this.$router.push("/my-students");
      return;
    }

    if (this.isParticipant) {
      if (this.hasPendingApplicationCreation) {
        await this.createApplication();
      } else if (this.hasRedirectData) {
        const redirectURL = `/program-page/${this.redirectSessionData.programId}?session-id=${this.redirectSessionData.sessionId}`;
        this.$store.commit("setRedirectSessionData", {
          programId: null,
          sessionId: null,
        });
        window.location.href = redirectURL;
      } else {
        await this.$router.push("/applications");
      }
    }
  },
  methods: {
    async logUtmTracking(oktaId) {
      const trackingInfoRaw = localStorage.getItem("utm_tracking");
      if (trackingInfoRaw) {
        const trackingInfo = JSON.parse(trackingInfoRaw);
        await profileService.addProfileTracking(oktaId, trackingInfo)
          .then(localStorage.removeItem("utm_tracking"))
          .catch((error) => console.error(`Unable to add tracking: ${error}`));
      }
    },
    async createApplication() {
      try {
        // V3 exclusive: Creates an application when user logs in after completing configurator
        // Create data for formio program selection form
        let formioData = this.createSubmissionDataForFormIo(
          this.programFromOrder?.programSession
        );

        // Prepare draft order data
        const orderData = await this.prepOrderData(
          formioData.data.application_id,
          this.programFromOrder?.programSession,
          this.currentOrder
        );

        // update contract number with contract found in order
        if (orderData?.billing_contract_id)
          formioData.data.contractNumber = orderData.billing_contract_id;

        // Create Draft Order
        const orderResponse = await ordersService.createOrder({
          payload: orderData,
        });

        // Save order in connect
        const orderID = orderResponse?.data?.data?.order?.id ?? "";
        if (!orderID) throw "Missing order id.";

        // Submit Program Selection Form
        const formSubmission = await formService.createOrUpdateSubmission(
          "programselection",
          formioData
        );

        if (!formSubmission?.data) throw "Missing program selection data.";

        this.sendNotifications("start", formSubmission.data.programSession);

        try {
          await applicationService.createV3Application({
            application_id: formioData.data.application_id,
            order_id: orderID,
            program_id: this.currentOrder.program_page_id,
            configurator_completed: !this.configuratorIncomplete,
          });
        } catch (error) {
          Sentry.captureException(
            new Error(
              "Error at creation of new application after login (Connect Application)."
            ),
            {
              tags: {
                error: error.message,
                order_id: orderID,
              },
            }
          );
        }

        // Send housing questionnaire to profile service
        try {
          await this.createOrUpdatedHousingQuestionnaire(
            this.profileData?.okta_id,
            orderID,
            formioData.data.application_id,
            this.housingQuestionnaire
          );
        } catch (error) {
          Sentry.captureException(
            new Error(
              "Error at creation of new application after login (Housing Questionnaire)."
            ),
            {
              tags: {
                error: error.message,
                order_id: orderID,
              },
            }
          );
        }

        const saveForLater = this.$store.getters[
          "configurator/getSaveForLater"
        ];
        // clear the current order in vuex
        await this.$store.dispatch("configurator/resetCurrentOrder");
        // clear housing in vuex
        await this.$store.dispatch("configurator/resetHousingQuestionnaire");
        await this.$store.dispatch("configurator/resetConfiguratorIncomplete");
        await this.$store.dispatch("configurator/setSaveForLater", false);

        if (saveForLater) {
          this.$router.push({ name: "applications" });
        } else {
          // Redirect to continue V3 app
          this.$router.push({
            name: "application-resume",
            params: { applicationId: formioData.data.application_id },
          });
        }
      } catch (error) {
        this.orderFailed = true;
        Sentry.captureException(
          new Error("Error at creation of new application after login."),
          {
            tags: {
              error: error.message,
            },
          }
        );
      }
    },
  },
};
</script>
