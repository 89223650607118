<template>
  <div
    v-if="showSidebar"
    class="hidden md:block min-h-full flex w-[80px] bg-[#1f264c] top-0 left-0 flex-col space-y-1 p-1"
  >
    <template v-for="item in items">
      <router-link
        v-if="item.featureFlagOn"
        v-slot="{ navigate, isActive }"
        :key="item.id"
        :to="{ name: item.name }"
        :data-testid="item.name"
        exact
      >
        <NavItem
          class="text-center p-2 px-1 rounded text-xs relative flex flex-col items-center justify-center"
          :show-chevron="item.nestedItems !== undefined"
          :feature-flag-on="item.featureFlagOn"
          :name="getIconDisplayName(item)"
          :icons="item.icons"
          :nested-items="item.nestedItems"
          :is-active="isActive"
          @click="navigate"
        />
      </router-link>
    </template>
  </div>
  <div v-else />
</template>

<script>
import NavItem from "./NavItem";
import { sidebarMixin } from "@/mixins/sidebar.js";

export default {
  name: "DesktopSideBar",
  components: { NavItem },
  mixins: [sidebarMixin],
  methods: {
    getIconDisplayName: function (item) {
      return item?.meta?.displayName || "";
    },
  },
};
</script>
