import { getApiClient } from "./API";

const application = {
  async getOptionsInitialData() {
    return (await getApiClient().get("/initial-data/options")).data;
  },
  async getUserInitialData(countries, school, cipCodes) {
    return (
      await getApiClient().get(
        `/initial-data/user?countries=${countries}&school=${school}&cipCodes=${cipCodes}`
      )
    ).data;
  },
  async createV3Application(payload) {
    return getApiClient().post("/v3-applications/application", payload);
  },
  async updateV3Application(applicationId, payload) {
    return getApiClient().put(
      `/v3-applications/application/${applicationId}`,
      payload
    );
  },
  async recordDeadlineEvent(applicationId, payload) {
    return getApiClient().post(
      `/v3-applications/application/${applicationId}/deadline-event`,
      payload
    );
  },
};

export const clearCacheRequest = async (type) => {
  const response = await getApiClient().get("/cache/clear", { params: { type } });
  return response;
};

export default application;
