<template>
  <Dialog
    class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
    @closeModal="closeModal"
    @click.self="closeModal"
  >
    <div
      class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-10 sm:rounded-lg sm:shadow z-20 relative"
    >
      <div class="absolute xs:top-1r sm:top-1r md:top-1r lg:top-1r right-1r">
        <button
          class="h-6 w-6 flex items-center justify-center"
          @click="closeModal"
        >
          <span class="sr-only">Close</span>
          <CloseIcon />
        </button>
      </div>
      <DialogPanel>
        <DialogTitle
          class="flex justify-center mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl my-10"
        >
          Save Before Proceeding
        </DialogTitle>
        <DialogDescription>
          <ExclamationTriangleIcon
            class="text-help-warning w-16 h-16 mx-auto"
          />
          <p class="text-center mt-10 mb-2">
            {{ content }}
          </p>
          <div
            v-show="errorMessage"
            class="block text-sm error text-error-900 text-center"
          >
            {{ errorMessage }}
          </div>
        </DialogDescription>
        <div class="flex justify-center mt-14">
          <BaseButton
            class="mr-10"
            outlined
            @click.self="onContinueWithoutSaving"
          >
            Continue Without Saving
          </BaseButton>
          <BaseButton v-if="housingValid" @click.self="saveModal">
            Save
          </BaseButton>
          <BaseButton v-else @click.self="closeModal">
            Continue Editing
          </BaseButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from "@headlessui/vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";

const props = defineProps({
  housingName: String,
  errorMessage: String,
  housingValid: Boolean,
});
const emit = defineEmits(["onCancel", "onSave", "onContinueWithoutSaving"]);
function saveModal() {
  emit("onSave");
}
function closeModal() {
  emit("onCancel");
}

function onContinueWithoutSaving() {
  emit("onContinueWithoutSaving");
}

const acceptanceText = computed(() => {
  return props.housingValid ? "Save" : "Continue Editing";
});

const content = computed(() => {
  return `You have unsaved changes on ${props.housingName}. Do you want to save before proceeding?`;
});

//
</script>
