<template>
  <div>
    <Modal
      :title="title"
      :open="open && !isLoading"
      :show-logo="false"
      @closeModal="closeModal"
    >
      <template #modal-content>
        <component
          :is="currentTab"
          :is-loading="isLoading"
          :housing-data="housing"
          @closeModal="closeModal"
          @form="finalStep"
          @next="nextStep"
        ></component>
      </template>
    </Modal>
    <Modal
      title="Save Unsuccessful"
      :open="isLoading"
      :show-logo="false"
      @closeModal="closeModal"
    >
      <template #modal-content>
        <HousingErrorModal :title="title" @backEvent="backEvent">
          <span>'{{ housing.name }}' has NOT been saved successfully.</span>
        </HousingErrorModal>
      </template>
    </Modal>
    <div class="text-center text-gray-600">
      <BaseButton
        type="button"
        variant="primary"
        variant-type="block"
        outlined
        :on-click="openModal"
        :disabled="isDisabled"
      >
        New
      </BaseButton>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from "vuex";
import { HOUSING_TYPES } from "../../../constants";
import Modal from "../Modal.vue";
import CreateHousing from "./CreateHousing.vue";
import HousingForm from "./HousingForm.vue";
import HousingErrorModal from "./HousingErrorModal.vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";

const defaultHousingData = {
  name: undefined,
  type: "Apartment",
  genericListing: "no",
};

export default {
  name: "HousingsModals",
  components: {
    Modal,
    CreateHousing,
    HousingForm,
    HousingErrorModal,
    BaseButton,
  },
  props: {
    isDefaultOpen: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: this.isDefaultOpen,
      title: "Creating Housing",
      housing: { ...defaultHousingData },
      isLoading: false,
      currentTab: "CreateHousing",
      tabs: ["CreateHousing", "HousingForm", "HousingErrorModal"],
    };
  },
  computed: {
    ...mapGetters("programManager", [
      "getCurrentEditing",
      "getInvalidCreatNewHouse",
    ]),
  },
  watch: {
    getInvalidCreatNewHouse: {
      handler(current) {
        if (current) {
          this.isLoading = true;
          this.currentTab = this.tabs[2];
        }
      },
    },
    getCurrentEditing: {
      handler(current) {
        if (current.id) {
          this.isLoading = false;
          this.open = false;
          this.currentTab = this.tabs[0];
          this.$router.push({
            name: "housing-edit",
            params: { itemId: current.id },
          });
        }
      },
    },
  },
  created() {
    const housingType = HOUSING_TYPES.filter(
      (item) => item.param === this.$route.params.housingType
    );
    if (housingType && housingType.length > 0) {
      this.nextStep({ operation: "Create ", type: housingType[0].type });
    }
  },
  methods: {
    openModal() {
      this.open = true;
    },
    nextStep({ operation, type }) {
      this.title = operation + type;
      this.housing.type = type;
      this.currentTab = this.tabs[1];
    },
    finalStep(message) {
      this.isLoading = false;
      this.housing.name = message.name;
      this.housing.genericListing = message.genericListing;
      this.createHousingRequest({
        name: this.housing.name,
        status: "Draft",
        type: this.housing.type,
        generic_listing: this.housing.genericListing === "yes",
      });
    },
    backEvent() {
      this.isLoading = false;
      this.currentTab = this.tabs[1];
    },
    closeModal() {
      this.isLoading = false;
      this.housing = { ...defaultHousingData };
      this.open = false;
      this.currentTab = this.tabs[0];
    },
    ...mapActions("programManager", ["createHousingRequest"]),
  },
};
</script>
