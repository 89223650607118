<template>
  <FormPanel
    :id="'singleRoomPreference'"
    :title="'Single Room Preference'"
    :disabled-panel="disabledPanel"
    :initially-expanded="!disabledPanel"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <p class="mb-6">
        The standard room is a double - most participants share a room with a
        roommate. Single rooms may be requested, and they are available on a
        first come, first serve basis*. Single rooms may not be available for
        any hotel stays during orientation or API excursions.
      </p>
      <div class="grid grid-cols-1 gap-y-2">
        <div v-for="option in SINGLE_ROOM_OPTIONS" :key="option.value">
          <label :for="'singleRoom' + option.value" class="flex mb-2">
            <input
              :id="'singleRoom' + option.value"
              v-model.trim="v$.value.singleRoom.$model"
              type="radio"
              name="single-room"
              :value="option.value"
              class="mt-1 form-radio"
              :class="[
                { 'bg-error-100': v$.value.singleRoom.$error },
                `${primaryColorClass}`,
              ]"
              aria-controls="api-single-room"
            />
            <span class="ml-2">{{ option.label }}</span>
          </label>

          <div
            v-if="option.value === 'yes' && value.singleRoom === 'yes'"
            class="mt-4 mb-4 ml-12 space-y-4"
          >
            <label
              v-for="yesOption in YES_OPTIONS"
              :key="yesOption.value"
              class="flex"
              :for="yesOption.value"
            >
              <input
                :id="yesOption.value"
                v-model.trim="v$.value.singleRoomType.$model"
                type="radio"
                :value="yesOption.value"
                name="yes-single-room"
                class="mt-1 form-radio"
                :class="[
                  { 'bg-error-100': v$.value.singleRoomType.$error },
                  `${primaryColorClass}`,
                ]"
              />
              <span class="ml-2">{{ yesOption.label }}</span>
            </label>
            <div
              v-if="
                v$.value.singleRoomType.required.$invalid &&
                v$.value.singleRoomType.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </div>
        </div>
        <div
          v-if="
            v$.value.singleRoom.required.$invalid && v$.value.singleRoom.$error
          "
          class="error text-error-900 text-sm"
        >
          Field is required
        </div>
      </div>
      <p>
        *If you will require specific accommodations based on medical or other
        special needs please let your Program Coordinator know.
      </p>
    </template>
  </FormPanel>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import housingForm from "../../../mixins/housingForm.js";

const SINGLE_ROOM_OPTIONS = [
  {
    value: "yes",
    label: "Yes, I would like to request a single room.",
  },
  {
    value: "no",
    label: "No, I would like a double room.",
  },
];

const YES_OPTIONS = [
  {
    value: "prefer",
    label:
      "I PREFER a single room but I do not want to pay extra to request it. I acknowledge that single rooms will first be given to students who pay the additional fee, and any extra single rooms will be assigned randomly. I understand that checking this box does not guarantee that I will get a single room.",
  },
  {
    value: "request",
    label:
      "I REQUEST a single room, and I agree to submit the reservation fee. I understand that checking this box does not guarantee that I will get a single room and that I will be refunded if a single room cannot be reserved. Contact your Program Coordinator for fees.",
  },
];

export default {
  name: "SingleRoom",
  mixins: [housingForm],
  props: {
    singleRoom: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:single-room"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        singleRoom: "",
        singleRoomType: "",
      },
    };
  },
  validations: {
    value: {
      singleRoom: { required },
      singleRoomType: {
        required: requiredIf(function () {
          return this.value.singleRoom === "yes";
        }),
      },
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update:single-room", this.value);
      },
      deep: true,
      immediate: true,
    },
    "value.singleRoom": {
      handler: function (val) {
        if (val === "no") this.value.singleRoomType = "";
      },
    },
  },
  created() {
    this.SINGLE_ROOM_OPTIONS = SINGLE_ROOM_OPTIONS;
    this.YES_OPTIONS = YES_OPTIONS;
  },
};
</script>
