<script setup>
import { formattedPriceFromCentsWithoutCents as formattedPrice } from "@/util/formatter";
import { defineEmits, toRefs } from "vue";

const props = defineProps({
  selectedSession: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["preview"]);
const { selectedSession } = toRefs(props);
</script>

<template>
  <div class="font-bold text-indigo-base text-base">
    <p class="font-bold text-indigo-base text-base">
      Starting Price:
      {{ formattedPrice(selectedSession?.base_price_in_cents) }}
    </p>

    <button class="underline" 
      :class="[secondaryColor ? `${secondaryColorClass}` : 'text-university-secondary']"
      @click="emit('preview', true)"
    >
      See price breakdown here
    </button>
  </div>
</template>

<style scoped></style>
