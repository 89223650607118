<template>
  <section class="mt-4">
    <h2 :class="`${primaryColorClass}`">
      IMPORTANT: Please only list the financial aid amount(s) approved for your
      study abroad term/session.
    </h2>
    <div class="grid gap-4 grid-cols-3 mt-2 text-center">
      <div class="hidden sm:block">Award type/Name</div>
      <div class="hidden sm:block">Disbursement date</div>
      <div class="hidden sm:block">Total Award Amount</div>
    </div>

    <AwardItem
      v-for="(item, index) in value.awards"
      :key="index"
      :item="value.awards[index]"
      :row="index"
      :submitted-data="{
        award: submittedData['awardType' + (index + 1)],
        amount: submittedData['awardAmount' + (index + 1)],
        date: submittedData['disbursementDate' + (index + 1)],
      }"
      @update:single-award="value.awards[index] = $event"
    />

    <div class="flex xs:justify-start sm:justify-end font-semibold mt-4">
      <p>Deductions:</p>
    </div>
    <div class="grid grid-cols-1">
      <div class="flex flex-col xs:items-start sm:items-end mb-2">
        <label
          class="flex flex-col w-full sm:flex-row sm:w-auto xs:items-start sm:items-center xs:justify-start sm:justify-end text-gray-600"
        >
          <span>University Fees:</span>
          <input
            id="university-fee"
            v-model="value.universityFee"
            type="text"
            class="form-input min-h-10 sm:ml-4 xs:w-full sm:w-auto"
            :class="{
              'bg-error-100': v$.value.universityFee.$error,
            }"
            maxlength=""
          />
        </label>
        <p
          v-if="v$.value.universityFee.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.universityFee.$error }"
        >
          {{ validationMessage["required"] }}
        </p>
        <p
          v-if="v$.value.universityFee.decimal.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.universityFee.$error }"
        >
          {{ validationMessage["decimal"] }}
        </p>
        <p
          v-if="
            v$.value.universityFee.mustNotHaveHyphen.$invalid &&
            !v$.value.universityFee.decimal.$invalid
          "
          class="error text-error-900"
          :class="{ hidden: !v$.value.universityFee.$error }"
        >
          {{ validationMessage["mustNotHaveHyphen"] }}
        </p>
      </div>
      <div class="flex flex-col xs:items-start sm:items-end mb-2">
        <label
          class="flex flex-col w-full sm:flex-row sm:w-auto xs:items-start sm:items-center xs:justify-start sm:justify-end text-gray-600"
        >
          <span>Loan Fees:</span>
          <input
            id="loan-fee"
            v-model="value.loanFee"
            type="text"
            class="form-input min-h-10 sm:ml-4 xs:w-full sm:w-auto"
            :class="{
              'bg-error-100': v$.value.loanFee.$error,
            }"
            maxlength=""
          />
        </label>
        <p
          v-if="v$.value.loanFee.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.loanFee.$error }"
        >
          {{ validationMessage["required"] }}
        </p>
        <p
          v-if="v$.value.loanFee.decimal.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.loanFee.$error }"
        >
          {{ validationMessage["decimal"] }}
        </p>
        <p
          v-if="
            v$.value.loanFee.mustNotHaveHyphen.$invalid &&
            !v$.value.loanFee.decimal.$invalid
          "
          class="error text-error-900"
          :class="{ hidden: !v$.value.loanFee.$error }"
        >
          {{ validationMessage["mustNotHaveHyphen"] }}
        </p>
      </div>
      <div class="flex flex-col xs:items-start sm:items-end mb-2">
        <label
          class="flex flex-col w-full sm:flex-row sm:w-auto xs:items-start sm:items-center xs:justify-start sm:justify-end text-gray-600"
        >
          <span class="font-semibold">Total Financial Aid Amount:</span>
          <input
            id="total-aid"
            v-model="value.totalFee"
            type="text"
            class="form-input min-h-10 sm:ml-4 xs:w-full sm:w-auto"
            :class="{
              'bg-error-100': v$.value.totalFee.$error,
            }"
            maxlength=""
          />
        </label>
        <p
          v-if="v$.value.totalFee.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.totalFee.$error }"
        >
          {{ validationMessage["required"] }}
        </p>
        <p
          v-if="v$.value.totalFee.decimal.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.totalFee.$error }"
        >
          {{ validationMessage["decimal"] }}
        </p>
        <p
          v-if="
            v$.value.totalFee.mustNotHaveHyphen.$invalid &&
            !v$.value.totalFee.decimal.$invalid
          "
          class="error text-error-900"
          :class="{ hidden: !v$.value.totalFee.$error }"
        >
          {{ validationMessage["mustNotHaveHyphen"] }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { required, decimal } from "@vuelidate/validators";
import validationMessage from "../../mixins/validationMessages";
import AwardItem from "./AwardItem.vue";
import { mustNotHaveHyphen } from "../../mixins/customValidators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "AwardsInputs",
  components: { AwardItem },
  mixins: [validationMessage],
  props: {
    awards: {
      type: Object,
      default: function () {
        return {};
      },
    },
    submittedData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:awards"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        awards: [
          {
            award: "",
            amount: "",
            date: "",
          },
          {
            award: "",
            amount: "",
            date: "",
          },
          {
            award: "",
            amount: "",
            date: "",
          },
          {
            award: "",
            amount: "",
            date: "",
          },
          {
            award: "",
            amount: "",
            date: "",
          },
        ],
        universityFee: "",
        loanFee: "",
        totalFee: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:awards", this.value);
      },
      deep: true,
    },
  },
  created() {
    if (this.submittedData.universityFees) {
      this.value.universityFee = this.submittedData.universityFees.trim();
    }
    if (this.submittedData.loanFees) {
      this.value.loanFee = this.submittedData.loanFees.trim();
    }
    if (this.submittedData.totalFinancialAid) {
      this.value.totalFee = this.submittedData.totalFinancialAid.trim();
    }
  },

  validations: {
    value: {
      universityFee: {
        required,
        decimal,
        mustNotHaveHyphen,
      },
      loanFee: {
        required,
        decimal,
        mustNotHaveHyphen,
      },
      totalFee: {
        required,
        decimal,
        mustNotHaveHyphen,
      },
      awards: {},
    },
  },
};
</script>
