<template>
  <div v-if="open">
    <div class="fixed inset-0 z-50 py-6 bg-transparent modal sm:p-12">
      <div
        class="max-w-xs sm:max-w-lg px-6 mx-auto bg-white py-5 rounded-lg shadow-lg z-20 relative border border-solid border-gray-300"
      >
        <label class="flex items-center text-gray-600">
          <span>URL</span>
          <input
            v-model.trim="url"
            data-cy="url"
            type="text"
            placeholder=""
            class="form-input min-h-10 ml-4 block w-full"
          />
        </label>
        <div class="flex flex-none mt-5 justify-end">
          <button
            data-cy="cancel-url-button"
            :class="[
              secondaryColor
                ? `${secondaryColorClassOutlined}`
                : `${secondaryColorClassOutlined} hover:text-teal-100 focus:text-teal-100`,
            ]"
            class="inline-block text-center rounded font-semibold border-2 border-transparent text-sm px-3 py-1 bg-white hover:text-white mr-2"
            @click="$emit('cancel')"
          >
            <span>Cancel</span>
          </button>
          <button
            data-cy="set-url-button"
            :class="[
              secondaryColor
                ? 'brandingSecondary'
                : 'bg-university-secondary hover:bg-teal-100 focus:bg-teal-100',
            ]"
            class="inline-block text-center rounded font-semibold border-2 border-transparent text-sm px-3 py-1 text-white hover:text-white focus:text-white"
            @click="$emit('setUrl', url)"
          >
            <span>OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    previousUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["cancel", "setUrl"],
  data() {
    return {
      url: "",
    };
  },
  watch: {
    previousUrl: {
      handler: function (val) {
        this.url = val;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.modal {
  top: 50%;
  transform: translateY(-50%);
}
</style>
