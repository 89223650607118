<script setup>
import PlusIcon from "@/components/shared/icons/PlusIcon.vue";
import { computed, defineProps, defineEmits, ref, watch } from "vue";
import { useVModel } from "@vueuse/core";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import { cloneDeep } from "lodash";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["update:modelValue"]);
const programPage = useVModel(props, "modelValue", emit);

const isTestimonial = ref(null);

watch(isTestimonial, (val) => {
  val ? addTestimonial() : deleteTestimonial();
});

const testimonials = computed(() => {
  return programPage.value.program_page_testimonials;
});

programPage.value.program_page_testimonials =
  cloneDeep(testimonials.value) || [];

const deleteTestimonial = (index) => {
  programPage.value.program_page_testimonials.splice(index, 1);
};
const resetTestimonial = (testimonial) => {
  testimonial.learner_quote = undefined;
  testimonial.learner_name = undefined;
  testimonial.learner_details = undefined;
};

const addTestimonial = () => {
  programPage.value.program_page_testimonials.push({
    program_page_id: programPage.value.id || undefined,
    learner_quote: undefined,
    learner_name: undefined,
    learner_details: undefined,
  });
};
</script>
<template>
  <FormPanel
    :id="'testimonial'"
    :title="'TESTIMONIAL'"
    class="mt-6 border-0"
    panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
  >
    <template #content>
      <div class="flex">
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="testimonial"
            v-model="isTestimonial"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
            type="checkbox"
            :checked="testimonials.length > 0"
          />
          <label
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
          ></label>
        </div>
        <label class="ml-3 block text-sm text-gray-700"
          >Add learner testimonial to program page
        </label>
        <PlusIcon
          v-if="testimonials.length > 0"
          class="ml-auto text-teal-900 w-6 h-6 cursor-pointer"
          @click="addTestimonial"
        />
      </div>
      <div v-for="(testimonial, index) in testimonials" :key="index">
        <div class="border-2 border-dashed border-gray-300 mb-4 mt-8">
          <div class="flex p-4 bg-white items-center border-gray-300 border-b">
            <div class="font-semibold text-gray-700">
              Testimonial {{ index + 1 }}
            </div>
            <div
              class="ml-auto cursor-pointer"
              @click="
                () =>
                  testimonials.length > 1
                    ? deleteTestimonial(index)
                    : resetTestimonial(testimonial)
              "
            >
              <i class="fa fa-trash text-gray-650"></i>
            </div>
          </div>
          <div class="p-4">
            <div class="w-full mb-4">
              <label for="quote" class="block text-sm font-medium text-gray-700"
                >Learner Quote</label
              >
              <div class="mt-1">
                <textarea
                  id="quote"
                  v-model="testimonial.learner_quote"
                  rows="12"
                  name="quote"
                  class="block w-full form-input sm:text-sm"
                />
              </div>
            </div>
            <div class="mb-4">
              <label name="name" class="block text-sm font-medium text-gray-700"
                >Learner Name</label
              >
              <div class="flex">
                <input
                  id="name"
                  v-model="testimonial.learner_name"
                  type="text"
                  name="name"
                  class="h-10 w-full form-input"
                />
              </div>
            </div>
            <div>
              <label
                name="details"
                class="block text-sm font-medium text-gray-700"
                >Learner Details</label
              >
              <div class="flex">
                <input
                  id="details"
                  v-model="testimonial.learner_details"
                  type="text"
                  name="details"
                  class="h-10 w-full form-input"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </FormPanel>
</template>
