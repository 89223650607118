export const columnsConfig = [
  {
    id: "id",
    header: "",
    size: 60,
    type: "checkbox",
    getColumnDisplayValue: () => "",
  },
  {
    id: "title",
    header: "Name",
    size: 400,
    getColumnDisplayValue: ({ title }) => title || "",
  },
  {
    id: "category_name",
    header: "Category",
    size: 400,
    getColumnDisplayValue: ({ category_name }) => category_name || "",
  },
  {
    id: "location",
    header: "Location",
    size: 200,
    getColumnDisplayValue: ({ location }) => location || "",
  },
  {
    id: "duration",
    header: "Duration",
    size: 200,
    getColumnDisplayValue: ({ duration }) => duration,
  },
  {
    id: "type",
    header: "Mode of Experience",
    size: 400,
    getColumnDisplayValue: ({ type }) => type || "",
  },
  {
    id: "host",
    header: "Event Host",
    size: 200,
    getColumnDisplayValue: ({ host }) => host,
  },
  {
    id: "product_price",
    header: "Price",
    size: 200,
    getColumnDisplayValue: ({ product_price }) => product_price,
  },
];

export const ERROR_MESSAGE =
  "An error occurred when trying to update excursions data. Please try again.";

export const tableHeaders = [
  "Excursions Name",
  "Duration",
  "Date & Time",
  "Price",
  "Status",
];
