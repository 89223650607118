<script setup>
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { computed, defineEmits, defineProps, watch } from "vue";

defineEmits(["remove", "bypassValidation"]);

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  id: {
    type: Number,
    default: 0,
  },
});

const cardId = computed(() => {
  return "cardName" + props.id;
});
const templateId = computed(() => {
  return "tippyTemplate" + props.id;
});
const divElement = computed(() => {
  return document.getElementById(cardId.value);
});

const citySemesterLabel = computed(() => {
  let cityLabel = props.item.cityCountrySession.city;
  const country = props.item.cityCountrySession.country;

  const semester = props.item?.semester ?? "";
  const year = props.item?.year ?? "";
  const semesterLabel = `${semester} ${year}`;
  const join = semesterLabel.length && cityLabel.length ? "|" : "";

  if (country.length && cityLabel.length) {
    cityLabel += `, ${country}`;
  } else if (!cityLabel.length && country.length) {
    cityLabel = country;
  }

  return `${cityLabel} ${join} ${semesterLabel}`;
});

watch(
  () => props.item,
  (newValue) => {
    if (divElement.value && newValue.id === "bypassId") {
      const template = document.getElementById(templateId.value);

      tippy(divElement.value, {
        content: template.innerHTML,
        allowHTML: true,
        theme: "light",
        role: "tooltip",
        placement: "top-end",
        maxWidth: 318,
        showOnCreate: true,
      });
    }
  },
  { deep: true }
);
</script>
<template>
  <div
    class="w-full max-w-[228px]"
    :class="
      Object.keys(props.item).length
        ? `border-3 bg-white ${secondaryColorClassOutlinedNoHover} text-sm py-3 px-1 relative`
        : `border-2 ${primaryColorClassBG} border-dashed border-[#C0C0C0] text-center text-xs p-2`
    "
  >
    <p
      :id="cardId"
      class="text-indigo-base font-bold"
      :class="{ hidden: !Object.keys(props.item).length }"
    >
      <slot name="item-name"> {{ item.name }}</slot>
    </p>
    <div :id="templateId" class="hidden relative">
      <div class="text-black m-3">
        <p class="text-xl font-semibold mb-2 inline-block">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block align-middle"
          >
            <path
              d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
              fill="#41BEB2"
            />
          </svg>

          FYI
        </p>
        <p class="text-sm font-medium">
          No worries, continue your application and we will reach out to advise
          on additional placements that may be a fit.
        </p>
      </div>
      <div class="absolute top-0 right-0">
        <button class="block h-6 w-6 items-center justify-center">
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </div>
    <template v-if="Object.keys(props.item).length">
      <p
        v-if="item.country || item.semester || item.year"
        class="text-indigo-light font-medium"
        :class="{ hidden: !Object.keys(props.item).length }"
      >
        {{ citySemesterLabel }}
      </p>
      <div
        class="absolute top-0 right-0"
        :class="{ hidden: !Object.keys(props.item).length }"
      >
        <button
          class="block h-6 w-6 items-center justify-center"
          @click="$emit('remove', props.item.id)"
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </template>

    <template v-if="!Object.keys(props.item).length && required">
      <p class="text-white">
        Add one more internship to personalize your experience.
      </p>

    </template>
  </div>
</template>
