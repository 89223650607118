<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps, ref } from "vue";
import { useVModel } from "@vueuse/core";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";

const props = defineProps({
  modelValue: {
    type: Object,
  },
});

const events = useVModel(props, "modelValue");

const type = ref(events.value["Mode of Experience"]);
const locationType = ref(events.value["Location Type"]);
const price = ref(events.value["Price per Student"]);
</script>
<template>
  <div class="relative">
    <div class="grid xs:grid-cols-2 mt-4 sm:grid-cols-2 gap-4">
      <div class="text-base font-medium text-gray-700">
        Event Category:
        <span v-if="events.Category" class="font-semibold">{{
          events.Category
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Mode of experience:
        <span v-if="type" class="font-semibold">{{ type }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Location type:
        <span v-if="locationType" class="font-semibold">{{
          locationType
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Price per student:
        <span v-if="price" class="font-semibold">$ {{ price }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        How long is the event:
        <span v-if="events.Duration" class="font-semibold"
          >{{ events.Duration }} {{ events.duration_units }}</span
        >
      </div>
      <div class="text-base mb-4 font-medium text-gray-700">
        Description:
        <JSONtoHTML
          v-if="events.Description"
          class="font-semibold"
          :tiptap-json="events.Description"
        />
      </div>
      <div class="text-base font-medium text-gray-700">
        Host:
        <span class="font-semibold">Academic Programs International</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Tags:
        <span v-if="events.Tags" class="font-semibold">{{
          events?.Tags?.map((item) => item.name).join(", ") || ""
        }}</span>
      </div>
    </div>
  </div>
</template>
