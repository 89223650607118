<template>
  <div class="min-h-screen items-center">
    <template v-if="isLoading || !Boolean(formioToken)">
      <spinner>
        <template v-if="Boolean(formioToken)" #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
        </template>
      </spinner>
    </template>
    <div>
      <div
        class="w-full px-4 py-6 mx-auto bg-blue-100 max-w-7xl sm:px-16 sm:py-10"
      >
        <step-tracker
          :forms="forms"
          :application-submissions="applicationSubmissions"
          @go-to-step="goToStep"
        />
      </div>
      <div class="w-full mx-auto max-w-7xl md:px-8 md:mb-8">
        <div
          class="px-4 py-6 bg-white border-t border-gray-200 dashboard sm:grid sm:grid-cols-12 sm:gap-6 md:gap-12 sm:px-8 md:p-12 md:border md:rounded"
        >
          <section class="dashboard__body sm:col-span-7 lg:col-span-8">
            <component
              :is="currentComponent"
              v-if="ready"
              :application-id="getApplicationId"
              :in-progress="inProgress"
              :form-url="programSelectionURL"
              :secondary-application-id="secondaryApplicationId"
              :application-submissions="applicationSubmissions"
              @form-submit="changeStep"
              @select-program="selectProgram"
              @set-application-id="setNewApplicationId"
              @set-secondary-application-id="setNewSecondaryApplicationId"
            />
          </section>
          <application-sidebar :forms-list="forms" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AcademicProfile from "./AcademicProfile";
import PayDepositFormComponent from "../PayDeposit/FormComponent";
import NextSteps from "./NextSteps";
import studentApplication from "../../../mixins/studentApplication.js";
import forms from "../../../mixins/forms.js";
import ProgramSelectionForm from "../ProgramSelection/ProgramSelectionForm.vue";
import Spinner from "../../helpers/Spinner.vue";
import ConfirmationPage from "../customProgram/ConfirmationPage.vue";

export default {
  name: "StepsLayout",
  components: {
    Spinner: Spinner,
    "program-selection": ProgramSelectionForm,
    "academic-profile": AcademicProfile,
    "institution-pays": PayDepositFormComponent,
    "pay-deposit": PayDepositFormComponent,
    "next-steps": NextSteps,
    "program-confirmation": ConfirmationPage,
  },
  mixins: [studentApplication, forms],
  props: {
    slug: {
      type: String,
      default: "",
    },
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formURL: "/api/forms/programselection",
    };
  },
  computed: {
    ...mapState(["formioToken"]),
    message() {
      return "Loading application information";
    },
    currentComponent() {
      return this.slug;
    },
    programSelectionURL() {
      let url = this.formURL;
      let activeFormSubmissions = this.getFormSubmissions(
        this.forms[0],
        this.getApplicationId
      );

      if (activeFormSubmissions)
        url += "/submission/" + activeFormSubmissions[0]._id;

      return url;
    },
  },
  watch: {
    currentStep: {
      handler: function (newVal) {
        this.validateCurrentStep(newVal);
      },
    },
  },
  methods: {
    validateCurrentStep(currentIndex) {
      //Redirect to last step if the current form is the payment or program selection
      let isProgramSelection =
        (this.$route.params.slug === "program-selection" ||
          this.$route.params.slug === "program-confirmation") &&
        currentIndex === 0;
      if (
        (currentIndex === this.paymentFormIndex || isProgramSelection) &&
        this.applicationPaid
      ) {
        let lastForm = this.forms[this.forms.length - 1];
        this.currentStep = this.forms.findIndex((form) => {
          return form.slug === lastForm.slug;
        });
        this.$router.replace({
          path: "/applications/" + this.getApplicationId + "/" + lastForm.slug,
        });
      }
    },
  },
};
</script>
