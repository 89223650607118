export default {
  methods: {
    doesElementIdExists(elementId) {
      if (!elementId) return false;
      return document.getElementById(elementId) !== null;
    },
    addChildToElement(elementId, newElement, prepend = false) {
      if (prepend)
        return document.getElementById(elementId).prepend(newElement);
      return document.getElementById(elementId).append(newElement);
    },
    changeInnerText(elementId, text) {
      document.getElementById(elementId).innerText = text;
    }
  },
};
