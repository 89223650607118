<template>
  <Spinner v-if="state.loading" class="relative h-65vh bg-blue-100" />
  <component
    :is="state.currentComponent"
    v-else
    :units="state.units"
    :unit="state.selectedUnit"
    :show-warning="state.showWarning"
    :default-room="program?.programSession?.default_room"
    :selected-rooms="selectedRooms"
    :program="program"
    :room-prices="housing.roomPrices"
    :is-pre-select-housing="disabledDeselect"
    @dismiss-warning="() => (state.showWarning = false)"
    @back-to-previous-step="backToPreviousStep"
  />
</template>

<script setup>
import Spinner from "@/components/helpers/Spinner.vue";
import getApplicableContract from "@/composables/useBillings.js";
import {
  computed,
  inject,
  markRaw,
  onMounted,
  provide,
  reactive,
  readonly,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import HousingStepListing from "./HousingStepListing.vue";
import HousingStepProductDetail from "./HousingStepProductDetail.vue";
import HousingStepQuestionnaire from "./HousingStepQuestionnaire.vue";

const store = useStore();
const { program } = inject("program");
const { housing, setRoomIds } = inject("housing");
const contracts = inject("contracts");
const { goToNextStep } = inject("steps");

const state = reactive({
  loading: false,
  units: [],
  currentComponent: markRaw(HousingStepListing),
  pastComponent: undefined,
  selectedUnit: undefined,
  showWarning: true,
});

const selectedRooms = computed(() => housing.roomIds);

const sessionHasDefaultRoom = computed(
  () => !!program?.programSession?.default_room?.id || selectedRooms?.value?.length >= 1
);

const selectUnit = (unit) => {
  // Enters unit so we can select a room
  state.selectedUnit = unit;
  state.currentComponent = markRaw(HousingStepProductDetail);
};
const deselectUnit = () => {
  // Goes back to Housing List
  state.selectedUnit = undefined;
  state.currentComponent = markRaw(HousingStepListing);
};
const setRoom = (id) => {
  // Adds room to selectedRooms or de-selects it.
  if (disabledDeselect.value) return;
  if (selectedRooms.value.includes(id)) {
    // deselect room, already selected
    setRoomIds(
      selectedRooms.value.filter((selectedRoomId) => selectedRoomId !== id)
    );
  } else {
    if (housing.limit === selectedRooms.value.length) {
      setRoomIds([id]); // Replace room
    } else {
      setRoomIds([...selectedRooms.value, id]); // Adds room
    }
  }
};

const backToPreviousStep = () => {
  state.currentComponent = state.pastComponent;
};

provide("selectUnit", selectUnit);
provide("deselectUnit", deselectUnit);
provide("setRoom", setRoom);

const toQuestionnaire = () => {
  const featureFlags = store.state.featureFlags;
  if (
    !Boolean(featureFlags["disable-configurator-housing-questionnaire"]) &&
    sessionHasDefaultRoom.value
  ) {
    state.currentComponent = markRaw(HousingStepQuestionnaire);
  } else {
    if (
      housing?.questionnaire?.question_responses?.length > 0 &&
      sessionHasDefaultRoom.value
    ) {
      state.currentComponent = markRaw(HousingStepQuestionnaire);
    } else {
      goToNextStep();
    }
  }
};
const toSelectRoom = () => {
  state.currentComponent = markRaw(HousingStepProductDetail);
};

provide("toQuestionnaire", toQuestionnaire);
provide("toSelectRoom", toSelectRoom);

const findSingleActiveUnit = () => {
  const activeUnits = state.units.filter(
    (unit) => unit.unit.status === "Active"
  );
  if (activeUnits.length === 1) {
    return activeUnits[0].unit;
  }
  return null;
};

const disabledDeselect = ref(false);

const findSingleActiveRoom = (activeUnit) => {
  const activeRooms = activeUnit.rooms.filter(
    (room) => room.status === "Active"
  );
  if (activeRooms.length === 1) {
    return activeRooms[0];
  }
  return null;
};

const preSelectHousing = () => {
  const activeUnit = findSingleActiveUnit();
  if (!activeUnit) {
    return;
  }
  selectUnit(activeUnit);
  const activeRoom = findSingleActiveRoom(activeUnit);
  if (!activeRoom) {
    return;
  }
  if (selectedRooms.value.length === 0 && sessionHasDefaultRoom.value) {
    setRoom(activeRoom.id);
  }
  if (sessionHasDefaultRoom.value) {
    disabledDeselect.value = true;
  }
};

provide("disabledDeselect", readonly(disabledDeselect));

watch(program, () => {
  state.units = program?.programSession?.session_units ?? [];
});

/**
 * CONTRACTS
 */
const applicableContract = computed(() =>
  getApplicableContract(contracts.value, program?.programSession)
);

watch(
  () => applicableContract.value,
  (newContract) => {
    const feeCap = Number(newContract?.housing_fee_cap ?? 0) * 100;
    const payHousing = newContract
      ? Boolean(newContract.housingFee === "Institution")
      : false;
    store.dispatch("configurator/setHousingBilling", {
      housingFeeCap: feeCap,
      institutionPayHousing: payHousing,
    });
  },
  { immediate: true, deep: true }
);

watch(
  () => state.currentComponent,
  (newComponent, oldComponent) => {
    if (newComponent !== oldComponent) {
      state.pastComponent = oldComponent;
    }
  }
);

onMounted(() => {
  state.units = program?.programSession?.session_units ?? [];
  preSelectHousing();
});
</script>
