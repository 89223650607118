<template>
  <DropdownList :options="visibleOptions">
    <IconElips />
  </DropdownList>
</template>

<script>
import IconElips from "../icons/IconElips.vue";
import DropdownList from "./DropdownList.vue";
import { isLibraryItemOwner } from "../../../composables/authorization"

export default {
  components: {
    DropdownList,
    IconElips,
  },
  props: {
    actionsRouteParams: {
      type: Object,
      default: () => {},
    },
    eventRoute: {
      type: String,
      default: "",
    },
    actionsEventParams: {
      type: Object,
      default: () => {},
    },
    permissions: {
      type: Object,
      default: () => {},
    },
    entityId: {
      type: String,
      required: false,
    },
  },
  computed: {
    options() {
      return [
        this.isEvent()
          ? {
              name: "View",
              type: "dispatch",
              event: "setViewModalStateOpen",
              payload: this.actionsEventParams,
              show: !!(this.permissions?.read_lite || this.permissions?.read),
            }
          : {
              name: "View",
              type: "navigateTo",
              routeTo: this.actionsRouteParams,
              show: !!(this.permissions?.read_lite || this.permissions?.read),
            },
        {
          name: "Edit",
          type: "navigateTo",
          routeTo: this.actionsRouteParams,
          show: this.permissions?.write && (!this.isPrograms() || this.isOwner),
        },
        {
          name: "Duplicate",
          type: "dispatch",
          event: "setDuplicateModalStateOpen",
          payload: this.actionsEventParams,
          show: !!this.permissions?.pub && !this.isPrograms() || (this.permissions?.write && this.isOwner),
        },
        {
          name: "Archive",
          type: "dispatch",
          event: "setArchivingModalStateOpen",
          payload: this.actionsEventParams,
          show: !!this.permissions?.pub && !this.isPrograms() || (this.permissions?.pub && this.isOwner),
        },
      ];
    },
    visibleOptions() {
      return this.options.filter((option) => option.show);
    },
    isOwner() {
      return isLibraryItemOwner(this.entityId);
    },
  },
  methods: {
    isEvent() {
      return this.eventRoute.includes("events");
    },
    isHousing() {
      return this.eventRoute.includes("housing");
    },
    isPrograms() {
      return this.eventRoute.includes("programs");
    },
  },
};
</script>
