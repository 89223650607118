<script setup>
import { useVuelidate } from "@vuelidate/core";
import { ValidateEach } from "@vuelidate/components";
import PlusIcon from "@/components/shared/icons/PlusIcon.vue";
import { cloneDeep } from "lodash";
import {
  defineProps,
  reactive,
  computed,
  onBeforeMount,
  defineEmits,
} from "vue";
import { useVModel } from "@vueuse/core";
import { COMMUNICATION, MOBILITY, SENSORY } from "@/constants";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["update:modelValue"]);
const events = useVModel(props, "modelValue", emit);

const locations = computed(() => {
  return events.value.event_locations;
});

const toggleMobility = (location, id) => {
  const mobility = location.mobility || [];
  if (mobility.includes(id)) {
    location.mobility = mobility.filter((i) => i !== id);
  } else {
    location.mobility = [...mobility, id];
  }
};
const toggleCommunication = (location, id) => {
  const communication = location.communication || [];
  if (communication.includes(id)) {
    location.communication = communication.filter((i) => i !== id);
  } else {
    location.communication = [...communication, id];
  }
};
const toggleSensory = (location, id) => {
  const sensory = location.sensory || [];
  if (sensory.includes(id)) {
    location.sensory = sensory.filter((i) => i !== id);
  } else {
    location.sensory = [...sensory, id];
  }
};

const deleteLocation = (index) => {
  events.value.event_locations.splice(index, 1);
};
const duplicateLocation = (index) => {
  const dup = reactive(cloneDeep(events.value.event_locations[index]));
  dup.id = undefined;
  events.value.event_locations.push(dup);
};
const addLocation = () => {
  events.value.event_locations.push({
    event_id: events?.value?.id || undefined,
    event_lat_lng: undefined,
    address: undefined,
    image: undefined,
    mobility: undefined,
    communication: undefined,
    sensory: undefined,
  });
};

const rules = {
  address: {},
  mobility: {},
  communication: {},
  sensory: {},
};

let v$ = {};
onBeforeMount(() => {
  v$ = useVuelidate({}, events, {
    $registerAs: "ProgramLocationsSection",
    $lazy: true,
  });
});
</script>
<template>
  <ValidateEach
    v-for="(location, index) in locations"
    :key="index"
    :state="location"
    :rules="rules"
  >
    <template #default="{ v }">
      <div class="border-2 border-dashed border-gray-300 mb-4">
        <div class="flex p-4 bg-white items-center">
          <div class="font-semibold text-gray-700">
            Location {{ index + 1 }}
          </div>
          <div
            class="ml-auto uppercase text-xs font-semibold text-teal-900 mr-10"
            @click="() => duplicateLocation(index)"
          >
            duplicate
          </div>
          <div @click="() => deleteLocation(index)">
            <i class="fa fa-trash text-gray-650"></i>
          </div>
        </div>
        <div class="p-4">
          <div class="flex flex-col md:flex-row">
            <div class="flex-1">
              <label class="block text-sm">
                <span class="mt-8 block text-sm font-bold text-gray-700">
                  <span
                    ><span class="text-red-100 pr-2 align-sub">**</span>Online
                    Location</span
                  >
                </span>
                <input
                  v-model="location.address"
                  aria-placeholder="‘Enter the online location (e.g., Zoom, Google Meet, MS Teams, etc’)"
                  placeholder="‘Enter the online location (e.g., Zoom, Google Meet, MS Teams, etc’)"
                  class="mt-1 block w-full bg-white rounded-md ring-1 ring-gray-300 border-gray-300 py-2 pl-3 h-10 pr-10 text-base focus:border-teal-900 focus:outline-none focus:ring-teal-900 sm:text-sm"
                />
              </label>
            </div>
          </div>
          <div class="flex flex-col md:flex-row">
            <div class="flex-1">
              <label class="block text-sm">
                <span class="mt-8 block text-sm font-bold text-gray-700">
                  <span
                    ><span class="text-red-100 pr-2 align-sub">**</span
                    >URL</span
                  >
                </span>
                <input
                  v-model="location.google_place_details"
                  placeholder="`Enter the link for the event...`"
                  aria-placeholder="`Enter the link for the event...`)"
                  class="mt-1 block w-full bg-white rounded-md ring-1 ring-gray-300 border-gray-300 py-2 pl-3 h-10 pr-10 text-base focus:border-teal-900 focus:outline-none focus:ring-teal-900 sm:text-sm"
                />
              </label>
            </div>
          </div>
          <div class="flex flex-col md:flex-row">
            <div class="flex-1">
              <div class="py-6">
                <div class="">
                  <h1 class="text-xl font-semibold leading-6 text-gray-900">
                    Accessibility
                  </h1>
                </div>
              </div>
              <div class="my-4">
                <span class="text-xs text-gray-500 font-semibold tracking-wide">
                  MOBILITY
                </span>
              </div>
              <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
                <div
                  v-for="{ id, name } in MOBILITY"
                  :key="id"
                  class="flex justify-between w-fit items-center mb-3"
                >
                  <div class="flex flex-1 items-center space-x-2">
                    <div class="flex items-center">
                      <input
                        :id="id"
                        type="checkbox"
                        :checked="location.mobility?.includes(id)"
                        class="h-5 w-5 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                        @input="() => toggleMobility(location, id)"
                      />
                    </div>
                    <div class="text-sm">
                      <label class="text-gray-700">
                        {{ name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-4">
                <span class="text-xs text-gray-500 font-semibold tracking-wide">
                  COMMUNICATION
                </span>
              </div>
              <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
                <div
                  v-for="{ id, name } in COMMUNICATION"
                  :key="id"
                  class="flex justify-between w-fit items-center mb-3"
                >
                  <div class="flex flex-1 items-center space-x-2">
                    <div class="flex items-center">
                      <input
                        :id="id"
                        type="checkbox"
                        :checked="location.communication?.includes(id)"
                        class="h-5 w-5 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                        @input="() => toggleCommunication(location, id)"
                      />
                    </div>
                    <div class="text-sm">
                      <label class="text-gray-700">
                        {{ name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-4">
                <span class="text-xs text-gray-500 font-semibold tracking-wide">
                  SENSORY
                </span>
              </div>
              <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
                <div
                  v-for="{ id, name } in SENSORY"
                  :key="id"
                  class="flex justify-between w-fit items-center mb-3"
                >
                  <div class="flex flex-1 items-center space-x-2">
                    <div class="flex items-center">
                      <input
                        :id="id"
                        type="checkbox"
                        :checked="location.sensory?.includes(id)"
                        class="h-5 w-5 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                        @input="() => toggleSensory(location, id)"
                      />
                    </div>
                    <div class="text-sm">
                      <label class="text-gray-700">
                        {{ name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ValidateEach>
  <div
    class="mt-4 bg-white border-2 border-dashed border-gray-300 rounded cursor-pointer"
    @click="addLocation"
  >
    <div class="flex items-center m-5 p-1">
      <PlusIcon class="mr-2 text-teal-900 w-10 h-10" />
      <label class="common-label-text inline">Add another location</label>
    </div>
  </div>
</template>
