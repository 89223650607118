<script>
export default {
  name: "TreeSelect",
};
</script>
<script setup>
import TreeSelect from "primevue/treeselect";
import Chip from "primevue/chip";
import CheckIcon from "primevue/icons/check";
import MinusIcon from "primevue/icons/minus";
import ChevronDownIcon from "primevue/icons/chevrondown";
import ChevronRightIcon from "primevue/icons/chevronright";
import SearchChevron from "@/components/svg-icons/SearchChevron.vue";
import { ref, computed, unref, toRefs, watch } from "vue";
import { cloneDeep } from "lodash";
import ClearAll from "@/components/shared/icons/ClearAll.vue";
import { getFullNodeMap, removeNode, addNode, setPartials } from "./utils";
import { useVModels, watchDeep } from "@vueuse/core";

const props = defineProps({
  selectedTreeNodes: {
    type: Object,
    default: () => {},
  },
  treeOptions: {
    type: Array,
    default: () => [],
  },
  showChips: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
});
const { showChips, treeOptions } = toRefs(props);
const emit = defineEmits(["update:selectedTreeNodes"]);
const { selectedTreeNodes: selectedTreeNodesData } = useVModels(props, emit);

const treeValue = ref({});
const treeOpen = ref(false);
const treeNodeMap = ref({});
const treeRef = ref();

const nonPartialValues = computed(() => {
  return Object.entries(unref(treeValue))
    .map(([key, value]) => {
      const parts = key.split(" > ");
      if (parts?.length === 2) {
        return { key, value, label: parts[1] };
      } else {
        return undefined;
      }
    })
    .filter(({ value } = {}) => value && value.checked && !value.partialChecked)
    .sort(({ aKey = "" }, { bKey = "" }) => aKey.localeCompare(bKey));
});

const removeSelection = (selection) => {
  const clonedSelectedValuesMap = cloneDeep(unref(treeValue));
  const parentNode = { key: "root", children: unref(treeOptions) };
  removeNode(parentNode, selection.key, clonedSelectedValuesMap);
  setPartials(parentNode, clonedSelectedValuesMap);
  treeValue.value = clonedSelectedValuesMap;
};

const addSelection = (selection) => {
  const clonedSelectedValuesMap = cloneDeep(unref(treeValue));
  const parentNode = { key: "root", children: unref(treeOptions) };
  addNode(parentNode, selection.key, clonedSelectedValuesMap);
  setPartials(parentNode, clonedSelectedValuesMap);
  treeValue.value = clonedSelectedValuesMap;
};

watchDeep(treeValue, (updated) => {
  const model = [];
  const fullData = [];
  for (const [key, value] of Object.entries(updated)) {
    if (value.checked) {
      model.push(key);
      fullData.push(cloneDeep(unref(treeNodeMap)[key]));
    }
  }

  selectedTreeNodesData.value = fullData;
});

watch(
  () => selectedTreeNodesData.value,
  (newVal, oldVal) => {
    const removedDiffs = oldVal?.filter(
      (e) => !newVal?.find((val) => val.key === e.key)
    );
    const addedDiffs = newVal?.filter(
      (e) => !oldVal?.find((val) => val.key === e.key)
    );
    if (removedDiffs?.length) {
      for (const diff of removedDiffs) {
        if (diff && unref(treeValue)[diff.key]?.checked) {
          removeSelection(diff);
        }
      }
    }
    if (addedDiffs?.length) {
      const valueSize = Object.keys(unref(treeValue)).length;
      if (valueSize > 0) {
        for (const diff of addedDiffs) {
          if (diff && !unref(treeValue)[diff.key]?.checked) {
            addSelection(diff);
          }
        }
      } else {
        const newTreeVal = addedDiffs.reduce((acc, val) => {
          const { key } = val;
          acc[key] = {
            checked: true,
            partialChecked: false,
          };
          return acc;
        }, {});
        const clonedSelectedValuesMap = cloneDeep(unref(newTreeVal));
        const parentNode = { key: "root", children: unref(treeOptions) };
        setPartials(parentNode, clonedSelectedValuesMap);
        treeValue.value = clonedSelectedValuesMap;
      }
    }
  },
  { immediate: true, deep: true }
);

watch(
  treeOptions,
  (newVal) => {
    treeNodeMap.value = getFullNodeMap(newVal);
  },
  { immediate: true, deep: true }
);

const onToggle = (node, expanded) => {
  const expandedKeys = cloneDeep(treeRef.value.expandedKeys);
  expandedKeys[node.key] = expanded;
  treeRef.value.onNodeToggle(expandedKeys);
};
</script>
<template>
  <div>
    <div
      class="flex flex-wrap mb-21px gap-2 border-indigo-base text-indigo-base"
      v-if="showChips"
    >
      <Chip
        v-for="value in nonPartialValues"
        :key="value.key"
        :label="value.label"
        class="hover:bg-indigo-light hover:border-0 hover:cursor-pointer capitalize h-6 max-w-125px"
        removable
        :pt-options="{ state: { visible: true } }"
        @remove="removeSelection(value)"
        @click="removeSelection(value)"
      >
        <template #removeicon>
          <ClearAll class="ml-2 w-3 h-3" />
        </template>
      </Chip>
    </div>
    <TreeSelect
      ref="treeRef"
      append-to="self"
      v-model="treeValue"
      class="w-full"
      selection-mode="checkbox"
      display="chip"
      :options="treeOptions"
      panel-class="max-w-[400px] w-full"
      :pt="{ tree: { label: { class: '' } } }"
      @show="treeOpen = true"
      @hide="treeOpen = false"
      @node-select="(val) => addSelection(val)"
      @node-unselect="(val) => removeSelection(val)"
      scroll-height="350px"
      :placeholder="placeholder"
    >
      <template #value="{ value, placeholder}"
        ><div>
          <div
            v-if="!value?.length"
            class="pl-3 pt-1 font-bold uppercase placeholder"
          >
            {{ placeholder }}
          </div>
        </div></template
      >
      <template #triggericon>
        <SearchChevron :rotate-direction="treeOpen ? 'up' : 'down'" />
      </template>
      <template #itemtogglericon="{ expanded, node }">
        <ChevronDownIcon v-if="expanded" @click.stop="onToggle(node, false)" />
        <ChevronRightIcon v-else @click.stop="onToggle(node, true)" />
      </template>
      <template #itemcheckboxicon="{checked, partialChecked}">
        <CheckIcon v-if="checked" class="bg-indigo-base text-white p-[1px]" />
        <MinusIcon
          v-else-if="partialChecked"
          class="bg-indigo-base text-white p-[1px]"
        />
      </template>
    </TreeSelect>
  </div>
</template>

<style scoped>
.placeholder {
  color: rgba(30, 38, 76, 0.5);
}
</style>
