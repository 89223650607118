<template>
  <div class="mx-auto mb-10 w-full">
    <div class="flex animate-pulse space-x-4">
      <div class="aspect-5/4 w-28 bg-blue-300 sm:w-44 md:w-64"></div>
      <div class="flex-1 space-y-6 py-1">
        <div class="h-5 max-w-2xl rounded bg-blue-300"></div>
        <div class="space-y-3">
          <div class="grid max-w-xl grid-cols-3 gap-4">
            <div class="col-span-2 h-2 rounded bg-blue-300"></div>
            <div class="col-span-1 h-2 rounded bg-blue-300"></div>
          </div>
          <div class="h-2 max-w-xl rounded bg-blue-300"></div>
          <div class="grid max-w-xl grid-cols-3 gap-4">
            <div class="col-span-1 h-2 rounded bg-blue-300"></div>
            <div class="col-span-2 h-2 rounded bg-blue-300"></div>
          </div>
          <div class="h-2 max-w-lg rounded bg-blue-300"></div>
          <div class="grid max-w-xl grid-cols-3 gap-4">
            <div class="col-span-1 h-2 rounded bg-blue-300"></div>
            <div class="col-span-1 h-2 rounded bg-blue-300"></div>
            <div class="col-span-1 h-2 rounded bg-blue-300"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.aspect-5\/4 {
  aspect-ratio: 5 / 4;
}
</style>
