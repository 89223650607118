<template>
  <section>
    <ArchivingModal />
    <DuplicateSessionModal
      v-if="$route.meta.subcategory === 'sessions'"
      :data="duplicateModalState"
      @close-modal="closeDuplicateModal"
    />
    <DuplicateModal v-else />
    <DetailModal
      v-if="$route.meta.subcategory === 'sessions'"
      :data="detailModalState"
      @close-modal="closeDetailModal"
    />
    <EventViewModal v-if="$route.meta.subcategory === 'events'" />
    <BreadCrumb class="max-w-screen-2xl mx-auto mt-10" />
    <div v-show="showToggle" class="flex mt-5 flex-wrap justify-end mr-5">
      <div
        class="inline-flex items-center rounded-full p-1 bg-gray-300 h-10 mr-2"
      >
        <label for="my-items" data-testid="my-items-toggle">
          <input
            type="radio"
            id="my-items"
            name="toggle"
            value="my-items"
            checked
            class="hidden peer"
            @change="handleToggleChange"
          />
          <span
            for="my-items"
            class="px-4 py-1 rounded-full cursor-pointer bg-gray-300 text-black peer-checked:bg-blue-700 peer-checked:text-white"
          >
            My Items
          </span>
        </label>

        <label for="shared-items" data-testid="shared-items-toggle">
          <input
            type="radio"
            id="shared-items"
            name="toggle"
            value="shared-items"
            class="hidden peer"
            @change="handleToggleChange"
          />
          <span
            for="shared-items"
            class="px-4 py-1 rounded-full cursor-pointer bg-gray-300 text-black peer-checked:bg-blue-700 peer-checked:text-white"
          >
            Shared Items
          </span>
        </label>

        <label for="hidden" data-testid="hidden-items-toggle">
          <input
            type="radio"
            id="hidden"
            name="toggle"
            value="hidden"
            class="hidden peer"
            @change="handleToggleChange"
          />
          <span
            class="px-4 py-1 rounded-full cursor-pointer bg-gray-300 text-black peer-checked:bg-blue-700 peer-checked:text-white"
          >
            Hidden
          </span>
        </label>
      </div>
    </div>
    <div
      class="flex mt-4 mx-auto justify-between px-3 max-w-screen-2xl flex-wrap"
    >
      <div
        v-if="toggleState === 'shared-items'"
        class="flex items-end justify-around md:ml-7"
      >
        <button
          :class="`${secondaryColorClass}`"
          class="mr-7 text-center uppercase"
          :disabled="selectedItems.length < 1"
          @click="handleBlock"
          data-testid="block-button"
        >
          HIDE
        </button>
      </div>
      <div
        v-else-if="toggleState === 'hidden'"
        class="flex items-end justify-around md:ml-7"
      >
        <button
          :class="`${secondaryColorClass}`"
          class="mr-7 text-center uppercase"
          :disabled="selectedItems.length < 1"
          @click="handleUnblock"
          data-testid="unblock-button"
        >
          UNHIDE
        </button>
      </div>
      <div v-else class="flex items-end justify-around md:ml-7">
        <button
          v-if="showBulkEdit"
          :class="`${secondaryColorClass}`"
          class="mr-7 text-center uppercase"
          disabled
        >
          BULK EDIT
        </button>
        <button
          v-if="hasWritePermission && $route.meta?.subcategory !== 'classes'"
          :class="`${secondaryColorClass}`"
          class="text-center uppercase"
          :disabled="selectedItems.length < 1"
          @click="openArchive"
        >
          ARCHIVE
        </button>
      </div>
      <div class="flex flex-wrap">
        <SearchBar
          class="align-end flex mr-3 mt-3 min-w-[300px] md:w-[400px] searchbar xs:mt-0 sm:mr-7"
          :input-from-parent="q"
          @handleSearch="handleSearch"
          @handleCustomFilter="openAdvancedFilters = true"
        >
          <template
            v-if="
              $route.meta?.subcategory === 'sessions' ||
              $route.meta?.subcategory === 'events'
            "
            #advancedFilters
          >
            <SessionAdvancedFiltersModal
              v-show="$route.meta?.subcategory === 'sessions'"
              @update-filters="updateSessionAdvancedFilters"
            />

            <EventAdvancedFiltersModal
              v-show="$route.meta?.subcategory === 'events'"
              @update-filters="updateEventAdvancedFilters"
            />
          </template>
        </SearchBar>
        <HousingModals
          v-if="subcategory === 'Housing'"
          :is-default-open="defaultOpen && hasWritePermission"
          :is-disabled="!(hasWritePermission || hasPublishPermission)"
        />
        <ProgramSessionCreateModal v-else-if="isProgramSessions" />
        <BaseButton
          v-else-if="createRouteName"
          type="button"
          variant="primary"
          variant-type="block"
          outlined
          :on-click="redirectToCreatePage"
          :disabled="!(hasWritePermission || hasPublishPermission)"
          class="mt-3 xs:mt-0"
        >
          New
        </BaseButton>
        <component
          :is="currentAdvancedFilter"
          :title="`${subcategory} Filter`"
          :is-modal-open="openAdvancedFilters"
          @closeModal="openAdvancedFilters = false"
        />
      </div>
    </div>
    <div class="flex justify-center">
      <BaseTable
        v-if="!getLoading && !getListingError && !toggleAction"
        class="max-w-screen-2xl"
        :header-visible="false"
        :active-filter="getActiveFilter"
        :headers="visibleHeaders"
        :rows="getTableItems"
        :actions-routes="getTableItemActionsRoutes"
        :show-checkbox="hasWritePermission"
        @open-duplicate-modal="openDuplicateModal"
        @open-detail-modal="openDetailModal"
      />
      <Spinner
        v-else-if="(getLoading && !getListingError) || toggleAction"
        class="relative h-65vh bg-blue-100"
      />
      <ErrorPage v-else-if="getListingError" :message="getListingError" />
    </div>
    <footer>
      <Pagination
        v-if="showPagination"
        class="mb-14"
        :page="page"
        :total-of-records="getActiveFilter.count"
        :records-per-page="limit"
        @changePage="changePage"
      />
    </footer>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { internshipFilter } from "./filters";
import ArchivingModal from "../../modals/ArchivingModal";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import DuplicateSessionModal from "../../modals/duplicate/ProgramSession.vue";
import DuplicateModal from "../../modals/DuplicateModal.vue";
import EventViewModal from "../../modals/events/EventsView.vue";
import BaseTable from "@/components/shared/Table/BaseTable.vue";
import BreadCrumb from "@/components/shared/BreadCrumb.vue";
import ErrorPage from "@/components/errorPage.vue";
import ClassesFilterModal from "@/components/program-manager/housing/components/AdvancedFilters/Classes";
import HousingFilterModal from "@/components/program-manager/housing/components/AdvancedFilters/Housing";
import InternshipsFilterModal from "@/components/program-manager/housing/components/AdvancedFilters/Internships";
import ProgramsFilterModal from "@/components/program-manager/housing/components/AdvancedFilters/Programs";
import EventAdvancedFiltersModal from "@/components/program-manager/events/components/AdvancedFilters";
import SessionAdvancedFiltersModal from "@/components/program-manager/sessions/components/AdvancedFiltersModal";
import HousingModals from "@/components/modals/housing/HousingModals.vue";
import DetailModal from "@/components/modals/DetailModal";
import Pagination from "@/components/shared/Pagination.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import ProgramSessionCreateModal from "@/components/program-manager/sessions/components/ProgramSessionCreateModal";
import {
  SESSION_INCLUDE_FIELDS,
  PROGRAM_PAGE_INCLUDE_FIELDS,
} from "@/components/program-manager/sessions/constants.js";
import { EVENTS_INCLUDE_FIELDS } from "@/components/program-manager/events/constants.js";
import { setIncludedFields } from "@/components/program-manager/events/utils.js";
import entityVisibilityService from "@/services/entityVisibility";

import { useVisibleHeaders } from "@/composables/visibleHeaders";
import { eventBus } from "@/app";
import { useToast } from "vue-toast-notification";
import { PERMISSIONS } from "@/constants";

const viewMoreFilters = {
  internships: internshipFilter,
};

export default {
  name: "ProgramManagerViewMore",
  components: {
    ArchivingModal,
    DuplicateModal,
    DuplicateSessionModal,
    EventViewModal,
    DetailModal,
    BaseButton,
    BaseTable,
    BreadCrumb,
    ClassesFilterModal,
    HousingFilterModal,
    HousingModals,
    InternshipsFilterModal,
    EventAdvancedFiltersModal,
    Pagination,
    ProgramsFilterModal,
    SearchBar,
    Spinner,
    ErrorPage,
    ProgramSessionCreateModal,
    SessionAdvancedFiltersModal,
  },
  beforeRouteEnter(to, from, next) {
    to.meta.breadcrumb[1] = {
      title: to.meta.subcategory.replace(/^\w/, (c) => c.toUpperCase()),
      parent: to.name,
      active: true,
    };
    next((vm) => {
      // try to load filters from sessionStorage
      const filters = sessionStorage.getItem("filters");
      if (filters) {
        vm.setAdvancedFilters(JSON.parse(filters));
      }
    });
  },
  props: {
    defaultOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      visibleHeaders,
      defaultHeaders,
      setVisibleHeaders,
      updateHeaders,
      setPersistedHeaders,
    } = useVisibleHeaders(9);
    const toast = useToast();
    return {
      visibleHeaders,
      defaultHeaders,
      setVisibleHeaders,
      updateHeaders,
      setPersistedHeaders,
      toast,
    };
  },
  data() {
    return {
      recentlyHiddenColumns: [],
      currentAdvancedFilter: "HousingFilterModal",
      currentViewmorePage: this.$route?.meta?.subcategory ?? "",
      openAdvancedFilters: false,
      page: 1,
      searchtext: "",
      selectedItems: [],
      subcategory: undefined,
      duplicateModalState: {
        state: false,
        id: undefined,
      },
      detailModalState: {
        state: false,
        id: undefined,
      },
      sessionCurrentFilters: {},
      eventCurrentFilters: {},
      toggleState: "my-items",
      toggleAction: false,
    };
  },
  computed: {
    ...mapGetters("programManager", [
      "getAdvancedFilters",
      "getActiveFilter",
      "getTableItems",
      "getTableHeaders",
      "getCards",
      "getTableItemActionsRoutes",
      "getCurrentEntity",
      "getLoading",
      "getListingError",
      "getEntityFilters",
    ]),
    ...mapGetters({
      permissions: "getPermissions",
      userEntities: "getEntityIds",
    }),
    ...mapState(["featureFlags"]),
    pageFetcheDataBasedOnColumns() {
      return ["sessions", "events"].includes(this.currentViewmorePage);
    },
    getSubcategoryCapitalized() {
      return this.$route.meta.subcategory.replace(/^\w/, (c) =>
        c.toUpperCase()
      );
    },
    showPagination() {
      return this.getActiveFilter.count > this.limit;
    },
    hasWritePermission() {
      return this.permissions.includes(PERMISSIONS.ITEMS_WRITE);
    },
    hasPublishPermission() {
      return this.permissions.includes(PERMISSIONS.ITEMS_PUB);
    },
    showBulkEdit() {
      return (
        this.$route.meta.subcategory !== "internships" &&
        this.$route.meta.subcategory !== "classes"
      );
    },
    createRouteName() {
      return this.getTableItemActionsRoutes.create ?? "";
    },
    isProgramSessions() {
      return this.$route?.meta?.subcategory === "sessions";
    },
    limit() {
      return this.currentViewmorePage !== "programs" &&
        this.currentViewmorePage !== "sessions"
        ? 25
        : 10; // https://apiabroad.atlassian.net/browse/RAG-634 bugfix
    },
    showToggle() {
      return (
        (this.currentViewmorePage === "programs" ||
          this.currentViewmorePage === "sessions") &&
        this.featureFlags["ownership-filter"] &&
        !this.getLoading
      );
    },
  },
  beforeUnmount() {
    // Prevent event bus from executing callbacks multiple times
    eventBus.$off("updateSelectedItems");
    eventBus.$off("updateHeaders");
  },
  created() {
    const currentLibraryFilters = this.getActiveFilter?.title?.toLowerCase();
    if (currentLibraryFilters !== this.currentViewmorePage) {
      this.$store.commit("programManager/setAdvancedFilters", {});
    }

    this.setPersistedHeaders([]);

    eventBus.$on("updateSelectedItems", (data) => {
      this.updateSelectedItems(data);
    });
    eventBus.$on("updateHeaders", (selectedHeaders) => {
      const needsToFetch = this.columnRequiresNewFetch(selectedHeaders);
      this.setRecentlyHiddenColumns(this.visibleHeaders, selectedHeaders);
      this.setPersistedHeaders(selectedHeaders);
      this.updateHeaders(selectedHeaders);
      if (this.pageFetcheDataBasedOnColumns && needsToFetch) {
        this.requestTableData();
      }
    });

    this.setVisibleHeaders(this.defaultHeaders);
    this.subcategory = this.getSubcategoryCapitalized;
    this.currentAdvancedFilter = `${this.subcategory}FilterModal`;
    const { page, q } = this.$route.query;
    this.q = q || "";
    this.page = Number(page) || 1;
    if (
      this.getCurrentEntity != this.$route.meta.subcategory ||
      this.getTableItems.length <= this.limit
    ) {
      this.updateHeaders([]);
      this.requestTableData();
    }

    this.fetchAmenitiesData();
    this.eventCategories();
    this.eventSubCategories();
    this.fetchLocations();
    this.fetchTagsData();
    this.fetchTestimonialsData();
  },
  methods: {
    ...mapActions({
      classes: "programManager/fetchClassesData",
      housing: "programManager/fetchHousingData",
      programs: "programManager/fetchProgramsData",
      programsPage: "programManager/fetchProgramPagesData",
      sessions: "programManager/fetchProgramSessionsData",
      internships: "programManager/fetchInternshipsData",
      setAllCounts: "programManager/setAllCounts",
      events: "programManager/fetchEventsData",
      clearProgramManagerData: "programManager/clearProgramManagerData",
      fetchAmenitiesData: "programManager/fetchAmenitiesData",
      eventCategories: "events/fetchCategories",
      eventSubCategories: "events/fetchSubCategories",
      fetchLocations: "events/fetchLocations",
      fetchTagsData: "tagManager/fetchTagsData",
      fetchTestimonialsData: "testimonialManager/fetchTestimonialsData",
    }),
    columnRequiresNewFetch(currentlySelected = []) {
      let requireNewFetch = false;
      if (this.pageFetcheDataBasedOnColumns) {
        for (let i=0; i < currentlySelected.length; i++) {
          if (!this.recentlyHiddenColumns.includes(currentlySelected[i]) && !this.visibleHeaders.includes(currentlySelected[i])) {
            requireNewFetch = true;
            break;
          }
        }
      }
      return requireNewFetch
    },
    setRecentlyHiddenColumns(previouslyVisible = [], currentlySelected = []) {
      if (this.pageFetcheDataBasedOnColumns) {
        // Add columns that were removed
        previouslyVisible.forEach((previousColumn) => {
          if (!currentlySelected.includes(previousColumn)) {
            this.recentlyHiddenColumns.push(previousColumn);
          }
        });

        // Remove hidden columns if they were added back
        this.recentlyHiddenColumns = this.recentlyHiddenColumns.filter((hiddenCol) => {
          return !currentlySelected.includes(hiddenCol);
        });
      }
    },
    setDefaultEventFields() {
      if (Array.isArray(this.visibleHeaders) && this.visibleHeaders.length > 1) {
        return false;
      }
      return true;
    },
    requestTableData() {
      // before calling the API endpoint, format the filters
      this.recentlyHiddenColumns = []; // On data fetch hidden columns aren't needed
      let formattedFilters = {};
      let payload = { extraParams: {} };

      const entityFilter =
        this.getEntityFilters[this.currentViewmorePage] || {};

      let allAvailableKey;

      if (this.currentViewmorePage === "sessions") {
        allAvailableKey = "all_available_sessions";
      } else if (this.currentViewmorePage === "programs") {
        allAvailableKey = "all_available_program_pages";
      }

      switch (this.toggleState) {
        case "my-items":
          payload = {
            extraParams: {
              owner_entity_id: this.userEntities.map((entity) => entity)[0],
            },
          };
          break;
        case "shared-items":
          payload = {
            extraParams: {
              ...entityFilter,
              is_approved_for_subdomain: true,
              [allAvailableKey]: true,
            },
          };
          break;
        case "hidden":
          payload = {
            extraParams: {
              ...entityFilter,
              is_approved_for_subdomain: "false",
              [allAvailableKey]: true,
            },
          };
          break;
      }

      switch (this.currentViewmorePage) {
        case "sessions":
          formattedFilters = this.sessionCurrentFilters;
          payload.extraParams = {
            ...payload.extraParams,
            ...formattedFilters,
            include: SESSION_INCLUDE_FIELDS,
          };
          break;
        case "programs":
          payload.extraParams = {
            ...payload.extraParams,
            include: PROGRAM_PAGE_INCLUDE_FIELDS,
          };
          break;
        case "events":
          formattedFilters = this.eventCurrentFilters;
          payload.extraParams = {
            ...payload.extraParams,
            ...formattedFilters,
            include: this.setDefaultEventFields() ? EVENTS_INCLUDE_FIELDS : setIncludedFields(this.visibleHeaders),
          };
          break;
        case "internships":
          formattedFilters = viewMoreFilters[this.currentViewmorePage](
            this.getAdvancedFilters
          );
          payload.extraParams = {
            ...payload.extraParams,
            ...formattedFilters,
          };
          break;
        case "housing":
          payload = { extraParams: this.getAdvancedFilters };
          payload.extraParams = {
            ...payload.extraParams,
            ...this.getAdvancedFilters,
          };
          break;
      }

      if (this[this.currentViewmorePage]) {
        try {
          this[this.currentViewmorePage]({
            limit: this.limit,
            page: this.page,
            q: this.searchtext,
            ...payload,
          });
        } catch (error) {
          if (error.code === 413) {
            toast.open({
              message: "Error: entity is too large",
              type: "error",
              position: "bottom",
              duration: 5000,
            });
          }
        }
      } else {
        toast.open({
          message: `Couldn't find this library`,
          type: "error",
          position: "bottom",
          duration: 5000,
        });
      }
    },
    handleSearch(q) {
      this.searchtext = q;
      setTimeout(() => {
        if (q === this.searchtext) {
          this.$router.push({
            query: { page: 1, ...(this.searchtext && { q: this.searchtext }) },
          });
          this.requestTableData();
        }
      }, 1000);
    },
    changePage(page) {
      this.page = page;
      setTimeout(() => {
        if (page === this.page) {
          this.$router.push({
            query: { page, ...(this.searchtext && { q: this.searchtext }) },
          });
          this.requestTableData();
        }
      }, 1000);
    },
    updateSelectedItems(items) {
      this.selectedItems = items;
    },
    openArchive() {
      this.$store.dispatch("programManager/setArchivingModalStateOpen", {
        selectedItems: this.selectedItems,
      });
    },
    redirectToCreatePage() {
      this.$router.push({ name: this.createRouteName });
    },
    openDuplicateModal({ id }) {
      this.duplicateModalState = { id, state: true };
    },
    closeDuplicateModal() {
      this.duplicateModalState = { id: undefined, state: false };
    },
    openDetailModal({ id }) {
      this.detailModalState = { id, state: true };
    },
    closeDetailModal() {
      this.detailModalState = { id: undefined, state: false };
    },
    updateSessionAdvancedFilters(filters) {
      this.sessions({
        limit: this.limit,
        page: this.page,
        q: this.searchtext,
        extraParams: filters,
      });
      this.sessionCurrentFilters = filters;
    },
    updateEventAdvancedFilters(filters) {
      this.events({
        limit: this.limit,
        page: this.page,
        q: this.searchtext,
        extraParams: filters,
      });
      this.eventCurrentFilters = filters;
    },
    formatLibraryPayload(libraryName, filters) {
      switch (libraryName) {
        case "session":
          return { extraParams: filters };
        case "internship":
          return filters;
      }
    },
    handleToggleChange(event) {
      this.toggleState = event.target.value;
      this.requestTableData();
    },
    async handleBlock() {
      this.toggleAction = true;
      try {
        const entity_id = this.userEntities.map((entity) => entity)[0];
        const data = this.selectedItems.map((item) => ({
          entity_id: entity_id,
          product_id: item,
        }));
        await entityVisibilityService.optOut(data);
        this.requestTableData();
        this.toast.open({
          message: "Selected items hidden successfully.",
          type: "success",
          position: "bottom",
          duration: 5000,
        });
      } catch (error) {
        this.toast.open({
          message: "Failed to hide selected items.",
          type: "error",
          position: "bottom",
          duration: 5000,
        });
      } finally {
        this.toggleAction = false;
      }
    },
    async handleUnblock() {
      this.toggleAction = true;
      try {
        const entity_id = this.userEntities.map((entity) => entity)[0];
        const product_ids = this.selectedItems.map((item) => item);
        await entityVisibilityService.optIn(entity_id, product_ids);
        this.requestTableData();
        this.toast.open({
          message: "Selected items unhidden successfully.",
          type: "success",
          position: "bottom",
          duration: 5000,
        });
      } catch (error) {
        this.toast.open({
          message: "Failed to unhide selected items.",
          type: "error",
          position: "bottom",
          duration: 5000,
        });
      } finally {
        this.toggleAction = false;
      }
    },
  },
};
</script>
