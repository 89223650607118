<script setup>
import {
  V3_stepCanBeReturned,
  V3_stepShouldBeShown,
} from "@/composables/VersionHelper.js";
import { inject, reactive, ref, watch, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  requiredIf,
  minValue,
  maxValue,
  email,
  maxLength,
} from "@vuelidate/validators";
import { validEmailAddress } from "@/mixins/customValidators";
import { ACADEMIC_YEARS, PAYMENT_PLANS, API_CONNECT_MEMBER, OVER_PRICE_2000 } from "@/constants.js";
import { useRouter } from "vue-router";
import {
  entityTypes,
  proficiencyLevelsOptions,
  sessionType,
} from "@/components/program-manager/sessions/constants.js";
import _ from "lodash";
import applicationService from "@/services/application.js";
import profileService from "@/services/profile.js";
import formService from "@/services/form.js";
import entitiesService from "@/services/entities.js";
import cipCodesService from "@/services/cipCodes.js";
import SingleSelect from "@/components/forms/SharedComponents/DarkBlueInputs/SingleSelect.vue";
import TextField from "@/components/forms/SharedComponents/DarkBlueInputs/TextField.vue";
import AddressComponent from "@/components/forms/AddressInputComponent/AddressComponent.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import billingAgreement from "@/composables/useBillings.js";
import enrollmentService from "@/services/enrollment.js";
import CheckBox from "@/components/forms/SharedComponents/DarkBlueInputs/Checkbox.vue";
import RadioButtons from "@/components/forms/SharedComponents/DarkBlueInputs/RadioButtons.vue";
import ManualAddressForm from "@/components/ExperiencePage/Misc/ManualAddressForm.vue";
import {
  INTERNATIONAL_GRADUATE_STUDENT_ID,
  US_COLLEGE_STUDENT_ID,
  US_GRADUATE_STUDENT_ID,
  US_HIGH_SCHOOL_STUDENT_ID,
  requiredSchool,
} from "@/constants.js";
import { usePastDeadline } from "@/composables/usePastDeadline.js";
import { compareStringsInLowerCase } from "@/util/string";

const updateStepAndRedirect = inject("updateStepAndRedirect");
const {
  learnerTypes,
  schools,
  cipCodes: initialCipCodes,
  languages,
  countries,
} = inject("initialOptionsData");

const {
  disabledContinueButton,
  isPastDeadline,
  validateDeadline,
} = usePastDeadline();

// Variables
const billingAddressSameAs = ref("");
const isSameAddress = ref(false);
const store = useStore();
const router = useRouter();
const learnerInfoSubmit = ref(null);
const formioSubmisionId = ref(null);
const initialCurrentAddress = ref(null);
const initialPermanentAddress = ref(null);
const initialBillingAddress = ref(null);
const submissionError = ref("");
const contracts = ref([]);
const form = reactive({
  learnerType: null, // required
  school: null, // required if learner type is either; us college student or us graduate student
  majors: [], // required, at least one
  minors: [],
  cumulativeGpa: null, // required
  expectedGraduation: null, // required
  yearInSchool: null, // required
  paymentPlans: [],
  languagesYouKnow: [],
  hadDisciplinarySanctions: null, // required
  currentAddress: {}, // required
  permanentAddress: {},
  billingAddress: {},
  parentGuardianName: "", // required if student age is under 18 - Could be populated by the 1st parent found
  parentGuardianEmail: "", // required if student age is under 18 - Could be populated by the 1st parent found
  certifyFAFSA: false,
  consideredForScholarships: false,
});
const parentGuardiansUpdate = ref([]); // To avoid modifying the state, we will clone the parents object from profile_data and modify this one
const learnerTypeOptions = ref([]);
const addressOptions = ref([
  { label: "Same as current address", value: "currentAddress" },
  { label: "Same as permanent address", value: "permanentAddress" },
  { label: "Other", value: "other" },
]);
const universityParams = {
  account_types: [entityTypes.home_institution],
  field: "name",
  order: "ASC",
};
const schoolOptions = ref([]);
const schoolDetails = ref(null);
const cipCodes = ref([]); // This is equal to Major(s) and Minor(s)
const languageOptions = ref([]);
const displinarySanctionsOptions = ref([
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
]);
const scholarshipOptions = ref([
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
]);

const addressController = reactive({
  currentAddress: {
    showManualForm: null, // hides autocomplete input then allows to capture address manually (if true)
  },
  permanentAddress: {
    showManualForm: null,
  },
  billingAddress: {
    showManualForm: null,
  },
});
const expectedGraduationValidation = helpers.regex(/^[\d/]+$/); // regex: Only allows numbers and the '/' character
const proficiencyValidation = (value) => {
  // Validates that all languages have a proficiency
  return undefined === value.find((language) => !language?.proficiency?.id);
};
const requiredErrorCopy = "This field is required.";
const emailErrorCopy =
  "Email does not have proper formatting. Please try again.";
const requiredToMinors = [US_COLLEGE_STUDENT_ID];
const requiredToMajors = [US_COLLEGE_STUDENT_ID];
const requiredToShowGPA = [
  US_COLLEGE_STUDENT_ID,
  US_GRADUATE_STUDENT_ID,
  US_HIGH_SCHOOL_STUDENT_ID,
];
const requiredSchoolStanding = [
  US_HIGH_SCHOOL_STUDENT_ID,
  US_COLLEGE_STUDENT_ID,
  INTERNATIONAL_GRADUATE_STUDENT_ID,
  US_GRADUATE_STUDENT_ID,
];
const minLengthGpaCopy = "GPA must be from 0.00-4.00.";
const requiredAddressFields = [
  "address_postal_code",
  "address_city",
  "address_state",
  "address_country_id",
];
const futureDateValidation = (value) => {
  if (!requiredSchool.includes(form.learnerType?.id)) {
    return true;
  }
  const regex = /^(0[1-9]|1[0-2])\/\d{4}$/;
  if (!regex.test(value)) {
    return false;
  }
  const [month, year] = value.split("/");
  const selectedDate = new Date(`${year}-${month}-01`);
  const currentDate = new Date();

  return selectedDate > currentDate;
};
// Form validation rules (Vuelidate library)
const rules = {
  learnerType: { required: helpers.withMessage(requiredErrorCopy, required) },
  school: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => requiredSchool.includes(form.learnerType?.id))
    ),
  },
  majors: {
    required: helpers.withMessage(
      "At least one major is required.",
      requiredIf(() => requiredToMajors.includes(form.learnerType?.id))
    ),
    maxLength: helpers.withMessage("Select up to two majors", maxLength(2)),
  },
  minors: {
    maxLength: helpers.withMessage("Select up to three minors", maxLength(3)),
  },
  cumulativeGpa: {
    minValue: helpers.withMessage(minLengthGpaCopy, minValue(0)),
    maxValue: helpers.withMessage(minLengthGpaCopy, maxValue(4)),
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => showGpa.value)
    ),
  },
  expectedGraduation: {
    valid: helpers.withMessage(
      "Format must be MM/YYYY",
      expectedGraduationValidation
    ),
    futureDate: helpers.withMessage(
      "Expected graduation date must be in the future",
      futureDateValidation
    ),
  },
  yearInSchool: { required: helpers.withMessage(requiredErrorCopy, required) },
  paymentPlans: {},
  hadDisciplinarySanctions: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(
        () =>
          requiredSchoolStanding.includes(form.learnerType?.id) &&
          !isFacultyLedProgram.value
      )
    ),
  },
  currentAddress: {
    address_line_1: {
      required: helpers.withMessage("Address is required", required),
    },
    address_postal_code: {
      required: helpers.withMessage(
        "The address provided doesn't include a postal code.",
        requiredIf(() => form.currentAddress?.address_line_1?.length)
      ),
    },
    address_city: {
      required: helpers.withMessage(
        "The address provided doesn't include a city.",
        requiredIf(() => form.currentAddress?.address_line_1?.length)
      ),
    },
    address_state: {
      required: helpers.withMessage(
        "The address provided doesn't include a state.",
        requiredIf(() => form.currentAddress?.address_line_1?.length)
      ),
    },
    address_country_id: {
      required: helpers.withMessage(
        "The address provided doesn't include a country.",
        requiredIf(() => form.currentAddress?.address_line_1?.length)
      ),
    },
  },
  permanentAddress: {
    address_line_1: {
      required: helpers.withMessage("Address is required", required),
    },
    address_postal_code: {
      required: helpers.withMessage(
        "The address provided doesn't include a postal code.",
        requiredIf(() => form.permanentAddress?.address_line_1?.length)
      ),
    },
    address_city: {
      required: helpers.withMessage(
        "The address provided doesn't include a city.",
        requiredIf(() => form.permanentAddress?.address_line_1?.length)
      ),
    },
    address_state: {
      required: helpers.withMessage(
        "The address provided doesn't include a state.",
        requiredIf(() => form.permanentAddress?.address_line_1?.length)
      ),
    },
    address_country_id: {
      required: helpers.withMessage(
        "The address provided doesn't include a country.",
        requiredIf(() => form.permanentAddress?.address_line_1?.length)
      ),
    },
  },
  billingAddress: {
    address_line_1: {
      required: helpers.withMessage(
        "Address is required",
        requiredIf(() => contractNumber.value !== "")
      ),
    },
    address_postal_code: {
      required: helpers.withMessage(
        "The address provided doesn't include a postal code.",
        requiredIf(
          () =>
            form.billingAddress?.address_line_1?.length &&
            contractNumber.value !== ""
        )
      ),
    },
    address_city: {
      required: helpers.withMessage(
        "The address provided doesn't include a city.",
        requiredIf(
          () =>
            form.billingAddress?.address_line_1?.length &&
            contractNumber.value !== ""
        )
      ),
    },
    address_state: {
      required: helpers.withMessage(
        "The address provided doesn't include a state.",
        requiredIf(
          () =>
            form.billingAddress?.address_line_1?.length &&
            contractNumber.value !== ""
        )
      ),
    },
    address_country_id: {
      required: helpers.withMessage(
        "The address provided doesn't include a country.",
        requiredIf(
          () =>
            form.billingAddress?.address_line_1?.length &&
            contractNumber.value !== ""
        )
      ),
    },
  },
  languagesYouKnow: {
    required: helpers.withMessage(
      "At least one language is required.",
      required
    ),
    missingProficiency: helpers.withMessage(
      "Set a proficiency for each language",
      proficiencyValidation
    ),
  },
  parentGuardianName: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => isUnderage.value)
    ),
    maxLength: helpers.withMessage(
      ({ $params }) => `Name must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },
  parentGuardianEmail: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => isUnderage.value)
    ),
    validEmailAddress: helpers.withMessage(emailErrorCopy, validEmailAddress),
    email: helpers.withMessage(emailErrorCopy, email),
  },
};
const v$ = useVuelidate(rules, form);

// Computed

const isScholarshipFeatureEnabled = computed(() => {
  return (
    store.state.featureFlags["api-connect-member-scholarship-eligibility"] ===
    true
  );
});

const filteredProficiencyLevelsOptions = computed(() => {
  return proficiencyLevelsOptions.filter(
    (option) => option.value !== "All Levels"
  );
});
const formatSchools = computed(() =>
  schoolOptions.value.map((school) => ({
    label: school?.name,
    value: school?.ope_id,
    ...school,
  }))
);

const hideBillingAddressInput = computed(() => {
  return ["currentAddress", "permanentAddress", ""].includes(
    billingAddressSameAs.value
  );
});

const showGpa = computed(() =>
  requiredToShowGPA.includes(form.learnerType?.id)
);

const showMinors = computed(() =>
  requiredToMinors.includes(form.learnerType?.id)
);
const applicationId = computed(() => store.state.currentApplicationId);

const oktaId = computed(() => {
  return store.state.currentUser?.participantId ?? "";
});

// TODO: Works while impersonating?
const student_formio_submission_id = computed(() => {
  return store.state.currentUser?.formioStudentId ?? "";
});

const profile_data = computed(() => {
  return store.state.profileData;
});

const financialAid = computed(() => {
  if (applicationId.value)
    return profile_data.value?.financial_aid.find(financialAid => financialAid.application_id === applicationId.value)
});

const profileSelectedObjects = computed(() => {
  return store.state.profileSelectedObjects;
});

const currentUser = computed(() => {
  return store.state.currentUser;
});

const languagesYouKnowFormatted = computed(() => {
  // Helpful for profile service that only stores the ids
  return form.languagesYouKnow.map((language) => {
    return {
      language_id: language?.id ?? "",
      language_proficiency_id: language?.proficiency?.id ?? "",
    };
  });
});

const majorsFormatted = computed(() => {
  // Helpful for profile service that only stores the id/cip_code
  return form.majors.map((major) => {
    return { cip_code: major.cip_code };
  });
});

const minorsFormatted = computed(() => {
  return form.minors.map((minor) => {
    return { cip_code: minor.cip_code };
  });
});

const paymentPlansFormatted = computed(() => {
  // Get their values instead of labels
  return form.paymentPlans.map((plan) => plan.value);
});


const disciplinarySanctionsFormatted = computed(() => {
  return form.hadDisciplinarySanctions?.value === "yes";
});

const collegesFormatted = computed(() => {
  return form?.school?.id ? [ { college_id: form.school.id  } ] : [];
});

const formatGPA = computed(() => {
  const parsedGPA = parseFloat(form.cumulativeGpa);
  if (isNaN(parsedGPA)) return null;
  return parsedGPA % 1 !== 0
    ? parseFloat(parsedGPA.toFixed(2))
    : parseFloat(parsedGPA.toFixed(1));
});

const profileSaveObject = computed(() => {
  // Data that goes to profile service
  return {
    email: profile_data.value.email, // TODO: Remove email from request; profile-service endpoint requires it but that shouldn't happen
    learner_type_id: form.learnerType?.id ?? null, // should not be null though
    majors: majorsFormatted.value,
    minors: minorsFormatted.value,
    cumulative_gpa: formatGPA.value,
    expected_graduation_date: form.expectedGraduation,
    languages: languagesYouKnowFormatted.value,
    year_in_school_at_time_of_api_program: form.yearInSchool?.value ?? "", // should not be empty though
    plans_for_paying: paymentPlansFormatted.value,
    manual_current_address_completion:
      addressController.currentAddress.showManualForm,
    current_address_country_id: form.currentAddress?.address_country_id ?? null,
    current_address_city: form.currentAddress?.address_city ?? null,
    current_address_line_1: form.currentAddress?.address_line_1 ?? null,
    current_address_line_2: form.currentAddress?.address_line_2 ?? null,
    current_address_state: form.currentAddress?.address_state ?? null,
    current_address_postal_code:
      form.currentAddress?.address_postal_code ?? null,
    current_address_neighborhood_name:
      form.currentAddress?.neighborhood ?? null,
    current_address_administrative_area_level_2:
      form.currentAddress?.administrative_area_level_2 ?? null,
    manual_permanent_address_completion:
      addressController.permanentAddress.showManualForm,
    permanent_address_country_id:
      form.permanentAddress?.address_country_id ?? null,
    permanent_address_city: form.permanentAddress?.address_city ?? null,
    permanent_address_line_1: form.permanentAddress?.address_line_1 ?? null,
    permanent_address_line_2: form.permanentAddress?.address_line_2 ?? null,
    permanent_address_postal_code:
      form.permanentAddress?.address_postal_code ?? null,
    permanent_address_state: form.permanentAddress?.address_state ?? null,
    permanent_address_neighborhood_name:
      form.permanentAddress?.neighborhood ?? null,
    permanent_address_administrative_area_level_2:
      form.permanentAddress?.administrative_area_level_2 ?? null,
    manual_billing_address_completion:
      addressController.billingAddress.showManualForm,
    billing_address_country_id: form.billingAddress?.address_country_id ?? null,
    billing_address_city: form.billingAddress?.address_city ?? null,
    billing_address_line_1: form.billingAddress?.address_line_1 ?? null,
    billing_address_line_2: form.billingAddress?.address_line_2 ?? null,
    billing_address_state: form.billingAddress?.address_state ?? null,
    billing_address_postal_code:
      form.billingAddress?.address_postal_code ?? null,
    billing_address_neighborhood_name:
      form.billingAddress?.address_neighborhood ?? null,
    billing_address_administrative_area_level_2:
      form.billingAddress?.administrative_area_level_2 ?? null,
    parents: parentGuardiansUpdate.value,
    colleges: collegesFormatted.value,
    disciplinary_sanctions: disciplinarySanctionsFormatted.value,
    user_types: profile_data.value?.user_types ?? [],
    okta_id: profile_data.value?.okta_id,
  };
});

const formioSaveObject = computed(() => {
  // Data that goes to formio at first submission
  return {
    data: {
      application_id: applicationId.value,
      "school-name": requiredSchool.includes(form.learnerType?.id)
        ? {
            label: form.school?.label ?? "",
            value: form.school?.value?.toString().padStart(8, "0") ?? "",
          }
        : {},
      major: {
        name: form.majors[0]?.cip_title ?? "",
      }, // First major
      minor:
        form.minors[0]?.cip_title !== undefined
          ? { name: form.minors[0].cip_title }
          : {}, // First minor
      gpa: formatGPA.value,
      "academic-level": form.yearInSchool?.value ?? "", // year in school
      languageProficiency: _.camelCase(
        form.languagesYouKnow?.[0]?.proficiency?.value ?? ""
      ), // of first lenguage selected, also https://lodash.com/docs/4.17.15#camelCase
      disciplinarySanctions: form.hadDisciplinarySanctions?.value,
      learnerType: form.learnerType?.value,
    },
  };
});

const formioPatchObject = computed(() => {
  // Data that goes to formio when updating submission
  // Re-uses previous computed property to avoid duplicating logic to fetch values
  // Updates studentApplicationStage2Step1
  return [
    patchObject("application_id", formioSaveObject.value.data.application_id),
    patchObject("school-name", formioSaveObject.value.data["school-name"]),
    patchObject("major", formioSaveObject.value.data.major),
    patchObject("minor", formioSaveObject.value.data.minor),
    patchObject("gpa", formioSaveObject.value.data.gpa),
    patchObject(
      "academic-level",
      formioSaveObject.value.data["academic-level"]
    ),
    patchObject(
      "languageProficiency",
      formioSaveObject.value.data.languageProficiency
    ),
    patchObject(
      "disciplinarySanctions",
      formioSaveObject.value.data.disciplinarySanctions
    ),
  ];
});

const formioPatchStudentObject = computed(() => {
  // Updates student submission
  return [
    patchObject("schoolname", formioSaveObject.value.data["school-name"]),
    patchObject("student_type", formioSaveObject.value.data["learnerType"]),
  ];
});

const programSelectionContractNumber = computed(() => {
  // The contract number from the program selection form
  return store.state.stepsInV3[0]?.data?.data?.contractNumber ?? "";
});

const contractNumber = computed(() => {
  return store.state.appliedAgreement?.contract_number ?? "";
});

const programSelectionSubmissionId = computed(() => {
  return store.state.stepsInV3[0]?.data?._id ?? "";
});

const formioPatchProgramSelection = computed(() => {
  // Updates program selection submission
  return [patchObject("contractNumber", contractNumber.value)];
});

const isUnderage = computed(() => {
  // Bail if birthdate not set
  if (!currentUser.value?.birthdate) return false;
  return (
    new Date().getFullYear() -
      new Date(currentUser.value.birthdate).getFullYear() <
    18
  );
});

const sessionV3Data = computed(() => {
  return store.state.programSessionDataV3;
});

const isFacultyLedProgram = computed(() =>
  sessionV3Data.value?.session_types?.some(
    (val) => val.id == sessionType.faculty_led
  )
);

const isCollegeStudentId = (id) => {
  return US_COLLEGE_STUDENT_ID === id;
}

const hidePlanToPay = computed(() => {
  const isUSCollegeStudent = isCollegeStudentId(form.learnerType?.id);
  return !isUSCollegeStudent;
});

const hasFinancialAid = computed(() => {
  return form.paymentPlans.find((plan) => compareStringsInLowerCase(plan.value, "financialAid")) !== undefined;
})

const hasSelectedPaymentPlan = () => Boolean(form.paymentPlans.find((plan) => compareStringsInLowerCase(plan.value, "financialAid") ||
  compareStringsInLowerCase(plan.value, "scholarshipsGrantsFromYourSchool")
));

const showScholarshipQuestion = computed(() => {
  if (!isCollegeStudentId(form.learnerType?.id)) return false;
  if (!isScholarshipFeatureEnabled?.value) return false;
  if (!hasSelectedPaymentPlan()) return false;

  const isBasePriceOver2000 = sessionV3Data.value.base_price_in_cents > OVER_PRICE_2000;
  if (!isBasePriceOver2000) return false;
  if (!schoolDetails?.value?.data?.data?.items.api_relationship) return false
  if (
    !compareStringsInLowerCase(schoolDetails.value?.data?.data?.items.api_relationship, API_CONNECT_MEMBER)
  ) return false;
  if (isFacultyLedProgram.value) return false;

  return true;
});

// Watchers
watch(
  () => contracts.value,
  (newVal) => {
    if (newVal.length) {
      // Check if billing agreement can be applied
      const appliableContract = billingAgreement(
        contracts.value,
        sessionV3Data.value
      );

      store.commit("setInstitutionPaysValue", {
        field: "applicationFee",
        value: appliableContract?.applicationFee === "Institution",
      });

      store.commit(
        "setAppliedAgreement",
        appliableContract !== undefined ? appliableContract : {}
      );
    }
  }
);


watch(
  () => form?.school?.id,
  async (newValue) => {
    schoolDetails.value = await entitiesService.getEntityById(newValue);
  }
);
watch(
  () => form.school,
  async (newVal) => {
    resetBillingAgreements();

    // ope_id is different if it comes from a pre-fetched field
    let ope_id = newVal?.ope_id
      ? newVal.ope_id
      : newVal?.value
      ? newVal.value
      : null;

    if (ope_id) {
      // Get billing agreements
      const contracts_response = await enrollmentService.loadContracts(
        String(ope_id).padStart(8, "0")
      ); // Some ids have missing zeros, we ensure their length is 8 chars long

      if (Array.isArray(contracts_response)) {
        contracts.value = contracts_response;
      }
    }
  }
);

watch(
  [
    () => v$.value.currentAddress.$errors,
    () => v$.value.permanentAddress.$errors,
    () => v$.value.billingAddress.$errors,
  ],
  (
    // Renders form to manually set address if autocomplete caused an error
    [newCurrentAddress, newPermanentAddress, newBillingAddress]
  ) => {
    // Once flag is active we won't show back the autocomplete
    if (
      newCurrentAddress?.some((error) => {
        return requiredAddressFields.includes(error.$property);
      })
    ) {
      addressController.currentAddress.showManualForm = true;
    }

    if (
      newPermanentAddress?.some((error) => {
        return requiredAddressFields.includes(error.$property);
      })
    ) {
      addressController.permanentAddress.showManualForm = true;
    }

    if (
      newBillingAddress?.some((error) => {
        return requiredAddressFields.includes(error.$property);
      })
    ) {
      addressController.billingAddress.showManualForm = true;
    }
  }
);

// Permanent address
watch(isSameAddress, (newValue) => {
  if (newValue) {
    form.permanentAddress = { ...form.currentAddress };
    if (billingAddressSameAs.value === "permanentAddress") {
      // Copy to billing if available
      form.billingAddress = { ...form.permanentAddress };
    }

    if (addressController.currentAddress.showManualForm) {
      // stores if it was copied from autocomplete or manual input
      addressController.permanentAddress.showManualForm = true;
    }
  } else {
    initialPermanentAddress.value = null; // Clears out permanent address autocomplete input
    form.permanentAddress = {};
    addressController.permanentAddress.showManualForm = null;
  }
});

watch(billingAddressSameAs, (newValue, oldValue) => {
  if (newValue === "currentAddress") {
    // Copy current address
    form.billingAddress = { ...form.currentAddress };
  }
  if (newValue === "permanentAddress") {
    // Copy permanent address
    form.billingAddress = { ...form.permanentAddress };
  }
  if (newValue === "other" && oldValue !== "") {
    // adding oldValue prevents from removing billing address when user re-visits the form
    // he should see the previous billing address he set
    initialBillingAddress.value = {}; // Clears out billing address input
    form.billingAddress = {}; // Let the user input an address
  }
});

watch(
  () => form.learnerType,
  (learnerType) => {
    resetBillingAgreements();

    if (!requiredSchool.includes(learnerType?.id)) form.school = null;
    if (!requiredToMajors.includes(learnerType?.id)) form.majors = [];
    if (!requiredToMinors.includes(learnerType?.id)) form.minors = [];
  },
  { deep: true }
);
watch(
  () => [form.parentGuardianEmail, form.parentGuardianName],
  ([newEmail, newName]) => {
    // When setting either the email or name of a parent guardian we will set/update the first one in their relationship
    if (parentGuardiansUpdate.value[0] !== undefined) {
      parentGuardiansUpdate.value[0].name = newName;
      parentGuardiansUpdate.value[0].email = newEmail;
      return;
    }
    parentGuardiansUpdate.value.push({ name: newName, email: newEmail });
  }
);


watch(
  () => form.languagesYouKnow,
  (newLanguages) => {
    // To each language selected we add a 'proficiency' property to capture their id
    // This avoids infinite loop: Checks if an item does not have the proficiency property
    if (
      !newLanguages.find(
        (language) => !Object.hasOwnProperty.call(language, "proficiency")
      )
    ) {
      return;
    }

    // Add proficiency property if it does not have it
    form.languagesYouKnow = newLanguages.map((language) => {
      if (!Object.hasOwnProperty.call(language, "proficiency")) {
        language["proficiency"] = null;
        v$._value.languagesYouKnow.$reset();
      }
      return language;
    });
  },
  { deep: true }
);

watch(
  form.currentAddress,
  () => {
    // Keep addresses up to date if current address changes
    if (isSameAddress.value) form.permanentAddress = { ...form.currentAddress };
    if (billingAddressSameAs.value === "currentAddress")
      form.billingAddress = { ...form.currentAddress };
  },
  { deep: true }
);

watch(
  form.permanentAddress,
  () => {
    // Keep address up to date if permanent address changes
    if (billingAddressSameAs.value === "permanentAddress")
      form.billingAddress = { ...form.permanentAddress };
  },
  { deep: true }
);

watch(
  () => form.paymentPlans,
  () => {
    if(!hasFinancialAid.value) {
      // reset additional hidden fields
      form.certifyFAFSA = false;
      form.consideredForScholarships = "no";
    }
  }
)

// Methods
const resetBillingAgreements = () => {
  contracts.value = [];

  store.commit("setInstitutionPaysValue", {
    field: "applicationFee",
    value: false,
  }); // Institution doesn't pay application fee

  // Reset store
  store.commit("setAppliedAgreement", {});
};

const patchObject = (fieldName = "", value = "") => {
  // fieldName should match the name of the field in formio
  // value is the new value to assign
  return {
    op: "add",
    path: "/data/" + fieldName,
    value: value,
  };
};

const updateAddress = (addressType, addressInfo) => {
  // Address type should be either currentAddress or permanentAddress
  // So it updates the right object inside 'form' reactive object
  const {
    street_address_1,
    street_address_2,
    number,
    city,
    state,
    postal_code,
    lat,
    long,
    iso, // country id
    country,
    utc_offset_minutes,
    neighborhood,
    administrative_area_level_2,
  } = addressInfo;
  form[addressType]["address_line_1"] = `${number ? `${number} ` : ""}${
    street_address_1 || ""
  }`;
  form[addressType]["address_line_2"] = street_address_2;
  form[addressType]["address_postal_code"] = postal_code || null;
  form[addressType]["address_city"] = city;
  form[addressType]["address_state"] = state;
  form[addressType]["neighborhood"] = neighborhood || null;
  form[addressType]["address_lat_lng"] = `${lat || ""} ${long || ""}`; //37.4224428 -122.0829089197085
  form[addressType]["address_country_id"] = iso || null;
  form[addressType]["timezone_offset_mins"] = utc_offset_minutes;
  form[addressType]["country"] = country || ""; // label
  form[addressType]["administrative_area_level_2"] =
    administrative_area_level_2 || "";
};

const updateAddressManually = (addressType, addressInfo) => {
  form[addressType]["address_line_1"] = addressInfo["address"] || "";
  form[addressType]["address_postal_code"] =
    addressInfo["address_postal_code"] || null;
  form[addressType]["address_city"] = addressInfo["address_city"];
  form[addressType]["address_state"] = addressInfo["address_state"];
  form[addressType]["address_country_id"] =
    addressInfo["address_country_id"] || null;
  form[addressType]["country"] = addressInfo["country"] || ""; // label of country
  form[addressType]["administrative_area_level_2"] =
    addressInfo["administrative_area_level_2"] ||
    form[addressType]["administrative_area_level_2"];

  // We do not capture these manually, clear them
  form[addressType]["neighborhood"] = null;
  form[addressType]["address_lat_lng"] = null;
};

const searchSchools = async ({ search, loading }) => {
  loading(true);
  entitiesService
    .getEntities({ q: search, extraParams: universityParams })
    .then(({ data }) => {
      schoolOptions.value = data?.data?.items ?? [];
    })
    .catch(() => (schoolOptions.value = []))
    .finally(() => {
      loading(false);
    });
};

const searchCipCodes = async ({ search, loading }) => {
  // Majors and minors will come from Learning Service from now own. (V3)
  try {
    if (search === "") {
      cipCodes.value = [...initialCipCodes];
      return;
    }
    cipCodes.value = await cipCodesService.list(search, "cip_title", "ASC", 25);
  } catch (error) {
    cipCodes.value = [];
  } finally {
    loading(false);
  }
};

const submitLearnerInfo = async () => {
  learnerInfoSubmit.value.startLoading();
  try {
    validateDeadline();

    if (isPastDeadline.value) return;

    const financialAidPayload = {
        profile_id: oktaId.value,
        application_id: applicationId.value,
        using_financial_aid: hasSelectedPaymentPlan(),
        certified_fafsa_eligible: form.consideredForScholarships === "no" ? false : form.certifyFAFSA,
        considered_for_scholarships: form.consideredForScholarships === "yes",
      };
    if (hasSelectedPaymentPlan()) {
      if (financialAid.value) {
        await profileService.toggleFinancialAid(
          applicationId.value,
          financialAidPayload
        );
      } else {
          await profileService.createFinancialAid(
          financialAidPayload
        );
      }
    } else if (financialAid.value) { // Used to be selected
      await profileService.toggleFinancialAid(
          applicationId.value,
          financialAidPayload
        );
    }

    // Update Profile Service
    const profile_response = await profileService.updateProfile(
      oktaId.value,
      profileSaveObject.value
    );

    if (profile_response?.status == 200) {
      store.commit("setProfileData", profile_response.data.data);
    }

    // Create/Update formio submission
    let formio_response;
    if (formioSubmisionId.value === null) {
      // Create
      formio_response = await formService.createSubmission(
        "studentapplicationstage2step1",
        formioSaveObject.value
      );
    } else {
      // Update - PATCH: We won't use PUT because it would replace all data and this form has other forms inside of it, PATCH avoids edge-cases
      formio_response = await formService.partiallyUpdateSubmission(
        "studentapplicationstage2step1",
        formioSubmisionId.value,
        formioPatchObject.value
      );
    }

    // Update student submission - if college changed
    if (form.school?.value !== currentUser.value?.university?.value) {
      await formService.partiallyUpdateSubmission(
        "student",
        student_formio_submission_id.value,
        formioPatchStudentObject.value
      );
    }

    // Update program selection
    if (
      programSelectionSubmissionId.value &&
      contractNumber.value !== programSelectionContractNumber.value
    ) {
      await formService.partiallyUpdateSubmission(
        "programselection",
        programSelectionSubmissionId.value,
        formioPatchProgramSelection.value
      );
    }

    if (
      profile_response?.status === 200 &&
      formio_response?._id !== undefined
    ) {
      // Set fromio data on step
      store.commit("setStepDataV3", {
        stepName: "LearnerInfo",
        data: formio_response,
      });

      // Get address countries
      const currentCountry = form.currentAddress?.address_country_id ?? "";
      const permanentCountry = form.permanentAddress?.address_country_id ?? "";
      const billingCountry = form.billingAddress?.address_country_id ?? "";
      let userCountries = [
        currentCountry,
        permanentCountry,
        billingCountry,
      ].filter((item) => item);

      const countriesResponse = await applicationService.getUserInitialData(
        [...new Set(userCountries)].join(",")
      );

      // Update data on vuex
      store.commit("setProfileSelectedObjects", {
        propertyName: "school",
        value: { ...form.school },
      });
      store.commit("setProfileSelectedObjects", {
        propertyName: "majors",
        value: [...form.majors],
      });
      store.commit("setProfileSelectedObjects", {
        propertyName: "minors",
        value: [...form.minors],
      });

      if (userCountries.length) {
        store.commit("setProfileSelectedObjects", {
          propertyName: "currentCountry",
          value: countriesResponse?.[currentCountry]?.data || null,
        });
        store.commit("setProfileSelectedObjects", {
          propertyName: "permanentCountry",
          value: countriesResponse?.[permanentCountry]?.data || null,
        });
        store.commit("setProfileSelectedObjects", {
          propertyName: "billingCountry",
          value: countriesResponse?.[billingCountry]?.data || null,
        });
      }

      // Mark as complete
      store.commit("setStepCompletedV3", "LearnerInfo");

      let goToGroupVisa = await V3_stepShouldBeShown("GroupVisa");
      let nextStep = goToGroupVisa
        ? "application-group-visa"
        : "application-review";

      updateStepAndRedirect(nextStep, applicationId.value);
    }
  } catch (e) {
    submissionError.value = e;
  } finally {
    learnerInfoSubmit.value.stopLoading();
  }
};

const prepareInitialAddress = (addressType = "") => {
  // Address component needs a specific object in order to populate what users saved before
  // We'll have to query for the country to get its name
  switch (addressType) {
    case "current": {
      // TODO: Have list of countries in constants.js instead of querying them?
      initialCurrentAddress.value = {
        street_address_1: profile_data.value?.current_address_line_1 ?? null,
        street_address_2: profile_data.value?.current_address_line_2 ?? null,
        city: profile_data.value?.current_address_city ?? null,
        state: profile_data.value?.current_address_state ?? null,
        country: profileSelectedObjects.value.currentCountry?.name ?? null,
        postal_code: profile_data.value?.current_address_postal_code ?? null,
        utc_offset_minutes: null,
        administrative_area_level_2:
          profile_data?.value?.administrative_area_level_2 ?? null,
      };
      break;
    }
    case "permanent": {
      initialPermanentAddress.value = {
        street_address_1: profile_data.value?.permanent_address_line_1,
        street_address_2: profile_data.value?.permanent_address_line_2,
        city: profile_data.value?.permanent_address_city,
        state: profile_data.value?.permanent_address_state,
        country: profileSelectedObjects.value.permanentCountry?.name ?? null,
        postal_code: profile_data.value?.permanent_address_postal_code,
        utc_offset_minutes: null,
        administrative_area_level_2:
          profile_data?.value?.administrative_area_level_2 ?? null,
      };
      break;
    }
    case "billing": {
      initialBillingAddress.value = {
        street_address_1: profile_data.value?.billing_address_line_1,
        street_address_2: profile_data.value?.billing_address_line_2,
        city: profile_data.value?.billing_address_city,
        state: profile_data.value?.billing_address_state,
        country: profileSelectedObjects.value.permanentCountry?.name ?? null,
        postal_code: profile_data.value?.billing_address_postal_code,
        utc_offset_minutes: null,
        administrative_area_level_2:
          profile_data?.value?.administrative_area_level_2 ?? null,
      };
      break;
    }
  }
};

function areAddressesSame(address1, address2) {
  // List of fields we want to compare
  const fieldsToCompare = [
    "address_country_id",
    "address_line_1",
    "address_line_2",
    "address_postal_code",
    "address_city",
    "address_state",
    "address_neighborhood",
    "administrative_area_level_2",
  ];

  const allFieldsNull = (address) =>
    fieldsToCompare.every((field) => address[field] === null);

  // If both addresses have all fields set to null, return false
  if (allFieldsNull(address1) && allFieldsNull(address2)) {
    return false;
  }

  // ensure they are equal and not null
  return fieldsToCompare.every((field) => address1[field] === address2[field]);
}

const getProfileData = async () => {
  if (!_.isEmpty(profile_data.value)) {
    // Pre-populate form
    const foundLearnerType = learnerTypeOptions.value.findIndex(
      (learner) => learner.id === profile_data.value?.learner_type_id
    );
    if (foundLearnerType > -1) {
      form.learnerType = JSON.parse(
        JSON.stringify(learnerTypeOptions.value[foundLearnerType])
      );
    } // TODO: Query for learner type if it's outside of initial list (?)

    // School - Populate with the first college from the profile. If not available, leave it empty.
    if (profile_data.value?.colleges[0] !== undefined) {
      form.school = { ...profileSelectedObjects.value.school };
    } else {
      form.school = null;
    }

    // Majors
    form.majors = [...profileSelectedObjects.value.majors];
    // Minors
    form.minors = [...profileSelectedObjects.value.minors];

    // Cumulative GPA
    form.cumulativeGpa =
      typeof profile_data.value?.cumulative_gpa === "number"
        ? profile_data.value.cumulative_gpa.toString()
        : null;

    // Expected graduation date
    form.expectedGraduation =
      profile_data.value?.expected_graduation_date ?? null;

    // Languages + Proficiency
    // I know it's ugly, but it works and i was under the clock!
    for (let i = 0; i < profile_data.value?.languages.length ?? []; i++) {
      let foundLanguage = languageOptions.value.findIndex(
        (language) =>
          language.id === profile_data.value.languages[i].language_id
      );

      if (foundLanguage > -1) {
        // Only set proficiency if language was found
        // Push returns the latest length of the array, we'll use that to get the latest index
        let latest = form.languagesYouKnow.push(
          JSON.parse(JSON.stringify(languageOptions.value[foundLanguage]))
        );

        latest = latest - 1; // Get index

        let foundProficiency = proficiencyLevelsOptions.findIndex(
          (proficiencyLevel) =>
            proficiencyLevel.id ===
            profile_data.value.languages[i].language_proficiency_id
        );
        form.languagesYouKnow[latest]["proficiency"] =
          foundProficiency > -1
            ? JSON.parse(
                JSON.stringify(proficiencyLevelsOptions[foundProficiency])
              )
            : {};
      }
    }

    // Year in school
    let academicYear =
      profile_data.value?.year_in_school_at_time_of_api_program ?? null;
    form.yearInSchool = !academicYear
      ? null
      : JSON.parse(
          JSON.stringify(
            ACADEMIC_YEARS.find((year) => year.value === academicYear)
          )
        );
    // Plans for paying
    if (Array.isArray(profile_data.value?.plans_for_paying)) {
      for (let i = 0; i < profile_data.value.plans_for_paying.length; i++) {
        let foundPlan = PAYMENT_PLANS.find(
          (plan) => plan.value === profile_data.value.plans_for_paying[i]
        );
        if (foundPlan !== undefined)
          form.paymentPlans.push(JSON.parse(JSON.stringify(foundPlan)));
      }
    }
    form.consideredForScholarships = financialAid?.value?.considered_for_scholarships ? "yes" : "no";
    form.certifyFAFSA = financialAid?.value?.certified_fafsa_eligible ?? false;

    // School standing - hadDisciplinarySanctions
    let sanctionToFind =
      profile_data.value?.disciplinary_sanctions === true
        ? "yes"
        : profile_data.value?.disciplinary_sanctions === false
        ? "no"
        : null;
    let foundSanction = displinarySanctionsOptions.value.find(
      (option) => option.value === sanctionToFind
    );
    form.hadDisciplinarySanctions = foundSanction
      ? JSON.parse(JSON.stringify(foundSanction))
      : null;

    // Current address - AddressComponent does not trigger an update event when the initialAddressInfo prop is set
    // so we need to set the data in the form too
    form.currentAddress.country =
      profileSelectedObjects.value.currentCountry?.name ?? "";
    form.currentAddress.address_country_id =
      profile_data.value?.current_address_country_id ?? null;
    form.currentAddress.address_line_1 =
      profile_data.value?.current_address_line_1 ?? null;
    form.currentAddress.address_line_2 =
      profile_data.value?.current_address_line_2 ?? null;
    form.currentAddress.address_postal_code =
      profile_data.value?.current_address_postal_code ?? null;
    form.currentAddress.address_city =
      profile_data.value?.current_address_city ?? null;
    form.currentAddress.address_state =
      profile_data.value?.current_address_state ?? null;
    form.currentAddress.address_neighborhood =
      profile_data.value?.current_address_neighborhood_name ?? null;
    form.currentAddress.administrative_area_level_2 =
      profile_data.value.current_address_administrative_area_level_2 ?? null;

    addressController.currentAddress.showManualForm =
      profile_data.value?.manual_current_address_completion || null;
    // Current address - This will populate an object that will be used in initialAddressInfo prop to populate the input
    prepareInitialAddress("current");

    // Permanent address
    form.permanentAddress.country =
      profileSelectedObjects.value.permanentCountry?.name ?? "";
    form.permanentAddress.address_country_id =
      profile_data.value?.permanent_address_country_id ?? null;
    form.permanentAddress.address_line_1 =
      profile_data.value?.permanent_address_line_1 ?? null;
    form.permanentAddress.address_line_2 =
      profile_data.value?.permanent_address_line_2 ?? null;
    form.permanentAddress.address_postal_code =
      profile_data.value?.permanent_address_postal_code ?? null;
    form.permanentAddress.address_city =
      profile_data.value?.permanent_address_city ?? null;
    form.permanentAddress.address_state =
      profile_data.value?.permanent_address_state ?? null;
    form.permanentAddress.address_neighborhood =
      profile_data.value?.permanent_address_neighborhood_name ?? null;
    form.permanentAddress.administrative_area_level_2 =
      profile_data.value.permanent_address_administrative_area_level_2 || null;
    addressController.permanentAddress.showManualForm =
      profile_data.value?.manual_permanent_address_completion ?? null;
    prepareInitialAddress("permanent");

    // Billing address
    form.billingAddress.country =
      profileSelectedObjects.value.billingCountry?.name ?? "";
    form.billingAddress.address_country_id =
      profile_data.value?.billing_address_country_id ?? null;
    form.billingAddress.address_line_1 =
      profile_data.value?.billing_address_line_1 ?? null;
    form.billingAddress.address_line_2 =
      profile_data.value?.billing_address_line_2 ?? null;
    form.billingAddress.address_postal_code =
      profile_data.value?.billing_address_postal_code ?? null;
    form.billingAddress.address_city =
      profile_data.value?.billing_address_city ?? null;
    form.billingAddress.address_state =
      profile_data.value?.billing_address_state ?? null;
    form.billingAddress.address_neighborhood =
      profile_data.value?.billing_address_neighborhood_name ?? null;
    form.billingAddress.administrative_area_level_2 =
      profile_data.value?.billing_address_administrative_area_level_2 || null;
    form.billingAddress.administrative_area_level_2 =
      profile_data.value.billing_address_administrative_area_level_2 || null;
    addressController.billingAddress.showManualForm =
      profile_data.value?.manual_billing_address_completion ?? null;
    prepareInitialAddress("billing");

    // Check if addresses are the same, if so, check the "same address" checkbox
    if (areAddressesSame(form.currentAddress, form.permanentAddress)) {
      isSameAddress.value = true; // Check the "same address" checkbox and hide the current address field
    }

    // Check which billing address matches current or permanent address, if so, we mark the corresponding radio-button
    if (areAddressesSame(form.billingAddress, form.currentAddress)) {
      billingAddressSameAs.value = "currentAddress";
    } else if (areAddressesSame(form.billingAddress, form.permanentAddress)) {
      billingAddressSameAs.value = "permanentAddress";
    } else if (
      Object.keys(form.billingAddress).some(
        (billingAddressProperty) =>
          typeof form.billingAddress[billingAddressProperty] === "string"
      )
    ) {
      // If any of the properties from billing address is not a null value then we set radio to 'Other'
      billingAddressSameAs.value = "other";
    }

    // Parent Guardian - This form will allow us to view/edit the first parent
    // We clone the entire list of parents but a watcher is in place to modify the first one
    parentGuardiansUpdate.value = JSON.parse(
      JSON.stringify(profile_data.value.parents)
    );

    if (profile_data.value?.parents[0] !== undefined) {
      form.parentGuardianEmail = profile_data.value.parents[0].email;
      form.parentGuardianName = profile_data.value.parents[0].name;
    }
  }
};

const checkFormioSubmission = async () => {
  try {
    formioSubmisionId.value = store.state.stepsInV3[2]?.data?._id ?? null;
  } catch (error) {
    console.info("No submission found", error);
  }
};

async function canViewForm() {
  // Allow to return if step was completed or is next step
  let canViewForm = await V3_stepCanBeReturned("LearnerInfo");
  if (!canViewForm) {
    // Go to About You
    router.push({
      name: "application-about-you",
      params: { applicationId: applicationId.value },
    });
  }
}

const setInitialData = async () => {
  learnerTypeOptions.value = [...learnerTypes];
  schoolOptions.value = [...schools];
  cipCodes.value = [...initialCipCodes];
  languageOptions.value = [...languages];
};

const preSelectAddress = function (addressType) {
  // Address type is either; currentAddress, permanentAddress, other
  billingAddressSameAs.value = addressType;
};

// Lifecycle hooks
onBeforeMount(async () => {
  await canViewForm();
  setInitialData();

  // Fetch profile & Pre-populate
  await getProfileData();
  // Look for form.io submission
  await checkFormioSubmission();
});
</script>
<template>
  <div
    class="font-montserrat text-indigo-base text-left bg-white w-full p-14 border-collapse md:mt-0 md:p-0"
  >
    <div class="grid grid-cols-1 gap-y-7 pt-4">
      <div>
        <h1 class="font-bold text-[28px] uppercase">
          Learner Information
        </h1>
      </div>
      <p class="font-medium text-xs">
        Fields marked with (*) are required.
      </p>
      <!-- Learner Type -->
      <SingleSelect
        id="learnerType"
        v-model.trim="form.learnerType"
        label="What best describes you?*"
        :vuelidate-field="v$.learnerType"
        :options="learnerTypeOptions"
        option-label="value"
      >
        <template
          v-if="form.learnerType?.id === GAP_YEAR_STUDENT"
          #help-content
        >
          <p class="text-sm">
            For students taking a break between high school and college.
          </p>
        </template>
      </SingleSelect>

      <!-- Current School -->
      <SingleSelect
        v-if="requiredSchool.includes(form.learnerType?.id)"
        id="school"
        v-model.trim="form.school"
        label="Current School*"
        :vuelidate-field="v$.school"
        :options="formatSchools"
        :search-method="true"
        @search="searchSchools"
      />

      <!-- Major(s) -->
      <SingleSelect
        v-if="requiredToMajors.includes(form.learnerType?.id)"
        id="majors"
        v-model.trim="form.majors"
        label="Major(s)*"
        :vuelidate-field="v$.majors"
        :options="cipCodes"
        :search-method="true"
        :allow-multiple-options="true"
        option-label="cip_title"
        @search="searchCipCodes"
      />

      <!-- Minor(s) -->
      <SingleSelect
        v-if="showMinors"
        id="minors"
        v-model.trim="form.minors"
        label="Minor(s)"
        :vuelidate-field="v$.minors"
        :options="cipCodes"
        :search-method="true"
        :allow-multiple-options="true"
        option-label="cip_title"
        @search="searchCipCodes"
      />

      <!-- 2 cols -->
      <div class="grid grid-cols-2 gap-y-7 gap-x-7">
        <div v-if="showGpa">
          <!-- Cumulative GPA -->
          <TextField
            id="gpa"
            v-model.trim="form.cumulativeGpa"
            label="Cumulative GPA*"
            placeholder="Enter Your GPA"
            :vuelidate-field="v$.cumulativeGpa"
            type="number"
            :show-errors="true"
          />
        </div>
        <div v-if="requiredSchool.includes(form.learnerType?.id)">
          <!-- Expected Graduation -->
          <TextField
            id="graduationDate"
            v-model.trim="form.expectedGraduation"
            label="Expected Graduation*"
            placeholder="MM/YYYY"
            :vuelidate-field="v$.expectedGraduation"
            :show-errors="true"
            :input-mask="{ maskType: 'month-year' }"
          />
        </div>
      </div>

      <!-- Languages you know -->
      <SingleSelect
        id="languagesYouKnow"
        v-model.trim="form.languagesYouKnow"
        label="What languages do you speak?*"
        :vuelidate-field="v$.languagesYouKnow"
        :options="languageOptions"
        :allow-multiple-options="true"
        option-label="value"
      />

      <!-- Language proficiency (1 per lang selected)-->
      <div v-if="form.languagesYouKnow.length">
        <div class="grid grid-cols-2 gap-y-7 gap-x-7">
          <span class="font-medium text-sm">Language name</span>
          <span class="font-medium text-sm"
            >What is your proficiency in each language?*</span
          >
        </div>
        <div
          v-for="(language, index) in form.languagesYouKnow"
          :key="index"
          class="grid grid-cols-2 gap-y-7 gap-x-7"
        >
          <span>{{ language.value }}</span>
          <SingleSelect
            :id="'proficiency-' + index"
            v-model.trim="language.proficiency"
            :vuelidate-field="v$.languagesYouKnow"
            :options="filteredProficiencyLevelsOptions"
            option-label="value"
          />
        </div>
      </div>

      <!-- Year in school -->
      <SingleSelect
        id="yearSchool"
        v-model.trim="form.yearInSchool"
        label="Year in school at time of program.*"
        :vuelidate-field="v$.yearInSchool"
        :options="ACADEMIC_YEARS"
      />

      <!-- Payment Plans-->
      <SingleSelect
        v-if="!hidePlanToPay"
        id="paymentplans"
        v-model.trim="form.paymentPlans"
        label="How do you plan to pay for your program?"
        :vuelidate-field="v$.paymentPlans"
        :options="PAYMENT_PLANS"
        :allow-multiple-options="true"
      />

      <!-- Scholarship Consideration -->
      <div class="my-6" v-if="showScholarshipQuestion">
        <h2
          class="block text-sm font-medium text-indigo-base flex justify-between mb-1"
        >
          Would you like to be considered for scholarships?
        </h2>
        <RadioButtons
          data-testid="considered-for-scholarships"
          :group-name="'consideredForScholarships'"
          v-model="form.consideredForScholarships"
          :options="scholarshipOptions"
          groupName="consideredForScholarships"
          @option-choosed="(value) => (form.consideredForScholarships = value)"
          :container-class="'flex justify-start space-x-4'"
        />
      </div>
      <!-- FAFSA Certification Statement -->
      <div
        v-if="showScholarshipQuestion && form.consideredForScholarships === 'yes'"
        class="mt-4"
      >
        <CheckBox
          data-testid="certify-FAFSA"
          id="certifyFAFSA"
          v-model="form.certifyFAFSA"
          :vuelidate-field="v$.sameAsPermanentAddress"
        >
          <template #label>
            I certify that I complete a FAFSA form annually and have
            demonstrated <br /> eligibility for financial aid.
          </template>
        </CheckBox>
      </div>

      <!-- School Standing -->
      <div
        v-if="
          requiredSchoolStanding.includes(form.learnerType?.id) &&
          !isFacultyLedProgram
        "
      >
        <h1 class="font-bold text-[28px] uppercase">
          School Standing
        </h1>
        <p class="font-medium text-xs pb-4">
          Please answer truthfully. We may require additional documentation.
        </p>
        <!-- Displinary sanctions -->
        <SingleSelect
          id="hadDisplinarySanctions"
          v-model.trim="form.hadDisciplinarySanctions"
          label="Have you ever had any disciplinary or academic sanctions at your school?*"
          :vuelidate-field="v$.hadDisciplinarySanctions"
          :options="displinarySanctionsOptions"
        />
      </div>

      <!-- Address(es) -->
      <div>
        <h1 class="font-bold text-[28px] uppercase">
          Address(es)
        </h1>
        <p class="font-medium text-xs">
          For sending important documents and visa advising.
        </p>
      </div>

      <!-- Current Address -->
      <AddressComponent
        v-if="!addressController.currentAddress.showManualForm"
        id="currentAddress"
        label="Current Address*"
        template-name="v3"
        :vuelidate-field="v$.currentAddress"
        :initial-address-info="initialCurrentAddress"
        @update-address-info="
          (addressInformation) => {
            updateAddress('currentAddress', addressInformation);
          }
        "
      />
      <!-- User will input his own current address -->
      <ManualAddressForm
        v-if="addressController.currentAddress.showManualForm"
        :initial-countries="countries"
        :parent-object-label="'Current Address'"
        :show-error="v$.currentAddress.$invalid"
        :initial-address="form.currentAddress.address_line_1"
        :initial-postal-code="form.currentAddress.address_postal_code"
        :initial-city="form.currentAddress.address_city"
        :initial-state="form.currentAddress.address_state"
        :initial-country-id="form.currentAddress.address_country_id"
        :initial-country-label="form.currentAddress.country"
        @update-address-object="
          (addressInformation) =>
            updateAddressManually('currentAddress', addressInformation)
        "
      />

      <!-- Permanent Address -->
      <div>
        <label
          for="permanentAddress"
          class="block text-sm text-indigo-base font-bold mb-17px"
        >
          <span class="font-medium flex justify-between mb-1">
            Permanent Address*
          </span>
        </label>
        <!-- CheckBox for same address using the provided CheckBox component -->
        <CheckBox
          id="sameAsPermanentAddress"
          v-model="isSameAddress"
          :vuelidate-field="v$.sameAsPermanentAddress"
        >
          <template #label>
            Same as current address
          </template>
        </CheckBox>
        <AddressComponent
          v-if="
            !isSameAddress && !addressController.permanentAddress.showManualForm
          "
          id="permanentAddress"
          template-name="v3"
          :vuelidate-field="v$.permanentAddress"
          :initial-address-info="initialPermanentAddress"
          @update-address-info="
            (addressInformation) => {
              updateAddress('permanentAddress', addressInformation);
            }
          "
        />

        <div class="grid grid-cols-1 gap-y-7 pt-10">
          <!-- User will input his own permanent address -->
          <ManualAddressForm
            v-if="
              addressController.permanentAddress.showManualForm &&
              !isSameAddress
            "
            :initial-countries="countries"
            :parent-object-label="'Permanent Address'"
            :show-error="v$.permanentAddress.$invalid"
            :initial-address="form.permanentAddress.address_line_1"
            :initial-postal-code="form.permanentAddress.address_postal_code"
            :initial-city="form.permanentAddress.address_city"
            :initial-state="form.permanentAddress.address_state"
            :initial-country-id="form.permanentAddress.address_country_id"
            :initial-country-label="form.permanentAddress.country"
            @update-address-object="
              (addressInformation) =>
                updateAddressManually('permanentAddress', addressInformation)
            "
          />
        </div>
      </div>

      <!--  Billing Address -->
      <label
        v-if="contractNumber"
        for="billingAddress"
        class="block text-sm text-indigo-base font-bold"
      >
        <span class="font-medium flex justify-between mb-17px">
          Billing Address*
        </span>

        <RadioButtons
          v-model="billingAddressSameAs"
          :container-class="`grid grid-cols-1 md:grid-cols-3 gap-x-7 ${
            hideBillingAddressInput ? '' : 'mb-17px'
          }`"
          :options="addressOptions"
          :group-name="'address'"
          @option-choosed="preSelectAddress"
        />
        <AddressComponent
          v-if="
            contractNumber &&
            !hideBillingAddressInput &&
            !addressController.billingAddress.showManualForm
          "
          id="billingAddress"
          template-name="v3"
          :vuelidate-field="v$.billingAddress"
          :initial-address-info="initialBillingAddress"
          @update-address-info="
            (addressInformation) => {
              updateAddress('billingAddress', addressInformation);
            }
          "
        />
        <div class="grid grid-cols-1 gap-y-7 font-normal">
          <!-- User will input his own billing address -->
          <ManualAddressForm
            v-if="
              addressController.billingAddress.showManualForm &&
              !hideBillingAddressInput
            "
            :initial-countries="countries"
            :parent-object-label="'Billing Address'"
            :show-error="v$.billingAddress.$invalid"
            :initial-address="form.billingAddress.address_line_1"
            :initial-postal-code="form.billingAddress.address_postal_code"
            :initial-city="form.billingAddress.address_city"
            :initial-state="form.billingAddress.address_state"
            :initial-country-id="form.billingAddress.address_country_id"
            :initial-country-label="form.billingAddress.country"
            @update-address-object="
              (addressInformation) =>
                updateAddressManually('billingAddress', addressInformation)
            "
          />
        </div>
      </label>

      <!-- Parent Guardians -->
      <div v-if="isUnderage">
        <h1 class="font-bold text-[28px] uppercase">
          Parent/Guardian Information
        </h1>
        <p class="font-medium text-xs">
          Since you’re under 18 you’re required to share the name of a
          parent/guardian and their email address. They will be copied on all
          correspondence and be required to sign all legal documents.
        </p>
      </div>

      <!-- Parent Guardian name -->
      <TextField
        v-if="isUnderage"
        id="parentName"
        v-model.trim="form.parentGuardianName"
        label="Parent/Guardian Name*"
        placeholder="Enter Parent/Guardian Name"
        :vuelidate-field="v$.parentGuardianName"
        :show-errors="true"
      />

      <!-- Parent Guardian email -->
      <TextField
        v-if="isUnderage"
        id="parentEmail"
        v-model.trim="form.parentGuardianEmail"
        label="Parent/Guardian Email*"
        placeholder="Enter Parent/Guardian Email"
        :vuelidate-field="v$.parentGuardianEmail"
        :show-errors="true"
      />

      <ButtonWithSpinner
        id="learnerInfoButton"
        ref="learnerInfoSubmit"
        data-cy="learnerInfoButton"
        type="submit"
        variant="secondary"
        variant-type="block"
        button-height="min-h-[58px] md:min-h-[60px] mt-4"
        :grey-disabled-class="true"
        :disabled="v$.$invalid || disabledContinueButton"
        @click="submitLearnerInfo"
      >
        <div class="flex items-center justify-center">
          <span class="pr-3 uppercase">
            Continue
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
      <div v-if="submissionError" class="error text-error-900 mt-2">
        {{ submissionError }}
      </div>
    </div>
  </div>
</template>
