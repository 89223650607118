import tagsService from "@/services/tags";
import _ from "lodash";

const tagManager = {
  namespaced: true,
  state: {
    tags: [],
    tagsLoading: false,
    tagsFailedMessage: undefined,
  },
  mutations: {
    setTags(state, payload) {
      state.tags = payload || [];
    },
    setTagsLoading(state, payload) {
      state.tagsLoading = payload;
    },
    setTagsFailedMessage(state, payload) {
      state.tagsFailedMessage = payload;
    },
  },
  actions: {
    async fetchTagsData({ commit, getters }, payload = {}) {
      if (getters.getTags.length) return;

      commit("setTagsLoading", true);
      const { limit, page, q } = payload;
      try {
        const { data: response } = await tagsService.getTagsOrderByCategory(
          limit,
          page,
          q
        );
        commit("setTags", response?.data?.items);
      } catch (error) {
        // Unable to load tags ...
        commit("setTagsFailedMessage", error.message);
      } finally {
        commit("setTagsLoading", false);
      }
    },
  },
  getters: {
    getTags(state) {
      return state.tags;
    },
    getFlatCategoryTags(state) {
      const tags = [];
      for (const category of state.tags) {
        if (category?.tags) {
          tags.push({ id: undefined, name: category.name, category: true });
          for (const tag of category.tags) {
            tags.push({ id: tag.id, name: tag.name, category: false });
          }
        }
      }
      return _.cloneDeep(tags);
    },
    getSpecificCategoryTags(state) {
      return (payload) => {
        const tags = [];
        const tagCat = state.tags?.find((val) => val?.name === payload);
        if (tagCat?.tags) {
          tags.push({ id: undefined, name: tagCat.name, category: true });
          for (const tag of tagCat.tags) {
            tags.push({ id: tag.id, name: tag.name, category: false });
          }
        }
        return _.cloneDeep(tags);
      };
    },
    filterCategoryTagsBySpecificCategories(state) {
      return (payload) => {
        const categories =
          state.tags?.filter((val) => payload.includes(val?.name)) || [];
        const tags = [];
        for (const category of categories) {
          if (category?.tags) {
            tags.push({ id: undefined, name: category.name, category: true });
            for (const tag of category.tags) {
              tags.push({ id: tag.id, name: tag.name, category: false });
            }
          }
        }
        return _.cloneDeep(tags);
      };
    },
    getTagsLoading(state) {
      return state.tagsLoading;
    },
    getTagsFailedMessage(state) {
      return state.tagsFailedMessage;
    },
  },
};

export default tagManager;
