export const columnsConfig = [
  {
    id: "id",
    header: "",
    size: 120,
    type: "checkbox",
    getColumnDisplayValue: () => "",
  },
  {
    id: "name",
    header: "Name",
    size: 400,
    type: "anchor",
    getColumnDisplayValue: ({ name }) => name ?? "",
  },
  {
    id: "status",
    header: "Status",
    size: 300,
    getColumnDisplayValue: ({ status }) => status ?? "",
  },
];
