<template>
  <svg
    width="33"
    height="24"
    viewBox="0 0 33 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor" fill-rule="evenodd">
      <path
        d="M27.344.295H4.918a4.782 4.782 0 0 0-4.77 4.783v13.795a4.781 4.781 0 0 0 4.77 4.782h22.426a4.782 4.782 0 0 0 4.77-4.782V5.078a4.781 4.781 0 0 0-4.77-4.783zM4.918 2.755h22.426a2.323 2.323 0 0 1 2.312 2.323v1.365H2.606V5.078a2.323 2.323 0 0 1 2.312-2.324zm22.426 18.442H4.918a2.323 2.323 0 0 1-2.311-2.324V8.902h27.049v9.971a2.323 2.323 0 0 1-2.312 2.324z"
      />
      <path
        d="M25.967 16.279H19.82a1.23 1.23 0 1 0 0 2.459h6.147a1.23 1.23 0 1 0 0-2.46z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCreditCard",
};
</script>

<style></style>
