import { DEFAULT_IMAGE } from "../constants";
import { imageTransformation } from "@/util/cloudinary";
import {
  CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
  DEFAULT_IMAGE_CARD_SIZE,
  DURATION_IN_DAYS_THRESHOLD,
} from "@/constants";
import { formattedPriceFromCentsWithoutCents as formattedPrice } from "@/util/formatter";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import getApplicableContract from "@/composables/useBillings";

export const transformImage = (imagePath) => {
  return imageTransformation({
    ...CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
    ...DEFAULT_IMAGE_CARD_SIZE,
    path: imagePath,
    compress: true,
  });
};

export const cardImage = (imageUrl, program) => {
  if (imageUrl) {
    return transformImage(imageUrl);
  }
  const leadMedia = program?.program_page?.lead_media;
  const mediaList = leadMedia ? JSON.parse(leadMedia) : [];
  const firstImage = mediaList.find((media) =>
    /\.(jpg|jpeg|png|gif)$/i.test(media)
  );
  return firstImage ? transformImage(firstImage) : DEFAULT_IMAGE;
};

export const getSessionCardTitle = (session) => {
  if (session?.session_types?.length == 1) {
    if (session?.session_types?.[0].name === "Study") {
      return "Study Abroad";
    }
    return `${session?.session_types?.[0].name}`;
  } else if (session?.session_types?.length > 1) {
    let sessionTypes = session?.session_types;
    let found = sessionTypes.find((element) => element.name === "Study");
    if (found) {
      let foundIdx = sessionTypes.findIndex(
        (element) => element.name === "Study"
      );
      if (foundIdx > 0) {
        return `Study + ${session?.session_types?.[foundIdx - 1].name}`;
      } else {
        return `Study + ${session?.session_types?.[1].name}`;
      }
    } else {
      return `${session?.session_types?.[0].name} + ${session?.session_types?.[1].name}`;
    }
  }
  return "";
};

export const customApplyClass = (selected, disabledApply) => {
  let classes =
    "box-border flex flex-row justify-center items-center w-auto h-auto max-h-60p max-w-260p border-2 py-3 text-sm";
  if (selected) {
    classes +=
      " bg-university-secondary text-white border-university-secondary";
  } else if (disabledApply) {
    classes += " cursor-not-allowed bg-gray-275 text-gray-485 border-gray-485";
  } else {
    classes +=
      " bg-white text-university-secondary border-university-secondary";
  }
  return classes;
};

export const getAcademicCredits = (session) => {
  const maxCredits = session?.academic_max_credits ?? "";
  const minCredits = session?.academic_min_credits ?? "";
  if (maxCredits === minCredits) {
    return `${maxCredits}`;
  }
  return maxCredits
    ? `${minCredits ? minCredits : "0"} - ${maxCredits}`
    : `${minCredits}`;
};

export const getQualifications = (program) => {
  let qualificationsArr = [];
  if (program?.academic_levels)
    qualificationsArr.push(program?.academic_levels[0]?.academic_year);
  if (program?.needed_skills)
    qualificationsArr.push(program?.needed_skills[0]?.value);
  if (program?.gained_skills)
    qualificationsArr.push(program?.gained_skills[0]?.value);
  if (
    program?.required_languages?.length &&
    program?.required_languages !== "English"
  )
    qualificationsArr.push(program?.required_languages[0]?.value);
  if (qualificationsArr.length === 0) "";
  const concatenatedQualifications = qualificationsArr.join(", ");
  const maxCharacters = 110;
  const truncatedQualifications =
    concatenatedQualifications.length > maxCharacters
      ? concatenatedQualifications.slice(0, maxCharacters) + "…"
      : concatenatedQualifications;
  return truncatedQualifications;
};

export const getPriceInDollars = (program, homeInstitutionId) => {
  const institutionPrice =
    program?.session?.[`base_price_${homeInstitutionId}`];
  if (!Number.isNaN(Number(institutionPrice))) {
    return formattedPrice(institutionPrice);
  } else if (!Number.isNaN(Number(!program?.session?.base_price_in_cents))) {
    return formattedPrice(program?.session?.base_price_in_cents);
  } else {
    return undefined;
  }
};

export const getRedirectUrl = (program) => {
  return `/program-page/${program?.program_page?.id}?session-id=${program?.session?.id}`;
};

export const contentWrapperClasses = (cardType) => {
  const baseClasses = "px-4";
  let specificClasses = "";
  switch (cardType) {
    case "courses":
      specificClasses = "injected-courses-card";
      break;
    case "landing":
      specificClasses = "h-full flex flex-col justify-between";
      break;
    case "program":
      specificClasses = "lg:col-span-2 lg:grid lg:grid-cols-2 lg:gap-x-4";
      break;
    default:
      specificClasses = "1/2xl:col-span-2 1/2xl:grid 1/2xl:grid-cols-2";
      break;
  }
  return `${baseClasses} ${specificClasses}`.trim();
};

export const programCardClasses = (cardType) => {
  const baseClasses =
    "font-montserrat not-italic leading-5 bg-white shadow-md md:offset-y-4 md:offset-x-0 md:blur-20 md:text-opacity-10 overflow-hidden w-full";
  let specificClasses = "";
  switch (cardType) {
    case "courses":
      specificClasses = "injected-courses-card cursor-pointer";
      break;
    case "landing":
      specificClasses = "flex flex-col justify-between max-w-xl lg:gap-x-4";
      break;
    case "program":
      specificClasses = "grid max-w-6xl lg:gap-x-4 cursor-pointer";
      break;
  }
  return `${baseClasses} ${specificClasses}`.trim();
};

export const getIsPastAppDeadline = (program) => {
  return pastSessionDeadline(program?.session);
};

export const hidePrice = (billingRules, session) => {
  const contracts = billingRules.value?.agreements ?? [];
  if (contracts.length < 1) {
    return false;
  }
  const applicableContract = getApplicableContract(contracts, session);
  return (
    Boolean(applicableContract) &&
    applicableContract?.direct_billing_type !== "Pass through"
  );
};

export const getFormattedInternshipDuration = (session) => {
  const startDate = session?.academic_start_date;
  const endDate = session?.academic_end_date;
  if (!startDate || !endDate) {
    return null;
  }
  const durationInDays = Math.ceil(
    (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
  );
  if (durationInDays < DURATION_IN_DAYS_THRESHOLD) {
    const days = `day${durationInDays > 1 ? "s" : ""}`;
    return `${durationInDays} ${days}`;
  } else {
    const durationInWeeks = Math.ceil(durationInDays / 7);
    const weeks = `week${durationInWeeks > 1 ? "s" : ""}`;
    return `${durationInWeeks} ${weeks}`;
  }
};

export const getInternshipCompensationTag = (internship, session) => {
  return `${getFormattedInternshipDuration(session)} ${internship?.compensation !== "Unpaid" ? "- Paid" : ""}`;
};

export const getIndustries = (internship) => {
  if (internship?.industries?.length > 0) {
    const uniqueCareerPathways = new Set(
      internship.industries.map((industry) => industry.career_pathway)
    );
    return Array.from(uniqueCareerPathways).join(", ");
  }
  return "";
};

export const getCareerInterests = (internship) => {
  const interests = internship?.career_interests ?? [];
  let result = "";
  let totalLength = 0;
  for (let i = 0; i < interests.length; i++) {
    const title = interests[i].soc_title;
    const additionLength = (result ? 2 : 0) + title.length;
    if (totalLength + additionLength <= 80) {
      result += (result ? ", " : "") + title;
      totalLength += additionLength;
    } else if (totalLength < 80) {
      const availableSpace = 80 - totalLength - (result ? 2 : 0);
      if (availableSpace > 0) {
        let truncatedTitle = title.slice(0, availableSpace - 3);
        result += (result ? ", " : "") + truncatedTitle + "...";
      } else if (!result) {
        result = title.slice(0, 77) + "...";
      }
      break;
    }
  }
  return {
    text: result,
    total: interests.length,
    notShown:
      interests.length -
      (result.match(/, /g)?.length ? result.match(/, /g).length + 1 : 1),
  };
};

export const getAreaOfStudy = (subjectArea, summarized) => {
  if (subjectArea?.length <= 0) {
    return "";
  }

  const areaMap = subjectArea
    .map((area) => area.split(">")[1].trim())
    .filter((area) => area)
    .reduce((acc, area) => {
      acc[area] = true;
      return acc;
    }, {});

  const areasOfStudy = Object.keys(areaMap).sort();

  if (summarized) {
    if (areasOfStudy.length <= 2) {
      return areasOfStudy.join(", ");
    }

    const displayedAreas = areasOfStudy.slice(0, 2).join(", ");
    return `${displayedAreas}, ${areasOfStudy.length - 2}+`;
  } else {
    return areasOfStudy.join(", ");
  }
};
