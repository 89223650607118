<template>
  <div v-if="open">
    <div :class="elementClasses" />
    <div :class="modalClasses">
      <div :class="contentClasses">
        <div class="xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r">
          <button
            class="block h-6 w-6 flex items-center justify-center ml-auto mr-2"
            @click="close()"
          >
            <span class="sr-only">Close</span>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="rgba(0, 127, 128, 1)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <h1 class="mx-auto mt-2 text-center text-2xl sm:text-3xl">
          Just double checking...
        </h1>
        <p class="w-11/12 mx-auto mt-5 text-center sm:mt-12">
          You've made a recent payment of ${{ dollarAmount }}.
        </p>
        <p class="w-11/12 mx-auto mt-5 text-center mt-px">
          Are you sure you want to proceed?
        </p>
        <div
          class="w-11/12 mx-auto mt-64 bg-teal-900 p-2 text-white text-center rounded sm:w-1/6 sm:mt-12 cursor-pointer"
          @click="close"
        >
          Go Back
        </div>
        <div
          class="w-11/12 mx-auto mt-2 bg-white p-2 text-teal-900 text-center font-bold sm:w-1/6 cursor-pointer"
          @click="continuePayment"
        >
          Continue
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { centsToDollars } from "../../mixins/helpers";

const ElementClasses =
  "fixed inset-0 z-0 hidden bg-university-primary opacity-50 sm:block";
const ModalClasses =
  "fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12";
const ContentClasses =
  "w-320 h-538 mx-auto bg-white z-20 relative rounded sm:w-3/6 sm:p-16";
export default {
  name: "DuplicatePaymentModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    amountInCents: {
      type: Number,
      default: 0,
    },
  },
  emits: ["closeModal", "continue"],
  data() {
    return {
      modalClasses: ModalClasses,
      contentClasses: ContentClasses,
      elementClasses: ElementClasses,
      logo: localStorage.logoUrl,
    };
  },
  computed: {
    dollarAmount() {
      return centsToDollars(this.amountInCents);
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    continuePayment() {
      this.$emit("continue");
    },
  },
};
</script>
