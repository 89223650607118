<script setup>
import { defineProps, defineEmits, toRefs, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { uuid } from "vue-uuid";
import { parse, isAfter, isEqual } from "date-fns";
import { PlusCircleIcon } from "@heroicons/vue/24/outline";
import PriceItem from "./PriceItem.vue";

// Events
const emit = defineEmits(["addPrice", "updatePrice", "removePrice"]);

// Props
const props = defineProps({
  prices: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  requiredToPublish: {
    type: Boolean,
    default: true,
  },
});

// Variables
const { prices, disabled, requiredToPublish } = toRefs(props);
const parseDateFormat = "yyyy-MM-dd";

const emptyPriceList = computed(() => prices?.value.length === 0 ?? true);
const lastPriceFilled = computed(() =>
  prices?.value[prices?.value.length - 1]?.effective_end_date ? true : false
);

// Validation
const checkStartEndDatesCrossItems = helpers.withMessage(
  "Previous end date should be filled, and lower than start date",
  (value, obj) => {
    const analyzePrices = prices?.value;
    const size = analyzePrices?.length || 0;
    if (size > 1) {
      for (let idx = 1; idx < size; idx++) {
        if (obj === analyzePrices[idx]) {
          const effectiveEndDate = analyzePrices[idx - 1].effective_end_date;
          const effectiveStartDate = analyzePrices[idx].effective_start_date;
          if (effectiveStartDate && !effectiveEndDate) {
            return false;
          }
          if (
            (effectiveStartDate &&
              effectiveEndDate &&
              !!isAfter(
                parse(effectiveEndDate, parseDateFormat, new Date()),
                parse(effectiveStartDate, parseDateFormat, new Date())
              )) ||
            isEqual(
              parse(effectiveStartDate, parseDateFormat, new Date()),
              parse(effectiveEndDate, parseDateFormat, new Date())
            )
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }
);

const rules = computed(() => ({
  $each: helpers.forEach({
    effective_start_date: {
      checkStartEndDatesCrossItems,
    },
  }),
}));

const v$ = useVuelidate(rules, prices, {
  $registerAs: `PriceSection-${uuid.v4()}`,
  $lazy: true,
  $autoDirty: true,
});
</script>

<template>
  <div>
    <PriceItem
      v-for="(price, idx) in prices"
      :key="idx"
      :disabled="disabled"
      :index="idx"
      :model-value="price"
      :required-to-publish="requiredToPublish"
      :error-message="v$.$each?.$response?.$errors[idx]?.effective_start_date"
      :class="{
        'bg-error-100':
          v$.$each?.$response?.$errors[idx]?.effective_start_date?.length,
      }"
      @update:model-value="
        (newPriceValue) => {
          emit('updatePrice', idx, { ...price, ...newPriceValue });
          v$.$touch();
        }
      "
      @remove-price="
        () => {
          emit('removePrice', idx);
          v$.$touch();
        }
      "
    >
      <template #postfixInputLabel>
        <slot name="postfixInputLabel"></slot>
      </template>
    </PriceItem>
    <div v-if="!disabled" class="grid md:grid-cols-3 mt-2 md:space-x-8">
      <div class="flex-1 w-full col-span-3">
        <span class="text-sm italic">
          <slot name="information"
            >* If price does not have and end date, that price is assumed to be
            the price for it perpetuity</slot
          >
        </span>
      </div>
    </div>
    <button
      v-if="!disabled"
      :disabled="!emptyPriceList && !lastPriceFilled"
      class="flex flex-row mt-2 text-center items-center cursor-pointer w-fit"
      @click.prevent="() => emit('addPrice')"
    >
      <div :class="`${secondaryColorClass}`" class="h-7 w-7">
        <PlusCircleIcon />
      </div>
      <div class="text-sm font-semibold text-gray-700 ml-2">
        Add another price
      </div>
    </button>
  </div>
</template>
