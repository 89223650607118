export const COLUMNS_IDS = {
  action: "Action",
  more_info: "More Info",
  status: "Status",
  type: "Type",
  name: "Name",
};

export const headers = [
  "Type",
  "Name",
  "Company/Vendor",
  "More Info",
  "Student Pays",
  "Institution Pays",
  "Total Price",
  "Status",
  "Action",
];

export const ORDER_STATUSES = {
  Unfulfilled: "Unfulfilled",
  Fulfilled: "Fulfilled",
  Canceled: "Canceled",
};

export const ORDER_CONTRACT_SAVE_ERROR_MSG =
  "Unable to save. The contract number or ope_id is invalid for this institution.";

export const ORDER_CONTRACT_SAVE_SUCCESS_MSG =
  "Order contract saved successfully.";
