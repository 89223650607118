export const countApplication = function (totals = {}) {
  let status = ["Applicant"];
  return sumStatus(status, totals);
};

export const countProspective = function (totals = {}) {
  let status = ["programSelected", "shownInterest", "generalInterest"];
  return sumStatus(status, totals);
};

export const countAccepted = function (totals = {}) {
  let status = ["Accepted"];
  return sumStatus(status, totals);
};

export const countOnSite = function (totals = {}) {
  let status = ["Onsite"];
  return sumStatus(status, totals);
};

export const countAlumni = function (totals = {}) {
  let status = ["Alumni"];
  return sumStatus(status, totals);
};

export const countWithdrawn = function (totals = {}) {
  let status = ["Withdrawn"];
  return sumStatus(status, totals);
};

export const countAll = function (totals = {}) {
  let status = ["allStudents"];
  return sumStatus(status, totals);
};

export const sumStatus = function (status, totals) {
  let i = 0;
  let totalSum = 0;
  for (i; i < status.length; i++) {
    totalSum += Object.hasOwnProperty.call(totals, status[i])
      ? totals[status[i]]
      : 0;
  }
  return totalSum;
};
