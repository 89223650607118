import { getApiClient } from "./API";

export default {
  async getCurrentUser() {
    return (await getApiClient().get("/user")).data;
  },
  async updateUserUniversity(payload) {
    return (await getApiClient().put(`/user/update-university`, payload)).data;
  },
  async impersonate(email) {
    return (await getApiClient().post("/impersonate", { email })).data;
  },
  async exitImpersonation() {
    return (await getApiClient().post("/impersonate/exit")).data;
  },
};
