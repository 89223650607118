import { mapState } from "vuex";
import formService from "@/services/form";
import * as Sentry from "@sentry/vue";

export default {
  computed: {
    ...mapState(["previousApplication"]),
    programHasChange() {
      return (
        this.previousApplication.submissionId.length > 1 &&
        !this.previousApplication.finished
      );
    },
  },
  methods: {
    updateProgramChange(application_id, action) {
      let bodyContent = [];

      switch (action) {
        case "application":
          bodyContent.push({
            op: "add",
            path: "/data/newApplicationId",
            value: application_id,
          });
          break;
        case "payment":
          bodyContent.push({
            op: "add",
            path: "/data/changeFinished",
            value: true,
          });
          break;
        default:
          break;
      }

      const submissionId = this.previousApplication["submissionId"];
      formService
        .partiallyUpdateSubmission("programchanges", submissionId, bodyContent)
        .then(() => {
          if (action === "payment") {
            this.$store.commit("setPreviousApplicationIdByField", {
              handle: "finished",
              value: true,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(new Error("Change Program Error"), {
            tags: {
              error: error,
            },
          });
        });
    },
  },
};
