<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import {
  Dialog,
  DialogPanel,
  DialogDescription,
  DialogTitle,
} from "@headlessui/vue";
import internshipService from "@/services/internship";
import housingService from "@/services/housings";
import eventService from "@/services/events";
import programsPageService from "@/services/programsPage";
import { useRoute, useRouter } from "vue-router";
import { DUPLICATE_MSG, AUDIT_FIELDS } from "@/constants.js";
import BaseButton from "@/components/shared/Button/BaseButton";
import _ from "lodash";

const store = useStore();
const router = useRouter();
const route = useRoute();
const duplicateResponse = ref(null);
const disabledButtons = ref(false);
const displayError = ref(false);

const resources = {
  Housing: {
    singular: "Housing",
  },
  Internships: {
    singular: "Internship",
  },
  Programs: {
    singular: "Program",
  },
  Events: {
    singular: "Event",
  },
};

const resource = computed(() => {
  return route.meta.subcategory.replace(/^\w/, (c) => c.toUpperCase());
});

const modalState = computed(
  () => store.getters["programManager/getDuplicateModalState"]
);

const item = computed(() => store.getters["programManager/getDuplicateItem"]);

const title = computed(() => {
  if (duplicateResponse.value === null || duplicateResponse.value === true) {
    return `Duplicate ${resources[resource.value]?.singular}`;
  } else {
    return `Duplicate Unsuccessful`;
  }
});

const formatDuplicateMessage = computed(() => {
  let itemName = "";
  switch (route.meta.subcategory) {
    case "housing":
      itemName = item.value["Housing Type"];
      break;
    case "events":
      itemName = item.value["Name"];
      break;
    case "programs":
      itemName = item.value["Program Name"];
      break;
    default:
      itemName = item.value["Position/Project Title"];
  }
  if (duplicateResponse.value === null || duplicateResponse.value === true) {
    return `${DUPLICATE_MSG} '${itemName}' ?`;
  } else {
    return `'${itemName}' has NOT been duplicated successfully`;
  }
});

const icon = computed(() => {
  return "icon-warning-60.svg";
});

const closeModal = () => {
  store.commit("programManager/setDuplicateModalStateClose");
  displayError.value = false;
  disabledButtons.value = false;
  duplicateResponse.value = null;
};

const duplicateInternship = async () => {
  try {
    const result = await internshipService.getOne(item.value.id);
    const newTitle = "Copy of " + result.data.title;
    const data = _.omit(result.data, ["id", "title", ...AUDIT_FIELDS]);
    data.product_prices = data.product_prices.map(
      ({ id, ...product_price }) => {
        return product_price;
      }
    );
    data.title = newTitle;

    const duplicateInternship = await internshipService.create({ data });
    duplicateResponse.value = !!duplicateInternship.data;

    router.push({
      name: "internship-item",
      params: {
        itemId: duplicateInternship.data.id,
      },
    });
  } catch (err) {
    displayError.value = true;
    throw err;
  }
};

const duplicateHousing = async () => {
  try {
    const { data: response } = await housingService.duplicateHousing(
      item.value.id
    );
    displayError.value = false;
    router.push({
      name: "housing-edit",
      params: {
        itemId: response?.data?.id,
      },
    });
  } catch (err) {
    displayError.value = true;
    throw err;
  }
};

const duplicateEvent = async () => {
  try {
    const result = await eventService.getOne(item.value.id);
    const newTitle = "Copy of " + result.data.data.title;
    const data = _.omit(result.data.data, ["id", "status", ...AUDIT_FIELDS]);
    data.title = newTitle;
    data.status = "Draft";

    const duplicateEvent = await eventService.create(data);
    try {
      let location_data = data?.event_locations;
      location_data.forEach((location) => {
        location.event_id = duplicateEvent.data.data.id;
      });
      location_data.forEach((location) => {
        store.dispatch("events/createLocation", {
          ...location,
        });
      });
    } catch (e) {
      displayError.value = true;
      throw e;
    }
    router.push({
      name: "edit-event",
      params: {
        itemId: duplicateEvent.data.data.id,
      },
    });
  } catch (err) {
    displayError.value = true;
    throw err;
  }
};

const duplicateProgram = async () => {
  try {
    const {
      data: {
        data: { items: programDetails },
      },
    } = await programsPageService.getProgramPage(item.value.id);

    const newProgramData = {
      ..._.omit(programDetails, ["id", "status", "status_id", ...AUDIT_FIELDS]),
      name: `Copy of ${programDetails.name}`,
      status_id: 1,
    };

    const {
      data: {
        data: { items: duplicatedProgram },
      },
    } = await programsPageService.createProgramPage(newProgramData);

    duplicateResponse.value = !!duplicatedProgram;

    router.push({
      name: "programs-edit",
      params: { itemId: duplicatedProgram.id },
    });
  } catch (error) {
    displayError.value = true;
    console.error("Failed to duplicate program:", error);
    throw error;
  }
};

const duplicateProcedures = {
  housing: duplicateHousing,
  internships: duplicateInternship,
  events: duplicateEvent,
  programs: duplicateProgram,
};

const duplicateRecord = async () => {
  disabledButtons.value = true;
  displayError.value = false;
  try {
    await duplicateProcedures[route.meta.subcategory]();
    duplicateResponse.value = true;
    store.commit("programManager/setDuplicateModalStateClose");
  } catch (err) {
    duplicateResponse.value = false;
    displayError.value = true;
  } finally {
    disabledButtons.value = false;
  }
};
</script>

<template>
  <div v-if="modalState" class="relative z-50">
    <div
      class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
    />
    <Dialog
      :open="modalState"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
      @closeModal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-20 relative"
      >
        <div class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r">
          <button
            class="h-6 w-6 flex items-center justify-center"
            @click="closeModal"
            :disabled="disabledButtons"
          >
            <span class="sr-only">Close</span>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <DialogPanel>
          <DialogTitle
            class="flex justify-center mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            {{ title }}
          </DialogTitle>
          <DialogDescription class="mb-10">
            <img
              v-if="duplicateResponse === false"
              :src="'/images/' + icon"
              alt="icon"
              class="mx-auto my-10"
            />
            <p class="text-center mt-10">
              {{ formatDuplicateMessage }}
            </p>
            <p
              v-if="displayError"
              class="text-sm mt-2 text-red-100 text-center"
            >
              Error: unable to copy {{ item["Housing Type"] }}. Please refresh
              your page and try again.
            </p>
          </DialogDescription>

          <div class="flex justify-center">
            <template v-if="duplicateResponse === null">
              <BaseButton
                outlined
                :on-click="closeModal"
                :disabled="disabledButtons"
              >
                Cancel
              </BaseButton>
              <BaseButton :disabled="disabledButtons" @click="duplicateRecord">
                Yes
              </BaseButton>
            </template>
            <BaseButton v-else outlined :on-click="closeModal">
              Close
            </BaseButton>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>
