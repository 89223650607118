<template>
  <component
    :is="component"
    :student="student"
    :program="program"
    @update-component="changeComponent($event)"
  />
</template>

<script>
import Spinner from "../helpers/Spinner";
import InternshipForm from "./forms/InternshipLetter";
import AbroadForm from "./forms/AbroadLetter";
import ThankYouComponent from "./ThankYouComponent";
import ProgramSessionDates from "../../mixins/ProgramSessionAndDates";

export default {
  components: {
    "internship-form": InternshipForm,
    "abroad-form": AbroadForm,
    Spinner: Spinner,
    "thank-you": ThankYouComponent,
  },
  mixins: [ProgramSessionDates],
  props: {
    applicationHash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      component: "Spinner",
      student: {},
      program: {},
    };
  },
  async mounted() {
    try {
      // Validating Student Data
      let studentRequest = await this.validateStudent();
      if (!this.guardStudentData(studentRequest)) return;
      this.student = studentRequest;

      // Gathering Program Data
      let programRequest = await this.getProgramSessionAndDates({
        program_id: studentRequest["program_id"],
        apolloClient: this.$apollo,
      });
      if (!this.guardProgramData(programRequest)) return;
      this.program = programRequest["data"]["programSessionBySalesforceId"];
    } catch (error) {
      console.error(error);
      return;
    }

    // Setting up form
    if (this.program["program"]["internship"]) {
      this.changeComponent("internship-form");
      return;
    }
    this.changeComponent("abroad-form");
  },
  methods: {
    validateStudent() {
      let postData = {
        hash: this.applicationHash,
      };
      return fetch(
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "recommendation/validate",
        {
          method: "post",
          body: JSON.stringify(postData),
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.warn(e);
          return false;
        });
    },
    changeComponent(updatedComponent) {
      this.component = updatedComponent;
    },
    guardStudentData(data) {
      if (!data) return false;
      if (!Object.hasOwnProperty.call(data, "program_id")) return false;
      return true;
    },
    guardProgramData(data) {
      if (!data) return false;
      if (!Object.hasOwnProperty.call(data, "data")) return false;
      if (
        !Object.hasOwnProperty.call(
          data["data"],
          "programSessionBySalesforceId"
        )
      )
        return false;
      if (
        !Object.hasOwnProperty.call(
          data["data"]["programSessionBySalesforceId"],
          "program"
        )
      )
        return false;
      return true;
    },
  },
};
</script>
