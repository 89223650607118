<template>
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0.883789L7.5 7.38379L14 0.883789"
      stroke="#1E264C"
      stroke-width="2"
    />
  </svg>
</template>
