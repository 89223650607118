import { getApiClient } from "./API";

const v3FormTracker = {
  async isComplete(form_name = "", application_id = "") {
    return (
      await getApiClient().get(
        `/v3-form-tracker/find/${form_name}/${application_id}`
      )
    ).data;
  },
  async setAsCompleted(form_name = "", application_id = "", extraPayload = {}) {
    return (
      await getApiClient().post(
        `/v3-form-tracker/create/${form_name}/${application_id}`,
        { ...extraPayload }
      )
    ).data;
  },
};

export default v3FormTracker;
