export const compareStringsInLowerCase = (value, valueToCompare) => {
  return value.toLowerCase() === valueToCompare.toLowerCase();
};

export const useCapitalizeWords = (phrase) => {
  const words = phrase.split(" ");

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export const hasDotPrefix = (value) => {
  const isValid = value.every((fileType) => fileType.startsWith("."));
  if (!isValid) {
    console.error(
      "Invalid file types provided. Each file type should start with a dot (.)"
    );
  }
  return isValid;
};

export const isLowerCase = (value, message = "Error") => {
  const isValid = value.every(
    (fileType) => fileType === fileType.toLowerCase()
  );
  if (!isValid) {
    console.error(
      `${message} : Invalid file types provided. Each file type should be in lowercase.`
    );
  }
  return isValid;
};
export const randomString = (length = 5) => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const createTestId = (str, suffix = "") => {
  const formattedStr = str.toLowerCase().trim().replace(/\s+/g, "-");
  return suffix ? `${formattedStr}-${suffix}` : formattedStr;
};
