import FormioUtils from "formiojs/utils";
import { revertCamelCase } from "./helpers";
export default {
  methods: {
    processHousingRules(form) {
      // Query to get component
      let component = FormioUtils.findComponents(form.components, {
        type: "radio",
        key: "accommodationStylePreference",
      });
      // Check that only one component has returned
      if (Array.isArray(component) && component.length === 1) {
        let newOptions = [];
        let i = 0;
        for (i = 0; i < component[0]["component"]["values"].length; i++) {
          // Checking that component has attribute 'value'
          let optionValue = Object.hasOwnProperty.call(
            component[0]["component"]["values"][i],
            "value"
          )
            ? revertCamelCase(component[0]["component"]["values"][i]["value"])
            : "";
          // Adding component to new set of options if it's on the program housing rules list
          if (this.program.housing_rules.includes(optionValue)) {
            newOptions.push(component[0]["component"]["values"][i]);
          }
        }
        component[0]["component"]["values"] = newOptions;
        component[0].redraw();
      }
    },
  },
};
