<script setup>
import { defineProps, toRefs, watch } from "vue";
import EventsCard from "@/components/ExperiencePage/ProgramDetailsSection/excursions/Components/EventsCard.vue";

defineOptions({
  name: "EventsCards"
})
const props = defineProps({
  events: {
    type: Object,
    required: true,
  },
  defaultEvent: {
    type: Object,
    required: true,
  },
});

const { events } = toRefs(props);

watch(events, () => {
  events.value.forEach((event) => {
    event.image = JSON.parse(event.image);
  });
});
</script>

<template>
  <div
    class="mt-10 grid md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-8 pb-18"
  >
    <div
      v-for="(event, idx) in events || []"
      :key="event?.id || idx"
      class="w-full relative overflow-hidden shadow-xl shadow-gray-500 bg-white"
    >
      <EventsCard :event-data="event" :default-event="defaultEvent" />
    </div>
  </div>
</template>
