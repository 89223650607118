import store from "@/store";
import { computed } from "vue";
import { FEATURES, PERMISSIONS, API_RELATIONSHIPS } from "@/constants.js";

export const featureFlags = computed(() => store?.state?.featureFlags);

const currentUserAPIRelationship = computed(
  () => store.getters.getEntityApiRelationship
);

const currentUserPermissions = computed(
  () => store.state?.currentUser?.permissions ?? []
);

export const isAdmin = () =>
  currentUserPermissions.value.includes(PERMISSIONS.SUPER_ADMIN);

export const isApiEmployee = () =>
  currentUserPermissions.value.includes(PERMISSIONS.API_EMPLOYEE);

export const isTenantManager = () =>
  currentUserPermissions.value.includes(PERMISSIONS.TENANT_MANAGER);

export const hasPublishPermission = () => isAuthorized(PERMISSIONS.ITEMS_PUB);
export const hasWritePermission = () => isAuthorized(PERMISSIONS.ITEMS_WRITE);

export const hasProgramsReadLitePermission = () =>
  isAuthorized([
    PERMISSIONS.PRGMS_PUB,
    PERMISSIONS.PRGMS_WRITE,
    PERMISSIONS.PRGMS_READ,
    PERMISSIONS.PRGMS_READ_LITE,
  ]);
export const hasProgramsReadPermission = () =>
  isAuthorized([
    PERMISSIONS.PRGMS_PUB,
    PERMISSIONS.PRGMS_WRITE,
    PERMISSIONS.PRGMS_READ,
  ]);
export const hasProgramsWritePermission = () =>
  isAuthorized([PERMISSIONS.PRGMS_PUB, PERMISSIONS.PRGMS_WRITE]);

export const hasProgramsPublishPermission = () =>
  isAuthorized([PERMISSIONS.PRGMS_PUB]);

export const isAuthorized = (requiredPermissions) => {
  if (!Array.isArray(requiredPermissions)) {
    requiredPermissions = [requiredPermissions];
  }
  if (requiredPermissions.length < 1) {
    return true;
  }

  return (
    isAdmin() ||
    currentUserPermissions.value.some((permission) =>
      requiredPermissions.includes(permission)
    )
  );
};

export const hasItemsWritePermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return permissions.includes(PERMISSIONS.ITEMS_WRITE);
});

export const hasItemsReadPermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return permissions.includes(PERMISSIONS.ITEMS_READ);
});

export const hasAPIEmployeePermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return permissions?.includes(PERMISSIONS.API_EMPLOYEE);
});

export const curriculumAppEnabled = computed(() => {
  // Delete this computed property when flag gets deprecated
  return featureFlags.value[PERMISSIONS.ENABLE_CURRICULUM_INTEGRATION_APP];
});

export const hasEnableResearchApp = computed(() => {
  return featureFlags.value[PERMISSIONS.ENABLE_RESEARCH_APP];
});

export const hasEnableHomeApp = computed(() => {
  return featureFlags.value[PERMISSIONS.ENABLE_HOME_APP];
});

export const hasEnableMyStudentsApp = computed(() => {
  return featureFlags.value[PERMISSIONS.ENABLE_MY_STUDENTS_APP];
});
export const hasEnableCareerAndSkillsApp = computed(() => {
  return featureFlags.value[PERMISSIONS.ENABLE_CAREER_AND_SKILLS_APP];
});

export const hasCacheFeatureEnabled = computed(() => {
  return (
    featureFlags.value[FEATURES.cacheClear] && hasAPIEmployeePermission.value
  );
});

export const hasInvoiceFeatureEnabled = computed(() => {
  return featureFlags.value[FEATURES.invoice];
});

export const hasOrdersReadPermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return permissions.includes(PERMISSIONS.ORDERS_READ);
});

export const hasOrdersWritePermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return permissions.includes(PERMISSIONS.ORDERS_WRITE);
});

export const hasOrdersAdminPermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return permissions.includes(PERMISSIONS.ORDERS_ADMIN);
});

export const hasUsersAdvisePermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return permissions.includes(PERMISSIONS.USERS_ADVISE);
});

export const hasStudentViewPermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return (
    permissions.includes(PERMISSIONS.INSTITUTION) ||
    permissions.includes(PERMISSIONS.STUDENT_MANAGEMENT)
  );
});

export const advisorHasTableauMemberPermission = computed(() => {
  const permissions = store.getters.getPermissions;
  return (
    permissions.includes(PERMISSIONS.INSTITUTION) &&
    permissions.includes(PERMISSIONS.TABLEAU_VIEWER_MEMBER)
  );
});

export const isExclusivelyParticipant = computed(() => {
  const userTypes = store?.state?.profileData?.user_types;
  return userTypes?.length === 1 && userTypes[0].value === "Participant";
});

export const isTenantLibrariesFlagEnabled = computed(() => {
  return featureFlags.value[FEATURES.tenantLibraries];
});

/**
 * Checks if the current user is the owner of a library item.
 * @param {string} entityId - The ID of the entity to check ownership for.
 * @returns {boolean} True if the user is the owner of the library item, has API employee permissions,
 * or the feature flag is disabled, false otherwise.
 * NB: Should be refactored to remove isTenantLibrariesEnabled condition as soon the feature flag is removed
 */
export const isLibraryItemOwner = (entityId) => {
  if (isApiEmployee() || isTenantManager()) {
    return true;
  }

  const userEntities = store.getters.getEntityIds;
  return isTenantLibrariesFlagEnabled.value
    ? userEntities.some((entity) => entity === entityId)
    : true;
};

/**
 * Sets marketplace to true.
 * @returns {boolean} True if the user is not a tenant manager or the feature flag is disabled, false otherwise
 * NB: Should be refactored to remove isTenantLibrariesEnabled condition as soon the feature flag is removed
 */
export const shouldAddMarketplace = () => {
  return !isTenantManager() || !isTenantLibrariesFlagEnabled.value;
};

/**
 * Checks if user has access to the curriculum integration app
 * @returns {boolean} True if user can view icon in sidebar and access the route, otherwise false and block him from entering.
 * Notes: Used as a route navigation guard and inside a computed property
 */
export function checkCurriculumAppAccess() {
  if (!curriculumAppEnabled.value) {
    return false; // Delete condition if flag gets deprecated.
  }

  if (advisorHasTableauMemberPermission.value) {
    return currentUserAPIRelationship.value === API_RELATIONSHIPS.APIC_MEMBER;
  }
  return false;
}

export const hasAccessToCurriculumIntegration = computed(() =>
  checkCurriculumAppAccess()
);
