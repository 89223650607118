<template>
  <div
    class="font-montserrat text-[#1E264C] text-xs py-6 px-6 md:px-20 md:py-12 md:w-10/12"
  >
    <div v-if="!loading && isLangFetched">
      <h1 class="font-bold text-4xl" data-testid="credits-header">
        CREDITS
      </h1>
      <p
        class="text-xl mt-[21px] leading-8 md:w-full text-left"
        data-testid="credits-question"
      >
        Choose how you would like to receive your credits (select one)
      </p>
      <div class="md:mt-[33px]">
        <label
          v-for="[id, option] in Object.entries(options)"
          :key="option.value"
          :data-testid="`radio-option-${option.value}`"
        >
          <div
            v-if="showOption(option.value)"
            class="flex mt-[21px] md:mt-[17px] h-16 md:h-12 md:items-center"
          >
            <input
              v-model="selectedOptionId"
              type="radio"
              :value="id"
              name="credits"
              :class="`min-w-[20px] ${secondaryColorClassAccent} w-5 min-h-max min-h-[20px] h-5`"
            />
            <div :id="option.value" class="text-lg font-medium leading-6 ml-3">
              <span v-if="option.value === 'uMassAmherst'">{{
                uMassTitle
              }}</span>
              <span v-else> {{ option.title }}</span>
              <template v-if="showPrice">
                <div
                  v-if="option.cost === 0"
                  :id="`nocost-${option.value}`"
                  :class="`font-bold ${secondaryColorClass} text-base  md:ml-2.5 md:inline-block`"
                >
                  NO COST
                </div>
                <div
                  v-else
                  :id="`cost-${option.value}`"
                  :class="`font-bold text-base ${secondaryColorClass} md:ml-2.5 inline-block`"
                >
                  + {{ formattedPrice(option.cost).split(".")[0] }}
                </div>
              </template>
            </div>
          </div>
        </label>
        <hr class="w-full text-indigo-light mb-5 mt-5" />
        <div class="text-xl">
          <p>
            Review available internships and select up to three placements.
            After submitting your application and materials such as resume and
            statement of purpose, you will review your selections with a career
            coach, complete an employer interview and secure your placement!
          </p>
          <p data-testid="credit-paragraph-1" class="mt-5">
            In addition to gaining valuable work experience,<span
              v-if="isUMassOrHomeSchool"
            >
              students interning in Italy are
              <span class="font-bold">required</span> to enroll in academic
              credit for your internship either by your home university or
            </span>
            <span v-else>
              you can earn academic credit for your internship
            </span>
            by enrolling in
            <a
              href="/files/UMass_Amherst_Syllabus.pdf"
              target="_blank"
              :class="`${secondaryColorClass}`"
              data-testid="intern-301-link"
              >INTERN 301</a
            >. The asynchronous course,
            <span v-if="isAnyUMass"
              >accredited and transcripted by University of Massachusetts at
              Amherst,</span
            >
            prepares API interns for navigating diverse cultural workplace
            environments and developing essential skills necessary for success
            in a global workplace.
          </p>
          <p
            v-if="isUMassAndCongreso"
            class="mt-5"
            data-testid="credit-paragraph-2"
          >
            If you are completing your internship in Spanish, you can earn
            Spanish-language credit by enrolling in
            <a
              href="/files/Universidad_de_Congreso_Syllabus.pdf"
              target="_blank"
              :class="`${secondaryColorClass} italic`"
              data-testid="ppc-internship-link"
              >PPC Internship Experience in Spanish</a
            >. This course includes synchronous class meetings and offline work.
            Students must have taken at least 2 Spanish classes at the 300 level
            or above, or demonstrate written and oral proficiency at the
            equivalent level.
          </p>
        </div>
      </div>
    </div>
    <div v-else><Spinner class="relative h-65vh" /></div>
  </div>
  <StickyFooter
    max-next-width="max-w-[260px]"
    :disabled-next="!selectedOptionId"
    :show-required-section="false"
    @back="goToPreviousStep"
    @next="goToNextStep"
  />
</template>
<script setup>
import { ref, inject, onMounted, computed, watch } from "vue";
import Spinner from "@/components/helpers/Spinner.vue";
import StickyFooter from "@/components/Configurator/StickyFooter.vue";
import { CONGRESO_ID, UMASS_ID } from "@/constants";
import getApplicableContract from "@/composables/useBillings.js";
import {
  UMassCities,
  UMassAndCongresoCities,
  UMassOrHomeSchoolCities,
} from "@/components/program-manager/sessions/components/Internships/constants.js";
import { formattedPriceFromCents as formattedPrice } from "@/util/formatter";

const { credits, setCreditsValue, internships, miscProductIds } = inject(
  "credits"
);
const { goToNextStep, goToPreviousStep } = inject("steps");
const { program } = inject("program");
const contracts = inject("contracts");

const prices = computed(() => credits.prices);
const loading = computed(() => credits.loading);

const options = computed(() => {
  return {
    [UMASS_ID]: {
      title: "Get credit through the University of Massachusetts-Amherst",
      cost: prices.value?.[UMASS_ID] ?? null,
      value: "uMassAmherst",
      productId: UMASS_ID,
    },
    [CONGRESO_ID]: {
      title:
        "Get 3 credits through Universidad de Congreso (Spanish Transcript)",
      cost: prices.value?.[CONGRESO_ID] ?? null,
      value: "Congreso",
      productId: CONGRESO_ID,
    },
    university: {
      title: "My home university awards me credit",
      cost: 0,
      value: "university",
      productId: null,
    },
    noCredit: {
      title: "I don't need credit",
      cost: 0,
      value: "noCredit",
      productId: null,
    },
  };
});

const initializeModel = () => {
  for (const productId of [CONGRESO_ID, UMASS_ID]) {
    if (miscProductIds.value.includes(productId)) {
      selectedOptionId.value = productId;
      break;
    }
  }
};

const selectedOptionId = ref(null);
const languageFetched = ref(false);
const isLangFetched = computed(() => {
  return languageFetched.value;
});

const spanishLanguage = ref(false);
const checkSpanish = (requiredLang) => {
  return requiredLang.value === "Spanish";
};

const applicableContract = computed(() =>
  getApplicableContract(contracts.value, program?.programSession)
);
const showPrice = computed(() => {
  const institutionPays = Boolean(
    applicableContract.value?.transrcriptFeeInternAbroad === "Institution"
  );
  const isPassthrough = Boolean(
    applicableContract.value?.direct_billing_type === "Pass through"
  );

  if (institutionPays && !isPassthrough) return false;
  return true;
});

const showOption = (optionValue) => {
  if (!isUMassOrHomeSchool.value)
    return optionValue !== "Congreso" ? true : spanishLanguage.value;
  return ["uMassAmherst", "university"].includes(optionValue);
};

const isAnyUMass = computed(() => {
  return isUMass.value || isUMassAndCongreso.value || isUMassOrHomeSchool.value;
});

const arrivalCityId = computed(() =>
  program?.programSession?.session_travel_details[0]?.arrival_city?.id?.toString()
);

const isUMass = computed(() =>
  Object.keys(UMassCities).includes(arrivalCityId.value)
);

const isUMassAndCongreso = computed(() =>
  Object.keys(UMassAndCongresoCities).includes(arrivalCityId.value)
);

const isUMassOrHomeSchool = computed(() =>
  Object.keys(UMassOrHomeSchoolCities).includes(arrivalCityId.value)
);

const uMassTitle = computed(() => {
  if (!internships.value.length > 0) return options.value[UMASS_ID].title;
  let cred = 0;
  let opts = [8, 10, 12];
  if (internships?.value?.some((item) => item.duration_unit === "weeks")) {
    internships.value.forEach((item) => {
      if (opts.includes(item.duration) && Boolean(item.duration)) {
        if (item.duration === 8 && cred < 8) cred = 6;
        else if (item.duration === 10 && cred < 10) cred = 8;
        else cred = 9;
      }
    });
  }
  if (!cred) return options.value[UMASS_ID].title;
  return `Get ${cred} credits through University of Massachusetts-Amherst`;
});

const loadLanguageInternship = () => {
  languageFetched.value = false;
  internships?.value.forEach((int) => {
    if (int.required_languages.some(checkSpanish)) spanishLanguage.value = true;
  });
  languageFetched.value = true;
};

const loadSavedModel = async () => {
  const id = Object.keys(options.value).find(
    (id) => options.value[id].option === credits.option
  );
  selectedOptionId.value = id;
};

onMounted(() => {
  spanishLanguage.value = false;
  loadSavedModel();
  loadLanguageInternship();
  initializeModel();
});

watch(
  () => selectedOptionId.value,
  (oldId, newId) => setCreditsValue(options.value[oldId], options.value[newId])
);
</script>
<style scoped>
</style>
