import { trailingSlash } from "./helpers";

export default {
  data() {
    return {
      sites: [],
    };
  },
  computed: {
    programsURL() {
      return (
        trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) +
        "programs/" +
        this.location.value +
        "/" +
        this.semester.toLowerCase()
      );
    },
  },
  methods: {
    async initProgramsList() {
      await fetch(this.programsURL).then((response) =>
        response.json().then((result) => {
          let programs = [];
          for (let key in result) {
            if (
              result[key]["customized"] &&
              result[key]["program_participation_criteria"] !== "All Students"
            ) {
              if (
                this.userData.data.schoolname.value ===
                result[key]["sponsoring_institution_ope8_id"]
              ) {
                programs.push({
                  label: result[key]["name"],
                  value: result[key]["id"],
                  shortcut: "",
                });
              }
            } else {
              programs.push({
                label: result[key]["name"],
                value: result[key]["id"],
                shortcut: "",
              });
            }
          }
          if (programs.length > 0) {
            this.availablePrograms = programs;
          } else {
            this.noProgramsAvailable = true;
          }
        })
      );
    },
  },
};
