import { DEFAULT_IMAGE } from "../constants";

export const getImageList = (leadMedia) => {
  try {
    return JSON.parse(leadMedia).filter((media) =>
      /\.(jpg|jpeg|png|gif)$/i.test(media)
    );
  } catch {
    return [];
  }
};

export const selectImageForProgram = (hit, programMapImageIndices) => {
  let image = DEFAULT_IMAGE;
  if (hit?.program_page?.lead_media) {
    const imageList = getImageList(hit.program_page.lead_media);
    if (imageList.length) {
      const programId = hit.program_page.id;
      if (programMapImageIndices[programId] === undefined) {
        programMapImageIndices[programId] = 0;
      }
      image = imageList[programMapImageIndices[programId] % imageList.length];
      programMapImageIndices[programId] = programMapImageIndices[programId] + 1;
    }
  }
  return image;
};
