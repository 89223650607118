import { MAJORS } from "../constants";

export default {
  created() {
    const sortMajors = MAJORS.sort((a, b) => a.label.localeCompare(b.label));
    this.MAJORS = sortMajors;
  },
  methods: {
    searchMajor(options, search) {
      return search.length
        ? options.filter((major) =>
            major.label.toLowerCase().includes(search.toLowerCase())
          )
        : options;
    },
  },
};
