<template>
  <div>
    <div class="text-gray-600">
      <p>
        We need a headshot!! This will be used for things like knowing who you
        are at airport pick up, university registration, immigration documents,
        ID cards, etc. You can have a photo taken that meets our requirements at
        places like Walgreens, CVS, Post Offices, or do it yourself. If you go
        the DIY route, you must meet the size and quality requirements outlined
        below.
      </p>
      <ol class="pl-6 mb-6 list-decimal">
        <li>
          Your photo should be in color and the background must be plain white
          (such as against a white untextured wall).
        </li>
        <li>
          Your photo should only show your head and shoulders and you should be
          turned directly toward the camera.
        </li>
        <li>You must be the only person in the photo.</li>
        <li>
          You should not have anything covering your head (hat, scarf) or
          obstructing your face (sunglasses). Religious attire is allowed if it
          does not cover your face.
        </li>
        <li>
          Please make your facial expression as neutral as possible. You can
          smile, but it must be a natural and unexaggerated smile. Both your
          eyes must be open.
        </li>
        <li>Do not submit selfies or cut your head out of a group photo.</li>
      </ol>
      <p class="font-bold">Example:</p>
      <a
        target="_blank"
        href="https://apiabroad-cdn.s3.us-east-2.amazonaws.com/images/passport-photo-instructions.png"
      >
        <img
          src="https://apiabroad-cdn.s3.us-east-2.amazonaws.com/images/passport-photo-instructions.png"
          class="w-32 max-w-6xl h-auto mx-auto"
          alt="passport-photo-instructions"
        />
      </a>
      <p class="mt-3 font-bold">Submission Instructions</p>
      <p>Upload a photo below following these specifications:</p>
      <ol class="pl-6 mb-6 list-decimal">
        <li>2 inches by 2 inches</li>
        <li>180-300 dpi resolution</li>
        <li>File size no more than 15 mb</li>
        <li>jpeg or pdf format only</li>
      </ol>
      <form role="form" aria-label="Headshot" @submit.prevent="submitForm()">
        <FileUpload
          :name="'headshot'"
          :formio-files="v$.value.files.$model"
          :file-types="['.jpeg', '.pdf', '.jpg']"
          :max-file-size="15"
          @updateProgress="updateProgress"
          @updateFiles="updateFiles"
        />
        <button-with-spinner
          ref="formButton"
          :type="'submit'"
          variant="primary"
          variant-type="block"
          :disabled="v$.$invalid || button.doingSubmission"
        >
          {{ button.message }}
        </button-with-spinner>
        <br />
        <span v-if="button.finished" class="text-success-900 mt-2">
          Submission successful. Please watch your email for any necessary
          corrections from your Program Coordinator.
        </span>
      </form>
    </div>
  </div>
</template>

<script>
import nestedModelComponent from "../../../mixins/nestedModelComponent";
import FileUpload from "../SharedComponents/FileUpload.vue";

import { required, minLength } from "@vuelidate/validators";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import formService from "@/services/form";
import useVuelidate from "@vuelidate/core";

export default {
  name: "HeadShotForm",
  components: {
    FileUpload,
    ButtonWithSpinner,
  },
  mixins: [nestedModelComponent],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
    submissionId: {
      type: String,
      default: "",
    },
    submissionData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    componentForm: {
      type: String,
      default: "headshot",
    },
  },
  emits: ["button-style", "set-form-info"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        files: [],
      },
      uploadOnProgress: false,
      uploadErrors: false,

      button: {
        message: "Submit",
        doingSubmission: false,
        finished: false,
      },
      formField: "headshotImage",
    };
  },
  watch: {
    submissionData: {
      handler: function (newval) {
        if (newval === undefined || newval === null) return;
        if (!Object.hasOwnProperty.call(newval, this.formField)) return;
        if (!Array.isArray(newval[this.formField])) return;
        this.value["files"] = newval[this.formField];
        this.setButtonBehavior("submit-again");
      },
      immediate: true,
    },
  },
  validations: {
    value: {
      files: {
        required,
        minLength: minLength(1),
      },
    },
    uploadOnProgress: {
      isFalse: (value) => value === false,
    },
    uploadErrors: {
      isFalse: (value) => value === false,
    },
  },

  methods: {
    async submitForm() {
      this.setButtonBehavior("start-submission");
      try {
        let submission = await this.prepareFormioSubmission();
        if (!submission.ok) {
          this.setButtonBehavior("end-submission-fail");
          return;
        }
        let submissionData = await submission.json();
        this.$emit("button-style");
        this.$emit("set-form-info", {
          type: "headshotSubmission",
          id: submissionData["_id"],
          data: submissionData["data"],
        });
        this.button.finished = true;
      } catch (e) {
        // False positive
        if (e instanceof Error && Object.hasOwnProperty.call(e, "response")) {
          let error = await e.response.json();
          if (
            Object.hasOwnProperty.call(error, "errors") &&
            Array.isArray(error.errors) &&
            error.errors.find((err) => err === "Resource not found")
          ) {
            this.button.finished = true;
          }
        } else {
          console.error(e);
        }
      } finally {
        this.setButtonBehavior("submit-again"); // Let user submit again
      }
    },
    prepareFormioSubmission() {
      const submission = {
        application_id: this.applicationId,
        [this.formField]: this.value["files"],
        headshotSubmitted: true,
      };

      return {
        update: () =>
          formService.updateSubmission(
            this.componentForm,
            this.submissionId,
            submission
          ),
        create: () =>
          formService.createSubmission(this.componentForm, submission),
      }[this.submissionId ? "update" : "create"]();
    },
    updateProgress(val) {
      this.uploadOnProgress = val;
    },
    updateFiles(files, error) {
      this.value["files"] = files;
      this.uploadErrors = error;
      this.button.finished = false;
    },
    setButtonBehavior(action = "") {
      switch (action) {
        case "start-submission":
          this.button["doingSubmission"] = true;
          this.button["message"] = "Submitting, please wait.";
          this.button["finished"] = true;
          if (this.$refs.formButton) this.$refs.formButton.startLoading();
          break;
        case "end-submission-ok":
          this.button["doingSubmission"] = false;
          this.button["message"] = "Submission complete.";
          this.button["finished"] = true;
          if (this.$refs.formButton) this.$refs.formButton.stopLoading();
          break;
        case "end-submission-fail":
          this.button["doingSubmission"] = false;
          this.button["message"] = "Submission failed, retry.";
          if (this.$refs.formButton) this.$refs.formButton.stopLoading();
          break;
        case "submit-again":
          this.button["doingSubmission"] = false;
          this.button["message"] = "Update Submission";
          this.button["finished"] = true;
          if (this.$refs.formButton) this.$refs.formButton.stopLoading();
          break;
      }
    },
  },
};
</script>
