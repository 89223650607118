<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="rvdw6q6baa"
        d="M8 1v.03l3.007.021a1 1 0 0 1 .993 1v.982H0V2.05a1 1 0 0 1 .993-1L4 1.03V1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zM0 13.802c0 1.23 1 2.227 2.231 2.227h7.535a2.23 2.23 0 0 0 2.231-2.227v-9.75H0v9.75zM2 6.05h8v7.242a.745.745 0 0 1-.744.742H2.744A.745.745 0 0 1 2 13.291V6.049zm5 .967h2v5.988H7V7.016zm-2 0H3v5.988h2V7.016z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 1h16v16H0z" />
      <g transform="translate(2 .97)">
        <mask id="1toqi4q5nb" fill="#fff">
          <use xlink:href="#rvdw6q6baa" />
        </mask>
        <use fill="#6B6C7E" xlink:href="#rvdw6q6baa" />
        <path fill="#6A7181" mask="url(#1toqi4q5nb)" d="M-2 .029h16v16H-2z" />
      </g>
    </g>
  </svg>
</template>
