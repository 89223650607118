<template>
  <Dialog
    class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
    @closeModal="closeModal"
    @click.self="closeModal"
  >
    <div
      class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-10 sm:rounded-lg sm:shadow z-20 relative"
    >
      <div class="absolute xs:top-1r sm:top-1r md:top-1r lg:top-1r right-1r">
        <button
          class="h-6 w-6 flex items-center justify-center"
          @click="closeModal"
        >
          <span class="sr-only">Close</span>
          <CloseIcon />
        </button>
      </div>
      <DialogPanel>
        <DialogTitle
          class="flex justify-center mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl my-10"
        >
          Conflicting Orders
        </DialogTitle>
        <DialogDescription>
          <ExclamationTriangleIcon
            class="text-help-warning w-16 h-16 mx-auto"
          />
          <p class="text-left mt-10 mb-2">
            {{ FUTURE_BOOKINGS_WARNING_MESSAGE }}
          </p>
          <p class="text-left font-semibold mt-6 mb-2">
            Total Number of Conflicting Bookings:
            {{ conflictBookingDetails?.bookings?.length ?? 0 }}
          </p>
          <div class="flex flex-wrap">
            <div
              class="flex justify-between w-2/4 items-center my-1 pr-6"
              v-for="booking in bookings"
            >
              <p>{{ booking?.name ?? `Order ${booking.order_id}` }}</p>
              <div
                class="rounded-md border px-6 py-2 text-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-none font-medium mr-5 shadow-sm bg-teal-900 text-white hover:bg-white hover:text-teal-900 hover:border-gray-300"
                :data-testid="`${dataTestId}-order-link`"
              >
                <router-link
                  :key="`order-details-${booking.order_id}`"
                  :to="{
                    name: 'order-detail',
                    params: { orderId: booking.order_id }
                  }"
                  target="_blank"
                  >Order Details</router-link
                >
              </div>
            </div>
          </div>
        </DialogDescription>
        <div class="flex justify-center mt-14">
          <BaseButton
            :data-testid="`${dataTestId}-close-button`"
            class="h-10"
            outlined
            @click.self="closeModal"
          >
            Close
          </BaseButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { computed, defineProps, defineEmits, toRefs } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from "@headlessui/vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import { FUTURE_BOOKINGS_WARNING_MESSAGE } from "@/components/program-manager/housing/constants"

const props = defineProps({
  conflictBookingDetails: Object,
  dataTestId: {
    type: String,
    default: "booking-conflict"
  },
});

const emit = defineEmits(["onClose"]);
const { dataTestId, conflictBookingDetails } = toRefs(props);

const closeModal = () => {
  emit("onClose")
}

const bookings = computed(() =>
  conflictBookingDetails.value?.bookings?.slice(0, 5) ?? []
);

</script>
