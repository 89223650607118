<script setup>
import { defineProps, toRefs, computed } from "vue";

const props = defineProps({
  quoteObj: {
    type: Object,
    required: true,
    default: () => {},
  },
});
const { quoteObj } = toRefs(props);

let learnerQuote = computed(() => {
  return quoteObj?.value?.learner_quote;
});

let learnerName = computed(() => {
  return quoteObj?.value?.learner_name;
});

let learnerDetail = computed(() => {
  return quoteObj?.value?.learner_details;
});
</script>
<template>
  <div>
    <h1 class="text-xl mt-4 lg:mt-8 mb-2 font-bold text-indigo-base">
      {{ learnerQuote || "" }}
    </h1>
    <h3 
      class="text-sm "
      :class="[
        secondaryColor ? `${secondaryColorClass}` : `text-university-secondary`
      ]"
      >
      {{ learnerName || "" }}
    </h3>
    <h3 
      class="text-xs font-semibold"
      :class="[
        secondaryColor ? `${secondaryColorClass}` : `text-university-secondary`
      ]"
    >
      {{ learnerDetail || "" }}
    </h3>
  </div>
</template>
