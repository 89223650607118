<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { useVModel } from "@vueuse/core";
import PlusIcon from "@/components/shared/icons/PlusIcon.vue";
import { ValidateEach } from "@vuelidate/components";
import { requiredIf, minLength } from "@vuelidate/validators";

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  isOtherPacking: Boolean,
  otherPacking: {
    type: Array,
    default: () => [],
  },
  events: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits([
  "togglePacking",
  "addPackingContent",
  "removePackingContent",
  "updateOtherPacking",
]);

const handleAddContent = () => {
  emit("addPackingContent");
};

const handleRemoveContent = (index) => {
  emit("removePackingContent", index);
};

const updateOtherPackingValue = (index, newValue, v) => {
  v.value.$model = newValue;
  emit("updateOtherPacking", { index, newValue });
};

const events = useVModel(props, "events", emit);

const isChecked = computed(() => {
  return events.value.packing_list?.includes(props.item.id);
});

const rules = computed(() => ({
  value: {
    required: requiredIf(() => props.isOtherPacking),
    minLength: minLength(1),
  },
}));
</script>

<template>
  <div
    class="flex justify-between w-fit items-center mb-3"
    :class="isOtherPacking ? 'col-span-2' : ''"
  >
    <input
      :id="item.id"
      :checked="isChecked"
      type="checkbox"
      class="h-4 w-4 form-checkbox border-gray-300 mr-10"
      @input="emit('togglePacking', item)"
    />
    <label :for="item.id" class="text-sm font-semibold text-gray-700">{{
      item.name
    }}</label>
    <div v-if="isOtherPacking">
      <ValidateEach
        v-for="(text, index) in otherPacking"
        :key="index"
        :state="{ value: text }"
        :rules="rules"
      >
        <template #default="{ v }">
          <div class="ml-5 mt-1 w-full rounded-sm flex">
            <textarea
              :id="`otherPackingDescription-${index}`"
              :value="v.value.$model"
              name="otherPackingDescription"
              maxlength="50"
              class="h-10 md:min-w-[330px] block bg-white rounded-md ring-1 ring-gray-300 border-gray-300 py-2 pl-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-2"
              @input="updateOtherPackingValue(index, $event.target.value, v)"
            />
            <div
              v-for="(error, errorIndex) in v.value.$errors"
              :key="errorIndex"
              class="text-red-500 flex items-center justify-center ml-2"
            >
              {{ error.$message }}
            </div>
            <div
              v-show="isOtherPacking && otherPacking.length > 1"
              class="flex items-center justify-center"
              @click="() => handleRemoveContent(index)"
            >
              <i class="fa fa-trash text-gray-650 ml-4"></i>
            </div>

            <div
              v-if="isOtherPacking && index === 0"
              class="flex items-center justify-center"
              @click="() => handleAddContent()"
            >
              <PlusIcon class="ml-4 text-teal-900 w-10 h-10" />
            </div>
          </div>
        </template>
      </ValidateEach>
    </div>
  </div>
</template>
