import { getApiClient } from "./API";
import entitiesService from "@/services/entities.js";
import {
  LEARNING_SERVICE_API,
  PROFILE_SERVICE_API,
  PAYMENT_SERVICE_API,
} from "@/constants";
import axios from "axios";

export const skip = (page, limit) => (page - 1) * limit;

export const parseErrorResponse = (message, raw = false) => {
  if (!!message && message.constructor === Object) {
    const errors = message.data?.details?.detail;
    if (Array.isArray(errors)) {
      const parseElem = (el) =>
        `${el?.loc?.filter((l) => l !== "body").join(", ")} (${el?.msg || ""})`;

      return raw
        ? errors.map((el) => `${parseElem(el)}`).join("; ")
        : `<ul class='list-disc list-inside'>${errors
            .map((el) => `<li>${parseElem(el)})</li>`)
            .join("")}</ul>`;
    }
    return errors || message.data?.details?.error?.message;
  }
  return message;
};

export const raiseDefaultException = (
  message,
  cause,
  statusCode,
  wasCancelled = false
) => {
  const defaultError = new Error(message ?? "Response failed", { cause });
  if (statusCode) {
    defaultError.code = statusCode;
  }
  if (cause) {
    defaultError.response = cause;
  }
  defaultError.wasCancelled = wasCancelled;
  throw defaultError;
};

export const programServiceRequest = async ({
  method,
  url,
  params = {},
  payload: data,
  message,
}) => {
  const filteredParams = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(params).filter(([_, v]) => v || v === 0)
  );
  try {
    const response = await getApiClient().request({
      method,
      url: url,
      params: filteredParams,
      data,
    });
    return {
      success: true,
      message: message.success,
      response: response?.data,
    };
  } catch (error) {
    return {
      success: false,
      message: parseErrorResponse(
        message.failure ? message.failure : error?.response,
        true
      ),
      response: null,
    };
  }
};

export const learningServiceRequest = async ({
  method,
  url,
  params = {},
  payload: data,
  failureMessage = "",
  source,
  skipErrorBus,
  // controller,
}) => {
  const filteredParams = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(params).filter(([_, v]) => v || v === 0)
  );

  // TODO: CancelToken is deprecated in the axios 22 so come back and uncomment and replace this https://axios-http.com/docs/cancellation
  // const controller = new AbortController();
  try {
    return await getApiClient().request({
      method,
      url: `${LEARNING_SERVICE_API}${url}`,
      params: filteredParams,
      data,
      cancelToken: source?.token,
      skipErrorBus: skipErrorBus ?? false,
      // signal: controller.signal,
      paramsSerializer: (params) => {
        const queryList = [];
        for (const [key, value] of Object.entries(params)) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              queryList.push(`${key}=${encodeURIComponent(item)}`);
            });
          } else {
            queryList.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
        return queryList.join("&");
      },
    });
  } catch (error) {
    const wasCancelled = axios.isCancel(error);
    raiseDefaultException(
      failureMessage,
      error?.response,
      error?.response?.status,
      wasCancelled
    );
  }
};

export const paymentServiceRequest = async ({
  method,
  url,
  params = {},
  payload: data,
  failureMessage = "",
  source,
  skipErrorBus,
  // controller,
}) => {
  const filteredParams = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(params).filter(([_, v]) => v || v === 0)
  );

  // TODO: CancelToken is deprecated in the axios 22 so come back and uncomment and replace this https://axios-http.com/docs/cancellation
  // const controller = new AbortController();
  try {
    return await getApiClient().request({
      method,
      url: `${PAYMENT_SERVICE_API}${url}`,
      params: filteredParams,
      data,
      cancelToken: source?.token,
      skipErrorBus: skipErrorBus ?? false,
      // signal: controller.signal,
      paramsSerializer: (params) => {
        const queryList = [];
        for (const [key, value] of Object.entries(params)) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              queryList.push(`${key}=${encodeURIComponent(item)}`);
            });
          } else {
            queryList.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
        return queryList.join("&");
      },
    });
  } catch (error) {
    const wasCancelled = axios.isCancel(error);
    raiseDefaultException(
      failureMessage,
      error?.response,
      error?.response?.status,
      wasCancelled
    );
  }
};

export const eventsServiceRequest = async ({
  method,
  url,
  params = {},
  payload: data,
  failureMessage = "",
  source,
  skipErrorBus,
}) => {
  const filteredParams = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(params).filter(([_, v]) => v || v === 0)
  );
  try {
    return await getApiClient().request({
      method,
      url: `${url}`,
      params: filteredParams,
      data,
      cancelToken: source?.token,
      skipErrorBus: skipErrorBus ?? false,
      paramsSerializer: (params) => {
        const queryList = [];
        for (const [key, value] of Object.entries(params)) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              queryList.push(`${key}=${encodeURIComponent(item)}`);
            });
          } else {
            queryList.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
        return queryList.join("&");
      },
    });
  } catch (error) {
    raiseDefaultException(failureMessage, error?.response);
  }
};

export const profileServiceRequest = async (parameters) => {
  const filteredParams = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(parameters?.params || {}).filter(([_, v]) => v || v === 0)
  );
  // TODO: CancelToken is deprecated in the axios 22 so come back and uncomment and replace this https://axios-http.com/docs/cancellation
  try {
    return await getApiClient().request({
      method: parameters?.method,
      url: `${PROFILE_SERVICE_API}${parameters?.url}`,
      params: filteredParams,
      data: parameters?.payload,
      cancelToken: parameters?.token,
      skipErrorBus: parameters?.skipErrorBus ?? false,
      // signal: controller.signal,
      paramsSerializer: (params) => {
        const queryList = [];
        for (const [key, value] of Object.entries(params)) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              queryList.push(`${key}=${encodeURIComponent(item)}`);
            });
          } else {
            queryList.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
        return queryList.join("&");
      },
    });
  } catch (error) {
    raiseDefaultException(parameters?.failureMessage, error?.response);
  }
};

export const isStringAValidJSON = (jsonString) => {
  if (!jsonString) return false;

  return /^[\],:{}\s]*$/.test(
    jsonString
      .replace(/\\["\\/bfnrtu]/g, "@")
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
        "]"
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
  );
};

export const removeDuplicatesByKey = (array, key) => {
  const keys = {};
  const result = [];

  for (const obj of array) {
    const val = obj[key];
    if (!keys[val]) {
      result.push(obj);
      keys[val] = true;
    }
  }

  return result;
};

export const safeFloat = (number, divisor = 100) => {
  const parsedNum = number ? parseFloat(number) : null;
  return parsedNum && !isNaN(parsedNum) ? parsedNum / divisor : null;
};

export const toTwoDecimals = (val) => {
  return Number(Number(val).toFixed(2)) || undefined;
};

export const convertCentsToDolarAsCurrency = (val) => {
  const num = Number(val);
  if (!isNaN(num)) {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    const price = (num / 100).toFixed(2);
    return `${USDollar.format(price)}`;
  }
  return "";
};

export const updateFieldToDecimals = (field, object) => {
  const convertedNum = toTwoDecimals(object[field]);
  if (convertedNum !== Number(object[field])) {
    object[field] = convertedNum;
  }
};

export const checkPricingUtilIsValidPrice = (
  effectiveStartDate,
  effectiveEndDate,
  startDate
) => {
  return (
    Date.parse(effectiveStartDate) <= Date.parse(startDate) &&
    (Date.parse(effectiveEndDate) || Number.MAX_SAFE_INTEGER) >=
      Date.parse(startDate)
  );
};

export const getEntityById = async (entityId) => {
  let entity;
  try {
    const getEntity = await entitiesService.getEntityById(entityId);
    entity = getEntity.data?.data?.items;
  } catch {
    entity = "";
  }
  return entity;
};
