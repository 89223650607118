<template>
  <div class="space-y-6">
    <p>
      Here are a few frequently asked questions regarding arrival that can help
      you in your planning. Got more questions? Get in touch with your program
      manager - they will be happy to advise you.
    </p>
    <div>
      <h3
        class="mb-2 text-xs font-semibold tracking-widest text-gray-500 uppercase"
      >
        On site orientation.
      </h3>
      <p>
        By arriving on or before the arrival date, you won’t experience any
        issue in joining your on-site team and fellow students for our mandatory
        orientation, which begins on your program start date. Your API Arrival
        Guide will be jam-packed with important information on where you will be
        staying on your first day and what to expect during your program.
      </p>
    </div>
    <div v-if="rule === 'Arrival FAQ'">
      <h3
        class="mb-2 text-xs font-semibold tracking-widest text-gray-500 uppercase"
      >
        Airport pick-up
      </h3>
      <p>
        For participants flying in to the arrival airport within the arrival
        window, an API team member will be there to greet you. You can expect
        more guidance on where to meet when you receive your API Arrival Guide.
        day.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArrivalFaqs",
  props: {
    rule: {
      type: String,
      default: "",
    },
  },
};
</script>
