<template>
  <spinner
    v-if="state.loading"
    :outer-classes="
      secondaryColor
        ? `h-64 flex items-center justify-center bg-gray-275`
        : `h-64 flex items-center justify-center bg-teal-light`
    "
    :show-spinner-content="true"
  >
    <template #spinnercontent>Putting together your experience...</template>
  </spinner>
  <div v-else :class="[secondaryColor ? `bg-gray-275` : `bg-teal-light`]">
    <slot name="summary-header">
      <h3
        class="text-base font-montserrat text-indigo-base"
        data-testid="configurator-order-summary"
      >
        {{ city }}, {{ country }}
      </h3>
      <h2 class="text-2xl font-bold text-indigo-base">
        {{ programNameDisplay }}
      </h2>
      <hr class="my-4 border-configurator-order-summary-gray" />
      <table class="w-full text-xs text-indigo-base">
        <tr v-if="programDuration">
          <td class="">Program Duration</td>
          <td class="font-semibold text-right">
            {{ programDuration }}
          </td>
        </tr>
        <tr v-if="terms.length > 0">
          <td v-if="terms.length > 1" class="flex items-start">
            <div class="h-4">Terms</div>
          </td>
          <td v-else class="flex items-start">
            <div class="h-4">Term</div>
          </td>
          <td class="font-semibold text-right">
            <ul>
              <li v-for="(term, index) in terms" :key="index" class="h-4">
                <div v-if="index < terms.length - 1" class="w-full">
                  {{ term?.name }},
                </div>
                <div v-else class="w-full">{{ term?.name }}</div>
              </li>
            </ul>
          </td>
        </tr>
        <tr v-if="applicationDeadline && !hideSessionDeadline">
          <td class="">Application Deadline</td>
          <td class="font-semibold text-right">
            {{ applicationDeadline }}
          </td>
        </tr>
        <tr v-if="programDates.length">
          <td>
            <div class="flex items-start h-12">
              <div class="w-full h-4">Program Dates</div>
            </div>
          </td>
          <td class="flex gap-1 justify-end items-start w-full text-right">
            <DatesFinalizedIcon
              :icon-id="dateId"
              :program-dates-finalized="programDatesNotFinalized"
              class="w-4 h-6"
            />
            <div class="h-12 font-semibold text-right w-fit">
              <ul>
                <li
                  v-for="(date, index) in programDates"
                  :key="index"
                  class="h-4"
                >
                  {{ date }}
                  <span v-if="index < programDates.length - 1">-</span>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
      <hr class="my-4 border-configurator-order-summary-gray" />
    </slot>

    <div>
      <h2
        class="flex justify-between items-center my-4 text-xl font-bold cursor-pointer"
        @click="toggleExpansion"
      >
        <p class="text-indigo-base">Selections</p>
        <svg
          v-if="!props.isAttachedToEnrollment"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
          :class="{ 'rotate-180': showBreakdown }"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </h2>
      <div v-if="showBreakdown" class="border-collapse">
        <!-- COURSES-->
        <div v-if="showCoursesSection">
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <div class="flex justify-between items-center px-1 py-4">
              <div class="text-xl text-indigo-base">Courses</div>
              <check-icon
                v-if="currentOrder?.credits > 0"
                :class="[`${secondaryColorClass}`]"
              />
              <div
                v-else
                :class="[coursesStepSelectorStyles, `${secondaryColorClass}`]"
                @click="goToConfiguratorStep('courses')"
              >
                Need to do
              </div>
            </div>
            <!-- Courses credits -->
            <div
              v-if="currentOrder?.credits"
              class="px-1"
              :class="
                !shouldCalculateAdditionalCredits
                  ? 'flex justify-between'
                  : 'flex flex-col'
              "
            >
              <div class="flex items-center">
                <edit-button
                  v-if="showEditButtons"
                  class="text-base"
                  @click="() => goToConfiguratorStep('courses')"
                />
                <p class="font-semibold text-indigo-base">
                  {{ currentOrder?.credits }} Credits
                </p>
              </div>
              <div
                v-if="!shouldCalculateAdditionalCredits"
                class="font-bold uppercase text-indigo-base"
              >
                Included
              </div>
              <div
                v-else
                class="p-1 text-xs font-bold leading-none text-indigo-base"
              >
                * Additional credit fee will be determined during course
                registration.
              </div>
            </div>
          </div>
        </div>
        <!-- INTERNSHIP-->
        <div v-if="showInternshipSection">
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <!-- Internship selection info -->
            <div class="flex justify-between items-center px-1 py-4">
              <div class="text-xl text-indigo-base">
                Internships
              </div>
              <check-icon
                v-if="internshipProductListings?.length"
                :class="[`${secondaryColorClass}`]"
              />
              <div
                v-else
                :class="[
                  internshipStepSelectorStyles,
                  `${secondaryColorClass}`,
                ]"
                @click="() => goToConfiguratorStep('internship')"
              >
                Need to do
              </div>
            </div>
          </div>
          <div
            v-for="internshipProduct in internshipProductListings"
            :key="internshipProduct.product_id"
            class="flex justify-between px-1"
          >
            <div class="flex items-center">
              <edit-button
                v-if="showEditButtons"
                @click="() => goToConfiguratorStep('internship')"
              />
              <span class="text-indigo-base max-w-32 md:max-w-48">{{
                internshipProduct.product_name
              }}</span>
            </div>
            <div
              v-if="
                internshipProduct.student_price_difference_from_default === 0
              "
              class="font-bold uppercase text-indigo-base"
            >
              Included
            </div>
            <div
              v-else
              class="text-lg font-semibold"
              :class="[`${secondaryColorClass}`]"
            >
              {{
                internshipProduct.student_price_difference_from_default > 0
                  ? "+"
                  : ""
              }}
              {{
                formattedPrice(
                  internshipProduct.student_price_difference_from_default
                )
              }}
            </div>
          </div>
        </div>
        <!-- HOUSING-->
        <div v-if="showHousingSection">
          <hr class="border-configurator-order-summary-gray" />
          <div class="flex justify-between items-center px-1 py-4">
            <div class="text-xl text-indigo-base">Housing</div>
            <check-icon
              v-if="roomPrices?.length || !sessionHasDefaultRoom"
              :class="[`${secondaryColorClass}`]"
            />
            <div
              v-else
              :class="[housingStepSelectorStyles, `${secondaryColorClass}`]"
              @click="() => goToConfiguratorStep('housing')"
            >
              Need to do
            </div>
          </div>
          <div
            v-for="housingProduct in roomPrices"
            :key="housingProduct.productId"
            class="flex px-1 justify-between"
          >
            <div class="flex items-center">
              <edit-button
                v-if="showEditButtons"
                @click="() => goToConfiguratorStep('housing')"
              />
              <span class="text-indigo-base">{{
                housingProduct.productName
              }}</span>
            </div>
            <div class="text-indigo-base font-bold uppercase">
              {{ housingProduct.roomPriceLabel() }}
            </div>
          </div>
        </div>
        <!-- EXCURSIONS-->
        <div v-if="showExcursionSection">
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <!-- Excursion item description -->
            <div class="flex justify-between items-center px-1 py-4">
              <div class="text-xl text-indigo-base">
                Excursions
              </div>
              <check-icon
                v-if="excursionPrices.length"
                :class="[`${secondaryColorClass}`]"
              />
              <div
                v-else
                :class="[
                  excursionsStepSelectorStyles,
                  `${secondaryColorClass}`,
                ]"
                @click="goToConfiguratorStep('excursions')"
              >
                Need to do
              </div>
            </div>
          </div>
          <!-- EXCURSIONS LIST -->
          <div
            v-for="excursionProductListing in excursionPrices"
            :key="excursionProductListing.productId"
            class="flex justify-between px-1"
          >
            <div class="flex items-center">
              <edit-button
                v-if="showEditButtons"
                @click="() => goToConfiguratorStep('excursions')"
              />
              <span class="text-indigo-base max-w-32 md:max-w-48">{{
                excursionProductListing.productName
              }}</span>
            </div>
            <div>
              <span :class="pricingClass(excursionProductListing.isIncluded() || excursionProductListing.isExcursionIncluded, secondaryColorClass)">
                {{ excursionProductListing.excursionPriceLabel(excursionProductListing.isExcursionIncluded) }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="addonProductListings.length > 0">
          <hr
            class="border-configurator-order-summary-gray"
            data-testid="addons-divider-summary"
          />
          <div>
            <div
              class="flex justify-between items-center px-1 py-4"
              data-testid="addons-header-summary"
            >
              <div
                class="text-xl text-indigo-base"
                data-testid="addons-title-summary"
              >
                Add-ons
              </div>
              <check-icon
                :class="[`${secondaryColorClass}`]"
                data-testid="addons-check-icon-summary"
              />
            </div>
          </div>
          <div
            v-for="addon in addonProductListings"
            :key="addon?.product_id"
            class="flex justify-between px-1"
            data-testid="addons-option-summary"
          >
            <div class="flex items-center" data-testid="addons-details-summary">
              <edit-button
                v-if="showEditButtons && Boolean(addon?.configuratorStepToEdit)"
                @click="
                  () => goToConfiguratorStep(addon.configuratorStepToEdit)
                "
                data-testid="addons-edit-button-summary"
              />
              <span
                class="text-indigo-base max-w-32 md:max-w-48"
                data-testid="addons-name-summary"
              >
                {{ addon?.product_name || "" }}
              </span>
            </div>
            <div
              class="text-lg font-semibold"
              :class="[`${secondaryColorClass}`]"
              data-testid="addons-price-summary"
            >
              {{ addon?.student_total_price_in_cents > 0 ? "+" : "" }}
              {{ formattedPrice(addon?.student_total_price_in_cents) }}
            </div>
          </div>
        </div>
        <div v-if="groupFlightExpense">
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <!-- Group Flight item description -->
            <div class="flex justify-between items-center p-1 py-4">
              <div class="text-xl text-indigo-base">
                Group Flight
              </div>
              <check-icon :class="[`${secondaryColorClass}`]" />
            </div>
          </div>
          <div class="flex justify-between px-1">
            <div class="flex items-center">
              <span class="text-indigo-base max-w-32 md:max-w-48">{{
                groupFlightExpense?.product_name || ""
              }}</span>
            </div>
            <div>
              <span class="font-bold uppercase text-indigo-base">Included</span>
            </div>
          </div>
        </div>

        <div v-if="publicTransportationExpense">
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <div class="flex justify-between items-center px-1 py-4">
              <div class="text-xl text-indigo-base">
                Public Transportation Card
              </div>
              <check-icon :class="[`${secondaryColorClass}`]" />
            </div>
          </div>
          <div class="flex justify-between px-1">
            <div class="flex items-center">
              <span class="text-indigo-base max-w-32 md:max-w-48">{{
                publicTransportationExpense?.product_name || ""
              }}</span>
            </div>
            <div>
              <span class="font-bold uppercase text-indigo-base">Included</span>
            </div>
          </div>
        </div>

        <div v-if="insuranceExpenses?.length">
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <!-- insuranceExpense item description -->
            <div class="flex justify-between items-center px-1 py-4">
              <div class="text-xl text-indigo-base">
                Insurance
              </div>
              <check-icon :class="[`${secondaryColorClass}`]" />
            </div>
          </div>
          <div
            v-for="insuranceExpense in insuranceExpenses"
            :key="insuranceExpense?.product_id"
            class="flex justify-between px-1"
          >
            <div class="flex items-center">
              <span class="text-indigo-base max-w-32 md:max-w-48">{{
                insuranceExpense?.product_name || ""
              }}</span>
            </div>
            <div>
              <span class="font-bold uppercase text-indigo-base">Included</span>
            </div>
          </div>
        </div>
        <hr class="border-configurator-order-summary-gray" />
      </div>
    </div>
    <div
      v-if="!hidePrices && totalPrice > 0"
      class="flex justify-between items-end mt-4 h-10"
    >
      <p class="block text-2xl font-semibold text-indigo-base">Total</p>
      <p
        class="mb-1 text-3xl font-bold font-inter"
        :class="[
          secondaryColor
            ? `${secondaryColorClass}`
            : 'text-university-secondary',
        ]"
      >
        {{ formattedPrice(totalPrice) }}
      </p>
    </div>
    <!-- Total Price Breakdown FEES -->
    <div v-if="!props.hidePrices && isBillingPassThrough">
        <div class="grid grid-cols-2 cursor-pointer">
        <div class="col-span-2 mt-2 space-y-2 text-base">
            <div v-if="studentTotalPrice > 0" class="flex justify-between items-center">
            <div class="text-indigo-base">
              Total due to API:
            </div>
            <div class="font-bold text-indigo-base">
              {{ formattedPrice(studentTotalPrice) }}
            </div>
            </div>
            <div class="flex justify-between items-center">
            <div class="text-indigo-base">
              Your University will bill:
            </div>
            <div class="font-bold text-indigo-base">
              {{ formattedPrice(institutionTotalPrice) }}
            </div>
            </div>
        </div>
        </div>
    </div>
    <p v-if="!props.hidePrices && isBillingPassThrough" class="mt-4">
        * The total listed above will be billed by your University and may include
        additional fees based on their published price.<span
        v-if="!studentPaysButNotConfirmation"
        >  Please continue personalizing your experience.</span
        >
    </p>
    <template v-if="!hidePrices">
      <p
        v-if="totalPrice <= 0 && hasBillingAgreement && !isBillingPassThrough"
        class="mt-4"
      >
        The items above listed as "INCLUDED" will be billed by your University
        based on their published price.<span
          v-if="!studentPaysButNotConfirmation"
          >  Please continue personalizing your experience.</span
        >
      </p>
      <p
        v-if="totalPrice > 0 && hasBillingAgreement && !isBillingPassThrough"
        class="mt-4"
      >
        It looks like one or more of your choices requires direct payment to
        API. The items listed as INCLUDED will be billed by your University
        based on their published price.<span
          v-if="!studentPaysButNotConfirmation"
          >  Please continue personalizing your experience.</span
        >
      </p>
    </template>
    <div v-if="showConfirmationPayment && !isInstitutionPayingConfirmation">
      <hr class="border-configurator-order-summary-gray" />
      <div class="flex justify-between items-end mt-4 h-10">
        <p class="block text-2xl font-semibold text-indigo-base">
          Due Now
        </p>
        <p class="text-[#007F80] font-inter text-3xl font-bold mb-1">
          {{ formattedPrice(40000) }}
        </p>
      </div>
    </div>

    <!-- PRICE BREAK DOWN FEES -->
    <template v-if="showPriceBreakdownSection">
      <hr class="my-4 border-configurator-order-summary-gray" />
      <div>
        <div
          class="grid grid-cols-2 cursor-pointer"
          data-testid="price-breakdown-toggler"
          @click="togglePriceBreakDown"
        >
          <div class="flex col-span-2 justify-between items-center">
            <p class="block w-full text-base text-indigo-base">
              Starting Price Breakdown
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
              :class="showPriceBreakdown ? 'transform rotate-180' : ''"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          <hr
            v-if="showPriceBreakdown"
            class="col-span-2 my-4 border-configurator-order-summary-gray"
          />
          <div
            v-if="showPriceBreakdown"
            class="col-span-2 mt-2 space-y-2 text-base"
          >
            <div class="flex justify-between items-center">
              <div class="text-indigo-base" data-testid="housing-fee-label">
                Housing Fee
              </div>
              <div class="font-bold text-indigo-base">
                {{ formattedPrice(housingFee) }}
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="text-indigo-base">Educational Fee</div>
              <div
                class="font-bold text-indigo-base"
                data-testid="housing-fee-value"
              >
                {{ formattedPrice(educationFee) }}
              </div>
            </div>
            <div
              v-if="groupFlightFee"
              class="flex justify-between items-center"
            >
              <div class="text-indigo-base">Group Flight Fee</div>
              <div class="font-bold text-indigo-base">
                {{ formattedPrice(groupFlightFee) }}
              </div>
            </div>
            <div
              v-if="publicTransportationExpense?.student_total_price_in_cents"
              class="flex justify-between items-center"
            >
              <div class="text-indigo-base">
                Public Transportation Fee
              </div>
              <div class="font-bold text-indigo-base">
                {{
                  formattedPrice(
                    publicTransportationExpense?.student_total_price_in_cents
                  )
                }}
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="text-indigo-base">Insurance</div>
              <div class="font-bold text-indigo-base">
                {{ formattedPrice(totalInsuranceExpenses) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <slot name="call-to-action">
      <!-- BUTTON -->
      <hr
        v-if="!hideButtons"
        class="my-4 border-configurator-order-summary-gray"
      />
      <div
        v-if="!hideButtons"
        class="px-1 space-x-2 md:flex md:items-center md:justify-between"
      >
        <!-- Save-->
        <div
          :class="`cursor-pointer border w-full ${secondaryColorClassOutlinedNoHover} py-4 px-8 bg-white font-montserrat uppercase font-semibold text-center text-base flex items-center justify-center`"
          @click="handleSave"
        >
          <span class="inline">Save</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
        <!-- Check Out-->
        <div
          class="flex justify-center items-center px-8 py-4 w-full text-base font-semibold text-center uppercase bg-white border cursor-pointer font-montserrat"
          :class="[
            canCheckOut ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed',
            `${secondaryColorClassOutlinedNoHover}`,
          ]"
          @click="handleCheckout()"
        >
          <span class="inline">Check Out</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
    </slot>
  </div>
</template>

<script setup>
import _ from "lodash";
import Spinner from "@/components/helpers/Spinner";
import {
  BILLING_TYPES,
  DURATION_IN_DAYS_THRESHOLD,
  ORDER_STATUS,
  SESSION_TYPE
} from "@/constants";
import {
  GROUP_FLIGHT,
  MEDICAL_INSURANCE,
  PUBLIC_TRANSPORTATION_CARD,
  SPAIN_MEDICAL_INSURANCE,
  UMASS_TRANSCRIPT_SERVICES,
  UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES,
} from "@/components/program-manager/sessions/constants";
import ordersService from "@/services/orders";
import { formattedPriceFromCents as formattedPrice } from "@/util/formatter";
import { computed, inject, provide, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import { formatDate } from "@/mixins/helpers";
import DatesFinalizedIcon from "@/components/Configurator/widgets/DatesFinalizedIcon.vue";
import CheckIcon from "@/components/Configurator/widgets/CheckIcon";
import EditButton from "@/components/shared/EditButton.vue";
import { RoomPrice, ExcursionPrice } from "@/components/Configurator/pricing";

const toast = useToast();
const props = defineProps({
  currentOrder: {
    type: Object,
    default: () => {},
  },
  hidePrices: {
    type: Boolean,
    default: false,
  },
  showConfirmationPayment: {
    type: Boolean,
    default: false,
  },
  isAttachedToEnrollment: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const route = useRoute();

const emit = defineEmits(["getPricing"]);

const excursionRules = inject("excursionRules", {});

const { program } = inject("program", {});

const sessionHasDefaultRoom = computed(
  () => !!program?.programSession?.default_room?.id
);

const configuratorSteps = computed(
  (() => {
    // injected data may be undefined if the Configurator is not
    // in the parent component chain;
    // using an IIFE here so that
    // 1. `steps` gets injected exactly once at component initialization
    // 2. the component namespace is protected from unverified data
    const { steps } = inject("steps", {});

    return () => steps?.value ?? [];
  })()
);

const pricingClass = (isIncluded, secondaryOption) => {
  if (isIncluded) {
    return "text-indigo-base font-bold uppercase";
  }
  return `text-lg font-semibold ${secondaryOption ?? ""}`;
};

// route = useRoute();
const isAttachedToConfigurator = computed(() => {
  return ["configurator", "configurator-new"].includes(route.name);
});

const requiredSections = computed(() =>
  configuratorSteps.value
    .filter((step) => step?.showStep)
    .map((step) => step?.slug)
);

const selectableSections = computed(() =>
  configuratorSteps.value
    .filter((step) => step?.isSelectable())
    .map((step) => step?.slug)
);

const canSelectSection = (section) =>
  isDraftOrder.value &&
  (!isAttachedToConfigurator.value ||
    selectableSections.value.includes(section));

const stepSelectorStyles = (step) => {
  const styles = ["font-inter", "font-bold", "uppercase"];
  if (canSelectSection(step)) {
    styles.push("cursor-pointer");
  }
  return styles.join(" ");
};

const coursesStepSelectorStyles = computed(() => {
  return stepSelectorStyles("courses");
});

const housingStepSelectorStyles = computed(() => {
  return stepSelectorStyles("housing");
});

const internshipStepSelectorStyles = computed(() => {
  return stepSelectorStyles("internship");
});

const excursionsStepSelectorStyles = computed(() => {
  return stepSelectorStyles("excursions");
});

const state = reactive({
  loading: true,
  expanded: true,
  pricing: {},
});

const currentOrder = computed(() => props.currentOrder);

const isDraftOrder = computed(
  () =>
    (currentOrder?.value?.status ?? ORDER_STATUS.draft) === ORDER_STATUS.draft
);

const showEditButtons = computed(
  () =>
    isDraftOrder.value &&
    !isAttachedToConfigurator.value &&
    !props.isAttachedToEnrollment
);

const showCoursesSection = computed(
  () =>
    (currentOrder.value?.credits ?? 0) > 0 ||
    requiredSections.value.includes("courses")
);

const showInternshipSection = computed(
  () =>
    currentOrder.value?.internship_ids.length > 0 ||
    requiredSections.value.includes("internship")
);

const showHousingSection = computed(
  () =>
    currentOrder.value?.room_ids.length > 0 ||
    requiredSections.value.includes("housing")
);

const showExcursionSection = computed(
  () =>
    currentOrder.value?.event_occurrence_ids?.length > 0 ||
    requiredSections.value.includes("excursions")
);

const showPriceBreakdownSection = computed(
  () =>
    !(
      hidePrices.value ||
      hasBillingAgreement.value ||
      props.isAttachedToEnrollment
    )
);

const showPriceBreakdown = ref(false);

const handleSave = () => {
  // Handle the "Check Out" button click event
};

const goToConfiguratorStep = (slug) => {
  if (!canSelectSection(slug)) {
    return;
  }

  const { program_page_id, id: orderId, credits } = currentOrder.value;
  let to = {
    name: orderId ? "configurator" : "configurator-new",
    params: {
      programPageId: program_page_id,
      slug,
    },
    query: {
      session: currentOrder.value.session_id,
      credits,
      rooms: currentOrder.value.room_ids,
      internships: currentOrder.value.internship_ids,
      event_occurrences: currentOrder.value.event_occurrence_ids,
      misc_products: currentOrder.value.misc_product_ids,
    },
  };

  if (orderId) {
    to.params.orderId = orderId;
  }

  router.push(to);
};
provide("goToConfiguratorStep", goToConfiguratorStep);

const studentTotalPrice = computed(
  () => state?.pricing?.student_total_price_in_cents ?? 0
);

const institutionTotalPrice = computed(
  () => state?.pricing?.institution_total_price_in_cents ?? 0
);

const totalPrice = computed(() => {
  if (isBillingPassThrough) {
    return state?.pricing?.total_price_in_cents ?? 0;
  }
  return state?.pricing?.student_total_price_in_cents ?? 0;
});

const applicationDeadline = computed(() =>
  formatDate(state?.pricing?.session_application_deadline, "long")
);

//PROGRAM DATES SECTION
const dateId = computed(() => {
  return "icon-date-id";
});
const programDates = computed(() => {
  const startDateString = state.pricing?.session_start_date;
  const endDateString = state.pricing?.session_end_date;
  if (startDateString && endDateString) {
    return [
      formatDate(startDateString, "long"),
      formatDate(endDateString, "long"),
    ];
  }
  return [];
});
const programDatesNotFinalized = computed(() => {
  return (
    !state?.pricing?.session_academic_end_date_finalized ||
    !state?.pricing?.session_academic_start_date_finalized
  );
});
//
//PROGRAM NAME SECTION
const programNameDisplay = computed(
  () => state?.pricing?.program_name ?? state?.pricing?.session_name ?? ""
);

const shouldCalculateAdditionalCredits = computed(() => {
  const minCredits = state?.pricing?.session_academic_min_credits ?? 0;
  const maxCredits = state?.pricing?.session_academic_max_credits ?? Infinity;
  return (
    currentOrder.value.credits &&
    !(
      currentOrder.value.credits >= minCredits &&
      currentOrder.value.credits <= maxCredits
    )
  );
});

const roomPrices = computed(() => {
  const rooms = (state?.pricing?.rooms ?? []).map(room => {return {...RoomPrice}.optionMapper(room)});
  return currentOrder.value.room_ids.map((room_id) => rooms.find((r) => r.productId === room_id));
});

const excursionPrices = computed(() => {
  const isIncluded = function(exId) {
    return excursionRules?.value?.defaultIds.includes(exId) || excursionRules?.value?.andIds.includes(exId);
  }
  const excursions = (state?.pricing?.event_occurrences ?? [])
    .map(excursion => {
      const ex = {...ExcursionPrice}.optionMapper(excursion);
      return {...ex, isExcursionIncluded: isIncluded(ex.productId)}
    });
  return currentOrder.value.event_occurrence_ids.map((id) => excursions.find((e) => e.productId === id));
});

const internshipProductListings = computed(() => {
  const pricedProducts = state?.pricing?.internships ?? [];
  const pricedProductsById = Object.fromEntries(
    pricedProducts.map((prod) => [prod?.product_id ?? "", prod])
  );
  return currentOrder.value.internship_ids
    .map((id) => pricedProductsById[id])
    .filter((obj) => Boolean(obj));
});

const excursionProductListings = computed(() => {
  const pricedProducts = state?.pricing?.event_occurrences ?? [];
  const pricedProductsById = Object.fromEntries(
    pricedProducts.map((prod) => [prod?.product_id ?? "", prod])
  );
  return currentOrder.value.event_occurrence_ids
    .map((id) => pricedProductsById[id])
    .filter((obj) => Boolean(obj));
});

const addonProductListings = computed(() => {
  const listings = state?.pricing?.addons ?? [];
  return listings.map((listing) => {
    const transcriptServiceIds = [
      UMASS_TRANSCRIPT_SERVICES,
      UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES,
    ].map((product) => product.id);
    if (transcriptServiceIds.includes(listing.product_id)) {
      return {
        ...listing,
        configuratorStepToEdit: "credits",
      };
    }
    return listing;
  });
});

const housingFee = computed(() => {
  return state?.pricing?.session_base_housing_fee ?? 0;
});

const educationFee = computed(() => {
  return state?.pricing?.session_base_educational_fee ?? 0;
});

const groupFlightFee = computed(() => {
  return state?.pricing?.session_base_group_flight_fee ?? 0;
});

const updateProductData = async (newCurrentOrder) => {
  if (newCurrentOrder?.session_id) {
    const order_id = newCurrentOrder?.id ?? newCurrentOrder?.orderId ?? null;
    state.loading = true;

    ordersService
      .getOrdersPricing({
        payload: { ...newCurrentOrder, order_id: order_id },
      })
      .then((pricingResponse) => {
        state.pricing = pricingResponse?.data?.data ?? {};
        // kind of a hack but useful -- enable parent component to access up-to-date pricing
        // information without a second HTTP call
        emit("getPricing", state.pricing);
      })
      .catch(() => {
        toast.open({
          message: "Failed to load product information",
          type: "error",
          position: "bottom",
          duration: 5000,
        });
      })
      .finally(() => {
        state.loading = false;
      });
  }
};

const toggleExpansion = () => {
  if (props.isAttachedToEnrollment) return;
  state.expanded = !state.expanded;
};

const togglePriceBreakDown = () => {
  showPriceBreakdown.value = !showPriceBreakdown.value;
};

const showBreakdown = computed(() => state.expanded);

const hidePrices = computed(() => {
  return (
    props.hidePrices ||
    totalPrice.value <= 0 ||
    state?.pricing?.direct_billing_type === "Home School Tuition" ||
    state?.pricing?.direct_billing_type === "Not Specified"
  );
});

const hideButtons = true;

const isInstitutionPayingConfirmation = computed(() => {
  return (
    currentOrder?.value?.billing_rules?.confirmationPayment === "Institution"
  );
});

const hasBillingAgreement = computed(() => {
  const institutionPays = state.pricing?.institution_total_price_in_cents ?? 0;
  return institutionPays > 0;
});

const isBillingPassThrough = computed(
  () => state.pricing?.direct_billing_type === BILLING_TYPES.PASS_THROUGH
)

watch(() => props.currentOrder, async (newState, prevState) => {
    if (!_.isEqual(newState, prevState)) {
      updateProductData(newState);
    }
}, {
    immediate: true,
    deep: true,
});

const programDuration = computed(() => {
  const durationInDays = state?.pricing?.session_duration_in_days;
  if (!durationInDays) {
    return undefined;
  }

  if (durationInDays < DURATION_IN_DAYS_THRESHOLD) {
    return `${durationInDays} day${durationInDays > 1 ? "s" : ""}`; // Pluralize the word 'day' if necessary
  } else {
    const durationInWeeks = Math.ceil(durationInDays / 7);
    return `${durationInWeeks} week${durationInWeeks > 1 ? "s" : ""}`; // Pluralize the word 'week' if necessary
  }
});

const isFacultyLed = computed(() => {
  const sessionTypes = state?.pricing?.session_types ?? [];
  return sessionTypes
    .map((type) => type.id)
    .includes(SESSION_TYPE.facultyLed.id);
});

const hideSessionDeadline = computed(() => isFacultyLed.value);

const insuranceExpenses = computed(() => {
  const additionalFees = state?.pricing?.additional_fees ?? [];
  return additionalFees.filter((fee) => {
    return [SPAIN_MEDICAL_INSURANCE.id, MEDICAL_INSURANCE.id].includes(
      fee?.product_id
    );
  });
});

const totalInsuranceExpenses = computed(() => {
  return state?.pricing?.session_base_insurance_fee ?? 0;
});

const publicTransportationExpense = computed(() =>
  state?.pricing?.additional_fees?.find(
    (val) => val?.product_id === PUBLIC_TRANSPORTATION_CARD.id
  )
);

const groupFlightExpense = computed(() =>
  state?.pricing?.additional_fees?.find(
    (val) => val?.product_id === GROUP_FLIGHT.id
  )
);

const city = computed(() => state?.pricing?.session_city ?? "");
const country = computed(() => state?.pricing?.session_country ?? "");
const terms = computed(() => state?.pricing?.session_terms ?? []);
</script>
