<template>
  <div class="grid grid-cols-2 gap-x-4 mt-6">
    <template v-if="showInputs">
      <div class="col-span-2 md:col-span-1">
        <label :for="labelPrefix + 'Name'" class="block text-sm">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Name</span>
          </span>
          <input
            :id="labelPrefix + 'Name'"
            v-model.trim="v$.value.name.$model"
            type="text"
            :placeholder="placeholderName"
            class="form-input block w-full min-h-10"
            :class="{ 'bg-error-100': v$.value.name.$error }"
          />
          <div
            v-if="v$.value.name.required.$invalid && v$.value.name.$error"
            class="error text-error-900"
          >
            Field is required
          </div>
          <div
            v-if="v$.value.name.maxLength.$invalid"
            class="error text-error-900"
          >
            Name must have no more than
            {{ v$.value.name.maxLength.$params.max }}
            characters.
          </div>
        </label>
      </div>
      <div class="col-span-2 md:col-span-1">
        <label :for="labelPrefix + 'email'" class="block text-sm">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Email</span>
          </span>
          <input
            :id="labelPrefix + 'email'"
            v-model.trim="v$.value.email.$model"
            type="email"
            :placeholder="placeholderEmail"
            class="form-input block w-full min-h-10"
            :class="{ 'bg-error-100': v$.value.email.$error }"
          />
          <div
            v-if="v$.value.email.required.$invalid && v$.value.email.$error"
            class="error text-error-900"
          >
            Field is required
          </div>
          <div
            v-if="v$.value.email.email.$invalid"
            class="error text-error-900"
          >
            Email must be a valid email
          </div>
          <div
            v-if="v$.value.email.maxLength.$invalid"
            class="error text-error-900"
          >
            Email must have no more than
            {{ v$.value.email.maxLength.$params.max }}
            characters.
          </div>
        </label>
      </div>
    </template>
    <div class="flex justify-center col-span-2 md:col-span-2 mt-6">
      <ButtonWithSpinner
        :ref="labelPrefix + 'Button'"
        type="button"
        variant="secondary"
        variant-type="normal"
        :disabled="disableButton || v$.$invalid"
        @click.prevent="validateForm()"
      >
        <span>
          {{ buttonLabel }}
          <svg
            v-if="successSubmission && !v$.$invalid"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="inline feather feather-check"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </ButtonWithSpinner>
    </div>
    <div
      v-if="successSubmission && !v$.$invalid"
      class="text-center text-success-900 col-span-2"
    >
      Thank you, your request has been submitted.
    </div>
    <div class="text-center error text-error-900 col-span-2">
      <p v-if="error">{{ error }}</p>
      <slot name="errors" />
    </div>
  </div>
</template>

<script>
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import sentryHelper from "../../../mixins/sentryHelper";
import { mapState } from "vuex";

import { email, maxLength, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const requiredIfOther = requiredIf(function () {
  return this.showInputs && this.requiredInputs;
});

export default {
  name: "EmailSender",
  components: {
    ButtonWithSpinner,
  },
  mixins: [sentryHelper],
  props: {
    emailInformation: {
      type: Object,
      default: function () {
        return {};
      },
    },
    labelPrefix: {
      type: String,
      default: "advisor",
    },
    placeholderName: {
      type: String,
      default: "Advisors Name",
    },
    placeholderEmail: {
      type: String,
      default: "Advisors Email",
    },
    showInputs: {
      type: Boolean,
      default: true,
    },
    requiredInputs: {
      type: Boolean,
      default: true,
    },
    selectedName: {
      type: String,
      default: "",
    },
    selectedEmail: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: "Request",
    },
    disableButton: {
      type: Boolean,
      default: true,
    },
    emailURL: {
      type: String,
      default: "",
    },
    emailData: {
      type: FormData,
      default: new FormData(),
    },
    applicationId: {
      type: String,
      default: "",
    },
    hasParentValidations: {
      type: Boolean,
      default: true,
    },
    successSubmission: {
      type: Boolean,
      default: false,
    },
    nameMaxLength: {
      type: Number,
      default: 32,
    },
    emailMaxLength: {
      type: Number,
      default: 64,
    },
  },
  emits: ["emailSent", "validateForm", "submitForm", "update:email-sender"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        name: "",
        email: "",
      },
      error: "",
    };
  },
  validations() {
    return this.setValidations();
  },
  computed: {
    ...mapState(["formioToken"]),
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:email-sender", this.value);
      },
      deep: true,
      immediate: true,
    },
    showInputs: {
      handler: function (newVal) {
        if (!newVal) {
          this.value.name = "";
          this.value.email = "";
        }
      },
    },
    selectedName: {
      handler: function (newVal) {
        this.value.name = newVal;
      },
      immediate: true,
    },
    selectedEmail: {
      handler: function (newVal) {
        this.value.email = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    async validateForm() {
      this.error = "";

      /*
        *****NOTE***** .
        If parent have more sections to validate set hasParentValidations to true
        and pass validateForm method to component, after validations emit submit on parent
      */
      if (this.hasParentValidations) {
        this.$emit("validateForm");
      } else {
        this.v$.$touch();
        this.submit();
      }
    },
    submit() {
      if (!this.disableButton && !this.v$.$invalid) {
        this.$refs[this.labelPrefix + "Button"].startLoading();
        this.sendEmailRequest()
          .then(() => {
            this.$emit("emailSent");
          })
          .catch((error) => {
            this.error =
              "Something went wrong while sending the email, please try later.";
            const sentryTags = { applicationId: this.applicationId };
            this.throwSentryError("Email request: " + error, sentryTags);
          })
          .finally(() => {
            this.$emit("submitForm");
          });
      }
    },
    async sendEmailRequest() {
      let response = await fetch(this.emailURL, {
        method: "POST",
        headers: {
          accept: "application/json",
          "x-jwt-token": this.formioToken,
        },
        body: this.emailData,
      });

      if (response.status !== 200) {
        let error = await response.text();
        throw error;
      }
    },
    setValidations() {
      return {
        value: {
          name: {
            required: requiredIfOther,
            maxLength: maxLength(this.nameMaxLength),
          },
          email: {
            required: requiredIfOther,
            email,
            maxLength: maxLength(this.emailMaxLength),
          },
        },
      };
    },
  },
};
</script>
