<template>
  <nav v-if="breadcrumbs.length" class="flex my-4" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center flex-wrap">
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        class="flex justify-around"
      >
        <div class="flex items-center text-sm">
          <span
            v-if="customSpacer && index !== 0"
            class="mx-2"
            :id="`breadcrumb-spacer-${index}`"
          >
            <slot></slot>
          </span>
          <ArrowRight
            v-else-if="!customSpacer && index !== 0"
            custom-class="w-3 h-3 mx-3"
            :id="`breadcrumb-arrow-${index}`"
          />
          <router-link
            :class="[
              !experiencePage
                ? breadcrumb.active
                  ? 'text-gray-700 font-semibold'
                  : 'font-medium text-gray-500 hover:text-gray-700 ml-4 text-sm'
                : 'text-sm',
            ]"
            :to="{ name: breadcrumb?.parent }"
            :exact-active-class="
              experiencePage
                ? `${secondaryColorClassLinks}`
                : 'router-link-exact-active'
            "
            :id="`breadcrumb-link-${index}`"
          >
            {{ breadcrumb.title }}
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
// For details of how to use it, check the PR for the ticket 1586
import ArrowRight from "./icons/ArrowRight.vue";
export default {
  name: "BreadCrumb",
  components: {
    ArrowRight,
  },
  props: {
    customSpacer: {
      type: Boolean,
      default: false,
    },
    experiencePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  mounted() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbs = this.$route.meta.breadcrumb;
    },
    forceUpdate() {
      this.breadcrumbs = JSON.parse(
        JSON.stringify(this.$route.meta.breadcrumb)
      );
    },
  },
};
</script>
