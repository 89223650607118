<script setup>
import { ref, computed, defineEmits, defineProps, watch } from "vue";
import { useVModel } from "@vueuse/core";
import IncludeItem from "./IncludeItem.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["update:modelValue"]);
const events = useVModel(props, "modelValue", emit);

const included = ref([]);

const includesOther = computed(() => {
  return events?.value?.includes_other;
});
const otherIncluded = ref([""]);

const addIncludeContent = () => {
  otherIncluded.value.push("");
};

const removeIncludeContent = (index) => {
  if (otherIncluded.value.length > 1) {
    otherIncluded.value.splice(index, 1);
  }
};

const includes = [
  { id: "transportation", name: "Transportation", value: 0 },
  { id: "breakfast", name: "Breakfast", value: 0, hasNumericInput: true },
  { id: "lunch", name: "Lunch", value: 0, hasNumericInput: true },
  { id: "dinner", name: "Dinner", value: 0, hasNumericInput: true },
  {
    id: "food + drinks",
    name: "Food + Drinks",
    value: 0,
    hasNumericInput: true,
  },
  { id: "accomodation", name: "Accommodation", value: 0 },
  { id: "guided tour", name: "Guided Tour", value: 0 },
  { id: "private tour", name: "Private Tour", value: 0 },
  { id: "entry ticket/fee", name: "Entry ticket/fee", value: 0 },
  { id: "free wi-fi", name: "Free Wi-Fi", value: 0 },
  { id: "equipment rental", name: "Equipment Rental", value: 0 },
  { id: "free time to explore", name: "Free time to explore", value: 0 },
  { id: "includeOther", name: "Other", value: 0 },
];

const processIncludesData = (data) => {
  return data.map((item) => {
    if (typeof item === "string") {
      return { id: item, name: item, value: 0 };
    }
    return item;
  });
};

const includedData = computed(() => events.value?.includes);

const toggleIncluded = (item) => {
  const index = included.value.findIndex(
    (includedItem) => includedItem.id === item.id
  );
  if (index !== -1) {
    included.value.splice(index, 1);
  } else {
    included.value.push({ ...item, value: 0 });
  }
};

const updateValue = (event, id) => {
  let value = event.target.value;

  if (value.includes(".")) {
    value = value.split(".")[0];
    event.target.value = value;
  }

  const itemIndex = included.value.findIndex(
    (includedItem) => includedItem.id === id
  );
  if (itemIndex !== -1) {
    included.value[itemIndex].value = parseInt(value) || 0;
  }
};

const otherIncludedFields = (id) => {
  const included = ["includeOther"];
  return (
    included.includes(id) &&
    events.value.includes?.some((item) => item.id === id)
  );
};

const updateOtherIncluded = ({ index, newValue }) => {
  otherIncluded.value[index] = newValue;
  if (!includesOther.value) {
    events.value.includes_other = [];
    events.value.includes_other[index] = newValue;
  }
};

watch(
  included,
  (newIncluded) => {
    events.value.includes = newIncluded.map((i) => ({
      id: i.id,
      name: i.name,
      value: i.value,
    }));
  },
  { deep: true }
);

const unwatch = watch(includedData, (newVal) => {
  if (newVal) included.value = processIncludesData(newVal);
  unwatch();
});

watch(
  includesOther,
  (val) => {
    otherIncluded.value = val;
  },
  { deep: true }
);
</script>

<template>
  <div class="mb-4">
    <label label class="font-semibold">What is included?</label>
    <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
      <IncludeItem
        v-for="item in includes"
        :key="item.id"
        :item="item"
        :is-other-included="otherIncludedFields(item.id)"
        :other-included="otherIncluded"
        :events="events"
        @update-value="updateValue"
        @update-other-included="updateOtherIncluded"
        @toggle-included="toggleIncluded"
        @add-include-content="addIncludeContent"
        @remove-include-content="removeIncludeContent"
      />
    </div>
  </div>
</template>
