import { computed, ref } from "vue";
import { formattedPriceFromCentsWithoutCents } from "@/util/formatter";

const originalSessionPrices = ref(undefined);
const updatedOrder = ref(undefined);

const show = (property) => {
  return (
    originalSessionPrices.value[property] > 0 ||
    updatedOrder.value[property] > 0
  );
};
const hasChanged = (property) => {
  return originalSessionPrices.value[property] !== updatedOrder.value[property];
};

const diffs = computed(() => {
  return {
    educationalFee: {
      original: formattedPriceFromCentsWithoutCents(
        originalSessionPrices?.value?.session_base_educational_fee
      ),
      updated: formattedPriceFromCentsWithoutCents(
        updatedOrder?.value?.session_base_educational_fee
      ),
      show: show("session_base_educational_fee"),
      hasChanged: hasChanged("session_base_educational_fee"),
      name: "Educational Fee",
    },
    internshipFee: {
      original: formattedPriceFromCentsWithoutCents(
        originalSessionPrices?.value?.session_base_internships_price
      ),
      updated: formattedPriceFromCentsWithoutCents(
        updatedOrder?.value?.session_base_internships_price
      ),
      show: show("session_base_internships_price"),
      hasChanged: hasChanged("session_base_internships_price"),
      name: "Internship Fee",
    },
    housingFee: {
      original: formattedPriceFromCentsWithoutCents(
        originalSessionPrices?.value?.session_base_housing_fee
      ),
      updated: formattedPriceFromCentsWithoutCents(
        updatedOrder?.value?.session_base_housing_fee
      ),
      show: show("session_base_housing_fee"),
      hasChanged: hasChanged("session_base_housing_fee"),
      name: "Housing Fee",
    },
    excursionsFee: {
      original: formattedPriceFromCentsWithoutCents(
        originalSessionPrices?.value?.session_base_events_fee
      ),
      updated: formattedPriceFromCentsWithoutCents(
        updatedOrder?.value?.session_base_events_fee
      ),
      show: show("session_base_events_fee"),
      hasChanged: hasChanged("session_base_events_fee"),
      name: "Excursions",
    },
    insuranceFee: {
      original: formattedPriceFromCentsWithoutCents(
        originalSessionPrices?.value?.session_base_insurance_fee
      ),
      updated: formattedPriceFromCentsWithoutCents(
        updatedOrder?.value?.session_base_insurance_fee
      ),
      show: show("session_base_insurance_fee"),
      hasChanged: hasChanged("session_base_insurance_fee"),
      name: "Insurance",
    },
    groupFlightFee: {
      original: formattedPriceFromCentsWithoutCents(
        originalSessionPrices?.value?.session_base_group_flight_fee
      ),
      updated: formattedPriceFromCentsWithoutCents(
        updatedOrder?.value?.session_base_group_flight_fee
      ),
      show: show("session_base_group_flight_fee"),
      hasChanged: hasChanged("session_base_group_flight_fee"),
      name: "Group Visa Service",
    },
    transportationFee: {
      original: formattedPriceFromCentsWithoutCents(
        originalSessionPrices?.value?.session_base_transportation_fee
      ),
      updated: formattedPriceFromCentsWithoutCents(
        updatedOrder?.value?.session_base_transportation_fee
      ),
      show: show("session_base_transportation_fee"),
      hasChanged: hasChanged("session_base_transportation_fee"),
      name: "Transportation Fee",
    },
    classesFee: {
      original: formattedPriceFromCentsWithoutCents(
        originalSessionPrices?.value?.session_base_class_academic_fee
      ),
      updated: formattedPriceFromCentsWithoutCents(
        updatedOrder?.value?.session_base_class_academic_fee
      ),
      show: show("session_base_class_academic_fee"),
      hasChanged: hasChanged("session_base_class_academic_fee"),
      name: "Classes Fee",
    },
  };
});

const getDiffs = ({ left = undefined, right = undefined }) => {
  originalSessionPrices.value = left;
  updatedOrder.value = right;
  return diffs?.value;
};

export const usePrifeDifference = () => {
  return {
    getDiffs,
  };
};
