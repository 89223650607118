<script setup>
import { defineProps, defineEmits } from "vue";
import { debounce } from "lodash";
import { useVModel } from "@vueuse/core";
import { useGetProfiles } from "@/components/program-manager/sessions/composable";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import BasicTextInput from "@/components/shared/input/BasicTextInput.vue";
import { getProfileLabel } from "@/components/program-manager/sessions/utils";
import { PROFILE_LEADER_TYPES } from "@/constants";

const emit = defineEmits(["update:modelValue", "deleteItem"]);

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  parentLoading: {
    type: Boolean,
    default: false,
  },
  vuelidateInstance: {
    type: Object,
    default: () => {},
  },
  hasRole: {
    type: Boolean,
    default: false,
  },
  displayLabel: {
    type: Boolean,
    default: true,
  },
});

const profile = useVModel(props, "modelValue", emit);

const {
  execute: fetchProfiles,
  state: profileOptions,
  isLoading: profileIsLoading,
} = useGetProfiles(
  { immediate: false, throwError: false, resetOnExecute: true },
  { limit: 10, skip: 0 }
);

const reduceProfileOption = (profileOption) => {
  if (props.hasRole) {
    return {
      id: profileOption.id,
      role: profile.value.role,
      preferred_first_name: profileOption.preferred_first_name,
      legal_first_name: profileOption.legal_first_name,
      last_name: profileOption.last_name,
    };
  }
  return {
    id: profileOption.id,
    preferred_first_name: profileOption.preferred_first_name,
    legal_first_name: profileOption.legal_first_name,
    last_name: profileOption.last_name,
  };
};

const fetchProfilesOptions = debounce(async (search) => {
  if (search) {
    try {
      await fetchProfiles(0, {
        extraParams: { full_names: [search], user_types: PROFILE_LEADER_TYPES },
        cancel: true,
      });
    } catch (err) {
      console.error(err);
    }
  }
}, 250);
</script>

<template>
  <SearchableSelect
    class="w-full"
    v-model="profile"
    :loading="profileIsLoading || parentLoading"
    :options="profileOptions.items"
    :clearable="false"
    placeholder="Search and add"
    :reduce="reduceProfileOption"
    :vuelidate-instance="vuelidateInstance"
    :get-option-label="getProfileLabel"
    @search="fetchProfilesOptions"
  >
    <template #fieldLabel v-if="props.displayLabel">
      <label class="common-label-text"
        ><span class="text-red-100 pr-2">**</span>Name</label
      >
    </template>
  </SearchableSelect>
  <div class="flex items-center">
    <BasicTextInput
      v-if="props.hasRole"
      v-model="profile.role"
      outer-classes="flex-1"
      field-label="Role"
      label="role"
    />
    <div class="ml-2 cursor-pointer" @click="() => emit('deleteItem')">
      <i class="fa fa-trash text-gray-650"></i>
    </div>
  </div>
</template>
