<template>
  <div class="relative z-50">
    <Dialog
      :open="isModalOpen"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
      @closeModal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
      ></div>
      <div
        class="max-w-4xl p-6 mx-auto bg-white sm:rounded-lg sm:shadow z-20 relative"
      >
        <DialogPanel>
          <div class="text-right">
            <button @click="closeModal">
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <section class="sm:px-16 md:px-24 sm:py-10">
            <h1
              class="flex justify-center text-xl leading-tight text-center sm:text-2xl md:text-3xl mb-8"
            >
              {{ props.title }}
            </h1>
            <slot></slot>
            <div class="flex justify-end mt-5">
              <button
                :class="`${secondaryColorClass}`"
                class="mr-7 my-7 text-center uppercase hover:opacity-70"
                @click="() => emit('clearFilters', true)"
              >
                RESET FILTERS
              </button>
            </div>
            <div class="flex justify-center">
              <BaseButton class="text-base" outlined :on-click="closeModal">
                Cancel
              </BaseButton>
              <BaseButton class="text-base" :on-click="applyFilters">
                Apply
              </BaseButton>
            </div>
          </section>
        </DialogPanel>
      </div>
    </Dialog>
    <div
      class="flex-none flex items-center pr-3 cursor-pointer hover:opacity-80 w-full h-full"
      @click="openModal"
    >
      <IconFilter />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";

import { Dialog, DialogPanel } from "@headlessui/vue";

import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import IconFilter from "@/components/shared/icons/IconFilter.vue";
import BaseButton from "@/components/shared/Button/BaseButton";

const emit = defineEmits([
  "closeModal",
  "openModal",
  "clearFilters",
  "applyFilters",
]);

const props = defineProps({
  title: {
    type: String,
    default: "Housing Filter",
  },
});

const isModalOpen = ref(false);
const openModal = () => {
  isModalOpen.value = true;
  emit("openModal", true);
};
const closeModal = () => {
  isModalOpen.value = false;
  emit("clearFilters", true);
  emit("closeModal", true);
};
const applyFilters = () => {
  isModalOpen.value = false;
  emit("applyFilters", true);
  emit("closeModal", true);
};
</script>
