<template>
  <div>
    <div
      v-if="notFound"
      class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8"
    >
      <div class="my-4">
        <div
          class="absolute inset-0 flex items-center justify-center bg-white z-10"
        >
          <div class="flex flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-alert-triangle text-university-primary"
            >
              <path
                d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
              ></path>
              <line x1="12" y1="9" x2="12" y2="13"></line>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
            <div class="mt-5 text-sm text-center text-gray-600 space-y-2">
              <p>
                The page you requested was not found.
              </p>
              <router-link
                :to="{
                  name: 'applications/landing',
                  params: { applicationId: $route.params.applicationId },
                }"
                :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
                class="underline hover:no-underline"
              >
                Please click here to go back.
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="dynamicComponent" v-else />
  </div>
</template>

<script>
import onBoardingSection from "../../mixins/onBoardingSection";
import forms from "../../mixins/forms";
import Spinner from "../helpers/Spinner.vue";

export default {
  name: "SingleFormLoader",
  components: { Spinner },
  mixins: [forms, onBoardingSection],
  data() {
    return {
      dynamicComponent: "",
      notFound: false,
    };
  },
  computed: {
    getDynamicRouteData() {
      return this.onBoardingForms.find((item) => {
        return item.slug === this.$route.params.form;
      });
    },
  },
  async created() {
    //Redirect to the landing page if applicationId is not valid
    const applicationValid = await this.isValidApplication(
      this.$route.params.applicationId,
      ""
    );

    if (applicationValid) {
      if (
        _.isEmpty(this.program) ||
        this.currentApplicationId !== this.$route.params.applicationId
      ) {
        this.$store.commit(
          "setCurrentApplicationId",
          this.$route.params.applicationId
        );
        await this.$store.dispatch("setProgramDataByApplicationId", {
          applicationId: this.$route.params.applicationId,
          apolloClient: this.$apollo,
        });
      }
      this.loadComponent();
    } else {
      window.location.href = "/landing-page";
    }
  },

  methods: {
    async loadComponent() {
      if (
        this.getDynamicRouteData &&
        ((await this.isFormAllowedByFormRules(
          this.getDynamicRouteData.ruleName,
          this.$route.params.applicationId
        )) ||
          this.getDynamicRouteData.specialDisplayLogic)
      ) {
        this.dynamicComponent = this.getDynamicRouteData.component;
      } else {
        this.notFound = true;
      }
    },
  },
};
</script>
