import { learningServiceRequest, skip } from "@/services/utils";

const entitiesService = {
  async getEntities({
    limit = 100,
    page = 1,
    search,
    q = "",
    extraParams = {},
    failureMessage,
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/entities",
      params: {
        limit,
        skip: skip(page, limit),
        q: q || search,
        ...extraParams,
      },
      failureMessage,
      source,
    });
  },
  async getEntityById(entityId) {
    return await learningServiceRequest({
      method: "get",
      url: `/entities/${entityId}`,
      failureMessage: "Error loading Entity data",
    });
  },
};

export default entitiesService;
