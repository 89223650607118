import axios from "axios";
import store from "../store";
import ErrorBus from "@/components/ErrorBus.vue";

const skipErrorBus = Boolean(process.env.MIX_APP_ENV === "production");

export const getApiClient = () => {
  const client = axios.create({
    baseURL: "/api",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "platform-type": "web",
    },
  });

  client.interceptors.response.use(
    // success callback;
    // we're just telling axios to handle successful requests normally
    (response) => {
      return response;
    },
    // error callback;
    // here we are defining centralized error handling for all backend requests
    (error) => {
      // Only trigger the logout action on the /user url
      // for other type of errors we will show the error modal
      const errorCode = error?.response?.status;
      if (error?.response?.config?.url === "/user" && errorCode === 401) {
        store.dispatch("logout", {
          sessionExpired: true,
          reload: true,
        });
      } else if (
        error?.response?.config?.url === "/forms/current" &&
        errorCode === 500
      ) {
        window.location.href = "/landing-page";
      } else if (
        !skipErrorBus &&
        !error?.response?.config?.skipErrorBus &&
        error?.response?.data?.sentryError &&
        errorCode !== 404
      ) {
        ErrorBus.registerError(error?.response?.data?.sentryError);
      }

      return Promise.reject(error);
    }
  );

  return client;
};
