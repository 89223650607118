export default {
  emits: ["input"],
  watch: {
    value: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
};
