<script setup>
import { computed, defineProps } from "vue";
const props = defineProps({
  content: {
    type: String,
    default: "",
  },
  maxCharacters: {
    type: Number,
    default: 180,
  },
});

const content = computed(() => {
  return props.content.slice(0, props.maxCharacters);
});
</script>

<template>
  <p class="whitespace-pre-wrap max-w-[400px]">
    {{ content }}
    <span
      v-if="props.content.length > props.maxCharacters"
      :class="`${secondaryColorClassLinks} lowercase font-semibold`"
    >
      ... more
    </span>
  </p>
</template>
