<script setup>
import {
  ref,
  computed,
  watch,
  defineProps,
  defineEmits,
  reactive,
  toRefs,
} from "vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import { email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { getApiClient } from "@/services/API.js";

const props = defineProps({
  labelPrefix: {
    type: String,
    default: "aid",
  },
  placeholderEmail: {
    type: String,
    default: "ENTER YOUR EMAIL",
  },
  program: {
    type: Object,
    required: true,
  },
  session: {
    type: Object,
    required: true,
  },
});

const { program, session } = toRefs(props);

const emits = defineEmits(["emailSent", "submitForm", "update:email-sender"]);

const state = reactive({
  value: {
    email: "",
  },
});
const error = ref("");
const successSubmission = ref(false);
const submitButton = ref(null);
const initialEmail = ref("");

const rules = computed(() => ({
  value: {
    email: {
      email,
    },
  },
}));

watch(
  state,
  () => {
    emits("update:email-sender", state);
  },
  { deep: true, immediate: true }
);

const submit = () => {
  submitButton.value.startLoading();
  if (!v$.$invalid) {
    sendEmailRequest();
  }
};

const sendEmailRequest = async () => {
  let programPageURL = program.value?.url;
  let programName = program.value?.name;
  let sessionStartDate = session.value?.session_travel_details[0]?.arrival_date;
  let sessionEndDate = session.value?.session_travel_details[0]?.departure_date;
  let emailAddress = state.value?.email;
  let date = new Date(Date.now());

  const data = {
    programPageURL,
    programName,
    sessionStartDate,
    sessionEndDate,
    emailAddress,
    date,
  };

  const response = await getApiClient().post("/send-financial-aid-email", data);

  if (response.status == 200) {
    successSubmission.value = true;
    submitButton.value.stopLoading();
    initialEmail.value = data.emailAddress;
  } else {
    error.value =
      "Something went wrong while sending the email, please try later.";
    submitButton.value.stopLoading();
  }
};

const showSuccess = () => {
  if (
    successSubmission.value &&
    !v$.$invalid &&
    state.value.email == initialEmail.value
  ) {
    return true;
  }
};

const v$ = useVuelidate(rules, state, {
  $registerAs: "Email",
  $lazy: true,
});
</script>
<template>
  <div
    class="grid lg:grid-cols-3 md:lg:grid-cols-3 xs:grid-cols-1 xs:grid-rows-2 grid-cols-3 gap-x-4 mt-6"
  >
    <label :for="labelPrefix + 'email'" class="block text-sm">
      <input
        :id="labelPrefix + 'email'"
        v-model="state.value.email"
        type="email"
        :placeholder="placeholderEmail"
        class="block w-full text-indigo-base bg-white border-gray-600 border min-h-[58px] font-medium p-4 focus:outline-none"
        :class="[
          {
            'bg-error-100': v$.value.email.$errors?.length,
          },
        ]"
        @blur="v$.value.email.$touch"
      />
      <div v-if="v$.value.email.email.$invalid" class="error text-error-900">
        Email must be formatted correctly.
      </div>
    </label>
    <div class="flex w-full sm:block">
      <ButtonWithSpinner
        id="submitButton"
        ref="submitButton"
        class="w-48"
        data-cy="submitButton"
        variant="secondary-outlined"
        variant-type="normal"
        button-height="min-h-[58px] md:min-h-[58px]"
        :custom-class="`${v$.$invalid || !state.value.email}` ? 'border-gray-600 rounded-none' : ''"
        :disabled="v$.$invalid || !state.value.email"
        :grey-disabled-class="true"
        @click="submit"
      >
        <div class="flex items-center justify-center">
          <span class="pr-3 uppercase">
            Submit
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
    </div>
    <div v-if="showSuccess()"
      class="col-span-2"
      :class="[
        secondaryColor ?
            `${brandingSecondaryText}` :
            'text-university-secondary'
      ]"
    >
      Success! Be on the look out for more information on scholarship
      opportunities available for this program.
    </div>
    <div class="text-center error text-error-900 col-span-2">
      <p v-if="error">{{ error }}</p>
      <slot name="errors" />
    </div>
  </div>
</template>
