<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div v-if="showHeader">
      <slot name="pageHeader" class="my-4">
        <h1 class="pt-6 text-xl font-semibold md:text-2xl text-blue-900">
          Settings
        </h1>
      </slot>
    </div>
    <div class="px-4 py-6 -mx-4 sm:-mx-8 sm:px-8">
      <slot name="pageContent"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageDefaultDesign",
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
