import { trailingSlash } from "./helpers";
import SentryHelp from "../mixins/sentryHelper";

export default {
  mixins: [SentryHelp],
  created: function () {
    this.initList();
  },
  data() {
    return {
      countries: [],
    };
  },
  methods: {
    async initList() {
      return await fetch(
        trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) +
          "countries"
      )
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          return response.json();
        })
        .then((data) => {
          this.countries = Object.values(data);
        })
        .catch((error) => {
          console.log(error);
          this.throwSentryError("Fetch Countries (Lead)", { error: error });
        });
    },
    /**
     * Triggered when the search text changes on vSelect component for country list
     *
     * @param search  {String}    Current search text
     * @param loading {Function}	Toggle loading class
     */
    async fetchCountries(search, loading) {
      loading(true);
      return await fetch(
        trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) +
          "countries?search=" +
          escape(search)
      )
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          return response.json();
        })
        .then((data) => {
          this.countries = Object.values(data);
          loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.throwSentryError("Fetch Countries (Lead)", { error: error });
          loading(false);
        });
    },
  },
};
