<script setup>
import NextSteps from "@/components/ApplicationSuccessNextSteps/ApplicationSuccessNextSteps.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const sessionTypesLength = computed(() => {
  return store.state.programSessionDataV3.session_types?.length ?? 0;
});
const programVariant = computed(() => {
  // Career and internship are used interchangeably
  return sessionTypesLength.value === 1 &&
    ["Career", "Internship"].includes(
      store.state.programSessionDataV3.session_types?.[0]?.name
    )
    ? "internship"
    : "studyAbroad";
});
const theme = computed(() => {
  return store.state.featureFlags?.["next-steps-mobile-app"] === true
    ? "mobileScreen"
    : "default";
});
</script>
<template>
  <NextSteps :variant="programVariant" :design-theme="theme" />
</template>
