<script setup>
import {defineProps, toRefs} from "vue";

const props = defineProps({
  name: {
    required: true,
    type: String,
  },
  description: {
    required: false,
    type: String,
    default: ""
  }
});
const { name, description } = toRefs(props);
</script>

<template>
  <li
    class="profile-menu-item cursor-pointer"
  >
    <span class="text-sm text-gray-600">{{name}}</span><br/>
    <small v-if="description" class="text-xs text-gray-600">{{description}}</small>
  </li>
</template>
