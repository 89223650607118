<template>
  <svg
    width="47"
    height="47"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_5035_47196)">
      <ellipse
        cx="23.3425"
        cy="23.798"
        rx="19.3425"
        ry="19.2004"
        fill="#1E264C"
      />
      <path
        d="M41.9349 23.798C41.9349 33.9827 33.616 42.2484 23.3425 42.2484C13.0689 42.2484 4.75 33.9827 4.75 23.798C4.75 13.6134 13.0689 5.34766 23.3425 5.34766C33.616 5.34766 41.9349 13.6134 41.9349 23.798Z"
        stroke="#1E264C"
        stroke-width="1.5"
      />
    </g>
    <path
      d="M19.4727 33.6475L28.1768 23.9226L19.4727 14.1977"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <filter
        id="filter0_d_5035_47196"
        x="0"
        y="0.597656"
        width="46.6836"
        height="46.4004"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5035_47196"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5035_47196"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
