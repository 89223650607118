import axios from "axios";
import { trailingSlash } from "../mixins/helpers";

const enrollmentServiceRoot = trailingSlash(
  process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
);

const getUniversities = async (params) => {
  return (
    await axios.get(`${enrollmentServiceRoot}universities`, { params })
  ).data.sort((a, b) => a.label.localeCompare(b.label));
};

const searchUniversities = async (search) => {
  return await getUniversities({ search });
};

const fetchBillingRules = async (ope8Id) => {
  const url = `${enrollmentServiceRoot}university/billing/${ope8Id}`;
  return (await axios.get(url)).data;
};

const loadContracts = async (ope8_id) => {
  let contracts = [];
  try {
    const rules = await fetchBillingRules(ope8_id);
    if (rules?.agreements?.length) {
      contracts = rules.agreements;
    }
  } catch (e) {
    if (e.response.status === 404) {
      console.info(`Billing agreement for ${ope8_id}: ${e.response.data}`);
    } else {
      console.warn(`Error fetching billing agreements for ${ope8_id}: ${e.response.statusText}`);
    }
  }
  return contracts;
};

const getUniversity = async (universityId) => {
  return (await axios.get(`${enrollmentServiceRoot}university/${universityId}`))
    .data;
};

const enrollmentService = {
  getUniversities,
  searchUniversities,
  loadContracts,
  getUniversity,
  fetchBillingRules,
};

export default enrollmentService;
