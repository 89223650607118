<script setup>
import NoResultsPage from "@/components/NoResultsPage.vue";
import { computed, onMounted, ref, unref, watch, reactive } from "vue";
import { useStore } from "vuex";
import algoliasearch from "algoliasearch/lite";
import { algoliaConfigurationMap, TABS, TAB_ROUTE_NAMES } from "./constants";
import { INITIAL_FILTERS } from "@/components/ExperiencePage/Algolia/AlgoliaSideBar/constants";
import QuickFilter from "./QuickFilter";
import ArrowRightIcon from "../Menu/ArrowRightIcon.vue";
import Spinner from "../../helpers/Spinner.vue";
import HomeSessionCard from "./ResultCards/HomeSessionCard";
import { selectImageForProgram } from "./Tabs/utils";
import { useRouter } from "vue-router";
import { cloneDeep, isEmpty, isEqual, merge } from "lodash";
import { useConvertToUrlParams } from "./composables";
import { AisConfigure } from "vue-instantsearch/vue3/es";
import { applicationAlreadyExists } from "@/components/ExperiencePage/utils.js";
import ApplicationExistsModal from "@/components/ExperiencePage/ApplicationExistsModal.vue";
import { buildFilterStrings2 } from "@/components/ExperiencePage/Algolia/utils";

const store = useStore();
const router = useRouter();

const programImageIndices = {};
const showApplicationTakenModal = ref(false);
const algoliaFilters = ref("");

const domainEntityId = computed(() => store.getters.getDomainEntityId);
const ss1140FeatureFlag = computed(() => {
  return store.state.featureFlags?.["ss-1140"];
});
const tenancyDomainRestrictedMap = {
  sessionsDomainRestrictedId: domainEntityId.value,
  programsDomainRestrictedId: domainEntityId.value,
  programsPageOwnerEntityIdFilter: domainEntityId.value,
  sessionsOwnerEntityIdFilter: domainEntityId.value,
};

const state = reactive({
  filters: {
    ...INITIAL_FILTERS,
  },
  searchResults: [],
  searchIsLoading: false,
  searchIsError: false,
});

const searchClient = algoliasearch(
  process.env.MIX_ALGOLIA_APP_ID,
  process.env.MIX_ALGOLIA_API_KEY
);

const oktaId = computed(() => {
  return store.state.currentUser?.participantId ?? "";
});

const profileData = computed(() => {
  return store.state.profileData;
});

const activeAlgoliaIndex = computed(() => {
  const currentTab = TABS[0];
  return currentTab ? currentTab.algoliaIndex : undefined;
});

const currentSearchIndex = computed(() => {
  return searchClient.initIndex(unref(activeAlgoliaIndex));
});

const algoliaConfiguration = computed(() => {
  return algoliaConfigurationMap[TABS[0].value] || {};
});

const updateFilters = (clonedFilters) => {
  const combinedTenancyFilters = merge(
    clonedFilters,
    tenancyDomainRestrictedMap
  );
  if (clonedFilters) {
    algoliaFilters.value = buildFilterStrings2(
      unref(algoliaConfiguration),
      combinedTenancyFilters,
      unref(ss1140FeatureFlag)
    );
  }
};

const search = async () => {
  try {
    state.searchIsLoading = true;
    state.searchIsError = false;
    const algoliaParams = {
      hitsPerPage: 8,
      page: 0,
      filters: algoliaFilters.value,
    };
    const searchResponse = await currentSearchIndex.value.search(
      "",
      algoliaParams
    );
    state.searchResults = searchResponse.hits;
  } catch (error) {
    state.searchIsError = true;
  } finally {
    state.searchIsLoading = false;
  }
};

const redirectToViewPage = (programId, sessionId) => {
  router.push({
    name: "program-detail-view",
    params: { programId },
    query: { "session-id": sessionId },
  });
};

const onSubmit = () => {
  const query = useConvertToUrlParams(state.filters);
  const pathName =
    state?.filters?.programTypes?.length === 1 &&
    state?.filters?.programTypes?.[0] === "Internships"
      ? TAB_ROUTE_NAMES.INTERNSHIP_POSTINGS
      : TAB_ROUTE_NAMES.PROGRAMS;
  if (!isEmpty(query)) {
    router.push({ name: pathName, query });
  } else {
    router.push({ path: pathName });
  }
};

const onApplyClicked = async (program) => {
  const { studentApplications } = store.state;
  const exists = await applicationAlreadyExists({
    programSession: program.session,
    studentApplications: studentApplications,
    skipRequest: false,
  });
  showApplicationTakenModal.value = exists;
  if (!exists) {
    await router.push({
      name: "configurator-new",
      params: { programPageId: program?.program_page?.id },
      query: { session: program?.session?.id },
    });
  }
};

watch(
  () => state.filters,
  (newFilters, oldFilters) => {
    if (!isEqual(newFilters, oldFilters)) {
      const clonedFilters = cloneDeep(newFilters);
      updateFilters(clonedFilters);
    }
  },
  { deep: true, immediate: true }
);

onMounted(async () => {
  if (oktaId.value && isEmpty(profileData.value)) {
    await store.dispatch("getProfile", oktaId.value);
  }
  if (store.state.currentUser) {
    await store.dispatch("getStudentApplications");
  }
  await search();
});
</script>

<template>
  <ais-instant-search
    :index-name="activeAlgoliaIndex"
    :search-client="searchClient"
  >
    <ais-configure :filters="algoliaFilters" />
    <ApplicationExistsModal
      :show-application-taken-modal="showApplicationTakenModal"
      @close="showApplicationTakenModal = false"
    />
    <QuickFilter
      v-model="state.filters"
      :algolia-configuration="algoliaConfigurationMap.programs"
      :current-search-index="currentSearchIndex"
      @search-submitted="onSubmit"
    />
    <div id="landingPageContent" class="px-2.5 bg-white mb-36 mt-4-625">
      <div
        id="landingPageHeader"
        class="flex flex-col items-start md:flex-row md:justify-between md:mb-12"
      >
        <h3
          id="trendingProgramsTitle"
          class="font-montserrat non-italic font-bold text-indigo-base uppercase leading-9 text-xl sm:text-2.5xl mb-5 md:mb-0"
        >
          TRENDING PROGRAMS
        </h3>
        <button
          id="browseAllProgramsButton"
          :class="`flex flex-row justify-center items-center ${secondaryColorClass} gap-x-3 font-bold uppercase text-base mb-9 md:mb-0`"
          type="button"
          @click="$router.push({ name: 'results' })"
        >
          <span class="leading-none">Browse All Programs</span>
          <ArrowRightIcon :fill-color="`${secondaryColor}`" />
        </button>
      </div>
      <div
        v-if="state.searchIsLoading && !state.searchResults.length > 0"
        class="relative h-50-screen"
      >
        <Spinner />
      </div>
      <div
        v-if="state.searchResults.length > 0"
        class="justify-items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-12 lg:px-4 pb-18"
      >
        <HomeSessionCard
          v-for="(program, index) in state.searchResults"
          :key="index"
          :image-url="selectImageForProgram(program, programImageIndices)"
          :program="program"
          class="grid-cols-1"
          event-category="HP Personalize CTA"
          @view-clicked="
            redirectToViewPage(program?.program_page?.id, program?.session?.id)
          "
          @apply-clicked="onApplyClicked(program)"
        />
      </div>
      <div v-if="!state.searchIsLoading && !state.searchResults.length > 0">
        <NoResultsPage margin-top="mt-5" />
      </div>
      <div
        id="browseAllProgramsButtonBottom"
        class="flex justify-center items-center mt-11 sm:mt-24"
      >
        <button
          :class="`flex flex-row justify-center items-center ${secondaryColorClass} gap-x-3 font-bold uppercase text-base mb-9 md:mb-0`"
          type="button"
          @click="$router.push({ name: 'results' })"
        >
          <span class="leading-none">Browse All Programs</span>
          <ArrowRightIcon :fill-color="`${secondaryColor}`" />
        </button>
      </div>
    </div>
  </ais-instant-search>
</template>
