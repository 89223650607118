<script setup>
import { ref, reactive, computed, provide, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { eventBus } from "@/app";
import { debounce } from "lodash";
import { FlexRender } from "@tanstack/vue-table";

import MinimalActionsMenu from "@/components/shared/Table/MinimalActionsMenu.vue";
import ErrorPage from "@/components/errorPage.vue";
import Pagination from "@/components/shared/Pagination.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import HeadersCheckboxList from "@/components/shared/Table/HeadersCheckboxList.vue";
import CogTooth from "@/components/shared/icons/CogTooth.vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { useGetCityOptions } from "@/components/program-manager/sessions/composable";
import { createColumnHelper } from "@tanstack/vue-table";
import {
  getCoreRowModel,
  getSortedRowModel,
  useVueTable,
} from "@tanstack/vue-table";

const router = useRouter();
const route = useRoute();

const headers = ["City", "Country", "Tags"];

const visibleHeaders = ref(headers);
provide("visibleOptions", visibleHeaders);

const requestParams = reactive({
  limit: 25,
  page: 1,
  search: "",
  extraParams: {},
});

const {
  isLoading: loading,
  error: fetchError,
  execute: fetchCities,
  state: citiesData,
} = useGetCityOptions(
  { immediate: true, throwError: true, resetOnExecute: true },
  { city_ascii: requestParams.search }
);

const openAdvancedFilters = ref(false);
const selectedSortHeader = ref(headers[0]);
const isDesc = ref(false);

const { page: routePage, q } = route.query;

requestParams.search = q || "";
requestParams.page = Number(routePage) || 1;

const activeFilter = computed(() => ({
  title: "Cities",
  count: citiesData?.value?.count || 0,
}));

const actionsRoutes = (cityId = undefined) => [
  {
    name: `/account/settings/cities/${cityId}`,
    description: "View/Detail",
  },
];

const showPagination = computed(
  () => activeFilter.value.count > requestParams.limit
);

const toggleColumnsVisibility = (visibleColumns) => {
  let columns = table?.getAllColumns();
  columns.forEach((column) => {
    if (!visibleColumns.includes(column.id)) {
      column.toggleVisibility(false);
    } else {
      column.toggleVisibility(true);
    }
  });
};

eventBus.$on("updateHeaders", (selectedHeaders) => {
  visibleHeaders.value = selectedHeaders;
  toggleColumnsVisibility(selectedHeaders);
});

watch(requestParams, (newParams) => {
  fetchCities(0, {
    city_ascii: newParams.search,
    page: newParams.page,
    limit: newParams.limit,
  });
});

const columnHelper = createColumnHelper();

const table = useVueTable({
  get data() {
    return (
      citiesData?.value?.items?.map((item) => ({
        id: item?.id,
        City: item?.city_ascii,
        Country: item?.country?.name,
        Tags: item?.tags?.map(({ name }) => name).join(", "),
      })) || []
    );
  },
  columns: headers.map((header) => {
    return columnHelper.accessor(header, {
      cell: (info) => info.getValue(),
      header,
    });
  }),
  enableColumnResizing: true,
  columnResizeMode: "onChange",
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  manualPagination: true,
});

toggleColumnsVisibility(visibleHeaders.value);

const handleSearch = (search) => {
  requestParams.search = search || "";
  requestParams.page = 1;
  router.push({
    query: {
      page: 1,
      q: requestParams.search,
    },
  });
};

const changePage = (newPage) => {
  requestParams.page = newPage;
  router.push({
    query: {
      ...route.query,
      page: requestParams.page,
    },
  });
};

const handleClickHeader = ({ id }) => {
  if (id === selectedSortHeader.value) {
    isDesc.value = !isDesc.value;
  } else {
    isDesc.value = false;
    selectedSortHeader.value = id;
  }
  table?.getColumn(id).toggleSorting(isDesc.value);
};

const search = debounce(handleSearch, 250);
</script>

<template>
  <div>
    <section>
      <div class="flex mt-10 px-3 w-full flex-wrap">
        <div class="flex ml-2 w-full justify-between flex-wrap">
          <h1 class="text-2xl font-bold text-blue-900">
            Cities
          </h1>
          <SearchBar
            class="flex mt-3 sm:w-full min-w-[300px] md:w-[400px]"
            :input-from-parent="q"
            @handleSearch="search"
            @handleCustomFilter="openAdvancedFilters = true"
          >
          </SearchBar>
        </div>
      </div>
    </section>

    <div class="flex justify-center">
      <table v-if="!loading && !fetchError && table" class="m-4 p-2 w-full">
        <thead>
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
          >
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              class="py-4 pr-3 first:pl-2 text-left text-sm items-center text-gray-900"
              @click="handleClickHeader(header)"
            >
              <div class="flex items-center">
                {{ header.column.columnDef.header }}
                <button
                  v-if="selectedSortHeader === header.id"
                  class="ml-2 h-full w-fit duration-200"
                  :class="{ 'rotate-180': isDesc }"
                >
                  <ChevronDownIcon class="w-4 h-4" />
                </button>
              </div>
            </th>
            <th class="py-4 text-sm text-gray-900 w-[5%]">
              <HeadersCheckboxList :options="headers" max-headers="4">
                <CogTooth class="text-teal-900" />
              </HeadersCheckboxList>
            </th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr
            v-for="row in table.getRowModel().rows"
            :key="row.id"
            class="hover:bg-blue-350"
          >
            <td
              v-for="cell in row.getVisibleCells()"
              :key="cell.column.id"
              class="py-8 text-sm first:pl-4 first:text-teal-900 capitalize border-t-[1px] border-solid border-t-gray-200"
            >
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"
              />
            </td>
            <td class="py-8 border-t-[1px] border-solid border-t-[#f1f2f4]">
              <MinimalActionsMenu :routes="actionsRoutes(row.original.id)" />
            </td>
          </tr>
        </tbody>
      </table>
      <Spinner
        v-else-if="loading && !fetchError"
        class="relative h-65vh bg-blue-100"
      />
      <ErrorPage
        v-else-if="fetchError"
        class="relative h-65vh"
        :message="fetchError"
        disable-code
      />
    </div>
    <footer>
      <Pagination
        v-if="showPagination && !fetchError"
        class="mb-14"
        :page="requestParams.page"
        :total-of-records="activeFilter.count"
        :records-per-page="requestParams.limit"
        @changePage="changePage"
      />
    </footer>
  </div>
</template>
