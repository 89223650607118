<template>
  <div class="bg-white">
    <div class="m-4">
      <div
        v-if="showWarning"
        class="bg-teal-light p-6 pr-12 font-montserrat text-sm relative top-[-3rem] md:top-0 text-justify"
      >
        <span v-if="isPreSelectHousing" class="md:ml-14 md:inline-block">
          You can find information about program housing below. Please answer a
          few questions about your housing lifestyle so we can cultivate a
          comfortable experience for you. Please note all images are for
          illustration only. Actual booked units may vary.</span
        >
        <span v-else class="md:ml-14 md:inline-block">
          Note that this is a request to book, not a confirmation of booking. To
          finalize your request to book, select your preferred housing, then,
          answer a few questions about roommates and housing lifestyle. Please
          note all images are for illustration only. Actual booked units may
          vary.
        </span>

        <div
          class="absolute top-4 right-4 rounded-full bg-teal-900 text-center w-[1.25rem] md:top-1/3 md:left-12"
        >
          <span class="text-white font-courier font-semibold">!</span>
        </div>
        <div
          class="hidden md:block absolute top-4 right-4 rounded-full text-center w-[1.25rem] cursor-pointer"
          @click="() => emit('dismissWarning')"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
              fill="#1E264C"
            />
          </svg>
        </div>
      </div>
      <housing-unit-card
        v-for="(unit, index) in sortedUnits"
        :key="unit.id"
        :unit="unit.unit"
        :index="index"
        :default-room="defaultRoom"
        :room-prices="roomPrices"
        :selected="
          unit.unit?.rooms?.some((room) => selectedRooms.includes(room.id))
        "
      />
    </div>
    <StickyFooter
      max-next-width="max-w-[260px]"
      :disabled-next="shouldKeepNextButtonDisabled"
      :show-required-section="false"
      :show-footer="showFooter"
      @back="goToPreviousStep"
      @next="toQuestionnaire"
    />
  </div>
</template>

<script setup>
import StickyFooter from "@/components/Configurator/StickyFooter.vue";
import { computed, defineEmits, defineProps, inject } from "vue";
import HousingUnitCard from "./HousingUnitCard.vue";

const props = defineProps({
  units: {
    type: Array,
    default: () => [],
  },
  showWarning: {
    type: Boolean,
    default: false,
  },
  defaultRoom: {
    type: Object,
    default: () => ({}),
  },
  selectedRooms: {
    type: Array,
    default: () => [],
  },
  program: {
    type: Object,
    default: () => ({}),
  },
  roomPrices: {
    type: Object,
    default: () => ({}),
  },
  isPreSelectHousing: {
    type: Boolean,
    default: false,
  },
  showFooter: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["dismissWarning"]);
const { goToPreviousStep } = inject("steps");
const toQuestionnaire = inject("toQuestionnaire");

const sortedUnits = computed(() => {
  const preSortedUnits = (props.units ?? []).slice(0);
  if (preSortedUnits.length > 0 && (preSortedUnits[0].unit?.rooms ?? []).length > 0) {
    return preSortedUnits
      .sort((l, r) => {
        const lPrices = l.unit.rooms.map(rml => rml.student_total_price_in_cents);
        const rPrices = r.unit.rooms.map(rmr => rmr.student_total_price_in_cents);
        const lMin = Number(Math.min.apply(null, lPrices));
        const rMin = Number(Math.min.apply(null, rPrices));
        return (lMin < rMin ? -1 : 1);
      })
      // Filter active units
      .filter(u => u.unit.status === "Active")
      // Filter active units with at least one active room
      .filter(u => {
        const activeRooms = u.unit.rooms?.filter(r => r.status === "Active");
        return activeRooms && activeRooms.length > 0;
      });
  }
  return preSortedUnits;
});

const shouldKeepNextButtonDisabled = computed(() => 
  !!props?.defaultRoom?.id && props?.selectedRooms?.length < 1
);
</script>
