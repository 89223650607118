import store from "@/store";
import { computed } from "vue";
import {
  isExclusivelyParticipant,
  hasOrdersReadPermission,
  hasOrdersWritePermission,
} from "@/composables/authorization.js";
import {
  VIZ_RESEARCH_URL,
  VIZ_CURRICULUM_INTEGRATION_URL,
  VIZ_HOME_URL,
  VIZ_MY_STUDENTS_URL,
  VIZ_CAREER_AND_SKILLS_URL,
  VIZ_FORMS_URL,
  VIZ_ORDERS_ACTUAL_HOUSING_BOOKINGS_REPORT,
  VIZ_ORDERS_EXCURSIONS_INTERNATIONAL_REPORT,
  VIZ_ORDERS_HOUSING_INTERNATIONAL_REPORT,
  VIZ_ORDERS_MISCELLANEOUS_PRODUCTS_REPORT,
  VIZ_ORDERS_REPORT,
} from "@/constants.js";

export const entityId = computed(() => {
  return store.getters.getEntitiesFromProfile?.[0];
});

export const researchVizSource = computed(() =>
  !isExclusivelyParticipant.value ? VIZ_RESEARCH_URL : ""
);

export const curriculumIntegrationVizSource = computed(() =>
  !isExclusivelyParticipant.value ? VIZ_CURRICULUM_INTEGRATION_URL : ""
);

export const homeVizSource = computed(() =>
  !isExclusivelyParticipant.value ? VIZ_HOME_URL : ""
);

export const myStudentsVizSource = computed(() =>
  !isExclusivelyParticipant.value ? VIZ_MY_STUDENTS_URL : ""
);

export const careerAndSkillsVizSource = computed(() =>
  !isExclusivelyParticipant.value ? VIZ_CAREER_AND_SKILLS_URL : ""
);

export const formsVizSource = computed(() =>
  !isExclusivelyParticipant.value ? VIZ_FORMS_URL : ""
);

export const ordersReportSource = computed(() =>
  hasOrdersReadPermission.value ? VIZ_ORDERS_REPORT : ""
);

export const ordersMiscellaneousProductsReportSource = computed(() =>
  hasOrdersWritePermission.value ? VIZ_ORDERS_MISCELLANEOUS_PRODUCTS_REPORT : ""
);

export const ordersActualHousingBookingsReportSource = computed(() =>
  hasOrdersWritePermission.value
    ? VIZ_ORDERS_ACTUAL_HOUSING_BOOKINGS_REPORT
    : ""
);

export const ordersHousingInternationalReportSource = computed(() =>
  hasOrdersWritePermission.value ? VIZ_ORDERS_HOUSING_INTERNATIONAL_REPORT : ""
);

export const ordersExcursionsInternationalReportSource = computed(() =>
  hasOrdersWritePermission.value
    ? VIZ_ORDERS_EXCURSIONS_INTERNATIONAL_REPORT
    : ""
);
