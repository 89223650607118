<template>
  <button
    :id="buttonId"
    class="font-normal text-gray-600 whitespace-no-wrap border-t-4 border-transparent block flex flex-col items-center px-2 py-2 md:px-6 hover:no-underline focus:outline-none"
    role="tab"
    :aria-controls="titleKebab"
    :aria-selected="[selected ? true : false]"
    :class="[
      selected ? activeTabClass : inactiveTabClass,
      tabactive ? tabCanBeSelectedClasses : tabCantBeSelectedClasses,
    ]"
    @click="selectTab(tabindex)"
  >
    <span class="hidden font-semibold md:block">
      <span v-if="tabactive === true"> {{ count }}</span>
      <span v-else class="relative">
        <img
          src="../../assets/images/coming_soon_text.svg"
          alt=""
          class="relative block m-auto w-6"
        />
      </span>
    </span>
    <span class="text-sm"> {{ title }} </span>
  </button>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    tabactive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabCanBeSelectedClasses:
        "hover:text-university-primary hover:bg-blue-300",
      tabCantBeSelectedClasses: "cursor-default",
      activeTabClass:
        "bg-white border-university-primary text-university-primary",
      inactiveTabClass: "border-transparent hover:border-transparent",
    };
  },
  computed: {
    buttonId() {
      return this.title.replace(/\s+/g, "-").toLowerCase();
    },
    titleKebab() {
      return this.buttonId + "-tab";
    },
  },
  methods: {
    ...mapMutations("university", ["setCurrentTab"]),
    selectTab(tabIndex) {
      if (this.tabactive) {
        this.setCurrentTab({ index: tabIndex, clean: true });
      }
    },
  },
};
</script>
