<template>
  <section>
    <FormPanel
      :id="'pre-departure2'"
      :title="getPanelTitle"
      :initially-expanded="panelExpanded"
      main-panel-class="bg-white sm:mt-4 sm:rounded-lg sm:shadow-md"
      panel-content-class="grid grid-cols-1 pb-6 mb-6 border-b border-gray-200 px-4"
      panel-button-text-class="text-lg capitalize xs:tracking-normal"
      main-header-class="text-lg md:text-xl capitalize py-4 px-8 border-none"
      data-testid="pre-departure-section"
      @panel-expanded-event="setExpanded"
    >
      <template #panelHeaderCheckbox>
        <FormCounter
          v-if="uiDisplay"
          :is-open="panelExpanded"
          :total="onboardingStep2Forms.totalForms"
          :submitted="onboardingStep2Forms.submittedForms"
          :completed="completedCounter"
        />
      </template>
      <template #content>
        <div class="flex flex-col md:grid grid-cols-3">
          <div class="col-span-2 grid sm:grid-cols-2">
            <component
              :is="item.component"
              v-for="(item, index) in preDepartureForms"
              :key="index"
              class="flex flex-col justify-start items-start"
              :application-id="$route.params.applicationId"
              :program-details="programDetails"
              :show-housing-questionnaire="showHousingQuestionnaire"
              :form-submissions="formSubmissions"
            >
            </component>
          </div>
          <div>
            <div
              id="onboarding2-countdown"
              class="flex flex-row justify-start items-start px-4 mt-4 md:mt-0"
            >
              <!-- countdown component -->
              <DateCountdown
                v-if="showStep2Counter && !completedFirstSection"
                :application-deadline="formatDeadline"
                :is-large-icon="false"
                :is-forms-completion-count-down="true"
              />
            </div>
          </div>
        </div>
        <TravelSection
          :program-details="programDetails"
        />
      </template>
    </FormPanel>
  </section>
</template>
<script>
import FormPanel from "../forms/SharedComponents/panel.vue";
import { mapState } from "vuex";
import forms from "../../mixins/forms";
import CodeOfConductCard from "../codeOfConduct/CodeOfConductCard.vue";
import ParsonsParisAgreementCard from "../parsonsParisAgreement/ParsonsParisAgreementCard.vue";
import AcademicInformationCard from "../academicInformation/AcademicInformationCard.vue";
import HousingCard from "../Housing/HousingCard.vue";
import HealthWellnessCard from "../HealthWellness/HealthWellnessCard.vue";
import ParticipantAgreementCard from "../agreements/participant-agreement-card.vue";
import PolimodaPrivacyPolicyCard from "../polimodaPrivacyPolicy/PolimodaPrivacyPolicyCard.vue";
import NetworkOfSupportCard from "../NetworkOfSupport/NetworkOfSupportCard.vue";
import CoursesCard from "../forms/CourseSelection/CoursesCard.vue";
import PassportsCard from "../forms/Passport/PassportsCard.vue";
import HeadshotCard from "../forms/HeadShot/HeadshotCard.vue";
import BadgeCard from "@/components/forms/BadgesInterests/BadgeCard.vue";
import FormCounter from "../forms/SharedComponents/FormCounter.vue";
import CPCodeOfConduct from "@/components/codeOfConduct/CustomizedCodeOfConductCard.vue";
import SupplementalCard from "../forms/Supplemental/SupplementalCard.vue";
import ParticipantAgreementINDCard from "../forms/ParticipantAgreementIND/ParticipantAgreementCard.vue";
import ParticipantAgreementIDCard from "../forms/ParticipantAgreementID/ParticipantAgreementCard.vue";
import NoInsuranceDirectPay from "../forms/ParticipantAgreement/NoInsuranceDirectPay.vue";
import AgreementNoInsuranceOrPayCard from "@/components/agreements/customized/NoInsuranceOrPayCard.vue";
import DateCountdown from "../DateCountdown.vue";
import { endOfDay, format, getTime, subDays } from "date-fns";
import TravelSection from "./TravelSection.vue";
import HSCodeOfConductCard from "@/components/forms/HSCodeOfConduct/HSCodeOfConductCard.vue";
import HousingQuestionnaireCard from "@/components/forms/HousingQuestionnaire/HousingQuestionnaireCard.vue";
import TravelPlans from "../travels/travel-plans/travel-plans-card.vue";

export default {
  name: "PreDepartureSection",
  components: { FormPanel, FormCounter, DateCountdown, TravelSection },
  mixins: [forms],
  props: {
    programDetails: {
      type: Object,
      default() {
        return {};
      },
    },
    showHousingQuestionnaire: {
      type: Boolean,
      default: false,
    },
    formSubmissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
      panelExpanded: true,
      completedCounter: 0,
      formsCompletedFirstSection: 0,
      formsCompletedSecondSection: 0,
    };
  },
  computed: {
    ...mapState([
      "uiVersion",
      "program",
      "onboardingStep2Forms",
      "enrollmentApplications",
    ]),
    showStep2Counter() {
      if (this.program.enrollment_deadline) {
        return true;
      }
      return this.startDateMinus30 > this.applicationDeadline;
    },
    applicationDeadline() {
      //returns date in milliseconds
      const date =
        this.program.application_extension_deadline ??
        this.program.application_deadline;
      return getTime(endOfDay(new Date(date)));
    },
    startDateMinus30() {
      //returns date in milliseconds
      return getTime(subDays(endOfDay(new Date(this.program.start_date)), 30));
    },
    startDateMinus60() {
      //returns date in milliseconds
      return getTime(subDays(endOfDay(new Date(this.program.start_date)), 60));
    },
    formatDeadline() {
      if (this.program.enrollment_deadline) {
        return this.program.enrollment_deadline;
      }
      //due date = start date - 60 days
      if (
        this.program.start_date &&
        this.startDateMinus60 > this.applicationDeadline
      ) {
        return format(this.startDateMinus60, "yyyy-MM-dd");
      }

      //due date = start date - 30 days
      if (
        this.program.start_date &&
        this.startDateMinus30 > this.applicationDeadline
      ) {
        return format(this.startDateMinus30, "yyyy-MM-dd");
      }
      return "";
    },
    getPanelTitle() {
      if (
        this.uiVersion &&
        (this.uiVersion === "v2" || this.uiVersion === "v3")
      ) {
        if (this.program.form_rules.length <= 0) {
          return "Onboarding";
        }
        return "Onboarding Step 2";
      }
      return "Pre-departure";
    },
    preDepartureForms() {
      //add the component for the form link that should display in this section
      //make sure to run the logic to show or hide the component inside of its self
      const list = [
        {
          component: CodeOfConductCard,
        },
        {
          component: ParsonsParisAgreementCard,
        },
        {
          component: AcademicInformationCard,
        },
        {
          component: HousingCard,
        },
        {
          component: HealthWellnessCard,
        },
        {
          component: ParticipantAgreementCard,
        },
        {
          component: PolimodaPrivacyPolicyCard,
        },
        {
          component: NetworkOfSupportCard,
        },
        {
          component: CoursesCard,
        },
        {
          component: PassportsCard,
        },
        {
          component: HeadshotCard,
        },
        {
          component: BadgeCard,
        },
        {
          component: CPCodeOfConduct,
        },
        {
          component: SupplementalCard,
        },
        {
          component: ParticipantAgreementINDCard,
        },
        {
          component: ParticipantAgreementIDCard,
        },
        {
          component: NoInsuranceDirectPay,
        },
        {
          component: AgreementNoInsuranceOrPayCard,
        },
        {
          component: HSCodeOfConductCard,
        },
        {
          component: HousingQuestionnaireCard,
        },
        {
          component: TravelPlans,
        },
      ];

      return list.filter(
        (item) =>
          !(this.uiVersion === "v3" && item.component.name === "HousingCard")
      );
    },
    getFormsStatus() {
      let application = this.enrollmentApplications.filter((item) => {
        return item.application_id === this.$route.params.applicationId;
      });
      return application.length > 0 && application[0].enrollment_form_status
        ? JSON.parse(application[0].enrollment_form_status)
        : "";
    },
    uiDisplay() {
      return this.uiVersion === "v2" || this.uiVersion === "v3";
    },
    formsFirstSection() {
      return Object.hasOwnProperty.call(this.onboardingStep2Forms, "forms")
        ? this.onboardingStep2Forms.forms.filter((form) => !form.travelSection)
        : [];
    },
    formsSecondSection() {
      return Object.hasOwnProperty.call(this.onboardingStep2Forms, "forms")
        ? this.onboardingStep2Forms.forms.filter((form) => form.travelSection)
        : [];
    },
    completedFirstSection() {
      return (
        this.formsFirstSection &&
        this.formsFirstSection.length === this.formsCompletedFirstSection
      );
    },
    completedSecondSection() {
      return (
        this.formsSecondSection &&
        this.formsSecondSection.length === this.formsCompletedSecondSection
      );
    },
  },
  watch: {
    onboardingStep2Forms: {
      handler: function () {
        this.setUp();
      },
      deep: true,
    },
  },
  created() {
    this.setUp();
  },
  methods: {
    setExpanded(isExpanded) {
      this.panelExpanded = isExpanded;
    },
    setUp() {
      //get the form statuses from enrollment service
      const keys = Object.keys(this.getFormsStatus);
      this.completedCounter = 0;
      this.formsCompletedFirstSection = 0;
      this.formsCompletedSecondSection = 0;
      //set number of completed forms
      if (
        Object.keys(this.onboardingStep2Forms).length !== 0 &&
        this.onboardingStep2Forms.constructor === Object
      ) {
        Object.values(this.onboardingStep2Forms.forms).forEach((val) => {
          let rule = ""; // the form rule we are checking
          //get the form_rule that exists on the enrollment statuses
          if (Array.isArray(val.ruleName)) {
            //loop thru each value to find the one that exist in the enrollment status array
            for (let x = 0; x < val.ruleName.length; x++) {
              rule = keys.includes(val.ruleName[x]) ? val.ruleName[x] : "";
              if (rule) {
                //we found a match, exit the loop
                break;
              }
            }
          } else {
            //when we pass a single value check if it exists in the enrrollment status array
            if (val.ruleName === "Network of Support") {
              let item = keys.find((key) => key.includes("Network of Support"));
              rule = item ? item : "";
            } else if (val.ruleName === "Visa Upload") {
              //added mapping bc the status that comes from salesforce is named differently
              rule = keys.includes("Visa Permit") ? "Visa Permit" : "";
            } else if (val.ruleName === "Group Visa Information") {
              //added mapping bc the status that comes from salesforce is named differently
              rule = keys.includes("Group Visa") ? "Group Visa" : "";
            } else {
              rule = keys.includes(val.ruleName) ? val.ruleName : "";
            }
          }
          if (rule) {
            const formStatus = this.getFormsStatus[rule];
            //if we do have a rule that matches on int the enrollment status, check if it has been marked as submitted in salesforce
            if (formStatus === "Complete") this.completedCounter++;

            if (formStatus === "Complete" || formStatus === "Submitted") {
              if (val.travelSection) this.formsCompletedSecondSection++;
              else this.formsCompletedFirstSection++;
            }
          }

          /*
            If travel plan is marked as submited on onboardingStep2Forms
            and Student Travel Arrangements form rule doesnt exit on post_acceptance_form_rules
            marked the form as completed
          */
          const hasTravelArrangementRule = this.program.post_acceptance_form_rules.includes(
            "Student Travel Arrangements"
          );
          if (
            val.label === "Travel Plans" &&
            val.submitted &&
            !hasTravelArrangementRule
          ) {
            this.completedCounter++;
            this.formsCompletedSecondSection++;
          }
        });
      }

      //collapse panel on all forms completed
      if (
        !_.isEmpty(this.onboardingStep2Forms) &&
        this.completedCounter === this.onboardingStep2Forms.totalForms
      ) {
        this.setExpanded(false);
      }
    },
  },
};
</script>
