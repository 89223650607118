import { learningServiceRequest, skip } from "./utils";
import { LEARNING_SERVICE_QUERY_LIMIT } from "@/constants";

const amenitiesService = {
  async getAmenities(
    limit = LEARNING_SERVICE_QUERY_LIMIT,
    page = 1,
    q = "",
    sort = "ASC"
  ) {
    return await learningServiceRequest({
      method: "get",
      url: "/amenities",
      params: { limit, skip: skip(page, limit), q, order: sort },
    });
  },
  async getAmenitiesById(amenityId) {
    return await learningServiceRequest({
      method: "get",
      url: `/amenities/${amenityId}`,
    });
  },
};

export default amenitiesService;
