import { profileServiceRequest, skip } from "./utils";
import { DEFAULT_LIMIT_PER_PAGE } from "@/constants";

const ProfileTrackingModel = {
  utm_id: '',
  utm_campaign: '',
  utm_content: '',
  utm_medium: '',
  utm_source: '',
  utm_term: ''
}

const profileService = {
  async getProfiles({ limit = 10, page = 1, q = "", extraParams = {} }) {
    return await profileServiceRequest({
      method: "get",
      url: "/profiles",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      failureMessage: "Error loading Profile data",
    });
  },
  async getLimitedProfiles({ extraParams = {} }) {
    return await profileServiceRequest({
      method: "get",
      url: "/profiles/limited/",
      params: {
        ...extraParams,
      },
      failureMessage: "Error loading Profile data",
    });
  },
  async getProfile(oktaId) {
    return await profileServiceRequest({
      method: "get",
      url: `/profiles/details/${oktaId}`,
      failureMessage: "Error loading Profile data",
    });
  },
  async createProfile(payload) {
    return await profileServiceRequest({
      method: "post",
      url: "/profiles/create",
      payload: payload,
    });
  },
  async updateProfile(oktaId, payload) {
    return await profileServiceRequest({
      method: "put",
      url: `/profiles/update/${oktaId}`,
      payload: payload,
    });
  },
  async updatePartiallyProfile(profileId, payload) {
    return await profileServiceRequest({
      method: "patch",
      url: `/profiles/update/${profileId}`,
      payload: payload,
    });
  },
  async getFinancialAid(applicationId, profileId) {
    return await profileServiceRequest({
      method: "get",
      url: `/profiles/financial_aid/${applicationId}/${profileId}`,
    });
  },
  async createFinancialAid(payload) {
    const id = payload?.okta_id ?? payload?.profile_id;
    return await profileServiceRequest({
      method: "post",
      url: `/profiles/financial_aid/${id}`,
      payload: {
        ...payload,
        profile_id: id,
      },
    });
  },
  async toggleFinancialAid(applicationId, payload) {
    const id = payload?.okta_id ?? payload?.profile_id;
    return await profileServiceRequest({
      method: "put",
      url: `/profiles/financial_aid/${applicationId}/${id}`,
      payload: {
        ...payload,
        profile_id: id,
      },
    });
  },
  async getSubmissionsByOrder(orderId, extraParams = {}) {
    return await profileServiceRequest({
      method: "get",
      url: `/onboarding/submissions/order/${orderId}`,
      params: {
        ...extraParams,
      },
    });
  },
  async createSubmission(payload) {
    return await profileServiceRequest({
      method: "post",
      url: `/onboarding/submissions`,
      payload: payload,
    });
  },
  async updateSubmission(questionnaireId, payload) {
    return await profileServiceRequest({
      method: "put",
      url: `/onboarding/submissions/${questionnaireId}`,
      payload: payload,
    });
  },
  async getOnboardingRequiredTodos(oktaId, applicationId) {
    return await profileServiceRequest({
      method: "get",
      url: `/onboarding/required_todos/${oktaId}/${applicationId}`,
    });
  },
  async getProfileComments({
    limit = DEFAULT_LIMIT_PER_PAGE,
    page = 1,
    q = "",
    extraParams = {},
    skipErrorBus,
  }) {
    return await profileServiceRequest({
      method: "get",
      url: `/profiles/comments/`,
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      skipErrorBus,
      failureMessage: "Error loading profile comments data",
    });
  },
  async createProfileComment({ payload = {}, skipErrorBus }) {
    return await profileServiceRequest({
      method: "post",
      url: "/profiles/comments",
      payload: payload,
      skipErrorBus,
    });
  },
  async updateProfileComment({ payload = {}, skipErrorBus }) {
    return await profileServiceRequest({
      method: "put",
      url: `/profiles/comments`,
      payload: payload,
      skipErrorBus,
    });
  },
  async getProfileDevices({ limit = 10, page = 1, extraParams = {} }) {
    return await profileServiceRequest({
      method: "get",
      url: "/profiles/devices",
      params: {
        limit,
        skip: skip(page, limit),
        ...extraParams,
      },
      failureMessage: "Error loading Profile devices data",
    });
  },
  async createSubmissionsPlatform({ payload = {}, skipErrorBus }) {
    return await profileServiceRequest({
      method: "post",
      url: `/onboarding/platform`,
      payload: payload,
      skipErrorBus,
    });
  },
  /**
   * Add UTM Marketing tracking to a Profile
   *
   * @param {string} oktaId Users Okta Id
   * @param {ProfileTrackingModel} payload UTM Tracking Information
   * @param {boolean} skipErrorBus
   * @returns
   */
  async addProfileTracking(oktaId = '', payload = ProfileTrackingModel, skipErrorBus = false) {
    return await profileServiceRequest({
      method: "post",
      url: `/profiles/track/${oktaId}`,
      payload: payload,
      skipErrorBus: skipErrorBus,
    });
  }
};

export default profileService;
