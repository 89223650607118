<script setup>
import {
  computed,
  defineProps,
  defineEmits,
  reactive,
  toRefs,
  ref,
  watch,
} from "vue";
import { watchDebounced } from "@vueuse/core";
import { debounce } from "lodash";
import { useGetBeds } from "@/components/StudentFulfillment/composables/beds";
import { getCityCountryOptionLabel } from "@/components/program-manager/sessions/utils";
import { useGetCityOptions } from "@/components/program-manager/sessions/composable";
import { convertBedsFilterCriteria } from "@/components/StudentFulfillment/components/modals/BedFulfillmentModal/utils";
import {
  HOUSING_TYPES,
  TIERS,
  TIERS_OBJ,
  ROOM_TYPES_OPTIONS,
  ALLOWED_PROGRAM_TYPES_OPTIONS,
  SESSION_TYPES_NAMES,
  CANCEL,
} from "@/constants";
import { READ_ONLY_FILTERS } from "@/components/StudentFulfillment/components/modals/BedFulfillmentModal/constants";
import { columnsConfig } from "@/components/StudentFulfillment/components/modals/BedFulfillmentModal/constants";
import DefaultFulfillmentModal from "@/components/StudentFulfillment/components/modals/DefaultFulfillmentModal";
import Magnifier from "@/components/shared/icons/Magnifier";
import CheckboxSelect from "@/components/shared/select/CheckboxSelect.vue";

const emit = defineEmits(["closeModal", "updateSelectedItems"]);

const filterLabelMap = {
  types: ({ type = "" }) => type,
  cities: ({ city_ascii = "" }) => city_ascii,
  tiers: ({ label = "" }) => label,
};

const readOnlyFilters = READ_ONLY_FILTERS;

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    default: false,
  },
  session: {
    type: Object,
    required: true,
    default: () => ({
      session_id: undefined,
      city_id: undefined,
      city: undefined,
      session_types: [],
      arrival_date: undefined,
      departure_date: undefined,
    }),
  },
  housing: {
    type: Object,
    required: true,
  },
  orderRoomTypes: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const { isModalOpen, session, housing, orderRoomTypes } = toRefs(props);

const isModalReady = ref(false);

const pagination = reactive({
  limit: 10,
  page: 1,
});

const filters = reactive({
  text: undefined,
  extra: {
    cities: [],
    allowed_program_types: [],
    date: [],
    types: [],
    tiers: [],
    arrival_date: undefined,
    departure_date: undefined,
    room_types: [],
  },
});

const state = computed(() => ({
  loading: bedsIsLoading.value || cityLoading.value,
  ready: bedsIsReady.value && cityIsReady.value,
}));

const {
  execute: fetchBedsOptions,
  isLoading: bedsIsLoading,
  state: bedsOptions,
  isReady: bedsIsReady,
} = useGetBeds({ immediate: false, throwError: false }, true);

const {
  execute: executeFetchCityOptions,
  isLoading: cityLoading,
  state: cityOptions,
  isReady: cityIsReady,
} = useGetCityOptions({ immediate: false, throwError: true }, true);

const fetchCityOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    loading(true);
    try {
      await executeFetchCityOptions(0, {
        city_ascii: search,
        cancel: true,
      });
      loading(false);
    } catch (e) {
      if (e?.message !== CANCEL) loading(false);
    }
  }
}, 0);

const callFetchBedsOptions = () => {
  fetchBedsOptions(0, {
    q: filters.text,
    extraParams: convertBedsFilterCriteria(filters),
    limit: pagination.limit,
    page: pagination.page,
    cancel: true,
  });
};

const closeModal = () => {
  bedsOptions.value = undefined;
  emit("closeModal", true);
};

const updateSelectedItems = (items) => {
  emit("updateSelectedItems", items);
};

const changePage = ({ limit, page }) => {
  pagination.limit = limit;
  pagination.page = page;
  callFetchBedsOptions();
};

const handleSearch = (search) => (filters.text = search);

const clearFilters = () => {
  filters.extra.cities = [];
  filters.extra.types = [];
  filters.extra.tiers = [];
  filters.extra.dates = [];
  filters.extra.room_types = [];
};

watch(isModalOpen, (open) => {
  isModalReady.value = false;
  if (open) {
    executeFetchCityOptions(0, {
      city_ascii: session?.value?.city ?? "chi",
    });
    clearFilters();

    const housingTypes = housing?.value?.type
      ? [{ type: housing.value.type }]
      : [];

    const housingTiers = housing.value?.tier?.id
      ? [
          {
            id: housing.value?.tier?.id,
            name: housing.value?.tier?.name,
            label: TIERS_OBJ[housing.value?.tier?.name],
          },
        ]
      : [];

    const sessionTypesOptions = session.value?.session_types;
    const allowedProgramTypesValues = ALLOWED_PROGRAM_TYPES_OPTIONS.filter(
      (item) => {
        if (
          (item?.label === SESSION_TYPES_NAMES.custom &&
            sessionTypesOptions?.includes(SESSION_TYPES_NAMES.faculty_led)) ||
          (item?.label === SESSION_TYPES_NAMES.internship &&
            sessionTypesOptions?.includes(SESSION_TYPES_NAMES.internship)) ||
          (item?.label === SESSION_TYPES_NAMES.study_abroad &&
            sessionTypesOptions?.includes(SESSION_TYPES_NAMES.study))
        ) {
          return item;
        }
      }
    );

    filters.extra.types = housingTypes;
    filters.extra.tiers = housingTiers;
    filters.extra.room_types = orderRoomTypes?.value;
    filters.extra.arrival_date = session?.value?.arrival_date;
    filters.extra.departure_date = session?.value?.departure_date;
    filters.extra.allowed_program_types = allowedProgramTypesValues;
    isModalReady.value = true;
  }
});

watch(cityOptions, (cities) => {
  if (cities) {
    const city = cities?.items?.find(
      (city) => city?.id === session?.value?.city_id
    );
    if (city) {
      filters.extra.cities.push(city);
    }
  }
});

watchDebounced(
  () => filters,
  () => {
    if (isModalReady.value) callFetchBedsOptions();
  },
  { immediate: false, debounce: 1000, deep: true }
);
</script>

<template>
  <div>
    <DefaultFulfillmentModal
      :is-modal-open="isModalOpen"
      :state="state"
      :data="{
        columns: columnsConfig,
        rows: bedsOptions?.data ?? [],
        count: bedsOptions?.count ?? 0,
      }"
      :filters="filters"
      :filter-label-map="filterLabelMap"
      :read-only-filters="readOnlyFilters"
      @close-modal="closeModal"
      @search-data="handleSearch"
      @clear-filters="clearFilters"
      @change-page="changePage"
      @update-selected-items="updateSelectedItems"
    >
      <template #header>
        <h1
          class="flex justify-center text-xl leading-tight text-center sm:text-2xl md:text-3xl"
        >
          Confirm Housing
        </h1>
      </template>
      <template #filters>
        <div class="flex bg-blue-450 rounded-md ml-2 flex-wrap flex-0">
          <CheckboxSelect
            v-model="filters.extra.cities"
            :close-on-select="false"
            :components="{ OpenIndicator: Magnifier }"
            :deselect-from-dropdown="true"
            :filterable="false"
            label="city_ascii"
            :multiple="true"
            :get-option-label="getCityCountryOptionLabel"
            :options="cityOptions?.items || []"
            :loading="cityLoading || !cityIsReady"
            outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
            placeholder="City"
            scroll-input="auto"
            input-max-height="29px"
            @search="fetchCityOptions"
          />
          <CheckboxSelect
            v-model="filters.extra.types"
            :close-on-select="false"
            :components="{ OpenIndicator: Magnifier }"
            :deselect-from-dropdown="true"
            label="type"
            :multiple="true"
            option-key="type"
            :options="HOUSING_TYPES"
            outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
            placeholder="Housing Type"
            scroll-input="auto"
            input-max-height="29px"
          />
          <CheckboxSelect
            v-model="filters.extra.tiers"
            :close-on-select="false"
            :components="{ OpenIndicator: Magnifier }"
            :deselect-from-dropdown="true"
            label="label"
            :multiple="true"
            option-key="name"
            :options="TIERS"
            outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
            placeholder="Housing Tier"
            scroll-input="auto"
            input-max-height="29px"
          />
          <CheckboxSelect
            v-model="filters.extra.room_types"
            :close-on-select="false"
            :components="{ OpenIndicator: Magnifier }"
            :deselect-from-dropdown="true"
            label="label"
            :multiple="true"
            option-key="label"
            :options="ROOM_TYPES_OPTIONS"
            outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
            placeholder="Room Type"
            scroll-input="auto"
            input-max-height="29px"
          />
        </div>
      </template>
    </DefaultFulfillmentModal>
  </div>
</template>
