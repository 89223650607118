<template>
  <div>
    <div
      id="process-steps"
      data-testid="process-steps"
      class="flex justify-between p-6 w-full border-collapse bg-white md:p-0"
    >
      <template v-for="(step, index) in steps" :key="step.slug">
        <process-step
          v-bind="step"
          :current-step="currentStep"
          :last-step-slug="lastStepSlug"
          :index="index"
          :data-testid="step.slug + '-configurator-breadcrumb'"
          @set-current-step="(slug) => emit('setCurrentStep', slug)"
        />
        <hr
          v-if="index < steps.length - 1"
          class="inline-block border-dark-gray my-auto mx-1 border w-10 md:hidden"
        />
      </template>
    </div>
    <component :is="currentStep.component" />
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue";
import DummyComponent from "./DummyComponent.vue";
import ProcessStep from "./ProcessStep.vue";

const props = defineProps({
  steps: {
    type: Array,
    default: () => [],
  },
  currentStep: {
    type: Object,
    default: () => {
      return {
        slug: "",
        title: "",
        isSelectable: () => true,
        isComplete: () => true,
        component: DummyComponent,
      };
    },
  },
});

const emit = defineEmits(["setCurrentStep"]);

const lastStepSlug = computed(() => {
  const { steps } = props;
  const numSteps = props.steps.length;
  if (numSteps < 1) {
    return "";
  }

  return steps[numSteps - 1]?.slug || "";
});
</script>
