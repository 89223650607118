<template>
  <div class="flex items-center justify-center h-screen px-12 py-12">
    <spinner v-if="loading">
      <template #spinnercontent>
        <p class="text-gray-600">
          Loading application
        </p>
        <p>
          Something not working as expected?
        </p>
        <router-link
          :to="{
            name: 'applications',
          }"
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `text-university-secondary hover:text-university-accentSecondary focus:text-university-secondary`,
          ]"
          class="underline hover:no-underline"
        >
          Click here to go back.
        </router-link>
      </template>
    </spinner>
    <div class="w-full max-w-2xl md:w-2/3 h-full">
      <div
        v-if="isDirectProgram"
        class="w-full bg-cream-100 p-4 flex items-center justify-center rounded mb-4 relative"
        data-cy="direct-program-banner"
      >
        <p class="text-sm ml-auto">
          {{ bannerText }}
        </p>
      </div>
      <div class="flex flex-col items-center mb-10">
        <img
          class="auth-logo hidden sm:block"
          :src="logoUrl"
          alt="API Abroad"
        />
        <div id="portal-title"></div>
      </div>
      <component
        :is="currentComponent"
        :redirect-to="redirectTo"
        :email="email"
        :component-name="currentComponent"
        :is-pass-set="isPassSet"
        :token="token"
        :parent-mounted="componentMounted"
        @changeComponent="changeComponent"
        @setEmail="setEmail"
        @setPassword="setPassword"
        @logout="logout"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import { Formio } from "formiojs";
import { mapState, mapGetters } from "vuex";
import { APICompany } from "../constants";
import titleMixin from "../mixins/pageTitles";
import ChangePassForm from "./auth/ChangePassForm";
import LeadForm from "./auth/LeadForm.vue";
import ResetConfirmation from "./auth/ResetConfirmation";
import ResetForm from "./auth/ResetForm";
import SignInForm from "./auth/SignInForm";
import Spinner from "./helpers/Spinner.vue";
import { useToast } from "vue-toast-notification";
import { ERROR_TIMEOUT } from "@/constants.js";


export default {
  name: "SignIn",
  components: {
    "sign-in-form": SignInForm,
    "set-form": ResetForm,
    "reset-form": ResetForm,
    "reset-confirmation": ResetConfirmation,
    "change-password": ChangePassForm,
    "lead-form": LeadForm,
    Spinner,
  },
  mixins: [titleMixin],
  props: {
    logo: {
      type: String,
      default: localStorage.logoUrl,
    },
    redirectTo: {
      type: String,
      default: "/",
    },
    directProgramId: {
      type: String,
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentComponent: "sign-in-form",
      email: "",
      isPassSet: null,
      loading: true,
      componentMounted: false,
      logoUrl: "",
    };
  },
  computed: {
    ...mapGetters({ currentOrder: "configurator/getCurrentOrder" }),
    ...mapState(["directProgramData"]),
    isDirectProgram() {
      return !_.isEmpty(this.directProgramData);
    },
    bannerText() {
      return this.directProgramData.customized
        ? "Please create an account or log in to an existing account before continuing to apply to the " +
            this.directProgramData.programName +
            " program."
        : `We do not recognize the Program you are trying to apply to. Please reach out to productsupport${APICompany.companyEmail}for assistance.`;
    },
  },
  watch: {
    $route(to) {
      this.setComponent(to);
    },
    currentComponent: {
      handler: function (newVal) {
        this.setSignInTitle(newVal);
      },
      immediate: true,
    },
  },
  async mounted() {
    // V3 - If order has an application id, reset it. Causes duplicate applications
    if (this.currentOrder?.application_id) {
      this.$store.dispatch("configurator/resetCurrentOrder");
    }

    if (!localStorage.branding) {
      this.logoUrl =
        process.env.MIX_CDN_ENDPOINT + "/images/api-connect-logo.png";
    } else {
      this.logoUrl = localStorage.logoUrl;
    }
    //check if requesting a specific program via link
    if (this.directProgramId) {
      //set the direct program data to state
      await this.$store.dispatch("directProgram", this.directProgramId);
    } else {
      //clear state to prevent unwanted behavior
      this.$store.commit("reset");
    }
    this.componentMounted = true;
    this.setInitialComponent();
    this.loading = false;
    const toast = useToast();
        if (!localStorage.getItem("primaryColor") && localStorage.getItem("branding")) {
          toast.open({
            message: "Custom branding failed to load. Please try refreshing the page or contact support for assistance at productsupport@apiexperience.com.",
            type: "error",
            position: "bottom",
            duration: ERROR_TIMEOUT,
          });
        }
  },
  methods: {
    setInitialComponent() {
      if (this.$route.name === "change-password") {
        this.currentComponent = "change-password";
      } else {
        this.setComponent(this.$route);
      }
    },
    setComponent(route) {
      let routeComponent;
      if (route.name === "create-account" || route.name === "change-password") {
        routeComponent = route.name;
      } else {
        routeComponent = route.params.component;
      }

      switch (routeComponent) {
        case "set-password":
          this.currentComponent = "set-form";
          break;
        case "forgot-password":
          this.currentComponent = "reset-form";
          break;
        case "create-account":
          this.currentComponent = "lead-form";
          break;
        default:
          this.currentComponent = "sign-in-form";
          break;
      }
    },
    changeComponent(component) {
      this.currentComponent = component;
    },
    setEmail(email) {
      this.email = email;
    },
    setPassword(isSet) {
      this.isPassSet = isSet;
    },
    logout() {
      Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
      Formio.setAuthUrl(
        `${window.location.protocol}//${window.location.host}/api/forms`
      );
      Formio.logout();
      this.$store.dispatch("logout", {
        reload: false,
      });
    },
  },
};
</script>

<style scoped>
.auth-logo {
  max-width: 270px;
  max-height: 50px;
}
</style>
