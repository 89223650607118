<script setup>
import { onBeforeMount, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { V3_stepCanBeReturned } from "@/composables/VersionHelper.js";
import Spinner from "@/components/helpers/Spinner.vue";

const steps = reactive([
  {
    slug: "application-create-account",
    title: "AccountCreation",
  },
  {
    slug: "application-about-you",
    title: "AboutYou",
  },
  {
    slug: "application-learner-info",
    title: "LearnerInfo",
  },
  {
    slug: "application-group-visa",
    title: "GroupVisa",
  },
  {
    slug: "application-review",
    title: "Review",
  },
  {
    slug: "application-checkout",
    title: "Checkout",
  },
  {
    slug: "application-next-steps",
    title: "NextSteps",
  },
]);
const store = useStore();
const router = useRouter();
const stepStatus = reactive([]);

// Computed
const oktaId = computed(() => {
  return store.state.currentUser?.participantId ?? "";
});

const applicationId = computed(() => store.state.currentApplicationId);

const completableSteps = computed(() => {
  // Filters by steps that can actually be completed
  return stepStatus.filter((step) => step.viewable);
});

onBeforeMount(async () => {
  // Get student applications and current-user profile
  const promises = [store.dispatch("getV3Steps", applicationId.value)];
  if (oktaId.value) {
    promises.push(store.dispatch("getProfile", oktaId.value));
  }
  await Promise.allSettled(promises);

  // Array.forEach is an option too but it isn't async
  for (let i = 0; i < steps.length; i++) {
    let completed =
      store.state.stepsInV3.find((step) => step.step === steps[i]["title"])
        ?.completed ?? false;
    let canView = await V3_stepCanBeReturned(steps[i]["title"]);
    stepStatus.push({
      slug: steps[i]["slug"],
      complete: completed,
      viewable: canView,
    });
  }

  const redirect = completableSteps.value.find(
    (step) => !step.complete && step.viewable
  );
  if (redirect) {
    router.push({
      name: redirect.slug,
      params: { applicationId: applicationId.value },
    });
  } else if (completableSteps.value.every((step) => step.complete)) {
    // All steps are complete - go to next-steps
    router.push({
      name: "application-next-steps",
      params: { applicationId: applicationId.value },
    });
  } else {
    // No step found
    router.push({ name: "applications" });
  }
});
</script>

<template>
  <spinner>
    <template #spinnercontent>
      <p class="text-gray-600">
        Resuming your application, please wait.
      </p>
    </template>
  </spinner>
</template>
