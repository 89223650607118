<template>
  <DropdownList :options="getTableHeaders" :render-checkbox-list="true">
    <CogTooth class="text-teal-900" />
  </DropdownList>
</template>

<script>
import CogTooth from "../icons/CogTooth.vue";
import DropdownList from "./DropdownList.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DropdownList,
    CogTooth,
  },
  computed: {
    ...mapGetters("programManager", ["getTableHeaders"]),
  },
};
</script>
