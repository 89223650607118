<template>
  <div class="relative pt-6 sm:p-6 my-1">
    <div class="flex justify-between items-center">
      <h1 class="text-xl font-semibold leading-6 text-gray-900">
        Building Amenities
      </h1>
      <RequiredToSavePublish />
    </div>
    <section class="mt-6">
      <AmenitiesList />
    </section>
    <section class="w-full mt-5">
      <label class="font-semibold my-5">Tag</label>
      <TagsInput
        :model-value="getEditingTags"
        :options="getFlatCategoryTags"
        label="name"
        @update:model-value="updateTags"
      />
    </section>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapActions, mapGetters } from "vuex";
import RequiredToSavePublish from "../RequiredToSavePublish/index.vue";
import TagsInput from "@/components/shared/TagsInput.vue";
import AmenitiesList from "./AmenitiesList.vue";
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

export default {
  name: "BuildingAmenitiesTab",
  components: { RequiredToSavePublish, TagsInput, AmenitiesList },
  props: {
    originalHousingRecord: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate({
        $registerAs: "BuildingAmenitiesTab",
        $lazy: true,
        $autoDirty: true,
      }),
    };
  },
  data() {
    return {
      housingUpdates: {
        tags: this.originalHousingRecord.tags?.map((tag) => tag.id),
      },
    };
  },
  computed: {
    ...mapGetters("programManager", ["getCurrentEditingTags"]),
    ...mapGetters("tagManager", ["getFlatCategoryTags"]),
    getEditingTags() {
      return cloneDeep(this.getCurrentEditingTags);
    },
  },
  watch: {
    "originalHousingRecord.tags": {
      handler: function () {
        this.housingUpdates.tags = this.originalHousingRecord?.tags.map(
          (tag) => tag.id
        );
      },
    },
  },
  validations: {
    getCurrentEditingTags: {
      required: requiredIf(() => false),
    },
  },
  async created() {
    await this.fetchTagsData();
  },
  methods: {
    getUpdates() {
      return { updates: this.housingUpdates };
    },
    updateTags(tags) {
      this.setCurrentEditingTags(cloneDeep(tags));
    },
    ...mapActions("tagManager", ["fetchTagsData"]),
    ...mapActions("programManager", ["setCurrentEditingTags"]),
  },
};
</script>
