<script setup>
import DuplicateUserError from "@/components/auth/AccountCreation/DuplicateUserError.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import Checkbox from "@/components/forms/SharedComponents/DarkBlueInputs/Checkbox.vue";
import DateField from "@/components/forms/SharedComponents/DarkBlueInputs/DateField.vue";
import PhoneField from "@/components/forms/SharedComponents/DarkBlueInputs/PhoneField.vue";
import SingleSelect from "@/components/forms/SharedComponents/DarkBlueInputs/SingleSelect.vue";
import TextField from "@/components/forms/SharedComponents/DarkBlueInputs/TextField.vue";
import programSessionsService from "@/services/programSessions.js";
import { checkForExistingLeads } from "@/composables/duplicateUser.js";
import { learnerTypesCustomOrder } from "@/constants.js";
import { trackEvent } from "@/util/eventTracker.js";
import {
  minDateValidator,
  validEmailAddress,
  validPhoneNumber,
} from "@/mixins/customValidators";
import { entityTypes } from "@/components/program-manager/sessions/constants.js";
import entitiesService from "@/services/entities.js";
import { getApiClient } from "@/services/API";
import learnerTypesServices from "@/services/learnerTypes.js";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  maxLength,
  required,
  requiredIf,
  sameAs,
} from "@vuelidate/validators";
import axios from "axios";
import { format, isFuture, isValid, parse } from "date-fns";
import {
  computed,
  defineEmits,
  defineProps,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useProgramSelectionData } from "@/composables/useProgramSelectionData.js";
import { useRoute, useRouter } from "vue-router";
import { useNotifications } from "@/composables/useNotifications";
import billingAgreement from "@/composables/useBillings.js";
import enrollmentService from "@/services/enrollment.js";
import { GAP_YEAR_STUDENT, requiredSchool } from "@/constants";

const props = defineProps({
  orderFailed: {
    type: Boolean,
    default: false,
  },
});

/**
 * Validations
 */
const { sendNotifications } = useNotifications();
const store = useStore();
const route = useRoute();
const router = useRouter();
const program = computed(() => store.getters["configurator/getCurrentProgram"]);
const dateErrorCopy = "Invalid birthday. Please use calendar.";
const emailErrorCopy =
  "Email does not have proper formatting. Please try again.";
const requiredErrorCopy = "This field is required.";
const notFutureDate = (value) => !isFuture(new Date(value));
// Passwords must be 12 characters long and contain at least one number, one lower case letter, and one upper case letter
const passwordRegex = helpers.regex(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}/);
const contracts = ref([]);
const form = reactive({
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  learnerType: null,
  school: null,
  isValidPhoneNumber: true,
  phoneCode: "",
  phone: "",
  birthday: "",
  eula: false,
  programSession: null,
  applicationId: null,
});
const rules = {
  firstName: {
    required: helpers.withMessage(requiredErrorCopy, required),
    maxLength: helpers.withMessage(
      ({ $params }) => `Name must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },
  lastName: {
    required: helpers.withMessage(requiredErrorCopy, required),
    maxLength: helpers.withMessage(
      ({ $params }) => `Name must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },
  email: {
    email: helpers.withMessage(emailErrorCopy, email),
    validEmailAddress: helpers.withMessage(emailErrorCopy, validEmailAddress),
    required: helpers.withMessage(requiredErrorCopy, required),
  },
  password: {
    required: helpers.withMessage(requiredErrorCopy, required),
    passwordRegex: helpers.withMessage(
      "Password does not meet requirements. Please try again.",
      passwordRegex
    ),
  },
  learnerType: { required: helpers.withMessage(requiredErrorCopy, required) },
  school: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => requiredSchool.includes(form.learnerType?.id))
    ),
  },
  isValidPhoneNumber: {},
  phoneCode: {},
  phone: {
    required: helpers.withMessage(requiredErrorCopy, required),
    validPhoneNumber: helpers.withMessage(
      "Invalid phone number. Please try again.",
      validPhoneNumber
    ),
  },
  birthday: {
    required: helpers.withMessage(requiredErrorCopy, required),
    isValidMinDate: helpers.withMessage(
      dateErrorCopy,
      minDateValidator("1940")
    ),
    notFutureDate: helpers.withMessage(dateErrorCopy, notFutureDate),
  },
  eula: { sameAs: helpers.withMessage(requiredErrorCopy, sameAs(true)) },
  programSession: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => route.params?.sessionId)
    ),
  },
  applicationId: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => route.params?.sessionId)
    ),
  },
};
const v$ = useVuelidate(rules, form);
const contractNumber = computed(() => {
  return store.state.appliedAgreement?.contract_number ?? "";
});
const sessionV3Data = computed(() => {
  return store.state.programSessionDataV3;
});

/**
 * Inputs
 */
const updatePhone = (data) => {
  form.isValidPhoneNumber = data.isValid;
  form.phone = data?.nationalNumber;
  form.phoneCode = data?.countryCallingCode;
};

const learnerTypeOptions = ref([]);

const getLearnerTypes = async () => {
  try {
    const response = await learnerTypesServices.list({ limit: 50 });
    learnerTypeOptions.value = response?.items ?? [];

    learnerTypeOptions.value.sort((a, b) => {
      const indexA = learnerTypesCustomOrder.indexOf(a.id);
      const indexB = learnerTypesCustomOrder.indexOf(b.id);

      if (indexA === -1 && indexB === -1) {
        return 0;
      } else if (indexA === -1) {
        return 1;
      } else if (indexB === -1) {
        return -1;
      } else {
        return indexA - indexB;
      }
    });
  } catch (error) {
    learnerTypeOptions.value = [];
  }
};

const resetBillingAgreements = () => {
  contracts.value = [];

  store.commit("setInstitutionPaysValue", {
    field: "applicationFee",
    value: false,
  }); // Institution doesn't pay application fee

  // Reset store
  store.commit("setAppliedAgreement", {});
};

watch(
  () => contracts.value,
  (newVal) => {
    if (newVal.length) {
      // Check if billing agreement can be applied
      const appliableContract = billingAgreement(
        contracts.value,
        sessionV3Data.value
      );
      store.commit("setInstitutionPaysValue", {
        field: "applicationFee",
        value: appliableContract?.applicationFee === "Institution",
      });

      store.commit(
        "setAppliedAgreement",
        appliableContract !== undefined ? appliableContract : {}
      );
    }
  }
);

watch(
  () => form.school,
  async (newVal) => {
    resetBillingAgreements();

    if (newVal?.ope_id) {
      // Get billing agreements
      const contracts_response = await enrollmentService.loadContracts(
        String(newVal.ope_id).padStart(8, "0")
      ); // Some ids have missing zeros, we ensure their length is 8 chars long

      if (Array.isArray(contracts_response)) {
        contracts.value = contracts_response;
      }
    }
  }
);

watch(
  () => sessionV3Data.value,
  (newVal) => {
    formData.value = createSubmissionDataForFormIo(newVal);
    form.applicationId = formData.value.data.application_id;
  },
  {
    deep: true,
  }
);

watch(
  () => form.learnerType,
  (learnerType) => {
    if (!requiredSchool.includes(learnerType?.id)) form.school = null;
  },
  { deep: true }
);

watch(
  () => props.orderFailed,
  (newVal) => {
    if (newVal) {
      creationAccountSubmit.value.stopLoading();
      submissionError.value =
        "Please try again. If this problem persists, please contact productsupport@apiexperience.com.";
    }
  }
);

const universityParams = {
  account_types: [entityTypes.home_institution],
  field: "name",
  order: "ASC",
};

const schoolOptions = ref([]);

const getSchools = async () => {
  entitiesService
    .getEntities({ extraParams: universityParams })
    .then(({ data }) => {
      schoolOptions.value = data?.data?.items ?? [];
    })
    .catch(() => (schoolOptions.value = []));
};

const searchSchools = async ({ search, loading }) => {
  loading(true);
  entitiesService
    .getEntities({ q: search, extraParams: universityParams })
    .then(({ data }) => {
      schoolOptions.value = data?.data?.items ?? [];
    })
    .catch(() => (schoolOptions.value = []))
    .finally(() => {
      loading(false);
    });
};

const utmTracking = computed(() => {
  return [{
    utm_id: route.query?.utm_id,
    utm_campaign: route.query?.utm_campaign,
    utm_content: route.query?.utm_content,
    utm_medium: route.query?.utm_medium,
    utm_source: route.query?.utm_source,
    utm_term: route.query?.utm_term
  }]
});

async function prePopulateConfiguratorData() {
  if (program.value) {
    form.learnerType = program?.value?.learnerType ?? null;
    form.school = program?.value?.school ?? null;
  }
}

onBeforeMount(async () => {
  await router.isReady();
  if (route?.params?.sessionId) {
    // Get data of program session
    let response = await programSessionsService.getProgramSession({
      id: route.params.sessionId,
      home_institution_id: program?.value?.school?.id ?? "",
    });
    if (typeof response?.data?.data?.items?.id !== "undefined") {
      form.programSession = response.data.data.items.salesforce_id;
      store.commit("setProgramSessionDataV3", response.data.data.items);
    }
  } else if (sessionV3Data.value?.salesforce_id) {
    form.programSession = sessionV3Data.value.salesforce_id;
    formData.value = createSubmissionDataForFormIo(sessionV3Data.value);
    form.applicationId = formData.value.data.application_id;
  }
});

onMounted(async () => {
  getLearnerTypes();
  prePopulateConfiguratorData();
  getSchools();
});

/**
 * Account creation
 */
const emit = defineEmits(["afterCreationAction"]);
const creationAccountSubmit = ref(null);
const isDuplicateAccount = ref(false);
const submissionError = ref("");
const { createSubmissionDataForFormIo } = useProgramSelectionData();
const formData = ref({});
const saveObject = computed(() => {
  return {
    email: form.email.toLowerCase(),
    password: form.password,
    first_name: form.firstName,
    last_name: form.lastName,
    countryCode: form.phoneCode,
    phoneNumber: form.phone,
    phone: phone.value,
    birthdate: formatBirthdate.value,
    student_type: form.learnerType?.value ?? "",
    school_name: form.school
      ? {
          label: form.school.label,
          value: form?.school?.value?.toString().padStart(8, "0") ?? "",
        }
      : {},
    school_id: form.school?.id ?? null,
    learner_type_id: form.learnerType?.id ?? "",
    programSession: form.programSession,
    applicationId: form.applicationId,
    uiVersion: "v3",
    semester: formData.value?.data?.semester ?? null,
    location: formData.value?.data?.location ?? null,
    experienceType: formData.value?.data?.experienceType ?? null,
    contractNumber: contractNumber.value,
    tracking: utmTracking.value
  };
});
const formatSchools = computed(() => {
  return schoolOptions.value.map((school) => {
    if (!Object.hasOwnProperty.call("label", school)) {
      school.label = school?.name ? school.name : school.label;
    }
    if (!Object.hasOwnProperty.call("value", school)) {
      school.value = school?.ope_id ? school.ope_id : school.value;
    }
    return school;
  });
});
const phone = computed(() => {
  let phone = form.phoneCode + form.phone;
  let numberPattern = /\d+/g;
  return phone.match(numberPattern).join("");
});
const formatBirthdate = computed(() => {
  return isValid(parse(form.birthday, "yyyy-MM-ddd", new Date()))
    ? format(parse(form.birthday, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")
    : "";
});

const createAccount = async () => {
  try {
    creationAccountSubmit.value.startLoading();
    isDuplicateAccount.value = false;
    submissionError.value = "";

    if (!v$.$invalid) {
      if (props.orderFailed) {
        emit("afterCreationAction");
      } else {
        isDuplicateAccount.value = await checkForExistingLeads(
          form.firstName,
          form.lastName,
          form.email.toLowerCase(),
          form.phoneCode + form.phone,
          form.birthday
        );

        if (isDuplicateAccount.value) creationAccountSubmit.value.stopLoading();
        else submitForm();
      }
    }
  } catch {
    creationAccountSubmit.value.stopLoading();
  }
};

const submitForm = async () => {
  try {
    localStorage.removeItem("crossCheckingProgramSession");
    await axios.post("/register", saveObject.value);
    await store.dispatch("refreshUserDataFromServer"); // Gets current user from session (Laravel)
    await store.dispatch("initialSetUp"); // Gets billing rules (Enrollment Service) and user from form.io
    store.commit("setStepCompletedV3", "AccountCreation");
    if (form.applicationId && form.programSession) {
      // Trigger notification if we have an application id and program session id
      // meaning he's a new student that just completed configurator
      sendNotifications("start", form.programSession);
    }

    trackEvent(
      "sign_up",
      "FormSubmission",
      "SubmitButton",
      store.getters?.getCurrentUser?.participantId
    );

    store.commit("setProfileSelectedObjects", {
      propertyName: "school",
      value: { ...form.school },
    });
    emit("afterCreationAction");
  } catch (e) {
    creationAccountSubmit.value.stopLoading();
    if (e?.response?.status === 500) {
      submissionError.value =
        "There was an error creating your account. Please try again.";
    } else {
      Object.values(e.response.data.errors).forEach((field) => {
        field.forEach((error) => {
          submissionError.value += error + "\n";
        });
      });
    }
  }
};

/**
 * White Labeling school
 */
const ipedsId = computed(() => {
  return localStorage.ipedsId ?? "";
});
const setSchool = async (ipeds) => {
  if (ipeds) {
    const params = { ipeds_id: ipeds };
    const response = await getApiClient().get("/home-university", { params });
    const items = response?.data?.data?.items ?? [];

    if (items.length) {
      form.learnerType = learnerTypeOptions.value.find((item) =>
        requiredSchool.includes(item.id)
      );
      form.school = {
        label: items[0].name,
        value: items[0].ope_id,
      };
    }
  }
};
watch(
  ipedsId,
  (newValue) => {
    setSchool(newValue.value);
  },
  { immediate: true }
);
watch(learnerTypeOptions, () => {
  setSchool(ipedsId.value);
});
</script>

<template>
  <div class="grid grid-cols-1 gap-y-7 pt-10">
    <p class="text-xs font-medium">
      Fields marked with (*) are required to create your account.
    </p>

    <TextField
      id="firstName"
      v-model.trim="form.firstName"
      label="Legal First Name*"
      placeholder="Enter Your First Name"
      :vuelidate-field="v$.firstName"
      :show-errors="true"
      label-test-id="legal-first-name-label"
    />

    <TextField
      id="lastName"
      v-model.trim="form.lastName"
      label="Last Name*"
      placeholder="Enter Your Last Name"
      :vuelidate-field="v$.lastName"
      :show-errors="true"
    />

    <TextField
      id="email"
      v-model.trim="form.email"
      label="Email*"
      placeholder="Enter Email"
      :vuelidate-field="v$.email"
      :show-errors="true"
    />

    <TextField
      id="password"
      v-model.trim="form.password"
      label="Password*"
      placeholder="Enter Password"
      type="password"
      :vuelidate-field="v$.password"
      :show-errors="true"
    >
      <template #help-content>
        <div class="pt-2 text-sm">
          <p>Passwords must have:</p>
          <ul class="ml-8 list-disc">
            <li>at least 12 characters</li>
            <li>one number</li>
            <li>lower and upper case letters</li>
          </ul>
        </div>
      </template>
    </TextField>

    <SingleSelect
      id="learnerType"
      v-model.trim="form.learnerType"
      label="What best describes you?*"
      :vuelidate-field="v$.learnerType"
      :options="learnerTypeOptions"
      option-label="value"
    >
      <template v-if="form.learnerType?.id === GAP_YEAR_STUDENT" #help-content>
        <p class="text-sm">
          For students taking a break between high school and college.
        </p>
      </template>
    </SingleSelect>

    <SingleSelect
      v-if="requiredSchool.includes(form.learnerType?.id)"
      id="school"
      v-model.trim="form.school"
      label="Current School*"
      :vuelidate-field="v$.school"
      :options="formatSchools"
      :search-method="true"
      @search="searchSchools"
    />

    <PhoneField
      id="phoneNumber"
      v-model.trim="form.phone"
      label="Phone Number*"
      :vuelidate-field="v$.phone"
      @update-phone="updatePhone"
    >
    </PhoneField>

    <DateField
      id="birthday"
      v-model.trim="form.birthday"
      label="Birthday*"
      :vuelidate-field="v$.birthday"
    >
      <template #help-content>
        <p class="text-sm">
          For eligibility for programs with age restrictions.
        </p>
      </template>
    </DateField>

    <Checkbox
      id="eula"
      v-model.trim="v$.eula.$model"
      :vuelidate-field="v$.eula"
    >
      <template #label>
        I have read and I understand and agree to be bound by the<a
          data-cy="eulaLink"
          :class="secondaryColorClass"
          class="underline cursor-pointer"
          href="https://apiabroad.com/terms-of-service/"
          target="_blank"
        >
          Terms of Service.
        </a>
        *
      </template>
    </Checkbox>
    <div>
      <ButtonWithSpinner
        id="creationAccountButton"
        ref="creationAccountSubmit"
        data-cy="creationAccountButton"
        type="submit"
        variant="secondary"
        variant-type="block"
        button-height="min-h-[58px] md:min-h-[60px] mt-4"
        :grey-disabled-class="true"
        :disabled="v$.$invalid"
        @click="createAccount"
      >
        <div class="flex justify-center items-center">
          <span class="pr-3 uppercase">
            Create Account
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
      <div class="max-w-full">
        <DuplicateUserError v-if="isDuplicateAccount" />
      </div>
      <div v-if="submissionError" class="mt-2 error text-error-900">
        {{ submissionError }}
      </div>
    </div>
  </div>
</template>
