import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

// let token = document.head.querySelector('meta[name="csrf-token"]')

const httpLinkPrograms = new HttpLink({
  uri: process.env.MIX_PROGRAM_SERVICE_GRAPHQL_API_ENDPOINT,
  headers: {
    //   'X-CSRF-TOKEN': token.content,
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
  },
});

const httpLinkClasses = new HttpLink({
  uri: process.env.MIX_CLASSES_SERVICE_GRAPHQL_API_ENDPOINT,
  headers: {
    Authorization: "Bearer " + process.env.MIX_CLASSES_SERVICE_API_TOKEN,
  },
});

const cache = new InMemoryCache();

// Create the apollo client
const apolloPrograms = new ApolloClient({
  link: httpLinkPrograms,
  cache,
});

const apolloClasses = new ApolloClient({
  link: httpLinkClasses,
  cache,
});


export default createApolloProvider({
  clients: {
    apolloPrograms,
    apolloClasses,
  },
  defaultClient: apolloPrograms,
});
