<template>
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_146_2312)">
      <path
        d="M10.21 18.62L14.66 7.12H16.22L20.67 18.62H19.08L17.95 15.57H12.93L11.8 18.62H10.21ZM2.32 15.68L1.27 14.63L6.24 9.65C5.66 9.07 5.11 8.37 4.59 7.57C4.07 6.77 3.64 5.95 3.31 5.13H4.9C5.19 5.74 5.54 6.34 5.95 6.95C6.36 7.56 6.81 8.11 7.29 8.6C8 7.88 8.67 7.01 9.31 5.99C9.94 4.97 10.39 4.01 10.65 3.13H0V1.63H6.56V0H8.06V1.62H14.62V3.12H12.17C11.85 4.24 11.33 5.42 10.62 6.64C9.91 7.87 9.15 8.88 8.35 9.67L10.82 12.2L10.25 13.74L7.3 10.71L2.33 15.68H2.32ZM13.41 14.25H17.47L15.44 8.79L13.41 14.25Z"
        fill="#1E264C"
      />
    </g>
    <defs>
      <clipPath id="clip0_146_2312">
        <rect width="20.68" height="18.62" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
