<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps, computed } from "vue";
import { useVModel } from "@vueuse/core";
import { useStore } from "vuex";
import {
  COMMUNICATION,
  MOBILITY,
  SENSORY,
  DEFAULT_EXCURSION_IMAGE,
} from "@/constants";
import { getRidOfJSON } from "@/util/removeJson.js";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const events = useVModel(props, "modelValue");
const store = useStore();

const getLocations = computed(() => {
  return store.getters["events/getLocations"];
});

const locations = computed(() => {
  return events?.value?.id
    ? getLocations.value.filter(
        (location) => location?.event_id === events?.value?.id
      )
    : [];
});

const images = computed(() => getRidOfJSON(events.value?.image));
</script>
<template>
  <div class="relative mt-4">
    <span
      v-if="!events?.transportation && !locations && !images"
      class="font-semibold text-gray-700 mb-4"
      >This tab will be hidden to viewers when published if there is no data to
      display</span
    >
    <div class="mt-4 text-base text-gray-700">
      Getting to the Event:
      <span v-if="events?.transportation" class="font-semibold text-gray-700">{{
        events.transportation
      }}</span>
    </div>

    <div class="mt-4">
      Image(s):
      <div v-if="images?.length">
        <img
          v-for="(image, ind) in images"
          :key="ind"
          :src="image ?? DEFAULT_EXCURSION_IMAGE"
          class="mt-4"
        />
      </div>
      <div v-else>
        <img :src="DEFAULT_EXCURSION_IMAGE" class="mt-4" />
        <p>
          Default image. Upload an image to customize how the event is displayed
          on the website.
        </p>
      </div>
    </div>
    <div v-for="(location, index) in locations" :key="index" class="relative">
      <div class="font-semibold text-gray-700 mt-4">
        Location {{ index + 1 }}
      </div>
      <div class="text-base mb-4 font-medium text-gray-700">
        Address:
        <span v-if="location?.address" class="font-semibold"
          >{{ location.address?.number }}
          {{ location.address?.street_address_1 }},
          {{ location.address?.city }}, {{ location.address?.state }},
          {{ location.address?.country }}</span
        >
      </div>
      <div class="text-base mb-4 font-medium text-gray-700">
        City:
        <span v-if="location?.city" class="font-semibold"
          >{{ location.city?.city_ascii }},
          {{ location.city?.country?.name }}</span
        >
      </div>
      <div class="mt-4">
        Accessibility:
        <div class="mt-4">
          Mobility:
          <span
            v-if="
              getRidOfJSON(location?.mobility) &&
              getRidOfJSON(location?.mobility) !== 'null'
            "
            class="font-semibold"
            >{{
              MOBILITY.filter((item) =>
                getRidOfJSON(location.mobility).includes(item.id)
              )
                .map((item) => item.name)
                .join(", ") || ""
            }}</span
          >
        </div>
        <div class="mt-4">
          Communication:
          <span
            v-if="
              getRidOfJSON(location?.communication) &&
              getRidOfJSON(location?.communication) !== 'null'
            "
            class="font-semibold"
            >{{
              COMMUNICATION.filter((item) =>
                getRidOfJSON(location.communication).includes(item.id)
              )
                .map((item) => item.name)
                .join(", ") || ""
            }}</span
          >
        </div>
        <div class="mt-4">
          Sensory:
          <span
            v-if="
              getRidOfJSON(location?.sensory) &&
              getRidOfJSON(location?.sensory) !== 'null'
            "
            class="font-semibold"
            >{{
              SENSORY.filter((item) =>
                getRidOfJSON(location.sensory).includes(item.id)
              )
                .map((item) => item.name)
                .join(", ") || ""
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
