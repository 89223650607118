<script setup>
import ProgramPagination from "../Pagination/ProgramPagination.vue";
import QuoteCard from "../StudentsSection/QuoteCard.vue";
import { ref, computed, defineProps, watch, toRefs } from "vue";

let currentPage = ref(1);

const props = defineProps({
  quotes: {
    type: Array,
    required: true,
    default: () => {},
  },
});
const { quotes } = toRefs(props);

const totalPages = computed(() => {
  return quotes.value.length;
});

const handlePageChange = (page) => {
  currentPage.value = page;
};

const quoteImgSrc = computed(() => {
  if (localStorage.getItem("secondaryColor")) {
    return "/images/quote_circle_grey.svg";
  }
  return "/images/quote_circle.svg"
});

let showPage = false;
watch(quotes, (newQuotes) => {
  if (newQuotes.length != 0) {
    showPage = true;
  }
});
</script>

<template>
  <div
    v-if="showPage && quotes"
    class="bg-abstract-pattern pt-20 px-4 md:px-8 text-indigo-base"
  >
    <h3 class="text-4xl mt-4 mb-2 font-bold text-indigo-base">
      <div class="text-2xl">What Students are</div>
      <div>SAYING</div>
    </h3>
    <div class="lg:flex">
      <img :src="quoteImgSrc" alt="quote" class="mr-8" />
      <QuoteCard :quote-obj="quotes[currentPage - 1]" />
    </div>
    <ProgramPagination
      :current-page="currentPage"
      :total-pages="totalPages"
      @page-change="handlePageChange"
    />
    <hr class="my-1 md:mt-20 border-b-3 border-gray-200" />
  </div>
</template>
