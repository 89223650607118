export const baseHousingDetail = {
  address_lat_lng: null,
  address_line_1: "",
  address_line_2: "",
  address_postal_code: "",
  allowed_participants: [],
  allowed_program_types: [],
  archived_at: null,
  building_access: "",
  city_id: undefined,
  city: "",
  state: "",
  country: {},
  country_id: "",
  created_at: null,
  curfew: "",
  description: "",
  generic_listing: false,
  guest_allowed: "",
  guest_checkin: false,
  housing_amenities: [],
  housing_availability: [],
  id: null,
  name: "",
  neighborhood: "",
  occupants: [],
  on_campus: true,
  rules: "",
  smoking: false,
  staff_hours: "",
  staffed_reception: null,
  status: "Draft",
  supporting_media: [],
  tags: [],
  terms_available: [],
  type: "Apartment",
  units: [],
  updated_at: null,
  updated_by: null,
  vendor: null,
  vendor_institution_id: null,
  website_url: "",
  hotel_rating: undefined,
  tier: null,
  tier_id: null,
  subcategory: null,
  min_stay: null,
};
