export const IS_BETWEEN = "is_between";
export const IS_BETWEEN_OPTION = {
  label: "Is between",
  value: IS_BETWEEN,
};

export const IS_OPTION = {
  label: "Is exactly",
  value: "is",
};

export const EMPTY_OPTION =   {
  label: "",
  value: "",
};

const options = [
  EMPTY_OPTION,
  IS_OPTION,
  IS_BETWEEN_OPTION,
];
export const START_DATE_SETTINGS = {
  type: "single_select",
  labelInput: "label",
  options: options,
  active: true,
  customIndicator: true,
  customSelected: true,
};

export const END_DATE_SETTINGS = {
  type: "single_select",
  labelInput: "label",
  options: options,
  active: true,
  customIndicator: true,
  customSelected: true,
};

export const MAX_YEARS_OFFSET = 2;
