<template>
  <div class="w-full">
    <section>
      <div class="flex mt-10 px-3 w-full flex-wrap">
        <div class="flex ml-2 w-full justify-between flex-wrap">
          <h1 class="text-2xl font-bold text-blue-900">
            Host Institution
          </h1>
          <SearchBar
            class="flex mt-3 sm:w-full min-w-[300px] md:w-[400px]"
            :input-from-parent="q"
            @handleSearch="search"
            @handleCustomFilter="openAdvancedFilters = true"
          >
          </SearchBar>
        </div>
      </div>
    </section>

    <div class="flex justify-center">
      <table v-if="!loading && !error && table" class="m-4 p-2 w-full">
        <thead>
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
          >
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              class="py-4 pr-3 first:pl-2 text-left text-sm items-center text-gray-900"
              @click="handleClickHeader(header)"
            >
              <div
                class="flex items-center"
                :class="{ 'ml-8': header.id === 'Images and Videos' }"
              >
                {{ header.column.columnDef.header }}
                <button
                  v-if="selectedSortHeader === header.id"
                  class="ml-2 h-full w-fit duration-200"
                  :class="{ 'rotate-180': isDesc }"
                >
                  <ChevronDownIcon class="w-4 h-4" />
                </button>
              </div>
            </th>
            <th class="py-4 text-sm text-gray-900">
              <HeadersCheckboxList :options="headers" max-headers="4">
                <CogTooth class="text-teal-900" />
              </HeadersCheckboxList>
            </th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr
            v-for="row in table.getRowModel().rows"
            :key="row.id"
            class="hover:bg-blue-350"
          >
            <td
              v-for="cell in row.getVisibleCells()"
              :key="cell.column.id"
              class="py-8 text-sm first:pl-4 first:text-teal-900 capitalize border-t-[1px] border-solid border-t-[#f1f2f4]"
            >
              <FlexRender
                :render="
                  cell.column.columnDef.type !== IMAGE_CELL
                    ? cell.column.columnDef.cell
                    : ImagesAndVideos
                "
                :props="
                  cell.column.columnDef.type !== IMAGE_CELL
                    ? cell.getContext()
                    : { ...cell.getContext(), images: imagesPlaceholder }
                "
              />
            </td>
            <td class="py-8 border-t-[1px] border-solid border-t-gray-200">
              <MinimalActionsMenu :routes="actionsRoutes(row.original.id)" />
            </td>
          </tr>
        </tbody>
      </table>
      <Spinner
        v-else-if="loading && !error"
        class="relative h-65vh bg-blue-100"
      />
      <ErrorPage
        v-else-if="error"
        class="relative h-65vh"
        message="Failed to load host institution data."
      />
      <AdvancedFilterModal
        :is-modal-open="openAdvancedFilters"
        @applyFilters="applyAdvancedFilters"
        @closeModal="openAdvancedFilters = false"
      />
    </div>
    <footer>
      <Pagination
        v-if="showPagination && !error"
        class="mb-14"
        :page="requestParams.page"
        :total-of-records="activeFilter.count"
        :records-per-page="requestParams.limit"
        @changePage="changePage"
      />
    </footer>
  </div>
</template>

<script setup>
import { ref, reactive, computed, provide } from "vue";
import { useRoute, useRouter } from "vue-router";
import { eventBus } from "@/app";
import { debounce } from "lodash";
import { FlexRender } from "@tanstack/vue-table";

import AdvancedFilterModal from "./AdvancedFilter.vue";
import MinimalActionsMenu from "@/components/shared/Table/MinimalActionsMenu.vue";
import ErrorPage from "@/components/errorPage.vue";
import Pagination from "@/components/shared/Pagination.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import ImagesAndVideos from "@/components/HostInstitution/ImagesAndVideos.vue";
import HeadersCheckboxList from "@/components/shared/Table/HeadersCheckboxList.vue";
import CogTooth from "@/components/shared/icons/CogTooth.vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { useGetHostInstitutionOptions } from "@/components/program-manager/sessions/composable";
import { createColumnHelper } from "@tanstack/vue-table";
import {
  getCoreRowModel,
  getSortedRowModel,
  useVueTable,
} from "@tanstack/vue-table";

const REGULAR_CELL = "REGULAR_CELL";
const IMAGE_CELL = "IMAGE_CELL";

const router = useRouter();
const route = useRoute();

const headers = [
  "Name",
  "City",
  "Country",
  // "Images and Videos", Temporarily removed as part of RAG-366
  "Registration Type",
  "Assessment Structure",
  "Accreditation",
];

const visibleHeaders = ref(headers.slice(0, 3));
provide("visibleOptions", visibleHeaders);

const {
  isLoading: loading,
  execute: fetchHostInstitution,
  state: hostInstitutionData,
} = useGetHostInstitutionOptions(false);

const openAdvancedFilters = ref(false);
const selectedSortHeader = ref(headers[0]);
const isDesc = ref(false);
const error = ref(false);
const table = ref(null);
const count = ref(0);

const requestParams = reactive({
  limit: 25,
  page: 1,
  search: "",
  extraParams: {},
});

const { page: routePage, q } = route.query;

requestParams.search = q || "";
requestParams.page = Number(routePage) || 1;

const activeFilter = computed(() => {
  return {
    title: "Host Institution",
    count: count.value,
  };
});
const imagesPlaceholder = computed(() => [
  "https://placekitten.com/300/200",
  "https://placekitten.com/301/200",
  "https://placekitten.com/302/200",
  "https://placekitten.com/303/200",
]);
const actionsRoutes = (hostInstitutionId = undefined) => [
  {
    name: `/account/settings/host-institutions/${hostInstitutionId}`,
    description: "View/Edit Details",
  },
];

const showPagination = computed(
  () => activeFilter.value.count > requestParams.limit
);

const toggleColumnsVisibility = (visibleColumns) => {
  let columns = table.value.getAllColumns();
  columns.forEach((column) => {
    if (!visibleColumns.includes(column.id)) {
      column.toggleVisibility(false);
    } else {
      column.toggleVisibility(true);
    }
  });
};

eventBus.$on("updateHeaders", (selectedHeaders) => {
  visibleHeaders.value = selectedHeaders;
  toggleColumnsVisibility(selectedHeaders);
});

const buildTable = () => {
  const items = hostInstitutionData.value.items?.map((item) => ({
    id: item?.id,
    Name: item?.name,
    City: item?.shipping_address_city || "", 
    Country: item?.country?.name,
  }));

  const columnHelper = createColumnHelper();
  const columns = headers.map((header) => {
    return columnHelper.accessor(header, {
      cell: (info) => info.getValue(),
      header,
      type: header === "Images and Videos" ? IMAGE_CELL : REGULAR_CELL,
    });
  });

  table.value = useVueTable({
    data: items,
    columns: columns,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  toggleColumnsVisibility(visibleHeaders.value);

  count.value = hostInstitutionData.value.count;
};

const refresh = async ({
  search = "",
  page = 1,
  limit = 25,
  extraParams = {},
}) => {
  error.value = false;
  try {
    await fetchHostInstitution(0, { search, page, limit, extraParams });
    buildTable();
  } catch (err) {
    error.value = true;
  }
};

const handleSearch = (search) => {
  requestParams.search = search || "";
  requestParams.page = 1;
  router.push({
    query: {
      page: 1,
      q: requestParams.search,
    },
  });
  refresh(requestParams);
};

const changePage = (newPage) => {
  requestParams.page = newPage;
  router.push({
    query: {
      ...route.query,
      page: requestParams.page,
    },
  });
  refresh(requestParams);
};

const applyAdvancedFilters = (extraParams) => {
  requestParams.extraParams = extraParams;
  refresh(requestParams);
};

const handleClickHeader = ({ id }) => {
  if (id === selectedSortHeader.value) {
    isDesc.value = !isDesc.value;
  } else {
    isDesc.value = false;
    selectedSortHeader.value = id;
  }
  table.value.getColumn(id).toggleSorting(isDesc.value);
};

const search = debounce(handleSearch, 250);

refresh(requestParams);
</script>
