<template>
  <div
    :id="code"
    class="w-full px-4 mx-auto max-w-7xl flex flex-col items-center justify-center text-center h-100 sm:px-8 md:px-8 md:mb-8"
  >
    <img class="-mb-4" src="../../images/no-records.svg" alt="" />
    <h3 class="mb-sm text-lg font-semibold md:text-xl" data-testid="404-error-page">
      {{ errorMessage }}
    </h3>
    <p v-if="!disableCode" class="max-w-md text-2sm mb-sm text-gray-600">
      Error code: {{ errorCode }}
    </p>
    <p v-if="showLinks" class="max-w-md text-2sm text-gray-600">
      Here are some helpful links instead:
    </p>
    <span
      v-if="$route.path.includes('internships')"
      :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
      class="student-link cursor-pointer"
      @click="$router.go(-1)"
    >
      Go back
    </span>
    <div v-else>
      <router-link
        v-for="(link, index) in redirectLinks"
        :key="index"
        :class="`${secondaryColorClassLinks} flex justify-center font-semibold hover:underline`"
        class="student-link"
        :to="link.path"
        >{{ link.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  props: {
    message: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "404",
    },
    disableCode: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    showLinks() {
      return !_.isEmpty(this.redirectLinks);
    },
    redirectLinks() {
      return !_.isEmpty(this.links)
        ? this.links
        : [
            { path: "/", label: "Home" },
            { path: "/results/programs", label: "Select a program" },
          ];
    },
    errorMessage() {
      return this.message
        ? this.message
        : "Oops! We can't seem to find the page you're looking for.";
    },
    errorCode() {
      return this.code ? this.code : "404";
    },
  },
  created() {
    this.$store.commit("clearProgram");
  },
};
</script>

<style scoped>
.mb-sm {
  margin-bottom: 10px;
}
</style>
