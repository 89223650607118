<template>
  <div>
    <PageStyle>
      <template #pageContent>
        <div class="sm:flex justify-between items-center">
          <h2 class="mb-2 text-lg font-medium">
            Organization Branding
          </h2>
          <div class="sm:flex justify-between items-center">
            <button-with-spinner
              ref="saveButton"
              :disabled="!buttonEnabled || isSubmitting"
              variant="secondary"
              :customClass="'inline-block text-center rounded font-semibold border-2 border-transparent px-8 min-h-10 text-white bg-teal-900 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white items-center justify-center w-full mt-4 xs:w-auto xs:mt-0'"
              @click="updateAndSaveWhiteLabelBranding"
            >
              {{ buttonText }}
            </button-with-spinner>
          </div>
        </div>

        <FormPanel
          :id="'urlPanel'"
          :title="'URL'"
          class="mt-6"
          panel-content-class="text-gray-600 border-gray-200"
        >
          <template #content>
            <div class="flex flex-col gap-4">
              <div>
                <label
                  data-testid="ownerLabel"
                  for="subdomain"
                  class="block text-sm font-medium text-gray-700"
                  ><span class="text-error-900 align-top">*</span> Owner</label
                >
                <SearchableSelect
                  :clear-search-on-blur="() => false"
                  :deselect-from-dropdown="true"
                  :loading="entitiesOptionsLoading"
                  :options="entitiesOptions.items"
                  placeholder="Select Owner"
                  label="name"
                  @search="fetchEntitiesOptions"
                  v-model="selectedOwnerEntity"
                  @option:selected="(val) => setSelectedEntityId(val)"
                >
                </SearchableSelect>
              </div>
              <BrandingURL
                :defaultUrl="brandingUrl"
                @branding-url="updateUrl"
              />
            </div>
          </template>
        </FormPanel>
        <FormPanel
          :id="'logoPanel'"
          :title="'ORGANIZATION LOGO'"
          class="mt-6"
          panel-content-class="text-gray-600 border-gray-200"
        >
          <template #content>
            <ImageUpload
              :name="'logo'"
              :file-types="['.jpeg', '.png', '.jpg']"
              :max-file-size="15"
              :custom-button-label="'Choose Logo'"
              :custom-file-box-label="'This logo will appear to learners throughout APIConnect. It will also show in emails and admin views. For best results, use a square logo version.'"
              @updateProgress="updateProgress"
              @updateFiles="updateFiles"
              @s3Location="updateLogoLocation"
            />
          </template>
        </FormPanel>
        <FormPanel
          :id="'colorPanel'"
          :title="'ORGANIZATION COLORS'"
          class="mt-6"
          panel-content-class="text-gray-600 border-gray-200"
        >
          <template #content>
            <BrandingColour
              class="bg-white p-8 border-2 border-gray-200 rounded-lg"
              :defaultPrimary="primary"
              :defaultSecondary="secondary"
              :defaultAccent="accent"
              @branding-primary="updatePrimaryColor"
              @branding-secondary="updateSecondaryColor"
              @branding-accent="updateAccentColor"
              @invalid-primary="invalidPrimaryCheck"
              @invalid-secondary="invalidSecondaryCheck"
              @invalid-accent="invalidAccentCheck"
              @selected-option="checkIfColorIsDefault"
            />
          </template>
        </FormPanel>
      </template>
    </PageStyle>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { ERROR_TIMEOUT } from "@/constants.js";

import { useStore } from "vuex";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import PageStyle from "@/components/forms/SharedComponents/Layout/SettingsPage.vue";
import BrandingColour from "../../university/components/SiteComponents/branding/BrandingColour.vue";
import BrandingURL from "../../university/components/SiteComponents/branding/BrandingURL.vue";
import ImageUpload from "../forms/SharedComponents/ImageUpload.vue";
import ButtonWithSpinner from "../forms/SharedComponents/ButtonWithSpinner.vue";
import axios from "axios";
import { getApiClient } from "@/services/API";
import forms from "@/mixins/forms";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import {
  debounceSearchWrapper,
  useGetEntities,
} from "@/components/program-manager/sessions/composable";
import { entityTypes } from "@/components/program-manager/sessions/constants";
import { useToast } from "vue-toast-notification";
import entitiesService from "@/services/entities.js";

const {
  execute: executeGetEntities,
  state: entitiesOptions,
  isLoading: entitiesOptionsLoading,
} = useGetEntities(
  { immediate: true, throwError: false, resetOnExecute: true },
  {
    extraParams: {
      account_types: [entityTypes.host_institution],
    },
  }
);

const fetchEntitiesOptions = debounceSearchWrapper(executeGetEntities, 250, {
  extraParams: {
    account_types: [entityTypes.home_institution, entityTypes.host_institution],
  },
});

const store = useStore();
const toast = useToast();

const primary = ref("");
const secondary = ref("");
const accent = ref("");
const brandingUrl = ref("");
const logoPath = ref("");
const ipeds_id = ref("");
const savedBranding = ref("");
const selectedOption = ref("");
const buttonText = ref("Save");
const invalidPrimary = ref(false);
const invalidSecondary = ref(false);
const invalidAccent = ref(false);
const buttonEnabled = ref(false);
const isSubmitting = ref(false);
const selectedOwnerEntityId = ref(null);
const selectedOwnerEntity = ref(null);

const currentUser = computed(() => store.state.currentUser);

const setSelectedEntityId = (entityVal) => {
  selectedOwnerEntityId.value = entityVal.id;
};
const enableSave = () => {
  if (selectedOption.value === "custom") {
    if (brandingUrl.value && logoPath.value) {
      if (
        invalidPrimary.value ||
        invalidSecondary.value ||
        invalidAccent.value ||
        !selectedOwnerEntityId.value ||
        !selectedOwnerEntity.value
      ) {
        buttonEnabled.value = false;
      } else buttonEnabled.value = true;
    } else {
      buttonEnabled.value = false;
    }
  } else if (
    brandingUrl.value &&
    logoPath.value &&
    selectedOwnerEntityId.value &&
    selectedOwnerEntity.value
  ) {
    buttonEnabled.value = true;
  } else {
    buttonEnabled.value = false;
  }
  if (buttonEnabled.value) buttonBehavior();
};

const buttonBehavior = (buttonState) => {
  switch (buttonState) {
    case "submitting":
      buttonText.value = "Submitting: Please Wait...";
      buttonEnabled.value = false;
      if (saveButton.value) saveButton.value.startLoading();
      break;
    case "done":
      buttonText.value = "Success!";
      buttonEnabled.value = true;
      if (saveButton.value) saveButton.value.stopLoading();
      break;
    case "failed":
      buttonText.value = "Failed: Please Retry";
      buttonEnabled.value = true;
      if (saveButton.value) saveButton.value.stopLoading();
      break;
    default:
      buttonText.value = "Save";
      buttonEnabled.value = true;
      if (saveButton.value) saveButton.value.stopLoading();
      break;
  }
};

const setIpedsId = async () => {
  const opeId = currentUser.value.university.value ?? "";
  try {
    const response = await getApiClient().get(
      `/home-university/ipeds/${opeId}`
    );
    return response.data?.ipeds ?? null;
  } catch {
    return null;
  }
};
const fireErrorToast = (errorMessage) => {
  toast.open({
    message: errorMessage,
    type: "error",
    position: "bottom",
    duration: ERROR_TIMEOUT,
  });
};

const getOwnerEntityName = async (entityId) => {
  const response = await entitiesService.getEntityById(entityId);
  return response?.data?.data?.items?.name || "";
};

const getWhiteLabelBrandingIfExists = async () => {
  try {
    ipeds_id.value = await setIpedsId();
    const response = await axios.get(
      `/api/white-label-branding/${ipeds_id.value}`
    );
    if (!response.data || !response.data.data) {
      fireErrorToast(
        "An error ocurred while trying to get white label branding"
      );
      return;
    }
    const whiteLabelData = response.data.data;
    selectedOwnerEntityId.value = whiteLabelData.owner_entity_id ?? null;
    selectedOwnerEntity.value = whiteLabelData.owner_entity_id
      ? await getOwnerEntityName(whiteLabelData.owner_entity_id)
      : "";
    primary.value = whiteLabelData.primary_color;
    secondary.value = whiteLabelData.secondary_color;
    accent.value = whiteLabelData.accent_color;
    brandingUrl.value = whiteLabelData.subdomain;
  } catch {
    fireErrorToast("An error ocurred while trying to get white label branding");
  }
};

const updateAndSaveWhiteLabelBranding = async () => {
  if (!selectedOwnerEntityId.value) {
    toast.open({
      message: "You must choose an owner for this subdomain",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
    return;
  }
  if (!brandingUrl.value) {
    toast.open({
      message: "You must put a valid subdomain in",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
    return;
  }
  buttonBehavior("submitting");
  let primaryColor = "#043544";
  let secondaryColor = "#009999";
  let accentColor = "#42bfb3";
  if (selectedOption.value == "custom") {
    primaryColor = primary.value;
    secondaryColor = secondary.value;
    accentColor = accent.value;
  }
  try {
    savedBranding.value = await axios.put(
      `/api/white-label-branding/${ipeds_id.value}`,
      {
        ipeds_id: ipeds_id.value,
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        accent_color: accentColor,
        subdomain: brandingUrl.value,
        logo: logoPath.value,
        owner_entity_id: selectedOwnerEntityId.value,
      }
    );
    buttonBehavior("done");
  } catch (error) {
    buttonBehavior("failed");
    if (error.response.status === 409) {
      fireErrorToast(
        "It looks like that subdomain is already in use, choose a different one"
      );
    } else {
      fireErrorToast(
        "An error occurred when trying to create/update white label data. Please try again later."
      );
    }
  }
};

const checkIfColorIsDefault = (option) => {
  selectedOption.value = option;
};

const updateUrl = (url) => {
  brandingUrl.value = url;
};

const invalidPrimaryCheck = (value) => {
  invalidPrimary.value = value;
};

const invalidSecondaryCheck = (value) => {
  invalidSecondary.value = value;
};

const invalidAccentCheck = (value) => {
  invalidAccent.value = value;
};

const updatePrimaryColor = (color) => {
  primary.value = color;
};

const updateSecondaryColor = (color) => {
  secondary.value = color;
};

const updateAccentColor = (color) => {
  accent.value = color;
};

const updateLogoLocation = (path) => {
  logoPath.value = path.url;
};

const saveButton = ref(null);

watch(
  [
    primary,
    secondary,
    accent,
    invalidPrimary,
    invalidSecondary,
    invalidAccent,
    brandingUrl,
    logoPath,
    selectedOption,
    selectedOwnerEntityId,
    selectedOwnerEntity,
  ],
  enableSave
);

onMounted(async () => {
  await getWhiteLabelBrandingIfExists();
});
</script>

<style scoped>
:deep(#vs1__combobox) {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
</style>
