<script setup>
import { ref, watch, defineProps, defineEmits, toRefs, computed, unref } from "vue";
import { AisRangeInput } from "vue-instantsearch/vue3/es";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import { formattedPriceWithoutCents } from "../../../../util/formatter";

const props = defineProps({
  attribute: String,
  priceValue: {
    type: Array,
    default: () => [0, 10],
    required: false,
  },
  defaultPrice: {
    type: Array,
    default: () => [0, 10],
    required: false,
  },
  min: {
    type: Number,
    default: 0,
    required: false,
  },
  max: {
    type: Number,
    default: 100,
    required: false,
  },
  interval: {
    type: Number,
    default: 10,
    required: false,
  },
  precision: {
    type: Number,
    default: 2,
    required: false,
  },
  sliderSettings: {
    required: false,
    type: Object,
    default: () => ({
      height: 8,
      dotSize: 24,
      tooltip: "none",
    }),
  },
  lazy: {
    type: Boolean,
    default: true,
  },
});

const { defaultPrice } = toRefs(props);

const internalValue = ref([...defaultPrice.value]);
const emit = defineEmits(["update:priceValue"]);

const emitValue = (value) => {
  emit("update:priceValue", value);
};


const refineAndEmit = (value, refine) => {
  emitValue(value);
  refine({ min: value[0], max: value[1] });
};


watch(
  () => props.priceValue,
  (newValue) => {
    if(newValue?.length === 2) {
      internalValue.value = newValue;
    } else {
      internalValue.value = [props.min, props.max];
    }
  },
  { immediate: true, deep: true }
);

const inputMin = computed(() => {
  return unref(internalValue)?.[0] || props.min;
});

const inputMax = computed(() => {
  return unref(internalValue)?.[1] || props.max;
})

</script>

<template>
  <div class="flex flex-col">
    <ais-range-input
      :attribute="attribute"
      :min="inputMin"
      :max="inputMax"
      :precision="precision"
    >
      <template v-slot="{ currentRefinement, range, refine }">
        <vue-slider
          class="algolia-slider"
          v-model="internalValue"
          :min="props.min"
          :max="props.max"
          :interval="interval"
          :lazy="lazy"
          v-bind="sliderSettings"
          :enable-cross="false"
          @change="(value) => refineAndEmit(value, refine)"
        />
        <div class="flex justify-between">
          <p class="text-sm text-indigo-base">
            {{ formattedPriceWithoutCents(internalValue?.[0]/100) }}
          </p>
          <p class="text-sm text-indigo-base">
            {{ formattedPriceWithoutCents(internalValue?.[1]/100) }}
          </p>
        </div>
      </template>
    </ais-range-input>
  </div>
</template>

<style scoped>
.algolia-slider :deep(.vue-slider-process) {
  @apply bg-[#1e264c];
}

.algolia-slider:hover :deep(.vue-slider-process) {
  @apply bg-[#1e264c];
}

.algolia-slider :deep(.vue-slider-dot .vue-slider-dot-handle) {
  @apply border-none;
  background: url("../../../../../images/icon-slider-thumb.svg") white center
    center no-repeat;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
}
</style>
