<script setup>
import AcademicCredits from "@/components/shared/Card/AcademicCredits.vue";
import ActionButtonsCard from "@/components/shared/Card/ActionButtonsCard.vue";
import CardContent from "../CardContent";
import ProgramPriceCard from "@/components/shared/Card/ProgramPriceCard.vue";
import TitleCard from "@/components/shared/Card/TitleCard.vue";
import { computed, toRefs, unref } from "vue";
import { useStore } from "vuex";
import {
  cardImage,
  getRedirectUrl,
  hidePrice,
  getAcademicCredits,
  getIsPastAppDeadline,
  getPriceInDollars,
  getSessionCardTitle,
} from "../utils";

defineEmits(["bookmarkClicked", "viewClicked", "applyClicked"]);

const store = useStore();

const billingRules = computed(() => store.state.billingRules);
const homeInstitutionId = computed(
    () => store.state.profileData?.colleges?.[0]?.college_id ?? ""
);

const props = defineProps({
  program: {
    type: Object,
    default() {
      return {};
    },
  },
  showViewLink: {
    type: Boolean,
    default: true,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  randomTags: {
    type: Boolean,
    default: false,
  },
  showBookmark: {
    type: Boolean,
    default: false,
  },
  showImage: {
    type: Boolean,
    default: true,
  },
  uppercaseName: {
    type: Boolean,
    default: false,
  },
  eventCategory: {
    type: String,
    default: "",
  },
  imageUrl: {
    type: String,
    default: "",
  },
});

const { program } = toRefs(props);

const session = computed(() => unref(program)?.session);
const sessionTypes = computed(() => unref(session)?.session_types);

const program_page = computed(() => unref(program)?.program_page);
const programPageId = computed(() => unref(program_page)?.id);

const showPriceComponent = computed(
  () => !hidePrice(billingRules, unref(session))
);
const sessionCardTitle = computed(() => getSessionCardTitle(unref(session)));
const priceInDollars = computed(() => getPriceInDollars(unref(program), unref(homeInstitutionId)));
const academicCredits = computed(() => getAcademicCredits(unref(session)));
const redirectUrl = computed(() => getRedirectUrl(unref(program)));
const isPastAppDeadline = computed(() => getIsPastAppDeadline(unref(program)));
const showSessionTypes = computed(() => unref(sessionTypes)?.length > 1);
</script>

<template>
  <div
    id="program-card"
    class="program-card-base lg:min-h-[18.75rem] grid max-w-6xl lg:gap-x-4 cursor-pointer"
    @click="$emit('viewClicked')"
  >
    <div class="flex flex-col">
      <TitleCard
        :title="sessionCardTitle"
        :action-id="programPageId"
        :show-bookmark="showBookmark"
        @bookmark-clicked="(id) => $emit('bookmarkClicked', id)"
      />
      <CardContent
        v-if="!showImage && horizontal"
        :program="program"
        :horizontal="horizontal"
        :random-tags="randomTags"
        :uppercase-name="uppercaseName"
      />
      <div v-if="showImage" class="flex-auto min-h-125p">
        <div class="h-full relative overflow-hidden">
          <img
            :id="`card-image-${programPageId}`"
            :src="cardImage(imageUrl, program)"
            :alt="program_page?.name || ''"
            class="absolute inset-0 h-full w-full object-cover bg-gray-75"
          />
        </div>
      </div>
    </div>
    <div
      :class="[
        'px-4 lg:grid lg:gap-x-4',
        horizontal
          ? 'lg:col-span-2 lg:grid-cols-2'
          : 'lg:col-span-1 lg:grid-cols-1',
      ]"
    >
      <CardContent
        v-if="!horizontal || (horizontal && showImage)"
        :program="program"
        :horizontal="horizontal"
        :random-tags="randomTags"
        :uppercase-name="uppercaseName"
      >
      </CardContent>
      <div class="flex flex-col flex-grow justify-end gap-y-11">
        <div id="api-card-description">
          <ProgramPriceCard
            v-if="showPriceComponent"
            :price-in-dollars="priceInDollars"
          />
          <AcademicCredits
            v-if="academicCredits"
            :academic-credits="academicCredits"
          />
        </div>
        <div
          class="flex flex-col sm:flex-row sm:justify-around justify-center items-center self-end w-full gap-4 mb-6"
        >
          <ActionButtonsCard
            :show-view-link="showViewLink"
            :redirect-url="redirectUrl"
            :program="program"
            custom-apply-class="program-card-apply-button-base"
            :is-past-app-deadline="isPastAppDeadline"
            :event-category="eventCategory"
            @apply-clicked="() => $emit('applyClicked')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
