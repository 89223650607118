<template>
  <div class="grid grid-cols-2">
    <div
      class="col-span-2 flex flex-col lg:flex-row md:justify-between lg:justify-between"
    >
      <div
        class="mt-6 md:mt-24 lg:mt-24 mx-6 md:mx-24 lg:mx-24 flex flex-col md:screen lg:w-4/6"
      >
        <div>
          <h1
            data-testid="fare-finder-title"
            class="text-3xl text-blue-900 font-semibold tracking-tight leading-tight"
          >
            Enjoy Significant Travel Discounts Exclusively for Students!
          </h1>
        </div>
        <div class="text-lg mt-4">
          <span
            >Using APIConnect's Fare Finder app, students gain access to reduced
            airfare prices!</span
          >
        </div>
        <div class="mt-6">
          <a :href="keyTravelUrl" target="_blank">
            <button
              :class="btnClass"
              class="text-center px-12 md:px-6 lg:px-6 text-white rounded font-semibold border-2 border-transparent h-12"
            >
              <span>Find My Fare</span>
            </button>
          </a>
        </div>
        <div v-if="!hasAcceptedEula" class="text-sm mt-8">
          <span
            >It looks like you haven't accepted API's End User License Agreement
            yet which is needed to access our Fare Finder App. You can access
            that
            <a
              :href="eulaUrl"
              :class="[
                secondaryColor ? `${secondaryColorClass}` : 'text-teal-900',
                `hover:${secondaryColorClass}`,
              ]"
              class="inline-flex items-center font-semibold text-sm underline"
            >
              here.
            </a>
          </span>
        </div>
      </div>
      <div
        class="m-6 md:mx-24 lg:mx-6 flex justify-start lg:justify-end lg:mt-24 md:w-screen lg:w-Full items-start"
      >
        <iframe
          src="https://player.vimeo.com/video/817074523?h=368582dc01"
          width="686"
          height="383"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="col-span-2 flex items-start mx-6 md:mx-24 lg:mx-24">
      <Alerts show-alert-icon>
        <div id="alert-content">
          <p>
            Please note that the Fare Finder app can ONLY be used to book
            academic travel. Any other, non-education-based airfare bookings are
            prohibited and will be canceled automatically by the system.
          </p>
        </div>
      </Alerts>
      <Alerts v-if="populateTravelForm" show-alert-icon>
        <div id="alert-content">
          <p>
            To make your life a little easier, bookings made on the Fare Finder
            app sync into your
            <a
              v-if="getlatestApplicationUrl"
              :href="getlatestApplicationUrl"
              :class="[
                secondaryColor
                  ? `${secondaryColorClass}`
                  : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
              ]"
              style="font-weight: normal;"
              >APIConnect Travel Plans Form</a
            >
            <span v-else>APIConnect Travel Plans Form</span>.
          </p>
        </div>
      </Alerts>
    </div>
    <div
      class="flex justify-end items-end h-32 md:mt-10 px-6 md:px-12 lg:px-12 col-span-2 col-start-2 mb-6"
    >
      <span class="text-sm">Powered by Key Travel</span>
    </div>
  </div>
</template>

<script>
import formService from "@/services/form.js";
import { mapState } from "vuex";
import Alerts from "@/components/helpers/Alerts";
import { POST_FORM_RULES } from "@/constants";

export default {
  components: { Alerts },
  props: {
    userEmail: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hasAcceptedEula: false,
      eulaUrl: "/EULA",
      keyTravelUrl:
        "https://sp.keytravel.com/cr/login?entityId=http%3A%2F%2Fwww.okta.com%2Fexk7a10jy2tw1efuS5d7&atlasUrl=https%3A%2F%2Finteractive.keytravel.com&departmentId=1001436",
      formUrl: "eulaacceptance",
    };
  },
  computed: {
    ...mapState(["studentApplications", "currentUser", "featureFlags"]),
    btnClass() {
      return !this.hasAcceptedEula
        ? `opacity-33 cursor-not-allowed text-white ${this.secondaryColorClassBG}`
        : [
            this.secondaryColor ? `bg-${this.secondaryColor}` : "bg-teal-900",
            `hover:${this.secondaryColorClassBG} focus:${this.secondaryColorClassBG}`,
          ];
    },
    email() {
      return this.userEmail !== ""
        ? this.userEmail
        : this.currentUser?.email || "";
    },
    getlatestApplicationUrl() {
      let app = this.studentApplications.find((app) => {
        return (
          app.applicationPaid &&
          app.programSession.post_acceptance_form_rules.some((item) =>
            POST_FORM_RULES["Travel Plans"].includes(item)
          )
        );
      });
      if (app) {
        return `enrollment/${app.id}/travel-plans`;
      } else {
        return null;
      }
    },
    populateTravelForm() {
      return this.featureFlags["fare-finder-travel-form"];
    },
  },
  created() {
    formService
      .submissionExists(this.formUrl, {
        "data.email": this.email,
      })
      .then(() => {
        this.hasAcceptedEula = true;
      });
  },

  methods: {
    goToKeyTravel() {
      if (this.hasAcceptedEula) {
        window.location.href = this.keyTravelUrl;
      }
    },
  },
};
</script>
