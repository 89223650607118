<script>
export default {
  name: "ViewNotesDetails",
};
</script>

<script setup>
import { computed, defineEmits, toRefs, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import { watchDebounced, useWindowSize } from "@vueuse/core";
import InfiniteLoading from "v3-infinite-loading/lib/v3-infinite-loading.es.js";
import Spinner from "@/components/helpers/Spinner.vue";
import AddEditNote from "@/components/StudentFulfillment/components/OrderDetail/sections/Notes/AddEditNote";
import PencilIcon from "@/components/shared/icons/PencilIcon";
import profileService from "@/services/profile";
import { parseCreatedAt } from "@/mixins/helpers";
import { useGetProfileComments } from "@/components/StudentFulfillment/composables/comments";
import { ERROR_TIMEOUT, DEFAULT_LIMIT_PER_PAGE } from "@/constants.js";
import { DEFAULT_EMPTY_NOTE } from "@/components/StudentFulfillment/components/OrderDetail/sections/Notes/constants";
import {
  hasUsersAdvisePermission,
  hasOrdersWritePermission,
} from "@/composables/authorization";

const emit = defineEmits(["closeModalAddEditNoteModal"]);

const { width } = useWindowSize();
const store = useStore();
const toast = useToast();

const props = defineProps({
  orderData: {
    type: Object,
    required: true,
  },
  openAddEditNoteModal: {
    type: Boolean,
    default: false,
  },
});

const { orderData, openAddEditNoteModal } = toRefs(props);

const state = reactive({
  selectedNote: { ...DEFAULT_EMPTY_NOTE },
  isNoteSaved: false,
  modalIsOpen: false,
  editNote: false,
  notesCache: [],
});

const pagination = reactive({
  limit: DEFAULT_LIMIT_PER_PAGE,
  page: 0,
});

const orderIds = computed(() => ({
  id: orderData?.value?.order?.id,
  oktaId: orderData?.value?.order?.participant_id,
}));

const isLoadingNotes = computed(
  () => !!isNotesLoading?.value && !isNotesReady?.value
);

const {
  execute: fetchNotes,
  isLoading: isNotesLoading,
  ready: isNotesReady,
} = useGetProfileComments({ immediate: false, throwError: false }, true);

const executeFetchNotes = async () => {
  const notes = await fetchNotes(0, {
    extraParams: {
      field: "created_at",
      order: "DESC",
      order_id: orderIds.value?.id,
      okta_id: orderIds.value?.oktaId,
    },
    ...pagination,
  });

  (notes?.data ?? []).forEach((note) => {
    state.notesCache.push(note);
  });

  return notes;
};

const loadComments = async ($state) => {
  if (!(isLoadingNotes.value || !orderData?.value?.order)) {
    try {
      pagination.page++;
      const notes = await executeFetchNotes();

      if (notes?.end < notes?.count) {
        $state.loaded();
      } else {
        $state.complete();
      }
    } catch (error) {
      $state.error();
    }
  }
};

const convertedCreatedAt = (note) => {
  return `Created By ${
    note?.profile_created_by?.full_name ?? ""
  } on ${parseCreatedAt(note.created_at)}`;
};

const allowedToEdit = (note) =>
  note?.profile_created_by?.okta_id ===
    store?.state?.currentUser?.participantId &&
  (hasUsersAdvisePermission.value || hasOrdersWritePermission.value);

const openAddEditNote = (note) => {
  state.editNote = false;
  state.selectedNote = { ...DEFAULT_EMPTY_NOTE };
  if (note && allowedToEdit(note)) {
    state.editNote = true;
    state.selectedNote = { ...note };
  }
  state.modalIsOpen = true;
};

const closeAddEditNote = () => {
  state.modalIsOpen = false;
  state.selectedNote = { ...DEFAULT_EMPTY_NOTE };
  emit("closeModalAddEditNoteModal");
};

const saveNote = async (note) => {
  state.isNoteSaved = false;
  const toastMessage = {
    message: "Error while saving notes data. Please, try it later",
    type: "error",
    position: "bottom",
    duration: ERROR_TIMEOUT,
  };

  try {
    const payload = {
      id: note?.id,
      order_id: orderIds.value?.id,
      okta_id: orderIds.value?.oktaId,
      library_type: note?.library_type ?? "",
      comment: note?.comment ?? "",
      private: note?.private ?? false,
    };

    const shouldCreate = !note?.id;

    const callable = shouldCreate
      ? profileService.createProfileComment
      : profileService.updateProfileComment;
    const response = await callable({ payload, skipErrorBus: true });
    const newNote = response?.data;
    if (newNote) {
      if (shouldCreate) {
        state.notesCache.unshift(newNote);
      } else {
        const idx = state.notesCache.findIndex(
          (note) => note?.id === newNote?.id
        );
        if (idx >= 0) state.notesCache[idx] = newNote;
      }
      closeAddEditNote();

      toastMessage.message = "Note saved succesfully";
      toastMessage.type = "success";
    }
    toast.open(toastMessage);
  } catch {
    toast.open(toastMessage);
  } finally {
    state.isNoteSaved = true;
  }
};

watch(openAddEditNoteModal, (open) => {
  if (open) {
    openAddEditNote();
  }
});

watchDebounced(
  () => orderData,
  async () => {
    await executeFetchNotes();
  },
  { deep: true, debounce: 500, maxWait: 1000 }
);

const infiniteLoadingDistance = reactive({ value: 25 });
watchDebounced(
  () => width,
  (newValue) => {
    infiniteLoadingDistance.value = Math.round(newValue * 0.4);
  },
  { immediate: true, debounce: 500, maxWait: 1000 }
);
</script>

<template>
  <div>
    <AddEditNote
      v-model="state.selectedNote"
      :edit-note="state.editNote"
      :modal-is-open="state.modalIsOpen"
      :is-saved="state.isNoteSaved"
      @save-note="saveNote"
      @close-modal="closeAddEditNote"
    />
    <div>
      <div v-if="state.notesCache.length">
        <section v-for="note in state.notesCache" :key="note.id" class="mb-4">
          <div
            class="bg-white border rounded-md border-configurator-order-summary-gray p-4"
          >
            <div class="flex justify-between p-2">
              <div>
                <label class="font-semibold">{{
                  note?.library_type ?? ""
                }}</label>
                <span v-if="note?.private">&nbsp;(Sensitive)</span>
              </div>
              <div class="flex justify-center items-center">
                <span class="font-normal">{{ convertedCreatedAt(note) }}</span>
                <button
                  class="flex justify-center items-center ml-4 text-sm font-bold bg-white text-teal-900"
                  @click="openAddEditNote(note)"
                >
                  <PencilIcon v-if="allowedToEdit(note)" class="h-3" />
                </button>
              </div>
            </div>
            <div class="p-2 break-all">
              <label class="font-normal">{{ note?.comment ?? "" }}</label>
            </div>
          </div>
        </section>
      </div>
      <InfiniteLoading
        class="full-width"
        :distance="50"
        @infinite="loadComments"
      >
        <template #spinner>
          <div>
            <Spinner
              v-if="isLoadingNotes"
              class="bg-blue-100 relative"
              style="height: 10vh;"
            />
          </div>
        </template>
        <template #complete><div></div></template>
      </InfiniteLoading>
    </div>
  </div>
</template>
