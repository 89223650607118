<script>
export default {
  name: "FilterBadgeBar",
};
</script>

<script setup>
import {
  defineProps,
  defineEmits,
  toRefs,
  unref,
  computed,
  toValue,
} from "vue";
import Badge from "@/components/shared/Badge/Badge.vue";
import ClearAll from "@/components/shared/icons/ClearAll.vue";

const emits = defineEmits(["remove-one-filter", "clear-all"]);
const props = defineProps({
  filters: {
    type: Object,
    default: () => {},
  },
  valueConfig: {
    type: Object,
    default: () => {},
  },
  homeInstitutionId: {
    type: String,
  },
});

const { filters, valueConfig } = toRefs(props);

const clearAll = () => {
  emits("clear-all");
};
const handleRemoveOneFilter = (payload) => {
  emits("remove-one-filter", payload);
};

const convertLabel = (key, value) => {
  const config = unref(valueConfig)[key];
  if (config?.converter) {
    return config.converter(value);
  }
  return value;
};

const singleEntry = (key) => {
  const config = unref(valueConfig)[key];
  return !!config?.singleEntry;
};
const shouldBeExcluded = (key) => {
  const config = unref(valueConfig)[key];
  return !!config?.exclude;
};

const foundAFilter = computed(() => {
  for (const [_key, value] of Object.entries(toValue(filters))) {
    const config = unref(valueConfig)[_key];
    if (
      !config?.exclude &&
      (value?.length || (value && !Array.isArray(value)))
    ) {
      return true;
    }
  }
  return false;
});
</script>
<template>
  <div class="flex font-montserrat flex-wrap gap-2">
    <template v-for="(filter, key) in filters">
      <template
        v-if="
          !shouldBeExcluded(key) &&
          singleEntry(key) &&
          (filter?.length || (filter && !Array.isArray(filter))) &&
          (!homeInstitutionId || (homeInstitutionId && key !== 'price'))
        "
      >
        <Badge
          :key="`${key}`"
          :item="{ key, label: convertLabel(key, filter) }"
          :truncate="false"
          @item-removed="handleRemoveOneFilter"
        />
      </template>
      <template
        v-else-if="
          !shouldBeExcluded(key) &&
          (!homeInstitutionId || (homeInstitutionId && key !== 'price'))
        "
      >
        <Badge
          v-for="(value, index) in filter"
          :key="`${key}_${index}`"
          :item="{ key, label: convertLabel(key, value), value }"
          :truncate="false"
          @item-removed="handleRemoveOneFilter"
        />
      </template>
    </template>
    <button
      v-if="foundAFilter"
      class="flex flex-col justify-center items-center"
      @click="clearAll"
    >
      <span
        class="flex justify-center items-center gap-1 font-bold text-base text-teal-900 leading-4.5 uppercase"
        >Clear All
        <ClearAll />
      </span>
    </button>
  </div>
</template>
