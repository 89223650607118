export const columnsConfig = [
  {
    id: "placementType",
    header: "Placement Type",
    size: 280,
    getColumnDisplayValue: ({ placement_types }) =>
      placement_types?.length
        ? placement_types.map((val) => `${val?.value || ""}`).join(", ")
        : "",
  },
  {
    id: "location",
    header: "Location",
    size: 160,
    getColumnDisplayValue: ({ city_data } = {}) =>
      `${city_data?.city_ascii || ""}, ${city_data?.admin_name_ascii || ""}`,
  },
  {
    id: "format",
    header: "Format",
    size: 100,
    getColumnDisplayValue: ({ work_locations }) =>
      work_locations?.length
        ? work_locations.map((val) => `${val?.value || ""}`).join(", ")
        : "",
  },
  {
    id: "language",
    header: "Language",
    size: 100,
    getColumnDisplayValue: ({ required_languages }) =>
      required_languages?.length
        ? required_languages.map((val) => `${val?.value || ""}`).join(", ")
        : "",
  },
  {
    id: "paidUnpaid",
    header: "Paid vs Unpaid",
    size: 140,
    getColumnDisplayValue: ({ compensation }) => `${compensation || ""}`,
  },
  {
    id: "academicLevels",
    header: "Academic Levels",
    size: 140,
    getColumnDisplayValue: ({ academic_levels }) =>
      academic_levels?.length
        ? academic_levels.map((val) => `${val?.academic_year || ""}`).join(", ")
        : "",
  },
  {
    id: "duration",
    header: "Duration",
    size: 100,
    getColumnDisplayValue: ({ duration, duration_unit }) =>
      duration ? `${duration || ""} ${duration_unit || "Weeks"}` : "",
  },
  {
    id: "session",
    header: "Session",
    size: 100,
    getColumnDisplayValue: ({ terms }) =>
      terms?.length ? terms.map((val) => `${val?.name || ""}`).join(", ") : "",
  },
  {
    id: "price",
    header: "Price",
    type: "price",
    size: 100,
    getColumnDisplayValue: () => "",
  },
];

export const miniTableColumns = [
  {
    id: "title",
    header: "Title",
    size: 600,
    getColumnDisplayValue: ({ title = "" }) => `${title}`,
  },
  {
    id: "company",
    header: "Company Name",
    size: 600,
    getColumnDisplayValue: ({ company }) => `${company?.name || ""}`,
  },
  {
    id: "city",
    header: "City",
    size: 420,
    getColumnDisplayValue: ({ city_data }) => `${city_data?.city || ""}`,
  },
  {
    id: "price",
    header: "Price",
    type: "price",
    size: 240,
    addedClasses: "text-right justify-end",
    getColumnDisplayValue: () => "",
  },
  {
    id: "default",
    header: "Default",
    size: 240,
    type: "default_checkbox",
    addedClasses: "text-center justify-center",
    getColumnDisplayValue: () => "",
  },
];

export const internshipDurationOptions = [...Array(52).keys()].map(
  (val, index) => ({
    id: index + 1,
    label: `${index + 1} W`,
  })
);

export const UMassCities = {
  1372595407: "Dublin",
  1826645935: "London",
  1826492520: "Edinburgh",
  1620619017: "Lisbon",
  1036192929: "Brisbane",
  1036074917: "Sydney",
  1554435911: "Auckland",
  1250015082: "Paris",
  1826289852: "Sheffield",
};

export const UMassAndCongresoCities = {
  1152337970: "Valparaiso",
  1152554349: "Santiago",
  1032717330: "Buenos Aires",
  1032433516: "Mendoza",
  1724616994: "Madrid",
  1724991838: "Sevilla",
  1724594040: "Barcelona",
  1724331267: "Bilbao",
};

export const UMassOrHomeSchoolCities = {
  1380726561: "Florence",
  1380382862: "Rome",
  1380724377: "Madridilan",
};
