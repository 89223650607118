<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter id="e6sdatyf0a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.184314 0 0 0 0 0.376471 0 0 0 0 0.584314 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <rect
        width="52"
        height="52"
        rx="26"
        fill="#FAFBFF"
        stroke="#2F6095"
        stroke-width="2"
        transform="translate(1 1)"
      />
      <g filter="url(#e6sdatyf0a)" transform="translate(1 1)">
        <g fill="#000">
          <path
            d="M35.114 21.77a.329.329 0 0 1-.32-.333c0-.18.14-.32.32-.32h1.307c.18 0 .32.14.32.32s-.14.333-.32.333h-1.307zM31.858 21.77a.329.329 0 0 1-.32-.333c0-.18.14-.32.32-.32h1.307c.18 0 .32.14.32.32s-.14.333-.32.333h-1.307z"
            stroke="#979797"
            stroke-width=".5"
          />
          <path
            d="M12.323 12.003h18.24c.18 0 .32.14.32.32v18.56h-18.56a.318.318 0 0 1-.32-.32v-18.24c0-.18.14-.32.32-.32zm8.793 9.113h9.114v-8.46H12.656V30.23h8.46v-9.114z"
            stroke="#272733"
          />
          <path
            d="M21.77 33.806a.34.34 0 0 1-.333.334.329.329 0 0 1-.32-.334v-1.294c0-.18.14-.333.32-.333a.34.34 0 0 1 .333.333v1.294zM21.77 37.062c0 .18-.154.32-.333.32a.318.318 0 0 1-.32-.32v-1.307c0-.18.14-.32.32-.32s.333.14.333.32v1.307zM22.09 39.344c.18 0 .32.154.32.333 0 .18-.14.32-.32.32h-.653a.318.318 0 0 1-.32-.32v-.667c0-.179.14-.32.32-.32.179 0 .333.141.333.32v.334h.32zM25.346 39.344c.18 0 .32.154.32.333 0 .18-.14.32-.32.32H24.04a.318.318 0 0 1-.32-.32c0-.18.14-.333.32-.333h1.307zM28.602 39.344c.18 0 .32.154.32.333 0 .18-.14.32-.32.32h-1.307a.318.318 0 0 1-.32-.32c0-.18.14-.333.32-.333h1.307zM31.845 39.344a.34.34 0 0 1 .333.333c0 .18-.154.32-.333.32H30.55a.329.329 0 0 1-.333-.32.34.34 0 0 1 .333-.333h1.295zM35.101 39.344a.34.34 0 0 1 .333.333c0 .18-.154.32-.333.32h-1.295a.329.329 0 0 1-.333-.32.34.34 0 0 1 .333-.333h1.295zM38.357 39.344a.34.34 0 0 1 .333.333c0 .18-.154.32-.333.32h-1.295a.329.329 0 0 1-.333-.32.34.34 0 0 1 .333-.333h1.295zM39.344 37.728c0-.179.154-.32.333-.32.18 0 .32.141.32.32v1.308c0 .18-.14.32-.32.32a.329.329 0 0 1-.333-.32v-1.308zM39.344 34.473c0-.18.154-.32.333-.32.18 0 .32.14.32.32v1.307c0 .18-.14.32-.32.32a.329.329 0 0 1-.333-.32v-1.307zM39.344 31.217c0-.18.154-.32.333-.32.18 0 .32.14.32.32v1.307c0 .18-.14.32-.32.32a.329.329 0 0 1-.333-.32v-1.307zM39.344 27.961c0-.18.154-.32.333-.32.18 0 .32.14.32.32v1.307c0 .18-.14.32-.32.32a.329.329 0 0 1-.333-.32v-1.307zM39.344 24.718c0-.192.154-.333.333-.333.18 0 .32.14.32.333v1.295c0 .179-.14.32-.32.32a.329.329 0 0 1-.333-.32v-1.295zM39.677 21.116c.18 0 .32.141.32.32-.012.437 0 .885 0 1.32 0 .18-.14.334-.32.334a.34.34 0 0 1-.333-.333v-.987h-.974a.329.329 0 0 1-.32-.333c0-.18.14-.32.32-.32h1.307z"
            stroke="#979797"
            stroke-width=".5"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
