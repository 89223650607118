<script setup>
import { defineEmits, defineProps } from "vue";

const emit = defineEmits(["back", "next"]);

const props = defineProps({
  hideBack: {
    type: Boolean,
    default: false,
  },
  disabledNext: {
    type: Boolean,
    default: true,
  },
  maxItems: {
    type: Number,
    default: 0,
  },
  requiredItems: {
    type: Number,
    default: 0,
  },
  items: {
    type: Number,
    default: 0,
  },
  nextLabel: {
    type: String,
    default: "Next",
  },
  showRequiredSection: {
    type: Boolean,
    default: true,
  },
  maxNextWidth: {
    type: String,
    default: "max-w-[168px]",
  },
  showFooter: {
    type: Boolean,
    default: true,
  },
});

const checkIfDeviceIsMobile = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  return screenWidth < 900;
};

const scrollUp = () => {
  if (checkIfDeviceIsMobile()) {
    const element = document.getElementById("process-steps");
    if (element) {
      element.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  } else {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }
};

const handleBackClick = () => {
  emit("back");
  scrollUp();
};

const handleNextClick = () => {
  emit("next");
  scrollUp();
};
</script>

<template>
  <footer
    v-if="showFooter"
    class=" gap-x-4 sticky bottom-0 md:p-4 md:flex mt-6 font-montserrat z-10"
    :class="secondaryColor ? `bg-gray-275` : 'bg-indigo-base'"
  >
    <div class="flex-1 hidden md:flex gap-2 justify-start">
      <slot name="custom" />
    </div>
    <div
      v-if="showRequiredSection"
      class="flex-1 text-sm uppercase font-bold md:hidden bg-blue-350 px-3 py-5 text-right"
    >
      <p class="text-indigo-base">{{ items }} of {{ maxItems }} selected</p>
      <p class="text-[#B1B1B1]">{{ requiredItems }} required</p>
    </div>
    <div
      class="flex-1 flex md:gap-4 md:items-center"
      :class="{
        'justify-center md:justify-end': !showRequiredSection && props.hideBack,
        'justify-end': showRequiredSection || !props.hideBack,
      }"
    >
      <div
        v-if="showRequiredSection"
        class="text-sm uppercase font-bold hidden md:block"
      >
        <p :class="secondaryColor ? `${secondaryColorClass}` : 'text-white'">{{ items }} of {{ maxItems }} selected</p>
        <p class="text-[#B1B1B1]">{{ requiredItems }} required</p>
      </div>

      <button
        v-if="!props.hideBack"
        data-testid="sticky-footer-back-button"
        class="w-full p-5 max-w-260p uppercase font-bold border-0 md:border-2"
        :class="secondaryColor ? `${secondaryColorClassOutlinedNoHover}` : 'border-white text-white'"
        @click="handleBackClick"
      >
        <div class="flex items-center justify-center gap-2">
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.61134 6.99126L9 1.71183L7.10819 -1.30562e-07L-3.05598e-07 6.99126L7.10819 14L9 12.2882L3.61134 6.99126Z"
              fill="currentColor"
            />
          </svg>
          <span>Back</span>
        </div>
      </button>
      <button
        type="button"
        class="w-full p-5 uppercase font-bold"
        data-testid="sticky-footer-next-button"
        :class="[
          props.disabledNext
            ? 'cursor-not-allowed bg-gray-275 text-gray-485 border-2 border-gray-275'
            : (secondaryColor ? `${secondaryColorClassBG} text-white` : 'bg-teal-900 text-white border-2 border-teal-900'),
          maxNextWidth,
        ]"
        :disabled="props.disabledNext"
        @click="handleNextClick"
      >
        <div class="flex items-center justify-center gap-2">
          <span>{{ nextLabel }}</span>
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -7.77409e-07L-7.14641e-07 1.71183L5.38866 7.00874Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </button>
    </div>
  </footer>
</template>
