<script setup>
import {
  ref,
  computed,
  onMounted,
  reactive,
  watch,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import GeneralInfoTab from "./components/GeneralInfoTab.vue";
import { useToast } from "vue-toast-notification";
import { cloneDeep } from "lodash";
import LocationsTab from "./components/LocationTab.vue";
import AvailabilityTab from "./components/AvailabilityTab.vue";
import EventDetailsTab from "./components/EventDetails/EventDetailsTab.vue";
import OccurrencesTab from "./components/Occurrences/OccurencesTab.vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import BreadCrumb from "@/components/shared/BreadCrumb.vue";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import { entityTypes } from "@/components/program-manager/sessions/constants";
import {
  debounceSearchWrapper,
  useGetEntities,
} from "@/components/program-manager/sessions/composable";
import { PERMISSIONS } from "@/constants";

import NavigateAwayWarning from "../housing/components/NavigateAwayWarning";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@headlessui/vue";
import Spinner from "@/components/helpers/Spinner.vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { STATUS } from "../../../constants";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {
  hasPublishPermission,
  hasWritePermission,
} from "@/composables/authorization.js";
import eventService from "@/services/events";
import { useGetOccurrences } from "@/components/program-manager/sessions/composable";

import {
  convertEvents,
  convertEventsBeforeSave,
} from "@/components/program-manager/events/utils";
import EventPreview from "./preview/EventPreview.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const eventId = ref(route?.params?.itemId ?? null);
const currentMode = ref(eventId.value ? "editing" : "creating");
const events = ref(undefined);

const saveSuccessful = ref(false);

const baseUrl = `${window.location.protocol}//${window.location.host}/program-manager/events`;

const selectedTab = ref(0);
const preview = ref(false);
const statuses = STATUS;

const dynamicTab = ref({});
const tabValidators = ref([]);

const warningModalError = ref("");
const warningModalValid = ref(undefined);
const warningModalOpen = ref(false);
const navigateTo = ref(undefined);
const eventOccurrenceIds = ref([]);
const selected_entity_Id = ref("");

const isStatusAllowed = (status) => {
  return (
    hasPublishPermissionToChangeStatus(status) ||
    hasWritePermissionToChangeStatus(status)
  );
};

const getLoading = computed(() => {
  return store.getters["events/getLoadingEvent"];
});
const profileInfo = computed(() => {
  return store.getters["getProfileData"];
});

const permissions = computed(() => store.getters.getPermissions);

const isAPIEmployee = computed(() => {
  return permissions.value.includes(PERMISSIONS.API_EMPLOYEE);
});

const entityId = computed(() => {
  return profileInfo.value?.colleges?.[0]?.college_id || null;
});

const isEventId = computed(() => {
  return events.value.id || null;
});

const setEventEntityId = (val) => {
  selected_entity_Id.value = val.id;
};

const getSubCategories = computed(() => {
  return store.getters["events/getSubCategories"];
});

events.value = reactive({
  ...events?.value,
  title: events?.value?.title || null,
  status: events?.value?.status || "Draft",
  event_category: events?.value?.event_category || undefined,
  category_id: events?.value?.event_category.id || 1,
  subcategory_id: events?.value?.sub_category?.id || 1,
  transportation: events?.value?.transportation,
});

const isLoading = computed(() => {
  return getLoading.value;
});
const lastTab = computed(() => {
  return selectedTab.value === getTabs.value.length - 1;
});
const firstTab = computed(() => {
  return selectedTab.value === 0;
});
const shouldShowTab = (tabName) => {
  return tabName.toLowerCase() === "occurrences" && !!eventId.value === false
    ? false
    : true;
};
const getTabs = computed(() => {
  return [
    { name: "General Info", component: GeneralInfoTab },
    { name: "Location", component: LocationsTab },
    { name: "Availability", component: AvailabilityTab },
    { name: "Event Details", component: EventDetailsTab },
    { name: "Occurrences", component: OccurrencesTab },
  ];
});

const changeTab = (index) => {
  selectedTab.value = index;
};
const updateListBox = (newValue) => {
  selectedTab.value = getTabs.value.findIndex((val) => val.name === newValue);
};
const previousTab = () => {
  if (selectedTab.value > 0) {
    selectedTab.value = selectedTab.value - 1;
  }
};
const enablePreview = () => {
  preview.value = true;
};
onBeforeRouteLeave((to, from, next) => {
  if (v$.value.$anyDirty && !warningModalOpen.value && !navigateTo.value) {
    warningModalError.value = "";
    navigateTo.value = to;
    v$.value
      .$validate()
      .then((valid) => {
        if (valid) {
          warningModalValid.value = valid;
          warningModalOpen.value = true;
          next(false);
        } else {
          next();
        }
      })
      .catch(() => {
        next(false);
      });
  } else {
    next();
  }
});

const hasPublishPermissionToChangeStatus = (status) => {
  return ["Active", "Archived"].includes(status) && hasPublishPermission();
};
const hasWritePermissionToChangeStatus = (status) => {
  return ["Draft", "Inactive"].includes(status) && hasWritePermission();
};

const onWarningClose = () => {
  warningModalError.value = "";
  navigateTo.value = undefined;
  warningModalValid.value = undefined;
  warningModalOpen.value = false;
};
const onWarningContinue = () => {
  warningModalError.value = "";
  warningModalValid.value = undefined;
  warningModalOpen.value = false;
  router.push(navigateTo.value);
};
const onWarningSave = async () => {
  const allValid = await v$.value.$validate();
  if (allValid) {
    try {
      await saveEvent();
      saveSuccessful.value = true;
    } catch {
      warningModalError.value = "Event failed to save.";
    }
    if (saveSuccessful.value) {
      router.push(navigateTo.value);
    }
  } else {
    warningModalError.value = "Please fix errors before saving";
  }
};

const rules = computed(() => ({
  events: {
    title: {
      required: helpers.withMessage("Event Name is required", required),
    },
  },
}));

const {
  execute: executeFetchOccurrences,
  state: occurrencesData,
} = useGetOccurrences(
  { immediate: true, throwError: true },
  {
    occurrence_ids: eventOccurrenceIds.value,
    limit: eventOccurrenceIds.value?.length ?? 20,
  }
);

events.value.event_occurrences = occurrencesData?.value?.data;

const {
  execute: executeGetEntities,
  state: entitiesOptions,
  isLoading: entitiesOptionsLoading,
} = useGetEntities(
  { immediate: true, throwError: false, resetOnExecute: true },
  {
    extraParams: {
      account_types: [entityTypes.host_institution],
    },
  }
);

const fetchEntitiesOptions = debounceSearchWrapper(executeGetEntities, 250, {
  extraParams: {
    account_types: [entityTypes.home_institution, entityTypes.host_institution],
  },
});

let state = reactive({
  events,
});

watch(
  () => eventOccurrenceIds,
  (ids) => {
    if (ids?.value?.length > 0) {
      executeFetchOccurrences(0, {
        occurrence_ids: ids.value,
        limit: ids?.value?.length ?? 20,
      });
    }
  },
  { immediate: false, deep: true }
);

const saveEvent = async () => {
  let eventData = convertEventsBeforeSave(events);
  eventData.entity_id = entityId.value;
  if (isAPIEmployee && selected_entity_Id.value) {
    eventData.entity_id = selected_entity_Id.value;
  }
  try {
    let updatedEvents;

    if (currentMode.value === "editing") {
      if (eventData?.status === "Archived") {
        const response = await store.dispatch("events/archiveEvent", {
          eventId: events.value.id,
        });
        if (response.status === 204) {
          saveSuccessful.value = true;
        }
        return;
      }
      updatedEvents = await store.dispatch("events/updateEvents", {
        eventId: events.value.id,
        eventData,
      });
    } else {
      updatedEvents = await store.dispatch("events/createEvents", {
        ...eventData,
      });

      let newEventId = updatedEvents.id;

      window.location = `${baseUrl}/${newEventId}`;
    }

    if (updatedEvents) {
      saveSuccessful.value = true;
      events.value = cloneDeep(updatedEvents);

      state = reactive({
        events: convertEvents(
          events,
          cloneDeep(updatedEvents),
          getSubCategories
        ),
      });
    } else {
      saveSuccessful.value = false;
    }
  } catch (e) {
    toast.open({
      message: "Failed to save event",
      type: "info",
      position: "bottom",
      duration: 5000,
    });
  }
};

const registerTabValidator = (index, validator) => {
  tabValidators.value[index] = validator;
};

const savedStatusActive = ref();
const savePage = async () => {
  v$.value.$reset();
  const isValid = await v$.value.$validate();
  if (isValid) {
    try {
      await saveEvent();
    } catch (e) {
      toast.open({
        message: "Failed to save event",
        type: "info",
        position: "bottom",
        duration: 5000,
      });
    }
    if (saveSuccessful.value) {
      toast.open({
        message: "Event Saved Successfully",
        type: "success",
        position: "bottom",
        duration: 5000,
      });
      savedStatusActive.value = events.value.status === "Active" ? true : false;
    } else {
      toast.open({
        message: "Failed to save event",
        type: "info",
        position: "bottom",
        duration: 5000,
      });
    }
  } else {
    toast.open({
      message: "Please fix validation errors before saving",
      type: "info",
      position: "bottom",
      duration: 5000,
    });
  }
};

const saveAndClose = async () => {
  v$.value.$reset();
  const isValid = await v$.value.$validate();
  if (isValid) {
    try {
      await saveEvent();
    } catch (e) {
      toast.open({
        message: "Failed to save event",
        type: "info",
        position: "bottom",
        duration: 5000,
      });
    }
    if (saveSuccessful.value) {
      toast.open({
        message: "Event Saved Successfully",
        type: "success",
        position: "bottom",
        duration: 5000,
      });
      navigateTo.value = { name: "program-manager-events" };
      router.push(navigateTo.value);
    } else {
      toast.open({
        message: "Failed to save event",
        type: "info",
        position: "bottom",
        duration: 5000,
      });
    }
  } else {
    toast.open({
      message: "Please fix validation errors before saving",
      type: "info",
      position: "bottom",
      duration: 5000,
    });
  }
};

const nextTab = async () => {
  v$.value.$reset();
  const currentTab = selectedTab.value;
  const isValid = await tabValidators.value[currentTab]();
  if (isValid) {
    selectedTab.value = selectedTab.value + 1;
  } else {
    toast.open({
      message: "Please fix validation errors before saving",
      type: "info",
      position: "bottom",
      duration: 5000,
    });
  }
};

const v$ = useVuelidate(rules, state, {
  $registerAs: "CreateEditEvents",
  $lazy: true,
});

onMounted(async () => {
  if (!getSubCategories.value) {
    await store.dispatch("events/fetchSubCategories");
  }

  if (eventId.value) {
    await store.dispatch("events/fetchEventData", {
      eventId: eventId.value,
    });
  }
  if (currentMode.value === "editing") {
    store.commit("events/setLoadingEvent", true);
    try {
      const {
        data: { data },
      } = await eventService.getOne(eventId.value);
      eventOccurrenceIds.value = data?.event_occurrences?.map(
        (occurrence) => occurrence.id
      );
      convertEvents(events, cloneDeep(data), getSubCategories);
      store.commit("events/setEvent", data);

      store.commit("events/setLoadingEvent", false);
      savedStatusActive.value = events.value.status === "Active" ? true : false;
    } catch (error) {
      store.commit("events/setEventError", error.message);
    } finally {
      store.commit("events/setLoadingEvent", false);
    }
  }
});
onBeforeUnmount(() => {
  store.commit("events/setOccurrences", {
    upcoming: undefined,
    past: undefined,
  });
});

const showTenantSearchToggle = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["tenant-libraries"];
});
</script>

<template>
  <div v-show="!isLoading" class="relative">
    <EventPreview
      :model-value="events"
      :show-modal="preview"
      @update:show-modal="preview = $event"
    />
    <div class="p-4">
      <div class="flex justify-between mb-1">
        <BreadCrumb />
        <div class="flex text-sm">
          <h3 class="font-bold mr-1">Excursion + Event ID:</h3>
          <span>{{ events.id }}</span>
        </div>
      </div>
      <div class="p-6">
        <div class="pb-5 sm:flex sm:items-center sm:justify-between">
          <div class="w-full flex">
            <span class="text-red-100 pr-4 align-top">*</span>
            <div class="flex w-full">
              <input
                id="name"
                v-model="events.title"
                type="text"
                name="name"
                :class="[
                  {
                    'bg-error-100': v$.events.title.$errors?.length,
                  },
                  'w-full text-2xl font-medium leading-6 text-gray-900 bg-blue-100 focus:outline-none min-w-[300px]',
                ]"
                @blur="v$.events.title.$touch"
              />
              <img
                v-if="events.marketplace"
                src="/images/icon-marketplace.svg"
              />
              <div
                v-if="v$.events.title.$errors.length"
                class="common-error-text"
              >
                <span
                  v-for="(error, idx) in v$.events.title.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-4 mt-3 sm:mt-0 sm:ml-4">
            <template v-if="showTenantSearchToggle">
              <label
                v-if="isEventId"
                class="block text-sm font-semibold text-gray-700"
              >
                Owner
              </label>
              <label v-if="isEventId && !isAPIEmployee"
                >&nbsp;{{ events.entity }}</label
              >
              <div class="mt-1 block w-96">
                <SearchableSelect
                  v-if="isEventId && isAPIEmployee"
                  v-model="events.entity"
                  :clear-search-on-blur="() => false"
                  :deselect-from-dropdown="true"
                  :loading="entitiesOptionsLoading"
                  :options="entitiesOptions.items"
                  placeholder="Select Owner"
                  label="name"
                  @search="fetchEntitiesOptions"
                  @option:selected="(val) => setEventEntityId(val)"
                >
                </SearchableSelect>
              </div>
            </template>
            <label class="block text-sm font-semibold text-gray-700"
              ><span class="text-red-100 pr-2 align-sub">*</span>Status</label
            >
            <div class="mt-1 block w-48">
              <v-select
                v-model="events.status"
                :model-value="events?.status"
                :options="statuses"
                :selectable="isStatusAllowed"
              ></v-select>
            </div>
            <BaseButton
              v-show="lastTab"
              class="inline-flex items-center py-2 text-sm font-medium whitespace-nowrap"
              type="button"
              @click.prevent="saveAndClose"
            >
              Save & Close
            </BaseButton>
            <button
              type="button"
              class="inline-flex items-center rounded-md border-[#007f80] border-solid border bg-[#007f80] px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#ffffff] hover:text-[#007f80]"
              @click.prevent="enablePreview"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <TabGroup :selected-index="selectedTab" @change="changeTab">
        <TabList class="hidden sm:flex space-x border-b border-gray-200">
          <template v-for="tab in getTabs" :key="tab.name">
            <Tab
              v-if="shouldShowTab(tab.name)"
              v-slot="{ selected }"
              as="template"
            >
              <button
                :class="[
                  selected
                    ? `${primaryColorClassOutlined}`
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'flex-1	whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                ]"
                :aria-current="selected ? 'page' : undefined"
              >
                <img
                  v-if="v$.$getResultsForChild(tab.component)?.$errors?.length"
                  src="/images/icon-warning-60.svg"
                  alt="Complete icon"
                  class="w-3.5 mb-1 !inline"
                />
                <span>&nbsp;{{ tab.name }}</span>
              </button>
            </Tab>
          </template>
        </TabList>
        <div class="sm:hidden">
          <Listbox
            :default-value="getTabs[0].name"
            :value="getTabs[selectedTab].name"
            as="div"
            @update:modelValue="updateListBox"
          >
            <div class="relative mt-1">
              <ListboxButton
                class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              >
                <span class="block truncate">{{ getTabs[0].name }}</span>
                <span
                  class="h-8 w-6 pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
              <ListboxOptions
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-for="tab in getTabs"
                  :key="tab.name"
                  v-slot="{ selected }"
                  as="template"
                  :value="tab.name"
                >
                  <li
                    :class="[
                      'relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900',
                    ]"
                  >
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                      >{{ tab.name }}</span
                    >
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
        <TabPanels>
          <div v-for="(tab, index) in getTabs" :key="tab.name">
            <TabPanel :unmount="false">
              <component
                :is="tab.component"
                ref="dynamicTab"
                v-model="events"
                :is-preview="preview"
                :original-event="events"
                :saved-status-active="savedStatusActive"
                @validator="
                  (validator) => registerTabValidator(index, validator)
                "
              ></component>
            </TabPanel>
          </div>
        </TabPanels>
      </TabGroup>
      <div class="p-6 mt-6 border-t border-gray-200">
        <div class="grid grid-cols-3 gap-4">
          <div>
            <BaseButton
              v-show="!firstTab"
              outlined
              type="button"
              @click.prevent="previousTab"
            >
              Previous
            </BaseButton>
          </div>
          <div class="relative justify-end flex col-end-4">
            <BaseButton
              class="inline-flex items-center !mr-0"
              type="button"
              @click.prevent="nextTab"
              v-show="!lastTab"
            >
              {{ `Next` }}
            </BaseButton>
            <BaseButton
              class="inline-flex items-center !mr-0"
              type="button"
              @click.prevent="savePage"
            >
              {{ `Save` }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <NavigateAwayWarning
      :open="warningModalOpen"
      :housing-name="events.title"
      :error-message="warningModalError"
      :housing-valid="warningModalValid"
      @on-cancel="onWarningClose"
      @on-save="onWarningSave"
      @on-continue-without-saving="onWarningContinue"
    />
  </div>
  <Spinner
    v-show="isLoading"
    class="m-auto w-full h-full !fixed opacity-75 bg-blue-100 !z-0"
  />
</template>
