<template>
  <ul class="items-stretch self-stretch hidden text-base md:flex">
    <li class="relative flex items-center">
      <button
        data-toggle="user-menu"
        aria-label="Desktop Menu Toggle"
        data-toggle-indicator="flip"
        class="flex items-center text-base text-gray-600 hover:text-gray-700 hover:no-underline gap-2"
        @click="openOrClose"
      >
        <img src="/images/icon-profile-circle-bg.svg" alt="profile icon" />
      </button>
      <div
        id="user-menu"
        class="absolute right-0 hidden bg-white shadow-lg menu top-full"
        :style="{ display: menuOpen ? 'block' : 'none', width: '300px' }"
      >
        <ul class="text-sm text-left mx-4">
          <li>
            <div class="flex flex-col break-words my-4">
              <span class="font-semibold text-gray-600">{{ userName }}</span>
              <span class="text-gray-800">{{ userEmail }}</span>
            </div>
            <div class="h-px bg-gray-400"></div>
          </li>
          <li v-if="Object.values(menuItems?.myAccount)?.length">
            <NavBarSection
              title="My Account"
              :nav-links="Object.values(menuItems?.myAccount)"
              @click="openOrClose"
            />
          </li>
          <li v-if="Object.values(menuItems?.accountSettings)">
            <NavBarSection
              title="Settings"
              :nav-links="Object.values(menuItems?.accountSettings)"
              @click="openOrClose"
            />
          </li>
          <li
            v-if="
              Object.values(menuItems?.apiEmployee)?.length ||
              hasCacheFeatureEnabled
            "
          >
            <NavBarSection
              title="API Employees"
              :nav-links="Object.values(menuItems?.apiEmployee)"
              @click="openOrClose"
            />
            <CacheMenu v-if="hasCacheFeatureEnabled" />
          </li>
          <slot name="nav-desktop-list-items" />
          <li class="my-5">
            <button
              class="block font-normal text-gray-600 hover:font-semibold hover:text-gray-700 hover:no-underline w-full"
              @click="logout"
            >
              <div class="flex items-center">
                <img src="/images/icon-signout.svg" alt="sign out icon" />
                <div class="pl-2">Sign Out {{ logoutName }}</div>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { computed, ref, reactive, onBeforeMount } from "vue";
import { useStore } from "vuex";
import NavBarSection from "./NavBarSection.vue";
import CacheMenu from "./CacheMenu.vue";
import CitiesIcon from "@/components/svg-icons/CitiesIcon.vue";
import { PERMISSIONS } from "@/constants";
import { hasCacheFeatureEnabled } from "@/composables/authorization";
const store = useStore();
const baseUrl = `${window.location.origin}`;
const props = defineProps({
  isExperiencePages: {
    type: Boolean,
    default: false,
  },
});
const menuInExperiencePage = computed(() => {
  return props.isExperiencePages;
});
const menuOpen = ref(false);
// When using this menu in a page not in the main layout for example
// for experience pages that use a different layout  from the main connect application layout
// we prive de full url so that a full page redirect is made, that way the requested page is loaded in its own layout and
// it does not load like a SPA in the existing layout.
const menuItems = reactive({
  myAccount: {
    studentApplications: {
      name: "My Applications",
      description: "Manage program applications",
      icon: null,
      path: menuInExperiencePage.value
        ? `${baseUrl}/applications`
        : "/applications",
    },
    fareFinder: {
      name: "Fare Finder",
      description: "Search for discounted travel",
      icon: null,
      path: menuInExperiencePage.value
        ? `${baseUrl}/fare-finder`
        : { name: "fare-finder" },
    },
    profile: {
      name: "Profile",
      description: "Update your information",
      icon: "/images/icon-profile.svg",
      path: `${baseUrl}/account/about-you`,
    },
    password: {
      name: "Password",
      description: "Change your password",
      icon: "/images/icon-password-lock.svg",
      path: "#",
    },
    notificationSettings: {
      name: "Notification Settings",
      description: " Update notification settings",
      icon: null,
      path: menuInExperiencePage.value
        ? `${baseUrl}/notification-settings`
        : "/notification-settings",
    },
    switchAccount: {
      name: "Switch Account",
      description: "Manage multiple logins",
      icon: "/images/icon-switch-account.svg",
      path: "#",
    },
  },
  accountSettings: {
    curriculumIntegration: {
      name: "Curriculum Integration",
      description: "Manage course approvals",
      icon: "/images/icon-integrate.svg",
      path: "#",
    },
    brand: {
      name: "Brand",
      description: "Manage logo and colors",
      icon: "/images/icon-brand.svg",
      path: menuInExperiencePage.value
        ? `${baseUrl}/settings`
        : { name: "settings" },
    },
    hostInstitutions: {
      name: "Host Institutions",
      description: "Upload images and videos",
      icon: "/images/icon-host.svg",
      path: menuInExperiencePage.value
        ? `${baseUrl}/settings/institutions`
        : { name: "host-institutions" },
    },
    cities: {
      name: "Cities",
      description: "Edit city metadata",
      iconComponent: "CitiesIcon",
      path: menuInExperiencePage.value
        ? `${baseUrl}/settings/cities`
        : { name: "cities" },
    },
    forms: {
      name: "Forms",
      description: "Manage your forms",
      icon: "/images/icon-form.svg",
      path: "#",
    },
    userManagement: {
      name: "User Management",
      description: "Update team permissions",
      icon: "/images/icon-user-management.svg",
      path: menuInExperiencePage.value
        ? `${baseUrl}/settings/profiles`
        : { name: "user-management" },
    },
    helpMenu: {
      name: "Help",
      description: "Get assistance",
      icon: null,
      path: "#",
    },
  },
  apiEmployee: {
    studentView: {
      name: "Student View",
      description: "Impersonate Students",
      icon: null,
      path: menuInExperiencePage.value ? `${baseUrl}/students` : "/students",
    },
  },
});
const openOrClose = () => {
  menuOpen.value = !menuOpen.value;
};
const removeMenuItems = () => {
  if (!isParticipant.value && !isImpersonating.value) {
    delete menuItems.myAccount.studentApplications;
  }
  if (!fareFinder.value) {
    delete menuItems.myAccount.fareFinder;
  }
  if (!hasSettingsEnabled.value || !isApiEmployee.value) {
    delete menuItems.accountSettings.brand;
  }
  if (!hasSettingsEnabled.value || !hasItemsWritePermission.value) {
    delete menuItems.accountSettings.hostInstitutions;
    delete menuItems.accountSettings.cities;
  }
  if (!hasUserManagementEnabled.value) {
    delete menuItems.accountSettings.userManagement;
  }
  if (!hasHelpLink.value) {
    delete menuItems.accountSettings.helpMenu;
  }
  if (
    (!hasStudentManagementPermission.value && !dynamicUniversity.value) ||
    isImpersonating?.value
  ) {
    delete menuItems.myAccount.myStudents;
  }
  if (!hasStudentManagementPermission.value) {
    delete menuItems.myAccount.notificationSettings;
  }
  if (!studentView.value) {
    delete menuItems.apiEmployee.studentView;
  }
  if (!store.state?.featureFlags?.["profiles"]) {
    delete menuItems.myAccount.profile;
  }
  // Unknown removing for now and no active path
  delete menuItems.myAccount.switchAccount;
  delete menuItems.myAccount.password;
  delete menuItems.accountSettings.forms;
  delete menuItems.accountSettings.curriculumIntegration;
};
const logout = async () => {
  if (isImpersonating.value) {
    await store.dispatch("exitImpersonation");
  } else {
    await store.dispatch("logout", {
      reload: true,
    });
  }
};
const isParticipant = computed(() => {
  return permissions.value.includes(PERMISSIONS.PARTICIPANTS);
});
const isApiEmployee = computed(() => {
  return permissions.value.includes(PERMISSIONS.API_EMPLOYEE);
});
const fareFinder = computed(() => {
  return (
    permissions.value.includes(PERMISSIONS.PARTICIPANTS) &&
    !permissions.value.includes(PERMISSIONS.INSTITUTION)
  );
});
const hasSettingsEnabled = computed(() => {
  return featureFlags.value["settings"];
});
const hasUserManagementEnabled = computed(() => {
  return (
    featureFlags.value["user-management"] &&
    (hasItemsWritePermission.value || hasItemsReadPermission.value)
  );
});
const hasHelpLink = computed(() => {
  return featureFlags.value["help-link"];
});
const studentView = computed(() => {
  return (
    permissions.value.includes(PERMISSIONS.API_EMPLOYEE_STUDENT_VIEW) &&
    !isImpersonating.value
  );
});
const hasStudentManagementPermission = computed(() => {
  return (
    permissions.value.includes(PERMISSIONS.STUDENT_MANAGEMENT) &&
    !isImpersonating.value
  );
});
const hasItemsWritePermission = computed(() => {
  return permissions.value.includes(PERMISSIONS.ITEMS_WRITE);
});

const hasItemsReadPermission = computed(() => {
  return permissions.value.includes(PERMISSIONS.ITEMS_READ);
});

const isImpersonating = computed(() => store.getters.isImpersonating);
const userData = computed(() => store.state.userData);
const currentUser = computed(() => store.getters.getCurrentUser);
const permissions = computed(() => store.getters.getPermissions);
const featureFlags = computed(() => store.state.featureFlags);
const dynamicUniversity = computed(
  () => store.getters["university/dynamicUniversity"]
);
const userName = computed(() => {
  return isImpersonating.value
    ? `Student View: ${userData.value.data.firstname} ${userData.value.data.lastname}`
    : `${currentUser.value?.firstName ?? ""} ${
        currentUser.value?.lastName ?? ""
      }`;
});
const userEmail = computed(() => {
  return isImpersonating.value
    ? userData.value?.email
    : currentUser.value.email;
});
const logoutName = computed(() => {
  return isImpersonating.value ? "Student View" : "";
});
onBeforeMount(async () => {
  removeMenuItems();
});
</script>

<style lang="scss" scoped></style>
