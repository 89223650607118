<template>
  <ul>
    <li
      v-for="(item, index) in arrangements"
      :key="index"
      class="border-b border-gray-200"
    >
      <div class="relative">
        <div class="flex">
          <div class="sm:flex-wrap sm:flex sm:items-center">
            <h3 class="text-xl font-semibold sm:mr-4">
              Travel Arrangement
            </h3>
          </div>
        </div>
        <div class="flex absolute right-0">
          <button
            class="flex items-center justify-center w-8 h-8 text-gray-600"
            @click="$emit('delete', item.id)"
          >
            <i class="fa fa-trash"></i>
          </button>
          <button
            class="flex items-center justify-center w-8 h-8 text-gray-600"
            @click="$emit('edit', item.id)"
          >
            <span class="sr-only">Edit</span>
            <svg
              class="feather feather-edit-2 w-4 h-4"
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
              ></path>
            </svg>
          </button>
        </div>

        <table class="w-full table-fixed">
          <tbody class="text-sm text-left">
            <template
              v-for="(value, propName, propIndex) in item"
              :key="propIndex"
            >
              <tr
                v-if="
                  value &&
                  !invalidProps.includes(propName) &&
                  showProp(value, propName)
                "
                class="block my-4 xs:my-0 xs:table-row"
              >
                <td
                  class="block text-xs font-semibold tracking-widest text-gray-500 uppercase xs:py-2 xs:pr-2 xs:table-cell"
                >
                  {{ transformString(propName) }}
                </td>
                <td class="block xs:py-2 xs:pl-2 xs:table-cell">
                  <ul v-if="propName === 'acknowledge'">
                    <li>
                      I know that i will be responsible of my own transportation
                      if i travel outside the arrival or departure guidelines:
                      {{
                        value.studentResponsible
                          ? " I accept"
                          : " I did not accept"
                      }}
                    </li>
                    <li>
                      I know that i should follow any immigration guidelines as
                      i make travel arrangements:
                      {{
                        value.studentFollowsGuidelines
                          ? " I accept"
                          : " I did not accept"
                      }}
                    </li>
                  </ul>
                  <template v-else>
                    {{ getPropValue(value, propName) }}
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </li>
  </ul>
</template>

<script>
import { format, parse } from "date-fns";

export default {
  name: "ArrangementDetailView",
  props: {
    arrangements: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
  },
  emits: ["delete", "edit"],
  data() {
    return {
      invalidProps: ["id", "editView"],
    };
  },
  methods: {
    transformString(str) {
      if (str === "acknowledge") return "Acknowledgements";
      if (str === "otherAirline") return "Airline";
      else return str.replace(/([A-Z])/g, " $1");
    },
    getPropValue(value, prop) {
      return prop === "departureDate" || prop === "arrivalDate"
        ? format(parse(value, "yyyy-MM-dd", new Date()), "MM-dd-yyyy")
        : value;
    },
    showProp(value, prop) {
      if (prop === "airline" && value === "other") return false;
      else return true;
    },
  },
};
</script>
