/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "core-js";
import { createRouter, createWebHistory } from "vue-router";
import apolloProvider from "../../graphql.apollo";
import VueCookies, { useCookies } from "vue3-cookies";
import { CONSTANT_KEYS, TAB_TITLES, FLASH_MESSAGE } from "./constants";
import vSelect from "vue-select";
import ToastPlugin, { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import draggable from "vuedraggable";
import * as Sentry from "@sentry/vue";
import formService from "@/services/form";
import withUUID from "vue-uuid";
import { createVuetify } from "vuetify";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { datadogLogs } from "@datadog/browser-logs";

/**
 * Global components
 *
 * This makes e.g. StudentApplication available in our components as StudentApplication, instead of
 * having to import it each time. Only use this for widely used components; otherwise import
 * them explicitly where needed.
 */
import ErrorBus from "./components/ErrorBus.vue";
import ServerJsonReceiver from "./components/ServerJsonReceiver";
import StudentApplication from "./components/StudentApplication";
import ApplicationSidebar from "./components/ApplicationSidebar";
import FormioForm from "./components/FormioForm";
import BaseHeader from "./components/Header/BaseHeader";
import SignIn from "./components/SignIn";
import UniversityApproval from "./components/UniversityApproval";
import MyApplications from "./components/MyApplications";
import RecommendationParent from "./components/recommendation/RecommendationParent";
import StepTracker from "./components/multistep/StepTracker";
import StepComplete from "./components/multistep/StepComplete";
import StepCurrent from "./components/multistep/StepCurrent";
import StepPending from "./components/multistep/StepPending";
import StepDivider from "./components/multistep/StepDivider";
import ApplicationReceived from "./components/ApplicationReceived";
import TravelPlans from "./components/travels/travel-plans/travel-plans";
import PaymentRequestSummary from "./components/PaymentRequest/Summary";
import PaymentRequestSuccess from "./components/PaymentRequest/Success";
import DesktopSideBar from "./components/Sidebar/DesktopSideBar";
import MobileSideBar from "./components/Sidebar/MobileSideBar";
import FareFinderView from "./components/FareFinder/FareFinderView";
import ExperienceBaseHeader from "./components/ExperiencePage/Header/ExperienceBaseHeader";
import ExperienceBaseFooter from "./components/ExperiencePage/Footer/ExperienceBaseFooter";
import AccountCreationSection from "@/components/auth/AccountCreation/AccountCreationSection.vue";
import MyStudents from "@/university/views/MyStudents";
import { trailingSlash } from "./mixins/helpers";
import VueGtag from "vue-gtag";
import InstantSearch from "vue-instantsearch/vue3/es";

import store from "./store";
import routes from "./routes";
import { createApp } from "vue";
import emitter from "tiny-emitter/instance";

import lowercase from "./directives/lowercase";

//Styles
import "@mdi/font/css/materialdesignicons.css";

require("./bootstrap");
require("./tooltips");

import PrimeVue from "primevue/config";
import Lara from "../presets/lara";

const APP_NAME = "APIConnect";
const app = withUUID(createApp({}));
app.use(PrimeVue, { unstyled: true, pt: Lara });

const BUILD_VERSION = process.env.MIX_GIT_SHA || "";
const APP_ENV = process.env.MIX_APP_ENV || "local";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    release: BUILD_VERSION,
    environment: APP_ENV,
    dsn:
      "https://80c43b62221d49f19395b7fb711e5859@o409860.ingest.sentry.io/5283188",
    logErrors: true,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/connect\.apiabroad\.com.*/],

    // https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/
    trackComponents: true,
    hooks: ["activate", "create", "unmount", "mount", "update"],

    integrations: [
      Sentry.replayIntegration({
        // Text and media can be masked/blocked
        // See: https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/privacy/
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.browserTracingIntegration(),
    ],
  });
}

const DD_CLIENT_ID = process.env.MIX_DD_CLIENT_ID;
const DD_SERVICE = process.env.MIX_DD_SERVICE;

if ((APP_ENV === "production" || APP_ENV === "staging") && DD_CLIENT_ID) {
  console.log("DD logs init");
  datadogLogs.init({
    clientToken: DD_CLIENT_ID,
    service: DD_SERVICE || "api_portal_ui",
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    env: APP_ENV,
    version: BUILD_VERSION,
  });
}

export const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition;
      // For anchors
    } else if (to.hash) {
      return { selector: to.hash };
      // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {};
    }
    // Scroll to top
    window.scrollTo(0, 0);
    return { x: 0, y: 0 };
  },
});
const vuetify = createVuetify();

/**
 * Register an event bus to communicate between components without going thru parent or the store
 */
export const eventBus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
app.component("ErrorBus", ErrorBus);
app.component("ServerJsonReceiver", ServerJsonReceiver);
app.component("DesktopSideBar", DesktopSideBar);
app.component("MobileSideBar", MobileSideBar);
app.component("StudentApplication", StudentApplication);
app.component("ApplicationSidebar", ApplicationSidebar);
app.component("FormioForm", FormioForm);
app.component("BaseHeader", BaseHeader);
app.component("ExperienceHeader", ExperienceBaseHeader);
app.component("ExperienceFooter", ExperienceBaseFooter);
app.component("SignInForm", SignIn);
app.component("UniversityApproval", UniversityApproval);
app.component("MyApplications", MyApplications);
app.component("RecommendationComponent", RecommendationParent);
app.component("StepTracker", StepTracker);
app.component("StepComplete", StepComplete);
app.component("StepCurrent", StepCurrent);
app.component("StepPending", StepPending);
app.component("StepDivider", StepDivider);
app.component("ApplicationReceived", ApplicationReceived);
app.component("TravelPlans", TravelPlans);
app.component("PaymentRequestSummary", PaymentRequestSummary);
app.component("PaymentRequestSuccess", PaymentRequestSuccess);
app.component("FareFinderView", FareFinderView);
app.component("AccountCreationSection", AccountCreationSection);
app.component("MyStudents", MyStudents);

/**
 * Global mixins
 *
 * This makes e.g. trailingSlash available in our components as this.trailingSlash(), instead of
 * having to import it each time. Only use this for widely used helpers; otherwise import
 * them explicitly where needed, e.g. as we do with getNested().
 */
app.mixin({
  data() {
    return {
      buildVersion: BUILD_VERSION,
    };
  },
  computed: {
    primaryColor() {
      return store?.getters?.getBrandingDetails?.primary_color || "";
    },
    secondaryColor() {
      return store?.getters?.getBrandingDetails?.secondary_color || "";
    },
    tertiaryColor() {
      return store?.getters?.getBrandingDetails?.accent_color || "";
    },
    primaryColorClass() {
      if (this.primaryColor) {
        return "brandingPrimaryText";
      } else {
        return "text-university-primary";
      }
    },
    primaryColorBorder() {
      if (this.primaryColor) {
        return "brandingPrimaryBorder";
      } else {
        return "border-university-primary";
      }
    },
    primaryColorClassBG() {
      if (this.primaryColor) {
        return "brandingPrimary";
      } else {
        return "bg-university-primary";
      }
    },
    primaryColorClassOutlined() {
      if (this.primaryColor) {
        return "primaryBrandingOutlined";
      } else {
        return "text-university-primary border-university-primary";
      }
    },
    primaryColorClassOutlinedNoHover() {
      if (this.primaryColor) {
        return "primaryBrandingOutlinedNoHover";
      } else {
        return "text-university-primary border-university-primary";
      }
    },
    secondaryColorClass() {
      if (this.secondaryColor) {
        return "brandingSecondaryText";
      } else {
        return "text-university-secondary";
      }
    },
    secondaryColorClassLinks() {
      if (this.secondaryColor) {
        return "brandingSecondaryText";
      } else {
        return "text-university-secondary";
      }
    },
    secondaryColorClassButton() {
      if (this.secondaryColor) {
        return "brandingSecondaryButton";
      } else {
        return "bg-university-secondary text-white";
      }
    },
    secondaryColorClassAccent() {
      if (this.secondaryColor) {
        return "brandingSecondaryAccent";
      } else {
        return "nonBrandingAccent";
      }
    },
    secondaryColorClassBG() {
      if (this.secondaryColor) {
        return "brandingSecondary";
      } else {
        return "bg-university-secondary";
      }
    },
    secondaryColorClassOutlined() {
      if (this.secondaryColor) {
        return "brandingOutlined";
      } else {
        return "text-university-secondary border-university-secondary";
      }
    },
    secondaryColorClassOutlinedNoHover() {
      if (this.secondaryColor) {
        return "brandingOutlinedNoHover";
      } else {
        return "text-university-secondary border-university-secondary";
      }
    },
    tertiaryColorClass() {
      if (this.tertiaryColor) {
        return "brandingTertiary";
      } else {
        return "bg-university-tertiary";
      }
    },
  },
  methods: {
    trailingSlash,
  },
});

/**
 * Plugins
 *
 * This makes e.g. router available in our components as this.$router, instead of
 * having to import it each time.
 */
app.use(apolloProvider);
app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});
app.use(store);
app.use(router);
app.use(vuetify);
app.use(ToastPlugin, {
  // One of the options
  dismissible: true,
  queue: true,
  position: "bottom",
});
if (process.env.MIX_APP_ENV === "production") {
  app.use(VueGtag, {
    config: { id: "G-RM9T04V8DH" },
    deferScriptLoad: true, // This adds the defer attribute to the script tag
  });
} else {
  app.use(VueGtag, {
    config: { id: "G-95ZZFDDV33" },
    deferScriptLoad: true,
  });
}
app.use(InstantSearch);
app.use(VueQueryPlugin);

app.component("VSelect", vSelect);
app.component("VDraggable", draggable);

app.config.compilerOptions.whitespace = "preserve";

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const { cookies } = useCookies();

const toast = useToast();

if (cookies.get("student_portal_remove_local")) {
  store.dispatch("removeFormioLocalStorage");
}

// Add event to make form.io sections collapsible with enter
document.addEventListener("keydown", (e) => {
  if (e.key === "Enter" && e.target.classList.contains("collapsible-title")) {
    e.target.click();
  }
});

const authorized = function (to) {
  const requiredPermissions = to?.meta?.permissions ?? [];
  if (requiredPermissions.length < 1) {
    return true;
  }
  const userPermissions = store?.getters?.getPermissions ?? [];
  return requiredPermissions.some((permission) =>
    userPermissions.includes(permission)
  );
};

const getTabTitle = (to) => {
  try {
    return TAB_TITLES[to?.params?.form];
  } catch (_) {
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.meta?.addBackBreadcrumb) {
    if (["new-account-creation", "application-resume"].includes(from.name)) {
      to.meta.breadcrumb = from.meta?.breadcrumb ?? [];
    } else {
      to.meta.breadcrumb = [
        { title: "Home", parent: "home" },
        { title: "Back", path: from.fullPath },
      ];
    }
  }

  if (!authorized(to)) {
    next({ name: "error-page" });
  }

  const customTitle = getTabTitle(to);
  if (customTitle) Object.assign(to.meta, { title: customTitle });

  //Redirect if session expired
  if (!to.path.includes("/sign-in") && store.getters.getSessionExpired) {
    const redirect = store.getters.getRedirectPath;
    store.commit("setRedirectPath", "");
    store.commit("setSessionExpired", false);
    localStorage.removeItem("expiredApplicationId");

    if (redirect && !to.path.includes(redirect)) {
      router.push(redirect);
    }
  }

  const token = localStorage.getItem("formioToken") ?? "";
  //Set current user data
  if (
    token &&
    !store?.getters?.isLoggedIn &&
    !to.path.includes("/landing-page")
  ) {
    if (store.getters.getPermissions.includes("student-management")) {
      try {
        let user = await formService.currentFormServiceUser();
        await store.dispatch(
          "university/updateUniversity",
          user.data.university.value
        );
        store.commit("university/setUserDetails", {
          email: user.data.email,
          name: user.data.firstname + " " + user.data.lastname,
          university: user.data.university.label,
          universityId: user.data.university.value,
          loggedIn: true,
          dynamicUniversity: user.data.dynamicUniversity,
        });
      } catch (e) {
        console.log("fetch user failed", e);
      }
    }
  }

  //Set Employe if a new tab is open
  let hasStudentViewPermission = store.getters.getPermissions.includes(
    "api-employee-student-view"
  );

  let authSessionExpired = cookies.get("authSessionExpired")
    ? !!JSON.parse(cookies.get("authSessionExpired"))
    : false;

  if (authSessionExpired && hasStudentViewPermission) {
    store.dispatch("exitImpersonation");
  }

  if (
    Object.hasOwnProperty.call(to, "params") &&
    Object.hasOwnProperty.call(to["params"], "applicationId") &&
    !to.path.includes("/payment-request")
  ) {
    let toApplicationId = to.params.applicationId;
    let fromApplicationId = "";

    if (Object.hasOwnProperty.call(from, "params"))
      fromApplicationId = from.params.applicationId ?? "";

    if (
      from.path !== "/applications" &&
      fromApplicationId !== toApplicationId
    ) {
      await store.dispatch("getUiVersion", toApplicationId);
      next();
    } else {
      next();
    }
  } else if (
    to.path.includes("/applications/new") ||
    to.path.includes("/application-create-account")
  ) {
    // Students wants to apply to a session in UI v2 or V3
    if (from.path !== "/applications") {
      store.commit("setUiVersion", CONSTANT_KEYS.UI_VERSION);
    }
    next();
  } else {
    store.commit("setUiVersion", "");
    next();
  }
});

//Validate if token still valid

router.afterEach((to) => {
  //Some of the URLs are not available on vue routes file and they cannot use exemptFromLogin pro

  // Scroll to top
  // window.scrollTo(0, 0); <-- hopefully this will be handle by scrollbehavior in router config above
  if (documentTitleGuard(to)) document.title = to.meta.title + " | " + APP_NAME;

  if (!localStorage.getItem("formioToken")) {
    store.commit("setFormioToken");
  }

  // Set application ID in redirect if present
  if (
    Object.hasOwnProperty.call(to, "params") &&
    Object.hasOwnProperty.call(to["params"], "applicationId") &&
    to["params"]["applicationId"]
  ) {
    store.commit("setCurrentApplicationId", to["params"]["applicationId"]);

    //Validate enrollment status
    if (!store.state.studentApplications.length) {
      if (!store.state.formioToken) {
        store.commit("setFormioToken");
      }
      store.dispatch("getEnrollmentData");
    }
  }
});

router.onError(error => {
  console.error('Router error:', error);
});

function documentTitleGuard(navigationMeta) {
  if (!Object.hasOwnProperty.call(navigationMeta, "meta")) return false;
  if (!Object.hasOwnProperty.call(navigationMeta["meta"], "title"))
    return false;
  if (!navigationMeta["meta"]["title"]) return false;
  return true;
}

function handleFlashMessage() {
  const urlParams = new URLSearchParams(window.location.search);
  const flashMessageFlag = urlParams.get('invalid_subdomain');

  if (flashMessageFlag === 'true') {
    toast.error(FLASH_MESSAGE, {
      position: "bottom",
    });

    setTimeout(() => {
      let newUrl = window.location.pathname + window.location.search.replace(/([?&])invalid_subdomain=true(&|$)/, '$1').replace(/&$/, '');
      newUrl.endsWith('?') ? newUrl = newUrl.slice(0, -1) : newUrl;
      window.history.replaceState({}, document.title, newUrl);
    }, 3000);
  }
}


handleFlashMessage();

app.config.errorHandler = (err, vm, info) => {
  console.error("Error:", err);
  console.error("Vue component:", vm);
  console.error("Additional info:", info);
};

app.directive("lowercase", lowercase);

app.mount("#app");

if (window.Cypress) {
  // only available during E2E tests
  window.app = app;
}
