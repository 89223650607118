import { getApiClient } from "./API";
import { learningServiceRequest, skip } from "./utils";

const internshipService = {
  async list({ source, limit, page, ...params }) {
    const response = await getApiClient().get(`/internships`, {
      params: { ...params, limit, skip: page ? skip(page, limit) : 0 },

      cancelToken: source?.token,
      paramsSerializer: (params) => {
        const queryList = [];
        for (const [key, value] of Object.entries(params)) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              queryList.push(`${key}=${encodeURIComponent(item)}`);
            });
          } else {
            queryList.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
        return queryList.join("&");
      },
    });
    return response.data;
  },

  async create(data) {
    const response = await getApiClient().post(`/internships`, data);
    return response.data;
  },

  async update(id, data) {
    const response = await getApiClient().put(`/internships/${id}`, data);
    return response;
  },

  async getOne(id) {
    const response = await getApiClient().get(`/internships/${id}?get_archived=true`);
    return response.data;
  },

  async archiveMany(data) {
    const response = await getApiClient().put(`/internships/archive/`, data);
    return response.data;
  },

  async archive(id) {
    const response = await getApiClient().put(`/internships/archive/${id}`);
    return response.data;
  },

  async getInternships({
    limit = "",
    page = 1,
    q = "",
    status = "",
    source,
    ...extraParams
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/internships",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        status,
        ...extraParams,
      },
      failureMessage: "Error loading Internship data",
      source,
    });
  },

  async getMany({ internship_ids, source }) {
    return await learningServiceRequest({
      method: "post",
      url: "/internships/get_many",
      payload: { internship_ids },
      failureMessage: "Error loading Internship data",
      source,
    });
  },
};

export default internshipService;
