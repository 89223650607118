import { cloneDeep } from "lodash";

export const removeNodeAndChildren = (node, selectedValuesMap) => {
  selectedValuesMap[node.key] = { checked: false, partialChecked: false };
  if (node.children) {
    for (const child of node.children) {
      removeNodeAndChildren(child, selectedValuesMap);
    }
  }
};

export const removeNode = (node, key, selectedValuesMap) => {
  if (node.key === key) {
    removeNodeAndChildren(node, selectedValuesMap);
    return true;
  } else {
    if (node.children) {
      for (const child of node.children) {
        const isChild = removeNode(child, key, selectedValuesMap);
        if (isChild) {
          const foundCheckSiblings = node.children.filter(
            (val) =>
              selectedValuesMap[val.key]?.checked ||
              selectedValuesMap[val.key]?.partialChecked
          );
          if (foundCheckSiblings.length) {
            selectedValuesMap[node.key] = {
              checked: false,
              partialChecked: true,
            };
          } else {
            selectedValuesMap[node.key] = {
              checked: false,
              partialChecked: false,
            };
          }
        }
      }
    }
  }
  return false;
};

export const addNode = (node, key, selectedValuesMap) => {
  if (node.key === key) {
    return true;
  } else {
    if (node.children) {
      for (const child of node.children) {
        const isChild = addNode(child, key, selectedValuesMap);
        if (isChild) {
          const foundCheckSiblings = node.children.filter(
            (val) =>
              selectedValuesMap[val.key]?.checked ||
              selectedValuesMap[val.key]?.partialChecked
          );
          if (foundCheckSiblings.length) {
            selectedValuesMap[node.key] = {
              checked: !!selectedValuesMap?.[node.key]?.checked,
              partialChecked: true,
            };
          }
        }
      }
    }
  }
  return false;
};

export const setPartials = (node, selectedValuesMap) => {
  let childCheckeds = 0;
  let childPartials = 0;
  if (node?.children?.length > 0) {
    for (const child of node.children) {
      const checkedKids = setPartials(child, selectedValuesMap);
      if (checkedKids.checked) {
        childCheckeds++;
      }
      if (checkedKids.partialChecked) {
        childPartials++;
      }
    }

    if (childCheckeds === node?.children?.length) {
      selectedValuesMap[node.key] = {
        checked: true,
        partialChecked: false,
      };
    } else if (childCheckeds > 0 || childPartials > 0) {
      selectedValuesMap[node.key] = {
        checked: false,
        partialChecked: true,
      };
    } else {
      selectedValuesMap[node.key] = {
        checked: false,
        partialChecked: false,
      };
    }
  }
  const foundNode = selectedValuesMap[node.key];
  return {
    checked: !!foundNode?.checked,
    partialChecked: !!foundNode?.partialChecked,
  };
};

export const getFullNodeMap = (options) => {
  const stack = [...options];
  const map = {};
  while (stack.length > 0) {
    const node = stack.pop();
    map[node.key] = cloneDeep(node);
    node?.children?.forEach((node) => stack.push(node));
  }
  return map;
};
