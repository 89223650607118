<template>
  <div v-if="showNetworkOfSupportTile" class="flex flex-col">
    <router-link
      :to="{
        name: 'enrollment/network-of-support',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Network of Support
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
import formIoApi from "../../mixins/formIoApi";
import postFormRules from "../../mixins/postFormRules";
import { eventBus } from "../../app";
import { mapState } from "vuex";

export default {
  name: "NetworkOfSupportCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi, postFormRules],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["currentApplicationSubmissions", "program"]),
    completed() {
      const formPaths = [
        "universitysupport",
        "communicatinginanemergency",
        "mysupport",
      ];
      if (this.program.program.customized) {
        return ["communicatinginanemergency", "mysupport"].every((form) =>
          Object.keys(this.currentApplicationSubmissions).includes(form)
        );
      }
      return formPaths.every((formPath) =>
        Object.hasOwn(this.currentApplicationSubmissions, formPath)
      );
    },
    buildQueryString() {
      return "data.application_id=" + this.applicationId + "&state=submitted";
    },
  },
  async mounted() {
    if (this.showNetworkOfSupportTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Network of Support",
        ruleName: "Network of Support",
        routeName: "enrollment/network-of-support",
        submitted: this.completed,
      });
    }
  },
};
</script>

<style></style>
