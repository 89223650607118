<template>
  <nav class="flex justify-between py-5" aria-label="Table navigation">
    <span v-if="pagination.total > 0" class="page-stats">
      {{ pagination.from }} - {{ pagination.to }} of
      {{ pagination.total }}
    </span>
    <ul v-if="pagination.total > 25" class="flex">
      <li class="page-item">
        <button
          v-if="pagination.prevPageUrl"
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `${secondaryColorClass} hover:bg-teal-100 focus:bg-teal-100`,
          ]"
          class="border-2 border-transparent font-semibold py-2 px-4 bg-white hover:text-white rounded"
          @click="$emit('prev')"
        >
          Previous
        </button>
        <button v-else class="opacity-50 cursor-not-allowed" :disabled="true">
          Previous
        </button>
      </li>
      <li class="page-item">
        <button
          v-if="pagination.nextPageUrl"
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `${secondaryColorClass} hover:bg-teal-100 focus:bg-teal-100`,
          ]"
          class="border-2 border-transparent font-semibold py-2 px-4 bg-white hover:text-white rounded"
          @click="$emit('next')"
        >
          Next
        </button>

        <button v-else class="opacity-50 cursor-not-allowed" :disabled="true">
          Next
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["prev", "next"],
  computed: {
    secondaryColorClass() {
      const { secondaryColor } = this;
      if (secondaryColor) {
        return "brandingOutilined";
      } else {
        return "text-university-secondary border-university-secondary";
      }
    },
  },
};
</script>

<style scoped>
ul li.page-item button {
  cursor: pointer;
  text-decoration: none;

  @apply py-2 px-4;
  @apply text-university-primary font-semibold;
  @apply border border-blue-500 rounded bg-transparent;
}
ul li.page-item button:hover {
  @apply bg-blue-500 text-white border-transparent;
}
ul li button.disabled-link,
ul li button.disabled-link:hover {
  @apply py-2 px-4 mx-2;
  @apply bg-gray-300 rounded-l;
  @apply text-gray-700 font-bold;
}
</style>
