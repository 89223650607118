<script>
export default {
  name: "StatusFulfillmentModal",
};
</script>

<script setup>
import { toRefs, ref, computed } from "vue";
import pLimit from "p-limit";

import orders from "@/services/orders";
import {
  CANCELLATION_REASONS_OTHER,
  CANCELLATION_REASONS,
  MAX_NOTE_TEXTAREA_LIMIT,
  REGULAR_MISC_PRODUCTS,
  SPECIAL_MISC_PRODUCTS,
  ERROR_TIMEOUT,
  SUCCESS_TIMEOUT
} from "@/constants";
import {
  GENERAL_GROUP_VISA_SERVICE,
  SPAIN_GROUP_VISA_SERVICE,
  UMASS_TRANSCRIPT_SERVICES,
  UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES,
  CAREER_COACHING,
  PROPRIETARY_CONTENT,
  CREDENTIAL,
  INTERNSHIP_COURSE,
} from "@/components/program-manager/sessions/constants";
import { useGetProductPrices } from "@/components/StudentFulfillment/composables/orders";
import { useToast } from "vue-toast-notification";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import PlusIcon from "@/components/shared/icons/PlusIcon.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import { convertCentsToDolarAsCurrency } from "@/services/utils.js";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";
import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import SimpleTextarea from "@/components/shared/SimpleTextarea.vue";
import { ERROR_MESSAGE } from "@/components/StudentFulfillment/components/modals/HousingFulfillmentModal/constants";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

const FIXED_PRICE_ADDONS = [
  GENERAL_GROUP_VISA_SERVICE,
  SPAIN_GROUP_VISA_SERVICE,
  UMASS_TRANSCRIPT_SERVICES,
  UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES,
  CAREER_COACHING,
  PROPRIETARY_CONTENT,
  CREDENTIAL,
  INTERNSHIP_COURSE,
].map(({ id, name, price_in_cents }) => ({
  new_product_id: id,
  total_price_in_cents: price_in_cents,
  name,
  status: "Unfulfilled",
  product_type: "MISC",
}));

const props = defineProps({
  modalIsOpen: {
    type: Boolean,
    default: true,
  },
  orderId: {
    type: String,
    default: "",
  },
  sessionId: {
    type: String,
    default: "",
  },
  statusOptions: {
    type: Array,
    default: () => [],
  },
  profileName: {
    type: String,
    default: "",
  },
  selectedMiscProducts: {
    type: Array,
    default: () => [],
  },
});

const {
  orderId,
  modalIsOpen,
  sessionId,
  statusOptions,
  profileName,
  selectedMiscProducts,
} = toRefs(props);

const toast = useToast();

const plimit = pLimit(2);

const emit = defineEmits(["closeModal"]);

const isSaving = ref(false);

const newMiscProducts = ref([]);

const checkOtherCancellationReason = (cancellationReason) =>
  cancellationReason && CANCELLATION_REASONS.findIndex(({ id }) => id === cancellationReason) < 0;

const updateMiscProductsDefaultValue = () =>
  selectedMiscProducts.value
    ?.filter(({ misc_product }) => !SPECIAL_MISC_PRODUCTS[misc_product.id])
    ?.map(
      ({
        misc_product,
        status,
        quantity,
        total_price_in_cents,
        cancellation_reason,
      }) => ({
        new_product_id: misc_product.id,
        name: misc_product.name,
        total_price_in_cents,
        status,
        quantity,
        cancellationReason: 
          checkOtherCancellationReason(cancellation_reason) ?
          CANCELLATION_REASONS_OTHER :
          cancellation_reason,
        otherCancellationReason: 
          checkOtherCancellationReason(cancellation_reason) ?
            cancellation_reason :
            "",
      })
    ) || [];

const updateMiscProducts = ref(updateMiscProductsDefaultValue());

const rulesNewProducts = {
  $each: helpers.forEach({
    product: {
      required: helpers.withMessage(
        "Please, provide a valid product to be added",
        required
      ),
    },
  }),
};

const rulesUpdateProducts = {
  $each: helpers.forEach({
    cancellation_reason: {
      requiredIfCanceled: helpers.withMessage(
        "Cancellation reason is required when status is Canceled",
        (value, parentVm) => (parentVm.status === "Canceled" ? !!value : true)
      ),
    },
  }),
};

const createV$ = useVuelidate(rulesNewProducts, newMiscProducts);

const updateV$ = useVuelidate(rulesUpdateProducts, updateMiscProducts);

const { state: productPricesDetails } = useGetProductPrices(
  { immediate: true, throwError: true, resetOnExecute: false },
  { session_id: sessionId.value, limit: 10, skip: 0 }
);

const productItemsOptions = computed(
  () =>
    [
      ...(productPricesDetails.value?.data?.map(
        ({ product_id, price_in_cents }) => ({
          new_product_id: product_id,
          total_price_in_cents: price_in_cents,
          name: REGULAR_MISC_PRODUCTS[product_id],
          status: "Unfulfilled",
          product_type: "MISC",
        })
      ) || []),
      ...FIXED_PRICE_ADDONS,
    ]?.filter(
      ({ new_product_id }) =>
        !SPECIAL_MISC_PRODUCTS[new_product_id] &&
        ![...updateMiscProducts.value, ...newMiscProducts.value].find(
          (item) => item.new_product_id === new_product_id
        )
    ) || []
);

const enableAddButton = computed(() => !!productItemsOptions.value?.length);

const savingErrorToast = (message) => {
  toast.open({
    message: `${message ?? ERROR_MESSAGE}`,
    type: "error",
    position: "bottom",
    duration: ERROR_TIMEOUT,
  });
};

const handleCloseModal = () => {
  newMiscProducts.value = [];
  emit("closeModal");
};

const addNewLine = () => {
  newMiscProducts.value.push({
    name: undefined,
    status: "Unfulfilled",
    product_type: "MISC",
  });
};

const hideNewLine = (index) => {
  newMiscProducts.value.splice(index, 1);
};

const updateAllSelectedOrderAddons = async () =>
  await Promise.allSettled(
    updateMiscProducts.value?.map(({cancellationReason, otherCancellationReason, ...item}) =>
      // Limiting the amount of concurrent requests
      plimit(async () => {
        try {
          item.cancellation_reason = 
            cancellationReason === CANCELLATION_REASONS_OTHER ?
            otherCancellationReason :
            cancellationReason;
          const { data } = await orders.updateOrderLineItem({
            orderId: orderId.value,
            productId: item.new_product_id,
            payload: item,
            skipErrorBus: true,
          });
          const index =
            selectedMiscProducts.value?.findIndex(
              ({ misc_product }) => misc_product.id === item.new_product_id
            ) ?? -1;
          if (index >= 0) {
            selectedMiscProducts.value[index].status = item.status;
          }
          return data;
        } catch (err) {
          savingErrorToast(
            `Error changing status of ${item.name}. Please, try it later.`
          );
          throw err;
        }
      })
    ) || []
  );

const createNewSelectedOrderAddons = async () =>
  await Promise.allSettled(
    newMiscProducts.value?.map((product) =>
      // Limiting the amount of concurrent requests
      plimit(async () => {
        try {
          const { data } = await orders.createOrderLineItem({
            orderId: orderId.value,
            productId: product.new_product_id,
            payload: { ...product },
            skipErrorBus: true,
          });
          const new_product = data?.data?.product_details?.misc_products?.find(
            ({ misc_product }) => misc_product?.id === product.new_product_id
          );
          if (new_product) {
            selectedMiscProducts.value.push(new_product);
            updateMiscProducts.value.push(product);
          }
          return data;
        } catch (err) {
          savingErrorToast(
            `Error adding ${product.name}. Please, try it later.`
          );
          throw err;
        }
      })
    ) || []
  );

const handleSaveAddOns = async () => {
  createV$.value.$reset();
  updateV$.value.$reset();
  const isValidUpdate = await updateV$.value.$validate();
  const isValidCreate = await createV$.value.$validate();

  if (!isValidCreate) {
    savingErrorToast("Please, provide a valid product to be added");
    return;
  }
  if (!isValidUpdate) {
    savingErrorToast("Cancellation reason is required when status is Canceled");
    return;
  }

  try {
    isSaving.value = true;
    const updateResponse = await updateAllSelectedOrderAddons();
    const createResponse = newMiscProducts.value?.length
      ? await createNewSelectedOrderAddons()
      : [];
    if (
      [...updateResponse, ...createResponse].every(
        ({ status }) => status === "rejected"
      )
    ) {
      throw new Error("Error saving data");
    }
    toast.open({
      message: "Order item status updated/created successfully",
      type: "success",
      position: "bottom",
      duration: SUCCESS_TIMEOUT,
    });
  } catch (exception) {
    savingErrorToast();
  } finally {
    isSaving.value = false;
    newMiscProducts.value = [];
    handleCloseModal();
  }
};
</script>

<template>
  <div>
    <div v-if="modalIsOpen" class="relative z-50">
      <div
        class="fixed inset-0 z-40 hidden bg-university-primary opacity-50 sm:block"
      ></div>
      <Dialog
        :open="modalIsOpen"
        class="w-screen fixed inset-0 z-50 overflow-auto bg-white py-6 sm:bg-transparent sm:p-12 sm:p-6"
        @close-modal="handleCloseModal"
        @click.self="handleCloseModal"
      >
        <div
          class="relative z-50 mx-auto max-w-6xl bg-white px-6 sm:rounded-lg sm:px-16 sm:py-20 sm:shadow md:px-24"
        >
          <div
            class="absolute right-3r xs:top-1r sm:top-3r md:top-3r lg:top-3r"
          >
            <button
              class="flex h-6 w-6 items-center justify-center"
              @click="handleCloseModal"
            >
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <DialogPanel>
            <DialogTitle
              class="mb-4 text-center text-xl sm:text-2xl md:text-3xl"
            >
              {{ profileName ? `${profileName}'s ` : "" }}Add-ons
            </DialogTitle>
            <table class="w-full">
              <tr
                class="grid grid-cols-4 md:grid-cols-[1fr,1fr,10rem,10rem] border-b-[1px] border-solid border-b-gray-200"
              >
                <th class="py-3 text-left">Add-on Name</th>
                <th class="py-3 text-left">More Info</th>
                <th class="py-3 text-left">Total Price</th>
                <th class="sm:w-40 py-3 text-left">Status</th>
              </tr>
              <tr
                class="grid grid-cols-4 md:grid-cols-[1fr,1fr,10rem,10rem] border-b-[1px] border-solid border-b-gray-200"
                :key="`update-product-${index}`"
                v-for="(misc_product, index) in updateMiscProducts"
              >
                <td id="product-name-data" class="py-6 text-left">
                  {{ misc_product?.name }}
                </td>
                <td id="product-more-info-data" class="py-6 text-left"></td>
                <td id="product-price-data" class="py-6 text-left">
                  {{
                    convertCentsToDolarAsCurrency(
                      misc_product?.total_price_in_cents
                    )
                  }}
                </td>
                <td id="product-status-data" class="sm:w-40 py-6">
                  <VSelectCaret
                    v-model="misc_product.status"
                    :clearable="false"
                    outer-classes="w-full checkbox-placeholder"
                    scroll-input="auto"
                    :options="statusOptions"
                  ></VSelectCaret>
                </td>
                <td
                  id="product-reason-cancellation-data"
                  class="col-start-2 col-end-5 flex items-center justify-end py-6"
                  v-if="misc_product.status === 'Canceled'"
                >
                  <label class="mr-2" for="reason-for-cancelation"
                    >Reason for Cancellation</label
                  >
                  <VSelectCaret
                    id="reason-for-cancelation"
                    v-model="misc_product.cancellationReason"
                    label="label"
                    outer-classes="w-4/5 checkbox-placeholder"
                    scroll-input="auto"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    :options="CANCELLATION_REASONS"
                  ></VSelectCaret>
                </td>
                <td
                  id="product-reason-other-cancellation-data"
                  class="col-start-2 col-end-5 flex items-center justify-end py-6"
                  v-if="
                    misc_product.status === 'Canceled' &&
                    misc_product.cancellationReason === CANCELLATION_REASONS_OTHER
                  "
                >
                  <SimpleTextarea
                    v-model="misc_product.otherCancellationReason"
                    label-name="Cancellation Reason"
                    outer-classes="w-full"
                    :max-length="MAX_NOTE_TEXTAREA_LIMIT"
                    :disabled="isSaving"
                  />
                </td>
              </tr>
              <tr
                class="grid grid-cols-4 md:grid-cols-[1fr,1fr,10rem,10rem] border-b-[1px] border-solid border-b-gray-200"
                :key="`new-product-${index}`"
                v-for="(newItem, index) in newMiscProducts"
              >
                <td id="select-new-product-name" class="mr-10 py-6 col-start-1 col-end-3 md:col-end-2">
                  <VSelectCaret
                    v-model="newMiscProducts[index]"
                    outer-classes="checkbox-placeholder"
                    label="name"
                    placeholder="Add-on Name"
                    scroll-input="auto"
                    :clearable="false"
                    :options="productItemsOptions"
                  ></VSelectCaret>
                </td>
                <td id="new-product-price" class="py-6 col-start-3">
                  {{
                    convertCentsToDolarAsCurrency(newItem?.total_price_in_cents)
                  }}
                </td>
                <td id="new-product-remove" class="text-right">
                  <button
                    id="new-product-remove-button"
                    class="py-6 duration-300 hover:scale-110 hover:text-red-100"
                    @click="hideNewLine(index)"
                  >
                    <TrashIcon class="h-5" />
                  </button>
                </td>
              </tr>
            </table>
            <button
              class="mt-4 flex w-fit cursor-pointer items-center text-teal-900 duration-300 hover:scale-110"
              v-if="enableAddButton"
              @click="addNewLine"
            >
              <PlusIcon class="mr-2" />
              <p class="text-sm font-semibold uppercase">New Add-on</p>
            </button>
          </DialogPanel>
          <div class="mt-8 flex justify-center">
            <ButtonWithSpinner
              id="cancel-btn"
              ref="cancelBtn"
              data-cy="cancel-btn"
              variant="secondary-outlined"
              custom-class="mr-5 w-107px"
              @click="handleCloseModal"
            >
              <span>Cancel </span>
            </ButtonWithSpinner>
            <ButtonWithSpinner
              :prop-loading="isSaving"
              id="save-btn"
              ref="saveBtn"
              data-cy="save-btn"
              variant="secondary"
              custom-class="w-107px"
              @click="handleSaveAddOns"
            >
              <span>Save</span>
            </ButtonWithSpinner>
          </div>
        </div>
      </Dialog>
    </div>
  </div>
</template>
