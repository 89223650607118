<template>
  <form-panel
    :id="'current-address'"
    :title="'Current Address'"
    :initially-expanded="!sameAsBilling"
    panel-content-class="grid grid-cols-1 gap-4 pb-6 mb-6 border-b border-gray-200 billing-form md:grid-cols-2 sm:gap-6"
  >
    <template v-if="!isStandAloneComponent" #panelHeaderCheckbox>
      <label class="flex text-sm md:col-span-2">
        <input
          v-model.lazy="value.sameAsBilling"
          data-cy="checkSameBillingAddress"
          type="checkbox"
          :class="`${primaryColorClass}`"
          class="mt-1 form-checkbox"
        />
        <span class="ml-2">Same as billing address</span>
      </label>
    </template>
    <template #content>
      <label class="block text-sm md:col-span-2">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Address</span>
        </span>
        <input
          v-model.lazy="value.address"
          type="text"
          class="form-input block w-full min-h-10"
          data-cy="currentAddress"
          :class="[
            inputIsDisabled,
            { 'bg-error-100': v$.value.address.$error },
          ]"
          :readonly="sameAsBilling"
        />
        <div
          v-if="v$.value.address.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.address.$error }"
        >
          Field is required
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>City</span>
        </span>
        <input
          v-model.lazy="value.city"
          type="text"
          data-cy="currentAddressCity"
          class="form-input block w-full min-h-10"
          :class="[inputIsDisabled, { 'bg-error-100': v$.value.city.$error }]"
          :readonly="sameAsBilling"
        />
        <div
          v-if="v$.value.city.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.city.$error }"
        >
          Field is required
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>State/Province</span>
        </span>
        <input
          v-model.lazy="value.state"
          type="text"
          data-cy="currentAddressState"
          class="form-input block w-full min-h-10"
          :class="[inputIsDisabled, { 'bg-error-100': v$.value.state.$error }]"
          :readonly="sameAsBilling"
        />
        <div
          v-if="v$.value.state.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.state.$error }"
        >
          Field is required
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Country</span>
        </span>
        <VSelect
          v-model="value.country"
          data-cy="currentAddressCountry"
          :value="value.country"
          :reduce="(country) => country.value"
          :options="countries"
          aria-label="Country"
          :class="[
            inputIsDisabled,
            { 'bg-error-100': v$.value.country.$error },
          ]"
          :disabled="sameAsBilling"
          @search="fetchCountries"
        ></VSelect>
        <div
          v-if="v$.value.country.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.country.$error }"
        >
          Field is required
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Post Code</span>
        </span>
        <input
          v-model.lazy="value.postCode"
          data-cy="currentAddressPostCode"
          type="text"
          class="form-input block w-full min-h-10"
          :class="[
            inputIsDisabled,
            { 'bg-error-100': v$.value.postCode.$error },
          ]"
          :readonly="sameAsBilling"
        />
        <div
          v-if="v$.value.postCode.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.postCode.$error }"
        >
          Field is required
        </div>
      </label>
    </template>
  </form-panel>
</template>

<script>
import formPanel from "../SharedComponents/panel";
import countryListApi from "../../../mixins/countryListApi";

import { required } from "@vuelidate/validators";
import { eventBus } from "../../../app";
import useVuelidate from "@vuelidate/core";

export default {
  name: "CurrentAddress",
  components: { formPanel },
  mixins: [countryListApi],
  props: {
    currentAddress: {
      type: Object,
      default: function () {
        return {};
      },
    },
    billingAddress: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isStandAloneComponent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:current-address"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        address: "",
        city: "",
        state: "",
        country: "United States of America",
        postCode: "",
        sameAsBilling: false,
      },
    };
  },
  computed: {
    sameAsBilling() {
      return this.value.sameAsBilling;
    },
    inputIsDisabled() {
      return this.sameAsBilling
        ? "opacity-33 cursor-not-allowed border-2 bg-gray-200 text-gray-700"
        : "";
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:current-address", this.value);
      },
      deep: true,
      immediate: true,
    },
    billingAddress: function () {
      this.value.address = this.billingAddress.address;
      this.value.state = this.billingAddress.state;
      this.value.city = this.billingAddress.city;
      this.value.country = this.billingAddress.country;
      this.value.postCode = this.billingAddress.postCode;
    },
    sameAsBilling(newVal) {
      if (!this.isStandAloneComponent) {
        eventBus.$emit("billingAddressToCurrent", newVal);
      }
    },
  },
  validations: {
    value: {
      address: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      country: {
        required,
      },
      postCode: {
        required,
      },
    },
  },
};
</script>
