<template>
  <component
    :is="component.componentName"
    :parent-mounted="grandParentMounted"
  />
</template>

<script>
import prospective from "./tabs/ProspectiveStudentTabContent";
import aplication from "./tabs/ApplicationTabComponent";
import accepted from "./tabs/AcceptedTabContentCompnent";
import onSite from "./tabs/OnSiteTabContentComponent";
import withdrawn from "./tabs/WithdrawnTabComponent";
import all from "./tabs/AllTabComponent";
import alumni from "./tabs/AlumniTabContentCompnent";
import { mapGetters } from "vuex";

export default {
  components: {
    prospective: prospective,
    application: aplication,
    accepted: accepted,
    onsite: onSite,
    withdrawn: withdrawn,
    all: all,
    alumni: alumni,
  },
  props: {
    grandParentMounted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("university", {
      component: "currentTabComponent",
    }),
  },
};
</script>
