import { learningServiceRequest, skip } from "./utils";

const programSessionsService = {
  async getProgramSessions({
    limit = 10,
    page = 1,
    q = "",
    source,
    extraParams = {},
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/programming/sessions",
      source,
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      failureMessage: "Error loading program sessions data",
    });
  },
  async getProgramSession({ id, ...params }) {
    return await learningServiceRequest({
      method: "get",
      url: `/programming/sessions/${id}`,
      failureMessage: "Error loading program session data",
      params,
    });
  },
  async updateOrCreateProgramSession({ updatedSession }) {
    if (updatedSession.id) {
      return await learningServiceRequest({
        method: "put",
        url: `/programming/sessions/${updatedSession.id}?rules=True&housing=True`,
        payload: updatedSession,
        skipErrorBus: true,
      });
    } else {
      return await learningServiceRequest({
        method: "post",
        url: `/programming/sessions/?rules=True&housing=True`,
        payload: updatedSession,
        skipErrorBus: true,
      });
    }
  },
  async duplicateProgramSession({ id }) {
    return await learningServiceRequest({
      method: "post",
      url: `/programming/sessions/${id}/copy`,
      failureMessage: "Error loading program session data",
    });
  },
  async getProgramSessionSummary({ id, ...params }) {
    return await learningServiceRequest({
      method: "get",
      url: `/programming/sessions/${id}/summary`,
      failureMessage: "Error loading program session data",
      params,
    });
  },
};

export default programSessionsService;
