import { isValid, parseISO } from "date-fns";

function validateContractDateType(startDate, endDate) {
  // Validate that dates have right Type
  return typeof startDate === "string" && typeof endDate === "string";
}

function setTime(setAsEndDay = false, dateToChange = new Date()) {
  // Sets time in a date
  if (setAsEndDay) {
    dateToChange.setHours(23);
    dateToChange.setMinutes(59);
    dateToChange.setSeconds(59); // xxxx:xx:xx 23:59:59
  } else {
    dateToChange.setHours(0);
    dateToChange.setMinutes(0);
    dateToChange.setSeconds(0); // xxxx:xx:xx 00:00:00
  }
  return dateToChange;
}

function betweenDates(startDate, endDate) {
  // Check if curret date is between Start and End date
  if (
    !(startDate instanceof Date) ||
    !(endDate instanceof Date) ||
    !isValid(startDate) ||
    !isValid(endDate)
  ) {
    return false;
  }
  const currentTime = new Date().getTime();
  const startTime = setTime(false, startDate).getTime();
  const endTime = setTime(true, endDate).getTime();
  return currentTime >= startTime && currentTime <= endTime;
}

export default function getApplicableContract(contracts, programSession) {
  if (!Array.isArray(contracts) || !contracts?.length) return;

  // Gather terms of program session
  if (!programSession?.terms?.length) return undefined;
  const termsFromSession = programSession.terms.map((term) => term.name);

  // Check if program session has internship
  const hasInternship = programSession?.session_types.some(
    (type) => type.id === 2
  );

  // Check if program session has study abroad
  const hasStudy = programSession?.session_types.some((type) => type.id === 1);

  // Filter contracts that are between start and end dates and active
  const validContracts = contracts.filter((contract) => {
    // Check that at least fields are strings
    return (
      validateContractDateType(
        contract?.contract_start_date,
        contract?.contract_end_date
      ) &&
      betweenDates(
        parseISO(contract.contract_start_date),
        parseISO(contract.contract_end_date)
      ) &&
      contract?.status === "Activated"
    );
  });

  for (let contract of validContracts) {
    if (
      contract.programs.some(
        (program) =>
          program?.salesforce_program_session_id ===
          programSession?.salesforce_id
      )
    ) {
      return contract;
    }

    for (let contractTerm of contract.terms) {
      if (termsFromSession.includes(contractTerm)) {
        if (hasStudy && contract.all_hosts) {
          return contract;
        } else if (hasStudy) {
          const hostsFromContract = contract?.hosts.map(
            (host) => host.salesforce_host_university_id
          );
          const hasHost = programSession.session_hosting_institutions.some(
            (host) => hostsFromContract.includes(host.entity_id)
          );
          if (hasHost) return contract;
        } else if (hasInternship && contract.all_sites) {
          return contract;
        } else if (hasInternship) {
          const sitesFromContract = contract?.sites.map((site) => site.name);
          const hasSite = programSession.session_travel_details.some(
            (travel_details) =>
              sitesFromContract.includes(travel_details.arrival_city.city)
          );

          if (hasSite) return contract;
        }
      }
    }
  }

  return undefined;
}
