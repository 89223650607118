<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mx-2.5"
  >
    <path
      d="M17.1673 2.47801L15.5223 0.833008L9.00065 7.35468L2.47898 0.833008L0.833984 2.47801L7.35565 8.99967L0.833984 15.5213L2.47898 17.1663L9.00065 10.6447L15.5223 17.1663L17.1673 15.5213L10.6457 8.99967L17.1673 2.47801Z"
      fill="currentColor"
    />
  </svg>
</template>
