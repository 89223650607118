import {
  CLOUDINARY_DEFAULT_IMAGE_COMPRESSION,
  CLOUDINARY_DEFAULT_IMAGE_CROP,
} from "@/constants.js";

/**
 * Function that add extra params to Cloudinary image url for image transformation
 *
 * @param {Object} parameters { path, compress, aspectRatio, crop, width, height, widthPortion, heightPortion }
 * @returns {String}
 */
export const imageTransformation = (parameters) => {
  if (!(!!parameters && parameters.constructor === Object)) return "";

  const getWidthPortionParameters = (parameters) => {
    const wp = parameters?.widthPortion;
    if (wp && Number(wp) > 0 && !Number.isInteger(wp)) return `w_${wp}`;
    return undefined;
  };

  const getHeightPortionParameters = (parameters) => {
    const hp = parameters?.heightPortion;
    if (hp && Number(hp) > 0 && !Number.isInteger(hp)) return `h_${hp}`;
    return undefined;
  };

  const params = (
    [
      // Compress
      parameters?.compress ? CLOUDINARY_DEFAULT_IMAGE_COMPRESSION : undefined,
      // Aspect Ratio
      parameters?.aspectRatio ? `ar_${parameters.aspectRatio}` : undefined,
      // Crop
      parameters?.crop ? CLOUDINARY_DEFAULT_IMAGE_CROP : undefined,
      // Width
      parameters?.width && Number(parameters?.width) > 0
        ? `w_${parameters?.width}`
        : getWidthPortionParameters(parameters),
      // Height
      parameters?.height && Number(parameters?.height) > 0
        ? `h_${parameters?.height}`
        : getHeightPortionParameters(parameters),
    ].filter((item) => item) || []
  ).join();

  if (params && parameters?.path) {
    const upload = "upload";
    const splitedPath = parameters?.path.split(`${upload}/`);
    splitedPath.splice(1, 0, `${upload}/${params}/`);
    return splitedPath.join("");
  }
  return parameters?.path ?? "";
};

/**
 * Convert a video string file from cloudinary to mp4 if its an avi / mov
 * These formats that might be broken, its browser dependant.
 * https://cloudinary.com/documentation/video_manipulation_and_delivery#delivering_in_a_different_format
 * https://github.com/videojs/video.js/issues/5938
 *
 * @param {String} video
 * @returns String
 */
export const convertToMp4 = (video) => {
  if (!video || typeof video === "object") return "";
  const idx = video.lastIndexOf(".");
  const ext = video.slice(idx + 1).toLowerCase();
  return ["avi", "mov"].includes(ext) ? `${video.slice(0, idx)}.mp4` : video;
};
