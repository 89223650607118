import store from "../store";

export const processPayload = (payload, selectedEntityView, key) => {
  payload = { ...payload, extraParams: { ...payload.extraParams }};
  if (selectedEntityView?.id) {
    payload.extraParams[key] = selectedEntityView.id;
  } else {
    const entityId = store.getters.getEntityIds;
    payload.extraParams[key] = entityId;
  }
  return payload;
};
