<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps } from "vue";
import { useVModel } from "@vueuse/core";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
});

const events = useVModel(props, "modelValue");
</script>
<template>
  <div class="relative">
    <div class="grid xs:grid-cols-2 mt-4 sm:grid-cols-2 gap-4">
      <div class="text-base font-medium text-gray-700">
        Event Category:
        <span v-if="events?.event_category?.name" class="font-semibold">{{
          events.event_category.name
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Event Sub-category:
        <span v-if="events?.sub_category?.name" class="font-semibold">{{
          events.sub_category.name
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Mode of experience:
        <span v-if="events?.type" class="font-semibold">{{ events.type }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        This event is primarily:
        <span v-if="events?.is_outdoors" class="font-semibold">{{
          events.is_outdoors
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Location Type:
        <span v-if="events?.location_type" class="font-semibold">{{
          events.location_type
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Price per student:
        <span v-if="events?.price_student" class="font-semibold"
          >$ {{ events.price_student }}</span
        >
      </div>
      <div class="text-base font-medium text-gray-700">
        How long is the event:
        <span v-if="events?.duration" class="font-semibold"
          >{{ events.duration }} {{ events.duration_units }}</span
        >
      </div>
      <div class="text-base mb-4 font-medium text-gray-700">
        Description:
        <JSONtoHTML
          v-if="events?.description"
          class="font-semibold"
          :tiptap-json="events.description"
        />
      </div>
      <div class="text-base font-medium text-gray-700">
        Host:
        <span v-if="events?.host" class="font-semibold">{{ events.host }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Tags:
        <span v-if="events?.tags" class="font-semibold">{{
          events.tags?.map((item) => item.name).join(", ") || ""
        }}</span>
      </div>
    </div>
  </div>
</template>
