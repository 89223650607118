
import { useToast } from "vue-toast-notification";

const toast = useToast();

export const handleOperationFailure = (message, error) => {
  console.log(error);
  toast.open({
    message: message,
    type: "info",
    position: "bottom",
    duration: 5000,
  });
};
