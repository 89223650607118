<template>
  <div class="mt-2">
    <span class="font-semibold uppercase text-gray-600 py-2">
      {{ title }}</span
    >
    <ul class="list-none">
      <li
        v-for="(navLink, index) in navLinks"
        :key="index"
        class="font-normal py-2 hover:hover:font-semibold hover:text-gray-700 hover:no-underline"
      >
        <BaseAnchor :to="navLink.path">
          <div class="flex items-center">
            <img
              v-if="navLink.icon"
              :src="navLink.icon"
              :alt="navLink.name + ' icon'"
            />
            <template v-else-if="navLink.iconComponent">
              <component :is="navLink.iconComponent" />
            </template>
            <div class="pl-2">
              <span v-if="navLink.name" class="text-sm text-gray-600">{{
                navLink.name
              }}</span
              ><br />
              <small v-if="navLink.description" class="text-xs text-gray-600">{{
                navLink.description
              }}</small>
            </div>
          </div>
        </BaseAnchor>
      </li>
    </ul>
    <div class="h-px bg-gray-400"></div>
  </div>
</template>
<script>
import CitiesIcon from "@/components/svg-icons/CitiesIcon.vue";
import BaseAnchor from "@/components/shared/Anchor/BaseAnchor.vue";
export default {
  name: "NavBarSection",
  components: {
    CitiesIcon,
    BaseAnchor,
  },
  props: {
    navLinks: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    }
  },
};
</script>
