<script setup>
import { computed, defineProps, onMounted } from "vue";
import { useRoute } from "vue-router";
import { FORMS_SUBMISSIONS } from "@/constants.js";
import { eventBus } from "@/app";
import CheckMarkIcon from "@/components/forms/SharedComponents/CheckMarkIcon.vue";

const route = useRoute();
const props = defineProps({
  showHousingQuestionnaire: {
    type: Boolean,
    default: false,
  },
  formSubmissions: {
    type: Array,
    default: () => [],
  },
});

const completed = computed(() => {
  const submission = props.formSubmissions.find(
    (submission) =>
      submission.form_name === FORMS_SUBMISSIONS.housingQuestionnaire.name
  );
  return Boolean(submission);
});

const applicationId = computed(() => route.params.applicationId);

onMounted(() => {
  if (props.showHousingQuestionnaire) {
    eventBus.$emit("addPreDepartureForm", {
      label: FORMS_SUBMISSIONS.housingQuestionnaire.name,
      ruleName: "",
      routeName: FORMS_SUBMISSIONS.housingQuestionnaire.slug,
      submitted: completed.value,
    });
  }
});
</script>

<template>
  <div v-if="props.showHousingQuestionnaire" class="flex flex-col">
    <router-link
      :to="{
        name: FORMS_SUBMISSIONS.housingQuestionnaire.slug,
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4"
        :data-testid="
          FORMS_SUBMISSIONS.housingQuestionnaire.slug + '-link-text'
        "
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        {{ FORMS_SUBMISSIONS.housingQuestionnaire.name }}
      </p>
    </router-link>
  </div>
</template>
