import { mapState } from "vuex";
import * as Sentry from "@sentry/vue";
import formService from "@/services/form";
import impersonationFormSniffer from "./ImpersonationFormSniffer";

export default {
  data() {
    return {
      hasFormIoValidationErrors: false,
      formioErrors: [],
      submission_id: "",
      submissionData: {},
    };
  },
  computed: {
    ...mapState(["currentApplicationId"]),
  },
  mixins: [impersonationFormSniffer],
  methods: {
    submitToFormIo(formPath, body, method = "post", submissionId = "") {
      body = this.attachEmployeeToSubmission(body, method);

      const formServiceOperation = {
        post: () => formService.createSubmission(formPath, body),
        put: () => formService.updateSubmission(formPath, submissionId, body),
        patch: () =>
          formService.partiallyUpdateSubmission(formPath, submissionId, body),
      }[method.toLowerCase()];

      return formServiceOperation()
        .then((submission) => {
          this.logSubmission(formPath, this.submissionData, body);
          return submission._id;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              form: formPath,
              application_id: this.currentApplicationId,
            },
          });
          throw error;
        });
    },
    formioValidateSubmission(formPath, body) {
      return formService
        .validateSubmission(formPath, body)
        .then(() => {
          this.hasFormIoValidationErrors = false;
          return false;
        })
        .catch((error) => {
          const validationFailures =
            error?.response?.data?.details?.details || [];
          this.formioErrors = validationFailures.map(
            (validationFailure) =>
              `${validationFailure.context.label} : ${validationFailure.message}`
          );
          this.hasFormIoValidationErrors = true;
        });
    },
    formioSubmissionExists(formPath, applicationId) {
      return formService
        .submissionExists(formPath, {
          "data.application_id": applicationId,
        })
        .then((submission) => {
          this.submission_id = submission._id;
          return submission._id;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            throw error;
          }
          return false;
        });
    },
    getFormioSubmission(formPath, submissionId) {
      return formService
        .getSubmission(formPath, submissionId)
        .then((submission) => submission?.data)
        .catch((error) => {
          if (error?.response?.status !== 404) {
            throw error;
          }
          return false;
        });
    },
    formioSubmissionExistsWithCustomParams(formPath, queryString) {
      return formService
        .submissionExists(formPath, queryString)
        .then((queryResult) => {
          this.submission_id = queryResult._id;
          return queryResult._id;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            throw error;
          }
          return false;
        });
    },
    lookupSubmissionByApplicationId(formPath, applicationId) {
      return formService.submissionExists(formPath, {
        "data.application_id": applicationId,
      });
    },
  },
};
