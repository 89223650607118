<template>
  <div v-if="`${secondaryColor}`" v-bind="$attrs">
    <div id="json-to-html-branded" :class="customClass" v-html="sanitizedOutput"></div>
    <slot></slot>
  </div>
  <div v-else v-bind="$attrs">
    <div id="json-to-html" :class="customClass" v-html="sanitizedOutput"></div>
    <slot></slot>
  </div>
</template>

<script>
import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { generateHTML } from "@tiptap/html";
import DOMPurify from "dompurify";

export default {
  inheritAttrs: false,
  props: {
    tiptapJson: {
      type: Object,
      required: true,
    },
    customClass: {
      type: [String, Array],
      default: "",
    },
  },

  computed: {
    output() {
      return generateHTML(this.tiptapJson, [
        Document,
        Paragraph,
        Text,
        Bold,
        Blockquote,
        BulletList,
        ListItem,
        HardBreak,
        Heading,
        HorizontalRule,
        Italic,
        Link,
        OrderedList,
        Strike,
        Underline,
      ]);
    },
    sanitizedOutput() {
      // TODO make WYSIWYG component flexible enough to support everything we have here, so we can centralize the usage there
      // by default the lib just create empty paragraphs, if we just convert to html we won´t have the break lines, just included it manually
      const outputNormalized = this.output.replaceAll("<p></p>", "<p><br /></p>");
      return DOMPurify.sanitize(outputNormalized);
    },
  },
};
</script>

<style>
/* Styles for mobile devices */
@media (max-width: 768px) {
  #json-to-html ul li {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  #json-to-html ul li::before {
    content: "\2022";
    position: absolute;
    left: 0;
  }
  #json-to-html ol li {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  #json-to-html ol li::before {
    content: counters(list-item, ".") ".";
    counter-increment: list-item;
    position: absolute;
    left: 0;
  }
  #json-to-html-branded ul li {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  #json-to-html-branded ul li::before {
    content: "\2022";
    position: absolute;
    left: 0;
  }
  #json-to-html-branded ol li {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  #json-to-html-branded ol li::before {
    content: counters(list-item, ".") ".";
    counter-increment: list-item;
    position: absolute;
    left: 0;
  }
}

/* Styles for desktop devices */
@media (min-width: 769px) {
  #json-to-html ul {
    padding: 0 1rem;
    list-style: disc;
  }
  #json-to-html ol {
    padding: 0 1rem;
    list-style: decimal;
  }
  #json-to-html-branded ul {
    padding: 0 1rem;
    list-style: disc;
  }
  #json-to-html-branded ol {
    padding: 0 1rem;
    list-style: decimal;
  }
}

#json-to-html a {
  font-weight: 600;
  text-decoration: underline;
  color: #007f80;
}
#json-to-html-branded a {
  font-weight: 600;
  text-decoration: underline;
  color: var(--branding-secondary);
}
</style>
