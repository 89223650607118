<template>
  <FilterModal>
    <div>
      <label class="block font-semibold mt-6" for="housing-type"
        >Housing Type</label
      >
      <v-select
        id="housing-type"
        name="housing-type"
        :model-value="advancedFilters.type"
        :options="housingTypeOptions"
        @update:model-value="changeAdvancedFilters($event, 'type')"
      ></v-select>
      <label class="block font-semibold mt-6" for="housing-status"
        >Status</label
      >
      <v-select
        id="housing-status"
        name="housing-status"
        :model-value="advancedFilters.status"
        :options="STATUS"
        @update:model-value="changeAdvancedFilters($event, 'status')"
      ></v-select>
      <label class="block font-semibold mt-6" for="housing-country"
        >Country</label
      >
      <v-select
        id="housing-country"
        name="housing-country"
        :reduce="(val) => val.id"
        :model-value="advancedFilters.country_id"
        :options="countries"
        :get-option-label="(option) => option.name"
        @update:model-value="changeAdvancedFilters($event, 'country_id')"
      ></v-select>
      <label class="block font-semibold mt-6" for="housing-terms"
        >Terms Available</label
      >
      <v-select
        id="housing-terms"
        name="housing-terms"
        :reduce="(val) => val.id"
        :model-value="advancedFilters.terms_ids"
        :options="terms"
        :get-option-label="(option) => option.name"
        @update:model-value="changeAdvancedFilters($event, 'terms_ids')"
      ></v-select>
      <label class="block font-semibold mt-6" for="housing-city">City</label>
      <v-select
        id="housing-city"
        name="housing-city"
        :reduce="(val) => val.id"
        :loading="cityLoading || !cityReady"
        :model-value="advancedFilters.city_ids"
        :options="citiesData.items"
        :get-option-label="
          ({ city = '', admin_name = '', country_id = '' }) =>
            `${city}, ${admin_name ? admin_name + ', ' : ''} ${country_id}`
        "
        @update:model-value="changeAdvancedFilters($event, 'city_ids')"
        @search="searchCity"
      ></v-select>
      <label class="block font-semibold mt-6">Generic Listing</label>
      <div class="flex w-full">
        <div class="flex items-center">
          <div class="flex-auto">
            <input
              id="housing-generic_listing-yes"
              v-model="advancedFilters.generic_listing"
              value="true"
              type="radio"
              class="mt-1 text-university-primary form-radio"
              name="housing-generic_listing"
              @input="
                changeAdvancedFilters($event.target.value, 'generic_listing')
              "
            />
          </div>
          <div class="flex-auto ml-3 mt-1">
            <label for="housing-generic_listing-yes">Yes</label>
          </div>
        </div>
        <div class="flex ml-5 items-center">
          <div class="flex-auto">
            <input
              id="housing-generic_listing-no"
              v-model="advancedFilters.generic_listing"
              value="false"
              type="radio"
              class="mt-1 text-university-primary form-radio"
              name="housing-generic_listing"
              @input="
                changeAdvancedFilters($event.target.value, 'generic_listing')
              "
            />
          </div>
          <div class="flex-auto ml-3 mt-1">
            <label for="housing-generic_listing-no">No</label>
          </div>
        </div>
      </div>
      <label class="block font-semibold mt-6" for="housing-on_campus"
        >On Campus</label
      >
      <div class="flex w-full">
        <div class="flex items-center">
          <div class="flex-auto">
            <input
              id="housing-on_campus-yes"
              v-model="advancedFilters.on_campus"
              value="true"
              type="radio"
              class="mt-1 text-university-primary form-radio"
              name="housing-on_campus"
              @input="changeAdvancedFilters($event.target.value, 'on_campus')"
            />
          </div>
          <div class="flex-auto ml-3 mt-1">
            <label for="housing-on_campus-yes">Yes</label>
          </div>
        </div>
        <div class="flex ml-5 items-center">
          <div class="flex-auto">
            <input
              id="housing-on_campus-no"
              v-model="advancedFilters.on_campus"
              value="false"
              type="radio"
              class="mt-1 text-university-primary form-radio"
              name="housing-on_campus"
              @input="changeAdvancedFilters($event.target.value, 'on_campus')"
            />
          </div>
          <div class="flex-auto ml-3 mt-1">
            <label for="housing-on_campus-no">No</label>
          </div>
        </div>
      </div>
      <label class="block font-semibold mt-6" for="housing-tier">Tier</label>
      <v-select
        id="housing-tier"
        name="housing-tier"
        :model-value="advancedFilters.tier"
        label="label"
        :reduce="(val) => val.name"
        :options="TIERS"
        @update:model-value="changeAdvancedFilters($event, 'tier')"
      ></v-select>
      <label
        class="block font-semibold mt-6"
        for="housing-allowed_program_types"
        >Allowed Program Types</label
      >
      <CheckboxSelect
        id="housing-allowed_program_types"
        name="housing-allowed_program_types"
        :modelValue="advancedFilters.allowed_program_types"
        :options="ALLOWED_PROGRAM_TYPES_OPTIONS"
        :close-on-select="false"
        :components="{ OpenIndicator: Magnifier }"
        :deselect-from-dropdown="true"
        :multiple="true"
        scroll-input="auto"
        input-max-height="29px"
        @update:model-value="
          changeAdvancedFilters($event, 'allowed_program_types')
        "
      />
      <label class="block font-semibold mt-6" for="housing-floor_plan"
        >Floor Plan</label
      >
      <v-select
        id="housing-floor_plan"
        name="housing-floor_plan"
        :model-value="advancedFilters.floor_plan"
        :options="FLOOR_PLAN"
        @update:model-value="changeAdvancedFilters($event, 'floor_plan')"
      ></v-select>
      <label class="block font-semibold mt-6" for="housing-room_type"
        >Room Type</label
      >
      <v-select
        id="housing-room_type"
        name="housing-room_type"
        :model-value="advancedFilters.room_type"
        :options="ROOM_TYPES"
        @update:model-value="changeAdvancedFilters($event, 'room_type')"
      ></v-select>
      <div v-if="hasItemsWritePermission" class="w-full">
        <label class="block font-semibold mt-6" for="housing-room_price"
          >Price</label
        >
        <div class="flex w-full">
          <div class="flex-auto">
            <input
              id="housing-room_price"
              :value="parsePrice(advancedFilters.min_room_price)"
              placeholder="Min"
              type="number"
              name="housing-min_room_price"
              class="h-10 w-full form-input"
              @input="
                changeAdvancedFilters($event.target.value, 'min_room_price')
              "
              @blur="checkMinMaxPrice()"
            />
          </div>
          <div class="flex-auto ml-5">
            <input
              :value="parsePrice(advancedFilters.max_room_price)"
              placeholder="Max"
              type="number"
              name="housing-max_room_price"
              class="h-10 w-full form-input"
              @input="
                changeAdvancedFilters($event.target.value, 'max_room_price')
              "
              @blur="checkMinMaxPrice()"
            />
          </div>
        </div>
      </div>
      <label class="block font-semibold mt-6">Amenities</label>
      <TagsInput
        :model-value="selectedAmenities"
        :options="amenities"
        @update:model-value="changeAdvancedFiltersAmenities"
      />
      <label class="block font-semibold mt-6">Address Coming Soon</label>
      <div class="flex w-full">
        <div class="flex items-center">
          <div class="flex-auto">
            <input
              id="housing-address_coming_soon-yes"
              v-model="advancedFilters.address_coming_soon"
              value="true"
              type="radio"
              class="mt-1 text-university-primary form-radio"
              name="housing-address_coming_soon"
              @input="
                changeAdvancedFilters(
                  $event.target.value,
                  'address_coming_soon'
                )
              "
            />
          </div>
          <div class="flex-auto ml-3 mt-1">
            <label for="housing-address_coming_soon-yes">Yes</label>
          </div>
        </div>
        <div class="flex ml-5 items-center">
          <div class="flex-auto">
            <input
              id="housing-address_coming_soon-no"
              v-model="advancedFilters.address_coming_soon"
              value="false"
              type="radio"
              class="mt-1 text-university-primary form-radio"
              name="housing-address_coming_soon"
              @input="
                changeAdvancedFilters(
                  $event.target.value,
                  'address_coming_soon'
                )
              "
            />
          </div>
          <div class="flex-auto ml-3 mt-1">
            <label for="housing-address_coming_soon-no">No</label>
          </div>
        </div>
      </div>
    </div>
  </FilterModal>
</template>

<script>
export default {
  name: "HousingAdvancedFilters",
};
</script>

<script setup>
import FilterModal from "@/components/modals/AdvancedFilterModal";
import { ERROR_TIMEOUT } from "@/constants.js";
import { useGetCityOptions } from "@/components/program-manager/sessions/composable";
import TagsInput from "@/components/shared/TagsInput";
import { isAuthorized } from "@/composables/authorization";
import CheckboxSelect from "@/components/shared/select/CheckboxSelect.vue";
import Magnifier from "@/components/shared/icons/Magnifier";
import {
  HOUSING_TYPES,
  STATUS,
  FLOOR_PLAN,
  ROOM_TYPES,
  TIERS,
  ALLOWED_PROGRAM_TYPES_OPTIONS,
} from "@/constants.js";
import _ from "lodash";
import { useToast } from "vue-toast-notification";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const toast = useToast();

store.dispatch("programManager/fetchCountries");
store.dispatch("programManager/fetchTerms");
store.dispatch("programManager/fetchAmenitiesData");

const housingTypeOptions = HOUSING_TYPES.map((el) => el.type);

const amenities = _.uniq(
  store.getters["programManager/getAllAmenities"].map(({ title }) => title)
)?.map((title) => ({ id: title, title }));

const countries = store.getters["programManager/getCountries"];

const terms = store.getters["programManager/getTermsData"];

const {
  execute: fetchCities,
  state: citiesData,
  loading: cityLoading,
  isReady: cityReady,
} = useGetCityOptions(
  { immediate: true, throwError: true, resetOnExecute: true },
  {}
);

const searchCity = _.debounce(async (city_ascii, loading) => {
  if (city_ascii && city_ascii?.length > 2) {
    loading(true);
    try {
      await fetchCities(0, { city_ascii, cancel: true });
      loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        loading(false);
        toast.open({
          message: "Error while loading city data. Please, try it later",
          type: "error",
          position: "bottom",
          duration: ERROR_TIMEOUT,
        });
      }
    }
  }
}, 500);

const advancedFilters = computed(
  () => store.getters["programManager/getAdvancedFilters"]
);

const selectedAmenities = computed(() => {
  return (
    advancedFilters.value.amenities?.map((title) => ({
      id: title,
      title,
      checked: true,
    })) || []
  );
});

const parsePrice = (price) => (!isNaN(price) ? price / 100 : undefined);

const checkMinMaxPrice = () => {
  if (
    advancedFilters.value["min_room_price"] >= 0 &&
    advancedFilters.value["max_room_price"] >= 0 &&
    advancedFilters.value["min_room_price"] >
      advancedFilters.value["max_room_price"]
  ) {
    store.commit("programManager/setAdvancedFilters", {
      ...advancedFilters.value,
      ["max_room_price"]: advancedFilters.value["min_room_price"],
      ["min_room_price"]: advancedFilters.value["max_room_price"],
    });
  }
};

const changeAdvancedFilters = (value, key) => {
  if (["min_room_price", "max_room_price"].includes(key)) {
    value = value * 100;
  }
  store.commit("programManager/setAdvancedFilters", {
    ...advancedFilters.value,
    [key]: value,
  });
};

const changeAdvancedFiltersAmenities = (amenities) => {
  store.commit("programManager/setAdvancedFilters", {
    ...advancedFilters.value,
    amenities: amenities.map((amenity) => amenity.title),
  });
};

const hasItemsWritePermission = () => isAuthorized("items.write");
</script>
