<template>
  <div class="relative z-50">
    <Dialog
      :open="props.isModalOpen"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-12"
      @close-modal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
      ></div>
      <div
        class="max-w-4xl p-6 mx-auto bg-white sm:rounded-lg sm:shadow z-20 relative"
      >
        <DialogPanel>
          <div class="text-right">
            <button @click="closeModal">
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <section class="sm:px-16 md:px-24 sm:py-10">
            <h1
              class="flex justify-center text-xl leading-tight text-center sm:text-2xl md:text-3xl"
            >
              {{ props.title }}
            </h1>
            <slot> </slot>
            <div class="flex justify-end mt-5">
              <button
                :class="`${secondaryColorClass}`"
                class="mr-7 my-7 text-center uppercase hover:opacity-70"
                @click="clearFilters"
              >
                RESET FILTERS
              </button>
            </div>
            <div class="flex justify-center">
              <BaseButton class="text-base" outlined :on-click="closeModal">
                Cancel
              </BaseButton>
              <BaseButton class="text-base" :on-click="applyFilters">
                Apply
              </BaseButton>
            </div>
          </section>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed } from "vue";
import { useStore } from "vuex";

import { useRoute } from "vue-router";
import { Dialog, DialogPanel } from "@headlessui/vue";

import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import BaseButton from "@/components/shared/Button/BaseButton";
import { eventBus } from "../../../app";

const emit = defineEmits(["closeModal"]);
const store = useStore();
const route = useRoute();
const resources = {
  Housing: {
    singular: "Housing",
  },
  Internships: {
    singular: "Internship",
  },
  Programs: {
    singular: "Program",
  },
};

const advancedFilters = computed(
  () => store.getters["programManager/getAdvancedFilters"]
);

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "Housing Filter",
  },
  applyFiltersAction: {
    type: String,
    default: "programManager/fetchHousingData",
  },
});

const clearFilters = () => {
  store.commit("programManager/setAdvancedFilters", {});
  eventBus.$emit("clear");
};
const closeModal = () => {
  emit("closeModal", true);
  clearFilters();
};

const applyFilters = async () => {
  let filters = { ...advancedFilters.value };
  Object.keys(filters).forEach((key) => {
    if (!filters[key]) {
      delete filters[key];
    } else if (Array.isArray(filters[key])) {
      // Clean array values inside the filters object
      filters[key] = filters[key].filter((value) => value !== null);
    }
  });

  if (filters.company) {
    filters.company = filters.company.name;
  }

  if (filters.status) {
    filters.status = filters?.status?.name;
  }

  if (filters.placement_type) {
    filters.placement_type = filters.placement_type
      .map((type) => type.value)
      .join(",");
  }
  if (filters.career_area) {
    let careerValues = filters.career_area.map((type) => type.soc_title);
    filters.career_area = careerValues.join(",");
  }
  if (filters.major) {
    let majorValues = filters.major.map((type) => type.cip_title);
    filters.major = majorValues.join(",");
  }
  if (filters.session) {
    let sessionValues = filters.session.map((type) => type.label_desc);
    filters.session = sessionValues.join(",");
  }
  if (filters.durations) {
    filters.durations = filters.durations.value;
  }
  if (filters.countries) {
    let coutryValues = filters.countries.map((type) => type.label);
    filters.countries = coutryValues.join(",");
  }
  if (filters.cities) {
    let citiesValues = filters.cities.map((type) => type.city_ascii);
    filters.cities = citiesValues.join(",");
  }
  if (route.meta.subcategory === "internships") {
    filters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value !== "")
    );

    store.dispatch("programManager/fetchInternshipsData", {
      ...filters,
      limit: 25,
    });
  } else if (
    route.meta.subcategory === resources.Housing.singular.toLowerCase()
  ) {
    store.dispatch(props.applyFiltersAction, {
      extraParams: {
        ...advancedFilters.value,
        allowed_program_types:
          advancedFilters.value?.allowed_program_types?.map(
            (item) => item.label
          ) ?? undefined,
      },
    });
  }

  emit("closeModal", true);
};
</script>
