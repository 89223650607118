<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import { debounce } from "lodash";
import axios from "axios";

const emit = defineEmits(["search", "update:modelValue"]);

const props = defineProps({
  vuelidateField: {
    // Vuelidate object inherited, useful to display validation errors
    type: Object,
    default: () => {},
  },
  modelValue: {
    type: Object,
    default: () => {},
  },
  label: {
    // Text to display as label of this input
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  placeholder: {
    // Text to display when no option is selected
    type: String,
    default: "-select-",
  },
  options: {
    // All the options available for dropdown
    type: Array,
    default: () => [],
  },
  optionLabel: {
    // The name of the property where the label should be used
    type: String,
    default: "label",
  },
  searchMethod: {
    // Will emit an event if true, example: Trigger an API Call from parent component
    type: Boolean,
    default: false,
  },
  allowMultipleOptions: {
    // Converts component in multiselect if true
    type: Boolean,
    default: false,
  },
  testId: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const selectOption = ref(props.modelValue);
watch(selectOption, (newVal) => {
  emit("update:modelValue", newVal);
});
watch(
  () => props.modelValue,
  (newVal) => {
    selectOption.value = newVal;
  },
  { deep: true }
);

const search = debounce(async (search, loading) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  //to cancel the requests in excess
  source.cancel("cancel");
  cancelToken = axios.CancelToken;
  source = cancelToken.source();
  if (props.searchMethod) {
    loading(true);
    emit("search", { search, loading });
  }
}, 700);
</script>

<template>
  <div>
    <label :for="props.id" class="block text-sm font-medium text-indigo-base">
      <span class="font-medium flex justify-between mb-1">
        {{ props.label }}
      </span>
      <VSelect
        :id="props.id"
        v-model="selectOption"
        :disabled="props.disabled"
        :data-testid="`dropdown${props.testId}`"
        :value="selectOption"
        :options="props.options"
        :data-cy="props.id + 'Select'"
        :class="{
          'custom-select-error': props.vuelidateField.$error,
          'custom-select': !props.allowMultipleOptions,
          'custom-multi-select': props.allowMultipleOptions,
        }"
        :get-option-label="(option) => option[props.optionLabel]"
        :placeholder="props.placeholder"
        :multiple="props.allowMultipleOptions"
        @search:blur="props.vuelidateField?.$touch"
        @search="search"
      >
        <template v-if="props.testId" #option="option">
          <div :data-testid="`option-${option.value}`">
            {{ option[props.optionLabel] }}
          </div>
        </template>
      </VSelect>
    </label>
    <slot name="help-content"></slot>
    <p
      v-for="error of props.vuelidateField?.$errors"
      :key="error.$uid"
      class="error text-error-900 text-sm"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<style>
.custom-multi-select .vs__dropdown-toggle {
  @apply pt-3 pb-4 rounded-none border-indigo-base;
  max-height: none;
}

.custom-multi-select .vs__dropdown-toggle .vs__selected-options .vs__selected {
  margin-right: 5px;
  position: relative;
}

.custom-select .vs__dropdown-toggle {
  @apply pt-3 pb-4 rounded-none border-indigo-base;
  max-height: 3.15rem;
}

.custom-select .vs__search {
  @apply m-0;
}

.custom-select-error .vs__dropdown-toggle {
  @apply border-error-900;
}
</style>
