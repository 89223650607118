<script setup>
import ApplyButton from "@/components/shared/Button/ApplyButton.vue";
import { PROGRAM_SESSION_TERMS_DISPLAY_MAP } from "@/components/program-manager/sessions/constants.js/";
import { computed } from "vue";
import { useStore } from "vuex";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import {
    formatDate,
    buildStringDateRangeSessionTravel,
    hideSessionDeadline,
} from "@/mixins/helpers";
import { formattedPriceFromCentsWithoutCents as formattedPrice } from "@/util/formatter";
import useAppliableContract from "@/composables/useBillings";


const store = useStore();
const emit = defineEmits(["dategridrow-set-session-id", "applyBtnClicked"]);
const props = defineProps({
    session: {
        type: Object,
        default() {
            return {}
        }
    },
    indexNumber: {
        type: Number,
        default: 0,
    },
    oddrow: {
        type: Boolean,
        default: false, // Changes the BG Color of columns 2 & 3
    },
    selectedSessionId: {
        type: String,
        default: "",
    },
});

const bgClass = computed(() => {
    return props.oddrow ? 'bg-gray-30' : 'bg-white'
});

const billingRules = computed(() => store.state.billingRules);

const hidePrice = computed(() => {
    const contracts = billingRules.value?.agreements ?? [];
    if (contracts.length < 1) {
        return false;
    }

    const applicableContract = useAppliableContract(contracts, props.session);
    return (
        Boolean(applicableContract) &&
        applicableContract?.direct_billing_type !== "Pass through"
    );
});

const sessionTerms = computed(() => {
    return props.session?.terms?.map((term) => term.name) ?? [];
});

const getDisplayNameForTerms = computed(() => {
    const normalizedTerms = [...sessionTerms.value].sort();
    const termKey = normalizedTerms.join(" + ");
    return (
        PROGRAM_SESSION_TERMS_DISPLAY_MAP[termKey] || normalizedTerms.join(" + ")
    );
});

const getDisplayDates = computed(() => {
    return buildStringDateRangeSessionTravel(props.session?.session_travel_details,
        {
            academic_start_date: props.session?.academic_start_date,
            academic_end_date: props.session?.academic_end_date,
        }
    );
});

const getFormattedPrice = computed(() => {
    return formattedPrice(props.session?.base_price_in_cents)
});

const displayDeadline = computed(() => {
    return props.session?.application_deadline && !hideSessionDeadline(props.session);
});

const getFormattedDeadline = computed(() => {
    return formatDate(props.session?.application_deadline);
});

const radioButtonId = computed(() => {
    return 'dates-radio-' + props.indexNumber
});

const isSelectedSession = computed(() => {
    return props.selectedSessionId === props.session?.id ?? ""
});

const disableButton = computed(() => {
    return !props.session?.application_deadline || pastSessionDeadline(props.session);
});

const overrideColumnDisplay = computed(() => {
    if (!isSelectedSession.value) return {};
    return {
        display: 'block !important',
    };
});
</script>

<template>
    <div :class="isSelectedSession ? `border ${primaryColorBorder}` : 'cursor-pointer'"
        class="grid grid-cols-1 lg:grid-cols-3 font-montserrat" data-id="dates-grid"
        @click.stop="emit('dategridrow-set-session-id', props.session?.id)">
        <div :class="isSelectedSession ? `${primaryColorClassBG}` : bgClass" class="pt-4 pb-4 pr-2 pl-2">
            <div class="grid grid-cols-date-radios">
                <input :id="radioButtonId" type="radio"
                    class="w-5 h-5 relative inset-y-29percent inset-x-27percent custom-date-radiobutton"
                    name="session-date-grid-option" :checked="isSelectedSession" :value="props.session?.id" />

                <!-- Term(s) & Dates -->
                <div :class="isSelectedSession ? 'text-white' : 'text-university-primary'" class="ml-4 flex flex-col">
                    <span v-if="sessionTerms" :id="`grid-session-term-${props.session?.id}`"
                        class="font-normal text-sm">
                        {{ getDisplayNameForTerms }}
                    </span>
                    <span v-if="props.session?.session_travel_details" :id="`api-session-dates-${props.session?.id}`"
                        class="font-bold text-base mt-2 uppercase">
                        {{ getDisplayDates }}
                    </span>
                </div>
            </div>
        </div>
        <div :class="bgClass" class="pt-4 pb-4 pr-2 pl-5">
            <div class="flex flex-col">
                <span v-if="props.session?.base_price_in_cents && !hidePrice"
                    :id="`grid-program-price-${props.session?.id}`" :class="`uppercase ${secondaryColorClass} font-bold`">
                    Starting at {{ getFormattedPrice }}
                </span>
                <div v-if="displayDeadline" class="flex justify-start items-center mt-2"
                    :id="`grid-program-deadline-${props.session?.id}`">
                    <img src="/images/icon-calendar.svg" class="object-contain" width="24" height="24"
                        alt="calendar icon" />
                    <div class="text-sm ml-2 text-university-primary">
                        <p>
                            Application Deadline:
                            <span class="font-bold text-university-primary uppercase">
                                {{ getFormattedDeadline }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div :class="bgClass" class="pt-4 pb-4 pr-4 pl-4 lg:pl-2 hidden lg:pr-2 lg:block"
            :style="overrideColumnDisplay">
            <div v-if="isSelectedSession" class="flex justify-center justify-items-center">
                <ApplyButton :id="`card-apply-btn-${props.session?.id}`" :disabled="disableButton"
                    class="h-60p w-16r xs:w-60r lg:w-16r border-transparent" event-category="Program Page Personalize CTA"
                    @click="emit('applyBtnClicked')" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.custom-date-radiobutton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 50%;
}

.custom-date-radiobutton:checked {
    background-color: #fff;
    border-color: rgb(0, 0, 0);
    border: 1px solid rgb(0, 0, 0);
}

.custom-date-radiobutton:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    background-color: #fff;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 50%;
}
</style>
