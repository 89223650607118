<script setup>
import { defineEmits, defineProps, computed, watch } from "vue";
import OnlineLocation from "./OnlineSection.vue";
import InPersonLocation from "./InPersonSection.vue";
import HybridLocation from "./HybridSection.vue";
import { useVModel } from "@vueuse/core";
import useVuelidate from "@vuelidate/core";
import { cloneDeep } from "lodash";
import EventsImageUpload from "./EventsImageUpload.vue";

import RequiredToSavePublish from "../../housing/components/RequiredToSavePublish";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  isPreview: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "validator"]);
const events = useVModel(props, "modelValue", emit);

const validateTab = async () => {
  return v$.value.$validate().then(() => {
    return !v$.value.$invalid;
  });
};

emit("validator", validateTab);

const type = computed(() => {
  return events.value.type;
});

const getLocation = computed(() => {
  return events.value.event_locations;
});

events.value.event_locations = cloneDeep(getLocation.value) || [];

watch(type, () => {
  if (getLocation.value.length < 1) {
    const initialLocations = [
      {
        event_id: events.value.id || undefined,
        event_lat_lng: undefined,
        address: undefined,
        city: undefined,
        city_id: undefined,
        image: undefined,
        mobility: undefined,
        communication: undefined,
        sensory: undefined,
      },
    ];
    events.value.event_locations = initialLocations;
  }
});

const rules = {
  events: {
    transportation: {},
  },
};

const v$ = useVuelidate(rules, events, {
  $registerAs: "LocationTab",
  $lazy: true,
});

const eventLocations = [
  "Public Transportation",
  "API-organized",
  "Private transportation",
  "Walking",
];
</script>

<template>
  <div class="relative">
    <div class="p-6">
      <div class="flex justify-between items-start flex-wrap">
        <h1 class="text-xl font-semibold leading-6 text-gray-900">Location</h1>
        <RequiredToSavePublish />
      </div>
      <div class="grid grid-cols-1 mt-2">
        <div class="mb-4">
          <div class="flex flex-col justify-start md:max-w-[300px]">
            <div>
              <label class="block text-sm font-semibold text-gray-700"
                >Getting to the event</label
              >
              <v-select
                v-model="events.transportation"
                :options="eventLocations"
                label="category"
                class="md:max-w-[300px]"
                :class="[
                  {
                    'bg-error-100': v$.events.transportation.$errors?.length,
                  },
                ]"
                @blur="v$.events.transportation.$touch"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><i
                      class="fa fa-caret-down text-gray-500"
                      v-bind="attributes"
                    ></i
                  ></span>
                </template>
              </v-select>
            </div>
            <div class="block text-sm">
              <span class="my-4 block text-sm text-gray-700">
                <span>Image(s)</span>
              </span>
              <EventsImageUpload v-model="events" />
            </div>
            <div
              v-if="v$.events.transportation.$errors.length"
              class="block text-sm error text-error-900"
            >
              <span
                v-for="(error, idx) in v$.events.transportation.$errors"
                :key="idx"
              >
                {{ error.$message }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="cardContainer" class="flex flex-col gap-4">
        <OnlineLocation v-if="events.type === 'Online'" v-model="events" />
        <InPersonLocation v-if="events.type === 'In Person'" v-model="events" />
        <HybridLocation v-if="events.type === 'Hybrid'" v-model="events" />
      </div>
    </div>
  </div>
</template>
