<template>
  <div id="price-break-down">
    <div class="grid gap-12">
      <div class="grid gap-6">
        <section>
          <h3>
            {{ programName }}
          </h3>
          <p>{{ programSite }}</p>
          <p>
            All fees are approximations and can fluctuate according to the
            exchange rates, program dates, program fees and changes in program
            structure. The amounts shown are NOT to be used for determining any
            amount of refund or discount for students who may have secured their
            own housing.
          </p>
          <ul>
            <li v-if="priceBreakDown.educationalFee">
              Educational Fees: ${{
                formatPrice(
                  getEducationalFee(
                    programSessionid,
                    priceBreakDown.educationalFee
                  )
                )
              }}*
            </li>
            <li v-if="priceBreakDown.housingFee">
              Housing: ${{
                formatPrice(
                  getHousingFee(programSessionid, priceBreakDown.housingFee)
                )
              }}
              (opt out)
            </li>
            <li v-if="priceBreakDown.medicalFee">
              Medical &amp; Life Insurance: ${{
                formatPrice(
                  getMedLifeFee(programSessionid, priceBreakDown.medicalFee)
                )
              }}
            </li>
          </ul>
        </section>
        <div class="breakdown">
          Total: ${{
            formatPrice(getPrice(programSessionid, priceBreakDown.total))
          }}
        </div>
      </div>
      <section>
        <ul>
          <li>
            Disclaimer for Educational Fees: *The budget for educational fees
            includes all tuition costs, administrative fees, program excursions,
            pre-departure and on-site orientations, predeparture and on-site
            student services and support, post-program follow-up, transcript
            services as well as cultural visits and activities that may vary by
            program. All services and activities are designed to complement the
            student&rsquo;s academic experience.
          </li>

          <li>
            Disclaimer for Housing: **API programs are designed to include
            housing. Fees above reflect the cost of finding and arranging
            housing for all students as well as the actual rental cost. This
            figure is not the amount that will be deducted should students elect
            to opt out of API housing. Please contact your Program
            Coordinator/Manager for a specific deduction figure if interested.
          </li>
        </ul>
      </section>
    </div>
    <div class="mt-12ss">
      <button
        class="close hover:bg-yellow-900 focus:bg-yellow-900"
        @click="$emit('close')"
      >
        <span>Close</span>
      </button>
    </div>
  </div>
</template>

<script>
import customPrice from "@/mixins/customPrice.js";
export default {
  name: "PriceBreakDown",
  mixins: [customPrice],
  props: {
    priceBreakDown: {
      type: Object,
      default: function () {
        return {
          educationalFee: 0,
          housingFee: 0,
          medicalFee: 0,
          total: 0,
        };
      },
    },
    programSessionid: {
      type: String,
      default: "",
    },
    programName: {
      type: String,
      default: "",
    },
    programSite: {
      type: String,
      default: "",
    },
  },
  emits: ["close"],
  methods: {
    formatPrice(number) {
      return number.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  },
};
</script>

<style scoped>
h3 {
  @apply text-lg font-semibold;
}
section p {
  @apply mb-4;
  @apply text-gray-600;
}
ul {
  @apply pl-5 mt-2;
  @apply text-gray-600 list-disc;
}
.breakdown {
  @apply text-xl font-semibold;
}
button.close {
  @apply block w-full px-4 min-h-10;
  @apply text-center font-semibold text-gray-700;
  @apply border-2 border-transparent rounded bg-university-tertiary;
}
</style>
