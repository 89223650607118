<template>
  <div>
    <!-- TEXT -->
    <div
      v-if="fieldSettings.type === 'text' || fieldSettings.type === 'number'"
    >
      <div>
        <div class="relative mt-1 rounded-md shadow-sm">
          <input
            v-bind="$attrs"
            :min="isAgeOrCapacity() ? 0 : null"
            :value="modelValue"
            :name="fieldSettings.name"
            :class="
              vuelidateErrors.length
                ? textErrorClass
                : 'block form-input w-full text-[#665eaa]'
            "
            @keydown="
              isAgeOrCapacity()
                ? $event.key === '-'
                  ? $event.preventDefault()
                  : null
                : null
            "
            @input="$emit('update:modelValue', $event.target.value)"
          />
          <div
            v-if="vuelidateErrors.length"
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <ExclamationCircleIcon
              class="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- RADIO -->
    <fieldset
      v-else-if="fieldSettings.type === 'radio'"
      :class="customRadioCss"
    >
      <div v-for="v in radioOptions" :key="v" class="mt-1">
        <input
          :id="`radio_${uniqueId}_${v.label}`"
          :checked="v.selected"
          v-bind="$attrs"
          :name="fieldSettings.name"
          :value="v.label"
          class="text-[#665eaa] form-radio"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <label
          class="ml-2 pointer-events-none"
          :for="`radio_${uniqueId}_${v.label}`"
          >{{ v.label }}</label
        >
      </div>
    </fieldset>
    <!-- SINGLE SELECT -->
    <div v-else-if="fieldSettings.type === 'single_select'">
      <VSelect
        v-model="selectValue"
        :label="fieldSettings.labelInput ?? 'label'"
        :options="
          fieldSettings.options.length
            ? fieldSettings.options
            : searchableOptions
        "
        :class="[
          vuelidateErrors.length
            ? selectErrorClass
            : 'w-full border border-indigo-base bg-white rounded-none',
          fieldSettings?.selectClass,
        ]"
        :selectable="selectable"
        :field-settings="fieldSettings"
        :placeholder="fieldSettings.placeholder"
        @search="searchData"
      >
        <template #option="option">
          <div
            :class="{
              'whitespace-normal inline-block align-top break-words overflow-wrap-anywhere': wrapOptions,
            }"
          >
            <p style="margin: 0;">
              {{ option[fieldSettings.labelInput ?? "label"] }}
            </p>
          </div>
        </template>
        <template
          v-if="fieldSettings.customSelected"
          #selected-option-container="{ option, deselect, multiple, disabled }"
        >
          <span
            class="inline-flex items-center font-montserrat font-bold text-base leading-5 uppercase text-indigo-base text-opacity-50"
          >
            {{ option[fieldSettings.labelInput] }}
          </span>
        </template>
        <template
          v-if="fieldSettings.customIndicator"
          #open-indicator="{ attributes }"
        >
          <span v-bind="attributes"
            ><i class="fa fa-caret-down text-gray-500" v-bind="attributes"></i
          ></span>
        </template>
      </VSelect>
    </div>
    <!-- SKILLS SELECT -->
    <div v-else-if="fieldSettings.type === 'searchable_select'">
      <div
        v-if="fieldSettings.externalOptionContainerId"
        :id="fieldSettings.externalOptionContainerId"
        class="flex flex-wrap mb-21px gap-2"
      ></div>
      <MultiSelect
        v-model="selectValue"
        :option-template="searchableOptions"
        :multiple="true"
        :updated-by-other-component="updatedByOtherComponent"
        :class="vuelidateErrors.length ? selectErrorClass : 'w-full'"
        :searchable="true"
        :placement="'bottom'"
        :label="fieldSettings.labelInput ?? 'label'"
        :deselect-from-dropdown="true"
        :close-on-select="false"
        :field-settings="fieldSettings"
        @search="searchData"
      />
    </div>
    <!-- TERMS SELECT -->
    <div v-else-if="fieldSettings.type === 'term_select'">
      <MultiSelect
        v-model="selectValue"
        :option-template="searchableOptions"
        :multiple="true"
        :class="vuelidateErrors.length ? selectErrorClass : 'w-full'"
        :searchable="true"
        :placement="'bottom'"
        :label="fieldSettings.labelInput ?? 'label'"
        :deselect-from-dropdown="true"
        :close-on-select="false"
        :field-settings="fieldSettings"
      />
    </div>
    <!-- MULTIPLE SELECT WITH ALL -->
    <div v-else-if="fieldSettings.type === 'multiple_select_with_all'">
      <MultiSelect
        v-model="selectValue"
        :searchable="false"
        :placement="'bottom'"
        :option-template="fieldSettings.options"
        :class="vuelidateErrors.length ? selectErrorClass : 'w-full'"
        multiple
        :deselect-from-dropdown="true"
        :close-on-select="false"
        :field-settings="fieldSettings"
        :label="fieldSettings.labelInput ?? 'label'"
      />
    </div>

    <!-- GRID SELECT WITH ALL -->
    <div v-else-if="fieldSettings.type === 'grid_select_with_all'">
      <GridMultiSelect
        v-model="selectValue"
        :searchable="false"
        :placement="'bottom'"
        :option-template="fieldSettings.options"
        :class="vuelidateErrors.length ? selectErrorClass : 'w-full'"
        multiple
        :deselect-from-dropdown="true"
        :close-on-select="false"
        :field-settings="fieldSettings"
        :label="fieldSettings.labelInput ?? 'label'"
      />
    </div>
    <!-- MULTIPLE SELECT -->
    <div v-else>
      <MultiSelect
        v-model="selectValue"
        :option-template="fieldSettings.options"
        :class="vuelidateErrors.length ? selectErrorClass : 'w-full'"
        :searchable="false"
        :placement="'bottom'"
        multiple
        :deselect-from-dropdown="true"
        :close-on-select="false"
        :field-settings="fieldSettings"
        :label="fieldSettings.labelInput ?? 'label'"
      />
    </div>
    <div v-if="vuelidateErrors.length">
      <div v-for="error of vuelidateErrors" :key="error.$uid">
        <p class="mt-2 text-sm text-red-600">{{ error.$message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import {
  skillsService,
  skillsServiceSubcategories,
} from "@/services/skills.js";
import termsService from "@/services/terms.js";
import academicLevelsService from "@/services/academicLevels.js";
import socCodesService from "@/services/socCodes.js";
import cipCodesService from "@/services/cipCodes.js";
import perksService from "@/services/perks.js";
import sessionTypesService from "@/services/sessionTypes.js";
import placementTypesService from "@/services/placementTypes.js";
import workLocationsService from "@/services/workLocations.js";
import requiredLanguageService from "@/services/requiredLanguage.js";
import companiesService from "@/services/companies.js";
import { ExclamationCircleIcon } from "@heroicons/vue/20/solid";
import { eventBus } from "../../app";
import MultiSelect from "./MultiSelect.vue";
import GridMultiSelect from "./GridMultiSelect.vue";
import { removeDuplicatesByKey } from "@/services/utils";
import citiesService from "@/services/cities.js";

export default {
  name: "BaseInput",
  components: { ExclamationCircleIcon, MultiSelect, GridMultiSelect },
  props: {
    modelValue: {
      type: [Array, String, Object, Number],
      default: () => {},
    },
    fieldSettings: {
      type: Object,
      default: () => {},
    },
    vuelidateErrors: {
      type: Array,
      default: () => [],
    },
    customRadioCss: {
      type: String,
      default: "",
    },
    useFlex: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    subcategories: {
      type: Array,
      default: () => [],
    },
    updatedByOtherComponent: {
      type: Boolean,
      default: false,
    },
    wrapOptions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const getSubcategoriesIds = (value) =>
      value?.map((subcategory) => subcategory?.id)?.filter((item) => item) ||
      [];

    const selectValue = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        context.emit("update:modelValue", value);
      },
    });
    const searchableOptions = ref([]);
    const selectErrorClass =
      "block min-h-10 w-full rounded border text-red-900 border-red-300 placeholder-red-300 focus:border-red-500 focus:ring-red-500";
    const textErrorClass =
      "block pl-2 min-h-10 w-full rounded border border-red-300 pr-10 placeholder-red-300 focus:border-red-500 focus:ring-red-500";
    const services = {
      skill_subcategories: skillsServiceSubcategories,
      company_name: companiesService,
      required_skills: skillsService,
      enhanced_skills: skillsService,
      academic_level: academicLevelsService,
      career_areas: socCodesService,
      industries_name: socCodesService,
      major_fit: cipCodesService,
      perks_associated: perksService,
      placement_type: placementTypesService,
      work_location_description: workLocationsService,
      languages_required: requiredLanguageService,
      terms: termsService,
      eligible_program_type: sessionTypesService,
      cities: citiesService,
    };
    const searchData = async (search, loading) => {
      if (props.fieldSettings.type === "term_select") return;
      loading(true);

      try {
        // if the field is required_skills or enhanced_skills, we need to pass the subcategories
        if (
          ["required_skills", "enhanced_skills"].includes(
            props.fieldSettings.name
          )
        ) {
          // extract lighcast_id from each subcategory in the array
          const ids = getSubcategoriesIds(props.subcategories);
          if (ids) {
            searchableOptions.value =
              (await services[props.fieldSettings?.name]?.list(search, ids)) ||
              [];
          }
        } else if (props.fieldSettings.proxy) {
          const response = await axios.get(props.fieldSettings.proxy, {
            params: {
              q: search,
            },
          });
          if (response.status === 200) {
            searchableOptions.value = extractResponseData(response);
          }
        } else if (props.fieldSettings.name === "terms") {
          let terms = await services[props.fieldSettings.name].list(search);
          searchableOptions.value = terms?.length
            ? wrapTermsOptions(terms)
            : [];
        } else if (props.fieldSettings.name === "industries_name") {
          let careerAreas = await services[props.fieldSettings.name].list(
            search
          );
          searchableOptions.value = removeDuplicatesByKey(
            careerAreas,
            "career_pathway"
          );
        } else if (props.fieldSettings.name === "cities") {
          const response = await services[props.fieldSettings?.name]?.getCities(
            {
              has_internships_only: true,
              q: search,
            }
          );
          if (response.status === 200) {
            searchableOptions.value = extractResponseData(response).items;
          }
        } else {
          searchableOptions.value =
            (await services[props.fieldSettings?.name]?.list(search)) || [];
        }
      } catch {
        console.warn("Error parsing Skills identifiers (1)");
      }
      loading(false);
    };

    const extractResponseData = (response) => {
      if (response.data && response.data.data) {
        return response.data.data;
      }
      return response.data;
    };

    const { emit } = context;
    onMounted(async () => {
      try {
        if (props.fieldSettings.name === "terms") {
          const optionsTerms = await termsService.list();
          const filterConcat = wrapTermsOptions(optionsTerms);

          searchableOptions.value = filterConcat;
        } else if (
          ["required_skills", "enhanced_skills"].includes(
            props.fieldSettings.name
          )
        ) {
          // extract lighcast_id from each subcategory in the array
          const ids = getSubcategoriesIds(props.subcategories);
          if (ids) {
            searchableOptions.value =
              (await services[props.fieldSettings?.name]?.list(null, ids)) ||
              [];
          }
        } else if (props.fieldSettings.name === "cities") {
          const response = await services[props.fieldSettings?.name]?.getCities(
            {
              has_internships_only: true,
            }
          );
          if (response.status === 200) {
            searchableOptions.value = extractResponseData(response).items;
          }
        } else if (props.fieldSettings.proxy) {
          const response = await axios.get(props.fieldSettings.proxy);
          if (response.status === 200) {
            searchableOptions.value = extractResponseData(response);
          }
        } else if (services[props.fieldSettings.name]) {
          searchableOptions.value =
            (await services[props.fieldSettings?.name]?.list()) || [];
        }
      } catch {
        console.warn("Error parsing Skills identifiers (2)");
      }
      eventBus.$on("clear", () => {
        selectValue.value = []; // Here you reset the selectValue
        emit("update:modelValue", "");
      });
    });

    function concatSessionWithName(obj) {
      return obj.name + " : " + obj.description;
    }
    const wrapTermsOptions = (terms) => {
      if (!terms.length) return [];
      return terms.map((item) => {
        return {
          name: item.name,
          id: item.id,
          name_desc: concatSessionWithName(item),
          capacity: item.capacity ?? 0,
        };
      });
    };
    return {
      searchableOptions,
      searchData,
      selectErrorClass,
      textErrorClass,
      services,
      selectValue,
      getSubcategoriesIds,
    };
  },
  data() {
    return {
      radioSelected: false,
      radioOptions: [],
      uniqueId: this.generateUniqueId(),
    };
  },
  watch: {
    modelValue: {
      handler: function (value) {
        if (this.fieldSettings.type.includes("radio")) {
          this.radioOptions = this.fieldSettings.options.map((item) => {
            return {
              label: item,
              selected: this.modelValue === item,
            };
          });
        }
      },
      deep: true,
      immediate: true,
    },
    subcategories: {
      handler: function (value) {
        if (
          ["required_skills", "enhanced_skills"].includes(
            this.fieldSettings.name
          )
        ) {
          this.loadSubcategories(value);
        }
      },
    },
  },
  methods: {
    generateUniqueId() {
      return Math.random().toString(36).substring(2, 8);
    },
    isAgeOrCapacity() {
      return (
        this.fieldSettings.name === "minimum_age" ||
        this.fieldSettings.name === "maximum_age" ||
        this.fieldSettings.name === "capacity"
      );
    },
    async loadSubcategories(value) {
      const ids = this.getSubcategoriesIds(value);
      if (ids) {
        this.searchableOptions =
          (await this.services[this.fieldSettings?.name]?.list(
            undefined,
            ids
          )) || [];
      }
    },
  },
};
</script>
