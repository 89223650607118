<template>
  <div class="bg-white h-full flex flex-col font-montserrat">
    <p class="mx-10 mt-8 text-xl font-medium text-indigo-base">
      Here are some of the excursions available for your program. A full list of
      activities will be available in your itinerary.
    </p>
    <div class="m-10 flex-grow">
      <excursion-card
        v-for="excursion in props.excursions"
        :key="excursion.id"
        :excursion="excursion"
        :selected="selectedExcursions.includes(excursion.id)"
        :default-excursion="defaultExcursion"
      />
    </div>

    <StickyFooter
      max-next-width="max-w-[260px]"
      :disabled-next="props.disabled"
      :show-required-section="false"
      @back="goToPreviousStep"
      @next="goToNextStep"
    />
  </div>
</template>

<script setup>
import { defineProps, inject } from "vue";
import ExcursionCard from "./ExcursionCard";
import StickyFooter from "@/components/Configurator/StickyFooter.vue";

const { goToNextStep, goToPreviousStep } = inject("steps");

const props = defineProps({
  excursions: {
    type: Array,
    default: () => [],
  },
  selectedExcursions: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: true,
  },
  defaultExcursion: {
    type: Object,
    default: () => {},
  },
});
</script>
