<script setup>
import { ref, watch, reactive, computed, toRefs } from "vue";
import ShowMore from "../ShowMore/ShowMore.vue";
import InternshipPage from "../InternshipSection/InternshipPage.vue";
import { joinArrayItems } from "@/mixins/helpers.js";
import internshipService from "@/services/internship";
import { differenceInWeeks, getYear } from "date-fns";
import { useRouter, useRoute } from "vue-router";
import { scrollToTarget } from "../../../util/scrollToTarget";
import {
  UMassCities,
  UMassAndCongresoCities,
  UMassOrHomeSchoolCities,
} from "@/components/program-manager/sessions/components/Internships/constants.js";

import _ from "lodash";
const router = useRouter();
const route = useRoute();

const props = defineProps({
  internshipsObj: {
    type: Object,
    required: true,
    default: () => {},
  },
  session: {
    type: Object,
    required: true,
    default: () => {},
  },
  program: {
    type: Object,
    required: true,
    default: () => {},
  },
});

const emit = defineEmits(["observeSection"]);
const { internshipsObj, session, program } = toRefs(props);

let showPage = ref(false);
let totalPages = 0;
const urlName = "internship_page";
const isPages = ref(false);
const items = ref([]);
const pageParam = ref(router?.currentRoute?.value?.query?.internship_page ?? 1);
const mainCardDiv = ref(null);
const scrollDuration = 600;

const handleShowLess = (eventData) => {
  showLessData(eventData);
  scrollToTarget(mainCardDiv, scrollDuration);
};

function showMoreData(page) {
  pageParam.value = page;
  let newItems = chunkedInternshipArray.value[page - 1];
  items.value = [...items.value, ...newItems];
}

function showLessData(page) {
  pageParam.value = page;
  let newItems = chunkedInternshipArray.value[page - 1];
  items.value = [...newItems];
}

function updateShowMore(bool) {
  isPages.value = bool;
}

const chunkedInternshipArray = ref([]);

const internships = reactive({
  items: [],
  count: 0,
  end: 0,
  start: 0,
});

const getQualifications = (academicLevels, skills, languages) => {
  const concatAcademicLevels = joinArrayItems(academicLevels, "academic_year");
  const concatSkills = joinArrayItems(skills, "value");
  const concatLanguages = joinArrayItems(
    languages,
    "proficiency_level",
    "value",
    ""
  );

  const qualificationsCopy = [
    concatAcademicLevels,
    concatSkills,
    concatLanguages,
  ]
    .filter(Boolean)
    .join(", ");

  if (qualificationsCopy) return "Open to " + qualificationsCopy;
  else return "";
};

const isAnyUMass = computed(() => {
  return isUMass.value || isUMassAndCongreso.value || isUMassOrHomeSchool.value;
});

const arrivalCityId = computed(() =>
  session?.value?.session_travel_details[0]?.arrival_city?.id?.toString()
);

const isUMass = computed(() =>
  Object.keys(UMassCities).includes(arrivalCityId.value)
);

const isUMassAndCongreso = computed(() =>
  Object.keys(UMassAndCongresoCities).includes(arrivalCityId.value)
);

const isUMassOrHomeSchool = computed(() =>
  Object.keys(UMassOrHomeSchoolCities).includes(arrivalCityId.value)
);

const weeks = computed(() => {
  return differenceInWeeks(
    new Date(session?.value?.session_travel_details?.departure_date),
    new Date(session?.value?.session_travel_details?.arrival_date)
  );
});

const year = computed(() => {
  return getYear(
    new Date(session?.value?.session_travel_details?.arrival_date)
  );
});

const semesterItems = () => {
  let termsNoCopy = [];
  termsNoCopy.push(session?.value?.terms?.[0]);

  session?.value?.terms?.forEach((term) => {
    if (termsNoCopy.every((noCopy) => noCopy.session !== term.session))
      termsNoCopy.push(term);
  });

  return joinArrayItems(termsNoCopy, "session");
};

const cityCountrySession = computed(() => {
  const city = session?.value?.arrival_city?.city_ascii ?? "";
  const country = session?.value?.arrival_city?.country?.name ?? "";

  return { city, country };
});

const getInterships = async (newInternships) => {
  let cityArray = [];
  let termsArray = [];
  newInternships?.cities?.forEach((obj) => {
    cityArray.push(String(obj?.city?.city_ascii));
  });
  newInternships?.terms?.forEach((obj) => {
    termsArray.push(String(obj?.id));
  });
  let params = {
    limit: 20,
    cities: cityArray,
    terms: termsArray,
    durations: newInternships?.durations ?? [],
    status: "Active",
  };
  const response = await internshipService.getInternships(params);
  const data = response?.data?.data ?? {};
  const mapItems = data?.items
    ? data.items.map((item) => {
        return {
          ...item,
          program_page: {
            id: item.id,
            name: item.title,
          },
          name: item.title,
          industry: joinArrayItems(item?.industries, "career_pathway"),
          qualifications: getQualifications(
            item.academic_levels,
            item.needed_skills,
            item.required_languages
          ),
          weeks: weeks.value,
          semester: semesterItems(),
          year: year.value,
          program_id: program?.value?.id,
          session_id: session?.value?.id,
          internship_id: item?.id,
          session: {
            session_type: { name: "Internship" },
            session_travel_details: session?.value?.session_travel_details,
            academic_start_date: session?.value?.academic_start_date ?? "",
            academic_end_date: session?.value?.academic_end_date ?? "",
            academic_start_date_finalized:
              session?.value?.academic_start_date_finalized,
            academic_end_date_finalized:
              session?.value?.academic_end_date_finalized,
          },
          cityCountrySession: cityCountrySession.value,
        };
      })
    : [];

  internships.items = mapItems;
  internships.count = data?.count ?? 0;
  internships.end = data?.end ?? 0;
  internships.start = data?.start ?? 0;

  chunkedInternshipArray.value = _.chunk(internships.items, 3);
  totalPages = chunkedInternshipArray.value.length;

  if (pageParam.value > chunkedInternshipArray.value.length) {
    pageParam.value = 1;
    await router.push({
      query: { ...route.query, internship_page: pageParam.value },
    });
  }

  if (isNaN(pageParam.value)) {
    pageParam.value = 1;
    await router.push({
      query: { ...route.query, internship_page: pageParam.value },
    });
  }
  isPages.value =
    pageParam.value >= chunkedInternshipArray.value.length ? true : false;
  items.value = _.flatten(
    _.slice(chunkedInternshipArray.value, 0, pageParam.value)
  );
};

watch(
  internshipsObj,
  async (newInternships) => {
    if (newInternships.length != 0) {
      showPage.value = true;
      await getInterships(newInternships);
    }
  },
  { immediate: true, deep: true }
);

watch(
  [() => showPage.value, () => session.value, () => internships],
  ([newShowPage, newSession, newInternships]) => {
    if (
      newShowPage &&
      newSession?.internship_filter_criteria &&
      newInternships
    ) {
      emit("observeSection", "internships");
    }
  }
);
</script>

<template>
  <div
    v-if="showPage && session?.internship_filter_criteria && internships"
    ref="mainCardDiv"
    class="text-indigo-base pt-20 px-4 md:px-8"
  >
    <div class="text-4xl mt-4 mb-4 font-bold text-indigo-base">
      <div class="text-2xl" data-testid="internship-section-title">
        Internship
      </div>
      <div data-testid="internship-section-subtitle">
        OPPORTUNITIES
      </div>
    </div>
    <div
      class="space-y-4 block text-justify h-fit w-full mb-8 text-indigo-base"
    >
      <p>
        Review available internships and select up to three placements that
        utilize and build upon your skillset, moving you toward your career
        goals! Internship details include required skills and intern
        responsibilities, work schedule, and work site location. After reviewing
        your selections with a career coach, you will have an employer interview
        and secure your placement!
      </p>
      <p>
        In addition to gaining valuable work experience,<span
          v-if="isUMassOrHomeSchool"
        >
          students interning in Italy are
          <span class="font-bold">required</span> to enroll in academic credit
          for your internship either by your home university or
        </span>
        <span v-else>
          you can earn academic credit for your internship
        </span>
        by enrolling in
        <a
          target="_blank"
          href="/files/UMass_Amherst_Syllabus.pdf"
          :class="`${secondaryColorClass} font-semibold hover:underline`"
          >INTERN 301</a
        >. The asynchronous course,
        <span v-if="isAnyUMass"
          >accredited and transcripted by University of Massachusetts at
          Amherst,</span
        >
        prepares API interns for navigating diverse cultural workplace
        environments and developing essential skills necessary for success in a
        global workplace.
      </p>
      <p v-if="isUMassAndCongreso">
        If you are completing your internship in Spanish, you can earn
        Spanish-language credit by enrolling in
        <a
          target="_blank"
          href="/files/Universidad_de_Congreso_Syllabus.pdf"
          class="text-teal-900 font-semibold hover:underline"
        >
          PPC Internship Experience in Spanish</a
        >. This course includes synchronous class meetings and offline work.
        Students must have taken at least 2 Spanish classes at the 300 level or
        above, or demonstrate written and oral proficiency at the equivalent
        level.
      </p>
    </div>
    <InternshipPage :session="session" :display-internship="items" />
    <ShowMore
      v-if="chunkedInternshipArray"
      :total-pages="totalPages"
      :current-page="pageParam"
      :is-pages="isPages"
      :url-param-name="urlName"
      @page-change="showMoreData"
      @show-less="handleShowLess"
      @show-bool="updateShowMore"
    />
    <hr class="my-1 border-b-3 md:mt-20 border-gray-200" />
  </div>
</template>
