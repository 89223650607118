<script setup>

</script>

<template>
  <div id="tooltip" :class="`${secondaryColorClass}`">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      :class="`w-6 h-6 ${secondaryColorClass}`"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
      />
    </svg>

    <div id="tooltiptext" class="w-[413px]">
      <div>
        <div class="flex items-center">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6 text-indigo-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          </div>
          <div class="font-semibold uppercase text-lg ml-2 text-indigo-base">
            ORGANIZATION SIZE
          </div>
        </div>
        <div class="space-y-3 text-sm mt-2 ml-5 text-indigo-base">
          <li>Small: 10-49 employees</li>
          <li>Medium: 50-249 employees</li>
          <li>Large: More than 250 employees</li>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
 
.container {
  position: absolute;
  top: 370px;
  right: 540px;
  display: inline-block;
}

#tooltip {
  position: relative;
  cursor: pointer;
  padding: 2px;
}

#tooltiptext {
  position: absolute;
  left: 50%;
  transform: translateX(-10%);
  background-color: #ffffff;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.1);
  color: black;
  padding: 1.5rem 1.5rem;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}
#tooltiptext::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-175px);
  border: 10px solid;
  border-color: #ffffff transparent transparent transparent;
}
#tooltip:hover #tooltiptext {
  bottom: 100%;
  visibility: visible;
  opacity: 1;
}
</style>
