<script setup>
import OrderDetails from "@/components/OrderDetails/OrderDetails.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import { V3_stepCanBeReturned } from "@/composables/VersionHelper.js";
import v3FormTracker from "@/services/V3FormTracker.js";
import { computed, inject, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { formattedPriceFromCents as formattedPrice } from "@/util/formatter";
import enrollmentService from "@/services/enrollment";
import getApplicableContract from "@/composables/useBillings";
import { APPLICATION_FEE_DATA, OLD_APPLICATION_FEE_AMOUNT } from "@/constants";
import { dollarsToCents } from "@/util/formatter";
import { usePastDeadline } from "@/composables/usePastDeadline.js";
import * as Sentry from "@sentry/vue";
import paymentService from "@/services/payment";
import SkeletonLoader from "@/components/shared/loaders/BaseSkeletonLoader.vue";

const {
  disabledContinueButton,
  isPastDeadline,
  validateDeadline,
} = usePastDeadline();

const store = useStore();
const router = useRouter();
const route = useRoute();
const submissionError = ref("");
const reviewSubmit = ref(null);
const updateStepAndRedirect = inject("updateStepAndRedirect");
const pricing = ref(null);

// Computed
const applicationId = computed(() => store.state.currentApplicationId);
const program = computed(() => store.getters["configurator/getCurrentProgram"]);
const currentOrder = computed(() => store.getters["configurator/getCurrentOrder"]);
const homeInstitutionId = computed(
  () => store.state.profileData?.colleges?.[0]?.college_id ?? ""
);

const billingRules = ref(currentOrder.value?.billing_rules);
const opeId = currentOrder.value?.ope_id;


const displayApplicationFee = computed(() => !(billingRules.value?.applicationFee === "Institution"));
const displayConfirmationPayment = computed(() => !(billingRules.value?.comfirmationPayment === "Institution"));
const displayPaymentSchedule = computed(() => !(billingRules.value?.direct_billing_type));
const paymentScheduleIsExpanded = ref(false);
const showApplicationFeeLoader = ref(false);
const applicationFeeAmountPaid = ref(undefined);
const applicationFee = computed(() => {
  if(applicationFeeAmountPaid.value) {
    return {
      value: dollarsToCents(applicationFeeAmountPaid.value),
      label: "Total Paid",
    }
  } else {
    return {
      value: dollarsToCents(APPLICATION_FEE_DATA.amount),
      label: "Due Today"
    }
  }
});
const confirmationPayment = computed(() => 40000);
const finalPayment = computed(() => {
  let total = pricing.value?.student_total_price_in_cents;
  if (!total) {
    return 0;
  }
  total = total - confirmationPayment.value;
  if (!APPLICATION_FEE_DATA.flagActive || applicationFeeAmountPaid.value === dollarsToCents(OLD_APPLICATION_FEE_AMOUNT)) {
    total = total - applicationFee.value;
  }
  return total;
});

const rootConfiguratorHref = computed(() => {
  if (!currentOrder.value.program_page_id) {
    return "";
  }
  const location = router.resolve({
    name: "configurator",
    params: {
      programPageId: currentOrder.value.program_page_id,
      orderId: currentOrder.value.id,
      slug: "program",
    },
    query: {
      session: currentOrder.value.session_id,
    },
  });

  return location.href;
});

// Methods
const submitReview = async () => {
  try {
    reviewSubmit.value.startLoading();

    validateDeadline();

    if (isPastDeadline.value) return;

    const response = await v3FormTracker.setAsCompleted(
      "Review",
      applicationId.value
    );

    // All went ok - redirect
    if (response?.id !== undefined) {
      store.commit("setStepCompletedV3", "Review");
      let nextStep = "application-checkout";
      updateStepAndRedirect(nextStep, route.params.applicationId);
    }
  } catch (e) {
    submissionError.value = e;
  } finally {
    reviewSubmit.value.stopLoading();
  }
};

async function canViewForm() {
  // Allow to return if step was completed or is next step
  let canViewForm = await V3_stepCanBeReturned("Review");
  if (!canViewForm) {
    // Go to About You
    router.push({
      name: "application-about-you",
      params: { applicationId: applicationId.value },
    });
  }
}

async function getPayments() {
  try {
    showApplicationFeeLoader.value = true;
    const response = await paymentService
      .getApplicationFeeAmount(applicationId.value);
    applicationFeeAmountPaid.value = response;
  } catch (err) {
    Sentry.captureException(
        new Error("Get payment failed in review page"),
        {
          tags: {
            error: err,
          },
        }
      );
  } finally {
    // need to include the loading since paymentFee has default value as 0 and for first checkout without results,
    // this endpoint will return 404 and will assume default value
    showApplicationFeeLoader.value = false;
  }
};

onBeforeMount(async () => {
  const promises = [getPayments(), canViewForm()];

  if (!billingRules.value && opeId.value) {
    promises.push(enrollmentService.fetchBillingRules(opeId.value).then(
      (rule) => {
        const contracts = rule?.agreements || [];
        const applicableContract = getApplicableContract(contracts, program.value?.programSession);
        billingRules.value = applicableContract;
      }
    ))
  }

  await Promise.all(promises);
});
</script>

<template>
  <div
    class="font-montserrat text-indigo-base text-left bg-white w-full pb-4 max-w-4xl md:px-0 lg:px-0"
  >
    <div class="grid grid-cols-1 gap-y-7 pt-4">
      <div class="p-14 md:p-0">
        <h1 class="font-bold text-[28px] uppercase" data-testid="review-headline">
          Take a moment to make sure all of your selections look just right!
        </h1>
        <p
          v-if="currentOrder.status === 'Draft'"
          class="font-medium text-xl pt-10"
          data-testid="review-copy"
        >
          <router-link
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            :to="rootConfiguratorHref"
            >Go back</router-link
          >
          if something doesn’t look right. <br />
          <br />
          Don’t worry, we’ll save your progress so you can pick up right where you left off!
        </p>
      </div>

      <div
        class="flex flex-col w-full bg-teal-light py-4 px-6 gap-8 md:max-w-[50%] md:m-auto"
      >
        <order-details
          :current-order="currentOrder"
          :program-session="program.programSession"
          :ope8-id="program?.school?.ope_id"
          :home-institution-id="homeInstitutionId"
          @get-pricing="(_pricing) => (pricing = _pricing)"
        />
        <template v-if="displayPaymentSchedule">
          <hr class="mt-1 border-configurator-order-summary-gray" />
          <div>
            <div
              class="grid grid-cols-2 cursor-pointer"
              data-testid="price-breakdown-toggler"
              @click="
                () => (paymentScheduleIsExpanded = !paymentScheduleIsExpanded)
              "
            >
              <div v-if="pricing" class="flex items-center justify-between col-span-2">
                <p class="text-base block w-full text-indigo-base">
                  <span>Payment Schedule</span>
                  <template v-if="displayApplicationFee">
                    <SkeletonLoader
                      v-if="showApplicationFeeLoader"
                      rounded
                      class="ml-8"
                      style="width: 30%; float: right;"
                    />
                    <span v-else class="ml-8 font-bold"
                      >{{ applicationFee.label }}: {{ formattedPrice(applicationFee.value) }}</span
                    >
                  </template>
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                  :class="
                    paymentScheduleIsExpanded ? 'transform rotate-180' : ''
                  "
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
              <hr
                v-if="paymentScheduleIsExpanded"
                class="col-span-2 my-4 border-configurator-order-summary-gray"
              />
              <div
                v-if="paymentScheduleIsExpanded"
                class="col-span-2 space-y-2 mt-2 text-base"
              >
                <div v-if="displayConfirmationPayment" class="flex justify-between items-center">
                  <div class="text-indigo-base" data-testid="housing-fee-label">
                    Confirmation Payment
                  </div>
                  <div class="font-bold text-indigo-base">
                    {{ formattedPrice(40000) }}
                  </div>
                </div>
                <p class="text-xs">
                  Payment deadline - Two weeks after acceptance
                </p>
                <div class="flex justify-between items-center">
                  <div class="text-indigo-base">Final Payment</div>
                  <div
                    class="font-bold text-indigo-base"
                    data-testid="housing-fee-value"
                  >
                    {{ formattedPrice(finalPayment) }}
                  </div>
                </div>
                <p class="text-xs">
                  Payment deadline - Two weeks after confirmation payment.
                </p>
                <p class="text-xs">
                  *Financial aid can be used to push back your final payment
                  deadline.
                </p>
              </div>
            </div>
          </div>
        </template>
        <ButtonWithSpinner
          id="reviewButton"
          ref="reviewSubmit"
          data-cy="reviewButton"
          type="submit"
          variant="secondary"
          variant-type="block"
          button-height="min-h-[58px] md:min-h-[60px]"
          :grey-disabled-class="true"
          :disabled="disabledContinueButton"
          @click="submitReview"
        >
          <div class="flex items-center justify-center">
            <span class="pr-3 uppercase" data-testid="review-summary-text">
              <!-- Todo: Think, is text different if group visa is the next step, instead of checkout? -->
              Next
            </span>
            <ArrowRight custom-class="w-4 h-4" />
          </div>
        </ButtonWithSpinner>
      </div>

      <div v-if="submissionError" class="error text-error-900 mt-2">
        {{ submissionError }}
      </div>
    </div>
  </div>
</template>
