<template>
  <div class="bg-blue-100 text-sm text-indigo-base">
    <div class="mx-5">
      <div class="flex flex-col items-baseline justify-between">
        <div ref="ele" class="w-full"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  TABLEAU_VIZ_FRAME_HEIGHT,
  TABLEAU_VIZ_FRAME_WIDTH,
} from "@/constants.js";
import { isExclusivelyParticipant } from "@/composables/authorization.js";
import { researchVizSource } from "@/composables/tableau.js";

import { ref, onMounted } from "vue";

import { TableauViz, TableauEventType } from "@tableau/embedding-api";

const ele = ref(null);

onMounted(() => {
  if (ele.value) {
    const viz = new TableauViz();
    viz.src = researchVizSource.value;
    viz.toolbar = "hidden";
    viz.width = TABLEAU_VIZ_FRAME_WIDTH;
    viz.height = TABLEAU_VIZ_FRAME_HEIGHT;

    ele.value.appendChild(viz);
  }
});
</script>
