<script setup>
import {
  Dialog,
  DialogPanel,
  DialogDescription,
  DialogTitle,
} from "@headlessui/vue";

import CloseIcon from "@/components/svg-icons/CloseIcon";
import WarningIcon60 from "@/components/svg-icons/WarningIcon60.vue";
import BaseButton from "@/components/shared/Button/BaseButton";

const props = defineProps({
  open: Boolean,
});

const emit = defineEmits(["closeModal", "saveRoomType"]);

const closeModal = () => {
  emit("closeModal");
};

const saveRoomType = () => {
  emit("saveRoomType");
  emit("closeModal");
};
</script>

<template>
  <div v-if="props.open" class="relative z-50">
    <div
      class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
    />
    <Dialog
      :open="props.open"
      class="fixed inset-0 z-50 overflow-auto bg-white py-6 sm:bg-transparent sm:p-12 sm:p-6"
      @close-modal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="relative z-20 mx-auto max-w-4xl bg-white px-6 sm:rounded-lg sm:px-16 sm:py-20 sm:shadow md:px-24"
      >
        <div class="absolute right-3r xs:top-1r sm:top-3r md:top-3r lg:top-3r">
          <button
            class="flex h-6 w-6 items-center justify-center"
            @click="closeModal"
          >
            <span class="sr-only">Close</span>
            <CloseIcon />
          </button>
        </div>
        <DialogPanel>
          <DialogTitle
            class="mt-6 flex justify-center text-center text-xl leading-tight sm:text-2xl md:text-3xl"
          >
            Wait a minute...
          </DialogTitle>
          <div>
            <WarningIcon60 class="mx-auto mt-1"></WarningIcon60>
            <DialogDescription class="mb-10">
              <p class="mt-10 text-center">
                Updating the room type on active housing can impact students
                currently assigned to this housing. It is recommended to create
                a new housing listing or new unit with the new room type rather
                than updating an active unit.
              </p>
            </DialogDescription>
          </div>
          <p class="mb-5 text-center">Do you want to update room type?</p>
          <div class="flex justify-center">
            <BaseButton outlined :on-click="closeModal">Cancel</BaseButton>
            <BaseButton @click="saveRoomType">Yes</BaseButton>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>
