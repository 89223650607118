<template>
  <div class="relative z-10">
    <Dialog
      :open="props.isModalOpen"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
      @closeModal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="fixed inset-0 z-0 hidden bg-university-primary opacity-50 sm:block"
      ></div>
      <div
        class="max-w-4xl p-6 mx-auto bg-white sm:rounded-lg sm:shadow z-20 relative"
      >
        <DialogPanel>
          <div class="text-right">
            <button @click="closeModal">
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <section class="sm:px-16 md:px-24 sm:py-10">
            <h1
              class="flex justify-center text-xl leading-tight text-center sm:text-2xl md:text-3xl"
            >
              Host Institution Filter
            </h1>
            <div>
              <CheckboxSelect
                id="housing-country"
                name="housing-country"
                field-label-classes="block font-semibold mt-6"
                class="col-span-3"
                field-label="Country"
                label="name"
                placeholder="Search and add"
                :model-value="advancedFilters.country_id"
                :components="{ OpenIndicator: Magnifier }"
                :loading="countriesLoading"
                :reduce="(val) => val.id"
                :options="countriesState"
                :deselect-from-dropdown="true"
                @search="filterCountryOptions"
                @update:modelValue="changeCountry($event)"
              >
              </CheckboxSelect>
              <CheckboxSelect
                field-label-classes="block font-semibold mt-6"
                class="col-span-3"
                field-label="City"
                label="name"
                placeholder="Search and add"
                :model-value="advancedFilters.city"
                :components="{ OpenIndicator: Magnifier }"
                :options="cityOptions?.items || []"
                :loading="cityLoading || !cityReady"
                :deselect-from-dropdown="true"
                :clear-search-on-blur="() => false"
                :get-option-label="getCityOptionLabel"
                @search="fetchCityOptions"
                @update:modelValue="changeCity($event)"
              >
              </CheckboxSelect>
            </div>
            <div class="flex justify-end mt-5">
              <button
                :class="`${secondaryColorClass}`"
                class="mr-7 my-7 text-center uppercase hover:opacity-70"
                @click="clearFilters"
              >
                RESET FILTERS
              </button>
            </div>
            <div class="flex justify-center">
              <BaseButton class="text-base" outlined :on-click="closeModal">
                Cancel
              </BaseButton>
              <BaseButton class="text-base" :on-click="applyFilters">
                Apply
              </BaseButton>
            </div>
          </section>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, reactive } from "vue";
import { debounce } from "lodash";

import Magnifier from "@/components/shared/icons/Magnifier";
import CheckboxSelect from "@/components/shared/select/CheckboxSelect.vue";
import {
  useGetCityOptions,
  useSearchCountryOptions,
} from "@/components/program-manager/sessions/composable";

import { Dialog, DialogPanel } from "@headlessui/vue";

import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import BaseButton from "@/components/shared/Button/BaseButton";

const emit = defineEmits(["closeModal", "applyFilters"]);
const props = defineProps({
  isModalOpen: {
    type: Boolean,
    default: false,
  },
});

const advancedFilters = reactive({
  country_id: undefined,
  city: undefined,
});

const {
  execute: executeFetchCityOptions,
  isLoading: cityLoading,
  state: cityOptions,
  isReady: cityReady,
} = useGetCityOptions(
  { immediate: true, throwError: true },
  { city_ascii: "chi" }
);

const {
  isLoading: countriesLoading,
  state: countriesState,
} = useSearchCountryOptions(
  { immediate: true, throwError: true },
  { limit: 500 }
);

const fetchCityOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    loading(true);
    try {
      await executeFetchCityOptions(0, { city_ascii: search, cancel: true });
      loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        loading(false);
      }
    }
  }
}, 500);

const clearFilters = () => {
  advancedFilters.city = undefined;
  advancedFilters.country_id = undefined;
};

const filterCountryOptions = (textInput) =>
  countriesState.value?.filter(({ name }) => name.includes(textInput)) || [];

const getCityOptionLabel = (option) => {
  if (option) {
    const { city_ascii = "", country: { iso } = {}, admin_name = "" } = option;
    return `${city_ascii}, ${admin_name}, ${iso}`;
  }
  return "";
};

const changeCountry = (value) => {
  advancedFilters.country_id = value;
};

const changeCity = (value) => {
  advancedFilters.city = value;
};

const closeModal = () => {
  emit("closeModal", true);
};

const applyFilters = () => {
  const { country_id, city } = advancedFilters;
  emit("applyFilters", {
    ...(country_id ? { country_id } : {}),
    ...(city ? { shipping_address_city: city.city_ascii } : {}),
  });
  emit("closeModal", true);
};
</script>
