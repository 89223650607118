<script setup>
import StaffReceptionInput from "@/components/program-manager/housing/components/StaffReceptionInput/index.vue";
import ScheduledTime from "@/components/program-manager/housing/components/StaffReceptionInput/ScheduledTime.vue";
import CitySection from "@/components/program-manager/housing/components/CitySection";
import { computed, reactive, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  maxLength,
  minLength,
  minValue,
  numeric,
  required,
  requiredIf,
} from "@vuelidate/validators";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import BaseInput from "./BaseInput.vue";
import BaseToggle from "../forms/SharedComponents/BaseToggle.vue";
import { formSettings } from "./formSettings";
import LoadingPageComponent from "./Loading.vue";
import LanguageInputs from "./LanguageInputs.vue";
import WYSIWYG from "../forms/SharedComponents/WYSIWYG/WYSIWYG.vue";
import AddressComponent from "@/components/forms/AddressInputComponent/AddressComponent";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import BreadCrumb from "@/components/shared/BreadCrumb.vue";
import PriceSection from "@/components/shared/PriceSection";
import internshipService from "@/services/internship";
import ErrorMessage from "@/components/shared/ErrorMessage";
import ErrorPage from "@/components/errorPage.vue";
import ArchivingModal from "@/components/modals/ArchivingModal.vue";
import DuplicateModal from "@/components/modals/DuplicateModal.vue";
import Terms from "./Terms.vue";
import { ExclamationCircleIcon } from "@heroicons/vue/20/solid";
import { isAuthorized } from "../../composables/authorization";
import { ERROR_TIMEOUT, SUCCESS_TIMEOUT } from "@/constants.js";
import { dollarsToCents, centsToDollars } from "@/mixins/helpers";
import { useGetInternship } from "./composable";
import AddButton from "@/components/shared/AddButton.vue";
import { dayOrder } from "@/constants";
import _ from "lodash";

const store = useStore();
const route = useRoute();
const router = useRouter();

const internshipId = computed(() => {
  return route?.params?.itemId ?? null;
});

const WEEKLY_RECURRING = "Weekly Recurring";

const currentMode = ref(internshipId.value ? "viewing" : "creating");
const modelValue = ref();
const toast = useToast();

const readLiteOnly =
  isAuthorized("items.read.lite") && !isAuthorized("items.read");

const canWrite = isAuthorized("items.write");

const statusIsSelectable = (statusObject) => {
  const status = statusObject.value;
  if (status !== "Active") {
    return true;
  }
  return isAuthorized("items.pub");
};

/* Vuelidate settings */
const settings = reactive(formSettings);
const form = ref({
  status: {
    id: "Draft",
    value: "Draft",
  },

  weeklyRecurringSchedule: {},
  company: "",
  projectTitle: "",
  aboutCompany: "",
  companySize: "",
  placementType: "",
  headquarterLocation: "",
  workLocationDescription: "",
  cityData: {},
  workLocationStreetAddress: {},
  positionDescription: "",
  academicLevel: "",
  otherLanguageRequirement: "",
  languagesRequired: [],
  languageAndProficiency: [],
  whenStarts: [],
  scheduleType: null,
  careerAreas: [],
  majorFit: [],
  industries: [],
  skillSubcategories: [],
  requiredSkills: [],
  enhancedSkills: [],
  perksAssociated: [],
  marketplace: true,
  internshipPayed: "Unpaid",
  creditsAvailable: 0,
  interviewRequired: "Yes",
  duration: null,
  durationUnit: "weeks",
  fall_capacity: null,
  spring_capacity: null,
  summer_capacity: null,
  minimum_age: null,
  maximum_age: null,
  scheduleDuration: null,
  eligibleProgramType: null,
  product_prices: [],
});

watch(
  () => form.value.scheduleType,
  (newVal) => {
    if (newVal === "Other") {
      form.value.weeklyRecurringSchedule = {};
    } else if (newVal === WEEKLY_RECURRING) {
      form.value.ifOtherWhatSchedule = "";
    }
  }
);

const mustHaveText = (item) => {
  if (!item?.content[0]?.content) return false;
  return true;
};
const mustHaveTextIf = (item, others) => {
  if (others.scheduleType !== "Other") return true;
  if (!item?.content[0]?.content) return false;
  return true;
};
const requiredIfOther = (item, others) => {
  if (others.scheduleType !== "Other") return true;
  if (!item) return false;
  return true;
};
const mingLengthIf = (item, others) => {
  if (others.otherLanguageRequirement !== "Yes") return true;
  if (!item.length) return false;
  return true;
};
const mustHaveProficiencyLevel = (item, others) => {
  if (others.otherLanguageRequirement !== "Yes") return true;
  if (!Array.isArray(item)) return false;
  if (!item.length) return false;
  return true;
};
const mustHaveEnabledTrue = (item = {}, others) =>
  others.scheduleType === WEEKLY_RECURRING
    ? Object.values(item || {})?.find(({ enabled = undefined }) => enabled)
        ?.enabled
    : true;

const rules = {
  product_prices: {
    required: helpers.withMessage("Price is required", required),
  },
  status: { required },
  company: { required },
  projectTitle: { required, maxLength: maxLength(255) },
  placementType: { required },
  workLocationDescription: { required },
  positionDescription: {
    required,
    mustHaveText: helpers.withMessage(
      "This field cannot be empty",
      mustHaveText
    ),
  },
  academicLevel: { required },
  otherLanguageRequirement: { required },
  languageAndProficiency: {
    mustHaveProficiencyLevel: helpers.withMessage(
      "This field cannot be empty",
      mustHaveProficiencyLevel
    ),
  },
  languagesRequired: {
    required: requiredIf(function () {
      return form.value.otherLanguageRequirement === "Yes";
    }),
    mingLengthIf: helpers.withMessage(
      "This field cannot be empty",
      mingLengthIf
    ),
  },
  whenStarts: { required, minLength: minLength(1) },
  minimum_age: {
    minValue: minValue(1),
    numeric,
  },
  maximum_age: {
    minValue: minValue(1),
    numeric,
  },
  scheduleType: { required },
  ifOtherWhatSchedule: {
    mustHaveTextIf: helpers.withMessage(
      "This field cannot be empty",
      mustHaveTextIf
    ),
  },
  careerAreas: { required, minLength: minLength(1) },
  industries: { required },
  skillSubcategories: { required },
  requiredSkills: { required },
  enhancedSkills: { required },
  internshipPayed: { required },
  creditsAvailable: { required, numeric },
  interviewRequired: { required },
  workLocationStreetAddress: {
    required: requiredIf(function () {
      return !hideStreetAddress.value;
    }),
  },
  weeklyRecurringSchedule: {
    mustHaveEnabledTrue: helpers.withMessage(
      "Please, select at least one day of the week",
      mustHaveEnabledTrue
    ),
  },
  scheduleDuration: {
    requiredIfOther: helpers.withMessage(
      "This field cannot be empty",
      requiredIfOther
    ),
  },
  eligibleProgramType: { required },
};
const v$ = useVuelidate(rules, form);

/* Languages inputs */
const updateLanguages = (value) => {
  form.value.languageAndProficiency = form.value.languageAndProficiency.filter(
    (item) => item.value !== value.value
  );
  if (value.operation !== "remove" || currentMode.value === "viewing") {
    form.value.languageAndProficiency.push(value);
  }
};

/* Computeds */

const requiredToPublish = computed(() => {
  return form.value.status.value === "Active";
});

const computedRequiredLanguages = computed(() => {
  if (!form.value.languagesRequired.length) return null;
  let newValue = form.value.languagesRequired.map((language) => {
    let proficiency = form.value.languageAndProficiency.find(
      (p) => p.value === language.value
    );
    if (!proficiency) return null;
    return {
      id: language.id,
      value: language.value,
      proficiency_level: proficiency.proficiency_level.value,
    };
  });
  let newValueFiltered = newValue.filter((lang) => Boolean(lang));
  if (!newValueFiltered.length) return null;
  return newValueFiltered;
});
const computedSchedule = computed(() => {
  if (!form.value.scheduleType) return {};
  let schedule = {};
  schedule["type"] = form.value.scheduleType;
  schedule["duration"] = form.value.scheduleDuration;
  if (form.value.ifOtherWhatSchedule) {
    schedule["description"] = { ...form.value.ifOtherWhatSchedule };
  }
  if (form.value.scheduleType === WEEKLY_RECURRING) {
    schedule["description"] = { ...form.value.weeklyRecurringSchedule };
  }
  return schedule;
});
const computedPositionResponsabilityDescription = computed(() => {
  return { ...form.value.positionDescription };
});
const computedSkillSubcategories = computed(() => {
  if (!form.value.skillSubcategories.length) return [];
  return form.value.skillSubcategories.map((subcategory) => {
    return {
      id: subcategory.id,
      value: subcategory.value,
    };
  });
});
const computedRequiredSkills = computed(() => {
  if (!form.value.requiredSkills.length) return [];
  return form.value.requiredSkills.map((skill) => {
    return {
      id: skill.id,
      value: skill.value,
    };
  });
});
const computedEnhancedSkills = computed(() => {
  if (!form.value.enhancedSkills.length) return [];
  return form.value.enhancedSkills.map((skill) => {
    return {
      id: skill.id,
      value: skill.value,
    };
  });
});
const computedInternshipCity = computed(() =>
  form?.value?.cityData?.id ? form?.value?.cityData : undefined
);
const computedPageCopy = computed(() =>
  currentMode.value === "creating" ? "Create Internship" : "Internship Detail"
);

const addressFieldContent = computed(() => {
  if (form.value.workLocationStreetAddress === undefined) return "";
  let tempAddress = form.value.workLocationStreetAddress.street_address_1
    ? `${form.value.workLocationStreetAddress.street_address_1}, `
    : "";
  tempAddress += form.value.workLocationStreetAddress.number
    ? `${form.value.workLocationStreetAddress.number}, `
    : "";
  tempAddress +=
    form.value.workLocationStreetAddress.city ||
    form.value?.cityData?.city_ascii
      ? `${
          form.value.workLocationStreetAddress.city ||
          form.value?.cityData?.city_ascii
        }, `
      : "";
  tempAddress += form.value.workLocationStreetAddress.state
    ? `${form.value.workLocationStreetAddress.state}, `
    : "";
  tempAddress +=
    form.value.workLocationStreetAddress.country ||
    form.value?.cityData?.country?.name
      ? `${
          form.value.workLocationStreetAddress.country ||
          form.value?.cityData?.country?.name
        }, `
      : "";
  return tempAddress.trim().replace(/\r?\n/g, " ").replace(/,*$/, "").trim();
});
const creationOrEditView = computed(() => {
  return currentMode.value === "creating" || currentMode.value === "editing";
});
const allowButton = computed(() => {
  let minimalFilled =
    form.value.company !== undefined &&
    form.value.company?.name !== undefined &&
    form.value.projectTitle !== "" &&
    !lockButton.value;
  return !minimalFilled;
});
const unpackLanguagesAndProficiency = (bundle) => {
  let proficiencyLevel = formSettings.languageAndProficiency.options.find(
    (option) => option.value === bundle.proficiency_level
  );
  return {
    value: bundle.value,
    proficiency_level: {
      value: proficiencyLevel.value,
      id: proficiencyLevel.id,
    },
  };
};

const unpackTerms = (terms) => {
  const years = terms.map((term) => term.year);
  const uniqueYears = _.uniq(years)

  let uniqueTerms = [];
  
  for (const year of uniqueYears) {
    const termsInYear = terms.filter((term) => term.year == year);
    uniqueTerms = [
      ...uniqueTerms,
      {
        year: year,
        terms: termsInYear.map((term) => {
          return {
            id: term.term_id,
            actual_id: term.id,
            name: term.name,
            capacity: term.capacity,
          };
        }),
      },
    ];
  }

  return uniqueTerms;
};

const packTerms = (uniqueTerms) => {
  let unconvertedTerms = [];
  for (const term of uniqueTerms) {
    const processedTerm = term.terms.map((item) => ({
      id: item.actual_id,
      term_id: item.id,
      capacity: item.capacity,
      year: term.year,
    }));
    unconvertedTerms = [...unconvertedTerms, ...processedTerm];
  }
  return unconvertedTerms;
};

const updatePrice = (index, newPrice) => {
  form.value.product_prices[index] = newPrice;
};

const deletePrice = (index) => {
  form.value.product_prices.splice(index, 1);
};
const addPrice = () => {
  form.value.product_prices.push({
    effective_start_date: null,
    effective_end_date: null,
    price_in_cents: null,
  });
};

const addTerm = () => {
  const baseInternshipTerm = {
    year: undefined,
    terms: [],
  };

  form.value.whenStarts = [...form.value.whenStarts, baseInternshipTerm];
};

const removeTerm = (deletingIndex) => {
  const previousTerms = form.value.whenStarts;
  form.value.whenStarts = previousTerms.filter(
    (_, index) => index != deletingIndex
  );
};

const computedLat = computed(() => {
  return currentMode.value === "editing"
    ? form?.value?.workLocationStreetAddress?.latitude
    : form?.value?.workLocationStreetAddress?.lat;
});

const computedLon = computed(() => {
  return currentMode.value === "editing"
    ? form?.value?.workLocationStreetAddress?.longitude
    : form?.value?.workLocationStreetAddress?.long;
});

const packData = () => {
  return {
    title: form.value.projectTitle,
    company: {
      id: form.value.company.id,
      name: form.value.company.name,
    },
    status: form.value.status.value,
    credits: form.value.creditsAvailable,
    language_skills_required:
      form.value.otherLanguageRequirement === "Yes" ? true : false,
    interview_required: form.value.interviewRequired === "Yes" ? true : false,
    compensation: form.value.internshipPayed === "Paid" ? "Paid" : "Unpaid",
    street_address_1:
      form.value.workLocationStreetAddress?.street_address_1 ?? "",
    number: form.value.workLocationStreetAddress?.number ?? "",
    city: form.value.workLocationStreetAddress?.city ?? "",
    state: form.value.workLocationStreetAddress?.state ?? "",
    country: form.value.workLocationStreetAddress?.country ?? "",
    postal_code: form.value.workLocationStreetAddress?.postal_code ?? "",
    city_id: form.value.workLocationStreetAddress?.city_id ?? null,
    latitude: computedLat.value ?? null,
    longitude: computedLon.value ?? null,
    skill_subcategories: computedSkillSubcategories.value ?? null,
    needed_skills: computedRequiredSkills.value ?? null,
    terms: packTerms(form.value.whenStarts) ?? null,
    gained_skills: computedEnhancedSkills.value ?? null,
    career_interests: form.value.careerAreas ?? null,
    industries: form.value.industries ?? null,
    majors: form.value.majorFit ?? null,
    placement_types: form.value.placementType ?? null,
    perks: form.value.perksAssociated ?? null,
    marketplace: form.value.marketplace ?? true,
    work_locations: form.value.workLocationDescription ?? null,
    academic_levels: form.value.academicLevel,
    required_languages: computedRequiredLanguages.value,
    fall_capacity: form.value.fall_capacity ?? null,
    summer_capacity: form.value.summer_capacity ?? null,
    spring_capacity: form.value.spring_capacity ?? null,
    schedule: computedSchedule.value,
    responsibilities: computedPositionResponsabilityDescription.value ?? null,
    minimum_age: form.value.minimum_age,
    maximum_age: form.value.maximum_age,
    eligible_program_types: form.value.eligibleProgramType,
    product_prices: form.value.product_prices.map((product_price) => ({
      ...product_price,
      price_in_cents: dollarsToCents(product_price.price_in_cents, true),
    })),
    duration: Number(form.value.duration?.value) ?? null,
    duration_unit: "weeks",

    // NOT COVERED YET
    // tags: [
    //   {
    //     id: "94db2e5a-705b-4156-8c3b-9eb8692d60aa",
    //     name: "Language Intensive",
    //   },
    // ],
  };
};

const unpackData = (bundle) => {
  if (!bundle) return;
  form.value.status = {
    id: bundle.status,
    value: bundle.status,
  };
  form.value.product_prices =
    bundle.product_prices.map((price) => {
      return {
        ...price,
        price_in_cents: price.price_in_cents
          ? centsToDollars(price.price_in_cents, true)
          : "",
      };
    }) || [];
  form.value.company = bundle.company;
  form.value.projectTitle = bundle.title;
  form.value.aboutCompany = bundle.description;
  form.value.companySize = bundle.company_size;
  form.value.placementType = bundle.placement_types;

  form.value.headquarterLocation = bundle.company.shipping_address_city
    ? [
        bundle.company.shipping_address_city,
        bundle.company.shipping_address_country,
      ].join(", ")
    : "";

  form.value.workLocationDescription = !bundle.work_locations.length
    ? []
    : bundle.work_locations.map((wl) => {
        return {
          id: wl.id,
          value: wl.value,
        };
      });
  form.value.cityData = { ...bundle?.city_data, id: bundle?.city_id };
  form.value.workLocationStreetAddress =
    bundle.city_id || bundle.city || bundle.street_address_1
      ? {
          id: bundle?.city_data?.city_ascii,
          city_id: bundle.city_id,
          city: bundle?.city_data?.city_ascii ?? bundle.city,
          country: bundle?.city_data?.country?.name ?? bundle.country,
          street_address_1: bundle.street_address_1,
          number: bundle.number,
          state: bundle.state,
          postal_code: bundle.postal_code,
          street_address_2: bundle.street_address_2,
          latitude: bundle.latitude,
          longitude: bundle.longitude,
        }
      : {};
  form.value.positionDescription = bundle?.responsibilities || {};
  form.value.academicLevel = bundle.academic_levels;
  form.value.otherLanguageRequirement = bundle.language_skills_required
    ? "Yes"
    : "No";
  form.value.languagesRequired = bundle.required_languages ?? [];
  form.value.languageAndProficiency = !bundle.required_languages.length
    ? []
    : bundle.required_languages.map((language) =>
        unpackLanguagesAndProficiency(language)
      );
  form.value.fall_capacity = bundle.fall_capacity;
  form.value.spring_capacity = bundle.spring_capacity;
  form.value.summer_capacity = bundle.summer_capacity;
  form.value.spring_duration = {
    name: bundle.spring_duration ? `${bundle.spring_duration}W` : null,
    value: bundle.spring_duration ? bundle.spring_duration : null,
  };
  form.value.fall_duration = {
    name: bundle.fall_duration ? `${bundle.fall_duration}W` : null,
    value: bundle.fall_duration ? bundle.fall_duration : null,
  };
  form.value.summer_duration = {
    name: bundle.summer_duration ? `${bundle.summer_duration}W` : null,
    value: bundle.summer_duration ? bundle.summer_duration : null,
  };
  form.value.whenStarts = unpackTerms(bundle.terms);
  form.value.scheduleType = bundle.schedule?.type ? bundle.schedule?.type : "";
  form.value.scheduleDuration = bundle.schedule?.duration
    ? bundle.schedule?.duration
    : "";
  if (form.value.scheduleType === "Other") {
    form.value.ifOtherWhatSchedule = bundle.schedule?.description ?? "";
  }

  if (form.value.scheduleType === WEEKLY_RECURRING) {
    form.value.weeklyRecurringSchedule = bundle.schedule?.description ?? "";
  }

  form.value.careerAreas = bundle.career_interests;
  form.value.majorFit = bundle.majors;
  form.value.industries = bundle.industries;
  form.value.skillSubcategories = !bundle.skill_subcategories.length
    ? []
    : bundle.skill_subcategories.map((subcategory) => {
        return {
          id: subcategory.id,
          value: subcategory.value,
        };
      });
  form.value.requiredSkills = !bundle.needed_skills.length
    ? []
    : bundle.needed_skills.map((skill) => {
        return {
          id: skill.id,
          value: skill.value,
        };
      });
  form.value.enhancedSkills = !bundle.gained_skills.length
    ? []
    : bundle.gained_skills.map((skill) => {
        return {
          id: skill.id,
          value: skill.value,
        };
      });
  form.value.perksAssociated = bundle.perks;
  form.value.marketplace = bundle.marketplace ?? true;
  form.value.internshipPayed =
    bundle.compensation === "Paid" ? "Paid" : "Unpaid";
  form.value.interviewRequired = bundle.interview_required ? "Yes" : "No";
  form.value.creditsAvailable = bundle.credits ?? "";
  form.value.minimum_age = bundle.minimum_age ?? "";
  form.value.maximum_age = bundle.maximum_age ?? "";
  form.value.eligibleProgramType = bundle.eligible_program_types;
  form.value.duration = bundle.duration
    ? { value: bundle.duration, name: bundle.duration }
    : "";
};

const validateNestedComponents = async (v, componentPrefix) => {
  let componentKeys = Object.keys(v.value).filter((item) =>
    item.startsWith(componentPrefix)
  );

  let componentIsValid = true;
  if (componentKeys.length) {
    for (const componentKey of componentKeys) {
      componentIsValid = await v.value[componentKey].$validate();
      if (!componentIsValid) {
        break;
      }
    }
  }

  return componentIsValid;
};

const submit = async () => {
  let notDraftValidationStatus = false;
  let notDraft = form.value.status.value !== "Draft";

  if (notDraft) {
    notDraftValidationStatus = await v$.value.$validate();
  }

  if (notDraft && !notDraftValidationStatus) {
    toast.open({
      message: "Please fix validation errors before saving",
      type: "info",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  } else {
    const companyValid = await v$.value.company.$validate();
    const titleValid = await v$.value.projectTitle.$validate();
    const pricesValid = await validateNestedComponents(v$, "PriceSection");
    const termsValid = await validateNestedComponents(v$, "Terms")


    if (!companyValid || !titleValid || !pricesValid || !termsValid) {
      toast.open({
        message: "Please fix validation errors before saving",
        type: "info",
        position: "bottom",
        duration: ERROR_TIMEOUT,
      });
    } else {
      try {
        processing.value = true;
        let packagedData = packData();

        processSuccess.value = false;
        if (currentMode.value === "creating" && !lockButton.value) {
          lockButton.value = true;
          let result = await internshipService.create({ data: packagedData });
          window.location = `${baseUrl}/item-${result.data.id}`;
        }
        if (currentMode.value === "editing") {
          lockButton.value = true;
          const internship = await internshipService.update(internshipId.value, {
            data: packagedData,
          });
          
          unpackData(internship.data.data)

          currentMode.value = "viewing";
        }
        toast.open({
          message: "Internship saved successfully.",
          type: "success",
          position: "bottom",
          duration: SUCCESS_TIMEOUT,
        });

        processSuccess.value = true;
      } catch (e) {
        toast.open({
          message: "Error while saving internship data. Please, try it later",
          type: "error",
          position: "bottom",
          duration: ERROR_TIMEOUT,
        });
      } finally {
        lockButton.value = false;
        processing.value = false;
        processSuccess.value = false;
      }
    }
  }
};
const openDuplicate = async () => {
  store.dispatch("programManager/setDuplicateModalStateOpen", {
    item: {
      id: internshipId.value,
      "Position/Project Title": form.value.projectTitle,
    },
  });
};

const showMarketplaceToggle = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["global-libraries"];
})

const {
  execute: fetchInternship,
  isLoading: isLoading,
  error: hasError,
  state: internshipData,
} = useGetInternship({ immediate: false, resetOnExecute: true });

watch(
  internshipId,
  (newInternshipId) => {
    if (newInternshipId) {
      fetchInternship(0, newInternshipId).then(() =>
        unpackData(internshipData.value.data)
      );
    }
  },
  { immediate: currentMode.value !== "creating" }
);

const fieldsBaseClass = "mb-6 text-blue-900";
const baseViewingTextClass = "mt-2 text-gray-700";
const lockButton = ref(false);
const switchCurrentMode = (mode) => {
  currentMode.value =
    mode === "editing" ? "editing" : mode === "viewing" ? "viewing" : "editing";
};
const processSuccess = ref(false);
const baseUrl = `${window.location.protocol}//${window.location.host}/program-manager/internships`;
const cancelBehavior = () => {
  if (currentMode.value === "editing") currentMode.value = "viewing";
  if (currentMode.value === "creating")
    router.push({ name: "program-manager-internships" });
};
const processing = ref(false);
const openArchive = () => {
  store.dispatch("programManager/setArchivingModalStateOpen", {
    item: {
      id: internshipId.value,
      "Position/Project Title": form.value.projectTitle,
    },
  });
};
const hideStreetAddress = computed(() => {
  return (
    form?.value?.workLocationDescription?.length == 1 &&
    form?.value?.workLocationDescription[0]?.value === "Virtual"
  );
});

const updateInternshipSchedule = (internshipSchedule) => {
  form.value.weeklyRecurringSchedule = internshipSchedule;
};
const sortedSchedule = computed(() => {
  if (!form.value.weeklyRecurringSchedule) {
    return {};
  }

  let schedule = {};

  dayOrder.forEach((day) => {
    if (form.value.weeklyRecurringSchedule[day]) {
      schedule[day] = form.value.weeklyRecurringSchedule[day];
    }
  });

  return schedule;
});
</script>

<template>
  <div class="h-full">
    <ArchivingModal />
    <DuplicateModal />
    <div
      v-if="!isLoading && !hasError"
      class="space-y-3 w-full md:p-5 lg:p-5 p-4"
    >
      <!-- HEAD -->
      <div class="flex justify-between">
        <div>
          <BreadCrumb />
        </div>
        <div v-if="internshipId">
          <span class="font-bold mr-1">Internship ID: </span>
          <span> {{ internshipId }} </span>
        </div>
      </div>
      <div class="md:flex w-full lg:flex md:justify-between lg:justify-between">
        <!-- PAGE INFO -->
        <div class="flex text-2xl font-semibold leading-6 text-gray-900 mb-5">
          {{ computedPageCopy }}
          <img
              v-if="form.marketplace && showMarketplaceToggle"
              data-testid="globe-icon"
              src="/images/icon-marketplace.svg"
              class="size-1 mb-6"
            />
        </div>
               
        <!-- Buttons -->
        <div>
          <div>
            <div
              v-if="currentMode === 'viewing' && canWrite"
              class="flex md:items-center lg:items-center md:justify-around lg:justify-around justify-between"
            >
              <button
                class="md:ml-7 mr-7 text-center text-teal-500 uppercase font-semibold"
                @click="openArchive"
              >
                ARCHIVE
              </button>
              <button
                class="text-center text-teal-500 uppercase mr-2 md:mr-8 lg:mr-8 font-semibold"
                @click="openDuplicate"
              >
                DUPLICATE
              </button>
              <BaseButton
                type="button"
                class="flex h-[40px] w-[108px] mr-0 text-base"
                @click.prevent="switchCurrentMode()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
                <span class="ml-2 text-base font-semibold">Edit</span>
              </BaseButton>
            </div>
            <div class="flex justify-end">
              <BaseButton
                v-if="currentMode !== 'viewing'"
                outlined
                type="button"
                class="h-[40px] w-[108px]"
                @click="cancelBehavior"
              >
                <span class="text-base font-semibold"> Cancel </span>
              </BaseButton>
              <BaseButton
                v-if="currentMode !== 'viewing'"
                type="button"
                class="h-[40px] w-[108px]"
                :disabled="allowButton"
                @click.prevent="submit"
              >
                <span class="text-base"> Save </span>
              </BaseButton>
            </div>
          </div>
          <div class="mt-2 flex justify-end mr-5">
            <span class="font-semibol mr-2 text-error-900">*</span>
            Required Information
          </div>
          <div v-if="processSuccess" class="mt-3 text-teal-900">Success!</div>
          <div v-if="processing" class="mt-3 text-teal-900">Processing</div>
        </div>
      </div>
      <!-- FORM -->
      <div class="flex">
        <div class="md:w-[800px] mx-auto">
          <!-- Status and Company Name -->
          <div class="md:flex lg:flex block justify-between w-full">
            <!-- Status -->
            <div class="md:w-72 lg:w-72 w-full" :class="fieldsBaseClass">
              <label class="font-semibold">
                <span
                  v-if="creationOrEditView"
                  class="font-semibol mr-2"
                  :class="{ 'text-error-900': settings.status.required }"
                  >*</span
                >{{ settings.status.copy }}</label
              >
              <BaseInput
                v-if="creationOrEditView"
                v-model="form.status"
                :type="settings.status.type"
                :field-settings="settings.status"
                :vuelidate-errors="v$.status.$errors"
                :selectable="statusIsSelectable"
              />
              <p v-else :class="baseViewingTextClass">
                {{ form.status.value }}
              </p>
            </div>
            <!-- Company Name -->
            <div
              v-if="!readLiteOnly"
              class="md:w-72 lg:w-72 w-full"
              :class="fieldsBaseClass"
            >
              <label class="font-semibold">
                <span
                  v-if="settings.company.required && creationOrEditView"
                  class="font-semibol mr-2"
                  :class="{ 'text-error-900': settings.company.required }"
                  >*</span
                >{{ settings.company.copy }}</label
              >
              <BaseInput
                v-if="creationOrEditView"
                v-model="form.company"
                :type="settings.company.type"
                :field-settings="settings.company"
                :vuelidate-errors="v$.company.$errors"
              />
              <p v-else-if="!readLiteOnly" :class="baseViewingTextClass">
                {{ form.company.name }}
              </p>
            </div>
          </div>
          <!-- Project Title -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.projectTitle.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.projectTitle.required }"
                >*</span
              >{{ settings.projectTitle.copy }}</label
            >
            <BaseInput
              v-if="creationOrEditView"
              v-model="form.projectTitle"
              :value="modelValue"
              :type="settings.projectTitle.type"
              :field-settings="settings.projectTitle"
              :vuelidate-errors="v$.projectTitle.$errors"
              class="!text-black"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.projectTitle }}
            </p>
          </div>
          <!-- Marketplace -->
        <div v-if="showMarketplaceToggle"
          :class="fieldsBaseClass"
        >
          <label class="block text-sm font-semibold text-gray-700">
            Available in Marketplace
          </label>
          <div class="flex flex-row items-center h-full">
            <BaseToggle
              v-if="creationOrEditView"
              v-model="form.marketplace"
              width="w-10"
              />
            <img
              v-if="form.marketplace"
              data-testid="globe-icon"
              src="/images/icon-marketplace.svg"
              class="mb-6"
            />
            <div
              v-if="!creationOrEditView && !form.marketplace"
            > 
              No
            </div>
          </div>
        </div>
          <!-- About the company -->
          <div class="min-h-30" :class="fieldsBaseClass">
            <label class="font-semibold">
              <p>About the Company</p>
            </label>
            <p>{{ form.company?.description ?? "" }}</p>
          </div>
          <!-- Organization Size And PlacementType-->
          <div
            class="md:flex lg:flex block justify-between"
            :class="fieldsBaseClass"
          >
            <div :class="fieldsBaseClass">
              <label class="font-semibold">Company Size</label>
              <p :class="baseViewingTextClass">
                {{ form.company?.company_size ?? "" }}
              </p>
            </div>
            <div class="md:w-72 lg:w-72">
              <label class="font-semibold">
                <span
                  v-if="settings.placementType.required && creationOrEditView"
                  class="font-semibol mr-2"
                  :class="{ 'text-error-900': settings.placementType.required }"
                  >*</span
                >{{ settings.placementType.copy }}</label
              >
              <BaseInput
                v-if="settings.placementType.active && creationOrEditView"
                v-model="form.placementType"
                :value="modelValue"
                :type="settings.placementType.type"
                :field-settings="settings.placementType"
                :vuelidate-errors="v$.placementType.$errors"
              />
              <p v-else :class="baseViewingTextClass">
                {{ form.placementType.map((item) => item.value).join(", ") }}
              </p>
            </div>
          </div>
          <!-- Eligible Programs -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="
                  settings.eligibleProgramType.required && creationOrEditView
                "
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.eligibleProgramType.required,
                }"
                >*</span
              >{{ settings.eligibleProgramType.copy }}</label
            >
            <BaseInput
              v-if="creationOrEditView"
              v-model="form.eligibleProgramType"
              :type="settings.eligibleProgramType.type"
              :field-settings="settings.eligibleProgramType"
              :vuelidate-errors="v$.eligibleProgramType.$errors"
            />
            <p v-else :class="baseViewingTextClass">
              {{
                form.eligibleProgramType
                  ? form.eligibleProgramType.map((item) => item.name).join(", ")
                  : ""
              }}
            </p>
          </div>
          <!-- Headquarter Location -->
          <div class="mb-12">
            <label class="font-semibold mr-2">Headquarter Location</label>
            <p :class="baseViewingTextClass">
              {{
                form.headquarterLocation !== ""
                  ? form.headquarterLocation
                  : ![null, undefined].includes(
                      form?.company?.shipping_address_city
                    ) &&
                    ![null, undefined].includes(
                      form?.company?.shipping_address_country
                    )
                  ? `${form?.company?.shipping_address_city}, ${form?.company?.shipping_address_country}`
                  : ""
              }}
            </p>
          </div>
          <!-- Work Location Description -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="
                  settings.workLocationDescription.required &&
                  creationOrEditView
                "
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.workLocationDescription.required,
                }"
                >*</span
              >{{ settings.workLocationDescription.copy }}</label
            >
            <BaseInput
              v-if="creationOrEditView"
              v-model="form.workLocationDescription"
              :type="settings.workLocationDescription.type"
              :field-settings="settings.workLocationDescription"
              :vuelidate-errors="v$.workLocationDescription.$errors"
            />
            <p v-else :class="baseViewingTextClass">
              {{
                form.workLocationDescription
                  .map((item) => item.value)
                  .join(", ")
              }}
            </p>
          </div>
          <!-- Google Address Component -->
          <div v-if="!readLiteOnly && !hideStreetAddress">
            <label class="font-semibold">
              <span
                v-if="
                  settings.workLocationStreetAddress.required &&
                  creationOrEditView
                "
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.workLocationStreetAddress.required,
                }"
                >*</span
              >{{ settings.workLocationStreetAddress.copy }}</label
            >
            <AddressComponent
              v-if="creationOrEditView"
              v-model="form.workLocationStreetAddress"
              :auto-validation="false"
              :internship-context="true"
              error-class="block pl-2 min-h-10 w-full rounded border  pr-10 placeholder-red-300 focus:border-red-500 focus:ring-red-500 !border-red-500"
              :initial-address-info="
                currentMode === 'editing'
                  ? form.workLocationStreetAddress
                  : null
              "
            >
              <template #error>
                <div
                  v-if="v$.workLocationStreetAddress.$error"
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <ExclamationCircleIcon
                    class="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              </template>
            </AddressComponent>
            <p v-else :class="baseViewingTextClass" class="mb-6">
              {{ addressFieldContent }}
            </p>
          </div>

          <div class="grid sm:grid-cols-2 md:grid-cols-3 mt-2 space-x-64">
            <CitySection
              v-model="form.workLocationStreetAddress.city_id"
              :vuelidate-instance="v$.workLocationStreetAddress"
              :city="computedInternshipCity"
              :editable="creationOrEditView"
              :city-required="!hideStreetAddress"
              :show-lat-lng-field="false"
            />
          </div>

          <!-- Position Description -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="
                  settings.positionDescription.required && creationOrEditView
                "
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.positionDescription.required,
                }"
                >*</span
              >{{ settings.positionDescription.copy }}</label
            >
            <WYSIWYG
              v-model="form.positionDescription"
              :editable="creationOrEditView"
              :vuelidate-errors="v$.positionDescription.$errors"
              :character-limit="10000"
            />
          </div>
          <!-- When Starts | Terms -->
          <div>
            <label
              class="font-semibold"
              :class="{
                'bg-error-100':
                  v$.whenStarts.$errors.length &&
                  creationOrEditView &&
                  settings.termSession.required,
              }"
            >
              <span
                v-if="creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.termSession.required }"
                >*</span
              >{{ settings.termSession.copy }}</label
            >
            <div
              v-if="form.whenStarts.length"
              class="pb-5 rounded bg-blue-100 border-gray-200 border-2 gap-5"
            >
              <Terms
                v-for="(term, index) in form.whenStarts"
                @remove-term="removeTerm"
                :term="term"
                :all-terms="form.whenStarts"
                :settings="settings"
                :is-editing="creationOrEditView"
                :index="index"
                :key="index"
              />
            </div>
            <div class="pb-5 pt-1">
              <AddButton
                v-if="creationOrEditView"
                :label="form.whenStarts.length ? 'Add Another' : 'Add Term'"
                @addItem="addTerm"
              />
            </div>
            <!-- Duration -->
            <div v-if="form?.whenStarts?.length" :class="fieldsBaseClass">
              <fieldset>
                <label class="font-semibold">
                  <span
                    class="font-semibold mr-2"
                    :class="{ 'text-error-900': settings.duration.required }"
                    >*</span
                  >{{ settings.duration.copy }}
                </label>
                <div class="flex items-center">
                  <div
                    :class="creationOrEditView ? 'w-32 ' : 'w-2'"
                    class="md:ml-1 lg:ml-1"
                  >
                    <BaseInput
                      v-if="creationOrEditView"
                      v-model="form.duration"
                      :type="settings.duration.type"
                      class="!text-black"
                      :field-settings="settings.duration"
                    />
                    <p
                      v-else
                      :class="creationOrEditView ? baseViewingTextClass : ''"
                    >
                      {{ form.duration.value }}
                    </p>
                  </div>
                  <label
                    class="text-base text-gray-700 md:w-[70px] lg:w-[70px] px-2"
                  >
                    {{ form.durationUnit }}
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <!-- Schedule Type -->
          <div
            :class="fieldsBaseClass"
            class="space-y-3"
            :vuelidate-errors="v$.whenStarts.$errors"
          >
            <label class="font-semibold">
              <span
                v-if="settings.scheduleType.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.scheduleType.required }"
                >*
              </span>
              {{ settings.scheduleType.copy }}</label
            >
            <div
              v-if="settings.scheduleType.required && creationOrEditView"
            ></div>

            <fieldset class="space-y-6">
              <div
                v-for="option in settings.scheduleType.options"
                :key="option"
              >
                <input
                  v-if="settings.scheduleType.active && creationOrEditView"
                  v-model="form.scheduleType"
                  :value="option"
                  type="radio"
                  class="text-[#665eaa] form-radio"
                />
                <label
                  v-if="
                    (form.scheduleType === option && !creationOrEditView) ||
                    creationOrEditView
                  "
                  class="ml-2"
                >
                  {{ option }}
                </label>
                <div
                  v-if="
                    form.scheduleType === 'Weekly Recurring' &&
                    option === 'Weekly Recurring' &&
                    creationOrEditView
                  "
                >
                  <StaffReceptionInput
                    enabled
                    :default-availability="form.weeklyRecurringSchedule"
                    @updateStaffField="updateInternshipSchedule"
                  />
                  <ErrorMessage
                    :vuelidate-instance="v$.weeklyRecurringSchedule"
                  />
                </div>
                <ScheduledTime
                  v-if="
                    form.scheduleType === 'Weekly Recurring' &&
                    option === 'Weekly Recurring' &&
                    !creationOrEditView
                  "
                  :scheduled-time="sortedSchedule"
                />
              </div>
            </fieldset>
            <div v-if="v$.scheduleType.$error" class="text-error-900">
              Value is required
            </div>
          </div>

          <!-- Schedule Description -->
          <div v-if="form.scheduleType === 'Other'" :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="
                  settings.ifOtherWhatSchedule.required && creationOrEditView
                "
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.ifOtherWhatSchedule.required,
                }"
                >*</span
              >{{ settings.ifOtherWhatSchedule.copy }}</label
            >
            <div>
              <label v-if="creationOrEditView" class="font-semibold"
                >Describe the schedule</label
              >
              <WYSIWYG
                v-model="form.ifOtherWhatSchedule"
                :editable="creationOrEditView"
                :vuelidate-errors="v$.ifOtherWhatSchedule.$errors"
                :character-limit="10000"
              />
            </div>
          </div>
          <!-- Schedule Duration -->
          <div
            v-if="form.scheduleType === 'Other'"
            :class="fieldsBaseClass"
            class="space-y-3"
          >
            <label class="font-semibold">
              {{ settings.scheduleDuration.copy }}</label
            >
            <div>
              <BaseInput
                v-if="settings.scheduleDuration.active && creationOrEditView"
                v-model="form.scheduleDuration"
                :type="settings.scheduleDuration.type"
                :field-settings="settings.scheduleDuration"
                :vuelidate-errors="v$.scheduleDuration.$errors"
                :custom-radio-css="'space-y-6'"
              />
              <p v-else :class="baseViewingTextClass">
                {{ form.scheduleDuration ?? "" }}
              </p>
            </div>
          </div>
          <!-- Academic Level -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.academicLevel.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.academicLevel.required }"
                >*</span
              >{{ settings.academicLevel.copy }}</label
            >
            <BaseInput
              v-if="creationOrEditView"
              v-model="form.academicLevel"
              :type="settings.academicLevel.type"
              :field-settings="settings.academicLevel"
              :vuelidate-errors="v$.academicLevel.$errors"
            />
            <p v-else :class="baseViewingTextClass">
              {{
                form.academicLevel.length
                  ? form.academicLevel
                      .map((item) => item.academic_year)
                      .join(", ")
                  : ""
              }}
            </p>
          </div>
          <!-- Other Language Requirement Radio Button -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="
                  settings.otherLanguageRequirement.required &&
                  creationOrEditView
                "
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.otherLanguageRequirement.required,
                }"
                >*</span
              >{{ settings.otherLanguageRequirement.copy }}</label
            >
            <BaseInput
              v-if="
                settings.otherLanguageRequirement.active && creationOrEditView
              "
              v-model="form.otherLanguageRequirement"
              :type="settings.otherLanguageRequirement.type"
              :field-settings="settings.otherLanguageRequirement"
              :vuelidate-errors="v$.otherLanguageRequirement.$errors"
              :custom-radio-css="'flex space-x-40'"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.otherLanguageRequirement }}
            </p>
          </div>
          <!-- Languages Required -->
          <div
            v-if="form.otherLanguageRequirement === 'Yes'"
            :class="fieldsBaseClass"
          >
            <label class="font-semibold">
              <span
                v-if="settings.languagesRequired.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.languagesRequired.required,
                }"
                >*</span
              >{{ settings.languagesRequired.copy }}</label
            >
            <BaseInput
              v-if="creationOrEditView"
              v-model="form.languagesRequired"
              :type="settings.languagesRequired.type"
              :field-settings="settings.languagesRequired"
              :vuelidate-errors="v$.languagesRequired.$errors"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.languagesRequired.map((item) => item.value).join(", ") }}
            </p>
          </div>
          <!-- Language And Proficiency -->
          <div :class="fieldsBaseClass" class="w-full">
            <div
              v-if="
                form.languagesRequired.length &&
                form.otherLanguageRequirement === 'Yes'
              "
            >
              <label class="font-semibold"
                >What proficiency level should the candidate have?
              </label>
              <div v-if="creationOrEditView">
                <LanguageInputs
                  v-for="languageTitle in form.languagesRequired"
                  :key="languageTitle"
                  class="w-full"
                  :field-settings="settings.languageAndProficiency"
                  :label="languageTitle.value"
                  :started-value="
                    currentMode === 'editing'
                      ? formSettings.languageAndProficiency.options.find(
                          (item) =>
                            item.value === languageTitle.proficiency_level
                        )
                      : null
                  "
                  @update:modelValue="updateLanguages"
                />
              </div>
              <div v-else>
                <p v-for="(v, k) of form.languageAndProficiency" :key="k">
                  <span>{{ v.value }}: {{ v.proficiency_level.value }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- Career Areas -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.careerAreas.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.careerAreas.required }"
                >*</span
              >{{ settings.careerAreas.copy }}</label
            >
            <BaseInput
              v-if="settings.careerAreas.active && creationOrEditView"
              v-model="form.careerAreas"
              :type="settings.careerAreas.type"
              :field-settings="settings.careerAreas"
              :vuelidate-errors="v$.careerAreas.$errors"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.careerAreas.map((item) => item.soc_title).join(", ") }}
            </p>
          </div>
          <!-- Major Fit -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.majorFit.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.majorFit.required }"
                >*</span
              >{{ settings.majorFit.copy }}</label
            >
            <BaseInput
              v-if="settings.majorFit.active && creationOrEditView"
              v-model="form.majorFit"
              :type="settings.majorFit.type"
              :field-settings="settings.majorFit"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.majorFit.map((item) => item.cip_title).join(", ") }}
            </p>
          </div>
          <!-- Industries -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.industries.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.industries.required }"
                >*</span
              >{{ settings.industries.copy }}</label
            >
            <BaseInput
              v-if="settings.industries.active && creationOrEditView"
              v-model="form.industries"
              :type="settings.industries.type"
              :field-settings="settings.industries"
              :vuelidate-errors="v$.industries.$errors"
            />
            <p v-else :class="baseViewingTextClass">
              {{
                form.industries.map((item) => item.career_pathway).join(", ")
              }}
            </p>
          </div>
          <!-- Types of Skills (subcategories) -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="
                  settings.skillSubcategories.required && creationOrEditView
                "
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.skillSubcategories.required,
                }"
                >*</span
              >{{ settings.skillSubcategories.copy }}</label
            >
            <BaseInput
              v-if="settings.skillSubcategories.active && creationOrEditView"
              v-model="form.skillSubcategories"
              :type="settings.skillSubcategories.type"
              :field-settings="settings.skillSubcategories"
              :vuelidate-errors="v$.skillSubcategories.$errors"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.skillSubcategories.map((item) => item.value).join(", ") }}
            </p>
          </div>
          <!-- Required Skills -->
          <div v-if="form?.skillSubcategories?.length" :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.requiredSkills.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.requiredSkills.required }"
                >*</span
              >{{ settings.requiredSkills.copy }}</label
            >
            <BaseInput
              v-if="settings.requiredSkills.active && creationOrEditView"
              v-model="form.requiredSkills"
              :type="settings.requiredSkills.type"
              :field-settings="settings.requiredSkills"
              :vuelidate-errors="v$.requiredSkills.$errors"
              :subcategories="form.skillSubcategories"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.requiredSkills.map((item) => item.value).join(", ") }}
            </p>
          </div>
          <!-- Enhanced Skills -->
          <div v-if="form?.skillSubcategories?.length" :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.enhancedSkills.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.enhancedSkills.required }"
                >*</span
              >{{ settings.enhancedSkills.copy }}</label
            >
            <BaseInput
              v-if="settings.enhancedSkills.active && creationOrEditView"
              v-model="form.enhancedSkills"
              :type="settings.enhancedSkills.type"
              :field-settings="settings.enhancedSkills"
              :vuelidate-errors="v$.enhancedSkills.$errors"
              :subcategories="form.skillSubcategories"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.enhancedSkills.map((item) => item.value).join(", ") }}
            </p>
          </div>
          <!-- Perks Associated -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.perksAssociated.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.perksAssociated.required }"
                >*</span
              >{{ settings.perksAssociated.copy }}</label
            >
            <BaseInput
              v-if="settings.perksAssociated.active && creationOrEditView"
              v-model="form.perksAssociated"
              :type="settings.perksAssociated.type"
              :field-settings="settings.perksAssociated"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.perksAssociated.map((item) => item.value).join(", ") }}
            </p>
          </div>
          <!-- Internship Payed -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.internshipPayed.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{ 'text-error-900': settings.internshipPayed.required }"
                >*</span
              >{{ settings.internshipPayed.copy }}</label
            >
            <BaseInput
              v-if="settings.internshipPayed.active && creationOrEditView"
              v-model="form.internshipPayed"
              :type="settings.internshipPayed.type"
              :field-settings="settings.internshipPayed"
              :vuelidate-errors="v$.internshipPayed.$errors"
              :custom-radio-css="'flex space-x-20'"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.internshipPayed }}
            </p>
          </div>

          <!-- Internship price section -->
          <div
            class="flex-1 w-full px-4 py-4 border rounded-md border-gray-200 my-4"
            :class="{ 'bg-blue-300': currentMode !== 'viewing' }"
          >
            <PriceSection
              :disabled="currentMode === 'viewing'"
              :prices="form.product_prices"
              :required-to-publish="requiredToPublish"
              @add-price="addPrice()"
              @update-price="(index, newPrice) => updatePrice(index, newPrice)"
              @remove-price="(index) => deletePrice(index)"
            >
              <template #information
                >* If price does not have and end date, that price is assumed to
                be the price for that internship in perpetuity</template
              >
              <template #postfixInputLabel>/day</template>
            </PriceSection>
            <div
              v-if="v$.product_prices?.$errors.length"
              class="block text-sm error text-error-900"
              :vuelidate-errors="v$.product_prices.$errors"
            >
              <span v-for="error in v$.product_prices?.$errors" :key="error">
                {{ error.$message }}<br />
              </span>
            </div>
          </div>

          <!-- Interview Required -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.interviewRequired.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.interviewRequired.required,
                }"
                >*</span
              >{{ settings.interviewRequired.copy }}</label
            >
            <BaseInput
              v-if="settings.interviewRequired.active && creationOrEditView"
              v-model="form.interviewRequired"
              :type="settings.interviewRequired.type"
              :field-settings="settings.interviewRequired"
              :vuelidate-errors="v$.interviewRequired.$errors"
              :custom-radio-css="'flex space-x-20'"
            />
            <p v-else :class="baseViewingTextClass">
              {{ form.interviewRequired }}
            </p>
          </div>
          <!-- Minimum and Maximum Age -->
          <div :class="fieldsBaseClass">
            <label class="font-semibold">
              <span
                v-if="settings.minimumAge.required && creationOrEditView"
                class="font-semibol mr-2"
                :class="{
                  'text-error-900': settings.minimumAge.required,
                }"
                >*</span
              >What is the minimum and maximum age?
            </label>
            <div
              class="md:flex lg:flex"
              :class="
                creationOrEditView ? 'md:ml-3 lg:ml-3 md:mt-3 lg:mt-3' : ''
              "
            >
              <!-- Minimum Age -->
              <div
                class="md:flex lg:flex items-center justify-between md:space-x-2 lg:space-x-2"
              >
                <span v-if="creationOrEditView">{{
                  settings.minimumAge.copy
                }}</span>
                <BaseInput
                  v-if="settings.minimumAge.active && creationOrEditView"
                  v-model="form.minimum_age"
                  :type="settings.minimumAge.type"
                  :field-settings="settings.minimumAge"
                  :vuelidate-errors="v$.minimum_age.$errors"
                  class="md:!w-64 lg:!w-64 !text-black"
                />
                <!-- :vuelidate-errors="v$.minimumAge.$errors" -->
                <p v-else :class="baseViewingTextClass">
                  Min: {{ form.minimum_age }}
                </p>
              </div>
              <!-- Maximum Age -->
              <div
                class="md:flex lg:flex md:ml-16 items-center md:space-x-2 lg:space-x-2"
              >
                <span v-if="creationOrEditView">{{
                  settings.maximumAge.copy
                }}</span>
                <BaseInput
                  v-if="settings.maximumAge.active && creationOrEditView"
                  v-model="form.maximum_age"
                  :type="settings.maximumAge.type"
                  :field-settings="settings.maximumAge"
                  :vuelidate-errors="v$.maximum_age.$errors"
                  class="md:!w-64 lg:!w-64 !text-black"
                />
                <!-- :vuelidate-errors="v$.maximumAge.$errors" -->
                <p v-else :class="baseViewingTextClass">
                  Max: {{ form.maximum_age }}
                </p>
              </div>
            </div>
            <div v-if="!creationOrEditView" class="italic text-gray-700 mt-2">
              * Due to visa/labor regulations some placements and projects have
              age restrictions.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!hasError">
      <LoadingPageComponent />
    </div>
    <div v-if="hasError">
      <ErrorPage />
    </div>
  </div>
</template>
