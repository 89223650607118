<script>
export default {
  name: "AdvancedFilters",
};
</script>

<script setup>
import { computed, defineEmits, onMounted, reactive, ref, unref } from "vue";
import { useStore } from "vuex";
import { cloneDeep, debounce } from "lodash";
import { format, parse } from "date-fns";
import BaseSearchBarFilterModal from "@/components/shared/BaseSearchBarFilterModal.vue";
import CheckboxSelect from "@/components/shared/select/CheckboxSelect.vue";
import BasicTextInput from "@/components/shared/input/BasicTextInput.vue";
import SliderYesNo from "@/components/shared/input/SliderYesNo.vue";
import Magnifier from "@/components/shared/icons/Magnifier";
import TagsInput from "@/components/shared/TagsInput.vue";
import CalendarInput from "@/components/shared/CalendarInput.vue";
import {
  useGetCityOptions,
  useGetHomeInstitutionOptions,
  useGetHostInstitutionOptions,
  useGetProfiles,
  useGetTagsOptions,
  useAcademicYearFilter,
  useSearchCountryOptions,
} from "@/components/program-manager/sessions/composable";
import {
  channelTypeOptions,
  sessionTypes,
  sessionStatuses,
} from "@/components/program-manager/sessions/constants";
import { baseFilters } from "./constants";
import { getCityCountryOptionLabel } from "../../utils";

const filters = ref(cloneDeep(baseFilters));

const store = useStore();
const termsOptions = computed(
  () => store.getters["programManager/getTermsData"]
);

const getTermsIsLoading = computed(
  () => store.getters["programManager/getTermsIsLoading"]
);

const {
  options: academicYearOptions,
  reduceAcademicYearOption,
} = useAcademicYearFilter();

const {
  execute: executeFetchProgramLeadersOptions,
  state: programLeadersOptions,
  isLoading: programLeadersOptionsLoading,
} = useGetProfiles(
  { immediate: true, throwError: false, resetOnExecute: true },
  {
    limit: 10,
    skip: 0,
  }
);

const fetchProgramLeadersOptions = debounce(async (search, loading) => {
  if (search) {
    loading(true);
    try {
      await executeFetchProgramLeadersOptions(0, {
        extraParams: { full_names: [search] },
      });
    } finally {
      loading(false);
    }
  }
}, 250);

const {
  execute: executeFetchSponsoringInstitutionOptions,
  state: sponsorInstitutionOptions,
  isLoading: sponsoringInstitutionLoading,
} = useGetHomeInstitutionOptions();
const fetchSponsorInstitutionOptions = debounce(async (search, loading) => {
  if (search) {
    loading(true);
    try {
      await executeFetchSponsoringInstitutionOptions(0, { search });
    } finally {
      loading(false);
    }
  }
}, 250);

const {
  execute: executeFetchHostInstitutionOptions,
  state: hostInstitutionOptions,
  isLoading: hostInstitutionOptionsLoading,
} = useGetHostInstitutionOptions();

const fetchHostInstitutionOptions = debounce(async (search, loading) => {
  if (search) {
    loading(true);
    try {
      await executeFetchHostInstitutionOptions(0, { search });
    } finally {
      loading(false);
    }
  }
}, 250);

const {
  execute: executeFetchCityOptions,
  isLoading: cityLoading,
  state: cityOptions,
  isReady: cityReady,
} = useGetCityOptions(
  { immediate: true, throwError: true },
  { city_ascii: "chi" }
);

// const cityOptions = ref([]);
const fetchCityOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    loading(true);
    try {
      await executeFetchCityOptions(0, { city_ascii: search, cancel: true });
      loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        loading(false);
      }
    }
  }
}, 500);

const {
  execute: executeFetchCountryOptions,
  state: countryOptions,
  isLoading: countryOptionsLoading,
} = useSearchCountryOptions({ immediate: true, throwError: true });

// const cityOptions = ref([]);
const fetchCountryOptions = debounce(async (search, loading) => {
  if (search) {
    loading(true);
    try {
      await executeFetchCountryOptions(0, { q: search, cancel: true });
    } finally {
      loading(false);
    }
  }
}, 250);

// TODO: Check RAG-451 ticket comments
// const { state: tagOptions, isLoading: loadingTabs } = useGetTagsOptions(true, [
//   "Thematic",
// ]);

const checkMinMaxPrice = () => {
  if (filters.value?.program_session_base_price_exact) {
    const min_price = Number(filters.value?.base_price_from);
    const max_price = Number(filters.value?.base_price_to);
    if (min_price >= 0 && max_price >= 0 && min_price > max_price) {
      filters.value.base_price_from = max_price;
      filters.value.base_price_to = min_price;
    }
  }
};

const updateApplicationDeadlineDates = (evt) => {
  if (filters.value?.application_deadline_exact) {
    filters.value.application_deadline_from =
      filters.value?.application_deadline_to;
  }
};

const updateBasePrices = (evt) => {
  if (filters.value?.program_session_base_price_exact) {
    filters.value.base_price_from = filters.value?.base_price_to;
  }
};

const emit = defineEmits(["updateFilters"]);
const applyFilters = () => {
  const advancedFilters = Object.keys(filters.value).reduce(
    (acc, cur) => (
      (acc[cur] = Array.isArray(filters.value?.[cur])
        ? filters.value?.[cur].map((item) => unref(item)?.id).filter(Boolean)
        : unref(filters.value?.[cur])),
      acc
    ),
    {}
  );

  if (!Number.isNaN(Number(advancedFilters.base_price_from))) {
    advancedFilters.base_price_from *= 100;
  }
  if (!Number.isNaN(Number(advancedFilters.base_price_to))) {
    advancedFilters.base_price_to *= 100;
  }
  // Backend doesn't care about it
  advancedFilters.program_session_base_price_exact = !advancedFilters.program_session_base_price_exact;

  if (
    !advancedFilters.application_deadline_from &&
    !advancedFilters.application_deadline_to
  ) {
    advancedFilters.application_deadline_exact = undefined;
  } else {
    // Backend accepts the invertion of this
    advancedFilters.application_deadline_exact = !advancedFilters.application_deadline_exact;
  }

  if (advancedFilters.academic_year) {
    advancedFilters.academic_year = filters.value?.academic_year?.map(
      (academicYear) => academicYear.name || []
    );
  }

  emit("updateFilters", advancedFilters);
};
const clearFilters = () => {
  filters.value = reactive(cloneDeep(baseFilters));
  emit("updateFilters", filters?.value);
};

const config = {
  dateFormat: "m/d/Y",
  parseDate: (dateStr) => {
    return parse(dateStr, "yyyy-MM-dd", new Date(), {});
  },
};

onMounted(async () => {
  await store.dispatch("programManager/fetchTerms");
});
</script>

<template>
  <BaseSearchBarFilterModal
    title="Program Session Filter"
    @apply-filters="applyFilters"
    @clear-filters="clearFilters"
  >
    <template #default="{ isModalOpen }">
      <div class="grid grid-cols-3 gap-x-24 gap-y-4">
        <CheckboxSelect
          v-model="filters.terms"
          outer-classes="col-span-3"
          :options="termsOptions"
          :loading="getTermsIsLoading"
          :multiple="true"
          :deselect-from-dropdown="true"
          :close-on-select="false"
          placeholder="Pick a value"
          label="name"
          field-label="Term(s)"
        >
        </CheckboxSelect>
        <CheckboxSelect
          v-model="filters.channel"
          outer-classes="col-span-3"
          :options="channelTypeOptions"
          :multiple="true"
          :deselect-from-dropdown="true"
          :close-on-select="false"
          placeholder="Pick a value"
          label="name"
          field-label="Channel"
        >
        </CheckboxSelect>
        <CheckboxSelect
          v-model="filters.program_session_type"
          outer-classes="col-span-3"
          :options="sessionTypes"
          :multiple="true"
          :deselect-from-dropdown="true"
          :close-on-select="false"
          placeholder="Pick a value"
          label="name"
          field-label="Program Session Type"
        >
        </CheckboxSelect>
        <CheckboxSelect
          v-model="filters.status"
          outer-classes="col-span-3"
          :options="sessionStatuses"
          :multiple="true"
          :deselect-from-dropdown="true"
          :close-on-select="false"
          placeholder="Pick a value"
          label="name"
          field-label="Status"
        >
        </CheckboxSelect>
        <CheckboxSelect
          v-model="filters.city"
          :clear-search-on-blur="() => false"
          :close-on-select="false"
          :components="{ OpenIndicator: Magnifier }"
          :deselect-from-dropdown="true"
          :get-option-label="getCityCountryOptionLabel"
          :filterable="false"
          :multiple="true"
          :options="cityOptions?.items || []"
          :loading="cityLoading || !cityReady"
          outer-classes="col-span-3"
          field-label="City"
          placeholder="Search and add"
          @search="fetchCityOptions"
        >
        </CheckboxSelect>
        <CheckboxSelect
          v-model="filters.country"
          :clear-search-on-blur="() => false"
          :close-on-select="false"
          :components="{ OpenIndicator: Magnifier }"
          :deselect-from-dropdown="true"
          :loading="countryOptionsLoading"
          :multiple="true"
          :options="countryOptions"
          outer-classes="col-span-3"
          field-label="Country"
          label="name"
          placeholder="Search and add"
          @search="fetchCountryOptions"
        >
        </CheckboxSelect>
        <CheckboxSelect
          v-model="filters.host_institution"
          outer-classes="col-span-3"
          field-label="Host Institutions"
          label="name"
          :multiple="true"
          :close-on-select="false"
          placeholder="Search and add"
          :components="{ OpenIndicator: Magnifier }"
          :options="hostInstitutionOptions.items || []"
          :loading="hostInstitutionOptionsLoading"
          :deselect-from-dropdown="true"
          :clear-search-on-blur="() => false"
          @search="fetchHostInstitutionOptions"
        >
        </CheckboxSelect>
        <CheckboxSelect
          v-model="filters.sponsor_institution"
          outer-classes="col-span-3"
          field-label="Sponsor Institutions"
          label="name"
          :multiple="true"
          :close-on-select="false"
          placeholder="Search and add"
          :components="{ OpenIndicator: Magnifier }"
          :options="sponsorInstitutionOptions || []"
          :loading="sponsoringInstitutionLoading"
          :deselect-from-dropdown="true"
          :clear-search-on-blur="() => false"
          @search="fetchSponsorInstitutionOptions"
        >
        </CheckboxSelect>

        <!-- TODO:  Check RAG-451 ticket comments
          TagsInput
          v-model="filters.tags"
          field-label="Thematic Tags"
          class="col-span-3"
          label="name"
          :options="tagOptions"
          :loading="loadingTabs"
          placeholder="Search and add"
        />-->

        <SliderYesNo
          v-model="filters.application_deadline_exact"
          class="col-span-3"
          field-label="Application Deadline"
          yes-label="Exact Date"
          no-label="Range"
          @click="updateApplicationDeadlineDates"
        ></SliderYesNo>

        <CalendarInput
          v-if="filters.application_deadline_exact"
          outer-classes="flex align-center"
          class="w-[160px] rounded-sm"
          placeholder="Select date"
          name="date"
          :config="config"
          :model-value="filters.application_deadline_from || null"
          @onChange="
            (date) =>
              (filters.application_deadline_from =
                date && date[0] ? format(new Date(date[0]), 'yyyy-MM-dd') : '')
          "
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">From</div>
          </template>
        </CalendarInput>
        <CalendarInput
          v-if="filters.application_deadline_exact"
          outer-classes="flex align-center"
          class="w-[160px] rounded-sm"
          placeholder="Select date"
          name="date"
          :config="config"
          :model-value="filters.application_deadline_to || null"
          @onChange="
            (date) =>
              (filters.application_deadline_to =
                date && date[0] ? format(new Date(date[0]), 'yyyy-MM-dd') : '')
          "
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">To</div>
          </template>
        </CalendarInput>

        <CalendarInput
          v-if="!filters.application_deadline_exact"
          outer-classes="flex align-center"
          class="w-[160px] rounded-sm"
          placeholder="Select date"
          name="date"
          :config="config"
          :model-value="filters.application_deadline_to || null"
          @onChange="
            (date) => {
              filters.application_deadline_to =
                date && date[0] ? format(new Date(date[0]), 'yyyy-MM-dd') : '';
              filters.application_deadline_from =
                filters.application_deadline_to;
            }
          "
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">Date</div>
          </template>
        </CalendarInput>

        <label class="common-label-text col-span-3"
          >Start Date and End Date</label
        >

        <CalendarInput
          outer-classes="flex align-center"
          class="w-[160px] rounded-sm"
          placeholder="Select date"
          name="date"
          :config="config"
          :model-value="filters.start_date_from || null"
          @onChange="
            (date) =>
              (filters.start_date_from =
                date && date[0] ? format(new Date(date[0]), 'yyyy-MM-dd') : '')
          "
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">From</div>
          </template>
        </CalendarInput>
        <CalendarInput
          outer-classes="flex align-center"
          class="w-[160px] rounded-sm"
          placeholder="Select date"
          name="date"
          :config="config"
          :model-value="filters.start_date_to || null"
          @onChange="
            (date) =>
              (filters.start_date_to =
                date && date[0] ? format(new Date(date[0]), 'yyyy-MM-dd') : '')
          "
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">To</div>
          </template>
        </CalendarInput>

        <BasicTextInput
          v-model="filters.program_session_id"
          outer-classes="col-span-3"
          field-label="Parent Program Session ID"
        />
        <CheckboxSelect
          v-model="filters.program_leaders"
          outer-classes="col-span-3"
          field-label="Program Leaders"
          label="full_name"
          :multiple="true"
          :loading="programLeadersOptionsLoading"
          :close-on-select="false"
          :components="{ OpenIndicator: Magnifier }"
          :options="programLeadersOptions?.items || []"
          :deselect-from-dropdown="true"
          :clear-search-on-blur="() => false"
          placeholder="Search and add"
          @search="fetchProgramLeadersOptions"
        >
        </CheckboxSelect>

        <SliderYesNo
          v-model="filters.program_session_base_price_exact"
          class="col-span-3"
          field-label="Program Session base price"
          yes-label="Exact Match"
          no-label="Range"
          @click="updateBasePrices"
        />

        <BasicTextInput
          v-if="filters.program_session_base_price_exact"
          v-model="filters.base_price_from"
          class="rounded-sm border-gray-300 w-[160px]"
          outer-classes="flex col-span-1"
          type="number"
          min="0.01"
          step="0.01"
          max="25000000"
          @blur="checkMinMaxPrice"
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">From $</div>
          </template>
        </BasicTextInput>
        <BasicTextInput
          v-if="filters.program_session_base_price_exact"
          v-model="filters.base_price_to"
          class="rounded-sm border-gray-300 w-[160px]"
          outer-classes="flex flex-grow-1 col-span-1"
          type="number"
          min="0.01"
          step="0.01"
          max="25000000"
          @blur="checkMinMaxPrice"
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">To $</div>
          </template>
        </BasicTextInput>

        <BasicTextInput
          v-if="!filters.program_session_base_price_exact"
          class="rounded-sm border-gray-300 w-[160px]"
          outer-classes="flex flex-grow-1 col-span-1"
          type="number"
          min="0.01"
          step="0.01"
          max="25000000"
          :model-value="filters.base_price_to"
          @update:modelValue="
            (val) => {
              filters.base_price_to = val;
              filters.base_price_from = val;
            }
          "
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">Price $</div>
          </template>
        </BasicTextInput>

        <CheckboxSelect
          v-model="filters.academic_year"
          outer-classes="col-span-3"
          label="name"
          field-label="Academic Year"
          :multiple="true"
          :close-on-select="false"
          :components="{ OpenIndicator: Magnifier }"
          :options="academicYearOptions"
          :deselect-from-dropdown="true"
          placeholder="Pick a value"
        >
        </CheckboxSelect>
      </div>
    </template>
  </BaseSearchBarFilterModal>
</template>
