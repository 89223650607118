<template>
  <div>
    <div class="mt-6">
      <span class="font-bold text-sm leading-normal font-sans">
        Please provide the name and if this is a general listing to get started.
        Don't worry, you can change them later if necessary.
      </span>
    </div>
    <form
      id="modal-housing-form"
      class="mb-1"
      action=""
      aria-label="Choose name and if generic listing for Housing"
    >
      <div class="mt-8 flex flex-col text-sm mt-4">
        <label class="block text-sm font-bold text-gray-700" for="fname"
          ><span class="text-red-100">* </span>Name</label
        >
        <input
          v-model="housing.name"
          class="mt-1 block w-full h-8 px-2 rounded-md ring-1 ring-gray-300 shadow-sm focus:border-teal-900 focus:outline-none focus:ring-teal-900 sm:text-sm"
          type="text"
          :class="{
            'bg-error-100': v$.housing.name.$error,
          }"
        />
        <div class="w-full">
          <div v-if="v$.housing.name.$error" class="error text-error-900">
            {{ customErrorCopy }}
          </div>
        </div>
        <label class="mt-8 block text-sm font-bold text-gray-700" for="fname">
          <span class="text-red-100">* </span>Is this a general listing?
        </label>
        <div
          class="flex items-center mt-3 sm:flex-col md:flex-row sm:items-start md:items-center"
        >
          <label class="flex pr-8">
            <input
              v-model="housing.genericListing"
              name="genericListing"
              type="radio"
              class="mt-1 text-university-primary form-radio"
              value="yes"
              :checked="housing.genericListing === 'yes'"
              :disabled="isLocalHostType()"
            />
            <span class="ml-2">Yes</span>
          </label>
          <label class="flex">
            <input
              v-model="housing.genericListing"
              name="genericListing"
              type="radio"
              class="mt-1 text-university-primary form-radio"
              value="no"
              :checked="housing.genericListing === 'no'"
              :disabled="isLocalHostType()"
              :class="{
                'bg-error-100': v$.housing.genericListing.$error,
              }"
            />
            <span class="ml-2">No</span>
          </label>
        </div>
        <div class="w-full">
          <div
            v-if="v$.housing.genericListing.$error"
            class="error text-error-900"
          >
            {{ customErrorCopy }}
          </div>
        </div>
      </div>
      <div class="flex mt-14 justify-center">
        <button
          class="py-2 px-6 mr-5 inline-flex items-center rounded-md border border-teal-900 bg-white text-sm font-medium text-teal-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus: focus:ring-offset-2"
          :disabled="disableCancelButton"
          @click="$emit('closeModal')"
        >
          Cancel
        </button>
        <div class="flex items-center">
          <ButtonWithSpinner
            ref="saveNext"
            data-cy="save-next-button"
            variant="secondary"
            variant-type="normal"
            @click.prevent="submit()"
          >
            <span>
              Save & Next
            </span>
          </ButtonWithSpinner>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { LOCAL_HOST_HOUSING } from "../../../constants";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";

export default {
  name: "HousingForm",
  components: {
    ButtonWithSpinner,
  },
  props: {
    housingData: {
      type: Object,
      default: () => {
        return {
          name: null,
          type: "Apartment",
          genericListing: "no",
        };
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    customErrorCopy: {
      type: String,
      default: "This field is required.",
    },
  },
  emits: ["form", "closeModal"],
  data() {
    return {
      v$: useValidate(),
      housing: { ...this.housingData },
      disableCancelButton: false,
    };
  },
  watch: {
    isLoading: {
      handler(current) {
        if (current) {
          this.disableCancelButton = false;
          this.$refs.saveNext.stopLoading();
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.isLocalHostType()) {
      this.housing.genericListing = "yes";
    }
  },

  methods: {
    async submit() {
      const valid = await this.v$.$validate();
      if (valid) {
        this.disableCancelButton = true;
        this.$refs.saveNext.startLoading();
        this.$emit("form", {
          name: this.housing.name,
          genericListing: this.isLocalHostType()
            ? "yes"
            : this.housing.genericListing,
        });
      }
    },
    isLocalHostType() {
      return this.housing.type === LOCAL_HOST_HOUSING;
    },
  },
  validations() {
    return {
      housing: {
        name: { required },
        genericListing: { required },
      },
    };
  },
};
</script>
<style scoped>
input[type="radio"]:disabled {
  border-color: rgba(200, 200, 200, var(--tw-border-opacity));
  color: rgba(200, 200, 200, var(--tw-text-opacity));
}
</style>
