<template>
  <section>
    <h1 class="mb-2 text-xl text-gray-650 md:text-2xl">What's Next?</h1>
    <p class="text-base text-gray-600">
      We received all of your application materials and you will hear back from
      us within a week on your acceptance!
    </p>

    <div class="flex flex-col justify-start items-start p-4">
      <p class="flex flex-row justify-start items-center mb-2">
        <CheckMarkIcon :completed="completed" />

        <span class="text-sm ml-4 text-gray-600">
          Account Information
        </span>
      </p>
      <p class="flex flex-row justify-start items-center mb-2">
        <CheckMarkIcon :completed="completed" />

        <span class="text-sm ml-4 text-gray-600">
          Program Selection
        </span>
      </p>
      <p class="flex flex-row justify-start items-center mb-2">
        <CheckMarkIcon :completed="completed" />

        <span class="text-sm ml-4 text-gray-600">
          Academic Information
        </span>
      </p>
      <p
        v-if="!institutionPaysApplicationFee"
        class="flex flex-row justify-start items-center mb-2"
      >
        <CheckMarkIcon :completed="completed" />

        <span class="text-sm ml-4 text-gray-600">
          {{ usePascalCase(APPLICATION_FEE_DATA.typeLabel) }}
        </span>
      </p>
    </div>

    <p class="text-base text-gray-600">
      If you have any questions contact your program coordinator. <br />API
      Enrollment Management Team:
      <span>
        <a
          id="coordinator-email"
          class="font-base text-gray-600 break-all hover:underline hover:text-gray-700 text-teal-40"
          :href="'mailto:support-other@apiexperience.com'"
        >
          support-other@apiexperience.com
        </a>
      </span>
    </p>

    <p class="text-base text-gray-600 mt-6">
      If you want to get a headstart with your onboarding materials, you can
      click below to continue!
    </p>
    <p class="text-base text-gray-600 mt-6">
      Thank you for submitting your application!
    </p>
    <div class="mt-10">
      <ButtonWithSpinner
        ref="SubmitForm"
        data-cy="nextStepsSubmit"
        type="submit"
        variant="primary"
        variant-type="block"
        @click.prevent="submit()"
      >
        <span>Continue</span>
      </ButtonWithSpinner>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import CheckMarkIcon from "../SharedComponents/CheckMarkIcon.vue";
import forms from "../../../mixins/forms";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import { eventBus } from "../../../app";
import { APICompany, APPLICATION_FEE_DATA } from "@/constants";
import { usePascalCase } from "@/composables/stringManupulation";
export default {
  name: "NextSteps",
  components: { CheckMarkIcon, ButtonWithSpinner },
  mixins: [forms],
  props: {
    applicationSubmissions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    // We need to include APPLICATION_FEE_DATA to be able to use in ui
    return { APPLICATION_FEE_DATA };
  },
  data() {
    return {
      completed: true,
      redirect: false,
      redirectTo: "",
    };
  },
  computed: {
    ...mapState(["program"]),
    getCity() {
      return this.program.site ? this.program.site.city : "";
    },

    getFallbackEmailLabel() {
      return this.program.program_manager_email &&
        this.program.program_manager_email.length > 1
        ? this.program.program_manager_email
        : `Enrollment Management <enrollment${APICompany.companyEmail}>`;
    },
    paymentFormIsComplete() {
      return this.applicationSubmissions.some((submission) => {
        return (
          submission.form === this.forms[this.paymentFormIndex].id &&
          submission.state === "submitted"
        );
      });
    },
  },
  watch: {
    redirect: function (newVal) {
      if (newVal) {
        //TODO where do we redirect if user does not own the application ID or if it not paid?
        //redirect to my applications
        this.$router.push({
          name: this.redirectTo,
        });
      }
    },
  },
  async created() {
    this.applicationId = this.$route.params.applicationId;

    //Make sure the current user owns the application ID
    if (!(await this.isApplicationOwner(this.applicationId))) {
      this.redirectTo = "applications";
      this.redirect = true;
      return;
    }

    if (!this.paymentFormIsComplete) {
      this.redirectTo = "applications";
      this.redirect = true;
      return;
    }

    eventBus.$emit("showSpinner", false);
  },

  methods: {
    usePascalCase,
    submit() {
      this.$router.push({
        name: "applications/landing",
        params: {
          applicationId: this.applicationId,
        },
      });
    },
  },
};
</script>
