<script setup>
import { FOOTERLINKS } from "@/constants.js";
import getYear from "date-fns/getYear";
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();


const copyYear = computed(() => {
  return getYear(new Date());
});

const isBranded = computed(() => {
  return store?.getters?.getBrandingDetails?.secondary_color || "";
});

</script>
<template>
  <div
  class="pb-17 pt-14 sm:pt-13 bg-gray-75 px-20 md:pr-44"
  >
    <img
      src="/images/api-experience-logo.png"
      alt="Academic Programs International"
      class="sm:hidden mb-10"
    />
    <div
    v-if="!isBranded"
    class="grid footer-grid mb-24 gap-10">
      <div
        class="flex flex-col font-montserrat non-italic font-medium text-sm text-indigo-base leading-6"
      >
        <h1 class="font-bold text-base leading-5 uppercase">EXPERIENCES</h1>
        <a
          v-for="(link, index) in FOOTERLINKS.experiences"
          :key="index"
          :href="link.href"
          :id="'footerexperiencelink-' + index"
          >{{ link.label }}</a
        >
      </div>
      <div
        class="flex flex-col font-montserrat non-italic font-medium text-sm text-indigo-base leading-6"
      >
        <h1 class="font-bold text-base leading-5 uppercase">
          <a href="https://apiabroad.com/who-we-serve/">Who we serve</a>
        </h1>
        <a
          v-for="(link, index) in FOOTERLINKS.whoweserve"
          :key="index"
          :href="link.href"
          :id="'footerwhoweservelink-' + index"
          >{{ link.label }}</a
        >
      </div>
      <div
        class="flex flex-col font-montserrat non-italic font-medium text-sm text-indigo-base leading-6"
      >
        <h1 class="font-bold text-base leading-5 uppercase">Resources</h1>
        <a
          v-for="(link, index) in FOOTERLINKS.resources"
          :key="index"
          :href="link.href"
          :id="'footerresourceslink-' + index"
          >{{ link.label }}</a
        >
      </div>

      <div
        class="flex flex-col font-montserrat non-italic font-medium text-sm text-indigo-base leading-6"
      >
        <h1 class="font-bold text-base leading-5 uppercase">Why API</h1>
        <a
          v-for="(link, index) in FOOTERLINKS.whyapi"
          :key="index"
          :href="link.href"
          :id="'footerwhyapilink-' + index"
          >{{ link.label }}</a
        >
      </div>

      <div
        class="flex flex-col font-montserrat non-italic font-medium text-sm text-indigo-base leading-6"
      >
        <h1 class="font-bold text-base leading-5 uppercase">Legal</h1>
        <a
          v-for="(link, index) in FOOTERLINKS.legal"
          :key="index"
          :href="link.href"
          :id="'footerlegallink-' + index"
          >{{ link.label }}</a
        >
      </div>

      <div
        class="flex flex-col font-montserrat non-italic font-medium text-sm text-indigo-base leading-6"
      >
        <h1 class="font-bold text-base leading-5 uppercase mb-4 md:mb-8">
          Follow Us
        </h1>
        <div class="grid grid-cols-3 gap-1-875">
          <a
            v-for="(link, index) in FOOTERLINKS.socialmedia"
            :key="index"
            :href="link.href"
            :id="'footersocialmedialink-' + index"
          >
            <img :src="link.icon" :alt="link.label" class="w-8" />
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="!isBranded"
      class="flex justify-center font-montserrat text-indigo-base text-opacity-50 text-sm font-medium"
    >
      © {{ copyYear }} Academic Programs International, LLC. All rights
      reserved.
    </div>
    <div
      class="flex justify-center pl-20 pr-24 lg:pl-40 lg:pr-24 xl:pl-40 xl:pr-24"
      v-if="isBranded"
    >
      <div
        class="flex font-montserrat non-italic font-medium text-sm text-indigo-base leading-5"
      >
        <h1 class="font-bold justify-center text-base leading-5 uppercase">Legal &nbsp;</h1>
        <a
          v-for="(link, index) in FOOTERLINKS.legal"
          :key="index"
          :href="link.href"
          :id="'footerlegallink-' + index"
          >{{ link.label }} &nbsp;
        </a>
      </div>
      <div >
        <div
          class="flex justify-center leading-5 font-montserrat text-indigo-base  text-sm font-medium">
          © {{ copyYear }} Academic Programs International, LLC. All rights
          reserved.
        </div>
      </div>
    </div>
    <span class="text-gray-75">{{ buildVersion }}</span>
  </div>
</template>
