import { learningServiceRequest, skip } from "./utils";

const citiesService = {
  async getCities({
    limit = 150,
    page = 1,
    q,
    city_ascii = "",
    in_use = false,
    ignore_session_status = false,
    has_internships_only = false,
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/cities",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        city_ascii,
        in_use,
        ignore_session_status,
        has_internships_only,
      },
      source,
    });
  },
  async getSingleCity({ city_id = "" }) {
    return await learningServiceRequest({
      method: "get",
      url: `/cities/${city_id}`,
      failureMessage: "Error loading Cities data",
    });
  },
  async updateCity({ city_id = "", payload = {} }) {
    return await learningServiceRequest({
      method: "put",
      url: `/cities/${city_id}`,
      payload,
      failureMessage: "Error changing city record",
    });
  },
};

export default citiesService;
