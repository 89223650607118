<script setup>
import { inject, reactive, computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { GENDERSV3, GENDERS, GENDER_IDENTITIES } from "@/constants.js";
import {
  helpers,
  required,
  requiredIf,
  maxLength,
} from "@vuelidate/validators";
import { minDateValidator } from "@/mixins/customValidators";
import { isFuture } from "date-fns";
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import v3FormTracker from "@/services/V3FormTracker.js";
import countriesService from "@/services/countries.js";
import profileService from "@/services/profile.js";
import formService from "@/services/form.js";
import TextField from "@/components/forms/SharedComponents/DarkBlueInputs/TextField.vue";
import DateField from "@/components/forms/SharedComponents/DarkBlueInputs/DateField.vue";
import SingleSelect from "@/components/forms/SharedComponents/DarkBlueInputs/SingleSelect.vue";
import Checkbox from "@/components/forms/SharedComponents/DarkBlueInputs/Checkbox.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import { usePastDeadline } from "@/composables/usePastDeadline.js";

const updateStepAndRedirect = inject("updateStepAndRedirect");
const { countries: initialCountries } = inject("initialOptionsData");

const {
  disabledContinueButton,
  isPastDeadline,
  validateDeadline,
} = usePastDeadline();

// Variables
const router = useRouter();
const store = useStore();
const aboutYouSubmit = ref(null);
const form = reactive({
  legalFirstName: "", // required
  preferredFirstName:"",
  middleName: "",
  lastName: "", //required
  birthdate: "", // required
  gender: null, // required
  genderIdentity: null,
  otherGenderIdentity: null,
  pronouns: "",
  citizenship: null, // required
  imDualCitizen: false,
  citizenship2: null, // required if imDualCitizen is true
});
const countries = ref([]); // Gets data from countries service
const requiredErrorCopy = "This field is required.";
const dateErrorCopy = "Invalid birthdate. Please use calendar.";
const notFutureDate = (value) => !isFuture(new Date(value));
const patchObject = (fieldName = "", value = "") => {
  // fieldName should match the name of the field in formio
  // value is the new value to assign
  return {
    op: "add",
    path: "/data/" + fieldName,
    value: value,
  };
};
// Computed

// TODO: Works while impersonating?
const student_formio_submission_id = computed(() => {
  return store.state.currentUser?.formioStudentId ?? "";
});

const applicationId = computed(() => store.state.currentApplicationId);

const oktaId = computed(() => {
  return store.state.currentUser?.participantId ?? "";
});

const profile_data = computed(() => {
  return store.state.profileData;
});

const profileSelectedObjects = computed(() => {
  return store.state.profileSelectedObjects;
});

const currentUser = computed(() => {
  return store.state.currentUser;
});

const formioGenderFormat = computed(() => {
  if (form.gender?.value === "Non-Binary") return "nonBinary";
  return (
    GENDERS.find((gender) => gender.label === form.gender?.label)?.value ?? ""
  );
});

const formioPatchStudentObject = computed(() => {
  // Updates student submission
  return [
    patchObject("gender", formioGenderFormat.value),
    patchObject("citizenship", form.citizenship.name),
    patchObject("ihavedualcitizenship", form.imDualCitizen),
  ];
});

const saveObject = computed(() => {
  // Object to update profile service
  return {
    // TODO: While next todo item remains, think, shouldn't email come from Connect or Profile Service?
    email: currentUser.value.email, // TODO: Remove email from request; profile-service endpoint requires it but that shouldn't happen
    legal_first_name: form.legalFirstName,
    preferred_first_name: form.preferredFirstName,
    middle_name: form.middleName,
    last_name: form.lastName,
    birthdate: form.birthdate,
    gender_identity: form.gender.label, // gender on passport
    pronouns: form.pronouns,
    primary_citizenship_country_id: form.citizenship?.id ?? null,
    dual_citizenship: form.imDualCitizen,
    dual_citizenship_country_first_id: form.citizenship2?.id ?? null,
    majors: profile_data.value?.majors ?? [], // TODO: Fix endpoint, we should not sent this, removing this from request shouldn't clear relationships
    minors: profile_data.value?.minors ?? [], // TODO: Fix endpoint, we should not sent this, removing this from request shouldn't clear relationships
    colleges: profile_data.value?.colleges ?? [], // TODO: Fix endpoint, we should not sent this, removing this from request shouldn't clear relationships
    languages: profile_data.value?.languages ?? [], // TODO: Fix endpoint, we should not sent this, removing this from request shouldn't clear relationships
    parents: profile_data.value?.parents ?? [], // TODO: Fix endpoint, we should not sent this, removing this from request shouldn't clear relationships
    user_types: profile_data.value?.user_types ?? [], // TODO: Fix endpoint, we should not sent this, removing this from request shouldn't clear relationships
    okta_id: profile_data?.value?.okta_id,
    gender_identity_id: form.genderIdentity?.id,
    other_gender: form.otherGenderIdentity,
  };
});

// Form validation rules (Vuelidate library)
const rules = {
  legalFirstName: {
    required: helpers.withMessage(requiredErrorCopy, required),
    maxLength: helpers.withMessage(
      ({ $params }) =>
        `Legal First Name must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },
  preferredFirstName: {
    maxLength: helpers.withMessage(
      ({ $params }) =>
        `Preferred First Name must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },
  middleName: {
    maxLength: helpers.withMessage(
      ({ $params }) =>
        `Middle Name must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },
  lastName: {
    required: helpers.withMessage(requiredErrorCopy, required),
    maxLength: helpers.withMessage(
      ({ $params }) =>
        `Last Name must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },
  birthdate: {
    required: helpers.withMessage(requiredErrorCopy, required),
    isValidMinDate: helpers.withMessage(
      dateErrorCopy,
      minDateValidator("1940")
    ),
    notFutureDate: helpers.withMessage(dateErrorCopy, notFutureDate),
  },
  gender: {
    required: helpers.withMessage(requiredErrorCopy, required),
  },
  genderIdentity: {},
  otherGenderIdentity: {
    maxLength: helpers.withMessage(
      ({ $params }) =>
        `Gender Identity must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },

  pronouns: {
    maxLength: helpers.withMessage(
      ({ $params }) =>
        `Pronouns must have fewer than ${$params.max} characters.`,
      maxLength(100)
    ),
  },
  citizenship: {
    required: helpers.withMessage(requiredErrorCopy, required),
  },
  imDualCitizen: {},
  citizenship2: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => form.imDualCitizen == true)
    ),
  },
};
const v$ = useVuelidate(rules, form);

onMounted(async () => {
  countries.value = initialCountries;

  await canViewForm();
  prePopulateProfileData();
});

// Methods
async function canViewForm() {
  // Can view form if is authenticated
  let canViewForm = store.getters.getCurrentUser;
  if (!canViewForm) {
    // Go to applications
    router.push({ name: "applications" });
  }
}

async function getCountries({ search, loading }) {
  // Get Countries data
  const { data } = await countriesService.searchCountries({
    limit: 20,
    q: search ?? "",
  });
  loading(false);

  const filteredCountries =
    data?.data?.filter((country) => country.name !== "United States") || [];

  countries.value = filteredCountries;
}

const reorderedCountries = computed(() => {
  let reordered = [...countries.value];

  const unitedStates = {
    archived_at: null,
    created_at: "2021-10-14T14:22:08Z",
    id: "US",
    iso: "US",
    iso3: "USA",
    latitude: 38,
    longitude: -97,
    name: "United States",
    numeric_code: 840,
    updated_at: null,
    updated_by: null,
  };
  reordered.unshift(unitedStates);

  return reordered;
});

async function submitAboutYou() {
  aboutYouSubmit.value.startLoading();
  // TODO: What to do with existing accounts w/o profiles?
  try {
    validateDeadline();

    if (isPastDeadline.value) return;

    let profile_response = await profileService.updateProfile(
      oktaId.value,
      saveObject.value
    );

    // Keep Connect's Form Tracker up to date
    // TODO: Dont send if in state
    await v3FormTracker.setAsCompleted("AboutYou", applicationId.value);

    // Keep profile state up to date
    store.commit("setProfileData", profile_response.data.data);

    // Mark as complete
    store.commit("setStepCompletedV3", "AboutYou");

    // Update student submission - for gender, citizenships
    if (student_formio_submission_id.value) {
      await formService.partiallyUpdateSubmission(
        "student",
        student_formio_submission_id.value,
        formioPatchStudentObject.value
      );
    }

    // Update data on vuex
    store.commit("setProfileSelectedObjects", {
      propertyName: "citizenship",
      value: form.citizenship,
    });
    store.commit("setProfileSelectedObjects", {
      propertyName: "citizenship2",
      value: form.citizenship2,
    });

    updateStepAndRedirect("application-learner-info", applicationId.value);
  } finally {
    aboutYouSubmit.value.stopLoading();
  }
}

async function prePopulateProfileData() {
  if (!_.isEmpty(profile_data.value)) {
    // Gets data of profile, pre-populates fields
    form.legalFirstName = profile_data.value?.legal_first_name ?? "";
    form.preferredFirstName = profile_data.value?.preferred_first_name ?? "";
    form.middleName = profile_data.value?.middle_name ?? "";
    form.lastName = profile_data.value?.last_name ?? "";
    form.birthdate = profile_data.value?.birthdate ?? "";
    form.gender = profile_data.value?.gender_identity
      ? GENDERSV3.find(
          (gender) => gender.label == profile_data.value.gender_identity
        )
      : null;
    form.pronouns = profile_data.value?.pronouns ?? "";
    form.imDualCitizen = profile_data.value?.dual_citizenship ?? false;
  }

  form.citizenship = profileSelectedObjects.value.citizenship;
  form.citizenship2 = profileSelectedObjects.value.citizenship2;
}

const dualCitizenshipOptions = computed(() => {
  if (form.citizenship) {
    return reorderedCountries.value.filter(
      (country) => country.id !== form.citizenship.id
    );
  }
  return reorderedCountries.value;
});
</script>

<template>
  <div
    class="font-montserrat text-indigo-base text-left bg-white w-full p-14 border-collapse md:mt-0 md:p-0"
  >
    <div class="grid grid-cols-1 gap-y-7 pt-4">
      <div>
        <h1 class="font-bold text-[28px] uppercase">
          About You
        </h1>
        <p class="font-medium text-xs">
          With the exception of pronouns, please ensure all information matches
          your passport.
        </p>
      </div>
      <p class="font-medium text-xs">
        Fields marked with (*) are required.
      </p>
      <!-- Legal First Name* -->
      <TextField
        id="legalFirstName"
        v-model.trim="form.legalFirstName"
        label="Legal First Name*"
        placeholder="Enter Your Legal First Name"
        :vuelidate-field="v$.legalFirstName"
        :show-errors="true"
        label-test-id="legal-first-name-label"
      />
      <TextField
        id="preferredFirstName"
        v-model.trim="form.preferredFirstName"
        label="Preferred First Name"
        placeholder="Enter Your  Preferred First Name"
        :vuelidate-field="v$.preferredFirstName"
        :show-errors="true"
        label-test-id="preferred-first-name-label"
      />

      <!-- Middle Name -->
      <TextField
        id="middleName"
        v-model.trim="form.middleName"
        label="Middle Name"
        placeholder="Enter Your Middle Name"
        :vuelidate-field="v$.middleName"
        :show-errors="true"
      >
        <template #help-content>
          <p class="text-sm">
            Required if you have a middle name on your passport.
          </p>
        </template>
      </TextField>

      <!-- Last Name* -->
      <TextField
        id="lastName"
        v-model.trim="form.lastName"
        label="Last Name*"
        placeholder="Enter Your Last Name"
        :vuelidate-field="v$.lastName"
        :show-errors="true"
      />

      <!-- Birthdate -->
      <DateField
        id="birthdate"
        v-model.trim="form.birthdate"
        label="Birthdate*"
        :vuelidate-field="v$.birthdate"
      >
        <template #help-content>
          <p class="text-sm">
            For eligibility for programs with age restrictions.
          </p>
        </template>
      </DateField>

      <!-- Gender -->
      <SingleSelect
        id="gender"
        v-model.trim="form.gender"
        label="Gender on Passport*"
        :vuelidate-field="v$.gender"
        :options="GENDERSV3"
        option-label="label"
      />

      <!-- Gender Identity -->
      <SingleSelect
        id="genderIdentity"
        v-model.trim="form.genderIdentity"
        test-id="-gender-identity-field"
        label="Which most closely describes your gender?"
        :vuelidate-field="v$.genderIdentity"
        :options="GENDER_IDENTITIES"
        option-label="label"
      />

      <!-- Additional Gender Identity Field -->
      <TextField
        v-if="form.genderIdentity?.value === 'other'"
        id="otherGenderIdentity"
        v-model.trim="form.otherGenderIdentity"
        data-testid="additional-gender-identity-field"
        label="Please Specify Your Gender"
        placeholder="Enter Your Gender Identity"
        :vuelidate-field="v$.otherGenderIdentity"
        :show-errors="true"
      />

      <!-- Gender Pronouns -->
      <TextField
        id="pronouns"
        v-model.trim="form.pronouns"
        label="Gender Pronouns"
        placeholder="Enter Your Pronouns"
        :vuelidate-field="v$.pronouns"
        :show-errors="true"
      />

      <!-- Citizenship -->
      <SingleSelect
        id="citizenship"
        v-model.trim="form.citizenship"
        label="Citizenship*"
        :vuelidate-field="v$.citizenship"
        :options="reorderedCountries"
        :search-method="true"
        option-label="name"
        @search="getCountries"
      />

      <!-- I have dual citizenship -->
      <Checkbox
        id="dualcitizen"
        v-model.trim="v$.imDualCitizen.$model"
        :vuelidate-field="v$.imDualCitizen"
      >
        <template #label>
          I am a dual citizen.
        </template>
      </Checkbox>

      <!-- Citizenship 2nd (dual) -->
      <SingleSelect
        v-if="form.imDualCitizen"
        id="citizenship2"
        v-model.trim="form.citizenship2"
        label="Dual Citizenship*"
        :vuelidate-field="v$.citizenship2"
        :options="dualCitizenshipOptions"
        :search-method="true"
        option-label="name"
        @search="getCountries"
      />

      <ButtonWithSpinner
        id="aboutYouButton"
        ref="aboutYouSubmit"
        data-cy="aboutYouButton"
        data-testid="about-you-button"
        type="submit"
        variant="secondary"
        variant-type="block"
        button-height="min-h-[58px] md:min-h-[60px] mt-4"
        :grey-disabled-class="true"
        :disabled="v$.$invalid || disabledContinueButton"
        @click="submitAboutYou"
      >
        <div class="flex items-center justify-center">
          <span class="pr-3 uppercase">
            Continue
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
    </div>
  </div>
</template>
