<template>
  <div data-testid="career-interests">
    <span class="font-bold text-sm leading-4 text-indigo-base mr-1">
      {{ careerAreaLabel }}
    </span>
    <span class="text-sm leading-4 text-indigo-base font-normal mr-1">
      {{ careerInterests.text }}
    </span>
    <span
      v-if="careerInterests.notShown > 0"
      :class="`text-sm leading-4 ${secondaryColorClass} font-medium`"
    >
      + {{ careerInterests.notShown }} more
    </span>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";

const props = defineProps({
  sessionId: { type: String, default: "" },
  careerInterests: { type: Object, default: () => {} },
});

const { careerInterests } = toRefs(props);

const careerAreaLabel = computed(() => {
  return careerInterests.value.total > 1 ? "Career areas:" : "Career area:";
});
</script>
