<script setup>
import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from "@headlessui/vue";
import ProgramSessionAdopted from "@/components/shared/icons/ProgramSessionAdopted.vue";
import ProgramSessionCore from "@/components/shared/icons/ProgramSessionCore.vue";
import ProgramSessionCustom from "@/components/shared/icons/ProgramSessionCustom.vue";
import { channelTypes } from "@/components/program-manager/sessions/constants";
import { useRouter } from "vue-router";
import { hasProgramsWritePermission } from "@/composables/authorization";

const router = useRouter();
const isOpen = ref(false);
function setIsOpen(value) {
  isOpen.value = value;
}
const createProgramSession = (sessionType) => {
  router.push({
    name: "edit-program-sessions",
    params: { programSessionId: sessionType },
  });
};
</script>

<template>
  <div>
    <Dialog
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
      :open="isOpen"
      @close="() => setIsOpen(false)"
    >
      <div
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-20 relative"
      >
        <div class="absolute xs:top-1r sm:top-1r md:top-1r lg:top-1r right-1r">
          <button
            class="h-6 w-6 flex items-center justify-center"
            @close="() => setIsOpen(false)"
          >
            <span class="sr-only">Close</span>
            <CloseIcon @close="() => setIsOpen(false)" />
          </button>
        </div>
        <DialogPanel>
          <DialogTitle
            class="flex justify-center text-xl leading-tight text-center sm:text-2xl md:text-3xl mt-10 mb-6"
          >
            Create Program Session
          </DialogTitle>
          <DialogDescription>
            <p
              class="fmt-6 font-sans mt-6 font-semibold text-md-left leading-normal"
            >
              Which of these best describes the type of program session?
            </p>
            <div class="flex flex-row flex-wrap mt-10">
              <div
                class="flex flex-grow-1 mb-8 cursor-pointer"
                @click="() => createProgramSession(channelTypes.CORE)"
              >
                <ProgramSessionCore />
                <div class="flex flex-row flex-wrap ml-4">
                  <div
                    class="flex-grow-1 w-full fmt-6 font-sans font-semibold text-md-left leading-normal"
                  >
                    Core
                  </div>
                  <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </div>
                </div>
              </div>
              <div
                class="flex flex-grow-1 mb-8 cursor-pointer"
                @click="() => createProgramSession(channelTypes.CUSTOM)"
              >
                <ProgramSessionCustom />
                <div class="flex flex-row flex-wrap ml-4">
                  <div
                    class="flex-grow-1 w-full fmt-6 font-sans font-semibold text-md-left leading-normal"
                  >
                    Custom
                  </div>
                  <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </div>
                </div>
              </div>
              <div class="flex flex-grow-1 cursor-pointer">
                <ProgramSessionAdopted />
                <div
                  class="flex flex-row flex-wrap ml-4"
                  @click="() => createProgramSession(channelTypes.ADOPTED)"
                >
                  <div
                    class="flex-grow-1 w-full fmt-6 font-sans font-semibold text-md-left leading-normal"
                  >
                    Adopted
                  </div>
                  <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </div>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogPanel>
      </div>
    </Dialog>
    <div class="text-center text-gray-600">
      <BaseButton
        v-if="hasProgramsWritePermission()"
        type="button"
        variant="primary"
        variant-type="block"
        outlined
        :on-click="() => setIsOpen(true)"
      >
        New
      </BaseButton>
    </div>
  </div>
</template>
