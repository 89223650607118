<script setup>
import { ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { debounce } from "lodash";
import { FlexRender } from "@tanstack/vue-table";

import MinimalActionsMenu from "@/components/shared/Table/MinimalActionsMenu.vue";
import ErrorPage from "@/components/errorPage.vue";
import Pagination from "@/components/shared/Pagination.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { useGetProfiles } from "@/components/program-manager/sessions/composable";
import { createColumnHelper } from "@tanstack/vue-table";
import {
  getCoreRowModel,
  getSortedRowModel,
  useVueTable,
} from "@tanstack/vue-table";

const router = useRouter();
const route = useRoute();
const store = useStore();

const headers = [
  "First Name",
  "Last Name",
  "Email",
  "Job Title",
  // "City",
  // "Country",
];
const { page: routePage, q } = route.query;

const requestParams = reactive({
  limit: 25,
  page: Number(routePage) || 1,
  search: q || "",
  extraParams: {},
});

const {
  isLoading: loading,
  error: fetchError,
  execute: fetchProfiles,
  state: profilesData,
} = useGetProfiles(
  { immediate: true, throwError: true, resetOnExecute: true },
  { limit: 10, skip: 10 }
);

const openAdvancedFilters = ref(false);
const selectedSortHeader = ref(headers[0]);
const isDesc = ref(false);

const activeFilter = computed(() => ({
  title: "Profiles",
  count: profilesData?.value?.count || 0,
}));

const showPagination = computed(
  () => activeFilter.value.count > requestParams.limit
);

const isUserManagementEnabled = computed(() =>
  Boolean(store.state?.featureFlags["user-management"])
);

watch(requestParams, (newParams) => {
  fetchProfiles(0, {
    q: newParams.search,
    page: newParams.page,
    limit: newParams.limit,
  });
});

const columnHelper = createColumnHelper();

const table = useVueTable({
  get data() {
    return (
      profilesData?.value?.items?.map((item) => ({
        oktaId: item?.okta_id,
        "First Name": item?.legal_first_name || item?.preferred_first_name,
        "Last Name": item?.last_name,
        Email: item?.email,
        "Job Title": item?.job_title,
        // City: item?.city,
        // Country: item?.country,
      })) || []
    );
  },
  columns: headers.map((header) => {
    return columnHelper.accessor(header, {
      cell: (info) => info.getValue(),
      header,
    });
  }),
  enableColumnResizing: true,
  columnResizeMode: "onChange",
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  manualPagination: true,
});

const handleSearch = (search) => {
  requestParams.search = search || "";
  requestParams.page = 1;
  router.push({
    query: {
      page: 1,
      q: requestParams.search,
    },
  });
};

const changePage = (newPage) => {
  requestParams.page = newPage;
  router.push({
    query: {
      ...route.query,
      page: requestParams.page,
    },
  });
};

const handleClickHeader = ({ id }) => {
  if (id === selectedSortHeader.value) {
    isDesc.value = !isDesc.value;
  } else {
    isDesc.value = false;
    selectedSortHeader.value = id;
  }
  table?.getColumn(id).toggleSorting(isDesc.value);
};

const search = debounce(handleSearch, 500);
</script>

<template>
  <div v-if="isUserManagementEnabled" class="w-full">
    <section>
      <div class="flex mt-10 px-3 w-full flex-wrap">
        <div class="flex ml-2 w-full justify-between flex-wrap">
          <h1 class="text-2xl font-bold text-blue-900">
            User Management
          </h1>
          <SearchBar
            class="flex mt-3 sm:w-full min-w-[300px] md:w-[400px]"
            :input-from-parent="q"
            @handle-search="search"
            @handle-custom-filter="openAdvancedFilters = true"
          >
          </SearchBar>
        </div>
      </div>
    </section>

    <div class="flex justify-center">
      <table v-if="!loading && !fetchError && table" class="m-4 p-2 w-full">
        <thead>
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
          >
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              class="py-4 pr-3 first:pl-2 text-left text-sm items-center text-gray-900"
              @click="handleClickHeader(header)"
            >
              <div class="flex items-center">
                {{ header.column.columnDef.header }}
                <button
                  v-if="selectedSortHeader === header.id"
                  class="ml-2 h-full w-fit duration-200"
                  :class="{ 'rotate-180': isDesc }"
                >
                  <ChevronDownIcon class="w-4 h-4" />
                </button>
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr
            v-for="row in table.getRowModel().rows"
            :key="row.id"
            class="hover:bg-blue-350"
          >
            <td
              v-for="cell in row.getVisibleCells()"
              :key="cell.column.id"
              class="py-8 text-sm first:pl-4 border-t-[1px] border-solid border-t-gray-200"
            >
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"
              />
            </td>
            <td class="py-8 border-t-[1px] border-solid border-t-gray-200">
              <MinimalActionsMenu
                :routes="[
                  {
                    name: `edit-profile`,
                    description: 'View/Edit Detail',
                    params: { profileId: row.original.oktaId },
                  },
                ]"
              ></MinimalActionsMenu>
            </td>
          </tr>
        </tbody>
      </table>
      <Spinner
        v-else-if="loading && !fetchError"
        class="relative h-65vh bg-blue-100"
      />
      <ErrorPage
        v-else-if="fetchError"
        class="relative h-65vh"
        :message="fetchError"
        disable-code
      />
    </div>
    <footer>
      <Pagination
        v-if="showPagination && !fetchError && isUserManagementEnabled"
        class="mb-14"
        :page="requestParams.page"
        :total-of-records="activeFilter.count"
        :records-per-page="requestParams.limit"
        @change-page="changePage"
      />
    </footer>
  </div>
  <ErrorPage v-else class="relative h-65vh" />
</template>
