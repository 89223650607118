<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';

export default {
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    },
  },
  computed: {
    searchIsLoading() {
      return this?.state?.instantSearchInstance?.status === "loading" ||
        this?.state?.instantSearchInstance?.status === "stalled"
    },
    searchIsError() {
      return this?.state?.instantSearchInstance?.status === "error";
    }
  }
};
</script>


<script setup>
import { applicationAlreadyExists } from "@/components/ExperiencePage/utils.js";
import NoResultsPage from "@/components/NoResultsPage.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import SessionCard from "../ResultCards/SessionCard";
import { useRouter } from "vue-router";
import { selectImageForProgram } from './utils'
import { vElementVisibility } from '@vueuse/components';
import ApplicationExistsModal from "@/components/ExperiencePage/ApplicationExistsModal.vue";
import { useStore } from "vuex";
import {inject, ref} from "vue";

const router = useRouter();
const store = useStore();

const programImageIndices = {};
const showApplicationTakenModal = ref(false);

const onApplyClicked = async ({program_page: programPage, session} = {}) => {
  const { studentApplications } = store.state;
  const exists = await applicationAlreadyExists({
    programSession: session,
    studentApplications: studentApplications,
    skipRequest: false,
  });
  showApplicationTakenModal.value = exists;
  if (!exists) {
    // store.commit("setCurrentOrderProgram", program);
    await router.push({
      name: "configurator-new",
      params: { programPageId: programPage?.id },
      query: {
        session: session?.id,
      },
    });
  }
};

const redirectToViewPage = ({program_page: programPage, session} = {}) => {
  router.push({
    name: "program-detail-view",
    params: { programPageId: programPage?.id },
    query: { "session-id": session?.id },
  });
};

</script>

<template>
  <div v-if="state">
    <ApplicationExistsModal
      :show-application-taken-modal="showApplicationTakenModal"
      @close="showApplicationTakenModal = false"
    />
    <div v-if="searchIsLoading && !state.hits.length > 0">
      <Spinner class="relative h-50-screen" />
    </div>
    <div
      v-if="state.hits.length > 0"
      class="exp-grid-container justify-start items-center lg:px-10 gap-10 lg:flex lg:flex-col flex-wrap pb-18"
    >
      <SessionCard
        v-for="hit in state.hits"
        :key="hit.objectID"
        class="programs-program-card lg:grid-cols-3"
        :program="hit"
        :horizontal="true"
        :image-url="selectImageForProgram(hit, programImageIndices)"
        event-category="Results Personalize CTA"
        @view-clicked="redirectToViewPage(hit)"
        @apply-clicked="onApplyClicked(hit)"
      />
      <div v-element-visibility="[visibilityChanged]" />
    </div>
    <Spinner v-if="searchIsLoading && state.hits.length > 0" outer-classes="" />
    <div v-else-if="!searchIsLoading && !state.hits.length > 0">
      <NoResultsPage margin-top="mt-5" />
    </div>
  </div>
</template>
