<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_144_2269"
      style="mask-type: alpha;"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_144_2269)"></g>
    <g clip-path="url(#clip0_144_2269)">
      <path
        d="M5.98 18.44V10.94H7.48V18.44H5.98ZM11.48 18.44V10.94H12.98V18.44H11.48ZM3 21.94V20.44H21.46V21.94H3ZM16.98 18.44V10.94H18.48V18.44H16.98ZM3 8.94V7.52L12.23 3L21.46 7.52V8.94H3ZM6.55 7.44H17.92L12.24 4.69L6.56 7.44H6.55Z"
        fill="#1E264C"
      />
    </g>
    <defs>
      <clipPath id="clip0_144_2269">
        <rect
          width="18.46"
          height="18.94"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
