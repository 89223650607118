<template>
  <VSelect
    id="advisorUniversity"
    v-model="advisorUniversity"
    :value="advisorUniversity"
    :options="universities"
    :get-option-label="(option) => option.label"
    :clearable="false"
    aria-label="Advisor University Name"
    class="filters university-select text-sm"
    placeholder="Enter Institution Name"
    @search="searchUniversity"
  />
</template>

<script>
import universitiesListApi from "@/mixins/universitiesListApi.js";
import { mapState } from "vuex";
import formService from "@/services/form";
import userService from "@/services/user";

export default {
  name: "UniversityChange",
  mixins: [universitiesListApi],
  props: {
    showCurrent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      advisorUniversity: null,
    };
  },
  computed: {
    ...mapState("university", ["userDetails"]),
  },
  watch: {
    userDetails: {
      handler: function (newval) {
        if (this.showCurrent && newval && newval.university)
          this.setAdvisorUniversity(newval);
      },
      immediate: true,
      deep: true,
    },
    advisorUniversity: {
      handler: function (newval) {
        if (newval && newval.value !== this.userDetails.universityId)
          this.changeUniversity(newval);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setAdvisorUniversity(user) {
      this.advisorUniversity = {
        value: user.universityId,
        label: user.university,
      };
    },
    changeUniversity(university) {
      const changeInstructions = [
        { op: "replace", path: "/data/university", value: university },
      ];
      formService
        .partiallyUpdateSubmission(
          "advisor",
          this.$store.state.currentUser?.formioAdvisorId,
          changeInstructions
        )
        .then(() => {
          userService
            .updateUserUniversity({ university: university })
            .then((response) => {
              //Update token on local storage and vuex
              localStorage.setItem("formioToken", response.token);
              this.$store.commit("setFormioToken");
              this.$store.dispatch("refreshUserDataFromServer").then(() => {
                this.getUniversity(university);
              });
            });
        })
        .catch((error) => console.log("error", error));
    },
    getUniversity(university) {
      fetch(
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "university/" +
          university.value,
        { headers: { Accept: "application/json" } }
      )
        .then((response) => response.json())
        .then((result) => {
          this.$store.commit("university/setAllUniversities", {
            object: result,
            selected: university,
          });

          location.reload();
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<style>
.university-select .vs__selected {
  color: #6a7181;
}
</style>
