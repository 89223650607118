<script setup>
import StickyFooter from "@/components/Configurator/StickyFooter.vue";
import SingleSelect from "@/components/forms/SharedComponents/DarkBlueInputs/SingleSelect.vue";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";
import {
  useGetEntitySupplement,
  useGetHostInstitutionLanguages,
  useGetSingleHostInstitution,
} from "@/components/program-manager/sessions/composable.js";
import {
  CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
  CLOUDINARY_VIDEO_EXTENSION_TYPES,
  CONTACT_HOURS,
  US_COLLEGE_STUDENT_ID,
  US_GRADUATE_STUDENT_ID,
} from "@/constants.js";
import { imageTransformation } from "@/util/cloudinary";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { computed, inject, nextTick, reactive, ref, watch } from "vue";

const { selectedCredit, setAdditionalCredit, setCredits } = inject("courses");
const { goToNextStep, goToPreviousStep } = inject("steps");
const { program } = inject("program");
const learnerTypesForInfoParagraph = [
  US_COLLEGE_STUDENT_ID,
  US_GRADUATE_STUDENT_ID,
];

const form = reactive({
  credits: null,
});

const rules = {
  credits: { required },
};
const v$ = useVuelidate(rules, form);

const hasAdditionalCredits = computed(() =>
  Boolean(
    program.programSession?.academic_additional_courses_allowed &&
      program.programSession?.academic_max_additional_credits
  )
);

const showMultipleCredits = computed(() =>
  Boolean(
    program?.programSession?.academic_min_credits !==
      program?.programSession?.academic_max_credits ||
      hasAdditionalCredits.value
  )
);

const credits = computed(() => {
  if (showMultipleCredits.value) {
    const start = program.programSession.academic_min_credits;
    let end = program.programSession.academic_max_credits;

    if (hasAdditionalCredits.value)
      end += program.programSession.academic_max_additional_credits;

    return [...Array(end - start + 1).keys()].map((x) => {
      const label =
        x + start <= program.programSession.academic_max_credits
          ? " - Included"
          : " - Additional Fee";

      return {
        label: x + start + label,
        value: x + start,
      };
    });
  } else {
    return [program.programSession.academic_min_credits];
  }
});

watch(
  () => form.credits,
  (newValue) => {
    if (newValue?.value) {
      setCredits(newValue.value);
      setAdditionalCredit(
        showMultipleCredits.value &&
          newValue.value > program.programSession.academic_max_credits
      );
    } else {
      setCredits(0);
      setAdditionalCredit(false);
    }
  },
  { deep: true }
);
const isExpanded = ref(false);
const isToggleVisible = ref(false);
const paragraphRef = ref(null);

const isClamped = computed(() => !isExpanded.value);

const toggleExpansion = () => {
  isExpanded.value = !isExpanded.value;
};
const academicDescription = computed(() => {
  return program.programSession.academic_description;
});
const hasAcademicDescription = computed(() => {
  const academicDescription =
    program?.programSession?.academic_description?.content;
  const result =
    academicDescription && Object.values(academicDescription)[0]?.content;
  return result;
});
const titleCopy = computed(() => {
  return showMultipleCredits.value
    ? "How many credits are you hoping to earn?"
    : program?.programSession?.academic_min_credits + " U.S. Semester Credits";
});
const descriptionCopy = computed(() => {
  return showMultipleCredits.value
    ? "Select the amount of U.S credits you are hoping to earn."
    : "No selection is required for this step.";
});

const setCredit = () => {
  form.credits = {
    label: program?.programSession?.academic_min_credits,
    value: program?.programSession?.academic_min_credits,
  };
  setCredits(program?.programSession?.academic_min_credits);
};
const loadPreviousCredit = () => {
  const creditOption = credits.value.find(
    (option) => option.value === selectedCredit.value
  );
  if (creditOption) {
    form.credits = creditOption;
  } else {
    form.credits = {
      label: selectedCredit.value,
      value: selectedCredit.value,
    };
  }
};

const isAcademicDescriptionClamped = (element) => {
  const descElement = element.querySelector("#json-to-html");
  if (descElement) {
    return descElement.scrollHeight > descElement.clientHeight;
  }
  return false;
};

const checkClamping = () => {
  nextTick(() => {
    const paragraph = paragraphRef.value?.$el ?? paragraphRef.value;
    if (paragraph) {
      isToggleVisible.value = isAcademicDescriptionClamped(paragraph);
    }
  });
};

watch(
  hasAcademicDescription,
  (newValue) => {
    if (newValue) {
      checkClamping();
      window.addEventListener("resize", checkClamping);
    }
  },
  { immediate: true }
);

watch(
  program,
  () => {
    if (!showMultipleCredits.value) setCredit();
  },
  { immediate: true }
);
watch(
  () => selectedCredit.value,
  (newVal) => {
    if (newVal && !form.credits) loadPreviousCredit();
  },
  { immediate: true }
);
const infoParagraphs = computed(() => {
  return [
    {
      title: "pre-requisites",
      description: program?.programSession?.prerequisites,
      visible: !!program?.programSession?.prerequisites,
    },
    {
      title: "Co-requisites",
      description: program?.programSession?.corequisities,
      visible: !!program?.programSession?.corequisities,
    },
    {
      title: "HOW TO REGISTER",
      description:
        "You will indicate which courses you want to take during the API onboarding process.",
      visible: true,
    },
    {
      title: "Financial Aid",
      description:
        "If you are paying for all or a portion of your experience with financial aid, keep in mind that there are requirements to enroll in, and maintain, a certain number of credits in order for the aid to be disbursed.  Check with your financial aid counselor to verify your enrollment requirements.  For undergraduates, full time is usually 12 credits and halftime is 6 credits.  Graduate requirements will vary.",
      visible: !!learnerTypesForInfoParagraph.includes(
        program.learnerType?.id
      ),
    },
  ];
});

const sessionHostingInstitution = computed(
  () => program.programSession?.session_hosting_institutions?.[0]?.entity_id
);

let hostInstitutionData;
let entitySupplementData;
let languageData;

watch(
  () => sessionHostingInstitution.value,
  () => {
    if (sessionHostingInstitution.value) {
      hostInstitutionData = useGetSingleHostInstitution(
        sessionHostingInstitution.value
      )?.state;
      entitySupplementData = useGetEntitySupplement(
        sessionHostingInstitution.value,
        true
      )?.state;
      languageData = useGetHostInstitutionLanguages(
        sessionHostingInstitution.value
      )?.state;
    }
  },
  { immediate: true }
);

const images = computed(
  () =>
    entitySupplementData.value?.items?.supporting_media
      ?.filter((item) =>
        CLOUDINARY_VIDEO_EXTENSION_TYPES.some(
          (type) => item && !item.endsWith(type)
        )
      )
      ?.map((img) =>
        imageTransformation({
          ...CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
          height: 358,
          width: 1160,
          path: img,
          compress: true,
        })
      ) || []
);
const creditType = computed(() => {
  const creditUnitName = hostInstitutionData.value?.items?.credit_unit?.name;
  return creditUnitName && CONTACT_HOURS.includes(creditUnitName.trim())
    ? "Contact Hours"
    : creditUnitName || "";
});
const accreditations = computed(() => {
  return hostInstitutionData.value?.items?.accreditation?.join(", ") || "";
});

const languages = computed(() => {
  return languageData.value?.count > 0
    ? `${languageData.value?.items?.map(({ value }) => value).join(", ")}`
    : "";
});
const showHostInstitutionFacts = computed(() => {
  return (
    program.programSession?.session_hosting_institutions[0]?.entity?.name &&
    (accreditations.value || languages.value || creditType.value)
  );
});
</script>
<template>
  <div class="bg-white font-montserrat h-full">
    <div
      class=" p-10 mt-13 bg-abstract-pattern"
      :class="[secondaryColor ? `bg-gray-275` : `bg-indigo-light bg-opacity-30`]"
    >
      <div class="text-indigo-base">
        <h1 data-testid="courses-header" class="font-bold text-2.5xl">
          {{ titleCopy }}
        </h1>
        <p data-testid="credit-description" class="font-medium">
          {{ descriptionCopy }}
        </p>
        <SingleSelect
          v-if="showMultipleCredits"
          id="credits"
          v-model="form.credits"
          :vuelidate-field="v$.credits"
          :options="credits"
          :search-method="true"
          placeholder="Select your desired amount of credits"
          class="mt-8"
        />
      </div>
    </div>
    <div
      data-testid="what-to-know-section"
      class="bg-white p-10 text-indigo-base"
    >
      <!-- What You Should Know section -->
      <h1 class="font-bold text-2.5xl">What You Should Know</h1>
      <div v-for="(bullet, index) in infoParagraphs" :key="index">
        <h2
          v-if="bullet.visible"
          class="font-bold text-base uppercase leading-normal mt-6"
        >
          {{ bullet.title }}
        </h2>
        <p
          v-if="bullet.visible"
          class="text-sm font-medium leading-snug text-left mt-2"
        >
          {{ bullet.description }}
        </p>
      </div>
      <div v-if="hasAcademicDescription" data-testid="academic-section">
        <h2 class="font-bold text-base uppercase leading-normal mt-6">
          ACADEMIC INFORMATION
        </h2>
        <JSONtoHTML
          ref="paragraphRef"
          data-testid="academic-description"
          :tiptap-json="academicDescription"
          :customClass="[
            'text-sm font-medium leading-snug text-left mt-2',
            isClamped ? 'line-clamp-5' : '',
          ]"
        >
          <div v-if="isToggleVisible" class="flex justify-start mt-2">
            <button
              data-testid="toggle-expansion"
              type="button"
              @click="toggleExpansion"
              :class="`font-bold text-base text-left mt-2 ${secondaryColorClass}`"
              :aria-expanded="isExpanded"
            >
              {{ isExpanded ? "- less" : "+ more" }}
            </button>
          </div>
        </JSONtoHTML>
      </div>
      <div v-if="showHostInstitutionFacts" data-testid="host-institution-facts">
        <h1 class="mt-20 font-bold text-indigo-base text-2.5xl mb-6">
          Facts About
          {{
            program.programSession?.session_hosting_institutions[0]?.entity
              ?.name
          }}
        </h1>
        <div
          v-if="images.length > 0"
          class="w-full h-fit pb-10 mx-auto md:pr-[104px]"
        >
          <img
            :src="images?.[0]"
            loading="lazy"
            alt=""
            class="w-full max-w[405px] max-h-[125px] md:max-w-[1160px] md:max-h-[358px]"
          />
        </div>
        <h2
          v-if="creditType"
          class="font-montserrat text-indigo-base text-lg font-bold not-italic leading-6 capitalize mb-4"
        >
          Credit Type :
          <span class="leading-8 font-medium" data-testid="credit-type">{{
            creditType
          }}</span>
        </h2>
        <h2
          v-if="accreditations"
          class="font-montserrat text-indigo-base text-lg font-bold not-italic leading-6 capitalize mb-4"
        >
          Accreditation :
          <span class="leading-8 font-medium">{{ accreditations }}</span>
        </h2>
      </div>
    </div>
    <StickyFooter
      max-next-width="max-w-[260px]"
      :disabled-next="v$.$invalid"
      :show-required-section="false"
      @back="goToPreviousStep"
      @next="goToNextStep"
    />
  </div>
</template>
