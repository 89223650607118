<template>
  <FormPanel
    :id="'payments-panel'"
    :title="'Payments'"
    :initially-expanded="true"
    :remove-default-margins="true"
    :remove-default-paddings="true"
    main-panel-class="bg-white sm:mt-4 sm:rounded-lg sm:shadow-md"
    panel-content-class=""
    panel-button-text-class="text-lg md:text-xl capitalize xs:tracking-normal"
    main-header-class="text-lg md:text-xl capitalize pt-4 pb-2 px-8 border-none"
  >
    <template #panelHeaderCheckbox>
      <PaymentsHeader
        ref="paymentsHeader"
        :disabled-payment-button="disabledPaymentButton"
        @openRequestModal="openRequestModal"
        @goToPaymentPage="goToPaymentPage"
      />
    </template>
    <!-- remove this -->
    <template #panelHeaderItem>
      <div
        :class="
          pendingPaymentsEnabled && isPendingPayment ? 'mt-4' : 'md:order-last'
        "
      >
        <BaseCheckBox
          v-if="hasPayment"
          label="Select All"
          name="selectAll"
          :checked="allSelected"
          container-class="-ml-4 sm:ml-px"
          data-cy="selectAllCheckBox"
          @input="onFieldChange('allSelected', $event, true)"
        />
      </div>

      <div
        v-if="pendingPaymentsEnabled && isPendingPayment"
        class="mt-4 pending-basis text-right order-last"
      >
        <p
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
          ]"
          class="text-sm font-semibold hover:underline focus:underline cursor-pointer"
          @click="toggleModal"
        >
          View Pending Payments
        </p>
      </div>
    </template>
    <template #content>
      <div class="bg-white sm:mt-4 sm:rounded-lg">
        <PaymentCard
          v-for="(payment, index) in sortPaymentCards"
          :key="index"
          :payment="payment"
          :display-to-do="displayToDo"
          :remaining-balance="
            pendingPayments[payment.line_item_id]
              ? pendingPayments[payment.line_item_id].remainingBalance
              : 0
          "
          :show-remaining-balance="!!pendingPayments[payment.line_item_id]"
          @handlePayment="handlePayment"
        />

        <FinancialAidCard
          v-if="showFinancialAid"
          :display-to-do="displayToDo"
        />
      </div>
      <PaymentRequest
        ref="paymentRequest"
        :items="cleanPayments"
        @reset="reset"
      />

      <Modal
        :open="openModal"
        :title="'Pending Payments'"
        :show-logo="false"
        :footer-class="'mt-24 text-center'"
        @closeModal="toggleModal"
      >
        <template #modal-content>
          <PendingPayments :pending-payments="pendingPayments" />
        </template>
        <template #modal-footer>
          <button
            :class="[
              secondaryColor
                ? 'brandingSecondary'
                : 'bg-university-secondary hover:bg-teal-100 focus:bg-teal-100',
            ]"
            class="block w-full md:inline-block md:w-30 text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white hover:text-white focus:text-white focus:outline-none"
            @click="toggleModal"
          >
            <span>Close</span>
          </button>
        </template>
      </Modal>
    </template>
  </FormPanel>
</template>

<script>
import FinancialAidCard from "@/components/financialAid/FinancialAidCard.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import Modal from "@/components/modals/Modal.vue";
import PaymentCard from "@/components/paymentCards/PaymentsCard.vue";
import PaymentsHeader from "@/components/paymentCards/PaymentsHeader.vue";
import PaymentRequest from "@/components/paymentCards/PaymentRequest.vue";
import PendingPayments from "@/components/paymentCards/PendingPayments.vue";
import pendingPaymentsMixin from "@/mixins/pendingPayments.js";
import { eventBus } from "@/app";
import { mapState } from "vuex";
import { toFixed } from "@/mixins/helpers";
import BaseCheckBox from "@/components/forms/SharedComponents/BaseCheckBox";
import formInputsMixin from "@/mixins/formInputs.js";

export default {
  name: "PaymentSection",
  components: {
    FormPanel,
    FinancialAidCard,
    Modal,
    PaymentCard,
    PaymentsHeader,
    PaymentRequest,
    PendingPayments,
    BaseCheckBox,
  },
  mixins: [pendingPaymentsMixin, formInputsMixin],
  props: {
    sortPaymentCards: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
    displayToDo: {
      type: Boolean,
      default: false,
    },
    showFinancialAid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allSelected: false,
      selectedPayments: [],
      requestModal: false,
      disabledPaymentButton: true,
      openModal: false,
    };
  },

  computed: {
    ...mapState(["featureFlags"]),
    hasPayment() {
      return this.sortPaymentCards?.some((payment) => {
        if (
          Object.prototype.hasOwnProperty.call(
            this.pendingPayments,
            payment.line_item_id
          ) &&
          this.isPendingPayment
        ) {
          return (
            this.pendingPayments[payment.line_item_id].remainingBalance > 0
          );
        } else {
          return payment.balance > 0;
        }
      });
    },
    cleanPayments() {
      return this.selectedPayments.filter(
        (payment) => Number(payment.balance) > 0
      );
    },
    pendingPaymentsEnabled() {
      return this.featureFlags["pending-payments"];
    },
  },
  watch: {
    allSelected: {
      handler: function (val) {
        eventBus.$emit("selectPayment", val);
      },
    },
    selectedPayments: {
      handler: function (val) {
        this.disabledPaymentButton = !val.length;

        if (val.length === 0 && this.allSelected) this.allSelected = false;
      },
      deep: true,
    },
    sortPaymentCards: {
      handler: function (val) {
        if (this.pendingPaymentsEnabled) {
          this.setPaymentsBalance(val);
          this.getPendingPayments();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    reset() {
      this.selectedPayments = [];
      this.$refs.paymentsHeader.toggleMenu();
    },
    handlePayment(data) {
      const index = this.selectedPayments.findIndex(
        (payment) => payment.id === data.payment.id
      );
      if (data.selected) {
        if (index === -1) {
          //Copy object to update balance if remainingBalance exist
          let newPayment = Object.assign({}, data.payment);
          const remaininBalance = this.pendingPayments[newPayment.line_item_id]
            ? this.pendingPayments[newPayment.line_item_id].remainingBalance
            : 0;
          if (remaininBalance) {
            newPayment.balance = toFixed(remaininBalance / 100);
          }
          this.selectedPayments.push(newPayment);
        }
      } else {
        if (index !== -1) this.selectedPayments.splice(index, 1);
      }
    },
    openRequestModal() {
      if (this.cleanPayments.length > 0) {
        this.$refs.paymentRequest.openRequestPayment();
      }
    },
    goToPaymentPage() {
      if (this.cleanPayments.length > 0) {
        this.$store.commit("setItemsToPay", this.cleanPayments);
        this.$router.push({
          name: "payment",
          params: { applicationId: this.$route.params.applicationId },
        });
      }
    },
    toggleModal() {
      this.openModal = !this.openModal;
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .pending-basis {
    flex-basis: 100%;
  }
}
</style>
