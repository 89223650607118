<template>
  <div class="flex items-center">
    <div class="h-full">
      <button
        class="mr-1 bg-gray-300"
        :disabled="!currentIndex"
        @click="prevImage"
      >
        <ChevronLeftIcon class="h-5 w-5 text-gray-600" />
      </button>
    </div>
    <div class="flex justify-center items-center">
      <div
        v-for="(image, index) in images"
        :key="index"
        :class="{ hidden: index !== currentIndex }"
        class="flex justify-center max-h-20"
      >
        <img :src="image" class="rounded-sm object-cover max-w-32 max-h-18" />
      </div>
    </div>

    <div class="relative">
      <button
        class="ml-1 self-center bg-gray-300"
        :disabled="currentIndex + 1 === images.length"
        @click="nextImage"
      >
        <ChevronRightIcon class="h-5 w-5 text-gray-600" />
      </button>
      <p class="absolute text-sm ml-1 mt-2">
        {{ currentIndex + 1 }}/{{ images.length }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { ref, defineProps } from "vue";

const props = defineProps({
  images: {
    type: Array,
    default: () => [],
  },
});

const { images } = props;

const currentIndex = ref(0);

const nextImage = () => {
  currentIndex.value += 1;
};

const prevImage = () => {
  currentIndex.value -= 1;
};
</script>
