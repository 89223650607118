import { formatter } from "@/components/program-manager/sessions/utils.js";

const OptionPricing = {
  productId: "",
  productName: "",
  totalPriceInCents: 0.0,
  institutionTotalPriceInCents: 0.0,
  studentTotalPriceInCents: 0.0,
  studentPriceDifferenceFromDefault: 0.0,
  origTotalPriceInCents: 0.0,
  quantity: 1,
  isIncluded: function () {
    return this.studentPriceDifferenceFromDefault <= 0;
  },
  optionPriceLabel: function (isIncludedOverride = false) {
    if (this.isIncluded() || isIncludedOverride) {
      return "Included";
    }
    const studentPrice = this.studentPriceDifferenceFromDefault <= 0 ? 0 : this.studentPriceDifferenceFromDefault;
    return `+ ${formatter.format(studentPrice / 100)}`
  },
  optionMapper: function(optionObject) {
    if (optionObject) {
      this.productId =  optionObject.product_id;
      this.productName = optionObject.product_name;
      this.totalPriceInCents = optionObject.total_price_in_cents;
      this.institutionTotalPriceInCents = optionObject.institution_total_price_in_cents;
      this.studentTotalPriceInCents = optionObject.student_total_price_in_cents;
      this.studentPriceDifferenceFromDefault = optionObject.student_price_difference_from_default;
      this.origTotalPriceInCents = optionObject.orig_total_price_in_cents;
      this.quantity = optionObject.quantity;
    }
    return this;
  },
  isCheaper: function(otherRoom = RoomPrice) {
    if (this.studentTotalPriceInCents !== otherRoom.studentTotalPriceInCents) {
      return this.studentTotalPriceInCents - otherRoom.studentTotalPriceInCents;
    } else if (this.totalPriceInCents !== otherRoom.totalPriceInCents) {
      return this.totalPriceInCents - otherRoom.totalPriceInCents;
    }
    return (this.productName.toLowerCase() < otherRoom.productName.toLowerCase()) ? -1 : 1;
  }
}

export const RoomPrice = {
  ... OptionPricing,
  roomPriceLabel: function(isIncludedOverride = false) {
    return this.optionPriceLabel(isIncludedOverride);
  }
}

export const ExcursionPrice = {
  ...OptionPricing,
  excursionPriceLabel: function(isIncludedOverride = false) {
    return this.optionPriceLabel(isIncludedOverride);
  }
}

/**
 *
 * @param {{}} unit
 * @param {[RoomPrice]} rooms
 * @returns
 */
export const getRoomPriceLabelFromList = (
  unit,
  rooms = []
) => {
  const unitRooms = unit?.rooms?.filter((room) => room?.status === "Active") || [];
  const unitRoomPrices = rooms.filter((room) => unitRooms.find((unitRoom) => unitRoom.id === room.productId));

  let priceLabel = "";
  if (unitRoomPrices.every((room) => room.studentPriceDifferenceFromDefault <= 0)) {
    // All rooms are below the default price
    priceLabel = "Included";
  } else if (unitRoomPrices.every((room) => room.studentPriceDifferenceFromDefault > 0)) {
    // All rooms need to be paid - Display cheapest available
    priceLabel = `From ${unitRoomPrices[0].roomPriceLabel()}`;
  } else {
    // A room is included but others are not - Display cheapest available
    const cheapestNonZeroPrice = unitRoomPrices.filter(
      (room) => room.studentPriceDifferenceFromDefault > 0
    )[0];
    priceLabel = `Included or from ${cheapestNonZeroPrice.roomPriceLabel()}`;
  }
  return priceLabel;
}
