<script setup>
/*
  Component for receiving and processing data injected into the Vue app directly from the server;
  contents of `props` are supplied by blade template
 */
import { useStore } from "vuex";
import { computed, onBeforeMount } from "vue";
import jwtDecode from "jwt-decode";

const store = useStore();

const props = defineProps({
  currentUser: {
    type: String,
    default: null,
  },
  learnerTypes: {
    type: Array,
    default: () => [],
  },
  oktaToken: {
    type: String,
    default: null,
  },
  brandingDetails: {
    type: Object,
    default: () => ({}),
  },
  domainEntityId: {
    type: String,
    default: null,
  },
});

// This query parameter will allow us to bypass laravel authentication
// on the fronted when we do playwright test
const testMode = computed(() => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("test-mode") === "true";
});

onBeforeMount(() => {
  if (!testMode.value) {
    const currentUser = JSON.parse(props.currentUser);
    store.commit("setCurrentUser", currentUser);

    if (props.oktaToken) {
      // Decode the token and save only entity_id
      const decodedToken = jwtDecode(props.oktaToken);
      let entityId = decodedToken.entity_ids;
      if (Array.isArray(entityId) && entityId.length > 0) {
        const entityObjects = entityId.map((id) => ({ id }));
        store.commit("setEntityIDs", entityObjects);
      } else {
        store.commit("setEntityIDs", []);
        console.warn("entity_id not found in token");
      }
    }
  }
  store.commit("setDomainEntityId", props.domainEntityId);

  store.dispatch("learnerTypes/initialize", props.learnerTypes);

  store.dispatch("updateBrandingStyles", props.brandingDetails);
});
</script>

<template><meta /></template>
