import { learningServiceRequest } from "./utils";

const productPriceService = {
  async getList(params) {
    return await learningServiceRequest({
      method: "get",
      url: "/product_prices",
      params,
      failureMessage: "Error loading product prices data",
    });
  },
  async getProductPrice(product_id = "") {
    return await learningServiceRequest({
      method: "get",
      url: "/product_prices",
      params: {
        product_id,
      },
      failureMessage: "Error loading product price data",
    });
  },
  async updateProductPrice(productPriceId, payload) {
    return await learningServiceRequest({
      method: "put",
      url: `/product_prices/${productPriceId}`,
      payload: payload,
      failureMessage: "Error updating product price data",
    });
  },
  async createProductPrice(payload) {
    return await learningServiceRequest({
      method: "post",
      url: `/product_prices/`,
      payload: payload,
      failureMessage: "Error creating product price data",
    });
  },
};

export default productPriceService;
