<script setup>
import RequiredToSavePublish from "@/components/program-manager/housing/components/RequiredToSavePublish";
import { ref, computed, defineEmits, defineProps } from "vue";
import { useVModel } from "@vueuse/core";
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import WYSIWYG from "@/components/forms/SharedComponents/WYSIWYG/WYSIWYG.vue";
import IncludesSection from "./Includes/IncludesSection.vue";
import PackingListSection from "./PackingList/PackingListSection.vue";

const noDesignatedHousing = ref(null);

const activity = ["Low", "Moderate", "Vigorous"];

const props = defineProps({
  modelValue: {
    type: Object,
  },
  isPreview: {
    type: Boolean,
  },
});

const emit = defineEmits(["update:modelValue", "validator"]);
const events = useVModel(props, "modelValue", emit);

const validateTab = async () => {
  return v$.value.$validate().then(() => {
    return !v$.value.$invalid;
  });
};

emit("validator", validateTab);

const updateItineraryContent = (val) => {
  events.value.itinerary = val;
};
const updateAccomodationContent = (val) => {
  events.value.accommodation_description = val;
};

const rules = computed(() => ({
  events: {
    activity_level: {
      required: helpers.withMessage(
        "Activity level is required to Publish",
        requiredIf(function () {
          return (
            events.value.status === "Active" && !events.value.activity_level
          );
        })
      ),
    },
  },
}));

const v$ = useVuelidate(rules, events, {
  $registerAs: "EventDetailsTab",
  $lazy: true,
});
</script>

<template>
  <div class="p-6 sm:p-6">
    <div v-if="!isPreview" class="flex justify-between items-start flex-wrap">
      <h1 class="text-xl font-semibold leading-6 text-gray-900">
        Event Details
      </h1>
      <RequiredToSavePublish />
    </div>

    <div>
      <IncludesSection :model-value="events" />
    </div>

    <div class="mb-4">
      <PackingListSection :model-value="events" />
    </div>
    <div class="mb-4">
      <label class="mb-2 block text-sm font-semibold text-gray-700"
        >Itinerary</label
      >
      <WYSIWYG
        :content="events.itinerary"
        :resizable="true"
        @update-content="
          ($eventPayload) => updateItineraryContent($eventPayload)
        "
      />
    </div>

    <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3">
      <div class="mb-4">
        <label class="block text-sm font-semibold text-gray-700"
          ><span class="text-red-100 pr-2 align-sub">**</span>Activity
          Level</label
        >
        <v-select
          v-model="events.activity_level"
          :options="activity"
          label="activity"
          class="md:max-w-[300px]"
          :class="[
            {
              'bg-error-100': v$.events.activity_level.$errors?.length,
            },
          ]"
          @blur="v$.events.activity_level.$touch"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes"
              ><i class="fa fa-caret-down text-gray-500" v-bind="attributes"></i
            ></span>
          </template>
        </v-select>
        <div
          v-if="v$.events.activity_level.$errors?.length"
          class="block text-sm error text-error-900"
        >
          <span
            v-for="(error, idx) in v$.events.activity_level.$errors"
            :key="idx"
          >
            {{ error.$message }}
          </span>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="mb-4 flex">
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="noDesignatedHousing"
            v-model="noDesignatedHousing"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
          ></label>
        </div>
        <label class="ml-3 block text-sm text-gray-700"
          >No designated housing
        </label>
      </div>
      <div v-show="!noDesignatedHousing">
        <div class="mb-4">
          <label
            name="availableHousing"
            class="block text-sm font-semibold text-gray-700"
            >What type of housing is available?</label
          >
          <div class="flex">
            <input
              v-model="events.accommodation"
              id="availableHousing"
              type="text"
              name="availableHousing"
              class="h-10 w-full form-input"
              maxlength="255"
            />
          </div>
        </div>
        <label class="mb-2 block text-sm font-semibold text-gray-700"
          >Housing description</label
        >
        <WYSIWYG
          :content="events.accommodation_description"
          :resizable="true"
          @update-content="
            ($eventPayload) => updateAccomodationContent($eventPayload)
          "
        />
      </div>
    </div>
  </div>
</template>
