<script setup>
import { defineProps, defineEmits, toRefs, ref, watch } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { AisConfigure } from "vue-instantsearch/vue3/es";
import { debounce } from "lodash";

const props = defineProps({
  addBottomMargin: { type: Boolean, required: false, default: false },
  id: { type: String, required: false, default: "" },
  inputClass: {
    type: String,
    required: false,
    default:
      "mr-1 w-full md:max-w-260p max-h-12 h-12 bg-white pl-5 pb-6 pt-6 font-bold uppercase text-indigo-base focus:text-indigo-base placeholder-indigo-base outline-none focus:ring-0",
  },
  label: { type: String, required: false, default: "" },
  noIcon: { type: Boolean, required: false, default: false },
  type: { type: String, required: false, default: "search" },
  keywordValue: { type: String, required: false },
});
const { addBottomMargin, id, inputClass, label, noIcon, type } = toRefs(props);

const emits = defineEmits(["update:keywordValue"]);
const internalValue = ref(undefined);
const query = ref(undefined);

const onSearchClicked = () => {
  query.value = internalValue.value;
  emits("update:keywordValue", internalValue.value);
};

const onInputUpdated = ($event) => {
  internalValue.value = $event.currentTarget.value;
  if(noIcon.value) {
    emits("update:keywordValue", internalValue.value);
  }
};

watch(
  () => props.keywordValue,
  (newKeyword, oldKeyword) => {
    if (newKeyword !== oldKeyword && newKeyword !== internalValue.value) {
      internalValue.value = newKeyword;
      query.value = newKeyword;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div :class="['relative', 'mt-2', 'max-w-351p', { 'mb-6': addBottomMargin }]">
    <ais-configure :query="query" />
    <input
      :id="id"
      :placeholder="label"
      :class="inputClass"
      :type="type"
      :value="internalValue"
      @input="onInputUpdated"
      @keyup.enter="onSearchClicked"
    />
    <button
      v-if="!noIcon"
      :aria-label="'Search ' + label"
      class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer bg-white"
    >
      <slot name="icon">
        <MagnifyingGlassIcon
          @click="onSearchClicked"
          class="h-6 w-6 font-bold cursor-pointer text-university-secondary"
          aria-hidden
        />
      </slot>
    </button>
  </div>
</template>
