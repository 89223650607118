<template>
  <section>
    <div class="grid grid-cols-1 gap-y-7 pt-4">
      <p class="font-medium text-xs">
        Fields marked with (*) are required.
      </p>

      <!-- Total Due -->
      <div>
        <h1 class="font-bold text-[28px] uppercase">
          Total Due
        </h1>
        <p v-if="APPLICATION_FEE_DATA.flagActive">
          Your non-refundable application fee is due now.
        </p>
        <p v-else class="font-medium text-xs">
          Your non-refundable application deposit of $150 will be applied toward
          your total program cost.
        </p>
        <p v-if="isCubaProgram" class="text-xs text-gray-600 font-bold">
          Please call 1-800-844-4124 to make your payment.
        </p>
      </div>

      <!-- Total -->
      <div class="grid grid-cols-2 gap-y-7">
        <div
          v-if="validDiscountCode"
          class="col-span-2 grid grid-cols-2 gap-y-2"
        >
          <p class="text-lg font-normal">
            {{ usePascalCase(APPLICATION_FEE_DATA.typeLabel) }} Amount
          </p>
          <p class="text-lg font-semibold text-right">
            ${{ APPLICATION_FEE_DATA.amount }}
          </p>
          <p class="text-lg font-normal">Promo Code Discount</p>
          <p class="text-lg font-semibold text-right">
            -${{ APPLICATION_FEE_DATA.amount }}
          </p>
        </div>
        <div>
          <h1 class="font-bold text-[28px] uppercase">
            {{ isReadOnly ? "Total Paid" : "Total" }}
          </h1>
          <p
            v-if="!showDiscountButton && !isReadOnly"
            class="font-bold text-xs text-teal-900 uppercase underline"
            @click="showDiscountButton = true"
          >
            + Add promo code
          </p>
        </div>
        <SkeletonLoader
          v-if="showApplicationFeeLoader"
          rounded
          style="width: 30%; justify-self: end;"
        />
        <h1
          v-else
          data-testid="total-amount"
          class="font-bold text-[28px] uppercase text-teal-900 text-right"
        >
          ${{ paymentFee }}
        </h1>
        <!-- Promotional Code -->
        <InputWButton
          v-if="showDiscountButton"
          id="promoCode"
          ref="inputwbutton"
          v-model.trim="promoCode"
          class="col-span-2"
          label="Promo Code"
          placeholder="Enter promo code"
          :vuelidate-field="v$.promoCode"
          :disable-button="validDiscountCode"
          type="text"
          @button-clicked="applyPromoCode"
        />
        <p
          v-if="isCubaProgram"
          class="text-xs col-span-2 text-gray-600 font-bold"
        >
          Please call 1-800-844-4124 to make your payment and you will be given
          a promotion code to submit your application deposit.
        </p>
        <p
          v-if="promoMessage"
          class="col-span-2"
          :class="
            promoMessage && !validDiscountCode
              ? 'error text-error-900'
              : 'success text-success-900'
          "
        >
          {{ promoMessage }}
        </p>
      </div>

      <hr class="mt-4 mb-4" />
      <template v-if="!showApplicationFeeLoader">
        <!-- Billing Information -->
        <h1 class="font-bold text-[28px] uppercase">
          Billing Information
        </h1>

        <!-- Same as legal name-->
        <CheckBox
          id="sameLegalName"
          v-model.trim="v$.sameLegalName.$model"
          :vuelidate-field="v$.sameLegalName"
          :is-disabled="isReadOnly"
        >
          <template #label>
            Name is same as legal name.
          </template>
        </CheckBox>

        <!-- First name -->
        <TextField
          id="firstname"
          v-model.trim="legalFirstName"
          label="First Name*"
          placeholder="Enter Your First Name"
          :vuelidate-field="v$.legalFirstName"
          :disabled="sameLegalName || isReadOnly"
          :show-errors="true"
        />

        <!-- Last name -->
        <TextField
          id="lastname"
          v-model.trim="legalLastName"
          label="Last Name*"
          placeholder="Enter Your Last Name"
          :vuelidate-field="v$.legalLastName"
          :disabled="sameLegalName"
          :show-errors="true"
        />

        <!-- Billing Address -->
        <h1 class="font-semibold text-[18px]">
          Billing address is the same as:
        </h1>
        <RadioButtons
          v-model="precheckRadioOption"
          :options="addressOptions"
          :group-name="'address'"
          :disable-group="isReadOnly"
          @option-choosed="preSelectAddress"
        />

        <!-- Billing Address (Autocomplete)-->
        <AddressComponent
          v-if="
            showAutoComplete && !addressController.billingAddress.showManualForm
          "
          id="billingAddress"
          label="Billing Address*"
          template-name="v3"
          :vuelidate-field="v$.billingAddress"
          :initial-address-info="addressProp"
          :disable-input="blockAutoCompleteInput"
          @update-address-info="updateBillingAddress"
        />

        <SkeletonLoader v-if="showSkeletonLoader && isReadOnly" rounded />
        <!-- User will input his own billing address -->
        <ManualAddressForm
          v-if="
            addressController.billingAddress.showManualForm &&
            !showSkeletonLoader
          "
          :initial-countries="initialCountries"
          :parent-object-label="'Billing Address'"
          :show-error="addressController.billingAddress.shownDueToError"
          :initial-address="billingAddress.address"
          :initial-postal-code="billingAddress.postCode"
          :initial-city="billingAddress.city"
          :initial-state="billingAddress.state"
          :initial-country-id="billingAddress.country"
          :initial-country-label="billingAddress.countryName"
          :disabled="isReadOnly"
          @update-address-object="updateAddressManually"
        />
      </template>

      <!-- Stripe form component -->
      <StripePayDepositForm
        v-if="
          !isReadOnly &&
          stripePayDepositFormEnabled &&
          !hideCardInformation &&
          !paymentAlreadyExist &&
          !isCubaProgram
        "
        ref="stripePayDepositForm"
      />

      <!-- Stripe Errors -->
      <div
        v-if="
          stripePayDepositFormEnabled &&
          !hideCardInformation &&
          errorStripePayment
        "
        class="text-error-900"
      >
        <p>{{ errorStripePayment }}</p>
      </div>
      <!-- END Stripe Errors -->

      <!-- Stripe status message -->
      <div
        v-if="
          stripePayDepositFormEnabled &&
          !hideCardInformation &&
          stripeStatus &&
          !errorStripePayment
        "
        class="text-teal-900"
      >
        <p>{{ stripeStatus }}</p>
      </div>
      <!-- END Stripe status message -->

      <!-- Cancellation Policy -->
      <CheckBox
        v-if="!isCustomizedSession"
        id="cancellationPolicy"
        v-model.trim="v$.cancellationPolicyCheckbox.policy.$model"
        :vuelidate-field="v$.cancellationPolicyCheckbox.policy"
        :is-disabled="isReadOnly"
      >
        <template #label>
          I agree to the
          <a
            class="text-teal-700 underline"
            href="https://apiabroad.com/withdrawal-policy-safeguards/"
            target="_blank"
            >Withdrawal Policy.</a
          >*
        </template>
      </CheckBox>

      <ButtonWithSpinner
        id="paymentSubmitButton"
        ref="paymentSubmitButton"
        data-cy="paymentSubmitButton"
        type="submit"
        variant="secondary"
        variant-type="block"
        button-height="min-h-[58px] md:min-h-[60px] mt-4"
        :grey-disabled-class="true"
        :disabled="disableSubmit"
        @click="submitPayDeposit"
      >
        <div class="flex items-center justify-center">
          <span class="pr-3 uppercase">
            Submit Application
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
    </div>
  </section>
</template>

<script>
import {
  helpers,
  required,
  maxLength,
  requiredIf,
} from "@vuelidate/validators";
import { mapState } from "vuex";
import { APICompany, APPLICATION_FEE_DATA } from "@/constants";
import { parse, format } from "date-fns";
import FormValidation from "@/mixins/formValidation";
import CheckBox from "@/components/forms/SharedComponents/DarkBlueInputs/Checkbox.vue";
import TextField from "@/components/forms/SharedComponents/DarkBlueInputs/TextField.vue";
import InputWButton from "@/components/forms/SharedComponents/DarkBlueInputs/InputWithButton.vue";
import RadioButtons from "@/components/forms/SharedComponents/DarkBlueInputs/RadioButtons.vue";
import AddressComponent from "@/components/forms/AddressInputComponent/AddressComponent.vue";
import ManualAddressForm from "@/components/ExperiencePage/Misc/ManualAddressForm.vue";
import SkeletonLoader from "@/components/shared/loaders/BaseSkeletonLoader.vue";
import ProgramChangeMix from "@/mixins/programChange";
import formIoApi from "@/mixins/formIoApi";
import StripeChargeMixin from "@/mixins/StripeCharge";
import paymentCharge from "@/mixins/paymentCharge";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import StripePayDepositForm from "@/components/forms/PayDeposit/StripePayDepositForm.vue";
import * as Sentry from "@sentry/vue";
import { getApiClient } from "@/services/API";
import AdvisorNotifications from "@/mixins/AdvisorNotifications.js";
import useVuelidate from "@vuelidate/core";
import countriesService from "@/services/countries.js";
import profileService from "@/services/profile.js";
import applicationService from "@/services/application.js";
import { channelTypes } from "@/components/program-manager/sessions/constants";
import ordersService from "@/services/orders";
import paymentService from "@/services/payment";
import { trackPurchase, getCategoryItemArray } from "@/util/eventTracker.js";
import { toRaw } from "vue";
import { usePascalCase } from "../../../../composables/stringManupulation";
import { usePastDeadline } from "@/composables/usePastDeadline.js";

const requiredErrorCopy = "This field is required.";
const policyRequiredIf = (value, siblings, vm) =>
  vm.isCustomizedSession || value;

const requiredAddressFields = ["city", "state", "country", "postCode"];
export default {
  components: {
    InputWButton,
    CheckBox,
    TextField,
    RadioButtons,
    AddressComponent,
    ButtonWithSpinner,
    ArrowRight,
    StripePayDepositForm,
    ManualAddressForm,
    SkeletonLoader,
  },
  mixins: [
    paymentCharge,
    FormValidation,
    ProgramChangeMix,
    formIoApi,
    StripeChargeMixin,
    AdvisorNotifications,
  ],
  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    existingPaymentData: {
      type: Object,
      default() {
        return {};
      },
    },
    initialCountries: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  emits: ["update-order", "next-step"],
  setup() {
    const {
      disabledContinueButton,
      isPastDeadline,
      validateDeadline,
    } = usePastDeadline();

    // We need to include APPLICATION_FEE_DATA to be able to use in ui
    return {
      v$: useVuelidate(),
      APPLICATION_FEE_DATA,
      disabledContinueButton,
      isPastDeadline,
      validateDeadline,
    };
  },
  data() {
    return {
      addressController: {
        billingAddress: {
          showManualForm: null, // hides autocomplete input then allows to capture address manually (if true)
          shownDueToError: false, // displays message that address couldn't be validated (if true)
        },
      },
      showDiscountButton: false,
      formIoForm: "paymentform",
      sectionsToValidate: [
        "cardInformation",
        "billingAddress",
        "currentAddress",
        "permanentAddress",
        "cancellationPolicyCheckbox",
        "promoCode",
      ],
      hideCardInformation: false,
      validDiscountCode: false,
      cardInformation: {},
      cancellationPolicyCheckbox: {
        policy: false,
      },
      creditTransactionId: "",
      transactionId: "",
      expandPromoCode: false,
      promoCode: "",
      promoMessage: null,
      legalFirstName: "",
      legalLastName: "",
      sameLegalName: false,
      addressProp: {},
      addressOptions: [
        { label: "Current Address", value: "currentAddress" },
        { label: "Permanent Address", value: "permanentAddress" },
        { label: "Other", value: "other" },
      ],
      cacheCountries: [],
      sameAsAddressChoosen: "",
      billingAddressInitial: null,
      billingAddress: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        country: "", // id / iso
        countryName: "",
        postCode: "",
      },
      permanentAddress: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        country: "United States of America",
        countryName: "",
        postCode: "",
      },
      currentAddress: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        country: "United States of America",
        countryName: "",
        postCode: "",
      },
      showAutoComplete: false,
      preSelectedAddressRadioButton: "",
      pricingData: null,
      stripeRendered: false, // flag to prevent re-rendering stripe on change
      showSkeletonLoader: false, // flag to show skeleton while we recover country
      applicationFee: null,
      showApplicationFeeLoader: false,
    };
  },
  validations() {
    return {
      promoCode: {
        requiredIf: helpers.withMessage(
          requiredErrorCopy,
          requiredIf(function () {
            return this.isCubaProgram === true;
          })
        ),
      },
      sameLegalName: {},
      legalFirstName: {
        required: helpers.withMessage(requiredErrorCopy, required),
        maxLength: helpers.withMessage(
          ({ $params }) =>
            `Legal First Name must have fewer than ${$params.max} characters.`,
          maxLength(100)
        ),
      },
      legalLastName: {
        required: helpers.withMessage(requiredErrorCopy, required),
        maxLength: helpers.withMessage(
          ({ $params }) =>
            `Legal First Name must have fewer than ${$params.max} characters.`,
          maxLength(100)
        ),
      },
      billingAddress: {
        address: {
          required: helpers.withMessage("Address is required", required),
        },
        city: {
          required: helpers.withMessage(
            "The address provided doesn't include a city.",
            requiredIf(() => this.billingAddress.address)
          ),
        },
        state: {
          required: helpers.withMessage(
            "The address provided doesn't include a state.",
            requiredIf(() => this.billingAddress.address)
          ),
        },
        country: {
          required: helpers.withMessage(
            "The address provided doesn't include a country.",
            requiredIf(() => this.billingAddress.address)
          ),
        },
        postCode: {
          required: helpers.withMessage(
            "The address provided doesn't include a postal code.",
            requiredIf(() => this.billingAddress.address)
          ),
        },
      },
      cancellationPolicyCheckbox: {
        policy: {
          policyRequiredIf: helpers.withMessage(
            requiredErrorCopy,
            policyRequiredIf
          ),
        },
      },
    };
  },
  computed: {
    ...mapState([
      "program",
      "currentApplicationId",
      "featureFlags",
      "userData",
      "appliedAgreement",
      "enrollmentApplications",
      "studentApplications",
      "customer_id",
      "studentFormioSubmissions",
      "profileData",
      "programSessionDataV3",
      "currentUser",
      "profileSelectedObjects",
    ]),
    ...mapState("configurator", {
      currentOrder: (state) => state.currentOrder,
      currentProgram: (state) => state.currentProgram,
    }),
    blockAutoCompleteInput() {
      return (
        ["currentAddress", "permanentAddress"].includes(
          this.sameAsAddressChoosen
        ) || this.isReadOnly
      );
    },
    programSessionFinanceCode() {
      return null;
    },
    programType() {
      // TODO: What happens if session_types > 1?
      return this.programSessionDataV3.session_types?.[0]?.name ?? null;
    },
    depositAmount() {
      return APPLICATION_FEE_DATA.amount; // harcoded in V3
    },
    availableCredit() {
      return this.validDiscountCode // Returns application_fee so the total is 0
        ? APPLICATION_FEE_DATA.amount // this makes totalFee 0
        : 0; // this means there's no discount
    },
    hostUniversity() {
      // TODO: What happens if session_hosting_institutions > 1?
      return this.programSessionDataV3.session_hosting_institutions?.[0]?.entity
        ?.name;
    },
    totalFee() {
      return this.depositAmount - this.availableCredit;
    },
    getApplicationId() {
      if (this.currentApplicationId) {
        return this.currentApplicationId;
      } else {
        return this.$route.params.applicationId;
      }
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          firstname: this.billingAddress.firstName,
          lastname: this.billingAddress.lastName,
          address: this.billingAddress.address,
          city: this.billingAddress.city,
          state: this.billingAddress.state,
          country: this.billingAddress.country,
          postCode: this.billingAddress.postCode,
          currentAddress: this.currentAddress.address,
          currentCity: this.currentAddress.city,
          currentState: this.currentAddress.state,
          currentCountry: this.currentAddress.country,
          currentPostCode: this.currentAddress.postCode,
          permanentAddress: this.permanentAddress.address,
          permanentCity: this.permanentAddress.city,
          permanentState: this.permanentAddress.state,
          permanentCountry: this.permanentAddress.country,
          permanentPostCode: this.permanentAddress.postCode,
          promotionCode: this.promoCode,
          expMonth: this.cardInformation.month,
          cancellationPolicy: this.isCustomizedSession
            ? true
            : this.cancellationPolicyCheckbox.policy,
          creditTransactionId: this.creditTransactionId,
          application_id: this.getApplicationId,
          transactionId: this.transactionId,
          completedManually: this.addressController.billingAddress
            .showManualForm,
        },
      };
      return body;
    },
    getManagerName() {
      // TODO: Where to get this in V3?
      return this.program?.program_manager
        ? this.program?.program_manager
        : "API Enrollment Management Team";
    },
    getEmail() {
      // TODO: Where to get this in V3?
      return this.program?.program_manager_email
        ? this.program?.program_manager_email
        : `enrollment${APICompany.companyEmail}`;
    },
    isCubaProgram() {
      // TODO: What happens if session_travel_details > 1?
      return (
        this.programSessionDataV3.session_travel_details?.[0]?.arrival_city?.country?.name.toLowerCase() ===
        "cuba"
      );
    },
    birthDate() {
      return format(
        parse(this.userData.data.birthday, "MM/dd/yyyy", new Date()),
        "yyyy-MM-dd"
      );
    },
    year() {
      // TODO: What happens if session_travel_details > 1?
      const arrival_date =
        this.programSessionDataV3.session_travel_details[0].arrival_date ??
        this.programSessionDataV3?.academic_start_date;
      let year = format(parse(arrival_date, "yyyy-MM-dd", new Date()), "yyyy");
      return year;
    },
    stripePayDepositFormEnabled() {
      return this.featureFlags["stripe-application-deposit"];
    },
    paymentFee() {
      return this.validDiscountCode ? "0" : this.applicationFee;
    },
    precheckRadioOption() {
      return this.isReadOnly ? this.preSelectedAddressRadioButton : "";
    },
    disableSubmit() {
      if (this.isReadOnly) {
        return false;
      } else {
        return this.v$.$invalid || this.disabledContinueButton;
      }
    },
    isCustomizedSession() {
      return this.programSessionDataV3?.channel_id === channelTypes.CUSTOM;
    },
  },
  watch: {
    "v$.billingAddress.$errors": {
      handler: function (newBillingAddress) {
        // Renders form to manually set address if autocomplete caused an error
        if (
          newBillingAddress?.some((error) => {
            return requiredAddressFields.includes(error.$property);
          })
        ) {
          this.addressController.billingAddress.showManualForm = true;
          this.addressController.billingAddress.shownDueToError = true;
        }
      },
      deep: true,
    },
    legalFirstName: {
      handler: function (newval) {
        // Every time name changes we update the addresses
        this.billingAddress.firstName = newval;
        this.currentAddress.firstName = newval;
        this.permanentAddress.firstName = newval;
      },
    },
    applicationFee: {
      handler: function (newValue) {
        this.applicationFee = newValue ?? APPLICATION_FEE_DATA.amount;
      },
    },
    legalLastName: {
      handler: function (newval) {
        this.billingAddress.lastName = newval;
        this.currentAddress.lastName = newval;
        this.permanentAddress.lastName = newval;
      },
    },
    sameLegalName: {
      handler: function (newVal) {
        if (newVal) {
          this.legalFirstName = this.profileData.legal_first_name;
          this.legalLastName = this.profileData.last_name;
        }
      },
    },
    transactionId: function () {
      this.saveToFormIoAfterCardCharge();
    },
    billingAddress: {
      handler: function (newval) {
        // Render stripe form if billing address has all info, no promo code has been applied and form isn't currently active
        if (
          newval?.address &&
          newval?.city &&
          newval?.state &&
          newval?.country &&
          newval?.postCode &&
          newval?.firstName &&
          newval?.lastName &&
          !this.hideCardInformation &&
          !this.stripeRendered
        ) {
          this.stripeRendered = true;
          this.checkDataForStripe();
        }
      },
      deep: true,
    },
    sameAsAddressChoosen: {
      handler: function (newVal) {
        // Reset manual form - it will appear again if validations fail
        this.addressController.billingAddress.showManualForm = null;
        this.addressController.billingAddress.shownDueToError = false;
        switch (newVal) {
          case "currentAddress": {
            // Billing, current and permanent address will be identical to current address
            this.setAddress(
              "currentAddress",
              "current_address",
              "currentCountry"
            );
            this.setAddress(
              "billingAddress",
              "current_address",
              "currentCountry"
            );
            this.setAddress(
              "permanentAddress",
              "current_address",
              "currentCountry"
            );
            this.addressProp = this.createInitialAddress("current_address");
            this.remountAutoComplete();
            this.v$.billingAddress.$touch();
            break;
          }
          case "permanentAddress": {
            // Billing, current and permanent address will be identical to permanent address
            this.setAddress(
              "currentAddress",
              "permanent_address",
              "permanentCountry"
            );
            this.setAddress(
              "billingAddress",
              "permanent_address",
              "permanentCountry"
            );
            this.setAddress(
              "permanentAddress",
              "permanent_address",
              "permanentCountry"
            );
            this.addressProp = this.createInitialAddress("permanent_address"); // to pre-populate input
            this.remountAutoComplete();
            this.v$.billingAddress.$touch();
            break;
          }
          case "other": {
            // Reset values, billing, current and permanent adress will be identical to what they input now on in auto-complete / manual form
            // Other methods (updateBillingAddress/updateBillingAddress) will take care of that
            this.setAddress("currentAddress", "nullify_address", "n/a");
            this.setAddress("billingAddress", "nullify_address", "n/a");
            this.setAddress("permanentAddress", "nullify_address", "n/a");
            this.addressProp = {};
            this.remountAutoComplete();
            this.v$.billingAddress.$reset();
            break;
          }
        }
      },
    },
    chargeErrors: {
      handler() {
        if (this.chargeErrors.length > 0) {
          this.$refs.paymentSubmitButton.stopLoading();
        }
      },
      deep: true,
    },
    isCubaProgram: {
      handler: function (newVal) {
        if (newVal) {
          //If the program is from Cuba remove card section and validation
          this.showDiscountButton = true;
          this.removeCardSection();
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.preFillReadOnly();
    this.fetchOrderPricing();
    this.getPayments();
    await this.$store.dispatch("getCustomerId");
    await this.getCountryNames();
    //check if a payment exists for the application and set state accordingly
    await this.setPaymentState();
  },
  methods: {
    usePascalCase,
    updateAddressManually(addressInfo) {
      this.billingAddress["address"] = addressInfo["address"] || "";
      this.billingAddress["postCode"] =
        addressInfo["address_postal_code"] || null;
      this.billingAddress["city"] = addressInfo["address_city"];
      this.billingAddress["state"] = addressInfo["address_state"];
      this.billingAddress["country"] =
        addressInfo["address_country_id"] || null;
      this.billingAddress["countryName"] = addressInfo["country"] || ""; // label of country

      this.currentAddress = { ...this.billingAddress };
      this.permanentAddress = { ...this.billingAddress };
    },
    updateBillingAddress(addressInformation) {
      // Sets the same data across all address objects.
      const {
        street_address_1,
        number,
        city,
        state,
        postal_code,
        iso, // iso
        country,
        administrative_area_level_2,
      } = addressInformation;
      this.billingAddress.address = `${number ? `${number} ` : ""}${
        street_address_1 || ""
      }`;
      this.billingAddress.city = city;
      this.billingAddress.state = state;
      this.billingAddress.country = iso || null;
      this.billingAddress.postCode = postal_code || null;
      this.billingAddress.countryName = country || null;
      this.billingAddress.administrativeAreaLevel2 =
        administrative_area_level_2 || null;
      this.currentAddress = { ...this.billingAddress };
      this.permanentAddress = { ...this.billingAddress };
    },
    remountAutoComplete() {
      // Hides and shows auto-complete input so it shows the address
      this.showAutoComplete = false;
      setTimeout(() => {
        this.showAutoComplete = true;
      }, 100);
    },
    createInitialAddress(addressType = "") {
      // Creates an object to pre-populate the address component when mounted
      // Address type being one of; currentAddress, permanentAddress
      let country = this.cacheCountries.find(
        (country) =>
          country.id === this.profileData[addressType + "_country_id"]
      );
      return {
        street_address_1: this.profileData[addressType + "_line_1"] ?? null,
        street_address_2: this.profileData[addressType + "_line_2"] ?? null,
        city: this.profileData[addressType + "_city"] ?? null,
        state: this.profileData[addressType + "_state"] ?? null,
        country: country?.name ?? null,
        postal_code: this.profileData[addressType + "_postal_code"] ?? null,
        utc_offset_minutes: null,
      };
    },
    async getCountryNames() {
      // Profile service only stores the id of the country but not their names
      // So we'll try to get them to avoid confusion in the inputs
      if (this.profileData?.current_address_country_id) {
        const { data } = await countriesService.getCountry(
          this.profileData.current_address_country_id
        );
        if (
          typeof data?.data?.name === "string" &&
          !this.cacheCountries.find(
            (country) =>
              country.id === this.profileData?.current_address_country_id
          )
        ) {
          this.cacheCountries.push({
            id: this.profileData.current_address_country_id,
            name: data.data.name,
          });
        }
      }
      if (this.profileData?.permanent_address_country_id) {
        const { data } = await countriesService.getCountry(
          this.profileData.permanent_address_country_id
        );
        if (
          typeof data?.data?.name === "string" &&
          !this.cacheCountries.find(
            (country) =>
              country.id === this.profileData?.permanent_address_country_id
          )
        ) {
          this.cacheCountries.push({
            id: this.profileData?.permanent_address_country_id,
            name: data.data.name,
          });
        }
      }
    },
    setAddress(addressTypeToChange, addressTypeSource, stateName) {
      // Address type is either; currentAddress, permanentAddress, billingAddress
      // Should match names as in profile service
      this[addressTypeToChange]["address"] =
        this.profileData[addressTypeSource + "_line_1"] ?? null;
      this[addressTypeToChange]["city"] = this.profileData[
        addressTypeSource + "_city" ?? null
      ];
      this[addressTypeToChange]["state"] = this.profileData[
        addressTypeSource + "_state" ?? null
      ];
      this[addressTypeToChange]["country"] = this.profileData[
        addressTypeSource + "_country_id" ?? null
      ];
      this[addressTypeToChange]["postCode"] = this.profileData[
        addressTypeSource + "_postal_code" ?? null
      ];
      this[addressTypeToChange]["administrativeAreaLevel2"] = this.profileData[
        addressTypeSource + "_administrative_area_level_2" ?? null
      ];
      this[addressTypeToChange]["countryName"] =
        this.profileSelectedObjects[stateName]?.name ?? "";
    },
    preSelectAddress(addressType) {
      // Address type is either; current, permanent, billing
      this.sameAsAddressChoosen = addressType;
    },
    async applyPromoCode() {
      // This was done on a separate component
      this.$refs.inputwbutton.$refs.promoCodebutton.startLoading();
      try {
        // eslint-disable-next-line no-undef
        const promo_response = await axios.post(
          `/discounts/${this.promoCode}`,
          {
            user: this.userData,
          },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        if (promo_response?.data?.["0"] === 200) {
          this.validDiscountCode = true;
          this.promoMessage = "Code applied successfully";
          this.removeCardSection(); // do not validate card information
        } else {
          this.validDiscountCode = false;
          this.promoMessage = "Invalid Code";
        }
      } catch (e) {
        this.validDiscountCode = false;
        this.promoMessage = "Invalid Code";
      } finally {
        this.$refs.inputwbutton.$refs.promoCodebutton.stopLoading();
      }
    },
    getPayments() {
      this.showApplicationFeeLoader = true;
      paymentService
        .getApplicationFeeAmount(this.$route.params.applicationId)
        .then((response) => {
          this.applicationFee = response;
        })
        .catch((err) => {
          Sentry.captureException(
            new Error("Get payment failed in checkout page"),
            {
              tags: {
                error: err,
              },
            }
          );
        })
        .finally(() => {
          // need to include the loading since paymentFee has default value as 0 and for first checkout without results,
          // this endpoint will return 404 and will assume default value
          this.showApplicationFeeLoader = false;
        });
    },
    async saveToFormIoAfterCardCharge() {
      for (let i = 0; i <= 2; i++) {
        await this.submitToFormIo(
          this.formIoForm,
          this.createSubmissionDataForFormIo
        )
          .then(() => {
            i = 10;
            this.$emit("update-order", this.transactionId); // Complete step and redirect
          })
          .catch((error) => {
            if (i === 2) {
              Sentry.captureException(
                new Error("Save payment form to form.io failed"),
                {
                  tags: {
                    error: error,
                  },
                }
              );
              //TODO remove this alert once we know how we want to handle failures submitting to form.io
              alert(
                "Oops! There is an error but we have the payment you submitted for this application. Please enter the following promotion code to continue: pymntpg20"
              );
              this.$refs.paymentSubmitButton.stopLoading();
            }
          });
      }
    },
    async setPaymentState() {
      this.paymentDone = await this.checkTransactionExistsForApplicationId();
      //disable step 1 if we already have a payment for this application.
      if (this.paymentDone) {
        //check if payment exists in form.io if not save payment data from database to formio
        if (
          !(await this.formioSubmissionExists(
            this.formIoForm,
            this.getApplicationId
          ))
        ) {
          let response = await getApiClient().post("/payments", {
            applicationId: this.getApplicationId,
            formPath: this.formIoForm,
          });

          if (response.status === 200) {
            this.$emit("update-order", this.getApplicationId); // Complete step and redirect
          }
        }
      }
    },
    async fetchOrderPricing() {
      const payload = toRaw(this.currentOrder);

      try {
        this.pricingData = await ordersService.getOrdersPricing({ payload });
        this.pricingDataInitialized = true;
      } catch (error) {
        console.error("Error fetching order pricing", error);
      }
    },
    determineTransactionId() {
      if (this.promoCode && this.promoCode.trim() !== "") {
        return this.promoCode + "_" + this.applicationId;
      }
      return this.transactionId;
    },
    setCurrency() {
      return "USD";
    },
    async sendPurchaseEvent(pricingData) {
      if (!pricingData) return;
      const categories = [
        "Housing",
        "Internship",
        "Events",
        "Additional Fees",
        "Classes Academic Fees",
      ];

      categories.forEach((category) => {
        const itemsArrays = getCategoryItemArray(
          category,
          this.pricingData,
          this.currentOrder,
          this.programSessionDataV3
        );
        if (itemsArrays && itemsArrays.length > 0) {
          itemsArrays.forEach((item) => {
            trackPurchase(
              this.determineTransactionId(),
              this.depositAmount,
              this.setCurrency(),
              this.promoCode || "not_set",
              [item]
            );
          });
        }
      });
    },
    async submitPayDeposit() {
      this.$refs.paymentSubmitButton.startLoading();
      if (this.isReadOnly) {
        this.$emit("next-step");
        return;
      }
      try {
        this.validateDeadline();

        if (this.isPastDeadline) {
          this.$refs.paymentSubmitButton.stopLoading();
          return;
        }

        await this.formioValidateSubmission(
          this.formIoForm,
          this.createSubmissionDataForFormIo
        );
        if (!this.hasFormIoValidationErrors) {
          // Save billing address to profile services
          this.saveBillingAddress();

          // Discount applied - submit to formio, create event in payment service, create order in learning service and go to next step
          if (this.validDiscountCode) {
            this.submitToFormIo(
              this.formIoForm,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                // send avisor notification
                // TODO: Replace SF id later on
                this.sendNotifications(
                  "submit",
                  this.programSessionDataV3.salesforce_id
                );
                this.sendPurchaseEvent(this.pricingData); // when promoCode is used
                this.$emit("update-order", this.promoCode);
                if (this.stripePayDepositFormEnabled) {
                  this.submitToPaymentService();
                }
              }
            });
            return;
          }

          // Discount wasn't applied - try paying stripe
          // formio will be submitted if a transaction id was supplied, create an order in learning service and go to next step
          if (this.stripePayDepositFormEnabled) {
            await this.handleSubmitToStripe();
            if (this.paymentSuccess) {
              this.sendNotifications(
                "submit",
                this.programSessionDataV3.salesforce_id
              );
              this.sendPurchaseEvent(this.pricingData); // when stripe is used
            } else {
              this.$refs.paymentSubmitButton.stopLoading();
              return;
            }
          }
        } else {
          this.$refs.paymentSubmitButton.stopLoading();
        }
      } catch {
        this.$refs.paymentSubmitButton.stopLoading();
      }
    },
    removeCardSection() {
      this.hideCardInformation = true;
      const index = this.sectionsToValidate.indexOf("cardInformation");
      if (index > -1) this.sectionsToValidate.splice(index, 1);
    },
    async saveBillingAddress() {
      const data = {
        email: this.profileData?.email,

        manual_billing_address_completion: this.addressController.billingAddress
          .showManualForm,
        billing_address_country_id: this.billingAddress.country ?? null,
        billing_address_state: this.billingAddress.state ?? null,
        billing_address_city: this.billingAddress.city ?? null,
        billing_address_line_1: this.billingAddress.address ?? null,
        billing_address_postal_code: this.billingAddress.postCode ?? null,
        billing_address_administrative_area_level_2:
          this.billingAddress.administrativeAreaLevel2 ?? null,

        majors: this.profileData?.majors ?? [],
        minors: this.profileData?.minors ?? [],
        colleges: this.profileData?.colleges ?? [],
        languages: this.profileData?.languages ?? [],
        parents: this.profileData?.parents ?? [],
        user_types: this.profileData?.user_types ?? [],
        okta_id: this.profileData?.okta_id,
      };

      let profileResponse = await profileService.updateProfile(
        this.currentUser.participantId,
        data
      );

      // Keep profile state up to date
      this.$store.commit("setProfileData", profileResponse.data.data);

      // Update data on vuex
      const billingCountry = this.billingAddress?.country ?? "";
      let userCountries = [billingCountry].filter((item) => item);
      const countriesResponse = await applicationService.getUserInitialData(
        [...new Set(userCountries)].join(",")
      );
      if (userCountries.length) {
        this.$store.commit("setProfileSelectedObjects", {
          propertyName: "billingCountry",
          value: countriesResponse?.[billingCountry]?.data || null,
        });
      }
    },
    preFillReadOnly() {
      if (this.isReadOnly) {
        if (this.existingPaymentData?.data?.completedManually) {
          this.showSkeletonLoader = true;
          this.addressController.billingAddress.showManualForm = true;
        }
        this.prefillNameSection();
        this.prefillAddressSection();
        this.checkCancellationPolicy();
      }
    },
    checkCancellationPolicy() {
      this.cancellationPolicyCheckbox.policy = !!this.existingPaymentData.data
        .cancellationPolicy;
    },
    async prefillAddressSection() {
      // Define property mappings
      const propertyMappings = [
        ["address", "street_address_1"],
        ["city", "city"],
        ["state", "state"],
        ["postCode", "postal_code"],
      ];

      let address = this.createInitialAddress("current_address");
      if (
        this.areObjectsEqual(
          this.existingPaymentData.data,
          address,
          propertyMappings
        )
      ) {
        this.preSelectedAddressRadioButton = "currentAddress";
        this.preSelectAddress("currentAddress");
        return;
      }

      address = this.createInitialAddress("permanent_address");
      if (
        this.areObjectsEqual(
          this.existingPaymentData.data,
          address,
          propertyMappings
        )
      ) {
        this.preSelectedAddressRadioButton = "permanentAddress";
        this.preSelectAddress("permanentAddress");
        return;
      }

      //if no match found it means the user chose other radio button
      this.preSelectedAddressRadioButton = "other";
      let country = this.cacheCountries.find(
        (country) => country.id === this.existingPaymentData.data.country
      );
      if (!country) {
        const { data } = await countriesService.getCountry(
          this.existingPaymentData.data.country
        );
        country = { ...data.data };
      }
      this.remountAutoComplete();
      this.addressProp = {
        street_address_1: this.existingPaymentData.data.address ?? null,
        city: this.existingPaymentData.data.city ?? null,
        state: this.existingPaymentData.data.state ?? null,
        country: country?.name ?? null,
        postal_code: this.existingPaymentData.data.postCode ?? null,
        utc_offset_minutes: null,
      };

      // Set billing address (From data at time of submission)
      this.billingAddress.address =
        this.existingPaymentData.data.address ?? null;
      this.billingAddress.city = this.existingPaymentData.data.city ?? null;
      this.billingAddress.state = this.existingPaymentData.data.state ?? null;
      this.billingAddress.country = country?.id ?? null; //
      this.billingAddress.countryName = country?.name ?? null;
      this.billingAddress.postCode =
        this.existingPaymentData.data.postCode ?? null;
      this.showSkeletonLoader = false;
    },
    prefillNameSection() {
      //same as leagal checkbox
      if (
        this.existingPaymentData.data.firstname ===
          this.profileData.legal_first_name &&
        this.existingPaymentData.data.lastname === this.profileData.last_name
      ) {
        this.sameLegalName = true;
      } else {
        //populate name
        this.legalFirstName = this.existingPaymentData.data.firstname;
        this.legalLastName = this.existingPaymentData.data.lastname;
      }
    },
    areObjectsEqual(obj1, obj2, propMappings) {
      return propMappings.every(
        ([prop1, prop2]) => obj1[prop1] === obj2[prop2]
      );
    },
  },
};
</script>
