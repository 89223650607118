<script setup>
import { createPopper } from "@popperjs/core";
import Magnifier from "@/components/shared/icons/Magnifier";
import ErrorMessage from "../ErrorMessage.vue";
import { defineProps } from "vue";

const {
  placement,
  fieldLabel,
  fieldLabelClasses,
  inputClasses,
  errorClasses,
  vuelidateInstance,
} = defineProps({
  placement: {
    required: false,
    type: String,
  },
  fieldLabel: {
    type: String,
  },
  fieldLabelClasses: {
    type: String,
    default: "common-label-text",
  },
  inputClasses: {
    type: String,
  },
  errorClasses: {
    type: String,
  },
  vuelidateInstance: {},
  scrollInput: {
    type: String,
    required: false,
    default: "visible",
  },
  inputMaxHeight: {
    type: String,
    required: false,
    default: "inherit",
  },
});

const onKeyDown = (map, vm) => ({
  ...map,
  8: (e) => {
    // block backspace
    if (vm.search.length <= 0) e.preventDefault();
  },
});

const withPopper = (dropdownList, component, { width }) => {
  /**
   * We need to explicitly define the dropdown width since
   * it is usually inherited from the parent with CSS.
   */
  dropdownList.style.width = width;
  dropdownList.className += " searchable_select_override";

  /**
   * Here we position the dropdownList relative to the $refs.toggle Element.
   *
   * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
   * the dropdownList overlap by 1 pixel.
   *
   * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
   * wrapper so that we can set some styles for when the dropdown is placed
   * above.
   */
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: this.placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  /**
   * To prevent memory leaks Popper needs to be destroyed.
   * If you return function, it will be called just before dropdown is removed from DOM.
   */
  return () => popper.destroy();
};
</script>

<template>
  <div>
    <slot name="fieldLabel">
      <label
        v-show="fieldLabel"
        :name="fieldLabel"
        :class="fieldLabelClasses"
        >{{ fieldLabel }}</label
      >
    </slot>
    <div>
      <v-select
        v-bind="$attrs"
        :components="{ OpenIndicator: Magnifier }"
        :class="[
          'searchable_select_override',
          {
            'forced-error-background': !!vuelidateInstance?.$errors?.length,
          },
          inputClasses,
        ]"
        :map-keydown="onKeyDown"
        :append-to-body="!!placement"
        :calculate-position="placement && withPopper"
        @update:modelValue="vuelidateInstance?.$touch"
      >
        <template v-for="(_, name) in $slots" #[name]="slotProps">
          <slot :name="name" v-bind="slotProps || {}"></slot>
        </template>
      </v-select>
    </div>
    <ErrorMessage
      :error-classes="errorClasses"
      :vuelidate-instance="vuelidateInstance"
    />
  </div>
</template>

<style scoped>
.forced-select-box-grow :deep(.vs__dropdown-toggle),
:deep(.vs__selected-options) {
  overflow: v-bind(scrollInput);
  max-height: v-bind(inputMaxHeight);
}
.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
</style>

<style>
.searchable_select_override .vs__dropdown-toggle,
.vs__selected-options {
  max-height: none;
}
.searchable_select_override .vs__dropdown-option--highlight {
  background-color: #edf9f8 !important;
}
.searchable_select_override .vs__dropdown-option--deselect {
  background-color: #c8d0ec !important;
}
.searchable_select_override .vs__dropdown-option--selected {
  background-color: #edf9f8 !important;
}
.searchable_select_override .vs__open-indicator {
  transform: none !important;
}
.searchable_select_override .vs__dropdown-option--highlight {
  color: var(--vs-dropdown-option-color);
}
</style>
