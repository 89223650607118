<template>
  <tr v-if="show">
    <td>
      <span
        v-if="formExistsInConfig.exists"
        :class="`${secondaryColorClass}`"
        class="cursor-pointer font-semibold"
        @click="
          updateFormDesignSelected(formExistsInConfig.form);
          updateFormDesignModalOpen(true);
        "
      >
        {{ formTitle }}
      </span>
      <span v-else>
        {{ formTitle }}
      </span>
    </td>
    <td>
      <span>
        <template v-if="approvalForm && approvalPending">
          <button class="approve" @click="approveApplication">
            Approve
          </button>
        </template>
        <template v-else>
          <i :class="statusIconClass"></i>
          <span>
            {{ formStatus }}
          </span>
        </template>
      </span>
    </td>
  </tr>
</template>

<script type="text/javascript">
import { formConfig } from "@/mixins/formDesignsMixin.js";
import { mapMutations } from "vuex";
import universityApproval from "@/mixins/universityApproval.js";
import { eventBus } from "@/app";

export default {
  name: "SingleFormAndStatus",
  mixins: [formConfig, universityApproval],
  inject: ["tabApplication"],
  props: {
    name: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "Not Submitted",
    },
    simplifiedApproval: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["approveApplication"],
  data() {
    return {
      approvalPending: false,
    };
  },
  computed: {
    formExistsInConfig() {
      let item = this.formDesigns.find(
        (form) => form.name === this.formTitle.trim()
      );
      let exists = item !== undefined;
      let formData = exists ? item : {};
      return {
        exists: exists,
        form: formData,
      };
    },
    approvalForm() {
      return this.formTitle.trim() === "University Approval";
    },
    statusIconClass() {
      let status = this.formStatus;
      let statusClass = "";
      switch (status) {
        case "Complete":
        case "Submitted":
          statusClass = `mdi mdi-checkbox-blank-circle ${this.primaryColorClass} text-2xl mr-2`;
          break;
        case "Not Submitted":
          statusClass =
            "mdi mdi-checkbox-blank-circle-outline text-gray-400 text-2xl mr-2";
          break;
        case "In Progress":
          statusClass = `mdi mdi-chevron-right-circle ${this.primaryColorClass} text-2xl mr-2`;
          break;
        default:
          statusClass =
            "mdi mdi-checkbox-blank-circle-outline text-gray-400 text-2xl mr-2";
      }
      return statusClass;
    },
    formTitle() {
      let title = "";
      if (typeof this.name === "string") {
        title = this.name;
      }
      return title;
    },
    formStatus() {
      let status =
        this.approvalForm && !this.approvalPending ? "Complete" : this.status;
      return status;
    },
    show() {
      if (
        this.tabApplication.form_rules &&
        !this.tabApplication.form_rules.includes("University Approval") &&
        this.name === "University Approval"
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    simplifiedApproval: {
      handler: function () {
        this.setApprovalStatus();
      },
    },
  },
  created() {
    this.setApprovalStatus();
  },
  methods: {
    ...mapMutations("university", [
      "updateFormDesignModalOpen",
      "updateFormDesignSelected",
    ]),
    setApprovalStatus() {
      this.approvalPending =
        !this.approvedStatuses.includes(this.status) &&
        !this.simplifiedApproval;
    },
    approveApplication() {
      eventBus.$emit("approveApplication");
    },
  },
};
</script>

<style scoped>
button.approve {
  @apply inline-block px-4 min-h-10;
  @apply border-2 border-transparent rounded;
  @apply text-center text-gray-700 font-semibold;
  @apply bg-university-tertiary;
}
button.approve:hover {
  @apply bg-yellow-900;
}
button.approve:focus {
  @apply bg-yellow-900;
}
thead th {
  @apply py-3;
  @apply text-xs tracking-widest text-left text-gray-500 uppercase;
}
tbody tr {
  @apply border-t border-gray-200;
}
tbody tr td {
  @apply py-3;
}
tbody tr td > span {
  @apply flex items-center;
}
</style>
