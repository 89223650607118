import { formatter, formatterWithoutCents } from "./formatter";

/**
 * @typedef {Object} Room
 * @property {string} id - The unique identifier of the room.
 * @property {string} status - The status of the room (e.g., "Active", "Inactive").
 */

/**
 * Generates a price label based on room prices, default room price, and the status of rooms.
 *
 * @param {Object.<string, number>} roomPrices - An object where keys are room IDs and values are room prices in cents.
 * @param {string} defaultRoomId - The ID of the default room.
 * @param {Room[]} rooms - An array of room objects, each containing at least an `id` and `status` property.
 * @param {boolean} [removeCents=false] - Flag indicating whether to remove cents from the formatted price.
 * @returns {string} - A formatted price label indicating the price differences.
 */

export const priceLabel = (
  roomPrices,
  defaultRoomId,
  rooms,
  removeCents = false
) => {
  const defaultRoomPrice = roomPrices?.[defaultRoomId] ?? 0;
  let unitRooms = rooms?.filter((room) => room?.status === "Active") || [];
  const unitRoomPrices = unitRooms.map((room) => {
    return roomPrices?.[room.id] ?? 0;
  });

  const priceDifferencesFromDefault = unitRoomPrices
    .map((price) => Math.max(0, price - defaultRoomPrice))
    .sort((a, b) => a - b); // Subtract Room price - Default room price) and order them from lower to higher
  let priceLabel = "";
  if (priceDifferencesFromDefault.every((price) => price <= 0)) {
    // All rooms are below the default price
    priceLabel = "Included";
  } else if (priceDifferencesFromDefault.every((price) => price > 0)) {
    // All rooms need to be paid - Display cheapest available
    priceLabel = `From +${formatPrice(
      priceDifferencesFromDefault[0],
      removeCents
    )}`;
  } else {
    // A room is included but others are not - Display cheapest available
    const cheapestNonZeroPrice = priceDifferencesFromDefault.filter(
      (price) => price > 0
    )[0];
    priceLabel = `Included or from +${formatPrice(
      cheapestNonZeroPrice,
      removeCents
    )}`;
  }
  return priceLabel;
};

/**
 * Formats the given price in cents to a string representation in dollars.
 *
 * @param {number} price - The price in cents to be formatted.
 * @param {boolean} removeCents - Flag indicating whether to remove cents from the formatted price.
 * @returns {string} - The formatted price string.
 */
const formatPrice = (price, removeCents) => {
  if (removeCents) {
    return formatterWithoutCents.format(price / 100)
  }
  return formatter.format(price / 100);
};
