import { API_RELATIONSHIPS } from "@/constants";
let state = {
  formioToken: "",
  currentUser: null,
  enrollmentToken: "",
  userData: {},
  studentApplications: [],
  studentFormioSubmissions: [],
  currentApplicationSubmissions: {},
  onBoardingFormioSubmissions: [],
  selectedProgramId: "",
  program: {},
  isInternship: false,
  universityName: "",
  universityCity: "",
  universityImageUrl: "",
  universityWebsiteUrl: "",
  programSelected: false,
  billingRules: {},
  payments: [],
  term: "",
  enrollmentApplications: [],
  sessionExpired: false,
  redirectPath: "",
  previousApplication: {
    previousApplicationId: "",
    session: "",
    paidFee: false,
    applicationFee: 0,
    transactionId: "",
    finished: false,
    submissionId: "",
  },
  currentApplicationId: "",
  applicationPaid: false,
  customer_id: "",
  uiVersion: "",
  completedSections: {
    onboarding1: false,
    payment: false, //confirmation paymet
    onboarding2: false,
    otherPayments: false,
  },
  onboardingStep1Forms: {},
  onboardingStep2Forms: {},
  currentGpa: 0,
  directProgramData: {},
  appliedAgreement: {},
  institutionPaysApplicationFee: false,
  institutionPaysConfirmationPayment: false,
  institutionPaysAcademicFee: false,
  institutionPaysHousingFee: false,
  financialAid: "",
  certifyFAFSA: false,
  featureFlags: {},
  addressInfo: {},
  newAccountAndApplication: false, // Only for V3, used to show the 1st step one time
  profileData: {}, // Since V3: User have a profile from the profile-service repo
  profileSelectedObjects: {
    loaded: false,
    citizenship: null,
    citizenship2: null,
    school: null,
    majors: [],
    minors: [],
    currentCountry: null,
    permanentCountry: null,
    billingCountry: null,
  },
  stepsInV3: [
    { completed: false, data: null, step: "AccountCreation" },
    { completed: false, data: null, step: "AboutYou" },
    { completed: false, data: null, step: "LearnerInfo" },
    { completed: false, data: null, step: "Review" },
    { completed: false, data: null, step: "GroupVisa" },
    { completed: false, data: null, step: "Checkout" },
    { completed: false, data: null, step: "NextSteps" },
  ],
  programSessionDataV3: {}, // stores data of session
  redirectSessionData: {
    programId: null,
    sessionId: null,
  },
  currentAPIRelationship: API_RELATIONSHIPS.DEFAULT, // exclusive to advisors
};
export default state;
