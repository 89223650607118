<script setup>
import { defineProps, ref, defineEmits } from "vue";
import ErrorMessage from "../ErrorMessage.vue";
import { uuid } from "vue-uuid";

const {
  fieldLabelClasses,
  fieldLabel,
  modelValue,
  vuelidateInstance,
  errorClasses,
} = defineProps({
  fieldLabelClasses: {
    type: String,
    default: "common-label-text",
  },
  fieldLabel: {
    type: String,
  },
  yesLabel: {
    default: "Yes",
    type: String,
  },
  noLabel: {
    default: "No",
    type: String,
  },
  modelValue: { type: Boolean },
  vuelidateInstance: {},
  errorClasses: { default: "common-error-text" },
});

const emit = defineEmits(["update:modelValue"]);
const checkId = ref(uuid.v4());

const checkValue = ref(modelValue);
const updateInput = (val) => {
  checkValue.value = val.currentTarget.checked;
  emit("update:modelValue", checkValue.value);
};
</script>

<template>
  <div>
    <slot name="fieldLabel">
      <label
        v-show="fieldLabel"
        :name="fieldLabel"
        :class="fieldLabelClasses"
        >{{ fieldLabel }}</label
      >
    </slot>
    <div>
      <fieldset class="">
        <div class="flex items-center">
          <label
            :for="checkId"
            class="inline-flex items-center rounded-full p-1 cursor-pointer text-gray-500 bg-gray-300 h-10 mr-2"
          >
            <input
              :id="checkId"
              :checked="modelValue"
              type="checkbox"
              class="hidden peer"
              @change="updateInput"
            />
            <slot name="yesLabel">
              <span
                class="px-4 py-1 min-w-[140px] text-center rounded-full bg-blue-700 text-white peer-checked:bg-gray-300 peer-checked:text-black"
                >{{ yesLabel }}</span
              >
            </slot>
            <slot name="noLabel">
              <span
                class="px-4 py-1 min-w-[140px] text-center rounded-full bg-gray-300 text-black peer-checked:bg-blue-700 peer-checked:text-white"
                >{{ noLabel }}</span
              >
            </slot>
          </label>
        </div>
      </fieldset>
    </div>
    <ErrorMessage
      :error-classes="errorClasses"
      :vuelidate-instance="vuelidateInstance"
    />
  </div>
</template>

<style scoped></style>
