<template>
  <section>
    <div class="grid grid-cols-1 gap-y-7 md:pt-10 pt-4">
      <div
        v-if="isCustomizedSession"
        class="font-medium text-sm text-left sm:text-center"
      >
        <h1 class="font-bold text-2xl" data-testid="custom-header">
          Let's take the next step!
        </h1>
        <p class="mt-10" data-testid="custom-paragraph-1">
          Complete your enrollment forms so that API can finalize your onsite
          experience. These forms are required and are in addition to any forms
          you submitted to your university.
        </p>
        <p class="mt-5" data-testid="custom-paragraph-1">
          Your forms are due within 2 weeks, click continue to get started!
        </p>
      </div>
      <template v-else>
        <p class="font-medium text-xs">
          Fields marked with (*) are required.
        </p>
        <p class="font-bold text-2xl">
          {{ institutionName }} is covering your application deposit!
        </p>
        <p>
          To finalize your application, please acknowledge the withdrawal policy
          and click submit.
        </p>
        <!-- Withdrawal Policy -->
        <CheckBox
          id="cancellationPolicy"
          v-model.trim="v$.cancellationPolicyCheckbox.policy.$model"
          :vuelidate-field="v$.cancellationPolicyCheckbox.policy"
          :is-disabled="isReadOnly"
        >
          <template #label>
            I agree to the
            <a
              class="text-teal-700 underline"
              href="https://apiabroad.com/withdrawal-policy-safeguards/"
              target="_blank"
              >Withdrawal Policy.</a
            >*
          </template>
        </CheckBox>
      </template>

      <div class="md:mt-10 block">
        <ButtonWithSpinner
          id="paymentSubmitButton"
          ref="paymentSubmitButton"
          data-cy="paymentSubmitButton"
          type="submit"
          variant="secondary"
          variant-type="block"
          button-height="min-h-[58px] md:min-h-[60px] mt-4"
          :grey-disabled-class="true"
          :disabled="v$.$invalid || disabledContinueButton"
          @click="submitInstitutionPays"
        >
          <div class="flex items-center justify-center">
            <span class="pr-3 uppercase">
              {{ buttonLabel }}
            </span>
            <ArrowRight custom-class="w-4 h-4" />
          </div>
        </ButtonWithSpinner>
        <p v-if="hasErrors" class="text-error-900">
          Please try again. If this problem persists, please contact
          productsupport@apiexperience.com.
        </p>
      </div>
      <div v-if="formioErrors" class="text-error-900">
        <p v-for="error in formioErrors" :key="error">
          {{ error }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { helpers } from "@vuelidate/validators";
import formValidation from "@/mixins/formValidation";
import { mapState } from "vuex";
import formIoApi from "@/mixins/formIoApi";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import CheckBox from "@/components/forms/SharedComponents/DarkBlueInputs/Checkbox.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import forms from "@/mixins/forms";
import AddressComponents from "@/mixins/AddressComponents";
import { dollarsToCents } from "@/mixins/helpers";
import { format, parse } from "date-fns";
import axios from "axios";
import { uuid } from "vue-uuid";
import { getApiClient } from "@/services/API";
import AdvisorNotifications from "@/mixins/AdvisorNotifications.js";
import useVuelidate from "@vuelidate/core";
import * as Sentry from "@sentry/vue";
import { channelTypes } from "@/components/program-manager/sessions/constants";
import { trackPurchase, getCategoryItemArray } from "@/util/eventTracker.js";
import ordersService from "@/services/orders";
import { toRaw } from "vue";
import { usePastDeadline } from "@/composables/usePastDeadline.js";

const requiredErrorCopy = "This field is required.";
const requiredIf = (value, siblings, vm) => vm.isCustomizedSession || value;

export default {
  components: {
    ButtonWithSpinner,
    CheckBox,
    ArrowRight,
  },
  mixins: [
    formIoApi,
    formValidation,
    forms,
    AddressComponents,
    AdvisorNotifications,
  ],
  props: {
    ...mapState(["currentUser"]),
    showInstructions: {
      type: Boolean,
      default: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update-order", "next-step"],
  setup() {
    const {
      disabledContinueButton,
      isPastDeadline,
      validateDeadline,
    } = usePastDeadline();

    return {
      v$: useVuelidate(),
      disabledContinueButton,
      isPastDeadline,
      validateDeadline,
    };
  },
  data() {
    return {
      formIoForm: "institutionpayment",
      sectionsToValidate: [
        "permanentAddress",
        "currentAddress",
        "cancellationPolicyCheckbox",
      ],
      cancellationPolicyCheckbox: {
        policy: false,
      },
      ipedsId: null,
      submissionId: "",
      hasErrors: false,
      pricingData: null,
    };
  },
  validations() {
    return {
      cancellationPolicyCheckbox: {
        policy: {
          requiredIf: helpers.withMessage(requiredErrorCopy, requiredIf),
        },
      },
    };
  },
  computed: {
    ...mapState([
      "currentApplicationId",
      "userData",
      "program",
      "enrollmentApplications",
      "appliedAgreement",
      "studentApplications",
      "featureFlags",
      "customer_id",
      "profileData",
      "programSessionDataV3",
      "currentUser",
      "uiVersion",
    ]),
    ...mapState("configurator", {
      currentOrder: (state) => state.currentOrder,
      currentProgram: (state) => state.currentProgram,
    }),
    institutionName() {
      return this.userData?.data?.schoolname?.label ?? "Your college";
    },
    stripePayDepositFormEnabled() {
      return this.featureFlags["stripe-application-deposit"];
    },
    programSessionFinanceCode() {
      return null;
    },
    programType() {
      return this.programSessionDataV3.session_types?.[0]?.name ?? null;
    },
    depositAmount() {
      return 150; // harcoded in V3
    },
    year() {
      // TODO: What happens if session_travel_details > 1?
      const arrival_date =
        this.programSessionDataV3.session_travel_details[0].arrival_date ??
        this.programSessionDataV3?.academic_start_date;
      let year = format(parse(arrival_date, "yyyy-MM-dd", new Date()), "yyyy");
      return year;
    },
    setApplicationId() {
      if (this.currentApplicationId) {
        return this.currentApplicationId;
      } else {
        return this.$route.params.applicationId;
      }
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          currentAddress: this.profileData.current_address_line_1,
          currentCity: this.profileData.current_address_city,
          currentState: this.profileData.current_address_state,
          currentCountry: this.profileData.current_address_country_id,
          currentPostCode: this.profileData.current_address_postal_code,
          permanentAddress: this.profileData.permanent_address_line_1,
          permanentCity: this.profileData.permanent_address_city,
          permanentState: this.profileData.permanent_address_state,
          permanentCountry: this.profileData.permanent_address_country_id,
          permanentPostCode: this.profileData.permanent_address_postal_code,
          cancellationPolicy: this.isCustomizedSession
            ? true
            : this.cancellationPolicyCheckbox.policy,
          application_id: this.setApplicationId,
        },
      };
      return body;
    },
    isCustomizedSession() {
      return this.programSessionDataV3?.channel_id === channelTypes.CUSTOM;
    },
    buttonLabel() {
      return this.isCustomizedSession ? "Continue" : "Submit Application";
    },
  },
  async created() {
    await this.$store.dispatch("getCustomerId");
    this.fetchOrderPricing();
    if (this.isReadOnly) {
      this.cancellationPolicyCheckbox.policy = true;
    }
    this.ipedsId = await this.setIpedsId();
  },

  methods: {
    async submitToPaymentService() {
      axios.post(
        "/api/submit-to-paymentservice",
        this.getDataForPaymentService()
      );
    },
    getDataForPaymentService() {
      return {
        event_id: "evt_" + uuid.v4(),
        program_session_finance_code: this.programSessionFinanceCode,
        legacy_participant_account_id: this.customer_id,
        program_type: this.programType,
        program_name: this.programSessionDataV3.name,
        program_session: this.programSessionDataV3.terms?.[0]?.session ?? null, // TODO: What happens if terms > 1?
        session_country:
          this.programSessionDataV3.session_travel_details?.[0]?.arrival_city
            ?.country?.name ?? null, // TODO: What happens if session_travel_details > 1?
        session_year: this.year,
        source_type: "connect",
        amount_in_cents: dollarsToCents(150),
        application_id: this.setApplicationId,
        resource_id: this.userData._id,
        event_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        event_data: {
          first_name: this.userData.data.firstname,
          last_name: this.userData.data.lastname,
          email: this.userData.data.email,
          phone: this.userData.data.phone,
          participant_type: this.userData.data.student_type,
          birth_date: this.userData.data.birthday,
          home_university_name: this.userData.data.schoolname.label,
          home_institution_ipeds: this.ipedsId,
          program_city:
            this.programSessionDataV3.session_travel_details?.[0]?.arrival_city
              ?.city ?? null, // TODO: What happens if session_travel_details > 1?
          program_country:
            this.programSessionDataV3.session_travel_details?.[0]?.arrival_city
              ?.country.name ?? null, // TODO: What happens if session_travel_details > 1?
          program_id: this.programSessionDataV3.parent?.salesforce_id ?? "n/a",
          program_session_id: this.programSessionDataV3.salesforce_id,
          year: this.year,
          start_date:
            this.programSessionDataV3.session_travel_details[0].arrival_date ??
            this.programSessionDataV3?.academic_start_date, // TODO: What happens if session_travel_details > 1?
          end_date:
            this.programSessionDataV3.session_travel_details?.[0]
              ?.departure_date ?? this.programSessionDataV3?.academic_end_date, // TODO: What happens if session_travel_details > 1?
          end_date_is_final:
            this.programSessionDataV3.session_travel_details?.[0]
              ?.departure_date_is_final ?? false,
          start_date_is_final:
            this.programSessionDataV3.session_travel_details[0]
              .arrival_date_is_final ?? false, // TODO: What happens if session_travel_details > 1?
          host_institution: this.programSessionDataV3
            .session_hosting_institutions?.[0]?.entity?.name,
          current_address: this.profileData.current_address_line_1,
          current_city: this.profileData.current_address_city,
          current_state: this.profileData.current_address_state,
          current_country: this.profileData.current_address_country_id,
          current_post_code: this.profileData.current_address_postal_code,
          permanent_street: this.profileData.permanent_address_line_1,
          permanent_city: this.profileData.permanent_address_city,
          permanent_state_province: this.profileData.permanent_address_state,
          permanent_country: this.profileData.permanent_address_country_id,
          permanent_zip_postal_code: this.profileData
            .permanent_address_postal_code,
          cancellation_policy: this.isCustomizedSession
            ? true
            : this.cancellationPolicyCheckbox.policy,
          contract_number: this.appliedAgreement?.contract_number,
          product_name: "Application Deposit",
          price: 150,
          session: this.programSessionDataV3.terms?.[0]?.session ?? null, // TODO: What happens if terms > 1?
          is_application_fee: true,
          is_institution_pays: true,
          legacy_participant_account_id: this.customer_id,
          is_customized: this.isCustomizedSession,
          application_id: this.setApplicationId,
          billing_street: this.profileData.billing_address_line_1,
          billing_city: this.profileData.billing_address_city,
          billing_state_province: this.profileData.billing_address_state,
          billing_zip_postal_code: this.profileData.billing_address_postal_code,
          participant_id: this.currentUser?.participantId ?? "n/a",
          version: this.uiVersion || "v2",
        },
      };
    },
    async fetchOrderPricing() {
      const payload = toRaw(this.currentOrder);

      try {
        this.pricingData = await ordersService.getOrdersPricing({ payload });
      } catch (error) {
        console.error("Error fetching order pricing", error);
      }
    },
    determineTransactionId() {
      return "institution_pays_" + this.setApplicationId;
    },
    setCurrency() {
      return "USD";
    },
    async sendPurchaseEvent(pricingData) {
      if (!pricingData) return;
      const categories = [
        "Housing",
        "Internship",
        "Events",
        "Additional Fees",
        "Classes Academic Fees",
      ];

      categories.forEach((category) => {
        const itemsArrays = getCategoryItemArray(
          category,
          this.pricingData,
          this.currentOrder,
          this.programSessionDataV3
        );
        if (itemsArrays && itemsArrays.length > 0) {
          itemsArrays.forEach((item) => {
            trackPurchase(
              this.determineTransactionId(),
              this.depositAmount,
              this.setCurrency(),
              "not_set",
              [item]
            );
          });
        }
      });
    },
    async submitInstitutionPays() {
      this.hasErrors = false;
      this.$refs.paymentSubmitButton.startLoading();
      if (this.isReadOnly) {
        this.$emit("next-step");
        return;
      }
      try {
        this.validateDeadline();
        if (this.isPastDeadline) {
          this.$refs.paymentSubmitButton.stopLoading();
          return;
        }

        await this.checkForApplicationId();
        await this.validate();
        await this.formioValidateSubmission(
          this.formIoForm,
          this.createSubmissionDataForFormIo
        );
        if (!this.hasFormIoValidationErrors) {
          //Prevent duplicate submissions on Formio and Workato
          if (!this.submissionId) {
            if (this.stripePayDepositFormEnabled)
              await this.submitToPaymentService();
            //all good submit to form.io
            this.submitToFormIo(
              this.formIoForm,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                this.submissionId = response;
                // send avisor notification
                // TODO: Replace SF id later on
                this.sendNotifications(
                  "submit",
                  this.programSessionDataV3.salesforce_id
                );
                this.sendPurchaseEvent(this.pricingData);
                // Redirect to next steps
                this.$emit("update-order"); // Complete step and redirect
              }
            });
          }
        } else {
          this.$refs.paymentSubmitButton.stopLoading();
        }
      } catch (error) {
        this.hasErrors = true;
        Sentry.captureException(
          new Error(`Submitting application institution pays deposit failed.`),
          {
            tags: {
              error: error,
              application_id: this.setApplicationId,
            },
          }
        );
        this.$refs.paymentSubmitButton.stopLoading();
      }
    },
    async setIpedsId() {
      // Add zeros at the beginning until the university id is 8 characters long
      // Some universities from Learning service have missing zeros
      const opeId =
        String(this.userData.data?.schoolname?.value).padStart(8, 0) ?? "";

      return getApiClient()
        .get(`/home-university/ipeds/${opeId}`)
        .then((response) => {
          return response.data?.ipeds ?? null;
        })
        .catch(() => {
          return null;
        });
    },
  },
};
</script>
