<script setup>
import InternshipCard from "@/components/shared/Card/InternshipCard.vue";
import { provide, toRefs } from "vue";
import { useRouter } from "vue-router";

provide("cardType", "internship");
const router = useRouter();

const props = defineProps({
  displayInternship: {
    type: Object,
    required: true,
    default: () => {},
  },
  session: { type: Object, default: () => {} },
});

const { displayInternship } = toRefs(props);

const handleApplyEvent = (internship) => {
  const { href } = router.resolve({
    name: "configurator-new",
    params: { programPageId: internship.program_id },
    query: {
      session: internship.session_id,
      internships: [internship.id],
    },
  });
  window.open(href, "_blank");
};

const redirectToViewPage = (internship) => {
  const queries = {
    "internship-id": internship.internship_id,
    "program-id": internship.program_id,
    "session-id": internship.session_id,
  };

  let { href } = router.resolve({
    path: "/results/internships",
    query: queries,
  });

  window.open(href, "_blank");
};
</script>

<template>
  <div
    class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-12 px-5 lg:px-10 pb-18"
  >
    <InternshipCard
      v-for="internshipItem in displayInternship"
      :key="internshipItem?.id"
      :is-internship="true"
      :session="session"
      :program="internshipItem"
      event-category="Program Page Personalize CTA"
      class="internship-program-card lg:grid-cols-1 lg:max-w-sm min-w-[320px]"
      :show-image="false"
      :uppercase-name="true"
      :perks="internshipItem.perks"
      :show-bookmark="false"
      :flex-wrap="false"
      :company-size="internshipItem.company?.company_size"
      :placement_types="internshipItem.placement_types"
      :details-page-internship-bool="false"
      apply-label="Select Experience"
      @apply-clicked="handleApplyEvent(internshipItem)"
      @view-clicked="redirectToViewPage(internshipItem)"
    >
    </InternshipCard>
  </div>
</template>
