<script setup>
import { defineProps, computed, toRefs } from "vue";
import { imageTransformation } from "@/util/cloudinary";
import { CLOUDINARY_DEFAULT_WIDTH_IMAGES_RESOLUTION } from "@/constants.js";

const props = defineProps({
  cloudinaryImageUrl: {
    type: String,
    required: true,
    default: "",
  },
  wsizes: {
    type: Array,
    default: () => CLOUDINARY_DEFAULT_WIDTH_IMAGES_RESOLUTION,
  },
});

const { cloudinaryImageUrl, wsizes } = toRefs(props);

const images = computed(() => {
  const img = cloudinaryImageUrl.value;
  if (img) {
    return {
      srcset: wsizes.value
        .map(
          (size) =>
            `${imageTransformation({
              path: img,
              compress: true,
              width: size,
            })} ${size}w`
        )
        .join(", "),
      sizes: wsizes.value
        .map((size) => `(max-width: ${size}px) ${size}w`)
        .join(", "),
    };
  }
  return undefined;
});
</script>

<template>
  <img alt="" loading="lazy" :srcset="images?.srcset" :sizes="images?.sizes" />
</template>
