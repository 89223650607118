<script setup>
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import { useVModel } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { defineProps } from "vue";
import { useStore } from "vuex";
import OccurenceSection from "./OccurenceSection.vue";
import { formatDate } from "@/mixins/helpers";
import CreateEditOccurrenceModal from "../../../../modals/events/CreateEditOccurrenceModal.vue";

const props = defineProps({
  modelValue: {
    type: Object,
  },
  isPreview: {
    type: Boolean,
  },
  savedStatusActive: {
    type: Boolean,
  },
});

const emit = defineEmits([
  "update:modelValue",
  "update:upcomingOccurrences",
  "update:pastOccurrences",
]);
const events = useVModel(props, "modelValue", emit);
const isActive = computed(() => {
  return props.savedStatusActive ?? undefined;
});

const store = useStore();

const currentDisplayCountUpcoming = ref(10);
const currentDisplayCountPast = ref(10);
const createEditOccurrence = ref({});
const createEditModalOpen = ref(false);
const editOccurrence = ref(false);
const upcoming = ref(undefined);
const past = ref(undefined);

function openEditModal(occurrence) {
  createEditOccurrence.value = occurrence;
  createEditModalOpen.value = true;
  editOccurrence.value = true;
}

function openCreateModal() {
  // these cant be null - modal will break if null
  createEditOccurrence.value.event_id = events.value.id;
  createEditOccurrence.value.category_id = events.value.category_id;
  createEditOccurrence.value.subcategory_id = events.value.sub_category.id;
  createEditOccurrence.value.marketplace = events.value.marketplace;
  createEditModalOpen.value = true;
}

function closeCreateEditModal() {
  createEditModalOpen.value = false;
  if (createEditOccurrence.value?.id && !editOccurrence.value) {
    addNewOccurrence(createEditOccurrence.value);
  } else if (createEditOccurrence.value?.id) {
    updateExistingOccurrence(createEditOccurrence.value);
  }
  createEditOccurrence.value = {};
  editOccurrence.value = false;
}

const showMoreUpcoming = () => {
  currentDisplayCountUpcoming.value += 10;
};

const showMorePast = () => {
  currentDisplayCountPast.value += 10;
};

function addNewOccurrence(occurrence) {
  list_occurrences.value.push(occurrence);
}

function updateExistingOccurrence(occurrence) {
  if (isPastOccurrence(occurrence)) {
    updateExistingOccurrenceInArray(pastOccurrences.value, occurrence);
  } else {
    updateExistingOccurrenceInArray(upcomingOccurrences.value, occurrence);
  }
}

function updateExistingOccurrenceInArray(array, occurrence) {
  const updateEventIndex = array.findIndex((occ) => {
    occ.id === occurrence.id;
  });
  if (updateEventIndex !== -1) array[updateEventIndex] = occurrence;
}

const isPastOccurrence = (occurrence) => {
  const today = new Date();

  const endDate = new Date(occurrence?.end_date_time);
  
  const status = occurrence.occurrence_status?.name;
  const inOtherStatus = ["Draft"];
  const inUpcoming = ["Active", "Draft"];


  if (occurrence.end_date_time !== null) {
    return endDate.getTime() < today.getTime() && !inOtherStatus.includes(status);
  } else {
    return !inUpcoming.includes(status);
  }
};

const list_occurrences = computed(() => {
  return events.value.event_occurrences ?? [];
});

const sortedOccurrences = computed(() => {
  return list_occurrences.value.slice().sort((a, b) => {
    const aDate = new Date(a.start_date_time);
    const bDate = new Date(b.start_date_time);
    const aIsPast = isPastOccurrence(a);
    const bIsPast = isPastOccurrence(b);

    const statusPriority = {
      Active: 1,
      Draft: 2,
      Completed: 3,
      Inactive: 4,
      Archived: 4,
    };

    let inActiveorArchived = ["Inactive", "Archived"];

    if (!inActiveorArchived.includes(a.occurrence_status.name) && aIsPast) {
      a.occurrence_status.name = "Completed";
    }
    if (!inActiveorArchived.includes(b.occurrence_status.name) && bIsPast) {
      b.occurrence_status.name = "Completed";
    }

    if (aIsPast !== bIsPast) {
      return aIsPast ? 1 : -1;
    }

    if (
      statusPriority[a.occurrence_status.name] !==
      statusPriority[b.occurrence_status.name]
    ) {
      return (
        statusPriority[a.occurrence_status.name] -
        statusPriority[b.occurrence_status.name]
      );
    }

    return aDate - bDate;
  });
});

const upcomingOccurrences = computed(() => {
  const sorted = sortedOccurrences.value
    .filter((occ) => !isPastOccurrence(occ))
    .slice(0, currentDisplayCountUpcoming.value);
  return sorted;
});

const pastOccurrences = computed(() => {
  const today = new Date();
  return sortedOccurrences.value
    .filter((occ) => isPastOccurrence(occ))
    .slice(0, currentDisplayCountPast.value);
});
watch(
  () => upcomingOccurrences,
  (newVal) => {
    upcoming.value = newVal;
    store.commit("events/setOccurrences", {
      upcoming: upcoming.value,
      past: past.value,
    });
  }
);
watch(
  () => pastOccurrences,
  (newVal) => {
    past.value = newVal;
    store.commit("events/setOccurrences", {
      upcoming: upcoming.value,
      past: past.value,
    });
  }
);
</script>

<template>
  <div class="relative">
    <div class="p-6">
      <div class="flex flex-row justify-between">
        <h1 class="text-xl font-semibold mb-4 leading-6 text-gray-900">
          Occurrences
        </h1>
        <CreateEditOccurrenceModal
          :edit="editOccurrence"
          :event-name="events.title"
          :location="events.event_locations[0]?.city?.city"
          :default-time-zone="
            events.event_locations[0]?.address?.utc_offset_minutes
          "
          :model-value="createEditOccurrence"
          :open="createEditModalOpen"
          :enable-modal="isActive"
          :is-online="events.type === 'Online'"
          @close-modal="closeCreateEditModal"
          @open-modal="openCreateModal"
        />
      </div>
      <FormPanel
        :id="'upcomingOccurrences'"
        :title="'UPCOMING OCCURRENCES'"
        class="mt-6 border-b-0"
      >
        <template #content>
          <div
            class="text-blue-900 border-gray-200 rounded bg-white grid grid-cols-2 gap-10 mb-5"
          >
            <div
              v-for="occurrence in upcomingOccurrences"
              :key="occurrence?.id"
            >
              <OccurenceSection
                :event="events"
                :occurrence="occurrence"
                @edit-occurrence="openEditModal(occurrence)"
              />
            </div>
          </div>
          <button
            v-if="
              upcomingOccurrences.length <
              sortedOccurrences?.filter(
                (occ) => !isPastOccurrence(occ, new Date())
              ).length
            "
            @click="showMoreUpcoming"
          >
            Show More
          </button>
        </template>
      </FormPanel>
      <FormPanel
        :id="'pastOccurrences'"
        :title="'PAST OCCURRENCES'"
        class="mt-6 border-b-0"
      >
        <template #content>
          <div
            class="text-blue-900 border-gray-200 rounded bg-white grid grid-cols-2 gap-10 mb-5"
          >
            <div v-for="occurrence in pastOccurrences" :key="occurrence?.id">
              <OccurenceSection :event="events" :occurrence="occurrence" />
            </div>
          </div>
          <button
            v-if="
              pastOccurrences.length <
              sortedOccurrences?.filter((occ) =>
                isPastOccurrence(occ, new Date())
              ).length
            "
            @click="showMorePast"
          >
            Show More
          </button>
        </template>
      </FormPanel>
    </div>
  </div>
</template>
