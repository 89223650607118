<template>
  <div
    class="flex-none mt-4 lg:mt-0 lg:flex lg:justify-end lg:w-32"
    :class="{ 'mt-0': hideLabel }"
  >
    <label class="flex xs:items-center" @click="emitToggleEvent">
      <span v-if="!hideLabel" class="mr-4">Opt In</span>
      <span
        data-cy="opt-in-check"
        :data-testid="toggleSelected ? 'financial-aid-toogle-yes' : 'financial-aid-toogle-no'"
        :class="[
          toggleSelected
            ? `${primaryColorClassBG} justify-end`
            : 'bg-gray-300 justify-start',
        ]"
        class="flex items-center flex-none w-12 h-8 mt-1 transition-all duration-200 ease-in-out rounded-full shadow-inner cursor-pointer xs:mt-0 justify-start"
      >
        <span
          class="flex-none block w-6 h-6 mx-1 bg-white rounded-full shadow"
        />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "OptInToggler",
  props: {
    toggleSelected: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggleValue"],
  methods: {
    emitToggleEvent() {
      this.$emit("toggleValue");
    },
  },
};
</script>
