<template>
  <div>
    <i :id="id" :class="classes" :data-feather="iconName"></i>
  </div>
</template>

<script>
import feather from "feather-icons";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
export default {
  name: "FeatherIcon",
  props: {
    id: {
      type: String,
      default: "anId",
    },
    iconName: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
  mounted() {
    feather.replace();
    this.initTippy();
  },
  methods: {
    initTippy() {
      if (!this.message) return;
      let element = document.getElementById(this.id);
      let parent = element.parentElement;
      tippy(element, {
        content: this.message,
        theme: "light",
        role: "tooltip",
        appendTo: parent,
        aria: {
          content: "describedby",
          expanded: "auto",
        },
      });
    },
  },
};
</script>
