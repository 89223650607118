export const VERSIONS = {
  v2: "v2",
  v3: "v3",
};

export const CONSTANT_KEYS = Object.freeze({
  PASSWORD_STORAGE_KEY: "pending-forgot-password",
  RESET_TOKEN_STORAGE_KEY: "resettoken",
  RESET_EMAIL: "reset-email",
  UI_VERSION: "v3",
});

export const APICompany = {
  companyEmail: "@apiexperience.com",
};

export const DURATION_IN_DAYS_THRESHOLD = 30;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAYS_OF_WEEK = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const SEXES = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const GENDER_IDENTITIES = [
  {
    id: "1",
    label: "Woman",
    value: "woman",
  },
  {
    id: "2",
    label: "Man",
    value: "man",
  },
  {
    id: "3",
    label: "Transgender Woman",
    value: "transgenderWoman",
  },
  {
    id: "4",
    label: "Transgender Man",
    value: "transgenderMan",
  },
  {
    id: "5",
    label: "Non-Binary",
    value: "nonBinary",
  },
  {
    id: "6",
    label: "Agender/I don’t identify with any gender",
    value: "agender",
  },
  {
    id: "7",
    label: "A gender not listed here",
    value: "other",
  },
];

export const GENDERS = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Non-binary",
    value: "nonBinary",
  },
];

export const GENDERSV3 = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Famale",
  },
  {
    label: "Non-Binary",
    value: "Non-Binary",
  },
];

export const ACADEMIC_YEARS = [
  {
    oldValue: "Univesity Freshman",
    value: "universityFirstYear",
    label: "University First Year",
  },
  {
    oldValue: "universitySophomore",
    value: "universitySecondYear",
    label: "University Second Year",
  },
  {
    oldValue: "University Junior",
    value: "universityThirdYear",
    label: "University Third Year",
  },
  {
    oldValue: "University Senior",
    value: "universityFourthYear",
    label: "University Fourth Year",
  },
  {
    value: "graduateStudent",
    label: "Graduate Student",
  },
  {
    oldValue: "notCurrentlyEnrolled",
    value: "notEnrolled",
    label: "Not Enrolled",
  },
  {
    oldValue: "highSchoolFreshman",
    value: "highSchoolFirstYear",
    label: "High School First Year",
  },
  {
    oldValue: "highSchoolSophmore",
    value: "highSchoolSecondYear",
    label: "High School Second Year",
  },
  {
    oldValue: "highSchoolJunior",
    value: "highSchoolThirdYear",
    label: "High School Third Year",
  },
  {
    oldValue: "highSchoolSenior",
    value: "highSchoolFourthYear",
    label: "High School Fourth Year",
  },
  {
    oldValue: "risingFreshman",
    value: "incomingUndergraduateStudent",
    label: "Incoming Undergraduate Student",
  },
];

export const LANGUAGES = [
  {
    label: "Native ",
    value: "native",
  },
  {
    label: "Advanced",
    value: "advanced",
  },
  {
    label: "Intermediate",
    value: "intermediate",
  },
  {
    label: "Beginner",
    value: "beginner",
  },
  {
    label: "No Background",
    value: "noBackground",
  },
];

export const PLACEMENT_TYPES = ["Project-based Internship", "Internship"];

export const SESSIONS = ["Fall", "Spring", "Summer"];

export const MAJORS = [
  {
    label: "Architecture",
    value: "Architecture",
  },
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Agribusiness",
    value: "Agribusiness",
  },
  {
    label: "American Studies",
    value: "American Studies",
  },
  {
    label: "Animal Science",
    value: "Animal Science",
  },
  {
    label: "Agroecology",
    value: "Agroecology",
  },
  {
    label: "Agriculture Education",
    value: "Agriculture Education",
  },
  {
    label: "Plant Science",
    value: "Plant Science",
  },
  {
    label: "General",
    value: "General",
  },
  {
    label: "Area, Ethnic, and Cultural Studies",
    value: "Area, Ethnic, and Cultural Studies",
  },
  {
    label: "Latin American Studies",
    value: "Latin American Studies",
  },
  {
    label: "European Studies",
    value: "European Studies",
  },
  {
    label: "Judaic and Near Eastern Studies",
    value: "Judaic and Near Eastern Studies",
  },
  {
    label: "Cultural Studies, General",
    value: "Cultural Studies, General",
  },
  {
    label: "Biological and Biomedical Sciences",
    value: "Biological and Biomedical Sciences",
  },
  {
    label: "Anatomy/Physiology",
    value: "Anatomy/Physiology",
  },
  {
    label: "Biochemistry",
    value: "Biochemistry",
  },
  {
    label: "Biology",
    value: "Biology",
  },
  {
    label: "Botany, Ecology, and Biodiversity",
    value: "Botany, Ecology, and Biodiversity",
  },
  {
    label: "Marine Biology",
    value: "Marine Biology",
  },
  {
    label: "Microbiology",
    value: "Microbiology",
  },
  {
    label: "Neuroscience",
    value: "Neuroscience",
  },
  {
    label: "Zoology",
    value: "Zoology",
  },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Accounting and Related Services",
    value: "Accounting and Related Services",
  },
  {
    label: "Business Administration, Management and Operations",
    value: "Business Administration, Management and Operations",
  },
  {
    label: "Entrepreneurial and Small Business Operations",
    value: "Entrepreneurial and Small Business Operations",
  },
  {
    label: "Finance, Applied Economics, and Financial Management Services",
    value: "Finance, Applied Economics, and Financial Management Services",
  },
  {
    label: "Hospitality and Tourism Management",
    value: "Hospitality and Tourism Management",
  },
  {
    label: "Human Resources Management and Services",
    value: "Human Resources Management and Services",
  },
  {
    label: "International Business",
    value: "International Business",
  },
  {
    label: "Management Information Systems",
    value: "Management Information Systems",
  },
  {
    label: "Marketing and Advertising",
    value: "Marketing and Advertising",
  },
  {
    label: "Sports and Recreation Management",
    value: "Sports and Recreation Management",
  },
  {
    label: "Communication and Media Studies",
    value: "Communication and Media Studies",
  },
  {
    label: "Computer and Information Sciences",
    value: "Computer and Information Sciences",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Engineering",
    value: "Engineering",
  },
  {
    label: "Aerospace, Aeronautical and Astronautical Engineering",
    value: "Aerospace, Aeronautical and Astronautical Engineering",
  },
  {
    label: "Agricultural Engineering",
    value: "Agricultural Engineering",
  },
  {
    label: "Bioengineering/Biomedical/Medical Engineering",
    value: "Bioengineering/Biomedical/Medical Engineering",
  },
  {
    label: "Chemical Engineering",
    value: "Chemical Engineering",
  },
  {
    label: "Civil Engineering",
    value: "Civil Engineering",
  },
  {
    label: "Computer and Electrical Engineering",
    value: "Computer and Electrical Engineering",
  },
  {
    label: "Computer and Electronic Engineering",
    value: "Computer and Electronic Engineering",
  },
  {
    label: "Environmental/Environmental Health Engineering",
    value: "Environmental/Environmental Health Engineering",
  },
  {
    label: "Industrial Engineering",
    value: "Industrial Engineering",
  },
  {
    label: "Mechanical Engineering",
    value: "Mechanical Engineering",
  },
  {
    label: "Petroleum Engineering",
    value: "Petroleum Engineering",
  },
  {
    label: "English Language and Literature",
    value: "English Language and Literature",
  },
  {
    label: "Language",
    value: "Language",
  },
  {
    label: "Literature",
    value: "Literature",
  },
  {
    label: "Writing",
    value: "Writing",
  },
  {
    label: "Environmental, Natural Resource, and Conservation Studies",
    value: "Environmental, Natural Resource, and Conservation Studies",
  },
  {
    label: "Foreign Languages, Literatures, and Linguistics",
    value: "Foreign Languages, Literatures, and Linguistics",
  },
  {
    label: "Arabic Language and Literature",
    value: "Arabic Language and Literature",
  },
  {
    label: "Chinese Language and Literature",
    value: "Chinese Language and Literature",
  },
  {
    label: "Celtic Languages and Literatures",
    value: "Celtic Languages and Literatures",
  },
  {
    label: "Classics and Classical Languages and Literatures",
    value: "Classics and Classical Languages and Literatures",
  },
  {
    label: "Comparative and Related Language Studies",
    value: "Comparative and Related Language Studies",
  },
  {
    label: "Eastern European Languages and Literatures",
    value: "Eastern European Languages and Literatures",
  },
  {
    label: "German Language and Literature",
    value: "German Language and Literature",
  },
  {
    label: "Spanish Language and Literature",
    value: "Spanish Language and Literature",
  },
  {
    label: "French Language and Literature",
    value: "French Language and Literature",
  },
  {
    label: "Italian Language and Literature",
    value: "Italian Language and Literature",
  },
  {
    label: "Linguistics",
    value: "Linguistics",
  },
  {
    label: "Health Sciences",
    value: "Health Sciences",
  },
  {
    label: "Food Science/Nutrition",
    value: "Food Science/Nutrition",
  },
  {
    label: "Nursing",
    value: "Nursing",
  },
  {
    label: "Public Health",
    value: "Public Health",
  },
  {
    label: "Sports and Exercise Science/Kinesiology",
    value: "Sports and Exercise Science/Kinesiology",
  },
  {
    label: "History",
    value: "History",
  },
  {
    label: "Mathematics and Statistics",
    value: "Mathematics and Statistics",
  },
  {
    label: "Philosophy",
    value: "Philosophy",
  },
  {
    label: "Physical Sciences",
    value: "Physical Sciences",
  },
  {
    label: "Astronomy and Astrophysics",
    value: "Astronomy and Astrophysics",
  },
  {
    label: "Atmospheric Sciences and Meteorology",
    value: "Atmospheric Sciences and Meteorology",
  },
  {
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    label: "Geological and Earth Sciences/Geosciences",
    value: "Geological and Earth Sciences/Geosciences",
  },
  {
    label: "Materials Sciences",
    value: "Materials Sciences",
  },
  {
    label: "Physics",
    value: "Physics",
  },
  {
    label: "Religious Studies",
    value: "Religious Studies",
  },
  {
    label: "Social Sciences",
    value: "Social Sciences",
  },
  {
    label: "Anthropology and Archaeology",
    value: "Anthropology and Archaeology",
  },
  {
    label: "Criminology, Law, and Legal Studies",
    value: "Criminology, Law, and Legal Studies",
  },
  {
    label: "Economics",
    value: "Economics",
  },
  {
    label: "Geography and Cartography",
    value: "Geography and Cartography",
  },
  {
    label: "International Relations and National Security Studies",
    value: "International Relations and National Security Studies",
  },
  {
    label: "Political Science and Government",
    value: "Political Science and Government",
  },
  {
    label: "Psychology",
    value: "Psychology",
  },
  {
    label: "Sociology, Social Policy, and Social Work",
    value: "Sociology, Social Policy, and Social Work",
  },
  {
    label: "Women, Gender and Sexuality Studies",
    value: "Women, Gender and Sexuality Studies",
  },
  {
    label: "Visual and Performing Arts",
    value: "Visual and Performing Arts",
  },
  {
    label: "Art History",
    value: "Art History",
  },
  {
    label: "Acting, Theater Arts, and Technical Theater",
    value: "Acting, Theater Arts, and Technical Theater",
  },
  {
    label: "Arts, Entertainment,and Media Management",
    value: "Arts, Entertainment,and Media Management",
  },
  {
    label: "Dance",
    value: "Dance",
  },
  {
    label: "Design, Fashion, and Applied Arts",
    value: "Design, Fashion, and Applied Arts",
  },
  {
    label: "Film/Video and Photographic Arts",
    value: "Film/Video and Photographic Arts",
  },
  {
    label: "Fine and Studio Arts",
    value: "Fine and Studio Arts",
  },
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Undecided",
    value: "Undecided",
  },
];

export const INSTRUCTION_LANGUAGES = [
  {
    value: "arabic",
    label: "Arabic",
  },
  {
    value: "basque",
    label: "Basque",
  },
  {
    value: "catalan",
    label: "Catalan",
  },
  {
    value: "chinese",
    label: "Chinese",
  },
  {
    value: "croatian",
    label: "Croatian",
  },
  {
    value: "czech",
    label: "Czech",
  },
  {
    value: "dzongkha",
    label: "Dzongkha",
  },
  {
    value: "english",
    label: "English",
  },
  {
    value: "french",
    label: "French",
  },
  {
    value: "german",
    label: "German",
  },
  {
    value: "hungarian",
    label: "Hungarian",
  },
  {
    value: "italian",
    label: "Italian",
  },
  {
    value: "latin",
    label: "Latin",
  },
  {
    value: "mandarin",
    label: "Mandarin",
  },
  {
    value: "polish",
    label: "Polish",
  },
  {
    value: "portuguese",
    label: "Portuguese",
  },
  {
    value: "spanish",
    label: "Spanish",
  },
];

export const INTERNSHIP_AREAS = [
  {
    name: "Accounting",
  },
  {
    name: "Addiction",
  },
  {
    name: "Advertising",
  },
  {
    name: "Advocacy",
  },
  {
    name: "Animal Welfare",
  },
  {
    name: "Animation",
  },
  {
    name: "Architecture",
  },
  {
    name: "Asset Management",
  },
  {
    name: "Autism",
  },
  {
    name: "Banking",
  },
  {
    name: "Biology",
  },
  {
    name: "Broadcasting",
  },
  {
    name: "Business",
  },
  {
    name: "Business Administration",
  },
  {
    name: "Business Development",
  },
  {
    name: "Business Operations",
  },
  {
    name: "Charities",
  },
  {
    name: "Chemical Engineering",
  },
  {
    name: "Chemistry",
  },
  {
    name: "Civil Engineering",
  },
  {
    name: "Climate Change",
  },
  {
    name: "Coaching",
  },
  {
    name: "Communications",
  },
  {
    name: "Computer Science",
  },
  {
    name: "Conservation",
  },
  {
    name: "Construction",
  },
  {
    name: "Copy Writing",
  },
  {
    name: "Criminology",
  },
  {
    name: "Culinary",
  },
  {
    name: "Curating",
  },
  {
    name: "Customer Service",
  },
  {
    name: "Data Analytics",
  },
  {
    name: "Ecology",
  },
  {
    name: "Economics",
  },
  {
    name: "Education Administration",
  },
  {
    name: "Elementary Education",
  },
  {
    name: "Engineering",
  },
  {
    name: "Entomology",
  },
  {
    name: "Environmental",
  },
  {
    name: "Environmental Planning",
  },
  {
    name: "Environmental Policy",
  },
  {
    name: "Event Management",
  },
  {
    name: "Event Planning",
  },
  {
    name: "Exercise Science",
  },
  {
    name: "Family Planning",
  },
  {
    name: "Fashion",
  },
  {
    name: "Film",
  },
  {
    name: "Finance",
  },
  {
    name: "Financial Analyst",
  },
  {
    name: "Forestry",
  },
  {
    name: "Geography",
  },
  {
    name: "Graphic Design",
  },
  {
    name: "Health",
  },
  {
    name: "Hospitality",
  },
  {
    name: "Hotel Management",
  },
  {
    name: "Human Rights",
  },
  {
    name: "Information Technology",
  },
  {
    name: "Interior Design",
  },
  {
    name: "International Development",
  },
  {
    name: "International Trade",
  },
  {
    name: "Investment Banking",
  },
  {
    name: "Journalism",
  },
  {
    name: "Landscape Design",
  },
  {
    name: "Legal Administration",
  },
  {
    name: "Legal Advocacy",
  },
  {
    name: "Legal Research",
  },
  {
    name: "Logistics",
  },
  {
    name: "Marketing",
  },
  {
    name: "Mental Health",
  },
  {
    name: "Natural Resources",
  },
  {
    name: "Neuroscience",
  },
  {
    name: "NGO",
  },
  {
    name: "Painting",
  },
  {
    name: "Performing Arts",
  },
  {
    name: "Photography",
  },
  {
    name: "Physical Education",
  },
  {
    name: "Preschool Education",
  },
  {
    name: "Product Design",
  },
  {
    name: "Psychology",
  },
  {
    name: "Public Health",
  },
  {
    name: "Public Relations",
  },
  {
    name: "Research",
  },
  {
    name: "Resorts",
  },
  {
    name: "Restaurant Management",
  },
  {
    name: "Sales",
  },
  {
    name: "Secondary Education",
  },
  {
    name: "Social Media",
  },
  {
    name: "Social Services",
  },
  {
    name: "Social Welfare",
  },
  {
    name: "Special Education",
  },
  {
    name: "Sports Events",
  },
  {
    name: "Sports Journalism",
  },
  {
    name: "Sports Management",
  },
  {
    name: "Sports Marketing",
  },
  {
    name: "Startups",
  },
  {
    name: "Structural Engineering",
  },
  {
    name: "Sustainability",
  },
  {
    name: "Taxes",
  },
  {
    name: "Theater",
  },
  {
    name: "Therapy",
  },
  {
    name: "Tourism Marketing",
  },
  {
    name: "Vet Clinic",
  },
  {
    name: "Website Development",
  },
  {
    name: "Wildlife Parks",
  },
  {
    name: "Youth Work",
  },
  {
    name: "Zoo Keeping",
  },
];

export const RANKS_OF_IMPORTANCE = [
  {
    name: "Size of the company",
    value: "sizeOfTheCompany",
  },
  {
    name: "Working independently",
    value: "workingIndependently",
  },
  {
    name: "Working as part of a team",
    value: "workingAsPartOfATeam",
  },
  {
    name: "Specific internship project and duties",
    value: "specificInternshipProjectAndDuties",
  },
  {
    name: "Destination",
    value: "destination",
  },
];

export const AREAS = [
  {
    label: "Agriculture Agriculture Operations and Related Sciences",
    value: "agricultureAgricultureOperationsAndRelatedSciences",
  },
  {
    label: "Natural Resources and Conservation",
    value: "naturalResourcesAndConservation",
  },
  {
    label: "Architecture and Related Services",
    value: "architectureAndRelatedServices",
  },
  {
    label: "Area Ethnic Cultural and Gender Studies",
    value: "areaEthnicCulturalAndGenderStudies",
  },
  {
    label: "Communication Journalism and Related Programs",
    value: "communicationJournalismAndRelatedPrograms",
  },
  {
    label: "Communications Technologies Technicians and Support Services",
    value: "communicationsTechnologiesTechniciansAndSupportServices",
  },
  {
    label: "Computer and Information Sciences and Support Services",
    value: "computerAndInformationSciencesAndSupportServices",
  },
  {
    label: "Personal and Culinary Services",
    value: "personalAndCulinaryServices",
  },
  {
    label: "Education",
    value: "education",
  },
  {
    label: "Engineering",
    value: "engineering",
  },
  {
    label: "Engineering, Technologies, Technicians",
    value: "engineeringTechnologiesTechnicians",
  },
  {
    label: "Foreign Languages Literatures and Linguistics",
    value: "foreignLanguagesLiteraturesAndLinguistics",
  },
  {
    label: "Family and Consumer Sciences Human Sciences",
    value: "familyAndConsumerSciencesHumanSciences",
  },
  {
    label: "Legal Professions and Studies",
    value: "legalProfessionsAndStudies",
  },
  {
    label: "English Language and Literature Letters",
    value: "englishLanguageAndLiteratureLetters",
  },
  {
    label: "Liberal Arts and Sciences General Studies and Humanities",
    value: "liberalArtsAndSciencesGeneralStudiesAndHumanities",
  },
  {
    label: "Library Science",
    value: "libraryScience",
  },
  {
    label: "Biological and Biomedical Sciences",
    value: "biologicalAndBiomedicalSciences",
  },
  {
    label: "Mathematics and Statistics",
    value: "mathematicsAndStatistics",
  },
  {
    label: "Reserve Officer Training Corps JrotcRotc",
    value: "reserveOfficerTrainingCorpsJrotcRotc",
  },
  {
    label: "MilitaryTechnologies",
    value: "militaryTechnologies",
  },
  {
    label: "MultiInterdisciplinary Studies",
    value: "multiInterdisciplinaryStudies",
  },
  {
    label: "Parks Recreation Leisure and Fitness Studies",
    value: "parksRecreationLeisureAndFitnessStudies",
  },
  {
    label: "Basic Skills",
    value: "basicSkills",
  },
  {
    label: "Citizenship Activities",
    value: "citizenshipActivities",
  },
  {
    label: "Health Related Knowledge and Skills",
    value: "healthRelatedKnowledgeAndSkills",
  },
  {
    label: "Interpersonal and Social Skills",
    value: "interpersonalAndSocialSkills",
  },
  {
    label: "Leisure and Recreational Activities",
    value: "leisureAndRecreationalActivities",
  },
  {
    label: "Personal Awareness and Self Improvement",
    value: "personalAwarenessAndSelfImprovement",
  },
  {
    label: "Philosophy and Religious Studies",
    value: "philosophyAndReligiousStudies",
  },
  {
    label: "Theology and Religious Vocations",
    value: "theologyAndReligiousVocations",
  },
  {
    label: "Physical Sciences",
    value: "physicalSciences",
  },
  {
    label: "Science Technologies Technicians",
    value: "scienceTechnologiesTechnicians",
  },
  {
    label: "Psychology",
    value: "psychology",
  },
  {
    label: "Security and Protective Services",
    value: "securityAndProtectiveServices",
  },
  {
    label: "Public Administration and Social Service Professions",
    value: "publicAdministrationAndSocialServiceProfessions",
  },
  {
    label: "Social Sciences",
    value: "socialSciences",
  },
  {
    label: "Construction Trades",
    value: "constructionTrades",
  },
  {
    label: "Mechanic and Repair Technologies Technicians",
    value: "mechanicAndRepairTechnologiesTechnicians",
  },
  {
    label: "Precision Production",
    value: "precisionProduction",
  },
  {
    label: "Transportation and Materials Moving",
    value: "transportationAndMaterialsMoving",
  },
  {
    label: "Visual and Performing Arts",
    value: "visualAndPerformingArts",
  },
  {
    label: "Health Professions and Related Clinical Sciences",
    value: "healthProfessionsAndRelatedClinicalSciences",
  },
  {
    label: "Business Management Marketing and Related Support Services",
    value: "businessManagementMarketingAndRelatedSupportServices",
  },
  {
    label: "High School Secondary Diplomas and Certificates",
    value: "highSchoolSecondaryDiplomasAndCertificates",
  },
  {
    label: "History",
    value: "history",
  },
  {
    label: "Residency Programs",
    value: "residencyPrograms",
  },
];

export const POST_FORM_RULES = {
  Housing: [
    "Housing",
    "Accommodation Style Preference",
    "Single Room Preference",
    "Requesting a Roommate",
    "Declining a Roommate",
    "Requesting a Housemate",
    "Ranking Your Housing Preference",
    "Housing Questionnaire",
  ],
  "Academic Information": ["Birth Certificate Copy"],
  "Participant Agreement": [
    "Virtual Participant Agreement",
    "Study and Intern Participant Agreement",
  ],
  "Code Of Conduct": [
    "Intern Code of Conduct",
    "Study Code of Conduct",
    "Virtual Study Code of Conduct",
    "Virtual Intern Code of Conduct",
  ],
  "Travel Documents": [
    "Visa Upload",
    "Visa Appointment Information (online)",
    "Visa Appointment Information (In Person)",
    "Passport Info",
    "Group Visa Information",
  ],
  "Travel Plans": [
    "CP Departure Guidelines",
    "Arrival Guidelines",
    "Arrival FAQ",
    "Departure Guidelines",
    "Student Travel Arrangements",
    "CP Arrival Guidelines - Group Flight",
    "CP Arrival FAQ",
  ],
  "Badge Interests": ["Badge Interests"],
  "Customized Code Of Conduct": ["CP Code of Conduct- Study"],
  "CP Participant Agreement Form - No Insurance or Direct Pay": [
    "CP Participant Agreement Form - No Insurance or Direct Pay",
  ],
};

export const APPROVAL_STATUS = [
  {
    label: "Approve",
    value: "Approve",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const DISCIPLINARY_RECORDS_UNIVERSITIES = [
  {
    name: "Bridgewater State University",
    email: "communitystandards@bridgew.edu",
  },
  {
    name: "Clemson University",
    email: "ljclay@clemson.edu",
  },
  {
    name: "San Diego State University",
    email: "csrr@sdsu.edu",
  },
  {
    name: "University of Nebraska-Lincoln",
    email: "studentconduct@unl.edu",
  },
  {
    name: "University of South Carolina-Columbia",
    email: "saosc@mailbox.sc.edu",
  },
];

export const AIRLINES = [
  { label: "Aegean Airlines", value: "Aegean Airlines" },
  { label: "Aer Lingus", value: "Aer Lingus" },
  { label: "Aeroflot", value: "Aeroflot" },
  { label: "Aerolineas Argentinas", value: "Aerolineas Argentinas" },
  { label: "Aeromexico", value: "Aeromexico" },
  { label: "Air Arabia", value: "Air Arabia" },
  { label: "Air Astana", value: "Air Astana" },
  { label: "Air Austral", value: "Air Austral" },
  { label: "Air Baltic", value: "Air Baltic" },
  { label: "Air Belgium", value: "Air Belgium" },
  { label: "Air Canada", value: "Air Canada" },
  { label: "Air Caraibes", value: "Air Caraibes" },
  { label: "Air China", value: "Air China" },
  { label: "Air Corsica", value: "Air Corsica" },
  { label: "Air Dolomiti", value: "Air Dolomiti" },
  { label: "Air Europa", value: "Air Europa" },
  { label: "Air France", value: "Air France" },
  { label: "Air India", value: "Air India" },
  { label: "Air India Express", value: "Air India Express" },
  { label: "Air Macau", value: "Air Macau" },
  { label: "Air Malta", value: "Air Malta" },
  { label: "Air Mauritius", value: "Air Mauritius" },
  { label: "Air Namibia", value: "Air Namibia" },
  { label: "Air New Zealand", value: "Air New Zealand" },
  { label: "Air North", value: "Air North" },
  { label: "Air Seoul", value: "Air Seoul" },
  { label: "Air Serbia", value: "Air Serbia" },
  { label: "Air Tahiti Nui", value: "Air Tahiti Nui" },
  { label: "Air Transat", value: "Air Transat" },
  { label: "Air Vanuatu", value: "Air Vanuatu" },
  { label: "AirAsia", value: "AirAsia" },
  { label: "AirAsia X", value: "AirAsia X" },
  { label: "Aircalin", value: "Aircalin" },
  { label: "Alaska Airlines", value: "Alaska Airlines" },
  { label: "Allegiant", value: "Allegiant" },
  { label: "American Airlines", value: "American Airlines" },
  { label: "ANA", value: "ANA" },
  { label: "Asiana", value: "Asiana" },
  { label: "Austrian", value: "Austrian" },
  { label: "Avianca", value: "Avianca" },
  { label: "Norse Atlantic Airways", value: "Norse Atlantic Airways" },
  { label: "Azerbaijan Hava Yollary", value: "Azerbaijan Hava Yollary" },
  { label: "Azores Airlines", value: "Azores Airlines" },
  { label: "Azul", value: "Azul" },
  { label: "Bamboo Airways", value: "Bamboo Airways" },
  { label: "Bangkok Airways", value: "Bangkok Airways" },
  { label: "British Airways", value: "British Airways" },
  { label: "Brussels Airlines", value: "Brussels Airlines" },
  { label: "Caribbean Airlines", value: "Caribbean Airlines" },
  { label: "Cathay Dragon", value: "Cathay Dragon" },
  { label: "Cathay Pacific", value: "Cathay Pacific" },
  { label: "Cayman Airways", value: "Cayman Airways" },
  { label: "CEBU Pacific Air", value: "CEBU Pacific Air" },
  { label: "China Airlines", value: "China Airlines" },
  { label: "China Eastern", value: "China Eastern" },
  { label: "China Southern", value: "China Southern" },
  { label: "Condor", value: "Condor" },
  { label: "Copa Airlines", value: "Copa Airlines" },
  { label: "Croatia Airlines", value: "Croatia Airlines" },
  { label: "Czech Airlines", value: "Czech Airlines" },
  { label: "Delta", value: "Delta" },
  { label: "easyJet", value: "easyJet" },
  { label: "Edelweiss Air", value: "Edelweiss Air" },
  { label: "Egyptair", value: "Egyptair" },
  { label: "EL AL", value: "EL AL" },
  { label: "Emirates", value: "Emirates" },
  { label: "Ethiopian Airlines", value: "Ethiopian Airlines" },
  { label: "Etihad", value: "Etihad" },
  { label: "Eurowings", value: "Eurowings" },
  { label: "EVA Air", value: "EVA Air" },
  { label: "Fiji Airways", value: "Fiji Airways" },
  { label: "Finnair", value: "Finnair" },
  { label: "flydubai", value: "flydubai" },
  { label: "FlyOne", value: "FlyOne" },
  { label: "French bee", value: "French bee" },
  { label: "Frontier", value: "Frontier" },
  { label: "Garuda Indonesia", value: "Garuda Indonesia" },
  { label: "Gol", value: "Gol" },
  { label: "Gulf Air", value: "Gulf Air" },
  { label: "Hainan Airlines", value: "Hainan Airlines" },
  { label: "Hawaiian Airlines", value: "Hawaiian Airlines" },
  { label: "Helvetic Airways", value: "Helvetic Airways" },
  { label: "HK Express", value: "HK Express" },
  { label: "Hong Kong Airlines", value: "Hong Kong Airlines" },
  { label: "Iberia", value: "Iberia" },
  { label: "Icelandair", value: "Icelandair" },
  { label: "ITA Airways", value: "ITA Airways" },
  { label: "IndiGo Airlines", value: "IndiGo Airlines" },
  { label: "InterJet", value: "InterJet" },
  { label: "Japan Airlines", value: "Japan Airlines" },
  { label: "Jeju Air", value: "Jeju Air" },
  { label: "Jet2", value: "Jet2" },
  { label: "JetBlue", value: "JetBlue" },
  { label: "Jetstar", value: "Jetstar" },
  { label: "Jin Air", value: "Jin Air" },
  { label: "Kenya Airways", value: "Kenya Airways" },
  { label: "KLM", value: "KLM" },
  { label: "Korean Air", value: "Korean Air" },
  { label: "Kulula", value: "Kulula" },
  { label: "La Compagnie", value: "La Compagnie" },
  { label: "LATAM", value: "LATAM" },
  { label: "Lion Airlines", value: "Lion Airlines" },
  { label: "LOT Polish Airlines", value: "LOT Polish Airlines" },
  { label: "Lufthansa", value: "Lufthansa" },
  { label: "Luxair", value: "Luxair" },
  { label: "Malaysia Airlines", value: "Malaysia Airlines" },
  { label: "Mango", value: "Mango" },
  { label: "Middle East Airlines", value: "Middle East Airlines" },
  { label: "Nok Air", value: "Nok Air" },
  { label: "Nordwind Airlines", value: "Nordwind Airlines" },
  {
    label: "Norwegian Air International",
    value: "Norwegian Air International",
  },
  { label: "Norwegian Air Shuttle", value: "Norwegian Air Shuttle" },
  { label: "Norwegian Air Sweden", value: "Norwegian Air Sweden" },
  { label: "Norwegian Air UK", value: "Norwegian Air UK" },
  { label: "Other", value: "other" },
  { label: "Oman Air", value: "Oman Air" },
  {
    label: "Pakistan International Airlines",
    value: "Pakistan International Airlines",
  },
  { label: "Peach", value: "Peach" },
  { label: "Pegasus Airlines", value: "Pegasus Airlines" },
  { label: "Philippine Airlines", value: "Philippine Airlines" },
  { label: "Porter", value: "Porter" },
  { label: "Qantas", value: "Qantas" },
  { label: "Qatar Airways", value: "Qatar Airways" },
  { label: "Regional Express", value: "Regional Express" },
  { label: "Rossiya - Russian Airlines", value: "Rossiya - Russian Airlines" },
  { label: "Royal Air Maroc", value: "Royal Air Maroc" },
  { label: "Royal Brunei", value: "Royal Brunei" },
  { label: "Royal Jordanian", value: "Royal Jordanian" },
  { label: "RwandAir", value: "RwandAir" },
  { label: "Ryanair", value: "Ryanair" },
  { label: "S7 Airlines", value: "S7 Airlines" },
  {
    label: "SAS (Scandinavian Airlines)",
    value: "SAS (Scandinavian Airlines)",
  },
  { label: "Saudia", value: "Saudia" },
  { label: "Scoot Airlines", value: "Scoot Airlines" },
  { label: "Shanghai Airlines", value: "Shanghai Airlines" },
  { label: "Silkair", value: "Silkair" },
  { label: "Silver", value: "Silver" },
  { label: "Singapore Airlines", value: "Singapore Airlines" },
  { label: "Skylanes", value: "Skylanes" },
  { label: "South African Airways", value: "South African Airways" },
  { label: "Southwest", value: "Southwest" },
  { label: "SpiceJet", value: "SpiceJet" },
  { label: "Spirit", value: "Spirit" },
  { label: "Spring Airlines", value: "Spring Airlines" },
  { label: "Spring Japan", value: "Spring Japan" },
  { label: "SriLankan Airlines", value: "SriLankan Airlines" },
  { label: "Sun Country", value: "Sun Country" },
  { label: "Sunclass Airlines", value: "Sunclass Airlines" },
  { label: "Sunwing", value: "Sunwing" },
  { label: "SWISS", value: "SWISS" },
  { label: "Swoop", value: "Swoop" },
  { label: "TAAG", value: "TAAG" },
  { label: "TACA", value: "TACA" },
  { label: "TAP Portugal", value: "TAP Portugal" },
  { label: "THAI", value: "THAI" },
  { label: "tigerair Australia", value: "tigerair Australia" },
  { label: "Transavia Airlines", value: "Transavia Airlines" },
  { label: "TUI UK", value: "TUI UK" },
  { label: "TUIfly", value: "TUIfly" },
  { label: "Tunis Air", value: "Tunis Air" },
  { label: "Turkish Airlines", value: "Turkish Airlines" },
  { label: "Ukraine International", value: "Ukraine International" },
  { label: "United", value: "United" },
  { label: "Ural Airlines", value: "Ural Airlines" },
  { label: "UTair Aviation", value: "UTair Aviation" },
  { label: "Uzbekistan Airways", value: "Uzbekistan Airways" },
  { label: "Vietnam Airlines", value: "Vietnam Airlines" },
  { label: "Virgin Atlantic", value: "Virgin Atlantic" },
  { label: "Virgin Australia", value: "Virgin Australia" },
  { label: "Vistara", value: "Vistara" },
  { label: "Viva Aerobus", value: "Viva Aerobus" },
  { label: "Volaris", value: "Volaris" },
  { label: "Volotea", value: "Volotea" },
  { label: "Vueling Airlines", value: "Vueling Airlines" },
  { label: "WestJet", value: "WestJet" },
  { label: "Wizzair", value: "Wizzair" },
  { label: "Xiamen Airlines", value: "Xiamen Airlines" },
];

export const SKUS = {
  cc: process.env.MIX_APP_ENV === "production" ? "1321" : "1318",
  ach: process.env.MIX_APP_ENV === "production" ? "1320" : "1317",
};

export const STATES = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

export const STRIPE_ERRORS = {
  insufficient_funds: "Insufficient funds",
  approve_with_id: "The payment can’t be authorized.",
  call_issuer: "The card was declined for an unknown reason.",
  card_not_supported: "The card does not support this type of purchase.",
  do_not_honor: "You need to contact your card issuer for more information.",
  do_not_try_again:
    "You need to contact your card issuer for more information.",
  duplicate_transaction:
    "A transaction with identical amount and credit card information was submitted very recently.",
  test_mode_live_card:
    "Your card was declined. Your request was in test mode, but used a non test (live) card. For a list of valid test cards, visit: https://stripe.com/docs/testing.",
  api_error: `Something went wrong while trying to pay. Please try again later or contact productsupport${APICompany.companyEmail}`,
  default: "The card was declined for an unknown reason.",
  incorrect_cvc:
    "The card’s security code is incorrect. Check the card’s security code or use a different card",
  invalid_cvc:
    "The card’s security code is invalid. Check the card’s security code or use a different card.",
  generic_decline: "You need to contact your card issuer for more information.",
  processing_error:
    "An error occurred while processing your card. Try again in a little bit.",
  lost_card: "Your card has been declined.",
  stolen_card: "Your card has been declined.",
  expired_card: "Your card has expired.",
};

export const TAB_TITLES = {
  "code-of-conduct": "Code of Conduct",
  "cover-letter": "Statement of Purpose",
  "digital-badges": "Digital Badges",
  "global-course-selection": "Course Selection",
  "grade-report": "Grade Report",
  "group-visa-information": "Group Visa Information",
  "internship-credit-options": "Internship Credit Options",
  "internship-interests-form": "Internship Interest",
  "internship-letter-of-recommendation": "Letter of Recommendation",
  "ldm-registration-policy": "LDM Registration Policy",
  "statement-of-purpose": "Statement of Purpose",
  "study-abroad-letter-of-recommendation": "Letter of Recommendation",
  "supplemental-form": "Supplemental Form",
  "transcript-hard-copy": "Transcript",
  "university-approval-request": "University Approval Request",
  "unofficial-transcript": "Unofficial Transcript",
  etranscript: "E-Transcript",
  headshot: "Headshot",
  housing: "Housing",
  passport: "Passport",
  resume: "Resume",
};

export const CLOUDINARY_POSSIBLE_FOLDERS = [
  "events",
  "housing",
  "support",
  "unit",
  "entity",
  "programLead",
  "cities",
  "profiles",
];

export const CLOUDINARY_WIDGET_SOURCES = [
  "local",
  "url",
  "camera",
  "google_drive",
  "image_search",
];

export const CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION = {
  aspectRatio: "16:9",
  crop: true,
};
export const DEFAULT_IMAGE_CARD_SIZE = {
  width: 640,
  height: 360,
};
export const CLOUDINARY_DEFAULT_WIDTH_IMAGES_RESOLUTION = [640, 1366, 1920];

export const CLOUDINARY_DEFAULT_IMAGE_COMPRESSION = "q_auto:good";
export const CLOUDINARY_DEFAULT_IMAGE_CROP = "c_fill,g_auto";

export const CLOUDINARY_ERRORS = {
  unauthorized: "User not authorized to upload files",
  default: "Sorry, you can't upload a file right now! Please, try it later",
};

export const CLOUDINARY_ALLOWED_FORMATS = ["image", "video"];
export const CLOUDINARY_VIDEO_EXTENSION_TYPES = [
  "avi",
  "mov",
  "mp4",
  "ogg",
  "webm",
];
export const STATUS = ["Draft", "Active", "Inactive", "Archived"];

export const ACTIVE_STATUS = "Active";

export const EVENTS_STATUS = {
  draft: {
    id: "9bcb9964-654f-4508-9bfc-354910da8251",
    name: "Draft",
  },
  active: {
    id: "642b2fe7-d770-428d-817b-eed06a1cb764",
    name: "Active",
  },
};

export const DEFAULT_GOOGLE_MAP_ZOOM = 13;

export const LIBRARY_ID = "6ce06b0d-004c-49e0-8f1b-cadc97400206";

export const HOUSING_TYPES_MAP = {
  APARTMENT: "Apartment",
  DORM: "Dorm",
  HOTEL: "Hotel",
  HOUSE: "House",
  LOCAL_HOST: "Local Host",
};

export const HOUSING_TYPES = [
  {
    type: HOUSING_TYPES_MAP.APARTMENT,
    param: "apartment",
    description:
      "A unit within a non-student-specific multi-unit residential building or complex that contains a living space, kitchen, bathroom, and bedroom.",
  },
  {
    type: HOUSING_TYPES_MAP.DORM,
    param: "dorm",
    description:
      "Any student-only housing located on or off campus that includes, at a minimum, a bed and access to a bathroom.",
  },
  {
    type: HOUSING_TYPES_MAP.HOTEL,
    param: "hotel",
    description:
      "A business offering private rooms for guests (includes hostel or aparthotel).",
  },
  {
    type: HOUSING_TYPES_MAP.HOUSE,
    param: "house",
    description:
      "A home that may stand-alone or have shared walls, and always includes a kitchen, living space, bathroom(s), and bedroom(s).",
  },
  {
    type: HOUSING_TYPES_MAP.LOCAL_HOST,
    param: "local-host",
    description:
      "Live with a local individual, couple, or family that offers a room in their home to an API student during an API program.",
  },
];

export const LOCAL_HOST_HOUSING = "Local Host";

export const FLOOR_PLAN = [
  "6 bedrooms",
  "5 bedrooms",
  "4 bedrooms",
  "3 bedrooms",
  "2 bedrooms",
  "1 bedroom",
  "Studio",
];

export const ALLOWED_PROGRAM_TYPES = [
  "Study Abroad",
  "Internship",
  "Custom Programs",
];

export const ALLOWED_PROGRAM_TYPES_OPTIONS = [
  { id: "Study Abroad", label: "Study Abroad" },
  { id: "Internship", label: "Internship" },
  { id: "Custom Programs", label: "Custom Programs" },
];

export const SESSION_TYPES_NAMES = {
  study_abroad: "Study Abroad",
  internship: "Internship",
  custom: "Custom Programs",
  study: "Study",
  faculty_led: "Faculty Led",
};

export const TIERS = [
  { name: "Tier I", id: 1, label: "Basic" },
  { name: "Tier II", id: 2, label: "Classic" },
  { name: "Tier III", id: 3, label: "Comfort" },
];

export const TIERS_OBJ = TIERS.reduce(
  (acc, curr) => ({ ...acc, [curr.name]: curr.label }),
  {}
);

export const KITCHEN_TYPE = [
  "Communal",
  "No Kitchen Access",
  "Private",
  "Shared",
];

export const ROOM_TYPES = ["1 bed", "2 beds", "3 beds"];

export const ROOM_TYPES_OPTIONS = [
  { id: "1 bed", label: "1 bed" },
  { id: "2 beds", label: "2 beds" },
  { id: "3 beds", label: "3 beds" },
];

export const REQUESTED_ROOM_TYPE = {
  "1 bed": "Private Room",
  "2 beds": "Shared Room",
  "3 beds": "Shared Room",
};

export const CANCELLATION_REASONS = [
  { id: "Not Accepted by API", label: "Not Accepted by API" },
  { id: "Not Interested", label: "Not Interested" },
  { id: "Withdrawn from Program", label: "Withdrawn from Program" },
  {
    id: "Cannot accommodate participant's needs",
    label: "Cannot accommodate participant's needs",
  },
  {
    id:
      "Confirmation or additional information not received in time to fulfill",
    label:
      "Confirmation or additional information not received in time to fulfill",
  },
  { id: "Other", label: "Other" },
];

export const CANCELLATION_REASONS_OTHER = "Other";

export const NOTE_TYPES = [
  { id: "Housing", label: "Housing" },
  { id: "Internship", label: "Internship" },
  { id: "Excursions", label: "Excursions" },
];

export const BATHROOM_TYPES = [
  "Communal",
  "Private",
  "Private ensuite",
  "Shared",
  "Shared ensuite",
];

export const AMENITIES_CATEGORIES = {
  SAFETY: "Safety",
  AMENITIES: "Amenities",
  ACCESSIBILITY_AND_INCLUSIVITY: "Accessibility & Inclusivity",
  ROUTINE_CLEANING: "Routine Cleaning",
  ROUTINE_LAUNDRY_SERVICE: "Routine Laundry Service",
  MEAL_PLAN: "Meal Plan",
};

export const AMENITIES_GRANULARITIES = {
  HOUSING_LEVEL: "housing level",
  UNIT_LEVEL: "unit level",
  ROOM_LEVEL: "room level",
};

export const SUB_CATEGORIES = [
  {
    type: HOUSING_TYPES_MAP.APARTMENT,
    subcategories: ["Identical Apartment", "Unique Apartment"],
  },
  {
    type: HOUSING_TYPES_MAP.DORM,
    subcategories: ["Private Dorm", "Residencia", "University Operated Dorm"],
  },
  {
    type: HOUSING_TYPES_MAP.HOTEL,
    subcategories: [
      "Aparthotel",
      "Boutique Hotel",
      "Chain Hotel",
      "Hostel",
      "Independent Hotel",
      "Nature Lodge",
      "Outdoor Stay",
    ],
  },
  {
    type: HOUSING_TYPES_MAP.HOUSE,
    subcategories: [
      "Condominium (Condo)",
      "Semi-Detached Home",
      "Single-Family Home",
      "Townhome",
    ],
  },
  {
    type: HOUSING_TYPES_MAP.LOCAL_HOST,
    subcategories: [
      "Condominium (Condo)",
      "Identical Apartment",
      "Semi-Detached Home",
      "Single-Family Home",
      "Townhome",
      "Unique Apartment",
    ],
  },
];

export const ARCHIVING_MSG = "Are you sure you want to archive ";

export const DUPLICATE_MSG = "Are you sure you want to duplicate ";

export const DEFAULT_LIMIT_PER_PAGE = 10;

export const MAX_NOTE_TEXTAREA_LIMIT = 500;

export const ERROR_TIMEOUT = 7000;

export const SUCCESS_TIMEOUT = 2000;

export const LEARNING_SERVICE_QUERY_LIMIT = 1500;

export const LEARNING_SERVICE_API = "learning-service";
export const PROFILE_SERVICE_API = "profile-service";
export const EVENTS_SERVICE_API = "event-service";
export const PAYMENT_SERVICE_API = "payment-service";

export const SUCCESSFULLY_LOAD = "Successfully loaded";
export const API_ENTITY_INFORMATION = {
  id: "001Ql000008vlP2IAI",
  name: "Academic Programs International",
};

export const PERMISSIONS = {
  ITEMS_READ: "items.read",
  ITEMS_READ_LITE: "items.read.lite",
  ITEMS_WRITE: "items.write",
  ITEMS_PUB: "items.pub",
  PRGMS_READ: "prgms.read",
  PRGMS_READ_LITE: "prgms.read.lite",
  PRGMS_WRITE: "prgms.write",
  PRGMS_PUB: "prgms.pub",
  STUDENT_MANAGEMENT: "student-management",
  PARTICIPANTS: "participants",
  API_EMPLOYEE_STUDENT_VIEW: "api-employee-student-view",
  API_EMPLOYEE: "api-employees",
  EVERYONE: "everyone",
  INSTITUTION: "institution",
  SUPER_ADMIN: "super_admin",
  USERS_READ: "users.read",
  USERS_WRITE: "users.write",
  USERS_ADVISE: "users.advise",
  EMPLOYEE: "api-employees",
  ORDERS_READ: "orders.read",
  ORDERS_WRITE: "orders.write",
  ORDERS_FULFILLMENT: "orders.fulfillment",
  ORDERS_ADMIN: "orders.admin",
  TABLEAU_VIEWER: "tableau-viewer",
  TABLEAU_VIEWER_MEMBER: "tableau-viewer-member",
  ENABLE_CURRICULUM_INTEGRATION_APP: "enable-curriculum-integration-app",
  ENABLE_RESEARCH_APP: "enable-research-app",
  ENABLE_HOME_APP: "enable-home-app",
  ENABLE_MY_STUDENTS_APP: "enable-my-students-app",
  ENABLE_CAREER_AND_SKILLS_APP: "enable-cas-app",
  TENANT_MANAGER: "tenant-manager",
};

export const FEATURES = {
  cacheClear: "cache-clear",
  invoice: "invoice",
  tenantLibraries: "tenant-libraries",
};

export const PROFILE_LEADER_TYPES = ["Api Employee", "University", "Company"];

export const GROUP_PERMISSIONS = {
  REGULAR: {
    read: PERMISSIONS.ITEMS_READ,
    read_lite: PERMISSIONS.ITEMS_READ_LITE,
    write: PERMISSIONS.ITEMS_WRITE,
    pub: PERMISSIONS.ITEMS_PUB,
  },
  PROGRAMS: {
    read: PERMISSIONS.PRGMS_READ,
    read_lite: PERMISSIONS.PRGMS_READ_LITE,
    write: PERMISSIONS.PRGMS_WRITE,
    pub: PERMISSIONS.PRGMS_PUB,
  },
};

export const experiencesMenuItems = [
  {
    name: "Program Finder",
    href: "/",
  },
  {
    name: "Study Abroad",
    href: "https://apiabroad.com/study-abroad/",
  },
  {
    name: "Intern Abroad",
    href:
      "https://apiabroad.com/intern-abroad/?_ga=2.15273782.1061104510.1680622976-1778027508.1680622976",
  },
  {
    name: "Virtual",
    href:
      "https://apiabroad.com/international-virtual-programs/?_ga=2.15273782.1061104510.1680622976-1778027508.1680622976",
  },
  {
    name: "Gap Year",
    href: "https://apiabroad.com/gap-year-abroad/",
  },
  {
    name: "High School",
    href: "https://apiabroad.com/high-school-abroad/",
  },
];
export const resourcesMenuItems = [
  {
    name: "Resource Library",
    href: "https://apiabroad.com/resource-library/",
  },
  {
    name: "Blog",
    href: "https://apiabroad.com/blog/",
  },
  {
    name: "Financial Aid & Info",
    href: "https://apiabroad.com/financial-aid-information/",
  },
  {
    name: "Scholarships",
    href: "https://apiabroad.com/scholarships/",
  },
  {
    name: "Health & Safety",
    href: "https://apiabroad.com/health-safety/",
  },
];
export const asia = [
  {
    name: "Bhutan",
    href: "https://apiabroad.com/gap-intern-study-abroad-bhutan/",
  },
  {
    name: "China",
    href: "https://apiabroad.com/gap-intern-study-abroad-china/",
  },
  {
    name: "Japan",
    href: "https://apiabroad.com/gap-intern-study-abroad-japan/",
  },
  {
    name: "South Korea",
    href: "https://apiabroad.com/gap-intern-study-abroad-south-korea/",
  },
  {
    name: "United Arab Emirates",
    href: "https://apiabroad.com/gap-intern-study-abroad-united-arab-emirates/",
  },
];
export const latin = [
  {
    name: "argentina",
    href: "https://apiabroad.com/gap-intern-study-abroad-argentina/",
  },
  {
    name: "costa rica",
    href: "https://apiabroad.com/gap-intern-study-abroad-costa-rica/",
  },
  {
    name: "chile",
    href: "https://apiabroad.com/gap-intern-study-abroad-chile/",
  },
  {
    name: "cuba",
    href: "https://apiabroad.com/gap-intern-study-abroad-cuba/",
  },
];
export const north = [
  {
    name: "canada",
    href: "https://apiabroad.com/gap-study-intern-abroad-canada/",
  },
  {
    name: "U.S.A.",
    href: "https://apiabroad.com/united-states-of-america/",
  },
];
export const south = [
  {
    name: "australia",
    href: "https://apiabroad.com/gap-intern-study-abroad-australia/",
  },
  {
    name: "new zealand",
    href: "https://apiabroad.com/gap-intern-study-abroad-new-zealand/",
  },
];
export const europe = [
  {
    name: "Croatia",
    href: "https://apiabroad.com/gap-intern-study-abroad-croatia/",
  },
  {
    name: "France",
    href: "https://apiabroad.com/gap-intern-study-abroad-france/",
  },
  {
    name: "Greece",
    href: "https://apiabroad.com/gap-intern-study-abroad-greece/",
  },
  {
    name: "Ireland",
    href: "https://apiabroad.com/gap-intern-study-abroad-ireland/",
  },
  {
    name: "Portugal",
    href: "https://apiabroad.com/gap-intern-study-abroad-portugal/",
  },

  {
    name: "Hungary",
    href: "https://apiabroad.com/gap-intern-study-abroad-hungary/",
  },
  {
    name: "Italy",
    href: "https://apiabroad.com/gap-intern-study-abroad-italy/",
  },
  {
    name: "scotland",
    href: "https://apiabroad.com/gap-intern-study-abroad-scotland/",
  },
  {
    name: "england",
    href: "https://apiabroad.com/gap-intern-study-abroad-england/",
  },
  {
    name: "iceland",
    href: "https://apiabroad.com/gap-intern-study-abroad-iceland/",
  },
  {
    name: "Czech Republic",
    href: "https://apiabroad.com/gap-intern-study-abroad-czech-republic/",
  },
  {
    name: "poland",
    href: "https://apiabroad.com/gap-intern-study-abroad-poland/",
  },
  {
    name: "spain",
    href: "https://apiabroad.com/gap-intern-study-abroad-spain/",
  },
];
export const FOOTERLINKS = {
  experiences: [
    {
      label: "Program Finder",
      href: "/",
    },
    {
      label: "Study Abroad",
      href: "https://apiabroad.com/study-abroad/",
    },
    {
      label: "Intern Abroad",
      href:
        "https://apiabroad.com/intern-abroad/?_ga=2.15273782.1061104510.1680622976-1778027508.1680622976",
    },
    {
      label: "Virtual",
      href:
        "https://apiabroad.com/international-virtual-programs/?_ga=2.15273782.1061104510.1680622976-1778027508.1680622976",
    },
    {
      label: "Gap Year",
      href: "https://apiabroad.com/gap-year-abroad/",
    },
    {
      label: "High School",
      href: "https://apiabroad.com/high-school-abroad/",
    },
    {
      label: "Destinations Overview",
      href: "https://apiabroad.com/destinations-overview/",
    },
  ],
  whoweserve: [
    {
      label: "Overview",
      href: "https://apiabroad.com/who-we-serve/",
    },
    {
      label: "Students",
      href: "https://apiabroad.com/students/",
    },
    {
      label: "Colleges & Universities",
      href: "https://apiabroad.com/college-university-members-affiliates/",
    },
    {
      label: "Parents & Guardians",
      href: "https://apiabroad.com/parents-families/",
    },
    {
      label: "Alumni",
      href: "https://apiabroad.com/alumni/",
    },
  ],
  resources: [
    {
      label: "Resource Library",
      href: "https://apiabroad.com/resource-library/",
    },
    {
      label: "Blog",
      href: "https://apiabroad.com/blog/",
    },
    {
      label: "Events",
      href: "https://apiabroad.com/events",
    },
    {
      label: "Financial Aid & Info",
      href: "https://apiabroad.com/financial-aid-information/",
    },
    {
      label: "Scholarships",
      href: "https://apiabroad.com/scholarships/",
    },
    {
      label: "Health & Safety",
      href: "https://apiabroad.com/health-safety/",
    },
  ],
  whyapi: [
    {
      label: "Our Story",
      href: "https://apiabroad.com/our-story",
    },
    {
      label: "APIConnect",
      href: "https://apiabroad.com/api-connect",
    },
    {
      label: "Diversity, Equity & Inclusion",
      href: "https://apiabroad.com/diversity-equity-and-inclusion",
    },
    {
      label: "Digital Badges",
      href: "https://apiabroad.com/digital-badges",
    },
    {
      label: "Careers",
      href: "https://apiabroad.com/careers/",
    },
  ],
  legal: [
    {
      label: "All API Policies",
      href: "https://apiabroad.com/project/api-policies",
    },
    {
      label: "Privacy Policy",
      href: "https://apiabroad.com/privacy-policy",
    },
  ],
  socialmedia: [
    {
      label: "Facebook",
      href: "https://www.facebook.com/APIabroad",
      icon: "/images/api-footer-facebook-icon.svg",
    },
    {
      label: "Instagram",
      href: "https://www.instagram.com/apiabroad/",
      icon: "/images/api-footer-instagram-icon.svg",
    },
    {
      label: "YouTube",
      href: "https://www.youtube.com/channel/UChr2jBgVN47igO8JqlIyu7Q",
      icon: "/images/api-footer-youtube-icon.svg",
    },
    {
      label: "Twitter",
      href: "https://twitter.com/APIabroad",
      icon: "/images/api-footer-twitter-icon.svg",
    },
    {
      label: "Linkedin",
      href:
        "https://www.linkedin.com/company/academic-programs-international-api-/",
      icon: "/images/api-footer-linkedin-icon.svg",
    },
    {
      label: "TikTok",
      href: "https://www.tiktok.com/@api_abroad?lang=en",
      icon: "/images/api-footer-tiktok-icon.svg",
    },
  ],
};

export const COURSE_LANGUAGES = [
  {
    label: "Arabic",
    value: "arabic",
    selected: false,
  },
  {
    label: "Catalan",
    value: "catalan",
    selected: false,
  },
  {
    label: "Chinese",
    value: "chinese",
    selected: false,
  },
  {
    label: "Czech",
    value: "czech",
    selected: false,
  },
  {
    label: "German",
    value: "german",
    selected: false,
  },
  {
    label: "Italian",
    value: "italian",
    selected: false,
  },
  {
    label: "Latin",
    value: "latin",
    selected: false,
  },
  {
    label: "Polish",
    value: "polish",
    selected: false,
  },
  {
    label: "English",
    value: "english",
    selected: false,
  },
  {
    label: "French",
    value: "french",
    selected: false,
  },
  {
    label: "Spanish",
    value: "spanish",
    selected: false,
  },
  {
    label: "Portuguese",
    value: "portuguese",
    selected: false,
  },
];

export const COURSE_LEVEL = [
  {
    label: "100",
    value: "100",
    selected: false,
  },
  {
    label: "200",
    value: "200",
    selected: false,
  },
  {
    label: "300",
    value: "300",
    selected: false,
  },
  {
    label: "400",
    value: "400",
    selected: false,
  },
  {
    label: "Graduate",
    value: "graduate",
    selected: false,
  },
];
export const AUDIT_FIELDS = [
  "archived_at",
  "created_at",
  "updated_at",
  "updated_by",
];
export const message = `Don’t see what you are looking for? Email&nbsp;<a class="api-link" href="mailto:support-internships@apiexperience.com">support-internships@apiexperience.com</a>&nbsp;and we can help.`;

export const PAYMENT_PLANS = [
  {
    label: "Financial aid (Federal, State)",
    value: "financialAid",
  },
  {
    label: "Scholarships/grants from your school",
    value: "scholarshipsGrantsFromYourSchool",
  },
  {
    label: "Personal and family funds",
    value: "personalAndFamilyFunds",
  },
  {
    label: "Loans / Credit Cards",
    value: "loansCreditCards",
  },
  {
    label: "Other",
    value: "other",
  },
];
export const STUDENTS = "students";

export const ONE_CREDIT_PRODUCTS = {
  id: "edcbcb46-db4b-4033-ba11-968a289bd3d4",
  name: "Class Add-On",
  sku: 1468,
};

// TO-DO add 3 credit object
export const THREE_CREDIT_PRODUCTS = {
  id: "",
  name: "",
  sku: 0,
};

export const GROUP_VISA_ALLOWED_COUNTRIES = ["ES", "IT"]; // Learning service ids for Spain and Italy
export const GV_ALLOWED_STATES_SPAIN = [
  "Maine",
  "Massachusetts",
  "New Hampshire",
  "Rhode Island",
  "Vermont",
  "Alabama",
  "Arkansas",
  "Louisiana",
  "Mississippi",
  "New Mexico",
  "Oklahoma",
  "Texas",
  "Tennessee",
  "California",
  "Florida",
  "South Carolina",
  "Georgia",
  "Alaska",
  "Hawaii",
  "Idaho",
  "Montana",
  "Nevada",
  "Oregon",
  "Washington",
  "Wyoming",
  "American possessions in the Pacific",
  "Maryland",
  "North Carolina",
  "Virginia",
  "West Virginia",
  "District of Columbia",
  "Puerto Rico",
  // TODO: Add later "Culebra and Vieques Islands",
  "Virgin Islands",
]; // For permanent and current address in profile service
export const GV_ALLOWED_STATES_SPAIN_2 = [
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Nebraska",
  "North Dakota",
  "South Dakota",
  "Ohio",
  "Kentucky",
  "Wisconsin",
  "New York",
  "Connecticut",
  "Delaware",
  "Pennsylvania",
  "New Jersey",
]; // For current addresss in profile service
export const GV_ALLOWED_STATES_ITALY = [
  "Maine",
  "Massachusetts",
  "New Hampshire",
  "Rhode Island",
  "Vermont",
]; // For permanent and current address in profile service
export const GV_ALLOWED_COUNTIES_NEW_JERSEY = [
  "Bergen County",
  "Hudson County",
  "Morris County",
  "Passaic County",
  "Sussex County",
  "Union County",
  "Warren County",
  "Essex County",
  "Middlesex County",
  "Monmouth County",
  "Hunterdon County",
  "Mercer County",
  "Somerset County",
]; // For addresses
export const V3_ALLOWED_ROUTES = [
  "application-create-account",
  "application-about-you",
  "application-learner-info",
  "application-review",
  "application-group-visa",
  "application-checkout",
  "application-next-steps",
]; // Routes that will trigger navigation guard on StepsLayoutV3 component

export const applicationStepsBreadcrumb = [
  { title: "Home", parent: "home" },
  { title: "Personalize & Apply", parent: "personalize" },
];

export const OVER_PRICE_2000 = 200000;

export const API_CONNECT_MEMBER = "APIConnect Member";

// Events
export const MOBILITY = [
  { id: 1, name: "No stairs or steps" },
  { id: 2, name: "Accessible bathroom" },
  { id: 3, name: "Accessible parking spot" },
  { id: 4, name: "Mainly flat or leveled ground" },
];

export const COMMUNICATION = [
  { id: 1, name: "Detailed audio or verbal information" },
  { id: 2, name: "Sign language" },
  { id: 3, name: "Deaf aware techniques" },
  { id: 4, name: "Designated sighted guide" },
];

export const SENSORY = [
  { id: 1, name: "No extreme sensory stimuli" },
  { id: 2, name: "Quiet retreat space" },
];

export const ALLOW_FINANCIAL_AID_FOR = ["college"]; // student types for V2

export const US_COLLEGE_STUDENT_ID = "020e5861-e207-4a7f-989d-bc6ee07d5407";
export const US_HIGH_SCHOOL_STUDENT_ID = "8e4354e0-1d83-46a5-8354-5d60e3352305";
export const US_GRADUATE_STUDENT_ID = "e95844a2-ae40-449a-ad5f-7e5cfa8716f8";
export const INTERNATIONAL_HIGH_SCHOOL_STUDENT_ID =
  "ecbeadde-4fd3-4b9a-8aa5-2109c1788ae7";
export const INTERNATIONAL_COLLEGE_STUDENT_ID =
  "091dd1f8-6376-4e79-ad65-f70ac01c2178";
export const INTERNATIONAL_GRADUATE_STUDENT_ID =
  "61c93ca0-f769-4271-b4b5-7702b19eb544";
export const GAP_YEAR_STUDENT = "b6c8ff24-6bfb-4c44-a2a1-2e370e2e20de";
export const NOT_ENROLLED = "034f1b0b-291d-46ab-ad6f-90033830c805";
export const requiredSchool = [US_COLLEGE_STUDENT_ID, US_GRADUATE_STUDENT_ID];
export const learnerTypesCustomOrder = [
  US_COLLEGE_STUDENT_ID,
  US_GRADUATE_STUDENT_ID,
  US_HIGH_SCHOOL_STUDENT_ID,
  GAP_YEAR_STUDENT,
  INTERNATIONAL_HIGH_SCHOOL_STUDENT_ID,
  INTERNATIONAL_COLLEGE_STUDENT_ID,
  INTERNATIONAL_GRADUATE_STUDENT_ID,
  NOT_ENROLLED,
];
export const V3_ALLOW_FINANCIAL_AID_FOR = [
  {
    id: US_COLLEGE_STUDENT_ID,
  },
  {
    id: US_GRADUATE_STUDENT_ID,
  },
]; // student types for v3

export const CONTACT_HOURS = [
  "40 Contact Hours",
  "45 Contact Hours",
  "48 Contact Hours",
  "50 Contact Hours",
  "52 Contact Hours",
  "54 Contact Hours",
  "60 Contact Hours",
  "64 Contact Hours",
];

export const HOST_INSTITUTION_TAGS_CATEGORY = [
  { id: "d40d862c-b21e-4e26-8842-4f407efd2047", name: "Transit and Location" },
  { id: "fb180b97-2b07-42a3-b6d8-f263c62e9eda", name: "Perk" },
];

export const CONGRESO_ID = "b786c3ab-e23a-4195-9b10-14122f1383b3";
export const UMASS_ID = "b16d09da-a893-4fcc-b6f1-acb97ce1eec9";
export const SALESFORCE_UMASS_ID = "0015A00002DC2SCQA1";

export const ORDER_STATUS = {
  draft: "Draft",
  submitted: "Submitted",
  paidConfirmed: "Paid, Confirmed",
  fulfilled: "Fulfilled",
  withdrawn: "Withdrawn",
};

export const ORDER_PRODUCT_STATUS = {
  unfulfilled: "Unfulfilled",
  fulfilled: "Fulfilled",
  canceled: "Canceled",
};

export const ORDER_PRODUCT_TYPE = {
  room: "ROOM",
  event: "EVENT",
  misc: "MISC",
};

export const V3_VALID_ORDER_STATUS = [
  ORDER_STATUS.draft,
  ORDER_STATUS.submitted,
]; // Helps confirmation payment modal in Enrollment Landing Page

export const SCHOLARSHIP_LINK = "https://apiabroad.com/scholarships/";

export const DEFAULT_EXCURSION_IMAGE =
  "https://images.apiabroad.com/image/upload/v1701739290/defaultImage/defaultExcursion_om64co.jpg";

export const FINANCIAL_AID_INFO_LINK =
  "https://apiabroad.com/financial-aid-information/";

export const leftSidebarItems = {
  account: {
    aboutYou: {
      icon: "/images/icon-about-you.svg",
      title: "ABOUT YOU",
      description: "Name, Email, And More",
      route: "/account/about-you",
    },
    password: {
      icon: "/images/icon-password.svg",
      title: "PASSWORD",
      description: "Reset Your Password",
      route: "/home/location",
    },
    switchAccount: {
      icon: "/images/icon-switch-account.svg",
      title: "SWITCH ACCOUNT",
      description: "Manage multiple logins",
      route: "/home/location",
    },
  },
  settings: {
    curriculumIntegration: {
      icon: "/images/icon-curriculum-active.svg",
      title: "CURRICULUM INTEGRATION",
      description: "Manage course approvals",
      route: "/home",
    },
    brand: {
      icon: "/images/icon-brand-circle.svg",
      title: "BRAND",
      description: "Manage logo and colors",
      route: "/account/settings/brand",
    },
    forms: {
      icon: "/images/icon-form-circle.svg",
      title: "FORMS",
      description: "Manage your forms",
      route: "/home/location",
    },
    hostInstitutions: {
      icon: "/images/icon-host-circle.svg",
      title: "HOST INSTITUTIONS",
      description: "Upload images and videos",
      route: "/account/settings/host-institutions",
    },
    cities: {
      icon: "/images/icon-cities.svg",
      title: "CITIES",
      description: "Edit city metadata",
      route: "/account/settings/cities",
    },
    userManagement: {
      icon: "/images/icon-user-management-circle.svg",
      title: "USER MANAGEMENT",
      description: "Update team permissions",
      route: "/account/settings/user-management",
    },
  },
};

export const INITIAL_MAJORS = [
  { cip_title: "Psychology, General", cip_code: 42.0101 },
  { cip_title: "Marketing/Marketing Management, General", cip_code: 52.1401 },
  {
    cip_title: "Communication, journalism, and related programs, other",
    cip_code: 9.0199,
  },
  {
    cip_title: "Business administration, management and operations, other",
    cip_code: 52.0299,
  },
  { cip_title: "Fashion/Apparel Design", cip_code: 50.0407 },
  { cip_title: "Finance, General", cip_code: 52.0801 },
  { cip_title: "Political Science and Government, General", cip_code: 45.1001 },
  { cip_title: "Biology/Biological Sciences, General", cip_code: 26.0101 },
  { cip_title: "Computer and Information Sciences, Other", cip_code: 11.0199 },
  { cip_title: "Economics, General", cip_code: 45.0601 },
  { cip_title: "International Business/Trade/Commerce", cip_code: 52.1101 },
  {
    cip_title: "Health Services/Allied Health/Health Sciences, General",
    cip_code: 51.0,
  },
  { cip_title: "International Relations and Affairs", cip_code: 45.0901 },
  { cip_title: "Education, General", cip_code: 13.0101 },
  {
    cip_title: "Nursing/Registered Nurse (RN, ASN, BSN, MSN)",
    cip_code: 51.1601,
  },
  { cip_title: "Environmental Science", cip_code: 3.0104 },
  { cip_title: "English Language and Literature, General", cip_code: 23.0101 },
  { cip_title: "Accounting and related services, other", cip_code: 52.0399 },
  { cip_title: "Fine/Studio Arts, General", cip_code: 50.0702 },
  { cip_title: "Criminology", cip_code: 45.0401 },
  { cip_title: "Spanish Language and Literature", cip_code: 16.0905 },
  { cip_title: "Sociology", cip_code: 45.1101 },
  { cip_title: "History, General", cip_code: 54.0101 },
  { cip_title: "Kinesiology and Exercise Science", cip_code: 31.0505 },
  {
    cip_title: "Architecture (BArch, BA/BS, MArch, MA/MS, PhD)",
    cip_code: 4.0201,
  },
];

export const OLD_APPLICATION_FEE_AMOUNT = 150;

// if applicationfee feature flag is active, label is `Application Fee` else `Applicatin Deposit`
export const APPLICATION_FEE_DATA = {
  label: window?.flags?.["application-fee"]
    ? "application fee"
    : "application deposit",
  typeLabel: window?.flags?.["application-fee"] ? "fee" : "deposit",
  formName: window?.flags?.["application-fee"]
    ? "Application Fee"
    : "Pay Deposit",
  netsuiteSku: window?.flags?.["application-fee"] ? 1484 : 436,
  // if need to check if it has payment for application please use paymentService.getApplicationFeeAmount(applicationId) instead
  amount: window?.flags?.["application-fee"] ? 50 : OLD_APPLICATION_FEE_AMOUNT,
  flagActive: window?.flags?.["application-fee"],
};

export const SESSION_TYPE = {
  study: {
    id: 1,
    name: "Study",
  },
  internship: {
    id: 2,
    name: "Internship",
  },
  fellowship: {
    id: 3,
    name: "Fellowship",
  },
  facultyLed: {
    id: 4,
    name: "Faculty Led",
  },
  groupTravel: {
    id: 5,
    name: "Group Travel",
  },
  research: {
    id: 6,
    name: "Research",
  },
  virtual: {
    id: 7,
    name: "Virtual",
  },
  volunteer: {
    id: 8,
    name: "Volunteer",
  },
  teachingEnglish: {
    id: 9,
    name: "Teaching English",
  },
};

export const dayOrder = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const CANCEL = "cancel";

export const VIZ_CURRICULUM_INTEGRATION_URL = process.env
  .MIX_APP_TABLEAU_VIZ_CURRICULUM_INTEGRATION_URL
  ? process.env.MIX_APP_TABLEAU_VIZ_CURRICULUM_INTEGRATION_URL
  : "https://tableau.apiabroad.com/#/views/summary_curriculum_integration/CurriculumIntegration";

export const VIZ_RESEARCH_URL = process.env.MIX_APP_TABLEAU_VIZ_RESEARCH_URL
  ? process.env.MIX_APP_TABLEAU_VIZ_RESEARCH_URL
  : "https://tableau.apiabroad.com/#/views/summary_research/Research";

export const VIZ_HOME_URL = process.env
  .MIX_APP_TABLEAU_VIZ_STUDENT_LIFECYCLE_URL
  ? process.env.MIX_APP_TABLEAU_VIZ_STUDENT_LIFECYCLE_URL
  : "https://tableau.apiabroad.com/#/views/summary_student_lifecycle/StudentLifecycle";

export const VIZ_MY_STUDENTS_URL = process.env
  .MIX_APP_TABLEAU_VIZ_STUDENT_BENCHMARKING_URL
  ? process.env.MIX_APP_TABLEAU_VIZ_STUDENT_BENCHMARKING_URL
  : "https://tableau.apiabroad.com/#/views/summary_student_benchmarking/StudentBenchmarking";

export const VIZ_CAREER_AND_SKILLS_URL = process.env
  .MIX_APP_TABLEAU_VIZ_CAREER_INTERNSHIPS_URL
  ? process.env.MIX_APP_TABLEAU_VIZ_CAREER_INTERNSHIPS_URL
  : "https://tableau.apiabroad.com/#/views/summary_career_internships/CareerandInternships";

export const VIZ_FORMS_URL = process.env.MIX_APP_TABLEAU_VIZ_FORMS_URL
  ? process.env.MIX_APP_TABLEAU_VIZ_FORMS_URL
  : "https://tableau.apiabroad.com/views/FormStatusFunnel_17158095143230/FormsDashboard";

export const VIZ_ORDERS_REPORT =
  "https://tableau.apiabroad.com/views/orders_report/Orders";

export const VIZ_ORDERS_MISCELLANEOUS_PRODUCTS_REPORT =
  "https://tableau.apiabroad.com/views/miscellaneous_products_report/MiscellaneousOrdersReport";

export const VIZ_ORDERS_ACTUAL_HOUSING_BOOKINGS_REPORT =
  "https://tableau.apiabroad.com/views/actual_housing_and_bookings/HousingandBookings";

export const VIZ_ORDERS_HOUSING_INTERNATIONAL_REPORT =
  "https://tableau.apiabroad.com/views/housing_international_report/HousingReport";

export const VIZ_ORDERS_EXCURSIONS_INTERNATIONAL_REPORT =
  "https://tableau.apiabroad.com/views/excursions_international_report/ExcursionsDashboard";

export const TABLEAU_VIZ_FRAME_HEIGHT = "1563";

export const TABLEAU_VIZ_FRAME_WIDTH = "1500";

export const MISC_PRODUCTS_FEES = {
  CLASSES_ACADEMIC_FEE: "1a027ac5-ed21-46fe-90f8-d49fc4c07164",
  INSURANCE_FEE: "55a782d5-82cd-4f1d-8a2b-1cfd3ead1eea",
  GROUP_FLIGHT_FEE: "a4438874-9713-4948-a73e-31d8155b4885",
  PUBLIC_TRANSPORTATION_FEE: "0c5cb0e3-34a6-4bdf-ad89-693779dad241",
  SPAIN_MEDICAL_INSURANCE_FEE: "175919a7-a968-4683-9ab6-ff39071177f2",
};

export const FORMS_SUBMISSIONS = {
  housingQuestionnaire: {
    name: "Housing Questionnaire",
    slug: "housing-questionnaire",
  },
};

export const REGULAR_MISC_PRODUCTS = {
  "45c62933-62cf-4c17-930f-4112414a94cf": "Fees",
  "e159fe01-444b-4b53-822a-9650c63330b6": "General Group Visa Service",
  "9615bcb5-8fb3-4b19-9f41-bd5bc4ed98a8": "Airfare Fee",
  "b16d09da-a893-4fcc-b6f1-acb97ce1eec9": "Transcript services",
  "b786c3ab-e23a-4195-9b10-14122f1383b3": "Transcript Provider Services",
  "0c5cb0e3-34a6-4bdf-ad89-693779dad241": "Public Transportation Card",
  "219c75cb-ceb8-4262-8899-4a27099fdd06": "Shipping Service",
  "d336d3e4-ebed-4cfd-bbf7-9e52d2fd6e51": "Airport Reception (private driver)",
  "a4438874-9713-4948-a73e-31d8155b4885": "Group Flight",
  "933fd0c6-b04c-4c86-8007-9960967c3f72": "On-site Student Service",
  "df5afe99-3085-4bb0-8edc-307a78fad56a": "On-site Orientation",
  "c7b95c8d-5c1c-4311-9941-c0710c0bd9d1": "Change Fee",
  "a11c10b9-cfbf-45b4-8b54-f042f71746bb": "Discount Code",
  "f94ab97e-d0e0-4863-9b3d-e756f65dbf20": "Financial Aid deferrals",
  "01cd302a-245d-4c40-a239-482862f6dbc3": "API Scholarship",
  "d311f61e-57c0-49cc-a363-6bfec8be5299": "API Connect Scholarship",
  "72cc239f-3ac5-46fb-ab3a-f2f91b9c96f1": "Affiliate awards",
  "8d5b1f28-ddbb-43fd-a1a4-7a2611530485": "1 Class Credit Add-On",
  "041f60ce-6ff0-426a-b384-9c8629bbbf5e": "3 Class Credit Add-On",
  "82a825a0-6664-4e9e-a8d6-6e8696c939b3": "Spain Group Visa Service",
  "4967ffa7-a640-4741-be31-85fbd085bcf2": "Travel Insurance",
};

export const SPECIAL_MISC_PRODUCTS = {
  "55a782d5-82cd-4f1d-8a2b-1cfd3ead1eea": "Medical Insurance",
  "1a027ac5-ed21-46fe-90f8-d49fc4c07164": "Classes Academic Fees",
  "175919a7-a968-4683-9ab6-ff39071177f2": "Spain Medical Insurance",
};

export const INVOICE_STATUSES = {
  DRAFT: "Draft",
  OPEN: "Open",
  VOID: "Void",
  PAID: "Paid",
  UNCOLLECTIBLE: "Uncollectible",
  PAST_DUE: "Past Due",
};

export const PAYMENT_TYPES = {
  US_CREDIT_CARD: {
    value: "usCreditCard",
    name: "US Credit/Debit Card",
    feeFactor: 0.03,
  },
  INTERNATIONAL_CREDIT_CARD: {
    value: "internationalCreditCard",
    name: "International Credit/Debit Card",
    feeFactor: 0.04,
  },
  BANK_ACCOUNT: {
    value: "bankAccount",
    name: "US Bank Draft/Transfer (ACH)",
    feeFactor: null,
    achFee: 300,
  },
};

export const STRIPE_TYPES = {
  CARD: "card",
  US_BANK_ACCOUNT: "us_bank_account",
};

export const MOBILE_APP_LINKS = {
  APP_STORE: "https://apps.apple.com/us/app/apiconnect/id6448320549",
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.apiabroad.api_abroad_app",
};

export const TERMS = {
  fallQuarter: {
    id: "996e9614-af09-4fcf-878c-1b84e6ad6b53",
    name: "Fall Quarter",
  },
  fallExtended: {
    id: "e9b37baa-2ad8-4348-94bf-e9f9a1b5292d",
    name: "Fall Extended",
  },
  fallEarlyStart: {
    id: "ce5ea321-0106-4319-bc69-9e142a3b45b6",
    name: "Fall Early Start",
  },
  fallBreak: { id: "63a35e7b-190e-4a41-9f2b-167234b27e6b", name: "Fall Break" },
  fall: { id: "4f93aacb-f4b8-46ef-b889-4aca9ef1aed0", name: "Fall" },
  academicYear: {
    id: "678a6065-cd88-413f-98ed-5e39be538d95",
    name: "Academic Year",
  },
};

export const PLATFORM = {
  web: "web",
  mobile: "mobile",
};

export const API_RELATIONSHIPS = {
  APPROVED: "Approved",
  APIC_MEMBER: "APIConnect Member",
  PREFERRED: "Preferred",
  AFFILIATE: "Affiliate",
  GENERAL: "General",
  PENDING: "Pending",
  DEFAULT: "NOT_SET", // Not an actual value from Learning Service, default for internal use in frontend.
};

export const BILLING_TYPES = {
  PASS_THROUGH: "Pass through",
};

export const FLASH_MESSAGE =
  "Sorry, we couldn't find the page you're looking for. Please double-check the URL for any typos. We've redirected you back to our main site to help you find what you need.";
