<script setup>
import { defineProps, defineEmits } from "vue";
import Badge from "../../shared/Badge/Badge.vue";

const emits = defineEmits(["remove-one-filter", "clear-all"]);
const props = defineProps({
  filters: {
    type: Array,
    default: () => [],
  },
});

const clearAll = () => {
  emits("clear-all");
};
const handleRemoveOneFilter = (payload) => {
  emits("remove-one-filter", payload);
};
</script>
<template>
  <div class="flex font-montserrat flex-wrap gap-2">
    <Badge
      v-for="(value, index) in props.filters"
      :key="index"
      :item="value"
      :truncate="false"
      @item-removed="handleRemoveOneFilter"
    />
    <button
      v-if="props.filters.length > 0"
      class="flex flex-col justify-center items-center"
      @click="clearAll"
    >
      <span
        class="flex justify-center items-center gap-1 font-bold text-base leading-4.5 uppercase"
        :class="`${secondaryColorClass}`"
        >Clear All
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-2.5"
        >
          <path
            d="M17.1673 2.47801L15.5223 0.833008L9.00065 7.35468L2.47898 0.833008L0.833984 2.47801L7.35565 8.99967L0.833984 15.5213L2.47898 17.1663L9.00065 10.6447L15.5223 17.1663L17.1673 15.5213L10.6457 8.99967L17.1673 2.47801Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </button></div
></template>
