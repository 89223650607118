import { format, parse } from "date-fns";
import { getApiClient } from "@/services/API";

// https://stackoverflow.com/a/2631198/158449
export const getNested = (obj, ...args) => {
  return args.reduce((obj, level) => obj && obj[level], obj);
};

/**
 * Makes sure that we have a slash after the param we pass to it
 * @param url
 * @returns
 */
export const trailingSlash = (url) => {
  if (url) return url.replace(/\/?$/, "/");
};

export const toCamelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

/**
 * Convert a camel case string into separated words with first letter capitalized
 */
export const revertCamelCase = (str) => {
  // adding space between strings
  const result = str.replace(/([A-Z])/g, " $1");

  // converting first character to uppercase and join it to the final string
  const final = result.charAt(0).toUpperCase() + result.slice(1);

  return final;
};

export function parseSessionStartDate(session_travel_details) {
  let startDate = null;
  for (let details of session_travel_details) {
    let nextDate = new Date(details.arrival_date);
    if (!startDate) startDate = nextDate;
    else if (nextDate < startDate) startDate = nextDate;
  }
  return startDate;
}

export function parseSessionEndDate(session_travel_details) {
  let endDate = null;
  for (let details of session_travel_details) {
    let nextDate = new Date(details.departure_date);
    if (!endDate) endDate = nextDate;
    else if (nextDate > endDate) endDate = nextDate;
  }
  return endDate;
}

export const buildStringDateRangeSessionTravel = (
  sessionTravelDetails = [],
  { academic_start_date = null, academic_end_date = null } = {},
  monthFormat = "short"
) => {
  const arrivalDate = parseSessionStartDate(
    sessionTravelDetails
  )?.toISOString();
  const departureDate = parseSessionEndDate(
    sessionTravelDetails
  )?.toISOString();

  const finalStartDate =
    arrivalDate ||
    (academic_start_date ? new Date(academic_start_date).toISOString() : null);
  const finalEndDate =
    departureDate ||
    (academic_end_date ? new Date(academic_end_date).toISOString() : null);

  if (finalStartDate && finalEndDate) {
    return buildStringDateRange(finalStartDate, finalEndDate, monthFormat);
  }

  return "";
};

export const buildStringDateRange = (from, to, monthFormat = "short") => {
  let formattedFrom = formatDate(from, monthFormat);
  let formattedTo = formatDate(to, monthFormat);
  return formattedFrom + " - " + formattedTo;
};

export const formatDate = (date, monthFormat = "short") => {
  if (!date) return;
  const extractYear = date.substring(0, 4);
  const extractMonth = date.substring(5, 7);
  const extractDay = date.substring(8, 10);

  const newDate = new Date(extractYear, extractMonth - 1, extractDay);
  const dtf = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: monthFormat,
    day: "2-digit",
  });
  const formattedDate = dtf.formatToParts(newDate);
  const mo = formattedDate[0].value;
  const da = formattedDate[2].value;
  const ye = formattedDate[4].value;
  if (["numeric", "2-digit"].includes(monthFormat)) return `${mo}/${da}/${ye}`;
  return `${mo} ${da}, ${ye}`;
};

export const addClassToElementById = (elementId, newClass) => {
  let element = document.getElementById(elementId);
  if (element) {
    element.classList.add(newClass);
  }
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const isDateSupported = () => {
  const input = document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  return input.value !== value;
};

export const daysUntilDate = (date, includeEndDay = true) => {
  const extractYear = date.substring(0, 4);
  const extractMonth = date.substring(5, 7);
  const extractDay = date.substring(8, 10);
  const startDate = new Date(extractYear, extractMonth - 1, extractDay);
  const nowDate = new Date();
  nowDate.setHours(0, 0, 0, 0);
  let days = Math.ceil((startDate - nowDate) / (1000 * 3600 * 24));
  if (includeEndDay) {
    return days + 1;
  }
  return days;
};

export const readCookie = (cookieName) => {
  let fullCookieName = cookieName + "=";
  let documentCookies = decodeURIComponent(document.cookie);
  let cookiesArray = documentCookies.split(";");
  for (let i = 0; i < cookiesArray.length; i++) {
    let currentCookie = cookiesArray[i];
    while (currentCookie.charAt(0) === " ") {
      currentCookie = currentCookie.substring(1);
    }
    if (currentCookie.indexOf(fullCookieName) === 0) {
      return currentCookie.substring(
        fullCookieName.length,
        currentCookie.length
      );
    }
  }
  return "";
};

export const isEmployeeEmail = (email) => {
  const regex = /@(.*)/;
  let splitEmail = email.split(regex);
  return splitEmail[1] === "apiexperience.com";
};

export const isDecimal = (value) => {
  if (value.toString().includes(".")) {
    let isDecimal = value?.split(".");
    if (isDecimal != undefined && isDecimal[1]?.length > 2) {
      return false;
    }
  }
  return true;
};

export const isHigherThenCurrentBalance = (value, vm) => {
  if (Number(value) > Number(vm.rootAmount)) {
    return false;
  }
  return true;
};

export const isZeroOrBlank = (value) => {
  if (Number(value) === 0 || value === "" || !value) {
    return false;
  }
  return true;
};

export const from24to12 = (time, AMorPM) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    if (!AMorPM) {
      time[5] = +time[0] < 12 ? " " + " " + "AM" : " " + " " + "PM";
    } else {
      time[5] = +time[0] < 12 ? "" : " ";
    }

    time[0] = +time[0] % 12 || 12;
  }

  return time.join("");
};

export const from12to24 = (valOne, valTwo) => {
  if (valOne !== "" && valTwo !== "") {
    const convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");

      let [hours, minutes] = time.split(":");

      if (hours === "12") {
        hours = "00";
      }

      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
    };

    return convertTime12to24(valOne + " " + valTwo);
  }
};

export const normalizeDateForLaravel = (date) => {
  return format(parse(date, "MM-dd-yyyy", new Date()), "yyyy-MM-dd");
};

// https://stackoverflow.com/questions/10015027/javascript-tofixed-not-rounding/43998255#43998255
export const toFixed = (number, decimals = 2) => {
  let x = Math.pow(10, Number(decimals) + 1);
  return (Number(number) + 1 / x).toFixed(decimals);
};

export const dollarsToCents = (dollars, fixedDecimals = false) => {
  return fixedDecimals
    ? Math.round(floatWithFixedDecimals(dollars, 0, 2) * 100)
    : Math.round(parseFloat(dollars) * 100);
};

export const centsToDollars = (dollars, fixedDecimals = false) => {
  return fixedDecimals
    ? floatWithFixedDecimals(dollars / 100, 0, 2)
    : parseFloat(dollars) / 100;
};

export const removeParenthesis = (text) => {
  return text.toString().replace("(", "").replace(")", "");
};

export const joinArrayItems = (
  items,
  property,
  secondaryProperty = null,
  divider = "-"
) => {
  if (items) {
    return items
      .map(function (item) {
        if (secondaryProperty)
          return `${item?.[property] || ""} ${divider} ${
            item?.[secondaryProperty] || ""
          }`;
        else return item?.[property] || "";
      })
      .filter(Boolean)
      .join(", ");
  } else {
    return "";
  }
};

export const floatWithFixedDecimals = (number, fallbackValue, decimals = 2) => {
  try {
    return number ? parseFloat(number).toFixed(decimals) : fallbackValue;
  } catch {
    return fallbackValue;
  }
};

export const setIpedsId = async (opeId) => {
  // Add zeros at the beginning until the university id is 8 characters long
  // Some universities from Learning service have missing zeros
  const paddedOpeId = String(opeId).padStart(8, 0) ?? "";
  return getApiClient()
    .get(`/home-university/ipeds/${paddedOpeId}`)
    .then((response) => {
      return response.data?.ipeds ?? null;
    })
    .catch(() => {
      return null;
    });
};

/*
  This function returns a specific part of the decoded string based on the value of the 'what' parameter.
  The 'what' parameter can be either 'value' or 'label'.
  If 'what' is set to 'label', the function returns the first part of the decoded string.
  If 'what' is set to 'value' or any other value, the function returns the second part of the decoded string.
*/
export const getDecodedData = (encodedString, what = "value") => {
  const decodedString = decodeURIComponent(encodedString);
  const parts = decodedString.split("#$#");
  let position = what === "label" ? 0 : 1;
  return parts.length > 1 ? parts[position] : decodedString;
};

export const hideSessionDeadline = (session) => {
  return session?.session_types?.map((type) => type?.id).includes(4); // 4 Faculty Led;
};

export const parseCreatedAt = (createdAt) => {
  createdAt = createdAt || new Date().toISOString();
  return formatDate(createdAt, "numeric");
};
