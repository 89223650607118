<template>
  <div v-if="data.state" class="relative z-50">
    <div
      class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
    ></div>
    <Dialog
      :open="data.state"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
      @close-modal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-20 relative"
      >
        <div class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r">
          <button
            class="h-6 w-6 flex items-center justify-center"
            @click="closeModal"
          >
            <span class="sr-only">Close</span>
            <CloseIcon />
          </button>
        </div>
        <DialogPanel>
          <DialogTitle
            class="flex justify-center mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            {{ title }}
          </DialogTitle>
          <DialogDescription v-if="programs.items.length" class="mt-4 mb-10">
            <div
              v-for="program in programs.items"
              :key="program.id"
              class="w-full flex space-y-2"
            >
              <router-link
                class="text-teal-900 mt-2 cursor-pointer"
                :to="{
                  name: 'program-detail-view',
                  params: {
                    programPageId: program.id,
                  },
                  query: { 'session-id': props?.data?.id, 'session-all': true },
                }"
                target="_blank"
              >
                {{ program.name }}
              </router-link>
            </div>
            <Pagination
              v-if="showPagination"
              class="mt-4 mb-14"
              :page="requestParams.page"
              :total-of-records="programs.count"
              :records-per-page="requestParams.limit"
              @change-page="changePage"
            />
          </DialogDescription>
          <Spinner v-if="programsLoading">
            <template #spinnercontent>
              <p class="text-gray-600">
                Loading programs...
              </p>
            </template>
          </Spinner>
          <DialogDescription
            v-if="!programs.items.length && !programsLoading"
            class="flex justify-center"
          >
            <span class="mt-4"
              >No programs found for the session specified.</span
            >
          </DialogDescription>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogDescription,
  DialogTitle,
} from "@headlessui/vue";
import { useGetPrograms } from "@/components/program-manager/sessions/composable";
import { watch, computed, reactive } from "vue";
import Spinner from "@/components/helpers/Spinner.vue";
import Pagination from "@/components/shared/Pagination.vue";
import CloseIcon from "@/components/svg-icons/CloseIcon.vue";

const props = defineProps({
  title: {
    type: String,
    default: "Select Program Page",
  },
  data: {
    type: Object,
    default: () => ({
      id: undefined,
      state: false,
    }),
  },
});

const emit = defineEmits(["closeModal"]);

const requestParams = reactive({
  limit: 10,
  page: 1,
});

const {
  execute: getPrograms,
  state: programs,
  isLoading: programsLoading,
} = useGetPrograms({ immediate: false }, {});

const changePage = (newPage) => {
  requestParams.page = newPage;
};

const showPagination = computed(
  () => programs.value.count > requestParams.limit
);

watch(
  [() => props.data.id, () => requestParams.page],
  ([programId, newPage]) => {
    if (programId) {
      getPrograms(0, {
        page: newPage,
        extraParams: {
          session_ids: [programId],
        },
      });
    }
  }
);

const closeModal = () => {
  emit("closeModal");
};
</script>
