<script>
export default {
  name: "SelectFulfillHouseModal",
};
</script>

<script setup>
import {
  computed,
  defineEmits,
  defineProps,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toast-notification";
import { maxLength, helpers, requiredIf } from "@vuelidate/validators";
import { uuid } from "vue-uuid";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import { PencilIcon } from "@heroicons/vue/24/outline";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import BedFulfillmentModal from "@/components/StudentFulfillment/components/modals/BedFulfillmentModal";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import ExpandingSection from "@/components/shared/section/ExpandingSection";
import SimpleTextarea from "@/components/shared/SimpleTextarea.vue";
import PriceDifferenceModal from "@/components/StudentFulfillment/components/modals/PriceDifferenceModal";
import Spinner from "@/components/helpers/Spinner.vue";
import VSelectCaret from "@/components/shared/select/VSelectCaret";
import { useGetHousing } from "@/components/StudentFulfillment/composables/housings";
import { useGetProfile } from "@/components/StudentFulfillment/composables/profiles";
import { useGetProgramSession } from "@/components/StudentFulfillment/composables/sessions";
import ordersService from "@/services/orders";
import productPriceService from "@/services/productPrices";
import orders from "@/services/orders";
import { checkValidRequestedRoom } from "@/components/StudentFulfillment/components/modals/HousingFulfillmentModal/utils";
import { formattedPriceFromCentsWithoutCents } from "@/util/formatter";
import { useGetOrderSessionDiff } from "@/composables/useOrder.js";
import {
  CANCELLATION_REASONS_OTHER,
  CANCELLATION_REASONS,
  ERROR_TIMEOUT,
  HOUSING_TYPES_MAP,
  MAX_NOTE_TEXTAREA_LIMIT,
  ORDER_PRODUCT_STATUS,
  ORDER_STATUS,
  ORDER_PRODUCT_TYPE,
  REQUESTED_ROOM_TYPE,
  SUCCESS_TIMEOUT,
  TIERS_OBJ,
} from "@/constants";
import {
  DEFAULT_HOUSING_ROOM_PRICE,
  ERROR_EMPTY_ROOM_ID_MESSAGE,
  ERROR_MESSAGE,
  HOUSING_FULFILLMENT_MODAL_OPTIONS,
} from "@/components/StudentFulfillment/components/modals/HousingFulfillmentModal/constants";
const toast = useToast();

const props = defineProps({
  modalIsOpen: {
    type: Boolean,
    default: true,
  },
  order: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["closeModal"]);

const { modalIsOpen, order } = toRefs(props);

const optionSelectedId = ref(0);

const isEditingRequestedRoom = ref(false);
const requestedRoom = ref({});

const state = reactive({
  isSavingOrder: false,
  isSavingOrderRoom: false,
});

const modalStates = reactive({
  hideModal: false,
  openPriceDiffModal: false,
  openSelectBedsModal: false,
});

// Different from mocks: We will not have “Add Notes” for now; we’ll come back and add this in a fast follow ticket
// const checkBox = ref(false);
// const note = reactive({
//   type: undefined,
//   sensitivity: false,
//   notes: undefined,
// });

const housingFulfillmentOrderData = reactive({
  roomAssignmentSelected: [],
  cancellationReason: {
    option: undefined,
    text: "",
  },
});

const session = computed(() => {
  const metadata = order?.value?.payment_processing?.order_metadata;
  return {
    session_id: metadata?.session_id,
    city_id: metadata?.session_city_id,
    city: metadata?.session_city,
    session_types: metadata?.program_type?.split("; "),
    arrival_date: metadata?.start_date,
    departure_date: metadata?.end_date,
  };
});

const orderRoomTypes = computed(() => {
  return order?.value?.product_details?.rooms?.map(({ room }) => ({
    id: room?.type,
    label: room?.type,
  }));
});

const housingId = computed(() => {
  const room = order?.value?.product_details?.rooms?.[0];
  return room?.room?.unit?.housing?.id ?? room?.bed?.housing_id;
});

const roomTypeOptions = computed(
  () =>
    programSessionOptions.value?.items?.session_units?.flatMap(
      ({ unit }) =>
        unit?.rooms
          ?.filter((room) =>
            checkValidRequestedRoom(
              room,
              order?.value?.payment_processing?.order_metadata?.start_date
            )
          )
          ?.map((room) => ({ ...room, housing: { ...unit?.housings } })) || []
    ) || []
);

const orderRoomIsEditable = computed(() =>
  [
    ORDER_STATUS.draft,
    ORDER_STATUS.submitted,
    ORDER_STATUS.paidConfirmed,
  ].includes(order.value?.order?.status)
);

const roomType = computed(
  () =>
    REQUESTED_ROOM_TYPE?.[order?.value?.product_details?.rooms?.[0]?.room?.type]
);

const participant = computed(() => ({
  id: order?.value?.order?.participant_id,
  name: order?.value?.order?.participant_first_name ?? "",
  fullname: order?.value?.order?.participant_fullname ?? "",
}));

const needs_advising = computed(
  () => profileOptions.value?.needs_advising ?? false
);

const isHousingLocalHost = computed(
  () => housingOptions.value?.type === HOUSING_TYPES_MAP.LOCAL_HOST
);

const roomsDiff = ref({
  original: { ...DEFAULT_HOUSING_ROOM_PRICE },
  updated: { ...DEFAULT_HOUSING_ROOM_PRICE },
});

const ordersDiff = ref({
  original: null,
  updated: null,
});

const isLoading = computed(
  () =>
    (!!housingLoading?.value && !housingIsReady?.value) ||
    (!!programSessionLoading?.value && !programSessionIsReady?.value) ||
    (!!profileLoading?.value && !profileIsReady?.value)
);

const showCancellationReasonText = computed(
  () =>
    housingFulfillmentOrderData?.cancellationReason?.option?.id ==
    CANCELLATION_REASONS_OTHER
);

const showRoomAssignment = computed(
  () =>
    optionSelectedId.value === HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment &&
    !needs_advising.value &&
    housingFulfillmentOrderData?.roomAssignmentSelected?.length
);

const showCurrentRoomAssignment = computed(
  () =>
    optionSelectedId.value === HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment &&
    !!order?.value?.product_details?.rooms?.[0]?.room?.unit?.id
);

const canEditRequestedRoomType = computed(() => {
  const housingFulfillmentStatus =
    order.value?.product_details?.rooms?.[0]?.status;
  return (
    housingFulfillmentStatus !== ORDER_PRODUCT_STATUS.fulfilled &&
    housingFulfillmentStatus !== ORDER_PRODUCT_STATUS.canceled &&
    optionSelectedId.value !== HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment &&
    optionSelectedId.value !== HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled
  );
});

const updateRoom = async () => {
  state.isSavingOrderRoom = true;
  try {
    const oldProductId = order.value?.product_details?.rooms?.[0]?.room?.id;
    let response = null;

    if (oldProductId) {
      response = await orders.updateOrderLineItem({
        orderId: order.value.order.id,
        productId: oldProductId,
        payload: {
          new_product_id: requestedRoom.value.id,
          status: order.value?.product_details?.rooms?.[0]?.status,
          quantity: order.value?.product_details?.rooms?.[0]?.quantity,
        },
        skipErrorBus: true,
      });
    } else {
      response = await orders.createOrderLineItem({
        orderId: order.value.order.id,
        payload: {
          new_product_id: requestedRoom.value.id,
          status: order.value?.product_details?.rooms?.[0]?.status,
          product_type: ORDER_PRODUCT_TYPE.room,
        },
        skipErrorBus: true,
      });
      handleCloseModal();
    }

    order.value.product_details = response?.data?.data?.product_details;
    isEditingRequestedRoom.value = false;
    toast.open({
      message: "Order room updated successfully",
      type: "success",
      position: "bottom",
      duration: SUCCESS_TIMEOUT,
    });
  } catch (exception) {
    toast.open({
      message: `${ERROR_MESSAGE}`,
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  } finally {
    state.isSavingOrderRoom = false;
  }
};

const editRoom = () => {
  isEditingRequestedRoom.value = orderRoomIsEditable.value;
};

const cancelEditRoom = () => {
  isEditingRequestedRoom.value = false;
};

const getRoomType = (roomType) => {
  return roomType == "1 bed" ? "Private Room" : "Shared Room";
};

const hasCityAndCountry = (housing) => {
  return housing?.city_id_rel && housing?.city_id_rel.country;
};

const getCityAndCountry = (housing) => {
  if (hasCityAndCountry(housing)) {
    return `${housing.city_id_rel.city}, ${housing.city_id_rel.admin_name}, ${housing.city_id_rel.country.name}`;
  } else {
    return "";
  }
};

const {
  isLoading: housingLoading,
  isReady: housingIsReady,
  execute: executeFetchHousing,
  state: housingOptions,
} = useGetHousing({ immediate: false, throwError: true });

const {
  isLoading: profileLoading,
  isReady: profileIsReady,
  execute: executeFetchProfile,
  state: profileOptions,
} = useGetProfile({ immediate: false, throwError: true });

const {
  isLoading: programSessionLoading,
  isReady: programSessionIsReady,
  execute: executeFetchProgramSession,
  state: programSessionOptions,
} = useGetProgramSession({ immediate: false, throwError: true });

const handleRadioOption = (optionSelected) => {
  if (!state.isSavingOrder) {
    if (!needs_advising.value) {
      optionSelectedId.value = optionSelected;
      if (
        optionSelected === HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment &&
        !isHousingLocalHost.value
      ) {
        housingFulfillmentOrderData.roomAssignmentSelected = [];
        modalStates.openSelectBedsModal = true;
        modalStates.hideModal = true;
      }
    } else if (
      optionSelected !== HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment &&
      optionSelected !== HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled
    ) {
      optionSelectedId.value = optionSelected;
    }
  }
};

const handleCloseModal = () => {
  state.isSavingOrder = false;
  optionSelectedId.value = HOUSING_FULFILLMENT_MODAL_OPTIONS.empty;
  housingFulfillmentOrderData.roomAssignmentSelected = [];
  housingFulfillmentOrderData.cancellationReason.option = undefined;
  housingFulfillmentOrderData.cancellationReason.text = "";
  isEditingRequestedRoom.value = false;
  emit("closeModal");
};

const handleCloseBedsModal = () => {
  modalStates.openSelectBedsModal = false;
  modalStates.hideModal = false;
  if (!housingFulfillmentOrderData?.roomAssignmentSelected?.length) {
    optionSelectedId.value = HOUSING_FULFILLMENT_MODAL_OPTIONS.empty;
  }
};

const handleClosePriceDifferenceModal = () => {
  state.isSavingOrder = false;
  modalStates.openPriceDiffModal = false;
};

const updateSelectedItems = (items) => {
  housingFulfillmentOrderData.roomAssignmentSelected =
    items.map((item) => ({
      ...item,
      bed_id: item?.id,
      room_name: `${item?.room_type} | ${item?.id}`,
    })) || [];
};

const productId = computed(
  () => order?.value?.product_details?.rooms?.[0]?.room?.id
);

const executeSaveAndClose = async ({ payload }) => {
  try {
    v$.value.$reset();
    const isValid = await v$.value.$validate();
    const orderId = order?.value?.order?.id;
    const itemProductId = productId.value;

    if (isValid && orderId) {
      let response = null;

      if (itemProductId) {
        response = await ordersService.updateOrderLineItem({
          orderId: orderId,
          productId: itemProductId,
          payload: payload,
          skipErrorBus: true,
        });
      } else {
        response = await ordersService.createOrderLineItem({
          orderId: orderId,
          payload: {
            status: ORDER_PRODUCT_STATUS.unfulfilled,
            product_type: ORDER_PRODUCT_TYPE.room,
            new_product_id: requestedRoom.value.id,
          },
          skipErrorBus: true,
        });
      }
      const productDetails = response?.data?.data?.product_details;
      if (productDetails) {
        order.value.product_details = productDetails;

        toast.open({
          message: "Order updated Successfully",
          type: "success",
          position: "bottom",
          duration: SUCCESS_TIMEOUT,
        });
        handleCloseModal();
      }
    }
  } catch (err) {
    toast.open({
      message: `${ERROR_MESSAGE}`,
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  } finally {
    state.isSavingOrder = false;
  }
};

const validateRoomPricesAndSave = async ({ newProduct }) => {
  if (newProduct) {
    const newProductId = newProduct?.room_id;
    const productDetails = await productPriceService.getList({
      product_ids: [productId.value, newProductId],
    });
    const items = productDetails?.data?.data?.items;

    if (items) {
      const bedId = newProduct?.bed_id;
      const oldRoomPrice = items.find(
        (item) => item.product_id == productId.value
      );
      const newBedPrice = items.find((item) => item.product_id == newProductId);

      // This is going to call orders/pricing with and without the new product
      // so that we have diff to make comparison
      ordersDiff.value = await useGetOrderSessionDiff({
        programSession: programSessionOptions.value?.items,
        order: order?.value?.order,
        newProducts: [
          {
            type: "room_id",
            value: newProduct?.room_id,
          },
        ],
      });
      roomsDiff.value.original = {
        housingName: housingOptions?.value?.name,
        housingTier: housingOptions?.value?.tier?.name,
        roomType: order?.value?.product_details?.rooms?.[0]?.room?.type,
        roomPrice: formattedPriceFromCentsWithoutCents(
          oldRoomPrice.price_in_cents
        ),
      };
      roomsDiff.value.updated = {
        housingName: newProduct.housing_name,
        housingTier: newProduct.housing_tier,
        roomType: newProduct.room_type,
        roomPrice: formattedPriceFromCentsWithoutCents(
          newBedPrice.price_in_cents
        ),
      };
      modalStates.openPriceDiffModal = true;
    }
  }
};

const saveHousingFulfillmentOrderAfterPriceConfirmation = async () => {
  const selectedRoomBed =
    housingFulfillmentOrderData?.roomAssignmentSelected?.[0];
  if (selectedRoomBed) {
    modalStates.openPriceDiffModal = false;

    const roomId = selectedRoomBed?.room_id;
    const bedId = selectedRoomBed?.bed_id;
    if (roomId && bedId) {
      await executeSaveAndClose({
        payload: {
          new_product_id: roomId,
          bed_id: bedId,
          status: ORDER_PRODUCT_STATUS.fulfilled,
        },
      });
    } else {
      toast.open({
        message: ERROR_EMPTY_ROOM_ID_MESSAGE,
        type: "error",
        position: "bottom",
        duration: ERROR_TIMEOUT,
      });
    }
  }
};

const saveHousingFulfillmentOrder = async () => {
  state.isSavingOrder = true;

  if (
    optionSelectedId.value === HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment
  ) {
    if (!isHousingLocalHost.value) {
      await validateRoomPricesAndSave({
        newProduct: housingFulfillmentOrderData.roomAssignmentSelected[0],
      });
    } else {
      await executeSaveAndClose({
        payload: {
          status: ORDER_PRODUCT_STATUS.fulfilled,
        },
      });
    }
  } else {
    // Ready for Fulfillment | Canceled
    const payload = {
      bed_id: order?.value?.product_details?.rooms?.[0]?.bed?.id,
      status: ORDER_PRODUCT_STATUS.unfulfilled,
      cancellation_reason: undefined,
    };

    if (optionSelectedId.value === HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled) {
      payload.status = ORDER_PRODUCT_STATUS.canceled;
      payload.cancellation_reason =
        housingFulfillmentOrderData?.cancellationReason?.option?.label;
      if (showCancellationReasonText.value) {
        payload.cancellation_reason =
          housingFulfillmentOrderData?.cancellationReason?.text;
      }
    }
    await executeSaveAndClose({ payload });
  }
};

watch(modalIsOpen, async (open) => {
  const housingFulfillmentStatus =
    order.value?.product_details?.rooms?.[0]?.status;
  if (housingFulfillmentStatus === ORDER_PRODUCT_STATUS.fulfilled) {
    optionSelectedId.value = HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment;
  } else if (
    !needs_advising.value &&
    housingFulfillmentStatus === ORDER_PRODUCT_STATUS.unfulfilled
  ) {
    optionSelectedId.value =
      HOUSING_FULFILLMENT_MODAL_OPTIONS.ready_fulfillment;
  } else if (housingFulfillmentStatus === ORDER_PRODUCT_STATUS.canceled) {
    const reason =
      order.value?.product_details?.rooms?.[0]?.cancellation_reason;
    optionSelectedId.value = HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled;
    housingFulfillmentOrderData.cancellationReason.option = {
      id: reason,
      label: reason,
    };
    housingFulfillmentOrderData.cancellationReason.text = reason;
  } else {
    optionSelectedId.value = HOUSING_FULFILLMENT_MODAL_OPTIONS.empty;
  }
  if (open && !!order?.value?.order?.session_id && !!participant.value?.id) {
    if (!!housingId.value) {
      await executeFetchHousing(0, housingId.value);
    }
    await executeFetchProgramSession(0, order?.value?.order.session_id);
    await executeFetchProfile(0, participant.value?.id);
  } else {
    roomTypeOptions.value = undefined;
    housingOptions.value = undefined;
    profileOptions.value = undefined;
  }
});

const rules = computed(() => ({
  cancellationReason: {
    option: {
      required: helpers.withMessage(
        "This field is required",
        requiredIf(
          () =>
            optionSelectedId.value ===
            HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled
        )
      ),
    },
    text: {
      required: helpers.withMessage(
        "This field is required",
        requiredIf(
          () =>
            optionSelectedId.value ===
              HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled &&
            showCancellationReasonText.value
        )
      ),
      maxLength: maxLength(MAX_NOTE_TEXTAREA_LIMIT),
    },
  },
}));

const v$ = useVuelidate(rules, housingFulfillmentOrderData, {
  $registerAs: `FulfillmentHousingModal-${uuid.v4()}`,
  $lazy: true,
  $autoDirty: true,
});
</script>

<template>
  <div>
    <BedFulfillmentModal
      :is-modal-open="modalStates.openSelectBedsModal"
      :session="session"
      :housing="housingOptions"
      :order-room-types="orderRoomTypes"
      @close-modal="handleCloseBedsModal"
      @update-selected-items="updateSelectedItems"
    ></BedFulfillmentModal>

    <PriceDifferenceModal
      :is-modal-open="modalStates.openPriceDiffModal"
      :rooms-diff="roomsDiff"
      :orders-diff="ordersDiff"
      @update-price="saveHousingFulfillmentOrderAfterPriceConfirmation"
      @keep-original-price="() => {}"
      @close-modal="handleClosePriceDifferenceModal"
    ></PriceDifferenceModal>

    <div v-if="modalIsOpen && !modalStates.hideModal" class="relative z-50">
      <div
        class="fixed inset-0 z-40 hidden bg-university-primary opacity-50 sm:block"
      ></div>
      <Dialog
        :open="modalIsOpen && !modalStates.hideModal"
        class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-12"
        @close-modal="handleCloseModal"
        @click.self="handleCloseModal"
      >
        <div
          class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-50 relative"
        >
          <div
            class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r"
          >
            <button
              class="h-6 w-6 flex items-center justify-center"
              @click="handleCloseModal"
            >
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <DialogPanel>
            <div v-if="!isLoading">
              <DialogTitle
                class="flex justify-center mt-6 mb-4 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
              >
                {{ `${participant?.fullname} Housing` ?? "Housing" }}
              </DialogTitle>

              <ExpandingSection
                :field-label="`${participant.name}'s request`"
                :field-label-classes="`uppercase`"
                :input-classes="`!bg-white mt-0 mb-0 p-0 !border-white`"
                :unmount="false"
                class="col-span-2"
              >
                <template #content>
                  <div class="border-t-2 border-placeholder">
                    <div
                      class="grid xs:grid-cols-2 mb-4 sm:grid-cols-2 gap-4 mt-4"
                    >
                      <div
                        v-if="housingId"
                        class="text-base font-semibold text-gray-700"
                      >
                        Requested Housing Type: <br />
                        <span
                          v-if="housingOptions?.type"
                          class="font-medium flex-row"
                          >{{ housingOptions?.type }}</span
                        >
                        <div v-else class="h-4 w-3/6 bg-blue-400"></div>
                      </div>
                      <div
                        v-if="housingId"
                        class="text-base font-semibold text-gray-700"
                      >
                        Requested Tier: <br />
                        <span
                          class="font-medium"
                          v-if="housingOptions?.tier?.name"
                          >{{ TIERS_OBJ[housingOptions.tier.name] }}</span
                        >
                        <div v-else class="h-4 w-3/6 bg-blue-400"></div>
                      </div>
                      <div
                        v-if="housingId"
                        class="text-base font-semibold text-gray-700"
                      >
                        Requested Housing Listing: <br />
                        <span v-if="housingOptions?.name" class="font-medium"
                          ><router-link
                            :to="{
                              name: 'housing-edit',
                              params: { itemId: housingOptions?.id },
                            }"
                            class="inline-flex items-center text-sm text-teal-900 hover:underline"
                            >{{ housingOptions?.name }}</router-link
                          ></span
                        >
                        <div v-else class="h-4 w-3/6 bg-blue-400"></div>
                      </div>
                      <div
                        class="text-base font-semibold text-gray-700"
                        :class="[isEditingRequestedRoom ? 'row-span-2' : '']"
                      >
                        Requested Room Type: <br />
                        <div
                          v-if="!isEditingRequestedRoom"
                          class="flex justify-between"
                        >
                          <span v-if="roomType" class="font-medium">{{
                            getRoomType(
                              order.product_details?.rooms?.[0]?.room?.type
                            )
                          }}</span>
                          <button
                            @click="editRoom()"
                            :disabled="!canEditRequestedRoomType"
                          >
                            <pencil-icon
                              class="w-[18px] text-teal-900 mr-3 cursor-pointer"
                            />
                          </button>
                        </div>
                        <div v-else>
                          <VSelectCaret
                            v-model="requestedRoom"
                            :options="roomTypeOptions"
                            :disabled="state.isSavingOrder"
                            placeholder="Room type"
                            scroll-input="auto"
                            input-max-height="29px"
                          >
                            <template #selected-option="{ type }">
                              <div class="font-medium">
                                <span>
                                  {{
                                    getRoomType(
                                      requestedRoom?.type ||
                                        order.product_details?.rooms?.[0]?.room
                                          ?.type
                                    )
                                  }}
                                </span>
                              </div>
                            </template>
                            <template #option="{ id, housing, type }">
                              <div>
                                <div class="flex flex-col text-md">
                                  <span>
                                    {{ housing?.name }},
                                    {{ housing?.tier?.name }}, {{ type }}
                                  </span>
                                  <span v-if="hasCityAndCountry(housing)">
                                    {{ getCityAndCountry(housing) }}
                                  </span>
                                  <em class="font-medium text-md">
                                    {{ id }}
                                  </em>
                                </div>
                              </div>
                            </template>
                          </VSelectCaret>
                          <div class="mt-2 flex justify-end">
                            <BaseButton
                              class="text-base font-medium"
                              outlined
                              @click="cancelEditRoom()"
                            >
                              Cancel
                            </BaseButton>
                            <ButtonWithSpinner
                              :prop-loading="state.isSavingOrderRoom"
                              class="mr-0 text-base font-semibold"
                              data-cy="save-order-room-button"
                              variant="secondary"
                              variant-type="normal"
                              @click.prevent="updateRoom()"
                            >
                              <span>Save</span>
                            </ButtonWithSpinner>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="housingId"
                        class="text-base font-semibold text-gray-700"
                      >
                        Location: <br />
                        <span
                          v-if="hasCityAndCountry(housingOptions)"
                          class="font-medium"
                          >{{ getCityAndCountry(housingOptions) }}</span
                        >
                        <div v-else class="h-4 w-3/6 bg-blue-400"></div>
                      </div>
                    </div>
                  </div>
                </template>
              </ExpandingSection>
              <div v-show="housingId" class="border-t-2 border-placeholder">
                <div class="text-base font-semibold text-gray-700 mt-4">
                  Select Housing Assignment Status: <br />
                </div>
                <div>
                  <fieldset class="mt-4">
                    <div class="items-center">
                      <fieldset class="mt-2">
                        <div class="flex flex-row items-center">
                          <div
                            class="flex flex-1 items-center w-fit"
                            @click="
                              handleRadioOption(
                                HOUSING_FULFILLMENT_MODAL_OPTIONS.ready_fulfillment
                              )
                            "
                          >
                            <input
                              type="radio"
                              :checked="
                                optionSelectedId ===
                                HOUSING_FULFILLMENT_MODAL_OPTIONS.ready_fulfillment
                              "
                              :disabled="state.isSavingOrder"
                              class="h-4 w-4 border-gray-300 form-radio cursor-pointer"
                            />
                            <label
                              class="ml-3 block font-medium text-gray-700 cursor-pointer"
                              >Ready for Fulfillment</label
                            >
                          </div>
                          <div>
                            <!--
                              We will not have “Add Notes” for now; we’ll come back and add this in a fast follow ticket
                            <BasicCheckbox
                              v-if="
                                optionSelectedId ===
                                  HOUSING_FULFILLMENT_MODAL_OPTIONS.empty ||
                                optionSelectedId ===
                                  HOUSING_FULFILLMENT_MODAL_OPTIONS.ready_fulfillment
                              "
                              v-model="checkBox"
                              :disabled="state.isSavingOrder"
                              class="my-auto"
                              field-label="Add notes"
                            />-->
                          </div>
                        </div>
                        <!--
                              We will not have “Add Notes” for now; we’ll come back and add this in a fast follow ticket
                        <div
                          v-if="
                            checkBox &&
                            (optionSelectedId ===
                              HOUSING_FULFILLMENT_MODAL_OPTIONS.empty ||
                              optionSelectedId ===
                                HOUSING_FULFILLMENT_MODAL_OPTIONS.ready_fulfillment)
                          "
                          class="flex flex-col w-full mb-4"
                        >
                          <div class="grid grid-cols-2">
                            <div class="flex flex-col">
                              <div
                                class="text-base font-medium text-gray-700 mt-4"
                              >
                                Note Type
                              </div>
                              <div>
                                <VSelectCaret
                                  v-model="note.type"
                                  outer-classes="w-52 checkbox-placeholder"
                                  :options="NOTE_TYPES"
                                  placeholder="Type"
                                  label="label"
                                  scroll-input="auto"
                                  input-max-height="29px"
                                />
                              </div>
                            </div>
                            <div class="flex flex-col">
                              <div
                                class="text-base font-medium text-gray-700 mt-4"
                              >
                                Note Sensitivity
                              </div>
                              <div>
                                <BasicCheckbox
                                  v-model="note.sensitivity"
                                  class="my-auto"
                                  field-label="Sensitive and should be restricted"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="mt-2">
                            <label
                              class="text-base font-medium text-gray-700 mt-4"
                              >Notes</label
                            >
                            <div class="mt-1">
                              <textarea
                                id="notes"
                                v-model="note.description"
                                rows="5"
                                name="notes"
                                class="block w-full form-input sm:text-sm"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>-->
                      </fieldset>
                      <fieldset class="mt-2">
                        <div
                          class="flex items-center w-fit"
                          @click="
                            handleRadioOption(
                              HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment
                            )
                          "
                        >
                          <input
                            type="radio"
                            :disabled="needs_advising || state.isSavingOrder"
                            :checked="
                              optionSelectedId ===
                              HOUSING_FULFILLMENT_MODAL_OPTIONS.fulfillment
                            "
                            class="h-4 w-4 border-gray-300 form-radio cursor-pointer"
                          />
                          <label
                            :class="[
                              'ml-3 block font-medium text-gray-700 cursor-pointer',
                              needs_advising ? 'text-gray-400' : undefined,
                            ]"
                            >Fulfilled
                            <span v-if="!isHousingLocalHost"
                              >- Assign the student a room as requested</span
                            ></label
                          >
                        </div>
                      </fieldset>
                      <fieldset class="mt-2">
                        <div
                          class="flex items-center w-fit"
                          @click="
                            handleRadioOption(
                              HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled
                            )
                          "
                        >
                          <input
                            type="radio"
                            :disabled="needs_advising || state.isSavingOrder"
                            :checked="
                              optionSelectedId ===
                              HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled
                            "
                            class="h-4 w-4 border-gray-300 form-radio cursor-pointer"
                          />
                          <label
                            :class="[
                              'ml-3 block font-medium text-gray-700 cursor-pointer',
                              needs_advising ? 'text-gray-400' : undefined,
                            ]"
                            >Canceled - Cancel the student's request</label
                          >
                        </div>
                      </fieldset>
                      <!--
                        TODO: Add another radio button for Independent Housing that will be disabled for now
                        <fieldset class="mt-2">
                        <div class="flex items-center" @click="
                              handleRadioOption(
                                HOUSING_FULFILLMENT_MODAL_OPTIONS.independent_housing
                              )
                            ">
                          <input
                            type="radio"
                            :checked="
                              optionSelectedId ===
                              HOUSING_FULFILLMENT_MODAL_OPTIONS.independent_housing
                            "
                            class="h-4 w-4 border-gray-300 form-radio cursor-pointer"

                          />
                          <label
                            class="ml-3 block font-medium text-gray-700 cursor-pointer"

                            >Independent Housing</label
                          >
                        </div>
                      </fieldset> -->
                    </div>
                  </fieldset>
                </div>
                <div class="mt-8">
                  <div v-if="showRoomAssignment">
                    <label class="w-full common-label-text mt-4"
                      >Room Assignment</label
                    >
                    <div
                      v-for="room in housingFulfillmentOrderData?.roomAssignmentSelected ||
                      []"
                      :key="room.id ?? uuid.v4()"
                    >
                      {{ room?.room_name ?? "" }}
                    </div>
                  </div>
                  <div v-else-if="showCurrentRoomAssignment">
                    <label class="w-full common-label-text mt-4"
                      >Room Assignment</label
                    >
                    <div
                      v-for="room in order?.product_details?.rooms || []"
                      :key="room.room?.id ?? uuid.v4()"
                    >
                      {{ room?.room?.type ?? "" }} | {{ room?.bed?.id ?? "" }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      optionSelectedId ===
                      HOUSING_FULFILLMENT_MODAL_OPTIONS.canceled
                    "
                    class="w-full mb-4"
                  >
                    <label class="w-full common-label-text mt-4"
                      >Cancellation Reason</label
                    >
                    <div class="mt-1">
                      <VSelectCaret
                        v-model="
                          housingFulfillmentOrderData.cancellationReason.option
                        "
                        outer-classes="w-80 checkbox-placeholder"
                        placeholder="Reason"
                        label="label"
                        scroll-input="auto"
                        input-max-height="29px"
                        :disabled="state.isSavingOrder"
                        :options="CANCELLATION_REASONS"
                        :vuelidate-instance="v$.cancellationReason.option"
                        @option:selected="
                          () =>
                            (housingFulfillmentOrderData.cancellationReason.text =
                              '')
                        "
                      ></VSelectCaret>
                      <div class="mt-4" v-if="showCancellationReasonText">
                        <SimpleTextarea
                          v-model="
                            housingFulfillmentOrderData.cancellationReason.text
                          "
                          label-name="Cancellation Reason"
                          :vuelidate-instance="v$.cancellationReason.text"
                          :max-length="MAX_NOTE_TEXTAREA_LIMIT"
                          :disabled="state.isSavingOrder"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-center mt-6 mb-6 space-x-4">
                <BaseButton
                  class="text-base font-medium"
                  outlined
                  @click="handleCloseModal"
                >
                  Cancel
                </BaseButton>
                <ButtonWithSpinner
                  id="saveHousingFulfillmentOrderButton"
                  :prop-loading="state.isSavingOrder"
                  class="text-base font-semibold !h-7 w-24"
                  data-cy="save-order-button"
                  variant="secondary"
                  variant-type="normal"
                  @click.prevent="saveHousingFulfillmentOrder()"
                >
                  <span>Save</span>
                </ButtonWithSpinner>
              </div>
            </div>
            <Spinner
              v-else-if="isLoading"
              class="relative h-65vh bg-blue-100"
            />
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  </div>
</template>
