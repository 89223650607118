import { Formio } from "formiojs";

export const tokenValidationMixin = {
  methods: {
    async isTokenValid() {
      return true;
    },
    logout() {
      Formio.logout();
      this.$store.dispatch("resetCounts");
      this.$store.commit("logout");
      this.$router.push("/sign-in");
    },
  },
};
