import { learningServiceRequest, skip } from "./utils";
import { DAYS_OF_WEEK, LEARNING_SERVICE_QUERY_LIMIT } from "../constants";

const housingService = {
  async getHousings({
    limit = 25,
    page = 1,
    q = "",
    extraParams = {},
    failureMessage = "Error loading Housing data",
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/housings",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      source,
      failureMessage,
    });
  },
  async getHousingById(housingId, skipErrorBus = false) {
    return await learningServiceRequest({
      method: "get",
      url: `/housings/${housingId}`,
      failureMessage: "Error loading Housing data",
      skipErrorBus,
    });
  },
  async createHousing(payload) {
    return await learningServiceRequest({
      method: "post",
      url: "/housings",
      payload: payload,
    });
  },
  async duplicateHousing(housingId) {
    return await learningServiceRequest({
      method: "post",
      url: `/housings/${housingId}/copy`,
    });
  },
  async saveHousing(housingId, payload) {
    return await learningServiceRequest({
      method: "put",
      url: `/housings/${housingId}`,
      payload: payload,
    });
  },
  async getVendors(limit = LEARNING_SERVICE_QUERY_LIMIT, page = 1, q = "") {
    return await learningServiceRequest({
      method: "get",
      url: "/vendors",
      params: { limit, skip: skip(page, limit), q },
      failureMessage: "Error loading Vendor data",
    });
  },
  async getCompanies(
    limit = LEARNING_SERVICE_QUERY_LIMIT,
    page = 1,
    q = "",
    library_name = "Housing"
  ) {
    return await learningServiceRequest({
      method: "get",
      url: "/companies",
      params: { limit, skip: skip(page, limit), q, library_name },
      failureMessage: "Error loading Vendor data",
    });
  },
  async getTerms(params = {}) {
    const { limit = LEARNING_SERVICE_QUERY_LIMIT, page = 1, q = "" } = params;
    return await learningServiceRequest({
      method: "get",
      url: "/terms",
      params: { limit, page, q, ...params },
      failureMessage: "Error loading Term data",
    });
  },
  async getHomeInstitutions({
    limit = 100,
    page = 1,
    q = "",
    extraParams,
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/home_institutions",
      params: { limit, skip: skip(page, limit), q, ...extraParams },
      failureMessage: "Error loading Home Institution data",
      source,
    });
  },
  async getCountryById(countryId) {
    return await learningServiceRequest({
      method: "get",
      url: `/countries/${countryId}`,
      failureMessage: "Error loading Country data",
    });
  },
  async getHomeInstitutionsById(homeInstitutionIds) {
    const promises = homeInstitutionIds.map((id) => {
      return learningServiceRequest({
        method: "get",
        url: `/home_institutions/${id}`,
        failureMessage: "Error loading Housing data",
      });
    });
    const values = await Promise.all(promises);
    return values;
  },
  getDefaultHousingCurfew() {
    return DAYS_OF_WEEK.reduce(
      (acc, el) => ({
        ...acc,
        [el]: {
          enabled: false,
          availability: [{ start: "09:00", end: "17:00" }],
        },
      }),
      {}
    );
  },
  async archiveHousings(housingId) {
    return await learningServiceRequest({
      method: "put",
      url: `/housings/archive/${housingId}`,
    });
  },
};

export default housingService;
