<template>
  <div :class="outerClasses">
    <div class="flex flex-1 justify-start sm:hidden">
      <span
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer"
        @click.prevent="updateCurrentPage(_, 'previous')"
        >Previous</span
      >
    </div>
    <div>
      <p class="text-sm text-gray-700">
        <span class="font-medium">
          Showing {{ totalOfRecords > 0 ? startRangeOfRecords : 0 }} to
          {{ currentRangeOfRecords }} of {{ totalOfRecords }} results
        </span>
      </p>
    </div>
    <div class="flex flex-1 justify-end sm:hidden">
      <span
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer"
        @click.prevent="updateCurrentPage(_, 'next')"
        >Next</span
      >
    </div>
    <div
      class="flex flex-col hidden sm:flex sm:flex-1 sm:items-end sm:justify-between gap-4"
    >
      <div>
        <nav
          class="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <span
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            @click.prevent="updateCurrentPage(_, 'previous')"
          >
            <span class="sr-only">Previous</span>
            <ArrowLeft class="h-5 w-5 cursor-pointer" />
          </span>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <span
            v-for="(pg, index) in configPages.left"
            :key="index"
            :class="[
              pg === currentPage ? activeClass : inactiveClass,
              'cursor-pointer',
            ]"
            aria-current=" page"
            @click.prevent="updateCurrentPage(pg, 'left')"
          >
            {{ pg }}
          </span>
          <span
            v-if="configPages.middle"
            class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            >{{ configPages.middle }}</span
          >
          <span
            v-for="(pg, index) in configPages.right"
            :key="index"
            :class="[
              pg === currentPage ? activeClass : inactiveClass,
              'cursor-pointer',
            ]"
            aria-current="page"
            @click.prevent="updateCurrentPage(pg, 'right')"
          >
            {{ pg }}
          </span>
          <span
            :aria-disabled="currentPage === totalOfRecords"
            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            @click.prevent="updateCurrentPage(_, 'next')"
          >
            <span class="sr-only">Next</span>
            <ArrowRight class="cursor-pointer" />
          </span>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeft from "@/components/shared/icons/ArrowLeft.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";

export default {
  name: "Paginator",
  components: {
    ArrowLeft,
    ArrowRight,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    totalOfRecords: {
      type: Number,
      default: 100,
    },
    recordsPerPage: {
      type: Number,
      default: 10,
    },
    outerClasses: {
      type: String,
      default:
        "flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6",
    },
  },
  emits: ["changePage"],
  data() {
    return {
      currentPage: 1,
      configPages: {
        left: [1, 2, 3],
        middle: "...",
        right: [8, 9, 10],
      },
      activeClass:
        "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20",
      inactiveClass:
        "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(
        Number(this.totalOfRecords) / Number(this.recordsPerPage)
      );
    },
    currentRangeOfRecords() {
      let currentRangeOfRecords =
        Number(this.recordsPerPage) * Number(this.currentPage);
      return currentRangeOfRecords > this.totalOfRecords
        ? this.totalOfRecords
        : currentRangeOfRecords;
    },
    startRangeOfRecords() {
      let startRangeOfRecords =
        Number(this.currentRangeOfRecords) - Number(this.recordsPerPage) + 1;
      return Number(this.currentRangeOfRecords) === Number(this.totalOfRecords)
        ? Number(this.currentPage) * Number(this.recordsPerPage) -
            Number(this.recordsPerPage) +
            1
        : startRangeOfRecords;
    },
  },
  watch: {
    currentPage: {
      handler: function (newval) {
        this.$emit("changePage", newval);
      },
    },
    totalOfRecords: {
      handler: function () {
        this.updateChoices();
      },
    },
  },
  created() {
    this.currentPage = Number(this.page);
    this.updateChoices();
  },
  methods: {
    updateChoices() {
      if (this.pageCount > 10) {
        this.configPages["left"] = [1, 2, 3];
        this.configPages["right"] = [8, 9, 10];
        return;
      }
      this.configPages = {
        left: [],
        right: [],
      };
      for (let x = 0; x < this.pageCount; x++) {
        if (this.configPages["left"].length < 3)
          this.configPages["left"].push(x + 1);
        if (
          this.configPages["right"].length < Number(this.pageCount) - 3 &&
          this.pageCount > 3
        )
          this.configPages["right"].unshift(this.pageCount - x);
      }
    },
    updateCurrentPage(page, side) {
      const isDynamic = this.pageCount > 10;
      const movingByArrows = typeof page === "object";

      if (page === this.currentPage) return;
      if (side === "previous" && this.currentPage === 1) return;
      if (side === "next" && this.currentPage === this.pageCount) return;
      if (Number(page) > Number(this.pageCount)) return;

      if (!movingByArrows) {
        this.currentPage = page;
      } else if (movingByArrows && !isDynamic) {
        if (side === "next") {
          this.currentPage++;
        } else if (side === "previous") {
          this.currentPage--;
        }
      }

      if (!isDynamic) return;

      switch (side) {
        case "left":
          if (page > 1) {
            this.configPages[side] = [page - 1, page, page + 1];
            this.configPages["right"] = [page + 7, page + 8, page + 9];
          } else {
            this.configPages["right"] = [page + 7, page + 8, page + 9];
          }
          break;
        case "right":
          if (page > 8) {
            this.configPages[side] = [page - 1, page, page + 1];
            this.configPages["left"] = [page - 7, page - 6, page - 5];
          } else {
            if (this.configPages["right"][0] !== 8) {
              this.configPages[side] = [page - 1, page, page + 1];
            }
            if (this.configPages["left"][0] !== 1) {
              this.configPages["left"] = [page - 7, page - 6, page - 5];
            }
          }
          break;
        case "next":
          this.currentPage += 1;
          this.configPages["left"] = this.configPages["left"].map(
            (item) => item + 1
          );
          this.configPages["right"] = this.configPages["right"].map(
            (item) => item + 1
          );
          break;
        case "previous":
          if (this.configPages["left"][0] === 1) break;
          this.currentPage -= 1;
          this.configPages["left"] = this.configPages["left"].map(
            (item) => item - 1
          );
          this.configPages["right"] = this.configPages["right"].map(
            (item) => item - 1
          );
          break;
      }
    },
  },
};
</script>
