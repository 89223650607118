import { uuid } from "vue-uuid";

export const channelTypes = {
  CORE: 1,
  CUSTOM: 2,
  ADOPTED: 3,
  EXCHANGE: 4,
};

export const channelTypeOptions = [
  { name: "Core", id: 1 },
  { name: "Custom", id: 2 },
  { name: "Adopted", id: 3 },
  { name: "Exchanged", id: 4 },
];

export const channelTypesMapper = channelTypeOptions.reduce(
  (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
  {}
);

export const whoCanUseOptions = [
  { name: "Non-Students", id: "non-students" },
  { name: "Faculty", id: "faculty" },
];

export const minYearInSchoolOptions = [
  {
    id: "f1c83860-4d85-4cfb-928b-7db29d1cce0e",
    academic_year: "High School First Year",
  },
  {
    id: "f005bbb6-4133-4df7-8337-a7217f96cfb7",
    academic_year: "High School Second Year",
  },
  {
    id: "6af58f86-1748-4b97-9017-4d6e54549e92",
    academic_year: "High School Third Year",
  },
  {
    id: "5248857a-3402-4bca-a06b-f6abd3c78f9c",
    academic_year: "High School Fourth Year",
  },
  {
    id: "01aefff6-5e61-4d56-b856-2ec3fed4194f",
    academic_year: "Undergraduate First Year",
  },
  {
    id: "35bb9e05-0162-4e89-9d16-bb451f920a47",
    academic_year: "Undergraduate Second Year",
  },
  {
    id: "72565399-8b91-44ff-b321-7f6f28826123",
    academic_year: "Undergraduate Third Year",
  },
  {
    id: "5b7d2381-96b3-4819-a84a-3394b8c228e0",
    academic_year: "Undergraduate Fourth Year",
  },
  {
    id: "2318b5b5-d1a4-44b0-93b2-7e1fd91b1764",
    academic_year: "Not Enrolled",
  },
  {
    id: "6e188a75-4774-41f9-bbff-ff346c84899c",
    academic_year: "Graduate",
  },
  {
    id: "8a16891f-e83a-40db-a464-6881a8c4aee8",
    academic_year: "Continuing Education",
  },
];

export const requiredLanguagesOptions = [
  {
    id: "6741d907-e50c-47e2-8c51-bc51095b4222",
    value: "English",
  },
  {
    id: "01450428-ccbe-4b9c-9730-bef673958378",
    value: "Spanish",
  },
  {
    id: "88637048-253b-4ca3-bcad-ab4301347d84",
    value: "French",
  },
  {
    id: "d08f570d-806c-40b5-bef8-be2120da2c34",
    value: "Italian",
  },
  {
    id: "60bbb227-9dac-437d-9dd6-793d2090e876",
    value: "Arabic",
  },
  {
    id: "4dcd09a3-be35-46da-9aee-7841a30d2c9e",
    value: "Polish",
  },
  {
    id: "9f0a84ec-34ec-48d7-8fbc-61ceadbaa4bb",
    value: "Catalan",
  },
  {
    id: "0ca7cc27-1873-41da-a952-72badfb904db",
    value: "Portuguese",
  },
  {
    id: "2d4b08c2-06f4-43ec-a39b-637f07fbddf5",
    value: "Latin",
  },
  {
    id: "7b233cbb-7847-4f01-b716-bc215b70bc6a",
    value: "Dzongkha",
  },
  {
    id: "4591c0bf-4c9b-4d16-8253-750c2ff5155e",
    value: "Chinese",
  },
  {
    id: "b0c77279-8633-40ce-bb5e-9f1396f4992d",
    value: "Czech",
  },
  {
    id: "79dca12a-96a0-44d5-ae42-5619a5078075",
    value: "German",
  },
  {
    id: "4ecc843e-c652-4ed9-bda3-66d478db7ad2",
    value: "Croatian",
  },
  {
    id: "5f42a58e-9ac9-4cbd-9cff-7a36af767be8",
    value: "Hungarian",
  },
  {
    id: "dbaf7cf6-b793-48ca-9556-8d5300c47a5b",
    value: "Korean",
  },
  {
    id: "c332d108-f70b-4bd4-a80b-a103051ae792",
    value: "Greek",
  },
  {
    id: "2efd2a9c-7b33-408a-97b1-ecf02d7d416a",
    value: "Japanese",
  },
];

export const proficiencyLevelsOptions = [
  {
    id: "b8dcb08b-bc0b-414f-b904-7bcb601925c4",
    value: "Native",
  },
  {
    id: "1c487d7e-9139-41df-bf83-a2f0cd2a1ef5",
    value: "Advanced",
  },
  {
    id: "8508fa0c-403f-4f10-beb3-9f267e2b04bb",
    value: "Intermediate",
  },
  {
    id: "a6db9e92-6dc0-4164-aa7b-19f75726fb87",
    value: "Beginner",
  },
  {
    id: "e242f827-8564-45dc-94e9-abfbe91e0aae",
    value: "No Background",
  },
  {
    id: "6c9eac3e-3fe0-4e8d-8cf2-00368dd6e3df",
    value: "All Levels",
  },
];

export const gpaExceptionRequirementsOptions = [
  {
    id: 1,
    name: "GPA Statement",
  },
  {
    id: 2,
    name: "Phone Interview",
  },
  {
    id: 3,
    name: "Skype Interview",
  },
  {
    id: 4,
    name: "Letter of Recommendation",
  },
  {
    id: 5,
    name: "Transcript",
  },
];

export const learnerTypesOptions = [
  {
    id: "b0bb9878-b14a-4805-a8aa-fbda4a266ae1",
    value: "Undergraduate",
  },
  {
    id: "f11894e2-abb0-491e-aa2b-d75ca94b0cf2",
    value: "High School",
  },
  {
    id: "536eac19-e976-42d2-bb3f-b64d29b20f0a",
    value: "Continuing Education",
  },
  {
    id: "da4b9dc5-4d27-4977-84ee-750b851b7f9e",
    value: "Not Enrolled",
  },
  {
    id: "2b9e92c0-4e8d-4c9c-8e67-fa70bd301923",
    value: "Professional",
  },
];

export const acceptanceDecisions = [
  {
    id: 1,
    name: "API Abroad",
  },
  {
    id: 2,
    name: "Sponsoring Institution",
  },
  {
    id: 3,
    name: "Host Institution",
  },
];

export const sessionStatus = {
  draft: 1,
  openEnrollment: 2,
  inSession: 3,
  completed: 4,
  cancelled: 5,
};

export const sessionStatusNames = {
  draft: "Draft",
  openEnrollment: "Open Enrollment",
  inSession: "In-Session",
  completed: "Completed",
  cancelled: "Cancelled",
};

export const sessionStatuses = [
  { name: "Draft", id: sessionStatus.draft },
  { name: "Open Enrollment", id: sessionStatus.openEnrollment },
  { name: "In-Session", id: sessionStatus.inSession },
  { name: "Completed", id: sessionStatus.completed },
  { name: "Cancelled", id: sessionStatus.cancelled },
];

export const sessionType = {
  study: 1,
  internship: 2,
  fellowship: 3,
  faculty_led: 4,
  group_travel: 5,
  research: 6,
  virtual: 7,
  volunteer: 8,
  teaching_english: 9,
};
export const sessionTypes = [
  {
    id: 1,
    name: "Study",
  },
  {
    id: 2,
    name: "Internship",
  },
  {
    id: 3,
    name: "Fellowship",
  },
  {
    id: 4,
    name: "Faculty Led",
  },
  {
    id: 5,
    name: "Career Expedition",
  },
  {
    id: 6,
    name: "Research",
  },
  {
    id: 7,
    name: "Virtual",
  },
  {
    id: 8,
    name: "Volunteer",
  },
  {
    id: 9,
    name: "Teaching English",
  },
];

export const transportationProvidedOptionIds = {
  arrival: 1,
  departure: 2,
};

export const transportationProvidedOptions = [
  { id: transportationProvidedOptionIds.arrival, name: "Arrival" },
  { id: transportationProvidedOptionIds.departure, name: "Departure" },
];

export const baseClassSelectionRule = ({ parentId } = {}) => ({
  conjunction: "and",
  library_classes: [],
  name: "",
  rules: [],
  id: uuid.v4(),
  parent_rule_id: parentId,
  single_session_classes: [],
});

export const baseEventSelectionRule = ({ parentId } = {}) => ({
  conjunction: "and",
  libraries: [],
  name: "",
  rules: [],
  id: uuid.v4(),
  parent_rule_id: parentId,
  default_rule_id: undefined,
});

export const classesTaughtTimesOptionIds = {
  mornings: 1,
  afternoons: 2,
};

export const classesTaughtTimesOptions = [
  { id: classesTaughtTimesOptionIds.mornings, label: "Mornings" },
  { id: classesTaughtTimesOptionIds.afternoons, label: "Afternoons" },
];
export const daysOfTheWeekTaught = [
  { id: "Sunday", label: "Sunday" },
  { id: "Monday", label: "Monday" },
  { id: "Tuesday", label: "Tuesday" },
  { id: "Wednesday", label: "Wednesday" },
  { id: "Thursday", label: "Thursday" },
  { id: "Friday", label: "Friday" },
  { id: "Saturday", label: "Saturday" },
];

export const assessmentTypes = [
  { id: 1, label: "Continuous assessment" },
  { id: 2, label: "Non-continuous assessment" },
];

export const entityTypes = {
  vendor_institution: "725bdca2-372f-4b31-803e-56821f2d322f",
  home_institution: "7bda5a4e-23fe-45eb-b344-5c5bfd8a5164",
  host_institution: "d3b99b5e-405a-40b0-bdb2-3d4d35015115",
  business_institution: "d3b99b5e-405a-40b0-bdb2-3d4d35015154",
};

export const EVENT_SUBCATEGORIES = [
  {
    id: 1,
    name: "Guest lecture",
  },
  {
    id: 2,
    name: "Classroom visit",
  },
  {
    id: 3,
    name: "Office site visit or tour",
  },
  {
    id: 4,
    name: "Guest lecture (private)",
  },
  {
    id: 5,
    name: "Public lecture (public)",
  },
  {
    id: 6,
    name: "Industry networking event",
  },
  {
    id: 7,
    name: "Art and culture",
  },
  {
    id: 8,
    name: "Entertainment",
  },
  {
    id: 9,
    name: "Food and drink",
  },
  {
    id: 10,
    name: "Sports",
  },
  {
    id: 11,
    name: "Tours",
  },
  {
    id: 12,
    name: "Sightseeing",
  },
  {
    id: 13,
    name: "Wellness",
  },
  {
    id: 14,
    name: "Nature and outdoors",
  },
];

export const configuratorColors = [
  {
    left: "#E4E7F3",
    middle: ["#C3B8D8", "#7A4C9B"],
    right: "#EDF9F8",
  },
  {
    left: "#F2F6FF",
    middle: ["#BAC7DC", "#3E5F93"],
    right: "#E4E7F3",
  },
];

export const configuratorConditionals = [
  { id: "and", name: "And" },
  { id: "or", name: "Or" },
  { id: "not", name: "Not" },
];

export const excursionConfiguratorConditionals = [
  { id: "and", name: "And" },
  { id: "or", name: "Or" },
];

export const ONE_CREDIT_ADD_ON = {
  id: "8d5b1f28-ddbb-43fd-a1a4-7a2611530485",
  name: "1 Class Credit Add-On",
  key: "one_credit_add_on",
};

export const THREE_CREDIT_ADD_ON = {
  id: "041f60ce-6ff0-426a-b384-9c8629bbbf5e",
  name: "3 Class Credit Add-On",
  key: "three_credit_add_on",
};

export const CLASSES_EDUCATIONAL_FEE = {
  id: "1a027ac5-ed21-46fe-90f8-d49fc4c07164",
  name: "Classes Academic Fees",
  key: "classes_educational_fee",
};

export const GROUP_FLIGHT = {
  id: "a4438874-9713-4948-a73e-31d8155b4885",
  name: "Group Flight",
  key: "group_flight",
};

export const PUBLIC_TRANSPORTATION_CARD = {
  id: "0c5cb0e3-34a6-4bdf-ad89-693779dad241",
  name: "Public Transportation Card",
  key: "public_transportation_card",
};

export const MEDICAL_INSURANCE = {
  id: "55a782d5-82cd-4f1d-8a2b-1cfd3ead1eea",
  name: "Medical Insurance",
  key: "medical_insurance",
  price_in_cents: 1200, // fixed price
};

export const SPAIN_MEDICAL_INSURANCE = {
  id: "175919a7-a968-4683-9ab6-ff39071177f2",
  name: "Spain Medical Insurance",
  key: "spain_medical_insurance",
  price_in_cents: 1300, // fixed price
};

export const GENERAL_GROUP_VISA_SERVICE = {
  id: "e159fe01-444b-4b53-822a-9650c63330b6",
  name: "General Group Visa Service",
  key: "general_group_visa_service",
  price_in_cents: 18500, // fixed price
  optional: true,
  sku: "1350",
};

export const SPAIN_GROUP_VISA_SERVICE = {
  id: "82a825a0-6664-4e9e-a8d6-6e8696c939b3",
  name: "Spain Group Visa Service",
  key: "spain_group_visa_service",
  price_in_cents: 18500, // fixed price
  optional: true,
  sku: "1350",
};

export const UMASS_TRANSCRIPT_SERVICES = {
  id: "b16d09da-a893-4fcc-b6f1-acb97ce1eec9",
  name: "UMass Transcript Services",
  key: "umass_transcript_services",
  price_in_cents: 85000, // fixed price
  optional: true,
};

export const UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES = {
  id: "b786c3ab-e23a-4195-9b10-14122f1383b3",
  name: "Universidad Congreso Transcript Services",
  key: "universidad_congresso_transcript_services",
  price_in_cents: 75000, // fixed price
  optional: true,
};

export const CAREER_COACHING = {
  id: "37a6e73e-7311-4515-9dbe-ace875e43614",
  name: "Career Coaching",
  key: "career_coaching",
  price_in_cents: 12900, // fixed price
  optional: true,
};

export const PROPRIETARY_CONTENT = {
  id: "7dcce5af-63c8-4508-aa3e-4982fff9c69d",
  name: "Proprietary Content",
  key: "proprietary_content",
  price_in_cents: 12500, // fixed price
  optional: true,
};

export const CREDENTIAL = {
  id: "259a4738-0f41-4397-988f-aa0b56ad2c7f",
  name: "Credential",
  key: "credential",
  price_in_cents: 3400, // fixed price
  optional: true,
};

export const INTERNSHIP_COURSE = {
  id: "5f0ecfb4-a422-4b7a-b5aa-8b2dafc7d311",
  name: "Internship Course",
  key: "internship_course",
  price_in_cents: 35000, // fixed price
  optional: true,
};

export const PRICED_SECTIONS_EXCLUDE_FILTERS = ["pricing_date"];

export const SESSION_INCLUDE_FIELDS = [
  "id",
  "name",
  "updated_at",
  "updated_by",
  "created_at",
  "terms",
  "status",
  "owner_entity_id",
];

export const PROGRAM_PAGE_INCLUDE_FIELDS = [
  "id",
  "name",
  "status",
  "description",
  "created_at",
  "updated_at",
  "updated_by",
  "marketplace",
  "owner_entity_id",
];

export const OCCURRENCES_ACTIVE_STATUS_ID =
  "642b2fe7-d770-428d-817b-eed06a1cb764";

export const PROGRAM_SESSION_TERMS_DISPLAY_MAP = {
  "Fall + Spring": "Academic Year",
  "Fall + Fall Early Start": "Fall Early Start",
  "Spring + Spring Early Start": "Spring Early Start",
  "Winter Quarter + Jan Term": "Winter Quarter Extended",
  "Academic Year + Fall Early Start + Spring Early Start":
    "Academic Year Early Start",
  "Fall + Fall Extended": "Fall Extended",
  "Spring + Spring Extended": "Spring Extended",
  "Summer 1 + Summer 2": "Summer 1 & 2 Combined",
  "Summer 1 + Summer 2 + Summer 3": "Summer 1, 2 & 3 Combined",
  "Summer 1 + Summer 3": "Summer 1 & 3 Combined",
  "Summer 2 + Summer 3": "Summer 2 & 3 Combined",
};

// TODO: to be implemented on excursions selection modal filters
export const EVENT_DURATIONS = [];
export const MODES_OF_EXPERIENCE = [];
export const EVENT_HOSTS = [];

export const DURATION_TOOLTIP_MESSAGE =
  "Duration is used to calculate the price and is uneditable once the session is either Open Enrollment, In-Session, or Completed";

export const PRICE_DURATION_DISABLED_STATUS = [
  sessionStatus.openEnrollment,
  sessionStatus.inSession,
  sessionStatus.completed,
];
