import gql from "graphql-tag";
import { mapState } from "vuex";
import formioHelpers from "../mixins/formioHelpers";

export default {
  mixins: [formioHelpers],
  data() {
    return {
      rawClasses: [],
      classes: [],
      formioForm: null,
      dynamicCourseForm: false,
      presetCourseForm: false,
      programId: "",
    };
  },
  computed: {
    ...mapState(["selectedProgramId"]),
  },
  methods: {
    loadCourses(form) {
      this.formioForm = form;
      this.listenForCourseSelection(form);
      if (this.componentExists(form, "dynamicCourseSelection")) {
        this.dynamicCourseForm = true;
        form.getComponent("dynamicLanguageOfInstruction").setValue("");
      }
      if (this.componentExists(form, "presetCourseSelection")) {
        this.presetCourseForm = true;
      }
      if (this.dynamicCourseForm || this.presetCourseForm) {
        this.getClasses();
      }
    },
    async getClasses() {
      this.$apollo
        .query({
          query: gql`
            query entries($salesforceId: [QueryArgument]) {
              entries(section: "programs", salesforceId: $salesforceId) {
                id
              }
            }
          `,
          variables: {
            salesforceId: this.program.program.salesforce_id,
          },
          client: "apolloClasses",
        })
        .then((response) => {
          if (response.data.entries.length > 0) {
            this.programId = response.data.entries[0].id;
            this.getClassData();
          }
        })
        .catch((error) => {
          console.log("Error something went wrong: " + error);
        });
    },
    async getClassData() {
      this.$apollo
        .query({
          query: gql`
            query entries($relatedTo: [QueryArgument]) {
              entries(section: "classes", relatedTo: $relatedTo) {
                title
                ... on classes_classes_Entry {
                  description
                  syllabusLink
                  classCode
                  term {
                    title
                  }
                  languageOfInstruction {
                    title
                  }
                  hostInstitutionCredit
                  program {
                    title
                  }
                }
              }
            }
          `,
          variables: {
            relatedTo: this.programId,
          },
          client: "apolloClasses",
        })
        .then((response) => {
          this.rawClasses = response.data.entries;
          this.classes = this.transformClassData(response.data.entries);
          if (this.dynamicCourseForm) {
            this.populateClassDropdown();
          }
          if (this.presetCourseForm) {
            this.fillPresetCourseForm();
          }
        })
        .catch((error) => {
          console.log("Error something went wrong: " + error);
        });
    },
    transformClassData(entries) {
      let result = [];
      entries.forEach((entry) => {
        let course = {
          value: entry.title,
          label: entry.title,
        };
        result.push(course);
      });
      return result;
    },
    populateClassDropdown() {
      this.form.then((form) => {
        let dynamicCourseTitle = form.getComponent("dynamicCourseTitle");
        dynamicCourseTitle.component.data.values = this.classes;

        let dynamicAlternativeCourseTitle = form.getComponent(
          "dynamicAlternativeCourseTitle"
        );
        dynamicAlternativeCourseTitle.component.data.values = this.classes;
      });
    },
    listenForCourseSelection(form) {
      form.on("componentChange", (event) => {
        if (event.component.key === "dynamicCourseTitle") {
          let languageComponent = this.getMatchingComponent(
            event,
            "dynamicLanguageOfInstruction"
          );
          let creditsComponent = this.getMatchingComponent(
            event,
            "dynamicCredits"
          );

          this.populateLanguageOfInstruction(languageComponent, event.value);
          this.populateCredits(creditsComponent, event.value);
        }
        if (event.component.key === "dynamicAlternativeCourseTitle") {
          let languageComponent = this.getMatchingComponent(
            event,
            "dynamicAlternativeLanguageOfInstruction"
          );
          let creditsComponent = this.getMatchingComponent(
            event,
            "dynamicAlternativeCredits"
          );

          this.populateLanguageOfInstruction(languageComponent, event.value);
          this.populateCredits(creditsComponent, event.value);
        }
      });
    },
    populateLanguageOfInstruction(component, selectedClass) {
      if (selectedClass === "") {
        component.setValue("");
      } else {
        let course = this.rawClasses.find((rawClass) => {
          return rawClass.title === selectedClass;
        });

        if (course.languageOfInstruction.length) {
          let language = course.languageOfInstruction[0]["title"];
          component.setValue(language);
        }
      }
    },
    populateCredits(component, selectedClass) {
      if (selectedClass === "") {
        component.setValue("");
      } else {
        let course = this.rawClasses.find((rawClass) => {
          return rawClass.title === selectedClass;
        });

        if (
          Object.prototype.hasOwnProperty.call(
            course,
            "hostInstitutionCredit"
          ) &&
          Number.isInteger(course.hostInstitutionCredit)
        ) {
          component.setValue(course.hostInstitutionCredit);
        }
      }
    },
    getMatchingComponent(event, key) {
      let row = event.instance.rowIndex;
      let parent = event.instance.parent;

      return parent.components.find((component) => {
        return component.component.key === key && component.rowIndex === row;
      });
    },
    fillPresetCourseForm() {
      let presetGrid = this.formioForm.getComponent("presetCourseSelection");

      this.rawClasses.forEach((course, key, arr) => {
        let titleComponent = presetGrid.rows[key]["presetCourseTitle"];
        let creditsComponent = presetGrid.rows[key]["presetCredits"];
        let languageComponent =
          presetGrid.rows[key]["presetLanguageOfInstruction"];

        titleComponent.setValue(course.title);
        creditsComponent.setValue(course.hostInstitutionCredit);
        languageComponent.setValue(course.languageOfInstruction[0]["title"]);

        // If this is not the last iteration, add another row for the next iteration
        if (!Object.is(arr.length - 1, key)) {
          presetGrid.addRow();
        }
      });
    },
  },
};
