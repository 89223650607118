// TODO: This seems to be widely supported in browser but may need to swap for something like https://formatjs.io/docs/intl-messageformat/
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const formatterWithoutCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const convertCost = (val) => {
  const num = Number(val);
  return !Number.isNaN(num) ? formatter.format(num) : "$0";
};

export const formattedPriceFromCents = (priceInCents) => {
  const num = Number(priceInCents) / 100;
  return !Number.isNaN(num) ? formatter.format(num) : "$0";
};

export const getDollarPriceFromCents = (priceInCents) => {
  return Number(priceInCents) / 100;
};

export const convertOpeId = (ope_id) => {
  return String(ope_id).padStart(8, "0");
};

export const dollarsToCents = (dollars, fixedDecimals = false) => {
  return fixedDecimals
    ? Math.round(floatWithFixedDecimals(dollars, 0, 2) * 100)
    : Math.round(parseFloat(dollars) * 100);
};

export const floatWithFixedDecimals = (number, fallbackValue, decimals = 2) => {
  try {
    return number ? parseFloat(number).toFixed(decimals) : fallbackValue;
  } catch {
    return fallbackValue;
  }
};

export const formattedPriceFromCentsWithoutCents = (number) => {
  const num = parseInt(Number(number) / 100, 10);
  return !Number.isNaN(num) ? formatterWithoutCents.format(num) : "$0";
};

export const formattedPriceWithoutCents = (number) => {
  const num = parseInt(Number(number), 10);
  return !Number.isNaN(num) ? formatterWithoutCents.format(num) : "$0";
};

export const sanitizePriceInput = (value) => {
  let cleanedInput = value.replace(/[^0-9]/g, ""); // Remove non-digits
  let formattedInput;

  if (cleanedInput.length === 1) {
    formattedInput = "0.0" + cleanedInput;
  } else if (cleanedInput.length === 2) {
    formattedInput = "0." + cleanedInput;
  } else {
    const integerPart =
      cleanedInput.substring(0, cleanedInput.length - 2) || "0";
    const decimalPart = cleanedInput.substring(cleanedInput.length - 2);
    formattedInput = integerPart + "." + decimalPart;
  }

  return parseFloat(formattedInput).toFixed(2);
};
