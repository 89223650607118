export default {
  data() {
    return {
      hasErrors: false,
      missingAppId: false,
    };
  },
  methods: {
    async validate() {
      this.hasErrors = false;
      try {
        await this.sectionsToValidate.forEach((formSection) => {
          this.validateSection(formSection);
        });
      } catch {
        this.hasErrors = true;
      }
      return new Promise((resolve, reject) => {
        if (this.hasErrors) {
          reject("Please correct the errors");
        } else {
          resolve("Done");
        }
      });
    },
    validateSection(name) {
      return new Promise((resolve) => {
        // Only validate sections that exist
        if (typeof this.$refs[name] !== "undefined") {
          this.$refs[name].v$.$touch();
          if (this.$refs[name].v$.$invalid) {
            this.hasErrors = true;
          }
        }
        resolve();
      });
    },
    /**
     * Validate a single form that does not have nested sections
     */
    validateSingleForm() {
      this.hasErrors = false;
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.hasErrors = true;
      }

      return new Promise((resolve, reject) => {
        if (this.hasErrors) {
          reject("Please correct the errors");
        } else {
          resolve("Done");
        }
      });
    },
    checkForApplicationId() {
      this.missingAppId = false;
      //check if the application id is in the data that is going to get submitted on each form
      //form must have a computed property "createSubmissionDataForFormIo"
      if (
        !this.createSubmissionDataForFormIo.data.application_id ||
        this.createSubmissionDataForFormIo.data.application_id.trim().length !==
          32
      ) {
        this.missingAppId = true;
        throw new Error(
          "Invalid Application ID, please contact your API program manager to help you with your application."
        );
      }
    },
  },
};
