<template>
  <div
    class="mt-2 md:mt-0 md:flex md:justify-end"
    :class="displayToDo ? 'md:col-span-4' : 'md:col-span-6'"
  >
    <div class="flex justify-center w-41 lg:w-41">
      <SkeletonLoader
        v-if="loading"
        :min-width="50"
        class="font-semibold text-gray-650"
        :max-width="60"
      />
      <button
        v-else
        data-cy="verify-btn"
        class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white w-full focus:outline-none"
        :class="[
          buttonDisabled ? disabledClass : activeClass,
          displayToDo ? 'sm:w-41 md:w-full lg:w-41' : 'w-41 lg:w-41',
          `${secondaryColorClassBG}`,
        ]"
        :disabled="buttonDisabled"
        @click="openPDF"
      >
        <span>{{ financialAidFormCompleted ? "Update" : "Verify" }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from "../helpers/SkeletonLoader.vue";

export default {
  name: "VerifyUpdateButton",
  components: { SkeletonLoader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    financialAidSelected: {
      type: Boolean,
      default: false,
    },
    toggleSelected: {
      type: Boolean,
      default: false,
    },
    financialAidFormCompleted: {
      type: Boolean,
      default: false,
    },
    displayToDo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeClass:
        "hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white",
      disabledClass: "opacity-33 cursor-not-allowed",
    };
  },
  computed: {
    buttonDisabled() {
      return !this.toggleSelected;
    },
  },

  methods: {
    openPDF() {
      this.$router.push({
        name: "enrollment/financial-aid-verification",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
  },
};
</script>
