<template>
  <li>
    <button
      class="text-center bg-blue-300 block w-full p-3 border-t border-gray-200 hover:bg-blue-300 focus:outline-none"
      @click="selectTab(tabindex)"
    >
      {{ title }}
    </button>
  </li>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: "tab-menu",
  props: {
    title: {
      type: String,
      default: "",
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    tabactive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations("university", ["setCurrentTab"]),
    selectTab(tabIndex) {
      if (this.tabactive) {
        this.setCurrentTab({ index: tabIndex, clean: true });
      }
    },
  },
};
</script>
