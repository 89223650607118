<template>
  <section>
    <header>
      <h2 class="text-xl md:text-3xl">Select Your Program</h2>
    </header>
    <div class="text-base text-gray-600">Select your experience type</div>
    <form>
      <fieldset class="md:col-span-2">
        <div class="grid grid-cols-1 gap-6 mt-8 mb-8 lg:grid-cols-2">
          <div
            v-for="programType in PROGRAM_TYPES"
            :key="programType.value"
            class="flex items-center"
          >
            <input
              :id="programType.value"
              v-model="experienceType"
              type="radio"
              :value="programType.value"
              :class="`${primaryColorClass}`"
              class="mt-1 form-radio"
            />
            <label
              class="js-radio-card-panel transition-colors duration-150 flex items-center flex-1 p-4 ml-4 bg-white rounded-md shadow-lg bg-blue-100"
              :for="programType.value"
            >
              <img :src="experienceTypeIcon(programType.value)" />
              <span class="ml-4 font-semibold text-gray-700 uppercase">{{
                programType.label
              }}</span>
            </label>
          </div>
        </div>
        <div
          v-if="v$.experienceType.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.experienceType.$error }"
        >
          Field is required
        </div>
      </fieldset>
      <div>
        <span class="align-middle">Not sure about what program you want?</span>
        <span
          class="inline-flex items-center ml-2 align-middle has-tippy"
          data-tippy-template="help-programs"
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-info apiabroad-svg"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </svg>
        </span>

        <div style="display: none;">
          <div id="help-programs">
            Use our Program Finder
            <a
              class="underline"
              style="color: #00b2b3;"
              :href="programFinderUrl"
              target="_blank"
              >here</a
            >
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 gap-x-4 lg:grid-cols-2 lg:gap-x-6 mt-4 lg:mt-6"
      >
        <label
          v-show="experienceType === 'abroad'"
          for="location"
          class="block text-sm"
        >
          <span class="flex justify-between mb-2 font-semibold text-gray-600">
            <span>Where would you like to go?</span>
          </span>
          <VSelect
            id="location"
            v-model="location"
            :options="sites"
            :get-option-label="(option) => option.label"
            aria-label="Location"
            @search="searchSites"
          ></VSelect>
          <div
            v-if="v$.location.required.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.location.$error }"
          >
            Field is required
          </div>
        </label>
        <div class="flex flex-col">
          <label v-show="experienceType" for="semester" class="block text-sm">
            <span class="flex justify-between mb-2 font-semibold text-gray-600">
              <span>When would you like to participate?</span>
            </span>
            <VSelect
              id="semester"
              v-model="semester"
              :value="semester"
              :options="semesters"
              :get-option-label="(option) => option.label"
              :reduce="(semester) => semester.value"
              aria-label="Semester"
              :class="{
                'opacity-33 cursor-not-allowed border-2 bg-gray-200 text-gray-700': programHasChange,
              }"
              :disabled="programHasChange"
              @search="searchSemesters"
            ></VSelect>
          </label>
          <div
            v-if="v$.semester.required.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.semester.$error }"
          >
            Field is required
          </div>
        </div>
      </div>
      <fieldset v-show="semester" class="md:col-span-2 mt-4">
        <legend class="font-semibold text-gray-600">Program Session</legend>
        <div class="mt-2">
          <p v-if="noProgramsAvailable" class="ml-2 text-warning-900">
            Program changes must be for the same semester, but none are
            available for the chosen destination. Please choose another
            destination.
          </p>
          <label
            v-for="program in availablePrograms"
            :key="program.value"
            class="mb-4 flex"
            :for="program.value"
          >
            <input
              :id="program.value"
              v-model="programSession"
              type="radio"
              :value="program.value"
              :class="`${primaryColorClass}`"
              class="mt-1 form-radio"
              data-cy="programSession"
              :disabled="invalidProgramSession(program.value)"
            />
            <div class="flex flex-col">
              <span class="ml-2" data-cy="session-name-label">{{
                program.label
              }}</span>
              <span
                v-if="invalidProgramSession(program.value)"
                class="ml-2 text-warning-900"
                data-cy="duplicate-session-error"
                >You already have an active application for this program</span
              >
            </div>
          </label>
        </div>
        <div
          v-if="v$.programSession.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.programSession.$error }"
        >
          Field is required
        </div>
      </fieldset>

      <div class="mt-10 block">
        <ButtonWithSpinner
          ref="programSubmit"
          variant="primary"
          variant-type="block"
          data-cy="programSelectionSubmit"
          :disabled="!allowSubmit"
          @click.prevent="submit()"
        >
          <span>Continue Application</span>
        </ButtonWithSpinner>
        <p v-if="hasErrors" class="text-error-900">
          Please complete the required fields correctly.
        </p>
        <div v-if="formioErrors" class="text-error-900">
          <p v-for="error in formioErrors" :key="error">
            {{ error }}
          </p>
        </div>
        <div v-if="missingAppId" class="text-error-900">
          <p>
            Invalid Application ID, please contact your API program manager to
            help you with your application.
          </p>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import formIoApi from "../../../mixins/formIoApi";
import formValidation from "../../../mixins/formValidation";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner";
import { eventBus } from "../../../app";
import sitesListApi from "../../../mixins/sitesListApi";
import semestersListApi from "../../../mixins/semestersListApi";
import programsListApi from "../../../mixins/programsListApi";
import ProgramChangeMix from "../../../mixins/programChange";
import formService from "../../../services/form";
import useVuelidate from "@vuelidate/core";
import AdvisorNotifications from "@/mixins/AdvisorNotifications.js";

export default {
  components: {
    ButtonWithSpinner,
  },
  mixins: [
    formIoApi,
    sitesListApi,
    semestersListApi,
    programsListApi,
    formValidation,
    ProgramChangeMix,
    AdvisorNotifications,
  ],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      application_id: "",
      formIoForm: "programselection",
      experienceType: "",
      location: {},
      semester: "",
      programSession: "",
      PROGRAM_TYPES: [
        {
          value: "virtual",
          label: "Virtual",
        },
        {
          value: "abroad",
          label: "Abroad",
        },
      ],
      availablePrograms: [],
      noProgramsAvailable: false,
      previousProgramSelectionExists: false,
      previousSelections: {
        experienceType: null,
        location: null,
        semester: null,
        session: null,
        shouldSetSession: false,
      },
      loadingContract: true,
      waitingToSubmit: false,
      submissionId: null,
    };
  },
  computed: {
    ...mapState([
      "program",
      "currentApplicationId",
      "studentApplications",
      "userData",
      "appliedAgreement",
      "featureFlags",
    ]),
    uiVersion() {
      return "v2";
    },
    contractNumber() {
      return Object.hasOwnProperty.call(
        this.appliedAgreement,
        "contract_number"
      )
        ? this.appliedAgreement.contract_number
        : "";
    },
    getApplicationId() {
      if (this.application_id) {
        return this.application_id;
      } else if (this.currentApplicationId) {
        return this.currentApplicationId;
      } else {
        return this.$route.params.applicationId;
      }
    },
    createSubmissionDataForFormIo() {
      return {
        data: {
          application_id: this.getApplicationId,
          uiVersion: this.uiVersion,
          experienceType: this.experienceType,
          location: this.location,
          semester: this.semester,
          programSession: this.programSession,
          contractNumber: this.contractNumber,
        },
      };
    },
    programFinderUrl() {
      const map = {
        local: "https://find.apiabroad.dev/program-finder/",
        dev: "https://find.apiabroad.dev/program-finder/",
        staging: "https://find.apiabroad.app/program-finder/",
        production: "https://find.apiabroad.com/program-finder/",
      };
      return map[process.env.MIX_APP_ENV];
    },
    allowSubmit() {
      return (
        this.experienceType &&
        this.location &&
        this.location.constructor === Object &&
        Object.keys(this.location).length > 0 &&
        this.semester &&
        this.programSession &&
        !this.invalidProgramSession(this.programSession)
      );
    },
    virtual() {
      let virtual = [];
      for (var i = 0; i < this.sites.length; i++) {
        if (this.sites[i].label === "Virtual, Virtual") {
          virtual = this.sites[i];
        }
      }
      return virtual;
    },
  },
  watch: {
    experienceType: function (newExperienceType) {
      if (newExperienceType === "virtual") {
        this.location = this.virtual;
      } else {
        this.location = {};
      }
      this.semester = "";
      this.programSession = "";
      this.noProgramsAvailable = false;
    },
    location: function (newLocation) {
      this.semester = "";
      this.programSession = "";
      this.noProgramsAvailable = false;
      if (newLocation) {
        if (this.programHasChange && newLocation.value) {
          this.semester = this.previousApplication.session;
          this.initProgramsList();
        } else {
          this.initSemestersList();
        }
      } else {
        // If no location is selected, clear the semesters list.
        this.semesters = [];
      }
      this.availablePrograms = [];
    },
    async semester(newSemester) {
      this.programSession = "";
      this.noProgramsAvailable = false;
      if (newSemester) {
        await this.initProgramsList();
        if (this.previousSelections.shouldSetSession) {
          this.programSession = this.previousSelections.session;
          this.previousSelections.shouldSetSession = false;
        }
      } else {
        this.availablePrograms = [];
      }
    },
    programSession(newSession) {
      if (newSession) {
        if (!this.application_id) {
          this.generateApplicationId(newSession);
        }
        this.$emit("select-program", newSession);
      } else {
        this.$emit("select-program", "");
        this.loadingContract = true;
      }
    },
    loadingContract: {
      handler: function (newval) {
        if (!newval && this.waitingToSubmit) this.submit();
      },
      immediate: true,
    },
  },
  async created() {
    eventBus.$on("setLoadingContract", (data) => {
      this.setLoadingContract(data);
    });
    eventBus.$emit("showSpinner", true);
    //clear the direct program data since we wont need it anymore
    this.$store.commit("clearDirectProgram");
    this.application_id = this.getApplicationId;
    await this.loadPreviousSubmission();
    eventBus.$emit("showSpinner", false);
  },
  mounted() {
    if (!this.programSession) {
      this.$emit("select-program", "");
    } else {
      this.$emit("select-program", this.programSession);
    }
  },
  methods: {
    invalidProgramSession(programSession) {
      return this.studentApplications.some(
        (app) =>
          app.programSession.salesforce_id === programSession &&
          app.applicationPaid
      );
    },
    experienceTypeIcon(type) {
      return type === "virtual"
        ? "https://apiabroad-cdn.s3.us-east-2.amazonaws.com/images/program_virtual.svg"
        : "https://apiabroad-cdn.s3.us-east-2.amazonaws.com/images/program_abroad.svg";
    },
    updateApplicationId(id) {
      this.application_id = id;
      this.$emit("set-application-id", id);
    },
    async submit() {
      this.waitingToSubmit = false;
      this.$refs.programSubmit.startLoading();

      if (!this.loadingContract) {
        try {
          await this.checkForApplicationId();
          await this.validateSingleForm();
          await this.formioValidateSubmission(
            this.formIoForm,
            this.createSubmissionDataForFormIo
          );
          if (!this.hasFormIoValidationErrors) {
            await this.processForm();
          } else {
            this.$refs.programSubmit.stopLoading();
          }
        } catch {
          this.$refs.programSubmit.stopLoading();
        }
      } else {
        this.waitingToSubmit = true;
      }
    },
    async processForm() {
      if (this.programHasChange) {
        await this.updateProgramChange(
          this.createSubmissionDataForFormIo.data.application_id,
          "application"
        );
      }

      let submissionId = this.submissionId;

      if (this.previousProgramSelectionExists) {
        let previousSubmission = await this.lookupSubmissionByApplicationId(
          this.formIoForm,
          this.createSubmissionDataForFormIo.data.application_id
        );

        submissionId = previousSubmission._id
          ? previousSubmission._id
          : submissionId;
      }

      formService
        .createOrUpdateSubmission(
          this.formIoForm,
          this.createSubmissionDataForFormIo,
          submissionId
        )
        .then((submission) => {
          if (submission) {
            this.submissionId = submission._id;
            // send avisor notification
            this.sendNotifications("start", this.programSession);
            //move to next step
            eventBus.$emit(
              "changeStep",
              this.createSubmissionDataForFormIo.data.application_id
            );
          }
        })
        .catch(() => {
          this.$refs.programSubmit.stopLoading();
        });
    },
    generateApplicationId(newSession) {
      let appId = (
        Date.now() +
        newSession +
        Math.random().toString().substring(3)
      ).substring(0, 32);

      this.updateApplicationId(appId);
    },
    async setLocation(location) {
      await fetch(this.sitesURL)
        .then((response) => response.json())
        .then((data) => {
          this.location = data[location];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadPreviousSubmission() {
      if (!_.isEmpty(this.program)) {
        this.previousProgramSelectionExists = true;
        this.previousSelections.experienceType =
          this.program.site.country.toLowerCase() === "virtual"
            ? "virtual"
            : "abroad";
        this.previousSelections.location = this.program.site.city;
        this.previousSelections.semester = this.program.session;
        if (this.program.salesforce_id) {
          this.previousSelections.session = this.program.salesforce_id;
          this.previousSelections.shouldSetSession = true;
        }
        this.fillPreviousSubmission();
      }
    },
    async fillPreviousSubmission() {
      this.experienceType = this.previousSelections.experienceType;
      await this.setLocation(this.previousSelections.location);
      this.semester = this.previousSelections.semester;
    },
    setLoadingContract(value) {
      this.loadingContract = value;
    },
  },
  validations: {
    programSession: {
      required,
    },
    location: {
      required,
    },
    semester: {
      required,
    },
    experienceType: {
      required,
    },
  },
};
</script>
<style>
.vs--disabled .vs__search {
  cursor: not-allowed;
  background-color: transparent;
}
</style>
