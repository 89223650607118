<template>
  <div class="mb-12">
    <h2 class="uppercase text-lg font-bold text-indigo-base mb-4">
      Please acknowledge the following:
    </h2>

    <div class="space-y-6">
      <ul class="list-disc pl-5">
        <!-- Bullet points without if-statements -->
        <li class="mb-4 ml-2 text-lg font-medium">
          Any housing requests are processed on a
          <span class="font-bold">first come, first serve basis.</span>
        </li>
        <li class="mb-4 ml-2 text-lg font-medium">
          If an
          <span class="font-bold">upgrade fee</span> is associated with my
          housing selection,
          <span class="font-bold"
            >it will be reflected on my program invoice.</span
          >
        </li>
        <li class="mb-4 ml-2 text-lg font-medium">
          All images shown are for illustration only.
          <span class="font-bold">Actual booked units may vary.</span>
        </li>
        <li class="mb-4 ml-2 text-lg font-medium">
          I understand that my request is not guaranteed. If I do not get my
          housing request, I will be placed in another housing option, and if
          applicable, additional upgrade fees paid will be refunded.
        </li>
        <li class="mb-4 ml-2 text-lg font-medium">
          I understand that roommate requests must be mutual from both students
          in order for API to process a match and are not intended for romantic
          couples and are not guaranteed. As housing assignments are made on a
          rolling basis, roommate requests may not be possible if submitted 8
          weeks or less before a program start date.
        </li>
        <li class="mb-4 ml-2 text-lg font-medium">
          I understand that I may be living with other students from other
          universities.
        </li>
      </ul>

      <div class="mt-6">
        <label class="inline-flex items-center">
          <input
            id="allAcknowledgements"
            v-model="form.allAcknowledged"
            type="checkbox"
            class="min-w-5 h-5 w-5 border-gray-300 text-indigo-base focus:ring-indigo-base"
            :class="[primaryColorClass, `${secondaryColorClassAccent}`]"
            :disabled="disabled"
          />
          <span class="ml-2 text-lg font-medium">
            I acknowledge and understand ALL policies listed above.
          </span>
        </label>
        <p
          v-for="error of v$.allAcknowledged.$errors"
          :key="error.$uid"
          class="error text-error-900 text-sm"
        >
          {{ error.$message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import { defineEmits, defineProps, reactive, toRefs, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers, sameAs } from "@vuelidate/validators";

const emit = defineEmits(["setAcknowledgement"]);
const props = defineProps({
  previousQuestionnaire: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const { previousQuestionnaire, disabled } = toRefs(props);

const requiredCopy = "This field is required.";

const form = reactive({
  allAcknowledged: false,
});
const rules = {
  allAcknowledged: {
    sameAs: helpers.withMessage(requiredCopy, sameAs(true)),
  },
};
const v$ = useVuelidate(rules, form);

watch(
  form,
  () => {
    const questionResponse = {
      question_text: "I acknowledge and understand ALL policies listed above.",
      answer: form.allAcknowledged,
      model: "allAcknowledged",
      level: "acknowledgements",
    };
    emit("setAcknowledgement", questionResponse);
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => previousQuestionnaire.value,
  (newVal) => {
    if (!_.isEmpty(newVal.allAcknowledged)) {
      form.allAcknowledged = newVal.allAcknowledged.answer;
      v$.value.$touch();
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>
