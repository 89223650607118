<template>
  <div class="">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="white" />
      <path
        d="M12 21.3338C17.1549 21.3338 21.3338 17.1549 21.3338 12C21.3338 6.84512 17.1549 2.66624 12 2.66624C6.84512 2.66624 2.66624 6.84512 2.66624 12C2.66624 17.1549 6.84512 21.3338 12 21.3338ZM12 24C5.37248 24 0 18.6275 0 12C0 5.37248 5.37248 0 12 0C18.6275 0 24 5.37248 24 12C24 18.6275 18.6275 24 12 24Z"
        fill="#FF0000"
      />
      <path
        d="M17.3323 10.667V13.3332H6.66602V10.667H17.3323Z"
        fill="#FF0000"
      />
    </svg>
  </div>
</template>

<script setup></script>
