import { getApiClient } from "./API";

const learnerTypesServices = {
  async list(params) {
    const response = await getApiClient().get(`/learner_types`, { params });
    return response?.data?.data;
  },
};

export default learnerTypesServices;
