export const SALVE_REGINA_OPE8 = ["00341100"];
let SALVE_REGINA_PRICING = [];
if (process.env.MIX_APP_ENV === "production") {
  SALVE_REGINA_PRICING = [
    {
      session: "a056e00001JjiPGAAZ",
      program_fee: 13900,
      housing_fee: 2803,
      educational_fees: 10900,
      med_life_fee: 197,
    },
    {
      session: "a056e00001GPjjiAAD",
      program_fee: 13900,
      housing_fee: 3630,
      educational_fees: 10062,
      med_life_fee: 208,
    },
    {
      session: "a056e00001GPjeUAAT",
      program_fee: 17500,
      housing_fee: 6415,
      educational_fees: 10900,
      med_life_fee: 185,
    },
    {
      session: "a056e00001JjiLnAAJ",
      program_fee: 17500,
      housing_fee: 6415,
      educational_fees: 10900,
      med_life_fee: 185,
    },
    {
      session: "a056e00001JjiMQAAZ",
      program_fee: 15900,
      housing_fee: 4827,
      educational_fees: 10900,
      med_life_fee: 173,
    },
    {
      session: "a056e00001GPjiPAAT",
      program_fee: 15900,
      housing_fee: 5237,
      educational_fees: 10420,
      med_life_fee: 243,
    },
  ];
} else {
  SALVE_REGINA_PRICING = [
    {
      session: "a051g000002DknnAAC",
      program_fee: 12300,
      housing_fee: 5000,
      educational_fees: 7000,
      med_life_fee: 300,
    },
    {
      session: "a051g000002x75EAAQ",
      program_fee: 15900,
      housing_fee: 5237,
      educational_fees: 10420,
      med_life_fee: 243,
    },
  ];
}

export { SALVE_REGINA_PRICING };
