<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="ds7vq5ydua" d="M0-.007h59.954V52.5H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        d="M29.977 36.563a3.749 3.749 0 0 1-3.75-3.75v-11.25c0-2.07 1.68-3.75 3.75-3.75 2.07 0 3.75 1.68 3.75 3.75v11.25c0 2.073-1.68 3.75-3.75 3.75M29.977 45.938a3.749 3.749 0 0 1-3.75-3.75c0-2.074 1.68-3.75 3.75-3.75 2.07 0 3.75 1.676 3.75 3.75 0 2.073-1.68 3.75-3.75 3.75"
        fill="#E88A03"
      />
      <g transform="translate(0 3.75)">
        <mask id="mb1ooga8gb" fill="#fff">
          <use xlink:href="#ds7vq5ydua" />
        </mask>
        <path
          d="M29.977-.007c-3.596 0-7.197 1.807-9.195 5.426L.944 41.377c-2.76 5 .859 11.123 6.567 11.123h44.932c5.711 0 9.326-6.124 6.566-11.123L39.168 5.42C37.173 1.8 33.573-.007 29.977-.007m0 7.5c.66 0 1.882.202 2.628 1.552l19.07 34.564A.937.937 0 0 1 50.852 45H9.101a.937.937 0 0 1-.822-1.391L27.348 9.045c.746-1.35 1.969-1.553 2.629-1.553"
          fill="#E88A03"
          mask="url(#mb1ooga8gb)"
        />
      </g>
    </g>
  </svg>
</template>
