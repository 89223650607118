<template>
  <div>
    <div class="flex justify-end">
      <a
        id="templateCsv"
        href="/files/Bulk_Import_Template.csv"
        target="_blank"
        class="flex align-middle m-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-download text-teal-900 font-semibold"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="7 10 12 15 17 10"></polyline>
          <line x1="12" y1="15" x2="12" y2="3"></line>
        </svg>
        <span class="ml-2 text-teal-900 font-semibold">CSV Template</span>
      </a>
    </div>
    <file-upload-component
      :file-types="fileType"
      :custom-button-label="buttonLabel"
      :width-button="widthButton"
      :max-file-size="15"
      @updateFiles="handleUpdateFiles"
      @updateProgress="handleUpdateProgress"
    ></file-upload-component>
    <div class="flex mt-24">
      <button
        id="cancel-btn"
        ref="cancelBtn"
        data-cy="cancel-btn"
        type="button"
        variant="secondary-outlined"
        variant-type="normal"
        :custom-class="'w-107px'"
        :class="[
          secondaryColor
            ? `${secondaryColorClassOutlined}`
            : `${secondaryColorClassOutlined} hover:bg-teal-100 hover:border-teal-100`,
        ]"
        class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-white hover:text-white"
        @click="$emit('cancel-btn')"
      >
        <span>Cancel</span>
      </button>
      <div class="w-full ml-4">
        <button-with-spinner
          id="validate-btn"
          ref="validateBtn"
          data-cy="validate-btn"
          :disabled="buttonDisabled"
          :class="[
            tertiaryColor
              ? `${tertiaryColorClass}`
              : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
          ]"
          class="flex w-full justify-center rounded font-semibold border-2 border-transparent px-4 min-h-10 hover:no-underline items-center text-gray-700 hover:bg-yellow-900 hover:text-gray-700 focus:bg-yellow-900"
          @click.prevent="handleValidateCsvButton"
        >
          <span> Validate CSV File </span>
        </button-with-spinner>
      </div>
    </div>
    <div v-if="showCsvErrorMessage" class="w-full mt-4">
      <p class="text-gray-800">
        <img src="/images/icon-warning.svg" class="inline mr-3" /> Please review
        your CSV file and confirm that it follows the provided template. If
        necessary, make corrections to the file to address any missing or
        invalid data, and then re-upload the file. You can download the
        <a
          id="templateCsv"
          href="/files/Bulk_Import_Template.csv"
          class="text-teal-500"
          target="_blank"
        >
          CSV Template
        </a>
        here for reference.
      </p>
    </div>
  </div>
</template>

<script>
import FileUploadComponent from "../forms/SharedComponents/FileUpload";
import ButtonWithSpinner from "../forms/SharedComponents/ButtonWithSpinner";

export default {
  name: "BulkEnrollmentUploadScreen",
  components: {
    "file-upload-component": FileUploadComponent,
    "button-with-spinner": ButtonWithSpinner,
  },
  props: {
    csvErrorMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["validate-csv", "cancel-btn", "toggle-csv-error-message"],
  data() {
    return {
      fileType: [".csv"],
      buttonLabel: "Upload CSV file",
      customFileBoxLabel: "Teste",
      widthButton: "w-3/5",
      files: false,
      buttonDisabled: true,
      uploadErrors: false,
      showCsvErrorMessage: false,
    };
  },
  watch: {
    csvErrorMessage(value) {
      if (value && this.files.length > 0) {
        this.showCsvErrorMessage = true;
        this.stopLoadingBtn();
        this.disableValidateBtn(true);
      } else {
        this.showCsvErrorMessage = false;
      }
    },
    files(value) {
      if (value.length === 0 && this.csvErrorMessage) {
        this.showCsvErrorMessage = false;
        this.disableValidateBtn(true);
        this.$emit("toggle-csv-error-message", false);
      }
    },
  },

  methods: {
    handleUpdateFiles(files, errors) {
      this.files = files;
      if (errors) {
        this.stopLoadingBtn();
        this.uploadErrors = errors;
      }
    },
    handleUpdateProgress(stillRunning) {
      if (!stillRunning) {
        this.buttonDisabled = false;
      }
    },
    handleValidateCsvButton() {
      this.$refs.validateBtn.startLoading();
      /* Once multipleFile props isn't set in FileUploadComponent, it returns only 01 file */
      this.$emit("validate-csv", this.files[0]);
    },
    stopLoadingBtn() {
      this.$refs.validateBtn.stopLoading();
    },
    // expects true or false
    disableValidateBtn(toggle) {
      this.buttonDisabled = toggle;
    },
  },
};
</script>
