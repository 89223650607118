<template>
  <div class="p-4">
    <div class="mb-1">
      <SimpleBreadCrumb />
    </div>
    <div class="my-5 sm:flex sm:items-center sm:justify-between">
      <h1 class="text-2xl font-semibold">View City Details</h1>
      <div class="mt-3 flex items-center space-x-4 sm:ml-4 sm:mt-0">
        <div v-if="editState && !cityLoading" class="flex">
          <BaseButton
            class="text-base font-semibold"
            outlined
            @click="editState = false"
          >
            Cancel
          </BaseButton>
          <BaseButton class="text-base font-semibold" @click="updateCity">
            Save
          </BaseButton>
        </div>
        <BaseButton
          v-else-if="!cityLoading"
          class="flex items-center justify-between"
          @click="editState = true"
        >
          <PenIcon class="mr-3 h-4 w-4" />
          <p class="text-base font-semibold">Edit</p>
        </BaseButton>
      </div>
    </div>
    <Spinner
      v-if="cityLoading || loadingCityUpdate"
      class="relative !z-0 m-auto h-65vh"
    />
    <div v-else-if="!cityLoading && !cityError && cityDetails">
      <EditCityOverview v-if="editState" v-model="editData" />
      <CityOverview v-else :data="editData" />
      <EditCityCost v-if="editState" v-model="editData" />
      <CityCost v-else :data="editData" />
      <CitiesMedia v-model="editData" :edit-state="editState" />
    </div>
    <ErrorPage
      v-else-if="cityError"
      class="relative h-65vh"
      :message="cityError"
      disable-code
    />
  </div>
</template>

<script setup>
import { ERROR_TIMEOUT, SUCCESS_TIMEOUT } from "@/constants.js";
import { reactive, ref, watch } from "vue";
import {
  useGetSingleCity,
  useUpdateCityRecord,
} from "@/components/program-manager/sessions/composable.js";

import BaseButton from "@/components/shared/Button/BaseButton.vue";
import CitiesMedia from "./CitiesMedia.vue";
import CityCost from "./CityCostOfLiving.vue";
import CityOverview from "./CityOverview.vue";
import EditCityCost from "./Edit/CityCostOfLiving.vue";
import EditCityOverview from "./Edit/CityOverview.vue";
import ErrorPage from "@/components/errorPage.vue";
import PenIcon from "../svg-icons/PenIcon.vue";
import SimpleBreadCrumb from "@/components/shared/SimpleBreadCrumb.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import { useToast } from "vue-toast-notification";

const props = defineProps({
  cityId: {
    type: String,
    required: true,
  },
});

const COST_FIELDS = [
  "average_semester_books",
  "average_daily_local_transport",
  "average_daily_personal_expenses",
  "average_daily_meals",
];

const editState = ref(false);
const editData = reactive({});
const toast = useToast();

const {
  error: cityError,
  isLoading: cityLoading,
  state: cityDetails,
  execute: fetchCityRecord,
} = useGetSingleCity({ city_id: props.cityId });

const stringifyReasonToExperience = (value) => {
  try {
    return JSON.stringify(value);
  } catch {
    return "{}";
  }
};

const { isLoading: loadingCityUpdate, execute: updateCityRecord } =
  useUpdateCityRecord({
    city_id: props.cityId,
  });

const parseReasonToExperience = (data) => {
  try {
    return JSON.parse(data || "{}");
  } catch {
    return data;
  }
};

const updateEditDataValues = (value) => {
  Object.assign(editData, value);
  editData.reason_to_experience = parseReasonToExperience(
    value.reason_to_experience
  );
  COST_FIELDS.forEach((key) => {
    editData[key] = !isNaN(editData?.[key]) ? editData?.[key] / 100 : undefined;
  });
};

const updateCity = async () => {
  try {
    editData.reason_to_experience = stringifyReasonToExperience(
      editData.reason_to_experience
    );
    COST_FIELDS.forEach((key) => {
      editData[key] = !isNaN(editData?.[key])
        ? editData?.[key] * 100
        : undefined;
    });
    const response = await updateCityRecord(0, {
      payload: editData,
    });
    if (response) updateEditDataValues(response);
    editState.value = false;
    toast.open({
      message: "City Saved Successfully",
      type: "success",
      position: "bottom",
      duration: SUCCESS_TIMEOUT,
    });
  } catch {
    editState.value = false;
    toast.open({
      message: "Error while saving city data. Please, try it later",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
    fetchCityRecord();
  }
};

watch(cityDetails, (value) => {
  if (!cityError.value) {
    updateEditDataValues(value);
  }
});
</script>
