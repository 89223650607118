import { learningServiceRequest } from "./utils";

const socCodesService = {
  async list(search) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/soc_codes",
      params: { limit: 150, q: search },
      failureMessage: "Error loading Soc Codes",
    });
    if (response?.data?.items?.length > 0) {
      return response.data.items;
    } else {
      return [];
    }
  },
};

export default socCodesService;
