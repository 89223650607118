<template>
  <div class="flex flex-col lg:flex-row p-8 bg-slate-50 h-fit">
    <ArchivingModal />
    <DuplicateSessionModal
      v-if="$route.meta.subcategory === 'sessions'"
      :data="duplicateModalState"
      @close-modal="closeDuplicateModal"
    />
    <DetailModal
      v-if="$route.meta.subcategory === 'sessions'"
      :data="detailModalState"
      @close-modal="closeDetailModal"
    />
    <DuplicateModal v-else />
    <EventViewModal />
    <BaseSidebar title="Program Manager" :categories="getCards" />
    <BaseTable
      v-if="!getLoading && !getListingError"
      class="w-full lg:w-[calc(100%-325px)]"
      :active-filter="getActiveFilter"
      :headers="visibleHeaders"
      :rows="getTableItems"
      :actions-routes="getTableItemActionsRoutes"
      :view-more-route-name="viewMoreCurrentRoute"
      @open-duplicate-modal="openDuplicateModal"
      @open-detail-modal="openDetailModal"
    />
    <Spinner
      v-else-if="getLoading && !getListingError"
      class="m-auto relative bg-blue-100 opacity-75 !z-0"
    />
    <ErrorPage v-else-if="getListingError" :message="getListingError" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/app";
import ArchivingModal from "../modals/ArchivingModal";
import DuplicateModal from "../modals/DuplicateModal";
import DetailModal from "../modals/DetailModal";
import DuplicateSessionModal from "../modals/duplicate/ProgramSession.vue";
import EventViewModal from "../modals/events/EventsView.vue";
import BaseTable from "../shared/Table/BaseTable.vue";
import BaseSidebar from "../shared/Navigation/BaseSidebar.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import ErrorPage from "@/components/errorPage.vue";
import {
  SESSION_INCLUDE_FIELDS,
  PROGRAM_PAGE_INCLUDE_FIELDS,
} from "@/components/program-manager/sessions/constants.js";
import { EVENTS_INCLUDE_FIELDS } from "@/components/program-manager/events/constants.js";
import { useVisibleHeaders } from "@/composables/visibleHeaders";

export default {
  name: "ProgramManager",
  components: {
    ArchivingModal,
    DuplicateSessionModal,
    DuplicateModal,
    DetailModal,
    EventViewModal,
    BaseTable,
    BaseSidebar,
    Spinner,
    ErrorPage,
  },
  setup() {
    const {
      visibleHeaders,
      defaultHeaders,
      setVisibleHeaders,
      updateHeaders,
    } = useVisibleHeaders(5);
    return { visibleHeaders, defaultHeaders, setVisibleHeaders, updateHeaders };
  },
  data() {
    return {
      limit: 10,
      duplicateModalState: {
        state: false,
        id: undefined,
      },
      detailModalState: {
        state: false,
        id: undefined,
      },
    };
  },
  computed: {
    ...mapGetters("programManager", [
      "getActiveFilter",
      "getTableItems",
      "getTableHeaders",
      "getCards",
      "getTableItemActionsRoutes",
      "getCurrentEntity",
      "getLoading",
      "getListingError",
      "getEntityFilters",
    ]),
    includeFields() {
      switch (this.$route.meta?.subcategory) {
        case "sessions":
          return SESSION_INCLUDE_FIELDS;
        case "programs":
          return PROGRAM_PAGE_INCLUDE_FIELDS;
        case "events":
          return EVENTS_INCLUDE_FIELDS;
        default:
          return undefined;
      }
    },
    viewMoreCurrentRoute() {
      return this.getCurrentEntity + "-view-more";
    },
  },
  async created() {
    const currentLibraryFilters = this.getActiveFilter?.title?.toLowerCase();
    if (this.$route.meta?.subcategory !== currentLibraryFilters) {
      this.$store.commit("programManager/setAdvancedFilters", {});
    }
    await this.$store.dispatch("programManager/fetchAndSetUserEntityFilters");
    this.setListingError();
    this.setVisibleHeaders(this.defaultHeaders);

    this.updateHeaders([]);
    this.clearProgramManagerData();
    this[this.$route.meta?.subcategory] &&
      this[this.$route.meta.subcategory]({
        limit: this.limit,
        extraParams: {
          include: this.includeFields,
          ...this.getEntityFilters[this.$route.meta.subcategory],
        },
      });
    this.setAllCounts();

    this.$watch(
      () => this.$route.meta,
      (meta) => {
        this.clearProgramManagerData();
        this[meta?.subcategory] &&
          this[meta.subcategory]({
            limit: this.limit,
            extraParams: {
              include: this.includeFields,
              ...this.getEntityFilters[this.$route.meta.subcategory],
            },
          });
      }
    );

    this.fetchAmenitiesData();
    this.eventCategories();
    this.eventSubCategories();
    this.fetchLocations();
    this.fetchTagsData();
    this.fetchTestimonialsData();

    eventBus.$on("updateHeaders", (selectedHeaders) => {
      this.updateHeaders(selectedHeaders);
    });
  },
  methods: {
    ...mapActions({
      housing: "programManager/fetchHousingData",
      programs: "programManager/fetchProgramsData",
      sessions: "programManager/fetchProgramSessionsData",
      classes: "programManager/fetchClassesData",
      internships: "programManager/fetchInternshipsData",
      events: "programManager/fetchEventsData",
      setAllCounts: "programManager/setAllCounts",
      clearProgramManagerData: "programManager/clearProgramManagerData",
      setListingError: "programManager/setListingError",
      fetchAmenitiesData: "programManager/fetchAmenitiesData",
      eventCategories: "events/fetchCategories",
      eventSubCategories: "events/fetchSubCategories",
      fetchLocations: "events/fetchLocations",
      fetchTagsData: "tagManager/fetchTagsData",
      fetchTestimonialsData: "testimonialManager/fetchTestimonialsData",
    }),
    openDuplicateModal({ id }) {
      this.duplicateModalState = { id, state: true };
    },
    closeDuplicateModal() {
      this.duplicateModalState = { id: undefined, state: false };
    },
    openDetailModal({ id }) {
      this.detailModalState = { id, state: true };
    },
    closeDetailModal() {
      this.detailModalState = { id: undefined, state: false };
    },
  },
};
</script>
