<script setup>
import { computed, defineEmits, reactive, unref, watch } from "vue";
import { useStore } from "vuex";
import { cloneDeep, debounce, unionBy } from "lodash";
import { useToast } from "vue-toast-notification";
import { Dialog, DialogPanel } from "@headlessui/vue";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useVueTable,
  FlexRender,
} from "@tanstack/vue-table";

import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import Pagination from "@/components/shared/Pagination.vue";
import CheckboxSelect from "@/components/shared/select/CheckboxSelect.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import Magnifier from "@/components/shared/icons/Magnifier";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import BadgeBar from "@/components/program-manager/sessions/components/BadgeBar";
import { convertSTDFilters } from "@/components/program-manager/sessions/utils";
import {
  useGetCityOptions,
  useGetOccurrences,
} from "@/components/program-manager/sessions/composable";
import { transformOccurrences } from "@/components/program-manager/sessions/components/ExcursionsAndEvents/utils";

import {
  EVENT_SUBCATEGORIES,
  EVENT_DURATIONS,
  MODES_OF_EXPERIENCE,
  EVENT_HOSTS,
} from "@/components/program-manager/sessions/constants";

import { formatDateString } from "../../../../../util/formatDateString";
const toast = useToast();

const props = defineProps({
  terms: {
    type: Array,
  },
  session_travel_details: {
    type: Array,
  },
  modalIsOpen: {
    type: Boolean,
    default: false,
  },
  selectedOccurrences: {
    type: Array,
  },
  showFilters: {
    type: Boolean,
    default: false,
  },
  addAllEnabled: {
    type: Boolean,
    default: true,
  },
  saveOnSubmit: {
    type: Boolean,
    default: true,
  },
  columnsConfig: {
    type: Array,
  },
});

const emit = defineEmits([
  "closeModal",
  "updateSelectedOccurrences",
  "updateNewExcursions",
]);

const { newCities } = convertSTDFilters(props.session_travel_details);
const state = reactive({
  filters: {
    terms: cloneDeep(props.terms),
    cities: newCities,
    subcategory: [],
    duration: [],
    modes_of_experience: [],
    event_hosts: [],
    text: undefined,
  },
  selectedOccurrences: props.selectedOccurrences
    ? cloneDeep(props.selectedOccurrences)
    : [],
  loadingAll: false,
});

const occurrenceIndex = (occurrenceId) => {
  return state.selectedOccurrences?.findIndex(({ id }) => id === occurrenceId);
};

const occurrenceIsSelected = (id) => {
  return occurrenceIndex(id) >= 0;
};

const checkOccurrence = (occurrence) => {
  if (!occurrenceIsSelected(occurrence.id)) {
    state.selectedOccurrences?.push(occurrence);
  } else {
    const index = occurrenceIndex(occurrence.id);
    state.selectedOccurrences?.splice(index, 1);
  }
};

const filterLabelMap = {
  cities: ({ city_ascii = "" }) => city_ascii,
  categories: ({ name = "" }) => name,
};

const pagination = reactive({
  pageIndex: 1,
  pageSize: 20,
});

const hasArrivalDate = computed(
  () => props.session_travel_details?.[0]?.arrival_date
);

const onCancel = () => {
  emit("closeModal");
  resetFilters();
};

const addSelected = () => {
  emit("updateSelectedOccurrences", state.selectedOccurrences);
  resetFilters();
};

const addNewExcursions = () => {
  emit("updateNewExcursions", state.selectedOccurrences);
  state.selectedOccurrences = [];
  resetFilters();
};

const {
  execute: executeFetchCityOptions,
  isLoading: cityLoading,
  state: cityOptions,
  isReady: cityIsReady,
} = useGetCityOptions(
  { immediate: false, throwError: true },
  { city_ascii: "chi" }
);

const {
  execute: executeFetchOccurrencesOptions,
  isLoading: ocurrencesLoading,
  state: ocurrencesOptions,
  isReady: ocurrencesIsReady,
} = useGetOccurrences(
  { immediate: false, throwError: false },
  {
    limit: 20,
    pricing_date: hasArrivalDate.value,
  }
);

const executeFetchOccurrences = debounce(async (search) => {
  await executeFetchOccurrencesOptions(0, {
    ...{ q: search },
    limit: 20,
    pricing_date: hasArrivalDate.value,
    extraParams: { ...state.filters },
    cancel: true,
  });
}, 500);

const filterOccurrencesOptions = (occurrences) => {
  return (
    transformOccurrences(occurrences)?.filter(
      ({ title, location, host, id }) => {
        if (state.filters.text) {
          if (title) {
            return (
              title?.toLowerCase().includes(state.filters.text.toLowerCase()) ||
              location
                ?.toLowerCase()
                .includes(state.filters.text.toLowerCase()) ||
              host?.toLowerCase().includes(state.filters.text.toLowerCase()) ||
              occurrenceIsSelected(id)
            );
          } else {
            return false;
          }
        }
        return true;
      }
    ) || []
  );
};

const filteredOptions = computed(() => {
  return filterOccurrencesOptions(unref(ocurrencesOptions)?.data);
});

const addAll = async () => {
  try {
    state.loadingAll = true;
    const occurrences = await executeFetchOccurrencesOptions(0, {
      ...{ q: state?.filters?.text },
      limit: unref(ocurrencesOptions)?.data?.count ?? 10000,
      pricing_date: hasArrivalDate.value,
      cancel: false,
    });
    emit(
      "updateSelectedOccurrences",
      filterOccurrencesOptions(unref(occurrences)?.data) || []
    );
    resetFilters();
  } catch (e) {
    toast.open({
      message: "Failed to fetch all event occurrence records. Please try again",
      type: "info",
      position: "bottom",
      duration: 5000,
    });
  } finally {
    state.loadingAll = false;
  }
};

const fetchCityOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    loading(true);
    try {
      await executeFetchCityOptions(0, { city_ascii: search, cancel: true });
      loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        loading(false);
      }
    }
  }
}, 0);

const store = useStore();
const getTermsData = computed(
  () => store.getters["programManager/getTermsData"]
);

const changePage = (pageIndex) => {
  if (hasArrivalDate.value && unref(ocurrencesOptions)?.count > 0) {
    pagination.pageIndex = pageIndex;
    executeFetchOccurrencesOptions(0, {
      ...{ q: state?.filters?.text },
      pricing_date: hasArrivalDate.value,
      limit: pagination.pageSize,
      skip: pagination.pageSize * (pagination.pageIndex - 1),
      cancel: true,
    });
  }
};

const resetFilters = () => {
  state.filters.terms = [];
  state.filters.categories = [];
  state.filters.cities = [];
  state.filters.duration = [];
  state.filters.modes_of_experience = [];
  state.filters.event_hosts = [];
  state.filters.text = undefined;
};

const handleSearch = (search) => {
  state.filters.text = search;
  if (search && search?.length > 2 && hasArrivalDate.value) {
    executeFetchOccurrences(search);
  } else if (!search && search?.length === 0) {
    executeFetchOccurrences();
  }
};

watch(
  () => props.session_travel_details,
  (newTravelDetails) => {
    if (newTravelDetails?.[0]?.arrival_date && props.modalIsOpen) {
      executeFetchOccurrences(state.filters.text);
    }
    const { newCities } = convertSTDFilters(newTravelDetails);
    state.filters.cities = newCities || [];
  },
  { deep: true }
);

watch(
  () => props.terms,
  (newTerms) => {
    state.filters.terms = cloneDeep(newTerms);
  },
  { deep: true }
);

watch(
  () => props.selectedOccurrences,
  (selectedOccurrences) => {
    state.selectedOccurrences = cloneDeep(selectedOccurrences) || [];
  },
  { deep: true }
);

watch(
  () => props.modalIsOpen,
  (isOpen) => {
    if (
      isOpen &&
      hasArrivalDate.value &&
      unref(ocurrencesOptions)?.count <= 0
    ) {
      executeFetchOccurrences();
    }
  }
);

const columnHelper = createColumnHelper();
const columns = props.columnsConfig.map(
  ({ header, id, size, getColumnDisplayValue, type }) => {
    return columnHelper.accessor(getColumnDisplayValue, {
      id,
      header,
      size,
      type,
    });
  }
);
columns.map(() => columnHelper);
const table = useVueTable({
  get data() {
    return [
      ...unionBy(
        transformOccurrences(unref(ocurrencesOptions)?.data) || [],
        unref(filteredOptions) || [],
        "id"
      ),
    ];
  },
  columns: columns,
  columnResizeMode: "onChange",
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  manualPagination: true,
});
</script>

<template>
  <div>
    <Dialog
      :open="props.modalIsOpen"
      class="relative z-50"
      @close-modal="() => emit('closeModal')"
      @click.self="() => emit('closeModal')"
    >
      <div class="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel>
          <div
            class="fixed inset-0 z-0 hidden bg-university-primary opacity-50 sm:block"
          ></div>
          <div class="bg-white h-[95vh] p-6 rounded-xl z-20 relative w-[95vw]">
            <div class="text-right">
              <button @click="() => emit('closeModal')">
                <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
              </button>
            </div>
            <section class="flex flex-col h-[85vh]">
              <h1
                class="flex justify-center text-xl leading-tight text-center sm:text-2xl md:text-3xl mb-6 flex-0"
              >
                Add Excursions + Events
              </h1>
              <SearchBar
                class="flex mr-3 mt-3 min-w-[300px] md:w-[400px] searchbar xs:mt-0 sm:mr-7 ml-auto flex-0"
                @handleSearch="handleSearch"
              >
                <template #advancedFilters><div></div></template>
              </SearchBar>
              <div
                v-show="props.showFilters"
                class="flex bg-blue-450 rounded-md ml-2 flex-wrap flex-0 mt-4"
              >
                <CheckboxSelect
                  v-model="state.filters.categories"
                  :clear-search-on-blur="() => false"
                  :close-on-select="false"
                  :components="{ OpenIndicator: Magnifier }"
                  :deselect-from-dropdown="true"
                  label="name"
                  :multiple="true"
                  option-key="name"
                  :options="EVENT_SUBCATEGORIES"
                  outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
                  placeholder="Event Category"
                  scroll-input="auto"
                  input-max-height="29px"
                />
                <CheckboxSelect
                  v-if="getTermsData.length"
                  v-model="state.filters.terms"
                  :clear-search-on-blur="() => false"
                  :close-on-select="false"
                  :components="{ OpenIndicator: Magnifier }"
                  :deselect-from-dropdown="true"
                  label="name"
                  :multiple="true"
                  :options="getTermsData"
                  outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
                  placeholder="Term"
                  scroll-input="auto"
                  input-max-height="29px"
                />
                <CheckboxSelect
                  v-model="state.filters.cities"
                  :clear-search-on-blur="() => false"
                  :close-on-select="false"
                  :components="{ OpenIndicator: Magnifier }"
                  :deselect-from-dropdown="true"
                  label="city_ascii"
                  :multiple="true"
                  :options="cityOptions?.items || []"
                  :loading="cityLoading || !cityIsReady"
                  outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
                  placeholder="Location"
                  scroll-input="auto"
                  input-max-height="29px"
                  @search="fetchCityOptions"
                />
                <CheckboxSelect
                  v-model="state.filters.duration"
                  :clear-search-on-blur="() => false"
                  :close-on-select="false"
                  :components="{ OpenIndicator: Magnifier }"
                  :deselect-from-dropdown="true"
                  label="name"
                  :multiple="true"
                  :options="EVENT_DURATIONS"
                  outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
                  placeholder="Duration"
                  scroll-input="auto"
                  input-max-height="29px"
                />
                <CheckboxSelect
                  v-model="state.filters.modes_of_experience"
                  :clear-search-on-blur="() => false"
                  :close-on-select="false"
                  :components="{ OpenIndicator: Magnifier }"
                  :deselect-from-dropdown="true"
                  label="name"
                  :multiple="true"
                  option-key="name"
                  :options="MODES_OF_EXPERIENCE"
                  outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
                  placeholder="Mode of Experience"
                  scroll-input="auto"
                  input-max-height="29px"
                />
                <CheckboxSelect
                  v-model="state.filters.event_hosts"
                  :clear-search-on-blur="() => false"
                  :close-on-select="false"
                  :components="{ OpenIndicator: Magnifier }"
                  :deselect-from-dropdown="true"
                  label="name"
                  :multiple="true"
                  option-key="name"
                  :options="EVENT_HOSTS"
                  outer-classes="w-52 mx-2 my-2 checkbox-placeholder"
                  placeholder="Event Host"
                  scroll-input="auto"
                  input-max-height="29px"
                />
              </div>
              <div v-show="props.showFilters" class="grid grid-cols-12 flex-0">
                <BadgeBar
                  v-model="state.filters"
                  :filter-label-map="filterLabelMap"
                  class="col-span-11"
                />
                <div
                  class="ml-auto uppercase text-success-900 text-xs font-semibold mt-2 cursor-pointer"
                  @click="resetFilters"
                >
                  Reset Filters
                </div>
              </div>
              <div class="mt-4 mb-2 mr-auto pl-4 flex-0">
                {{ filteredOptions?.length || "No" }} Excursions + Events
              </div>
              <div class="flex-1 flex overflow-y-auto">
                <div
                  v-if="!hasArrivalDate"
                  class="w-full flex justify-center items-center text-lg"
                >
                  Please add an Arrival Date in order to see available
                  Excursions + Events
                </div>
                <div v-else class="flex-1 flex overflow-y-auto">
                  <table v-show="!ocurrencesLoading && ocurrencesIsReady">
                    <thead class="bg-white sticky top-0">
                      <tr
                        v-for="headerGroup in table.getHeaderGroups()"
                        :key="headerGroup.id"
                      >
                        <th
                          v-for="header in headerGroup.headers"
                          :key="header.id"
                          :data-testid="header.id + '-header'"
                          :colSpan="header.colSpan"
                          :style="{ width: `${header.getSize()}px` }"
                          class="py-4 pr-3 pl-2 text-left text-sm items-center text-gray-900"
                        >
                          <div class="flex items-center">
                            <FlexRender
                              v-if="!header.isPlaceholder"
                              :render="header.column.columnDef.header"
                              :props="header.getContext()"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr
                        v-for="(row, rowIndex) in table.getRowModel().rows"
                        v-show="!ocurrencesLoading && ocurrencesIsReady"
                        :key="row.id"
                        class="hover:bg-blue-350"
                      >
                        <td
                          v-for="cell in row.getVisibleCells()"
                          :key="cell.column.id"
                          :data-testid="`${cell.column.id}-cell-row-${
                            rowIndex + 1
                          }`"
                          :style="{ width: `${cell.column.getSize()}px` }"
                          class="py-8 text-sm pl-2 first:text-teal-900 capitalize border-t-[1px] border-solid border-t-[#f1f2f4]"
                        >
                          <div v-if="cell.column.columnDef.type === 'checkbox'">
                            <input
                              type="checkbox"
                              class="h-4 w-4 border-gray-300 form-checkbox"
                              :checked="
                                occurrenceIsSelected(cell.row.original.id)
                              "
                              @input="() => checkOccurrence(cell.row.original)"
                            />
                          </div>
                          <div
                            v-if="cell.column.columnDef.type === 'html'"
                            v-html="cell.row.original.location"
                          ></div>
                          <FlexRender
                            v-else
                            :render="cell.column.columnDef.cell"
                            :props="cell.getContext()"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Spinner
                    v-show="ocurrencesLoading || !ocurrencesIsReady"
                    outer-classes="flex items-center justify-center bg-white h-full relative mx-auto"
                  />
                </div>
              </div>
              <Pagination
                class="mb-4 flex-0"
                :page="pagination.pageIndex"
                :total-of-records="ocurrencesOptions?.count || 0"
                :records-per-page="pagination.pageSize"
                @change-page="changePage"
              />
              <div class="flex align-center justify-center h-10 flex-0">
                <BaseButton
                  outlined
                  :on-click="onCancel"
                  :disabled="state.loadingAll"
                  class="px-7"
                  >Cancel</BaseButton
                >
                <BaseButton
                  :on-click="saveOnSubmit ? addSelected : addNewExcursions"
                  :disabled="
                    state.loadingAll || ocurrencesLoading || !ocurrencesIsReady
                  "
                  >Add Selected</BaseButton
                >
                <ButtonWithSpinner
                  v-if="props.addAllEnabled"
                  variant-type="none"
                  custom-class="!h-10 rounded-md border px-6 py-2 text-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-none font-medium mr-5 shadow-sm bg-teal-900 text-white hover:bg-white hover:text-teal-900 hover:border-gray-300"
                  :disabled="
                    state.loadingAll || ocurrencesLoading || !ocurrencesIsReady
                  "
                  :prop-loading="state.loadingAll"
                  @click="addAll"
                  >Add All Results</ButtonWithSpinner
                >
              </div>
            </section>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>

<style scoped>
.checkbox-placeholder :deep(.vs__search) {
  font-size: 14px;
}
</style>
