import { eventsServiceRequest, skip } from "./utils";
import { EVENTS_SERVICE_API } from "../constants";
import { getApiClient } from "./API";
import { shouldAddMarketplace } from "@/composables/authorization"

const eventsService = {
  async listProxy({
    limit = 10,
    page = 1,
    q = "",
    source,
    failureMessage = "Error loading Events data",
    ...extraParams
  }) {
    const params = {
      limit,
      skip: skip(page, limit),
      q,
      ...extraParams,
    };

    if (shouldAddMarketplace()) {
      params.marketplace = true;
    }

    return await eventsServiceRequest({
      method: "get",
      url: `${EVENTS_SERVICE_API}/events`,
      params,
      failureMessage,
      source,
    });
  },

  async list(limit = 10, page = 1, q = "", extraParams = {}, source) {
    const params = {
      limit,
      skip: skip(page, limit),
      q,
      ...extraParams,
    };

    if (shouldAddMarketplace()) {
      params.marketplace = true;
    }

    return await eventsServiceRequest({
      method: "get",
      url: `${EVENTS_SERVICE_API}/events`,
      params,
      failureMessage: "Error loading Events data",
      source,
    });
  },

  async create(payload) {
    return await eventsServiceRequest({
      method: "post",
      url: "/events",
      payload,
    });
  },

  async update(id, payload) {
    return await eventsServiceRequest({
      method: "put",
      url: `/events/${id}`,
      payload,
    });
  },

  async getOne(id) {
    return await eventsServiceRequest({
      method: "get",
      url: `/events/${id}`,
      failureMessage: "Error loading Events data",
    });
  },

  async archive(id) {
    return await eventsServiceRequest({
      method: "put",
      url: `${EVENTS_SERVICE_API}/events/archive/${id}`,
    });
  },

  async archiveMany(data) {
    const response = await getApiClient().put(
      `${EVENTS_SERVICE_API}/events/archive_many/`,
      data
    );
    return response.data;
  },

  async getCategories() {
    return await eventsServiceRequest({
      method: "get",
      url: `/categories/`,
      failureMessage: "Error loading Categories",
    });
  },

  async getCategory(id) {
    return await eventsServiceRequest({
      method: "get",
      url: `/categories/${id}`,
      failureMessage: "Error loading Category",
    });
  },

  async getSubCategories(id) {
    return await eventsServiceRequest({
      method: "get",
      url: `/categories/${id}/subcategories/`,
      failureMessage: "Error loading Sub-Categories",
    });
  },

  async createLocation(payload) {
    return await eventsServiceRequest({
      method: "post",
      url: "/locations",
      payload,
    });
  },

  async updateLocation(id, payload) {
    return await eventsServiceRequest({
      method: "put",
      url: `/locations/${id}`,
      payload,
    });
  },

  async getLocations(limit = 1000, q = "") {
    return await eventsServiceRequest({
      method: "get",
      url: `/locations`,
      params: {
        limit,
        q,
      },
      failureMessage: "Error loading Categories",
    });
  },

  async getOccurrences({ source, ...params }) {
    return await eventsServiceRequest({
      method: "get",
      url: `${EVENTS_SERVICE_API}/occurrences`,
      failureMessage: "Error loading occurrences data",
      source,
      params,
    });
  },

  async getOccurrence({ id, ...params }) {
    return await eventsServiceRequest({
      method: "get",
      url: `${EVENTS_SERVICE_API}/occurrences/${id}`,
      failureMessage: "Error loading occurrences data",
      params,
    });
  },

  async updateOccurrence(id, payload) {
    return await eventsServiceRequest({
      method: "put",
      url: `${EVENTS_SERVICE_API}/occurrences/${id}`,
      payload,
    });
  },

  async createOccurrence(payload) {
    return await eventsServiceRequest({
      method: "post",
      url: `${EVENTS_SERVICE_API}/occurrences`,
      payload,
    });
  },

  async getEntityIdsForEventsOccurrences() {
    return await eventsServiceRequest({
      method: "get",
      url: `${EVENTS_SERVICE_API}/events/entities/all`,
    });
  }
};

export default eventsService;
