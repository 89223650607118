<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_144_2262"
      style="mask-type: alpha;"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_144_2262)"></g>
    <g clip-path="url(#clip0_144_2262)">
      <path
        d="M5.81 20C5.3 20 4.88 19.82 4.53 19.47C4.18 19.12 4 18.69 4 18.19V4.81C4 4.3 4.18 3.88 4.53 3.53C4.88 3.18 5.31 3 5.81 3H19.19C19.7 3 20.12 3.18 20.47 3.53C20.82 3.88 21 4.31 21 4.81V18.19C21 18.7 20.82 19.12 20.47 19.47C20.12 19.82 19.69 20 19.19 20H5.81ZM12.5 18.5H19.19C19.27 18.5 19.34 18.47 19.4 18.4C19.46 18.34 19.5 18.27 19.5 18.19V4.81C19.5 4.73 19.47 4.66 19.4 4.6C19.34 4.54 19.27 4.5 19.19 4.5H12.5V18.5Z"
        fill="#1E264C"
      />
    </g>
    <defs>
      <clipPath id="clip0_144_2262">
        <rect width="17" height="17" fill="white" transform="translate(4 3)" />
      </clipPath>
    </defs>
  </svg>
</template>
