<script>
export default {
  name: "BadgeBar",
};
</script>

<script setup>
import { useVModel } from "@vueuse/core";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { getFilterLabel } from "@/components/program-manager/sessions/utils";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  filterLabelMap: {
    type: Object,
    default: () => {},
    required: false,
  },
  excludeFilters: {
    type: Array,
    default: () => [],
    required: false,
  },
  readOnlyFilters: {
    type: Object,
    default: () => ({}),
    required: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
const filters = useVModel(props, "modelValue", emit);

const removeFilterCriteria = (parent, childId) => {
  if (
    filters.value[parent] &&
    filters.value[parent].findIndex((val) => val.id === childId) > -1 &&
    !props.readOnlyFilters?.[parent]
  ) {
    filters.value[parent].splice(
      filters.value[parent].findIndex((val) => val.id === childId),
      1
    );
  }
};
const removeSingleFilterCriteria = (parent) => {
  if (filters.value[parent] && !props.readOnlyFilters?.[parent]) {
    filters.value[parent] = undefined;
  }
};

const getLabel = (filterName, item) => {
  return props.filterLabelMap && props.filterLabelMap[filterName]
    ? props.filterLabelMap[filterName](item)
    : getFilterLabel(item);
};
</script>

<template>
  <div class="flex ml-3 mt-1 flex flex-wrap max-h-[4rem] overflow-y-auto">
    <template v-for="(filter, filterName) in filters" :key="filterName">
      <template
        v-if="Array.isArray(filter) && !excludeFilters.includes(filterName)"
      >
        <div
          v-for="item in filter"
          :key="item.id"
          class="cursor-pointer text-info-900 mr-2 flex text-sm font-normal non-italic"
          @click="() => removeFilterCriteria(filterName, item.id)"
        >
          {{ getLabel(filterName, item) }}
          <XMarkIcon
            v-if="!props.readOnlyFilters?.[filterName]"
            class="text-info-900 ml-1 w-4"
          />
        </div>
      </template>
      <div
        v-else-if="filter && !excludeFilters.includes(filterName)"
        class="cursor-pointer text-info-900 mr-2 flex text-sm font-normal non-italic capitalize"
        @click="() => removeSingleFilterCriteria(filterName)"
      >
        {{ getLabel(filterName, filter) }}
        <XMarkIcon
          v-if="!props.readOnlyFilters?.[filterName]"
          class="text-info-900 ml-1 w-4"
        />
      </div>
    </template>
  </div>
</template>
