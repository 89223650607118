import { learningServiceRequest, skip } from "@/services/utils";

const hostInstitutionService = {
  async getHostInstitutions({
    limit = 100,
    page = 1,
    q = "",
    extraParams = {},
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/host_institutions",
      params: { limit, skip: skip(page, limit), q, ...extraParams },
      failureMessage: "Error loading Home Institution data",
      source,
    });
  },
  async getHostInstitutionById(hostInstitutionId) {
    return await learningServiceRequest({
      method: "get",
      url: `/host_institutions/${hostInstitutionId}`,
      failureMessage: "Error loading HostInstitution data",
    });
  },
  async getEntitySupplementById(hostInstitutionId) {
    return await learningServiceRequest({
      method: "get",
      url: `/entity_supplement/${hostInstitutionId}`,
      failureMessage: "Error loading EntitySupplement data",
    });
  },
  async updateEntitySupplement(hostInstitutionId, payload) {
    return await learningServiceRequest({
      method: "put",
      url: `/entity_supplement/${hostInstitutionId}`,
      payload,
    });
  },
  async getHostInstitutionLanguageById(hostInstitutionId) {
    return await learningServiceRequest({
      method: "get",
      url: `/languages/${hostInstitutionId}`,
      failureMessage: "Error loading EntitySupplement data",
    });
  },
};

export default hostInstitutionService;
