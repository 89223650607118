import { learningServiceRequest, skip } from "./utils";
import { LEARNING_SERVICE_QUERY_LIMIT } from "@/constants";

const tagsService = {
  async getTags(limit = LEARNING_SERVICE_QUERY_LIMIT, page = 1, q = "") {
    return await learningServiceRequest({
      method: "get",
      url: `/tags`,
      params: { limit, skip: skip(page, limit), q },
    });
  },
  async getTagById(tagId) {
    return await learningServiceRequest({
      method: "get",
      url: `/tags/${tagId}`,
    });
  },
  async getTagsOrderByCategory(
    limit = LEARNING_SERVICE_QUERY_LIMIT,
    page = 1,
    q = ""
  ) {
    return await learningServiceRequest({
      method: "get",
      url: `/tags/order/by-category/`,
      params: { limit, skip: skip(page, limit), q },
    });
  },
};

export default tagsService;
