<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor" fill-rule="evenodd">
      <path
        d="m22.5 5.659-9-5.25a2.998 2.998 0 0 0-3.022 0l-9 5.25A2.997 2.997 0 0 0 0 8.25v1.875a3.003 3.003 0 0 0 1.5 2.596V21a1.5 1.5 0 1 0 0 3h21a1.5 1.5 0 1 0 0-3v-8.28a3.003 3.003 0 0 0 1.5-2.595V8.25a3.002 3.002 0 0 0-1.5-2.591zM3 8.25 12 3l9 5.25v1.875H3V8.25zm10.5 4.875V21h-3v-7.875h3zm-9 0h3V21h-3v-7.875zM16.5 21v-7.875h3V21h-3z"
      />
      <path d="M13.5 7.125c0 2-3 2-3 0s3-2 3 0" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBankAccount",
};
</script>

<style></style>
