<template>
  <div
    id="404"
    class="w-full px-4 mx-auto max-w-7xl flex flex-col items-center justify-center text-center h-100 sm:px-8 md:px-8 md:mb-8"
  >
    <img
      class="-mb-4"
      src="../assets/images/no-records.svg"
      alt="404 not found"
    />
    <h3 class="mb-sm text-lg font-semibold md:text-xl">
      Oops! We can't seem to find the page you're looking for.
    </h3>
    <p class="max-w-md text-2sm mb-sm text-gray-600">Error code: 404</p>
    <p class="max-w-md text-2sm text-gray-600">
      Here are some helpful links instead:
    </p>
    <p class="max-w-md text-2sm">
      <router-link
        v-if="isALoggedInAdvisor"
        :class="[`${secondaryColorClassLinks} font-semibold hover:underline`]"
        class="student-link"
        :to="'/my-students'"
      >
        My Students
      </router-link>
    </p>
    <p class="max-w-md text-2sm">
      <router-link
        v-if="isLoggedIn"
        :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
        class="student-link"
        :to="'/notification-settings'"
      >
        Notifications
      </router-link>
    </p>

    <p class="max-w-md text-2sm">
      <router-link
        v-if="!isLoggedIn"
        :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
        class="student-link"
        :to="'/sign-in'"
      >
        Sign In
      </router-link>
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/constants.js";
export default {
  name: "NotFound",
  computed: {
    ...mapGetters(["isLoggedIn", "getPermissions"]),
    isALoggedInAdvisor() {
      return this.isLoggedIn && this.getPermissions.includes(PERMISSIONS.INSTITUTION) && this.getPermissions.includes(PERMISSIONS.STUDENT_MANAGEMENT)
    },
    url() {
      return window.location.origin;
    },
  },
};
</script>

<style scoped>
.mb-sm {
  margin-bottom: 10px;
}
</style>
