<script setup>
import { computed, defineEmits, toRefs, reactive, unref, watch } from "vue";
import { uuid } from "vue-uuid";
import { useVModel, watchDebounced } from "@vueuse/core";
import useVuelidate from "@vuelidate/core";
import { maxLength, required } from "@vuelidate/validators";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import BasicCheckbox from "@/components/shared/input/BasicCheckbox.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import SimpleTextarea from "@/components/shared/SimpleTextarea.vue";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";
import { NOTE_TYPES, MAX_NOTE_TEXTAREA_LIMIT } from "@/constants";
import { DEFAULT_EMPTY_NOTE } from "@/components/StudentFulfillment/components/OrderDetail/sections/Notes/constants";
import { hasUsersAdvisePermission } from "@/composables/authorization";

const emit = defineEmits(["closeModal", "saveNote", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ ...DEFAULT_EMPTY_NOTE }),
  },
  isSaved: {
    type: Boolean,
    default: false,
  },
  modalIsOpen: {
    type: Boolean,
    default: false,
  },
  editNote: {
    type: Boolean,
    default: false,
  },
});

const note = useVModel(props, "modelValue", emit);
const { isSaved, modalIsOpen, editNote } = toRefs(props);

const state = reactive({
  isSavingLoading: false,
});

const saveNote = async () => {
  v$.value.$reset();
  const isValid = await v$.value.$validate();
  if (isValid) {
    state.isSavingLoading = true;
    emit("saveNote", unref(note));
  }
};

const handleCloseModal = () => {
  emit("closeModal");
};

watchDebounced(isSaved, (saved) => {
  if (saved) {
    state.isSavingLoading = false;
  }
});

const rules = computed(() => ({
  comment: { required, maxLength: maxLength(MAX_NOTE_TEXTAREA_LIMIT) },
}));

const v$ = useVuelidate(rules, note, {
  $registerAs: `FulfillmentNotes-${uuid.v4()}`,
  $lazy: true,
  $autoDirty: true,
});
</script>

<template>
  <div>
    <div v-if="modalIsOpen" class="relative z-50">
      <div
        class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
      ></div>
      <Dialog
        :open="modalIsOpen"
        class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-12"
        @close-modal="handleCloseModal"
        @click.self="handleCloseModal"
      >
        <div
          class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-20 relative"
        >
          <div
            class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r"
          >
            <button
              class="h-6 w-6 flex items-center justify-center"
              @click="handleCloseModal"
            >
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <DialogPanel>
            <DialogTitle
              class="flex justify-center mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
              >{{ !editNote ? "Add New" : "Edit" }} Note</DialogTitle
            >
            <div class="gap-4">
              <div>
                <fieldset class="mt-2">
                  <div v-if="!editNote" class="grid xs:grid-cols-2 mt-4 gap-4">
                    <div class="flex-1">
                      <VSelectCaret
                        v-model="note.library_type"
                        label="label"
                        :options="NOTE_TYPES"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        :disabled="state.isSaved"
                        :vuelidate-instance="v$.library_type"
                      >
                        <template #fieldLabel>
                          <label class="w-full common-label-text mt-4"
                            >Note Type</label
                          >
                        </template>
                      </VSelectCaret>
                    </div>
                    <div v-if="hasUsersAdvisePermission">
                      <label class="w-full common-label-text mt-4"
                        >Note Sensitivity</label
                      >
                      <BasicCheckbox
                        v-model="note.private"
                        class="my-auto font-normal"
                        field-label-classes="font-normal"
                        field-label="Sensitive and should be restricted"
                        :disabled="state.isSavingLoading"
                        :vuelidate-instance="v$.private"
                      />
                    </div>
                  </div>
                  <div class="w-full mb-4">
                    <label class="w-full common-label-text mt-4">Notes</label>
                    <div class="mt-1">
                      <SimpleTextarea
                        v-model="note.comment"
                        label-name="Notes"
                        :vuelidate-instance="v$.comment"
                        :max-length="MAX_NOTE_TEXTAREA_LIMIT"
                        :disabled="state.isSavingLoading"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="flex justify-center mt-6 mb-6 space-x-4">
              <BaseButton
                class="text-base font-semibold"
                outlined
                :disabled="state.isSavingLoading"
                @click="handleCloseModal"
              >
                Cancel
              </BaseButton>
              <ButtonWithSpinner
                id="saveProfileCommentButton"
                class="text-base font-semibold !h-7 w-24"
                data-cy="save-profile-comment-button"
                variant="secondary"
                variant-type="normal"
                :prop-loading="state.isSavingLoading"
                @click.prevent="saveNote()"
              >
                <span>Save</span>
              </ButtonWithSpinner>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  </div>
</template>
