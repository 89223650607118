<template>
  <div>
    <ais-refinement-list
      :attribute="facetName"
      :sort-by="sortBy"
      :limit="1000"
      operator="or"
      ref="refinementListRef"
    >
      <template v-slot="{ items, refine }" >
        <div v-show="items?.length === 0" class="text-1xs2 bg-gray-75 text-indigo-base rounded-md mt-1">
          <p>No options available. Clear a filter on another category.</p>
        </div>
        <div v-show="items?.length !== 0" class="mt-2">
          <div v-for="item in items" :key="item.value">
            <label class="flex mt-4 items-center mt-0 mb-4">
              <input
                type="checkbox"
                :value="item.value"
                :checked="item.isRefined"
                :class="[`${primaryColorClass}`]"
                class="w-6 h-6 form-checkbox"
                @change="handleChecked($event.currentTarget.checked, item, refine)"
              />
              <div class="flex-1 ml-2">
                <p
                  class="text-xs font-montserrat text-indigo-base font-medium text-sm"
                >
                  {{ item.label }}
                </p>
              </div>
            </label>
          </div>
        </div>
      </template>
    </ais-refinement-list>
  </div>
</template>

<script setup>
import {
  defineEmits,
  defineProps,
  ref,
  unref,
  watch,
  computed,
  toRefs,
} from "vue";
import { AisRefinementList } from "vue-instantsearch/vue3/es";
const checkedBoxes = ref([]);
const refinementListRef = ref(undefined);

const props = defineProps({
  filterName: String,
  algoliaConfiguration: {
    type: Object,
  },
  updateFilter: {
    type: Function, // Don't do this, for whatever reason the Algolia refine reverts if I use an emit
  },
  selectedOptions: {
    type: Array,
    default(rawProps) {
      return [];
    },
  },
  orderBy: {
    type: String,
    default: 'name:asc',
  },
});

const { algoliaConfiguration, filterName, orderBy } = toRefs(props);

const sortBy = ref([unref(orderBy)]);

const facetName = computed(() => {
  return unref(algoliaConfiguration)?.[unref(filterName)]
})

const handleChecked = (newValue, item, refine) => {
  let updatedCheckedBoxes;
  if (newValue) {
    updatedCheckedBoxes = [...unref(checkedBoxes), `${item.value}`];
  } else {
    updatedCheckedBoxes = unref(checkedBoxes).filter(
      (value) => value !== item.value
    );
  }
  checkedBoxes.value = updatedCheckedBoxes;
  refine(item.value)
  // emits("checkbox-filter", [...unref(checkedBoxes)]);
  // Don't do this, for whatever reason the Algolia refine reverts if I use an emit
  unref(props.updateFilter)(unref(props.filterName), [...updatedCheckedBoxes])
};

watch(
  () => props.selectedOptions,
  (updatedOptions) => {
    const algoliaState = unref(refinementListRef)?.state;
    if (algoliaState?.canRefine && algoliaState?.items) {
      algoliaState?.items.forEach((item) => {
        const included = updatedOptions.includes(item.value);
        const refined = checkedBoxes.value.includes(item.value)
        if ((included && !refined) || (!included && refined)) {
          unref(refinementListRef).refine(item.value);
        }
      });
    }
    checkedBoxes.value = [...updatedOptions];
  },
  { immediate: true, deep: true }
);
</script>
