<script setup>
import { defineProps, defineEmits, toRefs, computed, ref, watch } from "vue";
import { uniqBy } from "lodash";
import APIValidationBadge from "@/components/shared/icons/APIValidationBadge.vue";
import ApplyButton from "@/components/shared/Button/ApplyButton.vue";
import HousingCards from "@/components/ExperiencePage/ProgramDetailsSection/housing/components/HousingCards";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import ordersService from "@/services/orders.js";
import { priceLabel } from "@/util/pricingLabel.js";

defineOptions({
  name: "HousingSectionBlock",
})

const emit = defineEmits([
  "selectSession",
  "personalizeAndApply",
  "observeSection",
]);

const props = defineProps({
  sessionId: {
    type: String,
    required: true,
  },
  sessions: {
    type: Array,
    required: true,
  },
});

const { sessionId, sessions } = toRefs(props);

const getSelectedSession = () =>
  sessions.value?.find((session) => session?.id === sessionId.value) || {};

const selectedSession = ref(getSelectedSession());
const roomPrices = ref([]);
const completedFetchingRooms = ref(false);

watch(sessionId, () => {
  selectedSession.value = getSelectedSession();
  loadRoomPrices();
});

const sessionHousing = computed(() =>
  uniqBy(
    (
      selectedSession.value?.session_units?.map((session_units) => ({
        ...session_units.unit?.housings,
        startingPrice: completedFetchingRooms.value
          ? priceLabel(
              roomPrices.value,
              selectedSession.value?.default_room_id,
              session_units.unit?.rooms,
              true
            )
          : "",
      })) || []
    )?.filter((item) => item) || [],
    "id"
  )
);

watch(
  () => sessionHousing.value,
  (newSessionHousing) => {
    if (newSessionHousing) {
      emit("observeSection", "housing");
    }
  }
);

const goToPersonalizeAndApply = () => {
  emit("personalizeAndApply", sessionId.value);
};
const loadRoomPrices = () => {
  roomPrices.value = [];
  const sessionUnits = selectedSession.value?.session_units ?? [];

  // exit early if there are no rooms to price
  if (sessionUnits.length < 1) {
    return;
  }
  const roomIds = sessionUnits
    .map((sessionUnit) => {
      const rooms = sessionUnit?.unit?.rooms ?? [];
      return rooms.map((room) => room?.id).filter((id) => Boolean(id));
    })
    .flat();
  completedFetchingRooms.value = false;
  ordersService
    .getOrdersPricing({
      payload: {
        room_ids: roomIds,
        session_id: selectedSession.value?.id,
      },
    })
    .then((response) => {
      const roomPriceObjects = response.data?.data?.rooms ?? [];
      roomPrices.value = Object.fromEntries(
        roomPriceObjects.map((obj) => [
          obj.product_id,
          obj.student_total_price_in_cents,
        ])
      );
    })
    .finally(() => (completedFetchingRooms.value = true));
};
</script>

<template>
  <div v-if="sessionHousing" class="pt-20 px-4 md:px-8 text-indigo-base">
    <div class="flex flex-col lg:flex-row gap-4">
      <div class="h-fit w-full flex flex-col grow gap-4">
        <div class="justify-start">
          <div class="self-center w-full">
            <div class="flex self-center">
              <span class="self-center uppercase font-bold text-4xl"
                >Housing</span
              >
              <div class="w-full">
                <APIValidationBadge
                  custom-class="pl-2 w-20 h-20 float-right md:float-none"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="block text-justify h-fit w-full">
          We know that comfort and safety are top priorities when it comes to
          housing. That's why API's Visited and Verified™ brand means that every
          one of our housing options has been inspected by API personnel and
          meets our standards for quality, security and convenience.
        </div>
      </div>
    </div>
    <HousingCards :housings="sessionHousing" :room-prices="roomPrices" />
    <div class="mt-20 md:h-30 flex justify-end">
      <div
        class="w-full lg:w-96 grid md:grid-rows-2 grid-flow-rows gap-2 md:gap-4"
      >
        <div>
          <ApplyButton
            :id="sessionId"
            :disabled="pastSessionDeadline(selectedSession)"
            type="button"
            :outlined="true"
            class="py-2 mt-3 xs:mt-0 text-sm"
            event-category="Program Page Personalize CTA"
            @click="goToPersonalizeAndApply"
          />
        </div>
      </div>
    </div>
    <hr class="my-1 md:mt-20 border-b-3 border-gray-200" />
  </div>
</template>

<style scoped>
.bookmark-icon > svg {
  fill: #1e264c;
}
.bookmark-icon:hover > svg {
  fill: white;
}
.session-select-component-style :deep(.vs__dropdown-toggle) {
  overflow: auto;
  max-height: fit-content;
  display: flex;
  border-color: white;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.session-select-component-style :deep(.vs__selected-options) {
  display: contents;
  overflow: hidden;
}
.session-select-component-style :deep(.vs__dropdown-menu) {
  border-radius: 0;
  display: block;
  overflow: hidden;
  word-wrap: break-word;
}
.session-select-component-style :deep(.vs__dropdown-option) {
  white-space: normal;
}
.session-select-component-style :deep(.vs__dropdown-option--highlight) {
  background: #edf9f8 !important;
  cursor: auto;
}
.session-select-component-style :deep(.vs__selected) {
  display: block;
  width: 100%;
  position: relative !important;
}
</style>
