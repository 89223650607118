import { getNested, addClassToElementById } from "./helpers";
import { mapState, mapMutations } from "vuex";
import { _ } from "core-js";
import { eventBus } from "@/app";

export default {
  inject: {
    agreementsFlag: {
      // Provided from Checkout.vue (V3 component)
      // Workaround for V3 - avoids running billing agreement logic on an old mixin made in vue 2 because it overwrites what we set here due to different logic
      from: "blockV2BillingAgreements",
      default: false,
    },
  },
  watch: {
    program: {
      handler: function (newval) {
        if (!this.agreementsFlag)
          if (_.isEmpty(newval)) {
            // Remove Agreement if no program selected
            this.setInstitutionPaysValue({
              field: "applicationFee",
              value: false,
            });
            this.setInstitutionPaysValue({
              field: "confirmationPayment",
              value: false,
            });
            this.setInstitutionPaysValue({
              field: "academicFee",
              value: false,
            });
            this.setInstitutionPaysValue({
              field: "housingFee",
              value: false,
            });
            this.setAppliedAgreement({});
          } else {
            // Check if agreemeent applies if a program was selected
            this.institutionPaysPaymentType("applicationFee");
            this.institutionPaysPaymentType("confirmationPayment");
            this.institutionPaysPaymentType("academicFee");
            this.institutionPaysPaymentType("housingFee");
            eventBus.$emit("setLoadingContract", false);
          }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState([
      "isInternship",
      "program",
      "institutionPaysApplicationFee",
      "institutionPaysConfirmationPayment",
      "institutionPaysAcademicFee",
      "institutionPaysHousingFee",
    ]),
    billingRules() {
      return this.$store.state.billingRules;
    },
  },
  methods: {
    ...mapMutations(["setAppliedAgreement", "setInstitutionPaysValue"]),
    institutionPaysPaymentType(paymentType) {
      // Bail if we don't have info
      if (Object.keys(this.billingRules).length === 0) {
        this.setAppliedAgreement({});
        this.setInstitutionPaysValue({ field: paymentType, value: false });
        return false;
      }

      // Check if it qualifies to an agreement
      let agreement = this.billingRules.agreements.find((contract) => {
        // Check if current date is within the contract
        // Bail if not
        if (
          !this.dateWithinContract(
            contract.contract_start_date,
            contract.contract_end_date
          )
        ) {
          return false;
        }

        // Session is on list of approved sessions
        let sessionApproved = contract.programs.find(
          (session) =>
            session.salesforce_program_session_id === this.program.salesforce_id
        );
        if (sessionApproved !== undefined) {
          return true;
        }

        // Payment must be done by the participant or session term
        // not included in list of approved terms
        if (!this.inTerms(contract.terms)) {
          return false;
        } else {
          // Check the program type
          if (this.isInternship) {
            // Internship Programs
            // All sites approved OR program site appear in list of approved
            if (contract.all_sites) return true;
            if (this.inSites(contract.sites)) return true;
            return false;
          } else {
            // Abroad, any other kind of program
            // All host approved OR program university in list of approved
            if (contract.all_hosts) return true;
            if (this.inHosts(contract.hosts)) return true;
            return false;
          }
        }
      });
      this.setInstitutionPaysValue({
        field: paymentType,
        value:
          agreement !== undefined && agreement[paymentType] === "Institution",
      });

      this.setAppliedAgreement(agreement !== undefined ? agreement : {});
      return agreement !== undefined;
    },
    inHosts(hosts) {
      const approvedHosts = hosts.map((host) => {
        return host.salesforce_host_university_id;
      });
      return approvedHosts.includes(
        getNested(
          this.$store.state.program,
          "program",
          "university",
          "salesforce_id"
        )
      );
    },
    inSites(sites) {
      const approvedSites = sites.map((site) => {
        return site.salesforce_site_id;
      });
      return approvedSites.includes(
        getNested(this.$store.state.program, "site", "salesforce_id")
      );
    },
    inTerms(terms) {
      return terms.includes(this.$store.state.term);
    },
    processProgramFeesRules(form) {
      if (this.institutionPaysApplicationFee) {
        addClassToElementById("applicationFee", "hidden");
      }
      if (this.institutionPaysConfirmationPayment) {
        addClassToElementById("confirmationPayment", "hidden");
      }
      if (this.institutionPaysAcademicFee) {
        addClassToElementById("academicFee", "hidden");
        let scheduleOfProgramFees = form.getComponent("scheduleOfProgramFees");
        if (scheduleOfProgramFees) {
          form.removeComponent(scheduleOfProgramFees);
          form.redraw();
        }
      }
      if (this.institutionPaysHousingFee) {
        addClassToElementById("housingFee", "hidden");
      }
    },
    dateWithinContract(start, end) {
      let today = new Date().getTime();
      if (start === null || end === null) return false;
      if (start === undefined || end === undefined) return false;
      let startTime = this.setDayTime(new Date(start)); // 00:00:00
      let endTime = this.setDayTime(new Date(end), true); // 23:59:59
      if (today < startTime.getTime()) return false;
      if (today > endTime.getTime()) return false;
      return true;
    },
    setDayTime(paramDate = new Date(), isEndDay = false) {
      if (isEndDay) {
        paramDate.setHours(23);
        paramDate.setMinutes(59);
        paramDate.setSeconds(59);
      } else {
        paramDate.setHours(0);
        paramDate.setMinutes(0);
        paramDate.setSeconds(0);
      }
      return paramDate;
    },
  },
};
