<script>
export default {
  name: "ReportContainer",
};
</script>

<script setup>
import { defineProps, toRefs, ref, onMounted } from "vue";
import { TableauViz } from "@tableau/embedding-api";
import { TABLEAU_VIZ_FRAME_HEIGHT, TABLEAU_VIZ_FRAME_WIDTH } from "@/constants";

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: TABLEAU_VIZ_FRAME_WIDTH,
  },
  height: {
    type: String,
    default: TABLEAU_VIZ_FRAME_HEIGHT,
  },
  outerClasses: {
    type: String,
    default: "w-full",
  },
  innerClasses: {
    type: String,
    default: "w-full",
  },
});

const { src, width, height } = toRefs(props);

const report = ref(null);

onMounted(() => {
  if (report.value) {
    const viz = new TableauViz();
    viz.src = src.value;
    viz.toolbar = "bottom";
    viz.width = width.value;
    viz.height = height.value;
    report.value.appendChild(viz);
  }
});
</script>

<template>
  <div :class="outerClasses">
    <div ref="report" :class="innerClasses"></div>
  </div>
</template>
