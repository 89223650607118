<template>
  <svg width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="6q5syxdy5a">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.184314 0 0 0 0 0.376471 0 0 0 0 0.584314 0 0 0 1.000000 0"/>
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <rect width="52" height="52" rx="26" fill="#FAFBFF" stroke="#2F6095" stroke-width="2" transform="translate(1 1)"/>
      <g filter="url(#6q5syxdy5a)" transform="translate(1 1)">
        <g fill="#FFF">
          <path d="m37.725 23.491-1.575-.233-1.488 1.487 2.1.322v3.266l-2.188.292c-.291.058-.553.233-.67.525l-.788 1.926a.823.823 0 0 0 .088.816l1.312 1.75-2.303 2.304-1.75-1.312a.822.822 0 0 0-.817-.088l-1.896.787a.902.902 0 0 0-.525.642l-.322 2.187h-3.237l-.32-2.187c-.03-.292-.234-.526-.526-.642l-1.926-.787a.823.823 0 0 0-.816.088l-1.75 1.312-2.304-2.304 1.312-1.75a.822.822 0 0 0 .088-.816l-.758-1.926a.896.896 0 0 0-.67-.525l-2.158-.292v-3.266l2.157-.322a.83.83 0 0 0 .671-.525l.788-1.925a.823.823 0 0 0-.088-.817l-1.313-1.75 2.304-2.304 1.75 1.313a.907.907 0 0 0 .817.116l1.926-.787a.807.807 0 0 0 .495-.671l.321-2.187h3.267l.321 2.1 1.488-1.488-.233-1.575a.85.85 0 0 0-.846-.729h-4.726a.874.874 0 0 0-.845.73l-.35 2.42-1.05.438-1.954-1.458c-.322-.262-.788-.204-1.109.087l-3.296 3.325c-.292.292-.321.759-.088 1.108l1.459 1.954-.438 1.05-2.391.321a.85.85 0 0 0-.73.845v4.725c0 .116.03.262.088.38.03.087.088.175.176.233a.894.894 0 0 0 .467.234l2.421.35.437 1.02-1.458 1.954c-.293.321-.235.789.057 1.11l3.325 3.324a.882.882 0 0 0 1.108.088l1.954-1.458 1.05.437.35 2.421a.85.85 0 0 0 .846.73h4.724a.874.874 0 0 0 .846-.73l.35-2.42 1.05-.438 1.954 1.458a.845.845 0 0 0 1.108-.088l3.325-3.324c.291-.292.321-.759.088-1.109l-1.459-1.954.438-1.02 2.42-.35a.844.844 0 0 0 .73-.817l.001-4.755c-.088-.409-.408-.787-.817-.846z"/>
          <path d="M39.125 14.1 37.9 12.875c-.962-.963-2.625-.963-3.588 0L23.58 23.609a1.188 1.188 0 0 0-.204.32L21.8 28.248c-.204.554-.058 1.167.35 1.604.292.292.671.438 1.079.438.175 0 .35-.03.525-.088l4.317-1.575a.763.763 0 0 0 .322-.204l10.732-10.734c.467-.468.73-1.109.73-1.78 0-.7-.263-1.341-.73-1.808zM23.521 28.48l1.312-3.647 2.304 2.304-3.616 1.342zM37.929 16.49 28.39 26.03l-2.42-2.422 9.508-9.537a.863.863 0 0 1 1.197 0l1.224 1.225c.176.175.262.38.262.613a.865.865 0 0 1-.233.582z"/>
        </g>
      </g>
    </g>
  </svg>
</template>
