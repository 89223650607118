<script>
export default {
  name: "ClassesFeeFulfillmentModal",
};
</script>

<script setup>
import { toRefs, ref, computed, watch, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import useVuelidate from "@vuelidate/core";
import { maxLength, helpers } from "@vuelidate/validators";
import { uuid } from "vue-uuid";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import SimpleTextarea from "@/components/shared/SimpleTextarea.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";
import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import ordersService from "@/services/orders";
import {
  CANCELLATION_REASONS_OTHER,
  CANCELLATION_REASONS,
  ERROR_TIMEOUT,
  MAX_NOTE_TEXTAREA_LIMIT,
  ORDER_PRODUCT_STATUS,
  ORDER_STATUS,
  SUCCESS_TIMEOUT,
} from "@/constants";
import { ERROR_MESSAGE } from "@/components/StudentFulfillment/components/modals/HousingFulfillmentModal/constants";
import { hasOrdersWritePermission } from "@/composables/authorization";
import { formattedPriceFromCents } from "@/util/formatter";

const toast = useToast();

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  modalIsOpen: {
    type: Boolean,
    default: false,
  },
  orderId: {
    type: String,
    default: "",
  },
  orderStatus: {
    type: String,
    default: "",
  },
  lineItem: {
    type: Object,
    default: () => ({}),
  },
  statusOptions: {
    type: Array,
    default: () => [],
  },
});

const { orderId, orderStatus, modalIsOpen, lineItem, statusOptions } = toRefs(
  props
);

const tableHeaders = ["Total Price", "Status"];

const state = reactive({
  isSaving: false,
  orderIsFulfilled: orderStatus === ORDER_STATUS.fulfilled,
});

const classesFeeModalData = ref({
  totalPriceInCents: {
    isPriceOverride: false,
    value: lineItem?.value?.total_price_in_cents ?? 0,
  },
  status: lineItem?.value?.status ?? ORDER_PRODUCT_STATUS.unfulfilled,
  cancellationReason: undefined,
  otherCancellationReasonText: undefined,
});

const classesFeeTotalPriceOptions = computed(() => {
  const prices = [];

  prices.push({
    label: `Current Price (${formattedPriceFromCents(
      lineItem.value.total_price_in_cents
    )})`,
    id: lineItem?.value?.total_price_in_cents,
  });

  if (lineItem?.value?.total_price_in_cents !== lineItem?.value?.sessionPrice) {
    prices.push({
      label: `Session Price (${formattedPriceFromCents(
        lineItem.value.sessionPrice
      )})`,
      id: lineItem?.value?.sessionPrice,
    });
  }

  // TODO: To be worked later
  // prices.push({
  //   label: "Price Override",
  //   id: 0,
  // });

  return prices;
});
const isCanceledSelected = computed(
  () => classesFeeModalData?.value?.status === "Canceled"
);

const isPriceOverride = computed(
  () =>
    classesFeeModalData?.value?.totalPriceInCents?.value ===
    lineItem?.value?.sessionPrice
);

const checkOtherCancellationReason = (cancellationReason) =>
  cancellationReason &&
  CANCELLATION_REASONS.findIndex(({ id }) => id === cancellationReason) < 0;

const showCancellationReasonText = computed(
  () =>
    classesFeeModalData?.value?.cancellationReason ===
      CANCELLATION_REASONS_OTHER ||
    checkOtherCancellationReason(classesFeeModalData?.value?.cancellationReason)
);

const rules = {
  cancellationReason: {
    requiredIfCanceled: helpers.withMessage(
      "Cancellation reason is required when status is Canceled",
      (value, parentVm) => (parentVm?.status === "Canceled" ? !!value : true)
    ),
  },
  otherCancellationReasonText: {
    requiredIfCanceledAndOther: helpers.withMessage(
      "Text is required when status is Canceled and Reason is Other",
      (value, parentVm) =>
        parentVm?.status === "Canceled" &&
        parentVm?.cancellationReason == CANCELLATION_REASONS_OTHER
          ? !!value
          : true
    ),
    maxLength: maxLength(MAX_NOTE_TEXTAREA_LIMIT),
  },
};

const handleCloseModal = (order) => emit("closeModal", order);

const openErrorToast = (message = undefined) => {
  toast.open({
    message: `${message || ERROR_MESSAGE}`,
    type: "error",
    position: "bottom",
    duration: ERROR_TIMEOUT,
  });
};

const executeSaveAndClose = async ({ payload }) => {
  try {
    v$.value.$reset();
    const isValid = await v$.value.$validate();
    const itemProductId = lineItem?.value?.id;
    if (isValid && itemProductId && orderId.value) {
      const response = await ordersService.updateOrderLineItem({
        orderId: orderId.value,
        productId: itemProductId,
        payload: payload,
        skipErrorBus: true,
      });

      const orderData = response?.data;
      const productDetails = orderData?.data?.product_details;
      if (productDetails) {
        toast.open({
          message: "Academics: Classes updated Successfully",
          type: "success",
          position: "bottom",
          duration: SUCCESS_TIMEOUT,
        });
        handleCloseModal(orderData);
      }
    } else if (!isValid) {
      openErrorToast(v$?.value?.$errors?.[0]?.$message);
    }
  } catch (err) {
    openErrorToast();
  }
};

const saveClassesFulfillmentOrder = async () => {
  state.isSaving = true;
  try {
    const payload = {
      new_product_id: lineItem?.value?.id,
      status: classesFeeModalData.value?.status,
      quantity: lineItem?.value?.quantity,
      use_session_class_fee_price: isPriceOverride.value,
    };

    if (payload?.status === ORDER_PRODUCT_STATUS.canceled) {
      payload.cancellation_reason = showCancellationReasonText.value
        ? classesFeeModalData.value?.otherCancellationReasonText
        : classesFeeModalData.value?.cancellationReason;
    }
    await executeSaveAndClose({ payload });
  } catch (err) {
    toast.open({
      message: `${ERROR_MESSAGE}`,
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  } finally {
    state.isSaving = false;
  }
};

watch(modalIsOpen, (isOpen) => {
  if (isOpen) {
    const reason = lineItem?.value?.cancellation_reason;
    const cancellationReason = checkOtherCancellationReason(reason)
      ? CANCELLATION_REASONS_OTHER
      : reason;
    classesFeeModalData.value = {
      totalPriceInCents: {
        isPriceOverride: false,
        value: lineItem?.value?.total_price_in_cents ?? 0,
      },
      status: lineItem?.value?.status ?? ORDER_PRODUCT_STATUS.unfulfilled,
      cancellationReason,
      otherCancellationReasonText: reason ?? "",
    };
  }
});

const v$ = useVuelidate(rules, classesFeeModalData, {
  $registerAs: `FulfillmentAcademicsClassesFeeModal-${uuid.v4()}`,
  $lazy: true,
  $autoDirty: true,
});
</script>

<template>
  <div>
    <div v-if="modalIsOpen && hasOrdersWritePermission" class="relative z-50">
      <div
        class="fixed inset-0 z-40 hidden bg-university-primary opacity-50 sm:block"
      ></div>
      <Dialog
        :open="modalIsOpen"
        class="w-screen fixed inset-0 z-50 overflow-auto bg-white py-6 sm:bg-transparent sm:p-12"
        @close-modal="handleCloseModal"
        @click.self="handleCloseModal"
      >
        <div
          class="relative z-50 mx-auto max-w-8xl bg-white px-6 sm:rounded-lg sm:px-16 sm:py-20 sm:shadow md:px-24"
        >
          <div
            class="absolute right-3r xs:top-1r sm:top-3r md:top-3r lg:top-3r"
          >
            <button
              class="flex h-6 w-6 items-center justify-center"
              @click="handleCloseModal"
            >
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <DialogPanel>
            <DialogTitle
              class="mb-12 mt-6 flex justify-center text-center text-xl leading-tight sm:text-2xl md:text-3xl"
            >
              Classes Fee
            </DialogTitle>
            <div
              class="w-full flex-col justify-between pb-40 overflow-x-hidden"
            >
              <table
                class="m-4 w-full block border-b border-solid border-gray-200 sm:table sm:p-10"
              >
                <thead class="hidden md:table-header-group">
                  <tr class="border-b border-solid border-gray-200">
                    <th
                      id="Name"
                      class="items-center py-4 pr-3 text-left text-sm text-gray-900 first:pl-2"
                    >
                      Name
                    </th>
                    <th
                      v-for="header of tableHeaders"
                      :id="header"
                      class="items-center py-4 pr-3 text-left text-sm text-gray-900 first:pl-2"
                    >
                      <div class="ml-5">{{ header }}</div>
                    </th>
                  </tr>
                </thead>
                <tbody class="block sm:table-row-group">
                  <tr class="flex flex-col md:table-row">
                    <td class="hidden md:block py-5">
                      <span class="text-wrap block"> Classes Fee </span>
                    </td>
                    <td class="py-5">
                      <VSelectCaret
                        v-model="classesFeeModalData.totalPriceInCents.value"
                        outer-classes="checkbox-placeholder mr-5 md:mx-5"
                        scroll-input="auto"
                        :options="classesFeeTotalPriceOptions"
                        :clearable="false"
                        :reduce="(val) => val.id"
                        :disabled="state.isSaving || state.orderIsFulfilled"
                      ></VSelectCaret>
                    </td>
                    <td class="py-5">
                      <VSelectCaret
                        v-model="classesFeeModalData.status"
                        outer-classes="checkbox-placeholder mr-5 md:mx-5"
                        scroll-input="auto"
                        :options="statusOptions"
                        :clearable="false"
                        :disabled="state.isSaving"
                      ></VSelectCaret>
                    </td>
                  </tr>
                  <tr v-if="isPriceOverride"></tr>
                  <tr class="block sm:table-row" v-if="isCanceledSelected">
                    <td class="block w-full py-5 sm:table-cell" colspan="4">
                      <div
                        class="flex w-full flex-col items-start md:items-end"
                      >
                        <div
                          class="flex flex-col w-full items-baseline md:w-fit md:flex-row md:items-center"
                        >
                          <span class="common-label-text"
                            >Reason for Cancellation</span
                          >
                          <VSelectCaret
                            v-if="isCanceledSelected"
                            v-model="classesFeeModalData.cancellationReason"
                            outer-classes="mr-5 md:mx-5 w-full pr-5 md:pr-0 md:w-[28rem] checkbox-placeholder"
                            scroll-input="auto"
                            :reduce="(val) => val.id"
                            :options="CANCELLATION_REASONS"
                            :clearable="false"
                            :disabled="state.isSaving"
                            :vuelidate-instance="v$.cancellationReason"
                          >
                          </VSelectCaret>
                        </div>
                        <div
                          class="mt-4 w-full md:w-fit"
                          v-if="showCancellationReasonText"
                        >
                          <SimpleTextarea
                            v-model="
                              classesFeeModalData.otherCancellationReasonText
                            "
                            label-name="Cancellation Reason"
                            outer-classes="mr-5 md:mx-5 md:w-[28rem]"
                            :max-length="MAX_NOTE_TEXTAREA_LIMIT"
                            :disabled="state.isSaving"
                            :vuelidate-instance="v$.otherCancellationReasonText"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex justify-center space-x-4">
              <BaseButton
                class="text-base font-medium"
                outlined
                :disabled="state.isSaving"
                @click="handleCloseModal"
              >
                Cancel
              </BaseButton>
              <ButtonWithSpinner
                id="saveHousingFulfillmentOrderButton"
                :prop-loading="state.isSaving"
                class="h-7 w-24 text-base font-semibold"
                data-cy="save-order-button"
                variant="secondary"
                variant-type="normal"
                @click.prevent="saveClassesFulfillmentOrder"
              >
                <span>Save</span>
              </ButtonWithSpinner>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  </div>
</template>
