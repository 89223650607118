<script setup>
import { computed, reactive, toRefs, ref } from "vue";
import ArrowDownIcon from "@/components/ExperiencePage/Menu/ArrowDownIcon.vue";
import OccurrenceMenu from "./OccurrenceMenu.vue";
import { formatDate } from "@/mixins/helpers";

const props = defineProps({
  event: {
    type: Object,
    default: () => {},
  },
  occurrence: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["toggleMore", "editOccurrence"]);
const { event, occurrence } = toRefs(props);

const moreContent = ref(false);

const toggleMore = () => {
  moreContent.value = !moreContent.value;
};

const pastOccurrences = (...statuses) => {
  const today = new Date();
  const endDate = new Date(occurrence?.end_date_time);
  const status = occurrence?.value?.occurrence_status?.name;
  const inOtherStatus = ["Draft", "Inactive", ...statuses];
  return (
    endDate.getTime() < today.getTime() &&
    endDate !== null &&
    !inOtherStatus.includes(status)
  );
};

const pastOccurrenceCheck = () => {
  const occurrenceStatus = occurrence.value?.occurrence_status?.name;
  const statuses = new Set(["Draft", "Active"]);
  if (occurrence.value?.end_date_time === null) {
    return !statuses.has(occurrenceStatus);
  }
  if (occurrenceStatus === "Draft") {
    return false;
  }
  const today = new Date();
  const deadline = new Date(occurrence?.end_date_time);
  return deadline.getTime() < today.getTime();
};

const isPastOccurrence = computed(() => {
  return pastOccurrenceCheck();
});

const status = computed(() => occurrence?.value?.occurrence_status?.name);

const statusDetails = () => {
  if (!pastOccurrences("Archived")) {
    switch (status.value) {
      case "Active":
        return {
          text: "Open to registration",
          color: "bg-help-success",
        };
      case "Draft":
        return {
          text: "Unpublished",
          color: "bg-help-warning",
        };
      case "Inactive":
        return {
          text: "Cancelled",
          color: "bg-help-error",
        };
      case "Archived":
        return {
          text: "Cancelled",
          color: "bg-help-error",
        };
      default:
        return {
          text: "Completed",
          color: "bg-gray-400",
        };
    }
  } else {
    return {
      text: "Completed",
      color: "bg-gray-400",
    };
  }
};

const statusBar = reactive(statusDetails());
</script>

<template>
  <div
    class="text-blue-900 border-gray-200 rounded bg-white p-6 border shadow-sm"
  >
    <div class="flex justify-between">
      <div class="flex justify-center">
      <div
        class="rounded-full text-white mb-6 max-w-60 text-center uppercase text-xs px-4"
        :class="statusBar.color"
      >
        {{ statusBar.text }}
      </div>
      <img
          v-if="occurrence.marketplace"
          src="/images/icon-marketplace.svg"
          class="mb-6 ml-2"
        />
      </div>
      <OccurrenceMenu
        :is-view-only="isPastOccurrence"
        @open-edit-modal="emit('editOccurrence')"
      />
    </div>

    <div class="grid grid-cols-2 gap-5 mb-5">
      <div v-if="occurrence?.departure_city">
        <p class="font-semibold text-blue-900">
          Departure City
        </p>
        <p>{{ occurrence?.departure_city }}</p>
      </div>
      <div v-if="event?.event_locations?.length">
        <p class="font-semibold text-blue-900">
          Location City
        </p>
        <p>
          {{ event.event_locations[0]?.city?.city }},
          {{ event.event_locations[0]?.city?.country?.name }}
        </p>
      </div>
      <div v-if="occurrence?.start_date_time">
        <p class="font-semibold text-blue-900">
          Start Date & Time
        </p>
        <p>{{ formatDate(occurrence.start_date_time) }}</p>
      </div>
      <div v-if="occurrence?.end_date_time">
        <p class="font-semibold text-blue-900">
          End Date & Time
        </p>
        <p>{{ formatDate(occurrence.end_date_time) }}</p>
      </div>
      <div v-if="occurrence?.event_leaders?.length">
        <p class="font-semibold text-blue-900">
          Event Leader Name(s)
        </p>
        <p>
          {{ occurrence.event_leaders.map((item) => item).join(", ") || "" }}
        </p>
      </div>
    </div>
    <div v-if="moreContent" class="grid grid-cols-2 gap-5">
      <div v-if="occurrence?.occurrence_address" class="col-span-2">
        <p class="font-semibold text-blue-900">
          Meeting Point
        </p>
        <p>
          {{ occurrence.occurrence_address?.street_address_1 }},
          {{ occurrence.occurrence_address?.city }},
          {{ occurrence.occurrence_address?.state }},
          {{ occurrence.occurrence_address?.country }},
        </p>
      </div>
      <div v-if="occurrence?.collect_rsvp !== null">
        <p class="font-semibold text-blue-900">Collect RSVP</p>
        <p v-if="occurrence.collect_rsvp === true">Yes</p>
        <p v-else>No</p>
      </div>
      <div v-if="occurrence?.max_capacity !== null">
        <p class="font-semibold text-blue-900">
          Maximum Seating Capacity
        </p>
        <p v-if="occurrence.max_capacity > 0">
          Yes, {{ occurrence.max_capacity }}
        </p>
        <p v-else>No</p>
      </div>
      <div v-if="occurrence?.attendance_required !== null">
        <p class="font-semibold text-blue-900">Attendance Required</p>
        <p v-if="occurrence.attendance_required === true">Yes</p>
        <p v-else>No</p>
      </div>
      <div v-if="occurrence?.guests_allowed !== null">
        <p class="font-semibold text-blue-900">Guests Allowed</p>
        <p v-if="occurrence.guests_allowed === true">Yes</p>
        <p v-else>No</p>
      </div>
      <div v-if="occurrence?.price_student">
        <p class="font-semibold text-blue-900">Price Per Student</p>
        <p>
          {{
            (occurrence.price_student / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }}
        </p>
      </div>
    </div>
    <div
      class="flex items-center w-full justify-center cursor-pointer mt-5"
      @click="toggleMore"
    >
      <ArrowDownIcon
        custom-class="w-5 h-3"
        fill="#007f80"
        :class="{ 'rotate-180 transform': moreContent }"
        aria-hidden="true"
      />
    </div>
  </div>
</template>
