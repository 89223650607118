<template>
  <div :id="idTippyParent">
    <component :is="tag" v-if="showDates" data-cy="sidebar-program-dates-label">
      <slot>
        <span
          :id="id"
          class="inline-flex items-center"
          :class="{ 'flex-row-reverse': reverseIconOrder, [textClass]: true }"
          tabindex="0"
        >
          <i
            v-if="showIcon"
            class="mr-2"
            :class="{
              'ml-2': reverseIconOrder,
              [iconDetails.color]: true,
              'small-alert-icon': experienceCardIcon,
            }"
            :data-feather="iconDetails.icon"
          />
          <p>{{ dates }}</p>
        </span>
      </slot>
    </component>
  </div>
</template>

<script>
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import feather from "feather-icons";
export default {
  name: "ProgramDates",
  props: {
    id: {
      type: String,
      default: "anid",
    },
    dates: {
      type: String,
      default: "",
      required: true,
    },
    startIsFinal: {
      type: Boolean,
      default: false,
      required: true,
    },
    endIsFinal: {
      type: Boolean,
      default: false,
      required: true,
    },
    tag: {
      type: String,
      default: "div",
    },
    reverseIconOrder: {
      type: Boolean,
      default: false,
    },
    experienceCardIcon: {
      type: Boolean,
      default: false,
    },
    textClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    showTooltip() {
      if (this.experienceCardIcon && this.datesConfirmed) {
        return false;
      }
      return true;
    },
    showIcon() {
      if (this.experienceCardIcon && this.datesConfirmed) {
        return false;
      }
      return true;
    },
    divElement() {
      return document.getElementById(this.id);
    },
    showDates() {
      return this.dates;
    },
    overlayMessage() {
      let message = "";
      if (this.startIsFinal === true && this.endIsFinal === true) {
        message = "Program dates are confirmed.";
      } else if (this.startIsFinal === true && this.endIsFinal === false) {
        message =
          "Start date is final but end date will be finalized closer to the start of the program.";
      } else if (this.startIsFinal === false && this.endIsFinal === true) {
        message =
          "End date is final but start date will be finalized closer to the start of the program.";
      } else {
        message = "Dates will be finalized closer to the start of the program."; // Both false
      }
      return message;
    },
    datesConfirmed() {
      return this.startIsFinal === true && this.endIsFinal === true;
    },
    iconDetails() {
      return {
        icon: this.datesConfirmed
          ? "check-circle"
          : this.experienceCardIcon
          ? "alert-circle"
          : "alert-triangle",
        color: this.datesConfirmed
          ? "text-success-900"
          : this.experienceCardIcon
          ? "text-warning-800"
          : "text-warning-900",
      };
    },
    idTippyParent() {
      return "tippy-parent-" + this.id;
    },
    getTippyParent() {
      return document.getElementById(this.idTippyParent);
    },
  },
  mounted() {
    if (this.divElement) {
      this.initIcon();
    }
  },
  methods: {
    initIcon() {
      feather.replace();
      if (this.showTooltip) {
        tippy(this.divElement, {
          content: this.overlayMessage,
          theme: "light",
          role: "tooltip",
          appendTo: this.getTippyParent,
          aria: {
            content: "describedby",
            expanded: "auto",
          },
        });
      }
    },
  },
};
</script>
