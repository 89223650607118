import { cloneDeep } from "lodash";
import { computed } from "vue";
import { getRidOfJSON } from "@/util/removeJson";
import entitiesService from "@/services/entities.js";
import { HEADERS_MAP } from "@/components/program-manager/events/constants.js";

const sanitizeEventImage = (image) => {
  const sanitizedImage = image ? getRidOfJSON(image) : undefined;
  return sanitizedImage;
};

const sanitizeEventLocations = (eventLocationArray) => {
  const sanitizedLocationArray = eventLocationArray.map((locationObj) => {
    // Destructure the object to get values for specific keys
    const {
      image,
      mobility,
      communication,
      sensory,
      ...restOfLocation
    } = locationObj;

    // Apply getRidOfJSON function to specific keys
    const sanitizedImage = image ? getRidOfJSON(image) : undefined;
    const sanitizedMobility = mobility ? getRidOfJSON(mobility) : undefined;
    const sanitizedCommunication = communication
      ? getRidOfJSON(communication)
      : undefined;
    const sanitizedSensory = sensory ? getRidOfJSON(sensory) : undefined;

    // Create a new object with sanitized values
    const sanitizedLocationObj = {
      ...restOfLocation,
      image: sanitizedImage,
      mobility: sanitizedMobility,
      communication: sanitizedCommunication,
      sensory: sanitizedSensory,
    };

    return sanitizedLocationObj;
  });

  return sanitizedLocationArray;
};

const handleEventLocationsMultiMedia = (eventLocations = []) => {
  if (Array.isArray(eventLocations) && eventLocations.length > 0) {
    if (Object.hasOwnProperty.call(eventLocations[0], "image"))
      return getRidOfJSON(eventLocations[0].image);
  }
  return [];
};

const prepareEventImage = (image) => {
  const preparedImage = image ? JSON.stringify(getRidOfJSON(image)) : "[]";
  return preparedImage;
};

const prepareEventLocations = (eventLocationArray) => {
  if (eventLocationArray.length === 0) return;
  const preparedLocationArray = eventLocationArray.map((locationObj) => {
    // Destructure the object to get values for specific keys
    const {
      image,
      mobility,
      communication,
      sensory,
      ...restOfLocation
    } = locationObj;

    // Apply getRidOfJSON function to specific keys
    const preparedImage = image ? JSON.stringify(getRidOfJSON(image)) : "[]";
    const preparedMobility = mobility
      ? JSON.stringify(getRidOfJSON(mobility))
      : "[]";
    const preparedCommunication = communication
      ? JSON.stringify(getRidOfJSON(communication))
      : "[]";
    const preparedSensory = sensory
      ? JSON.stringify(getRidOfJSON(sensory))
      : "[]";

    // Create a new object with prepared values
    const preparedLocationObj = {
      ...restOfLocation,
      image: preparedImage,
      mobility: preparedMobility,
      communication: preparedCommunication,
      sensory: preparedSensory,
    };

    return preparedLocationObj;
  });

  return preparedLocationArray;
};

export const getEntityName = async (entityId) => {
  let entityName;
  try {
    const logEntityName = await entitiesService.getEntityById(entityId);
    entityName = logEntityName.data?.data?.items?.name;
  } catch {
    entityName = "";
  }
  return entityName;
};

export const convertEvents = async (events, bundle, getSubCategories) => {
  const subCategory = computed(() => {
    return bundle?.subcategory_id
      ? getSubCategories.value.filter(
          (subcategory) => subcategory?.id === bundle?.subcategory_id
        )
      : [];
  });
  const selectedSubCategory = cloneDeep(subCategory.value[0]);
  events.value.id = bundle?.id;
  events.value.title = bundle?.title;
  events.value.status = bundle?.status || "Draft";
  events.value.event_category = bundle?.event_category || undefined;
  events.value.category_id = bundle?.event_category?.id || null;
  events.value.sub_category = selectedSubCategory || null;
  events.value.subcategory_id = bundle?.sub_category?.id || null;
  events.value.location_type = bundle?.location_type || null;
  events.value.price_student = (bundle?.price_student / 100).toFixed(2) || 0;
  events.value.duration =
    bundle?.duration === 10 ? "10 or more" : bundle?.duration;
  events.value.duration_units = bundle?.duration_units || null;
  events.value.approx_length = bundle?.approx_length || null;
  events.value.host = bundle?.host ?? "";
  events.value.tags = bundle?.tags || null;
  events.value.type = bundle?.type || null;
  events.value.transportation = bundle?.transportation || null;
  events.value.radius = bundle?.radius || null;
  events.value.terms_available = bundle?.terms_available || null;
  events.value.event_academic_theme = bundle?.event_academic_theme || null;
  events.value.age_requirement =
    bundle?.age_requirement === true ? "Must be over 18" : "All ages";
  events.value.audience = null;
  events.value.includes = bundle?.includes || [];
  events.value.packing_list = bundle?.packing_list || [];
  events.value.primarily_attended_by = bundle?.primarily_attended_by || [];
  events.value.itinerary = bundle?.itinerary || null;
  events.value.accommodation_description =
    bundle?.accommodation_description || null;
  events.value.activity_level = bundle?.activity_level || null;
  events.value.address = bundle?.address || "Austin, Texas";
  events.value.description = bundle?.description || null;
  events.value.selected_whatever = bundle?.selected_whatever || null;
  events.value.accommodation = bundle?.accommodation || null;
  events.value.is_outdoors =
    bundle?.is_outdoors === true ? "Outdoors" : "Indoors";
  events.value.event_locations = bundle.event_locations
    ? sanitizeEventLocations(bundle.event_locations)
    : [];
  events.value.approx_length = bundle?.approx_length;
  events.value.events_multimedia =
    handleEventLocationsMultiMedia(bundle.event_locations) ?? [];
  events.value.event_occurrences = bundle?.event_occurrences;
  events.value.includes_other = bundle?.includes_other || [""];
  events.value.packing_other = bundle?.packing_other || [""];
  events.value.marketplace = bundle?.marketplace ?? true;
  events.value.image = bundle.image ? sanitizeEventImage(bundle?.image) : [];
  events.value.entity_id = bundle?.entity_id || null;
  events.value.entity = events.value.entity_id
    ? await getEntityName(events.value.entity_id)
    : "";
};

export const convertEventsBeforeSave = (events) => {
  const duration_units_map = {
    Hour: "Hours",
    Hours: "Hours",
    Day: "Days",
    Days: "Days",
    Week: "Weeks",
    Weeks: "Weeks",
    Month: "Months",
    Months: "Months",
  };
  return {
    title: events.value.title,
    within_term_availability: events.value.within_term_availability ?? false,
    status: events.value.status,
    event_category: events.value.event_category,
    category_id: events.value.event_category?.id,
    sub_category: events.value.sub_category,
    subcategory_id: events.value.sub_category?.id,
    type: events.value.type,
    host: events.value.host ?? null,
    tags: events.value.tags,
    terms_available: events.value.terms_available,
    age_requirement: events.value.age_requirement === "All ages" ? false : true,
    is_outdoors: events.value.is_outdoors === "Outdoors" ? true : false,
    itinerary: events.value.itinerary,
    event_academic_theme: events.value.event_academic_theme || "",
    activity_level: events.value.activity_level,
    duration_units: duration_units_map[events.value.duration_units],
    address: events.value.address || "",
    description: events.value.description,
    event_locations: prepareEventLocations(events.value.event_locations) || [],
    includes: events.value.includes || [],
    packing_list: events.value.packing_list || [],
    primarily_attended_by: events.value.primarily_attended_by || [],
    approx_length: events.value.approx_length || 0,
    location_type: events.value.location_type || null,
    price_student: events.value.price_student * 100 || 0,
    duration:
      events.value.duration === "10 or more" ? 10 : events.value.duration,
    transportation: events.value.transportation,
    radius: events.value.radius || null,
    audience: null,
    accommodation: events.value.accommodation || null,
    accommodation_description: events.value.accommodation_description || null,
    includes_other: events.value.includes_other || [""],
    packing_other: events.value.packing_other || [""],
    marketplace: events.value.marketplace ?? true,
    image: prepareEventImage(events.value.image) || [],
  };
};

/**
 * Returns a list of columns the endpoint must return
 * @param {Array} - A list of headers in the table
 * @returns {Array} - The list of columns that endpoint must return
 */
export const setIncludedFields = (headers = []) => {
  if (!Array.isArray(headers)) {
    return [];
  }

  const included_columns = [];
  headers.forEach((header) => {
    if (header in HEADERS_MAP) {
      if (typeof HEADERS_MAP[header] === "string" && HEADERS_MAP[header]) {
        included_columns.push(HEADERS_MAP[header]);
      } else if (Array.isArray(HEADERS_MAP[header])) {
        HEADERS_MAP[header].forEach((list_item) =>
          included_columns.push(list_item)
        );
      }
    }
  });

  return included_columns;
};
