<template>
  <div>
    <div class="max-w-7xl px-2 md:px-8 mx-auto">
      <div class="my-4">
        <span
          class="cursor-pointer inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
          @click="cleanPayments"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left w-4 h-4"
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
          <span class="ml-1">Back</span>
        </span>
      </div>
      <div class="w-full px-2 md:px-24 md:pb-4 mx-auto max-w-7xl bg-white">
        <h1 class="text-3xl text-center mb-8 pt-10">Make Payment</h1>
        <!-- Display paymentDetails section control -->
        <PanelForm
          :id="'itemsToPay'"
          :title="'PAYMENT DETAILS'"
          panel-content-class="text-gray-600 border-gray-200"
          :new-margin="'mb-2'"
          :remove-default-margins="true"
        >
          <template #content>
            <ItemsToPay
              ref="itemsToPayComponent"
              :items="value.items"
              payment-context="PaymentView"
              :is-payment-page="true"
              :order-id="orderId"
              @updateItems="updateItems"
            />
            <!-- Total and convenienceFee -->
            <div
              class="grid grid-cols-2 border rounded-md border-gray-400 p-3 mt-2"
            >
              <!-- convenience convenienceFee -->
              <div
                v-if="value.convenienceFee"
                class="col-span-2 flex justify-between mb-2"
              >
                <h2 class="text-base font-semibold ml-2">
                  Convenience Fee *
                </h2>
                <h2 class="text-base">
                  <span class="font-semibold mr-2"
                    >$ {{ value.convenienceFee }} USD</span
                  >
                </h2>
              </div>

              <!-- ACH fee -->
              <div
                v-if="value.achFee"
                class="col-span-2 flex justify-between mb-2"
              >
                <h2 class="text-base font-semibold ml-2">ACH Fee *</h2>
                <h2 class="text-base">
                  <span class="font-semibold mr-2"
                    >${{ value.achFee }} USD</span
                  >
                </h2>
              </div>

              <!-- Total -->
              <div class="col-span-2 flex justify-between">
                <h2 class="text-xl font-bold ml-2 text-gray-700">Total</h2>
                <h2 class="text-xl">
                  <span class="font-bold mr-2 text-gray-700"
                    >${{ value.total }} USD</span
                  >
                </h2>
              </div>
            </div>
          </template>
        </PanelForm>
        <!-- Payment Details -->
        <PaymentType
          ref="paymentType"
          :selected-type="value.paymentTypeSelected"
          :current-payment-type="value.paymentTypeSelected"
          :current-state="value.state"
          @update:payment-type="value.paymentTypeSelected = $event.paymentType"
          @updateState="updateState"
          @turnFeeOff="turnFeeOff"
        />
        <!-- Button -->
        <div class="w-full mt-6">
          <button-with-spinner
            id="sendRequestBtn"
            ref="sendRequestBtn"
            data-cy="sendRequest-btn"
            :disabled="!value.paymentTypeSelected || v$.$invalid"
            :class="[
              tertiaryColor
                ? `${tertiaryColorClass}`
                : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
            ]"
            class="flex w-full justify-center rounded font-semibold border-2 border-transparent px-4 min-h-10 hover:no-underline items-center text-gray-700 hover:bg-yellow-900 hover:text-gray-700 focus:bg-yellow-900"
            @click.prevent="handleContinueButton"
          >
            <span> Continue </span>
          </button-with-spinner>
          <div v-if="error" class="error text-error-900 mt-2">
            {{ error }}
          </div>
        </div>

        <!-- Proceeding warning -->
        <div class="mt-3">
          <span class="text-xs text-gray-700"
            >Selecting continue will take you to a secure payment page to enter
            your payment information.</span
          >
        </div>
      </div>
    </div>
    <DuplicatePaymentModal
      :open="isModalOpen"
      :amount-in-cents="amountMinusFeesInCents"
      @continue="continuePayment"
      @closeModal="isModalOpen = false"
    />
  </div>
</template>

<script>
import ItemsToPay from "@/components/paymentCards/ItemsToPay.vue";
import ButtonWithSpinner from "./forms/SharedComponents/ButtonWithSpinner.vue";
import PaymentType from "./PaymentRequest/PaymentType.vue";
import PanelForm from "./forms/SharedComponents/panel.vue";

import formValidation from "../mixins/formValidation";
import DuplicatePaymentModal from "./modals/DuplicatePaymentModal";
import { differenceInHours, parseISO } from "date-fns";
import {
  required,
  decimal,
  maxLength,
  requiredIf,
  helpers,
} from "@vuelidate/validators";
import {
  isDecimal,
  isHigherThenCurrentBalance,
  dollarsToCents,
  isZeroOrBlank,
} from "./../mixins/helpers";
import { mapState } from "vuex";
import axios from "axios";
import useVuelidate from "@vuelidate/core";

export default {
  name: "PaymentView",
  components: {
    ItemsToPay,
    ButtonWithSpinner,
    PaymentType,
    PanelForm,
    DuplicatePaymentModal,
  },
  mixins: [formValidation],
  props: {
    items: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      expandedPaymentDetails: true,
      error: false,
      byPassFee: false,
      sectionsToValidate: ["itemsToPayComponent"],
      pendingPayments: [],
      isModalOpen: false,
      value: {
        state: null,
        paymentTypeSelected: {},
        convenienceFee: false,
        achFee: false,
        total: 0,
        items: [],
      },
    };
  },
  computed: {
    ...mapState([
      "payments",
      "program",
      "enrollmentApplications",
      "studentApplications",
      "customer_id",
      "currentUser",
    ]),
    amountMinusFeesInCents() {
      return (
        dollarsToCents(this.value.total) -
        dollarsToCents(this.value.achFee || 0) -
        dollarsToCents(this.value.convenienceFee || 0)
      );
    },
    programSessionFinanceCode() {
      let application = this.studentApplications.find(
        (app) => app.id === this.applicationId
      );
      return Object.prototype.hasOwnProperty.call(application, "programSession")
        ? application.programSession.program_session_finance_code
        : null;
    },
    duplicatePayments() {
      return this.pendingPayments.filter(
        (payment) =>
          payment.amount === this.amountMinusFeesInCents &&
          differenceInHours(new Date(), payment.created) <= 1
      );
    },
    withoutFees() {
      let items = this.value.items.filter(
        (item) => !item.name.includes("Fee *")
      );
      return items.map((item) => {
        return {
          ...item,
          balance: item.balance,
        };
      });
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
    sessionYear() {
      return `${this.program.start_date.split("-")[0]} - ${
        this.program.end_date.split("-")[0]
      }`;
    },
    currentEnrollmentProgram() {
      const psfc = this.enrollmentApplications.find(
        (pg) => pg.application_id === this.$route.params.applicationId
      );
      return psfc ?? null;
    },
    baseUrl() {
      return window.location.origin;
    },
    orderId() {
      // optional query parameter indicating that a v3 application has an associated order
      return this.$route?.query?.orderId;
    },
    isV3() {
      return Boolean(this.orderId);
    },
    usingFinancialAid() {
      return this.$route.query?.usingFinancialAid;
    },
    successURL() {
      const base = `${location.protocol}//${location.hostname}/payment-success?`;
      const queryString = Object.entries({
        paymentType: this.value.paymentTypeSelected.value,
        applicationId: this.applicationId,
        orderId: this.orderId,
        usingFinancialAid: this.usingFinancialAid,
        session_id: "{CHECKOUT_SESSION_ID}", // template variable that Stripe will fill in
      })
        .filter(([, value]) => Boolean(value))
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      return base + queryString;
    },
    v2Fields() {
      // in v3, this information will get pulled in by the backend based on the order ID
      if (this.isV3) {
        return { version: "V3" };
      }

      return {
        program_session_finance_code: this.programSessionFinanceCode,
        program_type: this.currentEnrollmentProgram.program_type,
        program_name: this.program.program.name,
        session: this.program?.session,
        session_country: this.program.site.country,
        session_year: this.sessionYear,
        program_session_id: this.program.salesforce_id,
        version: "V2",
      };
    },
  },
  watch: {
    "value.paymentTypeSelected": {
      handler: async function (newval) {
        let reset =
          newval?.value !== "usCreditCard" ||
          (newval?.value === "usCreditCard" &&
            typeof this.value.state === "object"); // turn fee off if no state selected for Cc, or payment != than Cc (Cc = credit card)
        if (reset) {
          this.turnFeeOff(reset);
          // this.$refs.paymentType.cleanDropDown();
        }
        this.calculateAmount();
      },
      deep: true,
    },
    items: {
      handler: function (val) {
        this.value.items = val ?? this.payments;
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      state: {
        required: requiredIf(function () {
          return this.value.paymentTypeSelected?.value === "usCreditCard";
        }),
      },
      total: required,
      paymentTypeSelected: required,
      items: {
        $each: helpers.forEach({
          balance: {
            required,
            decimal,
            isDecimal,
            isHigherThenCurrentBalance,
            maxLength: maxLength(10),
            isZeroOrBlank,
          },
        }),
      },
    },
  },
  async created() {
    await this.fetchPendingPayments();
    this.value.total = this.getTotal();
    this.value.items = JSON.parse(localStorage.getItem("toPay"));
    this.value.items = this.value.items.map((item) =>
      Object.assign({ rootAmount: item.balance }, item)
    );
    if (localStorage.getItem("payment-backup")) {
      let backup = JSON.parse(localStorage.getItem("payment-backup"));
      this.value.state = backup.state;
      this.value.paymentTypeSelected = backup.paymentType ?? false;
      this.value.items = backup.items.map((item) => {
        return {
          ...item,
          otherAmount: Number(item.rootAmount) !== Number(item.balance),
        };
      });
    }
    this.calculateAmount();
  },
  mounted() {
    this.checkItemsToPay();
  },
  unmounted() {
    this.$store.commit("cleanPaymentsToPay");
  },
  methods: {
    fetchPendingPayments() {
      return axios
        .get(`/api/pending-payments/${this.applicationId}`)
        .then((response) => {
          this.pendingPayments = (response.data?.data ?? []).map((payment) => {
            return {
              ...payment,
              // adding a 'Z' to make javascript recognize that the time string is UTC
              created: parseISO(payment.created + "Z"),
              // filtering ACH and credit card fees out of the payment total
              amount: payment.line_items
                .filter((item) => {
                  const name = item.name.toLowerCase();
                  return !(
                    name.includes("convenience fee") || name.includes("ach fee")
                  );
                })
                .reduce((sum, item) => sum + parseInt(item.amount), 0),
            };
          });
        })
        .catch(() => {
          this.pendingPayments = [];
        });
    },
    checkItemsToPay() {
      this.value.items.forEach((val, key) => {
        this.$refs.itemsToPayComponent.checkAmount(key);
      });
      this.v$.$touch();
      this.validate();
    },
    updateState(state) {
      this.value.state = state;
    },
    turnFeeOff(turnOff) {
      this.byPassFee =
        this.value.paymentTypeSelected.value === "usCreditCard" && turnOff;
      this.calculateAmount();
    },
    updateItems(items) {
      this.value.items = items;
      this.calculateAmount();
    },
    getTotal() {
      return this.value.items
        .reduce((total, item) => Number(total) + Number(item.balance), 0)
        .toFixed(2);
    },
    cleanPayments() {
      localStorage.removeItem("payment-backup");
      this.$store.commit("cleanPaymentsToPay");
      this.$router.push(
        { name: "applications/landing" },
        { params: { applicationId: this.applicationId } }
      );
    },
    calculateAmount() {
      this.value.total = this.getTotal();
      this.value.convenienceFee = false;
      this.value.achFee = false;
      this.value.total = Number(this.getTotal());
      if (
        this.value.paymentTypeSelected?.stripe === "card" &&
        !this.byPassFee
      ) {
        this.value.convenienceFee = !this.byPassFee
          ? (
              Number(this.value.total) *
              Number(this.value.paymentTypeSelected.feeFactor)
            ).toFixed(2)
          : 0;
        this.value.total = (
          this.value.total + Number(this.value.convenienceFee)
        ).toFixed(2);
      } else if (this.value.paymentTypeSelected?.stripe === "us_bank_account") {
        this.value.achFee = 3;
        this.value.total = (
          this.value.total + Number(this.value.achFee)
        ).toFixed(2);
      }
    },
    normalizeItems(items) {
      let normalizedItems = items.map((item) => {
        // v2 field names
        if (!this.isV3) {
          return {
            name: item.name,
            amount: dollarsToCents(item.balance),
            sku: item.sku ?? null,
            invoice_id: item.invoice ?? null,
            line_item_id: item.line_item_id ?? null,
          };
        }

        // v3 field names
        return {
          product_name: item.name,
          product_amount: dollarsToCents(item.balance),
          product_sku: item.sku ?? null,
          invoice_id: item.invoice ?? null,
          line_item_id: item.line_item_id ?? null,
        };
      });
      if (this.value.convenienceFee) {
        normalizedItems.push(
          this.isV3
            ? {
                product_name: "Convenience Fee *",
                product_amount: dollarsToCents(this.value.convenienceFee),
                product_sku: this.value.paymentTypeSelected.sku,
              }
            : {
                name: "Convenience Fee *",
                amount: dollarsToCents(this.value.convenienceFee),
                sku: this.value.paymentTypeSelected.sku,
              }
        );
      }
      if (this.value.achFee) {
        normalizedItems.push(
          this.isV3
            ? {
                product_name: "ACH Fee *",
                product_amount: dollarsToCents(this.value.achFee),
                product_sku: this.value.paymentTypeSelected.sku,
              }
            : {
                name: "ACH Fee *",
                amount: dollarsToCents(this.value.achFee),
                sku: this.value.paymentTypeSelected.sku,
              }
        );
      }
      return normalizedItems;
    },
    getPayload() {
      return {
        line_items: this.normalizeItems(this.v$.value.items.$model),
        success_url: this.successURL,
        cancel_url: window.location.href,
        payment_method: this.value.paymentTypeSelected.stripe,
        application_id: this.applicationId,
        legacy_participant_account_id: this.customer_id,
        email: this.currentUser?.email,
        participant_id: this.currentUser?.participantId ?? "",
        order_id: this.orderId,
        card_billing_state: this.value.state,
        ...this.v2Fields,
      };
    },
    handleContinueButton() {
      if (this.duplicatePayments.length > 0) {
        this.isModalOpen = true;
      } else {
        this.continuePayment();
      }
    },
    async continuePayment() {
      this.isModalOpen = false;
      this.checkItemsToPay();
      let payload = this.getPayload();
      localStorage.setItem(
        "payment-details",
        JSON.stringify(payload.line_items)
      );
      if (!this.hasErrors && !this.v$.$invalid) {
        this.$refs.sendRequestBtn.startLoading();
        await axios
          .post(`${this.baseUrl}/payment-session`, payload)
          .then((response) => response.data)
          .then((data) => {
            localStorage.setItem(
              "payment-details",
              JSON.stringify(payload.line_items)
            );
            localStorage.setItem(
              "payment-backup",
              JSON.stringify({
                items: this.withoutFees,
                paymentType: this.value.paymentTypeSelected,
                state: this.value.state,
              })
            );
            window.location.href = data.url;
          })
          .catch((e) => {
            this.$refs.sendRequestBtn.stopLoading();
            this.error = e.message;
          });
      }
    },
  },
};
</script>
