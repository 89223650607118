import store from "@/store/index.js";
import {
  GROUP_VISA_ALLOWED_COUNTRIES,
  GV_ALLOWED_COUNTIES_NEW_JERSEY,
  GV_ALLOWED_STATES_ITALY,
  GV_ALLOWED_STATES_SPAIN,
  GV_ALLOWED_STATES_SPAIN_2,
} from "@/constants.js";

/**
 * Creates an application id based on program session id, saves it into vuex too.
 * @param {String} programSessionId -> The program session id that comes from Program OR Learning Services
 * @returns {String} 32 character string
 */
export function V3_generateApplicationId(programSessionId = "") {
  let removedHyphens = programSessionId.replace(/-/g, "");
  let appId = (
    Date.now() +
    removedHyphens +
    Math.random().toString().substring(3)
  ).substring(0, 32);
  store.commit("setCurrentApplicationId", appId);
  return appId;
}

/**
 * Iterates through a list of steps and determines:
 * Which ones should be shown - if they shouldn't be shown they're removed/spliced
 * Which ones are completed
 * Which ones can be interacted with
 * @param {Array} steps -> A list of step objects for SteppedProcess component, these can be editable
 * @param {Array} originalSteps -> A list of step objects that aren't editable, used for reference later
 * @param {Boolean} fetchSteps -> A flag that will do an api call to return the latest status of each step
 * @param {String} applicationId -> 32 character string
 * @returns {Array} A list of step objects
 */
export async function V3_setUpSteps(
  steps = [],
  originalSteps = [],
  fetchSteps = false,
  applicationId = ""
) {
  // Get latest steps data if user has no data or flag is active
  if (
    (store.getters.getCurrentUser &&
      store.getters.getStepsInV3[0].data === null) ||
    fetchSteps
  ) {
    await store.dispatch("getV3Steps", applicationId);
  }

  // Steps to remove
  // Account Creation - Remove if student is not applying for the first time
  let showAccountCreation = await V3_stepShouldBeShown("AccountCreation");
  if (!showAccountCreation) {
    let accountStep = find_stepIndex(steps, "new-account-creation");
    if (accountStep > -1) {
      steps.splice(accountStep, 1);
    }
  }

  // Group Visa - Remove if student doesn't need to complete form otherwise add it back if it was removed previously
  let showGroupVisa = await V3_stepShouldBeShown("GroupVisa");
  let visaStep = find_stepIndex(steps, "application-group-visa");
  if (showGroupVisa && visaStep === -1) {
    // If step doesn't exists we'll add it before Checkout
    let checkoutStep = find_stepIndex(steps, "application-checkout");
    let newVisaStep = find_stepIndex(originalSteps, "application-group-visa");
    if (checkoutStep > -1 && newVisaStep > -1) {
      steps.splice(checkoutStep, 0, originalSteps[newVisaStep]);
    }
  } else if (!showGroupVisa) {
    // Remove step (If it exists)
    if (visaStep > -1) steps.splice(visaStep, 1);
  }

  // Next Steps - Remove if checkout isn't complete, add it otherwise
  let showNextSteps = await V3_stepShouldBeShown("NextSteps");
  let nextStepsStep = find_stepIndex(steps, "application-next-steps");
  if (showNextSteps && nextStepsStep === -1) {
    let newNextStepsStep = find_stepIndex(
      originalSteps,
      "application-next-steps"
    );
    if (newNextStepsStep > -1) {
      // Add step at the end
      steps.push(originalSteps[newNextStepsStep]);
    }
  } else if (!showNextSteps) {
    if (nextStepsStep > -1) steps.splice(nextStepsStep, 1);
  }

  // Steps completed - Only check completion for logged in users
  if (store.getters.getCurrentUser) {
    // Account Creation
    let accountStep = find_stepIndex(steps, "new-account-creation");
    if (accountStep > -1) {
      let completed = store.getters.getStepsInV3[0]["completed"];
      steps[accountStep]["isComplete"] = () => completed;
    }

    // About You
    let aboutYouStep = find_stepIndex(steps, "application-about-you");
    if (aboutYouStep > -1) {
      let completed = store.getters.getStepsInV3[1]["completed"];
      steps[aboutYouStep]["isComplete"] = () => completed;
    }

    // Learner Information
    let learnerInfoStep = find_stepIndex(steps, "application-learner-info");
    if (learnerInfoStep > -1) {
      let completed = store.getters.getStepsInV3[2]["completed"];
      steps[learnerInfoStep]["isComplete"] = () => completed;
    }

    // Review
    let reviewStep = find_stepIndex(steps, "application-review");
    if (reviewStep > -1) {
      let completed = store.getters.getStepsInV3[3]["completed"];
      steps[reviewStep]["isComplete"] = () => completed;
    }

    // Group Visa
    let visaStep = find_stepIndex(steps, "application-group-visa");
    if (visaStep > -1) {
      let completed = store.getters.getStepsInV3[4]["completed"];
      steps[visaStep]["isComplete"] = () => completed;
    }

    // Checkout
    let checkoutStep = find_stepIndex(steps, "application-checkout");
    if (checkoutStep > -1) {
      let completed = store.getters.getStepsInV3[5]["completed"];
      steps[checkoutStep]["isComplete"] = () => completed;
    }

    // Next Steps
    let nextStepsStep = find_stepIndex(steps, "application-next-steps");
    if (nextStepsStep > -1) {
      let completed = store.getters.getStepsInV3[6]["completed"];
      steps[nextStepsStep]["isComplete"] = () => completed;
    }
  }

  // Steps selectable - They can click to return to them
  if (store.getters.getCurrentUser) {
    let aboutYouStep = find_stepIndex(steps, "application-about-you");
    if (aboutYouStep > -1) {
      let canReturn = await V3_stepCanBeReturned("AboutYou");
      steps[aboutYouStep]["isSelectable"] = () => canReturn;
    }

    let learnerInfoStep = find_stepIndex(steps, "application-learner-info");
    if (learnerInfoStep > -1) {
      let canReturn = await V3_stepCanBeReturned("LearnerInfo");
      steps[learnerInfoStep]["isSelectable"] = () => canReturn;
    }

    let reviewStep = find_stepIndex(steps, "application-review");
    if (reviewStep > -1) {
      let canReturn = await V3_stepCanBeReturned("Review");
      steps[reviewStep]["isSelectable"] = () => canReturn;
    }

    let visaStep = find_stepIndex(steps, "application-group-visa");
    if (visaStep > -1) {
      let canReturn = await V3_stepCanBeReturned("GroupVisa");
      steps[visaStep]["isSelectable"] = () => canReturn;
    }

    let checkoutStep = find_stepIndex(steps, "application-checkout");
    if (checkoutStep > -1) {
      let canReturn = await V3_stepCanBeReturned("Checkout");
      steps[checkoutStep]["isSelectable"] = () => canReturn;
    }

    let nextStepsStep = find_stepIndex(steps, "application-next-steps");
    if (nextStepsStep > -1) {
      let canReturn = await V3_stepCanBeReturned("NextSteps");
      steps[nextStepsStep]["isSelectable"] = () => canReturn;
    }
  }
  return steps;
}

/**
 * Navigation guard: Checks if user should be logged in
 * @returns {Boolean | Object} Boolean true will continue navigation, the object will redirect to the route set.
 */
export function V3_rejectNavigation() {
  if (!store.getters.getCurrentUser) {
    return { name: "landing-page" };
  }
  return true;
}

/**
 * Navigation guard: Only unauthenticated users can see thi
 * @returns {Boolean | Object} Boolean true will continue navigation, the object will redirect to the route set.
 */
export function V3_rejectNavigation_no_auth() {
  if (store.getters.getCurrentUser) {
    // Flag not active, go back
    return { name: "landing-page" };
  }
  return true;
}

export function V3_rejectNavigation_no_program_session() {
  if (!store.getters.isLoggedIn) {
    if (
      !store.state?.configurator?.currentOrder?.program_page_id ||
      !store.state?.configurator?.currentOrder?.session_id
    ) {
      return { name: "home" };
    }

    return true;
  }

  return true;
}

/**
 * Check if step should be shown
 * @param {String} step_name The name of the step we're trying to check, each name has a different way to check if it will be shown
 * @returns {Boolean}
 */
export async function V3_stepShouldBeShown(step_name = "") {
  let stepShouldBeShown = false;
  switch (step_name) {
    case "AccountCreation": {
      stepShouldBeShown = store.getters.getNewAccountAndApplication === true;
      break;
    }
    case "GroupVisa": {
      let session_requires_visa =
        store.getters.getProgramSessionDataV3?.visa_required ?? false;

      // If the session doesn't require visa, all the following logic is irrelevant
      if (!session_requires_visa) break;

      let user_country =
        store.getters.getProfileData?.current_address_country_id ?? "";
      let user_country_permanent =
        store.getters.getProfileData?.permanent_address_country_id ?? "";
      let state_current_address =
        store.getters.getProfileData?.current_address_state ?? "";
      let state_permanent_address =
        store.getters.getProfileData?.permanent_address_state ?? "";
      // TODO: What happens if session_travel_details > 1?
      let session_country =
        store.getters.getProgramSessionDataV3?.session_travel_details?.[0]
          ?.arrival_city?.country?.id ?? "";

      let current_address_county =
        store.getters.getProfileData
          ?.current_address_administrative_area_level_2 ?? "";
      let permanent_address_county =
        store.getters.getProfileData
          ?.permanent_address_administrative_area_level_2 ?? "";

      const newJerseyCounties = new Set(GV_ALLOWED_COUNTIES_NEW_JERSEY);
      const currentAddressIsNewJerseyAndHasCountyAndSessionCountryIsItaly =
        state_current_address === "New Jersey" &&
        newJerseyCounties.has(current_address_county) &&
        session_country === "IT";
      const permanentAddressIsNewJerseyAndHasCountyAndSessionCountryIsItaly =
        state_permanent_address === "New Jersey" &&
        newJerseyCounties.has(permanent_address_county) &&
        session_country === "IT";

      // Program Session requires Visa and matches list of allowed countries
      if (GROUP_VISA_ALLOWED_COUNTRIES.includes(session_country)) {
        // Program Session country is Italy and state is either...
        if (
          session_country === "IT" &&
          (GV_ALLOWED_STATES_ITALY.includes(state_current_address) ||
            GV_ALLOWED_STATES_ITALY.includes(state_permanent_address))
        ) {
          stepShouldBeShown = true;
        }

        // Program Session country is Spain and state is either...
        if (
          session_country === "ES" &&
          (GV_ALLOWED_STATES_SPAIN.includes(state_current_address) ||
            GV_ALLOWED_STATES_SPAIN.includes(state_permanent_address) ||
            GV_ALLOWED_STATES_SPAIN_2.includes(state_current_address))
        ) {
          stepShouldBeShown = true;
        }
      }

      if (
        [state_current_address, state_permanent_address].includes("New York") &&
        session_country === "IT"
      ) {
        stepShouldBeShown = true;
      }

      if (
        [user_country, user_country_permanent].includes("BM") &&
        session_country === "IT"
      ) {
        stepShouldBeShown = true;
      }

      if (
        permanentAddressIsNewJerseyAndHasCountyAndSessionCountryIsItaly ||
        currentAddressIsNewJerseyAndHasCountyAndSessionCountryIsItaly
      ) {
        stepShouldBeShown = true;
      }
      break;
    }
    case "NextSteps": {
      stepShouldBeShown = store.getters.getStepsInV3[5].completed;
      break;
    }
  }
  return stepShouldBeShown;
}

/**
 * Check if step can be clicked
 * clicked = return to step
 * @param {String} step_name The name of the step we're trying to check, each name has a different way to check if it can clicked
 * @returns {Boolean}
 */
export async function V3_stepCanBeReturned(step_name = "") {
  let stepCanReturn = false;
  switch (step_name) {
    case "AccountCreation": {
      // NEVEER
      break;
    }
    case "AboutYou": {
      // Allow to return if step was completed or is next step
      // 0 = account creation, 1 = about you
      stepCanReturn =
        store.getters.getStepsInV3[1].completed ||
        (store.getters.getStepsInV3[0].completed &&
          !store.getters.getStepsInV3[1].completed);
      break;
    }
    case "LearnerInfo": {
      // Allow to return if step was completed or is next step
      // 1 = about you, 2 = learner info
      stepCanReturn =
        store.getters.getStepsInV3[2].completed ||
        (store.getters.getStepsInV3[1].completed &&
          !store.getters.getStepsInV3[2].completed);
      break;
    }
    case "Review": {
      // Allow to return if step was completed or is next step but hasn't completed checkout
      // 3 = review, 5 = checkout, 2 = learner info

      stepCanReturn =
        store.getters.getStepsInV3[3].completed ||
        (store.getters.getStepsInV3[2].completed &&
          !store.getters.getStepsInV3[3].completed &&
          !store.getters.getStepsInV3[5].completed);
      break;
    }
    case "GroupVisa": {
      // Allow to return if step was completed or is next step but hasn't completed checkout
      // 3 = review, 5 = checkout, 4 = group visa
      let showGroupVisa = await V3_stepShouldBeShown("GroupVisa");
      stepCanReturn =
        showGroupVisa &&
        store.getters.getStepsInV3[3].completed &&
        !store.getters.getStepsInV3[5].completed &&
        (store.getters.getStepsInV3[4].completed ||
          !store.getters.getStepsInV3[4].completed);
      break;
    }
    case "Checkout": {
      let showGroupVisa = await V3_stepShouldBeShown("GroupVisa");
      if (store.getters.getStepsInV3[5].completed) {
        // Return if checkout already completed
        // 4 = group visa, 3 = review, 5 = checkout
        stepCanReturn = true;
      } else if (showGroupVisa) {
        // Return if group visa, review are complete but not Checkout
        // 4 = group visa, 3 = review, 5 = checkout
        stepCanReturn =
          store.getters.getStepsInV3[4].completed &&
          store.getters.getStepsInV3[3].completed &&
          !store.getters.getStepsInV3[5].completed;
      } else {
        // Return if review is complete but not checkout
        stepCanReturn =
          store.getters.getStepsInV3[3].completed &&
          !store.getters.getStepsInV3[5].completed;
      }
      break;
    }
    case "NextSteps": {
      // Return if step is complete or is next step
      // 5 = checkout, 6 = next steps
      stepCanReturn =
        store.getters.getStepsInV3[5].completed ||
        store.getters.getStepsInV3[6].completed;
      break;
    }
  }
  return stepCanReturn;
}

/**
 * Find index of an item on a list based on the slug property
 * @param {Array} steps The list to search in [haystack]
 * @param {String} stepSlug The name of the step we're trying to find [needle]
 * @returns {number}
 */
function find_stepIndex(steps = [], stepSlug = "") {
  return steps.findIndex((step) => step.slug === stepSlug);
}
