<template>
  <div v-if="showSidebar" class="hidden h-full">
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog
        as="div"
        class="relative z-20 md:hidden"
        @close="mobileMenuOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-1 flex-col bg-[#1f264c] pt-5 pb-4"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-1 right-0 -mr-14 p-1">
                  <button
                    type="button"
                    class="flex h-6 w-6 items-center justify-center rounded-full"
                    @click="mobileMenuOpen = false"
                  >
                    <XMarkIcon
                      class="h-[8px] w-[8px] text-white"
                      aria-hidden="true"
                    />
                    <span class="sr-only">Close sidebar</span>
                  </button>
                </div>
              </TransitionChild>
              <div class="flex flex-shrink-0 items-center px-4">
                <img
                  class="h-8 w-auto text-white"
                  :src="logo"
                  alt="API Connect logo"
                />
              </div>
              <div class="mt-5 h-0 flex-1 overflow-y-auto px-2">
                <nav class="flex h-full flex-col">
                  <div class="space-y-1">
                    <div v-for="item in items" :key="item.id">
                      <router-link
                        v-if="item.featureFlagOn"
                        v-slot="{ isActive }"
                        :key="item.id"
                        :to="{ name: item.name }"
                        :data-testid="item.name"
                      >
                        <div
                          :class="[
                            isActive
                              ? 'text-black bg-white'
                              : 'text-white hover:bg-[#494e6d]',
                            'group py-2 px-3 rounded-md flex items-center text-sm font-medium',
                          ]"
                          :aria-current="isActive ? 'page' : undefined"
                        >
                          <img
                            :src="
                              isActive ? item.icons.active : item.icons.default
                            "
                            class="w-6 h-6 mr-2 object-contain mb-1"
                          />
                          <span>{{ item.meta.displayName }}</span>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
</script>

<script>
import { eventBus } from "@/app";
import { sidebarMixin } from "@/mixins/sidebar.js";

export default {
  name: "MobileSideBar",
  mixins: [sidebarMixin],
  props: {
    logo: {
      type: String,
      default: localStorage.logoUrl,
    },
  },
  data() {
    return {
      mobileMenuOpen: false,
    };
  },
  created() {
    eventBus.$on("toggleMobileSidebar", () => {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    });
  },
};
</script>
