<template>
  <div
    :id="'unit-' + props.unit.id"
    class="font-montserrat my-4 mb-8 border border-light-gray card-box-shadow md:flex md:items-stretch"
  >
    <div class="h-full md:w-3/6 md:flex-grow">
      <div
        class="flex p-3 justify-between items-baseline"
        :class="[secondaryColor ? `bg-gray-275` : `bg-teal-accent`]"
        >
        <p :id="'type-' + props.unit.id" class="uppercase text-sm font-bold">
          {{ housingType }}
        </p>
        <p
          :id="'tier-' + props.unit.id"
          class="uppercase text-xs font-bold border py-1 px-8 flex justify-center items-center leading-none"
        >
          {{ tier }}
        </p>
      </div>
      <ImageSlider
        :id="'image-slider-' + props.unit.id"
        :image-paths="imagePaths"
        :use-aspect-ratio="true"
        :use-image-compression="true"
      />
    </div>

    <div
      class="flex flex-col justify-between m-4 md:flex-shrink md:w-3/6 gap-4"
    >
      <div>
        <div v-if="price" class="md:text-right">
          <span
            :id="'price-' + props.unit.id"
            :class="`inline-block uppercase ${secondaryColorClass} font-bold text-sm`"
          >
            {{ price }}
          </span>
        </div>
        <div class="flex flex-col gap-2">
          <div
            v-if="greatFor"
            :id="'great-for-' + props.unit.id"
            class="text-sm"
          >
            Great for: <span class="capitalize">{{ greatFor }}</span>
          </div>
          <div
            v-if="name"
            :id="'name-' + props.unit.id"
            class="text-2xl font-bold leading-none"
          >
            {{ name }}
          </div>
          <div
            v-if="tagsToShow?.length"
            :id="'tags-to-show-' + props.unit.id"
            class="text-sm whitespace-pre-wrap"
          >
            {{ tagsToShow.join(" | ") }}
          </div>
          <div
            v-if="nonGenericListing?.length"
            :id="'non-generic-listing-' + props.unit.id"
            class="text-sm whitespace-pre-wrap"
          >
            {{ nonGenericListing.join("  |  ") }}
          </div>
          <div
            v-if="housingAmenities?.length"
            :id="'housing-amenities-' + props.unit.id"
            class="text-sm whitespace-pre-wrap"
          >
            <span class="font-bold">Perks: </span>
            {{ housingAmenities.slice(0, 4).join(", ") }}
          </div>
        </div>
      </div>
      <div
        class="flex flex-row justify-around md:justify-end gap-2 align-baseline md:gap-2"
      >
        <button
          :id="'view-details-button-' + props.unit.id"
          :data-testid="'view-details-button-' + props.index"
          :class="`uppercase font-bold flex items-center ${secondaryColorClass} p-1 md:p-4 cursor-pointer`"
          @click="redirectToViewPage(props.unit)"
        >
          <span class="inline-block">View Details</span>
          <div class="inline-block ml-3 py-1">
            <svg
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -1.01583e-06L-7.14641e-07 1.71183L5.38866 7.00874Z"
                :fill="secondaryColor ? `${secondaryColor}` : `#007F80`"
              />
            </svg>
          </div>
        </button>
        <button
          :id="'select-unit-button-' + props.unit.id"
          :data-testid="'select-unit-button-' + props.index"
          class="border-2 p-3 md:p-4 uppercase font-bold flex items-center relative"
          :class="[
            props.disabled ?
              `cursor-not-allowed bg-gray-275 text-gray-485 border-gray-485` :
              (props.selected ? `${secondaryColorClassBG} text-white` : `${secondaryColorClassOutlinedNoHover}`)
          ]"
          :disabled="props.disabled && !props.selected"
          @click="
            () => {
              selectUnit(props.unit);
              scrollUp();
            }
          "
        >
          <span class="inline-block">
            {{ UnitSelection }}
          </span>
          <div class="inline-block ml-3 py-1">
            <svg
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -1.01583e-06L-7.14641e-07 1.71183L5.38866 7.00874Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div
            v-if="props.selected && !disabledDeselect"
            class="absolute -top-3 -right-3"
          >
            <MinusSignCircle />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import ImageSlider from "@/components/Configurator/widgets/ImageSlider.vue";
import { computed, defineProps, inject } from "vue";
import { useRoute } from "vue-router";
import MinusSignCircle from "../widgets/MinusSignCircle.vue";
import { getRoomPriceLabelFromList } from "@/components/Configurator/pricing";
const props = defineProps({
  unit: {
    type: Object,
    default: () => ({}),
  },
  defaultRoom: {
    type: Object,
    default: () => ({}),
  },
  selected: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  roomPrices: {
    type: Object,
    default: () => {},
  },
  index:{
    type: Number,
    default: 0,
  }
});

const selectUnit = inject("selectUnit");
const { program } = inject("program");
const disabledDeselect = inject("disabledDeselect");
const { configuratorOptions } = inject("configuratorOptions");

const route = useRoute();

/*
 * computed properties
 */
const housing = computed(() => props.unit?.housings || {});
const imagePaths = computed(() => housing.value?.supporting_media || []);
const housingType = computed(() => {
  if (housing.value?.subcategory === "Residencia") {
    return "Residencia";
  } else {
    return housing.value?.type || "";
  }
});
const name = computed(() => props.unit?.name || "");
const tagsToShow = computed(() => {
  return tags.value.length > 1 ? tags.value?.slice(1, tags.value.length) : [];
});
const tier = computed(() => {
  return {
    "Tier I": "Basic",
    "Tier II": "Classic",
    "Tier III": "Comfort",
  }[housing.value?.tier?.name || "Tier I"];
});
const tags = computed(() => {
  return housing.value?.tags?.map((item) => item.name) ?? [];
});
const greatFor = computed(() => {
  const goodForCategory = "1a506857-58aa-449f-b39e-811a1f9b3ed3";
  const { tags = [] } = housing.value || {};
  const goodForTags = tags
    .filter((item) => item.category_id === goodForCategory)
    .map((item) => item.name)
    .sort();
  return goodForTags[0] || "";
});
const housingAmenities = computed(() => {
  return housing.value?.housing_amenities
    ? housing.value.housing_amenities.map((item) => item.title)
    : [];
});
const nonGenericListing = computed(() => {
  if (housing.value?.generic_listing) {
    return [];
  } else {
    let items = [];
    if (props.unit?.floor_plan) items.push(props.unit.floor_plan);
    if (props.unit?.full_bath_count)
      items.push(`${props.unit.full_bath_count} full bath`);
    if (props.unit?.half_bath_count)
      items.push(`${props.unit.half_bath_count} half bath`);
    if (props.unit?.kitchen_type) items.push(props.unit.kitchen_type);

    return items;
  }
});

const price = computed(() => getRoomPriceLabelFromList(
  props.unit,
  configuratorOptions.rooms
));

const customButtonClass = computed(() => {
  const border = "border-teal-900";

  if (props.selected) {
    return `bg-teal-900 text-white ${border}`;
  } else {
    return props.disabled
      ? "cursor-not-allowed bg-gray-275 text-gray-485 border-gray-485"
      : `text-teal-900 ${border}`;
  }
});

const UnitSelection = computed(() => {
  return props.selected ? "Selected" : "Select Room";
});

const checkIfDeviceIsMobile = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  return screenWidth < 900;
};

const scrollUp = () => {
  if (checkIfDeviceIsMobile()) {
    const element = document.getElementById("process-steps");
    if (element) {
      element.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  } else {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }
};

const redirectToViewPage = (unit) => {
  sessionStorage.setItem("loadingHousingData", "true");

  const queries = {
    "housing-id": unit.housing_id,
    "program-id": route.params?.programPageId,
    "session-id": program.programSession.id,
    "unit-id": unit.id,
  };

  selectUnit(unit);
  scrollUp();

};
</script>

<style scoped>
.scoped-translate-up {
  transform: translateY(-1rem);
}
</style>
