<template>
  <div
    v-for="(question, index) in props.questions"
    :key="index"
    :class="question?.fieldsBelowLabel ? 'mb-4' : 'mb-12'"
  >
    <template
      v-if="question.show || (question.isLocalHost && props.isLocalHost)"
    >
      <h2
        v-if="question?.title"
        class="uppercase text-lg font-bold text-indigo-base mb-4"
      >
        {{ question?.title }}
      </h2>
      <fieldset v-if="question.type !== 'textarea'">
        <legend v-if="question?.question" class="mb-1 text-lg font-medium">
          {{ question?.question }}
          <p v-if="question?.boldText" class="text-lg font-bold">
            {{ question?.boldText }}
          </p>
        </legend>
        <p
          v-if="
            question?.model === 'housingRequirements' ||
            question?.model === 'smoking'
          "
          class="mb-1"
        >
          {{ question.secondaryText }}
        </p>
        <template v-if="question.type === 'checkbox'">
          <div
            v-for="option in question.options"
            :key="option.model"
            class="mt-6"
          >
            <Checkbox
              v-if="question?.model"
              :id="option.model"
              v-model.trim="v$[question?.model][option.model].$model"
              :label="option.label"
              :vuelidate-field="v$[question?.model][option.model]"
              :vuelidate-parent="v$[question?.model]"
              :disabled="disabled"
            />
            <Checkbox
              v-else
              :id="option.model"
              v-model.trim="v$[option.model].$model"
              :label="option.label"
              :vuelidate-field="v$[option.model]"
              :disabled="disabled"
            />

            <ApiTextArea
              v-if="
                option?.other &&
                (v$[option.model]?.$model ||
                  (question?.model &&
                    v$[question?.model][option.model]?.$model))
              "
              :ref="option?.other"
              :name="option?.other"
              :max-length="200"
              :validation-errors="v$[option?.other]"
              :disabled="disabled"
              custom-error-class="text-sm"
              label-span-custom-class="text-lg font-medium"
              @text-update="onFieldChange(option?.other, $event)"
            />
          </div>
          <p
            v-for="error of v$[question?.model]?.$errors"
            :key="error.$uid"
            class="error text-error-900 text-sm"
          >
            {{ error.$message }}
          </p>
        </template>
        <template v-else-if="question.type === 'radio'">
          <div
            v-for="option in question.options"
            :key="question.model + option"
            class="mt-6"
          >
            <label class="flex" :for="question.model + option">
              <input
                :id="question.model + option"
                v-model="v$[question.model].$model"
                type="radio"
                :name="question.model"
                :value="option"
                class="min-w-[20px] min-h-[20px] border-gray-300 text-indigo-base focus:ring-indigo-base"
                :class="[primaryColorClass, `${secondaryColorClassAccent}`]"
                :disabled="disabled"
              />
              <span class="ml-2 text-lg font-medium">
                {{ option }}
              </span>
            </label>
          </div>
          <p
            v-for="error of v$[question.model].$errors"
            :key="error.$uid"
            class="error text-error-900 text-sm"
          >
            {{ error.$message }}
          </p>
        </template>
      </fieldset>

      <template v-else-if="question.type === 'textarea'">
        <ApiTextArea
          :ref="question.model"
          :name="question.model"
          :label="question.question"
          :max-length="200"
          :validation-errors="v$[question.model]"
          custom-error-class="text-sm"
          :typed-text="v$[question.model].$model"
          :disabled="disabled"
          label-span-custom-class="text-lg font-medium"
          @text-update="onFieldChange(question.model, $event)"
        />
      </template>
    </template>
  </div>
</template>

<script setup>
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { defineEmits, defineProps, reactive, toRefs, watch } from "vue";
import ApiTextArea from "@/components/forms/SharedComponents/ApiTextArea.vue";
import Checkbox from "./HousingCheckbox.vue";
import useVuelidate from "@vuelidate/core";

const emit = defineEmits(["setQuestionnaireData"]);
const props = defineProps({
  isLocalHost: {
    type: Boolean,
    default: false,
  },
  questions: {
    type: Array,
    default: () => [],
  },
  previousQuestionnaire: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { isLocalHost, previousQuestionnaire, disabled } = toRefs(props);

const requiredCopy = "This field is required.";
const oneRequired = "At least one item is required";

const validateCheckbox = (data) => (value, siblings) => {
  let valid = false;

  // If the validations depends on other siblings field and is set to false skip validation and return true
  if (data?.parent && !siblings[data?.parent]) return true;

  // If the validations depends on other value and is set to false skip validation and return true
  if (Object.hasOwnProperty.call(data, "propValue") && !data.propValue)
    return true;

  let question = props.questions.find(
    (question) => question.model === data.main
  );

  for (const obj of question.options) {
    if (value[obj.model]) {
      valid = true;
      break;
    }
  }
  return valid;
};
const validateNonSmoking = (value, siblings) => {
  if (siblings.smoking !== "I smoke cigarettes.") return true;
  return value;
};

const form = reactive({
  describeMyself: {
    stayUpLate: false,
    getUpEarly: false,
    spacesClean: false,
    messy: false,
    chill: false,
    goOut: false,
    loveNightLife: false,
  },
  medicalCondition: false,
  housingRequirements: {
    privateRoom: false,
    airConditioning: false,
    elevator: false,
    wheelchairAccessible: false,
    otherMedicalCondition: false,
  },
  otherMedicalConditionText: "",
  comfortableLivingIn: {
    singleGenderRoomAndUnit: false,
    singleGenderRoomInMixedGenderUnit: false,
    genderInclusiveRoomAndUnit: false,
  },
  smoking: null,
  nonSmoking: false,
  dietaryRestrictions: {
    noneDietaryRestrictions: false,
    vegetarian: false,
    vegan: false,
    kosher: false,
    halal: false,
    glutenFree: false,
    otherDietaryRestriction: false,
  },
  otherDietaryRestrictionText: "",
  livingWithPets: null,
  localHostMatch: "",
  additionalHousingRequest: "",
  version: "2",
});
const rules = {
  describeMyself: {
    stayUpLate: {},
    getUpEarly: {},
    spacesClean: {},
    messy: {},
    chill: {},
    goOut: {},
    loveNightLife: {},
    required: helpers.withMessage(
      oneRequired,
      validateCheckbox({ main: "describeMyself" })
    ),
  },
  medicalCondition: {},
  housingRequirements: {
    privateRoom: {},
    airConditioning: {},
    elevator: {},
    wheelchairAccessible: {},
    otherMedicalCondition: {},
    required: helpers.withMessage(
      oneRequired,
      validateCheckbox({
        main: "housingRequirements",
        parent: "medicalCondition",
      })
    ),
  },
  otherMedicalConditionText: {
    required: helpers.withMessage(
      requiredCopy,
      requiredIf(() => form.housingRequirements.otherMedicalCondition)
    ),
  },
  comfortableLivingIn: {
    singleGenderRoomAndUnit: {},
    singleGenderRoomInMixedGenderUnit: {},
    genderInclusiveRoomAndUnit: {},
    required: helpers.withMessage(
      oneRequired,
      validateCheckbox({ main: "comfortableLivingIn" })
    ),
  },
  smoking: {
    required: helpers.withMessage(requiredCopy, required),
  },
  nonSmoking: {
    required: helpers.withMessage(requiredCopy, validateNonSmoking),
  },
  dietaryRestrictions: {
    noneDietaryRestrictions: {},
    vegetarian: {},
    vegan: {},
    kosher: {},
    halal: {},
    glutenFree: {},
    otherDietaryRestriction: {},
    required: helpers.withMessage(
      oneRequired,
      validateCheckbox({
        main: "dietaryRestrictions",
        propValue: isLocalHost.value,
      })
    ),
  },
  otherDietaryRestrictionText: {
    required: helpers.withMessage(
      requiredCopy,
      requiredIf(() => form.dietaryRestrictions.otherDietaryRestriction)
    ),
  },
  livingWithPets: {
    required: helpers.withMessage(
      requiredCopy,
      requiredIf(() => isLocalHost.value)
    ),
  },
  localHostMatch: {},
  additionalHousingRequest: {},
};
const v$ = useVuelidate(rules, form);

const onFieldChange = (field, value) => {
  form[field] = value;
};

const normalizeData = () => {
  let data = [];

  props.questions.forEach((question) => {
    let obj = {
      question_text: question?.question || question?.title,
      model: question.model ?? "",
      level: "basics",
    };

    if (question.type === "radio" || question.type === "textarea") {
      obj.answer = form[question.model];
    } else if (question.type === "checkbox") {
      if (!question?.model) {
        const option = question.options[0];
        obj.question_text = option.label;
        obj.model = option.model;
        obj.answer = form[option.model];
      } else {
        obj.answers = question.options
          .filter((option) => form[question.model][option.model]) //Filter selected options
          .map((option) => {
            let answer = {
              answer_text: option.label,
              model: option.model,
            };
            if (option?.other) {
              answer.other = {
                text: form[option.other],
                model: option.other,
              };
            }
            return answer;
          });
      }
    }

    data.push(obj);
  });
  return data;
};

watch(
  () => form.medicalCondition,
  () => {
    if (!form.medicalCondition) {
      Object.keys(form.housingRequirements).forEach((key) => {
        form.housingRequirements[key] = false;
      });
    }
  }
);
watch(
  () => form.housingRequirements,
  () => {
    if (
      Object.values(form.housingRequirements).some((item) => item) &&
      !form.medicalCondition
    )
      form.medicalCondition = true;
  },
  { deep: true }
);

watch(
  () => form.smoking,
  () => {
    if (form.smoking !== "I smoke cigarettes.") form.nonSmoking = false;
  }
);
watch(
  () => form.nonSmoking,
  () => {
    if (form.nonSmoking && form.smoking !== "I smoke cigarettes.")
      form.smoking = "I smoke cigarettes.";
  }
);

watch(
  form,
  () => {
    emit("setQuestionnaireData", {
      propName: "questions",
      value: normalizeData(),
    });
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => previousQuestionnaire.value,
  (newVal) => {
    for (const obj of newVal.questions) {
      if (Object.hasOwnProperty.call(form, obj.model) && obj?.answer) {
        form[obj.model] = obj.answer;
      } else if (obj?.answers) {
        obj.answers.forEach((answer) => {
          if (
            Object.hasOwnProperty.call(form, obj.model) &&
            Object.hasOwnProperty.call(form[obj.model], answer.model)
          ) {
            form[obj.model][answer.model] = true;
          }

          if (
            answer?.other &&
            Object.hasOwnProperty.call(form, answer?.other?.model)
          ) {
            form[answer.other.model] = answer.other.text;
          }
        });
      }
    }

    if (newVal.questions.length) v$.value.$touch();
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>
