<script>
export default {
  name: "QuickFilter",
};
</script>

<script setup>
import { defineEmits } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import AlgoliaKeywordInput from "@/components/ExperiencePage/Algolia/widgets/AlgoliaKeywordInput.vue";
import AlgoliaMultiSelect from "@/components/ExperiencePage/Algolia/widgets/AlgoliaMultiSelect.vue";
import SearchIcon from "@/components/svg-icons/SearchIcon.vue";
import { useVModel } from "@vueuse/core/index";
import AlgoliaTreeSelect2 from "@/components/ExperiencePage/Algolia/widgets/AlgoliaTreeSelect2/index.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      keyword: "",
      programTypes: [],
      cities: [],
      timeframe: [],
    }),
  },
  algoliaConfiguration: { type: Object, required: true },
  currentSearchIndex: { type: Object, required: true },
});

const emit = defineEmits(["update:modelValue", "searchSubmitted"]);
const filters = useVModel(props, "modelValue", emit);

const sortBy = ["count"];

const submitSearch = () => {
  emit("searchSubmitted");
};
</script>
<template>
  <div class="bg-gray-75 p-12 px-6 sm:px-20 md:pb-14 font-montserrat">
    <p
      id="programTitle"
      class="text-4xl leading-2.625rem non-italic font-montserrat font-bold uppercase text-indigo-base mb-7 md:leading-xl-loose md:text-5.5xl"
    >
      Find the program<br />
      that's best for you
    </p>
    <div id="programSearchDescription" class="mb-16 md:mb-0 md:h-24">
      <p class="font-medium non-italic leading-8 text-indigo-base text-xl">
        Start your experiential learning journey with API and launch<br />
        your personal and professional growth
      </p>
    </div>
    <div
      id="programSearchFilters"
      class="grid grid-quickfilter items-end justify-center font-montserrat non-italic text-base leading-5 text-indigo-base max-w-1210p"
    >
      <AlgoliaKeywordInput
        :keywordValue="filters.keyword"
        @update:keyword-value="($event) => filters.keyword = $event"
        id="keyword-search"
        label="Keyword"
        type="text"
        class="exp-keyword-input h-13 mr-1 w-full md:max-w-260p max-h-13 bg-white border border-indigo-base pb-2 font-bold uppercase text-indigo-base focus:text-indigo-base placeholder-indigo-base placeholder-opacity-50 outline-none focus:ring-0"
        input-class="mr-1 w-full md:max-w-260p max-h-12 h-12 bg-white pl-5 pb-4 pt-4 font-bold uppercase text-indigo-base focus:text-indigo-base placeholder-indigo-base outline-none focus:ring-0 placeholder-opacity-50 outline-none focus:ring-0"
        hide-label
        :no-icon="true"
      />
      <div id="programSearchProgramInput" class="flex items-center w-full">
        <AlgoliaMultiSelect
          v-model="filters"
          filterName="programTypes"
          :current-search-index="props.currentSearchIndex"
          :algolia-configuration="props.algoliaConfiguration"
          :sort-by="sortBy"
          placeholder="Program Type"
          class="exp-multi-select w-full"
        />
      </div>
      <div id="programSearchLocationInput" class="flex items-center w-full">
        <AlgoliaMultiSelect
          v-model="filters"
          filterName="cities"
          :current-search-index="props.currentSearchIndex"
          :algolia-configuration="props.algoliaConfiguration"
          placeholder="Location"
          class="exp-multi-select w-full"
        />
      </div>
      <div
        id="programSearchTermInput"
        class="flex items-center justify-center w-full"
      >
        <AlgoliaTreeSelect2
          v-model="filters"
          filterName="areaOfStudy"
          :attribute="props.algoliaConfiguration.areaOfStudy"
          :algolia-configuration="props.algoliaConfiguration"
          placeholder="AREA OF STUDY"
          class="exp-multi-select w-full"
        />
      </div>
      <div :class="`hidden ${secondaryColorClass} lg:flex flex-col items-center w-full max-w-28 mt-21px`">
        <button
          id="programSearchSubmitButton"
          class="flex flex-col justify-center items-center p-2 gap-2.5 max-w-28"
          @click="submitSearch"
        >
          <SearchIcon />
        </button>
      </div>
    </div>
    <div :class="`flex lg:hidden ${secondaryColorClass} flex-col items-end w-full mt-[8px]`">
      <button
        class="flex flex-col justify-center items-center p-2 gap-2.5 max-w-28"
        @click="submitSearch"
      >
        <SearchIcon />
      </button>
    </div>
  </div>
</template>
