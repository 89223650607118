import { learningServiceRequest } from "./utils";
import { SUCCESSFULLY_LOAD } from "@/constants";

const termsService = {
  async list(search) {
    let response = await learningServiceRequest({
      method: "get",
      url: "/terms",
      params: { limit: 20, q: search },
      message: {
        success: SUCCESSFULLY_LOAD,
        failure: "Error loading Terms",
      },
    });

    return response?.data?.data;
  },
};

export default termsService;
