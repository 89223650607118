<template>
  <div class="mb-12">
    <h2 class="uppercase text-lg font-bold text-indigo-base mb-4">
      Do you have a roommate request? (Person or people with whom you’d like to
      share a room)
    </h2>

    <div class="space-y-6">
      <div v-for="option in ['Yes', 'No']" :key="'roommateRequest' + option">
        <label class="flex" :for="'roommateRequest' + option">
          <input
            :id="'roommateRequest' + option"
            v-model="v$.roommateRequest.$model"
            type="radio"
            name="roommateRequest"
            :value="option"
            class="h-5 w-5 border-gray-300 text-indigo-base focus:ring-indigo-base"
            :class="[primaryColorClass, `${secondaryColorClassAccent}`]"
            :disabled="disabled"
          />
          <span class="ml-2 text-lg font-medium">
            {{ option }}
          </span>
        </label>
      </div>
      <p
        v-for="error of v$.roommateRequest.$errors"
        :key="error.$uid"
        class="error text-error-900 text-sm"
      >
        {{ error.$message }}
      </p>
      <p
        v-for="error of v$.roommates.$errors"
        :key="error.$uid"
        class="error text-error-900 text-sm"
      >
        {{ error.$message }}
      </p>
      <template v-if="form.roommateRequest === 'Yes'">
        <RoommatesForm
          v-for="(item, index) in form.roommates"
          :key="index"
          :roommate-index="index"
          :roommate="item"
          :roommate-request="form.roommateRequest"
          :universities="universities"
          :disabled="disabled"
          @remove-roommate="removeRoommate(index)"
          @search="search"
          @update-roommate="updateRoommate($event, index)"
        />

        <div v-if="form.roommates.length < 10">
          <button
            class="flex items-center"
            :class="[
              secondaryColor
                ? `${secondaryColorClass}`
                : 'text-teal-900 hover:text-teal-900 focus:text-teal-900',
              { 'opacity-33 cursor-not-allowed': disabled },
            ]"
            :disabled="disabled"
            @click="addRoommate"
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                :stroke="[secondaryColor ? `${secondaryColor}` : '#007F80']"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
            </span>
            <span class="ml-2 text-lg font-medium text-indigo-base">
              Add Another
            </span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import RoommatesForm from "@/components/Configurator/Housing/RoommatesForm.vue";
import { entityTypes } from "@/components/program-manager/sessions/constants.js";
import entitiesService from "@/services/entities.js";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import {
  defineEmits,
  defineProps,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import { debounce } from "lodash";

const emit = defineEmits(["setQuestionnaireData"]);
const props = defineProps({
  previousQuestionnaire: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const { previousQuestionnaire, disabled } = toRefs(props);

const universities = reactive({
  list: [],
  loading: true,
});

const requiredCopy = "This field is required.";
const requiredIfYes = requiredIf(() => form.roommateRequest === "Yes");

const form = reactive({
  roommateRequest: null,
  roommates: [
    {
      firstName: null,
      lastName: null,
      email: null,
      homeUniversity: null,
      phone: "",
      phoneCode: "",
      isValidPhoneNumber: false,
    },
  ],
});
const rules = {
  roommateRequest: {
    required: helpers.withMessage(requiredCopy, required),
  },
  roommates: {
    required: helpers.withMessage(
      "At least one roommate is required.",
      requiredIfYes
    ),
  },
};
const v$ = useVuelidate(rules, form);

const clearRoommates = () => {
  if (form.roommateRequest === "No") {
    form.roommates = [
      {
        firstName: "",
        lastName: "",
        email: "",
        homeUniversity: null,
        phone: "",
        phoneCode: "",
        isValidPhoneNumber: false,
      },
    ];
  }
};
const addRoommate = () => {
  form.roommates.push({
    firstName: "",
    lastName: "",
    email: "",
    homeUniversity: null,
    phone: "",
    phoneCode: "",
    isValidPhoneNumber: false,
  });
};
const updateRoommate = (data, index) => {
  form.roommates[index] = data;
};
const removeRoommate = (index) => {
  form.roommates.splice(index, 1);
};
const universityParams = {
  account_types: [entityTypes.home_institution],
  field: "name",
  order: "ASC",
};
const loadUniversityList = async () => {
  universities.loading = true;
  entitiesService
    .getEntities({ extraParams: universityParams })
    .then(({ data }) => {
      universities.list = data?.data?.items ?? [];
    })
    .catch(() => (universities.list = []))
    .finally(() => (universities.loading = false));
};
const search = debounce(({ search, loading }) => {
  loading(true);
  entitiesService
    .getEntities({ search: search, extraParams: universityParams })
    .then(({ data }) => {
      universities.list = data?.data?.items ?? [];
    })
    .catch(() => (universities.list = []))
    .finally(() => loading(false));
}, 250);

const normalizeData = () => {
  if (form.roommateRequest === "No") return [];
  return form.roommates.map((item) => {
    return {
      first_name: item.firstName,
      last_name: item.lastName,
      email: item.email,
      home_university: item.homeUniversity,
      phone: item.phone,
      phoneCode: item.phoneCode,
      isValidPhoneNumber: item.isValidPhoneNumber,
    };
  });
};

watch(
  () => form.roommateRequest,
  () => {
    clearRoommates();
  }
);

watch(
  form,
  () => {
    emit("setQuestionnaireData", {
      propName: "roommateRequests",
      value: normalizeData(),
    });
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => previousQuestionnaire.value,
  (newVal) => {
    if (newVal.questions.length && !newVal.roommateRequests.length) {
      form.roommateRequest = "No";
      v$.value.$touch();
    } else if (newVal.roommateRequests.length) {
      form.roommates = newVal.roommateRequests.map((roommate) => ({
        firstName: roommate.first_name,
        lastName: roommate.last_name,
        email: roommate.email,
        homeUniversity: roommate.home_university,
        phone: roommate.phone,
        phoneCode: roommate.phoneCode,
        isValidPhoneNumber: roommate.isValidPhoneNumber,
      }));
      form.roommateRequest = "Yes";

      v$.value.$touch();
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

onMounted(() => {
  loadUniversityList();
});
</script>
