import { learningServiceRequest } from "./utils";

const entityVisibilityService = {
  async optIn(entityId, productIds) {
    return await learningServiceRequest({
      method: "delete",
      url: `/entity_program_visibility/bulk_delete/`,
      payload: {
        entity_id: entityId,
        product_ids: productIds,
      },
    });
  },

  async optOut(data) {
    return await learningServiceRequest({
      method: "post",
      url: `/entity_program_visibility/bulk_create/`,
      payload: {
        items: data,
      },
    });
  },
};

export default entityVisibilityService;
