<template>
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.0019 9.86525C8.4994 9.86525 8.92473 9.68808 9.2779 9.33375C9.63123 8.97942 9.8079 8.5535 9.8079 8.056C9.8079 7.5585 9.63073 7.13308 9.2764 6.77975C8.92206 6.42658 8.49606 6.25 7.9984 6.25C7.5009 6.25 7.07557 6.42717 6.7224 6.7815C6.36907 7.13583 6.1924 7.56183 6.1924 8.0595C6.1924 8.557 6.36957 8.98233 6.7239 9.3355C7.07823 9.68867 7.50423 9.86525 8.0019 9.86525ZM8.00015 17.5135C9.95648 15.7622 11.4536 14.0823 12.4914 12.474C13.5292 10.8657 14.0481 9.457 14.0481 8.248C14.0481 6.425 13.469 4.92633 12.3106 3.752C11.1523 2.57767 9.71548 1.9905 8.00015 1.9905C6.28481 1.9905 4.84798 2.57767 3.68965 3.752C2.53131 4.92633 1.95215 6.425 1.95215 8.248C1.95215 9.457 2.47107 10.8657 3.5089 12.474C4.54673 14.0823 6.04381 15.7622 8.00015 17.5135ZM8.00015 19.5095C5.48348 17.3288 3.59631 15.2994 2.33865 13.4212C1.08098 11.5429 0.452148 9.8185 0.452148 8.248C0.452148 5.94033 1.19857 4.07208 2.6914 2.64325C4.1844 1.21442 5.95398 0.5 8.00015 0.5C10.0463 0.5 11.8159 1.21442 13.3089 2.64325C14.8017 4.07208 15.5481 5.94033 15.5481 8.248C15.5481 9.8185 14.9193 11.5429 13.6616 13.4212C12.404 15.2994 10.5168 17.3288 8.00015 19.5095Z"
      fill="#1E264C"
    />
  </svg>
</template>
