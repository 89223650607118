import { learningServiceRequest, skip } from "./utils";

const countriesService = {
  async getCountries(limit = 250, page = 1, name = "") {
    return await learningServiceRequest({
      method: "get",
      url: "/countries",
      params: { limit, skip: skip(page, limit), name },
      failureMessage: "Error loading Countries data",
    });
  },
  async searchCountries({ limit = 20, page = 1, q = "", source }) {
    return await learningServiceRequest({
      method: "get",
      url: "/countries",
      params: { limit, skip: skip(page, limit), q },
      failureMessage: "Error searching Countries data",
      source,
    });
  },
  async getCountry(country_id = "") {
    return await learningServiceRequest({
      method: "get",
      url: `/countries/${country_id}/`,
      failureMessage: "Error searching Country data",
    });
  },
};

export default countriesService;
