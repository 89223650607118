<script>
export default {
  name: "ExpandableRows",
};
</script>

<script setup>
import { ref, toRefs } from "vue";
import { pickBy, identity } from "lodash";
import { useVModel } from "@vueuse/core";
import { PencilIcon } from "@heroicons/vue/24/outline";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { FlexRender } from "@tanstack/vue-table";
import Spinner from "@/components/helpers/Spinner.vue";
import {
  COLUMNS_IDS,
  ORDER_STATUSES,
} from "@/components/StudentFulfillment/components/OrderDetail/sections/Details/constants";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  row: {
    type: [Array, Object],
    default: () => [],
  },
  label: {
    type: String,
    default: "",
  },
  expandEnabled: {
    type: Boolean,
    default: true,
  },
  editEnabled: {
    type: Boolean,
    default: true,
  },
  data: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
  statusOptions: {
    type: Array,
    default: () => [],
  },
});

const { expandEnabled, editEnabled, loading, row, label, data } = toRefs(props);

const emit = defineEmits(["openStatusModal", "expanded"]);
const open = ref(false);

const expand = () => {
  open.value = !open.value;
  emit("expanded", open.value);
};
</script>

<template>
  <tr
    class="border-t-[1px] border-solid border-t-gray-200 w-full hover:bg-blue-350"
    :class="[open && expandEnabled ? 'bg-gray-200' : '']"
  >
    <td
      v-for="cell in row.getVisibleCells()"
      :key="cell.column.id"
      class="py-8 text-md capitalize border-t-[1px] border-solid border-t-gray-200"
      :class="[
        cell.column.id.trim() === COLUMNS_IDS.name ? 'text-teal-900' : '',
      ]"
    >
      <div
        class="flex flex-col"
        v-if="cell.column.id.trim() === COLUMNS_IDS.more_info"
      >
        <span
          v-for="item in Object.values(
            cell.row.original[COLUMNS_IDS.more_info]
          )"
          v-if="cell.row.original[COLUMNS_IDS.more_info]"
        >
          {{ item }}
        </span>
      </div>

      <button
        v-else-if="cell.column.id.trim() === COLUMNS_IDS.action"
        class="flex justify-center items-center ml-4 text-md font-bold bg-transparent text-teal-900"
        :disabled="!editEnabled"
        @click="emit('openStatusModal', row.original.id)"
      >
        <PencilIcon class="h-5" />
      </button>

      <FlexRender
        v-else-if="cell.column.id.trim() !== COLUMNS_IDS.type"
        :render="cell.column.columnDef.cell"
        :props="cell.getContext()"
      />

      <div v-else class="flex">
        <button
          v-if="expandEnabled"
          :class="['text-md font-semibold items-center cursor-pointer']"
        >
          <ChevronRightIcon
            :class="[
              {
                'rotate-90 transform': open,
              },
              'h-6 w-6 inline-block',
            ]"
            @click="() => expand()"
          />
        </button>

        <div
          class="flex text-md font-semibold justify-start items-center"
          :class="[!expandEnabled ? 'pl-4' : '']"
        >
          <span>{{ label }}</span>
        </div>
      </div>
    </td>
  </tr>
  <tr v-if="open && expandEnabled">
    <td colspan="9" class="p-4">
      <div v-if="!loading" class="grid grid-cols-5 gap-4">
        <div
          class="flex flex-col"
          v-for="(value, key) in pickBy(data, identity)"
        >
          <span class="uppercase text-gray-900 text-md font-semibold">
            {{ key }}
          </span>
          <span class="text-md">
            {{ value }}
          </span>
        </div>
      </div>
      <Spinner v-else class="relative" />
    </td>
  </tr>
</template>
