export default {
  root: {
    class: [
      // Flexbox
      "inline-flex items-center",

      //Border
      "border border-badge-blue",

      // Spacing
      "px-2",

      // Shape
      "rounded-lg",

      // Colors
      "text-surface-700 dark:text-white/70",
      "bg-surface-200 dark:bg-surface-700",
      "text-sm",
      "font-medium"
    ],
  },
  label: {
    class: "leading-6 my-1.5 mx-0 cursor-pointer truncate",
  },
  icon: {
    class: "leading-6 mr-2",
  },
  image: {
    class: ["w-9 h-9 -ml-3 mr-2", "rounded-full"],
  },
  removeIcon: {
    class: [
      // Shape
      "rounded-md leading-6",

      // Spacing
      "ml-2",

      // Size
      "w-4 h-4",

      // Transition
      "transition duration-200 ease-in-out",

      // Misc
      "cursor-pointer",
    ],
  },
};
