<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 2h9v2H6zm0 5h9v2H6zm0 5h9v2H6z" />
    <circle cx="3" cy="3" r="1.75" />
    <circle cx="3" cy="8" r="1.75" />
    <circle cx="3" cy="13" r="1.75" />
  </svg>
</template>

<script>
export default {
  name: "IconBulletedList",
};
</script>
