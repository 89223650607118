import { mapGetters, mapState } from "vuex";
import { PERMISSIONS } from "@/constants";
import {
  hasEnableResearchApp,
  hasEnableHomeApp,
  hasEnableMyStudentsApp,
  hasEnableCareerAndSkillsApp,
  hasStudentViewPermission,
  hasAccessToCurriculumIntegration,
} from "@/composables/authorization";

export const sidebarMixin = {
  computed: {
    ...mapGetters({ permissions: "getPermissions" }),
    ...mapState(["featureFlags", "currentUser"]),
    showSidebar() {
      return this.items.some((item) => item.featureFlagOn);
    },
    fareFinderEnabled() {
      return (
        (this.permissions.includes(PERMISSIONS.PARTICIPANTS) &&
          !this.permissions.includes(PERMISSIONS.INSTITUTION)) ||
        this.permissions.includes(PERMISSIONS.API_EMPLOYEE_STUDENT_VIEW)
      );
    },
    settingsEnabled() {
      return this.featureFlags["settings"];
    },
    isProgramManagementEnabled() {
      return (
        (this.permissions.includes(PERMISSIONS.PRGMS_READ_LITE) ||
          this.permissions.includes(PERMISSIONS.PRGMS_READ) ||
          this.permissions.includes(PERMISSIONS.ITEMS_READ) ||
          this.permissions.includes(PERMISSIONS.ITEMS_READ_LITE)) &&
        this.featureFlags["program-management"]
      );
    },
    isStudentFulfillmentEnabled() {
      return (
        this.isProgramManagementEnabled &&
        this.permissions.includes(PERMISSIONS.ORDERS_READ)
      );
    },
    isCurrentUserAdvisor() {
      return this.permissions.includes(PERMISSIONS.INSTITUTION);
    },
    items() {
      return [
        {
          id: 12,
          name: "home-app",
          featureFlagOn: hasEnableHomeApp.value,
          meta: {
            displayName: "Home",
          },
          icons: {
            default: "/images/icon-home-default.svg",
            active: "/images/icon-home-active.svg",
          },
        },
        {
          id: 13,
          name: "my-students-app-benchmarking",
          featureFlagOn: hasEnableMyStudentsApp.value,
          meta: {
            displayName: "My Students",
          },
          icons: {
            default: "/images/icon-studentmgmt-default.svg",
            active: "/images/icon-studentmgmt-active.svg",
          },
          nestedItems: [
            {
              id: 1,
              name: "Benchmarking",
              path: "my-students-app-benchmarking",
            },
            {
              id: 2,
              name: "Forms",
              path: "my-students-app-forms",
            },
          ],
        },
        {
          id: 1,
          name: "applications",
          featureFlagOn: this.permissions.includes(PERMISSIONS.PARTICIPANTS),
          meta: {
            displayName: "My Applications",
          },
          icons: {
            default: "/images/icon-my-applications-default.svg",
            active: "/images/icon-my-applications-active.svg",
          },
        },
        {
          id: 2,
          name: "fare-finder",
          featureFlagOn: this.fareFinderEnabled,
          meta: {
            displayName: "Fare Finder",
          },
          icons: {
            default: "/images/icon-fare-finder-default.svg",
            active: "/images/icon-fare-finder-active.svg",
          },
        },
        {
          id: 3,
          name: "experience-search",
          featureFlagOn: false,
          meta: {
            displayName: "Experience Search",
          },
          icons: {
            default: "/images/icon-experiencesearch-default.svg",
            active: "/images/icon-experiencesearch-active.svg",
          },
        },
        {
          id: 4,
          name: "program-manager-root",
          featureFlagOn: this.isProgramManagementEnabled,
          meta: {
            displayName: "Program Manager",
          },
          icons: {
            default: "/images/icon-programmgmt-default.svg",
            active: "/images/icon-programmgmt-active.svg",
          },
          nestedItems: this.isStudentFulfillmentEnabled && [
            {
              id: 1,
              name: "Program Manager",
              path: "program-manager-root",
            },
            {
              id: 2,
              name: "Student Fulfillment",
              path: "student-fulfillment",
            },
          ],
        },
        {
          id: 6,
          name: "badges",
          featureFlagOn: false,
          meta: {
            displayName: "Badges",
          },
          icons: {
            default: "/images/icon-badges-default.svg",
            active: "/images/icon-badges-active.svg",
          },
        },
        {
          id: 7,
          name: "student-mgmt",
          featureFlagOn: false,
          meta: {
            displayName: "Student Mgmt",
          },
          icons: {
            default: "/images/icon-studentmgmt-default.svg",
            active: "/images/icon-studentmgmt-active.svg",
          },
          nestedItems: [{ id: 1, name: "Test", path: "blabla2" }],
        },
        {
          id: 8,
          name: "learning-experience",
          featureFlagOn: false,
          meta: {
            displayName: "Learning Experience",
          },
          icons: {
            default: "/images/icon-learningexperience-default.svg",
            active: "/images/icon-learningexperience-active.svg",
          },
        },
        {
          id: 9,
          name: "funnel-analytics-capacity-planning",
          featureFlagOn: false,
          meta: {
            displayName: "Funnel Analytics & Capacity Planning",
          },
          icons: {
            default: "/images/icon-funnel-default.svg",
            active: "/images/icon-funnel-active.svg",
          },
        },
        {
          id: 10,
          name: "research-app",
          featureFlagOn: hasEnableResearchApp.value,
          meta: {
            displayName: "Research",
          },
          icons: {
            default: "/images/icon-apiresearch-default.svg",
            active: "/images/icon-apiresearch-active.svg",
          },
        },
        {
          id: 11,
          name: "scholarship",
          featureFlagOn: false,
          meta: {
            displayName: "Scholarship",
          },
          icons: {
            default: "/images/icon-scholarship-default.svg",
            active: "/images/icon-scholarship-active.svg",
          },
        },
        {
          id: 5,
          name: "curriculum-integration-app",
          featureFlagOn: hasAccessToCurriculumIntegration.value,
          meta: {
            displayName: "Curriculum Integration",
          },
          icons: {
            default: "/images/icon-curriculum-default.svg",
            active: "/images/icon-curriculum-active.svg",
          },
        },
        {
          id: 14,
          name: "career-and-skills-app",
          featureFlagOn: hasEnableCareerAndSkillsApp.value,
          meta: {
            displayName: "Career And Skills",
          },
          icons: {
            default: "/images/icon-internship-default.svg",
            active: "/images/icon-internship-active.svg",
          },
        },
        {
          id: 15,
          name: "student-management",
          featureFlagOn: hasStudentViewPermission.value,
          meta: {
            displayName: "My Students",
          },
          icons: {
            default: "/images/icon_my_students.svg",
            active: "/images/icon_my_students_active.svg",
          },
        },
      ];
    },
  },
};
