<script setup>
import "flatpickr/dist/flatpickr.css";
import RequiredToSavePublish from "../../housing/components/RequiredToSavePublish";
import WYSIWYG from "@/components/forms/SharedComponents/WYSIWYG/WYSIWYG.vue";
import { ref, computed, defineEmits, defineProps, watch } from "vue";
import { useStore } from "vuex";
import { useVModel } from "@vueuse/core";
import BaseToggle from "../../../forms/SharedComponents/BaseToggle.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import BaseInput from "../../../InternshipLibrary/BaseInput.vue";
import TagsInput from "@/components/shared/TagsInput.vue";
import { sanitizePriceInput } from "@/util/formatter";

const store = useStore();

const props = defineProps({
  modelValue: {
    type: Object,
  },
});

const emit = defineEmits(["update:modelValue", "validator"]);
const events = useVModel(props, "modelValue", emit);

const validateTab = async () => {
  v$.value.$reset();
  return v$.value.$validate().then(() => {
    return !v$.value.$invalid;
  });
};

emit("validator", validateTab);

const getFlatCategoryTags = computed(
  () => store.getters["tagManager/getFlatCategoryTags"]
);

const getCategories = computed(() => {
  return store.getters["events/getCategories"];
});

const approx_length = computed(() => {
  return events.value.approx_length;
});

const lengthVaries = () => {
  events.value.approx_length = true;
};

const getSubCategories = computed(() => {
  return store.getters["events/getSubCategories"];
});

const subCategoryOptions = computed(() => {
  return events?.value?.event_category?.id
    ? getSubCategories.value.filter(
        (subcategory) =>
          subcategory?.category_id === events?.value?.event_category.id
      )
    : [];
});

watch(subCategoryOptions, (newVal) => {
  const newValContains = newVal.filter(
    (subcategory) => subcategory?.id === events?.value?.sub_category?.id
  );
  if (!newValContains.length) {
    events.value.sub_category = [];
  }
});

const experienceMode = ["In Person", "Online", "Hybrid"];
const host = ["Academic Programs International"];
const locationType = [
  "Single Location",
  "Multi-Location (same city)",
  "Multi-City",
];
const timeSpan = ["Hours", "Days", "Weeks", "Months"];
const timeSpanSingular = ["Hour", "Day", "Week", "Month"];

const time = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10 or more"];
const location = ref({
  required: true,
  type: "radio",
  options: ["Indoors", "Outdoors"],
  copy: "This event is primarily:",
  active: true,
  name: "location",
});

const updateContent = (val) => {
  events.value.description = val;
};

const rules = computed(() => ({
  events: {
    event_category: {
      required: helpers.withMessage(
        "Event Category is required",
        requiredIf(function () {
          return !events.value.event_category;
        })
      ),
    },
    sub_category: {
      required: helpers.withMessage(
        "Event Sub Category is required to save",
        requiredIf(function () {
          return (
            !events.value.sub_category ||
            events.value.sub_category?.length === 0
          );
        })
      ),
    },
    type: {
      required: helpers.withMessage(
        "Mode of experience is required",
        requiredIf(function () {
          return !events.value.type;
        })
      ),
    },
    description: {
      required: helpers.withMessage(
        "Description is required to Publish",
        requiredIf(function () {
          return events.value.status === "Active" && !events.value.description;
        })
      ),
    },
    duration: {
      required: helpers.withMessage(
        "Duration is required to Publish",
        requiredIf(function () {
          return events.value.status === "Active" && !events.value.duration;
        })
      ),
    },
    duration_units: {
      required: helpers.withMessage(
        "Duration units is required to Publish",
        requiredIf(function () {
          return (
            events.value.status === "Active" && !events.value.duration_units
          );
        })
      ),
    },
    price_student: {
      required: helpers.withMessage(
        "Price entered exceeds maximum digits allowed.  Please enter a price with fewer than 7 digits.",
        requiredIf(function () {
          return events.value.price_student > 9999999.99;
        })
      ),
    },
  },
}));

const v$ = useVuelidate(rules, events, {
  $registerAs: "GeneralInfoTab",
  $lazy: true,
});

const showMarketplaceToggle = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["tenant-libraries"];
});
</script>

<template>
  <div class="relative">
    <div :class="'p-6'">
      <div class="flex justify-between items-start flex-wrap">
        <h1 class="text-xl font-semibold leading-6 text-gray-900">
          General Info
        </h1>
        <RequiredToSavePublish />
      </div>
      <div class="grid xs:grid-cols-2 mt-2 sm:grid-cols-3 gap-8">
        <div>
          <div class="flex flex-col justify-start md:max-w-[300px]">
            <div>
              <label class="block text-sm font-semibold text-gray-700"
                ><span class="text-red-100 pr-2 align-sub">*</span>Event
                Category</label
              >
              <v-select
                v-model="events.event_category"
                :options="getCategories"
                label="name"
                class="md:max-w-[300px]"
                :class="[
                  {
                    'bg-error-100': v$.events.event_category.$errors?.length,
                  },
                ]"
                @blur="v$.events.event_category.$touch"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><i
                      class="fa fa-caret-down text-gray-500"
                      v-bind="attributes"
                    ></i
                  ></span>
                </template>
              </v-select>
              <div
                v-if="v$.events.event_category.$errors?.length"
                class="block text-sm error text-error-900"
              >
                <span
                  v-for="(error, idx) in v$.events.event_category.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label class="block text-sm font-semibold text-gray-700"
            ><span class="text-red-100 pr-2 align-sub">*</span>Event
            Sub-category</label
          >
          <v-select
            v-model="events.sub_category"
            :options="subCategoryOptions"
            label="name"
            class="md:max-w-[300px]"
            :class="[
              {
                'bg-error-100': v$.events.sub_category.$errors?.length,
              },
            ]"
            @blur="v$.events.sub_category.$touch"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"
                ><i
                  class="fa fa-caret-down text-gray-500"
                  v-bind="attributes"
                ></i
              ></span>
            </template>
          </v-select>
          <div
            v-if="v$.events.sub_category.$errors?.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.events.sub_category.$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div v-if="showMarketplaceToggle" class="mb-4">
          <label class="block text-sm font-semibold text-gray-700">
            Available in Marketplace
          </label>
          <div class="flex flex-row items-center h-full">
            <BaseToggle
              v-model="events.marketplace"
              label-after="true"
              width="w-10"
            />
            <img
              v-if="events.marketplace"
              src="/images/icon-marketplace.svg"
              class="mb-6"
            />
          </div>
        </div>
        <div class="mb-4">
          <label class="block text-sm font-semibold text-gray-700"
            ><span class="text-red-100 pr-2 align-sub">*</span>Mode of
            Experience</label
          >
          <v-select
            v-model="events.type"
            :options="experienceMode"
            label="experience"
            class="md:max-w-[300px]"
            :class="[
              {
                'bg-error-100': v$.events.type.$errors?.length,
              },
            ]"
            @blur="v$.events.type.$touch"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"
                ><i
                  class="fa fa-caret-down text-gray-500"
                  v-bind="attributes"
                ></i
              ></span>
            </template>
          </v-select>
          <div
            v-if="v$.events.type.$errors?.length"
            class="block text-sm error text-error-900"
          >
            <span v-for="(error, idx) in v$.events.type.$errors" :key="idx">
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div class="mb-4">
          <div>
            <label label class="font-semibold">
              <span class="font-semibol mr-2 text-error-900"></span>This event
              is primarily:</label
            >
            <BaseInput
              v-model="events.is_outdoors"
              type="radio"
              :field-settings="location"
              :custom-radio-css="'flex space-x-20'"
            />
          </div>
        </div>
        <div class="mb-4">
          <label class="block text-sm font-semibold text-gray-700"
            >Location Type</label
          >
          <v-select
            v-model="events.location_type"
            :options="locationType"
            label="location"
            class="md:max-w-[300px]"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"
                ><i
                  class="fa fa-caret-down text-gray-500"
                  v-bind="attributes"
                ></i
              ></span>
            </template>
          </v-select>
        </div>
        <div class="mb-4">
          <label name="price" class="block text-sm font-semibold text-gray-700"
            >Price per Student</label
          >
          <div class="flex md:max-w-[300px]">
            <span
              class="pr-2 flex items-center text-sm font-semibold text-gray-700"
              >$(USD)</span
            >
            <input
              id="price"
              v-model="events.price_student"
              type="text"
              name="price"
              class="h-10 w-full form-input"
              :class="[
                {
                  'bg-error-100': v$.events.price_student.$errors?.length,
                },
              ]"
              @blur="v$.events.price_student.$touch"
              @input="
                events.price_student = sanitizePriceInput(events.price_student)
              "
            />
          </div>
          <div
            v-if="v$.events.price_student.$errors?.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.events.price_student.$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>

        <div class="mb-4 col-span-2">
          <label class="block text-sm font-semibold text-gray-700"
            ><span class="text-red-100 pr-2 align-sub">**</span>How long is this
            event?</label
          >
          <div class="relative grid xs:grid-cols-1 sm:grid-cols-2 gap-8">
            <div class="col-span-1 flex space-x-4">
              <v-select
                v-model="events.duration"
                class="w-full"
                :options="time"
                placeholder="0"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><i
                      class="fa fa-caret-down text-gray-500"
                      v-bind="attributes"
                    ></i
                  ></span>
                </template>
              </v-select>
              <v-select
                v-model="events.duration_units"
                class="w-full"
                :options="events?.duration > 1 ? timeSpan : timeSpanSingular"
                placeholder="Hours"
                @blur="v$.events.duration_units.$touch"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><i
                      class="fa fa-caret-down text-gray-500"
                      v-bind="attributes"
                    ></i
                  ></span>
                </template>
              </v-select>
              <div
                v-if="v$.events.duration_units.$errors?.length"
                class="block text-sm error text-error-900"
              >
                <span
                  v-for="(error, idx) in v$.events.duration_units.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>

            <div class="flex items-center">
              <input
                id="length"
                name="length"
                type="checkbox"
                :checked="approx_length"
                @click="lengthVaries"
                class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
              />
              <label
                for="length"
                class="ml-3 block text-sm font-medium text-gray-700"
                >Length of this event varies</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-sm font-semibold text-gray-700"
          ><span class="text-red-100 pr-2 align-sub">**</span>Description</label
        >
        <WYSIWYG
          :content="events.description"
          :resizable="true"
          :characterLimit="20000"
          @update-content="($eventPayload) => updateContent($eventPayload)"
        />
        <div
          v-if="v$.events.description.$errors?.length"
          class="block text-sm error text-error-900"
        >
          <span
            v-for="(error, idx) in v$.events.description.$errors"
            :key="idx"
          >
            {{ error.$message }}
          </span>
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-sm font-semibold text-gray-700">Host</label>
        <v-select v-model="events.host" :options="host">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes"
              ><i class="fa fa-caret-down text-gray-500" v-bind="attributes"></i
            ></span>
          </template>
        </v-select>
      </div>

      <div class="mb-4">
        <TagsInput
          v-model="events.tags"
          field-label="Add Tags"
          class="col-span-2"
          :vuelidate-instance="v$.events.tags"
          label="name"
          :options="getFlatCategoryTags"
        />
      </div>
    </div>
  </div>
</template>
