<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { FORMS_SUBMISSIONS } from "@/constants.js";
import { useHousingQuestionnaire } from "@/composables/useHousingQuestionnaire";
import { ORDER_PRODUCT_STATUS } from "@/constants.js";
import Acknowledgements from "@/components/Configurator/Housing/QuestionnaireAcknowledgements.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import BasicQuestions from "@/components/Configurator/Housing/QuestionnaireBasicQuestions.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import Housemates from "@/components/Configurator/Housing/QuestionnaireHousemates.vue";
import ordersService from "@/services/orders";
import programSessionService from "@/services/programSessions";
import Roommates from "@/components/Configurator/Housing/QuestionnaireRoommates.vue";
import Spinner from "@/components/helpers/Spinner.vue";

const {
  QUESTIONS,
  previousQuestionnaire,
  unit,
  v$,
  isLocalHost,
  formattedSubmission,
  setUnit,
  setAcknowledgement,
  setQuestionnaireData,
  getHousingQuestionnaire,
  createOrUpdatedHousingQuestionnaire,
} = useHousingQuestionnaire();
const route = useRoute();
const store = useStore();

const submitButton = ref(null);
const loading = ref(false);
const order = ref({});
const session = ref({});
const submissionId = ref(null);
const formSubmitted = ref(false);
const errorOnSubmission = ref(false);

const applicationId = computed(() => route.params?.applicationId);
const participantId = computed(
  () => store.getters.getCurrentUser?.participantId ?? ""
);
const homeInstitutionId = computed(
  () => store.getters.getProfileData?.colleges?.[0]?.college_id ?? ""
);
const roomIsfulfilled = computed(() =>
  Boolean(
    order.value?.product_details?.rooms?.[0]?.status ===
      ORDER_PRODUCT_STATUS.fulfilled
  )
);

const getOrder = async () => {
  const orders = await ordersService.list({
    participant_id: participantId.value,
  });

  order.value = orders.find(
    (order) => order?.order?.application_id === applicationId.value
  );
};
const getSubmission = async () => {
  const questionnaireResponse = await getHousingQuestionnaire(
    order.value.order.id
  );

  const submissionResponseData = questionnaireResponse?.submission_data;
  submissionId.value = questionnaireResponse?.id;

  previousQuestionnaire.questions =
    submissionResponseData?.question_responses?.filter(
      (item) => item.level === "basics"
    ) ?? [];

  previousQuestionnaire.allAcknowledged =
    submissionResponseData?.allAcknowledged ?? {};

  previousQuestionnaire.roommateRequests =
    submissionResponseData?.requested_roommates ?? [];
  previousQuestionnaire.housemateRequests =
    submissionResponseData?.requested_housemates ?? [];
};
const getSession = async () => {
  const sessionServiceResponse = await programSessionService.getProgramSession({
    id: order.value.order.session_id,
    home_institution_id: homeInstitutionId.value,
  });
  session.value = sessionServiceResponse.data?.data?.items;
  setUnit(session.value?.session_units ?? [], order.value.order.room_ids);
};
const loadData = async () => {
  await getOrder();

  if (order.value) {
    await getSubmission();
    await getSession();
  }
};
const submit = async () => {
  formSubmitted.value = false;
  errorOnSubmission.value = false;
  const isValid = await v$.value.$validate();

  if (isValid) {
    try {
      submitButton.value.startLoading();

      const submissionResponse = await createOrUpdatedHousingQuestionnaire(
        participantId.value,
        order.value.order.id,
        applicationId.value,
        formattedSubmission.value,
        submissionId.value
      );

      submissionId.value = submissionResponse.data.data.id;
      formSubmitted.value = true;
    } catch (error) {
      errorOnSubmission.value = true;
    } finally {
      submitButton.value.stopLoading();
    }
  }
};

onMounted(async () => {
  loading.value = true;
  await loadData();
  loading.value = false;
});
</script>

<template>
  <Spinner v-if="loading" />
  <div v-else>
    <div
      v-if="roomIsfulfilled"
      class="p-5 font-montserrat bg-warning-banner text-center"
      data-testid="fulfilled-banner"
    >
      <p class="text-base text-indigo-base">
        <img src="/images/icon-attention.svg" class="inline mr-3" />
        <span>
          The Housing Questionnaire submission window is closed. The fields
          below cannot be edited anymore because your housing has been
          fulfilled.
        </span>
      </p>
    </div>
    <div class="bg-blue-100 p-5 font-montserrat">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-indigo-base font-semibold hover:text-indigo-base hover:underline"
        data-testid="back-link"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>

      <div
        class="m-1 md:m-5 md:mr-96 text-indigo-base"
        data-testid="form-section"
      >
        <h2 class="font-semibold text-2xl dark-blue mb-10">
          {{ FORMS_SUBMISSIONS.housingQuestionnaire.name }}
        </h2>

        <h1 class="text-4xl uppercase font-bold mb-5">
          {{ unit?.housings?.type ?? "" }}
        </h1>

        <p class="text-xl font-medium mb-4">
          Complete the following housing questionnaire to help us cultivate a
          safe and comfortable journey abroad. Check all that may apply.
        </p>

        <Roommates
          id="roommates-section"
          :previous-questionnaire="previousQuestionnaire"
          :disabled="roomIsfulfilled"
          @set-questionnaire-data="setQuestionnaireData"
        />
        <Housemates
          id="housemates-section"
          :previous-questionnaire="previousQuestionnaire"
          :disabled="roomIsfulfilled"
          @set-questionnaire-data="setQuestionnaireData"
        />

        <BasicQuestions
          :is-local-host="isLocalHost"
          :questions="QUESTIONS"
          :previous-questionnaire="previousQuestionnaire"
          :disabled="roomIsfulfilled"
          @set-questionnaire-data="setQuestionnaireData"
        />

        <Acknowledgements
          :previous-questionnaire="previousQuestionnaire"
          :disabled="roomIsfulfilled"
          @set-acknowledgement="setAcknowledgement"
        />

        <ButtonWithSpinner
          ref="submitButton"
          data-testid="housing-questionnaire-submit-button"
          type="submit"
          variant="secondary"
          variant-type="block"
          button-height="min-h-[58px] md:min-h-[60px] mt-4"
          :grey-disabled-class="true"
          :disabled="roomIsfulfilled"
          @click="submit"
        >
          <div
            class="text-lg md:text-base md:font-bold flex items-center justify-center"
          >
            <span class="pr-1 uppercase">
              Submit
            </span>
            <ArrowRight custom-class="w-5 h-5" />
          </div>
        </ButtonWithSpinner>
        <div
          v-if="formSubmitted"
          class="text-success-900"
          data-testid="success-submission-message"
        >
          <p>
            Submission Complete, thank you!
          </p>
        </div>
        <div
          v-if="errorOnSubmission"
          class="text-error-900"
          data-testid="error-submission-message"
        >
          <p>
            There was an error submitting the form. Please try again.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dark-blue {
  color: #2c2c3b;
}
</style>
