<template>
  <div data-testid="industries">
    <span class="font-bold text-sm leading-4 text-indigo-base mr-1">
      {{ industryLabel }}
    </span>
    <span class="text-sm leading-4 text-indigo-base font-normal">
      {{ industries }}
    </span>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";

const props = defineProps({
  industries: { type: String, default: "" },
});

const { industries } = toRefs(props);

const industryLabel = computed(() => {
  return industries.value.includes(",") ? "Industries:" : "Industry:";
});
</script>
