<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="#1E264C"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8771 9.16809H20.3979V7.64725H18.8771V9.16809ZM18.8771 13.716H20.3979V12.1952H18.8771V13.716ZM18.8771 18.2472H20.3979V16.7264H18.8771V18.2472ZM17.1 22.2076V20.9785H23.1458V5.02017H12.3749V8.07433L11.1458 7.21811V3.79102H24.3749V22.2076H17.1ZM1.625 22.2076V12.3847L8.4854 7.48478L15.3291 12.3813V22.2076H9.9729V17.0451H6.9979V22.2076H1.625ZM2.85415 20.9785H5.76875V15.816H11.202V20.9785H14.1V13.016L8.4854 9.02016L2.85415 13.0302V20.9785Z"
    />
  </svg>
</template>
