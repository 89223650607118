<script setup>
import {
  useGetEntitySupplement,
  useGetHostInstitutionLanguages,
} from "@/components/program-manager/sessions/composable.js";
import {
  CLOUDINARY_VIDEO_EXTENSION_TYPES,
  CONTACT_HOURS,
  ERROR_TIMEOUT,
} from "@/constants.js";
import { convertToMp4, imageTransformation } from "@/util/cloudinary";
import { videoPlayer } from "cloudinary-video-player";
import "cloudinary-video-player/cld-video-player.min.css";
import { computed, defineProps, onUnmounted, ref, toRefs, watch, onMounted } from "vue";
import { useToast } from "vue-toast-notification";

const GOOGLE_PLACES_API_KEY = process.env.MIX_UI_GOOGLE_PLACES_API_KEY;
const CLOUDINARY_CLOUD_NAME = process.env.MIX_UI_CLOUDINARY_CLOUD_NAME;
const NOT_AVAILABLE = "Not available";

const toast = useToast();

const props = defineProps({
  hostInstitution: {
    type: Object,
    required: true,
  },
});

const { hostInstitution } = toRefs(props);
const hasVideo = ref(false);
const cloudinaryVideoPlayer = ref();
const numberOfStudents = ref(0);
const initializeVideoPlayer = function () {
    cloudinaryVideoPlayer.value = videoPlayer("experiencePageHostInstitutionVideo",
    {
      cloud_name: CLOUDINARY_CLOUD_NAME,
      secure: true,
      seekThumbnails: false,
      sourceTypes: ["hls", "dash", "webm/vp9", "mp4/h265"].concat(
        CLOUDINARY_VIDEO_EXTENSION_TYPES
      ),
    }
  );
};


onMounted(() => {
  initializeVideoPlayer();
});

onUnmounted(() => {
  if (cloudinaryVideoPlayer.value) {
    cloudinaryVideoPlayer.value.dispose();
    cloudinaryVideoPlayer.value = undefined;
  }
});

const {
  execute: executeFetchEntitySupplementOptions,
  state: entitySupplementData,
} = useGetEntitySupplement(undefined, false);
const {
  execute: executeFetchHostInstitutionLanguagesOptions,
  state: languageData,
} = useGetHostInstitutionLanguages(undefined, false);

watch(hostInstitution, async (newHostInstitution) => {
  if (newHostInstitution?.id) {
    hasVideo.value = false;
    try {
      await executeFetchHostInstitutionLanguagesOptions(
        0,
        newHostInstitution.id
      );
      await executeFetchEntitySupplementOptions(0, newHostInstitution.id);
    } catch (exception) {
      toast.open({
        message: "Was not able to load Institution data.",
        type: "error",
        position: "bottom",
        duration: ERROR_TIMEOUT,
      });
    }
  }
},
{
  immediate: true
});

watch(entitySupplementData, async (newEntitySupplementData) => {
  hasVideo.value = false;
  if (cloudinaryVideoPlayer.value) {
    cloudinaryVideoPlayer.value.stop();
  }
  numberOfStudents.value =
    newEntitySupplementData.items?.total_number_of_students ?? 0;
  const video = Array.isArray(newEntitySupplementData.items?.supporting_media)
    ? newEntitySupplementData.items.supporting_media.filter((item) =>
        CLOUDINARY_VIDEO_EXTENSION_TYPES.some((type) => item.endsWith(type))
      )
    : undefined;
  if (video?.length > 0) {
    hasVideo.value = true;
    if (images.value?.[0]) {
      cloudinaryVideoPlayer.value.posterOptions({
        publicId: images.value?.[0],
      });
    }
    cloudinaryVideoPlayer.value.source(convertToMp4(video?.[0]));
  }
},
{
  immediate: true
});

const images = computed(
  () =>
    entitySupplementData.value?.items?.supporting_media
      ?.filter((item) =>
        CLOUDINARY_VIDEO_EXTENSION_TYPES.some(
          (type) => item && !item.endsWith(type)
        )
      )
      ?.map((img) => imageTransformation({ path: img, compress: true })) || []
);

const languageShowCount = computed(() =>
  languageData.value?.count > languageData.value?.end
    ? ` + ${languageData.value?.count - languageData.value?.end} more languages`
    : ""
);

const languages = computed(() => {
  return languageData.value?.count > 0
    ? `${languageData.value?.items?.map(({ value }) => value).join(", ")}${
        languageShowCount.value
      }`
    : undefined;
});

const address = computed(() => {
  if (!hostInstitution.value) return NOT_AVAILABLE;

  let addr = hostInstitution.value?.shipping_address_city
    ? `${hostInstitution.value.shipping_address_city}, `
    : "";
  addr += hostInstitution.value?.shipping_address_line_1
    ? `${hostInstitution.value.shipping_address_line_1}, `
    : "";
  addr += hostInstitution.value?.shipping_address_postal_code
    ? `${hostInstitution.value.shipping_address_postal_code}, `
    : "";
  addr += hostInstitution.value?.shipping_address_country
    ? `${hostInstitution.value.shipping_address_country}, `
    : "";
  addr += hostInstitution.value?.name ? `${hostInstitution.value.name}, ` : "";
  return addr.replace(/\r?\n/g, " ").replace(/,*$/, "").trim() || NOT_AVAILABLE;
});

const getMap = computed(() => {
  if (address.value !== NOT_AVAILABLE) {
    const googleApiKey = GOOGLE_PLACES_API_KEY;
    const zoom = 15;
    return `https://maps.googleapis.com/maps/api/staticmap?key=${googleApiKey}&center=${address.value}&zoom=${zoom}&format=png&maptype=roadmap&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&size=1200x360&scale=2&markers=color:red%7C${address.value}`;
  }
  return undefined;
});

const creditType = computed(() => {
  const creditUnitName = hostInstitution.value?.credit_unit?.name;
  return creditUnitName && CONTACT_HOURS.includes(creditUnitName.trim())
    ? "Contact Hours"
    : creditUnitName || "Not available";
});

const aboutCampus = computed(() =>
  (
    entitySupplementData.value?.items?.tags
      ?.map((item) => item?.name)
      ?.filter((item) => item) || []
  ).join(", ")
);

const numberOfStudentsFormatted = computed(() => {
  // Adds comma to a number (1500 => 1,500)
  return Number(numberOfStudents.value).toLocaleString("en-US");
});
</script>
<template>
  <div
    v-if="hostInstitution"
    class="pt-12 pb-12 md:pt-20 px-4 md:px-8 text-indigo-base text-indigo-base relative h-fit"
  >
    <div>
      <div>
        <div class="pb-8 font-bold text-4xl text-indigo-base">
          <div class="self-center text-2xl">Facts About</div>
          <div class="self-center">
            {{ hostInstitution?.name || "" }}
          </div>
        </div>
        <div v-show="hasVideo" class="w-full h-fit pb-8 mx-auto">
          <video
            id="experiencePageHostInstitutionVideo"
            controls
            muted
            class="w-full lg:max-h-[35rem] xl:max-h-[45rem] mx-auto"
          ></video>
        </div>
        <div
          v-if="!hasVideo && images?.length"
          class="w-full h-fit pb-8 mx-auto"
        >
          <img
            v-if="images?.[0]"
            :src="images?.[0]"
            loading="lazy"
            alt=""
            class="w-full lg:max-h-[35rem] xl:max-h-[45rem] ml-auto"
          />
        </div>
        <div>
          <div class="flex flex-col" :class="{ 'md:flex-row-reverse': getMap }">
            <div class="pb-8 md:pb-1 w-full">
              <div v-if="hostInstitution?.accreditation" class="mb-6">
                <span class="font-bold">Accreditation: </span>
                <span>{{
                  hostInstitution?.accreditation?.join(", ") || "Not available"
                }}</span>
              </div>
              <div
                v-if="
                  entitySupplementData?.items?.total_number_of_students &&
                  Number(
                    entitySupplementData?.items?.total_number_of_students
                  ) > 0
                "
                class="mb-6"
              >
                <span class="font-bold">Total number of students: </span>
                <span data-testid="total-number-of-students">
                  {{ numberOfStudentsFormatted }}
                </span>
              </div>
              <div
                v-if="
                  entitySupplementData?.items
                    ?.percentage_international_students &&
                  Number(
                    entitySupplementData?.items
                      ?.percentage_international_students
                  ) > 0
                "
                class="mb-6"
              >
                <span class="font-bold">
                  Percentage of international students:
                </span>
                <span>
                  {{
                    entitySupplementData?.items
                      ?.percentage_international_students
                  }}
                </span>
              </div>
              <div v-if="aboutCampus" class="mb-6">
                <span class="font-bold"
                  >About the campus and surrounding neighborhood:
                </span>
                <span>
                  {{ aboutCampus || "Not available" }}
                </span>
              </div>
              <div v-if="hostInstitution?.credit_unit" class="mb-6">
                <span class="font-bold">Credit Type: </span>
                <span data-testid="credit-type">
                  {{ creditType }}
                </span>
              </div>
            </div>
            <div v-if="getMap" class="pb-8 md:pb-1 md:pr-8 md:max-w-lg">
              <img
                id="host-institution-map"
                loading="lazy"
                :alt="address"
                :src="getMap"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-1 md:mt-20 border-b-3 border-gray-200" />
  </div>
</template>
