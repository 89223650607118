<script setup>
import AboutYou from "@/components/ExperiencePage/Application/Steps/AboutYou.vue";
import Checkout from "@/components/ExperiencePage/Application/Steps/Checkout.vue";
import GroupVisa from "@/components/ExperiencePage/Application/Steps/GroupVisa.vue";
import LearnerInfo from "@/components/ExperiencePage/Application/Steps/LearnerInformation.vue";
import NextSteps from "@/components/ExperiencePage/Application/Steps/NextSteps.vue";
import Review from "@/components/ExperiencePage/Application/Steps/Review.vue";
import SteppedProcess from "@/components/SteppedProcess/SteppedProcess.vue";
import AccountCreation from "@/components/auth/AccountCreation/AccountCreationSection.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import ArrowLeft from "@/components/shared/icons/ArrowLeft.vue";
import { V3_setUpSteps } from "@/composables/VersionHelper.js";
import { V3_ALLOWED_ROUTES, INITIAL_MAJORS } from "@/constants.js";
import {
  computed,
  markRaw,
  provide,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import _ from "lodash";
import applicationService from "@/services/application.js";
import ordersService from "@/services/orders";
import { eventBus } from "@/app";

const isLoading = ref(true);
const message = ref("Loading application steps.");
const store = useStore();
const route = useRoute();
const router = useRouter();

const initialOptionsData = reactive({
  countries: [],
  learnerTypes: [],
  schools: [],
  cipCodes: [],
  languages: [],
});
provide("initialOptionsData", initialOptionsData);

const original_steps = [
  {
    component: markRaw(AccountCreation),
    isSelectable: () => false, // Never selectable
    isComplete: () => false, // Each isComplete are Placeholders, will be edited later on by: V3_setUpSteps
    slug: "new-account-creation",
    title: "Account creation",
    // Account Creation = Appears if student hasn't registered an applies for the 1st time
  },
  {
    component: markRaw(AboutYou),
    isSelectable: () => false, // Selectable after completion
    isComplete: () => false,
    slug: "application-about-you",
    title: "About you",
    // About You = Updates data in Profile Service and Marks form as completed in Connect's db
  },
  {
    component: markRaw(LearnerInfo),
    isSelectable: () => false, // Selectable after completion
    isComplete: () => false,
    slug: "application-learner-info",
    title: "Learner Information",
    // Learner Information = Sends data to Academic Profile (formio: Student Application Stage 2 step 1) and updates profile service
  },
  {
    component: markRaw(GroupVisa),
    isSelectable: () => false, // Selectable after completion but not if application fee or deposit/checkout was completed
    isComplete: () => false,
    slug: "application-group-visa",
    title: "Group Visa Service",
    // Group Visa = Shown under a specific scenario, submits to connect's db and learning service
  },
  {
    component: markRaw(Review),
    isSelectable: () => false,
    isComplete: () => false,
    slug: "application-review",
    title: "Review",
  },
  {
    component: markRaw(Checkout),
    isSelectable: () => false, // Never selectable
    isComplete: () => false,
    slug: "application-checkout",
    title: "Checkout",
    // Checkout = Either the student pays or the institution based on a billing rule validation / contract.
  },
  {
    component: markRaw(NextSteps),
    isSelectable: () => false, // Never selectable
    isComplete: () => false,
    slug: "application-next-steps",
    title: "Next Steps",
    // Next Steps = Shows general information and allows user to go to enrollment page
  },
];

// Dynamic steps
const steps = ref(
  original_steps.map((step) => {
    return {
      component: step.component,
      isSelectable: step.isSelectable,
      isComplete: step.isComplete,
      slug: step.slug,
      title: step.title,
    };
  })
); // This can be editable now, but original_steps will remain intact for later use

const currentStep = ref(steps.value[0]);

const program_session_id = computed(() => {
  // Get program session based on data from AccountCreation step (Which comes from formio's programSelection form)
  return (
    store.state.stepsInV3.find((step) => step.step === "AccountCreation")?.data
      ?.data?.programSession ?? ""
  );
});

// Computed
const currentUser = computed(() => {
  return store.state.currentUser;
});

const oktaId = computed(() => {
  return store.state.currentUser?.participantId ?? "";
});

const profileData = computed(() => {
  return store.state.profileData;
});

const profileSelectedObjects = computed(() => {
  return store.state.profileSelectedObjects;
});

const application_id = computed(() => {
  return route.params?.applicationId ?? "";
});

const currentOrder = computed(() => store.state?.configurator?.currentOrder);

const orderId = computed(() => {
  return currentOrder.value?.orderId ?? currentOrder.value?.id;
});

const backSelectionsRoute = computed(() => {
  if (orderId.value) {
    return {
      name: "configurator",
      params: {
        programPageId: currentOrder.value.program_page_id,
        orderId: orderId.value,
      },
    };
  } else {
    return {
      name: "configurator-new",
      params: {
        programPageId: currentOrder.value.program_page_id,
      },
      query: {
        rooms: currentOrder.value?.room_ids ?? [],
        event_occurrences: currentOrder.value?.event_occurrence_ids ?? [],
        internships: currentOrder.value?.internship_ids ?? [],
        credits: currentOrder.value?.credits ?? 0,
        misc_products: currentOrder.value?.misc_product_ids ?? [],
        session: currentOrder.value?.session_id,
      },
    };
  }
});

const showPastDeadlineBanner = ref(false);
const isPastStartDate = ref(false);

eventBus.$on("showPastDeadlineBanner", (isPastSession) => {
  showPastDeadlineBanner.value = true;
  isPastStartDate.value = isPastSession;
});

const getInitialData = async () => {
  const response = await applicationService.getOptionsInitialData();
  initialOptionsData.countries = response?.countries?.data || [];
  initialOptionsData.learnerTypes = response?.learner_types?.data?.items ?? [];
  initialOptionsData.schools = response?.home_institutions?.data?.items ?? [];
  initialOptionsData.cipCodes = INITIAL_MAJORS;
  initialOptionsData.languages = response?.languages?.data?.items ?? [];
};

const getUserInitialData = async () => {
  // Define query parameters
  // Countries
  const primaryCountry =
    profileData.value?.primary_citizenship_country_id ?? "";
  const dualCountry =
    profileData.value?.dual_citizenship_country_first_id ?? "";
  const currentCountry = profileData.value?.current_address_country_id ?? "";
  const permanentCountry =
    profileData.value?.permanent_address_country_id ?? "";
  const billingCountry = profileData.value?.billing_address_country_id ?? "";
  let userCountries = [
    primaryCountry,
    dualCountry,
    currentCountry,
    permanentCountry,
    billingCountry,
  ].filter((item) => item);

  // School
  let userSchoolId = profileData.value?.colleges[0]?.college_id ?? "";

  if (userSchoolId) {
    // Check if school appears on initial list of schools returned from api call
    const userSchool = initialOptionsData.schools.find(
      (school) => school.value === userSchoolId
    );

    if (userSchool !== undefined) {
      userSchoolId = "";
      store.commit("setProfileSelectedObjects", {
        propertyName: "school",
        value: {
          ...userSchool,
          label: userSchool.name,
          value: userSchool.ope_id,
        },
      });
    }
  }

  // Major and Minors
  let userCipCodes = [];
  let userMajors = [];
  let userMinors = [];
  let missingMajors = [];
  let missingMinors = [];

  for (const major of profileData.value?.majors ?? []) {
    let foundMajor = initialOptionsData.cipCodes.find(
      (item) => item.cip_code == major.cip_code
    );

    if (foundMajor !== undefined) {
      userMajors.push({ ...foundMajor });
    } else {
      userCipCodes.push(major.cip_code);
      missingMajors.push(major.cip_code);
    }
  }

  for (const minor of profileData.value?.minors ?? []) {
    let foundMinor = initialOptionsData.cipCodes.find(
      (item) => item.cip_code == minor.cip_code
    );

    if (foundMinor !== undefined) {
      userMinors.push({ ...foundMinor });
    } else {
      userCipCodes.push(minor.cip_code);
      missingMinors.push(minor.cip_code);
    }
  }

  // Get data
  const response = await applicationService.getUserInitialData(
    [...new Set(userCountries)].join(","),
    userSchoolId,
    [...new Set(userCipCodes)].join(",")
  );

  // Set user data on store
  if (userCountries.length) {
    store.commit("setProfileSelectedObjects", {
      propertyName: "citizenship",
      value: response?.[primaryCountry]?.data || null,
    });
    store.commit("setProfileSelectedObjects", {
      propertyName: "citizenship2",
      value: response?.[dualCountry]?.data || null,
    });
    store.commit("setProfileSelectedObjects", {
      propertyName: "currentCountry",
      value: response?.[currentCountry]?.data || null,
    });
    store.commit("setProfileSelectedObjects", {
      propertyName: "permanentCountry",
      value: response?.[permanentCountry]?.data || null,
    });
    store.commit("setProfileSelectedObjects", {
      propertyName: "billingCountry",
      value: response?.[billingCountry]?.data || null,
    });
  }

  if (!_.isEmpty(response?.school)) {
    store.commit("setProfileSelectedObjects", {
      propertyName: "school",
      value: {
        ...response.school,
        label: response.school.name,
        value: response.school.ope_id,
      },
    });
  }

  for (let id of missingMajors) {
    if (response?.[id]?.data) userMajors.push(response[id].data);
  }
  for (let id of missingMinors) {
    if (response?.[id]?.data) userMinors.push(response[id].data);
  }
  store.commit("setProfileSelectedObjects", {
    propertyName: "majors",
    value: userMajors,
  });
  store.commit("setProfileSelectedObjects", {
    propertyName: "minors",
    value: userMinors,
  });

  store.commit("setProfileSelectedObjects", {
    propertyName: "loaded",
    value: true,
  });
};

// Life Cycle hooks
// Code from the created hook can be written directly in setup. = https://stackoverflow.com/a/64900114
store.commit("setFormioToken");

onMounted(async () => {
  // Get Status of steps and program session from Learning Service
  if (currentUser.value) {
    await store.dispatch("getV3Steps", application_id.value);
  }

  // Get profile data if we have a profile id
  if (oktaId.value && _.isEmpty(profileData.value)) {
    await store.dispatch("getProfile", oktaId.value);
  }

  // Redirect back if program session is not found and it's not trying to create account
  if (!program_session_id.value && route.name !== "new-account-creation") {
    router.push({ name: "applications" });
  } else {
    //Get initial data for dropdowns
    await getInitialData();

    if (!_.isEmpty(profileData.value) && !profileSelectedObjects.value.loaded) {
      //Get user selected options
      await getUserInitialData();
    }

    // If route has sessionId param it means he's trying to create an account
    if (route?.params?.sessionId) {
      store.commit("setNewAccountAndApplication", true);
    }

    if (application_id.value && !orderId.value) {
      const orderResponse = await ordersService.retrieveOrder(
        application_id.value
      );
      store.dispatch("configurator/setCurrentOrder", orderResponse.data);
    }

    findAndSetStep(route.name); // Set step to render
    await V3_setUpSteps(
      steps.value,
      original_steps,
      false,
      application_id.value
    ); // Sets steps completed, clickable or removes them
    isLoading.value = false;
  }
});

// Methods
function findAndSetStep(stepSlug = "") {
  // Updates step rendered
  let foundStep = steps.value.findIndex((step) => step.slug == stepSlug);
  if (foundStep > -1) {
    currentStep.value = steps.value[foundStep];
    return;
  }
  // TODO: Redirect to error page?
  return;
}

function redirectToStep(slug) {
  // Redirects with vue router
  let foundStep = steps.value.findIndex((step) => step.slug == slug);
  if (foundStep > -1) {
    const newRoute = {
      name: steps.value[foundStep].slug,
      params: { ...route.params },
    };
    router.push(newRoute);
    findAndSetStep(steps.value[foundStep].slug);
    return;
  }
}

const updateStepAndRedirect = (routeName, applicationId) => {
  // Note: This triggers only after submitting a step
  if (V3_ALLOWED_ROUTES.includes(routeName)) {
    // We will check: Which steps will appear in step tracker, which ones are clickable, which one to show next
    isLoading.value = true;

    const newRoute = {
      name: routeName,
      params: { applicationId: applicationId },
    };
    router.push(newRoute);

    V3_setUpSteps(steps.value, original_steps, false, applicationId).then(
      () => {
        // Can't use await on vue-router guards but we can use .then
        findAndSetStep(routeName);
        isLoading.value = false;
      }
    );
  }
};
provide("updateStepAndRedirect", updateStepAndRedirect);

const scrollUp = () => {
  window.scrollTo({
    top: 0,
    behavior: "auto",
  });
};

watch(currentStep, (newCurrentStep, oldCurrentStep) => {
  if (newCurrentStep.slug !== oldCurrentStep.slug) {
    scrollUp();
  }
});
</script>

<template>
  <div class="w-full justify-center min-h-screen bg-white mb-24">
    <!-- Spinner -->
    <template v-if="isLoading">
      <spinner>
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
        </template>
      </spinner>
    </template>
    <!-- Step + Component -->
    <template v-else>
      <div
        v-if="showPastDeadlineBanner"
        class="p-5 font-montserrat bg-warning-banner text-center"
      >
        <p class="text-base text-indigo-base">
          <img src="/images/icon-attention.svg" class="inline mr-3" />
          <template v-if="isPastStartDate">
            This program has already started, please search for another
            <a href="/" target="_blank" class="api-link">
              here
            </a>
            .
          </template>
          <span v-else>
            The application deadline has passed but we’ve sent an email to our
            team to consider if an exception can be made. We will be in touch
            soon.
          </span>
        </p>
      </div>
      <div class="flex md:w-full justify-center bg-white">
        <div>
          <router-link
            v-if="orderId"
            :to="backSelectionsRoute"
            data-testid="back-to-configurator-link"
            class="flex items-center text-lg cursor-pointer font-montserrat mt-4"
          >
            <ArrowLeft custom-class="w-6 h-6 text-teal-900" />
            <span class="font-bold text-teal-900">
              Back to Selections
            </span>
          </router-link>
          <stepped-process
            class="bg-white mt-8"
            :steps="steps"
            :current-step="currentStep"
            @set-current-step="(slug) => redirectToStep(slug)"
            @validate-deadline="validateDeadline"
          />
        </div>
      </div>
    </template>
  </div>
</template>
