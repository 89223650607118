<script setup>
import { computed, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
import { uuid } from "vue-uuid";
import RightArrowIcon from "../svg-icons/RightArrowIcon.vue";

const props = defineProps({
  icon: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  route: {
    type: String,
    default: "",
  },
});

const { icon, title, description, route, routeForceReload } = toRefs(props);
const manageRoute = useRoute();

const isOptionsSelected = computed(() => {
  return (
    manageRoute.fullPath === route.value ||
    manageRoute.fullPath.includes(route.value)
  );
});
</script>

<template>
  <router-link :to="route">
    <div
      :class="[
        'flex items-center border-b-3 border-gray-25 pb-3 pt-3 pl-2 pr-3 text-sm',
        isOptionsSelected ? 'bg-gray-150 border' : '',
      ]"
    >
      <img v-if="icon" :src="icon" :height="42" :width="42" />
      <div class="pl-3 flex-column">
        <h2 class="font-bold">{{ title }}</h2>
        <span>{{ description }}</span>
      </div>
      <div class="ml-auto">
        <RightArrowIcon />
      </div>
    </div>
  </router-link>
</template>
