<script>
export default {
  name: "InvoiceSection",
};
</script>

<script setup>
import Spinner from "@/components/helpers/Spinner.vue";
import { useQuery } from "@tanstack/vue-query";
import { toRefs, reactive, computed } from "vue";
import { formattedPriceFromCents } from "@/util/formatter";
import { format as formatDate } from "date-fns";
import {
  createColumnHelper,
  useVueTable,
  FlexRender,
  getCoreRowModel,
} from "@tanstack/vue-table";
import paymentService from "@/services/payment.js";
import { headers } from "./constants";

const props = defineProps({
  orderId: {
    type: String,
    default: "",
  },
  applicationId: {
    type: String,
    default: "",
  },
});

const { orderId, applicationId } = toRefs(props);

const { isLoading: isLoadingInvoices, data: invoiceData } = useQuery({
  queryKey: ["fetchInvoice", orderId.value],
  queryFn: () =>
    paymentService.getInvoices({
      order_id: orderId.value,
      application_id: applicationId.value,
    }),
});

const accountableInvoices = computed(
  () =>
    invoiceData.value?.data?.items?.filter(
      (invoice) => !["paid", "void"].includes(invoice.status)
    ) || []
);

const outstandingBalance = computed(
  () =>
    formattedPriceFromCents(
      accountableInvoices.value?.reduce(
        (acc, current) => acc + current.amount_due,
        0
      ) || 0
    ) || ""
);

const pastDue = computed(
  () =>
    formattedPriceFromCents(
      accountableInvoices.value?.reduce((acc, current) => {
        if (new Date(current.due_date * 1000) < new Date()) {
          return acc + current?.amount_due || 0;
        } else {
          return acc;
        }
      }, 0) || 0
    ) || ""
);

const getInvoiceCharges = (invoice) =>
  invoice?.lines?.data?.map(({ description }) => description).join(", ");

const getTableData = () => {
  return (
    invoiceData.value?.data?.items?.map((invoice) => ({
      "Invoice #": invoice.id,
      Charges: getInvoiceCharges(invoice),
      "Due Date": formatDate(new Date(invoice.due_date * 1000), "MM/dd/yyyy"),
      "Payment Status": invoice.status,
      Amount: formattedPriceFromCents(invoice.amount_due),
    })) || []
  );
};

const columnHelper = createColumnHelper();

const table = useVueTable(
  reactive({
    get data() {
      return getTableData();
    },
    columns: headers.map((header) => {
      return columnHelper.accessor(header, {
        cell: (info) => info.getValue(),
        header,
      });
    }),
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })
);
</script>

<template>
  <div class="overflow-x-auto md:overflow-x-hidden">
    <div class="flex text-lg justify-between bg-blue-350 m-4 p-6 rounded">
      <div class="space-x-2">
        <span class="text-red-100">Past Due: </span>
        <span class="font-bold">{{ pastDue }} USD</span>
      </div>
      <div class="space-x-2">
        Outstanding Balance:
        <span class="font-bold">{{ outstandingBalance }} USD</span>
      </div>
    </div>
    <table v-if="!isLoadingInvoices && table" class="m-4 p-2 w-full">
      <thead>
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
        >
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            class="py-4 pr-3 first:pl-2 text-sm items-center text-gray-900"
          >
            <div class="flex items-center">
              {{ header.column.columnDef.header }}
            </div>
          </th>
        </tr>
      </thead>

      <tbody class="bg-white">
        <tr
          v-for="row in table.getRowModel().rows"
          :key="row.id"
          class="hover:bg-blue-350"
        >
          <td
            v-for="cell in row.getVisibleCells()"
            :key="cell.id"
            class="whitespace-nowrap py-8 first:pl-4 text-sm capitalize border-t-[1px] border-solid border-t-[#f1f2f4] first:text-teal-900"
          >
            <FlexRender
              :render="cell.column.columnDef.cell"
              :props="cell.getContext()"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <Spinner v-else class="p-2 relative bg-blue-100" />
  </div>
</template>
