<template>
  <div id="changeProgramForm" />
</template>

<script>
import { Formio } from "formiojs";
import tailwind from "@apiabroad/formio-tailwind-template";
import formix from "../../mixins/forms";
import { APICompany } from "../../constants";
import { mapGetters, mapMutations } from "vuex";
import impersonationFormSniffer from "../../mixins/ImpersonationFormSniffer";
import formService from "@/services/form";
import { APPLICATION_FEE_DATA } from "@/constants";
import { compareStringsInLowerCase } from "@/util/string";

const attributesList = [
  "focusable",
  "viewBox",
  "fill",
  "stroke",
  "stroke-width",
  "stroke-linecap",
  "stroke-linejoin",
  "cx",
  "cy",
  "r",
  "x1",
  "x2",
  "y1",
  "y2",
  "points",
  "d",
];
const tagsList = ["svg", "circle", "line", "polyline", "path"];
export default {
  name: "ChangeProgramForm",
  mixins: [formix, impersonationFormSniffer],
  props: {
    applications: {
      type: Array,
      default: function () {
        return new Array();
      },
      required: true,
    },
    userId: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      form: {},
      session: "",
      withdrawDone: false,
    };
  },
  computed: {
    ...mapGetters([
      "getStudentToken",
      "getCurrentApplicationId",
      "getProgramData",
    ]),
    programFormId() {
      //Gets the form.io form-id of the "Program Selection" form
      let programForm = this.forms.find(
        (form) => form["name"] === "Select Your Program"
      );
      return programForm !== undefined ? programForm.id : "";
    },
    paymentFormId() {
      let paymentForm = this.forms.find(
        (form) =>
          compareStringsInLowerCase(form["name"], APPLICATION_FEE_DATA.formName)|| form["name"] === "Institution Pays"
      );
      return paymentForm !== undefined ? paymentForm.id : "";
    },
    programSubmissionId() {
      //Gets the submission id of "Program Selection" for the current application
      let formSubmission =
        this.currentSubmissions !== undefined
          ? this.currentSubmissions["submissions"].find(
              (submission) =>
                submission["form"] === this.programFormId &&
                submission["data"]["application_id"] ===
                  this.getCurrentApplicationId
            )
          : "";
      return formSubmission !== undefined ? formSubmission["_id"] : "";
    },
    currentSubmissions() {
      return this.applications.find(
        (application) => application["id"] === this.getCurrentApplicationId
      );
    },
    paymentForm() {
      return this.currentSubmissions !== undefined
        ? this.currentSubmissions["submissions"].find(
            (submission) =>
              submission["form"] === this.paymentFormId &&
              submission["data"]["application_id"] ===
                this.getCurrentApplicationId
          )
        : undefined;
    },
    applicationWasPaid() {
      return this.paymentForm !== undefined;
    },
    transactionId() {
      return this.paymentForm !== undefined
        ? this.paymentForm.data.transactionId
        : "";
    },
    hasProgramManager() {
      return (
        Object.hasOwnProperty.call(this.getProgramData, "program_manager") &&
        Object.hasOwnProperty.call(
          this.getProgramData,
          "program_manager_email"
        ) &&
        this.getProgramData["program_manager"] &&
        this.getProgramData["program_manager_email"]
      );
    },
  },
  mounted() {
    let element = document.getElementById("changeProgramForm");
    const url = "/api/forms/programchanges";
    Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
    Formio.setAuthUrl(
      `${window.location.protocol}//${window.location.host}/api/forms`
    );
    Formio.use(tailwind);
    this.form = Formio.createForm(element, url, {
      sanitizeConfig: {
        addAttr: attributesList,
        addTags: tagsList,
      },
      hooks: {
        beforeSubmit: async (submission, next) => {
          let submitErrors = null;
          submission = this.attachEmployeeToSubmission(submission, "post");

          if (this.programFormId && this.programSubmissionId) {
            let withdraw = await this.withdrawApplication();
            if (withdraw === false) {
              submitErrors = [];
              submitErrors.push({
                message: "Could not withdraw application Form.io issue",
              });
            }
          } else {
            submitErrors = [];
            submitErrors.push({
              message: `We cannot change this application because there is no matching submission ID, please contact us at api${APICompany.companyEmail}`,
            });
          }
          next(submitErrors);
        },
      },
    });

    this.form.then((formio) => {
      formio.nosubmit = true;
      formio.on("submit", (submission) => {
        formService
          .createSubmission("programchange", submission)
          .then((submission) => formio.emit("submitDone", submission));
      });
      this.session = this.getSessionName(this.applications);
      formio.data.session = this.session;
      formio.data.application_id = this.getCurrentApplicationId;
      if (this.applicationWasPaid) {
        formio.data.applicationPaid = true;
        formio.data.applicationFee = this.getProgramData.application_fee;
        formio.data.transactionId = this.transactionId;
      }
      if (this.hasProgramManager) {
        formio.data.managerName = this.getProgramData["program_manager"];
        formio.data.managerMail = this.getProgramData["program_manager_email"];
      }
      formio.data.showAdvisorInfo = this.hasProgramManager ? "yes" : "no";
      formio.redraw();

      formio.on("submitDone", (event) => {
        this.logSubmission("programchanges", {}, event.data);

        // Save state
        this.setPreviousApplicationIdByField({
          handle: "previousApplicationId",
          value: event.data.application_id,
        });
        this.setPreviousApplicationIdByField({
          handle: "session",
          value: event.data.session,
        });
        this.setPreviousApplicationIdByField({
          handle: "paidFee",
          value:
            Object.hasOwnProperty.call(event.data, "applicationPaid") &&
            event.data["applicationPaid"],
        });
        this.setPreviousApplicationIdByField({
          handle: "applicationFee",
          value: Object.hasOwnProperty.call(event.data, "applicationFee")
            ? event.data["applicationFee"]
            : 0,
        });
        this.setPreviousApplicationIdByField({
          handle: "transactionId",
          value: Object.hasOwnProperty.call(event.data, "transactionId")
            ? event.data["transactionId"]
            : "",
        });
        this.setPreviousApplicationIdByField({
          handle: "submissionId",
          value: event["_id"],
        });
        // Remove current application
        this.setCurrentApplicationId("");
        // Redirect to program selection
        window.location = "/applications/new/program-selection";
      });
    });
  },
  methods: {
    ...mapMutations([
      "setPreviousApplicationIdByField",
      "setCurrentApplicationId",
    ]),
    getSessionName(applications) {
      let application = applications.find(
        (app) =>
          Object.hasOwnProperty.call(app, "id") &&
          app.id === this.getCurrentApplicationId
      );
      return application !== undefined &&
        Object.hasOwnProperty.call(application, "programSession") &&
        Object.hasOwnProperty.call(application["programSession"], "session") &&
        application["programSession"]["session"]
        ? application["programSession"]["session"]
        : "";
    },
    withdrawApplication() {
      return formService
        .partiallyUpdateSubmission(
          "programselection",
          this.programSubmissionId,
          [
            {
              op: "add",
              path: "/data/reason",
              value: "Application withdrawn by user",
            },
            {
              op: "add",
              path: "/data/withdrawnStatus",
              value: true,
            },
          ]
        )
        .then(() => true)
        .catch(() => false);
    },
  },
};
</script>
