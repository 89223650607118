import { learningServiceRequest } from "./utils";

const academicLevelsServices = {
  cache: {},
  async list(search) {
    if (this.cache[search]) {
      return this.cache[search];
    }

    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/academic_levels",
      params: { limit: 30, q: search },
      failureMessage: "Error loading Academic Levels",
    });
    if (response?.data?.items?.length > 0) {
      const sortedItems = reorderItemsByAcademicYear(response.data.items);
      this.cache[search] = sortedItems;
      return sortedItems;
    } else {
      return [];
    }
  },
  async getOne(academic_level_id = "") {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: `/academic_levels/${academic_level_id}`,
      failureMessage: "Error loading Academic Levels",
    });
    return response?.data ?? [];
  },
};

const academicYearOrder = [
  "High School First Year",
  "High School Second Year",
  "High School Third Year",
  "High School Fourth Year",
  "Undergraduate First Year",
  "Undergraduate Second Year",
  "Undergraduate Third Year",
  "Undergraduate Fourth Year",
  "Graduate",
  "Continuing Education",
  "Not Enrolled",
];

const reorderItemsByAcademicYear = (items) => {
  const sortedItems = items.sort((a, b) => {
    const aIndex = academicYearOrder.indexOf(a.academic_year);
    const bIndex = academicYearOrder.indexOf(b.academic_year);

    if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  });

  return sortedItems;
};

export default academicLevelsServices;
