<script setup>
import PriceDetails from "@/components/ExperiencePage/ProgramDetailsSection/PriceDetails.vue";
import {
  PROGRAM_SESSION_TERMS_DISPLAY_MAP,
  sessionType,
} from "@/components/program-manager/sessions/constants.js";
import SimplePopup from "@/components/shared/SimplePopup.vue";
import {
  buildStringDateRangeSessionTravel,
  formatDate,
} from "@/mixins/helpers";
import { convertCentsToDolarAsCurrency } from "@/services/utils";
import { formattedPriceFromCentsWithoutCents as formattedPrice } from "@/util/formatter";
import { computed, defineEmits, defineProps, toRefs } from "vue";
import { useStore } from "vuex";

const store = useStore();
const emit = defineEmits(["closeModal"]);

const props = defineProps({
  session: {
    type: Object,
    required: true,
    default: () => {},
  },
  program: {
    type: Object,
    required: true,
    default: () => {},
  },
  showHousingFee: {
    type: Boolean,
    default: true,
  },
  openModal: {
    type: Boolean,
    default: false,
  },
});

const { session, program, openModal } = toRefs(props);

const hasValueToShow = (field) =>
  field && !isNaN(Number(field)) && Number(field) > 0;

const termsLabel = computed(() => {
  const sortTerms = session.value?.terms?.map((term) => term.name) ?? [];
  const termKey = sortTerms.join(" + ");
  return PROGRAM_SESSION_TERMS_DISPLAY_MAP[termKey] || sortTerms.join(", ");
});

const city = computed(
  () => session.value?.session_travel_details?.[0]?.arrival_city
);
const duration = computed(
  () => session.value?.session_travel_details?.[0]?.duration
);

const programCostData = [
  {
    title: "Passport",
    description:
      "This program requires a valid passport. The estimate assumes the current price of obtaining a passport.",
    price: "$165.00",
  },
  {
    title: "Airfare",
    description:
      "Price varies depending on the airline, departure city, and checked luggage. ",
    price: "$600.00 - $2,700.00",
  },
];
const startingPriceData = computed(() => {
  let data = [];

  if (hasValueToShow(session.value?.total_academic_fees_price_in_cents)) {
    data.push({
      title: "Educational Fee",
      description:
        "Includes all tuition costs, administrative fees, included excursions, pre-departure and on-site orientations, pre-departure and on-site student services and support, post-program follow-up, and transcript services. All services and activities are designed to complement the student’s academic experience.",
      price: convertCentsToDolarAsCurrency(
        session.value.total_academic_fees_price_in_cents
      ),
    });
  }

  if (hasValueToShow(session.value?.housing_price_in_cents)) {
    data.push({
      title: "Housing Fee",
      description: "API programs are designed to include housing.",
      price: convertCentsToDolarAsCurrency(
        session.value.housing_price_in_cents
      ),
    });
  }

  if (hasValueToShow(session.value?.insurance_price_in_cents)) {
    data.push({
      title: "Insurance Price Per Session",
      description: "",
      price: convertCentsToDolarAsCurrency(
        session.value.insurance_price_in_cents
      ),
    });
  }

  return data;
});
const additionalCostData = computed(() => {
  let data = [];

  if (hasValueToShow(city.value?.average_semester_books)) {
    data.push({
      title: "Books",
      description: "Varies depending on area of study and host university.",
      price: convertCentsToDolarAsCurrency(city.value.average_semester_books),
    });
  }

  if (hasValueToShow(city.value?.average_daily_local_transport)) {
    data.push({
      title: "Transportation",
      description: `Average cost of trains and buses in ${city.value?.city_ascii}`,
      price: convertCentsToDolarAsCurrency(
        city.value.average_daily_local_transport * duration.value
      ),
    });
  }

  if (hasValueToShow(city.value?.average_daily_personal_expenses)) {
    data.push({
      title: "Living Costs",
      description:
        "Varies depending on spending habits but includes estimates from past students, cost of local activities, and doctor visits.",
      price: convertCentsToDolarAsCurrency(
        city.value.average_daily_personal_expenses * duration.value
      ),
    });
  }

  if (hasValueToShow(city.value?.average_daily_meals)) {
    data.push({
      title: "Meals",
      description: "",
      price: convertCentsToDolarAsCurrency(
        city.value.average_daily_meals * duration.value
      ),
    });
  }

  return data;
});

const isVirtual = computed(() =>
  session.value?.session_types?.some((val) => val.id == sessionType.virtual)
);

const redirect = (url) => {
  const programId = program.value?.id;
  const sessionId = session.value?.id;
  store.commit("setRedirectSessionData", {
    programId: programId,
    sessionId: sessionId,
  });

  window.location.href = url;
};
</script>

<template>
  <SimplePopup
    :open="openModal"
    custom-class="z-30 font-montserrat"
    :header-color-class="secondaryColor ? 'bg-gray-275' : 'bg-university-accentPrimary'"
    :full-screen="true"
    :show-close-button="false"
    @close="emit('closeModal')"
  >
    <template #title>
      <div class="flex self-center">
        <div class="self-center pl-2 uppercase font-bold text-sm">
          Price Breakdown
        </div>
      </div>
    </template>
    <template #content>
      <div class="text-left text-indigo-base">
        <h1 class="text-xl font-bold">
          {{ program?.name }}
          <span v-if="session?.terms?.length">({{ termsLabel }})</span>
        </h1>

        <p class="mt-2.5 text-sm font-medium">
          <img src="/images/icon-calendar.svg" class="inline mr-1.5 h-4 w-4" />
          Program dates
          <span class="uppercase font-bold">
            {{
              buildStringDateRangeSessionTravel(
                session?.session_travel_details,
                {
                  academic_start_date: session?.academic_start_date,
                  academic_end_date: session?.academic_end_date,
                }
              )
            }}*
          </span>
        </p>

        <p class="mt-2.5 text-sm font-medium">
          <img
            src="/images/icon_calendar_clock.svg"
            class="inline mr-1.5 h-4 w-4"
          />
          Application deadline
          <span class="uppercase font-bold">
            {{ formatDate(session?.application_deadline, "long") }}*
          </span>
        </p>

        <div class="md:flex md:items-center mt-2.5">
          <div>
            <p :class="`uppercase text-base ${secondaryColorClass} font-bold`">
              Starting at {{ formattedPrice(session?.base_price_in_cents) }}
            </p>
          </div>
          <div class="md:ml-5">
            <p class="text-sm">
              The price breakdown below should get you started, but for
              personalized pricing, please
              <span
                id="sign-in-link"
                :class="`font-bold ${secondaryColorClass} cursor-pointer`"
                @click="redirect('/sign-in')"
              >
                LOG IN
              </span>
              or
              <span
                id="create-account-link"
                :class="`font-bold ${secondaryColorClass} cursor-pointer`"
                @click="redirect('/create-account')"
              >
                CREATE ACCOUNT
              </span>
            </p>
          </div>
        </div>

        <div
          class="px-5 py-3 text-sm mt-6"
          :class="[
            secondaryColor ? 'bg-gray-100' : 'bg-university-accentSecondary',
          ]"
        >
          <p class="uppercase font-bold">
            <img src="/images/icon-print.svg" class="inline mr-1.5" />
            How to print
          </p>

          <ul class="hidden sm:block pl-8 list-disc font-medium">
            <li>
              On PC: highlight all text > right click > select Print > Save PDF
            </li>
            <li>
              On Mac: View > Show Reader > Command + P > select PDF > Print
            </li>
          </ul>

          <ul class="sm:hidden pl-8 list-disc font-medium">
            <li>
              On an iPhone with Face ID: Quickly press and release the side
              button and volume up button at the same time. When the screenshot
              appears toggle to “Full Page” and save the full-page screenshot.
            </li>
            <li>
              On an iPhone with a Home button: Quickly press and release the
              side button and the Home button at the same time. When the
              screenshot appears toggle to “Full Page” and save the full-page
              screenshot.
            </li>
          </ul>
        </div>

        <div v-if="startingPriceData.length" class="mt-6">
          <p class="font-semibold text-lg">Starting Price Breakdown</p>
          <div class="mt-4">
            <hr class="text-indigo-base" />
            <PriceDetails :data="startingPriceData" />
          </div>
        </div>

        <div class="mt-6">
          <p class="font-semibold text-lg">Variable Price Breakdown</p>
          <hr class="mt-4 text-indigo-base" />
          <div class="relative">
            <div
              class="absolute h-full w-full flex justify-center items-center"
            >
              <p class="text-center uppercase font-bold watermark transform">
                Estimate
              </p>
            </div>
            <template v-if="!isVirtual">
              <p class="mt-4 text-base font-bold">
                Costs to consider when preparing for your program
              </p>
              <PriceDetails :data="programCostData" class="mt-2" />
            </template>

            <template v-if="city && additionalCostData.length > 0">
              <p class="mt-4 text-base font-bold">
                Additional Estimated Cost of Living in
                {{ city.city_ascii }}, {{ city.country.name }}
                for the duration of your program
              </p>
              <PriceDetails :data="additionalCostData" class="mt-2" />
            </template>
          </div>
        </div>

        <p class="text-xs font-medium leading-4 mt-6">
          * This price breakdown is provided to assist students and university
          administrators to process financial aid awards. All fees are
          approximations and can fluctuate according to the exchange rates,
          program dates, program fees and changes in program structure. The
          amounts shown are NOT to be used for determining any amount of refund
          or discount for students who may have secured their own housing.
        </p>
      </div>
    </template>
  </SimplePopup>
</template>

<style scoped>
.watermark {
  font-size: 90px;
  color: #e6e6e6;
  opacity: 0.5;
  --tw-rotate: -30deg;
}

@screen md {
  .watermark {
    font-size: 150px;
  }
}
</style>
