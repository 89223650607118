<template>
  <table class="datatable md:bg-white">
    <thead class="hidden md:table-header-group">
      <tr>
        <th
          v-for="column in columns"
          :key="column.name"
          scope="col"
          :style="'width:' + column.width + ';'"
        >
          <div class="flex items-center justify-center">
            <div class="w-90p">{{ column.label }}</div>
            <div class="w-10p">
              <i
                v-if="column.sortable"
                class="mdi text-xl"
                :style="'cursor:pointer;'"
                :class="{
                  'mdi-chevron-down': sortOrders[column.name] <= 0,
                  'mdi-chevron-up': sortOrders[column.name] === 1,
                  'text-gray-400': sortOrders[column.name] === 0,
                }"
                @click="$emit('sort', column.name)"
              ></i>
            </div>
          </div>
        </th>
        <th scope="col">
          <span class="sr-only">Actions</span>
        </th>
      </tr>
    </thead>
    <slot />
  </table>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
    sortKey: {
      type: String,
      default: "",
    },
    sortOrders: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["sort"],
};
</script>
