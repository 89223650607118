<template>
  <div class="relative">
    <div
      v-if="getCurrentEditing && Object.keys(getCurrentEditing).length !== 0"
      class="p-4"
    >
      <div class="flex justify-between mb-1">
        <BreadCrumb ref="breadcrumb" />
        <div class="flex text-sm">
          <h3 class="font-bold mr-1">{{ itemType }} ID:</h3>
          <span>{{ getCurrentEditing.id }}</span>
        </div>
      </div>
      <div class="p-6">
        <div
          class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
        >
          <div class="flex-grow">
            <input
              id="name"
              :value="getCurrentEditing.name"
              type="text"
              name="getCurrentEditingMame"
              class="w-full text-2xl font-semibold leading-6 text-gray-900 bg-blue-100 focus:outline-none"
              @blur="v$.getCurrentEditing.name.$touch"
              @input="setCurrentEditingName($event.target.value)"
            />
            <div
              v-if="v$.getCurrentEditing.name.$errors.length"
              class="block text-sm error text-error-900"
            >
              <span
                v-for="(error, idx) in v$.getCurrentEditing.name.$errors"
                :key="idx"
              >
                {{ error.$message }}
              </span>
            </div>
          </div>
          <div class="flex items-center space-x-4 mt-3 sm:mt-0 sm:ml-4">
            <label class="block text-sm font-semibold text-gray-700"
              ><span class="text-red-100 pr-2 align-sub">*</span>Status</label
            >
            <div class="mt-1 block w-48">
              <v-select
                :model-value="getCurrentEditing.status"
                :options="statuses"
                :selectable="isStatusAllowed"
                @update:modelValue="changeHousingStatus($event)"
              ></v-select>
            </div>
            <button
              type="button"
              class="inline-flex items-center rounded-md border-[#007f80] border-solid border bg-[#007f80] px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#ffffff] hover:text-[#007f80]"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <TabGroup :selected-index="selectedTab" @change="changeTab">
        <TabList class="hidden sm:flex space-x-8 border-b border-gray-200">
          <template v-for="tab in getTabs" :key="tab.name">
            <Tab v-slot="{ selected }" as="template">
              <button
                :class="[
                  selected
                    ? 'border-[#007f80] text-[#007f80]'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'flex-1	whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                ]"
                :aria-current="selected ? 'page' : undefined"
              >
                <img
                  v-if="
                    isNotValid(tab.name) ||
                    v$.$getResultsForChild(tab.component)?.$errors?.length
                  "
                  src="/images/icon-warning-60.svg"
                  alt="Complete icon"
                  class="w-3.5 mb-1 !inline"
                />
                <span>&nbsp;{{ tab.name }}</span>
              </button>
            </Tab>
          </template>
        </TabList>
        <div class="sm:hidden">
          <Listbox
            :default-value="getTabs[0].name"
            :value="getTabs[selectedTab].name"
            as="div"
            @update:modelValue="updateListBox"
          >
            <div class="relative mt-1">
              <ListboxButton
                class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              >
                <span class="block truncate">{{ getTabs[0].name }}</span>
                <span
                  class="h-8 w-6 pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
              <ListboxOptions
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-for="tab in getTabs"
                  :key="tab.name"
                  v-slot="{ selected }"
                  as="template"
                  :value="tab.name"
                >
                  <li
                    :class="[
                      'relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900',
                    ]"
                  >
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                      >{{ tab.name }}</span
                    >
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
        <TabPanels>
          <div v-for="tab in getTabs" :key="tab.name">
            <TabPanel :unmount="false">
              <component
                :is="tab.component"
                ref="dynamicTab"
                :original-housing-record="getCurrentEditing"
              ></component>
            </TabPanel>
          </div>
        </TabPanels>
      </TabGroup>
      <div class="p-6">
        <div class="grid grid-cols-3 gap-4">
          <div>
            <BaseButton outlined type="button" @click.prevent="previousTab">
              Previous
            </BaseButton>
          </div>
          <div></div>
          <div class="relative">
            <BaseButton
              class="absolute right-0 inline-flex items-center !mr-0"
              type="button"
              @click.prevent="nextTab"
            >
              {{ `Save & ${lastTab ? "Finish" : "Next"}` }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <NavigateAwayWarning
      :open="warningModalOpen"
      :housing-name="getCurrentEditing.name"
      :error-message="warningModalError"
      :housing-valid="warningModalValid"
      @on-cancel="onWarningClose"
      @on-save="onWarningSave"
      @on-continue-without-saving="onWarningContinue"
    />
    <BookingConflictModal
      data-testid="booking-conflict-modal"
      :open="conflictBookingModalOpen"
      :conflict-booking-details="conflictBookingDetails"
      @on-close="onCloseConflictModal"
    />
    <Spinner
      v-if="isLoading"
      class="m-auto w-full h-full !fixed opacity-75 bg-blue-100 !z-0"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import useVuelidate from "@vuelidate/core";
import { cloneDeep } from "lodash";
import { helpers, required } from "@vuelidate/validators";
import GeneralInfoTab from "./components/GeneralInfoTab";
import ImagesVideosTab from "./components/ImagesVideosTab";
import HouseRulesTab from "./components/HouseRulesTab";
import SafetySecurityTab from "./components/SafetySecurityTab";
import BuildingAmenitiesTab from "./components/BuildingAmenitiesTab";
import UnitsTab from "./components/UnitsTab";
import NavigateAwayWarning from "./components/NavigateAwayWarning";
import BookingConflictModal from "./components/BookingConflictModal";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import BreadCrumb from "@/components/shared/BreadCrumb.vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import Spinner from "@/components/helpers/Spinner.vue";
import {
  STATUS,
  HOUSING_TYPES_MAP,
  ERROR_TIMEOUT,
  SUCCESS_TIMEOUT,
} from "@/constants.js";
import {
  hasPublishPermission,
  hasWritePermission,
} from "@/composables/authorization.js";
import { dollarsToCents } from "@/mixins/helpers";

const SUCCESSFULLY_SAVED = "Successfully saved";

export default {
  name: "HousingEdit",
  components: {
    BreadCrumb,
    BaseButton,
    ChevronDownIcon,
    Spinner,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    NavigateAwayWarning,
    GeneralInfoTab,
    ImagesVideosTab,
    HouseRulesTab,
    SafetySecurityTab,
    BuildingAmenitiesTab,
    UnitsTab,
    BookingConflictModal,
  },
  beforeRouteEnter(to, from, next) {
    to.meta.breadcrumb[1] = {
      title: to.meta.subcategory.replace(/^\w/, (c) => c.toUpperCase()),
      parent: "program-manager-" + to.meta.subcategory,
      active: false,
    };
    next();
  },
  async beforeRouteLeave(to, from) {
    if (this.v$.$anyDirty && !this.warningModalOpen && !this.navigateTo) {
      this.warningModalError = "";
      this.navigateTo = to;
      this.warningModalValid = await this.v$.$validate();
      this.warningModalOpen = true;
      return false;
    } else {
      return true;
    }
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      selectedTab: 0,
      warningModalOpen: false,
      warningModalError: "",
      navigateTo: undefined,
      warningModalValid: undefined,
      loading: false,
      statuses: STATUS,
      items: {
        fetchers: {
          housing: this.fetchSingleHousingObject,
          programs: this.fetchSingleProgramObject,
        },
        types: {
          housing: "Housing",
          programs: "Programs",
        },
      },
      conflictBookingModalOpen: false,
    };
  },
  computed: {
    ...mapGetters("programManager", [
      "getCurrentEditing",
      "getCurrentEditingUnits",
      "getCurrentHousingType",
      "getLoading",
      "getRedirect",
      "getInvalidForm",
      "getUnitTabValidation",
      "getTermsIsLoading",
      "getVendorsIsLoading",
      "getVendorsData",
      "getTermsData",
      "getGenericListing",
      "getEditHousingError",
      "getConflictBookingsDetails",
      "getUnitConflictBookingsDetails",
    ]),
    getTabs() {
      if (!this.getGenericListing) {
        switch (this.getCurrentHousingType) {
          case HOUSING_TYPES_MAP.APARTMENT:
          case HOUSING_TYPES_MAP.DORM:
          case HOUSING_TYPES_MAP.HOTEL:
            return [
              { name: "General Info", component: "GeneralInfoTab" },
              { name: "Images & Videos", component: "ImagesVideosTab" },
              { name: "House Rules", component: "HouseRulesTab" },
              { name: "Safety & Security", component: "SafetySecurityTab" },
              { name: "Building Amenities", component: "BuildingAmenitiesTab" },
              { name: "Units", component: "UnitsTab" },
            ];
          case HOUSING_TYPES_MAP.LOCAL_HOST:
          case HOUSING_TYPES_MAP.HOUSE:
            return [
              { name: "General Info", component: "GeneralInfoTab" },
              { name: "Image & Videos", component: "ImagesVideosTab" },
              { name: "Building Amenities", component: "BuildingAmenitiesTab" },
              { name: "Units", component: "UnitsTab" },
            ];
          default:
            return [
              { name: "General Info", component: "GeneralInfoTab" },
              { name: "Image & Videos", component: "ImagesVideosTab" },
              { name: "House Rules", component: "HouseRulesTab" },
              { name: "Safety & Security", component: "SafetySecurityTab" },
              { name: "Building Amenities", component: "BuildingAmenitiesTab" },
              { name: "Units", component: "UnitsTab" },
            ];
        }
      } else {
        return [
          { name: "General Info", component: "GeneralInfoTab" },
          { name: "Image & Videos", component: "ImagesVideosTab" },
          { name: "Building Amenities", component: "BuildingAmenitiesTab" },
          { name: "Units", component: "UnitsTab" },
        ];
      }
    },
    itemType() {
      return this.items.types[this.$route.meta?.subcategory];
    },
    isLoading() {
      return (
        this.getLoading ||
        this.getTermsIsLoading ||
        this.getVendorsIsLoading ||
        !this.getCurrentEditing
      );
    },
    lastTab() {
      return this.selectedTab === this.getTabs.length - 1;
    },
    conflictBookingDetails() {
      return (
        this.getConflictBookingsDetails || this.getUnitConflictBookingsDetails
      );
    },
  },
  watch: {
    getCurrentEditing: {
      handler(current) {
        this.$route.meta.breadcrumb.splice(2, 1, {
          title: `Create ${current?.type}`,
          active: true,
        });
        this.$refs.breadcrumb?.forceUpdate();
      },
    },
    getEditHousingError: {
      handler(current) {
        if (current) {
          this.$toast.open({
            message: current,
            type: "error",
            duration: ERROR_TIMEOUT,
            onDismiss: this.onDismissAlert,
          });
        }
      },
    },
  },
  mounted() {
    window.addEventListener("beforeunload", () => {
      this.setCurrentEditing({});
    });
  },
  async created() {
    this.resetControlVariables();
    this.setLoading(true);

    // Load data
    await this.fetchAmenitiesData();
    await this.fetchTagsData();
    if (!this.getVendorsData?.length) {
      await this.fetchVendors();
    }
    if (!this.getTermsData?.length) {
      await this.fetchTerms();
    }

    if (this.$route.meta.breadcrumb.length < 3) {
      this.$route.meta.breadcrumb.push({
        title: "Create ",
        active: true,
      });
    }

    // Load Object
    const itemId = this.$route.params?.itemId;
    await this.items.fetchers[this.$route.meta?.subcategory](itemId);
  },
  unmounted() {
    this.setCurrentEditing({});
  },
  validations: {
    getCurrentEditing: {
      name: {
        required: helpers.withMessage("Housing name is required", required),
      },
    },
  },
  methods: {
    onDismissAlert() {
      if (this.getRedirect) {
        this.$router.replace({ name: "program-manager-housing" });
      }
    },
    isNotValid(tabName) {
      return tabName === "UnitsTab" && !this.getInvalidForm;
    },
    onWarningClose() {
      this.warningModalError = "";
      this.navigateTo = undefined;
      this.warningModalValid = undefined;
      this.warningModalOpen = false;
    },
    onWarningContinue() {
      this.warningModalError = "";
      this.warningModalValid = undefined;
      this.warningModalOpen = false;
      this.$router.push(this.navigateTo);
    },
    async onWarningSave() {
      const allValid = await this.validate();
      if (allValid) {
        const saveHousingAndUnitsSuccessfully = await this.saveHousingAndUnits();

        if (saveHousingAndUnitsSuccessfully) {
          this.$toast.open({
            message: SUCCESSFULLY_SAVED,
            type: "success",
            duration: SUCCESS_TIMEOUT,
          });
          this.$router.push(this.navigateTo);
        } else {
          this.warningModalError = "Housing failed to save.";
        }
      } else {
        this.warningModalError = "Please fix housing errors before saving";
      }
    },
    async validate() {
      this.setInvalidForm(false);
      await this.validateUnitTab();
      const validations = this.getUnitTabValidation;
      const valid = await this.v$.$validate();

      if (!valid || validations.checkForInvalidRoom) {
        this.setInvalidForm(false);
        return false;
      }
      return true;
    },
    async changeHousingStatus(value) {
      this.setCurrentEditingStatus(value);
      await this.validate();
    },
    async saveHousingUnits() {
      let current = this.getCurrentEditing;
      let unitsToSend = cloneDeep(this.getCurrentEditingUnits);
      for (let unit of unitsToSend) {
        for (let room of unit.rooms) {
          room.room_amenities = room.room_amenities.filter(
            (amenity) => amenity.checked
          );
          room.cost_in_cents = dollarsToCents(room.cost_in_cents, true);
          room.product_prices = room.product_prices?.map((price) => {
            price.price_in_cents = dollarsToCents(price.price_in_cents, true);
            return price;
          });
        }
      }
      // Request!
      return await this.saveHousingUnitsRequest({
        housing_id: current.id,
        units: unitsToSend,
      });
    },

    async saveHousing() {
      const current = this.getCurrentEditing;
      let tabUpdates = this.$refs.dynamicTab.reduce((acc, ref) => {
        if (ref.getUpdates) {
          const { updates = {} } = ref.getUpdates();
          acc = { ...acc, ...updates };
        }
        return acc;
      }, {});

      return await this.saveHousingRequest({
        housing_id: current.id,
        housing_data: { ...current, ...tabUpdates },
      });
    },
    async saveHousingAndUnits() {
      const saveHousingUnitsSuccessfully = await this.saveHousingUnits();
      const saveHousingSuccessfully = await this.saveHousing();
      return saveHousingUnitsSuccessfully && saveHousingSuccessfully;
    },
    ...mapActions("programManager", [
      "fetchSingleHousingObject",
      "fetchSingleProgramObject",
      "fetchAmenitiesData",
      "resetControlVariables",
      "setRedirect",
      "setCurrentEditing",
      "resetCurrentEditingCurfew",
      "setInvalidForm",
      "setLoading",
      "validateUnitTab",
      "saveHousingRequest",
      "saveHousingUnitsRequest",
      "fetchTerms",
      "fetchVendors",
    ]),
    ...mapActions("tagManager", ["fetchTagsData"]),
    ...mapMutations("programManager", [
      "createNewHousingForEditing",
      "setCurrentEditingName",
      "setCurrentEditingStatus",
      "setConflictBookingsDetails",
    ]),
    previousTab() {
      if (this.selectedTab > 0) {
        this.selectedTab = this.selectedTab - 1;
      }
    },
    onCloseConflictModal() {
      this.conflictBookingModalOpen = false;
      this.setConflictBookingsDetails();
    },
    async nextTab() {
      const allValid = await this.validate();
      if (allValid) {
        const saveHousingAndUnitsSuccessfully = await this.saveHousingAndUnits();

        if (saveHousingAndUnitsSuccessfully) {
          this.$toast.open({
            message: SUCCESSFULLY_SAVED,
            type: "success",
            duration: SUCCESS_TIMEOUT,
          });
        } else if (this.conflictBookingDetails) {
          this.conflictBookingModalOpen = true;
        }

        if (
          saveHousingAndUnitsSuccessfully &&
          this.selectedTab < this.getTabs.length
        ) {
          if (this.lastTab) {
            this.navigateTo = { name: "program-manager-housing" };
            this.$router.push(this.navigateTo);
          } else {
            this.selectedTab = this.selectedTab + 1;
          }
        }
      } else if (!this.lastTab) {
        this.selectedTab = this.selectedTab + 1;
      }
    },
    changeTab(index) {
      this.selectedTab = index;
    },
    updateListBox(newValue) {
      this.selectedTab = this.getTabs.findIndex((val) => val.name === newValue);
    },
    hasPublishPermissionToChangeStatus(status) {
      return ["Active", "Archived"].includes(status) && hasPublishPermission();
    },
    hasWritePermissionToChangeStatus(status) {
      return ["Draft", "Inactive"].includes(status) && hasWritePermission();
    },
    isStatusAllowed(status) {
      return (
        this.hasPublishPermissionToChangeStatus(status) ||
        this.hasWritePermissionToChangeStatus(status)
      );
    },
  },
};
</script>

<style>
.align-sub {
  vertical-align: sub;
}
</style>
