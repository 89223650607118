import {
  V3_rejectNavigation,
  V3_rejectNavigation_no_auth,
  V3_rejectNavigation_no_program_session,
} from "@/composables/VersionHelper.js";
import MyApplications from "./components/MyApplications.vue";
import EnrollmentLanding from "./components/EnrollmentLanding.vue";
import TravelPlans from "./components/travels/travel-plans/travel-plans.vue";
import CodeOfConduct from "./components/codeOfConduct/CodeOfConduct.vue";
import HealthWellnessForm from "./components/HealthWellness/HealthWellnessForm.vue";
import ParticipantAgreement from "./components/agreements/participant-agreement";
import FinancialAidVerification from "./components/financialAid/FinancialAidVerification.vue";
import NetworkOfSupportPage from "./components/NetworkOfSupport/NetworkOfSupportPage.vue";
import AcademicInformation from "./components/academicInformation/AcademicInformation.vue";
import ParsonsParisAgreement from "./components/parsonsParisAgreement/ParsonsParisAgreement.vue";
import PolimodaRegulationsPolicy from "./components/forms/PolimodaRegulationsPolicy/PolimodaRegulationsPolicy.vue";
import SingleFormLoader from "./components/Onboarding/SingleFormLoader";
import SignIn from "./components/SignIn.vue";
import CoursePage from "./components/forms/CourseSelection/CoursePage.vue";
import GroupVisaForm from "./components/forms/GroupVisaInformation/GroupVisaForm.vue";
import HousingForm from "./components/forms/Housing/HousingForm.vue";
import PassportsForm from "./components/forms/Passport/PassportsForm.vue";
import VisaAppointment from "./components/forms/VisaAppointment/VisaAppointmentForm.vue";
import VisaAndPermitForm from "./components/forms/VisaAndPermit/VisaAndPermitForm.vue";
import EULA_Form from "@/components/forms/EULA/EULA_Form.vue";
import HeadShotForm from "./components/forms/HeadShot/HeadshotForm.vue";
import BadgesForm from "@/components/forms/BadgesInterests/BadgesPage.vue";
import CPCodeOfConduct from "@/components/codeOfConduct/CustomizedCodeOfConduct.vue";
import SupplementalForm from "./components/forms/Supplemental/SupplementalForm.vue";
import ParticipantAgreementIND from "@/components/forms/ParticipantAgreementIND/ParticipantAgreementForm.vue";
import ParticipantAgreementID from "./components/forms/ParticipantAgreementID/ParticipantAgreementForm.vue";
import errorPage from "./components/errorPage.vue";
import Page404 from "./university/views/404Page.vue";
import { CONSTANT_KEYS, PERMISSIONS } from "./constants";
import NoInsuranceDirectPayForm from "./components/forms/ParticipantAgreement/NoInsuranceDirectPayForm.vue";
import CPAgreementNotInsuranceOrDpay from "@/components/agreements/customized/NoInsuranceOrPayForm.vue";
import HSCodeOfConductForm from "@/components/forms/HSCodeOfConduct/HSCodeOfConductForm.vue";
import EmployeeRegister from "./components/auth/EmployeeRegister";
import Impersonation from "./components/Impersonation/Students";
import PaymentView from "./components/PaymentView";
import LandingPage from "./components/LandingPage";
import MyStudents from "@/university/views/MyStudents";
import StudentDetail from "@/university/views/StudentDetail";
import NotificationSettings from "@/university/views/NotificationSettings.vue";
import StudentManagement from "@/components/StudentManagement.vue";
import StudentFulfillmentManager from "@/components/StudentFulfillment/components/MainPage";
import OrderDetail from "@/components/StudentFulfillment/components/OrderDetail";
import ViewReports from "@/components/StudentFulfillment/components/report/ViewReports";
import ProgramManager from "@/components/program-manager";
import CurriculumIntegrationApp from "@/components/CurriculumIntegrationApp";
import HomeApp from "@/components/HomeApp";
import MyStudentsApp from "@/components/MyStudentsApp";
import FormsApp from "@/components/FormsApp";
import CareerAndSkillsApp from "@/components/CareerAndSkillsApp";
import ResearchApp from "@/components/ResearchApp";
import ProgramManagerViewMore from "@/components/program-manager/view-more";
import ProgramManagerCreateEdit from "@/components/program-manager/create-edit";
import FareFinderView from "@/components/FareFinder/FareFinderView";
import SettingsView from "@/components/settings/FormattingPage.vue";
import Housing from "@/components/program-manager/housing";
import InternshipForm from "@/components/InternshipLibrary/InternshipForm";
import LayoutLoader from "@/components/LayoutLoader.vue";
import Configurator from "@/components/Configurator";
import CreateEditEvents from "@/components/program-manager/events/CreateEditEvents.vue";
import HostInstitution from "@/components/HostInstitution";
import HostInstitutionDetails from "@/components/HostInstitution/HostInstitutionDetails.vue";
import CitiesLandingPage from "@/components/Cities";
import CityDetails from "@/components/Cities/CityDetails.vue";
import InternshipProductPageView from "@/components/ExperiencePage/Views/InternshipProductPageView.vue";
import CourseProductPageView from "@/components/ExperiencePage/Views/CourseProductPageView.vue";
import HousingProductPageView from "@/components/Configurator/Housing/HousingProductPageView.vue";
import ProgramDetailsView from "@/components/ExperiencePage/Views/ProgramDetailsView.vue";
import AccountCreationSuccess from "@/components/auth/AccountCreation/AccountCreationSuccess.vue";
import LayoutV3 from "@/components/forms/applicationSteps/StepsLayoutV3.vue";
import ResumeV3 from "@/components/ExperiencePage/Application/Steps/ResumeApplication.vue";
import ApplicationExists from "@/components/ExperiencePage/Views/ApplicationExistsPage.vue";
import Profile from "@/components/Profile";
import EditViewProfile from "@/components/Profile/EditView.vue";
import store from "./store";
import AccountPage from "./components/Account/AccountPage.vue";
import RootWrapper from "@/components/ExperiencePage/Algolia/AlgoliaRootWrapper.vue";
import Results from "@/components/ExperiencePage/Algolia/AlgoliaResults";
// import CreateEditProgramSessions from "./components/program-manager/sessions/components/CreateEditProgramSessions";
import HousingQuestionnaireForm from "@/components/forms/HousingQuestionnaire/HousingQuestionnaireForm.vue";
import { checkCurriculumAppAccess } from "@/composables/authorization.js";

const routes = [
  {
    path: "/",
    component: RootWrapper,
    name: "home",
    props: true,
    meta: {
      title: "",
      exemptFromLogin: true,
    },
  },
  {
    path: "/fare-finder",
    component: FareFinderView,
    name: "fare-finder",
    meta: {
      title: "Fare Finder",
      permissions: ["participants"],
    },
  },
  {
    path: "/application-exists",
    component: ApplicationExists,
    name: "application-exists",
    meta: {
      title: "Application Exists",
    },
  },
  {
    path: "/account",
    component: AccountPage,
    name: "account",
    meta: {
      title: "Account Settings",
    },
    children: [
      {
        path: "profiles/:profileId",
        name: "edit-profile",
        component: EditViewProfile,
        meta: {
          title: "View/Edit Profile Detail",
          description: "View/Edit Detail",
        },
      },
      {
        path: "settings/host-institutions",
        name: "host-institutions",
        component: HostInstitution,
      },
      {
        path: "settings/host-institutions/:hostInstitutionId",
        name: "host-institution-edit",
        component: HostInstitutionDetails,
        props: true,
        meta: {
          title: "Host Institution",
          breadcrumb: [
            { title: "Host Institutions", parent: "host-institutions" },
            {
              title: "Edit Host Institution Details",
              parent: "host-institution-edit",
            },
          ],
          subcategory: "host-institutions",
          permissions: [PERMISSIONS.ITEMS_WRITE],
        },
      },
      {
        path: "settings/cities",
        name: "cities",
        component: CitiesLandingPage,
      },
      {
        path: "settings/cities/:cityId",
        name: "city-edit",
        component: CityDetails,
        props: true,
        meta: {
          title: "Cities",
          breadcrumb: [
            { title: "Cities", parent: "cities" },
            {
              title: "Edit City Details",
              parent: "city-edit",
            },
          ],
          subcategory: "cities",
          permissions: [PERMISSIONS.ITEMS_WRITE],
        },
      },
      {
        path: "settings/brand",
        name: "brand",
        component: SettingsView,
        permissions: [PERMISSIONS.API_EMPLOYEE],
      },
      {
        path: "settings/user-management",
        name: "user-management",
        component: Profile,
        meta: {
          permissions: [PERMISSIONS.USERS_WRITE],
        },
      },
      {
        path: "settings/profiles/:participantId",
        name: "user-management-profile",
        component: EditViewProfile,
        meta: {
          permissions: [PERMISSIONS.USERS_WRITE],
        },
      },
      {
        path: "about-you/",
        name: "about-you",
        component: EditViewProfile,
        meta: {
          permissions: [PERMISSIONS.USERS_READ],
        },
      },
    ],
  },
  {
    path: "/settings",
    component: SettingsView,
    name: "settings",
    meta: {
      title: "Settings",
      permissions: [PERMISSIONS.API_EMPLOYEE],
    },
  },
  {
    path: "/landing-page",
    component: LandingPage,
    name: "landing-page",
    meta: {
      title: "API Connect",
    },
  },
  {
    path: "/EULA",
    component: EULA_Form,
    name: "EULA",
    meta: {
      title: "End User License Agreement",
    },
  },
  {
    path: "/quiz",
    component: "",
    name: "Quiz",
    meta: {
      title: "Quiz",
    },
  },
  {
    path: "/applications",
    component: MyApplications,
    name: "applications",
    meta: {
      title: "My Applications",
    },
  },
  {
    path: "/:applicationId/make-payment",
    component: PaymentView,
    name: "payment",
    meta: {
      title: "Payment",
    },
  },
  {
    path: "/error-page",
    component: errorPage,
    name: "error-page",
    meta: {
      title: "Error",
    },
    props: true,
  },
  {
    path: "/program/:programSessionId?",
    component: () => {
      if (CONSTANT_KEYS.UI_VERSION === "v2") {
        return import(
          "./components/forms/customProgram/CustomProgramLandingPage"
        );
      } else {
        return import("./components/errorPage");
      }
    },
    name: "customProgram",
    meta: {
      title: "New custom program",
    },
  },
  {
    path: "/application-create-account/:sessionId",
    component: LayoutV3,
    name: "new-account-creation",
    meta: {
      title: "Create an account",
      exemptFromLogin: true,
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
    beforeEnter: [
      V3_rejectNavigation_no_auth,
      V3_rejectNavigation_no_program_session,
    ],
  },
  {
    path: "/application-about-you/:applicationId",
    component: LayoutV3,
    name: "application-about-you",
    meta: {
      title: "About You",
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
    beforeEnter: [V3_rejectNavigation, V3_rejectNavigation_no_program_session],
  },
  {
    path: "/application-learner-info/:applicationId",
    component: LayoutV3,
    name: "application-learner-info",
    meta: {
      title: "Learner Information",
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
    beforeEnter: [V3_rejectNavigation, V3_rejectNavigation_no_program_session],
  },
  {
    path: "/application-review/:applicationId",
    component: LayoutV3,
    name: "application-review",
    meta: {
      title: "Review application",
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
    beforeEnter: [V3_rejectNavigation, V3_rejectNavigation_no_program_session],
  },
  {
    path: "/application-group-visa/:applicationId",
    component: LayoutV3,
    name: "application-group-visa",
    meta: {
      title: "Group Visa Service",
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
    beforeEnter: [V3_rejectNavigation, V3_rejectNavigation_no_program_session],
  },
  {
    path: "/application-checkout/:applicationId",
    component: LayoutV3,
    name: "application-checkout",
    meta: {
      title: "Checkout",
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
    beforeEnter: [V3_rejectNavigation, V3_rejectNavigation_no_program_session],
  },
  {
    path: "/application-next-steps/:applicationId",
    component: LayoutV3,
    name: "application-next-steps",
    meta: {
      title: "Next steps",
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
    beforeEnter: [V3_rejectNavigation, V3_rejectNavigation_no_program_session],
  },
  {
    path: "/application-resume/:applicationId",
    component: ResumeV3,
    name: "application-resume",
    meta: {
      title: "Resuming application",
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
    beforeEnter: [V3_rejectNavigation],
  },
  {
    path: "/applications/new",
    redirect: "/applications/new/program-selection",
    meta: {
      title: "Program selection",
    },
  },
  {
    path: "/curriculum-integration",
    name: "curriculum-integration-app",
    component: CurriculumIntegrationApp,
    meta: {
      title: "Curriculum Integration",
    },
    beforeEnter: () => {
      return checkCurriculumAppAccess() ? true : { name: "landing-page" };
    },
  },
  {
    path: "/research",
    name: "research-app",
    component: ResearchApp,
    meta: {
      title: "Research",
      permissions: [
        PERMISSIONS.TABLEAU_VIEWER,
        PERMISSIONS.TABLEAU_VIEWER_MEMBER,
        PERMISSIONS.ITEMS_READ,
        PERMISSIONS.ITEMS_READ_LITE,
        PERMISSIONS.ORDERS_READ,
      ],
    },
  },
  {
    path: "/home",
    name: "home-app",
    component: HomeApp,
    meta: {
      title: "Home",
      permissions: [
        PERMISSIONS.TABLEAU_VIEWER,
        PERMISSIONS.ITEMS_READ,
        PERMISSIONS.ITEMS_READ_LITE,
        PERMISSIONS.ORDERS_READ,
      ],
    },
  },
  {
    path: "/my-students/benchmarking",
    name: "my-students-app-benchmarking",
    component: MyStudentsApp,
    meta: {
      title: "My Students - Benchmarking",
      permissions: [
        PERMISSIONS.TABLEAU_VIEWER,
        PERMISSIONS.ITEMS_READ,
        PERMISSIONS.ITEMS_READ_LITE,
        PERMISSIONS.ORDERS_READ,
      ],
    },
  },
  {
    path: "/my-students/forms",
    name: "my-students-app-forms",
    component: FormsApp,
    meta: {
      title: "My Students - Forms",
      permissions: [
        PERMISSIONS.TABLEAU_VIEWER,
        PERMISSIONS.ITEMS_READ,
        PERMISSIONS.ITEMS_READ_LITE,
        PERMISSIONS.ORDERS_READ,
      ],
    },
  },
  {
    path: "/career-and-skills",
    name: "career-and-skills-app",
    component: CareerAndSkillsApp,
    meta: {
      title: "Career And Skills",
      permissions: [
        PERMISSIONS.TABLEAU_VIEWER,
        PERMISSIONS.ITEMS_READ,
        PERMISSIONS.ITEMS_READ_LITE,
        PERMISSIONS.ORDERS_READ,
      ],
    },
  },
  {
    path: "/applications/new/:slug",
    component: LayoutLoader,
    name: "applications/new",
    props: true,
    meta: {
      title: "New application",
    },
  },
  {
    path: "/:applicationId/university-approval-form",
    redirect: "/:applicationId/university-approval-request",
  },
  {
    path: "/applications/:applicationId/:slug?",
    component: LayoutLoader,
    name: "applications/resume",
    props: true,
    meta: {
      title: "Resume Application",
    },
  },
  {
    path: "/enrollment/:applicationId",
    component: EnrollmentLanding,
    name: "applications/landing",
    props: true,
    meta: {
      title: "Enrollment",
    },
  },
  {
    path: "/enrollment/:applicationId/travel-plans",
    component: TravelPlans,
    name: "enrollment/travel-plans",
    props: true,
    meta: {
      title: "Travel Plans",
    },
  },
  {
    path: "/enrollment/:applicationId/health-wellness",
    component: HealthWellnessForm,
    name: "enrollment/health-wellness",
    props: true,
    meta: {
      title: "Health & Wellness",
    },
  },
  {
    path: "/enrollment/:applicationId/participant-agreement",
    component: ParticipantAgreement,
    name: "enrollment/participant-agreement",
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/enrollment/:applicationId/financial-aid-verification",
    component: FinancialAidVerification,
    name: "enrollment/financial-aid-verification",
    props: true,
    meta: {
      title: "Financial aid verification",
    },
  },
  {
    path: "/enrollment/:applicationId/code-of-conduct",
    component: CodeOfConduct,
    name: "enrollment/code-of-conduct",
    props: true,
    meta: {
      title: "Code of Conduct",
    },
  },
  {
    path: "/enrollment/:applicationId/network-of-support",
    component: NetworkOfSupportPage,
    name: "enrollment/network-of-support",
    props: true,
    meta: {
      title: "Network of Support",
    },
  },
  {
    path: "/sign-in/:component?",
    component: SignIn,
    name: "sign-in",
    props: true,
    meta: {
      title: "Sign in",
      exemptFromLogin: true,
    },
  },
  {
    path: "/sign-in/change-password/:token",
    component: SignIn,
    name: "change-password",
    props: true,
    meta: {
      title: "Sign in",
      exemptFromLogin: true,
    },
  },
  {
    path: "/employee-register",
    component: EmployeeRegister,
    name: "employee-register",
    meta: {
      title: "Register employee account",
    },
    props: true,
  },
  {
    path: "/create-account",
    component: SignIn,
    name: "create-account",
    meta: {
      title: "Create account",
      exemptFromLogin: true,
    },
  },
  {
    path: "/create-account-success",
    component: AccountCreationSuccess,
    name: "create-account-success",
    meta: {
      title: "Create Account Success",
    },
  },
  {
    path: "/enrollment/:applicationId/academic-information",
    component: AcademicInformation,
    name: "enrollment/academic-information",
    props: true,
    meta: {
      title: "Academic Information",
    },
  },
  {
    path: "/enrollment/:applicationId/parsons-paris-agreement",
    component: ParsonsParisAgreement,
    name: "enrollment/parsons-paris-agreement",
    props: true,
    meta: {
      title: "Parsons Paris Agreement",
    },
  },
  {
    path: "/:applicationId/polimoda-student-regulation-policy",
    component: PolimodaRegulationsPolicy,
    name: "polimoda-student-regulation-policy",
    props: true,
    meta: {
      title: "Polimoda Student Regulation Policy",
    },
  },
  {
    path: "/:applicationId/global-course-selection",
    component: CoursePage,
    name: "global-course-form",
    props: true,
    meta: {
      title: "Global Course Form",
    },
  },
  {
    path: "/:applicationId/passport",
    component: PassportsForm,
    name: "passport",
    props: true,
    meta: {
      title: "Passport",
    },
  },
  {
    path: "/:applicationId/housing",
    component: HousingForm,
    name: "housing",
    props: true,
    meta: {
      title: "Housing",
    },
  },
  {
    path: "/:applicationId/group-visa-information",
    component: GroupVisaForm,
    name: "group-visa-information",
    props: true,
    meta: {
      title: "Group Visa Information",
    },
  },
  {
    path: "/:applicationId/visa-appointment-details",
    component: VisaAppointment,
    name: "visa-appointment-details",
    props: true,
    meta: {
      title: "Visa Appointment Details",
    },
  },
  {
    path: "/:applicationId/visa-permit",
    component: VisaAndPermitForm,
    name: "visa-permit",
    props: true,
    meta: {
      title: "Visa Permit",
    },
  },
  {
    path: "/:applicationId/headshot",
    component: HeadShotForm,
    name: "headshot",
    props: true,
    meta: {
      title: "Headshot",
    },
  },
  {
    path: "/:applicationId/digital-badges",
    component: BadgesForm,
    name: "digitalBadges",
    props: true,
    meta: {
      title: "Digital Badges",
    },
  },
  {
    path: "/:applicationId/customized-code-of-conduct",
    component: CPCodeOfConduct,
    name: "cpcodeofconduct",
    props: true,
    meta: {
      title: "Customized Code of Conduct",
    },
  },
  {
    path: "/:applicationId/supplemental-form",
    component: SupplementalForm,
    name: "supplemental-form",
    props: true,
    meta: {
      title: "Supplemental Form",
    },
  },
  {
    path: "/:applicationId/custom-participant-agreement-i-nd",
    component: ParticipantAgreementIND,
    name: "custom-participant-agreement-i-nd",
    props: true,
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/:applicationId/custom-participant-agreement-i-d",
    component: ParticipantAgreementID,
    name: "custom-participant-agreement-i-d",
    props: true,
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/:applicationId/custom-participant-agreement-ni-d",
    component: NoInsuranceDirectPayForm,
    name: "custom-participant-agreement-ni-d",
    props: true,
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/:applicationId/custom-participant-agreement-ni-nd",
    component: CPAgreementNotInsuranceOrDpay,
    name: "noinsuranceorpayagreement",
    props: true,
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/:applicationId/hs-code-of-conduct",
    component: HSCodeOfConductForm,
    name: "hs-code-of-conduct",
    props: true,
    meta: {
      title: "HS Code of Conduct",
    },
  },
  {
    path: "/:applicationId/housing-questionnaire",
    component: HousingQuestionnaireForm,
    name: "housing-questionnaire",
    props: true,
    meta: {
      title: "Housing questionnaire",
    },
  },
  {
    path: "/:applicationId/:form",
    component: SingleFormLoader,
    name: "form-loader",
    props: true,
  },
  {
    path: "/students",
    component: Impersonation,
    name: "impersonation",
    props: true,
    meta: { permissions: ["api-employee-student-view"] },
  },
  {
    path: "/my-students",
    component: MyStudents,
    name: "student-management",
    props: true,
    meta: {
      title: "Student Management",
      permissions: ["student-management"],
    },
  },
  {
    path: "/student-management",
    component: StudentManagement,
    name: "student-management-view",
    props: true,
    meta: {
      title: "Student Management",
      permissions: ["student-management"],
    },
  },
  {
    path: "/student-detail/:id/:tab",
    name: "Student Details",
    component: StudentDetail,
    props: true,
  },
  {
    path: "/notification-settings",
    name: "Notification Settings",
    component: NotificationSettings,
  },
  {
    path: "/program-manager",
    name: "program-manager-root",
    redirect: () => {
      const userPermissions = store?.getters?.getPermissions ?? [];
      const prgmsPermissions = [
        PERMISSIONS.PRGMS_READ,
        PERMISSIONS.PRGMS_READ_LITE,
      ];
      const found = userPermissions.some((r) => prgmsPermissions.includes(r));
      if (found) {
        return { name: "program-manager-sessions" };
      }
      return { name: "program-manager-housing" };
    },
    children: [
      {
        path: "programs",
        name: "program-manager-programs",
        component: ProgramManager,
        meta: {
          title: "Programs",
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "programs",
          permissions: [PERMISSIONS.PRGMS_READ, PERMISSIONS.PRGMS_READ_LITE],
        },
      },
      {
        path: "sessions",
        name: "program-manager-sessions",
        component: ProgramManager,
        meta: {
          title: "Program Sessions",
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "sessions",
          permissions: [PERMISSIONS.PRGMS_READ, PERMISSIONS.PRGMS_READ_LITE],
        },
      },
      {
        path: "housing",
        name: "program-manager-housing",
        component: ProgramManager,
        meta: {
          title: "Housing",
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "housing",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
        },
      },
      {
        path: "classes",
        name: "program-manager-classes",
        component: ProgramManager,
        meta: {
          title: "Classes",
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "classes",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
        },
      },
      {
        path: "housing/:itemId",
        name: "housing-edit",
        component: Housing,
        meta: {
          title: "Housing",
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "housing",
          permissions: [PERMISSIONS.ITEMS_WRITE],
        },
      },
      {
        path: "programs/view-more",
        name: "programs-view-more",
        component: ProgramManagerViewMore,
        meta: {
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-programs" },
          ],
          subcategory: "programs",
          permissions: [PERMISSIONS.PRGMS_READ, PERMISSIONS.PRGMS_READ_LITE],
        },
      },
      {
        path: "sessions/view-more",
        name: "sessions-view-more",
        component: ProgramManagerViewMore,
        meta: {
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-programs" },
          ],
          subcategory: "sessions",
          permissions: [
            PERMISSIONS.ITEMS_READ,
            PERMISSIONS.ITEMS_READ_LITE,
            PERMISSIONS.PRGMS_READ,
            PERMISSIONS.PRGMS_READ_LITE,
          ],
        },
      },
      {
        path: "programs/create-new",
        name: "programs-create",
        component: ProgramManagerCreateEdit,
        meta: {
          title: "Program Manager",
          subcategory: "programs",
          permissions: [PERMISSIONS.PRGMS_WRITE],
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
            { title: "Programs", parent: "program-manager-programs" },
            { title: "Create Program Page", parent: "programs-create" },
          ],
        },
      },
      {
        path: "programs/:itemId",
        name: "programs-edit",
        component: ProgramManagerCreateEdit,
        props: true,
        meta: {
          title: "Program Manager",
          subcategory: "programs",
          permissions: [PERMISSIONS.PRGMS_WRITE, PERMISSIONS.PRGMS_PUB],
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
            { title: "Programs", parent: "program-manager-programs" },
            { title: "Edit Program Page", parent: "programs-edit" },
          ],
        },
      },
      {
        path: "housing/view-more",
        name: "housing-view-more",
        component: ProgramManagerViewMore,
        meta: {
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "housing",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
        },
      },
      {
        path: "housing/view-more/:housingType?",
        name: "housing-create",
        component: ProgramManagerViewMore,
        props: {
          defaultOpen: true,
        },
        meta: {
          title: "Create Housing",
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "housing",
          permissions: [PERMISSIONS.ITEMS_WRITE],
        },
      },
      {
        path: "classes/view-more",
        name: "classes-view-more",
        component: ProgramManagerViewMore,
        meta: {
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "classes",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
        },
      },
      {
        path: "internships",
        name: "program-manager-internships",
        component: ProgramManager,
        meta: {
          title: "Internships",
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "internships",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
        },
      },
      {
        path: "internships/view-more",
        name: "internships-view-more",
        component: ProgramManagerViewMore,
        meta: {
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "internships",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
        },
      },
      {
        path: "internships/create-new",
        name: "create-internship",
        component: InternshipForm,
        meta: {
          title: "Internship Library",
          permissions: [PERMISSIONS.ITEMS_WRITE],
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
            { title: "Internships", parent: "program-manager-internships" },
            { title: "Create Internship", parent: "create-internship" },
          ],
        },
      },
      {
        path: "internships/item-:itemId",
        name: "internship-item",
        component: InternshipForm,
        meta: {
          title: "Internship Library",
          subcategory: "internships",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
            { title: "Internships", parent: "program-manager-internships" },
            {
              title: "Internship Detail",
              parent: "internship-item",
            },
          ],
        },
      },
      {
        path: "events",
        name: "program-manager-events",
        component: ProgramManager,
        meta: {
          title: "Excursions + Events",
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "events",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
        },
      },
      {
        path: "events/view-more",
        name: "events-view-more",
        component: ProgramManagerViewMore,
        meta: {
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
          ],
          subcategory: "events",
          permissions: [PERMISSIONS.ITEMS_READ, PERMISSIONS.ITEMS_READ_LITE],
        },
      },
      {
        path: "events/create-new",
        name: "create-event",
        component: CreateEditEvents,
        meta: {
          title: "Excursions + Events",
          permissions: [PERMISSIONS.ITEMS_WRITE],
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
            { title: "Excursions + Events", parent: "program-manager-events" },
            { title: "Create Excursion + Event", parent: "create-event" },
          ],
        },
      },
      {
        path: "events/:itemId",
        name: "edit-event",
        component: CreateEditEvents,
        meta: {
          title: "Excursions + Events",
          permissions: [PERMISSIONS.ITEMS_WRITE],
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
            { title: "Excursions + Events", parent: "program-manager-events" },
            { title: "Edit Excursion + Event", parent: "edit-event" },
          ],
        },
      },
      {
        path: "sessions/:programSessionId",
        name: "edit-program-sessions",
        component: () =>
          import(
            /* webpackChunkName: "CreateEditProgramSessions" */ "./components/program-manager/sessions"
          ),
        props: true,
        meta: {
          title: "Program Session",
          permissions: [PERMISSIONS.PRGMS_WRITE],
          breadcrumb: [
            { title: "Program Manager", parent: "program-manager-root" },
            { title: "Program Session", parent: "program-manager-sessions" },
            {
              title: "Create Core Program Session",
              parent: "edit-program-sessions",
            },
          ],
        },
      },
      {
        path: "operations/",
        name: "student-fulfillment",
        component: StudentFulfillmentManager,
        meta: {
          title: "Student Fulfillment",
          breadcrumb: [{ title: "Student Fulfillment" }],
          permissions: [PERMISSIONS.ORDERS_READ],
        },
      },
      {
        path: "operations/order-details/:orderId",
        name: "order-detail",
        component: OrderDetail,
        meta: {
          title: "Order Details",
          breadcrumb: [
            { title: "Student Fulfillment", parent: "student-fulfillment" },
            { title: "View Order Details", parent: "order-detail" },
          ],
          permissions: [PERMISSIONS.ORDERS_READ],
        },
      },
      {
        path: "operations/reports",
        name: "fulfillment-reports",
        component: ViewReports,
        meta: {
          title: "View Reports",
          breadcrumb: [
            { title: "Student Fulfillment", parent: "student-fulfillment" },
            { title: "View Reports", parent: "fulfillment-reports" },
          ],
          permissions: [PERMISSIONS.ORDERS_READ, PERMISSIONS.TABLEAU_VIEWER],
        },
      },
    ],
  },
  {
    path: "/personalize/:programPageId",
    component: Configurator,
    name: "personalize",
    children: [
      {
        path: "new/:slug?",
        component: Configurator,
        name: "configurator-new",
      },
      {
        path: ":orderId/:slug?",
        component: Configurator,
        name: "configurator",
      },
    ],
    meta: {
      title: "Personalize & Apply",
      addBackBreadcrumb: true,
      breadcrumb: [],
    },
  },
  {
    path: "/results",
    name: "results",
    redirect: "/results/programs",
    component: Results,
    children: [
      {
        path: "programs",
        component: Results,
        name: "programs-results-view",
      },
      {
        path: "internship-postings",
        component: Results,
        name: "internship-postings-results-views",
      },
      {
        path: "courses",
        component: Results,
        name: "courses-results-view",
      },
    ],
    meta: {
      title: "Experience Results",
      breadcrumb: [
        { title: "Home", parent: "home" },
        { title: "Results", parent: "results" },
      ],
    },
  },
  {
    path: "/results/internships",
    component: InternshipProductPageView,
    name: "internship-product-page-view",
    meta: {
      title: "Internship Posting",
      breadcrumb: [
        { title: "Home", parent: "home" },
        { title: "Results", parent: "internship-postings-results-views" },
        {
          title: "Internships Postings",
          parent: "internship-product-page-view",
        },
      ],
    },
  },
  {
    path: "/results/housing",
    component: HousingProductPageView,
    name: "housing-product-page-view",
    meta: {
      breadcrumb: [
        { title: "Home", parent: "home" },
        { title: "Browse All Programs", parent: "results" },
        { title: "Program Detail", parent: "program-detail-view" },
        { title: "Personalize & Apply", parent: "personalize" },
        {
          title: "Housing Detail",
          parent: "housing-product-page-view",
        },
      ],
    },
  },
  {
    path: "/results/courses/:courseId",
    component: CourseProductPageView,
    name: "course-product-page-view",
    meta: {
      title: "Course Page",
      breadcrumb: [
        { title: "Home", parent: "home" },
        { title: "Browse All Programs", parent: "results" },
        {
          title: "Course Detail",
          parent: "course-product-page-view",
        },
      ],
    },
  },
  {
    path: "/program-page/:programPageId",
    component: ProgramDetailsView,
    name: "program-detail-view",
    meta: {
      title: "Program Detail",
      breadcrumb: [
        { title: "Home", parent: "home" },
        { title: "Browse All Programs", parent: "results" },
        { title: "Program Detail", parent: "program-detail-view" },
      ],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: Page404,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: Page404,
  },
];

export default routes;
