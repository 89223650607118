<template>
  <div
    class="flex items-center sm:flex-col md:flex-row sm:items-start md:items-center"
    :class="[templateClass]"
  >
    <div
      class="flex items-center justify-center flex-none rounded-full"
      :class="[
        isToday ? 'bg-warning-900' : '',
        bigCircleClass,
        pastDueClass,
        primaryColor ? `${primaryColorClass}` : 'bg-university-primary',
      ]"
    >
      <div
        class="flex flex-col items-center justify-center font-semibold text-center rounded-full"
        :class="[smallCircleClass, `${primaryColorClass}`]"
        data-cy="deadline-countdown"
      >
        <span
          v-if="!isPastDue && !isToday && deadlineCount > 0"
          class="block leading-none"
          :class="textClass"
        >
          {{ deadlineCount }}
        </span>
        <span v-if="isToday" class="block uppercase text-xs text-warning-900">
          DUE TODAY
        </span>
        <span
          v-else-if="isFormsCompletionCountDown && isPastDue && !isToday"
          class="block uppercase text-xs"
          :class="isPastDue ? 'text-error-900' : ''"
        >
          Past Due
        </span>

        <span v-else-if="deadlineCount > 0" class="block uppercase text-1xs">
          Days {{ isLargeIcon ? "Left" : "" }}
        </span>
        <img
          v-else-if="deadlineCount <= 0"
          :src="'/images/' + 'icon_person_on_computer' + '.svg'"
        />
      </div>
    </div>
    <div
      :class="{ 'hidden md:block': hideDateLabel }"
      class="ml-4 text-sm font-semibold sm:mt-4 sm:ml-0 md:mt-0 md:ml-4"
    >
      <p class="mb-2" data-cy="deadline-text">
        {{ deadlineMessage }}
      </p>
      <p>{{ textDate }}</p>
    </div>
  </div>
</template>

<script>
import { daysUntilDate, formatDate } from "../mixins/helpers";
import { endOfDay, isPast, isValid, isToday, parse } from "date-fns";

export default {
  name: "DateCountdown",
  props: {
    deadline: {
      type: Number,
      default: 0,
    },
    applicationDeadline: {
      type: String,
      default: "",
    },
    applicationExtensionDeadline: {
      type: String,
      default: "",
    },
    isLargeIcon: {
      type: Boolean,
      default: true,
    },
    isFormsCompletionCountDown: {
      type: Boolean,
      default: false,
    },
    hideDateLabel: {
      type: Boolean,
      default: false,
    },
    calculateDateCount: {
      type: Boolean,
      default: true,
    },
    timeLineBar: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isToday() {
      if (this.timeLineBar) {
        let deadLine = parse(
          this.applicationDeadline,
          "yyyy-MM-dd",
          new Date()
        );
        return isToday(deadLine);
      }
      return false;
    },
    currentDate() {
      return new Date();
    },
    applicationDeadlineDate() {
      if (isValid(endOfDay(new Date(this.applicationDeadline)))) {
        return endOfDay(new Date(this.applicationDeadline));
      }
      return "";
    },
    deadlineCount() {
      let count = 0;
      //retun the days left to deadline or extension deadline
      if (isPast(this.applicationDeadlineDate) && this.validExtensionDeadline) {
        count = daysUntilDate(this.applicationExtensionDeadline);
      } else if (this.calculateDateCount) {
        count = daysUntilDate(this.applicationDeadline);
      } else {
        count = this.deadline;
      }
      return count;
    },
    deadlineMessage() {
      switch (this.isFormsCompletionCountDown) {
        case true:
          if (this.isPastDue) {
            return "Materials were due";
          } else {
            return "All materials due";
          }
        default:
          //retun message for dealine or extension deadline
          if (this.isLargeIcon) {
            if (
              isPast(this.applicationDeadlineDate) &&
              this.validExtensionDeadline
            ) {
              return "Application deadline has been extended";
            }
            return "until application deadline";
          } else {
            if (this.deadlineCount <= 0) {
              return "Program Started";
            }
            //return message for enrollment landing page
            return "until program starts";
          }
      }
    },
    textDate() {
      //return the formatted deadline date or extesion dealine date
      let textDate = "";
      if (isPast(this.applicationDeadlineDate) && this.validExtensionDeadline) {
        textDate = this.validExtensionDeadline
          ? "(" + formatDate(this.applicationExtensionDeadline) + ")"
          : "";
      } else {
        textDate = isValid(new Date(this.applicationDeadline))
          ? "(" + formatDate(this.applicationDeadline) + ")"
          : "";
      }

      //fomrs completed countdown text update
      if (this.isFormsCompletionCountDown && this.isPastDue) {
        return "on " + textDate.replace(/[()]/g, "");
      } else if (this.isFormsCompletionCountDown) {
        return "by " + textDate.replace(/[()]/g, "");
      } else {
        return textDate;
      }
    },
    templateClass() {
      return this.isLargeIcon ? "mt-8" : "justify-end";
    },
    bigCircleClass() {
      return this.isLargeIcon ? "w-24 h-24" : "w-16 h-16";
    },
    smallCircleClass() {
      return this.isLargeIcon ? "w-20 h-20 bg-blue-100" : "w-13 h-13 bg-white";
    },
    textClass() {
      return this.isLargeIcon ? "text-3xl" : "text-2xl";
    },
    pastDueClass() {
      return this.isFormsCompletionCountDown && this.isPastDue
        ? "bg-error-900"
        : "";
    },
    isPastDue() {
      if (!this.isFormsCompletionCountDown) {
        return false;
      }
      //If all forms are not complete and the deadline is passed then show past due (red treatment).
      if (isPast(this.applicationDeadlineDate)) {
        return true;
      } else {
        return false;
      }
    },
    validExtensionDeadline() {
      return this.applicationExtensionDeadline
        ? isValid(new Date(this.applicationExtensionDeadline))
        : false;
    },
  },

  methods: {
    formatDate,
  },
};
</script>
