import { cloneDeep } from "lodash";
import { sessionTypes } from "@/components/program-manager/sessions/constants";
import { floatWithFixedDecimals } from "@/mixins/helpers";

const filtersMap = {
  // required_languages: "required_languages",
  // area_studies: "area_studies",
  profiles: "leaders",
  program_session_type: "types",
  academic_year: "academic_years",
  min_proficiency_level_ids: "language_levels",
  education_level_minimum_age_rule: "min_year",
  minimum_gpa_requirement: "min_gpa",
  city: "locations",
  terms: "terms",
  host_institution: "host_institutions",
};

export const minimalProgram = {
  description: "",
  name: "",
  url: "",
  status_id: 1,
  status: {
    id: 1,
    name: "Draft",
  },
  filters: {},
};

export const statusesIds = {
  DRAFT: 1,
  ACTIVE: 6,
  INACTIVE: 7,
  ARCHIVED: 8,
};

export const programStatuses = [
  { name: "Draft", id: statusesIds.DRAFT },
  { name: "Active", id: statusesIds.ACTIVE },
  { name: "Inactive", id: statusesIds.INACTIVE },
  { name: "Archived", id: statusesIds.ARCHIVED },
];

const mapFiltersBeforeSave = (filters) => {
  console.log("filter input", filters)
  let newFilters = {};
  for (let filterKey in filters) {
    newFilters[filtersMap[filterKey]] = filters[filterKey] || undefined;
  }
  console.log("filters", newFilters)
  return newFilters;
};

const setSelectedProgramTypes = (programTypesIds) => {
  return cloneDeep(sessionTypes).map((sessionType) => ({
    ...sessionType,
    checked: programTypesIds.includes(sessionType.id),
  }));
};

const setSelectedSessions = (sessions) =>
  sessions?.map((session) => ({
    ...session,
    selected: true,
  })) || [];

const getSelectedSessions = (sessions) =>
  sessions.filter((session) => session.selected).map((session) => session.id);

const stringifyBeforeSave = (value, fallbackValue) => {
  try {
    console.log("Stringify", JSON.stringify(value));
    return JSON.stringify(value);
  } catch {
    return fallbackValue;
  }
};

export const setTestimonialProgramId = (programPage) => {
  programPage.value.program_page_testimonials.forEach((testimonial) => {
    testimonial.program_page_id = programPage.value.id;
  });
};

export const parseBeforeRender = (value, fallbackValue) => {
  try {
    return JSON.parse(value);
  } catch {
    return fallbackValue;
  }
};

export const getSelectedProgramTypes = (programTypes) =>
  programTypes
    .filter((programSessionType) => programSessionType.checked)
    .map((programSessionType) => programSessionType.id);

export const convertProgram = async (programPage, program) => {
  const filters = program?.filters || {};

  programPage.value.owner_entity_id = program?.owner_entity_id;
  programPage.value.description = parseBeforeRender(program?.description, {});
  programPage.value.lead_media = parseBeforeRender(program?.lead_media, []);
  programPage.value.name = program?.name;
  programPage.value.url = program?.url;
  programPage.value.info_session_event_id = program?.info_session_event_id;
  programPage.value.status_id = program?.status_id;
  programPage.value.program_page_testimonials =
    program?.program_page_testimonials || [];
  programPage.value.marketplace = program?.marketplace ?? false;
  programPage.value.sessions = setSelectedSessions(program?.sessions || []);

  programPage.value.filters.program_session_type = setSelectedProgramTypes(
    filters?.types || []
  );
  programPage.value.filters.required_languages =
    filters.required_languages || [];

  programPage.value.filters.profiles = filters?.leaders || [];
  programPage.value.filters.min_proficiency_level_ids =
    filters?.language_levels || [];
  programPage.value.filters.minimum_gpa_requirement = floatWithFixedDecimals(
    filters?.min_gpa
  );
  programPage.value.filters.education_level_minimum_age_rule =
    filters?.min_year || "";
  programPage.value.filters.city = filters?.locations || [];
  programPage.value.filters.academic_year = filters?.academic_years || [];
  programPage.value.filters.terms = filters?.terms || [];
  programPage.value.filters.area_studies = filters?.area_studies || [];
  programPage.value.filters.host_institution = filters?.host_institutions || [];
  programPage.value.owner_entity_id =
    program?.owner_entity_id || programPage.value.owner_entity_id;
};

export const convertProgramBeforeSave = (programPage) => (
  console.log(programPage),{
  ...cloneDeep(programPage.value),
  sessions: getSelectedSessions(programPage.value?.sessions || []),
  description: stringifyBeforeSave(programPage.value?.description || {}, "{}"),
  lead_media: stringifyBeforeSave(programPage.value?.lead_media || [], "[]"),
  filters: mapFiltersBeforeSave({
    ...programPage.value.filters,
    min_gpa: floatWithFixedDecimals(programPage.value?.filters?.min_gpa),
    program_session_type: getSelectedProgramTypes(
      programPage.value.filters.program_session_type
    ),
  }),
});
