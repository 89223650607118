<template>
  <div class="p-1">
    <RoomTypeWarning
      :open="openRoomTypeWarning"
      :room-type-info="roomTypeInfo"
      @close-modal="closeRoomTypeModal"
      @save-room-type="saveRoomType"
    />
    <div
      v-for="(room, index) in rooms"
      :key="index"
      class="mt-4 border-2 border-dashed border-gray-300 rounded"
    >
      <div
        class="flex text-[13px] font-bold justify-between p-4 border-b-gray-300 bg-white"
      >
        <h2>ROOM {{ index + 1 }}</h2>
        <div class="flex space-x-8">
          <button
            class="font-bold text-teal-900"
            @click.stop="duplicateUnitRoom(index)"
          >
            DUPLICATE
          </button>
          <button
            :disabled="!hasPublishPermission"
            @click.stop="deleteUnitRoom(index)"
          >
            <img class="w-5 h-5" loading="lazy" :src="garbageIcon" />
          </button>
        </div>
      </div>
      <div class="p-3">
        <div class="grid md:grid-cols-3 mt-2 md:space-x-8">
          <div
            v-if="showFieldIfLocalHostOrHouseOrGenericListing"
            class="flex-1"
          >
            <label class="block text-sm">
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span class="text-red-100 pr-2 align-sub">*</span>Status
              </span>
              <v-select
                :id="`room.status.${index + 1}`"
                :model-value="room.status"
                :options="statuses"
                :placeholder="placeholder"
                class="mt-1"
                :class="{
                  'forced-error-background':
                    getValidatorResponseData(v$, index)?.status.length &&
                    room.is_not_valid,
                }"
                @update:modelValue="
                  updateStateFieldRoomType($event, 'status', index)
                "
              ></v-select>
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.status.length &&
                  room.is_not_valid
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)?.status"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
          <div class="flex-1">
            <label
              class="mt-8 block text-sm font-semibold text-gray-700"
              for="fname"
              ><span class="text-red-100 pr-2 align-sub">*</span>
              <span v-if="hideFieldIfLocalHostOrHouseOrGenericListing"
                >Room type</span
              >
              <span v-else>Capacity</span>
            </label>
            <v-select
              :id="`room.type.${index + 1}`"
              :model-value="room.type"
              :options="roomTypes"
              :placeholder="placeholder"
              class="mt-1"
              :class="{
                'forced-error-background':
                  getValidatorResponseData(v$, index)?.type?.length &&
                  room.is_not_valid,
              }"
              @update:model-value="
                openRoomTypeWarningModal($event, 'type', index)
              "
            ></v-select>
            <div
              v-if="
                getValidatorResponseData(v$, index)?.type?.length &&
                room.is_not_valid
              "
              class="block error text-error-900 text-sm"
            >
              <div
                v-for="error in getValidatorResponseData(v$, index)?.type"
                :key="error"
              >
                {{ error.$message }}
              </div>
            </div>
          </div>

          <div class="flex-1">
            <label
              class="mt-8 block text-sm font-semibold text-gray-700"
              for="fname"
              ><span class="text-red-100 pr-2 align-sub">*</span> Bathroom type
            </label>
            <v-select
              :id="`room.type.${index + 1}`"
              :model-value="room.bathroom_type"
              :options="bathroomTypes"
              :placeholder="placeholder"
              class="mt-1"
              :class="{
                'forced-error-background':
                  getValidatorResponseData(v$, index)?.bathroom_type?.length &&
                  room.is_not_valid,
              }"
              @update:modelValue="
                updateStateField($event, 'bathroom_type', index)
              "
            ></v-select>
            <div
              v-if="
                getValidatorResponseData(v$, index)?.bathroom_type?.length &&
                room.is_not_valid
              "
              class="block error text-error-900 text-sm"
            >
              <div
                v-for="error in getValidatorResponseData(v$, index)
                  ?.bathroom_type"
                :key="error"
              >
                {{ error.$message }}
              </div>
            </div>
          </div>

          <div
            class="flex-1"
            :class="{
              'no-margin': showFieldIfLocalHostOrHouseOrGenericListing,
            }"
          >
            <label
              class="mt-8 block text-sm font-semibold text-gray-700"
              for="fname"
            >
              Cost
            </label>
            <div class="flex items-center">
              <span>$</span>
              <input
                :value="room.cost_in_cents"
                class="h-10 mt-1 py-2 pl-3 w-full form-input"
                type="number"
                @input="
                  updateStateField($event.target.value, 'cost_in_cents', index)
                "
              />
              <span>/bed/week</span>
            </div>
          </div>
        </div>

        <div
          class="flex-1 px-4 py-4 border rounded-md border-gray-200 mt-4 bg-blue-300"
        >
          <PriceSection
            :prices="room.product_prices"
            :disabled="!hasPublishPermission"
            :required-to-publish="checkRoomRequiredToPublish(room)"
            @add-price="() => addRoomPriceLocal(index)"
            @update-price="
              (roomPriceIndex, newPriceValue) =>
                updateRoomPriceStateLocal(index, roomPriceIndex, newPriceValue)
            "
            @remove-price="
              (roomPriceIndex) => removeRoomPriceLocal(index, roomPriceIndex)
            "
          >
            <template #information
              >* If Room price does not have and end date, that price is assumed
              to be the price for that housing in perpetuity</template
            >
            <template #postfixInputLabel>/bed/day</template>
          </PriceSection>
        </div>

        <div
          class="my-4 block text-sm font-semibold text-gray-700"
          :class="{
            'bg-error-100': isRoomAmenitiesInvalid(room, 'Amenities'),
          }"
        >
          <span class="text-red-100 pr-2 align-sub">**</span>
          Select all the amenities that apply to this room accomodation
          <div
            v-if="isRoomAmenitiesInvalid(room, 'Amenities')"
            class="block error text-error-900"
          >
            <span>
              The field is required
            </span>
          </div>
        </div>
        <div
          v-if="room.room_amenities"
          class="flex-col mb-3 md:grid md:grid-cols-3 gap-5"
        >
          <div
            v-for="amenity in filterAmenitiesByCategory(
              room.room_amenities,
              'Amenities'
            )"
            :key="amenity.title"
            class="flex space-x-2 items-center mb-4"
          >
            <!-- Checkbox and amenity label -->
            <div class="flex flex-1 items-center space-x-2">
              <div class="flex items-center">
                <input
                  :value="amenity.amenity_id"
                  type="checkbox"
                  class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                  :checked="amenity.checked"
                  @input="
                    updateStateFieldRoomAmenities(
                      $event.target.value,
                      $event.target.checked,
                      index
                    )
                  "
                />
              </div>
              <div class="text-sm">
                <label class="text-gray-700">
                  {{ amenity.title }}
                </label>
              </div>
            </div>

            <!-- Amenity quantity label and input -->
            <div
              v-if="amenity.checked"
              class="flex flex-1 items-center space-x-4 text-sm"
            >
              <label
                :for="`${amenity.title}-amenity-quantity`"
                class="text-gray-700"
                >Quantity:</label
              >
              <input
                :id="`${amenity.title}-amenity-quantity`"
                :data-testid="`${amenity.title}-amenity-quantity`"
                type="number"
                min="1"
                step="1"
                :value="amenity.quantity"
                class="h-10 mt-1 md:max-w-[300px] w-full form-input"
                :class="{
                  'bg-error-100': isRoomAmenitiesInvalid(room, 'Amenities'),
                }"
                @input="
                  updateStateFieldAmenity(
                    $event.target.value,
                    'quantity',
                    index,
                    amenity.amenity_id
                  )
                "
              />
            </div>
          </div>
        </div>

        <div
          class="my-4 block text-sm font-semibold text-gray-700"
          :class="{
            'bg-error-100': isRoomAmenitiesInvalid(room, 'Safety'),
          }"
        >
          <span class="text-red-100 pr-2 align-sub">**</span>
          Select all the safety information that apply to this room
          accommodation
          <div
            v-if="isRoomAmenitiesInvalid(room, 'Safety')"
            class="block error text-error-900"
          >
            <span>
              The field is required
            </span>
          </div>
        </div>

        <div
          v-if="room.room_amenities"
          class="flex-col mb-3 md:grid md:grid-cols-3 gap-5"
        >
          <div
            v-for="amenity in filterAmenitiesByCategory(
              room.room_amenities,
              'Safety'
            )"
            :key="amenity.title"
            class="flex space-x-2 items-center mb-4"
          >
            <!-- Checkbox and amenity label -->
            <div class="flex flex-1 items-center space-x-2">
              <div class="flex items-center">
                <input
                  :id="`${amenity.title}-room-amenity-checkbox`"
                  :data-testid="`${amenity.title}-room-amenity-checkbox`"
                  :value="amenity.amenity_id"
                  type="checkbox"
                  class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                  :checked="amenity.checked"
                  @input="
                    updateStateFieldRoomAmenities(
                      $event.target.value,
                      $event.target.checked,
                      index
                    )
                  "
                />
              </div>
              <div class="text-sm">
                <label
                  :for="`${amenity.title}-room-amenity-checkbox`"
                  class="text-gray-700"
                >
                  {{ amenity.title }}
                </label>
              </div>
            </div>

            <!-- Amenity quantity label and input -->
            <div
              v-if="amenity.checked"
              class="flex flex-1 items-center space-x-4 text-sm"
            >
              <label
                :for="`${amenity.title}-safety-amenity-quantity`"
                class="text-gray-700"
              >
                Quantity:
              </label>
              <input
                :id="`${amenity.title}-safety-amenity-quantity`"
                :data-testid="`${amenity.title}-safety-amenity-quantity`"
                type="number"
                min="1"
                step="1"
                :value="amenity.quantity"
                class="h-10 mt-1 md:max-w-[300px] w-full form-input"
                :class="{
                  'bg-error-100': isRoomAmenitiesInvalid(room, 'Safety'),
                }"
                @input="
                  updateStateFieldAmenity(
                    $event.target.value,
                    'quantity',
                    index,
                    amenity.amenity_id
                  )
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-4 bg-white border-2 border-dashed border-gray-300 rounded cursor-pointer"
      @click.stop="addUnitRoom"
    >
      <button class="flex items-center m-5 p-1">
        <PlusIcon class="mr-2 text-teal-900 w-10 h-10" />
        <span class="font-medium">Add another room</span>
      </button>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import {
  ACTIVE_STATUS,
  STATUS,
  ROOM_TYPES,
  BATHROOM_TYPES,
  HOUSING_TYPES_MAP,
  PERMISSIONS,
} from "@/constants";
import RoomTypeWarning from "@/components/modals/RoomTypeWarning.vue";
import { duplicateRoom } from "@/components/program-manager/housing/components/utils";
import PlusIcon from "@/components/shared/icons/PlusIcon.vue";
import GarbageIcon from "@/assets/icons/garbage.svg";
import PriceSection from "@/components/shared/PriceSection";

const requiredMessage = "The field is required";
const roomCapacities = ROOM_TYPES.map((type, idx) => ({
  value: type,
  capacity: idx + 1,
}));

export default {
  name: "RoomSection",
  components: { PlusIcon, PriceSection, RoomTypeWarning },
  props: {
    unitIndex: {
      type: Number,
      default: 0,
    },
    unitStatus: {
      type: String,
      default: "Draft",
    },
    roomsProps: {
      type: Array,
      default: () => {},
    },
  },
  emits: ["addUnitRoom", "deleteUnitRoom"],
  setup() {
    return {
      v$: useVuelidate({
        $registerAs: `RoomSection-${uuid.v4()}`,
        $lazy: true,
        $autoDirty: true,
      }),
    };
  },
  data() {
    return {
      placeholder: "Please select one",
      garbageIcon: GarbageIcon,
      roomTypes: ROOM_TYPES,
      bathroomTypes: BATHROOM_TYPES,
      statuses: STATUS,
      roomCapacities: roomCapacities,
      openRoomTypeWarning: false,
      roomTypeUpdated: false,
      roomTypeInfo: {
        value: undefined,
        fieldname: undefined,
        index: undefined,
      },
    };
  },
  computed: {
    ...mapGetters("programManager", [
      "getCurrentHousingType",
      "getGenericListing",
    ]),
    ...mapGetters({ permissions: "getPermissions" }),
    isLocalHostType() {
      return this.getCurrentHousingType === HOUSING_TYPES_MAP.LOCAL_HOST;
    },
    isHouseType() {
      return this.getCurrentHousingType === HOUSING_TYPES_MAP.HOUSE;
    },
    hideFieldIfLocalHostOrHouseOrGenericListing() {
      return (
        !this.isLocalHostType && !this.isHouseType && !this.getGenericListing
      );
    },
    showFieldIfLocalHostOrHouseOrGenericListing() {
      return this.isLocalHostType || this.isHouseType || this.getGenericListing;
    },
    rooms() {
      return this.roomsProps;
    },
    hasPublishPermission() {
      return this.permissions.includes(PERMISSIONS.ITEMS_PUB);
    },
  },
  validations: {
    rooms: {
      $each: helpers.forEach({
        type: {
          required: helpers.withMessage(requiredMessage, required),
        },
        bathroom_type: {
          required: helpers.withMessage(requiredMessage, required),
        },
      }),
    },
  },
  methods: {
    checkRoomRequiredToPublish(room) {
      return room.status === ACTIVE_STATUS;
    },
    addUnitRoom() {
      this.$emit("addUnitRoom", this.unitIndex, null);
    },
    duplicateUnitRoom(index) {
      this.$emit(
        "addUnitRoom",
        this.unitIndex,
        duplicateRoom(this.rooms[index])
      );
    },
    deleteUnitRoom(roomIndex) {
      this.$emit("deleteUnitRoom", this.unitIndex, roomIndex);
    },
    setCapacity(index, room_type) {
      if (!room_type) {
        this.setRoomCapacity({
          unitIndex: this.unitIndex,
          roomIndex: index,
          capacity: 0,
        });
      } else {
        for (let r_type of this.roomCapacities) {
          if (r_type.value == room_type) {
            this.setRoomCapacity({
              unitIndex: this.unitIndex,
              roomIndex: index,
              capacity: r_type.capacity,
            });
            break;
          }
        }
      }
    },
    updateRoomPriceStateLocal(roomIndex, roomPriceIndex, newPriceValue) {
      this.updateRoomPriceState({
        unitIndex: this.unitIndex,
        roomIndex,
        roomPriceIndex,
        roomPrice: newPriceValue,
      });
    },
    addRoomPriceLocal(roomIndex) {
      this.addRoomPrice({ unitIndex: this.unitIndex, roomIndex });
    },
    removeRoomPriceLocal(roomIndex, roomPriceIndex) {
      this.removeRoomPrice({
        unitIndex: this.unitIndex,
        roomIndex,
        roomPriceIndex,
      });
    },
    isAmenityChecked(amenityId, amenities) {
      return amenities ? amenities.includes(amenityId) : false;
    },
    openRoomTypeWarningModal(value, fieldname, index) {
      if (this.$props.unitStatus === ACTIVE_STATUS && !this.roomTypeUpdated) {
        this.openRoomTypeWarning = true;
        this.roomTypeInfo = {
          value,
          fieldname,
          index,
        };
      } else {
        this.updateStateFieldRoomType(value, fieldname, index);
      }
    },
    clearRoomTypeInfo() {
      this.roomTypeInfo = {
        value: undefined,
        fieldname: undefined,
        index: undefined,
      };
    },
    closeRoomTypeModal() {
      this.clearRoomTypeInfo();
      this.openRoomTypeWarning = false;
    },
    saveRoomType() {
      const { value, fieldname = "type", index } = this.roomTypeInfo;
      this.roomTypeUpdated = true;
      this.clearRoomTypeInfo();
      this.updateStateFieldRoomType(value, fieldname, index);
    },
    updateStateFieldRoomType(value, fieldname, index) {
      this.setCapacity(index, value);
      this.updateStateField(value, fieldname, index);
    },
    updateStateField(value, fieldname, index) {
      this.setCurrentUnitStateField({
        type: "room",
        data: {
          unitIndex: this.unitIndex,
          roomIndex: index,
          fieldname: fieldname,
          value: value,
        },
      });
    },
    updateStateFieldRoomAmenities(value, checked, index) {
      this.setCurrentUnitRoomAmenities({
        unitIndex: this.unitIndex,
        roomIndex: index,
        value: value,
        checked: checked,
      });
    },
    updateStateFieldAmenity(value, fieldname, roomIndex, amenityId) {
      this.setCurrentUnitRoomAmenityStateField({
        unitIndex: this.unitIndex,
        roomIndex,
        amenityId,
        fieldname,
        value: parseInt(value),
      });
    },
    isRoomAmenitiesInvalid(room, category) {
      return room.is_not_valid_amenities
        ? room.is_not_valid_amenities[category]
        : false;
    },
    getValidatorResponseData(v$, index) {
      if (v$.rooms.$each.$response?.$errors) {
        return v$.rooms.$each.$response.$errors[index];
      }
      return undefined;
    },
    filterAmenitiesByCategory(amenities, category) {
      return amenities.filter((amenity) => amenity.category === category);
    },

    ...mapActions("programManager", [
      "setRoomCapacity",
      "addRoomPrice",
      "removeRoomPrice",
      "updateRoomPriceState",
      "setCurrentUnitStateField",
      "setCurrentUnitRoomAmenities",
      "setCurrentUnitRoomAmenityStateField",
    ]),
  },
};
</script>
<style scoped>
.no-margin {
  margin-left: 0 !important;
}
.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
</style>
