<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps } from "vue";
import { useVModel } from "@vueuse/core";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const events = useVModel(props, "modelValue");
</script>
<template>
  <div class="relative">
    <div class="grid xs:grid-cols-2 sm:grid-cols-2 mt-4 gap-4">
      <div class="text-base font-medium text-gray-700">
        Radius of allowed Participants:
        <span v-if="events?.radius" class="font-semibold"
          >{{ events.radius }} miles</span
        >
      </div>
      <div class="text-base font-medium text-gray-700">
        Seasonal Availability:
        <span v-if="events?.terms_available" class="font-semibold">{{
          events.terms_available?.map((item) => item.name).join(", ") || ""
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Relevant Academic Themes:
        <span v-if="events?.event_academic_theme" class="font-semibold">{{
          events.event_academic_theme
            ?.map((item) => item.soc_title)
            .join(", ") || ""
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        Age Requirement:
        <span v-if="events?.age_requirement" class="font-semibold">{{
          events.age_requirement
        }}</span>
      </div>
      <div class="text-base font-medium text-gray-700">
        The event is attended by:
        <span v-if="events?.primarily_attended_by" class="font-semibold">{{
          events.primarily_attended_by.map((item) => item).join(", ") || ""
        }}</span>
      </div>
    </div>
  </div>
</template>
