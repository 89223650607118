<script setup>
import { useVModel } from "@vueuse/core";
import SimpleText from "@/components/shared/SimpleText.vue";
import BasicTextInput from "@/components/shared/input/BasicTextInput.vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  labelName: {
    type: String,
    default: "",
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  vuelidateInstance: {
    type: Object,
    default: () => {},
  },
  dataTestId: {
    type: String,
    default: "labeledEditableFieldTestId",
  },
});
const emit = defineEmits(["update:modelValue"]);
const fieldValue = useVModel(props, "modelValue", emit);
</script>
<template>
  <div class="flex items-center">
    <slot name="label">
      <label class="uppercase w-[30%] font-semibold">
        {{ labelName }}
      </label>
    </slot>
    <slot v-if="isEditing" name="field">
      <BasicTextInput
        v-model="fieldValue"
        outer-classes="flex flex-col w-[70%] flex-4 space-y-2"
        input-classes="w-full bg-white h-10 border border-indigo-base pl-4 pb-4 pt-6 text-indigo-base focus:text-indigo-base placeholder-indigo-base placeholder-opacity-50 outline-none focus:ring-0"
        :vuelidate-instance="vuelidateInstance"
        :data-testid="dataTestId"
      />
    </slot>
    <slot v-else name="uneditable-state">
      <SimpleText :text-value="fieldValue" />
    </slot>
  </div>
</template>
