import _ from "lodash";

const configurator = {
  namespaced: true,
  state: {
    currentOrder: {
      orderId: "",
      programId: "",
      programSessionId: "",
      room_ids: [],
      event_occurrence_ids: [],
      internship_ids: [],
      credits: 0,
      courseIds: [],
      misc_product_ids: [],
    },
    currentProgram: {},
    housingQuestionnaire: {},
    housingBilling: {
      housingFeeCap: 0,
      institutionPayHousing: false,
    },
    configuratorIncomplete: false,
    saveForLater: false,
    pastDeadline: false,
  },
  mutations: {
    SET_CURRENT_ORDER(state, data) {
      state.currentOrder = _.cloneDeep(data);
    },
    RESET_CURRENT_ORDER(state) {
      state.currentOrder = {
        orderId: "",
        programId: "",
        programSessionId: "",
        room_ids: [],
        event_occurrence_ids: [],
        internship_ids: [],
        credits: 0,
        courseIds: [],
        misc_product_ids: [],
      };
    },
    SET_CURRENT_PROGRAM(state, data) {
      // stripping out some fields so that the program fits in session storage
      const program = {
        ...data,
        loadedSessions: [],
        programSession: {
          ...data.programSession,
          available_products: [],
          adopted_from: undefined,
          transcript_provider: undefined,
          session_units: [],
        },
        programPage: undefined,
      };
      state.currentProgram = program;
    },
    ADD_INTERNSHIP_ID_TO_CURRENT_ORDER(state, id) {
      if (!state.currentOrder.internship_ids.some((item) => item === id))
        state.currentOrder.internship_ids.push(id);
    },
    /**
     * Adds misc product id to the list (If it's not included)
     * @param {*} state
     * @param {String} misc_product_id An UUID from learning service.
     */
    ADD_ID_TO_MISC_PRODUCT_ID(state, misc_product_id) {
      if (!state.currentOrder.misc_product_ids.includes(misc_product_id)) {
        state.currentOrder.misc_product_ids.push(misc_product_id);
      }
    },
    /**
     * Removes the group visa ids from the list of misc product ids, leaving the other intact
     * @param {*} state
     * @param {Array} misc_product_ids A list of UUIDS (Group Visa) to remove
     */
    REMOVE_GROUP_VISA_FROM_MISC_PRODUCT_IDS(state, misc_product_ids) {
      const filtered_ids = state.currentOrder.misc_product_ids.filter(
        (product_id) => !misc_product_ids.includes(product_id)
      );
      state.currentOrder.misc_product_ids = filtered_ids;
    },
    SET_HOUSING_QUESTIONNAIRE(state, data) {
      state.housingQuestionnaire = _.cloneDeep(data);
    },
    RESET_HOUSING_QUESTIONNAIRE(state) {
      state.housingQuestionnaire = {};
    },
    SET_HOUSING_BILLING(state, data) {
      state.housingBilling.housingFeeCap = data.housingFeeCap;
      state.housingBilling.institutionPayHousing = data.institutionPayHousing;
    },
    SET_CONFIGURATOR_INCOMPLETE(state, incomplete) {
      state.configuratorIncomplete = incomplete;
    },
    SET_SAVE_FOR_LATER(state, value) {
      state.saveForLater = value;
    },
    SET_PAST_DEADLINE(state, value) {
      state.pastDeadline = value;
    },
  },
  actions: {
    setCurrentOrder({ commit }, data) {
      commit("SET_CURRENT_ORDER", data);
    },
    resetCurrentOrder({ commit }) {
      commit("RESET_CURRENT_ORDER");
    },
    setCurrentProgram({ commit }, data) {
      if (data.school) {
        data.school.label = data.school.name;
        data.school.value = data.school.ope_id;
      }
      commit("SET_CURRENT_PROGRAM", data);
    },
    setHousingQuestionnaire({ commit }, data) {
      commit("SET_HOUSING_QUESTIONNAIRE", data);
    },
    resetHousingQuestionnaire({ commit }) {
      commit("RESET_HOUSING_QUESTIONNAIRE");
    },
    setHousingBilling({ commit }, data) {
      commit("SET_HOUSING_BILLING", data);
    },
    setConfiguratorIncomplete({ commit }, data) {
      commit("SET_CONFIGURATOR_INCOMPLETE", data);
    },
    resetConfiguratorIncomplete({ commit }) {
      commit("SET_CONFIGURATOR_INCOMPLETE", false);
    },
    setSaveForLater({ commit }, data) {
      commit("SET_SAVE_FOR_LATER", data);
    },
    setPastDeadline({ commit }, data) {
      commit("SET_PAST_DEADLINE", data);
    },
  },
  getters: {
    getCurrentOrder(state) {
      return state?.currentOrder || [];
    },
    getCurrentProgram(state) {
      return state?.currentProgram || {};
    },
    getInternships(state) {
      return state?.internship || [];
    },
    getPreselectedSession(state) {
      return state?.preselections.session ?? {};
    },
    getHousingQuestionnaire(state) {
      return state?.housingQuestionnaire || {};
    },
    getHousingBilling(state) {
      return state.housingBilling;
    },
    getConfiguratorIncomplete(state) {
      return state.configuratorIncomplete;
    },
    getSaveForLater(state) {
      return state.saveForLater;
    },
    getPastDeadline(state) {
      return state.pastDeadline;
    },
  },
};
export default configurator;
