<script setup>
import "flatpickr/dist/flatpickr.css";
import "cloudinary-video-player/cld-video-player.min.css";

import { useStore } from "vuex";
import {
  ref,
  watch,
  computed,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import { videoPlayer } from "cloudinary-video-player";
import SimpleBreadCrumb from "@/components/shared/SimpleBreadCrumb.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import ExcursionBlock from "@/components/ExperiencePage/ProgramDetailsSection/excursions/Excursion.vue";
import AcademicSection from "@/components/ExperiencePage/AcademicSection/AcademicSection.vue";
import InternshipSection from "@/components/ExperiencePage/InternshipSection/InternshipSection.vue";
import StudentsSaying from "@/components/ExperiencePage/StudentsSection/StudentsSaying.vue";
import HostInstitutionBlock from "@/components/ExperiencePage/ProgramDetailsSection/host-institution";
import FinancialAid from "@/components/ExperiencePage/ProgramDetailsSection/financial-aid";
import HousingBlock from "@/components/ExperiencePage/ProgramDetailsSection/housing";
import LocationBlock from "@/components/ExperiencePage/ProgramDetailsSection/location";
import PriceBreakdownModal from "@/components/ExperiencePage/ProgramDetailsSection/price-breakdown";
import TeamBlock from "@/components/ExperiencePage/ProgramDetailsSection/team";
import Ribbon from "@/components/shared/Badge/Ribbon";
import ProgramDetailSideBar from "../SideBarMenu/ProgramDetailSideBar.vue";
import ProgramDatesSection from "../Sessions/Components/ProgramDatesSection";
import {
  useGetProgramPageOptions,
  useGetProgramPageOptionsLite,
} from "@/components/ExperiencePage/composables/program_page.js";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";
import ApplicationExistsModal from "@/components/ExperiencePage/ApplicationExistsModal.vue";
import { applicationAlreadyExists } from "@/components/ExperiencePage/utils.js";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import { isFacultyLed } from "@/util/isFacultyLed";
import {
  CLOUDINARY_VIDEO_EXTENSION_TYPES,
  ERROR_TIMEOUT,
  CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
  APICompany,
} from "@/constants.js";
import {
  buildStringDateRangeSessionTravel,
  formatDate,
  parseSessionStartDate,
} from "@/mixins/helpers";
import Page404 from "@/university/views/404Page.vue";
import { imageTransformation } from "@/util/cloudinary.js";
import _ from "lodash";
import ApplyButton from "@/components/shared/Button/ApplyButton.vue";
import { sessionStatus } from "@/components/program-manager/sessions/constants";
import SkeletonLoader from "@/components/shared/loaders/BaseSkeletonLoader.vue";
import { parse } from "date-fns";
import { useDynamicHeight } from "@/composables/useDynamicHeights.js";

const CLOUDINARY_CLOUD_NAME = process.env.MIX_UI_CLOUDINARY_CLOUD_NAME;

const store = useStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();

if (_.isEmpty(store.state.billingRules)) {
  store.dispatch("getBillingRules");
}

const displayNewDesigns = computed(() => {
  return store.state?.featureFlags?.['date-card-redesign'] === true;
});
const programPageId = computed(() => route?.params?.programPageId ?? null);
const initialSessionId = ref(route?.query?.["session-id"] ?? undefined);
const showAllSessions = computed(() => Boolean(route?.query?.["session-all"]) || Boolean(displayNewDesigns.value));

const sessionId = ref("");
const sessions = ref([]);
const leadMedia = ref([]);
const session_profiles = ref([]);
const programDetails = ref(undefined);
const programDetailsLite = ref(undefined);
const scrollTarget = ref("");
const preview = ref(false);
const hasVideo = ref(false);
const cloudinaryVideoPlayer = ref();
const image = ref("");
const showApplicationTakenModal = ref(false);
const oktaId = computed(() => store.state.currentUser?.participantId ?? "");
const profileData = computed(() => store.state.profileData);

const homeInstitutionId = computed(
  () => store?.state?.profileData?.colleges?.[0]?.college_id ?? ""
);

const deadlineNotPassedSessions = computed(() => {
  const today = new Date();
  const sessionsWithDeadlineAvailable = sessions.value.filter((session) => {
      const applicationDeadline = convertStringToDate(session.application_deadline, session.id)
      const isADateObject = applicationDeadline instanceof Date;
      const isAValidDate = !isNaN(applicationDeadline);
      return isADateObject && isAValidDate && applicationDeadline > today;
  });

  return sessionsWithDeadlineAvailable;
});

const nextAvailableSessionIndex = computed(() => {
  return sessions.value.findIndex((session) => {
    return session.id === deadlineNotPassedSessions.value?.[0]?.id
  });
});

onMounted(async () => {
  if (!cloudinaryVideoPlayer.value) {
    cloudinaryVideoPlayer.value = videoPlayer("experiencePageProgramVideo", {
      cloud_name: CLOUDINARY_CLOUD_NAME,
      secure: true,
      seekThumbnails: false,
      sourceTypes: ["hls", "dash", "webm/vp9", "mp4/h265"].concat(
        CLOUDINARY_VIDEO_EXTENSION_TYPES
      ),
    });
  }
  if (store.state.currentUser) await store.dispatch("getStudentApplications");

  // Get profile data if we have a profile id
  if (oktaId.value && _.isEmpty(profileData.value)) {
    await store.dispatch("getProfile", oktaId.value);
  }
});

onUnmounted(() => {
  if (observer.value) observer.value.disconnect();
  if (cloudinaryVideoPlayer.value) {
    cloudinaryVideoPlayer.value.dispose();
    cloudinaryVideoPlayer.value = undefined;
  }
});

const testFindBranding = async () => {
  if (localStorage.getItem("branding")) {
    if (
      !localStorage.getItem("primaryColor") ||
      !localStorage.getItem("secondaryColor")
    ) {
      toast.open({
        message:
          "Custom branding failed to load. Please try refreshing the page or contact support for assistance at productsupport@apiexperience.com.",
        type: "error",
        position: "bottom",
        duration: ERROR_TIMEOUT,
      });
    }
  }
};

const isValidProgramPage = ref(true);
const observer = ref(null);
const sideBarItemsToObserve = ref([]);

const initializeIntersectionObserver = () => {
  if (!observer.value) {
    // initialize observer if it hasn't started
    // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
    observer.value = new IntersectionObserver(
      (entries) => {
        entries.forEach((entryRecord) => {
          if (entryRecord?.target?.id) {
            let foundElement = sideBarItemsToObserve.value.find(
              (item) => item.id === entryRecord?.target?.id
            );
            if (foundElement) {
              foundElement.isIntersecting = entryRecord.isIntersecting;
              foundElement.intersectionRatio = entryRecord.intersectionRatio;
            }
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: [0, 0.25, 0.5, 0.7, 0.8, 0.9, 1],
      }
    );
  }
};

const retryObservation = (elementId) => {
  if (!observer.value) {
    return;
  }
  let maxRetries = 60;
  let retries = 0;
  const retryInterval = setInterval(() => {
    if (retries >= maxRetries) {
      clearInterval(retryInterval);
      return;
    }
    const element = document.getElementById(elementId);
    if (element) {
      try {
        observer.value.observe(element);
        clearInterval(retryInterval);
      } catch (e) {
        clearInterval(retryInterval);
      }
    }
    retries++;
  }, 1000);
};

const addElementToObserver = (elementId) => {
  if (!observer.value) {
    return;
  }
  const element = document.getElementById(elementId);
  if (!element) {
    retryObservation(elementId);
    return;
  }
  try {
    observer.value.observe(element);
  } catch (e) {
    console.warn("Failed to observe item: " + elementId + " retrying...", e);
    retryObservation(elementId);
  }
};

const setActiveMenuItems = (menuItems) => {
  // Stores which elements on screen user can interact with
  sideBarItemsToObserve.value = menuItems.map((item) => {
    return {
      isIntersecting: false,
      intersectionRatio: 0,
      id: item.links, // id of section
      sidebarid: item.sidebarlink, // id of sidebar element
    };
  });

  // Sets up observer to track which sections are currently being viewed
  initializeIntersectionObserver();

  // Observe menu items
  menuItems.forEach((menuItem) => addElementToObserver(menuItem.links));
};

const sideBarIntersectingElements = computed(() => {
  return structuredClone(sideBarItemsToObserve.value).sort(
    (a, b) => b.intersectionRatio - a.intersectionRatio
  ); // doesn't modify og array
});

watch(
  () => sideBarIntersectingElements.value,
  (recentElements) => {
    for (let i = 0; i < recentElements.length; i++) {
      let sideBarElementId = recentElements[i].sidebarid;
      let sideBarElement = document.getElementById(sideBarElementId);
      if (sideBarElement) {
        // First item in array will be highlighted
        i === 0 && recentElements[i].isIntersecting
          ? sideBarElement.classList.add("bg-gray-experienceitem")
          : sideBarElement.classList.remove("bg-gray-experienceitem");
      }
    }
  }
);

const scrollToSection = (id) => {
  scrollTarget.value = document.getElementById(id);
  if (scrollTarget.value) {
    scrollTarget.value.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const isMobile = () => {
  return window.innerWidth <= 760;
};

const isNotMobile = () => {
  return window.innerWidth >= 760;
};

onBeforeMount(async () => {
  const uuidRegex =
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$";
  if (!programPageId.value || !programPageId.value.match(uuidRegex)) {
    isValidProgramPage.value = false;
  }
  if (isValidProgramPage.value) {
    try {
      await fetchAndUseProgramPageData();
    } catch (error) {
      isValidProgramPage.value = false;
    }
  }
});

const fetchAndUseProgramPageData = async () => {
  executeFetchProgramPageOptionsLite(0, {
    program_page_id: programPageId.value,
    home_institution_id: homeInstitutionId.value,
    hide_sessions: true,
  });

  executeFetchProgramPageOptions(0, {
    program_page_id: programPageId.value,
    home_institution_id: homeInstitutionId.value,
  });
  await testFindBranding();
};

const {
  execute: executeFetchProgramPageOptionsLite,
  state: programPageOptionsLite,
  isLoading: liteLoading,
} = useGetProgramPageOptionsLite(
  {
    immediate: false,
    throwError: true,
    resetOnExecute: true,
  },
  {
    program_page_id: programPageId.value,
    params: {
      session_status: showAllSessions.value
        ? undefined
        : sessionStatus.openEnrollment,
      home_institution_id: homeInstitutionId.value,
      hide_sessions: true,
    },
  }
);

const {
  execute: executeFetchProgramPageOptions,
  state: programPageOptions,
  isLoading: isLoading,
} = useGetProgramPageOptions(
  {
    immediate: false,
    throwError: true,
    resetOnExecute: true,
  },
  {
    program_page_id: programPageId.value,
    params: {
      session_status: showAllSessions.value
        ? undefined
        : sessionStatus.openEnrollment,
      home_institution_id: homeInstitutionId.value,
    },
  }
);

function applySessionFilters(session) {
  const today = new Date();

  let checkStatus = false;
  if (!showAllSessions.value) {
    checkStatus =
      sessionStatus.openEnrollment !== session?.status?.id &&
      sessionStatus.inSession !== session?.status?.id;
  }

  return !(
    parseSessionStartDate(session?.session_travel_details) < today &&
    checkStatus
  );
}

function applySessionsSortByStartDate(session1, session2) {
  const session1StartDate = parseSessionStartDate(
    session1?.session_travel_details
  );
  const session2StartDate = parseSessionStartDate(
    session2?.session_travel_details
  );
  return session1StartDate - session2StartDate;
}

/**
 * Converts a String to a Date object
 * @param {String} dateString -> a date in the following format: yyyy-mm-dd
 * @returns {Date} A date object
 */
function convertStringToDate(dateString = "", sessionId = "") {
  try {
    return parse(dateString, "yyyy-MM-dd", new Date())
  } catch (e) {
    console.error("Error while trying to convert string into a date object for session: " + sessionId, e);
  }
}

watch(programPageOptionsLite, (state) => {
  programDetailsLite.value = state ?? {};
  leadMedia.value = JSON.parse(state.lead_media);
  image.value = Array.isArray(leadMedia.value)
    ? imageTransformation({
        ...CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
        path: leadMedia.value?.[0],
        height: 900,
        width: 1600,
      })
    : undefined;
});

watch(programPageOptions, (state) => {
  programDetails.value = state ?? {};
  hasVideo.value = false;
  leadMedia.value = JSON.parse(state.lead_media);
  image.value = Array.isArray(leadMedia.value)
    ? imageTransformation({
        ...CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
        path: leadMedia.value?.[0],
        height: 900,
        width: 1600,
      })
    : undefined;
  const video = Array.isArray(leadMedia.value)
    ? leadMedia.value.filter((item) =>
        CLOUDINARY_VIDEO_EXTENSION_TYPES.some((type) => item.endsWith(type))
      )
    : undefined;
  cloudinaryVideoPlayer.value.stop();
  if (Array.isArray(state.sessions)) {
    sessions.value = state.sessions
      .filter((session) => applySessionFilters(session))
      .sort((session1, session2) =>
        applySessionsSortByStartDate(session1, session2)
      );
    let ind;
    if (initialSessionId.value) {
      ind = sessions.value.indexOf(
        sessions.value?.find(
          (session) => session?.id === initialSessionId.value
        )
      );
      ind < 0 ? (ind = 0) : ind;
    } else {
      ind = nextAvailableSessionIndex.value < 0 ? 0 : nextAvailableSessionIndex.value;
    }
    sessionId.value = sessions.value[ind]?.id;
    session_profiles.value = sessions.value.flatMap(
      ({ session_profiles }) => session_profiles
    );
  }
  if (video?.length > 0) {
    hasVideo.value = true;
    cloudinaryVideoPlayer.value.source(video[0].replace(".avi", ".mp4"));
  }
});

const selectedSession = computed(
  () => sessions.value?.find((session) => session?.id === sessionId.value) || {}
);

const setSelectedSession = async (selectedSessionId) => {
  sessionId.value = selectedSessionId;
  if (displayNewDesigns.value) {
    // Update session id param
    router.replace({
      name: "program-detail-view",
      params: {
        programPageId: programPageId.value
      },
      query: {
        ...route?.query ?? {},
        "session-id": selectedSessionId
      },
    });
  }
}

const selectedSessionHostInstitution = computed(() =>
  selectedSession.value?.session_hosting_institutions &&
  Array.isArray(selectedSession.value?.session_hosting_institutions)
    ? selectedSession?.value?.session_hosting_institutions?.[0]?.entity
    : {}
);

const selectedSessionCity = computed(() =>
  selectedSession.value?.session_travel_details &&
  Array.isArray(selectedSession.value?.session_travel_details)
    ? selectedSession.value?.session_travel_details?.[0]?.arrival_city
    : {}
);

const selectedSessionInternships = computed(() =>
  selectedSession.value?.internship_filter_criteria
    ? selectedSession.value?.internship_filter_criteria
    : {}
);

const getLearnerQuotes = computed(() =>
  programDetails.value?.program_page_testimonials &&
  Array.isArray(programDetails.value?.program_page_testimonials)
    ? programDetails.value?.program_page_testimonials
    : []
);

watch(programPageId, async (newValue) => {
  if (newValue) {
    try {
      await fetchAndUseProgramPageData();
    } catch (error) {
      toast.open({
        message: "Error while loading ProgramPage. Please, try it later",
        type: "error",
        position: "bottom",
        duration: ERROR_TIMEOUT,
      });
    }
  } else {
    toast.open({
      message: "Empty program id. Please, try it later",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  }
});

const onApplyClicked = async (newSelectedSessionId) => {
  const { studentApplications } = store.state;
  const exists = await applicationAlreadyExists({
    programSession: selectedSession.value,
    studentApplications: studentApplications,
    skipRequest: true,
  });
  showApplicationTakenModal.value = exists;
  if (!exists) {
    if (newSelectedSessionId && sessionId.value !== newSelectedSessionId)
      sessionId.value = newSelectedSessionId;
    router.push({
      name: "configurator-new",
      params: { programPageId: programPageId.value },
      query: {
        session: sessionId.value,
      },
    });
  }
};

const closeModal = () => {
  preview.value = !preview.value;
};

const observeSection = (elementId) => {
  // Elements removed/deleted from DOM need to be added to observer again.
  // id must match the id of the section here
  addElementToObserver(elementId);
};

const menuInfoSection = ref(null)
const menuItemsSection = ref(null)
const menuSection = ref(null)
const sections = [menuInfoSection, menuItemsSection];
const { inViewHeight } = useDynamicHeight(sections, menuSection, 40);
</script>

<template>
  <div class="md:flex md:flex-col">
    <ApplicationExistsModal
      :show-application-taken-modal="showApplicationTakenModal"
      @close="showApplicationTakenModal = false"
    />
    <PriceBreakdownModal
      v-if="programDetails"
      :session="selectedSession"
      :program="programDetails"
      :open-modal="preview"
      @close-modal="closeModal"
    ></PriceBreakdownModal>
    <SimpleBreadCrumb
      class="md:!ml-[85px] !ml-0 md:mt-[12px] md:mb-[10px]"
      :custom-active-class="
        `${secondaryColor}`
          ? `${secondaryColorClass} font-semibold`
          : `text-university-secondary font-semibold`
      "
    />
    <div v-if="isValidProgramPage">
      <!--- CONTENT ---->
      <div
        class="grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-t-8 lg:mx-0 lg:max-w-none lg:grid-cols-2"
      >
        <div
          class="mt-4 sm:mx-0 lg:row-end-1 lg:col-span-1 lg:row-span-1 pl-4 md:pl-24 lg:pl-24"
        >
          <SkeletonLoader v-if="isLoading" :height="'h-10'" rounded />
          <h3
            v-if="
              selectedSessionCity &&
              selectedSession?.session_travel_details &&
              !isLoading
            "
            class="text-2xl font-bold text-indigo-base"
          >
            {{ selectedSessionCity.city_ascii }},
            {{ selectedSessionCity.country.name }}
          </h3>
          <SkeletonLoader v-if="liteLoading" :height="'h-16'" rounded />
          <h1
            v-if="!liteLoading"
            class="mb-2 uppercase font-bold text-4xl tracking-wide leading-none text-indigo-base"
          >
            {{ programDetailsLite?.name }}
          </h1>
          <div class="flex mb-2 items-center space-x-3">
            <div
              v-if="
                selectedSession &&
                selectedSession.session_travel_details &&
                !isLoading
              "
              class="flex items-center"
            >
              <img src="/images/icon-calendar.svg" class="h-6 w-6" />
              <label
                class="flex ml-3 whitespace-nowrap text-md font-semibold text-gray-700"
              >
                {{
                  buildStringDateRangeSessionTravel(
                    selectedSession?.session_travel_details,
                    {
                      academic_start_date: selectedSession?.academic_start_date,
                      academic_end_date: selectedSession?.academic_end_date,
                    },
                    (monthFormat = "long")
                  )
                }}
              </label>
            </div>
            <SkeletonLoader
              v-if="isLoading"
              :height="'h-7'"
              class="mb-1 mt-2"
              rounded
            />
            <div v-else>
              <div v-if="isNotMobile()" class="flex-shrink-0">
                <div class="lg:flex justify-left items-center w-full flex-wrap">
                  <button
                    class="font-bold text-base uppercase flex flex-row items-center cursor-pointer"
                    :class="[
                      secondaryColor
                        ? `${secondaryColorClass}`
                        : `text-university-secondary`,
                    ]"
                    @click="scrollToSection('dates')"
                  >
                    View more session dates
                    <ArrowRight custom-class="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SkeletonLoader
            v-if="isLoading && isMobile()"
            :height="'h-7'"
            class="mb-1 mt-2"
            rounded
          />
          <div v-else>
            <div v-if="isMobile()" class="flex mb-2 items-center space-x-3">
              <div class="flex-shrink-0">
                <div class="lg:flex justify-left items-center w-full flex-wrap">
                  <button
                    class="font-bold text-base uppercase text-university-secondary flex flex-row items-center cursor-pointer"
                    :class="[
                      secondaryColor
                        ? `${secondaryColorClass}`
                        : `text-university-secondary`,
                    ]"
                    @click="scrollToSection('dates')"
                  >
                    View more session dates
                    <ArrowRight custom-class="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SkeletonLoader
            v-if="isLoading"
            :height="'h-7'"
            class="mb-1 mt-2"
            rounded
          />
          <div v-else class="flex mb-2 items-center space-x-3">
            <div class="flex items-center">
              <img src="/images/icon_calendar_clock.svg" class="h-6 w-6" />
              <label
                class="flex ml-3 whitespace-nowrap text-md font-semibold text-gray-700"
              >
                {{
                  formatDate(selectedSession?.application_deadline, "long")
                }}</label
              >
            </div>
            <div v-if="isNotMobile()" class="flex-shrink-0">
              <div class="lg:flex justify-left items-center w-full flex-wrap">
                <button
                  class="font-bold text-base uppercase flex flex-row items-center cursor-pointer"
                  :class="[
                    secondaryColor
                      ? `${secondaryColorClass}`
                      : `text-university-secondary`,
                  ]"
                  @click="scrollToSection('dates')"
                >
                  View more application deadlines
                  <ArrowRight custom-class="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
          <SkeletonLoader
            v-if="isLoading && isMobile()"
            :height="'h-5'"
            class="mb-1 mt-2"
            rounded
          />
          <div v-else>
            <div v-if="isMobile()" class="flex mb-2 items-center space-x-3">
              <div class="flex-shrink-0">
                <div class="lg:flex justify-left items-center w-full flex-wrap">
                  <button
                    class="font-bold text-base uppercase text-university-secondary flex flex-row items-center cursor-pointer"
                    :class="[
                      secondaryColor
                        ? `${secondaryColorClass}`
                        : `text-university-secondary`,
                    ]"
                    @click="scrollToSection('dates')"
                  >
                    View more application deadlines
                    <ArrowRight custom-class="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SkeletonLoader
            v-if="liteLoading"
            :height="'h-30'"
            class="mb-4 mt-2"
            rounded
          />
          <div
            v-else-if="programDetailsLite?.description"
            class="mt-4 mb-2 font-semibold text-base text-indigo-base"
          >
            <JSONtoHTML
              class="font-normal"
              :tiptap-json="JSON.parse(programDetailsLite?.description)"
            />
          </div>
          <SkeletonLoader
            v-if="isLoading"
            :width="'w-96'"
            :height="'h-12'"
            rounded
          />
          <div v-else>
            <ApplyButton
              :id="selectedSession?.id"
              :disabled="pastSessionDeadline(selectedSession)"
              class="py-2 text-sm"
              type="button"
              event-category="Program Page Personalize CTA"
              @click="onApplyClicked(sessionId)"
            />
          </div>
        </div>

        <div v-show="liteLoading" style="height: 436px; width: auto;">
          <SkeletonLoader :height="'inherit'" class="mt-4" rounded />
        </div>
        <div
          class="bg-abstract-pattern flex w-full h-full pr-4 md:pr-24 lg:pr-24"
        >
          <div class="mt-4 lg:col-start-2 lg:row-end-1">
            <div
              v-show="hasVideo && !liteLoading"
              class="w-full h-fit pb-8 mx-auto"
            >
              <video
                id="experiencePageProgramVideo"
                controls
                muted
                class="w-full h-[20rem] lg:h-[20rem] xl:h-[20rem] mx-auto"
              ></video>
            </div>
            <div
              v-show="!hasVideo && leadMedia.length > 0 && !liteLoading"
              class="w-full h-fit pb-8 mx-auto"
            >
              <img class="w-full mx-auto" :src="image" />
            </div>
          </div>
        </div>
      </div>

      <Ribbon :session="selectedSession" :is-loading="isLoading" />

      <div class="md:flex">
        <section ref="menuSection" class="bg-gray-10 w-full md:max-w-492p transition-height overflow-y-hidden md:overflow-y-visible" :style="{ height: inViewHeight }">
          <div
          ref="menuInfoSection"
            id="side-bar-menu"
            class="md:block bg-gray-75 w-full md:max-w-492p md:min-w-492px scoped-sidebar-padding"
          >
            <ProgramDetailSideBar
              :session-id="sessionId"
              :sessions="sessions"
              :show-all-sessions="showAllSessions"
              :is-loading="isLoading"
              @apply-clicked="onApplyClicked"
              @preview="closeModal"
              @select-session="(sessionId) => setSelectedSession(sessionId)"
              @set-menu-items="setActiveMenuItems"
            />
          </div>
          <div ref="menuItemsSection" id="sticky-sidebar-items" class="sticky top-0 self-start"></div>
        </section>
        <div>
          <div class="mt-6">
            <div>
              <ProgramDatesSection
                id="dates"
                :sessions="sessions"
                :session-id="sessionId ?? ''"
                :travel_details="selectedSession?.session_travel_details"
                :is-loading="isLoading"
                @apply-clicked="onApplyClicked"
                @programdates-set-session-id="(sessionId) => setSelectedSession(sessionId)"
                @preview="closeModal"
              />
              <StudentsSaying :quotes="getLearnerQuotes" />
              <LocationBlock
                id="location"
                :city="selectedSessionCity"
                @observe-section="observeSection"
              />
              <HostInstitutionBlock
                v-if="
                  selectedSession.session_types &&
                  !isFacultyLed(selectedSession.session_types)
                "
                id="host"
                :host-institution="selectedSessionHostInstitution"
                @observe-section="observeSection"
              />
              <AcademicSection
                id="academicdetails"
                :session="selectedSession"
                :session-id="sessionId"
                :host-institution="selectedSessionHostInstitution"
                @apply-clicked="onApplyClicked"
                @observe-section="observeSection"
              />
              <div
                v-show="
                  selectedSession.session_units &&
                  selectedSession.session_units.length > 0
                "
              >
                <div id="housing">
                  <HousingBlock
                    :session-id="sessionId"
                    :sessions="sessions"
                    @select-session="
                      (sessionId) => setSelectedSession(sessionId)
                    "
                    @personalize-and-apply="onApplyClicked(sessionId)"
                    @observe-section="observeSection"
                  />
                </div>
              </div>
              <TeamBlock id="team" :session-profiles="session_profiles" />
              <div id="internships">
                <InternshipSection
                  v-if="programDetails"
                  :internships-obj="selectedSessionInternships"
                  :session="selectedSession"
                  :program="programDetails"
                  @observe-section="observeSection"
                />
              </div>
            </div>
          </div>
          <div id="excursions">
            <ExcursionBlock
              :session-id="sessionId"
              @personalize-and-apply="onApplyClicked"
              @observe-section="observeSection"
            />
          </div>
          <div id="scholarship">
            <FinancialAid
              v-if="programDetails"
              :session="selectedSession"
              :program="programDetails"
            />
          </div>
        </div>
      </div>
      <div class="bg-abstract-pattern">
        <div
          class="pt-20 pb-20 pl-20 pr-24 lg:pl-40 lg:pr-24 xl:pl-40 xl:pr-24 text-indigo-base"
        >
          <h1 class="text-center text-4xl mb-2 font-bold text-indigo-base">
            Hosted By
          </h1>
          <h3 class="text-center pb-4 text-xl mb-2 font-bold text-indigo-base">
            ACADEMIC PROGRAMS INTERNATIONAL
          </h3>
          <p class="text-center text-md">
            Questions? Contact:
          </p>
          <div class="grid place-items-center">
            <a
              class="font-bold"
              :class="[
                secondaryColor
                  ? `${secondaryColorClass}`
                  : `text-university-secondary`,
              ]"
              :href="`mailto:studentoutreach${APICompany.companyEmail}`"
            >
              {{ `studentoutreach${APICompany.companyEmail}` }}
            </a>
          </div>
        </div>
        <hr class="my-1 border-b-3 md:mt-20 border-gray-200" />
      </div>
    </div>
    <div v-else>
      <Page404 />
    </div>
  </div>
</template>

<style scoped>
.scoped-sidebar-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
