<script setup>
import Menu from "../Menu/Menu";
import { computed, defineProps, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  ldClient: {
    type: String,
    default: "",
  },
  logo: {
    type: String,
    default: "",
  },
});
const testMode = computed(() => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("test-mode") === "true";
});

onMounted(() => {
  if (props.ldClient && !testMode.value) {
    const ldClient = JSON.parse(props.ldClient);
    const featureFlags = Object.keys(ldClient)
      .filter((key) => !key.includes("$"))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: ldClient[key] });
      }, {});
    store.commit("setFeatureFlags", featureFlags);
  }
});
</script>

<template>
  <Menu :logo="props.logo" />
</template>
