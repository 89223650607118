export const findProductPriceBySession = (products, sessionStartDateString) => {
  if (!sessionStartDateString) return null;

  const sessionStartDate = new Date(sessionStartDateString);

  let sessionProduct = products.find((product) => {
    let inRange = false;

    if (product.effective_start_date && product.effective_end_date) {
      const priceStartDate = new Date(product.effective_start_date);
      const priceEndDate = new Date(product.effective_end_date);

      inRange = Boolean(
        sessionStartDate >= priceStartDate && sessionStartDate <= priceEndDate
      );
    }

    return inRange;
  });

  if (!sessionProduct) {
    sessionProduct = products.find((product) => !product.effective_end_date);
  }

  return sessionProduct;
};
