<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="#1E264C"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7454 21.1249H15.9745C15.9745 20.9236 15.9681 20.7187 15.9553 20.5104C15.9424 20.302 15.9266 20.0972 15.9079 19.8958H21.7454C21.8426 19.8958 21.9225 19.8645 21.985 19.802C22.0475 19.7395 22.0787 19.6597 22.0787 19.5624V6.43752C22.0787 6.34029 22.0475 6.26042 21.985 6.19791C21.9225 6.1354 21.8426 6.10415 21.7454 6.10415H4.2871C4.19682 6.10415 4.11869 6.1354 4.0527 6.19791C3.98672 6.26042 3.95373 6.34029 3.95373 6.43752V7.86457C3.75236 7.84583 3.54866 7.83003 3.34265 7.81717C3.13664 7.80433 2.93062 7.79792 2.72461 7.79792V6.43752C2.72461 6.01321 2.87895 5.64672 3.18763 5.33804C3.4963 5.02935 3.8628 4.875 4.2871 4.875H21.7454C22.1751 4.875 22.5429 5.02935 22.8489 5.33804C23.1549 5.64672 23.3079 6.01321 23.3079 6.43752V19.5624C23.3079 19.9867 23.1549 20.3532 22.8489 20.6619C22.5429 20.9706 22.1751 21.1249 21.7454 21.1249ZM2.72461 21.1249V18.777C3.35076 18.777 3.88576 19.0065 4.32962 19.4653C4.77348 19.9242 4.99541 20.4774 4.99541 21.1249H2.72461ZM7.76625 21.1249C7.76625 19.7144 7.27736 18.5104 6.29958 17.5129C5.32182 16.5154 4.13016 16.0132 2.72461 16.0062V14.7771C4.47092 14.7771 5.95263 15.3965 7.16972 16.6352C8.38682 17.874 8.99538 19.3706 8.99538 21.1249H7.76625ZM11.9746 21.1249C11.9746 19.8416 11.7339 18.6322 11.2527 17.4968C10.7714 16.3614 10.1128 15.3711 9.27666 14.526C8.44055 13.6809 7.46099 13.0149 6.33799 12.5281C5.21497 12.0413 4.01051 11.7979 2.72461 11.7979V10.5688C4.17738 10.5688 5.53946 10.8467 6.81086 11.4027C8.08226 11.9586 9.19136 12.7131 10.1382 13.6662C11.0849 14.6193 11.8326 15.7387 12.381 17.0246C12.9295 18.3104 13.2037 19.6772 13.2037 21.1249H11.9746Z"
    />
  </svg>
</template>
