<template>
  <div
    :class="[
      `relative h-10 flex justify-center items-center flex-grow border-collapse md:border-l md:border-t md:border-b md:border-medium-gray md:pl-[25px]
      ${title === 'loading' ? 'loading-gradient' : ''}
      ${isLastStep ? 'md:border-r' : ''}
      ${cursor}`,
      isCurrentStep || completed
        ? secondaryColor
          ? `bg-gray-275`
          : 'bg-teal-light'
        : 'bg-white',
    ]"
    @click.stop="handleClick"
  >
    <svg
      class="max-w-[24px] w-[24px]"
      :class="{ 'md:hidden': title === 'loading' }"
      viewBox="0 0 24 24"
    >
      <circle
        cx="50%"
        cy="50%"
        r="10"
        :stroke="
          isCurrentStep || completed
            ? secondaryColor
              ? `${secondaryColor}`
              : '#007f80'
            : '#79747E'
        "
        stroke-width="2.5"
        :fill="
          completed && !isCurrentStep
            ? secondaryColor
              ? `${secondaryColor}`
              : '#007f80'
            : 'none'
        "
      />
      <circle
        v-if="isCurrentStep"
        cx="50%"
        cy="50%"
        r="6"
        stroke-width="1"
        :fill="secondaryColor ? `${secondaryColor}` : '#007f80'"
      />
      <text
        v-if="!isCurrentStep"
        class="font-montserrat text-xs font-bold"
        :class="{ hidden: title === 'loading' }"
        x="50%"
        y="16"
        text-anchor="middle"
        :fill="numberColor"
      >
        {{ index + 1 }}
      </text>
      <circle
        v-if="title === 'loading'"
        cx="50%"
        cy="50%"
        r="6"
        stroke-width="1"
        fill="#79747E"
      />
    </svg>
    <div
      v-if="title !== 'loading'"
      class="hidden md:inline-block mx-2 font-montserrat text-xs font-bold"
      :class="
        isCurrentStep || completed ? `${secondaryColorClass}` : 'text-gray-900'
      "
    >
      {{ capitalizeTitle(title) }}
    </div>
    <div
      v-if="!isLastStep"
      class="hidden lg:block absolute h-full w-[25px] left-full top-0 z-10"
    >
      <svg class="h-full w-full" viewPort="0 0 20 40">
        <polygon
          points="0,0 20,20 0,40"
          stroke="none"
          :fill="
            isCurrentStep || completed
              ? secondaryColor
                ? `#eaebed`
                : '#EDF9F8'
              : 'white'
          "
        />
        <path d="M0,0 20,20 L0,40" stroke="#EBEBEB" fill="none" />
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    isSelectable: {
      type: Function,
      default: () => false,
    },
    isComplete: {
      type: Function,
      default: () => false,
    },
    currentStep: {
      type: Object,
      default: () => ({ slug: undefined }),
    },
    lastStepSlug: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  emits: ["setCurrentStep"],
  setup(props, { emit }) {
    const isCurrentStep = computed(() => props.slug === props.currentStep.slug);

    const isLastStep = computed(() => props.slug === props.lastStepSlug);

    const handleClick = () => {
      if (props.isSelectable()) {
        emit("setCurrentStep", props.slug);
      }
    };

    const completed = computed(() => props.isComplete());

    const cursor = computed(() =>
      props.isSelectable() ? "cursor-pointer" : "cursor-default"
    );

    const backgroundColorHexValue = computed(() =>
      isCurrentStep.value || completed.value ? "#EDF9F8" : "white"
    );

    const backgroundColorTailwindLabel = computed(() =>
      isCurrentStep.value || completed.value ? "blue-900" : "white"
    );

    const titleColor = computed(() =>
      completed.value || isCurrentStep.value ? "text-teal-900" : "text-gray-900"
    );

    const numberColor = computed(() => (completed.value ? "white" : "#5e5e5e"));

    const capitalizeTitle = (str) =>
      str.replace(
        /(^\w|\s\w)(\S*)/g,
        (_, firstLetter, restOfWord) =>
          firstLetter.toUpperCase() + restOfWord.toLowerCase()
      );

    return {
      backgroundColorHexValue,
      backgroundColorTailwindLabel,
      isCurrentStep,
      isLastStep,
      handleClick,
      completed,
      cursor,
      titleColor,
      numberColor,
      capitalizeTitle,
    };
  },
};
</script>

<style scoped>
.md:bg-teal-light {
  background-color: #edf9f8;
}

.text-gray-900 {
  color: #5e5e5e;
}
/* Media query for medium-sized screens (e.g., Tailwind CSS md breakpoint) */
@media (min-width: 900px) {
  .loading-gradient {
    background-image: linear-gradient(to right, #e5e7eb, white);
  }
}
</style>
