import { learningServiceRequest } from "./utils";

const requiredLanguageService = {
  async list(search) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/languages",
      params: { limit: 10, q: search },
      failureMessage: "Error loading Required Languages",
    });
    return response?.data?.items ?? [];
  },
};

export default requiredLanguageService;
