<template>
  <div
    v-if="isImpersonating"
    class="col-span-3 md:col-span-1 order-last md:order-2"
  >
    <section
      id="impersonation-banner"
      class="xs:flex xs:items-center bg-cream-100 font-semibold italic p-3 rounded-lg"
    >
      <span class="text-gray-800 w-full text-center">
        Viewing as {{ getUserName }}. Exit Student View before viewing another
        student.
      </span>
      <button class="inline-block" @click="exitImpersonation()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `${secondaryColorClass} hover:text-teal-100`,
          ]"
          class="h-6 w-6 stroke-current stroke-2"
          fill="none"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
      </button>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "ImpersonationBanner",

  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["isImpersonating"]),
    getUserName() {
      return [this.currentUser?.firstName, this.currentUser?.lastName]
        .filter((s) => s)
        .join(" ");
    },
  },

  methods: {
    exitImpersonation() {
      this.$store.dispatch("exitImpersonation");
    },
  },
};
</script>
