<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <h1 class="mb-2 text-xl md:font-semibold md:text-2xl">
        Additional {{ program.site.country }} Forms
      </h1>
      <div class="text-gray-600 space-y-6 mb-6">
        <p>
          {{ instructions }}
        </p>
      </div>
      <SupplementalUpload
        v-if="show.supplemental1"
        id="1"
        ref="supplemental1"
        :supplemental="supplemental1"
        :title="program.supplemental_form_one"
        :link="program.supplemental_link_one"
        :formio-data="formData.files1"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:supplemental="supplemental1 = $event"
      />
      <SupplementalUpload
        v-if="show.supplemental2"
        id="2"
        ref="supplemental2"
        :supplemental="supplemental2"
        :title="program.supplemental_form_two"
        :link="program.supplemental_link_two"
        :formio-data="formData.files2"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:supplemental="supplemental2 = $event"
      />
      <SupplementalUpload
        v-if="show.supplemental3"
        id="3"
        ref="supplemental3"
        :supplemental="supplemental3"
        :title="program.supplemental_form_three"
        :link="program.supplemental_link_three"
        :formio-data="formData.files3"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:supplemental="supplemental3 = $event"
      />
      <SupplementalUpload
        v-if="show.supplemental4"
        id="4"
        ref="supplemental4"
        :supplemental="supplemental4"
        :title="program.supplemental_form_four"
        :link="program.supplemental_link_four"
        :formio-data="formData.files4"
        :success-submission="successSubmission"
        @changeSuccessSubmission="changeSuccessSubmission"
        @update:supplemental="supplemental4 = $event"
      />
      <ButtonWithSpinner
        ref="supplementalSubmit"
        type="submit"
        variant="primary"
        variant-type="block"
        @click="submit"
      >
        <span>
          Submit
          <svg
            v-if="successSubmission && !hasErrors"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="inline feather feather-check"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </ButtonWithSpinner>
      <div v-if="submitError" class="error text-error-900 mt-2">
        {{ submitError }}
      </div>
      <div v-if="successSubmission && !hasErrors" class="text-success-900">
        Submission Complete, thank you!
      </div>
      <p v-if="hasErrors" class="text-error-900">
        Please complete the required fields correctly.
      </p>
    </template>
  </PageStyle>
</template>
<script>
import PageStyle from "@/components/forms/SharedComponents/Layout/PageDefault.vue";
import forms from "@/mixins/forms.js";
import SupplementalUpload from "./SupplementalUpload.vue";
import FormValidation from "@/mixins/formValidation";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import formIoApi from "@/mixins/formIoApi.js";

export default {
  name: "SupplementalForm",
  components: {
    PageStyle,
    SupplementalUpload,
    ButtonWithSpinner,
  },
  mixins: [forms, FormValidation, formIoApi],
  data() {
    return {
      formURL: "supplementalform",
      sectionsToValidate: [
        "supplemental1",
        "supplemental2",
        "supplemental3",
        "supplemental4",
      ],
      supplemental1: {},
      supplemental2: {},
      supplemental3: {},
      supplemental4: {},
      successSubmission: false,
      submitError: "",
      submissionId: "",
      formData: {
        files1: [],
        files2: [],
        files3: [],
        files4: [],
      },
      ruleType: "",
      show: {
        supplemental1: false,
        supplemental2: false,
        supplemental3: false,
        supplemental4: false,
      },
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
    instructions() {
      return this.program.supplemental_form_instructions ?? "";
    },
  },
  watch: {
    program: {
      handler: function () {
        this.setShow();
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    const valid = await this.isValidApplicationAndIsOnRules(
      this.applicationId,
      "Supplemental Form"
    );
    this.ruleType = valid.ruleType;

    if (valid.valid) {
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },

  methods: {
    async submit() {
      this.submitError = "";
      this.successSubmission = false;
      this.$refs.supplementalSubmit.startLoading();

      try {
        await this.validate();

        if (!this.hasErrors) {
          const method = this.submissionId ? "PUT" : "POST";

          this.submitToFormIo(
            this.formURL,
            this.getJsonData(),
            method,
            this.submissionId
          )
            .then((response) => {
              this.submissionId = response;
              this.successSubmission = true;
            })
            .catch((error) => {
              this.submitError = error;
            })
            .finally(() => {
              this.$refs.supplementalSubmit.stopLoading();
            });
        }
      } catch {
        this.$refs.supplementalSubmit.stopLoading();
      }
    },
    getJsonData() {
      return {
        data: {
          application_id: this.applicationId,
          supplementalFiles1: this.supplemental1.files ?? [],
          supplementalFiles2: this.supplemental2.files ?? [],
          supplementalFiles3: this.supplemental3.files ?? [],
          supplementalFiles4: this.supplemental4.files ?? [],
          submitted: true,
        },
      };
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;
          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.formData.files1 = response.supplementalFiles1 ?? [];
                this.formData.files2 = response.supplementalFiles2 ?? [];
                this.formData.files3 = response.supplementalFiles3 ?? [];
                this.formData.files4 = response.supplementalFiles4 ?? [];
              }
            );
          }
        }
      );
    },
    changeSuccessSubmission() {
      this.successSubmission = false;
    },
    setShow() {
      this.show.supplemental1 = !!(
        this.program.supplemental_form_one && this.program.supplemental_link_one
      );
      this.show.supplemental2 = !!(
        this.program.supplemental_form_two && this.program.supplemental_link_two
      );
      this.show.supplemental3 = !!(
        this.program.supplemental_form_three &&
        this.program.supplemental_link_three
      );
      this.show.supplemental4 = !!(
        this.program.supplemental_form_four &&
        this.program.supplemental_link_four
      );

      //Update validation sections
      for (const name in this.show) {
        const index = this.sectionsToValidate.indexOf(name);
        if (this.show[name]) {
          if (index === -1) this.sectionsToValidate.push(name);
        } else {
          if (index > -1) this.sectionsToValidate.splice(index, 1);
        }
      }
    },
  },
};
</script>
