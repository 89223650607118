<template>
  <div v-if="rows.length < 1" class="text-center pt-5">
    No matching data
  </div>
  <table v-else class="w-full">
    <thead>
      <tr v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
        <th
          v-for="(header, columnIndex) in headerGroup.headers"
          :key="header.id"
          :colSpan="header.colSpan"
          :class="[
            'py-4 pr-3 text-left text-sm text-gray-900 last:w-[5%]',
            columnIndex >= 1 ? 'sm:pl-3' : 'sm:pl-6',
            typeof table
              .getRowModel()
              .rows[0]?.getVisibleCells()
              [columnIndex].getValue() === 'number'
              ? 'text-center w-[15%]'
              : 'text-left',
          ]"
        >
          <div
            class="w-fit cursor-pointer flex items-center"
            :class="[
              header.id === 'Description' || header.id === 'Class Description'
                ? 'mx-auto'
                : 'text-left',
            ]"
            @click="handleClickHeader(header)"
          >
            <BaseCheckBox
              v-if="
                header.id === 'checkbox' &&
                $route.meta?.subcategory !== 'classes'
              "
              name="allSelected"
              data-cy="select-all-checkbox"
              :checked="allSelected"
              @input="onFieldChange('allSelected', $event, true)"
            />
            <FlexRender
              v-else-if="!header.isPlaceholder && header.id !== 'checkbox'"
              :render="header.column.columnDef.header"
              :props="header.getContext()"
            />
            <button
              v-if="selectedSortHeader === header.id"
              class="bg-gray-200 ml-2 h-full w-fit duration-200"
              :class="{ 'rotate-180': isDesc }"
            >
              <ChevronDownIcon class="w-4 h-4" />
            </button>
          </div>
        </th>
      </tr>
    </thead>
    <transition-group tag="tbody" class="bg-white">
      <tr
        v-for="row in table.getRowModel().rows"
        :key="row.id"
        class="hover:bg-blue-350"
        @click="redirectToDetailView(row.original.id)"
      >
        <td
          v-for="(cell, columnIndex) in row.getVisibleCells()"
          :key="cell.id"
          :style="
            cell.column.id === 'Class Title' ||
            cell.column.id === 'Areas of Study'
              ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '400px',
                }
              : { width: cell.column.getSize() + 'px' }
          "
          :class="[
            'whitespace-nowrap py-8 pl-4 pr-3 text-sm capitalize border-t-[1px] border-solid border-t-[#f1f2f4] last:w-[5%]',
            columnIndex < 1 || cell.column.id === 'Class Title'
              ? 'text-teal-900 sm:pl-6'
              : 'text-[#1e264c] sm:pl-3',
            cell.column.id === 'Class Title' ? 'cursor-pointer' : '',
            typeof cell.getValue() === 'number'
              ? 'text-center w-[15%]'
              : 'text-left',
            cell.column.id === 'Last Modified By' && 'normal-case',
          ]"
          @click="openClassDetailsInNewTab(cell)"
        >
          <BaseCheckBox
            v-if="
              cell.column.id === 'checkbox' &&
              $route.meta?.subcategory !== 'classes'
            "
            :name="'item-' + cell.id"
            :data-cy="cell.id + '-checkbox'"
            :checked="checkboxItems[row.original.id]"
            @input="updateCheckbox(row.original.id, $event)"
            @click.stop
          />

          <DescriptionPopUp
            v-else-if="
              cell.column.id === 'Description' ||
              cell.column.id === 'Class Description'
            "
            :description="
              row.original.Description || row.original['Class Description']
            "
          />
          <WrapContentCell
            v-else-if="cell.column.id === 'Areas of Study'"
            :content="cell.getValue()"
          />

          <FlexRender
            v-else-if="!(columnIndex + 1 === row.getVisibleCells().length)"
            :render="cell.column.columnDef.cell"
            :props="cell.getContext()"
          />

          <slot
            v-else-if="!row.original['Class Title']"
            :id="row.original.id"
            :entityId="row.original.owner_entity_id"
            name="actions"
          >
            <ActionsMenu
              :actions-route-params="
                getActionRouteParams(row.original.id, actionsRoutes.edit)
              "
              :event-route="params"
              :actions-event-params="getActionEventParams(row.original.id)"
              :permissions="permissions"
              :entityId="row.original.owner_entity_id"
              @click.stop
            />
          </slot>
        </td>
      </tr>
    </transition-group>
  </table>
</template>

<script>
import { eventBus } from "@/app";
import BaseCheckBox from "@/components/forms/SharedComponents/BaseCheckBox";
import DescriptionPopUp from "@/components/shared/DescriptionPopUp.vue";
import WrapContentCell from "@/components/shared/Table/WrapContentCell.vue";
import formInputsMixin from "@/mixins/formInputs.js";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import {
  FlexRender,
  getCoreRowModel,
  getSortedRowModel,
  useVueTable,
} from "@tanstack/vue-table";
import ActionsMenu from "./ActionsMenu.vue";

export default {
  components: {
    ActionsMenu,
    BaseCheckBox,
    ChevronDownIcon,
    DescriptionPopUp,
    FlexRender,
    WrapContentCell,
  },
  mixins: [formInputsMixin],
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    sortedColumn: {
      type: String,
      default: null,
    },
    actionsRoutes: {
      type: Object,
      default: () => {},
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    allSelected: false,
    changeSorting: false,
    checkboxItems: [],
    isDesc: false,
    selectedSortHeader: undefined,
    table: undefined,
    params: "",
  }),
  watch: {
    sortedColumn(value) {
      this.table.getColumn(value).toggleSorting(false);
      this.selectedSortHeader = value;
    },
    columns: {
      handler: function () {
        this.createTable();
      },
      deep: true,
    },
    allSelected: {
      handler: function (newValue) {
        Object.keys(this.checkboxItems).forEach((key) => {
          this.checkboxItems[key] = newValue;
        });
      },
    },
    checkboxItems: {
      handler: function (newValue) {
        let selectedItems = [];
        Object.entries(newValue).forEach(([key, val]) => {
          if (val) selectedItems.push(key);
        });

        eventBus.$emit("updateSelectedItems", selectedItems);
      },
      deep: true,
    },
  },
  created() {
    this.setCheckboxItems();
    this.createTable().getColumn(this.sortedColumn).toggleSorting();
    this.selectedSortHeader = this.sortedColumn;
    this.isEvent();
  },
  methods: {
    createTable() {
      this.table = useVueTable({
        data: this.rows,
        columns: this.columns,
        enableColumnResizing: true,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
      });
      return this.table;
    },
    getActionRouteParams(itemID, actionRouteName) {
      return {
        params: {
          ...this.$route.params,
          itemId: itemID,
        },
        name: actionRouteName,
      };
    },
    getActionEventParams(item_id) {
      return {
        item_id,
      };
    },
    redirectToDetailView(id) {
      /**
       * this.actionsRoutes.detail could change depending on where
       * the route to see the detail of a record is stored
       */
      if (this.actionsRoutes.detail) {
        this.$router.push({
          name: this.actionsRoutes.detail,
          params: {
            itemId: id,
          },
        });
      }
    },
    setCheckboxItems() {
      if (this.rows?.length) {
        this.checkboxItems = this.rows.reduce((obj, item) => {
          return {
            ...obj,
            [item.id]: false,
          };
        }, {});
      }
    },
    updateCheckbox(prop, value) {
      this.checkboxItems[prop] = value;
    },
    handleClickHeader({ id }) {
      if (id !== "actions") {
        if (id === this.selectedSortHeader) {
          this.isDesc = !this.isDesc;
        } else {
          this.isDesc = false;
          this.selectedSortHeader = id;
        }
        this.table.getColumn(id).toggleSorting(this.isDesc);
      }
    },
    openClassDetailsInNewTab(cell) {
      if (cell.column.id === "Class Title") {
        let routeData = this.$router.resolve(
          "/results/courses/" + cell.row.original.id
        );
        window.open(routeData.href, "_blank");
      }
    },
    isEvent() {
      const params = this.$route.path;
      this.params = params;
    },
  },
};
</script>

<style scoped>
.v-move {
  transition: transform 0.2s;
}
</style>
