<template>
  <FormPanel
    :id="'communicatingInAnEmergency'"
    :title="'Communicating in an Emergency'"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200 px-4"
  >
    <template #content>
      <p>
        API Abroad puts the health and safety of our participants first and
        foremost - in the event of an emergency, what is the best way to contact
        you?
      </p>
      <div class="my-5">
        <label
          v-for="option in CONTACT_OPTIONS"
          :key="option.value"
          class="flex mb-4 md:mb-0 md:flex-auto"
          :for="option.value + 'Contact'"
        >
          <input
            :id="option.value + 'Contact'"
            v-model.trim="v$.value.emergencyContact.$model"
            data-cy="emergency-contact-radio"
            type="radio"
            name="emergencyContact"
            :value="option.value"
            class="mt-1 text-university-primary form-radio"
            :class="{ 'bg-error-100': v$.value.emergencyContact.$error }"
          />
          <span class="ml-2">{{ option.label }}</span>
        </label>
        <div
          v-if="
            v$.value.emergencyContact.required.$invalid &&
            v$.value.emergencyContact.$error
          "
          class="error text-error-900 text-sm"
        >
          Field is required
        </div>
      </div>

      <div v-if="value.emergencyContact === 'myLocalCellPhone'" class="mb-5">
        <span
          class="inline font-semibold text-gray-600 justify-between mb-2 canary-classname canary-2"
        >
          My Local Cell Phone
        </span>
        <label
          v-for="option in LOCAL_OPTIONS"
          :key="option.value"
          class="flex mb-4 md:mb-0 md:flex-auto"
          :for="option.value + 'Local'"
        >
          <input
            :id="option.value + 'Local'"
            v-model.trim="v$.value.localCellPhone.$model"
            data-cy="local-cell-phone-radio"
            type="radio"
            name="localCellPhone"
            :value="option.value"
            class="mt-1 text-university-primary form-radio"
            :class="{ 'bg-error-100': v$.value.localCellPhone.$error }"
          />
          <span class="ml-2">{{ option.label }}</span>
        </label>
        <div
          v-if="
            v$.value.localCellPhone.required.$invalid &&
            v$.value.localCellPhone.$error
          "
          class="error text-error-900 text-sm"
        >
          Field is required
        </div>
      </div>

      <div
        v-if="
          value.emergencyContact === 'myCellPhone' ||
          value.localCellPhone === 'iHaveMyInternationalNumber'
        "
        class="mb-5"
      >
        <label for="phone" class="block text-sm">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Phone</span>
          </span>
          <MazPhoneNumberInput
            id="phone"
            :model-value="value.phone"
            data-cy="phone-text"
            type="tel"
            :fetch-country="false"
            :default-country-code="v$.value.phoneCode.$model ? null : 'US'"
            :no-use-browser-locale="v$.value.phoneCode.$model ? true : false"
            :clearable="true"
            :color="'info'"
            :preferred-countries="['US']"
            :no-example="true"
            :no-search="true"
            :required="true"
            :error="v$.value.phone.$error"
            :class="{ 'bg-error-100': v$.value.phone.$error }"
            @update="updatePhone($event)"
          />
          <div
            v-if="v$.value.phone.required.$invalid && v$.value.phone.$error"
            class="error text-error-900"
          >
            Field is required
          </div>
          <div
            v-if="
              v$.value.phone.validPhoneNumber.$invalid && v$.value.phone.$error
            "
            class="error text-error-900"
          >
            Invalid phone number. Please try another phone number.
          </div>
        </label>
      </div>

      <p v-if="value.emergencyContact === 'wiFiOnly'" class="mb-5">
        API recommends participants use an international plan or purchase a sim
        card / local phone plan. You may reconsider this upon arrival and can
        update this preference during your on-site orientation.
      </p>

      <p>
        Thank you for sharing with us how to get in touch in the case of an
        emergency. Supports you identified as Emergency Contact may also receive
        emergency communications in the event of a crisis. You will learn more
        about API’s health and safety procedures during your on-site
        orientation.
      </p>

      <div class="mt-5">
        <ButtonWithSpinner
          ref="emergencySubmit"
          data-cy="emergency-submitt-button"
          type="submit"
          variant="secondary"
          variant-type="normal"
          custom-class="text-sm"
          button-height="min-h-8"
          @click="submitForm"
        >
          <span>
            Save
          </span>
        </ButtonWithSpinner>
        <div v-if="submitError" class="error text-error-900 mt-2">
          {{ submitError }}
        </div>
        <div v-if="successSubmission && !v$.$invalid" class="text-success-900">
          Submission Complete, thank you!
        </div>
      </div>
    </template>
  </FormPanel>
</template>

<script>
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import { validPhoneNumber } from "@/mixins/customValidators";
import formIoApi from "@/mixins/formIoApi.js";
import formService from "@/services/form";
import { required, requiredIf } from "@vuelidate/validators";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import "maz-ui/css/main.css";
import useVuelidate from "@vuelidate/core";

const contactOptions = [
  {
    label: "My Cell Phone",
    value: "myCellPhone",
  },
  {
    label: "My Local Cell Phone – I will have an in-country cell phone",
    value: "myLocalCellPhone",
  },
  {
    label:
      "Wi-Fi Only – I will be accessible via a cell phone or other device with wifi connectvity",
    value: "wiFiOnly",
  },
];
const localOptions = [
  {
    label: "I have my international number and can provide it now",
    value: "iHaveMyInternationalNumber",
  },
  {
    label:
      "I  will provide my international number during on-site orientation.",
    value: "iWillProvideMyInternationalNumber",
  },
];

export default {
  name: "EmergencyForm",
  components: {
    ButtonWithSpinner,
    FormPanel,
    MazPhoneNumberInput,
  },
  mixins: [formIoApi],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        emergencyContact: "",
        localCellPhone: "",
        phone: "",
        phoneCode: "",
        isValidPhoneNumber: true,
      },
      formURL: "communicatinginanemergency",
      submissionId: "",
      successSubmission: false,
      submitError: "",
    };
  },
  validations: {
    value: {
      emergencyContact: { required },
      localCellPhone: {
        required: requiredIf(function () {
          return this.value.emergencyContact === "myLocalCellPhone";
        }),
      },
      phone: {
        required: requiredIf(function () {
          return (
            this.value.emergencyContact === "myCellPhone" ||
            (this.value.emergencyContact === "myLocalCellPhone" &&
              this.value.localCellPhone === "iHaveMyInternationalNumber")
          );
        }),
        validPhoneNumber,
      },
      phoneCode: {},
      isValidPhoneNumber: {},
    },
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.successSubmission = false;
        if (this.submitError) this.submitError = "";
      },
      deep: true,
    },
    "value.emergencyContact": {
      handler: function (newval) {
        if (newval !== "myLocalCellPhone") this.value.localCellPhone = "";
        if (newval === "wiFiOnly") {
          this.value.phone = "";
          this.value.phoneCode = "";
        }
      },
    },
    "value.localCellPhone": {
      handler: function (newval) {
        if (
          this.value.emergencyContact === "myLocalCellPhone" &&
          newval !== "iHaveMyInternationalNumber"
        ) {
          this.value.phone = "";
          this.value.phoneCode = "";
        }
      },
    },
  },
  created() {
    this.setConstants();
    this.getFormioData();
  },

  methods: {
    setConstants() {
      this.CONTACT_OPTIONS = contactOptions;
      this.LOCAL_OPTIONS = localOptions;
    },
    async submitForm() {
      this.successSubmission = false;
      this.submitError = "";
      this.$refs.emergencySubmit.startLoading();
      this.v$.$touch();

      if (!this.v$.$invalid) {
        const method = this.submissionId ? "PUT" : "POST";
        this.submitToFormIo(
          this.formURL,
          this.getJsonData(),
          method,
          this.submissionId
        )
          .then((response) => {
            this.submissionId = response;
            this.successSubmission = true;
          })
          .catch((error) => {
            this.submitError = error;
          })
          .finally(() => {
            this.$refs.emergencySubmit.stopLoading();
          });
      } else {
        this.$refs.emergencySubmit.stopLoading();
      }
    },
    updatePhone(data) {
      this.value.phone = data.nationalNumber;
      this.value.isValidPhoneNumber = data.isValid;
      this.value.phoneCode = data.countryCallingCode ?? "";
    },
    getFormioData() {
      formService
        .listSubmissions(this.formURL, {
          "data.application_id": this.applicationId,
          limit: 1,
        })
        .then((submissions) => {
          if (submissions.length) {
            const submission = submissions[0];
            this.submissionId = submission._id ?? "";

            const grid = submission.data?.emergencyCommGrid.length
              ? submission.data.emergencyCommGrid[0]
              : {};
            const phone = grid.emergencyCommPhoneNumber ?? "";
            const phoneCode = grid.emergencyCommCountryCode ?? "";
            const phoneFormat = phone && phoneCode ? "+" : "";

            this.value.emergencyContact = grid.emergencyComm ?? "";
            this.value.localCellPhone = grid.myLocalCellPhone ?? "";
            this.value.phoneCode = phoneCode;
            this.value.phone = phoneFormat + phoneCode + phone;
          }
        });
    },
    getJsonData() {
      return {
        data: {
          emergencyCommGrid: [
            {
              emergencyComm: this.value.emergencyContact ?? "",
              myLocalCellPhone: this.value.localCellPhone ?? "",
              emergencyCommCountryCode: this.value.phoneCode ?? "",
              emergencyCommPhoneNumber: this.value.phone ?? "",
            },
          ],
          application_id: this.applicationId,
        },
      };
    },
  },
};
</script>
