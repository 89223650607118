<template>
  <button
    :disabled="disabled"
    class="rounded-md border px-6 py-2 text-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-none font-medium mr-5 shadow-sm"
    :class="{
      'bg-teal-900 text-white hover:bg-white hover:text-teal-900 hover:border-gray-300': filled,
      'bg-white text-teal-900 hover:bg-teal-900 hover:text-white border-teal-900': outlined,
    }"
    :data-testid="dataTestId"
    @click.prevent="onClick"
  >
    <slot>
      {{ label }}
    </slot>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Click",
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: "button",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataTestId: {
      type: String,
      default: "BaseButtonTestId",
    },
  },
  computed: {
    filled() {
      return !this.outlined;
    },
  },
};
</script>
