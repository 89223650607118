import eventService from "@/services/events";
import productPriceService from "@/services/productPrices";
import { getEntityName } from "@/components/program-manager/events/utils";

const events = {
  namespaced: true,
  state: {
    events: {
      isLoading: false,
      isError: false,
      errorMessage: false,
      data: undefined,
      categories: undefined,
      subCategories: undefined,
      locations: undefined,
    },
    event: {
      isLoading: false,
      isError: false,
      errorMessage: false,
      data: undefined,
      location: undefined,
      price: undefined,
      invalidForm: false,
      refreshed: false,
      occurrences: {
        upcoming: undefined,
        past: undefined,
      },
      locations: undefined,
    },
  },
  mutations: {
    setLoadingEvent(state, payload) {
      state.event.isLoading = payload;
    },
    setRefresh(state, payload) {
      state.event.refreshed = payload;
    },
    setEventError(state, payload) {
      state.event.isError = payload;
    },
    setEventErrorMessage(state, payload) {
      state.event.errorMessage = payload;
    },
    setEvent(state, payload) {
      state.event.data = payload;
    },
    setLocation(state, payload) {
      state.event.locations = payload;
    },
    setOccurrences(state, payload) {
      state.event.occurrences = payload;
    },
    setPrice(state, payload) {
      state.event.price = payload;
    },
    setInvalidForm(state, payload) {
      state.event.invalidForm = payload;
    },
    setEventsLoading(state, payload) {
      state.events.isLoading = payload;
    },
    setEventsError(state, payload) {
      state.events.isError = payload;
    },
    setEventsErrorMessage(state, payload) {
      state.events.errorMessage = payload;
    },
    setEvents(state, payload) {
      state.events.data = payload;
    },
    setCategories(state, payload) {
      state.events.categories = payload;
    },
    setSubCategories(state, payload) {
      state.events.subCategories = payload;
    },
    setLocations(state, payload) {
      state.events.locations = payload;
    },
    setEventUpdate(state, payload) {
      state.eventUpdate.data = payload;
    },
    setEventUpdateErrorMessage(state, payload) {
      state.eventUpdate.errorMessage = payload;
    },
    setEventUpdateError(state, payload) {
      state.eventUpdate.isError = payload;
    },
    setLoadingEventUpdate(state, payload) {
      state.eventUpdate.isLoading = payload;
    },
  },
  actions: {
    async fetchEventsData(context, data) {
      context.commit("setEventsLoading", true);
      context.commit("setEventsError", false);
      try {
        const eventsResponse = await eventService.list(data);
        context.commit("setEvents", eventsResponse.response.data);
      } catch (e) {
        context.commit("setEventsError", true);
        context.commit("setEventsErrorMessage", e.message);
      } finally {
        context.commit("setEventsLoading", false);
      }
    },
    async fetchCategories({ commit }) {
      commit("setEventsLoading", true);
      commit("setEventsError", false);
      try {
        const {
          data: {
            data: { items },
          },
        } = await eventService.getCategories();
        commit("setCategories", items);
        return items;
      } catch (e) {
        commit("setEventsError", true);
        commit("setEventsErrorMessage", e.message);
      } finally {
        commit("setEventsLoading", false);
      }
    },
    async fetchEventData({ commit }, payload = {}) {
      let { eventId } = payload;
      commit("setLoadingEvent", true);
      try {
        const {
          data: { data },
        } = await eventService.getOne(eventId);
        commit("setEvent", data);
        commit("setLocation", data.event_locations);

        return data;
      } catch (error) {
        commit("setEventError", error.message);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
    async fetchSubCategories({ commit }) {
      let catId = [1, 2, 3, 4];
      commit("setLoadingEvent", true);
      try {
        const subCategories = catId.map(async (categoryId) => {
          const { data } = await eventService.getSubCategories(categoryId);
          return data.items;
        });
        const items = await Promise.all(subCategories);
        const allSubCategories = items.flat();

        commit("setSubCategories", allSubCategories);
        return items;
      } catch (error) {
        commit("setEventError", error.message);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
    async fetchLocations({ commit }) {
      commit("setEventsLoading", true);
      commit("setEventsError", false);
      try {
        const {
          data: {
            data: { items },
          },
        } = await eventService.getLocations();
        commit("setLocations", items);
        return items;
      } catch (e) {
        commit("setEventsError", true);
        commit("setEventsErrorMessage", e.message);
      } finally {
        commit("setEventsLoading", false);
      }
    },

    async createEvents({ commit }, payload = {}) {
      commit("setLoadingEvent", true);
      try {
        const {
          data: { data },
        } = await eventService.create(payload);
        commit("setEvent", data);
        return data;
      } catch (error) {
        commit("setEventErrorMessage", true);
        commit("setEventError", error.message);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
    async updateEvents({ commit }, payload = {}) {
      commit("setLoadingEvent", true);
      try {
        const { eventId, eventData } = payload;
        const {
          data: { data },
        } = await eventService.update(eventId, eventData);
        data.entity = await getEntityName(data.entity_id);
        commit("setEvent", data);
        return data;
      } catch (error) {
        commit("setEventUpdateErrorMessage", true);
        commit("setEventUpdateError", error.message);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
    async archiveEvent({ commit }, payload = {}) {
      commit("setLoadingEvent", true);
      try {
        const { eventId } = payload;
        return await eventService.archive(eventId);
      } catch (error) {
        commit("setEventUpdateErrorMessage", true);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
    async createLocation({ commit }, payload = {}) {
      commit("setLoadingEvent", true);
      try {
        const {
          data: { data },
        } = await eventService.createLocation(payload);
        commit("setLocation", data);
        return data;
      } catch (error) {
        commit("setEventErrorMessage", true);
        commit("setEventError", error.message);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
    async updateLocation({ commit }, payload = {}) {
      commit("setLoadingEvent", true);
      try {
        const { locationId, locationData } = payload;
        const {
          data: { data },
        } = await eventService.updateLocation(locationId, locationData);
        return data;
      } catch (error) {
        commit("setEventUpdateErrorMessage", true);
        commit("setEventUpdateError", error.message);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
    async createPrice({ commit }, payload = {}) {
      commit("setLoadingEvent", true);
      try {
        const {
          data: { data },
        } = await productPriceService.createProductPrice(payload);
        commit("setPrice", data);
        return data;
      } catch (error) {
        commit("setEventErrorMessage", true);
        commit("setEventError", error.message);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
    async updatePrice({ commit }, payload = {}) {
      commit("setLoadingEvent", true);
      try {
        const { productPriceId, priceData } = payload;
        const {
          data: { data },
        } = await productPriceService.updateProductPrice(
          productPriceId,
          priceData
        );
        commit("setPrice", data);
        return data;
      } catch (error) {
        commit("setEventUpdateErrorMessage", true);
        commit("setEventUpdateError", error.message);
      } finally {
        commit("setLoadingEvent", false);
      }
      return undefined;
    },
  },
  getters: {
    getLoadingEvent(state) {
      return state.event?.isLoading;
    },
    getRefresh(state) {
      return state.event?.refreshed;
    },
    getEventErrorMessage(state) {
      return state.event?.errorMessage;
    },
    getEventError(state) {
      return state.event?.isError;
    },
    getEvent(state) {
      return state.event.data;
    },
    getOccurrences(state) {
      return state.event.occurrences;
    },
    getCategories(state) {
      return state.events.categories;
    },
    getSubCategories(state) {
      return state.events.subCategories;
    },
    getLocations(state) {
      return state.events.locations;
    },
    getLocation(state) {
      return state.event.locations;
    },
    getInvalidForm(state) {
      return state.event.invalidForm;
    },
    getEventsIsLoading(state) {
      return state.events?.isLoading;
    },
    getEventsIsError(state) {
      return state.events?.isError;
    },
    getEventsData(state) {
      return state.events?.data?.items || [];
    },
  },
};

export default events;
