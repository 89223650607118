<template>
  <section v-if="displaySection">
    <div
      v-if="showAlert"
      class="w-full bg-cream-100 p-4 flex items-center justify-center rounded mb-4 relative"
    >
      <p class="text-sm ml-auto">
        While the original application deadline has already passed, we have
        extended the deadline to provide a little more time to submit the
        remaining materials. We strongly recommend submitting these forms prior
        to this extended deadline, as course and housing options may be filled
        on a first come, first served basis..
      </p>
      <p
        class="text-sm cursor-pointer absolute right-0 top-0 p-2"
        @click="hideAlert()"
      >
        X
      </p>
    </div>
    <FormPanel
      :id="'onboarding'"
      :title="'Onboarding Step 1'"
      :initially-expanded="panelExpanded"
      main-panel-class="bg-white sm:mt-4 sm:rounded-lg sm:shadow-md"
      panel-content-class="grid grid-cols-1 sm:grid-cols-3 sm:gap-2 pb-6 mb-6 border-b border-gray-200 px-4"
      panel-button-text-class="text-lg capitalize xs:tracking-normal"
      main-header-class="text-lg md:text-xl capitalize py-4 px-8 border-none"
      data-testid="onboarding-section"
      @panel-expanded-event="setExpanded"
    >
      <template #panelHeaderCheckbox>
        <FormCounter
          :is-open="panelExpanded"
          :total="onboardingStep1Forms.totalForms"
          :submitted="onboardingStep1Forms.submittedForms"
          :completed="onboardingStep1Forms.completedForms"
        />
      </template>
      <template #content>
        <div class="flex flex-col justify-start items-start px-4">
          <div v-for="(item, index) in firstColumn" :key="index">
            <OnboardingFormLink
              :url="item.slug"
              :form-name="item.label"
              :submitted="item.submitted"
              :show-pending-approval="showPendingApproval"
              :show-extra-space="
                index === approvalIndex &&
                item.label !== approvalName &&
                showPendingApproval
              "
            />
          </div>
        </div>

        <div class="flex flex-col justify-start items-start px-4">
          <div v-for="(item, index) in secondColumn" :key="index">
            <OnboardingFormLink
              :url="item.slug"
              :form-name="item.label"
              :submitted="item.submitted"
              :show-pending-approval="showPendingApproval"
              :show-extra-space="
                index === approvalIndex &&
                item.label !== approvalName &&
                showPendingApproval
              "
            />
          </div>
        </div>
        <div class="flex flex-row justify-start items-start px-4 mt-4 md:mt-0">
          <!-- Deadline column -->
          <DateCountdown
            v-if="showCountDown"
            :application-deadline="deadline"
            :is-large-icon="false"
            :is-forms-completion-count-down="true"
          />
        </div>
      </template>
    </FormPanel>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { FORMS_SUBMISSIONS } from "@/constants.js";
import FormPanel from "../forms/SharedComponents/panel.vue";
import onBoardingSection from "../../mixins/onBoardingSection";
import OnboardingFormLink from "./OnboardingFormLink.vue";
import DateCountdown from "../DateCountdown.vue";
import { endOfDay, format, isValid } from "date-fns";
import FormCounter from "../forms/SharedComponents/FormCounter.vue";
import formService from "@/services/form";

export default {
  name: "OnBoarding",
  components: { FormPanel, OnboardingFormLink, DateCountdown, FormCounter },
  mixins: [onBoardingSection],
  props: {
    showHousingQuestionnaire: {
      type: Boolean,
      default: false,
    },
    formSubmissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deadline: "",
      allFormsComplete: true,
      showAlert: false,
      panelExpanded: true,
      approvalName: "University Approval Request",
    };
  },
  computed: {
    ...mapGetters(["getCurrentStudentApplication"]),
    ...mapState([
      "uiVersion",
      "program",
      "enrollmentApplications",
      "onboardingStep1Forms",
      "currentGpa",
      "isInternship",
      "currentApplicationSubmissions",
    ]),
    displaySection() {
      return (
        (this.uiVersion === "v2" || this.uiVersion === "v3") &&
        this.program.form_rules.length > 0
      );
    },
    showCountDown() {
      let valid = false;
      if (this.isInternship) {
        let status = this.applicationData
          ? this.applicationData.enrollment_status
          : "";
        valid = Boolean(status === "Accepted" && this.acceptedDate);
      } else {
        valid = !!this.getApplicationDate;
      }

      return Boolean(!this.allFormsComplete && valid);
    },
    applicationData() {
      let applications = this.enrollmentApplications.filter((item) => {
        return item.application_id === this.setApplicationId;
      });
      return applications.length ? applications[0] : null;
    },
    getApplicationDate() {
      //get application date
      let application = this.applicationData;
      return application ? application.created_at : "";
    },
    acceptedDate() {
      let application = this.applicationData;
      return application ? application.date_accepted : "";
    },
    getFormsStatus() {
      //get application date
      let application = this.enrollmentApplications.filter((item) => {
        return item.application_id === this.setApplicationId;
      });
      return application.length > 0 && application[0].form_status
        ? JSON.parse(application[0].form_status)
        : "";
    },
    approvalIndex() {
      let firstColumnIndex = this.firstColumn.findIndex((item) => {
        return item.label === this.approvalName;
      });
      let secondColumnIndex = this.secondColumn.findIndex((item) => {
        return item.label === this.approvalName;
      });

      return firstColumnIndex !== -1 ? firstColumnIndex : secondColumnIndex;
    },
    showPendingApproval() {
      let approval = this.onBoardingForms.find(
        (item) => item.label === this.approvalName
      );

      return Boolean(
        !this.getCurrentStudentApplication?.advisorApproval?.formSubmitted &&
          approval?.submitted
      );
    },
  },
  async created() {
    let applicationId = this.setApplicationId;

    //Set approval data
    this.setAppovalData(applicationId);

    //Get the student GPA from enrollment service
    if (!this.studentGpa) {
      await this.setStudentGpa();
    }

    //Load the program data if not set
    if (Object.keys(this.program).length === 0) {
      await this.loadProgramData(applicationId);
    }

    await this.buildFormList();

    // show due date if all forms are not completed
    if (this.showCountDown) {
      //current date
      const currentDate = new Date();

      //application deadline
      const applicationDeadline = endOfDay(
        new Date(this.program.application_deadline)
      );

      const applicationDatePlusFourWeeks = new Date(this.getApplicationDate);
      applicationDatePlusFourWeeks.setDate(
        applicationDatePlusFourWeeks.getDate() + 28
      );

      const applicationDatePlusTwoWeeks = new Date(this.getApplicationDate);
      applicationDatePlusTwoWeeks.setDate(
        applicationDatePlusTwoWeeks.getDate() + 14
      );

      if (this.isInternship) {
        let acceptedDate = endOfDay(new Date(this.acceptedDate));

        //If program deadline is less than when the student is accepted then the counter should be 14 days from the application deadline.
        if (applicationDeadline < acceptedDate) {
          let deadlineDatePlusTwoWeeks = new Date(
            applicationDeadline.getTime()
          );
          deadlineDatePlusTwoWeeks.setDate(
            deadlineDatePlusTwoWeeks.getDate() + 14
          );
          this.deadline = format(deadlineDatePlusTwoWeeks, "yyyy-MM-d");
        } else {
          let acceptedDatePlusTwoWeeks = new Date(acceptedDate.getTime());
          acceptedDatePlusTwoWeeks.setDate(
            acceptedDatePlusTwoWeeks.getDate() + 14
          );
          this.deadline = format(acceptedDatePlusTwoWeeks, "yyyy-MM-d");
        }
      } else {
        //If application deadline is met and all forms are not complete, then show the extended deadline
        if (currentDate > applicationDeadline) {
          if (
            this.program.application_extension_deadline &&
            isValid(new Date(this.program.application_extension_deadline))
          ) {
            this.deadline = this.program.application_extension_deadline;
          } else {
            this.deadline = this.program.application_deadline;
          }
          if (
            currentDate <
            endOfDay(new Date(this.program.application_extension_deadline))
          ) {
            this.showAlert = true;
          }
        } else if (
          // If the student applies more than 4 weeks out of the application deadline then show the application deadline date.
          applicationDatePlusFourWeeks < applicationDeadline
        ) {
          this.deadline = this.program.application_deadline;
        } else if (
          // If student applies less than 2 weeks out the student has until Application deadline to complete onboarding materials.
          applicationDatePlusTwoWeeks > applicationDeadline
        ) {
          this.deadline = this.program.application_deadline;
        } else {
          // If student applies less than 4 weeks out from the application deadline, student has 2 weeks from the application date to complete materials
          this.deadline = format(applicationDatePlusTwoWeeks, "yyyy-MM-d");
        }
      }
    }
  },
  methods: {
    ...mapMutations(["setAdvisorApprovalData"]),
    setExpanded(isExpanded) {
      this.panelExpanded = isExpanded;
    },
    hideAlert() {
      this.showAlert = false;
    },
    async buildFormList() {
      //get the onboarding forms

      let filteredOnboradingForms = this.onBoardingForms;

      //Run the gpa Display logic
      if (!(await this.showGpaStatement())) {
        let noGpaList = filteredOnboradingForms.filter((item) => {
          return item.slug !== "gpa-statement";
        });

        filteredOnboradingForms = noGpaList;
      }

      //Run the letter of recommendation Display logic
      if (!(await this.showLetterOfRecommendation())) {
        let noLetterOfRecommendationList = filteredOnboradingForms.filter(
          (item) => {
            return item.slug !== "study-abroad-letter-of-recommendation";
          }
        );

        filteredOnboradingForms = noLetterOfRecommendationList;
      }

      // Remove housing questionnaire from list
      if (!this.showHousingQuestionnaire) {
        filteredOnboradingForms = filteredOnboradingForms.filter((item) => {
          return item.slug !== FORMS_SUBMISSIONS.housingQuestionnaire.slug;
        });
      }

      // check and set if form has been submitted
      let readyOnboardingForms = [];
      for (const form of filteredOnboradingForms) {
        //check if the form has been submitted to the given formio ID
        const submission = (this.currentApplicationSubmissions[
          form.formioPath
        ] ?? [])[0];

        if (form?.specialValidation && form?.formName) {
          form.submitted = this.submissionValidation(form.formName);
        } else if (form?.specialValidation && submission) {
          form.submitted = this.complexValidation(
            form.ruleName,
            submission,
            form.submittedFormioKey
          );
        } else {
          form.submitted = this.validateKeys(
            form.submittedFormioKey,
            submission?.data,
            form.specialSubmittedLogic,
            form.slug
          );
        }

        this.allFormsComplete = this.allFormsComplete && form.submitted;
        readyOnboardingForms.push(form);
      }

      //divide the forms list into 2 array to show in columns
      this.divideFormList(readyOnboardingForms);

      //set onboarding step 1 state variables

      //get the form statuses from enrollment service
      const keys = Object.keys(this.getFormsStatus);
      //init counters for submitted forms and completed forms
      let submittedFormsCounter = 0;
      let completedCounter = 0;

      //set number of completed forms
      keys.forEach((key, index) => {
        readyOnboardingForms.forEach((element) => {
          const ruleName = element.ruleName
            ? element.ruleName.toLowerCase()
            : "";
          if (
            ruleName === key.toLowerCase() &&
            this.getFormsStatus[key] === "Complete"
          ) {
            completedCounter++;
          }
        });
      });
      //set number of submitted forms
      readyOnboardingForms.forEach((element) => {
        if (element.submitted) {
          submittedFormsCounter++;
        }
      });

      let obj = {
        forms: readyOnboardingForms,
        totalForms: readyOnboardingForms.length,
        submittedForms: submittedFormsCounter,
        completedForms: completedCounter,
      };

      //save on state if sections is completed
      this.$store.commit("setCompletedSections", {
        field: "onboarding1",
        value: !!this.allFormsComplete,
      });
      if (this.allFormsComplete) {
        this.setExpanded(false);
      }
      return this.$store.commit("setOnboardingForms", obj);
    },

    //Sort in the order the form appears on the form rules
    sortList(list) {
      let result = [];
      let unsortable = [];

      this.program.form_rules.forEach(function (key) {
        list.filter(function (item) {
          if (
            item.ruleName === key &&
            !unsortable.includes(item) &&
            !result.includes(item)
          ) {
            result.push(item);
          } else if (
            (!item.ruleName || item.specialDisplayLogic) &&
            !unsortable.includes(item) &&
            !result.includes(item)
          ) {
            unsortable.push(item);
          }
        });
      });
      return result.concat(unsortable);
    },

    async divideFormList(formsUnsorted) {
      //sort based to form rules order
      let forms = this.sortList(formsUnsorted);
      //bail if we dont have forms to show
      if (forms.length === 0) return;

      //find the middle
      const middleIndex = Math.ceil(forms.length / 2);
      //minimum items on first column is 4
      if (middleIndex > 2) {
        this.firstColumn = forms.slice().splice(0, middleIndex);
        this.secondColumn = forms.slice().splice(middleIndex);
      } else {
        this.firstColumn = forms;
      }
    },
    //return the formio form ID that matches the given url
    getFormioFormId(formPath) {
      let id = "";
      for (let i = 0; i < this.formsV1.length; i++) {
        if (this.formsV1[i].url === "/api/forms/" + formPath) {
          id = this.formsV1[i].id;
        }

        if (id) {
          break;
        }
      }
      return id;
    },
    complexValidation(formName, submission, formKeys) {
      // Runs specific validations to determine if form is completed
      let completed = false;
      switch (formName) {
        // Abroad Letter of Rec
        case "Web Form Letter of Recommendation": {
          let requiredLetters = 1; // 1 or 2 letters required, depends if student meets the minimum gpa
          let sendLetters = 0;
          // Check if student requires two letters
          if (
            this.currentGpa &&
            Object.hasOwnProperty.call(this.program, "minimum_gpa") &&
            !isNaN(parseFloat(this.currentGpa)) &&
            !isNaN(parseFloat(this.program.minimum_gpa)) &&
            this.currentGpa < this.program.minimum_gpa
          ) {
            requiredLetters++;
          }
          if (Array.isArray(formKeys)) {
            for (let i = 0; i < formKeys.length; i++) {
              if (
                Object.hasOwnProperty.call(submission, "data") &&
                Object.hasOwnProperty.call(submission.data, formKeys[i]) &&
                submission.data[formKeys[i]] === true
              )
                sendLetters++;
            }
          }
          completed = sendLetters >= requiredLetters;
          break;
        }
        // Internship or any other letter of rec
        case "File Upload Letter of Recommendation": {
          let requiredLetters = 1;
          let sendLetters = 0;
          if (Array.isArray(formKeys)) {
            // Check how many letters were sent
            for (let i = 0; i < formKeys.length; i++) {
              // Has data, has submitted key, submitted key eq true
              if (
                Object.hasOwnProperty.call(submission, "data") &&
                Object.hasOwnProperty.call(submission.data, formKeys[i]) &&
                submission.data[formKeys[i]] === true
              )
                sendLetters++;
            }
          }
          completed = sendLetters >= requiredLetters;
          break;
        }
      }
      return completed;
    },
    validateKeys(keys, data = {}, specialSubmittedLogic, slug) {
      let groupSubmitted = true;

      if (Array.isArray(keys)) {
        keys.forEach((apiKey) => {
          groupSubmitted = Boolean(groupSubmitted && data[apiKey]);
        });
      } else {
        let submitted = Boolean(data[keys] !== undefined && data[keys]);

        if (specialSubmittedLogic && !submitted) {
          const application = Object.assign(
            {},
            this.getCurrentStudentApplication
          );

          if (slug === "university-approval-request" && application) {
            submitted =
              application.advisorApproval?.formSubmitted !== undefined &&
              application.advisorApproval?.formSubmitted;
          }
        }
        groupSubmitted = submitted;
      }

      return groupSubmitted;
    },
    submissionValidation(formName) {
      const submission = this.formSubmissions.find(
        (submission) => submission.form_name === formName
      );
      return Boolean(submission);
    },
    async setAppovalData(applicationId) {
      if (!this.getCurrentStudentApplication?.advisorApproval?.formSubmitted) {
        formService
          .listSubmissions("advisorapproval", {
            "data.application_id": applicationId,
            limit: 1,
          })
          .then((submissions) => {
            if (submissions.length) {
              const submission = submissions[0];
              this.setAdvisorApprovalData({
                field: "formSubmitted",
                value: true,
                applicationId: applicationId,
              });
              this.setAdvisorApprovalData({
                field: "data",
                value: submission?.data ?? {},
                applicationId: applicationId,
              });
            }
          });
      }
    },
  },
};
</script>
