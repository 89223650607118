import { useStore } from "vuex";
import { computed, ref } from "vue";
import orderService from "@/services/orders.js";
import entitiesService from "@/services/entities.js";
import enrollmentService from "@/services/enrollment.js";
import billingAgreement from "./useBillings";
import { sessionStartAndEndDate } from "@/components/program-manager/sessions/utils.js";
import _ from "lodash";
import { MISC_PRODUCTS_FEES } from "@/constants.js";
import { getSessionPricingSummaryInCents } from "@/components/program-manager/sessions/components/CreateEditProgramSessions/utils.js";

export function useOrder() {
  const store = useStore();
  const applicationId = ref(null);
  const opeId = ref(null);
  const contracts = ref([]);
  const session = ref(null);
  const order = ref(null);

  const prepOrderData = async (
    application_id,
    programSession,
    currentOrder
  ) => {
    applicationId.value = application_id;
    session.value = programSession;
    order.value = currentOrder;

    if (_.isEmpty(profileData.value)) {
      // Gets data of profile, pre-populates fields
      try {
        const oktaId = store.state.currentUser?.participantId ?? "";
        await store.dispatch("getProfile", oktaId);
      } catch (error) {
        if (!profileData.value?.okta_id) {
          throw new Error("Missing okta ID in user profile.");
        } else {
          throw new Error(error);
        }
      }
    }

    if (collegeId.value) {
      await prepBillingData();
    }
    return orderData.value;
  };

  const prepBillingData = async () => {
    // Get the entity ope_id to fetch billing agreements
    const entity = await entitiesService.getEntities({ q: collegeId.value });
    if (
      entity?.data?.data?.items?.[0]?.ope_id !== undefined &&
      entity?.data?.data?.items?.[0]?.ope_id !== null
    ) {
      opeId.value = String(entity.data.data.items[0]["ope_id"]).padStart(
        8,
        "0"
      );
      // Fetching billing agreements
      contracts.value = await enrollmentService.loadContracts(opeId.value);
      if (Array.isArray(contracts.value)) {
        // Check if billing agreement can be applied
        const appliableContract = billingAgreement(
          contracts.value,
          session.value
        );
        store.commit("setInstitutionPaysValue", {
          field: "applicationFee",
          value: appliableContract?.applicationFee === "Institution",
        });
        store.commit(
          "setAppliedAgreement",
          appliableContract !== undefined ? appliableContract : {}
        );
      }
      return;
    }
  };

  const isValidNumber = (value) => {
    // Convert the value to a number and check if it's not NaN
    return Number.isNaN(Number(value)) === false;
  };

  const profileData = computed(() => {
    return store.state.profileData;
  });

  const collegeId = computed(() => {
    const firstCollegeId = profileData.value?.colleges?.[0]?.college_id;

    return firstCollegeId != null && firstCollegeId !== "0"
      ? firstCollegeId
      : null;
  });

  const appliedAgreement = computed(() => store.state.appliedAgreement);

  const academicFeeCap = computed(() => {
    if (isValidNumber(appliedAgreement.value?.academic_fee_cap)) {
      // Transform to cents
      return parseFloat(appliedAgreement.value.academic_fee_cap) * 100;
    }
    return null;
  });

  const housingFeeCap = computed(() => {
    if (isValidNumber(appliedAgreement.value?.housing_fee_cap)) {
      // Transform to cents
      return parseFloat(appliedAgreement.value.housing_fee_cap) * 100;
    }
    return null;
  });

  const programFeeCap = computed(() => {
    if (isValidNumber(appliedAgreement.value?.program_fee_cap)) {
      // Transform to cents
      return parseFloat(appliedAgreement.value.program_fee_cap) * 100;
    }
    return null;
  });

  const orderData = computed(() => {
    const {
      room_ids,
      event_occurrence_ids,
      internship_ids,
      misc_product_ids,
    } = order.value;
    return {
      participant_id: profileData.value?.okta_id ?? null,
      application_id: applicationId.value,
      session_id: order.value.session_id,
      credits: order.value?.credits ?? 0,
      host_institution_id:
        session.value?.session_hosting_institutions?.[0]?.entity_id ?? null,
      ope_id: opeId.value,
      home_institution_id: collegeId.value,
      academic_fee_cap_in_cents: academicFeeCap.value,
      housing_fee_cap_in_cents: housingFeeCap.value,
      program_fee_cap_in_cents: programFeeCap.value,
      billing_contract_id: appliedAgreement.value?.contract_number ?? null,
      room_ids,
      event_occurrence_ids,
      internship_ids,
      misc_product_ids,
      program_page_id: order.value.program_page_id,
      status: order.value?.status ?? "Draft",
      application_fee_payment_id:
        order.value?.application_fee_payment_id ?? null,
    };
  });

  return {
    prepOrderData,
  };
}

const findAdditionalFee = (order, product) => {
  return (
    order?.additional_fees?.find((item) => item?.product_id === product)
      ?.total_price_in_cents || 0
  );
};

function extractFeesFromOrder(order, room_id) {
  const classes_fee = findAdditionalFee(
    order,
    MISC_PRODUCTS_FEES.CLASSES_ACADEMIC_FEE
  );

  let insurance_fee = findAdditionalFee(
    order,
    MISC_PRODUCTS_FEES.INSURANCE_FEE
  );

  const spain_insurance_fee = findAdditionalFee(
    order,
    MISC_PRODUCTS_FEES.SPAIN_MEDICAL_INSURANCE_FEE
  );

  const group_flight_fee = findAdditionalFee(
    order,
    MISC_PRODUCTS_FEES.GROUP_FLIGHT_FEE
  );

  const public_transportation_fee = findAdditionalFee(
    order,
    MISC_PRODUCTS_FEES.PUBLIC_TRANSPORTATION_FEE
  );
  let housing_fee = 0;
  if (room_id === undefined) {
    housing_fee = order?.rooms?.[0].total_price_in_cents;
  } else {
    housing_fee = order?.rooms?.find((item) => item?.product_id == room_id)
      ?.total_price_in_cents;
  }

  const events_fee = order?.event_occurrences?.reduce(
    (acc, item) => acc + item?.total_price_in_cents,
    0
  );
  const internship_fee = order?.internships?.reduce(
    (acc, item) => acc + item?.total_price_in_cents,
    0
  );

  insurance_fee += spain_insurance_fee;
  return {
    classes_fee,
    insurance_fee,
    events_fee,
    group_flight_fee,
    public_transportation_fee,
    housing_fee,
    internship_fee,
  };
}

/**
 * Get order pricing with new products if provided
 * @param {programSession} - A session object.
 * @param {order} - An order object.
 * @param {newProduct} - Array<{type: string, value: object}>.
 * @returns Object<{original: object, updated: object|undefined }>.
 */
export const useGetOrderSessionDiff = async ({
  programSession = {},
  order = {},
  newProducts = [],
}) => {
  try {
    const newRoomId = newProducts?.find((item) => item?.type === "room_id")
      ?.value;
    const { startDate } = sessionStartAndEndDate(programSession);
    let updatedOrder = undefined;
    let bodyRequest = {
      order_id: order?.id,
      session_id: order?.session_id,
      home_institution_id: order?.home_institution_id,
      pricing_date: startDate,
      duration: programSession?.duration || 0,
      room_ids: [],
      internship_ids: [],
      event_occurrence_ids: order?.event_occurrence_ids,
      misc_product_ids: order?.misc_product_ids,
    };

    // Normalize fees
    const sessionFees = getSessionPricingSummaryInCents(programSession);
    const sessionBasePrices = {
      session_base_educational_fee: sessionFees?.educationalFee,
      session_base_internships_price: sessionFees?.internshipFee,
      session_base_housing_fee: sessionFees?.housingFee,
      session_base_events_fee: sessionFees?.excursionFee,
      session_base_insurance_fee: sessionFees?.insuranceFee,
      session_base_group_flight_fee: sessionFees?.groupFlightFee,
      session_base_transportation_fee: sessionFees?.publicTransportationFee,
      session_base_class_academic_fee: sessionFees?.classesFee,
      total_price_in_cents: sessionFees?.totalBasePrice,
    };

    // If new product is provided replace the old with that in order to have a price difference.
    if (newProducts.length > 0) {
      bodyRequest.room_ids = [];
      newProducts?.forEach((product) => {
        if (product?.type === "room_id") {
          bodyRequest.room_ids = [product?.value];
        } else if (product?.type === "internship_ids") {
          if (product?.value?.[0]) {
            // Since we can have multiple internships we need to handle it differently
            bodyRequest.internship_ids = product?.value;
          } else {
            bodyRequest.internship_ids = [product?.value];
          }
        }
      });
    }
    updatedOrder = await orderService.getOrdersPricing({
      payload: bodyRequest,
      skipCache: true,
    });
    const updatedOrderData = updatedOrder?.data?.data;
    const {
      classes_fee,
      insurance_fee,
      events_fee,
      group_flight_fee,
      public_transportation_fee,
      housing_fee,
      internship_fee,
    } = extractFeesFromOrder(updatedOrderData, newRoomId);

    // Pricing endpoint returns the total price of the order taking into consideration the old room because it wasn't updated yet.
    // so we need to deduct the old product price from the total price so that we have information of how will the new price look like.
    if (updatedOrderData?.rooms?.length > 1) {
      const oldProduct = updatedOrderData?.rooms?.find(
        (item) => item?.product_id !== newRoomId
      );
      updatedOrderData.total_price_in_cents -=
        oldProduct?.total_price_in_cents ?? 0;
    }
    return {
      original: sessionBasePrices,
      updated: {
        ...updatedOrder?.data?.data,
        session_base_class_academic_fee: classes_fee,
        session_base_events_fee: events_fee,
        session_base_insurance_fee: insurance_fee,
        session_base_group_flight_fee: group_flight_fee,
        session_base_transportation_fee: public_transportation_fee,
        session_base_housing_fee: housing_fee,
        session_base_internships_price: internship_fee,
      },
    };
  } catch (e) {
    return {
      original: undefined,
      updated: undefined,
    };
  }
};
