<script setup>
import { inject, ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { V3_stepCanBeReturned } from "@/composables/VersionHelper.js";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import v3FormTracker from "@/services/V3FormTracker.js";
import { usePastDeadline } from "@/composables/usePastDeadline.js";

const updateStepAndRedirect = inject("updateStepAndRedirect");

const {
  disabledContinueButton,
  isPastDeadline,
  validateDeadline,
} = usePastDeadline();

const groupVisaSubmit = ref(null);
const groupVisaSubmit2 = ref(null);
const submissionError = ref("");
const store = useStore();
const router = useRouter();

// Computed
const applicationId = computed(() => store.state.currentApplicationId);

// Methods
const submitGroupVisa = async function (accepted = false) {
  try {
    // We'll block both buttons, we're out of control someone stop us
    groupVisaSubmit.value.startLoading();
    groupVisaSubmit2.value.startLoading();

    validateDeadline();

    if (isPastDeadline.value) return;

    const response = await v3FormTracker.setAsCompleted(
      "GroupVisa",
      applicationId.value,
      { accepted_group_visa: accepted }
    );

    // All ok, redirect
    if (response?.id !== undefined) {
      store.commit("setStepCompletedV3", "GroupVisa");
      store.commit("setStepDataV3", { stepName: "GroupVisa", data: response });
      updateStepAndRedirect("application-review", applicationId.value);
    }
  } catch (e) {
    submissionError.value = e;
  } finally {
    groupVisaSubmit.value.stopLoading();
    groupVisaSubmit2.value.stopLoading();
  }
};

async function canViewForm() {
  // View form if it's next step and hasn't paid 150$/completed checkout
  let canViewForm = await V3_stepCanBeReturned("GroupVisa");
  if (!canViewForm) {
    // Go to About You
    router.push({
      name: "application-about-you",
      params: { applicationId: applicationId.value },
    });
  }
}

onBeforeMount(async () => {
  await canViewForm();
});
</script>
<template>
  <div
    class="font-montserrat text-indigo-base text-left bg-white w-full border-collapse md:mt-0 p-14 max-w-4xl"
  >
    <div class="grid grid-cols-1 gap-y-7 pt-4">
      <div>
        <p class="text-xl text-center">
          Based on your address and program it looks like you are eligible for
          API’s Group Visa Process. This makes getting your Visa much easier and
          less stressful for you since we appear at the consulate on your
          behalf. In addition to the API Group Visa fee, you will be responsible
          for paying the consulate fee, you can find those fees
          <a
            id="studentVisasLink"
            href="https://apiabroad.com/student-visas/"
            target="_blank"
            class="api-link"
            >here</a
          >.<br />
          <br />
          Would you like to add a Group Visa to your experience for $180?
        </p>
      </div>
    </div>
    <div class="mt-24px">
      <ButtonWithSpinner
        id="groupVisaButton"
        ref="groupVisaSubmit"
        data-cy="groupVisaButton"
        type="submit"
        variant="secondary"
        variant-type="block"
        button-height="min-h-[58px] md:min-h-[60px] mt-4"
        :grey-disabled-class="true"
        :disabled="disabledContinueButton"
        @click="submitGroupVisa(true)"
      >
        <div class="flex items-center justify-center">
          <span class="pr-3 uppercase">
            YES, ADD GROUP VISA
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
    </div>
    <div class="mt-24px flex justify-center">
      <ButtonWithSpinner
        id="groupVisaButton2"
        ref="groupVisaSubmit2"
        data-cy="groupVisaButton2"
        type="submit"
        variant="secondary-outlined"
        variant-type="normal"
        button-height="min-h-[58px] md:min-h-[60px] mt-4 w-full"
        :grey-disabled-class="true"
        :disabled="disabledContinueButton"
        @click="submitGroupVisa(false)"
      >
        <div class="flex items-center justify-center">
          <span class="pr-3 uppercase">
            No thanks
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
    </div>
  </div>
</template>
