import service from "@/services/learnerTypes";

const learnerTypes = {
  namespaced: true,
  state: {
    learnerTypes: [],
  },
  mutations: {
    setLearnerTypes(state, payload = []) {
      state.learnerTypes = payload;
    },
  },
  getters: {
    getLearnerTypes(state) {
      return state?.learnerTypes ?? [];
    },
  },
  actions: {
    async initialize({ commit, state }, payload = []) {
      if (state.learnerTypes.length > 1) {
        // highly likely the learner types were already loaded via blade template injection
        return;
      }

      if (Array.isArray(payload) && payload.length > 0) {
        commit("setLearnerTypes", payload);
        return;
      }

      return service
        .list()
        .then((data) => {
          commit("setLearnerTypes", data?.items ?? []);
        })
        .catch(() => {
          commit("setLearnerTypes", []);
        });
    },
  },
};

export default learnerTypes;
