import _, { cloneDeep } from "lodash";
import { computed, reactive, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import profileService from "@/services/profile.js";

export function useHousingQuestionnaire() {
  const v$ = useVuelidate();

  const FORM_VERSION = 2;
  const QUESTIONS = [
    {
      question: "I would describe myself as… (select all that apply)",
      type: "checkbox",
      model: "describeMyself",
      options: [
        {
          model: "stayUpLate",
          label: "I like to stay up late and sleep in.",
        },
        {
          model: "getUpEarly",
          label: "I like to go to bed and get up early.",
        },
        {
          model: "spacesClean",
          label: "I love keeping my spaces clean and organized.",
        },
        {
          model: "messy",
          label: "I keep things messy and messy spaces do not bother me.",
        },
        {
          model: "chill",
          label: "Generally speaking, I like to stay in and chill.",
        },
        {
          model: "goOut",
          label: "I like to go out a couple of nights a week.",
        },
        {
          model: "loveNightLife",
          label: "I love the night life!",
        },
      ],
      show: true,
    },
    {
      title: "Accomodation",
      type: "checkbox",
      options: [
        {
          model: "medicalCondition",
          label:
            "I have a medical condition that necessitates certain housing requirements.",
        },
      ],
      fieldsBelowLabel: true,
      show: true,
    },
    {
      question:
        "Check the housing requirements needed in order to support your medical condition. Use the other field if the housing requirement is not listed.",
      type: "checkbox",
      model: "housingRequirements",
      secondaryText:
        "Please be aware that specific medically necessary housing accommodations may not be available in all destinations. In cases where such requests can be accommodated, they might incur extra costs.",
      options: [
        {
          model: "privateRoom",
          label: "Private Room",
        },
        {
          model: "airConditioning",
          label: "Air Conditioning",
        },
        {
          model: "elevator",
          label: "Elevator",
        },
        {
          model: "wheelchairAccessible",
          label: "Wheelchair accessible",
        },
        {
          model: "otherMedicalCondition",
          other: "otherMedicalConditionText",
          label: "Other",
        },
      ],
      show: false,
    },
    {
      title: "Gender",
      question:
        "Which housing configuration(s) do you feel comfortable living in? (select all that apply)",
      type: "checkbox",
      model: "comfortableLivingIn",
      options: [
        {
          model: "singleGenderRoomAndUnit",
          label: "Single-Gender: One gender per room and unit.",
        },
        {
          model: "singleGenderRoomInMixedGenderUnit",
          label: "Mixed Gender: Single-gender rooms, shared facilities",
        },
        {
          model: "genderInclusiveRoomAndUnit",
          label: "Gender-Inclusive: Open to all gender identities.",
        },
      ],
      show: true,
    },
    {
      title: "Smoking",
      secondaryText: "Please note all API housing is non-smoking.",
      model: "smoking",
      type: "radio",
      options: ["I do not smoke or vape", "I smoke or vape"],
      fieldsBelowLabel: true,
      show: true,
    },
    {
      title: "Dietary Restrictions",
      question:
        "Local Hosts can accommodate most dietary restrictions. Please select what, if any, restrictions you have.",
      boldText: "Special diets will incur an additional fee.",
      type: "checkbox",
      model: "dietaryRestrictions",
      options: [
        {
          model: "noneDietaryRestrictions",
          label: "None",
        },
        {
          model: "vegetarian",
          label: "Vegetarian",
        },
        {
          model: "vegan",
          label: "Vegan",
        },
        {
          model: "kosher",
          label: "Kosher",
        },
        {
          model: "halal",
          label: "Halal",
        },
        {
          model: "glutenFree",
          label: "Gluten-free",
        },
        {
          model: "otherDietaryRestriction",
          other: "otherDietaryRestrictionText",
          label: "Other",
        },
      ],
      show: false,
      isLocalHost: true,
    },
    {
      question: "Are you comfortable living with a Local Host that has pets?",
      model: "livingWithPets",
      type: "radio",
      options: ["Yes", "No"],
      show: false,
      isLocalHost: true,
    },
    {
      question:
        "Is there anything else you want us to know when creating your Local Host match? This can be anything from special interests, extracurriculars, etc.",
      type: "textarea",
      model: "localHostMatch",
      show: false,
      isLocalHost: true,
    },
    {
      question:
        "Your comfort and happiness are important to us. Is there anything else you want us to know about you or keep in mind when fulfilling your housing request?",
      type: "textarea",
      model: "additionalHousingRequest",
      show: true,
    },
  ];

  const unit = ref({});
  const questionnaire = reactive({
    roommateRequests: [],
    housemateRequests: [],
    questions: [],
    allAcknowledged: {},
  });
  const previousQuestionnaire = reactive({
    roommateRequests: [],
    housemateRequests: [],
    questions: [],
    allAcknowledged: {},
  });

  const formattedSubmission = computed(() => {
    return {
      allAcknowledged: questionnaire.allAcknowledged,
      version: FORM_VERSION,
      requested_roommates: questionnaire.roommateRequests,
      requested_housemates: questionnaire.housemateRequests,
      question_responses: [
        ...questionnaire.questions,
        questionnaire.allAcknowledged,
      ],
    };
  });
  const isLocalHost = computed(
    () => unit.value?.housings?.type === "Local Host"
  );

  watch(
    () => questionnaire.questions,
    (questions) => {
      const medicalConditionResponse = questions.find(
        (q) => q.model === "medicalCondition"
      );

      const housingRequirementsQuestion = QUESTIONS.find(
        (q) => q.model === "housingRequirements"
      );

      if (medicalConditionResponse?.answer) {
        if (housingRequirementsQuestion) {
          housingRequirementsQuestion.show = true;
        }
        if (v$.housingRequirements) {
          v$.housingRequirements.$reset();
        }
      } else {
        if (housingRequirementsQuestion) {
          housingRequirementsQuestion.show = false;
        }
      }
    },
    { deep: true }
  );

  const setUnit = (sessionUnits, roomIds) => {
    let sessionUnit = sessionUnits.find((unit) =>
      unit?.unit?.rooms?.some((room) => room.id === roomIds[0])
    );

    unit.value = sessionUnit?.unit ?? {};
  };
  const setAcknowledgement = (value) => {
    questionnaire.allAcknowledged = value;
  };
  const setQuestionnaireData = ({ propName, value }) => {
    questionnaire[propName] = [...value];
  };

  const createOrUpdatedHousingQuestionnaire = async (
    oktaId,
    orderId,
    applicationId,
    questionnaireData,
    questionnaireId = null
  ) => {
    let response = null;
    if (!_.isEmpty(questionnaireData) && orderId && oktaId) {
      const version = questionnaireData.version;
      let submissionData = cloneDeep(questionnaireData);
      delete submissionData.version;

      let payload = {
        okta_id: oktaId,
        application_id: applicationId,
        order_id: orderId,
        form_name: "Housing Questionnaire",
        form_version: version,
        submission_data: submissionData,
      };

      if (questionnaireId) {
        payload.id = questionnaireId;
        response = await profileService.updateSubmission(
          questionnaireId,
          payload
        );
      } else {
        response = await profileService.createSubmission(payload);
      }
    }
    return response;
  };
  const getHousingQuestionnaire = async (orderId) => {
    const extraParams = { form_name: "Housing Questionnaire" };
    const submissionsData = await profileService.getSubmissionsByOrder(
      orderId,
      extraParams
    );
    return submissionsData?.data?.items?.length
      ? submissionsData.data.items[0]
      : null;
  };

  return {
    v$,
    QUESTIONS,
    questionnaire,
    previousQuestionnaire,
    unit,
    formattedSubmission,
    isLocalHost,
    setUnit,
    setAcknowledgement,
    setQuestionnaireData,
    createOrUpdatedHousingQuestionnaire,
    getHousingQuestionnaire,
  };
}
