<template>
  <div
    id="accepted-tab"
    tabindex="0"
    role="tabpanel"
    aria-labelledby="accepted"
    class="focus:outline-none"
  >
    <Teleport v-if="parentMounted" to="#csvBtn">
      <a
        :class="`${secondaryColorClass}`"
        class="items-center hidden md:flex font-semibold"
        href="#"
        @click="csvExport()"
      >
        <span class="flex-none">
          <i class="mdi mdi-download" />
        </span>
        <span class="ml-2">CSV</span>
      </a>
    </Teleport>
    <modal
      v-if="showModal"
      :id="'breakdown'"
      :title="'Total Program Price Breakdown'"
      @closemodal="resetStudent"
    >
      <template #modal-content>
        <PriceBreakDown @close="resetStudent" />
      </template>
    </modal>
    <Teleport v-if="parentMounted" to="#searchbox">
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between sr-only">
          <span>Search</span>
        </span>
        <input
          v-model="tableData.search"
          class="form-input min-h-10 block w-full"
          type="text"
          placeholder="Search"
          @input="getStudents(searchUrl)"
        />
      </label>
    </Teleport>
    <NoRecords v-if="!loading && students.length == 0" />
    <DataTables
      v-else
      :columns="columns"
      :sort-key="sortKey"
      :sort-orders="sortOrders"
      role="region"
      aria-label="Alumni Results"
      @sort="sortBy"
    >
      <tr v-if="loading">
        <td class="card-data">
          <div class="w-full md:w-columnfixed-md2">Loading Please Wait..</div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm" />
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-md" />
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm" />
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm" />
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-ctrl" />
        </td>
      </tr>
      <p v-else-if="students.length == 0" class="text-red-600">
        No records found!
      </p>
      <tbody v-else>
        <tr
          v-for="(student, index) in students"
          :key="student.id + 'i' + index"
          class="card-row"
        >
          <td class="card-section">
            <div class="card-label">
              Student Name and Email
            </div>
            <div class="w-full md:w-columnfixed-md2">
              <span
                class="block text-sm font-semibold text-gray-700 student-link cursor-pointer"
                @click="
                  setTabDataAndRedirect(
                    student.session_id,
                    student.approval_application,
                    student.student_id,
                    'alumni'
                  )
                "
              >
                {{ student.name }}
              </span>
              <span class="break-all">
                <a
                  :class="[
                    secondaryColor
                      ? `${secondaryColorClass} focus:underline`
                      : `${secondaryColorClass} focus:underline hover:text-teal-900 focus:text-teal-900`,
                  ]"
                  :href="'mailto:' + student.email"
                  >{{ student.email }}</a
                >
              </span>
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">
              Major
            </div>
            <div
              class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-sm break-words"
            >
              {{ student.major || "-" }}
            </div>
          </td>

          <td class="card-section">
            <div class="card-label">
              Program Name
            </div>
            <div class="w-full md:w-columnfixed-md break-words">
              <a
                v-if="student.url"
                :href="student.url"
                target="_blank"
                :class="[
                  secondaryColor
                    ? `${secondaryColorClass} focus:underline`
                    : `${secondaryColorClass} focus:underline hover:text-teal-900 focus:text-teal-900`,
                ]"
              >
                {{ student.program_name }}
              </a>
              <span v-else class="block">{{ student.program_name }}</span>
              <span class="block">{{ student.site }}</span>
            </div>
          </td>

          <td class="card-data">
            <div class="card-label">
              Program Dates
            </div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-sm">
              <ProgramDates
                :id="student.id + 'r' + index"
                :dates="student.program_dates"
                :start-is-final="student.start_date_final > 0"
                :end-is-final="student.end_date_final > 0"
              />
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">
              Program Location
            </div>
            <div
              class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-sm break-words"
            >
              {{ student.site }}
            </div>
          </td>
          <td class="card-controls">
            <DropdownComponent :row="index + 1" :tab-name="'accepted'">
              <template #dropdown-options="slotProps">
                <li
                  @click="
                    setTabDataAndRedirect(
                      student.session_id,
                      student.approval_application,
                      student.student_id,
                      'alumni'
                    )
                  "
                >
                  <span class="dropdown-item cursor-pointer">View Detail</span>
                </li>
                <li class="border-t.border-gray-200">
                  <a class="dropdown-item" :href="'mailto:' + student.email"
                    >Email Student</a
                  >
                </li>
                <li class="border-t border-gray-200">
                  <a
                    class="dropdown-item"
                    :href="`mailto:api-urs${slotProps.companyEmail}`"
                  >
                    Email API</a
                  >
                </li>
              </template>
            </DropdownComponent>
          </td>
        </tr>
      </tbody>
    </DataTables>
    <Pagination
      :pagination="pagination"
      @prev="getStudents(pagination.prevPageUrl)"
      @next="getStudents(pagination.nextPageUrl)"
    />
  </div>
</template>

<script>
import DataTables from "@/university/components/dataTables/DataTables.vue";
import Pagination from "@/university/components/dataTables/Pagination.vue";
import ProgramDates from "@/university/components/dataTables/programDates.vue";
import NoRecords from "@/university/components/dataTables/NoRecords.vue";
import { APICompany } from "../../../../constants";
import { tabsMixin } from "@/mixins/tabsMixins.js";
import DropdownComponent from "@/university/components/SiteComponents/my-students/misc/dropdown.vue";
import { mapGetters } from "vuex";
import PriceBreakDown from "@/university/components/SiteComponents/priceBreakDown";
import modal from "@/university/components/modal";

export default {
  components: {
    DataTables,
    Pagination,
    ProgramDates,
    NoRecords,
    DropdownComponent,
    PriceBreakDown,
    modal,
  },
  mixins: [tabsMixin],
  props: {
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let sortOrders = {};
    //columns headers for this table, mus match a row from columnsWhiteList->row->name in tabMixin.js
    let tabColumns = [
      "nameEmail",
      "major",
      "programName",
      "programDates",
      "programLocation",
    ];
    tabColumns.forEach((column) => {
      sortOrders[column] = 0;
    });
    return {
      searchUrl:
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "students",
      id: "",
      loading: true,
      students: [],
      tabColumns: tabColumns,
      sortKey: "col1",
      sortOrders: sortOrders,
      perPage: ["10", "20", "30"],
      tableData: {
        draw: 0,
        length: 25,
        search: "",
        column: 0,
        dir: "asc",
        status: ["Alumni"],
        year: new Date().getFullYear(),
        current_year: new Date().getFullYear(),
        filters: {
          city: "",
          sessions: [],
          majors: [],
          approvalStatus: "",
        },
      },
      APICompany,
    };
  },
  computed: {
    ...mapGetters("university", ["getCurrentTabStatus"]),
  },
  created() {
    this.getStudents(this.searchUrl, true);
  },
  methods: {
    setYear(event) {
      this.tableData.year = event.target.value;
      this.getStudents(this.searchUrl);
    },
    prepDataForCsvExport(csvData) {
      return csvData.map((item) => ({
        Name: item.name,
        Email: item.email,
        Major: item.major,
        Program_Name: item.program_name,
        Program_Location: item.site,
        Program_Start_Date: item.start_date,
        Program_End_Date: item.end_date,
      }));
    },
  },
};
</script>
