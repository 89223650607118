import { differenceInMinutes } from "date-fns";
import { isEmpty } from "lodash";
import { getDecodedData } from "@/mixins/helpers.js";

const experience = {
  namespaced: true,
  state: {
    optionsUpdateTimestamp: "",
    programTypesOptions: [],
    programLengthOptions: [],
    coursesOptions: [],
    languagesOptions: [],
    timeframeOptions: [],
    programs: [],
    isLoading: false,
    isLoadingFilters: false,
  },
  mutations: {
    SET_LOADING_STATE(state, status) {
      state.isLoading = !!status;
    },
    SET_LOADING_FILTERS_STATE(state, status) {
      state.isLoadingFilters = !!status;
    },
    SET_PROGRAMTYPE_OPTIONS(state, data) {
      state.programTypesOptions = data;
    },
    SET_PROGRAMLENGTH_OPTIONS(state, data) {
      state.programLengthOptions = data;
    },
    SET_COURSES_OPTIONS(state, data) {
      state.coursesOptions = data;
    },
    SET_LANGUAGES_OPTIONS(state, data) {
      state.languagesOptions = data;
    },
    SET_SEARCH_PROGRAMS(state, data) {
      state.programs = data;
    },
    SET_LAST_UPDATE_TIMESTAMP(state) {
      state.optionsUpdateTimestamp = Date.now();
    },
    CLEAR_SEARCH_PROGRAMS(state) {
      state.programs = [];
    },
    CONCAT_SEARCH_PROGRAMS(state, data) {
      if (!Array.isArray(state.programs.items)) {
        state.programs.items = [];
      }
      if (Array.isArray(data.items) && data.items.length) {
        state.programs.items = state.programs.items.concat(data.items);
        state.programs.start = data.start;
        state.programs.end = data.end;
        state.programs.limit = data.limit;
        state.programs.count = data.count;
      }
    },
    SET_TIMEFRAMES_OPTIONS(state, data) {
      // Format the timeframes to show options for the current year and next
      const timeframes = [];
      for (let index = 0; index < data.length; index++) {
        timeframes.push({
          label: `${data[index]}`,
          value: `${data[index]}`,
        });
      }

      state.timeframeOptions = sortLabels(timeframes);
    },
  },
  actions: {
    toggleLoading({ commit }, status) {
      commit("SET_LOADING_STATE", status);
    },
    getInitialFiltersDataSource({ commit, state }, payload = {}) {
      commit("SET_LOADING_FILTERS_STATE", true);
      if (!requiresUpdate(state)) {
        commit("SET_LOADING_FILTERS_STATE", false);
        return;
      }
      return window.axios
        .get("/api/search-filter/getSources", {
          params: {
            in_use: payload?.in_use,
          },
        })
        .then((data) => {
          commit("SET_PROGRAMTYPE_OPTIONS", data.data.programTypes.original);
          commit("SET_PROGRAMLENGTH_OPTIONS", data.data.programLength.original);
          commit("SET_COURSES_OPTIONS", data.data.courses.original);
          commit("SET_LANGUAGES_OPTIONS", data.data.languages.original);
          commit("SET_TIMEFRAMES_OPTIONS", data.data.timeframes.original);
        })
        .finally(() => {
          commit("SET_LAST_UPDATE_TIMESTAMP");
          commit("SET_LOADING_FILTERS_STATE", false);
        });
    },
    //make the search request to the learning service
    async search({ commit, rootState }, data) {
      commit("SET_LOADING_STATE", true);
      //prep data for post request
      let post = {};
      const stringElements = ["limit", "skip", "keyword", "classTitle"];

      if (data?.filter_type) {
        post["filter_type"] = data.filter_type;
      }

      for (const element in data) {
        if (element === "session_id" && data[element]?.[0]?.value) {
          // Filter by exactly 1 session id
          post[element] = data[element][0].value;
        } else if (stringElements.includes(element)) {
          post[element] = data[element];
        } else if (!isEmpty(data[element]) && element !== "filter_type") {
          for (let index = 0; index < data[element].length; index++) {
            let value = getDecodedData(data[element][index].value);
            if (element in post) {
              post[element].push(value);
            } else {
              post[element] = [value];
            }
          }
        }
      }

      const isInitialLoad = !post.skip || post.skip === 0;
      if (isInitialLoad) commit("CLEAR_SEARCH_PROGRAMS");

      post["homeInstitutionId"] =
        rootState.profileData?.colleges?.[0]?.college_id ?? "";

      //filter academic year is semester is selected
      const shouldFilter = shouldFilterOutAcademicYear(data);

      await window.axios
        .post("/api/search-filter/search", post)
        .then((data) => {
          const filtered = shouldFilter
            ? removeAcademicYear(data.data.data)
            : data.data.data;
          commit(
            isInitialLoad ? "SET_SEARCH_PROGRAMS" : "CONCAT_SEARCH_PROGRAMS",
            filtered
          );
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    },
  },
  getters: {
    getPrograms(state) {
      return state?.programs || {};
    },
    getProgramTypeOptions(state) {
      return state.programTypesOptions;
    },
    getProgramLengthOptions(state) {
      return state.programLengthOptions;
    },
    getTimeframeOptions(state) {
      return state.timeframeOptions;
    },
    getCourseLevelOptions(state) {
      return state.coursesOptions;
    },
    getLoadingFilters(state) {
      return state.isLoadingFilters;
    },
  },
};
const sortLabels = (arr) => {
  const sortOrder = {
    Spring: 0,
    Summer: 1,
    Fall: 2,
    Virtual: 4,
  };

  arr.sort((a, b) => {
    const [aSeason, aYear] = a.label.split(" ");
    const [bSeason, bYear] = b.label.split(" ");

    if (aSeason === "Virtual" && bSeason !== "Virtual") {
      return 1; // "Virtual" comes after other seasons
    } else if (aSeason !== "Virtual" && bSeason === "Virtual") {
      return -1; // Other seasons come before "Virtual"
    }

    if (aYear !== bYear) {
      return aYear - bYear;
    }

    return sortOrder[aSeason] - sortOrder[bSeason];
  });

  return arr;
};

const requiresUpdate = (state) => {
  if (
    state.programTypesOptions.length > 0 &&
    state.coursesOptions.length > 0 &&
    state.languagesOptions.length > 0 &&
    state.timeframeOptions.length > 0 &&
    !timeExpired(state.optionsUpdateTimestamp)
  ) {
    return false;
  }
  return true;
};
/**
 * Return true if the data source for the options is more than the given minutes as margin
 * @returns
 * @param stateTimestamp
 */
const timeExpired = (stateTimestamp) => {
  const minutesMargin = 30;
  const secondTimestamp = Date.now();
  const timeDifferenceInMinutes = differenceInMinutes(
    secondTimestamp,
    stateTimestamp
  );
  return timeDifferenceInMinutes >= minutesMargin;
};

/**
 * Ignore academic year when semester is selected in the program lenght filter
 */
const removeAcademicYear = (data) => {
  data.items = data.items.filter((item) => {
    const terms = item.session?.terms;

    if (terms.length > 1) {
      const termNames = terms.map((termObj) => termObj.name.toLowerCase());
      if (termNames.includes("spring") && termNames.includes("fall")) {
        data.count--;
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  });
  return data;
};

/**
 * Boolean to determine if the academic year should be filtered
 */
const shouldFilterOutAcademicYear = (data) => {
  if (data.programLength?.length > 0) {
    const programLengthSelected = data.programLength.map((option) =>
      option.label.toLowerCase()
    );
    if (
      programLengthSelected.includes("semester") &&
      !programLengthSelected.includes("academic year")
    ) {
      return true;
    }
  }
  return false;
};
export default experience;
