<script>
export default {
  name: "ViewFulfillmentReports",
};
</script>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { TableauViz } from "@tableau/embedding-api";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import BreadCrumb from "@/components/shared/BreadCrumb";
import ReportContainer from "@/components/shared/ReportContainer";
import {
  ordersReportSource,
  ordersMiscellaneousProductsReportSource,
  ordersActualHousingBookingsReportSource,
  ordersHousingInternationalReportSource,
  ordersExcursionsInternationalReportSource,
} from "@/composables/tableau";
import {
  PERMISSIONS,
  TABLEAU_VIZ_FRAME_HEIGHT,
  TABLEAU_VIZ_FRAME_WIDTH,
} from "@/constants";
import {
  hasOrdersReadPermission,
  hasOrdersWritePermission,
} from "@/composables/authorization";
</script>

<template>
  <div class="bg-blue-100 text-sm text-indigo-base">
    <div class="mx-5">
      <div class="flex flex-col items-baseline justify-between">
        <BreadCrumb class="mt-10" />
        <TabView :activeIndex="0" :lazy="true" class="w-fit">
          <TabPanel header="Orders Report" v-if="hasOrdersReadPermission">
            <ReportContainer :src="ordersReportSource" :width="1200" />
          </TabPanel>
          <TabPanel
            header="Miscellaneous Products Report"
            v-if="hasOrdersWritePermission"
          >
            <ReportContainer
              :src="ordersMiscellaneousProductsReportSource"
              :width="1200"
            />
          </TabPanel>
          <TabPanel
            header="Actual Housing & Bookings Report"
            v-if="hasOrdersWritePermission"
          >
            <ReportContainer
              :src="ordersActualHousingBookingsReportSource"
              :width="1200"
            />
          </TabPanel>
          <TabPanel
            header="Housing International Report"
            v-if="hasOrdersWritePermission"
          >
            <ReportContainer
              :src="ordersHousingInternationalReportSource"
              :width="1200"
            />
          </TabPanel>
          <TabPanel
            header="Excursions International Report"
            v-if="hasOrdersWritePermission"
          >
            <ReportContainer
              :src="ordersExcursionsInternationalReportSource"
              :width="1200"
            />
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<style scoped>
li[data-pc-section="inkbar"] {
  display: block;
  position: absolute;
  bottom: 0;
}
</style>
