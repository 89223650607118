<template>
  <div>
    <div class="text-gray-600">
      <div>
        <label for="subdomain" class="block text-sm font-medium text-gray-700"
          ><span class="text-error-900 align-top">*</span> Subdomain URL</label
        >
        <div class="relative mt-1 rounded-md shadow-sm">
          <!-- disabled disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-600-->
          <input
            id="subdomain"
            v-model="brandingUrl"
            type="text"
            name="subdomain"
            class="form-input block w-full min-h-10"
            :placeholder="brandingUrl"
            :disabled="disabled"
            @input="getBrandingUrl"
          />
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <svg
              v-if="disabled"
              class="h-5 w-5 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 16c0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723v2.277h-2v-2.277c-.596-.347-1-.985-1-1.723zm11-6v14h-18v-14h3v-4c0-3.313 2.687-6 6-6s6 2.687 6 6v4h3zm-13 0h8v-4c0-2.206-1.795-4-4-4s-4 1.794-4 4v4zm11 2h-14v10h14v-10z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandingURL",
  data() {
    return {
      brandingUrl: '',
      disabled: false,
    };
  },
  props: {
    defaultUrl: {type: String},
  },
  watch: {
    defaultUrl (newValue, oldValue) {
      this.brandingUrl = newValue;
    },
  },
  emits: ["branding-url"],
  methods: {
    getBrandingUrl() {
      this.$emit("branding-url", this.brandingUrl);
    },
  }
};
</script>
