export const READ_ONLY_FILTERS = {
  allowed_program_types: true,
  arrival_date: true,
  departure_date: true,
};

export const columnsConfig = [
  {
    id: "id",
    header: "",
    size: 50,
    type: "radio",
    getColumnDisplayValue: () => "",
  },
  {
    id: "housing_type",
    header: "Housing Type",
    size: 50,
    getColumnDisplayValue: ({ housing_type }) => housing_type ?? "",
  },
  {
    id: "housing_tier",
    header: "Housing Tier",
    size: 50,
    getColumnDisplayValue: ({ housing_tier }) => housing_tier ?? "",
  },
  {
    id: "city",
    header: "City",
    size: 50,
    getColumnDisplayValue: ({ city }) => city ?? "",
  },
  {
    id: "country",
    header: "Country",
    size: 50,
    getColumnDisplayValue: ({ country }) => country ?? "",
  },
  {
    id: "housing_name",
    header: "Housing Name",
    size: 50,
    getColumnDisplayValue: ({ housing_name }) => housing_name ?? "",
  },
  {
    id: "tier",
    header: "Housing Subcategory",
    size: 50,
    getColumnDisplayValue: ({ housing_subcategory }) =>
      housing_subcategory ?? "",
  },
  {
    id: "actual_unit_name",
    header: "Unit Name",
    size: 50,
    getColumnDisplayValue: ({ actual_unit_name }) => actual_unit_name ?? "",
  },
  {
    id: "actual_unit_id",
    header: "Unit ID",
    size: 50,
    getColumnDisplayValue: ({ actual_unit_id }) => actual_unit_id ?? "",
  },
  {
    id: "unit_floor_plan",
    header: "Unit Floor Plan",
    size: 50,
    getColumnDisplayValue: ({ unit_floor_plan }) => unit_floor_plan ?? "",
  },
  {
    id: "room_type",
    header: "Room Type",
    size: 50,
    getColumnDisplayValue: ({ room_type }) => room_type ?? "",
  },
  {
    id: "name",
    header: "Bed Name",
    size: 50,
    getColumnDisplayValue: ({ name }) => name ?? "",
  },
  {
    id: "id",
    header: "Bed ID",
    size: 50,
    getColumnDisplayValue: ({ id }) => id ?? "",
  },
];
