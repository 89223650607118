import { learningServiceRequest, skip } from "./utils";

const workLocationsService = {
  async list(search) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/work_locations",
      params: { limit: 10, q: search },
      failureMessage: "Error loading Work Locations",
    });
    return response?.data?.items ?? [];
  },
  async getWorkLocations({
    limit = 10,
    page = 1,
    q = "",
    extraParams = {},
    failureMessage = "Error loading Work Locations data",
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/work_locations",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      source,
      failureMessage,
    });
  },
};

export default workLocationsService;
