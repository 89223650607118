<script setup>
import { computed, defineProps, toRefs, watch } from "vue";
import WYSIWYG from "@/components/forms/SharedComponents/WYSIWYG/WYSIWYG.vue";
import APIGlobe from "@/components/shared/icons/APIGlobe.vue";
import ImageSet from "@/components/shared/ImageSet.vue";
import { CLOUDINARY_VIDEO_EXTENSION_TYPES } from "@/constants.js";

const props = defineProps({
  city: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["observeSection"]);
const { city } = toRefs(props);

const reasons_to_experience = computed(() => {
  try {
    return city.value?.reason_to_experience
      ? JSON.parse(city.value?.reason_to_experience || "{}")
      : {};
  } catch {
    return {};
  }
});

const images = computed(
  () =>
    city.value?.supporting_media?.filter((item) =>
      CLOUDINARY_VIDEO_EXTENSION_TYPES.some(
        (type) => item && !item.endsWith(type)
      )
    ) || []
);

const hasReasonOfExperience = computed(
  () =>
    !!reasons_to_experience.value &&
    Object.keys(reasons_to_experience.value).length > 0
);

const hasImages = computed(() => !!images.value && images.value.length > 0);

const showInformation = computed(
  () => hasReasonOfExperience.value || hasImages.value
);

watch(
  () => showInformation.value,
  (newShowInformation) => {
    if (newShowInformation) {
      emit("observeSection", "location");
    }
  }
);
</script>

<template>
  <div
    v-if="showInformation"
    class="pt-12 pb-12 md:pt-20 px-4 md:px-8 text-indigo-base h-fit font-montserrat"
  >
    <div>
      <div class="flex flex-col relative">
        <div
          class="hidden md:block absolute -z-50 -top-10 left-1/3 w-fit h-fit"
        >
          <APIGlobe custom-class="w-44 h-44"></APIGlobe>
        </div>
        <div class="z-0">
          <div class="grid grid-rows-2 grid-flow-row">
            <div class="self-center font-bold text-4xl">
              Learn About This
            </div>
            <div class="self-center uppercase font-bold text-4xl">Location</div>
          </div>
          <div class="flex flex-col md:flex-row grow md:h-fit">
            <div class="flex flex-col md:w-1/3 h-fit">
              <div v-if="hasReasonOfExperience">
                <WYSIWYG
                  class="cities-wysiwyg-borders-none text-md bg-transparent"
                  :content="reasons_to_experience"
                  :editable="false"
                  :display-header="false"
                />
              </div>
              <div class="hidden md:block flex h-fit mt-auto pt-5">
                <ImageSet
                  v-if="images?.[1]"
                  :cloudinary-image-url="images?.[1]"
                />
              </div>
            </div>
            <div class="flex flex-col md:w-2/3 h-fit">
              <div class="flex flex-row grow">
                <div
                  class="block md:hidden flex-grow flex-col w-2/4 md:w-full py-5 pr-2 md:pr-0"
                >
                  <ImageSet
                    v-if="images?.[1]"
                    :cloudinary-image-url="images?.[1]"
                  />
                </div>
                <div
                  class="flex-row grow w-2/4 md:w-full md:h-2/4 md:pl-5 py-5 pl-2 md:pl-0"
                >
                  <ImageSet
                    v-if="images?.[0]"
                    :cloudinary-image-url="images?.[0]"
                    class="ml-auto"
                  />
                </div>
              </div>
              <div class="flex flex-row grow mt-auto md:h-2/4">
                <div
                  class="flex-row md:flex-col w-2/4 h-fit mt-auto pr-2 md:pl-5"
                >
                  <ImageSet
                    v-if="images?.[2]"
                    :cloudinary-image-url="images?.[2]"
                  />
                </div>
                <div
                  class="flex-row md:flex-col w-2/4 h-fit mt-auto pl-2 md:pl-5"
                >
                  <ImageSet
                    v-if="images?.[3]"
                    :cloudinary-image-url="images?.[3]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-1 md:mt-20 border-b-3 border-gray-200" />
  </div>
</template>

<style scoped>
.cities-wysiwyg-borders-none,
.cities-wysiwyg-borders-none .editor {
  border: none;
  box-shadow: none;
}

.cities-wysiwyg-borders-none ::v-deep(div) {
  background-color: transparent !important;
}

.cities-wysiwyg-borders-none :deep(.editor) {
  background-color: transparent !important;
}

.cities-wysiwyg-borders-none :deep(.editor__content) {
  height: 100% !important;
  padding: 0.25rem;
  overflow-y: inherit !important;
  background-color: transparent !important;
}
</style>
