<template>
  <div v-if="modalState" class="relative z-50">
    <div
      class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
    />
    <Dialog
      :open="modalState"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
      @closeModal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-20 relative"
      >
        <div class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r">
          <button
            class="h-6 w-6 flex items-center justify-center"
            @click="closeModal"
          >
            <span class="sr-only">Close</span>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <DialogPanel>
          <DialogTitle
            class="flex justify-center mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            {{ title }}
          </DialogTitle>
          <DialogDescription>
            <img
              v-if="archiveResponse !== null"
              :src="'/images/' + icon"
              alt="icon"
              class="mx-auto my-10"
            />
            <p class="text-center my-10">
              {{ formatArchivingMessage }}
            </p>
            <ul v-if="hasMultipleItems" class="text-center mb-10">
              <li v-for="name in itemsNames" :key="name">
                {{ name }}
              </li>
            </ul>
          </DialogDescription>
          <div class="flex justify-center">
            <template v-if="archiveResponse === null">
              <BaseButton
                outlined
                :on-click="closeModal"
                :disabled="disabledButtons"
              >
                Cancel
              </BaseButton>
              <BaseButton :on-click="archiveRecord" :disabled="disabledButtons">
                Archive
              </BaseButton>
            </template>
            <BaseButton v-else outlined :on-click="closeModal">
              Close
            </BaseButton>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  Dialog,
  DialogDescription,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import { ARCHIVING_MSG, DEFAULT_LIMIT_PER_PAGE } from "@/constants.js";
import BaseButton from "@/components/shared/Button/BaseButton";

const singularPluralResources = {
  Housing: {
    singular: "Housing",
    plural: "Housings",
  },
  Internships: {
    singular: "Internship",
    plural: "Internships",
  },
  Programs: {
    singular: "Program",
    plural: "Programs",
  },
  Events: {
    singular: "Event",
    plural: "Events",
  },
};

export default {
  name: "ArchivingModal",
  components: {
    BaseButton,
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
  },
  data() {
    return {
      archiveResponse: null,
      disabledButtons: false,
    };
  },
  computed: {
    resource() {
      return this.$route.meta.subcategory.replace(/^\w/, (c) =>
        c.toUpperCase()
      );
    },
    hasMultipleItems() {
      return this.items?.length > 1;
    },
    firstItem() {
      return this.items[0] ?? {};
    },
    itemsNames() {
      return this.items.map(
        (item) =>
          item.name ||
          item["Name"] ||
          item["Program Name"] ||
          item["Housing Type"] ||
          item["Position/Project Title"]
      );
    },
    pagination() {
      return {
        q: this.$route.query.q || "",
        page: Number(this.$route.query.page || 1),
        limit: Number(this.$route.query.limit || DEFAULT_LIMIT_PER_PAGE),
      };
    },
    title() {
      const resourceName = this.hasMultipleItems
        ? singularPluralResources[this.resource]?.plural
        : singularPluralResources[this.resource]?.singular;

      const message =
        this.archiveResponse === null
          ? resourceName
          : this.archiveResponse?.success
          ? "Completed"
          : "Unsuccessful";

      return `Archive ${message}`;
    },
    formatArchivingMessage() {
      const itemName = `'${this.itemsNames[0] ?? ""}'`;
      const multipleMsg = `The following ${
        singularPluralResources[this.resource]?.plural
      }`;
      let additionalMsg = "";

      if (this.archiveResponse === null) {
        additionalMsg = this.hasMultipleItems
          ? multipleMsg.charAt(0).toLowerCase() + multipleMsg.slice(1)
          : itemName;
        if (this.resource.toLowerCase() === "events") {
          return `Are you sure you want to archive ${additionalMsg.replace(
            /^['"]|['"]$/g,
            ""
          )}?  Any upcoming occurrences will not automatically be cancelled by this action.`;
        }
        return `${ARCHIVING_MSG} ${additionalMsg}?`;
      } else {
        const archiveResponseMsg = this.archiveResponse?.success
          ? " been archived successfully."
          : " NOT been archived successfully.";
        additionalMsg = this.hasMultipleItems
          ? `${multipleMsg} have`
          : `${itemName} has`;
        return `${additionalMsg} ${archiveResponseMsg}`;
      }
    },
    editPage() {
      return !!this.$route.params.itemId;
    },
    icon() {
      return this.archiveResponse?.success
        ? "icon-completed-60.svg"
        : "icon-warning-60.svg";
    },
    ...mapGetters("programManager", {
      modalState: "getArchivingModalState",
      items: "getArchivingItems",
    }),
  },
  methods: {
    closeModal() {
      this.$store.commit("programManager/setArchivingModalStateClose");
      this.archiveResponse = null;
      this.disabledButtons = false;
    },
    async archiveRecord() {
      this.disabledButtons = true;
      this.archiveResponse = null;

      if (this.hasMultipleItems) {
        this.archiveResponse = await this.$store.dispatch(
          `programManager/archive${this.resource}Objects`,
          this.items.map((item) => item.id)
        );
      } else {
        this.archiveResponse = await this.$store.dispatch(
          `programManager/archive${this.resource}Object`,
          this.items[0]?.id
        );
      }

      if (this.archiveResponse?.success) {
        if (!this.hasMultipleItems)
          this.$store.dispatch(`programManager/fetch${this.resource}Count`);

        this.$store.dispatch(
          `programManager/fetch${this.resource}Data`,
          this.pagination
        );

        if (this.editPage) {
          setTimeout(() => {
            this.closeModal();
            this.$router.push({
              name: `program-manager-${this.$route.meta.subcategory}`,
            });
          }, 1000);
        }
      }
    },
  },
};
</script>
