<template>
  <div class="space-y-6">
    <p v-if="rule === 'CP Arrival Guidelines - Group Flight'">
      A group flight is being arranged for your program! Please do not purchase
      flight tickets. API and/or your university will provide more detailed
      information regarding your group flight.
    </p>
    <template v-else>
      <p>
        It’s time to start booking your travel - API Abroad recommends all
        participants to book travel reservations that can be changed and are
        refundable. We recommend trip insurance, as well. Good travelers are
        always prepared for the unexpected.
      </p>
      <p>
        By booking your travel to arrive per the below guidelines, you will be
        arriving in time for your orientation and any events / excursions that
        are included in your program.
      </p>
      <template v-if="!show">
        <div class="grid grid-cols-2 gap-y-2 gap-x-6">
          <div
            class="text-xs font-semibold tracking-widest text-gray-500 uppercase"
          >
            There was an error while reading the program data, please try again.
          </div>
        </div>
      </template>
      <template v-if="show">
        <div class="grid grid-cols-2 gap-y-2 gap-x-6">
          <template v-if="arrivalDate">
            <div
              class="text-xs font-semibold tracking-widest text-gray-500 uppercase"
            >
              Arrival Date
            </div>
            <div class="text-gray-650">
              <program-dates
                :id="'arrival-dates'"
                :dates="arrivalDate"
                :start-is-final="isFinalDate"
                :end-is-final="isFinalDate"
              />
            </div>
          </template>
          <template v-if="arrivalTime">
            <div
              class="text-xs font-semibold tracking-widest text-gray-500 uppercase"
            >
              Arrive By
            </div>
            <div class="text-gray-650">
              {{ arrivalTime }}
            </div>
          </template>
          <template v-if="arrivalAirport">
            <div
              class="text-xs font-semibold tracking-widest text-gray-500 uppercase"
            >
              Arrival Airport
            </div>
            <div class="text-gray-650">
              {{ arrivalAirport }}
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import programDatesComponent from "../../ProgramDates.vue";
export default {
  name: "ArrivalGuidelines",
  components: {
    programDates: programDatesComponent,
  },
  props: {
    arrivalDate: {
      type: String,
      default: "",
    },
    arrivalTime: {
      type: String,
      default: "",
    },
    arrivalAirport: {
      type: String,
      default: "",
    },
    isFinalDate: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    rule: {
      type: String,
      default: "",
    },
  },
};
</script>
