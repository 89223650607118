<template>
  <label class="block text-sm mb-2 sm:mb-0 sm:flex-1 sm:mr-5" :for="name">
    <div :data-tippy-content="placeholder">
      <input
        :id="name"
        v-model="date"
        class="form-input min-h-10 text-sm block w-full"
        :type="inputType"
        :placeholder="dynamicPlaceholder"
        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
        @focus="onFocus"
      />
    </div>
  </label>
</template>

<script>
import parse from "date-fns/parse";
import format from "date-fns/format";
import { isValid } from "date-fns";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { eventBus } from "@/app";
import { isDateSupported } from "@/mixins/helpers.js";

export default {
  name: "CalendarFilter",
  props: {
    name: {
      type: String,
      default: "calendar",
    },
    stateDate: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Date",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    otherFilterValue: {
      type: String,
      default: "",
    },
  },
  emits: ["input"],
  data() {
    return {
      date: "",
      dynamicPlaceholder: "",
      inputType: "text",
      enableLeave: false,
    };
  },
  watch: {
    date: {
      handler: function (newval) {
        let formatDate = "";

        if (isDateSupported()) {
          formatDate = newval;
        } else {
          if (isValid(parse(newval, "MM-dd-yyyy", new Date()))) {
            formatDate = format(
              parse(newval, "MM-dd-yyyy", new Date()),
              "yyyy-MM-dd"
            );
          }
        }

        this.$emit("input", formatDate);
      },
    },
    stateDate: {
      handler: function (newval) {
        if (newval) {
          this.setPlaceholder(true);
          this.inputType = "date";
          this.date = isDateSupported()
            ? newval
            : format(parse(newval, "yyyy-MM-dd", new Date()), "MM-dd-yyyy");
        } else {
          this.setPlaceholder(false);
          this.date = newval;
          this.inputType = "text";
        }
      },
      immediate: true,
    },
    otherFilterValue: {
      handler: function (newval) {
        if (newval && !this.date) {
          this.onFocus();
        }
      },
      immediate: true,
    },
  },
  created() {
    eventBus.$on("reset", () => {
      this.reset();
    });

    this.setPlaceholder(this.stateDate);
  },
  mounted() {
    this.initTippy();
  },
  methods: {
    setPlaceholder(value) {
      this.dynamicPlaceholder = value ? "mm-dd-yyyy" : this.placeholder;
    },
    onFocus() {
      this.setPlaceholder(true);
      this.enableLeave = true;
      this.inputType = "date";

      if (!this.date && this.defaultValue) this.date = this.defaultValue;
    },
    initTippy() {
      tippy("[data-tippy-content]", {
        theme: "light",
        role: "tooltip",
        aria: {
          content: "describedby",
          expanded: "auto",
        },
      });
    },
    reset() {
      this.setPlaceholder(false);
      this.date = "";
      this.inputType = "text";
    },
  },
};
</script>
