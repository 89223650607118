<template>
  <div>
    <div class="relative pt-6 sm:p-6 my-1">
      <div class="flex justify-between items-center">
        <h1 class="text-xl font-semibold leading-6 text-gray-900">
          General Info
        </h1>
        <RequiredToSavePublish />
      </div>
      <div class="grid sm:grid-cols-2 md:grid-cols-3 mt-2">
        <div class="flex flex-col justify-start md:max-w-[300px] my-4">
          <div class="mt-1">
            <label class="block text-sm font-semibold text-gray-700"
              ><span class="text-red-100 pr-2 align-sub">**</span>Housing
              Tier</label
            >
            <v-select
              v-model="housingUpdates.tier_id"
              :reduce="(val) => val.id"
              :options="tiers"
              :close-on-select="true"
              :searchable="false"
              placeholder="Pick a value"
              label="label"
              :class="{
                'forced-error-background': !!v$.housingUpdates.tier_id.$errors
                  .length,
              }"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"
                  ><i
                    class="fa fa-caret-down text-gray-500"
                    v-bind="attributes"
                  ></i
                ></span>
              </template>
            </v-select>
          </div>
          <div
            v-if="v$.housingUpdates.tier_id.$errors.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.housingUpdates.tier_id.$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div
          v-if="!getGenericListing && !isLocalType"
          class="flex flex-col justify-start w-full col-span-2 my-4 mr-12"
        >
          <div class="mt-1">
            <label class="block text-sm font-semibold text-gray-700"
              ><span class="text-red-100 pr-2 align-sub">**</span
              >Provider/Vendor</label
            >
            <v-select
              v-model="housingUpdates.vendor_institution_id"
              :reduce="(val) => val.id"
              :options="getVendorsData"
              :close-on-select="true"
              placeholder="Pick a value"
              label="name"
              :class="{
                'forced-error-background': !!v$.housingUpdates
                  .vendor_institution_id.$errors.length,
              }"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"
                  ><i
                    class="fa fa-caret-down text-gray-500"
                    v-bind="attributes"
                  ></i
                ></span>
              </template>
            </v-select>
          </div>
          <div
            v-if="v$.housingUpdates.vendor_institution_id.$errors.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.housingUpdates.vendor_institution_id
                .$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div
          v-if="getGenericListing"
          class="flex flex-col justify-start md:max-w-[300px] my-4"
        >
          <div class="mt-1">
            <label class="block text-sm font-semibold text-gray-700"
              >Subcategory</label
            >
            <v-select
              v-model="housingUpdates.subcategory"
              :options="getSubcategory"
              :close-on-select="true"
              :searchable="false"
              placeholder="Pick a value"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"
                  ><i
                    class="fa fa-caret-down text-gray-500"
                    v-bind="attributes"
                  ></i
                ></span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div
        v-if="getGenericListing"
        class="grid sm:grid-cols-2 md:grid-cols-3 mt-2"
      >
        <CitySection
          v-model="housingUpdates.city_id"
          :city="originalHousingRecord.city_id_rel"
          :vuelidate-instance="v$.housingUpdates.city_id"
        ></CitySection>
      </div>
      <div class="grid sm:grid-cols-2 md:grid-cols-3 mt-2">
        <div
          v-if="!getGenericListing"
          class="flex flex-col justify-start md:max-w-[300px] my-4"
        >
          <div class="mt-1">
            <label class="block text-sm font-semibold text-gray-700"
              >Subcategory</label
            >
            <v-select
              v-model="housingUpdates.subcategory"
              :options="getSubcategory"
              :close-on-select="true"
              :searchable="false"
              placeholder="Pick a value"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"
                  ><i
                    class="fa fa-caret-down text-gray-500"
                    v-bind="attributes"
                  ></i
                ></span>
              </template>
            </v-select>
          </div>
        </div>
        <div
          v-if="isHotel"
          class="flex flex-col justify-start md:max-w-[300px] col-span-2 my-4 mr-12"
        >
          <div class="mt-1">
            <label class="block text-sm font-semibold text-gray-700"
              ><span class="text-red-100 pr-2 align-sub">**</span>Hotel Star
              Rating</label
            >
            <v-select
              v-model="housingUpdates.hotel_rating"
              :reduce="(val) => val.id"
              :options="hotelRating"
              :close-on-select="true"
              :searchable="false"
              placeholder="Pick a value"
              label="name"
              :class="{
                'forced-error-background': !!v$.housingUpdates.hotel_rating
                  .$errors.length,
              }"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"
                  ><i
                    class="fa fa-caret-down text-gray-500"
                    v-bind="attributes"
                  ></i
                ></span>
              </template>
            </v-select>
          </div>
          <div
            v-if="v$.housingUpdates.hotel_rating.$errors.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.housingUpdates.hotel_rating.$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 md:grid-cols-3 mt-2">
        <div class="col-span-3 flex flex-col w-full mt-2">
          <label
            for="description"
            class="block text-sm font-semibold text-gray-700"
            ><span class="text-red-100 pr-2 align-sub">**</span
            >Description</label
          >
          <div class="mt-1">
            <textarea
              id="description"
              v-model="descriptionBounce"
              rows="12"
              name="description"
              class="block w-full form-input sm:text-sm"
              :class="{
                'bg-error-100': v$.housingUpdates.description.$errors.length,
              }"
              :maxlength="maxDescriptionSize"
            />
          </div>
          <div class="flex flex-row flex-grow-1 w-full justify-between">
            <div
              v-if="v$.housingUpdates.description.$errors.length"
              class="block text-sm error text-error-900"
            >
              <span
                v-for="(error, idx) in v$.housingUpdates.description.$errors"
                :key="idx"
              >
                {{ error.$message }}
              </span>
            </div>
            <div
              class="font-semibold text-gray-600 block text-sm self-end ml-auto"
            >
              {{ descriptionBounce?.length || 0 }}/1000
            </div>
          </div>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 md:grid-cols-3 mt-4">
        <template
          v-for="(ha, index) in housingUpdates.housing_availability"
          :key="ha.id"
        >
          <div class="flex flex-col mt-2 justify-start md:max-w-[300px]">
            <label for="start" class="block text-sm font-semibold text-gray-700"
              ><span class="text-red-100 pr-2 align-sub">**</span>Available
              Start Date</label
            >
            <div class="relative flex flex-grow">
              <flat-pickr
                :model-value="
                  housingUpdates.housing_availability[index].start_date
                "
                :config="config"
                class="block h-10 w-full form-input sm:text-sm pl-2"
                alt-input-class="custom-date-picker"
                :class="{
                  'bg-error-100':
                    v$.housingUpdates.housing_availability.$each.$response
                      ?.$errors[index].start_date.length,
                }"
                placeholder="Select date"
                name="date"
                @onChange="
                  (date) =>
                    updateHousingAvailabilityDate(date, index, 'start_date')
                "
              ></flat-pickr>
              <div
                class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5 w-6 h-8 pt-2 mt-1 text-gray-600"
              >
                <CalendarDaysIcon />
              </div>
            </div>
            <div
              v-if="
                v$.housingUpdates.housing_availability.$each.$response?.$errors[
                  index
                ].start_date.length
              "
              class="block text-sm error text-error-900"
            >
              <span
                v-for="error in v$.housingUpdates.housing_availability.$each
                  .$response?.$errors[index].start_date"
                :key="error"
              >
                {{ error.$message }}
              </span>
            </div>
          </div>
          <div class="flex flex-col mt-2 justify-start">
            <label class="block text-sm font-semibold text-gray-700" for="end"
              ><span class="text-red-100 pr-2 align-sub">**</span>Available End
              Date</label
            >
            <div class="flex flex-row flex-grow max-h-10">
              <div class="relative flex flex-grow md:max-w-[300px]">
                <flat-pickr
                  :model-value="
                    housingUpdates.housing_availability[index].end_date
                  "
                  :config="config"
                  class="block h-10 w-full form-input sm:text-sm custom-date-picker"
                  :class="{
                    'bg-error-100':
                      v$.housingUpdates.housing_availability.$each.$response
                        ?.$errors[index].end_date.length,
                  }"
                  placeholder="Select date"
                  name="date"
                  @onChange="
                    (date) =>
                      updateHousingAvailabilityDate(date, index, 'end_date')
                  "
                ></flat-pickr>
                <div
                  class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5 w-6 h-8 pt-2 mt-1 text-gray-600"
                >
                  <CalendarDaysIcon />
                </div>
              </div>
              <div v-if="index > 0" class="w-8 cursor-pointer ml-2 pt-2 pl-2">
                <i
                  class="fa fa-trash text-gray-650"
                  @click="removeAvailability(index)"
                ></i>
              </div>
            </div>
            <div
              v-if="
                v$.housingUpdates.housing_availability.$each.$response?.$errors[
                  index
                ].end_date.length
              "
              class="block text-sm error text-error-900"
            >
              <span
                v-for="error in v$.housingUpdates.housing_availability.$each
                  .$response?.$errors[index].end_date"
                :key="error"
              >
                {{ error.$message }}
              </span>
            </div>
          </div>
          <div class="flex flex-col"><!-- Keep this div for alignment --></div>
        </template>
      </div>
      <div class="flex flex-row mt-4 text-center items-center">
        <div
          :class="`${secondaryColorClass}`"
          class="h-12 w-12 cursor-pointer"
          @click="addAvailability"
        >
          <PlusCircleIcon />
        </div>
        <div
          class="text-sm font-semibold text-gray-700 ml-2 cursor-pointer"
          @click="addAvailability"
        >
          Add another date range
        </div>
      </div>

      <div class="grid sm:grid-cols-2 md:grid-cols-3 mt-4 md:h-20">
        <div class="flex flex-col mt-2 justify-start md:max-w-[300px]">
          <label class="text-sm font-semibold text-gray-700 capitalize"
            ><span class="text-red-100 pr-2 align-sub">**</span>Length of Stay
            Requirements</label
          >
          <div id="length-of-stay-requirement" class="flex items-center mt-2">
            <label class="flex">
              <input
                v-model="minStayRequirement"
                type="radio"
                name="minStayRequirement"
                value="yes"
                class="mt-1 form-radio"
              />
              <span class="ml-2">Yes</span>
            </label>
            <label class="flex sm:ml-4">
              <input
                v-model="minStayRequirement"
                type="radio"
                name="minStayRequirement"
                value="no"
                class="mt-1 form-radio"
                @click="updateMinStay(0)"
              />
              <span class="ml-2">No</span>
            </label>
          </div>
        </div>
        <div class="flex flex-col mt-2 justify-start">
          <div
            v-if="minStayRequirement === 'yes'"
            class="xs:col-span-2 sm:col-span-3 block md:max-w-[300px]"
          >
            <label class="text-sm font-semibold text-gray-700 capitalize"
              ><span class="text-red-100 pr-2 align-sub">**</span>Length of
              Stay</label
            >
            <div class="flex items-center">
              <input
                :value="housingUpdates.min_stay"
                type="number"
                min="0"
                step="1"
                class="mt-1 block w-full form-input sm:text-sm"
                :class="{
                  'bg-error-100': v$.housingUpdates.min_stay.$errors.length,
                }"
                @input="updateMinStay($event.target.value)"
              />
              <span class="ml-2">Days</span>
            </div>
            <div
              v-if="
                minStayRequirement === 'yes' &&
                v$.housingUpdates.min_stay.$errors.length
              "
              class="block text-sm error text-error-900"
            >
              <span
                v-for="(error, idx) in v$.housingUpdates.min_stay.$errors"
                :key="idx"
              >
                {{ error.$message }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-col"><!-- Keep this div for alignment --></div>
      </div>
      <div class="grid xs:grid-cols-2 sm:grid-cols-3 mt-2">
        <div class="xs:col-span-2 sm:col-span-3 block">
          <label class="text-sm font-semibold text-gray-700 capitalize"
            ><span class="text-red-100 pr-2 align-sub">**</span>Terms
            Available</label
          >
          <div
            v-if="v$.housingUpdates.terms_available.$errors.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.housingUpdates.terms_available.$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>
        <template v-if="getTermsData">
          <div class="flex items-start my-2">
            <div class="flex h-5 items-center">
              <input
                id="all"
                name="all"
                type="checkbox"
                class="h-4 w-4 form-checkbox border-gray-300"
                :checked="isAllTerms"
                @click="setAllTerms"
              />
            </div>
            <div class="ml-3 text-sm">
              <label
                for="all"
                class="block text-sm font-semibold text-gray-700 capitalize"
                >All</label
              >
            </div>
          </div>
          <div
            v-for="term in getTermsData"
            :key="term.id"
            class="flex items-start my-2"
          >
            <div class="flex h-5 items-center">
              <input
                :id="term.id"
                :name="term.id"
                type="checkbox"
                class="h-4 w-4 form-checkbox border-gray-300"
                :checked="housingUpdates.terms_available.includes(term.id)"
                @input="addRemoveTerms(term.id)"
              />
            </div>
            <div class="ml-3 text-sm">
              <label
                :for="term.id"
                class="block text-sm font-semibold text-gray-700 capitalize"
                >{{ term.name }}</label
              >
            </div>
          </div>
        </template>
      </div>

      <div>
        <div v-if="!getGenericListing">
          <div class="flex flex-col mt-4">
            <label class="block text-sm font-semibold text-gray-700">
              <span class="text-red-100 pr-2 align-sub">*</span>Address of
              Housing</label
            >
            <div class="flex my-4 items-center">
              <input
                data-testid="address-coming-soon-checkbox"
                :checked="housingUpdates.address_coming_soon"
                type="checkbox"
                class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                @change="updateAddressComingSoon"
              />
              <label
                name="address-coming-soon-label"
                class="block text-sm font-semibold text-gray-700"
                >Address coming soon</label
              >
            </div>
            <AddressComponent
              class="forced-white-background"
              :auto-complete-types="getAddressComponentAutocompleteType"
              :initial-address-info="getInitialAddressInfo"
              @update-address-info="updateAddressInfo"
            />
          </div>
          <div class="grid xs:grid-cols-2 sm:grid-cols-3 mt-2">
            <CitySection
              v-model="housingUpdates.city_id"
              :search-city="searchCity"
              :city="originalHousingRecord.city_id_rel"
              :vuelidate-instance="v$.housingUpdates.city_id"
              :show-lat-lng-field="false"
              @selected-city="updateSelectedCity"
            ></CitySection>
            <div
              v-if="!getGenericListing && !isLocalType && !isHouseType"
              class="mb-4"
            >
              <label
                name="zip"
                class="block text-sm font-semibold text-gray-700"
                >Zip Code</label
              >
              <div class="mt-2">
                <input
                  id="zip"
                  v-model="housingUpdates.address_postal_code"
                  type="text"
                  name="zip"
                  class="h-4 border-gray-300 bg-blue-100 font-medium font-sans focus:outline-none"
                  readonly
                />
              </div>
            </div>
            <div class="mb-4">
              <label
                name="latLong"
                class="block text-sm font-semibold text-gray-700"
                >Lat/Long</label
              >
              <div class="mt-2">
                <input
                  id="latLong"
                  v-model="displayLatLng"
                  type="text"
                  name="latLong"
                  class="h-4 border-gray-300 bg-blue-100 font-medium font-sans focus:outline-none"
                  readonly
                />
              </div>
            </div>
            <div
              v-if="!getGenericListing && !isLocalType && !isHouseType"
              class="mb-4"
            >
              <label
                name="neighborhoodName"
                class="block text-sm font-semibold text-gray-700"
                >Neighborhood Name</label
              >
              <div class="mt-2">
                <input
                  id="neighborhoodName"
                  v-model="housingUpdates.neighborhood"
                  type="text"
                  name="neighborhoodName"
                  class="h-10 md:max-w-[300px] w-full form-input"
                  :class="{
                    'bg-error-100':
                      v$.housingUpdates.neighborhood.$errors.length,
                  }"
                />
              </div>
              <div
                v-if="v$.housingUpdates.neighborhood.$errors.length"
                class="block text-sm error text-error-900"
              >
                <span
                  v-for="(error, idx) in v$.housingUpdates.neighborhood.$errors"
                  :key="idx"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showHousingOnCampus" class="mb-4">
          <label class="block text-sm font-semibold text-gray-700"
            >Is this housing on campus?</label
          >
          <fieldset class="mt-4">
            <div class="grid grid-cols-2">
              <div class="flex items-center">
                <input
                  id="campusYes"
                  name="campusYes"
                  type="radio"
                  :value="!!housingUpdates.on_campus"
                  :checked="!!housingUpdates.on_campus"
                  class="h-4 w-4 border-gray-300 form-radio"
                  @input="housingUpdates.on_campus = true"
                />
                <label
                  for="campusYes"
                  class="ml-3 block text-sm font-medium text-gray-700"
                  >Yes</label
                >
              </div>
              <div class="flex items-center">
                <input
                  id="campusNo"
                  name="campusNo"
                  type="radio"
                  :value="!housingUpdates.on_campus"
                  :checked="!housingUpdates.on_campus"
                  class="h-4 w-4 border-gray-300 form-radio"
                  @input="housingUpdates.on_campus = false"
                />
                <label
                  for="campusNo"
                  class="ml-3 block text-sm font-medium text-gray-700"
                  >No</label
                >
              </div>
            </div>
          </fieldset>
        </div>
        <div class="mb-4">
          <label class="block text-sm font-semibold text-gray-700"
            ><span class="text-red-100 pr-2 align-sub">**</span>What program
            types can use this housing?</label
          >
          <WrappedVueSelect
            v-model="housingUpdates.allowed_program_types"
            :options="programTypeOptions"
            :reduce="(val) => val.id"
            :wrapped-values="housingUpdates.allowed_program_types"
            :multiple="true"
            :deselect-from-dropdown="true"
            :close-on-select="false"
            placeholder="Pick a value"
            :display-error="
              !!v$.housingUpdates.allowed_program_types.$errors.length
            "
          />
          <div
            v-if="v$.housingUpdates.allowed_program_types.$errors.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.housingUpdates.allowed_program_types
                .$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div class="mb-4">
          <label class="block text-sm font-semibold text-gray-700"
            ><span class="text-red-100 pr-2 align-sub">**</span>Who can use this
            housing?</label
          >
          <WrappedVueSelect
            v-model="whoCanUse"
            :options="whoCanUseOptions"
            :reduce="(val) => val.id"
            :wrapped-values="whoCanUse"
            :multiple="true"
            :deselect-from-dropdown="true"
            :close-on-select="false"
            placeholder="Pick a value"
            @update:modelValue="updateAllowedParticipants"
          />
        </div>
        <div v-if="displaySelectInstitutions" class="mb-4">
          <label class="block text-sm font-semibold text-gray-700"
            >Select Institution(s)?</label
          >
          <WrappedVueSelect
            v-model="selectedInstitutions"
            :options="entitiesOptions.items"
            :reduce="(val) => ({ id: val.id, name: val.name })"
            :loading="entitiesLoading"
            :wrapped-values="selectedInstitutions"
            :multiple="true"
            :deselect-from-dropdown="true"
            :close-on-select="false"
            placeholder="Pick a value"
            label="name"
            @update:modelValue="updateAllowedParticipants"
            @search="fetchEntitiesOptions"
          />
        </div>
        <div v-if="!getGenericListing && !isLocalType" class="mb-4">
          <label class="block text-sm font-semibold text-gray-700"
            >What other occupants are in the building?</label
          >
          <WrappedVueSelect
            v-model="housingUpdates.occupants"
            :options="occupantsOptions"
            :reduce="(val) => val.id"
            :wrapped-values="housingUpdates.occupants"
            :multiple="true"
            :deselect-from-dropdown="true"
            :close-on-select="false"
            placeholder="Pick a value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  requiredIf,
  maxLength,
  numeric,
  required,
} from "@vuelidate/validators";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import { CalendarDaysIcon } from "@heroicons/vue/20/solid";
import { PlusCircleIcon } from "@heroicons/vue/24/outline";
import AddressComponent from "@/components/forms/AddressInputComponent/AddressComponent.vue";
import RequiredToSavePublish from "../RequiredToSavePublish";
import WrappedVueSelect from "../WrappedVueSelect";
import CitySection from "@/components/program-manager/housing/components/CitySection";
import { convertLatLongDDtoDMS } from "@/components/program-manager/sessions/utils.js";
import {
  HOUSING_TYPES_MAP,
  ALLOWED_PROGRAM_TYPES,
  TIERS,
  SUB_CATEGORIES,
  STUDENTS,
} from "@/constants";
import {
  useGetEntities,
  debounceSearchWrapper,
} from "@/components/program-manager/sessions/composable";

const requiredToPublish = helpers.withMessage(
  "This field is required to Publish",
  requiredIf(function () {
    return this.getCurrentHousingStatus === "Active";
  })
);

const formatOriginalRecord = (originalHousingRecord) => {
  const newOriginalRecord = _.defaults(
    _.pick(_.cloneDeep(originalHousingRecord), [
      "address_coming_soon",
      "address_lat_lng",
      "address_line_1",
      "address_line_2",
      "address_postal_code",
      "allowed_participants",
      "allowed_program_types",
      "city_id",
      "city",
      "state",
      "country_id",
      "description",
      "hotel_rating",
      "tier",
      "subcategory",
      "min_stay",
      "housing_availability",
      "neighborhood",
      "occupants",
      "on_campus",
      "terms_available",
      "timezone_offset_mins",
      "vendor_institution_id",
    ]),
    {
      allowed_program_types: [],
      allowed_participants: [],
      on_campus: false,
      terms_available: [],
      housing_availability: [],
      occupants: [],
    }
  );
  if (!newOriginalRecord.housing_availability.length) {
    newOriginalRecord.housing_availability.push({
      start_date: new Date().toISOString(),
      end_date: new Date().toISOString(),
    });
  }
  newOriginalRecord.terms_available = newOriginalRecord.terms_available.map(
    ({ id }) => id
  );
  newOriginalRecord.tier_id = newOriginalRecord.tier?.id;
  return newOriginalRecord;
};

const occupantsOptions = [
  { id: "International", name: "International" },
  { id: "API Students", name: "API Students" },
  { id: "US Students", name: "US Students" },
  { id: "Locals", name: "Locals" },
];

const programTypeOptions = ALLOWED_PROGRAM_TYPES.map((name) => ({
  id: name,
  name,
}));

const hotelRating = [
  { name: "1 Star", id: "1 star" },
  { name: "2 Star", id: "2 star" },
  { name: "3 Star", id: "3 star" },
  { name: "4 Star", id: "4 star" },
  { name: "5 Star", id: "5 star" },
];

const whoCanUseOptions = [
  { name: "Students", id: "students" },
  { name: "Non-Students", id: "non-students" },
  { name: "Faculty", id: "faculty" },
];

const {
  execute: executeGetEntities,
  state: entitiesOptions,
  isLoading: entitiesLoading,
} = useGetEntities(
  { immediate: false, throwError: false, resetOnExecute: true },
  {
    extraParams: { limit: 100 },
  }
);

export default {
  name: "GeneralInfo",
  components: {
    AddressComponent,
    CalendarDaysIcon,
    CitySection,
    flatPickr,
    PlusCircleIcon,
    RequiredToSavePublish,
    WrappedVueSelect,
  },
  props: {
    originalHousingRecord: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate({
        $registerAs: "GeneralInfoTab",
        $lazy: true,
        $autoDirty: true,
      }),
    };
  },
  data() {
    return {
      occupantsOptions,
      programTypeOptions,
      hotelRating,
      tiers: TIERS,
      searchCity: "",
      whoCanUse: _.uniq(
        this.originalHousingRecord.allowed_participants.map(
          (val) => val.student_type || "students"
        )
      ),
      whoCanUseOptions,
      selectedInstitutions: [],
      config: {
        dateFormat: "m/d/Y",
      },
      institution: undefined,
      housingUpdates: formatOriginalRecord(this.originalHousingRecord),
      maxDescriptionSize: 1000,
      minStayRequirement:
        this.originalHousingRecord.min_stay !== 0 ? "yes" : "no",
      entitiesOptions,
      entitiesLoading,
    };
  },
  computed: {
    ...mapGetters("programManager", [
      "getTermsIsLoading",
      "getTermsIsLoading",
      "getTermsData",
      "getCurrentHousingType",
      "getGenericListing",
      "getCurrentHousingStatus",
      "getVendorsData",
      "getHomeInstitutionsDataForSelect",
    ]),
    getSubcategory() {
      const type = this.getCurrentHousingType;
      return SUB_CATEGORIES.filter((item) => item.type == type)[0]
        ?.subcategories;
    },
    descriptionBounce: {
      get() {
        return this.housingUpdates.description;
      },
      set: _.debounce(function (newValue) {
        this.housingUpdates.description = newValue;
      }, 500),
    },
    isAllTerms() {
      return (
        this.housingUpdates.terms_available?.length ===
        this.getTermsData?.length
      );
    },
    displayLatLng() {
      if (this.housingUpdates.address_lat_lng) {
        return convertLatLongDDtoDMS(this.housingUpdates.address_lat_lng);
      }
      return ``;
    },
    displaySelectInstitutions() {
      return !!this.whoCanUse.find((id) => id === "students");
    },
    isHotel() {
      return this.getCurrentHousingType === HOUSING_TYPES_MAP.HOTEL;
    },
    showHousingOnCampus() {
      return (
        (this.getCurrentHousingType === HOUSING_TYPES_MAP.APARTMENT ||
          this.getCurrentHousingType === HOUSING_TYPES_MAP.DORM) &&
        !this.getGenericListing
      );
    },
    getAddressComponentAutocompleteType() {
      if (
        this.getGenericListing ||
        this.getCurrentHousingType === HOUSING_TYPES_MAP.HOUSE ||
        this.isLocalType
      ) {
        return ["(cities)"];
      }
      return ["address"];
    },
    getInitialAddressInfo() {
      return {
        street_address_1: this.originalHousingRecord.address_line_1,
        street_address_2: this.originalHousingRecord.address_line_2,
        city: this.originalHousingRecord.city,
        state: this.originalHousingRecord.state,
        country: this.originalHousingRecord?.country?.name ?? "",
        postal_code: this.originalHousingRecord.address_postal_code,
        utc_offset_minutes: this.originalHousingRecord.timezone_offset_mins,
      };
    },
    isLocalType() {
      return this.getCurrentHousingType === HOUSING_TYPES_MAP.LOCAL_HOST;
    },
    isHouseType() {
      return this.getCurrentHousingType === HOUSING_TYPES_MAP.HOUSE;
    },
  },
  created() {
    const selectedInstitutionsIds = this.originalHousingRecord.allowed_participants
      .filter((institution) => !!institution.home_institution_id)
      .map((institution) => institution.home_institution_id);
    if (this.whoCanUse.find((val) => val === "students")) {
      executeGetEntities().then(() => {
        this.selectedInstitutions = entitiesOptions.value.items
          .filter((entity) => selectedInstitutionsIds.includes(entity.id))
          .map((entity) => ({ id: entity.id, name: entity.name }));
      });
    }
  },
  methods: {
    updateAddressComingSoon(e) {
      this.housingUpdates.address_coming_soon = e.target.checked;
    },
    updateHousingAvailabilityDate(date, index, fieldName) {
      this.housingUpdates.housing_availability = this.housingUpdates.housing_availability.map(
        (item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [fieldName]: date[0].toISOString(),
            };
          }

          return item;
        }
      );
    },
    fetchHomeInstitutions() {
      this.$store.dispatch("programManager/fetchHomeInstitutions", {
        limit: 100,
      });
    },
    updateAllowedParticipants() {
      let newAllowedParticipants = [];
      let newSelectedInstitutions = [];
      this.whoCanUse.forEach((id) => {
        if (id === "non-students" || id === "faculty") {
          newAllowedParticipants.push({ student_type: id });
        }
      });

      if (this.whoCanUse.find((val) => val === STUDENTS)) {
        if (this.selectedInstitutions.length) {
          this.selectedInstitutions.forEach(({ id, name }) => {
            newAllowedParticipants.push({
              student_type: STUDENTS,
              home_institution_id: id,
            });
            newSelectedInstitutions.push({
              id,
              name,
            });
          });
        } else {
          newAllowedParticipants.push({
            student_type: STUDENTS,
          });
        }
      } else {
        this.selectedInstitutions = [];
      }
      this.selectedInstitutions = newSelectedInstitutions;
      this.housingUpdates.allowed_participants = newAllowedParticipants;
    },
    updateAddressInfo(addressInfo) {
      const {
        street_address_1,
        street_address_2,
        number,
        city,
        state,
        country,
        postal_code,
        lat,
        long,
        iso,
        utc_offset_minutes,
      } = addressInfo;
      this.searchCity = city;
      this.housingUpdates.address_line_1 = `${number ? `${number} ` : ""}${
        street_address_1 || ""
      }`;
      this.housingUpdates.address_line_2 = street_address_2;
      this.housingUpdates.address_postal_code = postal_code || null;
      this.housingUpdates.city = city;
      this.housingUpdates.state = state;
      this.housingUpdates.address_lat_lng = `${lat || ""} ${long || ""}`; //37.4224428 -122.0829089197085
      this.housingUpdates.timezone_offset_mins = utc_offset_minutes;
    },
    updateSelectedCity(item) {
      this.housingUpdates.country_id = item?.country?.id;
    },
    setAllTerms(event) {
      if (this.getTermsData) {
        if (event?.currentTarget?.checked && !this.isAllTerms) {
          this.housingUpdates.terms_available = this.getTermsData.map(
            ({ id }) => id
          );
        } else {
          this.housingUpdates.terms_available = [];
        }
      }
    },
    addRemoveTerms(id) {
      if (this.getTermsData) {
        const index = this.housingUpdates.terms_available.findIndex(
          (val) => val === id
        );
        if (index >= 0) {
          this.housingUpdates.terms_available.splice(index, 1);
        } else {
          this.housingUpdates.terms_available = [
            ...this.housingUpdates.terms_available,
            id,
          ];
        }
      }
    },
    removeAvailability(index) {
      this.housingUpdates.housing_availability.splice(index, 1);
    },
    addAvailability() {
      this.housingUpdates.housing_availability.push({
        start_date: new Date().toISOString(),
        end_date: new Date().toISOString(),
      });
    },
    updateMinStay(minStayDays) {
      this.housingUpdates.min_stay = minStayDays;
    },
    fetchHomeInstitutionsOptions: _.debounce(async function (search, loading) {
      await loading(true);
      await this.$store.dispatch("programManager/fetchHomeInstitutions", {
        q: search,
        limit: 100,
      });
      await loading(false);
    }, 500),
    fetchEntitiesOptions: debounceSearchWrapper(executeGetEntities, 500, {
      extraParams: { limit: 100 },
    }),
    getUpdates() {
      return { updates: this.housingUpdates };
    },
  },
  validations: {
    housingUpdates: {
      vendor_institution_id: {
        required: helpers.withMessage(
          "This field is required to Publish",
          requiredIf(function () {
            return (
              this.getCurrentHousingStatus === "Active" &&
              !this.getGenericListing &&
              !this.isLocalType
            );
          })
        ),
      },
      city_id: {
        required: helpers.withMessage("The field is required", required),
      },
      hotel_rating: {
        required: helpers.withMessage(
          "This field is required to Publish",
          requiredIf(function () {
            return this.getCurrentHousingStatus === "Active" && this.isHotel;
          })
        ),
      },
      tier_id: {
        required: helpers.withMessage(
          "This field is required to Publish",
          requiredIf(function () {
            return this.getCurrentHousingStatus === "Active";
          })
        ),
      },
      min_stay: {
        required: helpers.withMessage(
          "This field is required to Publish",
          requiredIf(function () {
            return (
              this.getCurrentHousingStatus === "Active" &&
              this.minStayRequirement === "yes"
            );
          })
        ),
        numeric,
      },
      description: {
        required: requiredToPublish,
      },
      neighborhood: {
        maxLengthValue: helpers.withMessage(
          "Neighborhood name should be 255 characters or less",
          maxLength(255)
        ),
      },
      allowed_program_types: {
        required: requiredToPublish,
      },
      on_campus: {
        required: requiredIf(() => false),
      },
      subcategory: {
        required: requiredIf(() => false),
      },
      housing_availability: {
        $each: helpers.forEach({
          start_date: {
            required: requiredToPublish,
            lessThanEnd: helpers.withMessage(
              "Availability start date should be before the end date",
              function (value, siblings) {
                if (value && siblings.end_date) {
                  const startDate = Date.parse(value);
                  const endDate = Date.parse(siblings.end_date);
                  if (startDate <= endDate) {
                    return true;
                  }
                }
                return false;
              }
            ),
          },
          end_date: {
            required: requiredToPublish,
            greaterThanStart: helpers.withMessage(
              "Availability end date should be after the start date",
              function (value, siblings) {
                if (value && siblings.start_date) {
                  const startDate = Date.parse(siblings.start_date);
                  const endDate = Date.parse(value);
                  if (startDate <= endDate) {
                    return true;
                  }
                }
                return false;
              }
            ),
          },
        }),
      },
      terms_available: {
        minLengthValue: helpers.withMessage(
          "Terms are Required to Publish",
          function (value) {
            return (
              this.getCurrentHousingStatus !== "Active" ||
              (value && value.length)
            );
          }
        ),
      },
    },
    occupants: {
      required: helpers.withMessage(
        "Occupants are Required to save",
        function () {
          return true;
        }
      ),
    },
  },
};
</script>
<style scoped>
.forced-white-background :deep(input[type="text"]:focus) {
  background: white;
}
.custom-date-picker :deep(.dayContainer.flatpickr-day.selected) {
  background: #2f6095;
  border-color: #2f6095;
}
.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
</style>
<style>
.flatpickr-day.selected {
  background: #2f6095 !important;
  border-color: #2f6095 !important;
}
</style>
