<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["clearFilters"]);

const clearFilters = () => {
  emit("clearFilters");
};
</script>

<template>
  <div class="flex flex-wrap gap-4 bg-indigo-400/60 rounded w-full px-5 py-4">
    <slot name="filters"></slot>
  </div>
  <div class="w-full text-right mr-1">
    <span
      class="uppercase leading-8 tracking-widest font-light text-xs text-university-secondary/70 hover:opacity-80 cursor-pointer"
      @click="clearFilters"
      >reset filters</span
    >
  </div>
</template>
