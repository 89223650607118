<template>
  <div
    class="flex w-100 items-center justify-between mb-6"
    role="navigation"
    data-cy="application-progress-bar"
  >
    <template v-for="(step, index) in filteredForms" :key="step.name">
      <step-complete
        v-if="stepIsComplete(step)"
        :name="getStepName(step.name)"
        :step-number="index"
        :current-step-number="currentStep"
        :allow-back-nav="applicationStepTracker"
        @go-to-step="(step) => $emit('go-to-step', step)"
      />
      <step-current
        v-else-if="stepIsCurrent(step)"
        :name="getStepName(step.name)"
      />
      <step-pending
        v-else
        :name="getStepName(step.name)"
        :step-number="index + 1 + ''"
      />
      <template v-if="index < filteredForms.length - 1">
        <step-divider v-if="index >= currentStep" dashed />
        <step-divider v-if="index < currentStep" />
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    forms: {
      type: Array,
      default: () => [],
    },
    applicationStepTracker: {
      type: Boolean,
      default: true,
    },
    activeStep: {
      type: Number,
      default: 0,
    },
    applicationSubmissions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["go-to-step"],
  data() {
    return {};
  },
  computed: {
    ...mapState(["isInternship", "universityName", "programSelected"]),
    filteredForms() {
      return this.forms.filter((item) => {
        return !item.hide;
      });
    },
    slug() {
      return this.$route.params.slug;
    },
    currentStep() {
      if (this.applicationStepTracker) {
        let form = this.forms.find(
          (form) => form.slug === this.$route.params.slug
        );
        return parseInt(
          Object.keys(this.forms).find((key) => this.forms[key] === form)
        );
      } else {
        return this.activeStep;
      }
    },
  },
  methods: {
    stepIsCurrent(form) {
      return form.slug === this.$route?.params?.slug;
    },
    stepIsComplete(form) {
      return this.applicationSubmissions.some((submission) => {
        return submission.form === form.id && submission.state === "submitted";
      });
    },
    getStepName(name) {
      if (name === "University Information" && this.isInternship) {
        return "Internship Information";
      } else if (
        name === "University Information" &&
        this.programSelected &&
        this.universityName &&
        this.universityName.length > 1
      ) {
        return this.universityName;
      } else {
        return name;
      }
    },
  },
};
</script>
