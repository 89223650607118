<script setup>
import { useVModel } from "@vueuse/core";
import LabeledEditableField from "@/components/shared/LabeledEditableField.vue";
import ErrorMessage from "@/components/shared/ErrorMessage.vue";
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  labelName: {
    type: String,
    default: "",
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 250,
  },
  vuelidateInstance: {
    type: Object,
    default: () => {},
  },
  dataTestId: {
    type: String,
    default: "labeledEditableTextAreaId",
  },
});
const emit = defineEmits(["update:modelValue"]);
const fieldValue = useVModel(props, "modelValue", emit);
</script>

<template>
  <LabeledEditableField
    v-model="fieldValue"
    :label-name="labelName"
    :vuelidate-instance="vuelidateInstance"
    :is-editing="isEditing"
  >
    <template #field>
      <div class="w-[70%] flex-4">
        <textarea
          v-model="fieldValue"
          :maxlength="maxLength"
          class="w-full h-60 resize-none bg-white border border-indigo-base px-4 py-6 text-indigo-base focus:text-indigo-base placeholder-indigo-base placeholder-opacity-50 outline-none focus:ring-0"
          :data-testid="labeledEditableTextAreaId"
        />
        <span class="grid grid-cols-2">
          <span
            class="col-start-2 text-sm text-right font-semibold text-gray-600"
            >{{ fieldValue?.length || 0 }}/{{ maxLength }}</span
          >
        </span>
        <ErrorMessage :vuelidate-instance="vuelidateInstance" />
      </div>
    </template>
  </LabeledEditableField>
</template>
