<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';

export default {
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    },
  },
  computed: {
    searchIsLoading() {
      return this?.state?.instantSearchInstance?.status === "loading" ||
        this?.state?.instantSearchInstance?.status === "stalled"
    },
    searchIsError() {
      return this?.state?.instantSearchInstance?.status === "error";
    }
  }
};
</script>

<script setup>
import NoResultsPage from "@/components/NoResultsPage.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import ClassesCard from "../ResultCards/ClassesCard";
import { vElementVisibility } from '@vueuse/components';
</script>

<template>
  <div v-if="state">
    <div v-if="searchIsLoading && !state.hits.length > 0">
      <Spinner class="relative h-50-screen" />
    </div>
    <div
      v-if="state.hits.length > 0"
      class="grid grid-cols-1 gap-6 px-5 lg:px-10 pb-18"
    >
      <ClassesCard
        v-for="(hit, index) in state.hits"
        :key="hit?.objectID"
        :course="hit"
      />
      <div v-element-visibility="[visibilityChanged]" />
    </div>
    <Spinner v-if="searchIsLoading && state.hits.length > 0" outer-classes="" />
    <div v-else-if="!searchIsLoading && !state.hits.length > 0">
      <NoResultsPage margin-top="mt-5" />
    </div>
  </div>
</template>