<script setup>
import { ref, computed, reactive, defineProps } from "vue";
import { useStore } from "vuex";
import { uuid } from "vue-uuid";
import { debounce, map, cloneDeep, isEmpty, some } from "lodash";
import { watchDebounced } from "@vueuse/core";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import errorPage from "../../errorPage.vue"
import {
  useGetProfiles,
  useGetCityOptions,
  useGetEntities,
  useGetSingleProgram,
  useGetRequiredMajorOptions,
  useGetHostInstitutionOptions,
  useGetProgramSessionsOptions,
  useAcademicYearFilter,
  debounceSearchWrapper,
} from "@/components/program-manager/sessions/composable";
import BreadCrumb from "@/components/shared/BreadCrumb.vue";
import BasicCheckbox from "@/components/shared/input/BasicCheckbox.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import Magnifier from "@/components/shared/icons/Magnifier";
import ErrorMessage from "@/components/shared/ErrorMessage.vue";
import RequiredToSavePublish from "../housing/components/RequiredToSavePublish";
import WYSIWYG from "@/components/forms/SharedComponents/WYSIWYG/WYSIWYG.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import Tooltip from "@/components/forms/SharedComponents/Tooltip.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";
import CloudinaryButton from "@/components/CloudinaryUploadWidget";
import CopyIcon from "@images/copy-icon.svg";
import ImgPlaceholder from "@images/img-placeholder.png";
import SessionPlaceholder from "../sessions/components/SessionPlaceholder";
import BasicTextInput from "@/components/shared/input/BasicTextInput.vue";
import CheckboxSelect from "@/components/shared/select/CheckboxSelect.vue";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";
import MediaPreview from "../../modals/MediaPreview.vue";
import { useToast } from "vue-toast-notification";
import {
  getProfileLabel,
  getCityCountryOptionLabel,
} from "@/components/program-manager/sessions/utils";
import {
  convertProgram,
  convertProgramBeforeSave,
  getSelectedProgramTypes,
  statusesIds,
  programStatuses,
  minimalProgram,
  setTestimonialProgramId,
} from "@/components/program-manager/create-edit/utils";
import {
  proficiencyLevelsOptions,
  // requiredLanguagesOptions,
  minYearInSchoolOptions,
} from "@/components/program-manager/sessions/constants";
import useVuelidate from "@vuelidate/core";
import NavigateAwayWarning from "../housing/components/NavigateAwayWarning";
import TestimonialSection from "./components/TestimonialSection.vue";
import programsPageService from "@/services/programsPage";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { ERROR_TIMEOUT } from "@/constants.js";
import {
  buildStringDateRangeSessionTravel,
  parseSessionEndDate,
  parseSessionStartDate,
} from "@/mixins/helpers";
import BaseToggle from "../../forms/SharedComponents/BaseToggle.vue";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import { entityTypes } from "@/components/program-manager/sessions/constants";
import { PERMISSIONS } from "@/constants";
import { getEntityById } from "@/services/utils.js";

const store = useStore();
const router = useRouter();
const toast = useToast();

const props = defineProps({
  itemId: {
    type: String,
    required: false,
    default: undefined,
  },
});

const routeProgramId = ref(props.itemId);
const programId = routeProgramId.value || uuid.v4();
const currentMode = ref(routeProgramId.value ? "editing" : "creating");

const ownerEntityId = computed(() => {
  const entities = store.getters.getEntityIds;
  return entities?.map((id) => id)[0];
});

const baseUrl = `${window.location.protocol}//${window.location.host}/program-manager/programs`;

const autoSelect = ref(null);
const noDesigTerms = ref(null);
const noDesigLocation = ref(null);
const noEligibility = ref(null);

const {
  options: academicYearOptions,
  reduceAcademicYearOption,
} = useAcademicYearFilter();

const link = ref("");
const programs_media_path = "programLead";
let showPreview = ref(false);

const warningModalError = ref("");
const warningModalValid = ref(undefined);
const warningModalOpen = ref(false);
const navigateTo = ref(undefined);
const saveSuccessful = ref(false);
const moreInfo = ref(false);
const contactInfo = ref(false);
const progPrivate = ref(false);

const setPreview = (value) => {
  showPreview.value = value;
};

store.dispatch("programManager/fetchTerms");

const termsOptions = computed(
  () => store.getters["programManager/getTermsData"]
);

const profileInfo = computed(() => {
  return store.getters["getProfileData"];
});

const permissions = computed(() => store.getters.getPermissions);

const isAPIEmployee = computed(() => {
  return permissions.value.includes(PERMISSIONS.API_EMPLOYEE);
});

const hasProgramId = computed(() => {
  return programPage.value.id || null;
});

const isEditing = computed(() => {
  return currentMode.value === "editing" || null;
});

const programPage = ref({
  id: programId || undefined,
  description: "",
  name: "",
  url: "http://apiabroad.com/apiconnect/programs/",
  lead_media: [],
  program_page_testimonials: [],
  // info_session_event_id: "",
  status_id: 1,
  owner_entity_id: null,
  filters: {
    // required_languages: [],
    // area_studies: [],
    academic_year: [],
    profiles: [],
    program_session_type: [],
    min_proficiency_level_ids: [],
    education_level_minimum_age_rule: "",
    minimum_gpa_requirement: null,
    city: [],
    terms: [],
    host_institution: [],
  },
});
const loadedAndConverted = ref(false);

const {
  isLoading: programLoading,
  execute: fetchProgram,
} = useGetSingleProgram(programId, false);

const {
  execute: executeFetchProfiles,
  isLoading: profileLoading,
  state: profileOptions,
} = useGetProfiles(
  { immediate: false, throwError: false, resetOnExecute: true },
  { limit: 10, skip: 0 }
);

const {
  execute: executeFetchCityOptions,
  isLoading: cityLoading,
  state: cityOptions,
} = useGetCityOptions(
  { immediate: false, throwError: true, resetOnExecute: true },
  { city_ascii: "aac", in_use: true, ignore_session_status: true }
);

const {
  execute: executeFetchRequiredMajorOptions,
  isLoading: requiredMajorOptionsLoading,
  state: requiredMajorOptions,
} = useGetRequiredMajorOptions();

const {
  isLoading: hostInstitutionLoading,
  execute: executeFetchHostInstitution,
  state: hostInstitutionOptions,
} = useGetHostInstitutionOptions(false);

const {
  execute: executeFetchProgramSessions,
  isLoading: programSessionsLoading,
  state: programSessionsOptions,
} = useGetProgramSessionsOptions(
  { immediate: false, resetOnExecute: true, throwError: true },
  {}
);

const fetchProfileOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    loading(true);
    try {
      await executeFetchProfiles(0, {
        extraParams: { full_names: [search] },
        cancel: true,
      });
      loading(false);
    } catch (err) {
      loading(false);
    }
  }
}, 500);

const fetchCityOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    loading(true);
    try {
      await executeFetchCityOptions(0, { city_ascii: search, cancel: true });
      loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        loading(false);
      }
    }
  }
}, 500);

const {
  execute: executeGetEntities,
  state: entitiesOptions,
  isLoading: entitiesOptionsLoading,
} = useGetEntities(
  { immediate: true, throwError: false, resetOnExecute: true },
  {
    extraParams: {
      account_types: [ entityTypes.home_institution, entityTypes.host_institution ],
    },
  }
);

const fetchEntitiesOptions = debounceSearchWrapper(executeGetEntities, 250, {
  extraParams: {
    account_types: [ entityTypes.home_institution, entityTypes.host_institution ],
  },
});

const selectedEntity = ref(null);

const programPageEntityId = computed(() => {
  return isAPIEmployee &&
    selectedEntity.value?.id &&
    isFeatureTenantLibraries
    ? selectedEntity.value?.id
    : ownerEntityId.value;
});

const fetchCipcodesOptions = debounceSearchWrapper(
  executeFetchRequiredMajorOptions,
  500
);

const fetchHostInstitutionsOptions = debounceSearchWrapper(
  executeFetchHostInstitution,
  500
);

const reducedAcademicYearsFilters = computed(() =>
  programPage.value.filters?.academic_year?.map(
    (academicYear) => reduceAcademicYearOption(academicYear) || []
  )
);

const reducedCityFilters = computed(() =>
  map(programPage.value.filters?.city || [], "id")
);

const reducedProfileFilters = computed(() =>
  map(programPage.value.filters?.profiles || [], "id")
);

const reducedHostInstitutionFilters = computed(() =>
  map(programPage.value.filters?.host_institution || [], "id")
);

const reducedProgramTypesFilters = computed(
  () =>
    getSelectedProgramTypes(programPage.value.filters?.program_session_type) ||
    []
);

const filterSessions = async () => {
  try {
    await executeFetchProgramSessions(0, {
      limit: 50,
      extraParams: {
        ...programPage.value.filters,
        academic_year: reducedAcademicYearsFilters.value,
        profiles: reducedProfileFilters.value,
        city: reducedCityFilters.value,
        host_institution: reducedHostInstitutionFilters.value,
        program_session_type: reducedProgramTypesFilters.value,
        include: ["name", "session_travel_details"],
        owner_entity_id: programPageEntityId.value,
        all_available_sessions: true,
        is_approved_for_subdomain: "true",
      },
      cancel: true,
    });

    const incomingSessions = programSessionsOptions.value.items
      .filter((session) => !selectedSessionIds.value.includes(session.id))
      .map((session) => ({ ...session, selected: false }));

    programPage.value.sessions = [
      ...selectedSessions.value,
      ...incomingSessions,
    ];
  } catch (error) {
    if (error?.wasCancelled) return;
    let message =
      "An error occurred when trying to get sessions, please try again";
    if (error?.code === 413) {
      message = "Try selecting more filter options such as terms";
    }

    toast.open({
      message,
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  }
};

const initFiltersWatcher = () => {
  watchDebounced(programPage.value.filters, () => filterSessions(), {
    immediate: true,
    debounce: 500,
    maxWait: 1000,
  });
};

const loadProgramData = async (programItems) => {
  try {
    await convertProgram(programPage, programItems);
    initFiltersWatcher();
  } catch {
    loadedAndConverted.value = true;
    toast.open({
      message: "Error loading Program data. Please, try it later",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  } finally {

    if(isFeatureTenantLibraries) {
      if(ownerEntityId.value) {
        const entity = currentMode.value === "creating"
             ? ownerEntityId.value
             : programPage.value.owner_entity_id;

         selectedEntity.value = await getEntityById(entity);
      } else {
        toast.open({
          message: `No entity id associated with user`,
          type: "error",
          position: "bottom",
          duration: ERROR_TIMEOUT,
        });
      }
    }

    loadedAndConverted.value = true;
    v$.value.$reset();
  }
};

const unableToViewProgram = ref(false);

if (programId) {
    fetchProgram().then((response) => {
      unableToViewProgram.value = false;
      loadProgramData(response?.items || {});
    }).catch((error) => {
      if (error.code == 403) {
        unableToViewProgram.value = true;
      } else {
        unableToViewProgram.value = false;
        loadProgramData({});
      };
    });
} else {
  loadProgramData(cloneDeep(minimalProgram));
}

const selectedSessions = computed(
  () => programPage.value.sessions.filter((session) => session.selected) || []
);

const selectedSessionIds = computed(
  () => selectedSessions.value.map((session) => session.id) || []
);

const copyToClickboard = async () => {
  if (!link.value) return;

  try {
    await navigator.clipboard.writeText(programPage.value.url);
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

const programLeaderMultimedia = computed(
  () =>
    programPage.value.lead_media?.filter((el) => {
      try {
        return new URL(el).pathname.indexOf(programs_media_path) > -1;
      } catch {
        return false;
      }
    }) || []
);

const updateProgramsMultimediaOrder = (value = []) => {
  programPage.value.lead_media = value || [];
};

const setProgramsMultimedia = (payload) => {
  programPage.value.lead_media = [
    payload.secure_url,
    ...programPage.value.lead_media,
  ];
};

const removeProgramsMultimediaItem = (element) => {
  programPage.value.lead_media =
    programPage.value.lead_media.filter((item) => item !== element) || [];
};

const selectSession = (index) => {
  programPage.value.sessions[index].selected = true;
};

const unselectSession = (index) => {
  programPage.value.sessions[index].selected = false;
};

const saveProgram = async () => {
  v$.value.$reset();
  const isValid = await v$.value.$validate();
  if (isValid) {
    programPage.value.owner_entity_id = programPageEntityId.value;
    try {
      setTestimonialProgramId(programPage);
      let updatedProgramsPage;
      const program = convertProgramBeforeSave(programPage);

      if (currentMode.value === "creating") {
        updatedProgramsPage = (
          await programsPageService.createProgramPage(program)
        ).data.data.items;

        let newEventId = updatedProgramsPage.id;

        window.location = `${baseUrl}/${newEventId}`;

        if (updatedProgramsPage) {
          saveSuccessful.value = true;
          programPage.value = cloneDeep(convertProgram(updatedProgramsPage));
          state = reactive({
            programsPage: updatedProgramsPage,
          });
        } else {
          saveSuccessful.value = false;
        }
      } else if (currentMode.value === "editing") {
        updatedProgramsPage = await programsPageService.updateProgramPage(
          programId,
          program
        );
      }
      if (updatedProgramsPage) {
        toast.open({
          message: "Program Page Saved Successfully",
          type: "success",
          position: "bottom",
          duration: 5000,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
};

const panelExpanded = ref(true);
const setExpanded = (isExpanded) => {
  panelExpanded.value = isExpanded;
};

onBeforeRouteLeave(async (to, from, next) => {
  if (v$.value.$anyDirty && !warningModalOpen.value && !navigateTo.value) {
    warningModalError.value = "";
    navigateTo.value = to;
    warningModalValid.value = await v$.value.$validate();
    warningModalOpen.value = true;
    next(false);
  } else {
    next();
  }
});

const isStatusAllowed = (status) => {
  return true;
};

const onWarningClose = () => {
  warningModalError.value = "";
  navigateTo.value = undefined;
  warningModalValid.value = undefined;
  warningModalOpen.value = false;
};

const onWarningContinue = () => {
  warningModalError.value = "";
  warningModalValid.value = undefined;
  warningModalOpen.value = false;
  router.push(navigateTo.value);
};

const onWarningSave = async () => {
  const allValid = await v$.value.$validate();
  if (allValid) {
    try {
      await saveProgram();
      saveSuccessful.value = true;
    } catch {
      warningModalError.value = "Program  failed to save.";
    }
    if (saveSuccessful.value) {
      router.push(navigateTo.value);
    }
  } else {
    warningModalError.value = "Please fix errors before saving";
  }
  await saveProgram();
};

const changeProgramStatus = async () => {
  v$.value.$reset();
  await v$.value.$validate();
};

const requiredToPublish = helpers.withMessage(
  "This field is required to Publish",
  requiredIf(function () {
    return programPage?.value?.status_id === statusesIds.ACTIVE;
  })
);

const atLeastOneFilter = helpers.withMessage(
  "At least one filter is required to Publish.",
  () => {
    const filters = cloneDeep(programPage.value.filters);
    delete filters.program_session_type;

    return (
      programPage?.value?.status_id !== statusesIds.ACTIVE ||
      !some(filters, isEmpty) ||
      some(programPage.value.filters.program_session_type, ["checked", true])
    );
  }
);

const rules = computed(() => ({
  programPage: {
    name: { required },
    status_id: { required },
    lead_media: {
      required: requiredToPublish,
    },
    filters: {
      required: requiredToPublish,
      atLeastOneFilter,
    },
  },
}));

let state = reactive({
  programPage,
});

const v$ = useVuelidate(rules, state, {
  $registerAs: "CreateEditEvents",
  $lazy: true,
});
const showMarketplaceToggle = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["tenant-libraries"];
});
const sessionDetails = (session) => {
  const arrivalDate = parseSessionStartDate(
    session.session_travel_details
  )?.getFullYear();
  const departureDate = parseSessionEndDate(
    session.session_travel_details
  )?.getFullYear();

  if (session.terms?.[0]?.name) {
    if (arrivalDate === departureDate && arrivalDate) {
      return `${session.terms?.[0].name}, ${arrivalDate}`;
    } else if (arrivalDate !== departureDate && arrivalDate) {
      return `${session.terms?.[0].name}, ${arrivalDate} - ${departureDate}`;
    }
  } else if (!session.terms?.[0]?.name) {
    if (arrivalDate === departureDate && arrivalDate) {
      return `${arrivalDate}`;
    } else if (arrivalDate !== departureDate && arrivalDate) {
      return `${arrivalDate} - ${departureDate}`;
    }
  }
};

const disablePreviewButton = computed(
  () => !(programPage?.value?.sessions?.length > 0)
);

const showPreviewProgramDetail = () => {
  return {
    name: "program-detail-view",
    params: {
      programId: programId,
    },
    query: { "session-all": true },
  };
};

const isFeatureTenantLibraries = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["tenant-libraries"];
});

</script>

<template>
  <div>
    <div class="h-full" v-if="!unableToViewProgram">
      <div v-if="!programLoading && loadedAndConverted" class="relative">
        <div class="p-4">
          <div class="flex justify-between mb-1">
            <BreadCrumb />
          </div>
          <div class="px-6 mt-6">
            <div class="pb-5 sm:flex sm:items-center sm:justify-between">
              <span class="text-red-100 pr-4 align-top">*</span>
              <div class="flex flex-grow-1 items-center w-full">
                <input
                  id="name"
                  v-model="programPage.name"
                  type="text"
                  :class="[
                    {
                      'bg-error-100': v$.programPage?.name?.$errors?.length,
                    },
                    'w-full text-2xl font-medium leading-6 text-gray-900 bg-blue-100 focus:outline-none min-w-[300px]',
                  ]"
                  @blur="v$.programPage.name?.$touch"
                />
                <img
                  v-if="programPage.marketplace && showMarketplaceToggle"
                  src="/images/icon-marketplace.svg"
                />
              </div>
              <div class="flex items-center space-x-4 mt-3 sm:mt-0 sm:ml-4">
                  <template v-if="isFeatureTenantLibraries">
                    <label v-if="hasProgramId && isEditing" class="block text-sm font-semibold text-gray-700" > Owner </label>
                    <label v-if="hasProgramId && isEditing && !isAPIEmployee">&nbsp;{{ selectedEntity?.name }}</label>
                      <div v-if="hasProgramId && isEditing && isAPIEmployee" class="mt-1 block w-96">
                        <SearchableSelect
                          v-model="selectedEntity"
                          :clear-search-on-blur="() => false"
                          :deselect-from-dropdown="true"
                          :loading="entitiesOptionsLoading"
                          :options="entitiesOptions.items"
                          placeholder="Select Owner"
                          label="name"
                          @search="fetchEntitiesOptions"
                          >
                        </SearchableSelect>
                      </div>
                  </template>
                <label class="block text-sm font-semibold text-gray-700"
                  ><span class="text-red-100 pr-2 align-sub">*</span>Status</label
                >
                <div class="mt-1 block w-48">
                  <v-select-caret
                    v-model="programPage.status_id"
                    :options="programStatuses"
                    :selectable="isStatusAllowed"
                    :reduce="(val) => val.id"
                    label="name"
                    :vuelidate-instance="programPage.status_id"
                    :clearable="false"
                    @update:model-value="changeProgramStatus"
                  />
                </div>
                <router-link
                  :to="showPreviewProgramDetail"
                  target="_blank"
                  :disabled="disablePreviewButton"
                >
                  <button
                    type="button"
                    class="inline-flex items-center rounded-md border-teal-900 border-solid border bg-teal-900 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-white hover:text-teal-900"
                  >
                    Preview
                  </button>
                </router-link>
                <button
                  type="button"
                  class="inline-flex items-center rounded-md border-teal-900 border-solid border bg-teal-900 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-white hover:text-teal-900"
                  @click="saveProgram"
                >
                  Save
                </button>
              </div>
            </div>
            <div class="flex justify-between items-start flex-wrap">
              <h1 class="text-xl font-semibold leading-6 text-gray-900" data-testid="create-edit-program-page-program-information">
                Program Information
              </h1>
              <RequiredToSavePublish />
            </div>
          </div>
          <div>
            <div
              class="border-b border-gray-200 px-6 grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3"
            >
              <div class="lg:col-start-3 lg:row-end-1">
                <div v-if="showMarketplaceToggle" class="flex">
                  <BaseToggle
                    v-model="programPage.marketplace"
                    label-after="true"
                    width="w-10"
                  />
                  <label class="block text-sm font-semibold text-gray-700 ml-2"
                    >Available in Marketplace</label
                  >
                  <img
                    v-if="programPage.marketplace"
                    src="/images/icon-marketplace.svg"
                    class="mb-6 ml-2"
                  />
                </div>
                <div class="w-full">
                  <label class="block text-sm font-semibold"
                    >Direct Program Link</label
                  >
                  <div class="relative mt-2">
                    <input
                      ref="link"
                      v-model="programPage.url"
                      type="text"
                      name="link"
                      class="block w-full py-1.5 pr-10 text-lg font-light leading-6 text-blue-900 bg-blue-100 sm:text-sm sm:leading-6 focus:outline-none"
                    />
                    <div
                      class="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      <button @click="copyToClickboard">
                        <img class="w-5 h-5" loading="lazy" :src="CopyIcon" />
                      </button>
                    </div>
                  </div>
                </div>
                <TestimonialSection v-model="programPage" class="mt-6 border-0" />
                <FormPanel
                  :id="'infoSession'"
                  :title="'Info Session'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="flex">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="infoSession"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >Add info session to program page
                      </label>
                    </div>
                  </template>
                </FormPanel>
              </div>
              <div class="sm:mx-0 lg:col-span-2 lg:row-span-2 lg:row-end-2">
                <div class="mb-4">
                  <label class="mb-2 block text-sm font-semibold text-gray-700"
                    >Program Description
                  </label>
                  <WYSIWYG
                    v-model="programPage.description"
                    :editable="true"
                    :character-limit="500"
                  />
                </div>
                <FormPanel
                  id="leadMultimedia"
                  required="**"
                  title="Lead Multimedia"
                  class="mt-6 border-b-0 mb-6"
                  :class="[
                    {
                      'bg-error-100': v$.programPage?.lead_media?.$errors?.length,
                    },
                  ]"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="mb-6 flex sm:ml-3">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="autoSelect"
                          v-model="autoSelect"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >Auto select cover image from location
                      </label>
                    </div>
                    <div v-show="!autoSelect">
                      <div
                        class="mb-6 flex items-center space-x-4 mt-3 sm:mt-0 sm:ml-3"
                      >
                        <button
                          type="button"
                          class="inline-flex items-center rounded-md border-teal-900 border-solid border bg-teal-900 px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-teal-900"
                        >
                          Upload from library
                        </button>
                        <CloudinaryButton
                          :public-id-prefix="programId"
                          :folder="programs_media_path"
                          :placeholder="'Upload from computer'"
                          @uploaded-media-data="setProgramsMultimedia"
                        />
                      </div>
                      <VDraggable
                        :model-value="programLeaderMultimedia"
                        class="flex flex-wrap cursor-move"
                        item-key="index"
                        ghost-class="opacity-50"
                        @update:modelValue="updateProgramsMultimediaOrder"
                      >
                        <template #item="{ element: src }">
                          <div
                            class="w-24 h-24 mr-10 relative flex-none mt-10 hover:shadow-2xl hover:scale-110 duration-300"
                          >
                            <button
                              class="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full border border-gray-400 hover:text-red-100 hover:scale-125 duration-150"
                              @click="removeProgramsMultimediaItem(src)"
                            >
                              <TrashIcon class="w-5 h-5 mx-auto" />
                            </button>
                            <img
                              :src="src"
                              alt="Program Media"
                              class="h-full w-full"
                            />
                          </div>
                        </template>
                      </VDraggable>
                      <img
                        v-show="!programLeaderMultimedia"
                        :src="ImgPlaceholder"
                        class="mb-6 rounded w-24 h-24 p-4 border-2 border-dashed border-gray-300 sm:ml-3"
                      />
                    </div>
                    <MediaPreview
                      :is-open="showPreview"
                      @update:is-open="showPreview = $event"
                    />
                    <a
                      class="text-xs text-university-secondary sm:ml-3 tracking-wide font-semibold uppercase"
                      href="#"
                      tabindex="0"
                      @click.prevent="setPreview(true)"
                      >preview</a
                    >
                  </template>
                </FormPanel>
              </div>
            </div>
            <div
              class="border-b border-gray-200 px-6 grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3"
            >
              <div class="mt-4 lg:col-start-3 lg:row-end-1">
                <FormPanel
                  :id="'progSessions'"
                  :title="'MATCHING PROGRAM SESSIONS'"
                  :initially-expanded="panelExpanded"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                  @panelExpandedEvent="setExpanded"
                >
                  <template #content>
                    <label class="mb-4 block text-lg font-semibold"
                      >Total
                      {{ programPage.sessions?.length || 0 }}
                      program sessions</label
                    >
                    <div class="mb-4">
                      <p
                        class="mb-2 block text-sm font-thin italic text-gray-700"
                      >
                        Check any program sessions that should be mapped to this
                        program page.
                      </p>
                      <Tooltip
                        tooltip="If a program session is updated and no longer matches the program filter criteria,
                      the system will uncheck the program session to remove the mapping.  The session will still
                      appear in the Matching Program Sessions list for reference."
                      />
                    </div>
                    <div
                      v-if="!programSessionsLoading"
                      class="overflow-auto h-[40rem]"
                    >
                      <SessionPlaceholder
                        v-for="(session, index) in programPage.sessions"
                        :key="index"
                        :name="session.name"
                        :date="
                          buildStringDateRangeSessionTravel(
                            session?.session_travel_details,
                            (monthFormat = '2-digit')
                          )
                        "
                        :session-id="session.id"
                        :availability="sessionDetails(session)"
                        :checked="session.selected"
                        @select-session="selectSession(index)"
                        @unselect-session="unselectSession(index)"
                      />
                    </div>
                    <div v-else>
                      <Spinner outer-classes="bg-transparent" />
                    </div>
                  </template>
                </FormPanel>
                <div v-show="!panelExpanded">
                  <label class="mb-4 block text-lg font-semibold"
                    >Total 0 program sessions</label
                  >
                  <div class="mb-4">
                    <p class="mb-2 block text-sm font-thin italic text-gray-700">
                      There are no matching results.
                    </p>
                    <Tooltip
                      tooltip="If a program session is updated and no longer matches the program filter criteria,
                      the system will uncheck the program session to remove the mapping.  The session will still
                      appear in the Matching Program Sessions list for reference."
                    />
                  </div>
                </div>
              </div>
              <div class="mt-4 sm:mx-0 lg:col-span-2 lg:row-span-2 lg:row-end-2">
                <h1 class="text-xl font-semibold leading-6 text-gray-900">
                  <span class="text-red-100 pr-2">**</span> Program Session
                  Filters
                </h1>
                <div class="mb-4 lg:row-span-2">
                  <p class="mb-2 block text-sm font-thin italic text-gray-700">
                    Set the filter criteria for program sessions that should map
                    to this program. Matching program sessions will be listed on
                    the right side of the page.
                  </p>
                </div>
                <ErrorMessage
                  error-classes="bg-error-100"
                  :vuelidate-instance="v$.programPage.filters"
                />
                <FormPanel
                  :id="'progLeaders'"
                  :title="'Program Leaders'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="mb-4 sm:ml-3 pr-3">
                      <CheckboxSelect
                        v-model="programPage.filters.profiles"
                        outer-classes="col-span-2 mt-4"
                        placeholder="Type to look program leaders"
                        :multiple="true"
                        :reduce="
                          (val) => ({
                            id: val.id,
                            preferred_first_name: val.preferred_first_name,
                            legal_first_name: val.legal_first_name,
                            last_name: val.last_name,
                          })
                        "
                        :deselect-from-dropdown="true"
                        :close-on-select="false"
                        :get-option-label="getProfileLabel"
                        field-label="Search and add program leaders"
                        :components="{ OpenIndicator: Magnifier }"
                        :options="profileOptions?.items || []"
                        :loading="profileLoading"
                        option-key="id"
                        :clear-search-on-blur="() => false"
                        @search="fetchProfileOptions"
                      />
                    </div>
                  </template>
                </FormPanel>
                <FormPanel
                  :id="'progTypes'"
                  :title="'Program Types'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <label label class="text-sm font-semibold text-gray-700"
                      >Select all that applies</label
                    >
                    <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3">
                      <div
                        v-for="(programType, index) in programPage?.filters
                          ?.program_session_type || []"
                        :key="index"
                        class="mb-4 flex items-center"
                      >
                        <BasicCheckbox
                          v-model="
                            programPage.filters.program_session_type[index]
                              .checked
                          "
                          :field-label="programType.name"
                          class="my-auto"
                        />
                      </div>
                    </div>
                  </template>
                </FormPanel>
                <FormPanel
                  :id="'eligibility'"
                  :title="'Eligibility'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="flex sm:ml-3">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="noEligibility"
                          v-model="noEligibility"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >No designated eligibility criteria
                      </label>
                    </div>
                    <div v-show="!noEligibility">
                      <div class="mt-6 grid xs:grid-cols-2 sm:grid-cols-2 gap-8">
                        <!-- <CheckboxSelect
                          v-model="programPage.filters.required_languages"
                          :reduce="(val) => val.id"
                          :clear-search-on-blur="() => false"
                          :close-on-select="false"
                          :components="{ OpenIndicator: Magnifier }"
                          :deselect-from-dropdown="true"
                          field-label="Language(s) Required"
                          label="value"
                          :multiple="true"
                          :options="requiredLanguagesOptions"
                          outer-classes="w-full my-auto"
                          placeholder="Select the languages applicable"
                          scroll-input="auto"
                          input-max-height="29px"
                        /> -->
                        <CheckboxSelect
                          v-model="programPage.filters.min_proficiency_level_ids"
                          :reduce="(val) => val.id"
                          :clear-search-on-blur="() => false"
                          :close-on-select="false"
                          :components="{ OpenIndicator: Magnifier }"
                          :deselect-from-dropdown="true"
                          field-label="Language Level Required"
                          label="value"
                          :multiple="true"
                          :options="proficiencyLevelsOptions"
                          outer-classes="w-full my-auto"
                          placeholder="Select the languages level applicable"
                          scroll-input="auto"
                          input-max-height="29px"
                        />
                        <BasicTextInput
                          v-model="programPage.filters.minimum_gpa_requirement"
                          field-label="Minimum GPA"
                          type="number"
                          min="1"
                        />
                        <VSelectCaret
                          v-model="
                            programPage.filters.education_level_minimum_age_rule
                          "
                          :options="minYearInSchoolOptions"
                          :reduce="(val) => val.id"
                          label="academic_year"
                          field-label="Minimum year in school"
                        />
                      </div>
                    </div>
                  </template>
                </FormPanel>
                <FormPanel
                  :id="'locations'"
                  :title="'Locations'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="flex sm:ml-3">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="noDesigLocation"
                          v-model="noDesigLocation"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >No designated location
                      </label>
                    </div>
                    <div v-show="!noDesigLocation">
                      <div class="mb-6 mt-6 sm:mt-3">
                        <div class="mb-4 sm:ml-3 pr-3">
                          <CheckboxSelect
                            v-model="programPage.filters.city"
                            :clear-search-on-blur="() => false"
                            :close-on-select="false"
                            :reduce="
                              (val) => ({
                                city_ascii: val.city_ascii,
                                country: val.country,
                                admin_name: val.admin_name,
                                id: val.id,
                              })
                            "
                            :components="{ OpenIndicator: Magnifier }"
                            :deselect-from-dropdown="true"
                            field-label="Program Locations"
                            :get-option-label="getCityCountryOptionLabel"
                            :multiple="true"
                            :filterable="false"
                            :options="cityOptions?.items || []"
                            :loading="cityLoading"
                            outer-classes="w-full my-auto"
                            placeholder="Select the applicable cities"
                            scroll-input="auto"
                            input-max-height="29px"
                            @search="fetchCityOptions"
                          />
                        </div>
                        <div class="mb-4 sm:ml-3 flex items-center">
                          <input
                            id="virtual"
                            name="virtual"
                            type="checkbox"
                            class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                          />
                          <label
                            for="virtual"
                            class="flex ml-3 text-sm font-semibold text-gray-700"
                          >
                            Virtual</label
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </FormPanel>
                <FormPanel
                  :id="'terms'"
                  :title="'Terms'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="flex sm:ml-3">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="noDesigTerms"
                          v-model="noDesigTerms"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >No designated terms and dates
                      </label>
                    </div>
                    <div v-show="!noDesigTerms">
                      <div class="mb-6 mt-6 items-center sm:mt-3 sm:ml-3">
                        <div class="mb-4">
                          <CheckboxSelect
                            v-model="programPage.filters.academic_year"
                            outer-classes="col-span-3"
                            :options="academicYearOptions"
                            :multiple="true"
                            :deselect-from-dropdown="true"
                            :close-on-select="false"
                            placeholder="Select the academic years applicable"
                            label="name"
                            field-label="Academic Years"
                          />
                        </div>
                        <div class="mb-4">
                          <CheckboxSelect
                            v-model="programPage.filters.terms"
                            outer-classes="col-span-3"
                            :reduce="(val) => val.id"
                            :options="termsOptions"
                            :multiple="true"
                            :deselect-from-dropdown="true"
                            :close-on-select="false"
                            placeholder="Select the terms applicable"
                            label="name"
                            field-label="Terms"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </FormPanel>
                <FormPanel
                  :id="'acadLang'"
                  :title="'Academics and Language'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="mb-4 sm:ml-3 pr-3">
                      <!-- <CheckboxSelect
                        v-model="programPage.filters.area_studies"
                        outer-classes="col-span-2 mt-4"
                        label="name"
                        field-label="Areas
                      of Study"
                        :reduce="(val) => val.id"
                        :multiple="true"
                        :close-on-select="false"
                        placeholder="Type to look up areas of study"
                        :components="{
                          OpenIndicator: Magnifier,
                        }"
                        :options="requiredMajorOptions"
                        :loading="requiredMajorOptionsLoading"
                        :deselect-from-dropdown="true"
                        :clear-search-on-blur="() => false"
                        option-classes="block text-sm text-gray-700 capitalize
                      whitespace-pre-line"
                        scroll-input="auto"
                        input-max-height="29px"
                        @search="fetchCipcodesOptions"
                      /> -->
                    </div>
                    <div class="mb-4 sm:ml-3 pr-3">
                      <CheckboxSelect
                        v-model="programPage.filters.host_institution"
                        :reduce="(val) => ({ name: val.name, id: val.id })"
                        outer-classes="col-span-2"
                        placeholder="Type to look up institutions"
                        field-label="Select a Host Institution"
                        label="name"
                        :multiple="true"
                        :close-on-select="false"
                        :components="{
                          OpenIndicator: Magnifier,
                        }"
                        :options="hostInstitutionOptions?.items || []"
                        :loading="hostInstitutionLoading"
                        :deselect-from-dropdown="true"
                        :clear-search-on-blur="() => false"
                        option-classes="block text-sm text-gray-700 capitalize
                        whitespace-pre-line"
                        scroll-input="auto"
                        input-max-height="29px"
                        @search="fetchHostInstitutionsOptions"
                      >
                      </CheckboxSelect>
                    </div>
                    <!-- TODO: Post-MVP filter fields -->
                    <!-- <div class="mb-4 sm:ml-3 pr-3">
                      <label label class="text-sm font-semibold text-gray-700"
                        >Host Institution - select all that applies</label
                      >
                      <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-2">
                        <div class="mb-4 flex items-center">
                          <input
                            id="photos"
                            name="photos"
                            type="checkbox"
                            class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                          />
                          <label
                            for="student"
                            class="flex ml-3 text-sm font-semibold text-gray-700"
                          >
                            Show host institution photos</label
                          >
                        </div>
                        <div class="mb-4 flex items-center">
                          <input
                            id="videos"
                            name="videos"
                            type="checkbox"
                            class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                          />
                          <label
                            for="tourist"
                            class="flex items-center ml-3 text-sm font-semibold text-gray-700"
                            >Show host institution Videos</label
                          >
                        </div>
                        <div class="mb-4 flex items-center">
                          <input
                            id="creditUnits"
                            name="creditUnits"
                            type="checkbox"
                            class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                          />
                          <label
                            for="families"
                            class="flex ml-3 text-sm font-semibold text-gray-700"
                            >Show host institution credit units</label
                          >
                        </div>
                        <div class="mb-4 flex items-center">
                          <input
                            id="languages"
                            name="languages"
                            type="checkbox"
                            class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                          />
                          <label
                            for="languages"
                            class="flex ml-3 text-sm font-semibold text-gray-700"
                            >Show host institution instructional languages</label
                          >
                        </div>
                        <div class="mb-4 flex items-center">
                          <input
                            id="placements"
                            name="placements"
                            type="checkbox"
                            class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                          />
                          <label
                            for="professionals"
                            class="flex ml-3 text-sm font-semibold text-gray-700"
                            >Feature internship placements</label
                          >
                        </div>
                      </div>
                      <div class="mb-4">
                        <label
                          class="mb-2 block text-sm font-semibold text-gray-700"
                          >Host Organization Description
                        </label>
                        <WYSIWYG />
                      </div>
                    </div> -->
                  </template>
                </FormPanel>
                <!-- TODO: Post-MVP filter fields -->
                <!-- <FormPanel
                  :id="'housing'"
                  :title="'Housing'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="flex sm:ml-3">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="noDesigHousing"
                          v-model="noDesigHousing"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >No designated housing
                      </label>
                    </div>
                    <div v-show="!noDesigHousing">
                      <div class="mb-6 mt-6 items-center sm:mt-3 sm:ml-3">
                        <div class="mb-4">
                          <label
                            class="mb-2 block text-sm font-semibold text-gray-700"
                            >What type of housing is available
                          </label>
                          <v-select></v-select>
                        </div>
                        <div class="mb-4">
                          <label
                            class="mb-2 block text-sm font-semibold text-gray-700"
                            >Housing Description
                          </label>
                          <WYSIWYG />
                        </div>
                      </div>
                    </div>
                  </template>
                </FormPanel>
                <FormPanel
                  :id="'csfa'"
                  :title="'Costs, Scholarships and Financial Aid'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="mb-4 sm:ml-3 pr-3">
                      <SearchableSelect
                        :reduce="(val) => val.id"
                        placeholder="Type to look for scholarships"
                        label="scholarships"
                      >
                        <template #fieldLabel>
                          <label class="common-label-text mb-2"
                            >Feature scholarship(s) for this program</label
                          >
                        </template>
                      </SearchableSelect>
                    </div>
                    <div v-show="!autoSelect">
                      <div
                        class="mb-6 flex items-center space-x-4 mt-3 sm:mt-0 sm:ml-3"
                      ></div>
                    </div>
                    <div class="mb-4 flex items-center sm:ml-3">
                      <input
                        id="hideCost"
                        name="hideCost"
                        type="checkbox"
                        class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                      />
                      <label
                        for="hideCost"
                        class="flex ml-3 text-sm font-semibold text-gray-700"
                      >
                        Hide cost information</label
                      >
                    </div>
                    <div class="md:max-w-[300px] ml-3">
                      <label
                        name="price"
                        class="mb-2 block text-sm font-semibold text-gray-700"
                        >Maximum cost</label
                      >
                      <input
                        id="price"
                        type="text"
                        name="price"
                        class="h-10 w-full form-input"
                      />
                    </div>
                  </template>
                </FormPanel> -->
                <FormPanel
                  :id="'visibility'"
                  :title="'Visibility and Settings'"
                  class="mt-6 border-b-0"
                  panel-content-class="text-gray-600 border-gray-200 rounded bg-blue-300 p-4 border"
                >
                  <template #content>
                    <div class="mb-6 flex sm:ml-3">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="moreInfo"
                          v-model="moreInfo"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >Display request more information form
                      </label>
                    </div>
                    <div class="mb-6 flex sm:ml-3">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="contactInfo"
                          v-model="contactInfo"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >Display program contact information
                      </label>
                    </div>
                    <div class="mb-6 flex sm:ml-3">
                      <div
                        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          id="progPrivate"
                          v-model="progPrivate"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                          type="checkbox"
                        />
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300"
                        ></label>
                      </div>
                      <label class="ml-3 block text-sm text-gray-700"
                        >Make program private
                      </label>
                    </div>
                  </template>
                </FormPanel>
              </div>
            </div>
          </div>
        </div>
        <NavigateAwayWarning
          :open="warningModalOpen"
          :housing-name="programPage.name"
          :error-message="warningModalError"
          :housing-valid="warningModalValid"
          @on-cancel="onWarningClose"
          @on-save="onWarningSave"
          @on-continue-without-saving="onWarningContinue"
        />
      </div>
      <Spinner
        v-else
        class="m-auto absolute items-center justify-center bg-blue-100 !z-0"
      />
    </div>
    <errorPage v-else
    />
  </div>
</template>
