<template>
  <form-panel
    id="cities-edit-overview"
    title="Overview"
    panel-content-class="pb-6 mb-6"
    panel-button-text-class="tracking-normal"
  >
    <template #content>
      <div
        class="rounded-sm border border-gray-300 bg-white px-8 py-4 text-base"
      >
        <div
          class="grid-template-custom grid w-full justify-items-center gap-4"
        >
          <div class="w-full">
            <h4 class="font-semibold text-gray-700">City</h4>
            <p class="mt-2" data-cy="city-edit-name-label">
              {{ data?.city || "Not provided" }}
            </p>
          </div>
          <div class="w-full">
            <h4 class="font-semibold text-gray-700">State/Province</h4>
            <p class="mt-2" data-cy="city-population-label">
              {{ data?.admin_name || "Not provided" }}
            </p>
          </div>
          <div class="w-full">
            <h4 class="font-semibold text-gray-700">Country</h4>
            <p class="mt-2" data-cy="city-country-label">
              {{ data?.country?.name || "Not provided" }}
            </p>
          </div>
          <div class="w-full">
            <h4 class="font-semibold text-gray-700">Population</h4>
            <p class="mt-2" data-cy="sidebar-city-population-label">
              {{ data?.population || "Not provided" }}
            </p>
          </div>
          <div class="w-full">
            <h4 class="font-semibold text-gray-700">Lat/Long</h4>
            <p class="mt-2" data-cy="city-population-label">
              {{ convertLatLongDDtoDMS(data?.lat_lng) }}
            </p>
          </div>
        </div>
        <div class="mt-4 w-full">
          <h4 class="font-semibold text-gray-700">
            <span class="pr-1 text-red-100">*</span>Reasons to Experience
          </h4>
          <WYSIWYG
            v-model="data.reason_to_experience"
            data-cy="city-edit-reasons-label"
          />
        </div>
        <div class="mt-4 w-full">
          <h4 class="font-semibold text-gray-700">
            <span class="pr-1 text-red-100">*</span>Tags
          </h4>
          <TagsInput
            v-model="data.tags"
            :options="getFlatCategoryTags"
            label="name"
          />
        </div>
      </div>
    </template>
  </form-panel>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import { convertLatLongDDtoDMS } from "@/components/program-manager/sessions/utils.js";

import formPanel from "@/components/forms/SharedComponents/panel.vue";
import TagsInput from "@/components/shared/TagsInput.vue";
import WYSIWYG from "@/components/forms/SharedComponents/WYSIWYG/WYSIWYG.vue";
import { useVModel } from "@vueuse/core";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);
const data = useVModel(props, "modelValue", emit);

const store = useStore();

store.dispatch("tagManager/fetchTagsData");

const getFlatCategoryTags = computed(
  () => store.getters["tagManager/getFlatCategoryTags"]
);
</script>

<style scoped>
@media screen and (min-width: 900px) {
  .grid-template-custom {
    grid-template-columns: 1fr 1fr minmax(0, 320px);
  }
}
</style>
