import { format as formatDate, isAfter, isEqual, parse } from "date-fns";
import { DURATION_IN_DAYS_THRESHOLD } from "@/constants.js";

export function durationLabel({ startDate, endDate }) {
  const format = "MMMM d, yyyy";
  if (startDate && endDate) {
    const fmtStartDate = formatDate(
      parse(startDate, "yyyy-MM-dd", new Date()),
      format
    );
    const fmtEndDate = formatDate(
      parse(endDate, "yyyy-MM-dd", new Date()),
      format
    );

    return `${fmtStartDate} – ${fmtEndDate}`;
  } else {
    return "";
  }
}

export function getTravelDetails(session) {
  const travelDetails = session?.session_travel_details ?? [];

  const sortedAsc = travelDetails.sort(
    (a, b) =>
      Number(new Date(a.arrival_date)) - Number(new Date(b.arrival_date))
  );
  const arrivalDetails = sortedAsc.length ? sortedAsc[0] : {};

  const sortedDesc = travelDetails.sort(
    (a, b) =>
      Number(new Date(b.departure_date)) - Number(new Date(a.departure_date))
  );
  const departureDetails = sortedDesc.length ? sortedDesc[0] : {};

  return {
    city: arrivalDetails?.arrival_city?.city,
    country: arrivalDetails?.arrival_city?.country.name,
    startDate: arrivalDetails?.arrival_date ?? "",
    endDate: departureDetails?.departure_date ?? "",
  };
}

export function stillValid({ startDate, endDate }) {
  if (
    [null, undefined].includes(startDate) ||
    [null, undefined].includes(endDate)
  )
    return true;
  const now = new Date().getTime();
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  return now >= start && now <= end;
}

export const verifyStartDateLowerThenEndDate = (
  startDate,
  endDate,
  parseDateFormat = "yyyy-MM-dd"
) => {
  if (!startDate && !endDate) {
    return true;
  } else if (!startDate && endDate) {
    return false;
  }
  return (
    !isAfter(
      parse(startDate, parseDateFormat, new Date()),
      parse(endDate, parseDateFormat, new Date())
    ) &&
    !isEqual(
      parse(startDate, parseDateFormat, new Date()),
      parse(endDate, parseDateFormat, new Date())
    )
  );
};

export const formatDuration = (durationInDays) => {
  if (durationInDays === null || !Number.isInteger(Number(durationInDays))) {
    return null;
  }
  if (durationInDays < DURATION_IN_DAYS_THRESHOLD) {
    const days = `day${durationInDays > 1 ? "s" : ""}`;
    return `${durationInDays} ${days}`;
  } else {
    const durationInWeeks = Math.ceil(durationInDays / 7);
    const weeks = `week${durationInWeeks > 1 ? "s" : ""}`; // Pluralize 'Week' if necessary
    return `${durationInWeeks} ${weeks}`;
  }
};

export function convertOffSetFromMinutesToHours(offSetInMinutes) {
  const hour = Math.floor(offSetInMinutes / 60);
  const minutes = Math.abs(offSetInMinutes % 60);
  const plusMinus = hour > 0 ? "+" : "-";
  const hourString =
    Math.abs(hour) < 10 ? `0${Math.abs(hour)}` : `${Math.abs(hour)}`;
  const minutesString = minutes === 0 ? "00" : `${minutes}`;
  return `${plusMinus}${hourString}:${minutesString}`;
}

export const listOfFormattedTimezones = () => {
  const timezones = Intl.supportedValuesOf("timeZone");
  const offSets = [];
  const formattedTimezones = [];
  for (const timeZone of timezones) {
    const offSet = new Intl.DateTimeFormat("en", {
      timeZone: timeZone,
      timeZoneName: "shortOffset",
    })
      .formatToParts()
      .find((part) => part.type === "timeZoneName").value;
    const timeZoneAbbrivation = new Intl.DateTimeFormat("en", {
      timeZone: timeZone,
      timeZoneName: "long",
    })
      .formatToParts()
      .find((part) => part.type === "timeZoneName").value;
    const offSetInMinutes = convertOffSetToNumber(offSet) * 60;
    if (offSets.indexOf(offSet) === -1)
      formattedTimezones.push({
        timeZone,
        offSetInMinutes,
        formatted: `${offSet} ${timeZoneAbbrivation}`,
      });
    offSets.push(offSet);
  }
  return formattedTimezones.sort(compareOffSets);
};

function compareOffSets(a, b) {
  return a.offSetInMinutes - b.offSetInMinutes;
}

function convertOffSetToNumber(offSet) {
  let num = offSet
    .split(" ")[0]
    .replace("GMT", "")
    .replace(":30", ".5")
    .replace(":45", ".75");
  if (num[0] === "+") {
    num = parseFloat(num.replace("+", ""));
  } else if (num[0] === "-") {
    num = parseFloat(num.replace("-", "")) * -1;
  } else {
    num = 0;
  }
  return num;
}

export function dateTimeIsBeforeToday(time, timeZoneOffsetInMinutes) {
  const now = new Date();
  let today;
  if (timeZoneOffsetInMinutes) {
    const getOffset = now.getTimezoneOffset();
    const offsetDifference = timeZoneOffsetInMinutes + getOffset;
    const timeInMilliseconds = now.getTime() + offsetDifference * 60000;
    const adjustedDate = new Date(timeInMilliseconds);
    today = formatDate(adjustedDate, "yyyy-MM-dd HH:mm");
  } else {
    today = formatDate(now, "yyyy-MM-dd HH:mm");
  }
  const date_time = formatDate(time, "yyyy-MM-dd HH:mm");
  return date_time < today;
}

export function dateIsBeforeToday(date) {
  const today = formatDate(new Date(), "yyyy-MM-dd");
  const format_date = formatDate(date, "yyyy-MM-dd");
  return format_date < today;
}

export function endDateTimeIsBeforeStartDate(start_date_time, end_date_time) {
  return end_date_time < start_date_time;
}

export function endDateIsBeforeStartDate(start_date, end_date) {
  const format_start_date = formatDate(start_date, "yyyy-MM-dd");
  const format_end_date = formatDate(end_date, "yyyy-MM-dd");
  return format_end_date < format_start_date;
}

export function compareDates(date1, date2) {
  if (date1 < date2) {
    return 1;
  } else if (date1 > date2) {
    return -1;
  } else {
    return 0;
  }
}
