<template>
  <div class="w-full relative overflow-hidden">
    <div
      class="flex transition-transform duration-500 ease-in-out"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div
        v-for="(image, index) in imageUpdated"
        :key="index"
        class="min-w-full"
      >
        <img
          :src="image"
          class="select-none w-full h-full object-cover object-center"
        />
      </div>
    </div>
    <div
      v-if="props.imagePaths.length > 1"
      class="absolute inset-0 px-4 flex items-center justify-between"
    >
      <circled-pointer-left
        class="inline-block cursor-pointer"
        @click="decrementImage"
      />
      <circled-pointer-right
        class="inline-block cursor-pointer"
        @click="incrementImage"
      />
    </div>
    <div
      v-if="props.imagePaths.length > 1"
      class="absolute bottom-2 left-1/2 transform -translate-x-1/2 z-10 flex items-baseline justify-center"
    >
      <svg
        v-for="(imagePath, index) in props.imagePaths"
        :key="imagePath"
        class="inline-block h-[6px] w-[6px] mx-1"
      >
        <circle
          cx="50%"
          cy="50%"
          r="3"
          :fill="index === currentIndex ? 'white' : '#D6D6D6'"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
import CircledPointerLeft from "@/components/Configurator/widgets/CircledPointerLeft.vue";
import CircledPointerRight from "@/components/Configurator/widgets/CircledPointerRight.vue";
import { CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION } from "@/constants";
import { imageTransformation } from "@/util/cloudinary.js";
import { computed, defineProps, ref } from "vue";

const props = defineProps({
  imagePaths: {
    type: Array,
    default: () => [],
  },
  useImageCompression: {
    type: Boolean,
    default: false,
  },
  useAspectRatio: {
    type: Boolean,
    default: true,
  },
  resizeWidth: {
    type: Number,
    default: 650,
  },
  resizeHeight: {
    type: Number,
    default: 366,
  },
});

const currentIndex = ref(0);

const getImageParams = computed(() => {
  return {
    ...(props.useAspectRatio
      ? {
          ...CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
          height: props.resizeHeight,
          width: props.resizeWidth,
        }
      : {}),
    ...(props.useImageCompression ? { compress: true } : {}),
  };
});

const imageUpdated = computed(() => {
  let imageParams = getImageParams.value;
  if (Object.keys(imageParams).length !== 0) {
    return props.imagePaths.map((currentImagePath) =>
      imageTransformation({
        ...imageParams,
        path: currentImagePath,
      })
    );
  }
  return props.imagePaths;
});

const incrementImage = () => {
  currentIndex.value = (currentIndex.value + 1) % imageUpdated.value.length;
};

const decrementImage = () => {
  currentIndex.value =
    (currentIndex.value - 1 + imageUpdated.value.length) %
    imageUpdated.value.length;
};
</script>
