<template>
  <div v-if="loading">
    <Spinner class="relative h-65vh bg-blue-100" />
  </div>
  <div v-else>
    <HousingStepProductDetail
      :unit="unitData"
      :default-room="defaultRoomData"
      :selected-rooms="selectedRoomsData"
      :show-request-button="false"
      :show-footer="false"
      :show-price="false"
    />
  </div>
</template>

<script setup>
import HousingStepProductDetail from "./HousingStepProductDetail.vue";
import { computed, ref, onMounted } from "vue";
import programSessionService from "@/services/programSessions.js";
import { useRoute } from "vue-router";
import Spinner from "@/components/helpers/Spinner.vue";
import { useStore } from "vuex";

const store = useStore();

const unitData = ref({});
const defaultRoomData = ref({});
const selectedRoomsData = ref([]);
const route = useRoute();
const loading = ref(true);

const homeInstitutionId = computed(
  () => store.state.profileData?.colleges?.[0]?.college_id ?? ""
);

async function fetchData(sessionId, unitId, roomId) {
  try {
    const response = await programSessionService.getProgramSession({
      id: sessionId,
      home_institution_id: homeInstitutionId.value,
    });
    const sessionUnits = response.data.data.items.session_units;

    // Filter session_units based on unit_id
    const unit = sessionUnits.find((unit) => {
      if (unit.unit?.id === unitId) {
        return unit
      }
      if (unit.unit?.rooms?.find(room => room.id === roomId)) {
        selectedRoomsData.value = [roomId];
        return unit
      }
    });
    if (unit) {
      unitData.value = unit.unit;
      defaultRoomData.value = response.data.data.items.default_room;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    loading.value = false;
    sessionStorage.removeItem("loadingHousingData");
  }
}

onMounted(() => {
  const isLoading = sessionStorage.getItem("loadingHousingData");
  if (isLoading === "true") {
    loading.value = true;
  }

  const sessionId = route.query["session-id"];
  const unitId = route.query["unit-id"];
  const roomId = route.query["room-id"];
  if (sessionId) {
    fetchData(sessionId, unitId, roomId);
  }
});
</script>
