<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      userPermissions: "getPermissions",
    }),
  },
  methods: {
    showItem(permissions) {
      if (permissions && permissions?.length) {
        return permissions.some((permission) =>
          this.userPermissions.includes(permission)
        );
      } else {
        return true;
      }
    },
  },
};
</script>
<template>
  <div class="flex flex-col lg:flex-shrink-0 lg:flex-grow-0 lg:w-[300px]">
    <h1 class="text-2xl font-bold text-blue-900 mb-2">{{ title }}</h1>
    <template v-for="category in categories" :key="category?.title">
      <template
        v-if="category?.permissions ? showItem(category?.permissions) : true"
      >
        <h2 class="text-xs text-[#6a7181] font-medium mb-2">
          {{ category?.title }}
        </h2>
        <!-- Card -->
        <div class="flex w-full mb-6 select-none">
          <div class="block rounded shadow-md bg-white w-full py-3">
            <template
              v-for="(subcategory, subcategoryIndex) in category?.subcategories"
            >
              <router-link
                v-if="showItem(subcategory?.permissions)"
                v-slot="{ isActive }"
                :key="subcategoryIndex"
                :to="{ name: subcategory?.route }"
              >
                <div
                  :class="[
                    isActive && subcategory?.route
                      ? 'border-r-[#1f264c] bg-[#c8d0ec] text-[#1e264c]'
                      : 'border-r-white hover:border-r-blue-350 hover:bg-blue-350 text-gray-600',
                  ]"
                  class="border-r-4 border-solid flex justify-between w-full py-3 px-6"
                >
                  <span class="text-sm font-bold">{{
                    subcategory?.title
                  }}</span>
                  <span
                    class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-200 rounded-full"
                    >{{ subcategory?.count }}</span
                  >
                </div>
              </router-link>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
