<template>
  <div
    class="w-full overflow-auto relative mt-4 px-4"
    :class="{ 'hidden sm:block': mobileView }"
  >
    <table
      v-if="props.items.length"
      class="table-auto w-full text-left border-spacing-2"
    >
      <thead class="font-bold text-xs">
        <tr>
          <th
            v-for="column in props.columns"
            :key="column.id"
            :class="getColumnClass(column)"
          >
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody class="text-sm">
        <tr
          v-for="item in props.items"
          :key="item.invoice"
          class="border-t border-gray-200"
        >
          <td
            v-for="column in props.columns"
            :key="column.id"
            class="py-4"
            :class="getColumnClass(column)"
          >
            <div v-if="shouldRenderAsLink(column, item)" class="line-clamp-2">
              <a class="text-cyan-700" :href="item.url" target="_blank">{{
                item[column.id]
              }}</a>
            </div>
            <button
              v-if="shouldRenderButtonOrIcon(item[column.id]) == 'button'"
              class="items-center text-sm text-center px-5 rounded font-semibold border-2 border-transparent h-10 text-white hover:text-white focus:text-white bg-university-secondary hover:bg-teal-100 focus:bg-teal-500"
              @click="item[column.id].action"
            >
              <span>{{ item[column.id].text }}</span>
            </button>
            <button
              v-if="shouldRenderButtonOrIcon(item[column.id]) == 'icon'"
              class="items-center"
              @click="item[column.id].action"
            >
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <filter id="w29kyfuiga">
                    <feColorMatrix
                      in="SourceGraphic"
                      values="0 0 0 0 0.000000 0 0 0 0 0.499740 0 0 0 0 0.501982 0 0 0 1.000000 0"
                    />
                  </filter>
                </defs>
                <g
                  transform="translate(-563 -74)"
                  filter="url(#w29kyfuiga)"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g fill="#007F80" fill-rule="nonzero">
                    <path
                      d="M569 86a1.01 1.01 0 0 1-.716-.297l-2.237-2.237a1.008 1.008 0 0 1 0-1.432 1.008 1.008 0 0 1 1.43 0l.523.522V75a1 1 0 1 1 2 0v7.556l.521-.522a1.011 1.011 0 1 1 1.432 1.432l-2.237 2.237c-.192.19-.447.297-.716.297z"
                    />
                    <path
                      d="M573 86a1 1 0 1 1 2 0v4h-12v-4a1 1 0 1 1 2 0v2h8v-2z"
                    />
                  </g>
                </g>
              </svg>
            </button>
            <div
              v-if="isPastDueOrOpen(item[column.id])"
              class="flex flex-row line-clamp-2 text-red-600"
            >
              {{
                item[column.id] !== INVOICE_STATUSES.OPEN ? item[column.id] : ""
              }}
            </div>
            <div
              v-if="
                !shouldRenderAsLink(column, item) &&
                !shouldRenderButtonOrIcon(item[column.id]) &&
                !isPastDueOrOpen(item[column.id])
              "
              class="line-clamp-2"
            >
              {{
                item[column.id] !== INVOICE_STATUSES.OPEN &&
                item[column.id] !== INVOICE_STATUSES.DRAFT
                  ? item[column.id]
                  : ""
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    v-if="mobileView"
    class="pb-2 px-8 border-none text-xs xs:text-base"
    :class="{ 'sm:hidden': mobileView }"
  >
    <div v-for="item in props.items" :key="item.invoice">
      <hr class="my-4 text-gray-25" />
      <div v-if="shouldRenderAsLink('invoice', item)" class="line-clamp-2">
        <a class="text-cyan-700" :href="item.url" target="_blank">{{
          item.invoice
        }}</a>
      </div>
      <div v-if="shouldRenderAsLink('charges', item)" class="line-clamp-2">
        <a class="text-cyan-700" :href="item.url" target="_blank">{{
          item.charges
        }}</a>
      </div>
      <div class="flex flex-row w-full mt-1 rounded-lg justify-between">
        <div class="flex flex-col items-start gap-1.5">
          <p>Date Due: {{ item.due_date }}</p>
          <p>Amount: {{ item.amount }}</p>
        </div>
        <div class="flex flex-col items-end gap-1.5">
          <div
            v-if="isPastDueOrOpen(item.status)"
            class="flex flex-row text-red-600"
          >
            {{ item.status !== INVOICE_STATUSES.OPEN ? item.status : "" }}
          </div>
          <div
            v-if="
              !shouldRenderAsLink(column, item) &&
              !shouldRenderButtonOrIcon(item.status) &&
              !isPastDueOrOpen(item.status)
            "
            class=""
          >
            {{
              item.status !== INVOICE_STATUSES.OPEN &&
              item.status !== INVOICE_STATUSES.DRAFT
                ? item.status
                : ""
            }}
          </div>
          <button
            v-if="shouldRenderButtonOrIcon(item['actions']) == 'button'"
            class="items-center text-sm text-center px-5 rounded font-semibold border-2 border-transparent h-10 text-white hover:text-white focus:text-white bg-university-secondary hover:bg-teal-100 focus:bg-teal-500"
            @click="item['actions'].action"
          >
            <span>{{ item["actions"].text }}</span>
          </button>
          <button
            v-if="shouldRenderButtonOrIcon(item['actions']) == 'icon'"
            class="items-center"
            @click="item['actions'].action"
          >
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <filter id="w29kyfuiga">
                  <feColorMatrix
                    in="SourceGraphic"
                    values="0 0 0 0 0.000000 0 0 0 0 0.499740 0 0 0 0 0.501982 0 0 0 1.000000 0"
                  />
                </filter>
              </defs>
              <g
                transform="translate(-563 -74)"
                filter="url(#w29kyfuiga)"
                fill="none"
                fill-rule="evenodd"
              >
                <g fill="#007F80" fill-rule="nonzero">
                  <path
                    d="M569 86a1.01 1.01 0 0 1-.716-.297l-2.237-2.237a1.008 1.008 0 0 1 0-1.432 1.008 1.008 0 0 1 1.43 0l.523.522V75a1 1 0 1 1 2 0v7.556l.521-.522a1.011 1.011 0 1 1 1.432 1.432l-2.237 2.237c-.192.19-.447.297-.716.297z"
                  />
                  <path
                    d="M573 86a1 1 0 1 1 2 0v4h-12v-4a1 1 0 1 1 2 0v2h8v-2z"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { INVOICE_STATUSES } from "@/constants.js";
import { defineProps } from "vue";

const props = defineProps({
  columns: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  mobileView: {
    type: Boolean,
    required: false,
  },
});

const getColumnClass = (column) => {
  if (column.id === "charges" || column.title === "invoice") {
    return "w-[200px]";
  }
  if (column.id === "actions") {
    return "text-center";
  }
  return "";
};

const shouldRenderAsLink = (column, item) => {
  //is invoice and has a url
  const isInvoice = Boolean(item.invoice.startsWith("in_") && item.url);
  //table columns
  const isInvoiceOrCharges =
    (typeof column === "string" &&
      (column === "invoice" || column === "charges")) ||
    (typeof column === "object" &&
      (column.id === "invoice" || column.id === "charges"));
  return isInvoice && isInvoiceOrCharges;
};

const isPastDueOrOpen = (item) => {
  return item === INVOICE_STATUSES.PAST_DUE || item === INVOICE_STATUSES.OPEN;
};

const shouldRenderButtonOrIcon = (item) => {
  if (item?.type == "button") {
    return "button";
  }
  if (item?.type == "icon") {
    return "icon";
  }
  return false;
};
</script>

<style></style>
