import { getApiClient } from "@/services/API";

export default {
  async downloadSyllabus(url) {
    try {
      const baseUrl = `${window.location.origin}`;
      const proxyUrl = `${baseUrl}/download-syllabus?url=${encodeURIComponent(
        url
      )}`;
      const response = await getApiClient().get(proxyUrl, {
        responseType: "blob",
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw new Error("Download failed: " + error.message);
    }
  },
};
