<template>
  <div class="bg-white rounded-lg shadow-md p-5">
    <span class="text-lg font-semibold block">To Do List</span>

    <p
      v-if="message"
      class="text-sm font-semibold mt-4 mb-4 block text-gray-650"
    >
      {{ message }}
    </p>

    <template v-if="step1.display">
      <!-- Step 1 -->
      <span
        class="text-xs font-semibold text-gray-500 uppercase mt-6 block mb-4"
      >
        Onboarding Step 1
      </span>
      <ul data-testid="to-do-onboarding-list">
        <li v-for="link in step1.forms" :key="link.slug" class="mb-4">
          <a
            :href="'/' + applicationId + '/' + link.slug"
            class="text-sm font-semibold text-cyan-700"
          >
            {{ link.label }}
          </a>
        </li>
      </ul>
    </template>

    <template v-if="paymentStep.display && uiVersion !== 'v3'">
      <!-- Payments -->
      <span
        class="text-xs font-semibold text-gray-500 uppercase mt-6 block mb-4"
      >
        Payments
      </span>
      <ul>
        <li v-for="link in paymentStep.forms" :key="link.id" class="mb-4">
          <button
            type="button"
            class="text-sm font-semibold text-cyan-700"
            @click="triggerPayment('payment-' + link.id)"
          >
            {{ link.name }}
          </button>
        </li>
      </ul>
    </template>

    <template v-if="step2.display">
      <!-- Step 2 -->
      <span
        class="text-xs font-semibold text-gray-500 uppercase mt-6 block mb-4"
        data-cy="step2SectionTitle"
      >
        {{ step2.sectionTitle }}
      </span>
      <ul data-testid="to-do-pre-departure-list">
        <li v-for="link in step2.forms" :key="link.routeName" class="mb-4">
          <router-link
            :to="{
              name: link.routeName,
              params: { applicationId: applicationId },
            }"
            class="text-sm font-semibold text-cyan-700"
          >
            {{ link.label }}
          </router-link>
        </li>
      </ul>
    </template>
    <p v-if="inProgress" class="text-xs text-gray-500 italic">
      Some items require review, so please check your email regularly for any
      additional action.
    </p>
    <p v-else class="text-xs text-gray-500 italic">
      Great job, there’s nothing on your to-do list! Some items require manual
      review so make sure to check your email regularly!
    </p>

    <MobileAppTodosBanner v-if="showMobileAppInfo" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { compareStringsInLowerCase } from "@/util/string";
import { APPLICATION_FEE_DATA } from "@/constants";
import MobileAppTodosBanner from "@/components/MobileAppBanner/TodosBanner.vue";

export default {
  name: "ToDoListEnrollment",
  components: {
    MobileAppTodosBanner,
  },
  props: {
    paidWithPromoCode: {
      type: Boolean,
      default: false,
    },
    uiVersion: {
      type: String,
      default: "v2",
    },
    showMobileAppInfo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
      "onboardingStep1Forms",
      "onboardingStep2Forms",
      "completedSections",
      "payments",
      "program",
    ]),
    inProgress() {
      return (
        this.step1.forms.length > 0 ||
        this.paymentStep.forms.length > 0 ||
        this.step2.forms.length > 0
      );
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
    message() {
      let message = "";
      if (this.pendingConfirmation) {
        if (!this.step1Started && !this.step1.finished)
          message =
            "Start with submitting your confirmation payment and Onboarding Step 1 materials";
        if (!this.step2Started && this.step1.finished && !this.step2.finished)
          message =
            "Start with submitting your confirmation payment and Onboarding Step 2 materials";
      } else if (!this.step1Started && !this.step1.finished) {
        message = "Start with submitting your Onboarding Step 1 materials";
      } else if (
        this.step1.finished &&
        !this.step2.finished &&
        !this.step2Started
      ) {
        message = "Start with submitting your Onboarding materials";
      }
      return message;
    },
    step1() {
      return {
        display: this.step1Started,
        steps: this.step1TotalSteps,
        forms: this.step1PendingForms,
        finished: this.completedSections.onboarding1,
      };
    },
    step2() {
      return {
        display: this.step2PendingForms.length > 0 && this.step1.finished,
        steps: this.step2TotalSteps,
        forms: this.step2PendingForms,
        finished: this.completedSections.onboarding2,
        sectionTitle:
          this.program.form_rules.length <= 0
            ? "Onboarding"
            : "Onboarding Step 2",
      };
    },
    paymentStep() {
      return {
        display: !this.paymentsComplete && this.pendingBalance.length > 0,
        steps: this.pendingBalance.length,
        forms: this.pendingBalance,
      };
    },
    stepGuards() {
      return {
        step1:
          Object.hasOwnProperty.call(
            this.onboardingStep1Forms,
            "submittedForms"
          ) &&
          Object.hasOwnProperty.call(this.onboardingStep1Forms, "totalForms"),
        step2:
          Object.hasOwnProperty.call(
            this.onboardingStep2Forms,
            "submittedForms"
          ) &&
          Object.hasOwnProperty.call(this.onboardingStep2Forms, "totalForms"),
      }; // Validatig both sections have essential info.
    },
    step1Started() {
      return (
        this.stepGuards.step1 &&
        this.onboardingStep1Forms.submittedForms > 0 &&
        !this.completedSections.onboarding1
      );
    },
    step1PendingForms() {
      return this.stepGuards.step1
        ? this.onboardingStep1Forms.forms.filter(
            (form) => form.submitted === false
          )
        : [];
    },
    step1TotalSteps() {
      return this.stepGuards.step1 ? this.onboardingStep1Forms.forms.length : 0;
    },
    step2Started() {
      return (
        this.stepGuards.step2 &&
        this.onboardingStep2Forms.submittedForms > 0 &&
        !this.completedSections.onboarding2
      );
    },
    step2PendingForms() {
      return this.stepGuards.step2
        ? this.onboardingStep2Forms.forms.filter(
            (form) => form.submitted === false
          )
        : [];
    },
    step2TotalSteps() {
      return this.stepGuards.step2 ? this.onboardingStep2Forms.forms.length : 0;
    },
    participantPayments() {
      return this.payments.filter(
        (payment) => payment.paid_by === "Participant"
      );
    },
    pendingBalance() {
      let paymentsPendingBalance = [];
      let allValidPayments = this.participantPayments.filter(
        (payment) =>
          typeof payment.status !== "undefined" &&
          payment.status !== "Paid In Full"
      );

      allValidPayments.forEach((payment) => {
        const isApplicationDepositPromo = Boolean(
          payment.status === "Open" &&
            compareStringsInLowerCase(
              payment.name,
              APPLICATION_FEE_DATA.typeLabel
            ) &&
            this.paidWithPromoCode
        );

        if (!isApplicationDepositPromo) paymentsPendingBalance.push(payment);
      });

      return paymentsPendingBalance;
    },
    pendingConfirmation() {
      let payment = this.pendingBalance.find(
        (payment) => payment.name.toLowerCase() === "confirmation payment"
      );
      return (
        typeof payment !== "undefined" &&
        typeof payment.status !== "undefined" &&
        payment.status !== "Paid In Full"
      );
    },
    paymentsComplete() {
      if (!this.payments.length > 0) return false;
      if (!this.participantPayments.length > 0) return false;
      if (!this.pendingBalance.length !== 0) return false;
      return true;
    },
  },
  methods: {
    triggerPayment(id) {
      let element = document.getElementById(id);
      if (element !== null) element.click();
      if (element === null)
        console.warn("Payment with id: " + id + " was not found");
    },
  },
};
</script>
