<script setup>
import { defineProps, defineEmits, toRefs, computed } from "vue";
import { useVModel } from "@vueuse/core";
import BaseInput from "./BaseInput.vue";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";
import TrashCan from "@/components/shared/icons/TrashCan.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, minValue, required } from "@vuelidate/validators";
import { useAcademicYearFilter } from "@/components/program-manager/sessions/composable";
import { uuid } from "vue-uuid";

const props = defineProps({
  term: {
    type: Object,
    required: true,
  },
  allTerms: {
    type: Array,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
  settings: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const { isEditing, settings, index, allTerms } = toRefs(props);

const emit = defineEmits(["update:term", "removeTerm"]);
const data = useVModel(props, "term", emit);

const yearMustBeDifferent = () => {
  return !allTerms.value.some(
    (term, termIndex) =>
      term.year === data.value.year && index.value !== termIndex
  );
};

const rules = {
  year: {
    required,
    yearMustBeDifferent: helpers.withMessage(
      "Two or more terms can't have the same year",
      yearMustBeDifferent
    ),
  },
  terms: {
    required,
    $each: helpers.forEach({
      capacity: {
        required,
        minValue: minValue(1),
      },
    }),
  },
};

const v$ = useVuelidate(rules, data, {
  $registerAs: `Terms-${uuid.v4()}`
});

const fieldsBaseClass = "mb-6 text-blue-900";

const {
  options: academicYearOptions,
  reduceAcademicYearOption,
} = useAcademicYearFilter({idIsNotIndex: true});


const termsErrors = computed(() => {
  const didntFindCapacityValidation = !v$.value.terms?.$errors?.some(
    (error) => error?.$validator === "$each"
  );
  return didntFindCapacityValidation ? v$.value.terms.$errors : [];
});

const capacityErrors = computed(() => {
  return v$.value.terms.$each.$response.$errors.some(
    (item) => item.capacity.length > 0
  );
});

const listingTerms = computed(() => {
  return data.value.terms?.map((item) => item?.name).join(", ")
})
</script>
<template>
  <div>
    <div class="flex flex-col py-5 px-7">
      <div v-if="isEditing" class="ml-auto cursor-pointer"> 
        <TrashCan @click="emit('removeTerm', index)" />
      </div>
      <div class="grid grid-cols-4 md:gap-3 pr-2">
        <div :class="fieldsBaseClass" class="col-span-4 md:col-span-1">
          <label class="font-semibold">
            <span
              v-if="isEditing"
              class="font-semibol mr-2"
              :class="{ 'text-error-900': true }"
              >*</span
            >
            Academic Year
          </label>
          <v-select-caret
            v-if="isEditing"
            v-model="data.year"
            :options="academicYearOptions"
            :reduce="(val) => val.id"
            :clearable="false"
            :vuelidate-instance="v$.year"
            label="name"
          />
          <p v-else :class="baseViewingTextClass">
            {{ data.year }}
          </p>
        </div>
        <div :class="fieldsBaseClass" class="col-span-4 md:col-span-3">
          <label class="font-semibold">
            <span
              v-if="isEditing"
              class="font-semibol mr-2"
              :class="{ 'text-error-900': true }"
              >*</span
            >{{ settings.whenStarts.copy }}</label
          >
          <BaseInput
            v-if="isEditing"
            v-model="data.terms"
            :type="settings.whenStarts.type"
            :field-settings="settings.whenStarts"
            :vuelidate-errors="termsErrors"
          />
          <p v-else :class="baseViewingTextClass">
            {{ listingTerms }}
          </p>
        </div>
        <div
          v-if="data.terms?.length"
          :class="fieldsBaseClass"
          class="col-span-4"
        >
          <fieldset>
            <div
              :class="{ 'bg-error-100': isEditing && capacityErrors }"
            >
              <label class="font-semibold">
                <span
                  v-if="isEditing"
                  class="font-semibold mr-2"
                  :class="{ 'text-error-900': isEditing & settings.capacity.required }"
                  >*</span
                >{{ settings.capacity.copy }}</label
              >
              <span
                v-if="isEditing && capacityErrors"
                class="block font-semibold text-error-900"
                >Value greater than 0 is required</span
              >
            </div>
            <div v-if="isEditing" class="flex gap-5 flex-wrap mt-4">
              <div v-for="(period, key) in data.terms" :key="period">
                <div class="flex gap-5 items-center">
                  <label :for="period.label_desc">{{ period.name }}</label>
                  <input
                    v-if="isEditing"
                    v-model="data.terms[key].capacity"
                    type="number"
                    min="0"
                    step="1"
                    class="h-10 mt-1 w-[60px] form-input"
                    :class="{
                      'bg-error-100':
                        v$.terms?.$each?.$response?.$errors[key]?.capacity?.length ??
                        false,
                    }"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div v-for="period in data.terms" :key="period.id">
                <p :class="baseViewingTextClass">
                  {{ period.name }}:
                  {{ period.capacity }}
                </p>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>
