<template>
  <div @click="handleClick">
    <div :id="id" role="form" />
    <flex-date-internship v-if="flexDateModal" @close="flexDateModal = false" />
    <fixed-date-internship
      v-if="fixedDateModal"
      @close="fixedDateModal = false"
    />
    <study-and-intern
      v-if="studyAndInternModal"
      @close="studyAndInternModal = false"
    />
  </div>
</template>

<script>
import { Formio } from "formiojs";
import FormioUtils from "formiojs/utils";
import tailwind from "@apiabroad/formio-tailwind-template";
import { mapGetters, mapState } from "vuex";
import forms from "../mixins/forms.js";
import housingRules from "../mixins/housingRules";
import FlexDateInternship from "./modals/FlexDateInternship";
import FixedDateInternship from "./modals/FixedDateInternship";
import StudyAndIntern from "./modals/StudyAndIntern";
import courseHelper from "../mixins/courseHelper";
import * as Sentry from "@sentry/vue";
import domUtils from "../mixins/domUtilities";
import ProgramChangeMix from "../mixins/programChange";
import { CONSTANT_KEYS } from "../constants.js";
import impersonationFormSniffer from "../mixins/ImpersonationFormSniffer";
import formService from "@/services/form";
import { APICompany } from "../constants.js";

export default {
  components: {
    FlexDateInternship,
    FixedDateInternship,
    StudyAndIntern,
  },
  mixins: [
    forms,
    housingRules,
    courseHelper,
    domUtils,
    ProgramChangeMix,
    impersonationFormSniffer,
  ],
  props: {
    formUrl: {
      type: String,
      default: "",
    },
    applicationId: {
      type: String,
      default: "",
    },
    inProgress: Boolean,
    formNotification: {
      type: String,
      default: "",
    },
    secondaryApplicationId: {
      type: String,
      default: "",
    },
  },
  emits: [
    "select-program",
    "set-application-id",
    "form-submit",
    "set-secondary-application-id",
  ],
  data() {
    return {
      apiBase: this.trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT),
      enrollmentBase: this.trailingSlash(
        process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
      ),
      sites: [],
      location: "",
      semester: "",
      form: "",
      transactionId: "",
      programs: Object,
      paymentDone: false,
      secondarySubmitted: false,
      advisorApprovalSent: false,
      uploading: [],
      flexDateModal: false,
      fixedDateModal: false,
      studyAndInternModal: false,
      allowedFileTypes: "",
      maxFileSize: "",
      validDiscountCode: false,
      letterOfRecSent: {
        letter1: false,
        letter2: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getCurrentStudentApplication"]),
    ...mapState([
      "formioToken",
      "userData",
      "program",
      "isInternship",
      "studentApplications",
      "selectedProgramId",
      "previousApplication",
      "studentApplications",
      "appliedAgreement",
    ]),
    contractNumber() {
      return Object.hasOwnProperty.call(
        this.appliedAgreement,
        "contract_number"
      )
        ? this.appliedAgreement.contract_number
        : "";
    },
    id() {
      return "formio-" + this._uid;
    },
    approvalStatusBehaviour() {
      return this.getCurrentStudentApplication !== null
        ? this.getCurrentStudentApplication["advisorApproval"]["formSubmitted"]
          ? "showApproved"
          : this.advisorApprovalSent
          ? "showMailSent"
          : ""
        : "";
    },
    studentHasUniversity() {
      if (!this.userData) {
        return false;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.userData, "data") &&
        Object.prototype.hasOwnProperty.call(
          this.userData.data,
          "schoolname"
        ) &&
        Object.prototype.hasOwnProperty.call(
          this.userData.data.schoolname,
          "value"
        )
      ) {
        return true;
      }
      return false;
    },
    applicationFee() {
      return this.studentApplications.find(
        (app) => app.id === this.applicationId
      ).programSession.application_fee;
    },
    studentId() {
      if (!this.userData) {
        return "";
      }
      if (Object.prototype.hasOwnProperty.call(this.userData, "_id")) {
        return this.userData._id;
      }
      return "";
    },
    virtual() {
      let virtual = [];
      for (var i = 0; i < this.sites.length; i++) {
        if (this.sites[i].label === "Virtual, Virtual") {
          virtual = this.sites[i];
        }
      }
      return virtual;
    },
    getCity() {
      return this.program.site && this.program.site.city
        ? this.program.site.city
        : "****";
    },
    getStudentName() {
      //get first and lastname from token
      if (
        typeof Storage !== "undefined" &&
        localStorage.getItem("formioUser") !== null
      ) {
        return (
          JSON.parse(localStorage.getItem("formioUser")).data.firstname +
          " " +
          JSON.parse(localStorage.getItem("formioUser")).data.lastname
        );
      } else {
        return "****";
      }
    },
    getStudentFirstName() {
      if (
        typeof Storage !== "undefined" &&
        localStorage.getItem("formioUser") !== null
      ) {
        return JSON.parse(localStorage.getItem("formioUser")).data.firstname;
      } else {
        return "****";
      }
    },
    getManagerName() {
      return this.program.program_manager &&
        this.program.program_manager.length > 1
        ? this.program.program_manager
        : "API Enrollment Management Team";
    },
    getEmail() {
      return this.program.program_manager_email &&
        this.program.program_manager_email.length > 1
        ? this.program.program_manager_email
        : `enrollment${APICompany.companyEmail}`;
    },
    getFallbackEmailLabel() {
      return this.program.program_manager_email &&
        this.program.program_manager_email.length > 1
        ? this.program.program_manager_email
        : `Enrollment Management <enrollment${APICompany.companyEmail}>`;
    },
    depositAmount() {
      return this.program.application_fee;
    },
    availableCredit() {
      return this.programHasChange
        ? this.previousApplication.applicationFee
        : this.validDiscountCode
        ? this.program.application_fee
        : 0;
    },
    totalFee() {
      return this.depositAmount - this.availableCredit;
    },
  },
  watch: {
    "$store.state.program": {
      handler: function (newval) {
        if (newval) {
          this.form.then((form) => {
            this.processFormRules(form);
          });
        }
      },
      deep: true,
    },
    formUrl: {
      handler: function () {
        // Scroll to top
        window.scrollTo(0, 0);
      },
    },
    advisorApprovalSent: {
      handler: function () {
        this.guardAddApprovalStatus();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.maybeSetUserData();

    Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
    Formio.setAuthUrl(
      `${window.location.protocol}//${window.location.host}/api/forms`
    );
    Formio.use(tailwind);
    this.form = Formio.createForm(
      document.getElementById(this.id),
      this.formUrl,
      {
        sanitizeConfig: {
          addAttr: [
            "focusable",
            "viewBox",
            "fill",
            "stroke",
            "stroke-width",
            "stroke-linecap",
            "stroke-linejoin",
            "cx",
            "cy",
            "r",
            "x1",
            "x2",
            "y1",
            "y2",
            "points",
            "d",
          ],
          addTags: ["svg", "circle", "line", "polyline", "path"],
        },
        hooks: {
          beforeSubmit: (submission, next) => {
            //Set contract number (if exists)
            if (
              Object.prototype.hasOwnProperty.call(
                submission.data,
                "contractNumber"
              )
            ) {
              submission.data.contractNumber = this.contractNumber;
            }

            submission = this.attachEmployeeToSubmission(submission, "post");
            //Set current UI version
            if (
              Object.prototype.hasOwnProperty.call(submission.data, "uiVersion")
            ) {
              submission.data.uiVersion = CONSTANT_KEYS.UI_VERSION;
              this.$store.commit("setUiVersion", CONSTANT_KEYS.UI_VERSION);
            }

            // Only for forms that have application_id field
            if (submission.data.hasOwnProperty("application_id")) {
              submission.data.application_id = this.applicationId;
            }

            if (!submission.data.application_id) {
              // don't let the form submit
              let submitErrors = [];
              submitErrors.push({
                message:
                  "No Application ID, please contact your API program manager to help you with your application.",
                path: ["submit"],
              });
              next(submitErrors);
              return;
            }

            // Check if the passport field is submitted and if so, that it is not empty
            if (
              this.isInFormRules("Passport Info") &&
              submission.state !== "draft" &&
              submission.data.hasOwnProperty("noPassportBox") &&
              submission.data.noPassportBox === false &&
              submission.data["passport-image-01"].length === 0
            ) {
              let submitErrors = [];
              submitErrors.push({
                message:
                  '"The primary passport field" must contain at least 1 item.',
                path: ["passport-image-01"],
                type: "array.min",
                context: {
                  limit: 1,
                  value: [],
                  key: "passport-image-01",
                  label: "Passport Image 01",
                },
              });
              next(submitErrors);
              return;
            }
            if (this.uploading.length > 0) {
              let submitErrors = null;
              submitErrors = [];
              submitErrors.push({
                message: "Please wait for file to finish uploading...",
              });
              alert("Please wait for file to finish uploading...");
              //check if meesage is set
              var element = document.getElementById("upload-wait-message");
              if (typeof element != "undefined" && element != null) {
                //message already visible on page, do nothing.
              } else {
                let errorDivs = document.querySelectorAll(
                  ".apiabroad-error-message"
                );
                let element = document.createElement("p");
                element.setAttribute("style", "color:red;display:block;");
                element.setAttribute("id", "upload-wait-message");
                element.textContent =
                  "Please wait for file to finish uploading. ";
                errorDivs[errorDivs.length - 1].append(element);
              }

              next(submitErrors);
              return;
            }

            //Validate Program Session
            if (
              Object.prototype.hasOwnProperty.call(
                submission.data,
                "programSession"
              )
            ) {
              if (this.invalidProgramSession(submission.data.programSession)) {
                let submitErrors = [];
                submitErrors.push({
                  message:
                    "You already have an active application for this program",
                  path: ["submit"],
                });
                next(submitErrors);
                return;
              }
            }

            // Only for form that has secondary applications
            if (
              Object.prototype.hasOwnProperty.call(
                submission.data,
                "location2"
              ) &&
              Object.prototype.hasOwnProperty.call(
                submission.data,
                "semester2"
              ) &&
              Object.prototype.hasOwnProperty.call(
                submission.data,
                "programSession2"
              ) &&
              this.secondaryApplicationId !== "" &&
              this.secondarySubmitted === false
            ) {
              if (this.invalidProgramSession(submission.data.programSession2)) {
                let submitErrors = [];
                submitErrors.push({
                  message:
                    "You already have an active application for this program",
                  path: ["submit"],
                });
                next(submitErrors);
                return;
              } else {
                this.secondaryApplicationSubmission(
                  submission.data,
                  next,
                  this.secondaryApplicationId
                );
                return;
              }
            }

            // Only is a payment submission if it has a card number
            if (submission.data.hasOwnProperty("cardNumber")) {
              // If valid discount code or total is 0, skip this
              if (this.validDiscountCode) {
                next();
                return;
              } else if (
                this.programHasChange &&
                this.previousApplication.paidFee
              ) {
                submission.data.creditTransactionId = this.previousApplication.transactionId;
                if (this.totalFee <= 0) {
                  next();
                  return;
                }
              }

              // don't allow american express
              let amexpRegEx = /^(?:3[47][0-9]{13})$/;
              if (amexpRegEx.test(submission.data.cardNumber)) {
                let submitErrors = [];
                submitErrors.push({
                  message:
                    "We do not accept this type of credit card. Please try again with a supported card type.",
                  path: ["cardNumber"],
                  type: "array.min",
                  context: {
                    limit: 1,
                    value: [],
                    key: "cardNumber",
                    label: "Card Number",
                  },
                });
                next(submitErrors);
                return;
              }

              next({
                message:
                  "We do not accept payments for this version of the application",
                path: ["cardNumber"],
                type: "array.min",
                context: {
                  limit: 1,
                  value: [],
                  key: "cardNumber",
                  label: "Card Number",
                },
              });
              submission.data.cardNumber = "";
              submission.data.expiration = "";
              submission.data.expDate = "";
              submission.data.expYear = "";
              submission.data.cvCode = "";
            } else {
              next();
            }

            // Only for academic information
            if (this.formUrl.indexOf("studentapplicationstage2step1") !== -1) {
              if (this.letterOfRecSent["letter1"])
                submission.data["sentLetterOfRecommendation1"] = true;
              if (this.letterOfRecSent["letter2"])
                submission.data["sentLetterOfRecommendation2"] = true;
              if (this.advisorApprovalSent)
                submission.data["sentApproval"] = true;
            }
          },
        },
        noAlerts: true,
      }
    );

    // Process housing rules so only the correct accommodation style preference options appear
    this.form.then((form) => {
      this.processHousingRules(form);
    });

    //Add event listener to detect fileupload starting on components with custom class
    this.form.then((form) => {
      let comps = FormioUtils.findComponents(form.components, {
        type: "file",
        "component.customClass": "fileUploadField",
      });

      if (comps.length > 0) {
        //need to add eventlistener to filebrowse upload buttons
        document.addEventListener("click", (event) => {
          event.stopPropagation();
          if (
            typeof event.srcElement.attributes.ref !== "undefined" &&
            event.srcElement.attributes.ref.value === "fileBrowse"
          ) {
            //clear the data property for allowed file allowedFileTypes
            this.allowedFileTypes = [];
            try {
              //get the types of files allowed in this component, this types come from form.io
              let types =
                event.srcElement.parentNode.parentNode.component.component
                  .filePattern;
              this.allowedFileTypes = types.split(",");
              //max upload  file size allowed
              this.maxFileSize =
                event.srcElement.parentNode.parentNode.component.component
                  .fileMaxSize !== "undefined"
                  ? event.srcElement.parentNode.parentNode.component.component
                      .fileMaxSize
                  : "";
            } catch (e) {
              //console.warn(e);
            }
          }
        });

        //listen to file browse and upload
        document.addEventListener("change", (event) => {
          event.preventDefault();
          event.stopPropagation();
          if (event.target.type === "file") {
            if (event.target.files.length) {
              for (var i = 0; i < event.target.files.length; i++) {
                //only add to uploading array if its an allowed type of file
                if (
                  this.isAllowedFileType(event.target.files[i]) &&
                  this.validFileSize(event.target.files[i].size)
                ) {
                  console.log(
                    "starting upload of: " + event.target.files[i].name
                  );
                  this.uploading.push(event.target.files[i].name);
                }
              }
            }
          }
        });

        //listener if drop
        document.addEventListener("drop", (event) => {
          event.preventDefault();
          event.stopPropagation();
          // If the event target doesn't match bail
          if (!event.target.classList.contains("fileSelector")) return;
          if (event.dataTransfer.files.length > 0) {
            //clear the data property for allowed file allowedFileTypes
            this.allowedFileTypes = [];
            try {
              //get the types of files allowed in this component, this types come from form.io
              console.log(
                event.srcElement.parentNode.component.component.filePattern
              );
              let types =
                event.srcElement.parentNode.component.component.filePattern;
              this.allowedFileTypes = types.split(",");
            } catch (e) {
              // console.warn(e);
            }

            for (var i = 0; i < event.dataTransfer.files.length; i++) {
              //only add to uploading array if its an allowed type of file
              if (
                this.isAllowedFileType(event.dataTransfer.files[i]) &&
                this.validFileSize(event.dataTransfer.files[i].size)
              ) {
                console.log(
                  "starting upload of: " + event.dataTransfer.files[i].name
                );
                this.uploading.push(event.dataTransfer.files[i].name);
              }
            }
          }
        });
      }
    });

    this.form.then((form) => {
      form.submissionReady.then((form) => {
        // Changes University Approval & Letter of Recommendation styles if submitted
        this.guardSubmittedUniversityApproval(form);
        this.guardSubmittedLetterOfRec(form, 1);
        this.guardSubmittedLetterOfRec(form, 2);
        this.guardAddApprovalStatus(); // Adds university approval status upon submission loaded
      });

      if (this.program) {
        this.processFormRules(form);
        this.processProgramFeesRules(form);
        this.loadCourses(form);
      }

      let locationSelect = form.getComponent("location");
      let locationSelect2 = form.getComponent("location2");
      let programSelect = form.getComponent("programSession");
      let programSelect2 = form.getComponent("programSession2");
      let semesterSelect = form.getComponent("semester");
      let semesterSelect2 = form.getComponent("semester2");
      let approvalRequest = form.getComponent("request-approval-button");
      let anotherProgramButton = form.getComponent("anotherProgram");
      let secondaryPassport = form.getComponent("PassportSection02");
      let statementOfPurpose = form.getComponent("StatementofPurposeText");
      let totalFeeHtml = form.getComponent("totalFeeHtml");

      if (statementOfPurpose && this.program.statement_of_purpose) {
        statementOfPurpose.component.content = this.program.statement_of_purpose;
        statementOfPurpose.redraw();
      }

      if (this.inProgress) {
        if (anotherProgramButton) {
          form.removeComponent(anotherProgramButton);
        }
      }

      if (totalFeeHtml) {
        this.setTotalFeeSection();
        if (this.programHasChange && this.totalFee <= 0) {
          this.setNoFeeProps(form);
        }
      }

      if (approvalRequest) {
        // Fetch advisors
        if (this.studentHasUniversity) {
          this.fetchAdvisors(this.userData.data.schoolname.value);
        }

        // University Approval request
        this.componentSetListener(
          () => {
            this.universityApprovalButton();
          },
          "click",
          "request-approval-button"
        );
      }

      if (secondaryPassport) {
        // Hide section if student does not have dual citizenship
        if (
          Object.hasOwnProperty.call(this.$store.state.userData, "data") &&
          Object.hasOwnProperty.call(
            this.$store.state.userData["data"],
            "ihavedualcitizenship"
          ) &&
          this.$store.state.userData["data"]["ihavedualcitizenship"] === false
        ) {
          form.removeComponent(secondaryPassport);
          form.redraw();
        }
      }

      if (locationSelect) {
        fetch(this.apiBase + "sites", {
          headers: {
            "content-type": "application/json",
          },
          mode: "cors",
        })
          .then((response) => {
            if (!response.ok) throw new Error(response.status);
            response.json().then((result) => {
              let locations = [];
              for (let key in result) {
                locations.push({
                  label: result[key]["label"],
                  value: result[key]["value"],
                });
              }
              this.sites = locations;
              locationSelect.component.data.values = locations;
            });
          })
          .catch((error) => {
            console.error(error);
            Sentry.captureException(
              new Error("Fetch Sites (Program Selection)"),
              {
                tags: {
                  error: error,
                },
              }
            );
          });
      }

      form.on("change", (event) => {
        //detect file upload
        if (
          event.changed &&
          event.changed.component.customClass === "fileUploadField"
        ) {
          if (event.changed.value.length) {
            for (var i = 0; i < event.changed.value.length; i++) {
              let index = this.uploading.indexOf(
                event.changed.value[i].originalName
              );
              if (index !== -1) {
                console.log(
                  "removing " +
                    event.changed.value[i].originalName +
                    " from uploading array"
                );
                this.uploading.splice(index, 1);
                //Remove wait message if it is present and no uploading pending
                if (!this.uploading.length) {
                  let element = document.getElementById("upload-wait-message");
                  if (typeof element != "undefined" && element != null) {
                    element.remove();
                  }
                }
              }
            }
          }
        }
        if (
          event.changed &&
          (event.changed.component.key === "location" ||
            event.changed.component.key === "location2") &&
          event.changed.value
        ) {
          this.handleLocationChange(
            event.changed.value,
            event.changed.component.key,
            form
          );
        }

        // Watch for term/semester change and set in Vuex so our billing logic can reference
        if (event.changed && event.changed.component.key === "semester") {
          this.$store.commit("setTerm", event.changed.value);
        }

        if (
          event.changed &&
          (event.changed.component.key === "semester" ||
            event.changed.component.key === "semester2") &&
          event.changed.value
        ) {
          this.semester = event.changed.value;
          fetch(
            this.apiBase +
              "programs/" +
              this.location +
              "/" +
              this.semester.toLowerCase(),
            {
              headers: {
                "content-type": "application/json",
              },
              mode: "cors",
            }
          )
            .then((response) => {
              if (!response.ok) throw new Error(response.status);
              response.json().then((result) => {
                let programs = [];
                for (let key in result) {
                  programs.push({
                    label: result[key]["name"],
                    value: result[key]["id"],
                    shortcut: "",
                    disabled: this.invalidProgramSession(result[key]["id"]),
                  });
                }
                this.programs = result;
                if (event.changed.component.key === "semester") {
                  this.updateApplicationId("");
                  programSelect.component.values = programs;
                  programSelect.redraw();
                } else if (event.changed.component.key === "semester2") {
                  this.setSecondaryApplicationId("");
                  programSelect2.component.values = programs;
                  programSelect2.redraw();
                }
              });
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(
                new Error("Fetch Session Programs (Program Selection)"),
                {
                  tags: {
                    error: error,
                  },
                }
              );
            });
        }

        if (
          event.changed &&
          (event.changed.component.key === "programSession" ||
            event.changed.component.key === "programSession2") &&
          event.changed.value
        ) {
          let appId =
            Date.now() +
            event.changed.value +
            Math.random().toString().substring(3);
          appId = appId.substring(0, 32);
          if (event.changed.component.key === "programSession") {
            this.$emit("select-program", event.changed.value);
            this.updateApplicationId(appId);
          } else if (event.changed.component.key === "programSession2") {
            this.setSecondaryApplicationId(appId);
          }
        }
        if (
          event.changed &&
          event.changed.component.key === "semester" &&
          event.changed.value === ""
        ) {
          this.$emit("select-program", null);
          this.updateApplicationId("");
        }

        if (
          !this.studentHasUniversity &&
          event.changed &&
          event.changed.component.key === "school-name" &&
          event.changed.value.value !== ""
        ) {
          this.fetchAdvisors(event.changed.value.value);
        }

        // Virtual/abroad selection
        if (
          event.changed &&
          event.changed.component.key === "experienceType" &&
          event.changed.value !== ""
        ) {
          this.changesLocation(locationSelect);

          if (event.changed.value === "virtual") {
            //clear program data just in case there was one previusly selected
            this.$store.commit("clearProgram");
            this.$store.commit("setTerm", "");
            document
              .querySelector(".formio-component-columns1 > div")
              .classList.add("hidden");
            document
              .querySelector(".formio-component-form")
              .classList.add("programslist-virtual");
            this.location = {
              label: this.virtual.label,
              value: this.virtual.value,
            };
            form._data.location = this.location;
            this.handleLocationChange(this.location, "location", form);
          } else if (event.changed.value === "abroad") {
            //clear program data just in case there was one previusly selected
            this.$store.commit("clearProgram");
            this.$store.commit("setTerm", "");
            document
              .querySelector(".formio-component-columns1 > div")
              .classList.remove("hidden");
            document
              .querySelector(".formio-component-form")
              .classList.remove("programslist-virtual");
            this.location = {};
            form._data.location = this.location;
            locationSelect.setValue(this.location);
            semesterSelect.setValue("");
            programSelect.setValue("");
          }
        }

        if (
          event.changed &&
          event.changed.component.key === "experienceType2" &&
          event.changed.value !== ""
        ) {
          if (event.changed.value === "virtual") {
            document
              .querySelector(".formio-component-columns2 > div")
              .classList.add("hidden");
            document
              .querySelector(".formio-component-panel1")
              .classList.add("programslist-virtual");

            let location2 = {
              label: this.virtual.label,
              value: this.virtual.value,
            };

            form._data.location2 = location2;
            this.handleLocationChange(location2, "location2", form);
          } else if (event.changed.value === "abroad") {
            document
              .querySelector(".formio-component-columns2 > div")
              .classList.remove("hidden");
            document
              .querySelector(".formio-component-panel1")
              .classList.remove("programslist-virtual");
            let location2 = {};
            form._data.location2 = location2;
            locationSelect2.setValue(location2);
            semesterSelect2.setValue("");
            programSelect2.setValue("");
          }
        }
      });

      form.on("submitButton", (obj) => {
        let submitButton = form.getComponent(obj.component.key);
        submitButton.refs.button.disabled = true;
      });

      form.on("error", (errorEvent) => {
        if (errorEvent.length >= 1) {
          // Scroll to top if errors
          window.scrollTo(0, 0);
        }
      });

      form.on("submitDone", (submission) => {
        this.logSubmission(form._form.path, {}, submission.data);

        if (this.previousApplication["previousApplicationId"]) {
          // Only execute if in program selection form
          if (Object.hasOwnProperty.call(submission.data, "experienceType")) {
            this.updateProgramChange(submission, "application"); // Set new application
          } else if (
            Object.hasOwnProperty.call(submission.data, "cardNumber")
          ) {
            this.updateProgramChange(submission, "payment"); // Finish program change
            this.$store.commit(
              "updateChangeNotPending",
              this.previousApplication["previousApplicationId"]
            );
          }
        }
      });
    });

    //send recommendation request email
    this.form.then((form) => {
      let requestBtn = form.getComponent("RecommendationFormSendRequest1");
      let requestBtn2 = form.getComponent("RecommendationButton2");
      if (requestBtn || requestBtn2) {
        let vm = this;

        document.addEventListener("click", function (e) {
          if (
            (e.target && e.target.id == "RecommendationFormSendRequest1Btn") ||
            (e.target.parentNode &&
              e.target.parentNode.id == "RecommendationFormSendRequest1Btn")
          ) {
            let teacher = form.getComponent("recommendation-name-01");
            let email = form.getComponent("recommendation-email-01");

            let ReqData = {
              btn: requestBtn,
              email: email.getValue(),
              teacher: teacher.getValue(),
            };
            vm.HandleRecommendationRequest(ReqData);
            e.stopPropagation();
          }

          if (
            (e.target && e.target.id == "RecommendationFormSendRequest2Btn") ||
            (e.target.parentNode &&
              e.target.parentNode.id == "RecommendationFormSendRequest2Btn")
          ) {
            let teacher = form.getComponent("recommendation-name-02");
            let email = form.getComponent("recommendation-email-02");

            let ReqData = {
              btn: requestBtn2,
              email: email.getValue(),
              teacher: teacher.getValue(),
            };
            vm.HandleRecommendationRequest(ReqData);
            e.stopPropagation();
          }
        });
      }
    });

    // Pre populate academic information school and major
    this.form.then((form) => {
      if (form.getComponent("school-name")) {
        if (this.userData.data.schoolname) {
          form
            .getComponent("school-name")
            .setValue(this.userData.data.schoolname);
        }
      }
      if (form.getComponent("major")) {
        if (this.userData.data.major) {
          form
            .getComponent("major")
            .setValue({ name: this.userData.data.major });
        }
      }
    });

    this.form.then((form) => {
      let appReceived = form.getComponent("applicationReceivedContainer");
      if (appReceived) {
        form.submit();
        //get the element that will contain the student first and last name and set it accordingly
        document.getElementById(
          "app-student-name"
        ).innerText = this.getStudentName;

        //get the element that will contain the program city and set it accordingly
        document.getElementById("app-program-city").innerText = this.getCity;

        //get the element that will contain the student first name and set it accordingly
        document.getElementById(
          "app-student-firstname"
        ).innerText = this.getStudentFirstName;

        //get the element that will contain the program coordinator name and set it accordingly
        document.getElementById(
          "coordinator-name"
        ).innerText = this.getManagerName;

        //get the element that will contain the program coordinator email and set it accordingly
        document.getElementById("coordinator-email").innerText = this.getEmail;
        document.getElementById("coordinator-email").href =
          "mailto:" + this.getEmail;
      }
    });

    this.form.then((form) => {
      form.on("submit", (submission) => {
        this.draftSubmission(submission);
      });
    });

    this.form.then((form) => {
      form.on("customEvent", (event) => {
        if (event.type === "applyDiscountCode") {
          this.applyDiscountCode(event.data.promotionCode, form);
        }
      });
    });

    this.form.then((form) => {
      form.on("change", (event) => {
        //get location component
        let locationSelect = form.getComponent("location");
        let semesterSelect = form.getComponent("semester");
        let programSelect = form.getComponent("programSession");
        if (
          !this.location &&
          locationSelect &&
          event.data.location &&
          event.data.semester
        ) {
          this.location = event.data.location;
          this.semester = event.data.semester;
          locationSelect.setValue(this.location);
          semesterSelect.setValue(this.semester);

          fetch(
            this.apiBase +
              "programs/" +
              this.location.value +
              "/" +
              this.semester.toLowerCase(),
            {
              headers: {
                "content-type": "application/json",
              },
              mode: "cors",
            }
          ).then((response) => {
            response.json().then((result) => {
              let programs = [];
              for (let key in result) {
                programs.push({
                  label: result[key]["name"],
                  value: result[key]["id"],
                  shortcut: "",
                  disabled: this.invalidProgramSession(result[key]["id"]),
                });
              }
              this.programs = result;
              programSelect.component.values = programs;
              programSelect.redraw();
            });
          });
        }
      });
    });
  },
  methods: {
    universityApprovalStatusMsg() {
      let statusMsg = "";
      switch (this.approvalStatusBehaviour) {
        case "showApproved":
          statusMsg =
            "We received the University Approval form from your advisor on " +
            this.getCurrentStudentApplication["advisorApproval"][
              "formSubmittedDate"
            ] +
            ".";
          break;
        case "showMailSent":
          statusMsg = "An email request has been sent to your advisor.";
          break;
      }
      return statusMsg;
    },
    addOrUpdateApprovalStatus(createParagraph = true, msg = "") {
      let element = "";
      if (createParagraph) {
        element = document.createElement("p");
        element.setAttribute("id", "approval-status-id");
        element.innerText = msg;
        this.addChildToElement("selectAdvisor-label", element, true);
        return;
      }
      if (!this.doesElementIdExists("approval-status-id")) return;
      element = document.getElementById("approval-status-id");
      element.innerText = msg;
    },
    guardAddApprovalStatus() {
      if (!this.doesElementIdExists("selectAdvisor-label")) return;
      let message = this.universityApprovalStatusMsg();
      this.addOrUpdateApprovalStatus(
        !this.doesElementIdExists("approval-status-id"),
        message
      );
    },
    guardSubmittedUniversityApproval(formObject) {
      if (!this.objectHasProperty(formObject, "data")) return;
      if (!this.objectHasProperty(formObject["data"], "sentApproval")) return;
      if (!formObject["data"]["sentApproval"]) return;
      this.submittedApprovalStyleChange();
    },
    guardSubmittedLetterOfRec(formObject, letterNumber) {
      let letterApiName = "sentLetterOfRecommendation" + letterNumber;
      if (!this.objectHasProperty(formObject, "data")) return;
      if (!this.objectHasProperty(formObject["data"], letterApiName)) return;
      if (!formObject["data"][letterApiName]) return;
      this.submittedLetterRecStyleChange(letterNumber);
    },
    submittedLetterRecStyleChange(letterNumber) {
      let letterApiName = "RecommendationFormSendRequest" + letterNumber;
      this.letterOfRecSent["letter" + letterNumber] = true;
      this.componentLabel("Resend email", letterApiName);
    },
    submittedApprovalStyleChange() {
      this.advisorApprovalSent = true;
      this.componentLabel("Resend", "request-approval-button");
      this.componentChangeCustomClass(
        `text-${this.secondaryColorClass} bg-white hover:text-white border-${this.secondaryColorClass}`,
        [
          this.secondaryColor
            ? `hover:bg-${this.secondaryColor} hover:border-${this.secondaryColor}`
            : "hover:bg-teal-100 hover:border-teal-98",
        ],
        "request-approval-button",
        `bg-${this.tertiaryColorClass}`,
        [
          this.tertiaryColor
            ? `hover:bg-${this.tertiaryColor}  focus:bg-${this.tertiaryColor}`
            : "hover:bg-yellow-900 focus:bg-yellow-900",
        ]
      );
      this.componentSetListener(
        () => {
          this.universityApprovalButton();
        },
        "click",
        "request-approval-button"
      );
    },
    applyDiscountCode(code, form) {
      let discountInput = form.getComponent("promotionCode");
      const body = {
        user: this.userData,
      };

      if (this.validDiscountCode) {
        discountInput.addMessages([
          {
            level: "success",
            message:
              "You have already applied a code successfully; please continue.",
          },
        ]);
        let input = document.getElementsByName("data[promotionCode]")[0];
        input.classList.remove("bg-error-100");
        input.classList.add("bg-success-100");
        return;
      }

      fetch("/discounts/" + code, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json",
        },
        mode: "cors",
      })
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(() => {
          this.validDiscountCode = true;
          this.removePanel(form, "cardInformation");
          this.setTotalFeeSection();
          discountInput.addMessages([
            {
              level: "success",
              message: "Code applied successfully",
            },
          ]);
          let input = document.getElementsByName("data[promotionCode]")[0];
          input.classList.remove("bg-error-100");
          input.classList.add("bg-success-100");
        })
        .catch(() => {
          discountInput.addMessages([
            {
              level: "error",
              message: "Invalid code",
            },
          ]);
          let input = document.getElementsByName("data[promotionCode]")[0];
          input.classList.remove("bg-success-100");
          input.classList.add("bg-error-100");
          Sentry.captureException(new Error("Discount Error"), {
            tags: {
              error: "Invalid Code",
            },
          });
        });
    },
    removePanel(form, panelName) {
      let panel = form.getComponent(panelName);
      if (panel) {
        form.removeComponent(panel);
        form.redraw();
      }
    },
    whichLetterWasClicked(data) {
      if (!this.objectHasProperty(data, "btn")) return 0;
      if (!this.objectHasProperty(data["btn"], "component")) return 0;
      if (!this.objectHasProperty(data["btn"]["component"], "key")) return 0;
      if (data["btn"]["component"]["key"] === "RecommendationFormSendRequest1")
        return 1;
      if (data["btn"]["component"]["key"] === "RecommendationFormSendRequest2")
        return 2;
      return 0;
    },
    HandleRecommendationRequest(data) {
      let letterClicked = this.whichLetterWasClicked(data);
      //update button label while sending
      data.btn.component.label = "Sending email...";
      data.btn.redraw();
      //send request to enrollment service
      if (data.email && this.applicationId) {
        // create post params
        let postData = {
          program_session_id: this.selectedProgramId,
          application_id: this.applicationId,
          name: data.teacher,
          email: data.email,
        };

        //send request to enrollment service
        fetch(this.enrollmentBase + "recommendation", {
          method: "post",
          body: JSON.stringify(postData),
          headers: {
            "Content-type": "application/json",
            "x-jwt-token": this.formioToken,
          },
          mode: "cors",
        })
          .then((response) => {
            if (response.status == 200) {
              data.btn.component.label = "Resend email";
              data.btn.redraw();
              if (letterClicked) {
                this.letterOfRecSent["letter" + letterClicked] = true;
              }
            }
          })
          .catch((e) => {
            console.warn(e);
          });
      }
    },
    responseHandler(response) {
      let errors = [];
      return new Promise(function (resolve, reject) {
        if (response.messages.resultCode === "Error") {
          response.messages.message.forEach(function (message) {
            errors.push({
              message: message.code + ": " + message.text,
              path: ["submit"],
            });
            reject(errors);
          });
        }
        resolve(response);
      });
    },
    updateApplicationId(id) {
      this.form.then((form) => {
        form.submission.data.application_id = id;
      });
      this.$emit("set-application-id", id);
    },
    processFormRules(form) {
      if (this.program.form_rules && this.program.form_rules.length) {
        let componentsToRemove = [];
        FormioUtils.eachComponent(
          form.components,
          (component) => {
            const componentRuleName =
              component.component.properties["data-label"];
            if (componentRuleName) {
              if (!this.program.form_rules.includes(componentRuleName)) {
                componentsToRemove.push(component);
              }
            }
          },
          true
        );
        componentsToRemove.forEach((component) =>
          form.removeComponent(component)
        );
        form.redraw();
        //on program selection form if there is a form redraw we need to recheck if the experience type selected
        //is a virtual program and hide the location select box accordingly
        if (
          form._data.experienceType !== "undefined" &&
          form._data.experienceType === "virtual"
        ) {
          document
            .querySelector(".formio-component-columns1 > div")
            .classList.add("hidden");
          document
            .querySelector(".formio-component-form")
            .classList.add("programslist-virtual");
        }
        //Do same for secondary application
        if (
          form._data.experienceType2 !== "undefined" &&
          form._data.experienceType2 === "virtual"
        ) {
          document
            .querySelector(".formio-component-columns2 > div")
            .classList.add("hidden");
          document
            .querySelector(".formio-component-panel1")
            .classList.add("programslist-virtual");
        }
      }
    },
    draftSubmission(submission) {
      // Does not allow next-step event if submission is a draft
      if (submission.state !== "draft") {
        this.sendNotifications();
        this.$emit("form-submit");
      } else if (submission.state === "draft") {
        // Scroll to top to let user know submission status
        window.scrollTo(0, 0);
      }
    },
    fetchAdvisors(universityId) {
      formService
        .listSubmissions("advisor", {
          "data.university.value": universityId,
          limit: 200,
        })
        .then((advisors) => {
          this.setAdvisors(
            advisors.filter(
              (advisor) =>
                !(
                  advisor?.data?.apiAbroadAdmin ||
                  advisor?.data?.hideFromApprovalList
                )
            )
          );
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
    setAdvisors(advisors) {
      this.form.then((form) => {
        let advisorRadios = form.getComponent("selectAdvisor");
        let advisorShowOtherControl = form.getComponent("showOtherAdvisor"); // Extra logic to display advisor list
        for (let key in advisors) {
          advisorRadios.component.values.unshift({
            value:
              advisors[key]["data"]["firstname"] +
              "|" +
              advisors[key]["data"]["lastname"] +
              "|" +
              advisors[key]["data"]["email"],
            label:
              advisors[key]["data"]["firstname"] +
              " " +
              advisors[key]["data"]["lastname"] +
              ": " +
              advisors[key]["data"]["email"],
            shortcut: "",
          });
        }
        // No advisors found
        if (advisors.length === 0) {
          // dont show radios, change copy
          const noAdvisorCopy =
            "API requires that all applicants obtain approval from their home university, generally through the Study Abroad Office or the appropriate home university advisor responsible for study abroad and/or credit approval. Please type your advisor's name and email below and we will send them a digital University Approval Form via email. ";
          advisorShowOtherControl.setValue("show");
          advisorShowOtherControl.redraw();
          this.changeInnerText("selectAdvisor-label", noAdvisorCopy);
        } else {
          // show radios, keep copy
          advisorShowOtherControl.setValue("showRadios");
          advisorShowOtherControl.redraw();
          // remove default option
          let newAdvisorList = advisorRadios.component.values.filter(
            (advisor) => {
              return advisor.value !== "other";
            }
          );
          advisorRadios.component.values = newAdvisorList;
          advisorRadios.redraw();
        }
      });
    },
    sendUniversityApprovalRequest(
      firstname = "",
      lastname = "",
      email = "",
      programType = "",
      applicationId = "",
      studentId = ""
    ) {
      this.componentActive(false, "request-approval-button");
      this.componentLabel("Requesting...", "request-approval-button");
      if (this.advisorApprovalSent) {
        this.componentChangeCustomClass(
          `text-${this.secondaryColorClass} bg-white hover:text-white border-${this.secondaryColorClass}`,
          [
            this.secondaryColor
              ? `hover:bg-${this.secondaryColor} hover:border-${this.secondaryColor}`
              : "hover:bg-teal-100 hover:border-teal-98",
          ],
          "request-approval-button",
          `bg-${this.tertiaryColorClass}`,
          [
            this.tertiaryColor
              ? `hover:bg-${this.tertiaryColor}  focus:bg-${this.tertiaryColor}`
              : "hover:bg-yellow-900 focus:bg-yellow-900",
          ]
        );
      }
      fetch(this.enrollmentBase + "approval", {
        method: "POST",
        headers: {
          accept: "application/json",
          "x-jwt-token": this.formioToken,
        },
        body: this.universityApprovalFormData(
          firstname,
          lastname,
          email,
          applicationId,
          programType
        ),
      })
        .then((response) => {
          if (response.status === 200) {
            this.advisorApprovalSent = true;
            this.componentActive(true, "request-approval-button");
            this.componentLabel("Resend", "request-approval-button");
            this.componentChangeCustomClass(
              `text-${this.secondaryColorClass} bg-white hover:text-white border-${this.secondaryColorClass}`,
              [
                this.secondaryColor
                  ? `hover:bg-${this.secondaryColor} hover:border-${this.secondaryColor}`
                  : "hover:bg-teal-100 hover:border-teal-98",
              ],
              "request-approval-button",
              `bg-${this.tertiaryColorClass}`,
              [
                this.tertiaryColor
                  ? `hover:bg-${this.tertiaryColor}  focus:bg-${this.tertiaryColor}`
                  : "hover:bg-yellow-900 focus:bg-yellow-900",
              ]
            );
            this.componentSetListener(
              () => {
                this.universityApprovalButton();
              },
              "click",
              "request-approval-button"
            );
          } else {
            throw response;
          }
        })
        .catch((response) => {
          response.text().then((text) => {
            Sentry.captureException(new Error("University Approval: " + text), {
              tags: {
                email: email,
                application_id: applicationId,
              },
            });
          });
          this.componentActive(true, "request-approval-button");
          this.componentLabel("Error, resend", "request-approval-button");
          this.componentSetListener(
            () => {
              this.universityApprovalButton();
            },
            "click",
            "request-approval-button"
          );
        });
    },
    removeExceptLast(advisors) {
      // Removes all advisor options except last
      let newValues = advisors.component.values.slice(
        advisors.component.values.length - 1
      );
      advisors.component.values = newValues;
      advisors.redraw();
    },
    canRequestUniversityApproval(requestType, formData) {
      let can = false;
      let advisorName = "";
      let advisorMail = "";
      switch (requestType) {
        case "radioSelect":
          if (
            formData.data.selectAdvisor !== "other" &&
            formData.data.selectAdvisor !== ""
          ) {
            can = true;
          }
          break;
        case "customUserInput":
          advisorName = formData.getComponent("otherAdvisorsAdvisorName");
          advisorMail = formData.getComponent("advisor-email");
          if (
            formData.data.selectAdvisor === "other" &&
            advisorName.hasSetValue &&
            advisorName.errors.length === 0 &&
            advisorMail.hasSetValue &&
            advisorMail.errors.length === 0
          ) {
            can = true;
          }
          break;
        default:
          can = false;
      }
      return can;
    },
    componentActive(action = false, componentName = "") {
      this.form.then((form) => {
        let component = form.getComponent(componentName);
        component.disabled = action;
        component.redraw();
      });
    },
    componentLabel(label = "", componentName = "") {
      this.form.then((form) => {
        let component = form.getComponent(componentName);
        component.label = label;
        component.redraw();
      });
    },
    componentSetListener(
      method = function () {},
      listenerName = "click",
      componentName = ""
    ) {
      this.form.then((form) => {
        let component = form.getComponent(componentName);
        component.element.addEventListener(listenerName, function () {
          method();
        }); // redraw removes button listeners
      });
    },
    componentChangeCustomClass(
      classes = "",
      componentName = "",
      classesToRemove = ""
    ) {
      this.form.then((form) => {
        let component = form.getComponent(componentName);
        if (component) {
          component.component.customClass = classes;
          component.redraw();
          if (classesToRemove) {
            this.removeComponentClasses(component, classesToRemove);
          }
        }
      });
    },
    removeComponentClasses(component, classes) {
      // Made for formio components
      if (typeof classes !== "string" || !classes) return;
      if (component === null || component === undefined) return;
      if (!Object.hasOwnProperty.call(component, "element")) return;
      if (!("children" in component["element"])) return;
      if (!component["element"]["children"].length) return;

      setTimeout(() => {
        classes.split(" ").forEach((cssClass) => {
          component["element"]["children"][0]["classList"].remove(cssClass);
        });
      });
    },
    async universityApprovalButton() {
      let form = await this.form;
      let formData = await form;
      if (this.canRequestUniversityApproval("radioSelect", formData)) {
        let advisorData = form._data.selectAdvisor.split("|");
        this.sendUniversityApprovalRequest(
          advisorData[0], // first name
          advisorData[1], // last name
          advisorData[2], // email
          this.isInternship,
          this.applicationId,
          this.studentId
        );
      } else if (
        this.canRequestUniversityApproval("customUserInput", formData)
      ) {
        this.sendUniversityApprovalRequest(
          formData.data.otherAdvisorsAdvisorName,
          "",
          formData.data["advisor-email"],
          this.isInternship,
          this.applicationId,
          this.studentId
        );
      }
    },
    universityApprovalFormData(firstname, lastname, email, application_id) {
      let body = new FormData();
      body.append("email", email);
      body.append("name", firstname + " " + lastname);
      body.append("application_id", application_id);
      body.append("program_session_id", this.selectedProgramId);
      return body;
    },
    sendNotifications() {
      let firstSubmit = this.formUrl.indexOf("/submission/") === -1;

      if (this.formNotification.length > 0 && firstSubmit) {
        let postData = {
          event: this.formNotification,
          program_session_id: this.selectedProgramId,
        };

        fetch(this.enrollmentBase + "advisor/notification", {
          method: "post",
          body: JSON.stringify(postData),
          headers: {
            "Content-type": "application/json",
            "x-jwt-token": localStorage.getItem("formioToken"),
          },
          mode: "cors",
        }).catch((e) => {
          console.warn(e);
          Sentry.captureException(new Error("Advisor Notification"), {
            tags: {
              error: e,
            },
          });
        });
      }
    },
    setSecondaryApplicationId(appId) {
      this.$emit("set-secondary-application-id", appId);
    },
    secondaryApplicationSubmission(data, nextFunction, applicationId) {
      const payload = {
        application_id: applicationId,
        location: data.location2,
        semester: data.semester2,
        programSession: data.programSession2,
        experienceType: data.experienceType2,
        uiVersion: CONSTANT_KEYS.UI_VERSION,
      };
      formService
        .createSubmission("programselection", payload)
        .then(() => {
          console.log("Secondary Application created successfully");
          this.secondarySubmitted = true;
          nextFunction();
        })
        .catch((error) => {
          console.log("error ", error);
          nextFunction([
            {
              message:
                "Secondary application could not be submitted, due to an external error. " +
                "Try again, if the error persists please remove the secondary program and continue.",
            },
          ]);
        });
    },
    maybeSetUserData() {
      if (!this.$store.state.userData) {
        this.$store.dispatch("cacheFormioUser");
      }
    },
    handleClick(element) {
      let modalTriggers = document.querySelectorAll("[data-modal]");
      modalTriggers.forEach((trigger) => {
        if (trigger.contains(element.target)) {
          this.triggerModal(trigger.dataset.modal);
        }
      });
    },
    triggerModal(modalName) {
      switch (modalName) {
        case "Flex Date Internship Academic Credit Options":
          this.flexDateModal = true;
          break;
        case "Fixed Date Internship Academic Credit Options":
          this.fixedDateModal = true;
          break;
        case "Study and Intern Academic Credit Options":
          this.studyAndInternModal = true;
          break;
      }
    },
    handleLocationChange(location, componentKey, form) {
      let programSelect = form.getComponent("programSession");
      let programSelect2 = form.getComponent("programSession2");
      let semesterSelect = form.getComponent("semester");
      let semesterSelect2 = form.getComponent("semester2");
      this.location = typeof location === "object" ? location.value : location;
      fetch(this.apiBase + "terms/" + this.location, {
        headers: {
          "content-type": "application/json",
        },
        mode: "cors",
      })
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          response.json().then((result) => {
            if (componentKey === "location") {
              semesterSelect.component.data.values = result;
              programSelect.component.values = [];
              semesterSelect.setValue(""); // wipe out previous selection if changing locations
              programSelect.setValue(""); // wipe out previous selection if changing locations
              semesterSelect.redraw();
              programSelect.redraw();
              this.updateApplicationId("");
              this.$emit("select-program", null);

              //Disable semester if program changes
              this.disableSemester(semesterSelect);
            } else if (componentKey === "location2") {
              semesterSelect2.component.data.values = result;
              programSelect2.component.values = [];
              semesterSelect2.setValue("");
              programSelect2.setValue("");
              semesterSelect2.redraw();
              programSelect2.redraw();
              this.setSecondaryApplicationId("");
            }
          });
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(
            new Error("Fetch Site Sessions (Program Selection)"),
            {
              tags: {
                error: error,
              },
            }
          );
        });
    },
    isInFormRules(rule) {
      if (this.program.form_rules && this.program.form_rules.length) {
        if (this.program.form_rules.includes(rule)) {
          return true;
        }
      }
      return false;
    },
    isAllowedFileType(file) {
      return (
        this.allowedFileTypes.includes("*") ||
        this.allowedFileTypes.includes("*." + file.name.split(".").pop()) ||
        this.allowedFileTypes.includes(file.type)
      );
    },
    validFileSize(fileSize) {
      //allow any file size if no rule present
      if (!this.maxFileSize) {
        return true;
      }
      //is the validation rule in bytes, kb,mb or gb
      let ruleSizeExt = this.maxFileSize.replace(/[0-9]/g, "");
      let ruleSizeValue = this.maxFileSize.replace(/[^\d.-]/g, "");

      //convert max file size allowed to bytes
      let ruleMaxSizeInBytes;
      switch (ruleSizeExt.toLowerCase()) {
        case "bytes":
          ruleMaxSizeInBytes = ruleSizeValue;
          break;
        case "kb":
          ruleMaxSizeInBytes = ruleSizeValue * 1024;
          break;
        case "mb":
          ruleMaxSizeInBytes = ruleSizeValue * 1024 * 1024;
          break;
        case "gb":
          ruleMaxSizeInBytes = ruleSizeValue * 1024 * 1024 * 1024;
          break;
        default:
          //type of file size does nt match, allow any
          return true;
          break;
      }
      return ruleMaxSizeInBytes > fileSize;
    },
    changesLocation(locationSelect) {
      let locationURL = this.apiBase + "sites";
      const session = encodeURIComponent(
        this.previousApplication.session.toLowerCase()
      );

      locationURL = this.programHasChange
        ? locationURL + "/" + session
        : locationURL;

      locationSelect.component.data.url = locationURL;
    },
    disableSemester(dropbox) {
      if (this.programHasChange) {
        dropbox.disabled = true;
        dropbox.setValue(this.previousApplication.session);
      }
    },
    setTotalFeeSection() {
      document.getElementById("depositAmount").innerText = this.depositAmount;
      document.getElementById(
        "availableCredit"
      ).innerText = this.availableCredit;
      document.getElementById("totalFee").innerText = this.totalFee;
    },
    updateProgramChange(submission, action) {
      const updateInstructions =
        {
          application: [
            {
              op: "add",
              path: "/data/newApplicationId",
              value: submission.data["application_id"],
            },
          ],
          payment: [
            {
              op: "add",
              path: "/data/changeFinished",
              value: true,
            },
          ],
        }[action] || [];
      formService
        .partiallyUpdateSubmission(
          "programchanges",
          this.previousApplication.submissionId,
          updateInstructions
        )
        .then(() => {
          if (action === "payment") {
            this.$store.commit("setPreviousApplicationIdByField", {
              handle: "finished",
              value: true,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    },
    setNoFeeProps(form) {
      this.removePanel(form, "cardInformation");
      this.removePanel(form, "applyPromotion");
      this.setTotalFeeSection();
    },
    invalidProgramSession(prograSession) {
      return this.studentApplications.some(
        (app) =>
          app.programSession.salesforce_id === prograSession &&
          app.applicationPaid
      );
    },
  },
};
</script>
<style scoped>
.forceBtnCenter {
  display: block;
  margin: 0 auto;
}

.file {
  border: 5px solid red;
}
</style>
