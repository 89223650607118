<template>
  <RouterLink
    :id="`view-button-${programPageId}`"
    :to="redirectUrl"
    @click.stop
  >
    <div class="md:flex md:items-center md:justify-center">
      <button
        v-if="showViewLink"
        class="flex gap-x-3 justify-center items-center text-sm font-bold uppercase cursor-pointer"
        :class="
          secondaryColor
            ? `${secondaryColorClass}`
            : `text-university-secondary`
        "
        :aria-label="`View ${program?.program_page?.name} program information.`"
      >
        <span class="leading-none">View </span>
        <ArrowRight custom-class="w-5 h-5" />
      </button>
    </div>
  </RouterLink>
  <ApplyButton
    data-testid="apply-button"
    :id="`apply-button-${programPageId}`"
    type="button"
    :class="customApplyClass"
    :outlined="true"
    :disabled="isPastAppDeadline || (disabledApply && !selected)"
    :event-category="eventCategory"
    @click.stop="$emit('applyClicked', program)"
  />
</template>

<script setup>
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import ApplyButton from "@/components/shared/Button/ApplyButton.vue";
import { RouterLink } from "vue-router";
import { computed } from "vue";

const props = defineProps({
  showViewLink: { type: Boolean, default: true },
  redirectUrl: { type: String, default: "/" },
  program: { type: Object, default: () => ({}) },
  customApplyClass: { type: String, default: "" },
  isPastAppDeadline: { type: Boolean, default: false },
  disabledApply: { type: Boolean, default: false },
  selected: { type: Boolean, default: false },
  eventCategory: { type: String, default: "Programs" },
});

const programPageId = computed(() => {
  return props.program?.program_page?.id;
});

defineEmits(["applyClicked"]);
</script>
