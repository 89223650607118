<script setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import ArrowDownIcon from "./ArrowDownIcon.vue";
import { defineProps } from "vue";
import { asia, europe, latin, north, south } from "../../../constants";

const props = defineProps({
  labelClass: {
    type: String,
    default: "",
  },
});

const sortByObjectName = (arr) => {
  return arr.sort((a, b) =>
    a.name.localeCompare(b.name, "en", { sensitivity: "base" })
  );
};
</script>

<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton
      class="inline-flex items-center gap-x-2 font-montserrat non-italic font-bold text-base leading-10 text-indigo-base text-15p outline-none focus:ring-0"
      :class="props.labelClass"
    >
      Destinations
      <ArrowDownIcon
        custom-class="w-5 h-3"
        :class="{ 'rotate-180 transform': open }"
        aria-hidden="true"
      />
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 -translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-1"
    >
      <PopoverPanel
        class="font-montserrat md:absolute inset-x-0 md:left-1/2 z-10 bg-white w-screen max-w-max -translate-x-1/2 outline-none focus:ring-0 md:shadow-sm ml-24 md:m-0 pt-4 custom-popover"
      >
        <div class="mx-auto max-w-2xl">
          <div class="block md:grid grid-cols-2 gap-x-6 sm:gap-x-8">
            <div class="p-6">
              <h3 class="text-sm font-medium leading-6 text-gray-500 uppercase">
                Asia / Middle East
              </h3>
              <hr />
              <div class="grid grid-cols-3">
                <a
                  v-for="(item, index) in sortByObjectName(asia)"
                  :id="'asiamiddleeastlink-' + index"
                  :key="item.name"
                  :href="item.href"
                  :class="{
                    'leading-4': index === 3,
                    'leading-6': index !== 3,
                    'col-span-1': index === 3,
                  }"
                  class="text-sm font-medium text-gray-900 capitalize text-indigo-base mt-2"
                >
                  {{ item.name }}
                </a>
              </div>

              <h3
                class="text-sm font-medium leading-6 text-gray-500 uppercase mt-3"
              >
                Europe
              </h3>
              <hr />
              <div class="grid grid-cols-3">
                <a
                  v-for="(item, index) in sortByObjectName(europe)"
                  :id="'europelink-' + index"
                  :key="item.name"
                  :href="item.href"
                  class="text-sm font-medium text-gray-900 capitalize text-indigo-base mt-2"
                  :class="{
                    'leading-4': index === 9,
                    'leading-6': index !== 9,
                    'col-span-1': index === 9,
                  }"
                >
                  {{ item.name }}
                </a>
              </div>
            </div>
            <div class="p-6">
              <h3 class="text-sm font-medium leading-6 text-gray-500 uppercase">
                Latin America
              </h3>
              <hr />
              <div class="grid grid-cols-2">
                <a
                  v-for="item in sortByObjectName(latin)"
                  :id="'latinamericalink-' + index"
                  :key="item.name"
                  :href="item.href"
                  class="text-sm font-medium leading-6 text-gray-900 capitalize text-indigo-base mt-2"
                >
                  {{ item.name }}
                </a>
              </div>

              <h3
                class="text-sm font-medium leading-6 text-gray-500 uppercase mt-3"
              >
                North America
              </h3>
              <hr />
              <div class="grid grid-cols-2">
                <a
                  v-for="item in sortByObjectName(north)"
                  :id="'northamericalink-' + index"
                  :key="item.name"
                  :href="item.href"
                  class="text-sm font-medium leading-6 text-gray-900 capitalize text-indigo-base mt-2"
                >
                  {{ item.name }}
                </a>
              </div>

              <h3
                class="text-sm font-medium leading-6 text-gray-500 uppercase mt-3"
              >
                South Pacific
              </h3>
              <hr />
              <div class="grid grid-cols-2">
                <a
                  v-for="item in sortByObjectName(south)"
                  :id="'southpacificlink-' + index"
                  :key="item.name"
                  :href="item.href"
                  class="text-sm font-medium leading-6 text-gray-900 capitalize text-indigo-base mt-2"
                >
                  {{ item.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
