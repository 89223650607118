<template>
  <div>
    <form-panel
      id="host-institution-overview"
      title="Overview"
      panel-content-class="grid grid-cols-1 pb-6 mb-6 border-b border-gray-200 md:grid-cols-1"
      panel-button-text-class="tracking-normal"
    >
      <template #content>
        <p class="italic 2xs pb-2">
          * Information in the Overview section cannot be edited in API Connect.
          If you notice an error, please make updates on the institution’s
          account in Salesforce.
        </p>
        <div
          class="flex justify-center p-8 border border-gray-300 rounded-sm bg-white"
        >
          <div
            class="grid grid-cols-3-custom mt-1 gap-4 w-full text-base justify-items-center"
          >
            <div class="w-full">
              <span class="block font-semibold text-gray-700">
                Name
              </span>
              <p class="mt-2" data-cy="sidebar-program-name-label">
                {{ hostData?.name }}
              </p>
            </div>
            <div class="w-full">
              <span class="font-semibold text-gray-700">
                Accreditation
              </span>
              <p class="mt-2" data-cy="sidebar-program-name-label">
                {{ hostData?.accreditation?.join(", ") || "Not available" }}
              </p>
            </div>
            <div class="w-full xl">
              <span class="font-semibold text-gray-700">
                Total number of students
              </span>
              <p class="mt-2" data-cy="sidebar-program-name-label">
                {{
                  entitySupplementData?.total_number_of_students ??
                  "Not available "
                }}
              </p>
            </div>
            <div class="w-full">
              <span class="block font-semibold text-gray-700">
                Percentage of international students
              </span>
              <p class="mt-2" data-cy="sidebar-program-name-label">
                {{
                  Number.isFinite(
                    entitySupplementData?.percentage_international_students
                  )
                    ? entitySupplementData?.percentage_international_students +
                      "%"
                    : "Not available"
                }}
              </p>
            </div>
            <div class="md:items-center flex w-full">
              <div class="block lg:w-full">
                <span class="block font-semibold text-gray-700">
                  Language(s) of instruction
                </span>
                <p class="mt-2" data-cy="sidebar-program-name-label">
                  {{ languages }}
                </p>
              </div>
            </div>
            <div class="w-full">
              <span class="block font-semibold text-gray-700">
                Accommodates accessibility needs
              </span>
              <p class="mt-2" data-cy="sidebar-program-name-label">
                {{ accessibility_accomodations }}
              </p>
            </div>
            <div class="md:items-center flex justify-left w-full">
              <div class="block lg:w-full">
                <span class="block font-semibold text-gray-700">
                  Credit Type
                </span>
                <p class="mt-2" data-cy="sidebar-program-name-label">
                  {{ hostData?.credit_unit?.name || "Not available" }}
                </p>
              </div>
            </div>
            <div class="w-full">
              <span class="block font-semibold text-gray-700">
                Campus Setting
              </span>
              <p class="mt-2" data-cy="sidebar-program-name-label">
                {{ hostData?.locale || "Not available" }}
              </p>
            </div>
            <div class="w-full col-start-1">
              <span class="block font-semibold text-gray-700">
                Address
              </span>
              <p class="mt-2" data-cy="sidebar-program-name-label">
                {{ address }}
              </p>
            </div>
            <div class="mt-6 sm:col-start-1 md:col-start-2 sm:col-end-4">
              <img id="host-map" :src="generateMapUrl(getMapKey)" />
            </div>
          </div>
        </div>
      </template>
    </form-panel>
  </div>
</template>

<script>
import { DEFAULT_GOOGLE_MAP_ZOOM } from "@/constants";
import formPanel from "@/components/forms/SharedComponents/panel.vue";

export default {
  name: "HostInstitutionOverview",
  components: { formPanel },
  props: {
    hostData: {
      type: Object,
      required: true,
      default: () => {},
    },
    entitySupplementData: {
      type: Object,
      required: true,
      default: () => {},
    },
    languageData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getMapKey() {
      return process.env.MIX_UI_GOOGLE_PLACES_API_KEY;
    },
    languages() {
      return this.languageData?.count > 0
        ? `${this.languageData?.items?.map(({ value }) => value).join(", ")}${
            this.languageShowCount
          }`
        : "Not available";
    },
    languageShowCount() {
      return this.languageData.count > this.languageData.end
        ? ` + ${this.languageData.count - this.languageData.end} more languages`
        : "";
    },
    address() {
      return this.loadAddress();
    },
    accessibility_accomodations() {
      switch (this.hostData?.accessibility_accomodations) {
        case true:
          return "Yes";
        case false:
          return "No";
        default:
          return "Not available";
      }
    },
  },
  methods: {
    generateMapUrl(googleApiKey) {
      if (this.address !== "Not available") {
        return `https://maps.googleapis.com/maps/api/staticmap?key=${googleApiKey}&center=${this.address}&zoom=${DEFAULT_GOOGLE_MAP_ZOOM}&format=png&maptype=roadmap&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&size=1200x360&scale=2&markers=color:red%7C${this.address}`;
      }
    },
    loadAddress() {
      if (!this.hostData) return "Not available";
      return (
        [
          this.hostData.shipping_address_city,
          this.hostData.shipping_address_line_1,
          this.hostData.shipping_address_postal_code,
          this.hostData.shipping_address_country,
          this.hostData.name,
        ]
          .filter((item) => item)
          .join(", ")
          .replace(/\r?\n/g, " ") || "Not available"
      );
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 600px) {
  .grid-cols-3-custom {
    grid-template-columns: 1fr 1fr minmax(0, 300px);
  }
}
</style>
