import { mapGetters, mapState } from "vuex";
import { readCookie } from "./helpers";
import { PERMISSIONS } from "../constants";

const userType = "API Employee";

export default {
  computed: {
    ...mapState(["currentUser", "userData"]),
    ...mapGetters({ permissions: "getPermissions" }),
    hasStudentViewPermission() {
      return this.permissions.includes(PERMISSIONS.API_EMPLOYEE_STUDENT_VIEW);
    },
  },
  methods: {
    attachEmployeeToSubmission(body, method) {
      if (this.hasStudentViewPermission) {
        const lastUser = {
          id: this.currentUser.id,
          email: this.currentUser.email,
          userType,
        };

        method = method.toLowerCase();

        if (method === "post" || method === "put") {
          body.data.lastUser = lastUser;
        }
        if (method === "patch") {
          body.push({
            op: "add",
            path: "/data/lastUser",
            value: lastUser,
          });
        }
        if (method === "grid") {
          body.lastUser = lastUser;
        }
      }
      return body;
    },
    logSubmission(form, oldData, newData) {
      if (!this.hasStudentViewPermission) {
        return;
      }
      let submissionBody = {
        form_url: form,
        student_id: this.userData._id,
        user_id: this.currentUser.id,
        user_type: userType,
        old_data: oldData,
        new_data: newData,
      };

      fetch("/student/submission", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-Token": readCookie("XSRF-TOKEN"),
        },
        body: JSON.stringify(submissionBody),
      }).catch(() => console.log("Unable to log submission to db"));
    },
  },
};
