<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import "flatpickr/dist/flatpickr.css";
import { CalendarDaysIcon } from "@heroicons/vue/20/solid";
import flatPickr from "vue-flatpickr-component";
import ErrorMessage from "./ErrorMessage.vue";
import { defineProps } from "vue";

const {
  fieldLabel,
  fieldLabelClasses,
  inputClasses,
  errorClasses,
  vuelidateInstance,
  outerClasses,
} = defineProps({
  fieldLabel: {
    type: String,
  },
  fieldLabelClasses: {
    type: String,
    default: "common-label-text",
  },
  errorClasses: {
    type: String,
  },
  vuelidateInstance: {},
  inputClasses: {
    default:
      "h-10 w-full rounded-md border-gray-300 border shadow-sm sm:text-sm pl-2 custom-date-picker",
  },
  outerClasses: { type: String },
});
</script>
<template>
  <div :class="outerClasses">
    <slot name="fieldLabel">
      <label
        v-show="fieldLabel"
        :name="fieldLabel"
        :class="fieldLabelClasses"
        >{{ fieldLabel }}</label
      >
    </slot>
    <div class="flex flex-grow">
      <div class="relative w-full">
        <flat-pickr
          v-bind="$attrs"
          :class="[
            {
              'bg-error-100': vuelidateInstance?.$errors?.length,
            },
            inputClasses,
            'forced-white-background',
          ]"
          @onChange="vuelidateInstance?.$touch"
        ></flat-pickr>
        <div
          class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5 w-6 h-8 pt-2 mt-1 text-gray-600"
        >
          <CalendarDaysIcon />
        </div>
      </div>
      <slot name="extraField"></slot>
    </div>
    <ErrorMessage
      :error-classes="errorClasses"
      :vuelidate-instance="vuelidateInstance"
    />
  </div>
</template>

<style scope>
.custom-date-picker :deep(.dayContainer.flatpickr-day.selected) {
  background: #2f6095;
  border-color: #2f6095;
}
</style>
