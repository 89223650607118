export const baseFilters = {
  terms: [],
  channel: [],
  status: [],
  city: [],
  country: [],
  host_institution: [],
  sponsor_institution: [],
  tags: [],
  program_session_id: undefined,
  program_leaders: undefined,
  program_session_type: undefined,
  application_deadline_exact: true,
  application_deadline_from: undefined,
  application_deadline_to: undefined,
  start_date_to: undefined,
  start_date_from: undefined,
  program_session_base_price_exact: true,
  academic_year: [],
  base_price_from: undefined,
  base_price_to: undefined,
};
