<script setup>
import ProfileMenuItem from "../shared/Menu/ProfileMenuItem.vue"
import {useAsyncState} from "@vueuse/core";
import { clearCacheRequest } from "@/services/application";
import {useToast} from "vue-toast-notification";

const toast = useToast();
const { execute } = useAsyncState(
    async (type) =>{
      try {
        await clearCacheRequest(type)
        toast.open({
          message: `${type?.charAt(0)?.toUpperCase()}${type?.slice(1)} Cache Cleared`,
          type: "info",
          position: "bottom",
          duration: 5000,
        });
      } catch (e) {
        toast.open({
          message: "Failed to clear cache. Please try again",
          type: "error",
          position: "bottom",
          duration: 5000,
        });
      }
    },
    {},
    { immediate: false, throwError: true }
  );
</script>

<template>
  <ul class="list-none">
    <ProfileMenuItem name="Clear Sessions" description="Clears the search results Sessions cache" @click="() => execute(0, 'sessions')"/>
    <ProfileMenuItem name="Clear Classes" description="Clears the search results Classes cache" @click="() => execute(0, 'classes')"/>
    <ProfileMenuItem name="Clear Internships" description="Clears the search results Internships cache" @click="() => execute(0, 'internships')"/>
    <ProfileMenuItem name="Clear App Cache" description="Clears the entire application cache" @click="() => execute(0, 'all')"/>
  </ul>
</template>
