<template>
  <component :is="layout" :application-id="applicationId" :slug="slug" />
</template>

<script>
import StepsLayout from "@/components/forms/applicationSteps/StepsLayout.vue";
import StepsLayoutV3 from "@/components/forms/applicationSteps/StepsLayoutV3.vue";
import StudentApplication from "@/components/StudentApplication.vue";
import { mapState } from "vuex";

export default {
  // Displays a different application flow depending on the UI version
  name: "LayoutLoader",
  components: { StudentApplication, StepsLayout, StepsLayoutV3 },
  props: {
    applicationId: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      layout: "",
    };
  },
  computed: {
    ...mapState(["uiVersion"]),
  },
  async created() {
    if (this.uiVersion === "v2") {
      // For applications made between Aug, 18 2021 and Jun, 1 2023
      this.layout = "StepsLayout";
      return;
    } else {
      // For applications made before Aug, 18 2021
      this.layout = "StudentApplication";
    }
  },
};
</script>
