<script setup>
import {
  constructFilterCriteria,
  getCourseTrackData,
  updateConjunctionRules,
} from "@/components/ExperiencePage/utils";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";
import {
  useGetClasses,
  useGetHostInstitutionLanguages,
} from "@/components/program-manager/sessions/composable.js";
import { convertClassFilterCriteria } from "@/components/program-manager/sessions/utils";
import ApplyButton from "@/components/shared/Button/ApplyButton.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import { ERROR_TIMEOUT } from "@/constants.js";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import {
  computed,
  defineEmits,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import ClassCard from "./ClassCard.vue";
import TrackSection from "./TrackSection.vue";

const emit = defineEmits(["applyClicked", "observeSection"]);

const props = defineProps({
  sessionId: {
    type: String,
    required: true,
  },
  session: {
    type: Object,
    required: true,
    default: () => {},
  },
});

onMounted(async () => {
  await store.dispatch("experience/getInitialFiltersDataSource", {
    in_use: true,
  });
});

const { sessionId, session } = toRefs(props);
const store = useStore();
const toast = useToast();

const timeFrameOptions = computed(() => {
  return store.getters["experience/getTimeframeOptions"];
});

const numCoursesDisplayed = ref(6);
const hostInstitution = ref(undefined);

const academicLink = ref("");
const baseURL = new URL(
  `${window.location.protocol}//${window.location.host}/results/courses`
);

const state = reactive({
  toggleEmpty: false,
  showPage: false,
  isWrapperCourse: false,
  link_text: "",
  host_institution_url_syllabus: "",
  requiredCourses: [],
  electiveCourses: [],
  trackCourses: [],
});

const {
  execute: executeFetchHostInstitutionLanguagesOptions,
  state: languageData,
} = useGetHostInstitutionLanguages(undefined, false);

const filters = ref({});

const classesParams = ref(convertClassFilterCriteria(filters));
const conjunctionRules = ref(undefined);
const notConjunction = ref([]);
const andConjunction = ref([]);
const orConjunction = ref([]);
const andOrConjunctionRules = ref(undefined);
const anyClassFromLibrary = ref(false);
const anyClassFromList = ref(false);
const track = ref([]);
const isExpanded = ref(false);
const isToggleVisible = ref(false);
const paragraphRef = ref(null);

const isClamped = computed(() => !isExpanded.value);

const toggleExpansion = () => {
  isExpanded.value = !isExpanded.value;
};

const {
  execute: fetchClassesData,
  state: classesData,
  isReady: classesIsReady,
} = useGetClasses(
  { immediate: false, throwError: true, resetOnExecute: false },
  { ...classesParams.value, limit: 10, skip: 0 }
);

const hasCredits = computed(() => {
  return (
    session?.value?.academic_min_credits || session?.value?.academic_max_credits
  );
});

const academicCredits = computed(() => {
  const maxCredits = session?.value?.academic_max_credits ?? "";
  const minCredits = session?.value?.academic_min_credits ?? "";

  if (maxCredits === minCredits) {
    return maxCredits;
  }

  return maxCredits
    ? (!minCredits && maxCredits ? "0" : minCredits) + " - " + maxCredits
    : minCredits;
});

const academicDescription = computed(() => {
  return session?.value?.academic_description;
});

const hasAcademicDescription = computed(() => {
  const academicDescription = session?.value?.academic_description?.content;
  const result =
    academicDescription && Object.values(academicDescription)[0]?.content;
  return result;
});

const languages = computed(() => {
  return languageData.value?.count > 0
    ? languageData?.value?.items
        ?.filter((language) =>
          session?.value?.class_filter_criteria?.required_languages
            .map((language) => language.id)
            .includes(language.id)
        )
        .map((language) => language.value)
    : undefined;
});

const totalRecords = computed(() =>
  classesData?.value?.count && classesData?.value?.count !== -1
    ? classesData.value.count
    : 0
);

const conjuctionHasData = computed(
  () => andConjunction.value.length || orConjunction.value.length
);

const formattedTerms = computed(() => {
  const terms = session.value.terms;
  if (!terms || terms.length === 0) return "";

  const summerTerms = terms.filter(
    (term) => term.session === "Summer" && term.name.match(/Summer \d+/)
  );

  if (summerTerms.length <= 1) return terms[0]?.name || "";

  summerTerms.sort((a, b) => {
    const suffixA = parseInt(a.name.split(" ")[1]) || 0;
    const suffixB = parseInt(b.name.split(" ")[1]) || 0;
    return suffixA - suffixB;
  });

  const numericSuffixes = summerTerms
    .map((term) => parseInt(term.name.split(" ")[1]))
    .sort((a, b) => a - b);

  const commonPrefix = summerTerms[0].name.split(" ")[0];
  const formattedSuffixes = numericSuffixes
    .join(", ")
    .replace(/,([^,]*)$/, " &$1")
  return `${commonPrefix} ${formattedSuffixes} combined`;
});

const hasTracks = computed(() => {
  return state.trackCourses.some((obj) => obj.name && obj.name.trim() !== "");
});

watch([session, hostInstitution], async ([newSession, newHostInstitution]) => {
  const terms = newSession?.class_filter_criteria?.terms?.map(
    (term) => term.name
  );
  let selectedTimeFrames = [];
  let param = "";
  if (terms) {
    terms.forEach((term) => {
      timeFrameOptions.value?.forEach((timeFrameOption) => {
        if (timeFrameOption.value?.includes(term)) {
          selectedTimeFrames.push(timeFrameOption);
        }
      });
    });
  }
  baseURL.searchParams.delete("timeframe");
  selectedTimeFrames.forEach((selectedTimeFrame) => {
    baseURL.searchParams.append("timeframe", `${selectedTimeFrame.value}`);
  });
  baseURL.searchParams.delete("hostInstitution");
  newSession?.class_filter_criteria?.host_institutions?.forEach(
    (hostInstitution) => {
      param = `${hostInstitution?.name}#$#${hostInstitution?.id}`;
      baseURL.searchParams.append("hostInstitution", param);
    }
  );
  baseURL.searchParams.delete("areas");
  newSession?.class_filter_criteria?.cip_codes?.forEach((cipCode) => {
    param = `${cipCode?.cip_title}#$#${cipCode?.cip_code}`;
    baseURL.searchParams.append("areas", param);
  });
  baseURL.searchParams.delete("language");

  const requiredLanguages =
    newSession?.class_filter_criteria?.required_languages ?? [];
  if (requiredLanguages.length > 0) {
    requiredLanguages.forEach((requiredLanguage) => {
      param = `${requiredLanguage.value}#$#${requiredLanguage.id}`;
      baseURL.searchParams.append("language", param);
    });
  }
  baseURL.searchParams.delete("session_id");
  //add session id for correct filtering in results page
  param = `${newSession.name}#$#${newSession.id}`;
  baseURL.searchParams.append("session_id", param);
  academicLink.value = baseURL;
});

const getConjunctionIds = (conjunction, conjunctionRules) => {
  return conjunctionRules
    ?.filter((rule) => rule.conjunction === conjunction)
    .flatMap((rule) => rule.ids);
};

const fetchCourseTrackData = async (track) => {
  let fetchedData = [];

  for (const rule of track) {
    if (!!Object.hasOwn("conjunction") || rule.conjunction === "not") {
      continue;
    }
    const courseData = await getCourseTrackData(rule.ids);

    fetchedData.push({
      name: rule.name,
      conjunction: rule.conjunction,
      data: courseData,
      any_class_from_list: rule.any_class_from_list,
    });
  }
  state.trackCourses = fetchedData;
};

const fetchClassesDataWithFilters = async (filters) => {
  try {
    await fetchClassesData(0, filters);
    updateStateWithClassesData();
  } catch (e) {
    toast.open({
      message: "Unable to get course data.",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
    console.error(e);
  }
};

const updateStateWithClassesData = () => {
  state.toggleEmpty = !classesData?.value?.count;
  if (classesData?.value?.count === 1) {
    const [data] = classesData.value.data;
    if (data?.host_institution_url_syllabus && data?.link_text) {
      state.host_institution_url_syllabus = data.host_institution_url_syllabus;
      state.link_text = data.link_text;
      state.isWrapperCourse = true;
    }
  }
};

watch(session, async (newSession) => {
  if (!newSession) return;

  const sessionClassSelectionRules = newSession.session_class_selection_rules;
  const academicDescription = newSession.academic_description?.content;

  if (
    newSession?.class_filter_criteria ||
    (academicDescription && Object.values(academicDescription)[0]?.content)
  ) {
    state.showPage = true;
  }

  if (sessionClassSelectionRules) {
    const {
      conjunctionRules: newConjunctionRules,
      notConjunction: newNotConjunction,
      andOrConjunctionRules: newAndOrConjunctionRules,
      andConjunction: newAndConjunction,
      orConjunction: newOrConjunction,
      anyClassFromLibrary: newAnyClassFromLibrary,
      anyClassFromList: newAnyClassFromList,
      track: newTrack,
    } = updateConjunctionRules(sessionClassSelectionRules, getConjunctionIds);
    conjunctionRules.value = newConjunctionRules;
    notConjunction.value = newNotConjunction;
    andOrConjunctionRules.value = newAndOrConjunctionRules;
    andConjunction.value = newAndConjunction;
    orConjunction.value = newOrConjunction;
    anyClassFromLibrary.value = newAnyClassFromLibrary;
    anyClassFromList.value = newAnyClassFromList;
    track.value = newTrack;

    await fetchCourseTrackData(track.value);

    if (andConjunction.value.length) {
      state.requiredCourses = await getCourseTrackData(andConjunction.value);
    }

    if (orConjunction.value.length) {
      state.electiveCourses = await getCourseTrackData(orConjunction.value);
    }
  }

  if (newSession?.class_filter_criteria) {
    hostInstitution.value =
      newSession?.class_filter_criteria?.host_institutions?.[0];

    if (hostInstitution?.value?.id) {
      try {
        await executeFetchHostInstitutionLanguagesOptions(
          0,
          hostInstitution?.value?.id
        );
      } catch (exception) {
        toast.open({
          message: "Was not able to load Institution data.",
          type: "error",
          position: "bottom",
          duration: ERROR_TIMEOUT,
        });
      }
    }

    const newFilters = constructFilterCriteria(
      newSession,
      notConjunction.value
    );
    filters.value = newFilters;
    await fetchClassesDataWithFilters(newFilters);
  }
});

const isAcademicDescriptionClamped = (element) => {
  const descElement = element.querySelector("#json-to-html");
  if (descElement) {
    return descElement.scrollHeight > descElement.clientHeight;
  }
  return false;
};

const checkClamping = () => {
  nextTick(() => {
    const paragraph = paragraphRef.value?.$el ?? paragraphRef.value;
    if (paragraph) {
      isToggleVisible.value = isAcademicDescriptionClamped(paragraph);
    }
  });
};

watch(hasAcademicDescription, (newValue) => {
  if (newValue) {
    checkClamping();
    window.addEventListener("resize", checkClamping);
  }
});

watch(
  [() => state.showPage, () => classesIsReady.value],
  ([newShowPage, newClassesIsReady]) => {
    if (newShowPage || newClassesIsReady)
      emit("observeSection", "academicdetails");
  }
);
</script>

<template>
  <div v-if="state.showPage" class="pt-20 px-4 md:px-8 text-indigo-base">
    <h1
      data-testid="academics-details-section-title"
      class="text-4xl mt-4 mb-2 font-bold text-indigo-base"
    >
      ACADEMICS
    </h1>
    <p v-if="state.isWrapperCourse" class="mt-4 mb-2 text-lg">
      <span v-if="hasCredits">
        Participants will earn {{ academicCredits }} U.S. credits during this
        program.
      </span>
      <span v-if="hostInstitution?.name"
        >View the course catalog for {{ hostInstitution?.name }}:</span
      >
      <a
        class="text-university-secondary uppercase text-base font-bold"
        target="_blank"
        :href="state.host_institution_url_syllabus"
      >
        {{ ` ${state.link_text}` }}.
      </a>
      (Note: this link opens in a new tab)
    </p>
    <p v-else-if="conjuctionHasData" class="mb-4">
      <span v-if="hasTracks" data-testid="terms-credits-description-text">
        Participants will earn {{ academicCredits }} semester credits.
      </span>
      <span v-else data-testid="terms-credits-description-text">
        During {{ hostInstitution?.name }}'s {{ formattedTerms }} term API
        students will earn {{ academicCredits }} semester credits.
      </span>
      <span v-if="andConjunction.length && !orConjunction.length"
        >Required course details can be found below.</span
      >
      <span v-if="orConjunction.length && !andConjunction.length"
        >Additional course details can be found below.</span
      >
      <span v-if="andConjunction.length && orConjunction.length"
        >Additional course details, including required courses can be found
        below.</span
      >
    </p>
    <p v-else class="mt-4 mb-2 text-lg">
      <span v-if="hasCredits">
        Participants will earn {{ academicCredits }} U.S. credits during this
        program.
      </span>
      <span v-if="totalRecords > 1"
        >{{ hostInstitution?.name }} has {{ totalRecords }} courses available in
        the catalog during the {{ formattedTerms }} term.
      </span>
      <span v-else-if="totalRecords == 1"
        >{{ hostInstitution?.name }} has {{ totalRecords }} course available in
        available in the catalog.</span
      >
      <span v-else
        >{{ hostInstitution?.name }} has {{ totalRecords }} courses available in
        available in the catalog.</span
      >
      <span v-if="totalRecords > 6"
        >Please see the courses below as a starting point and click 'View More
        Classes' to see the full catalog if you want to continue your search
        (Opens in a new tab).</span
      >
    </p>

    <JSONtoHTML
      v-if="hasAcademicDescription"
      ref="paragraphRef"
      data-testid="academic-description"
      :tiptap-json="academicDescription"
      :custom-class="['text-base', isClamped ? 'line-clamp-5' : '']"
    >
      <div v-if="isToggleVisible" class="flex justify-start mt-2">
        <button
          data-testid="toggle-expansion"
          type="button"
          @click="toggleExpansion"
          class="font-bold text-base cursor-pointer text-university-secondary"
          :aria-expanded="isExpanded"
        >
          {{ isExpanded ? "- Less" : "+ More" }}
        </button>
      </div>
    </JSONtoHTML>

    <div v-if="!state.isWrapperCourse">
      <TrackSection
        v-for="track in state.trackCourses"
        :key="track.name"
        :class-list="track.data"
        :track="track"
        :show="
          Boolean(
            getConjunctionIds(track.conjunction, conjunctionRules).length &&
              track.name
          )
        "
        :any-class="track.any_class_from_list"
      />

      <div
        v-if="!conjuctionHasData || anyClassFromLibrary || anyClassFromList"
        class="mt-4"
      >
        <p v-if="anyClassFromLibrary" class="mb-4">
          Plus any of the below courses.
        </p>
        <ClassCard
          v-for="(classItem, index) in classesData.data.slice(0, 6)"
          :key="index"
          class="mb-4"
          :classes="classItem"
          :hidden="classesData?.data?.length !== 1"
        />
      </div>
      <p v-if="state.toggleEmpty" class="mt-4 mb-2 text-lg">
        Sorry, no courses available for this session
      </p>

      <div class="mt-20 md:h-40">
        <div
          class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:justify-end gap-2 md:gap-4"
        >
          <button
            v-if="
              totalRecords > 6 &&
              (!conjuctionHasData || anyClassFromLibrary || anyClassFromList)
            "
            type="button"
            class="inline-flex mt-3 md:mt-0 justify-center items-center w-full md:h-6 p-5 bg-university-secondary text-white border-university-secondary border-[1px] hover:text-university-secondary hover:bg-white uppercase font-bold"
            @click="openAcademic"
          >
            <div>
              <div
                class="flex gap-1 font-bold uppercase text-base tracking-tight items-center"
              >
                <a
                  :href="academicLink.href"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View More Classes
                </a>
                <ArrowRight custom-class="w-5 h-5" />
              </div>
            </div>
          </button>
          <ApplyButton
            :id="sessionId"
            :disabled="pastSessionDeadline(session)"
            type="button"
            :outlined="true"
            class="py-2 mt-3 xs:mt-0 text-sm"
            :class="
              totalRecords <= numCoursesDisplayed
                ? 'md:col-end-3 lg:col-end-3:'
                : ''
            "
            event-category="Program Page Personalize CTA"
            @click="emit('applyClicked', sessionId)"
          />
        </div>
      </div>
    </div>
    <hr class="my-1 border-b-3 md:mt-20 border-gray-200" />
  </div>
</template>
