<script setup>
import IconBankAccount from "@/components/PaymentRequest/Icons/IconBankAccount.vue";
import IconCreditCard from "@/components/PaymentRequest/Icons/IconCreditCard.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import SingleSelect from "@/components/forms/SharedComponents/DarkBlueInputs/SingleSelect.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import { PAYMENT_TYPES, SKUS, STATES, STRIPE_TYPES } from "@/constants";
import { getApiClient } from "@/services/API";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { computed, reactive, ref, shallowRef, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

const featureFlags = computed(() => store.state.featureFlags);

const isLegacyCheckout = computed(() => {
  return featureFlags.value["legacy-checkout"];
});

const emits = defineEmits(["update:invoice-url", "close:modal", "open:tab"]);
const requiredErrorCopy = "This field is required.";
const exceptionState = [
  "Colorado",
  "Connecticut",
  "Kansas",
  "Maine",
  "Massachusetts",
];
const invoiceButtonModal = ref(null);
const internalState = reactive({
  submissionError: null,
  paymentType: "",
  stateSelected: null,
  paymentTypes: [
    {
      name: PAYMENT_TYPES.US_CREDIT_CARD.name,
      value: PAYMENT_TYPES.US_CREDIT_CARD.value,
      stripe: STRIPE_TYPES.CARD,
      feeFactor: PAYMENT_TYPES.US_CREDIT_CARD.feeFactor,
      sku: SKUS.cc,
      icon: shallowRef(IconCreditCard),
    },
    {
      name: PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.name,
      value: PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.value,
      stripe: STRIPE_TYPES.CARD,
      feeFactor: PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.feeFactor,
      sku: SKUS.cc,
      icon: shallowRef(IconCreditCard),
    },
    {
      name: PAYMENT_TYPES.BANK_ACCOUNT.name,
      value: PAYMENT_TYPES.BANK_ACCOUNT.value,
      stripe: STRIPE_TYPES.US_BANK_ACCOUNT,
      feeFactor: PAYMENT_TYPES.BANK_ACCOUNT.feeFactor,
      sku: SKUS.ach,
      icon: shallowRef(IconBankAccount),
      achFee: PAYMENT_TYPES.BANK_ACCOUNT.achFee,
    },
  ],
});

watch(
  () => internalState.paymentType,
  () => {
    // Changing payment type resets state on US cc
    internalState.stateSelected = null;
  }
);

const showStateInput = computed(() => {
  // State dropdown will appear for this payment type
  return (
    internalState.paymentType?.value === PAYMENT_TYPES.US_CREDIT_CARD.value
  );
});

const paymentMessage = computed(() => {
  if (doNotApplyFee.value) {
    return "* Visa, MasterCard, American Express and Discover are accepted.";
  }
  if (
    internalState.paymentType?.value === PAYMENT_TYPES.US_CREDIT_CARD.value &&
    typeof internalState.stateSelected?.label === "string"
  ) {
    return "* Credit/debit card payments are subject to a 3% convenience fee. Visa, MasterCard, American Express and Discover are accepted.";
  }
  if (
    internalState.paymentType?.value ===
    PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.value
  ) {
    return "* International Credit/debit card payments are subject to a 4% convenience fee. Visa, MasterCard, American Express and Discover are accepted.";
  }
  if (internalState.paymentType?.value === PAYMENT_TYPES.BANK_ACCOUNT.value) {
    return "*Paying by ACH is subject to a $3 fee per transaction.";
  }
  return null;
});

const doNotApplyFee = computed(() => {
  // If selected state is on exception list, do not charge additional fee
  return exceptionState.some(
    (state) => state === internalState.stateSelected?.label
  );
});

// Form validation rules (Vuelidate library)
const rules = {
  paymentType: {
    required: helpers.withMessage(requiredErrorCopy, required),
  },
  stateSelected: {
    required: helpers.withMessage(
      requiredErrorCopy,
      requiredIf(() => showStateInput?.value)
    ),
  },
};
const v$ = useVuelidate(rules, internalState);

const props = defineProps({
  balance: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: "Initial invoice for your program",
  },
  invoiceId: {
    type: String,
    default: "",
  },
});

const showFee = computed(() => {
  let paymentType = internalState.paymentType?.value;
  if (doNotApplyFee.value) return false;
  if (
    [
      PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.value,
      PAYMENT_TYPES.BANK_ACCOUNT.value,
    ].includes(paymentType)
  )
    return true;
  // Show fee to pay if state is selected
  if (
    paymentType === PAYMENT_TYPES.US_CREDIT_CARD.value &&
    typeof internalState.stateSelected?.label === "string"
  )
    return true;
  return false;
});

const feeTotal = computed(() => {
  // Calculates total amount for a fee (If applicable)
  let paymentType = internalState.paymentType?.value;
  if (doNotApplyFee.value) return 0;
  if (
    [
      PAYMENT_TYPES.US_CREDIT_CARD.value,
      PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.value,
    ].includes(paymentType)
  ) {
    if (
      paymentType === PAYMENT_TYPES.US_CREDIT_CARD.value &&
      typeof internalState.stateSelected?.label !== "string"
    )
      return 0;
    // 3% or 4%
    return parseInt(props.balance * internalState.paymentType.feeFactor);
  }
  if (paymentType === PAYMENT_TYPES.BANK_ACCOUNT.value)
    return internalState.paymentType.achFee; // 3$ dollars
  return 0;
});

const feeName = computed(() => {
  // Name of the fee to display on grid
  let paymentType = internalState.paymentType?.value;
  if (doNotApplyFee.value) return null;
  if (
    [
      PAYMENT_TYPES.US_CREDIT_CARD.value,
      PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.value,
    ].includes(paymentType)
  )
    return "Convenience Fee";
  if (paymentType === PAYMENT_TYPES.BANK_ACCOUNT.value) return "ACH Fee";
  return null;
});

const total = computed(() => {
  // Price of invoice + Fees
  return props.balance + feeTotal.value;
});

const feePriceLabel = computed(() => {
  // Formats to x.xx USD
  return (feeTotal.value / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
});

const totalPriceLabel = computed(() => {
  // Formats to x.xx USD
  return (total.value / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
});

const balancePriceLabel = computed(() => {
  // Formats to x.xx USD
  return (props.balance / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
});

const requestObject = computed(() => {
  // Hits Payment service endpoint: /api/v1/invoice/:id/checkout/
  const paymentType = internalState.paymentType;
  return {
    invoice_id: props.invoiceId,
    ...(feeTotal.value > 0 && {
      // Include amount only if it's greater than 0
      amount: feeTotal.value,
      description: feeName.value,
    }),
    billingState: internalState.stateSelected?.label ?? null,
    paymentType: isLegacyCheckout.value
      ? paymentType?.stripe // Legacy format
      : {
          paymentMethodType: paymentType?.value,
          stripe: paymentType?.stripe, // New format
        },
  };
});

const payInvoice = async function () {
  invoiceButtonModal.value.startLoading();
  internalState.submissionError = null;
  try {
    const response = await getApiClient().post("/stripe-invoice/checkout", {
      ...requestObject.value,
    });
    if (response?.status === 200) {
      // Redirect to Stripe
      window.open(response.data.hosted_invoice_url, "_blank");
      emits("update:invoice-url", {
        invoice_id: props.invoiceId,
        url: response.data.hosted_invoice_url,
        new_charge: feeName.value,
        new_total: total.value, // cents
        status: response.data.status,
      });
      isLegacyCheckout.value ? emits("close:modal") : emits("open:tab");
    }
  } catch (e) {
    invoiceButtonModal.value.stopLoading();
    internalState.submissionError = e;
  }
};
</script>

<template>
  <FormPanel
    id="paymentDets"
    title="Payment Details"
    class="mb-6"
    panel-content-class="text-gray-600 border-gray-200"
    :initially-expanded="true"
  >
    <template #content>
      <div
        class="bg-teal-light rounded p-5 text-base font-semibold text-gray-650"
      >
        <div class="grid grid-cols-2 gap-y-2">
          <p class="text-left">Invoice Amount</p>
          <p class="text-right">{{ balancePriceLabel }} USD</p>
          <p v-if="showFee" class="text-left">{{ feeName }}</p>
          <p v-if="showFee" class="text-right">{{ feePriceLabel }} USD</p>
          <p class="font-bold text-xl text-left">Total</p>
          <p class="font-bold text-xl text-right">{{ totalPriceLabel }} USD</p>
          <p
            v-if="paymentMessage"
            class="text-sm w-full text-gray-650 mt-4 col-span-2 font-normal"
          >
            {{ paymentMessage }}
          </p>
        </div>
      </div>
    </template>
  </FormPanel>

  <FormPanel
    id="paymentType"
    title="Payment Type"
    class="mb-6"
    panel-content-class="text-gray-600 border-gray-200"
    :initially-expanded="true"
  >
    <template #content>
      <div class="flex flex-col md:flex-row md:justify-between">
        <div
          v-for="(paymentType, index) in internalState.paymentTypes"
          :key="paymentType.value"
          class="mt-3 w-full md:w-1/3"
          :class="[
            { 'mr-3': index !== internalState.paymentTypes.length - 1 },
            index ? 'md:w-1/3' : 'md:w-1/4',
          ]"
        >
          <input
            :id="paymentType.value"
            v-model="internalState.paymentType"
            type="radio"
            name="paymentType"
            :value="paymentType"
            class="hidden"
          />
          <label
            class="flex flex-col p-5 rounded-md cursor-pointer w-full h-full"
            :class="
              paymentType.value === internalState.paymentType.value
                ? 'bg-blue-selection text-white'
                : 'bg-blue-400 text-gray-650'
            "
            :for="paymentType.value"
          >
            <component :is="paymentType.icon" />
            <span class="text-sm mt-2">
              {{ paymentType.name }}
            </span>
          </label>
        </div>
      </div>
      <div v-if="showStateInput" class="mt-4 block w-full md:w-3/6 xl:w-3/6">
        <!-- State -->
        <SingleSelect
          id="state"
          v-model.trim="internalState.stateSelected"
          label="Select Billing State"
          :vuelidate-field="v$.stateSelected"
          :options="STATES"
          :get-option-label="(option) => option.label"
        />
      </div>
      <ButtonWithSpinner
        id="invoiceButtonModal"
        ref="invoiceButtonModal"
        data-cy="invoiceButtonModal"
        type="submit"
        variant="secondary"
        variant-type="block"
        button-height="min-h-[58px] md:min-h-[60px] mt-4"
        :grey-disabled-class="true"
        :disabled="v$.$invalid"
        @click="payInvoice"
      >
        <div class="flex items-center justify-center">
          <span class="pr-3 uppercase">
            Continue
          </span>
        </div>
      </ButtonWithSpinner>
      <p class="font-medium text-xs">
        Selecting continue will take you to a secure payment page to enter your
        payment information.
      </p>
      <div
        v-if="internalState.submissionError"
        class="error text-error-900 mt-2"
      >
        {{ internalState.submissionError }}
      </div>
    </template>
  </FormPanel>
</template>
