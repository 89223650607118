<script setup>
import { defineProps, ref, watch, unref, toRefs, computed, provide } from "vue";
import { isEqual } from "lodash";
import { reLabel } from "../utils";

import MultiSelect from "@/components/ExperiencePage/Algolia/widgets/AlgoliaBaseMultiSelect";
import Badge from "@/components/shared/Badge/Badge.vue";
import { useVModel } from "@vueuse/core/index";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  selectClass: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  labelId: {
    type: String,
    default: "value",
  },
  labelInput: {
    type: String,
    default: "label",
  },
  currentSearchIndex: {
    type: Object,
    required: true,
  },
  filterName: {
    type: String,
  },
  algoliaConfiguration: {
    type: Object,
  },
  translationMap: {
    required: false,
    type: Object,
    default: () => {},
  },
  sortBy: {
    type: Array,
    default: () => ['name:asc'],
  },
});

const emit = defineEmits(["update:modelValue"]);
const filters = useVModel(props, "modelValue", emit);
const { algoliaConfiguration } = toRefs(props);

const selectedOptions = ref([]);
const refinementListRef = ref(undefined);

const facetName = computed(() => {
  return unref(algoliaConfiguration)?.[props?.filterName]
})

const onSelectedOptionUpdated = (item) => {
  // refine(item.value);
  const foundEntry = selectedOptions.value?.find((element) => element?.value === item?.value)
  if(foundEntry) {
    foundEntry.isRefined = !foundEntry.isRefined;
    selectedOptions.value = [...selectedOptions.value]
  } else {
    selectedOptions.value = [...selectedOptions.value, item]
  }
};

watch(() => refinementListRef?.value?.state?.canRefine, (newCanRefine, oldCanRefine) => {
  if(newCanRefine) {
    const algoliaState = unref(refinementListRef)?.state;
    const updatedFilterValue = filters.value?.[props.filterName];
    const newSelectedOptions = selectedOptions?.value?.map((option) => {
      if(updatedFilterValue.includes(option.label) && option?.isRefined) {
        return {
          ...option,
          isRefined: true
        }
      }
      return { ...option, isRefined: false }
    }) || [];

    updatedFilterValue.forEach(filter => {
      const foundEntry = newSelectedOptions?.find((element) => element?.value === filter);
      if(!foundEntry) {
        newSelectedOptions.push({label: filter, value:filter, isRefined: true });
      }
    })
    if(algoliaState?.canRefine && algoliaState?.items) {
      for(const item of algoliaState.items) {
        const foundEntry = newSelectedOptions?.find((element) => element?.value === item?.value);
        if(foundEntry && foundEntry.isRefined !== item.isRefined) {
          unref(refinementListRef).refine(item.value);
        }
      }
    }
    selectedOptions.value = newSelectedOptions;
  }
});


watch(
  () => filters.value,
 (updatedFilter, oldFilter) => {
    if (
      !isEqual(updatedFilter?.[props.filterName], oldFilter?.[props.filterName])
    ) {
      const algoliaState = unref(refinementListRef)?.state;
      const updatedFilterValue = updatedFilter?.[props.filterName];
      const newSelectedOptions = selectedOptions?.value?.map((option) => {
        if(updatedFilterValue.includes(option.value) && option?.isRefined) {
          return {
            ...option,
            isRefined: true
          }
        }
        return { ...option, isRefined: false }
      }) || [];

      updatedFilterValue.forEach(filter => {
        const foundEntry = newSelectedOptions?.find((element) => element?.value === filter);
        if(!foundEntry) {
          newSelectedOptions.push({label: filter, value:filter, isRefined: true });
        }
      })
      if(algoliaState?.canRefine && algoliaState?.items) {
        for(const item of algoliaState.items) {
          const foundEntry = newSelectedOptions?.find((element) => element?.value === item?.value);
          if(foundEntry && foundEntry.isRefined !== item.isRefined) {
            unref(refinementListRef).refine(item.value);
          }
        }
      }
      selectedOptions.value = newSelectedOptions;
    }
  },
  { immediate: true }
);

watch(selectedOptions, (newValue, oldValue) => {
  if ( props.filterName ) {
    const updatedFilters = {};
    const newFilterArray = [];
    for(const option of newValue) {
      if(option?.isRefined) {
        newFilterArray.push(option?.value)
      }
    }
    updatedFilters[props.filterName] = newFilterArray;
    filters.value = { ...filters.value, ...updatedFilters }
  }
});
</script>

<template>
  <div>
    <ais-refinement-list
      :attribute="facetName"
      :limit="1000"
      operator="or"
      :sort-by="sortBy"
      ref="refinementListRef"
    >
      <template v-slot="{ items, refine, searchForItems }">
        <div v-show="items?.length === 0" class="text-1xs2 bg-gray-75 text-indigo-base rounded-md mt-1">
          <p>No options available. Clear a filter on another category.</p>
        </div>
        <div v-show="items?.length !== 0">
          <div class="flex flex-wrap mb-21px gap-2">
            <template v-for="(item) in items" :key="item.value">
              <span
                v-if="item?.isRefined"
                class="inline-flex items-center rounded-full bg-blue-300 mr-1 py-0.5 pr-1 text-sm font-medium text-[#665eaa]"
              >
                <Badge
                  :item="item"
                  :label="reLabel(item, translationMap)"
                  custom-class="border-indigo-base text-indigo-base"
                  @mousedown.prevent="onSelectedOptionUpdated(item)"
                />
              </span>
            </template>
          </div>
          <MultiSelect
            v-model="selectedOptions"
            :options="items"
            :multiple="true"
            class="w-full"
            :searchable="true"
            placement="bottom"
            :deselect-from-dropdown="true"
            :close-on-select="false"
            :select-class="selectClass"
            :placeholder="placeholder"
            :wrap-dropdown="true"
            :translation-map="translationMap"
            :disabled="items?.length === 0"
            @update:on-option-clicked="(updates) => onSelectedOptionUpdated(updates)"
          />
        </div>
      </template>
    </ais-refinement-list>
  </div>
</template>
