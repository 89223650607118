<template>
  <VSelect
    v-model="currentVal"
    class="md:max-w-full forced-select-box-grow"
    :class="[
      {
        'forced-error-background': displayError,
      },
      fieldSettings.selectClass,
    ]"
    :placeholder="fieldSettings.placeholder"
    :calculate-position="withPopper"
    v-bind="$attrs"
    append-to-body
    :options="currentVal.length < this.maxSelected ? options : []"
    :get-option-label="(option) => getLabel(option)"
    :clear-search-on-blur="
      ({ clearSearchOnSelect, multiple }) => multiple ?? false
    "
    @option:selected="select"
    @option:deselected="deselectItem"
  >
    <template
      #selected-option-container="{ option, deselect, multiple, disabled }"
    >
      <teleport
        v-if="fieldSettings.externalOptionContainerId"
        :to="`#${fieldSettings.externalOptionContainerId}`"
      >
        <Badge
          :item="option"
          :label="label"
          custom-class="border-indigo-base text-indigo-base"
          @mousedown.prevent="deselect(option)"
        />
      </teleport>
      <span
        v-else
        class="inline-flex items-center rounded-full bg-blue-300 mb-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium text-[#665eaa]"
      >
        {{
          fieldSettings.name !== "terms"
            ? getLabel(option)
            : getTermsLabel(option)
        }}
        <div
          class="ml-2 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-[#665eaa] hover:text-blue-500 focus:bg-indigo-500 focus:text-white focus:outline-none cursor-pointer"
          @keypress.enter.prevent="deselect(option)"
          @mousedown.prevent="deselect(option)"
        >
          <span class="sr-only">Remove large option</span>
          <svg
            class="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              stroke-linecap="round"
              stroke-width="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </div>
      </span>
    </template>
    <template #option="item">
      <div class="my-2 relative focus:bg-teal-100">
        <label for="cb">
          <div class="h-5 z-0">
            <input
              v-if="
                getLabel(item) !== 'Select all' &&
                getLabel(item) !== 'Deselect all'
              "
              id="cb"
              type="checkbox"
              :checked="item.checked"
              class="h-4 w-4 form-checkbox border-gray-300 cursor-pointer relative z-0"
              @click="select(item)"
              :key="this.componentKey"
            />
          </div>
        </label>
        <div class="text-sm absolute top-0 z-40">
          <div
            :for="getLabel(item)"
            class="pt-[3px] text-sm"
            :class="[
              getLabel(item) === 'Select all' ||
              getLabel(item) === 'Deselect all'
                ? '!text-teal-900'
                : 'text-gray-700',
              fieldSettings?.optionClass,
            ]"
          >
            {{ getLabel(item) }}
          </div>
        </div>
      </div>
    </template>
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes"
        ><i class="fa fa-caret-down text-gray-500" v-bind="attributes"></i
      ></span>
    </template>
    <template
      v-if="fieldSettings.type === 'searchable_select'"
      #no-options="{ search, searching }"
    >
      <template v-if="!searching">
        <em style="opacity: 0.5;">Start typing...</em>
      </template>
    </template>
    <!-- eslint-disable-next-line vue/no-unused-vars  -->
  </VSelect>
</template>
<script>
import { createPopper } from "@popperjs/core";
import Badge from "../shared/Badge/Badge.vue";
import { eventBus } from "../../app";

export default {
  name: "WrappedVueSelect",
  components: { Badge },
  props: {
    placement: {
      required: false,
      type: String,
      default: "bottom",
    },
    displayError: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "name",
    },
    modelValue: {
      type: [Array, Object],
      default: () => [],
    },
    optionTemplate: {
      type: Array,
      default: () => [],
    },
    fieldSettings: {
      type: Object,
      default: () => {},
    },
    updatedByOtherComponent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      selectedOptions: {},
      wrappedValue: this.modelValue ? this.modelValue : [],
      options: [],
      currentVal: [],
      componentKey: 0,
    };
  },
  computed: {
    maxSelected() {
      return this.fieldSettings.maxSelected || 1000;
    },
  },
  watch: {
    optionTemplate: {
      handler: function (value) {
        if (!value.length) return;

        this.options = value
          .filter((item) => item.value !== "English")
          .map((item) => ({
            ...item,
            checked: this.isSelected(item),
          }));
        this.options.unshift({
          name: "Select all",
          id: "All",
        });
        //run label mapping for pre selected options
        let labelId = this.getLabelId();
        const modelValueCopy = _.cloneDeep(this.modelValue);
        for (let i in modelValueCopy) {
          const option = this.optionTemplate.find(
            (option) =>
              option[labelId].toString() ===
              modelValueCopy[i][labelId].toString()
          );
          if (option) {
            modelValueCopy[i][this.label] = option[this.label];
          }
        }
        if (!_.isEqual(modelValueCopy, this.modelValue)) {
          this.$emit("update:modelValue", modelValueCopy);
        }
      },
      immediate: true,
      deep: true,
    },
    currentVal: {
      handler: function (value) {
        delete value["checked"];
        if (
          Array.isArray(value) &&
          value.length > 1 &&
          value.some((item) => Object.values(item).includes("Select all"))
        ) {
          this.toggleArrayCheckbox(this.optionTemplate, false);
          this.$emit("update:modelValue", []);
        } else if (
          typeof value === "object" &&
          value.some((item) => Object.values(item).includes("Select all"))
        ) {
          this.toggleArrayCheckbox(this.optionTemplate, true);
          this.$emit("update:modelValue", this.optionTemplate);
        } else if (!this.modelValue) {
          this.$emit("update:modelValue", value);
        } else {
          this.$emit("update:modelValue", value);
        }
      },
      deep: true,
    },
    modelValue: {
      handler: function (value) {
        if (!value) return;
        this.currentVal = value;
        if (
          this.fieldSettings.type === "searchable_select" &&
          !this.options.length
        ) {
          this.options = value.map((item) => {
            return { ...item, checked: true };
          });
        } else if (
          this.fieldSettings.type === "searchable_select" &&
          this.updatedByOtherComponent
        ) {
          //update the options checked status
          //this was added bc when clearinf the modelValue from outside the component the options check stateus did not update.
          for (const obj of this.options) {
            obj.checked = value.some((element) => {
              if (element.value === obj.value) {
                return true;
              }
              return false;
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    eventBus.$on("clear", this.clearCheckboxes);
    if (this.modelValue) {
      if (Array.isArray(this.modelValue)) {
        this.toggleArrayCheckbox(this.modelValue, true);
      } else {
        this.toggleObjectCheckbox(this.modelValue, true);
      }
    }
  },
  beforeUnmount() {
    eventBus.$off("clear", this.clearCheckboxes);
  },
  methods: {
    clearCheckboxes() {
      this.options = this.options.map((item) => {
        item.checked = false;
        return item;
      });
    },
    isSelected(item) {
      let labelId = this.getLabelId();
      if (
        this.selectedOptions.length &&
        this.selectedOptions.find((option) => option[labelId] === item[labelId])
      ) {
        return true;
      }

      if (
        this.currentVal.length &&
        this.currentVal.find((option) => option[labelId] === item[labelId])
      ) {
        return true;
      }
      return false;
    },
    getLabel(item) {
      if (item[this.label] === "Select all" && this.currentVal.length)
        return "Deselect all";
      return item[this.label];
    },
    getTermsLabel(item) {
      return item["label_desc"];
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;
      dropdownList.className += " wrapped_select_override grid_dropdown";

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          { name: "flip", enabled: false },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement !== "Bottom"
              );
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },
    deselectItem(item) {
      if (Array.isArray(item)) {
        this.toggleArrayCheckbox(item, false);
      } else {
        this.toggleObjectCheckbox(item, false);
      }
      this.removeFromSelectedOptions(item);
      this.forceRerender();
    },
    select(items) {
      if (this.fieldSettings.name === "terms") {
        items = items.map((item) => {
          item["label_desc"] = item.name_desc.split(" : ")[0];
          return item;
        });
      }
      this.selectedOptions = items;
      if (Object.values(items).includes("Select all")) return;
      if (Array.isArray(items)) {
        this.toggleArrayCheckbox(items, true);
      } else {
        this.toggleObjectCheckbox(items, !items.checked);
      }
      this.forceRerender();
    },
    toggleArrayCheckbox(item, toggle) {
      let labelId = this.getLabelId();
      for (let it of item) {
        const itemIndex = this.options.findIndex(
          (obj) => obj[labelId] === it[labelId]
        );
        if (itemIndex === -1) continue;
        this.options[itemIndex]["checked"] = toggle;
      }
    },
    toggleObjectCheckbox(item, toggle) {
      let labelId = this.getLabelId();
      const itemIndex = this.options.findIndex(
        (obj) => obj[labelId] === item[labelId]
      );
      if (itemIndex === -1) return;
      this.options[itemIndex]["checked"] = toggle;
      if (toggle) {
        this.currentVal.push(item);
      } else {
        this.currentVal = this.currentVal.filter(
          (option) => option.id !== item.id
        );
      }
    },
    removeFromSelectedOptions(item) {
      let labelId = this.getLabelId();
      if (Array.isArray(this.selectedOptions)) {
        this.selectedOptions = this.selectedOptions.filter(
          (option) => option[labelId] !== item[labelId]
        );
      } else if (Object.values(this.selectedOptions).length && !item.checked) {
        this.selectedOptions = [];
      }
    },
    getLabelId() {
      let labelId = this.fieldSettings.labelId
        ? this.fieldSettings.labelId
        : "id";
      return labelId;
    },
    closeDropdown() {
      if (this.fieldSettings.type !== "searchable_select") return;
      if (
        !Array.isArray(this.selectedOptions) &&
        !Object.values(!this.selectedOptions).length
      )
        this.options = [];
      if (
        (Array.isArray(this.selectedOptions) && !this.selectedOptions.length) ||
        (Array.isArray(this.selectedOptions.length) &&
          !this.selectedOptions.some((option) => option.checked))
      )
        this.options = [];
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
<style scoped>
.forced-select-box-grow :deep(.vs__dropdown-toggle),
:deep(.vs__selected-options) {
  overflow: visible;
  max-height: inherit;
}

:deep(.vs__clear) {
  display: none;
}

.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
</style>
<style>
.wrapped_select_override > .vs__dropdown-option--highlight {
  background-color: #edf9f8 !important;
}

.wrapped_select_override > .vs__dropdown-option--deselect {
  background-color: #c8d0ec !important;
}
.grid_dropdown > .vs__dropdown-option {
  display: inline-block !important;
  width: calc(33.333% - 1rem) !important;
  margin: 0.5rem !important;
  text-align: center !important;
}
.grid_dropdown > .vs__dropdown-option:first-child {
  width: 100%;
  display: block !important;
}
</style>
