<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps } from "vue";
import { useVModel } from "@vueuse/core";
import OccurenceSection from "../../components/Occurrences/OccurenceSection.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import { useStore } from "vuex";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const events = useVModel(props, "modelValue");
const store = useStore();

const upcomingOccurrences = store.getters["events/getOccurrences"]?.upcoming;
const pastOccurrences = store.getters["events/getOccurrences"]?.past;
</script>
<template>
  <div class="relative mt-4">
    <FormPanel
      :id="'upcomingOccurrences'"
      :title="'UPCOMING OCCURRENCES'"
      class="mt-6 border-b-0"
    >
      <template #content>
        <div
          class="text-blue-900 border-gray-200 rounded bg-white grid grid-cols-2 gap-10 mb-5"
        >
          <div v-for="occurrence in upcomingOccurrences" :key="occurrence?.id">
            <OccurenceSection :event="events" :occurrence="occurrence" />
          </div>
        </div>
      </template>
    </FormPanel>
    <FormPanel
      :id="'pastOccurrences'"
      :title="'PAST OCCURRENCES'"
      class="mt-6 border-b-0"
    >
      <template #content>
        <div
          class="text-blue-900 border-gray-200 rounded bg-white grid grid-cols-2 gap-10 mb-5"
        >
          <div v-for="occurrence in pastOccurrences" :key="occurrence?.id">
            <OccurenceSection :event="events" :occurrence="occurrence" />
          </div>
        </div>
      </template>
    </FormPanel>
  </div>
</template>
