export default {
  mounted(el) {
    const input = el.querySelector("input") || el.querySelector("textarea");

    if (input) {
      // Function to update the input value and trigger the input event to update v-model
      const updateValue = () => {
        const lowercasedValue = input.value.toLowerCase();

        // Only update if the value has changed
        if (input.value !== lowercasedValue) {
          input.value = lowercasedValue;
          input.dispatchEvent(new Event("input")); // Trigger input event to update v-model
        }
      };

      // Initial update on mount
      updateValue();
      el._lowercaseListener = updateValue;
      input.addEventListener("input", el._lowercaseListener);
    }
  },
  updated(el) {
    //we need this for when the field already has a value from db
    const input = el.querySelector("input") || el.querySelector("textarea");

    if (input && input.value) {
      const lowercasedValue = input.value.toLowerCase();

      if (input.value !== lowercasedValue) {
        input.value = lowercasedValue;
        input.dispatchEvent(new Event("input")); // Trigger input event to update v-model
      }
    }
  },
  unmounted(el) {
    const input = el.querySelector("input") || el.querySelector("textarea");
    if (input && el._lowercaseListener) {
      // Remove the event listener
      input.removeEventListener("input", el._lowercaseListener);
    }
  },
};
