import { programServiceRequest, learningServiceRequest, skip } from "./utils";
import { SUCCESSFULLY_LOAD } from "../constants";

const programsService = {
  async getPrograms(
    limit = 10,
    page = 1,
    q = "",
    sort = "ASC" || "DESC",
    extraParams = {}
  ) {
    return await programServiceRequest({
      method: "get",
      url: "/programs",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        order: sort,
        ...extraParams,
      },
      message: {
        success: SUCCESSFULLY_LOAD,
        failure: "Error loading Program data",
      },
    });
  },
  async getProgramById(programId, params = {}) {
    return await learningServiceRequest({
      method: "get",
      url: `/programming/program_pages/${programId}`,
      params: {
        ...params,
      },
    });
  },
};

export default programsService;
