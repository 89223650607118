<template>
  <form-panel
    id="cities-overview"
    title="Overview"
    panel-content-class="pb-6 mb-6"
    panel-button-text-class="tracking-normal"
  >
    <template #content>
      <div
        class="grid-template-custom grid w-full justify-items-center gap-4 rounded-sm border border-gray-300 bg-white px-8 py-4 text-base"
      >
        <div class="w-full">
          <h4 class="font-semibold text-gray-700">Name</h4>
          <p class="mt-2" data-cy="city-name-label">
            {{ props.data?.city || "Not provided" }}
          </p>
        </div>
        <div class="w-full">
          <h4 class="font-semibold text-gray-700">State/Province</h4>
          <p class="mt-2" data-cy="city-population-label">
            {{ props.data?.admin_name || "Not provided" }}
          </p>
        </div>
        <div class="w-full">
          <h4 class="font-semibold text-gray-700">Country</h4>
          <p class="mt-2" data-cy="city-country-label">
            {{ props.data?.country?.name || "Not provided" }}
          </p>
        </div>
        <div class="w-full">
          <h4 class="font-semibold text-gray-700">Population</h4>
          <p class="mt-2" data-cy="sidebar-city-population-label">
            {{ props.data?.population || "Not provided" }}
          </p>
        </div>
        <div
          class="col-start-1 mt-6 md:col-start-2 md:col-end-4 md:row-start-2 md:row-end-5"
        >
          <img id="city-map" :src="img_url" />
        </div>
        <div class="col-start-1 w-full">
          <h4 class="font-semibold text-gray-700">Reasons to Experience</h4>
          <WYSIWYG
            class="cities-wysiwyg-borders-none"
            :editable="false"
            :model-value="reasons_to_experience"
            :display-header="false"
          />
        </div>
        <div class="col-start-1 w-full">
          <h4 class="font-semibold text-gray-700">Tags</h4>
          <p class="mt-2" data-cy="sidebar-city-tags-label">
            {{ tags }}
          </p>
        </div>
      </div>
    </template>
  </form-panel>
</template>

<script setup>
import { computed } from "vue";
import formPanel from "@/components/forms/SharedComponents/panel.vue";
import WYSIWYG from "@/components/forms/SharedComponents/WYSIWYG/WYSIWYG.vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const img_url =
  "http://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDDl24sRJEmh8Tkdr9d3JlLQG-MFgjCKSs&center=123%20Street,%2078704,%20austin,%20USA&zoom=13&format=png&maptype=roadmap&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&size=1200x360&scale=2&markers=color:red%7C123%20Street,%2078704,%20austin,%20USA";

const tags = computed(
  () => props.data?.tags?.map(({ name }) => name)?.join(", ") || "None"
);

const reasons_to_experience = computed(() => props.data?.reason_to_experience);
</script>

<style scoped>
@media screen and (min-width: 900px) {
  .grid-template-custom {
    grid-template-columns: 1fr 1fr minmax(0, 320px);
    grid-template-rows: 70px 70px 1fr 1fr;
  }
}
</style>

<style>
.cities-wysiwyg-borders-none,
.cities-wysiwyg-borders-none .editor {
  border: none;
  box-shadow: none;
}
</style>
