<script>
export default {
  name: "ExcursionsFulfillmentModal",
};
</script>

<script setup>
import { cloneDeep } from "lodash";
import { toRefs, ref, unref, computed, watchEffect } from "vue";
import pLimit from "p-limit";
import orders from "@/services/orders";
import { useToast } from "vue-toast-notification";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import PlusIcon from "@/components/shared/icons/PlusIcon";
import SimpleTextarea from "@/components/shared/SimpleTextarea.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";
import { convertCost } from "@/components/program-manager/sessions/utils";
import { centsToDollars, formatDate } from "@/mixins/helpers";
import {
  CANCELLATION_REASONS,
  CANCELLATION_REASONS_OTHER,
  ERROR_TIMEOUT,
  MAX_NOTE_TEXTAREA_LIMIT,
  SUCCESS_TIMEOUT,
} from "@/constants";
import ExcursionsSelectionModal from "@/components/program-manager/sessions/components/ExcursionsAndEvents/ExcursionsSelectionModal.vue";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";
import { columnsConfig, ERROR_MESSAGE, tableHeaders } from "./constants";
import { ORDER_PRODUCT_STATUS } from "@/constants";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

const props = defineProps({
  modalIsOpen: {
    type: Boolean,
    default: true,
  },
  orderId: {
    type: String,
    default: "",
  },
  profileName: {
    type: String,
    default: "",
  },
  excursionsData: {
    type: Array,
    default: () => [],
  },
  programSession: {
    type: Object,
    default: () => {},
  },
  loadingExcursions: {
    type: Boolean,
  },
});

const checkOtherCancellationReason = (cancellationReason) =>
  cancellationReason &&
  CANCELLATION_REASONS.findIndex(({ id }) => id === cancellationReason) < 0;

const { orderId, modalIsOpen, loadingExcursions } = toRefs(props);
const updateExcursionsData = ref(cloneDeep(props.excursionsData));

const toast = useToast();
const plimit = pLimit(2);

const isSaving = ref(false);
const newExcursionsData = ref([]);

const excursionsSelectionModalIsOpen = ref(false);

const emit = defineEmits(["closeModal", "updateSavedExcursionData"]);

const statusOptions = computed(() => Object.values(ORDER_PRODUCT_STATUS) || []);

const buildDateRange = (excursion) => {
  return `${formatDate(excursion.start_date_time) || ""} - ${
    formatDate(excursion.end_date_time) || ""
  }`;
};

const rulesNewExcursions = {
  $each: helpers.forEach({
    excursion: {
      required: helpers.withMessage(
        "Please, provide a valid product to be added",
        required
      ),
    },
  }),
};

const rulesUpdateExcursions = {
  $each: helpers.forEach({
    cancellation_reason: {
      requiredIfCanceled: helpers.withMessage(
        "Cancellation reason is required when status is Canceled",
        (value, parentVm) => (parentVm.status === "Canceled" ? !!value : true)
      ),
    },
    otherCancellationReason: {
      requiredIfCanceledAndOther: (value, parentVm) =>
        parentVm.status === "Canceled" &&
        parentVm.cancellation_reason === "Other"
          ? !!value
          : true,
    },
  }),
};

const v$ = useVuelidate(rulesNewExcursions, newExcursionsData);
const updateV$ = useVuelidate(rulesUpdateExcursions, updateExcursionsData);

const savingErrorToast = (message) => {
  toast.open({
    message: `${message ?? ERROR_MESSAGE}`,
    type: "error",
    position: "bottom",
    duration: ERROR_TIMEOUT,
  });
};

const handleCloseModal = () => {
  clearNewExcursionsData();
  emit("closeModal");
};

const openExcursionsSelectionModal = () => {
  excursionsSelectionModalIsOpen.value = true;
};

const updateAllOrderExcursions = async () =>
  await Promise.all(
    updateExcursionsData.value
      ?.filter(
        (item, index) => item.status !== props.excursionsData[index].status
      )
      .map((item, index) =>
        // limit the amount of concurrent requests
        plimit(async () => {
          try {
            item.cancellation_reason =
              item.cancellation_reason === CANCELLATION_REASONS_OTHER
                ? item.otherCancellationReason
                : item.cancellation_reason;
            const { data } = await orders.updateOrderLineItem({
              orderId: orderId.value,
              productId: item.new_product_id,
              payload: item,
              skipErrorBus: true,
            });
            updateExcursionsData.value[index].status = item.status;
            emit("updateSavedExcursionData", data);
            return data;
          } catch (err) {
            savingErrorToast(
              `Error changing status of ${item.name}. Please, try it later.`
            );
            throw err;
          }
        })
      ) || []
  );

const createNewOrderExcursions = async () =>
  await Promise.all(
    newExcursionsData.value?.map((item, index) =>
      // limit the amount of concurrent requests
      plimit(async () => {
        try {
          const { data } = await orders.createOrderLineItem({
            orderId: orderId.value,
            productId: item.new_product_id,
            payload: { ...item },
            skipErrorBus: true,
          });
          emit("updateSavedExcursionData", data, item);
          removeNewExcursion(index);
          return data;
        } catch (err) {
          savingErrorToast(`Error adding ${item.title}. Please, try it later.`);
          throw err;
        }
      })
    ) || []
  );

const handleSaveExcursions = async () => {
  v$.value.$reset();
  updateV$.value.$reset();
  const isValidUpdate = await updateV$.value.$validate();
  const isValidCreate = await v$.value.$validate();

  if (!isValidUpdate) {
    savingErrorToast(
      "Cancellation reason is required when status is Canceled."
    );
    return;
  }

  if (!isValidCreate) {
    savingErrorToast("Please, provide a valid excursion to be added.");
    return;
  }

  try {
    isSaving.value = true;
    const updateResponse = await updateAllOrderExcursions();
    const createResponse = unref(newExcursionsData)?.length
      ? await createNewOrderExcursions()
      : [];
    if (
      [...updateResponse, ...createResponse].every(
        ({ status }) => status === "rejected"
      )
    ) {
      throw "Error saving data";
    }
    toast.open({
      message: "Order item status updated successfully",
      type: "success",
      position: "bottom",
      duration: SUCCESS_TIMEOUT,
    });
    handleCloseModal();
  } catch (exception) {
    savingErrorToast();
  } finally {
    isSaving.value = false;
    clearNewExcursionsData();
  }
};

const updateNewExcursions = (val) => {
  newExcursionsData.value =
    val?.map((item) => ({
      new_product_id: item?.id,
      name: item?.title ?? "",
      product_type: "EVENT",
      event_id: item?.event_id,
      title: item?.title ?? "",
      duration: item?.duration ?? "",
      product_price: item?.product_price ?? 0,
      quantity: 1,
      status: "Unfulfilled",
    })) || [];
  closeExcursionsSelectionModal();
};
const closeExcursionsSelectionModal = () => {
  excursionsSelectionModalIsOpen.value = false;
};

const removeNewExcursion = (index) => {
  newExcursionsData.value.splice(index, 1);
};

const clearNewExcursionsData = () => {
  newExcursionsData.value = [];
};

watchEffect(() => {
  updateExcursionsData.value = cloneDeep(props.excursionsData);
  updateExcursionsData.value = updateExcursionsData.value.map((excursion) => ({
    ...excursion,
    cancellation_reason: checkOtherCancellationReason(
      excursion.cancellation_reason
    )
      ? CANCELLATION_REASONS_OTHER
      : excursion.cancellation_reason,
    otherCancellationReason: checkOtherCancellationReason(
      excursion.cancellation_reason
    )
      ? excursion.cancellation_reason
      : "",
  }));
});
</script>

<template>
  <div>
    <ExcursionsSelectionModal
      :modal-is-open="excursionsSelectionModalIsOpen"
      :terms="programSession?.items?.terms"
      :session_travel_details="programSession?.items?.session_travel_details"
      :show-filters="false"
      :add-all-enabled="false"
      :save-on-submit="false"
      :columns-config="columnsConfig"
      @update-new-excursions="updateNewExcursions"
      @close-modal="closeExcursionsSelectionModal"
    />
    <div
      v-if="modalIsOpen && !excursionsSelectionModalIsOpen"
      class="relative z-50"
    >
      <div
        class="fixed inset-0 z-40 hidden bg-university-primary opacity-50 sm:block"
      ></div>
      <Dialog
        :open="modalIsOpen"
        class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6"
        @close-modal="handleCloseModal"
        @click.self="handleCloseModal"
      >
        <div
          class="max-w-5xl px-4 mx-auto bg-white sm:px-16 sm:py-16 sm:rounded-lg sm:shadow z-50 relative"
        >
          <div
            class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r"
          >
            <button
              class="h-6 w-6 flex items-center justify-center"
              @click="handleCloseModal"
            >
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <DialogPanel class="flex flex-col">
            <DialogTitle
              class="flex justify-center mt-6 mb-12 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
            >
              {{ profileName ? `${profileName}'s ` : "" }}Excursions
            </DialogTitle>
            <div class="">
              <table class="w-full">
                <thead>
                  <tr
                    class="grid grid-cols-5 md:grid-cols-[1fr,6rem,1fr,6rem,10rem] border-b-[1px] border-solid border-b-gray-200"
                  >
                    <th
                      v-for="header of tableHeaders"
                      class="py-3 text-left last:w-40"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr
                    class="grid grid-cols-5 md:grid-cols-[1fr,6rem,1fr,6rem,10rem] border-b-[1px] border-solid border-b-gray-200 items-center"
                    v-for="excursion in updateExcursionsData"
                    :key="excursion.id"
                  >
                    <td class="py-6 text-left">
                      {{ excursion?.name }}
                    </td>
                    <td class="py-6 text-left">
                      {{ excursion?.duration }}
                    </td>
                    <td class="sm:w-65 py-6">
                      {{ buildDateRange(excursion) }}
                    </td>
                    <td class="py-6 text-left">
                      {{
                        excursion?.total_price_in_cents
                          ? `${convertCost(
                              centsToDollars(excursion?.total_price_in_cents)
                            )}`
                          : excursion?.product_price
                      }}
                    </td>
                    <td class="sm:w-40 py-6">
                      <VSelectCaret
                        v-model="excursion.status"
                        :clearable="false"
                        outer-classes="w-full checkbox-placeholder"
                        scroll-input="auto"
                        :options="statusOptions"
                      ></VSelectCaret>
                    </td>
                    <td
                      class="col-start-3 col-end-6 flex items-center justify-end pb-6"
                      v-if="excursion.status === 'Canceled'"
                    >
                      <label
                        class="mr-2 text-sm font-semibold"
                        for="reason-of-cancellation"
                      >
                        Reason for Cancellation
                      </label>
                      <VSelectCaret
                        id="reason-for-cancellation"
                        v-model="excursion.cancellation_reason"
                        :clearable="false"
                        outer-classes="w-4/6 checkbox-placeholder"
                        scroll-input="auto"
                        :options="CANCELLATION_REASONS"
                        :reduce="(val) => val.label"
                      ></VSelectCaret>
                    </td>
                    <td
                      id="product-reason-cancellation-data"
                      class="col-start-3 col-end-6 flex items-center justify-end pb-6"
                      v-if="
                        excursion.status === 'Canceled' &&
                        excursion.cancellation_reason === 'Other'
                      "
                    >
                      <SimpleTextarea
                        v-model="excursion.otherCancellationReason"
                        label-name="Cancellation Reason"
                        outer-classes="w-full"
                        :max-length="MAX_NOTE_TEXTAREA_LIMIT"
                        :disabled="isSaving"
                      />
                    </td>
                  </tr>
                  <tr
                    class="grid grid-cols-5 md:grid-cols-[1fr,6rem,1fr,6rem,10rem] border-b-[1px] border-solid border-b-gray-200 items-center"
                    v-for="newExcursion in newExcursionsData"
                    :key="newExcursion.id"
                  >
                    <td class="py-6 text-left">
                      {{ newExcursion?.title }}
                    </td>
                    <td class="py-6 text-left">
                      {{ newExcursion?.duration }}
                    </td>
                    <td class="sm:w-65 py-6">
                      {{ newExcursion.date_time }}
                    </td>
                    <td class="py-6 text-left">
                      {{ newExcursion?.product_price }}
                    </td>
                    <td class="text-right">
                      <button
                        class="py-6 duration-300 hover:scale-110 hover:text-red-100"
                        @click="removeNewExcursion(index)"
                      >
                        <TrashIcon class="h-5" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Spinner
                v-show="loadingExcursions"
                outer-classes="flex items-center justify-center bg-white h-full relative mx-auto my-4"
              />
            </div>
            <button
              class="mt-4 flex w-fit cursor-pointer items-center text-teal-900 duration-300 hover:scale-110"
              @click="() => openExcursionsSelectionModal()"
            >
              <PlusIcon /><span class="ml-2 text-sm font-semibold uppercase"
                >New excursion</span
              >
            </button>
            <div class="flex justify-center">
              <BaseButton outlined :on-click="handleCloseModal" class="px-7"
                >Cancel</BaseButton
              >
              <ButtonWithSpinner
                :prop-loading="isSaving"
                class="mr-0 text-base font-semibold"
                data-cy="save-order-room-button"
                variant="secondary"
                variant-type="normal"
                @click.prevent="handleSaveExcursions"
              >
                <span>Save</span>
              </ButtonWithSpinner>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  </div>
</template>
