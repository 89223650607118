<template>
  <div
    class="px-4 sm:px-6 lg:px-8 w-full mt-2 rounded-sm mb-8 lg:mx-8 shadow-xl p-3 bg-white"
  >
    <CardHeader
      v-if="headerVisible"
      :active-filter="activeFilter"
      :sorting-options="sortingOptions"
      :view-more-route-name="viewMoreRouteName"
      @sortTable="
        (columnId) => {
          sortedColumn = columnId;
        }
      "
    />
    <div class="block h-full max-w-full pb-16 overflow-auto">
      <SortableTable
        v-if="headers.length > 0"
        :columns="columns"
        :rows="rows"
        :sorted-column="sortedColumn"
        :actions-routes="actionsRoutes"
        :permissions="permissions"
      >
        <!-- TODO: First pass at starting to to break up/simplify this uber components logic. -->
        <template
          v-if="$route.meta?.subcategory === 'sessions'"
          #actions="{ id, entityId }"
        >
          <ProgramSessionActions
            :id="id"
            :entityId="entityId"
            @open-duplicate-modal="$emit('openDuplicateModal', { id })"
            @open-detail-modal="$emit('openDetailModal', { id })"
          />
        </template>
      </SortableTable>
    </div>
  </div>
</template>

<script>
import SortableTable from "./SortableTable.vue";
import CardHeader from "./CardHeader.vue";
import TableMenu from "./TableMenu.vue";
import { createColumnHelper } from "@tanstack/vue-table";
import { isAuthorized } from "@/composables/authorization";
import ProgramSessionActions from "@/components/program-manager/sessions/components/ProgramSessionActions";

export default {
  components: {
    CardHeader,
    SortableTable,
    ProgramSessionActions,
  },
  props: {
    headerVisible: {
      type: Boolean,
      default: true,
    },
    activeFilter: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    actionsRoutes: {
      type: Object,
      default: () => {},
    },
    viewMoreRouteName: {
      type: String,
      default: null,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["openDuplicateModal", "openDetailModal"],
  data() {
    return {
      sortedColumn: this.headers[0],
      columnHelper: null,
    };
  },
  computed: {
    sortingOptions() {
      return this.headers.map((header) => ({ name: header }));
    },
    permissions() {
      return Object.fromEntries(
        Object.entries({ ...this.activeFilter?.permissions }).map(([k, v]) => [
          k,
          isAuthorized(v),
        ])
      );
    },
  },
  watch: {
    headers: {
      handler: function () {
        this.setColumns();
        this.setActionsColumns();
      },
      deep: true,
    },
  },
  created() {
    this.columnHelper = createColumnHelper();
    this.setColumns();
    this.setActionsColumns();
  },
  methods: {
    setColumns() {
      let columns = this.headers.map((header) => {
        return this.columnHelper.accessor(header, {
          cell: (info) => info.getValue(),
          header,
        });
      });

      if (this.showCheckbox) {
        const checkboxColumn = this.columnHelper.accessor("checkbox");
        columns.unshift(checkboxColumn);
      }

      this.columns = columns;
    },
    setActionsColumns() {
      this.columns.push(
        this.columnHelper.accessor("actions", {
          header: TableMenu,
        })
      );
    },
  },
};
</script>
