<template>
  <div class="items-center bg-white border border-gray-300 rounded mb-4 p-3">
    <div class="mb-2 flex items-center">
      <input
        id="name"
        name="name"
        type="checkbox"
        :checked="checked"
        class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
        @input="($event) => handleSessionCheckbox($event)"
      />
      <label
        for="name"
        class="flex ml-3 text-sm font-semibold text-university-secondary"
        ><a :href="`/program-manager/sessions/${sessionId}`" target="_blank">{{
          name
        }}</a>
      </label>
    </div>
    <div class="flex items-center ml-5 justify-between space-x-3">
      <div class="flex">
        <div
          class="-my-2 -ml-2 inline-flex items-center px-3 text-left text-sm font-light text-gray-600"
        >
          <span>{{ date }}</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <div
          class="inline-flex items-center px-3 text-sm font-light text-gray-600"
        >
          <span>{{ availability }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

defineProps({
  name: {
    type: String,
    default: "",
  },
  date: {
    type: String,
    default: "",
  },
  availability: {
    type: String,
    default: "",
  },
  checked: {
    type: Boolean,
    default: false,
  },
  sessionId: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["selectSession", "unselectSession"]);

const handleSessionCheckbox = ($event) => {
  if ($event.target.checked) {
    emits("selectSession");
  } else {
    emits("unselectSession");
  }
};
</script>

<style scoped>
.card {
  padding: 1rem;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.card-content {
  margin-left: 1rem;
}
</style>
