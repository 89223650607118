<template>
  <div
    v-if="loading"
    :class="[$attrs.class, `${width} ${bgGradient} ${computedHeight}`]"
    :style="computedStyle"
  ></div>
  <slot v-else></slot>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  fromColor: {
    type: String,
    default: "#E5E7EB",
  },
  toColor: {
    type: String,
    default: "#F9FAFB",
  },
  width: {
    type: String,
    default: "w-full",
  },
  height: {
    type: String,
    default: "h-10",
  },
  bgGradient: {
    type: String,
    default: "animate-pulse-speed-reverse",
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const computedHeight = computed(() => {
  return props.height !== "inherit" ? props.height : "";
});

const computedStyle = computed(() => {
  let style = `background-image: linear-gradient(to right, ${props.fromColor}, ${props.toColor});`;
  if (props.height === "inherit") {
    style += " height: inherit;";
  }
  return style;
});
</script>
<style scoped>
/* Increase the animation duration for a more pronounced pulsating effect */
.animate-pulse-speed-reverse {
  animation: pulse-speed-reverse 1.5s infinite;
}

@keyframes pulse-speed-reverse {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}
</style>
