import { learningServiceRequest, skip } from "@/services/utils";
import { DEFAULT_LIMIT_PER_PAGE } from "@/constants";

const bedService = {
  async getBeds({
    limit = DEFAULT_LIMIT_PER_PAGE,
    page = 1,
    q,
    extraParams = {},
    source,
  }) {
    return await learningServiceRequest({
      method: "get",
      url: `/beds`,
      params: { limit, skip: skip(page, limit), q, ...extraParams },
      failureMessage: "Error loading Beds data",
      source,
    });
  },
};

export default bedService;
