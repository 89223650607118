<template>
  <FormPanel
    :id="'passort'"
    :title="'Passport'"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <p class="mb-6">
       All students are required to submit a copy of the passport they will use during their session with API. Remember that the date of expiration need to be at least 6 months beyond the program end date. Please make sure the photo is a clear, scanned image.
      </p>
      <div v-if="ruleType === 'form_rules'">
        <label class="inline-flex mb-6">
          <input
            id="no-passport"
            v-model="noPassport"
            type="checkbox"
            :class="`${primaryColorClass}`"
            class="mt-1 form-checkbox"
          />
          <span class="ml-2">I don't currently have a passport</span>
        </label>
        <p class="mb-6">
          Not a problem. Continue with the application, and we will ask for your
          passport information again later. You will need a current passport to
          travel abroad. Information to Apply or Renew your US Passport can be
          found here:
          <a href="http://travel.state.gov">http://travel.state.gov</a>
        </p>
      </div>
      <div v-if="!noPassport">
        <Passport
          ref="firstPassport"
          :passport-info="firstPassport"
          :title="'Passport 1'"
          :formio-data="formio.firstPassport"
          @update:passport="firstPassport = $event"
        />
        <button
          v-if="showAddButton"
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
          ]"
          class="flex items-center mb-10"
          @click="handleSecondPassport()"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line
                v-if="!showSecondPassport"
                x1="12"
                y1="8"
                x2="12"
                y2="16"
              ></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </span>
          <span class="ml-2 text-sm font-semibold text-gray-600">
            {{ showSecondPassport ? "Remove " : "Add " }}
            Another Passport (for dual citizenship)
          </span>
        </button>
        <Passport
          v-if="showSecondPassport"
          ref="secondPassport"
          :passport-info="secondPassport"
          :title="'Passport 2'"
          :formio-data="formio.secondPassport"
          @update:passport="secondPassport = $event"
        />
      </div>
    </template>
  </FormPanel>
</template>

<script>
import FormPanel from "../SharedComponents/panel.vue";
import Passport from "./Passport.vue";
import FormValidation from "../../../mixins/formValidation";
import { mapState } from "vuex";
import { eventBus } from "../../../app";

export default {
  name: "PassportForm",
  components: {
    FormPanel,
    Passport,
  },
  mixins: [FormValidation],
  props: {
    showAddButton: {
      type: Boolean,
      default: false,
    },
    formioData: {
      type: Object,
      default() {
        return {};
      },
    },
    renderForm: {
      type: Boolean,
      default: false,
    },
    ruleType: {
      type: String,
      default: "",
    },
  },
  emits: ["sendPassportData"],
  data() {
    return {
      firstPassport: {},
      secondPassport: {},
      showSecondPassport: false,
      sectionsToValidate: ["firstPassport"],
      formio: {
        firstPassport: {},
        secondPassport: {},
      },
      noPassport: false,
    };
  },
  computed: {
    ...mapState(["userData"]),
    computedFormio() {
      return Object.assign({}, this.formioData);
    },
  },
  watch: {
    showSecondPassport: {
      handler: function (newValue) {
        if (newValue) {
          this.sectionsToValidate.push("secondPassport");
        } else {
          this.sectionsToValidate.splice(
            this.sectionsToValidate.indexOf("secondPassport"),
            1
          );
        }
      },
    },
    computedFormio: {
      handler: function (newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.setFormioData(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    renderForm: {
      handler: function () {
        this.showDualCitizenshipPassport();
      },
    },
    showAddButton: {
      handler: function () {
        this.showDualCitizenshipPassport();
      },
    },
  },
  async created() {
    eventBus.$on("getPassportData", () => {
      this.getPassportData();
    });
    this.showDualCitizenshipPassport();
  },

  methods: {
    handleSecondPassport() {
      this.showSecondPassport = !this.showSecondPassport;
      this.formio.secondPassport = {};
      this.secondPassport = {};
    },
    async showDualCitizenshipPassport() {
      if (!this.showAddButton && this.renderForm) {
        if (!Object.prototype.hasOwnProperty.call(this.userData, "data")) {
          await this.$store.dispatch("cacheFormioUser");
        }

        this.showSecondPassport =
          Object.hasOwnProperty.call(
            this.userData.data,
            "ihavedualcitizenship"
          ) && this.userData.data.ihavedualcitizenship;
      }
    },
    /*
      *****IMPORTANT*****
      Emit method on parent submit event
      @returns {{hasErrors: bolean, firstPassport: object, secondPassport: object}}
    */
    getPassportData() {
      let passportData = {
        hasErrors: this.noPassport ? false : this.hasErrors,
        firstPassport: this.noPassport ? {} : this.firstPassport,
        secondPassport: this.noPassport ? {} : this.secondPassport,
        noPassport: this.noPassport,
      };
      eventBus.$emit("dateChange");
      if (!this.noPassport) {
        this.validate()
          .then(() => {
            passportData.hasErrors = this.hasErrors;
            this.$emit("sendPassportData", passportData);
          })
          .catch(() => {
            passportData.hasErrors = this.hasErrors;
            this.$emit("sendPassportData", passportData);
          });
      } else {
        this.$emit("sendPassportData", passportData);
      }
    },
    setFormioData(formio) {
      this.noPassport = formio.noPassport;
      this.formio.firstPassport = formio.firstPassport
        ? Object.assign({}, formio.firstPassport)
        : this.formio.firstPassport;

      if (formio.secondPassport) {
        this.showSecondPassport = true;
        this.formio.secondPassport = Object.assign({}, formio.secondPassport);
      }
    },
  },
};
</script>
