import { learningServiceRequest } from "./utils";

const skillsService = {
  async list(search, subcategories) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/skills",
      params: { q: search, skill_subcategory_ids: subcategories },
      failureMessage: "Error loading skills",
    });
    return response?.data ?? [];
  },
};

const skillsServiceSubcategories = {
  async list(search) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/skills_subcategories",
      params: { q: search },
      failureMessage: "Error loading skills subcategories",
    });
    return response?.data ?? [];
  },
};

export { skillsService, skillsServiceSubcategories };
