<template>
  <div class="bg-white">
    <div
      class="mx-4 md:mx-auto md:ml-36 md:mr-11 my-24 font-montserrat text-indigo-base"
    >
      <h1 class="text-4xl uppercase font-bold mb-5">
        {{ unit?.housings?.type ?? "" }}
      </h1>
      <p class="text-xl font-medium mb-4">
        Complete the following housing questionnaire to help us cultivate a safe
        and comfortable journey abroad. Check all that may apply.
      </p>

      <Roommates
        :previous-questionnaire="previousQuestionnaire"
        @set-questionnaire-data="setQuestionnaireData"
      />
      <Housemates
        :previous-questionnaire="previousQuestionnaire"
        @set-questionnaire-data="setQuestionnaireData"
      />

      <BasicQuestions
        :is-local-host="isLocalHost"
        :questions="QUESTIONS"
        :previous-questionnaire="previousQuestionnaire"
        @set-questionnaire-data="setQuestionnaireData"
      />

      <Acknowledgements
        :previous-questionnaire="previousQuestionnaire"
        @set-acknowledgement="setAcknowledgement"
      />
    </div>

    <StickyFooter
      max-next-width="max-w-[260px]"
      :show-required-section="false"
      :disabled-next="false"
      @click="$emit('back-to-previous-step')"
      @next="next"
    />
  </div>
</template>

<script setup>
import { useHousingQuestionnaire } from "@/composables/useHousingQuestionnaire";
import StickyFooter from "@/components/Configurator/StickyFooter.vue";
import { inject, onMounted } from "vue";
import Acknowledgements from "./QuestionnaireAcknowledgements.vue";
import BasicQuestions from "./QuestionnaireBasicQuestions.vue";
import Housemates from "./QuestionnaireHousemates.vue";
import Roommates from "./QuestionnaireRoommates.vue";

defineEmits(["back-to-previous-step"]);

const { program } = inject("program");
const { housing, setQuestionnaire } = inject("housing");
const { goToNextStep } = inject("steps");

const {
  QUESTIONS,
  previousQuestionnaire,
  unit,
  v$,
  isLocalHost,
  formattedSubmission,
  setUnit,
  setAcknowledgement,
  setQuestionnaireData,
} = useHousingQuestionnaire();

const next = async () => {
  const isValid = await v$.value.$validate();

  if (isValid) {
    setQuestionnaire(formattedSubmission);
    goToNextStep();
  }
};

const loadPreviousSelection = () => {
  previousQuestionnaire.questions =
    housing.questionnaire?.question_responses?.filter(
      (item) => item.level === "basics"
    ) ?? [];

  previousQuestionnaire.allAcknowledged =
    housing.questionnaire?.allAcknowledged;

  previousQuestionnaire.roommateRequests =
    housing.questionnaire?.requested_roommates ?? [];
  previousQuestionnaire.housemateRequests =
    housing.questionnaire?.requested_housemates ?? [];
};

onMounted(() => {
  setUnit(program?.programSession?.session_units ?? [], housing.roomIds);
  loadPreviousSelection();
});
</script>

<style>
.custom-teal-checkbox:checked {
  accent-color: #007f80;
}
</style>
