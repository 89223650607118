import { getApiClient } from "./API";


const cloudinaryService = {
  async getCloudinaryUploadSignary(paramsToSign) {
    return await getApiClient().post("/cloudinary", paramsToSign);
  },
};

export default cloudinaryService;
