<template>
  <div class="bg-white">
    <div class="m-14 font-montserrat">
      <div class="md:flex items-stretch gap-10">
        <div class="flex flex-col md:flex-1 gap-2">
          <ImageSlider :image-paths="imagePaths" />
          <span class="text-xs text-indigo-base"
            >* All images shown are for illustration only. Actual booked units
            may vary.</span
          >
        </div>
        <div class="md:flex-1">
          <div
            class="flex flex-wrap pb-3 justify-between items-end border-b border-gray-25 gap-2"
          >
            <p
              class="capitalize text-xl font-bold pt-2 md:mt-0 leading-tight text-indigo-base"
            >
              {{ housingType }}
            </p>
            <div class="flex gap-2 order-first md:order-last mt-10 md:mt-0">
              <p
                v-if="props?.showPrice"
                class="py-1 px-2 uppercase font-bold bg-opacity-10"
                :class="[secondaryColor ? `bg-gray-275 ${secondaryColorClass}` : `text-teal-900 bg-teal-400`]"
              >
                {{ price }}
              </p>
              <p
                class="uppercase text-xs font-bold border py-1 px-8 flex justify-center items-center leading-none text-indigo-base"
              >
                {{ tier }}
              </p>
            </div>
          </div>
          <h1 class="text-4xl text-indigo-base font-bold leading-none mt-2">
            {{ name }}
          </h1>
          <div
            v-if="nonGenericListing"
            class="flex flex-wrap mt-4 gap-x-2 text-indigo-base"
          >
            <template v-for="(item, index) in nonGenericListing" :key="index">
              <span class="font-semibold text-base leading-6 break-words">
                {{ item }}
              </span>
              <span
                v-if="index < nonGenericListing.length - 1"
                class="font-semibold text-base leading-6"
              >
                |
              </span>
            </template>
          </div>
          <Description
            text-class="text-sm font-medium mt-4 leading-6"
            :description="unitDescription"
          />
        </div>
      </div>
      <SubtitleSection class="mt-12 md:mt-24" title="Why you’ll Love it Here" />
      <div class="md:flex items-stretch gap-10">
        <div class="flex-1">
          <Description
            text-class="text-lg font-medium leading-8"
            :description="housingDescription"
          />
        </div>
        <div class="flex-1 md:flex items-stretch gap-16 mt-4 md:mt-0">
          <ItemsList
            class="flex-1"
            title="What's included"
            :items="whatsIncluded"
          />
          <ItemsList class="flex-1" title="Perks" :items="perks" />
        </div>
      </div>
      <SubtitleSection class="mt-10" title="What to Expect" />
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <ItemsList title="Facilities" :items="facilities" :max-items="7" />
        <ItemsList
          title="Commute to Campus"
          :items="commuteToCampus"
          :max-items="7"
        />
        <ItemsList title="Neighborhood" :items="neighborhood" :max-items="7" />
        <ItemsList
          title="Safety & Security"
          :items="safetySecurity"
          :max-items="7"
        />
        <ItemsList title="Keep in Mind" :items="keepInMind" :max-items="7" />
      </div>
      <LightBox
        :open="modal?.open"
        :title="modal?.title"
        :items="modal?.items"
        :description="modal?.description"
        @close="closeModal"
      />
      <SubtitleSection class="mt-10" title="Rooms" />
      <div v-if="showRoomBanner" class="text-sm text-indigo-base">
        <p>
          <span class="font-bold">Shared:</span>
          Share a bedroom with another student. API will help match you with a
          roommate or you can make a specific request.
        </p>
        <p>
          <span class="font-bold">Private:</span>
          Enjoy your own room for an added level of privacy, while still having
          all the benefits of living in this student-only community
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-7">
        <RoomCard
          v-for="(room, index) in rooms"
          :key="room.id"
          :room="room"
          :test-id="index"
          :unit-type="props?.unit?.floor_plan"
          :default-room-price="defaultRoomPrice"
          :room-price="roomPrices?.[room.id] ?? 0"
          :selected="props?.selectedRooms.includes(room.id)"
          :show-price="props?.showPrice"
          :show-request-button="props?.showRequestButton"
        />
      </div>
    </div>
    <StickyFooter
      max-next-width="max-w-[260px]"
      :disabled-next="shouldKeepNextButtonDisabled"
      :show-required-section="false"
      :show-footer="props?.showFooter"
      @back="deselectUnit"
      @next="toQuestionnaire"
    />
  </div>
</template>

<script setup>
import Description from "@/components/Configurator/Housing/Description.vue";
import ItemsList from "@/components/Configurator/Housing/ItemsList.vue";
import RoomCard from "@/components/Configurator/Housing/RoomCard.vue";
import SubtitleSection from "@/components/Configurator/Housing/SubtitleSection.vue";
import StickyFooter from "@/components/Configurator/StickyFooter.vue";
import ImageSlider from "@/components/Configurator/widgets/ImageSlider.vue";
import LightBox from "@/components/Configurator/widgets/LightBox.vue";
import { computed, inject, provide, reactive } from "vue";
import { getRoomPriceLabelFromList } from "@/components/Configurator/pricing";


const props = defineProps({
  unit: {
    type: Object,
    default: () => {},
  },
  defaultRoom: {
    type: Object,
    default: () => {},
  },
  selectedRooms: {
    type: Array,
    default: () => [],
  },
  showRequestButton: {
    type: Boolean,
    default: true,
  },
  showFooter: {
    type: Boolean,
    default: true,
  },
  showPrice: {
    type: Boolean,
    default: true,
  },
  roomPrices: {
    type: Object,
    default: () => {},
  },
});
const toQuestionnaire = inject("toQuestionnaire");
const deselectUnit = inject("deselectUnit");
const { configuratorOptions } = inject("configuratorOptions") ?? {};

const housing = computed(() => props?.unit?.housings || {});
const imagePaths = computed(() => housing.value?.supporting_media || []);
const name = computed(() => props?.unit?.name || "");
const housingType = computed(() => {
  if (housing.value?.subcategory === "Residencia") {
    return "Residencia";
  } else {
    return housing.value?.type || "";
  }
});
const tier = computed(() => {
  return {
    "Tier I": "Basic",
    "Tier II": "Classic",
    "Tier III": "Comfort",
  }[housing.value?.tier?.name || "Tier I"];
});
const rooms = computed(
  () => props?.unit?.rooms?.filter((room) => room?.status === "Active") || []
);
const nonGenericListing = computed(() => {
  if (housing.value?.generic_listing) {
    return [];
  } else {
    let items = [];
    if (props?.unit?.floor_plan) items.push(props?.unit.floor_plan);
    if (props?.unit?.full_bath_count)
      items.push(`${props?.unit.full_bath_count} full bath`);
    if (props?.unit?.half_bath_count)
      items.push(`${props?.unit.half_bath_count} half bath`);
    if (props?.unit?.kitchen_type) items.push(props?.unit.kitchen_type);

    return items;
  }
});

const unitDescription = computed(() => props?.unit?.description || "");
const housingDescription = computed(() => housing.value?.description || "");

const defaultRoomPrice = computed(
  () => props?.roomPrices?.[props?.defaultRoom?.id] ?? 0
);

const price = computed(() => getRoomPriceLabelFromList(
  props.unit,
  configuratorOptions.rooms
));

const showRoomBanner = computed(() => {
  let validRooms = rooms.value.filter((room) => room.unitType !== "Studio");
  return (
    validRooms.some((item) => item.capacity === 1) &&
    validRooms.some((item) => item.capacity > 1)
  );
});

const shouldKeepNextButtonDisabled = computed(() => 
  !!props?.defaultRoom?.id && props?.selectedRooms?.length < 1
);

/**
 * MODAL
 */
const modal = reactive({
  open: false,
  title: "",
  items: [],
  description: "",
});
const openModal = (obj) => {
  modal.open = true;
  modal.title = obj?.title ?? "";
  modal.items = obj?.items ?? [];
  modal.description = obj?.description ?? "";
};
const closeModal = () => {
  modal.open = false;
  modal.title = "";
  modal.items = [];
  modal.description = "";
};
provide("openModal", openModal);

/**
 * AMENITIES
 */
const housingAmenities = computed(() => housing.value?.housing_amenities ?? []);
const unitAmenities = computed(() => props?.unit?.unit_amenities ?? []);
const whatsIncluded = computed(() => {
  const ids = [22, 23, 24, 27];
  const categories = ["Meal Plan", "Routine Laundry Service"];
  const label = "Inclusions";
  return housingAmenities.value
    .filter(
      (item) =>
        ids.includes(item.amenities_id) ||
        categories.includes(item.category) ||
        item.label === label
    )
    .map((item) => item.title)
    .sort();
});
const facilities = computed(() => {
  const excludedCategory = "Safety";
  const excludedGranularity = "room level";
  const label = "Facilities";

  let allFacilities = [...housingAmenities.value, ...unitAmenities.value];

  let filteredFacilities = allFacilities
    .filter(
      (item) =>
        item.label === label &&
        item.granularity_level !== excludedGranularity &&
        item.category !== excludedCategory &&
        !whatsIncluded.value.includes(item.title)
    )
    .map((item) => item.title);

  if (props?.unit?.kitchen_type) {
    props?.unit?.kitchen_type.toLowerCase() === "shared"
      ? filteredFacilities.push("Shared kitchen access")
      : filteredFacilities.push(props?.unit.kitchen_type);
  }

  return filteredFacilities.sort();
});
const safetySecurity = computed(() => {
  const category = "Safety";
  return housingAmenities.value
    .filter((item) => item.category === category)
    .map((item) => item.title)
    .sort();
});
const keepInMind = computed(() => {
  let amenities = [];

  let laundryAmenities = [39, 21]; // 39: Community Laundry (free), 21: Community Laundry (paid)

  if (props?.unit?.kitchen_type === "No Kitchen Access")
    amenities.push("There is no kitchen access");

  if (housingAmenities.value.some((item) => item.category === "Meal Plan"))
    amenities.push(
      "Dietary restrictions can be accommodated but may require an additional fee"
    );
  else amenities.push("Meals are not included");

  if (!unitAmenities.value.some((item) => item.id === 27))
    amenities.push("Kitchen Essentials not provided");

  if (!unitAmenities.value.some((item) => item.id === 44))
    amenities.push("Towels are not provided");

  if (
    !unitAmenities.value.some((item) => item.id === 144) &&
    !housingAmenities.value
      .map((item) => item.amenities_id)
      .some((id) => laundryAmenities.includes(id))
  )
    amenities.push("There isn't any on-site laundry");

  if (!unitAmenities.value.some((item) => item.id === 22))
    amenities.push("Bedding Essential are not provided");

  return amenities;
});

/**
 * TAGS
 */
const tags = computed(() => housing.value?.tags ?? []);
const perks = computed(() => {
  const categories = [
    "fb180b97-2b07-42a3-b6d8-f263c62e9eda",
    "1e451276-acda-4ab0-a6c8-8b7cbaf287a3",
  ];
  return tags.value
    .filter((item) => categories.includes(item.category_id))
    .map((item) => item.name)
    .sort();
});
const commuteToCampus = computed(() => {
  const subCategory = "bb9fa707-b0d6-4daa-8405-745a6afb1be9";
  return tags.value
    .filter((item) => item.subcategory_id === subCategory)
    .map((item) => item.name)
    .sort();
});
const neighborhood = computed(() => {
  const subCategories = "19a56c0b-f11b-4c41-981e-a81e44a04d97";
  const category = "d40d862c-b21e-4e26-8842-4f407efd2047";
  const excludedSubCategory = "bb9fa707-b0d6-4daa-8405-745a6afb1be9";

  return tags.value
    .filter(
      (item) =>
        (item.subcategory_id === subCategories ||
          item.category_id === category) &&
        item.subcategory_id !== excludedSubCategory
    )
    .map((item) => item.name)
    .sort();
});
</script>
