<template>
  <div
    class="my-4 mb-8 border font-montserrat text-indigo-base border-light-gray scoped-box-shadow md:flex md:items-stretch"
  >
    <div class="h-full md:w-3/6 md:flex-grow">
      <div
        class="p-3"
        :class="[secondaryColor ? `bg-gray-275` : 'bg-teal-accent']"
      >
        <p class="text-sm font-bold uppercase">{{ subcategory }}</p>
      </div>

      <ImageSlider :image-paths="imagePaths" :use-image-compression="true" />
    </div>

    <div class="flex flex-col p-4 md:py-0 md:pl-10 md:flex-shrink md:w-3/6">
      <div class="flex-grow">
        <div v-if="price" class="mt-4 md:text-right">
          <span
            :class="`inline-block uppercase ${secondaryColorClass} font-bold text-sm`"
          >
            {{ price }}
          </span>
        </div>

        <div class="mt-2 text-2xl font-bold leading-none">
          {{ title }}
        </div>

        <div class="mt-2 text-sm">{{ address }}</div>

        <div class="mt-2 text-sm whitespace-pre-wrap">
          {{ duration }}
        </div>

        <div class="mt-6 text-sm">
          <JSONtoHTML
            class="text-base text-indigo-base h-30"
            :tiptap-json="description"
            custom-class="line-clamp-3"
          />
        </div>
      </div>

      <div class="flex justify-between mt-6 mb-2 align-baseline">
        <div
          :class="`uppercase font-bold ${secondaryColorClass} p-4 flex cursor-pointer`"
          @click="handleViewDetailsClick(props.excursion)"
        >
          <span class="inline-block">{{ viewButtonVerbiage }}</span>
          <div class="inline-block py-1 ml-3">
            <svg
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -1.01583e-06L-7.14641e-07 1.71183L5.38866 7.00874Z"
                :fill="[secondaryColor ? `${secondaryColor}` : '#007F80']"
              />
            </svg>
          </div>
        </div>

        <button
          v-if="!isExcursionIncluded"
          class="flex relative items-center p-4 font-bold uppercase border-2"
          :class="
            props.selected
              ? `${secondaryColorClassBG} text-white`
              : `${secondaryColorClassOutlinedNoHover}`
          "
          @click="selectExcursion(props.excursion?.id)"
        >
          <span class="inline-block">
            {{ UnitSelection }}
          </span>
          <div v-if="!props.selected" class="inline-block ml-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </div>
          <div v-else class="absolute -top-3 -right-3">
            <MinusSignCircle />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import ImageSlider from "@/components/Configurator/widgets/ImageSlider.vue";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";
import { formatter } from "@/components/program-manager/sessions/utils.js";
import { computed, defineProps, inject } from "vue";
import MinusSignCircle from "../widgets/MinusSignCircle.vue";
import { getRidOfJSON } from "@/util/removeJson";
import { DEFAULT_EXCURSION_IMAGE } from "@/constants.js";

const excursionRules = inject("excursionRules");
const viewDetails = inject("viewDetails");
const selectExcursion = inject("selectExcursion");
const { configuratorOptions } = inject("configuratorOptions");

const props = defineProps({
  excursion: {
    type: Object,
    default: () => {
      return {};
    },
  },
  selected: {
    type: Boolean,
    default: false,
  },
  defaultExcursion: {
    type: Object,
    default: () => {},
  },
});

const event = computed(() => props.excursion?.event ?? {});
const location = computed(() =>
  event.value?.event_locations?.length ? event.value.event_locations[0] : {}
);
const subcategory = computed(() => event.value?.event_subcategory?.name ?? "");
const imagePaths = computed(() =>
  event.value?.image
    ? getRidOfJSON(event.value?.image)
    : [DEFAULT_EXCURSION_IMAGE]
);

const optionsPricing = computed(() => {
  const excursions = configuratorOptions.event_occurrences;
  return excursions.find((e) => e.productId === props.excursion?.id);
})

const price = computed(() => {
  return optionsPricing.value?.excursionPriceLabel(isExcursionIncluded.value) ?? "";
});

const isExcursionIncluded = computed(() => {
  return (
    excursionRules?.value?.defaultIds.includes(props.excursion?.id) ||
    excursionRules?.value?.andIds.includes(props.excursion?.id)
  );
});

const title = computed(() => event.value?.title ?? "");
const description = computed(() => event.value?.description ?? {});
const address = computed(() => {
  let city = location.value?.city?.city ?? "";
  let country = location.value?.city?.country?.name ?? "";
  if (!city && !country) return "";

  return `${city}, ${country}`;
});
const duration = computed(() =>
  event.value?.duration && event.value?.duration_units
    ? `${event.value.duration} ${
        event.value.duration_units.slice(-1) === "s"
          ? event.value.duration_units.slice(0, -1) + "(s)"
          : event.value.duration_units
      }`
    : ""
);
const includes = computed(() => {
  // per AP-3105
  const excludedTerms = [
    "food + drinks",
    "breakfast",
    "lunch",
    "dinner",
    "other",
    "includeOther",
  ];

  const includesItems =
    event.value?.includes.filter((x) => !excludedTerms.includes(x)) ?? [];

  return includesItems.join("  |  ");
});

const UnitSelection = computed(() => {
  return props.selected
    ? "Selected"
    : checkIfDeviceIsMobile()
    ? "Add"
    : "Add excursion";
});

const checkIfDeviceIsMobile = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  return screenWidth < 900;
};

const viewButtonVerbiage = computed(() =>
  checkIfDeviceIsMobile() ? "View " : "View Details"
);

const scrollUp = () => {
  if (checkIfDeviceIsMobile()) {
    const element = document.getElementById("process-steps");
    if (element) {
      element.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  } else {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }
};

const handleViewDetailsClick = (excursion) => {
  viewDetails(excursion);
  scrollUp();
};
</script>
