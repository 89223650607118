import { computed, onMounted, ref } from "vue";
import { format, parse } from "date-fns";
import { uuid } from "vue-uuid";
import { useStore } from "vuex";
import { channelTypes } from "@/components/program-manager/sessions/constants.js";
import { setIpedsId } from "@/mixins/helpers.js";

export function usePaymentGeneralEvent(applicationId, session, contractId) {
  const store = useStore();
  const ipedsId = ref(null);

  const userData = computed(() => store.state.userData);
  const profileData = computed(() => store.state.profileData);
  const currentUser = computed(() => store.state.currentUser);
  const customerId = computed(() => store.state.customer_id);
  const sessionYear = computed(() => {
    const arrival_date =
      session.session_travel_details[0].arrival_date ??
      session?.academic_start_date;
    let year = format(parse(arrival_date, "yyyy-MM-dd", new Date()), "yyyy");
    return year;
  });

  const getIpedsId = async () => {
    ipedsId.value = await setIpedsId(userData.value.data?.schoolname?.value);
  };

  const getDataForPaymentService = (product) => {
    return {
      event_id: "evt_" + uuid.v4(),
      event_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      resource_id: userData.value._id,
      amount_in_cents: product.price,
      application_id: applicationId,
      program_name: session.name,
      program_type: session.session_types?.[0]?.name ?? null,
      session_country:
        session.session_travel_details?.[0]?.arrival_city?.country?.name ??
        null,
      source_type: "connect",
      customer_id: customerId.value,
      event_data: {
        first_name: userData.value.data.firstname,
        last_name: userData.value.data.lastname,
        email: userData.value.data.email,
        phone: userData.value.data.phone,
        birth_date: userData.value.data.birthday,
        participant_type: userData.value.data.student_type,
        home_university_name: userData.value.data.schoolname.label,
        home_institution_ipeds: ipedsId.value,
        program_city:
          session.session_travel_details?.[0]?.arrival_city?.city ?? null,
        program_country:
          session.session_travel_details?.[0]?.arrival_city?.country.name ??
          null,
        program_session_id: session.salesforce_id,
        year: sessionYear.value,
        start_date:
          session.session_travel_details[0].arrival_date ??
          session?.academic_start_date,
        end_date:
          session.session_travel_details?.[0]?.departure_date ??
          session?.academic_end_date,
        end_date_is_final:
          session.session_travel_details?.[0]?.departure_date_is_final ?? false,
        start_date_is_final:
          session.session_travel_details[0].arrival_date_is_final ?? false,
        host_institution:
          session.session_hosting_institutions?.[0]?.entity?.name,
        permanent_street: profileData.value.permanent_address_line_1,
        permanent_city: profileData.value.permanent_address_city,
        permanent_state_province: profileData.value.permanent_address_state,
        permanent_country: profileData.value.permanent_address_country_id,
        permanent_zip_postal_code:
          profileData.value.permanent_address_postal_code,
        contract_number: contractId,
        product_name: product.title,
        price: product.price,
        session: session.terms?.[0]?.session ?? null,
        is_application_fee: false,
        is_institution_pays: true,
        legacy_participant_account_id: customerId.value,
        program_name: session.name,
        program_type: session.session_types?.[0]?.name ?? null,
        session_country:
          session.session_travel_details?.[0]?.arrival_city?.country?.name ??
          null,
        is_customized: session?.channel_id === channelTypes.CUSTOM,
        application_id: applicationId,
        billing_street: profileData.value.billing_address_line_1,
        billing_city: profileData.value.billing_address_city,
        billing_state_province: profileData.value.billing_address_state,
        billing_zip_postal_code: profileData.value.billing_address_postal_code,
        participant_id: currentUser.value?.participantId,
        version: store.state.uiVersion,
      },
      product: [],
    };
  };

  onMounted(() => {
    getIpedsId();
  });

  return {
    getDataForPaymentService,
  };
}
