<template>
  <div class="px-6 py-6 text-lg md:px-24 md:py-12 text-indigo-base">
    <h1
      v-if="!isFacultyLed"
      data-testid="personalize-header"
      class="font-bold font-montserrat md:text-configurator-h1 md:uppercase"
    >
      Personalize Your Adventure
    </h1>
    <h2 class="mt-4 mb-6 ml-6 md:mt-0 md:ml-0 font-montserrat md:text-xl">
      Before we get started, please confirm the information below.
    </h2>
    <div class="p-2">
      <div
        class="flex flex-col gap-4 items-start w-full md:flex-row md:justify-between md:items-end"
      >
        <div class="w-full md:p-2 md:w-3/6">
          <label class="block my-2 text-lg font-montserrat md:font-semibold">
            What best describes you?
          </label>
          <SkeletonLoader v-if="filteredLearnerTypes.length === 0" rounded />
          <v-select
            v-else
            id="learnerType"
            v-model="selectedLearnerType"
            data-testid="learner-type-input-box"
            class="v-select"
            :disabled="!selectedSession"
            :options="filteredLearnerTypes"
            :clearable="false"
            :get-option-label="(option) => option.value"
            append-icon="ArrowDown"
          >
            <template #open-indicator="{ attributes }">
              <ArrowDown :attributes="attributes" />
            </template>

            <template #option="option">
              <div>
                <div
                  :data-testid="`${option.value.replace(/\s+/g, '-')}-option`"
                  class="inline-block align-top whitespace-normal break-words overflow-wrap-anywhere"
                >
                  <p style="margin: 0;">
                    {{ option.value }}
                  </p>
                </div>
              </div>
            </template>
          </v-select>
        </div>
        <div
          v-if="requiredSchool.includes(learnerTypeId)"
          class="w-full md:p-2 md:w-3/6"
        >
          <label
            class="block text-lg font-montserrat md:font-semibold"
            :class="disabledUniParticipant ? 'my-2' : 'mt-2 mb-1'"
          >
            What is the name of your college/university?
          </label>
          <SkeletonLoader
            v-if="program.universitiesLoading && allowedUniversities.length < 1"
            rounded
          />
          <div v-else>
            <p
              data-testid="uni-search-instruction"
              v-if="!disabledUniParticipant"
              class="mb-2 text-sm"
            >
              Search by full name ex: University of Central Florida for UCF.
            </p>
            <v-select
              v-model="selectedHomeInstitution"
              data-testid="college-option-input-box"
              :options="universities"
              class="v-select"
              :get-option-label="(option) => option.name"
              :disabled="disabledUniParticipant"
              :clearable="!disabledUniParticipant"
              :no-drop="disabledUniParticipant"
              append-icon="ArrowDown"
              @search="search"
            >
              <template
                v-if="disabledUniParticipant"
                #open-indicator="{ attributes }"
              >
                <ArrowDown :attributes="attributes" />
              </template>
              <template #option="option">
                <div>
                  <div
                    :data-testid="`${option.name.replace(/\s+/g, '-')}-option`"
                    class="inline-block align-top whitespace-normal break-words overflow-wrap-anywhere"
                  >
                    <p style="margin: 0;">
                      {{ option.name }}
                    </p>
                  </div>
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <hr class="block my-8 w-full border border-light-gray" />
      <div class="p-2 md:w-3/6">
        <h2 class="text-lg font-montserrat md:text-2xl md:font-bold">
          {{ program?.programPage?.name }}
        </h2>
        <label class="block my-2 text-lg font-montserrat md:font-semibold">
          Program Session:
        </label>
        <SkeletonLoader v-if="sessionsAreLoading" rounded />
        <div v-else>
          <!-- Display label if there's an internships selected -->
          <div v-if="hasSelectedInternship">
            <div
              class="inline-block align-top whitespace-normal break-words overflow-wrap-anywhere"
            >
              <p style="margin: 0;">
                {{ preselectedSessionLabel }}
              </p>
            </div>
          </div>
          <!-- Display v-select if there's no internships selected-->
          <v-select
            v-else
            v-model="selectedSession"
            :options="sessions"
            data-testid="session-input-box"
            class="v-select"
            append-icon="ArrowDown"
          >
            <template #open-indicator="{ attributes }">
              <ArrowDown :attributes="attributes" />
            </template>

            <template #option="option">
              <div
                class="inline-block align-top whitespace-normal break-words overflow-wrap-anywhere"
              >
                <p style="margin: 0;">
                  {{ option.label }}
                </p>
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div
      class="flex justify-center items-center px-8 py-4 mx-auto my-8 align-middle font-montserrat md:mt-20 md:ml-auto md:-mr-20"
    >
      <ButtonWithSpinner
        id="getStartedButton"
        ref="getStartedButton"
        data-testid="get-started-button"
        type="submit"
        variant="secondary"
        variant-type="block"
        button-height="min-h-[58px] md:min-h-[60px] mt-4"
        :grey-disabled-class="true"
        :disabled="!isActionButtonEnabled"
        @click="handleActionButtonClick"
      >
        <div
          class="flex justify-center items-center text-lg md:text-base md:font-bold"
        >
          <span class="pr-3 uppercase">
            Get started
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
    </div>
  </div>
</template>

<script setup>
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import { entityTypes } from "@/components/program-manager/sessions/constants.js";
import ArrowDown from "@/components/shared/icons/ArrowDown.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import SkeletonLoader from "@/components/shared/loaders/BaseSkeletonLoader.vue";
import {
  GAP_YEAR_STUDENT,
  INTERNATIONAL_COLLEGE_STUDENT_ID,
  INTERNATIONAL_GRADUATE_STUDENT_ID,
  INTERNATIONAL_HIGH_SCHOOL_STUDENT_ID,
  NOT_ENROLLED,
  requiredSchool,
  US_COLLEGE_STUDENT_ID,
  US_GRADUATE_STUDENT_ID,
  US_HIGH_SCHOOL_STUDENT_ID,
} from "@/constants";
import entitiesService from "@/services/entities.js";
import { durationLabel } from "@/util/date";
import { debounce } from "lodash";
import { computed, inject, onMounted } from "vue";
import VSelect from "vue-select";
import { useStore } from "vuex";

const store = useStore();

const {
  program,
  setLoadedUniversities,
  setProgramSession,
  homeInstitutionId,
  learnerTypeId,
} = inject("program");

const selectedHomeInstitution = computed({
  get: () => universities.value.find((u) => u.id === homeInstitutionId.value),
  set: (school) => {
    homeInstitutionId.value = school?.id;
  },
});

const learnerTypes = computed(
  () => store.getters["learnerTypes/getLearnerTypes"]
);

const selectedLearnerType = computed({
  get: () =>
    filteredLearnerTypes.value.find((type) => type.id === learnerTypeId.value),
  set: (learnerType) => {
    learnerTypeId.value = learnerType?.id;
  },
});

const { goToNextStep, currentStep } = inject("steps");

const { selectedInternshipIds } = inject("internship");

const hasSelectedInternship = computed(
  () => selectedInternshipIds.value.length > 0
);

const isActionButtonEnabled = computed(() => currentStep.value.isComplete());

const handleActionButtonClick = () => {
  if (isActionButtonEnabled.value) {
    goToNextStep();
    scrollUp();
  }
};

const sessions = computed(() => {
  return [...program.loadedSessions]
    .sort((left, right) => {
      const ltd = getTravelDetails(left);
      const rtd = getTravelDetails(right);
      return Number(new Date(ltd.startDate)) - Number(new Date(rtd.startDate));
    })
    .map((session) => {
      const { startDate, endDate } = getTravelDetails(session);
      if (startDate !== "" && endDate !== "")
        return {
          ...session,
          label: durationLabel({ startDate, endDate }),
        };
    })
    .filter((session) => session?.label);
});

const selectedSession = computed({
  get: () => sessions.value.find((session) => session.id === program.sessionId),
  set: (newSession) => {
    setProgramSession(newSession);
  },
});

const filteredLearnerTypes = computed(() => {
  if (!selectedSession.value?.education_levels?.length) {
    return learnerTypes.value;
  }
  let filtered = [];
  let lType = [];
  (selectedSession.value?.education_levels ?? []).forEach((education_level) => {
    switch (education_level.value) {
      case "Undergraduate":
        lType = learnerTypes.value.filter((type) =>
          [
            US_COLLEGE_STUDENT_ID,
            INTERNATIONAL_COLLEGE_STUDENT_ID,
          ].some((opt) => type.id.includes(opt))
        );
        break;
      case "High School":
        lType = learnerTypes.value.filter((type) =>
          [
            US_HIGH_SCHOOL_STUDENT_ID,
            INTERNATIONAL_HIGH_SCHOOL_STUDENT_ID,
          ].some((opt) => type.id.includes(opt))
        );
        break;
      case "Not Enrolled":
        lType = learnerTypes.value.filter((type) =>
          [NOT_ENROLLED, GAP_YEAR_STUDENT].some((opt) => type.id.includes(opt))
        );
        break;
      case "Continuing Education":
        lType = learnerTypes.value.filter((type) =>
          [
            US_GRADUATE_STUDENT_ID,
            INTERNATIONAL_GRADUATE_STUDENT_ID,
          ].some((opt) => type.id.includes(opt))
        );
        break;
      case "Professional":
        lType = learnerTypes.value.filter((type) =>
          [
            US_GRADUATE_STUDENT_ID,
            INTERNATIONAL_GRADUATE_STUDENT_ID,
          ].some((opt) => type.id.includes(opt))
        );
        break;
      default:
        break;
    }
    filtered = filtered.concat(lType);
  });
  filtered = filtered
    //filtering so it does not have duplicates
    .filter((type, i) => {
      return filtered.indexOf(type) === i;
    })
    //sorting the learner types so it wont be messy
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

  return filtered;
});

const sessionsAreLoading = computed(() => program.loading);

const isFacultyLed = computed(() => {
  return selectedSession.value
    ? selectedSession.value?.session_types?.some((type) => type.id === 4)
    : false;
});

const getTravelDetails = (session) => {
  const travelDetails = session?.session_travel_details ?? [];

  const sortedAsc = travelDetails.sort(
    (a, b) =>
      Number(new Date(a.arrival_date)) - Number(new Date(b.arrival_date))
  );
  const arrivalDetails = sortedAsc.length ? sortedAsc[0] : {};

  const sortedDesc = travelDetails.sort(
    (a, b) =>
      Number(new Date(b.departure_date)) - Number(new Date(a.departure_date))
  );
  const departureDetails = sortedDesc.length ? sortedDesc[0] : {};

  return {
    startDate: arrivalDetails?.arrival_date ?? "",
    endDate: departureDetails?.departure_date ?? "",
  };
};

const preselectedSessionLabel = computed(() => {
  const { startDate, endDate } = getTravelDetails(selectedSession.value);
  return durationLabel({ startDate, endDate });
});

const search = debounce(async (search, loading) => {
  if(search.length === 0) {return;}
  loading(true);
  entitiesService
    .getEntities({
      q: search,
      extraParams: {
        account_types: [entityTypes.home_institution],
        field: "name",
        order: "ASC",
      },
    })
    .then(({ data }) => {
      setLoadedUniversities(data?.data?.items ?? []);
    })
    .catch(() => {
      setLoadedUniversities([]);
    })
    .finally(() => {
      loading(false);
    });
}, 500);

const allowedUniversities = computed(
  () => selectedSession.value?.allowed_participants || []
);

const universities = computed(() => {
  const schools =
    allowedUniversities.value.length < 1
      ? program.loadedUniversities
      : allowedUniversities.value.map((allowed) => {
          return {
            archived_at: allowed.archived_at,
            created_at: allowed.created_at,
            id: allowed.id,
            ipeds_id: allowed.ipeds_id,
            name: allowed.name,
            ope_id: allowed.ope_id,
            updated_at: allowed.updated_at,
            updated_by: allowed.updated_by,
          };
        });
  return schools.map((school) => ({
    label: school?.name,
    value: school?.ope_id,
    ...school,
  }));
});

const disabledUniParticipant = computed(
  () => allowedUniversities.value.length === 1
);

const checkIfDeviceIsMobile = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  return screenWidth < 900;
};

const scrollUp = () => {
  if (checkIfDeviceIsMobile()) {
    const element = document.getElementById("process-steps");
    if (element) {
      element.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  } else {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }
};

onMounted(() => {
  if (universities.value.length === 1) {
    selectedHomeInstitution.value = universities.value[0];
  }
});
</script>

<style scoped>
.v-select {
  --vs-border-radius: 0;
}

.v-select > .vs__dropdown-toggle {
  border-radius: 0 !important;
  border-color: black !important;
  color: #1e264c !important;
}
</style>
