<template>
  <div v-if="enabled">
    <div v-for="day in daysOfWeek" :key="day">
      <StartEndTimePerDay
        :day-of-week="day"
        :default-values="response[day]"
        @update-time="handleInput"
      />
    </div>
  </div>
</template>

<script>
import { DAYS_OF_WEEK } from "@/constants.js";

import StartEndTimePerDay from "./StartEndTimePerDay.vue";

export default {
  name: "StaffReceptionInput",
  components: {
    StartEndTimePerDay,
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    defaultAvailability: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["updateStaffField"],
  data() {
    return {
      daysOfWeek: DAYS_OF_WEEK,
      response: {},
    };
  },
  created() {
    this.response = this.defaultAvailability;
  },
  methods: {
    handleInput({ key, value }) {
      this.response[key] = value;
      this.$emit("updateStaffField", this.response);
    },
  },
};
</script>
