<script setup>
// For details of how to use it, check the PR for the ticket 1586
import ArrowRight from "./icons/ArrowRight.vue";
import ArrowLeft from "./icons/ArrowLeft.vue";
import { useRoute, useRouter } from "vue-router";
import { defineProps } from "vue";
import { anyAppliedFilter } from "@/composables/useQueryParams.js";

const route = useRoute();
const router = useRouter();
const breadcrumbs = route.meta.breadcrumb;
const props = defineProps({
  customActiveClass: {
    default: "font-semibold",
    type: String,
  },
  routeParams: {
    default: () => ({}),
    type: Object,
  },
});

const areThereParamsAndParamToIgnoreDoesntExist = (routeParams, parent) => {
  return (
    routeParams[parent] &&
    !routeParams[parent].params[routeParams[parent].paramToIgnoreRouteIfNotSet]
  );
};

const query = (parent) => {
  if (parent === "home" || parent == null || parent == "") return {};
  let filter = anyAppliedFilter(route);
  return filter;
};
</script>

<template>
  <nav
    v-if="breadcrumbs.length"
    data-testid="breadcrumb-nav"
    class="flex bg-transparent md:pt-4 md:pb-4 font-montserrat"
    aria-label="Breadcrumb"
  >
    <ol
      role="list"
      class="flex items-center flex-wrap md:space-x-4 lg:space-x-4 space-x-1"
    >
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :id="`breadcrumb-${index}`"
        :key="index"
        class="md:flex lg:flex justify-around hidden"
      >
        <div
          v-if="
            !areThereParamsAndParamToIgnoreDoesntExist(
              routeParams,
              breadcrumb?.parent
            )
          "
          class="flex items-center text-sm"
        >
          <ArrowRight v-if="index" custom-class="w-3 h-3" />
          <router-link
            v-if="breadcrumb.title === 'Back' && breadcrumb?.path"
            :id="`breadcrumb-link-${index}`"
            :class="[
              index === breadcrumbs.length - 1
                ? [props.customActiveClass, `${secondaryColorClass}`]
                : 'font-medium text-indigo-base hover:text-gray-900',
              'ml-4 text-xs',
            ]"
            :to="breadcrumb?.path"
          >
            {{ breadcrumb.title }}
          </router-link>
          <router-link
            v-else
            :id="`breadcrumb-link-${index}`"
            :class="[
              index === breadcrumbs.length - 1
                ? props.customActiveClass
                : 'font-medium text-indigo-base hover:text-gray-900',
              'ml-4 text-xs',
            ]"
            :to="{
              name: breadcrumb?.parent,
              params: props.routeParams[breadcrumb?.parent]?.params,
              query: query(breadcrumb?.parent),
            }"
          >
            {{ breadcrumb.title }}
          </router-link>
        </div>
      </li>
      <li class="md:hidden lg:hidden flex justify-start py-3">
        <div
          class="flex items-center text-sm cursor-pointer"
          @click="router.go(-1)"
        >
          <ArrowLeft :custom-class="`w-4 h-4 ${secondaryColorClass}`" />
          <span
            id="last-page-label"
            :class="[
              `font-bold hover:text-gray-700 ${secondaryColorClass}`,
              'ml-1 text-xs',
            ]"
          >
            Last Page
          </span>
        </div>
      </li>
    </ol>
  </nav>
</template>
