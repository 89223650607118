<template>
  <span
    :class="`flex justify-center items-center gap-1 font-bold text-base leading-4.5 uppercase`"
    >search
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="ml-3.5"
    >
      <path
        d="M9.76705 2.19997C5.82007 2.19997 2.60897 5.49772 2.60897 9.5512C2.60897 13.6047 5.82008 16.9024 9.76706 16.9024C13.714 16.9024 16.9252 13.6047 16.9252 9.55121C16.9252 7.58761 16.1806 5.74156 14.8286 4.3531C13.4766 2.96463 11.6791 2.19997 9.76705 2.19997ZM9.76705 0C9.76736 0 9.76675 0 9.76706 0C14.9035 0 19.0673 4.27622 19.0673 9.55121C19.0673 14.8262 14.9035 19.1024 9.76706 19.1024C4.63067 19.1024 0.466797 14.8262 0.466797 9.5512C0.466797 4.27654 4.63109 0 9.76705 0ZM19.0673 9.5512V9.55121C19.0673 9.5512 19.0673 9.5512 19.0673 9.5512Z"
        fill="currentColor"
      />
      <path
        d="M21.8917 22.0007L15.7559 15.6992"
        stroke="currentColor"
        stroke-width="3"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
