<script setup>
import BaseInput from "./BaseInput.vue";
import {
  watch,
  defineProps,
  ref,
  defineEmits,
  onUnmounted,
  onMounted,
} from "vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const input = ref({
  value: null,
  proficiency_level: null,
});

const mustHaveData = (item) => {
  if (item?.id === undefined || item?.value === undefined) return false;
  return true;
};
const rules = {
  proficiency_level: {
    required,
    mustHaveData: helpers.withMessage(
      "This field cannot be empty",
      mustHaveData
    ),
  },
};
const v$ = useVuelidate(rules, input);

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  fieldSettings: {
    required: true,
    type: Object,
    default: () => {},
  },
  label: {
    required: true,
    type: String,
    default: "",
  },
  startedValue: {
    required: false,
    type: Object,
    default: null,
  },
  vuelidateErrors: {
    type: Array,
    default: () => [],
  },
});

watch(
  input,
  (value) => {
    emit("update:modelValue", Object.assign(value, { value: props.label }));
  },
  { deep: true }
);

onMounted(() => {
  if (props.startedValue) {
    input.value.proficiency_level = {
      value: props.startedValue.value,
      id: props.startedValue.id,
    };
  }
});

onUnmounted(() => {
  emit("update:modelValue", Object.assign(input.value, { operation: "remove"}));
});
</script>

<template>
  <div>
    <fieldset class="md:flex lg:flex md:items-center mb-2">
      <label class="text-base text-gray-700 md:w-[100px] lg:w-[100px]">{{
        props.label
      }}</label>
      <BaseInput
        v-model="input.proficiency_level"
        class="w-full md:ml-8 lg:ml-8"
        :field-settings="props.fieldSettings"
        :vuelidate-errors="v$.proficiency_level.$errors"
      />
    </fieldset>
  </div>
</template>
