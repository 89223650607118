export const scrollToTarget = (targetedDiv, scrollDuration) => {
  const targetPosition = targetedDiv.value.offsetTop;
  const initialPosition = window.scrollY;
  const distance = targetPosition - initialPosition;
  const startTime = performance.now();

  const scroll = (currentTime) => {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / scrollDuration, 1);
    window.scrollTo(0, initialPosition + distance * progress);

    if (progress < 1) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};
