import SentryHelp from "../mixins/sentryHelper";
import { trailingSlash } from "./helpers";

export default {
  mixins: [SentryHelp],
  data() {
    return {
      universities: [],
    };
  },
  computed: {
    universitiesURL() {
      return (
        trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "universities"
      );
    },
  },
  created() {
    this.getUniversities();
  },
  methods: {
    getUniversities() {
      fetch(this.universitiesURL)
        .then((response) => {
          if (!response.ok) throw Error(response.status);
          return response.json();
        })
        .then((data) => {
          data.sort((a, b) => a.label.localeCompare(b.label));
          this.universities = data;
        })
        .catch((error) => {
          console.error(error);
          this.throwSentryError("Fetch Universities (Lead)", { error: error });
        });
    },
    searchUniversity(search, loading) {
      loading(true);
      fetch(this.universitiesURL + "?search=" + encodeURIComponent(search))
        .then((response) => {
          if (!response.ok) throw Error(response.status);
          return response.json();
        })
        .then((data) => {
          data.sort((a, b) => a.label.localeCompare(b.label));
          this.universities = data;
          loading(false);
        })
        .catch((error) => {
          console.error(error);
          vm.throwSentryError("Fetch Universities (Lead)", { error: error });
          loading(false);
        });
    },
  },
};
