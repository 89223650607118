<script setup>
import { ref, computed, defineEmits, defineProps, watch } from "vue";
import { useVModel } from "@vueuse/core";
import PackingListItem from "./PackingListItem.vue";

const props = defineProps({
  modelValue: {
    type: Object,
  },
});

const emit = defineEmits(["update:modelValue"]);
const events = useVModel(props, "modelValue", emit);

const packed = ref([]);

const packingOther = computed(() => {
  return events?.value?.packing_other;
});

const otherPacking = ref([""]);

const addPackingContent = () => {
  otherPacking.value.push("");
};

const removePackingContent = (index) => {
  if (otherPacking.value.length > 1) {
    otherPacking.value.splice(index, 1);
  }
};

const packingList = [
  { id: "snack", name: "Snack" },
  { id: "water", name: "Water" },
  { id: "sun protection", name: "Sun protection" },
  { id: "rain protection", name: "Rain protection" },
  { id: "warm clothes", name: "Warm clothes" },
  { id: "business attire", name: "Business attire" },
  { id: "head covering", name: "Head covering" },
  { id: "walking shoes", name: "Walking shoes" },
  { id: "passport/Identification", name: "Passport/Identification" },
  { id: "local currency", name: "Local currency" },
  { id: "packingOther", name: "Other" },
];

const togglePacked = (id) => {
  packed.value = events.value.packing_list || [];
  if (packed.value.includes(id)) {
    packed.value = packed.value.filter((i) => i !== id);
  } else {
    packed.value = [...packed.value, id];
  }
  events.value.packing_list = packed.value;
};

const otherPackingFields = (id) => {
  const includes = ["packingOther"];
  return includes.includes(id) && events.value.packing_list?.includes(id);
};

const updateOtherPacking = ({ index, newValue }) => {
  if (!packingOther.value) {
    events.value.packing_other = [];
    events.value.packing_other[index] = newValue;
  }
  otherPacking.value[index] = newValue;
};

watch(
  packingOther,
  (val) => {
    otherPacking.value = val;
  },
  { deep: true }
);
</script>

<template>
  <div class="mb-4">
    <label label class="font-semibold"
      >What should the participants pack?</label
    >
    <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
      <PackingListItem
        v-for="item in packingList"
        :key="item.id"
        :item="item"
        :is-other-packing="otherPackingFields(item.id)"
        :other-packing="otherPacking"
        :events="events"
        @update-other-packing="updateOtherPacking"
        @toggle-packing="togglePacked(item.id)"
        @add-packing-content="addPackingContent"
        @remove-packing-content="removePackingContent"
      />
    </div>
  </div>
</template>
