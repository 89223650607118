<template>
  <div class="relative flex items-center">
    <button
      v-if="isCubaProgram"
      data-toggle="make-payment-menu"
      aria-label="Make Payment Menu Toggle"
      data-toggle-indicator="flip"
      class="flex items-center text-sm inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white hover:text-white focus:text-white"
      data-cy="make-payment-menu-button"
      :disabled="disabledPaymentButton"
      :class="[
        { 'opacity-33 cursor-not-allowed': disabledPaymentButton },
        secondaryColor
          ? 'brandingSecondary'
          : 'bg-university-secondary hover:bg-teal-100 focus:bg-teal-100',
      ]"
      @click="toggleModal"
    >
      <span class="mr-2">Make Payment</span>
    </button>
    <button
      v-else
      data-toggle="make-payment-menu"
      aria-label="Make Payment Menu Toggle"
      data-toggle-indicator="flip"
      class="flex items-center text-sm inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white hover:text-white focus:text-white"
      data-cy="make-payment-menu-button"
      :disabled="disabledPaymentButton"
      :class="[
        { 'opacity-33 cursor-not-allowed': disabledPaymentButton },
        secondaryColor
          ? 'brandingSecondary'
          : 'bg-university-secondary hover:bg-teal-100 focus:bg-teal-100',
      ]"
      @click="toggleMenu"
    >
      <span class="mr-2">Make Payment</span>
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-down"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </button>
    <div
      class="absolute right-0 hidden w-48 bg-white shadow-lg menu top-full"
      :style="{ display: open ? 'block' : 'none' }"
    >
      <ul class="py-2 text-sm text-right">
        <li>
          <span
            class="block px-4 py-3 cursor-pointer font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline"
            data-cy="payment-request-menu-link"
            @click="$emit('openRequestModal')"
          >
            Send a Payment Request
          </span>
        </li>
        <li>
          <span
            class="block px-4 py-3 cursor-pointer font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline"
            data-cy="pay-now-menu-link"
            @click="$emit('goToPaymentPage')"
          >
            Pay Now
          </span>
        </li>
      </ul>
    </div>
    <Modal
      :open="modalIsOpen"
      :show-close-button="false"
      @closeModal="toggleModal"
    >
      <template #modalHeader>
        <div class="flex flex-col items-center justify-end">
          <h2
            class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            Payment Options
          </h2>
        </div>
      </template>
      <template #modal-content>
        <div v-if="isCubaProgram" class="flex justify-center gap-20 mt-5">
          Please call 1-800-844-4124 to make your payment.
        </div>
      </template>
      <template #modal-footer>
        <div class="flex justify-center">
          <button
            :class="[
              secondaryColor
                ? `${secondaryColorClass}`
                : `${secondaryColorClass} hover:bg-teal-100 focus:bg-teal-100`,
            ]"
            class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-white hover:text-white w-40"
            @click="closeModal()"
          >
            <span>Cancel</span>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Modal from "@/components/modals/Modal.vue";

export default {
  name: "PaymentsHeader",
  components: {
    Modal,
  },
  props: {
    disabledPaymentButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["openRequestModal", "goToPaymentPage"],
  data() {
    return {
      open: false,
      modalIsOpen: false,
    };
  },
  computed: {
    ...mapState(["customer_id", "program"]),
    isCubaProgram() {
      return this.program.site.country.toLowerCase() === "cuba";
    },
    secondaryColorClass() {
      const { secondaryColor } = this;
      if (secondaryColor) {
        return "brandingOutilined";
      } else {
        return "text-university-secondary border-university-secondary";
      }
    },
  },
  watch: {
    disabledPaymentButton: {
      handler: function (val) {
        if (val) this.open = false;
      },
    },
  },

  methods: {
    toggleMenu() {
      this.open = !this.open;
    },
    toggleModal() {
      this.modalIsOpen = !this.modalIsOpen;
    },
    closeModal() {
      this.modalIsOpen = false;
    },
  },
};
</script>
