export const orderStatusesIds = {
  DRAFT: 1,
  SUBMITTED: 2,
  PAID_CONFIRMED: 3,
  FULFILLED: 4,
  WITHDRAWN: 5,
};

export const orderStatuses = [
  { name: "Draft", id: orderStatusesIds.DRAFT },
  {
    name: "Submitted",
    id: orderStatusesIds.SUBMITTED,
  },
  { name: "Paid, Confirmed", id: orderStatusesIds.PAID_CONFIRMED },
  { name: "Fulfilled", id: orderStatusesIds.FULFILLED },
  { name: "Withdrawn", id: orderStatusesIds.WITHDRAWN },
];

export const productNamesId = {
  ALL: 1,
  HOUSING: 2,
  INTERNSHIPS: 3,
  EVENTS: 4,
  GROUP_FLIGHT: 5,
  GROUP_VISA: 6,
  TRANSCRIPT_SERVICES: 7,
  CLASS_ADDONS: 8,
};

export const productNames = [
  { name: "All", id: productNamesId.ALL },
  { name: "Housing", id: productNamesId.HOUSING },
  {
    name: "Internships",
    id: productNamesId.INTERNSHIPS,
  },
  { name: "Events", id: productNamesId.EVENTS },
  { name: "Group Flight", id: productNamesId.GROUP_FLIGHT },
  { name: "Group Visa", id: productNamesId.GROUP_VISA },
  { name: "Transcript Services", id: productNamesId.TRANSCRIPT_SERVICES },
  { name: "Class Add-ons", id: productNamesId.CLASS_ADDONS },
];

export const productStatusesId = {
  UNFULFILLED: "Unfulfilled",
  FULFILLED: "Fulfilled",
  CANCELED: "Canceled",
};

export const productStatuses = [
  { name: "Unfulfilled", id: productStatusesId.UNFULFILLED },
  {
    name: "Fulfilled",
    id: productStatusesId.FULFILLED,
  },
  { name: "Canceled", id: productStatusesId.CANCELED },
];

export const generateStudentName = (order) =>
  (order?.participant_first_name && order?.participant_last_name
    ? `${order?.participant_first_name} ${order?.participant_last_name}`
    : `${order?.participant_fullname}`) || "";
