export const housingLinks = {
  a056e00001OAUtcAAH:
    "https://members.apiabroad.com/housing-london-england-spring-2024/",
  a056e00001OAUtpAAH:
    "https://members.apiabroad.com/housing-london-england-spring-2024/",
  a056e00001OAUsNAAX:
    "https://members.apiabroad.com/housing-london-england-spring-2024/",
  a056e00001OAUu6AAH:
    "https://members.apiabroad.com/housing-london-england-spring-2024/",
  a056e00001OAUtoAAH:
    "https://members.apiabroad.com/housing-london-england-spring-2024/",
  a056e00001OAUseAAH:
    "https://members.apiabroad.com/housing-london-england-spring-2024/",
  a056e00001OAUsbAAH:
    "https://members.apiabroad.com/housing-in-granada-spain-spring-2024/",
  a056e00001OAUuzAAH:
    "https://members.apiabroad.com/housing-in-granada-spain-spring-2024/",
  a056e00001OAUuOAAX:
    "https://members.apiabroad.com/housing-seville-spain-spring-2024-upo/",

  a056e00001OAUsOAAX:
    "https://members.apiabroad.com/housing-madrid-spain-spring-2024-semester/",
  a056e00001OAUsgAAH:
    "https://members.apiabroad.com/housing-madrid-spain-spring-2024-semester/",
  a056e00001OAUurAAH:
    "https://members.apiabroad.com/housing-madrid-spain-spring-2024-semester/",
  a056e00001OAUu0AAH:
    "https://members.apiabroad.com/housing-barcelona-spain-semester-spring-2024/",
  a056e00001OAUq3AAH:
    "https://members.apiabroad.com/housing-barcelona-spain-semester-spring-2024/",
  a056e00001OAUudAAH:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a056e00001OAUt9AAH:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a056e00001OAUshAAH:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a056e00001OAUstAAH:
    "https://members.apiabroad.com/housing-paris-france-spring-2024-parsons/",
  a056e00001OAUuaAAH:
    "https://members.apiabroad.com/housing-london-england-ual-spring-2024/",

  a056e00001OAUsQAAX:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a056e00001OAUv5AAH:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a056e00001OAUv6AAH:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a05Ql000000ARmwIAG:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a056e00001OAUu8AAH:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a056e00001OAUu7AAH:
    "https://members.apiabroad.com/housing-paris-france-spring-2024/",
  a056e00001OAUvIAAX:
    "https://members.apiabroad.com/housing-paris-jan-term-2024/",
  a056e00001OAUtZAAX:
    "https://members.apiabroad.com/housing-florence-italy-spring-2024/",
  a056e00001OAUunAAH:
    "https://members.apiabroad.com/housing-florence-italy-spring-2024-janterm/",
  a056e00001OAUuAAAX:
    "https://members.apiabroad.com/housing-rome-italy-spring-2024/",
  a056e00001OAUtMAAX:
    "https://members.apiabroad.com/housing-rome-italy-spring-2024/",
  a056e00001OAUv7AAH:
    "https://members.apiabroad.com/housing-rome-italy-spring-2024/",

  a056e00001OAUsdAAH:
    "https://members.apiabroad.com/housing-lisbon-portugal-spring-2024/",
  a056e00001OAUvFAAX:
    "https://members.apiabroad.com/housing-lisbon-portugal-spring-2024-janterm/",
  a056e00001OAUtGAAX:
    "https://members.apiabroad.com/housing-barcelona-spain-uab-only-spring-2024/",
  a056e00001OAUt1AAH:
    "https://members.apiabroad.com/housing-barcelona-spain-spring-2024-upf/",
  a056e00001OAUucAAH:
    "https://members.apiabroad.com/housing-madrid-spain-jan-term-2024/",
  a056e00001OAUt7AAH:
    "https://members.apiabroad.com/housing-in-seville-spain-spring-2024-us/",
  a056e00001OAUv2AAH:
    "https://members.apiabroad.com/housing-in-seville-spain-spring-2024-us/",
  a056e00001OAUsfAAH:
    "https://members.apiabroad.com/housing-in-seville-spain-spring-2024-us/",
  a056e00001OAUv3AAH:
    "https://members.apiabroad.com/housing-in-seville-spain-spring-2024-us/",

  a056e00001OAUsTAAX:
    "https://members.apiabroad.com/housing-seville-spain-spring-2024-upo/",
  a056e00001OAUtqAAH:
    "https://members.apiabroad.com/housing-madrid-spain-spring-2024-general/",
  a056e00001OAUubAAH:
    "https://members.apiabroad.com/housing-madrid-spain-spring-2024-general/",
  a056e00001OAUtKAAX:
    "https://members.apiabroad.com/housing-in-granada-spain-spring-2024/",
  a056e00001OAUt5AAH:
    "https://members.apiabroad.com/housing-in-granada-spain-spring-2024/",
  a056e00001OAUsLAAX:
    "https://members.apiabroad.com/housing-in-granada-spain-spring-2024/",
  a056e00001OAUsrAAH:
    "https://members.apiabroad.com/housing-in-granada-spain-spring-2024/",
  a056e00001OAUsMAAX:
    "https://members.apiabroad.com/housing-in-granada-spain-spring-2024/",
};
