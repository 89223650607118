<template>
  <div>
    <template v-if="loading">
      <spinner>
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications/landing',
              params: { applicationId: applicationId },
            }"
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            class="underline hover:no-underline"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
    </template>
    <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
      <div class="my-4">
        <router-link
          :to="{
            name: 'applications/landing',
            params: { applicationId: applicationId },
          }"
          class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left w-4 h-4"
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
          <span class="ml-1">Back</span>
        </router-link>
      </div>
      <div
        class="max-w-4xl px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
      >
        <h1 class="mb-2 text-xl text-gray-650 md:text-2xl">
          Health &amp; Wellness
        </h1>
        <p class="text-sm text-gray-600 md:text-base">
          Going abroad involves physical and environmental stress factors that
          can affect our mental and physical health. It is important to
          realistically prepare for the ways that the stress of living in a new
          place/culture may affect us. Prior conditions may resurface even
          though they may be under control or in remission at home. Current
          conditions may worsen. Also, new conditions may surface unexpectedly.
          We encourage you to get a physical before you travel abroad and
          discuss your plans with a doctor who knows your medical (and
          psychological) history. Your doctor can also refer you to a travel
          medicine specialist regarding recommended or required precautions
          (e.g., use of insect repellant, taking caution with local food and
          water sources), immunizations and requirements for your destination
          country.
        </p>

        <FormPanel
          id="healthWellnessPanel"
          title="Medical Information"
          class="mt-6 md:mt-8"
          panel-content-class="text-gray-600 border-gray-200"
        >
          <template #content>
            <div v-if="dataFetched" id="medical-information">
              <form aria-label="Health And Wellness">
                <div class="grid grid-cols-1 gap-y-4 text-gray-600 md:gap-y-8">
                  <div class="text-gray-600 space-y-6">
                    <p>
                      We need you to share relevant medical information with us
                      so that we can proactively support you during the
                      pre-departure process and on-site. For example, in all our
                      sites, we have medical professionals and other resources
                      we can connect you with if you need to continue any
                      treatment while you are abroad. If you do not disclose a
                      pre-existing medical condition that requires special
                      accommodations on-site, we may not be able to accommodate
                      these needs. Therefore, please openly disclose all
                      relevant conditions right away so that we can ensure that
                      the care you need is available on-site, inform you about
                      available health care providers in advance of your
                      departure, and assist you with any logistics.
                    </p>
                    <p>
                      The medical information you share on this form will not be
                      outside of API, except in the following scenarios:
                    </p>
                    <ol class="ml-6 list-decimal space-y-2">
                      <li>
                        You have a condition that warrants special
                        accommodations in housing or in the classroom, in which
                        case we may need to share certain information with the
                        housing provider or host university.
                      </li>
                      <li>
                        We deem it necessary to share the information with local
                        health care providers and/or your emergency contact
                        because of an acute or emergency situation on-site.
                      </li>
                    </ol>
                  </div>

                  <RoutineMedical
                    ref="RoutineMedical"
                    :routine="routineMedical"
                    :selected-option="form.routineMedical"
                    :typed-text="form.routineMedicalTreatmentTextArea"
                    @update:routine-medical="routineMedical = $event"
                  />

                  <EmergencyMedical
                    ref="EmergencyMedical"
                    :emergency="emergencyMedical"
                    :selected-option="form.emergencyMedicalConditionRadio"
                    :typed-text="form.emergencyMedicalConditionTextArea"
                    @update:medical-emergency="emergencyMedical = $event"
                  />

                  <MentalHealth
                    ref="MentalHealth"
                    :mental="mentalHealth"
                    :selected-option="form.mentalHealthRadio"
                    :typed-text="form.mentalHealthTextArea"
                    @update:mental-health="mentalHealth = $event"
                  />

                  <Medications
                    ref="Medications"
                    :medications="medications"
                    :selected-option="form.takingMedicationsRadio"
                    :typed-text="form.takingMedicationsTeaxtArea"
                    @update:medications="medications = $event"
                  />

                  <Allergies
                    ref="Allergies"
                    :allergies="allergies"
                    :selected-option="form.allergiesRadio"
                    :selected-option-env="form['allergies-environment']"
                    :selected-option-share-allergies="form['share-allergies']"
                    :selected-option-pet-allergies="form.petAllergies"
                    :selected-option-smoke-allergies="form.smokeAllergies"
                    :selected-option-other-allergies="form.otherAllergies"
                    :typed-text="form.allergies"
                    :pet-allergies-reaction-from-database="
                      form.petAllergiesReaction
                    "
                    :smoke-allergies-reaction-from-database="
                      form.smokeAllergiesReaction
                    "
                    :other-allergies-reaction-from-database="
                      form.otherAllergiesReaction
                    "
                    @update:allergies="allergies = $event"
                  />

                  <RestrictedDiet
                    ref="RestrictedDiet"
                    :restricted-diet="restrictedDiet"
                    :selected-option="form.restrictedDietRadio"
                    :typed-text="form.restrictedDiet"
                    @update:restrict-diet="restrictedDiet = $event"
                  />

                  <CounselingNeeds
                    ref="CounselingNeeds"
                    :needs="counselingNeeds"
                    :selected-option="form.counselingNeedsRadio"
                    :typed-text="form.counselingNeeds"
                    @update:conseling-needs="counselingNeeds = $event"
                  />

                  <LearningDifferences
                    ref="learningDifferences"
                    :learning-differences="learningDifferences"
                    :selected-option="form.learningDifferencesRadio"
                    :typed-text="form.learningDifferences"
                    @update:learning-differences="learningDifferences = $event"
                  />

                  <Mobility
                    ref="Mobility"
                    :mobility="mobility"
                    :selected-option="form.mobilityRadio"
                    :typed-text="form.mobility"
                    @update:mobility="mobility = $event"
                  />

                  <CovidInformation
                    ref="CovidInformation"
                    :covid-info="covidInformation"
                    :other-covid-status-from-database="form.otherCovidStatus"
                    :selected-option="form.covidStatus"
                    :vaccinated-date-from-database="
                      form.covidStatusVaccinatedDate
                    "
                    :vaccine-type-from-database="form.vaccineType"
                    :booster-date-from-database="form.boosterDate"
                    @update:covid-info="covidInformation = $event"
                  />

                  <AdditionalHealth
                    ref="AdditionalHealthInformation"
                    :health="additionalHealthInformation"
                    :typed-text="form.additionalHealthInformation"
                    @update:additional-health="
                      additionalHealthInformation = $event
                    "
                  />

                  <div v-if="showInsuranceSection">
                    <h3 class="uppercase text-gray-700">Medical Insurance:</h3>
                    <p>
                      All participants are provided with medical insurance
                      during the official program dates. In most cases, fees for
                      medical services must be paid at the time that service is
                      rendered. To request reimbursement, receipts must be
                      retained and submitted along with a claim form within 90
                      days of treatment. Certain pre-existing conditions and
                      prescription drugs started prior to departure and needed
                      abroad are not covered under the insurance policy.
                    </p>
                  </div>

                  <div>
                    <CountrySpecificInfo
                      ref="CountrySpecificInfo"
                      :country-info="countrySpecific"
                      :typed-text="form.countrySpecificHealthInformation"
                      @update:country-info="countrySpecific = $event"
                    />
                    <PriorAcceptance
                      ref="PriorAcceptance"
                      :prior-acceptance="priorAcceptance"
                      :checked="
                        typeof form.knowledgePriorAcceptance !== 'undefined'
                          ? form.knowledgePriorAcceptance
                          : false
                      "
                      :status="status"
                      @update:prior-acceptance="priorAcceptance = $event"
                    />
                  </div>
                  <footer>
                    <ButtonWithSpinner
                      ref="SubmitForm"
                      type="button"
                      variant="primary"
                      variant-type="block"
                      @click.prevent="submit()"
                    >
                      <span
                        >Submit
                        <svg
                          v-if="formSubmitted && !hasErrors"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="inline feather feather-check"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline></svg
                      ></span>
                    </ButtonWithSpinner>
                    <p v-if="hasErrors" class="text-error-900">
                      Please complete the required fields correctly.
                    </p>
                    <div v-if="formioErrors" class="text-error-900">
                      <p v-for="error in formioErrors" :key="error">
                        {{ error }}
                      </p>
                    </div>
                    <div v-if="missingAppId" class="text-error-900">
                      <p>
                        No Application ID, please contact your API program
                        manager to help you with your application.
                      </p>
                    </div>
                    <div
                      v-if="formSubmitted && !hasErrors"
                      class="text-success-900"
                    >
                      <p>
                        Submission Complete, thank you!
                      </p>
                    </div>
                    <p class="mt-4 text-sm text-gray-600 md:text-base">
                      By submitting this form you certify that all responses
                      made on this Medical Form are true and accurate to the
                      best of your knowledge. You will notify API if there are
                      any significant changes in your health or wellbeing.
                    </p>
                  </footer>
                </div>
              </form>
            </div>
          </template>
        </FormPanel>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../helpers/Spinner";
import ButtonWithSpinner from "../forms/SharedComponents/ButtonWithSpinner.vue";
import RoutineMedical from "./RoutineMedical";
import EmergencyMedical from "./EmergencyMedical";
import MentalHealth from "./MentalHealth";
import Medications from "./Medications";
import RestrictedDiet from "./RestrictedDiet";
import CounselingNeeds from "./CounselingNeeds";
import LearningDifferences from "./LearningDifferences";
import Mobility from "./Mobility";
import AdditionalHealth from "./AdditionalHealth";
import Allergies from "./Allergies";
import PriorAcceptance from "./PriorAcceptance";
import { mapState } from "vuex";
import formIoApi from "../../mixins/formIoApi";
import formValidation from "../../mixins/formValidation";
import formioHelpers from "../../mixins/formioHelpers";
import CountrySpecificInfo from "./CountrySpecificInfo.vue";
import forms from "../../mixins/forms";
import { format, isValid, parse } from "date-fns";
import CovidInformation from "./CovidInformation.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";

export default {
  name: "HealthWellnessForm",
  components: {
    Spinner: Spinner,
    RoutineMedical,
    EmergencyMedical,
    MentalHealth,
    Medications,
    Allergies,
    RestrictedDiet,
    CounselingNeeds,
    LearningDifferences,
    Mobility,
    AdditionalHealth,
    PriorAcceptance,
    ButtonWithSpinner,
    CountrySpecificInfo,
    CovidInformation,
    FormPanel,
  },
  mixins: [formIoApi, formValidation, formioHelpers, forms],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sectionsToValidate: [
        "RoutineMedical",
        "EmergencyMedical",
        "MentalHealth",
        "Medications",
        "Allergies",
        "RestrictedDiet",
        "CounselingNeeds",
        "learningDifferences",
        "Mobility",
        "PriorAcceptance",
        "CountrySpecificInfo",
        "AdditionalHealthInformation",
        "CovidInformation",
      ],
      form: {},
      dataFetched: false,
      formPath: "healthwellnessform",
      loading: true,
      message: "Getting Student applications",
      shareMedical: {},
      routineMedical: {},
      emergencyMedical: {},
      mentalHealth: {},
      medications: {},
      allergies: {},
      restrictedDiet: {},
      counselingNeeds: {},
      learningDifferences: {},
      mobility: {},
      covidInformation: {},
      additionalHealthInformation: {},
      onSiteMedical: false,
      countrySpecific: {},
      priorAcceptance: {},
      formSubmitted: false,
    };
  },
  computed: {
    ...mapState(["formioToken", "program"]),
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          application_id: this.setApplicationId,
          routineMedical: this.routineMedical.selectedRadio,
          routineMedicalTreatmentTextArea: this.routineMedical.textarea,
          emergencyMedicalConditionRadio: this.emergencyMedical.selectedRadio,
          emergencyMedicalConditionTextArea: this.emergencyMedical.textarea,
          mentalHealthRadio: this.mentalHealth.selectedRadio,
          mentalHealthTextArea: this.mentalHealth.textarea,
          takingMedicationsRadio: this.medications.selectedRadio,
          takingMedicationsTeaxtArea: this.medications.textarea,
          allergiesFoodRadio: this.allergies.selectedRadioFoodAllergies,
          allergiesRadio: this.allergies.selectedRadioMedAllergies,
          allergies: this.allergies.medicationAllergicReaction,
          ["allergies-environment"]: this.allergies.selectedRadioEnv,
          ["share-allergies"]: this.allergies.selectedRadioShareAllergies,
          restrictedDietRadio: this.restrictedDiet.selectedRadio,
          restrictedDiet: this.restrictedDiet.textarea,
          counselingNeedsRadio: this.counselingNeeds.selectedRadio,
          counselingNeeds: this.counselingNeeds.textarea,
          learningDifferencesRadio: this.learningDifferences.selectedRadio,
          learningDifferences: this.learningDifferences.textarea,
          mobilityRadio: this.mobility.selectedRadio,
          mobility: this.mobility.textarea,
          additionalHealthInformation: this.additionalHealthInformation
            .textarea,
          countrySpecificHealthInformation: this.countrySpecific.textarea,
          knowledgePriorAcceptance: this.priorAcceptance.checkedOption,
          imInterestedInBeingConnectedWithAnOnSiteMedicalProfessionalToHelpMeManageAConditionWhileIAmAbroad: this
            .onSiteMedical,
          petAllergies: this.allergies.selectedRadioPetAllergies,
          petAllergiesReaction: this.allergies.petAllergiesReaction,
          smokeAllergies: this.allergies.selectedRadioSmokeAllergies,
          smokeAllergiesReaction: this.allergies.smokeAllergiesReaction,
          otherAllergies: this.allergies.selectedRadioOtherAllergies,
          otherAllergiesReaction: this.allergies.otherAllergiesReaction,
          covidStatus: this.covidInformation.selectedRadio,
          covidStatusVaccinatedDate: this.formatDate(
            this.covidInformation.vaccinatedDate
          ),
          otherCovidStatus: this.covidInformation.otherCovidStatus,
          vaccineType: this.covidInformation.vaccineType,
          boosterDate: this.formatDate(this.covidInformation.boosterDate),
        },
      };
      return body;
    },
    currentApp() {
      return this.$store.getters.currentApplication(this.applicationId);
    },
    status() {
      let status = "";
      if (
        Object.hasOwnProperty.call(this.currentApp, "enrollment_status") &&
        this.currentApp.enrollment_status
      ) {
        status = this.currentApp.enrollment_status;
      }

      return status;
    },
    showInsuranceSection() {
      return (
        this.program.post_acceptance_form_rules.length > 0 &&
        this.program.post_acceptance_form_rules.includes("Health And Wellness")
      );
    },
  },
  watch: {
    form: {
      handler(obj) {
        this.onSiteMedical =
          obj.imInterestedInBeingConnectedWithAnOnSiteMedicalProfessionalToHelpMeManageAConditionWhileIAmAbroad;
        this.countrySpecific = obj.countrySpecificHealthInformation;
      },
      deep: true,
    },
  },
  created() {
    //Make sure I user owns the application ID
    if (!this.isApplicationOwner(this.applicationId)) {
      //redirect to my applications
      this.$router.push({
        name: "applications",
      });
    }
    /**
     * Prefetch forms data from database
     */
    this.prefetchFormData();
  },

  methods: {
    loggit(param) {
      console.log(param);
    },
    formatDate(dateValue) {
      if (isValid(parse(dateValue, "yyyy-MM-ddd", new Date()))) {
        return format(parse(dateValue, "yyyy-MM-dd", new Date()), "MM/dd/yyyy");
      } else {
        return "";
      }
    },
    prefetchFormData() {
      /**
       * If the rules allow us to show the Health&Wellness form
       * lets check if any data exist on the database to pre fill the form
       */
      this.formioSubmissionExists(this.formPath, this.applicationId).then(
        (formSubmissionId) => {
          if (formSubmissionId) {
            this.getFormioSubmission(this.formPath, formSubmissionId).then(
              (response) => {
                this.form = response;
                this.dataFetched = true;
                this.loading = false;
              }
            );
          } else {
            this.dataFetched = true;
            this.loading = false;
          }
        }
      );
    },
    async submit() {
      this.submitted = false;
      //calling the startLoading method of the button, which enables the button to loading state
      this.$refs.SubmitForm.startLoading();
      try {
        await this.checkForApplicationId();
        await this.validate();
        await this.formioValidateSubmission(
          this.formPath,
          this.createSubmissionDataForFormIo
        );
        if (!this.hasFormIoValidationErrors) {
          if (this.submission_id) {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo,
              "put",
              this.submission_id
            ).then((response) => {
              if (response) {
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          } else {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                this.submission_id = response;
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          }
        } else {
          this.$refs.SubmitForm.stopLoading();
        }
      } catch {
        this.$refs.SubmitForm.stopLoading();
      }
    },
  },
};
</script>
