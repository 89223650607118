<template>
  <FormPanel
    :id="'independentHousing'"
    :title="'Independent Housing'"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <p>
        Note here if you would like to opt out of program housing. If housing
        has not already been secured and you are eligible for independent
        housing you will receive an 85% discount on your housing cost and API
        will retain 15% for administrative services. Contact your Program
        Coordinator for more details. If you decide to opt out of program
        housing, you will be asked to complete a waiver with the approval of
        your home institution. You will be responsible for making your own
        housing arrangements.
      </p>
      <div class="p-6 mt-6 text-sm bg-blue-100 rounded-lg">
        <div class="items-center md:flex">
          <div class="flex-initial mb-4 mr-6 md:mb-0">
            <img src="/images/icon_flag.svg" alt="" />
          </div>
          <div class="flex-1">
            <h4 class="mb-4 text-gray-700 text-base font-semibold md:text-xl">
              Independent Housing
            </h4>
            <label class="flex xs:items-center" @click="toggle">
              <span
                class="flex items-center flex-none w-12 h-8 mt-1 transition-all duration-200 ease-in-out rounded-full shadow-inner cursor-pointer xs:mt-0"
                :class="[
                  value.independentHousing
                    ? `${primaryColorClassBG} justify-end`
                    : 'bg-gray-300 justify-start',
                ]"
                tabindex="0"
                @keyup.enter="toggle"
              >
                <span
                  class="flex-none block w-6 h-6 mx-1 bg-white rounded-full shadow"
                ></span>
              </span>
              <span class="ml-4 text-sm md:text-base">
                I would like to opt out of program housing.
              </span>
            </label>
          </div>
        </div>
      </div>
      <template v-if="value.independentHousing">
        <p class="mt-6">
          Opting out of housing requires you to complete the following
          independent housing waiver. Please click the button below to download
          the Independent Housing Waiver.
        </p>
        <div class="text-center my-6">
          <a
            href="/files/College_Independent_Housing_Waiver.pdf"
            target="_blank"
            :class="[
              secondaryColor
                ? `${secondaryColorClassBG}`
                : `${secondaryColorClassBG} hover:bg-teal-100 focus:bg-teal-100`,
            ]"
            class="inline-flex justify-center items-center text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white hover:text-white focus:text-white"
          >
            <span>Download Waiver</span>
          </a>
        </div>
        <FileUpload
          name="independent-upload"
          :multiple-files="false"
          :file-types="['*']"
          custom-button-label="Upload Signed Waiver"
          custom-file-box-label="Upload and submit the waiver once you read and sign the document."
          :formio-files="formioFiles"
          @updateProgress="updateProgress"
          @updateFiles="updateFiles"
        />
      </template>
    </template>
  </FormPanel>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import FormPanel from "../SharedComponents/panel.vue";
import nestedModelComponent from "../../../mixins/nestedModelComponent";

import FileUpload from "../SharedComponents/FileUpload.vue";
import { requiredIf } from "@vuelidate/validators";

const validateFileUpload = (value) => value === false;

export default {
  name: "IndependentHousing",
  components: {
    FormPanel,
    FileUpload,
  },
  mixins: [nestedModelComponent],
  props: {
    indepentHousing: {
      type: Object,
      default() {
        return {};
      },
    },
    formioData: {
      type: Object,
      default() {
        return {};
      },
    },
    successSubmission: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["changeSuccessSubmission", "update:indepent-housing"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        independentHousing: false,
        waiverFile: [],
      },
      uploadOnProgress: false,
      uploadErrors: false,
      formioFiles: [],
    };
  },
  validations: {
    value: {
      independentHousing: {},
      waiverFile: {
        required: requiredIf(function () {
          return this.value.independentHousing;
        }),
      },
    },
    uploadOnProgress: { validateFileUpload },
    uploadErrors: { validateFileUpload },
  },
  watch: {
    "value.independentHousing": {
      handler: function (val) {
        if (!val) {
          this.value.waiverFile = [];
        }
      },
    },
    formioData: {
      handler: function (val) {
        for (const prop in val) {
          if (prop === "waiverFile") this.formioFiles = val.waiverFile;
          else this.value[prop] = val[prop];
        }
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update:indepent-housing", this.value);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    updateProgress(val) {
      this.uploadOnProgress = val;
    },
    updateFiles(files, error) {
      this.value.waiverFile = files;
      this.uploadErrors = error;
    },
    toggle() {
      this.value.independentHousing = !this.value.independentHousing;
    },
  },
};
</script>
