<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: enrollmentApplicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <h1 class="mb-2 text-xl md:font-semibold md:text-2xl">
        Code of Conduct
      </h1>
      <div v-for="rule in codeRules" :key="rule.url" data-cy="framePdf">
        <div
          v-if="rule.exist"
          :id="rule.url"
          role="form"
          :aria-label="rule.rule"
          class="mt-10 relative"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Formio } from "formiojs";
import tailwind from "@apiabroad/formio-tailwind-template";
import codeOfConductRules from "../../mixins/codeOfConductRules.js";
import impersonationFormSniffer from "../../mixins/ImpersonationFormSniffer";
import formService from "../../services/form";

export default {
  name: "CodeOfConduct",
  mixins: [codeOfConductRules, impersonationFormSniffer],
  async mounted() {
    let promises = [];

    if (!this.studentApplications.length) {
      promises.push(this.$store.dispatch("getFormioSubmissions"));
      promises.push(this.$store.dispatch("getStudentApplications"));
    }

    if (Object.keys(this.program).length === 0) {
      promises.push(
        this.$store.dispatch("setProgramDataByApplicationId", {
          applicationId: this.enrollmentApplicationId,
          apolloClient: this.$apollo,
        })
      );
    }
    await Promise.all(promises);
    this.setAllForm(this.program);

    this.$nextTick(() => {
      if (this.validateApplication()) {
        this.createdAllForm();
      }
    });
  },
  methods: {
    createdAllForm() {
      this.getAllSubmission();
      this.validRules.forEach((rule) => {
        this.createForm(rule.submission, rule.url);
      });
    },
    createForm(id, url) {
      let formURL = "/api/forms/" + url;
      if (id) {
        formURL += "/submission/" + id;
      }
      Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
      Formio.setAuthUrl(
        `${window.location.protocol}//${window.location.host}/api/forms`
      );
      Formio.use(tailwind);
      Formio.createForm(document.getElementById(url), formURL, {
        hooks: {
          beforeSubmit: (submission, next) => {
            submission.data.application_id = this.enrollmentApplicationId;
            submission = this.attachEmployeeToSubmission(submission, "post");
            next();
          },
        },
        noAlerts: true,
      }).then((form) => {
        // suppress the Form.io SDK default submit behavior so that we can submit
        // to our own backend with all necessary CSRF protection
        form.nosubmit = true;
        form.on("submit", (submission) => {
          formService
            .createOrUpdateSubmission(url, submission, id)
            .then((submission) => form.emit("submitDone", submission));
        });
        form.on("submitDone", (submission) => {
          this.logSubmission(url, {}, submission.data);
          this.$router.push({
            name: "applications/landing",
            params: {
              applicationId: this.enrollmentApplicationId,
            },
          });
        });
      });
    },
  },
};
</script>
