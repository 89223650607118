<script setup>
import MaintenanceIcon from "@/components/shared/icons/MaintenanceIcon.vue";
import Modal from "@/components/modals/Modal.vue";
import Button from "@/components/shared/Button/BaseButton.vue";
import { computed } from "vue";

const props = defineProps({
  showApplicationTakenModal: {
    type: Boolean,
    default: false,
  },
  redirect: {
    type: Boolean,
    default: false,
  },
});
defineEmits(["close"]);
const open = computed(() => props.showApplicationTakenModal);
const redirectToMyApplications = () => {
  window.location.href = "/applications";
};
</script>
<template>
  <Modal :open="open" :show-logo="false" @close-modal="$emit('close')">
    <template #modal-content>
      <div
        class="m-auto items-center text-center justify-center flex flex-col space-y-8"
      >
        <MaintenanceIcon v-if="open" />
        <div class="text-indigo-base text-2.5xl text-center font-semibold">
          <span class="text-center"
            >Oops, you already have an application for this program
            session.</span
          >
        </div>
        <div class="text-lg">
          Please Visit
          <span><a href="/applications">"My Applications"</a></span> To Resume
          Your Application
        </div>
        <div v-if="!redirect" class="md:mt-6 mt-32 w-full flex justify-center">
          <Button
            class="!h-16 md:!w-80 uppercase w-full md:mt-6 mt-36"
            @click="$emit('close')"
          >
            Got it
          </Button>
        </div>
        <div v-else>
          <button
            :id="`card-apply-btn-${setId}`"
            type="button"
            class="box-border md:mt-6 mt-32 flex flex-row justify-center items-center w-96 h-auto max-h-60p p-5 max-w-260p border-2 border-teal-400 text-teal-400"
            @click="redirectToMyApplications"
          >
            <div
              class="flex gap-1 font-bold uppercase text-base tracking-tight items-center"
            >
              <span>MY APPLICATIONS </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>
