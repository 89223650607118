<script setup>
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import { defineEmits, ref, onMounted, watch } from "vue";
const phoneNumber = ref();

defineEmits(["updatePhone"]);

const props = defineProps({
  vuelidateField: {
    type: Object,
    default: () => {},
  },
  modelValue: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
onMounted(() => {
  phoneNumber.value = props.modelValue;
});

watch(
  () => props.modelValue,
  (newValue) => {
    phoneNumber.value = newValue;
  }
);
</script>

<template>
  <div>
    <label :for="props.id" class="block text-sm font-medium text-indigo-base">
      <span class="font-medium flex justify-between mb-1">
        {{ props.label }}
      </span>
    </label>
    <MazPhoneNumberInput
      :id="props.id"
      v-model="phoneNumber"
      :color="'info'"
      :fetch-country="false"
      :clearable="true"
      :preferred-countries="['US']"
      :no-example="true"
      :no-search="true"
      :data-cy="props.id"
      type="tel"
      class="custom-phone"
      :disabled="disabled"
      @update="$emit('updatePhone', $event)"
      @blur="props.vuelidateField?.$touch"
    />
    <slot name="help-content"></slot>
    <p
      v-for="error of props.vuelidateField?.$errors"
      :key="error.$uid"
      class="error text-error-900 text-sm"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<style>
.custom-phone .m-input-wrapper,
.custom-phone .maz-border-info,
.m-select-input .m-phone-number-input__select .m-input-input,
.maz-rounded {
  @apply rounded-none outline-none p-[1px] w-full border-0 ring-1 ring-inset focus:ring-2 focus:ring-inset ring-indigo-base focus:ring-indigo-base;
}

.custom-phone .maz-rounded-lg {
  @apply rounded-none;
}
</style>
