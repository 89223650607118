<template>
  <div>
    <div class="flex flex-col items-center cursor-pointer">
      <img src="/images/fill-1.svg" @click="openModal" />
    </div>

    <Modal
      :open="displayModal"
      :show-logo="false"
      @closeModal="toggleModal(displayModal)"
    >
      <template #modal-content>
        <div>
          <p class="whitespace-normal mt-7 sm:mt-0">
            {{ description }}
          </p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ref } from "vue";
import Modal from "@/components/modals/Modal";
export default {
  name: "DescriptionPopUp",
  components: {
    Modal,
  },
  props: {
    description: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const displayModal = ref(false);

    function openModal() {
      displayModal.value = true;
    }

    function toggleModal() {
      displayModal.value = !displayModal.value;
    }

    return {
      displayModal,
      openModal,
      toggleModal,
    };
  },
};
</script>
<style scoped></style>
