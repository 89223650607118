<script setup>
import { defineEmits, ref, computed, reactive, onMounted, watch } from "vue";
import { helpers, required } from "@vuelidate/validators";
import SingleSelect from "@/components/forms/SharedComponents/DarkBlueInputs/SingleSelect.vue";
import TextField from "@/components/forms/SharedComponents/DarkBlueInputs/TextField.vue";
import countriesService from "@/services/countries.js";
import useVuelidate from "@vuelidate/core";

const emit = defineEmits(["updateAddressObject"]);
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  showError: {
    type: Boolean,
    default: false,
  },
  initialCountries: {
    type: Array,
    default: function () {
      return [];
    },
  },
  parentObjectLabel: {
    type: String,
    default: "", // The name to display for each field as in... Current Address, Billing Address, etc
    required: true,
  },
  initialAddress: {
    type: String, // Address line 1, street
    default: "",
  },
  initialPostalCode: {
    type: String,
    default: "",
  },
  initialCity: {
    type: String,
    default: "",
  },
  initialState: {
    type: String,
    default: "",
  },
  initialCountryId: {
    type: String, // id/iso - we will fetch its label if needed
    default: "",
  },
  initialCountryLabel: {
    type: String,
    default: "",
  },
});

const requiredErrorCopy = "This field is required.";
const countries = ref([]); // Gets data from countries service
const propagateFlag = ref(false); // Avoids overwriting original object when mounting initial data
const form = reactive({
  address: null,
  address_postal_code: null,
  address_city: null,
  address_state: null,
  address_country: null,
});
const rules = {
  address: {
    required: helpers.withMessage(requiredErrorCopy, required),
  },
  address_postal_code: {
    required: helpers.withMessage(requiredErrorCopy, required),
  },
  address_city: {
    required: helpers.withMessage(requiredErrorCopy, required),
  },
  address_state: {
    required: helpers.withMessage(requiredErrorCopy, required),
  },
  address_country: {
    required: helpers.withMessage(requiredErrorCopy, required),
  },
};
const v$ = useVuelidate(rules, form);

async function getCountries({ search, loading }) {
  // Get Countries data
  const { data } = await countriesService.searchCountries({
    limit: 20,
    q: search ?? "",
  });
  loading(false);

  const filteredCountries =
    data?.data?.filter((country) => country.name !== "United States") || [];

  countries.value = filteredCountries;
}

const kebabLabel = computed(() => {
  return _.kebabCase(props.parentObjectLabel);
});
const reorderedCountries = computed(() => {
  let reordered = [...countries.value];

  const unitedStates = {
    archived_at: null,
    created_at: "2021-10-14T14:22:08Z",
    id: "US",
    iso: "US",
    iso3: "USA",
    latitude: 38,
    longitude: -97,
    name: "United States",
    numeric_code: 840,
    updated_at: null,
    updated_by: null,
  };
  reordered.unshift(unitedStates);

  return reordered;
});

onMounted(() => {
  countries.value = structuredClone(props.initialCountries);

  // Pre-filling inputs
  if (props.initialAddress) form.address = props.initialAddress;
  if (props.initialPostalCode)
    form.address_postal_code = props.initialPostalCode;
  if (props.initialCity) form.address_city = props.initialCity;
  if (props.initialState) form.address_state = props.initialState;
  if (props.initialCountryId && props.initialCountryLabel) {
    form.address_country = {
      label: props.initialCountryLabel,
      value: props.initialCountryId,
      id: props.initialCountryId,
      name: props.initialCountryLabel,
    };
  }

  propagateFlag.value = true;
});

watch(
  () => form,
  (newValue) => {
    if (propagateFlag.value === false) return; // don't emit under mounting - avois emptying values when setting initial data
    emit("updateAddressObject", {
      ...newValue,
      address_country_id: form.address_country?.id, // figured we could only return the id
      country: form.address_country?.name, // label of country
    });
  },
  { deep: true }
);
</script>

<template>
  <p v-if="props.showError" class="error text-error-900">
    Address can't be validated, please enter manually.
  </p>
  <!-- Address Line -->
  <TextField
    :id="'address-' + kebabLabel"
    v-model.trim="form.address"
    :label="props.parentObjectLabel + '*'"
    :vuelidate-field="v$.address"
    :show-errors="true"
    :disabled="props.disabled"
  />

  <!-- Address City -->
  <TextField
    :id="'address-city-' + kebabLabel"
    v-model.trim="form.address_city"
    :label="props.parentObjectLabel + ' City*'"
    :vuelidate-field="v$.address_city"
    :show-errors="true"
    :disabled="props.disabled"
  />

  <!-- Address State -->
  <TextField
    :id="'address-state-' + kebabLabel"
    v-model.trim="form.address_state"
    :label="props.parentObjectLabel + ' State*'"
    :vuelidate-field="v$.address_state"
    :show-errors="true"
    :disabled="props.disabled"
  />

  <!-- Country -->
  <SingleSelect
    :id="'country-' + kebabLabel"
    v-model.trim="form.address_country"
    :label="props.parentObjectLabel + ' Country*'"
    :vuelidate-field="v$.address_country"
    :options="reorderedCountries"
    :search-method="true"
    :disabled="props.disabled"
    option-label="name"
    @search="getCountries"
  />

  <!-- Address Postal Code -->
  <TextField
    :id="'address-postal-code-' + kebabLabel"
    v-model.trim="form.address_postal_code"
    :label="props.parentObjectLabel + ' Postal Code*'"
    :vuelidate-field="v$.address_postal_code"
    :show-errors="true"
    :disabled="props.disabled"
  />
</template>
