<template>
  <div
    class="px-4 py-4 bg-white border-b border-gray-200 shadow-sm md:shadow-none sm:flex"
    role="search"
    aria-label="Student filters"
    aria-description="Filter students by major, session or city"
  >
    <div class="sm:flex-auto sm:flex">
      <label
        v-if="showCity"
        class="block text-sm mb-2 sm:mb-0 sm:flex-1 sm:mr-5"
        for="cities"
      >
        <VSelect
          id="cities"
          v-model="filters.city"
          class="filters"
          placeholder="City"
          :value="filters.city"
          :options="cities"
          :get-option-label="(option) => option.label"
          :reduce="(city) => city.value"
          aria-label="Cities"
        />
      </label>
      <multiselect
        :id="'sessions'"
        :select-data="filters.sessions"
        :placeholder="'Session'"
        :data="sessions"
        :clear="clear.sessions"
        @input="
          (newSessions) => {
            filters.sessions = newSessions.slice();
          }
        "
        @changeClear="
          () => {
            clear.sessions = false;
          }
        "
      />

      <multiselect
        :id="'majors'"
        :select-data="filters.sessions"
        :placeholder="'Major'"
        :data="majors"
        :clear="clear.majors"
        @input="
          (newMajors) => {
            filters.majors = newMajors.slice();
          }
        "
        @changeClear="
          () => {
            clear.majors = false;
          }
        "
      />

      <label
        v-if="showApproval"
        class="block text-sm mb-2 sm:mb-0 sm:flex-1 sm:mr-5"
        for="approval"
      >
        <VSelect
          :id="'approval'"
          v-model="filters.approvalStatus"
          class="filters"
          placeholder="Approval status"
          :value="filters.approvalStatus"
          :options="approvalStatus"
          :get-option-label="(option) => option.label"
          :reduce="(status) => status.value"
          aria-label="Approval status"
        />
      </label>
    </div>
    <div class="flex items-center flex-none">
      <button
        :class="[
          secondaryColor
            ? `${secondaryColorClass}`
            : `${secondaryColorClass} hover:bg-teal-100 focus:bg-teal-100`,
        ]"
        class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-white flex-auto hover:text-white"
        @click="filter()"
      >
        <span>Filter</span>
      </button>
      <button
        class="flex items-center justify-center px-4 ml-2 text-sm min-h-10 text-gray-650 focus:outline-none"
        type="reset"
        @click="resetFilters()"
      >
        Reset
      </button>
    </div>
  </div>
</template>

<script>
import { MAJORS } from "@/constants";
import { mapGetters } from "vuex";
import Multiselect from "@/university/components/dataTables/filter/Multiselect.vue";
import { APPROVAL_STATUS } from "@/constants";

export default {
  name: "Filters",
  components: { Multiselect },
  props: {
    showApproval: {
      type: Boolean,
      default: false,
    },
    showCity: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["getFilterData"],
  data() {
    return {
      cities: [],
      sessions: [],
      majors: MAJORS,
      approvalStatus: APPROVAL_STATUS,
      filters: {
        city: "",
        sessions: [],
        majors: [],
        approvalStatus: "",
      },
      clear: {
        sessions: false,
        majors: false,
      },
      enrollmentEndpoint: this.trailingSlash(
        process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
      ),
    };
  },
  computed: {
    ...mapGetters("university", ["getLastFilters"]),
    secondaryColorClass() {
      const { secondaryColor } = this;
      if (secondaryColor) {
        return "brandingOutilined";
      } else {
        return "text-university-secondary border-university-secondary";
      }
    },
  },
  created() {
    this.filters = Object.assign({}, this.getLastFilters);
    this.getCities();
    this.getSession();
  },

  methods: {
    async getCities() {
      fetch(this.enrollmentEndpoint + "locations", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-jwt-token": this.$store.state.currentUser.advisorToken,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.length) {
            let cities = json.map((item) => ({
              label: item,
              value: item,
            }));
            this.cities = cities;
          }
        })
        .catch((error) => {
          console.log("Error something went wrong: " + error);
        });
    },
    async getSession() {
      fetch(this.enrollmentEndpoint + "sessions", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-jwt-token": this.$store.state.currentUser.advisorToken,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.length) {
            let sessions = json.map((item) => ({
              label: item,
              value: item,
            }));
            this.sessions = sessions;
          }
        })
        .catch((error) => {
          console.log("Error something went wrong: " + error);
        });
    },
    filter() {
      this.$emit("getFilterData", this.filters);
    },
    resetFilters() {
      this.clear.sessions = true;
      this.clear.majors = true;

      this.filters.city = null;
      this.filters.sessions = [];
      this.filters.majors = [];
      this.filters.approvalStatus = "";
      this.$emit("getFilterData", this.filters);
    },
  },
};
</script>
