<script setup>
import {
  computed,
  defineEmits,
  defineProps,
  toRefs,
  reactive,
  ref,
  watch,
  unref,
} from "vue";
import { Dialog, DialogPanel } from "@headlessui/vue";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useVueTable,
  FlexRender,
} from "@tanstack/vue-table";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import Pagination from "@/components/shared/Pagination.vue";
import CogTooth from "@/components/shared/icons/CogTooth.vue";
import CloseIcon from "@/components/svg-icons/CloseIcon.vue";
import BadgeBar from "@/components/program-manager/sessions/components/BadgeBar";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { columnsConfig } from "@/components/StudentFulfillment/components/modals/DefaultFulfillmentModal/constants";
import HeadersCheckboxList from "@/components/shared/Table/HeadersCheckboxList.vue";

const emit = defineEmits([
  "closeModal",
  "changePage",
  "searchData",
  "updateSelectedItems",
  "clearFilters",
]);

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    default: false,
  },
  state: {
    type: Object,
    default: () => ({ loading: false, ready: true }),
  },
  data: {
    type: Object,
    default: () => ({ columns: undefined, rows: undefined, count: 0 }),
  },
  filters: {
    type: Object,
    default: () => ({
      text: undefined,
      extra: undefined,
    }),
  },
  filterLabelMap: {
    type: Object,
    default: () => ({}),
  },
  readOnlyFilters: {
    type: Object,
    default: () => ({}),
  },
});
const {
  isModalOpen,
  state,
  data,
  filters,
  filterLabelMap,
  readOnlyFilters,
} = toRefs(props);

const internalState = reactive({
  selectedItems: [],
});
const selectedSortHeader = ref(data?.value?.columns[0] ?? "id");
const isDesc = ref(false);
const pagination = reactive({
  pageIndex: 1,
  pageSize: 10,
});

const isLoading = computed(
  () => (state?.value?.loading ?? false) && !(state?.value?.ready ?? false)
);

const handleSearch = (search) => {
  if (search && search?.length > 2) {
    emit("searchData", search);
  } else if (!search && search?.length === 0) {
    emit("searchData", undefined);
  }
};

const clearFilters = () => {
  emit("clearFilters");
};

const closeModal = () => {
  emit("closeModal", true);
};

const addSelected = () => {
  if (internalState?.selectedItems?.length === 1) {
    emit("updateSelectedItems", internalState.selectedItems);
    emit("closeModal", true);
  }
};

const checkItem = (obj) => {
  internalState.selectedItems = [unref(obj)];
};

const IsItemSelected = (id) =>
  !!internalState.selectedItems.find((item) => item.id === id);

const changePage = (pageIndex) => {
  internalState.selectedItems = [];
  pagination.pageIndex = pageIndex;
  emit("changePage", {
    limit: pagination.pageSize,
    page: pagination.pageIndex,
  });
};

const handleClickHeader = ({ id }) => {
  if (id === selectedSortHeader.value) {
    isDesc.value = !isDesc.value;
  } else {
    isDesc.value = false;
    selectedSortHeader.value = id;
  }
  table?.getColumn(id).toggleSorting(isDesc.value);
};

watch(isLoading, () => (internalState.selectedItems = []));

const columnHelper = createColumnHelper();
const columns = (data?.value?.columns ?? columnsConfig).map(
  ({ header, id, size, getColumnDisplayValue, type }) => {
    return columnHelper.accessor(getColumnDisplayValue, {
      id,
      header,
      size,
      type,
    });
  }
);
columns.map(() => columnHelper);

const table = useVueTable({
  get data() {
    return data?.value?.rows ?? [];
  },
  columns: columns,
  columnResizeMode: "onChange",
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  manualPagination: true,
});
</script>

<template>
  <div>
    <div
      v-if="isModalOpen"
      class="fixed inset-0 z-40 hidden bg-university-primary opacity-50 sm:block"
    ></div>
    <Dialog
      :open="isModalOpen"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-8"
      @close-modal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="p-6 h-fit mx-auto bg-white sm:rounded-lg sm:shadow z-30 relative"
      >
        <DialogPanel>
          <div class="text-right">
            <button @click="closeModal">
              <CloseIcon size="28" stroke="#007f80" stroke-width="1.75" />
            </button>
          </div>
          <section class="sm:px-6 md:px-12 sm:py-10 h-fit">
            <slot name="header">
              <h1
                class="flex justify-center text-xl leading-tight text-center sm:text-2xl md:text-3xl"
              >
                Confirm Selection
              </h1>
            </slot>
            <div>
              <slot name="search">
                <SearchBar
                  class="flex min-w-80 md:w-96 searchbar mb-2 ml-auto flex-0"
                  @handle-search="handleSearch"
                  ><template #advancedFilters><div></div></template>
                </SearchBar>
              </slot>
              <div v-if="filters?.extra">
                <div class="flex bg-blue-450 rounded-md ml-2 flex-wrap flex-0">
                  <slot name="filters"></slot>
                </div>
                <div class="grid grid-cols-12 flex-0">
                  <BadgeBar
                    v-model="filters.extra"
                    :filter-label-map="filterLabelMap"
                    :read-only-filters="readOnlyFilters"
                    class="col-span-11"
                  />
                  <div
                    class="ml-auto uppercase text-teal-900 text-xs font-semibold mt-2 cursor-pointer"
                    @click="clearFilters"
                  >
                    Reset Filters
                  </div>
                </div>
              </div>
            </div>
            <slot name="table">
              <div class="flex-1 flex overflow-y-auto justify-center h-[22rem]">
                <table v-if="!isLoading" class="m-4 p-2 w-full">
                  <thead class="bg-white sticky top-0">
                    <tr
                      v-for="headerGroup in table.getHeaderGroups()"
                      :key="headerGroup.id"
                    >
                      <th
                        v-for="header in headerGroup.headers"
                        :key="header.id"
                        class="py-4 pr-3 first:pl-2 text-left text-sm items-center text-gray-900"
                        @click="handleClickHeader(header)"
                      >
                        <div class="flex items-center">
                          {{ header.column.columnDef.header }}
                          <button
                            v-if="selectedSortHeader === header.id"
                            class="ml-2 h-full w-fit duration-200"
                            :class="{ 'rotate-180': isDesc }"
                          >
                            <ChevronDownIcon class="w-4 h-4" />
                          </button>
                        </div>
                      </th>
                      <th class="py-4 text-sm text-gray-900 w-[5%]">
                        <HeadersCheckboxList :options="columns" max-headers="4">
                          <CogTooth class="text-teal-900" />
                        </HeadersCheckboxList>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr
                      v-for="row in table.getRowModel().rows"
                      :key="row.id"
                      class="hover:bg-blue-350"
                    >
                      <td
                        v-for="cell in row.getVisibleCells()"
                        :key="cell.column.id"
                        :style="{ width: `${cell.column.getSize()}px` }"
                        class="py-8 text-sm pl-2 first:text-teal-900 capitalize border-t-[1px] border-solid border-t-[#f1f2f4]"
                      >
                        <div v-if="cell.column.columnDef.type === 'anchor'">
                          <a
                            class="text-teal-900"
                            :href="`/program-manager/housing/${cell.row.original.id}`"
                            target="_blank"
                            >{{ cell.row.original.name }}</a
                          >
                        </div>
                        <div
                          v-else-if="cell.column.columnDef.type === 'checkbox'"
                        >
                          <input
                            type="checkbox"
                            class="h-4 w-4 border-gray-300 form-checkbox"
                            :checked="IsItemSelected(cell.row.original.id)"
                            @input="() => checkItem(cell.row.original)"
                          />
                        </div>
                        <div v-else-if="cell.column.columnDef.type === 'radio'">
                          <input
                            type="radio"
                            class="h-4 w-4 border-gray-300 form-radio cursor-pointer"
                            :name="cell.row?.original?.header ?? 'id'"
                            :checked="IsItemSelected(cell.row.original.id)"
                            @input="() => checkItem(cell.row.original)"
                          />
                        </div>
                        <FlexRender
                          v-else
                          :render="cell.column.columnDef.cell"
                          :props="cell.getContext()"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Spinner
                  v-else-if="isLoading"
                  class="relative h-65vh bg-blue-100"
                />
              </div>
              <footer>
                <Pagination
                  class="mb-4 flex-0"
                  :page="pagination.pageIndex"
                  :total-of-records="data?.count ?? 0"
                  :records-per-page="pagination.pageSize"
                  @change-page="changePage"
                />
              </footer>
            </slot>

            <slot name="footer">
              <div class="flex align-center justify-center h-10 flex-0">
                <BaseButton
                  outlined
                  :on-click="closeModal"
                  :disabled="isLoading"
                  class="px-7"
                  >Cancel</BaseButton
                >
                <BaseButton
                  :on-click="addSelected"
                  :disabled="
                    isLoading || internalState?.selectedItems?.length < 1
                  "
                  class="w-48"
                  >Confirm</BaseButton
                >
              </div>
            </slot>
          </section>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>
