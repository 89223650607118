<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ERROR_TIMEOUT, SUCCESS_TIMEOUT, PERMISSIONS } from "@/constants.js";
import profileService from "@/services/profile.js";
import ErrorPage from "@/components/errorPage.vue";
import EditableForm from "@/components/shared/EditableForm.vue";
import LabeledEditableField from "@/components/shared/LabeledEditableField.vue";
import LabeledEditableTextArea from "@/components/shared/LabeledEditableTextArea.vue";
import LabeledEditableSelect from "@/components/shared/LabeledEditableSelect.vue";
import LabeledField from "@/components/shared/LabeledField.vue";
import PhoneField from "@/components/forms/SharedComponents/DarkBlueInputs/PhoneField.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import {
  useGetSingleProfile,
  useGetSingleCity,
  useGetCityOptions,
  debouncedFetchCityOptions,
} from "@/components/program-manager/sessions/composable";
import CloudinaryButton from "@/components/CloudinaryUploadWidget";
import { TrashIcon } from "@heroicons/vue/24/outline";
import { cloneDeep } from "lodash";
import { reactive, ref, computed, watch, unref } from "vue";
import { useToast } from "vue-toast-notification";
import { maxLength, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import SimpleText from "@/components/shared/SimpleText.vue";

const route = useRoute();
const toast = useToast();
const store = useStore();

const v$ = ref({});
const isEditing = ref(false);

const selectedProfileLocation = ref({
  id: "",
  city_ascii: "",
  country: {
    name: "",
  },
});

const selectedProfileWorkLocation = ref({
  id: "",
  city_ascii: "",
  country: {
    name: "",
  },
});

let profile = reactive({
  legal_first_name: "",
  phone_number: "",
  last_name: "",
  department: "",
  pronouns: "",
  job_title: "",
  bio: "",
  email: "",
  location_city_id: "",
  work_location_city_id: "",
});

const routeParticipantId = computed(() => route?.params?.profileId);

const oktaId = computed(() =>
  Boolean(store.state?.featureFlags?.["user-management"]) &&
  routeParticipantId.value
    ? routeParticipantId.value
    : store?.state?.currentUser?.participantId
);

const {
  execute: fetchSingleProfile,
  isLoading,
  state,
  isReady,
  error,
} = useGetSingleProfile(oktaId.value, true);

const {
  isLoading: isLoadingCityLocation,
  execute: executeFetchCityOptions,
  state: cityOptions,
} = useGetCityOptions(
  {
    immediate: false,
    throwError: true,
    resetOnExecute: true,
  },
  {}
);

const fetchCityOptions = debouncedFetchCityOptions(
  executeFetchCityOptions,
  500
);

const {
  isLoading: isLoadingCityWorkLocation,
  execute: executeCityWorkLocationOptions,
  state: cityWorkLocationOptions,
} = useGetCityOptions(
  {
    immediate: false,
    throwError: true,
    resetOnExecute: true,
  },
  {}
);

const fetchOptionCityWorkLocationOptions = debouncedFetchCityOptions(
  executeCityWorkLocationOptions,
  500
);

const rules = computed(() => ({
  profile: {
    legal_first_name: { maxLength: maxLength(100) },
    last_name: { maxLength: maxLength(100) },
    department: { maxLength: maxLength(100) },
    pronouns: { maxLength: maxLength(100) },
    job_title: { maxLength: maxLength(100) },
    bio: { maxLength: maxLength(500) },
    email: { maxLength: maxLength(100), email },
  },
}));

watch(
  () => isReady.value,
  (isCurrentReady) => {
    if (isCurrentReady) {
      profile = reactive(cloneDeep(state.value.data));
      v$.value = unref(
        useVuelidate(rules, reactive({ profile }), {
          $registerAs: "EditProfile",
          $lazy: true,
        })
      );

      if (profile?.location_city_id) {
        useGetSingleCity({
          city_id: profile.location_city_id,
        }).then((data) => {
          selectedProfileLocation.value = data?.state?.value;
        });
      }

      if (profile?.work_location_city_id) {
        useGetSingleCity({
          city_id: profile.work_location_city_id,
        }).then((data) => {
          selectedProfileWorkLocation.value = data?.state?.value;
        });
      }
    }
  }
);

const saveSuccessMessage = computed(() => ({
  message: "Profile saved successfully.",
  type: "success",
  position: "bottom",
  duration: SUCCESS_TIMEOUT,
}));

const saveErrorMessage = computed(() => ({
  message: "Failed to save profile.",
  type: "error",
  position: "bottom",
  duration: ERROR_TIMEOUT,
}));

const isWorkLocationSameAsLocation = computed(() =>
  !selectedProfileLocation.value?.id || !selectedProfileWorkLocation.value?.id
    ? false
    : selectedProfileLocation.value?.id ===
      selectedProfileWorkLocation.value?.id
);

const userHasEditPermission = computed(() => {
  return (
    store.getters.getPermissions.includes(PERMISSIONS.USERS_WRITE) ||
    profile?.okta_id === oktaId.value
  );
});

const workLocationText = computed(() => {
  return isWorkLocationSameAsLocation.value || isEditing.value
    ? "Same as location above"
    : undefined;
});

const submit = async () => {
  v$.value.$reset();
  const isValid = await v$.value.$validate();
  if (isValid) {
    isLoading.value = true;
    isEditing.value = false;
    try {
      profile.location_city_id = selectedProfileLocation?.value?.id ?? null;
      profile.work_location_city_id =
        selectedProfileWorkLocation?.value?.id ?? null;

      const updatedProfile = await profileService.updateProfile(
        oktaId.value,
        profile
      );
      if (updatedProfile) toast.open(saveSuccessMessage.value);
    } catch (err) {
      console.warn(err);
      toast.open(saveErrorMessage.value);
      isEditing.value = true;
    }
    isLoading.value = false;
  }
};

const cancelEditForm = () => (isEditing.value = false);
const editForm = () => (isEditing.value = true);

const setProfileHeadshot = (payload) => {
  profile.headshot = payload.secure_url;
};

const unsetProfileHeadshot = () => {
  profile.headshot = undefined;
};

const updatePhone = (data) => {
  profile.phone_number = data?.nationalNumber;
};

const updateWorkLocationSameAsAbove = (event) => {
  if (event.target.checked) {
    selectedProfileWorkLocation.value = selectedProfileLocation.value;
  } else {
    selectedProfileWorkLocation.value = {};
  }
};
const locationCityData = computed(() => {
  if (cityOptions.value?.items?.length > 0) {
    let city = cityOptions?.value?.items?.find(
      (item) => item?.id === selectedProfileLocation?.value
    );
    return {
      countryName: city?.country?.name ?? "",
      cityName: city?.city_ascii ?? "",
    };
  } else {
    return {};
  }
});
const workLocationCityData = computed(() => {
  if (cityWorkLocationOptions.value?.items?.length > 0) {
    let city = cityWorkLocationOptions?.value?.items?.find(
      (item) => item?.id === selectedProfileWorkLocation?.value
    );
    return {
      countryName: city?.country?.name ?? "",
      cityName: city?.city_ascii ?? "",
    };
  } else {
    return {};
  }
});
</script>

<template>
  <EditableForm
    v-if="profile?.email && !isLoading && !error"
    :is-editing="isEditing"
    form-title="ABOUT YOU"
    :editable="userHasEditPermission"
    :vuelidate-instance="v$.profile"
    @edit-form="editForm"
    @save-form="submit"
    @cancel-edit-form="cancelEditForm"
  >
    <LabeledField
      :field-value="profile.legal_first_name"
      label-name="legal first name*"
    />

    <LabeledField :field-value="profile.last_name" label-name="last name*" />

    <LabeledField :field-value="profile.email" label-name="email*" />

    <LabeledEditableField
      v-model="profile.phone_number"
      label-name="phone number"
      :is-editing="isEditing"
    >
      <template #field>
        <PhoneField
          v-model="profile.phone_number"
          class="w-[70%] flex-4 max-h-10"
          :vuelidate-instance="v$.profile.phone_number"
          @update-phone="updatePhone"
        >
        </PhoneField>
      </template>
    </LabeledEditableField>

    <LabeledEditableField
      v-model="profile.job_title"
      label-name="job title"
      :vuelidate-instance="v$.profile.job_title"
      :is-editing="isEditing"
    />

    <LabeledEditableField
      v-model="profile.department"
      label-name="department"
      :vuelidate-instance="v$.profile.department"
      :is-editing="isEditing"
    />

    <LabeledEditableField
      v-model="profile.pronouns"
      label-name="pronouns"
      :vuelidate-instance="v$.profile.pronouns"
      :is-editing="isEditing"
    />
    <div :class="{ 'grid grid-cols-7 items-center': isEditing }">
      <div
        class="items-center"
        :class="isEditing ? 'col-span-2' : 'grid grid-cols-7 items-center'"
      >
        <label
          class="uppercase font-semibold"
          :class="{ 'col-span-2': !isEditing }"
        >
          location: city
        </label>
        <SimpleText
          :class="{ 'col-span-5 ml-5': !isEditing }"
          v-if="!isEditing"
          :text-value="
            locationCityData?.cityName || selectedProfileLocation?.city_ascii
          "
        />
      </div>
      <div class="col-span-5 col-start-3 ml-5">
        <SearchableSelect
          v-if="isEditing"
          :loading="isLoadingCityLocation"
          v-model="selectedProfileLocation"
          :options="[...(cityOptions?.items || [])]"
          :deselect-from-dropdown="true"
          :close-on-select="true"
          :filterable="false"
          placeholder="City"
          label="city_ascii"
          @search="fetchCityOptions"
          input-classes="bg-white h-11 border border-indigo-base text-indigo-base focus:text-indigo-base placeholder-indigo-base placeholder-opacity-50 outline-none focus:ring-0"
        >
          <template #option="{ city_ascii, country, admin_name_ascii }">
            <div class="text-sm">
              <div class="font-semibold">{{ city_ascii }}</div>
              <div v-if="country?.name">
                {{ country?.name }}
                <span>-</span>
                {{ admin_name_ascii }}
              </div>
            </div>
          </template>
        </SearchableSelect>
      </div>
    </div>
    <LabeledField
      label-name="location: country"
      :field-value="
        locationCityData?.countryName || selectedProfileLocation?.country?.name
      "
    />
    <LabeledEditableField
      v-model="workLocationText"
      label-name="work location"
      :is-editing="isEditing"
    >
      <template #field>
        <div class="flex items-center">
          <input
            type="checkbox"
            class="form-checkbox checked:bg-teal-900"
            :checked="isWorkLocationSameAsLocation"
            @change="updateWorkLocationSameAsAbove"
          />
          <span class="ml-2 text-base font-semibold">{{
            workLocationText
          }}</span>
        </div>
      </template>
    </LabeledEditableField>

    <div :class="{ 'grid grid-cols-7 items-center': isEditing }">
      <div
        class="items-center"
        :class="isEditing ? 'col-span-2' : 'grid grid-cols-7 items-center'"
      >
        <label
          class="uppercase font-semibold"
          :class="{ 'col-span-2': !isEditing }"
        >
          work location: city
        </label>
        <SimpleText
          :class="{ 'col-span-5 ml-5': !isEditing }"
          v-if="!isEditing"
          :text-value="
            workLocationCityData?.cityName ||
            selectedProfileWorkLocation?.city_ascii
          "
        />
      </div>
      <div class="col-span-5 col-start-3 ml-5">
        <SearchableSelect
          v-if="isEditing"
          :loading="isLoadingCityWorkLocation"
          v-model="selectedProfileWorkLocation"
          :options="[...(cityWorkLocationOptions?.items || [])]"
          :deselect-from-dropdown="true"
          :close-on-select="true"
          :filterable="false"
          placeholder="City"
          label="city_ascii"
          @search="fetchOptionCityWorkLocationOptions"
          input-classes="bg-white h-11 border border-indigo-base text-indigo-base focus:text-indigo-base placeholder-indigo-base placeholder-opacity-50 outline-none focus:ring-0"
        >
          <template #option="{ city_ascii, country, admin_name_ascii }">
            <div class="text-sm">
              <div class="font-semibold">{{ city_ascii }}</div>
              <div v-if="country?.name">
                {{ country?.name }}
                <span>-</span>
                {{ admin_name_ascii }}
              </div>
            </div>
          </template>
        </SearchableSelect>
      </div>
    </div>
    <LabeledField
      label-name="work location: country"
      :field-value="
        workLocationCityData?.countryName ||
        selectedProfileWorkLocation?.country?.name
      "
    />

    <LabeledEditableField label-name="headshot" :is-editing="true">
      <template #field>
        <div class="w-[70%] flex-4 flex flex-col">
          <div
            v-if="profile.headshot"
            class="w-24 h-24 mr-10 relative rounded-full flex-none my-10 cursor-move"
            :class="[
              isEditing
                ? ['hover:shadow-2xl', 'hover:scale-110', 'duration-300']
                : '',
            ]"
          >
            <button
              v-if="isEditing"
              class="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full border border-gray-400 hover:text-red-100 hover:scale-125 duration-150"
              @click="unsetProfileHeadshot"
            >
              <TrashIcon class="w-5 h-5 mx-auto" />
            </button>
            <img
              :src="profile.headshot"
              alt="Profile headshot"
              class="h-full w-full rounded-full"
            />
          </div>
          <CloudinaryButton
            v-if="isEditing"
            folder="profiles"
            placeholder="UPLOAD"
            :public-id-prefix="profile?.data?.okta_id"
            @uploaded-media-data="setProfileHeadshot"
          />
        </div>
      </template>
    </LabeledEditableField>

    <LabeledEditableTextArea
      v-model="profile.bio"
      label-name="bio"
      :vuelidate-instance="v$.profile.bio"
      :is-editing="isEditing"
      :max-length="500"
    />
  </EditableForm>
  <Spinner
    v-else-if="isLoading && !error"
    class="m-auto w-full h-full !fixed opacity-75 bg-blue-100 !z-0"
  />
  <ErrorPage
    v-else
    class="relative h-65vh"
    message="Failed to load profile data."
  />
</template>
