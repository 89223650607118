<script setup>
import { computed, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { watchOnce } from "@vueuse/core";

import { TrashIcon } from "@heroicons/vue/24/outline";
import { useToast } from "vue-toast-notification";

import ErrorPage from "@/components/errorPage.vue";
import SimpleBreadCrumb from "@/components/shared/SimpleBreadCrumb.vue";
import HostInstitutionOverview from "@/components/HostInstitution/HostInstitutionOverview.vue";
import formPanel from "@/components/forms/SharedComponents/panel.vue";
import CloudinaryButton from "@/components/CloudinaryUploadWidget";
import TagsInput from "@/components/shared/TagsInput.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import {
  useGetEntitySupplement,
  useGetSingleHostInstitution,
  useGetHostInstitutionLanguages,
} from "@/components/program-manager/sessions/composable.js";
import hostInstitutionService from "@/services/hostInstitution.js";
import {
  ERROR_TIMEOUT,
  SUCCESS_TIMEOUT,
  HOST_INSTITUTION_TAGS_CATEGORY,
} from "@/constants.js";

const tagsCategoriesNames = HOST_INSTITUTION_TAGS_CATEGORY.map(
  ({ name }) => name
);

const store = useStore();
const toast = useToast();

const host_institution_media_path = "entity";

store.dispatch("tagManager/fetchTagsData");

const getFlatCategoryTags = computed(() =>
  store.getters["tagManager/filterCategoryTagsBySpecificCategories"](
    tagsCategoriesNames
  )
);

// eslint-disable-next-line no-undef
const props = defineProps({
  hostInstitutionId: { type: String, required: true },
});

const { hostInstitutionId } = toRefs(props);

const {
  isLoading: hostInstitutionLoading,
  state: hostInstitutionData,
} = useGetSingleHostInstitution(hostInstitutionId.value);

const {
  error: entitySuplemmentError,
  isLoading: entitySuplemmentLoading,
  state: entitySupplementData,
} = useGetEntitySupplement(hostInstitutionId.value);

const { state: languageData } = useGetHostInstitutionLanguages(
  hostInstitutionId.value
);

const entitySupplement = reactive({
  supporting_media: [],
  tags: [],
});

const loadingUpdate = ref(false);

watchOnce(entitySuplemmentLoading, (value) => {
  if (!value && !entitySuplemmentError.value) {
    entitySupplement.supporting_media =
      entitySupplementData.value?.items?.supporting_media || [];
    entitySupplement.tags = entitySupplementData.value?.items?.tags || [];
  } else if (entitySuplemmentError.value) {
    toast.open({
      message:
        "Error while loading Host Institution Tags and Media. Please, try it later",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  }
});

const insertSupportingMedia = (payload) => {
  const url = payload.secure_url;
  const baseEntityMedia = entitySupplement.supporting_media || [];
  if (!baseEntityMedia.includes(url))
    entitySupplement.supporting_media = [url, ...baseEntityMedia];
};

const removeSupportingMedia = (index) => {
  entitySupplement.supporting_media.splice(index, 1);
};

const updateEntitySuplementData = async () => {
  loadingUpdate.value = true;
  try {
    await hostInstitutionService.updateEntitySupplement(
      hostInstitutionId.value,
      entitySupplement
    );
    toast.open({
      message: "Host Institution Saved Successfully",
      type: "success",
      position: "bottom",
      duration: SUCCESS_TIMEOUT,
    });
  } catch {
    toast.open({
      message: "Error while saving Host Institution. Please, try it later",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  } finally {
    loadingUpdate.value = false;
  }
};
</script>

<template>
  <div class="relative">
    <Spinner
      v-if="hostInstitutionLoading || entitySuplemmentLoading || loadingUpdate"
      class="relative h-65vh bg-blue-100"
    />
    <div v-else-if="hostInstitutionData" class="p-4">
      <div class="flex justify-between mb-1">
        <SimpleBreadCrumb />
      </div>
      <div class="p-6">
        <div class="sm:flex sm:items-center sm:justify-between">
          <h1
            class="self-center text-2xl font-semibold leading-6 text-gray-900 bg-blue-100 focus:outline-none"
          >
            Edit Host Institution Details
          </h1>
          <div class="flex items-center space-x-4 mt-3 sm:mt-0 sm:ml-4">
            <button
              type="button"
              :disabled="loadingUpdate"
              class="inline-flex items-center rounded-md border-[#007f80] border-solid border bg-[#007f80] px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#ffffff] hover:text-[#007f80]"
              @click="updateEntitySuplementData"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-y-4 mt-6">
        <HostInstitutionOverview
          ref="hostInstitutionOverview"
          :host-data="hostInstitutionData.items"
          :entity-supplement-data="entitySupplementData?.items || {}"
          :language-data="languageData"
        />
        <form-panel
          id="host-institution-media"
          title="Media Section"
          panel-content-class="grid grid-cols-1 gap-4 pb-6 mb-6 border-b border-gray-200 md:grid-cols-1 sm:gap-6"
          panel-button-text-class="tracking-normal"
        >
          <template #content>
            <div class="border border-gray-300 rounded-sm p-8 bg-white">
              <CloudinaryButton
                :folder="host_institution_media_path"
                placeholder="Upload"
                @uploadedMediaData="insertSupportingMedia"
              />
              <VDraggable
                v-model="entitySupplement.supporting_media"
                class="flex flex-wrap pb-2"
                item-key="index"
                ghost-class="opacity-50"
              >
                <template #item="{ element: src, index }">
                  <div
                    class="w-24 h-24 mr-10 relative flex-none mt-10 cursor-move hover:shadow-2xl hover:scale-110 duration-300"
                  >
                    <button
                      class="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full border border-gray-400 hover:text-red-100 hover:scale-125 duration-150"
                      @click="removeSupportingMedia(index)"
                    >
                      <TrashIcon class="w-5 h-5 mx-auto" />
                    </button>
                    <img :src="src" alt="Support media" class="h-full w-full" />
                  </div>
                </template>
              </VDraggable>
              <div class="mt-6 mb-4">
                <label
                  name="tag"
                  class="block text-sm font-semibold text-gray-700"
                  >Tags</label
                >
                <TagsInput
                  v-model="entitySupplement.tags"
                  :options="getFlatCategoryTags"
                  label="name"
                />
              </div>
            </div>
          </template>
        </form-panel>
      </div>
    </div>
    <ErrorPage
      v-else
      class="relative h-65vh"
      message="Failed to load host institution detail data."
    />
  </div>
</template>
