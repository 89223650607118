import { mapState } from "vuex";
import { shallowRef } from "vue";
import { FORMS_SUBMISSIONS } from "@/constants.js";
import formioHelpers from "./formioHelpers";
import ResumeForm from "../components/forms/Resume/ResumeForm";
import StatementOfPurposeForm from "../components/forms/StatementOfPurpose/StatementOfPurposeForm.vue";
import Transcript from "../components/forms/Transcript/Transcript";
import CoverLetter from "../components/forms/CoverLetter/CoverLetter";
import UniversityApprovalForm from "../components/forms/UniversityApproval/UniversityApprovalForm.vue";
import GradeReportForm from "../components/forms/GradeReport/GradeReportForm.vue";
import InternshipLetter from "../components/forms/LetterOfRecommendation/InternshipLetter.vue";
import AbroadLetter from "../components/forms/LetterOfRecommendation/AbroadLetter.vue";
import ETranscript from "../components/forms/E-Transcript/etranscript";
import InternshipCreditForm from "../components/forms/InternshipCreditOptions/InternshipCreditForm";
import LDMRegistrationPolicy from "../components/forms/LDMRegistrationPolicy/LDMRegistrationPolicy";
import forms from "../mixins/forms";
import letterOfRecAndGpaLogic from "./letterOfRecAndGpaLogic";
import UnofficialTranscript from "../components/forms/UnofficialTranscript/UnofficialTranscript.vue";
import GpaStatement from "../components/forms/Gpa/GpaStatement.vue";
import HousingForm from "../components/forms/Housing/HousingForm.vue";
import InternshipInterests from "@/components/forms/InternshipInterests/InternshipInterestsPage.vue";
import PassportsForm from "../components/forms/Passport/PassportsForm.vue";
import BadgesPage from "@/components/forms/BadgesInterests/BadgesPage.vue";
import HeadShotForm from "@/components/forms/HeadShot/HeadshotForm.vue";
import CourseForm from "../components/forms/CourseSelection/CoursePage.vue";
import CPCodeOfConduct from "@/components/codeOfConduct/CustomizedCodeOfConduct.vue";
import SupplementalForm from "../components/forms/Supplemental/SupplementalForm.vue";
import GroupVisaForm from "@/components/forms/GroupVisaInformation/GroupVisaForm.vue";
import HousingQuestionnaireForm from "@/components/forms/HousingQuestionnaire/HousingQuestionnaireForm.vue";

export default {
  mixins: [formioHelpers, forms, letterOfRecAndGpaLogic],
  data() {
    return {
      firstColumn: [],
      secondColumn: [],
    };
  },
  computed: {
    ...mapState([
      "program",
      "isInternship",
      "onBoardingFormioSubmissions",
      "currentApplicationId",
      "uiVersion",
    ]),
    courseFormSubmissionURL() {
      return this.uiVersion === "v2" || this.uiVersion === "v3"
        ? "courseform"
        : "studentapplicationstage2step2";
    },
    /**
     * List of forms loaded in onboarding section
     * ruleName: must match the form rule name for the component
     * component: the component to load for the requested route
     * slug: slug of the requested form
     */
    onBoardingForms() {
      //If no forms appear in the program service under application form rules then the section should not have any forms and it wont be visible
      if (this.program.form_rules.length === 0) return [];

      let initialList = [
        {
          ruleName: "Resume",
          component: shallowRef(ResumeForm),
          slug: "resume",
          label: "Resume",
          formioPath: "resume",
          submittedFormioKey: "resumeSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Statement Of Purpose",
          component: shallowRef(StatementOfPurposeForm),
          slug: "statement-of-purpose",
          label: "Statement Of Purpose",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: "statementOfPurposeSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Cover Letter",
          component: shallowRef(CoverLetter),
          slug: "cover-letter",
          label: "Intern Statement of Purpose",
          formioPath: "coverletter",
          submittedFormioKey: "coverLetterSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Grade Report",
          component: shallowRef(GradeReportForm),
          slug: "grade-report",
          label: "Grade Report",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: "gradeReportSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Paper Transcript",
          component: shallowRef(Transcript),
          slug: "transcript-hard-copy",
          label: "Transcript",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: "transcriptSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "University Approval",
          component: shallowRef(UniversityApprovalForm),
          slug: "university-approval-request",
          label: "University Approval Request",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: "universityApprovalSubmitted",
          submitted: false,
          specialValidation: false,
          specialSubmittedLogic: true,
        },
        {
          ruleName: "Web Form Letter of Recommendation",
          component: shallowRef(AbroadLetter),
          slug: "study-abroad-letter-of-recommendation",
          label: "Letter of Recommendation",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: [
            "letterOfRecommendationSubmitted1",
            "letterOfRecommendationSubmitted2",
          ],
          submitted: false,
          specialDisplayLogic: true,
          specialValidation: true,
        },
        {
          ruleName: "File Upload Letter of Recommendation",
          component: shallowRef(InternshipLetter),
          slug: "internship-letter-of-recommendation",
          label: "Letter of Recommendation",
          formioPath: "hostuniversityinternship",
          submittedFormioKey: ["letterSubmitted01", "letterSubmitted02"],
          submitted: false,
          specialValidation: true,
        },
        {
          ruleName: "E-Transcript",
          component: shallowRef(ETranscript),
          slug: "etranscript",
          label: "E-Transcript",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: "eTranscriptSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Fixed Date Internship Academic Credit Options",
          component: shallowRef(InternshipCreditForm),
          slug: "internship-credit-options",
          label: "Internship Credit Options",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: "internshipCreditSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "LdM Supplemental Application Form",
          component: shallowRef(LDMRegistrationPolicy),
          slug: "ldm-registration-policy",
          label: "LDM Registration Policy",
          formioPath: "studentapplicationstage2step2",
          submittedFormioKey: "ldmSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Unofficial Transcript",
          component: shallowRef(UnofficialTranscript),
          slug: "unofficial-transcript",
          label: "Unofficial Transcript",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: "unofficialTranscriptSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          component: shallowRef(GpaStatement),
          slug: "gpa-statement",
          label: "Gpa Statement",
          formioPath: "studentapplicationstage2step1",
          submittedFormioKey: "gpaStatementSubmitted",
          submitted: false,
          specialDisplayLogic: true,
          specialValidation: false,
        },
        {
          ruleName: "Housing Accommodations",
          component: shallowRef(HousingForm),
          slug: "housing",
          label: "Housing Accommodation",
          formioPath: "housing",
          submittedFormioKey: "submitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Internship Interests",
          component: shallowRef(InternshipInterests),
          slug: "internship-interests-form",
          label: "Internship Interests",
          formioPath: "hostuniversityinternship",
          submittedFormioKey: "completedInterests",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Passport Info",
          component: shallowRef(PassportsForm),
          slug: "passport",
          label: "Passport Information",
          formioPath: "passport",
          submittedFormioKey: "passportSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Head Shot",
          component: shallowRef(HeadShotForm),
          slug: "headshot",
          label: "Headshot",
          formioPath: "headshot",
          submittedFormioKey: "headshotSubmitted",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Course Selection",
          component: shallowRef(CourseForm),
          slug: "global-course-selection",
          label: "Course Selection",
          formioPath: this.courseFormSubmissionURL,
          submittedFormioKey: "completedCourseSelection",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "Badge Interests",
          component: shallowRef(BadgesPage),
          slug: "digital-badges",
          label: "Badges Interests",
          formioPath: "badges",
          submittedFormioKey: "completedDigitalBadges",
          submitted: false,
          specialValidation: false,
        },
        {
          ruleName: "CP Code of Conduct- Study",
          component: shallowRef(CPCodeOfConduct),
          slug: "customized-code-of-conduct",
          label: "Customized Code of Conduct",
          formioPath: "cpcodeofconduct",
          submittedFormioKey: "completedForm",
          submitted: false,
        },
        {
          ruleName: "Supplemental Form",
          component: shallowRef(SupplementalForm),
          slug: "supplemental-form",
          label: "Supplemental Form",
          formioPath: "supplementalform",
          submittedFormioKey: "submitted",
          submitted: false,
        },
        {
          ruleName: "Group Visa Information",
          component: shallowRef(GroupVisaForm),
          slug: "group-visa-information",
          label: "Group Visa Information",
          formioPath: "studentapplicationstage2step2",
          submittedFormioKey: "groupVisaSubmitted",
          submitted: false,
        },
        {
          component: shallowRef(HousingQuestionnaireForm),
          slug: FORMS_SUBMISSIONS.housingQuestionnaire.slug,
          label: FORMS_SUBMISSIONS.housingQuestionnaire.name,
          specialDisplayLogic: true,
          specialValidation: true,
          submitted: false,
          formName: FORMS_SUBMISSIONS.housingQuestionnaire.name,
        },
      ];

      //process form rules
      return initialList.filter(
        (item) =>
          !(
            (item.ruleName === "Housing Accommodations" ||
              item.ruleName ===
                "Fixed Date Internship Academic Credit Options") &&
            this.uiVersion === "v3"
          ) &&
          (this.program.form_rules.includes(item.ruleName) ||
            item.specialDisplayLogic)
      );
    },
    onBoardingFormioPaths() {
      //create an array of formio form paths from the on boarding forms list
      let list = this.onBoardingForms.map((item) => {
        return item.formioPath;
      });
      //remove duplicate paths
      return list.filter((item, index) => list.indexOf(item) === index);
    },
  },
};
