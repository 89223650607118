export default {
  computed: {
    websiteName() {
      return "APIConnect";
    },
  },
  methods: {
    setSignInTitle(currentView) {
      if (currentView === "set-form")
        this.changeDocumentTitle("Set your password");
      if (currentView === "reset-form")
        this.changeDocumentTitle("Forgot your password");
      if (currentView === "create-account")
        this.changeDocumentTitle("Create Account");
      if (currentView === "change-pass-form")
        this.changeDocumentTitle("Change your password");
    },
    setFormTitle(currentForm) {
      if (currentForm) this.changeDocumentTitle(currentForm);
    },
    changeDocumentTitle(newTitle) {
      document.title = newTitle + " | " + this.websiteName;
    },
  },
};
