<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { defineProps } from "vue";
import ErrorMessage from "../ErrorMessage.vue";

const {
  fieldLabel,
  modelValue,
  vuelidateInstance,
  inputClasses,
  fieldLabelClasses,
  errorClasses,
  placeholder,
  outerClasses,
} = defineProps({
  fieldLabel: {
    type: String,
  },
  modelValue: {},
  vuelidateInstance: {},
  inputClasses: {
    default: "h-10 w-full form-input",
  },
  fieldLabelClasses: { default: "common-label-text" },
  errorClasses: { default: "common-error-text" },
  placeholder: { type: String },
  outerClasses: {},
});
</script>

<template>
  <div :class="outerClasses">
    <slot name="fieldLabel">
      <label
        v-show="fieldLabel"
        :name="fieldLabel"
        :class="fieldLabelClasses"
        >{{ fieldLabel }}</label
      >
    </slot>
    <input
      :id="fieldLabel"
      :value="modelValue"
      type="text"
      :name="fieldLabel"
      :placeholder="placeholder"
      :class="[
        {
          'bg-error-100': vuelidateInstance?.$errors?.length,
        },
        inputClasses,
        'forced-white-background',
      ]"
      @blur="vuelidateInstance?.$touch"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
    />
    <ErrorMessage
      :error-classes="errorClasses"
      :vuelidate-instance="vuelidateInstance"
    />
  </div>
</template>

<style scoped>
.forced-white-background[type="text"]:focus {
  background-color: white;
}
</style>
