import { mapState } from "vuex";
import billingRules from "./billingRules";
import { APPLICATION_FEE_DATA } from "@/constants";

export default {
  mixins: [billingRules],
  computed: {
    ...mapState([
      "isInternship",
      "studentApplications",
      "program",
      "studentFormioSubmissions",
      "uiVersion",
      "userData",
      "directProgramData",
    ]),
    currentEnvironment() {
      const appEnv = process.env.MIX_APP_ENV;
      return appEnv === "local" ? "staging" : appEnv;
    },
    noUniversityInformationSections() {
      const universityInfoRules = [
        "Head Shot",
        "Course Selection",
        "Dynamic Course Selection",
        "Pre-set Courses",
        "LdM Supplemental Application Form",
        "Passport Info",
        "Group Visa Information",
        "Resume",
      ];

      // If form rules exist but length is zero, hide the form
      if (this.program.form_rules && this.program.form_rules.length === 0) {
        return true;
      }

      // if form rules exist and has length and they don't include at least one of the rules, hide housing form
      if (
        this.program.form_rules &&
        this.program.form_rules.length &&
        !universityInfoRules.some((el) => this.program.form_rules.includes(el))
      ) {
        return true;
      }
      return false;
    },
    forms() {
      // here we are mapping the UI version to the set of Form.io forms to use.
      // v2 and v3 share the same set of forms;
      // default to v1 on non-normal input
      return (
        {
          v1: this.formsV1,
          v2: this.formsV2,
          v3: this.formsV2,
        }[this.uiVersion] || "v1"
      );
    },
    formsV1() {
      let formData = [];
      switch (this.currentEnvironment) {
        case "production":
          formData = [
            {
              name: "Select Your Program",
              url: "/api/forms/programselection",
              slug: "program-selection",
              id: "5e4d6b7993920a7732410c8d",
              notification: "",
            },
            {
              name: this.institutionPaysApplicationFee
                ? "Institution Pays"
                : APPLICATION_FEE_DATA.formName,
              url: this.institutionPaysApplicationFee
                ? "/api/forms/institutionpayment"
                : "/api/forms/paymentform",
              slug: this.institutionPaysApplicationFee
                ? "institution-pays"
                : "pay-deposit",
              id: this.institutionPaysApplicationFee
                ? "5f4686082f068b84c96f8db2"
                : "5e4d93df6c740bfc00b07525",
              notification: "start",
            },
            {
              name: "Academic Information",
              url: "/api/forms/studentapplicationstage2step1",
              slug: "academic-information",
              id: "5e8342499c455837e10b50e2",
              notification: "",
            },
            {
              name: this.isInternship
                ? "Internship Information"
                : "University Information",
              url: this.isInternship
                ? "/api/forms/hostuniversityinternship"
                : "/api/forms/studentapplicationstage2step2",
              slug: this.isInternship
                ? "internship-information"
                : "university-information",
              id: this.isInternship
                ? "5ef397837f4f7f2409b30978"
                : "5e8f9725bdba182ba7627b84",
              notification: "",
            },
            {
              name: "Housing Accommodations",
              url: "/api/forms/housing",
              slug: "housing-accommodations",
              id: "5f777bb094a45f57de1ac4ac",
              notification: "",
            },
            {
              name: "Program Fees & Payment Options",
              url: "/api/forms/programfeespaymentoptions",
              slug: "program-fees",
              id: "5efb57f47ca92a01b7b34b2d",
              notification: "submit",
            },
            {
              name: "Application Received",
              url: "/api/forms/applicationreceived",
              slug: "application-received",
              id: "5edab728ad88126042e8842f",
              notification: "",
            },
          ];
          break;
        case "staging":
          formData = [
            {
              name: "Select Your Program",
              url: "/api/forms/programselection",
              slug: "program-selection",
              id: "5f0e1603870c115f00d8e146",
              notification: "",
            },
            {
              name: this.institutionPaysApplicationFee
                ? "Institution Pays"
                : APPLICATION_FEE_DATA.formName,
              url: this.institutionPaysApplicationFee
                ? "/api/forms/institutionpayment"
                : "/api/forms/paymentform",
              slug: this.institutionPaysApplicationFee
                ? "institution-pays"
                : "pay-deposit",
              id: this.institutionPaysApplicationFee
                ? "5f4684a8c9230479b6179900"
                : "5f0e1603870c11607ed8e141",
              notification: "start",
            },
            {
              name: "Academic Information",
              url: "/api/forms/studentapplicationstage2step1",
              slug: "academic-information",
              id: "5f0e1603870c11bab6d8e13e",
              notification: "",
            },
            {
              name: this.isInternship
                ? "Internship Information"
                : "University Information",
              url: this.isInternship
                ? "/api/forms/hostuniversityinternship"
                : "/api/forms/studentapplicationstage2step2",
              slug: this.isInternship
                ? "internship-information"
                : "university-information",
              id: this.isInternship
                ? "5f0e1603870c11f8fbd8e144"
                : "5f0e1603870c1177ffd8e145",
              notification: "",
            },
            {
              name: "Housing Accommodations",
              url: "/api/forms/housing",
              slug: "housing-accommodations",
              id: "5f777ba520b73e910a25f65b",
              notification: "",
            },
            {
              name: "Program Fees & Payment Options",
              url: "/api/forms/programfeespaymentoptions",
              slug: "program-fees",
              id: "5f0e1603870c1174c1d8e147",
              notification: "submit",
            },
            {
              name: "Application Received",
              url: "/api/forms/applicationreceived",
              slug: "application-received",
              id: "5f0e1603870c11b1d5d8e142",
              notification: "",
            },
          ];
          break;
        case "development":
          formData = [
            {
              name: "Select Your Program",
              url: "/api/forms/programselection",
              slug: "program-selection",
              id: "5ee7a436e9c4fd694207bc46",
              notification: "",
            },
            {
              name: this.institutionPaysApplicationFee
                ? "Institution Pays"
                : APPLICATION_FEE_DATA.formName,
              url: this.institutionPaysApplicationFee
                ? "/api/forms/institutionpayment"
                : "/api/forms/paymentform",
              slug: this.institutionPaysApplicationFee
                ? "institution-pays"
                : "pay-deposit",
              id: this.institutionPaysApplicationFee
                ? "5f4553626640264a813a5fb1"
                : "5ee7a436e9c4fd073f07bc47",
              notification: "start",
            },
            {
              name: "Academic Information",
              url: "/api/forms/studentapplicationstage2step1",
              slug: "academic-information",
              id: "5ee7a436e9c4fd061d07bc41",
              notification: "",
            },
            {
              name: this.isInternship
                ? "Internship Information"
                : "University Information",
              url: this.isInternship
                ? "/api/forms/hostuniversityinternship"
                : "/api/forms/studentapplicationstage2step2",
              slug: this.isInternship
                ? "internship-information"
                : "university-information",
              id: this.isInternship
                ? "5f0e29e7ca47bdcb0c541401"
                : "5ee7a436e9c4fd542107bc3e",
              notification: "",
            },
            {
              name: "Housing Accommodations",
              url: "/api/forms/housing",
              slug: "housing-accommodations",
              id: "5f401c7fdc7bcb322c8ca114",
              notification: "",
            },
            {
              name: "Program Fees & Payment Options",
              url: "/api/forms/programfeespaymentoptions",
              slug: "program-fees",
              id: "5f0e29e7ca47bd316c541402",
              notification: "submit",
            },
            {
              name: "Application Received",
              url: "/api/forms/applicationreceived",
              slug: "application-received",
              id: "5ee7a436e9c4fd5b0a07bc43",
              notification: "",
            },
          ];
          break;
        default:
          // Same as development
          formData = [
            {
              name: "Select Your Program",
              url: "/api/forms/programselection",
              slug: "program-selection",
              id: "5ee7a436e9c4fd694207bc46",
              notification: "",
            },
            {
              name: this.institutionPaysApplicationFee
                ? "Institution Pays"
                : APPLICATION_FEE_DATA.formName,
              url: this.institutionPaysApplicationFee
                ? "/api/forms/institutionpayment"
                : "/api/forms/paymentform",
              slug: this.institutionPaysApplicationFee
                ? "institution-pays"
                : "pay-deposit",
              id: this.institutionPaysApplicationFee
                ? "5f4553626640264a813a5fb1"
                : "5ee7a436e9c4fd073f07bc47",
              notification: "start",
            },
            {
              name: "Academic Information",
              url: "/api/forms/studentapplicationstage2step1",
              slug: "academic-information",
              id: "5ee7a436e9c4fd061d07bc41",
              notification: "",
            },
            {
              name: this.isInternship
                ? "Internship Information"
                : "University Information",
              url: this.isInternship
                ? "/api/forms/hostuniversityinternship"
                : "/api/forms/studentapplicationstage2step2",
              slug: this.isInternship
                ? "internship-information"
                : "university-information",
              id: this.isInternship
                ? "5f0e29e7ca47bdcb0c541401"
                : "5ee7a436e9c4fd542107bc3e",
              notification: "",
            },
            {
              name: "Housing Accommodations",
              url: "/api/forms/housing",
              slug: "housing-accommodations",
              id: "5f401c7fdc7bcb322c8ca114",
              notification: "",
            },
            {
              name: "Program Fees & Payment Options",
              url: "/api/forms/programfeespaymentoptions",
              slug: "program-fees",
              id: "5f0e29e7ca47bd316c541402",
              notification: "submit",
            },
            {
              name: "Application Received",
              url: "/api/forms/applicationreceived",
              slug: "application-received",
              id: "5ee7a436e9c4fd5b0a07bc43",
              notification: "",
            },
          ];
      }

      if (this.hideHousingFormInApplicationProcess) {
        formData = formData.filter((form) => {
          return form.name !== "Housing Accommodations";
        });
      }

      // remove the Program Fees & Payment Options form if institution pays all three fees
      if (
        this.institutionPaysApplicationFee &&
        this.institutionPaysConfirmationPayment &&
        this.institutionPaysAcademicFee
      ) {
        formData = formData.filter((form) => {
          return form.name !== "Program Fees & Payment Options";
        });
      }

      if (this.noUniversityInformationSections) {
        formData = formData.filter((form) => {
          return form.name !== "University Information";
        });
      }

      return formData;
    },
    formsV2() {
      let formData = [
        {
          name: this.directProgramData?.customized
            ? "Confirm your program"
            : "Select Your Program",
          slug: this.directProgramData?.customized
            ? "program-confirmation"
            : "program-selection",
          id: "5ee7a436e9c4fd694207bc46",
        },
        {
          name: "Academic Profile",
          slug: "academic-profile",
          id: "5ee7a436e9c4fd061d07bc41",
        },
        {
          name: this.institutionPaysApplicationFee
            ? "Institution Pays"
            : APPLICATION_FEE_DATA.formName,
          slug: this.institutionPaysApplicationFee
            ? "institution-pays"
            : "pay-deposit",
          id: this.institutionPaysApplicationFee
            ? "5f4553626640264a813a5fb1"
            : "5ee7a436e9c4fd073f07bc47",
        },
        {
          name: "What's Next?",
          slug: "next-steps",
          id: "",
        },
      ];

      if (this.currentEnvironment === "production") {
        formData = [
          {
            name: this.directProgramData?.customized
              ? "Confirm your program"
              : "Select Your Program",
            slug: this.directProgramData?.customized
              ? "program-confirmation"
              : "program-selection",
            id: "5e4d6b7993920a7732410c8d",
          },
          {
            name: "Academic Profile",
            slug: "academic-profile",
            id: "5e8342499c455837e10b50e2",
          },
          {
            name: this.institutionPaysApplicationFee
              ? "Institution Pays"
              : APPLICATION_FEE_DATA.formName,
            slug: this.institutionPaysApplicationFee
              ? "institution-pays"
              : "pay-deposit",
            id: this.institutionPaysApplicationFee
              ? "5f4686082f068b84c96f8db2"
              : "5e4d93df6c740bfc00b07525",
          },
          {
            name: "What's Next?",
            slug: "next-steps",
            id: "",
          },
        ];
      } else if (this.currentEnvironment === "staging") {
        formData = [
          {
            name: this.directProgramData?.customized
              ? "Confirm your program"
              : "Select Your Program",
            slug: this.directProgramData?.customized
              ? "program-confirmation"
              : "program-selection",
            id: "5f0e1603870c115f00d8e146",
          },
          {
            name: "Academic Profile",
            slug: "academic-profile",
            id: "5f0e1603870c11bab6d8e13e",
          },
          {
            name: this.institutionPaysApplicationFee
              ? "Institution Pays"
              : APPLICATION_FEE_DATA.formName,
            slug: this.institutionPaysApplicationFee
              ? "institution-pays"
              : "pay-deposit",
            id: this.institutionPaysApplicationFee
              ? "5f4684a8c9230479b6179900"
              : "5f0e1603870c11607ed8e141",
          },
          {
            name: "What's Next?",
            slug: "next-steps",
            id: "",
          },
        ];
      }

      return formData;
    },
    paymentIds() {
      let ids = [];

      if (this.currentEnvironment === "production") {
        ids = ["5f4686082f068b84c96f8db2", "5e4d93df6c740bfc00b07525"];
      } else if (this.currentEnvironment === "staging") {
        ids = ["5f4684a8c9230479b6179900", "5f0e1603870c11607ed8e141"];
      } else {
        ids = ["5f4553626640264a813a5fb1", "5ee7a436e9c4fd073f07bc47"];
      }

      return ids;
    },
    academicId() {
      return {
        production: "5e8342499c455837e10b50e2",
        staging: "5f0e1603870c11bab6d8e13e",
        development: "5ee7a436e9c4fd061d07bc41",
      };
    },
    institutionPayments() {
      return {
        production: "5f4686082f068b84c96f8db2",
        staging: "5f4684a8c9230479b6179900",
      };
    },
    paymentFormIndex() {
      return ["v2", "v3"].includes(this.uiVersion) ? 2 : 1;
    },
    hideHousingFormInApplicationProcess() {
      const housingRules = [
        "Accommodation Style Preference",
        "Single Room Preference",
        "Requesting a Roommate",
        "Declining a Roommate",
        "Requesting a Housemate",
        "Ranking Your Housing Preference",
        "Housing Questionnaire",
      ];

      // If form rules exist but length is zero, hide housing form
      if (this.program.form_rules && this.program.form_rules.length === 0) {
        return true;
      }

      // if form rules exist and has length and they don't include at least one of the rules, hide housing form
      if (
        this.program.form_rules &&
        this.program.form_rules.length &&
        !housingRules.some((el) => this.program.form_rules.includes(el))
      ) {
        return true;
      }
    },
  },
  methods: {
    formIsComplete(applicationId, uiVersion) {
      // here we are mapping the UI version to the set of Form.io forms to use;
      // v2 and v3 share the same set of forms;
      // default to v1 on non-normal input
      let forms =
        {
          v1: this.formsV1,
          v2: this.formsV2,
          v3: this.formsV2,
        }[uiVersion] || "v1";
      if (!applicationId) return false;
      let submissions = this.studentFormioSubmissions.filter((submission) => {
        return submission.data.application_id === applicationId;
      });

      //remove duplicates from submissions
      const filteredArr = [];
      Object.values(submissions).forEach((value) => {
        if (!filteredArr.includes(value.form)) {
          filteredArr.push(value.form);
        }
      });
      //check if the last form in the forms array is in the submissions, assuming
      //the last form in the forms array is the application received.
      let lastIndex = forms.length - (["v2", "v3"].includes(uiVersion) ? 2 : 1);
      let lastStepId = forms[lastIndex].id;
      let hasLastStep = false;

      if (["v2", "v3"].includes(uiVersion)) {
        this.paymentIds.forEach((paymentId) => {
          if (!hasLastStep) hasLastStep = filteredArr.includes(paymentId);
        });
      } else {
        hasLastStep = filteredArr.includes(lastStepId);
      }

      if (hasLastStep) return true;

      return false;
    },
    async isApplicationOwner(applicationId) {
      // ensure that the Vuex store of student applications has been appropriately set
      if (
        !(
          Array.isArray(this.studentApplications) &&
          this.studentApplications.length > 0
        )
      ) {
        await this.$store.dispatch("getFormioSubmissions");
        await this.$store.dispatch("getStudentApplications");
      }

      // return true if the IDs of any applications in store match the provided applicationId value
      return this.studentApplications.some(
        (application) => application.id === applicationId
      );
    },
    /**
     * get the forms last submission
     */
    getFormSubmissions(form, application_id) {
      if (application_id && form) {
        let submissions = this.studentFormioSubmissions.filter((submission) => {
          return (
            submission.data.application_id === application_id &&
            submission.form === form.id
          );
        });

        if (submissions.length === 0) {
          return false;
        }

        return submissions;
      } else {
        return false;
      }
    },
    isAnObject(aVariable) {
      return typeof aVariable === "object";
    },
    objectHasProperty(theObject, theProperty) {
      if (!this.isAnObject(theObject)) return false;
      return Object.hasOwnProperty.call(theObject, theProperty);
    },
    /**
     * Check application payment status
     * @returns return true if the application has been paid
     */
    isApplicationPaid(applicationId) {
      return this.studentFormioSubmissions.some((submission) => {
        return (
          submission.data.application_id === applicationId &&
          submission.form === this.forms[this.paymentFormIndex].id &&
          submission.state === "submitted"
        );
      });
    },
    async isFormAllowedByFormRules(form, applicationId, where = "form_rules") {
      //set the program data if not already set
      await this.$store.dispatch("setProgramDataByApplicationId", {
        applicationId: applicationId,
        apolloClient: this.$apollo,
      });

      //check if the form in the form rules
      return this.guardFormRuleExistence(where, form);
    },
    guardFormRuleExistence(propertyName, rule) {
      if (!rule) return false;
      if (typeof this.program !== "object") return false;
      if (!Object.prototype.toString.call(this.program) === "[object Object]")
        return false;
      if (!Object.hasOwnProperty.call(this.program, propertyName)) return false;
      if (!Array.isArray(this.program[propertyName])) return false;
      if (!this.program[propertyName].length) return false;
      if (!this.program[propertyName].includes(rule)) return false;
      return true;
    },
    async isValidApplication(applicationId, rule, where = "form_rules") {
      let valid = false;
      let validApp = false;
      if (!this.studentApplications.length) {
        await this.$store.dispatch("getFormioSubmissions");
        await this.$store.dispatch("getStudentApplications");
        validApp = this.$store.state.studentFormioSubmissions.some(
          (app) => app.data.application_id === applicationId
        );
      } else {
        validApp = await this.isApplicationOwner(applicationId);
      }
      if (validApp && rule) {
        if (!Object.prototype.hasOwnProperty.call(this.userData, "data")) {
          await this.$store.dispatch("cacheFormioUser");
        }

        valid = await this.isFormAllowedByFormRules(rule, applicationId, where);
      }

      return rule ? valid : validApp;
    },
    async isValidApplicationAndIsOnRules(applicationId, rule) {
      let response = { valid: false, ruleType: "" };
      const ruleTypes = ["form_rules", "post_acceptance_form_rules"];
      const valid = await this.isValidApplication(applicationId, "");

      if (valid) {
        if (Object.keys(this.program).length === 0) {
          await this.$store.dispatch("setProgramDataByApplicationId", {
            applicationId: applicationId,
            apolloClient: this.$apollo,
          });
        }

        for (const type of ruleTypes) {
          let validRule = await this.isFormAllowedByFormRules(
            rule,
            applicationId,
            type
          );

          if (validRule) {
            response.valid = true;
            response.ruleType = type;
            break;
          }
        }
      }

      return response;
    },
  },
};
