<template>
  <div v-if="modalState" class="relative z-50">
    <div
      class="fixed inset-0 hidden bg-university-primary opacity-50 sm:block"
    />
    <Dialog
      :open="modalState"
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
      @close-modal="closeModal"
      @click.self="closeModal"
    >
      <div
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-20 relative"
      >
        <div class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-3r">
          <button
            class="h-6 w-6 flex items-center justify-center"
            @click="closeModal"
          >
            <span class="sr-only">Close</span>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <DialogPanel>
          <DialogTitle
            class="flex justify-center mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            {{ title }}
          </DialogTitle>
          <DialogDescription class="mb-10">
            <WarningIcon60 v-if="displayError" class="mx-auto my-10" />
            <p
              v-if="displayError"
              class="text-sm mt-2 text-red-100 text-center"
            >
              Error: unable to copy {{ props.name }}. Please refresh your page
              and try again.
            </p>
            <p v-else class="text-center mt-10">
              {{ props.description }}
            </p>
          </DialogDescription>

          <div v-if="displayError" class="flex justify-center">
            <BaseButton outlined :on-click="closeModal">
              Close
            </BaseButton>
          </div>
          <div v-else class="flex justify-center">
            <BaseButton
              outlined
              :on-click="closeModal"
              :disabled="props.disabledButtons"
            >
              Cancel
            </BaseButton>
            <BaseButton
              :disabled="props.disabledButtons"
              @click="duplicateRecord"
            >
              Yes
            </BaseButton>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogDescription,
  DialogTitle,
} from "@headlessui/vue";
import BaseButton from "@/components/shared/Button/BaseButton";
import WarningIcon60 from "@/components/svg-icons/WarningIcon60.vue";
import { DUPLICATE_MSG } from "@/constants.js";

const props = defineProps({
  title: {
    type: String,
    default: "Duplicate Housing",
  },
  name: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: DUPLICATE_MSG,
  },
  displayError: Boolean,
  disabledButtons: Boolean,
  modalState: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["closeModal", "duplicateRecord"]);

const closeModal = () => {
  emit("closeModal");
};

const duplicateRecord = () => {
  emit("duplicateRecord");
};
</script>
