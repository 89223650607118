<script>
export default {
  name: "ExpandableRows",
};
</script>

<script setup>
import { ref, toRefs } from "vue";
import { PencilIcon } from "@heroicons/vue/24/outline";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { FlexRender } from "@tanstack/vue-table";
import Spinner from "@/components/helpers/Spinner.vue";
import { COLUMNS_IDS } from "@/components/StudentFulfillment/components/OrderDetail/sections/Details/constants";

const props = defineProps({
  rows: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: "",
  },
  editEnabled: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const { rows, label, loading, editEnabled } = toRefs(props);

const emit = defineEmits(["expanded", "openStatusModal"]);

const open = ref(false);

const expand = () => {
  if (rows.value.length) {
    open.value = !open.value;
    emit("expanded", open.value);
  }
};
</script>

<template>
  <tr
    class="border-t-[1px] border-solid border-t-gray-200 w-full hover:bg-blue-350"
    :class="[open && rows.length ? 'bg-gray-200' : '']"
    @click="expand"
  >
    <th
      colspan="8"
      class="py-8 text-md border-t-[1px] border-solid border-t-gray-200"
    >
      <button
        :class="['text-md font-semibold flex items-center cursor-pointer']"
      >
        <ChevronRightIcon
          v-if="rows.length"
          :class="[
            {
              'rotate-90 transform': open,
            },
            'h-6 w-6 inline-block',
          ]"
        />
        <div
          :class="[!rows.length ? 'pl-4' : '']"
          class="flex justify-start items-center"
        >
          <span> {{ label }} ({{ rows?.length }})</span>
        </div>
      </button>
    </th>
    <th class="py-8 text-md border-t-[1px] border-solid border-t-gray-200">
      <button
        v-if="!rows.length"
        class="flex justify-center items-center ml-4 text-md font-bold bg-transparent text-teal-900"
        @click="emit('openStatusModal')"
        :disabled="!editEnabled"
      >
        <PencilIcon class="h-5" />
      </button>
    </th>
  </tr>

  <tr
    v-if="open && !loading && rows.length"
    v-for="row in rows"
    class="w-full hover:bg-blue-350"
  >
    <td
      v-for="cell in row.getVisibleCells()"
      :key="cell.column.id"
      class="py-6 text-md first:pl-4 capitalize border-t-[1px] border-solid border-t-gray-200"
      :class="[
        cell.column.id.trim() === COLUMNS_IDS.name ? 'text-teal-900' : '',
      ]"
    >
      <button
        v-if="cell.column.id.trim() === COLUMNS_IDS.action"
        class="flex justify-center items-center ml-4 text-md font-bold bg-transparent text-teal-900"
        @click="emit('openStatusModal', row.original.id)"
        :disabled="!editEnabled"
      >
        <PencilIcon class="h-5" />
      </button>

      <div
        class="flex flex-col"
        v-else-if="cell.column.id.trim() === COLUMNS_IDS.more_info"
      >
        <span
          v-for="item in Object.values(
            cell.row.original[COLUMNS_IDS.more_info]
          )"
          v-if="cell.row.original[COLUMNS_IDS.more_info]"
        >
          {{ item }}
        </span>
      </div>

      <FlexRender
        v-else-if="cell.column.id.trim() !== COLUMNS_IDS.type"
        :render="cell.column.columnDef.cell"
        :props="cell.getContext()"
      />
    </td>
  </tr>
  <tr v-if="open && loading && rows.length">
    <td colspan="9">
      <Spinner class="w-full py-2 relative" />
    </td>
  </tr>
</template>
