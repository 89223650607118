<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="#1E264C"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0711 17.2294L13.0002 15.4523L15.9293 17.2294L15.1523 13.8981L17.7502 11.6586L14.3315 11.371L13.0002 8.22939L11.669 11.371L8.25022 11.6586L10.8481 13.8981L10.0711 17.2294ZM13.0169 24.4751L9.60022 21.1252H4.87523V16.4002L1.46484 13.0002L4.87523 9.60022V4.87523H9.60022L13.0169 1.46484L16.4002 4.87523H21.1252V9.60022L24.5356 13.0002L21.1252 16.4002V21.1252H16.4002L13.0169 24.4751ZM13.0169 22.7419L15.8832 19.896H19.896V15.8915L22.7856 13.0002L19.8943 10.1089V6.10438H15.8898L13.0169 3.21481L10.1106 6.10438H6.10611V10.1089L3.21481 13.0002L6.10438 15.8915V19.896H10.094L13.0169 22.7419Z"
    />
  </svg>
</template>
