<template>
  <div class="flex items-center justify-between border-b border-gray-200">
    <div
      class="flex w-full js-tabs md:w-auto"
      role="region"
      aria-label="Student Categories"
    >
      <div v-for="(tab, index) in tabs" :key="tab.title">
        <tabButton
          v-if="!tab.hide"
          :tabindex="index"
          :title="tab.title"
          :count="tab.count"
          :selected="currentTabIndex == index"
          :tabactive="tab.active"
          :class="{ 'hidden sm:inline-block': index > 1 }"
        />
      </div>
      <div class="relative js-tab-more sm:hidden">
        <button
          class="flex items-center block px-2 py-2 font-normal border-t-4 border-transparent hover:bg-white focus:outline-none"
          data-toggle-indicator="flip"
          aria-controls="tab-menu"
          :aria-expanded="openMenu"
          @click="toggleResponsiveMenu"
        >
          <span class="mr-1">More</span>
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <ul
          v-if="openMenu"
          id="tab-menu"
          class="absolute right-0 z-10 w-40 bg-white shadow-md top-full"
        >
          <template v-for="(tab, index) in tabs" :key="tab.title">
            <tabMenu
              v-if="!tab.hide"
              :tabindex="index"
              :title="tab.title"
              :tabactive="tab.active"
            />
          </template>
        </ul>
      </div>
    </div>
    <div id="csvBtn"></div>
  </div>
</template>

<script>
import tabButton from "./TabButton";
import tabMenu from "./TabResponsiveButton";
import { mapGetters } from "vuex";

export default {
  components: {
    tabButton: tabButton,
    tabMenu: tabMenu,
  },
  data() {
    return {
      openMenu: null,
    };
  },
  computed: {
    ...mapGetters("university", {
      currentTabIndex: "currentTab",
      tabs: "tabNamesAndCounts",
    }),
  },
  methods: {
    toggleResponsiveMenu() {
      this.openMenu = !this.openMenu;
    },
  },
};
</script>
