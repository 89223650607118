export const internshipFilter = (filters) => {
  let advancedFilters = { ...filters };
  let outputFilters = {}
  Object.keys(advancedFilters).forEach((key) => {
    if (Array.isArray(advancedFilters[key])) {
      // Clean array values inside the advancedFilters object
      advancedFilters[key] = advancedFilters[key].filter((value) => value !== null);
    }

  });

  if (advancedFilters['company']) {
    outputFilters['company'] = advancedFilters.company.name;
  }

  if(advancedFilters['status']) {
    outputFilters['status'] = advancedFilters?.status?.name; 
  }

  if (advancedFilters['placement_type']) {
    outputFilters['placement_type'] = advancedFilters.placement_type
      .map((type) => type.value)
      .join(",");
  }
  if (advancedFilters['career_area']) {
    let careerValues = advancedFilters.career_area.map((type) => type.soc_title);
    outputFilters['career_area'] = careerValues.join(",");
  }
  if (advancedFilters['major']) {
    let majorValues = advancedFilters.major.map((type) => type.cip_title);
    outputFilters['major'] = majorValues.join(",");
  }
  if (advancedFilters['session']) {
    let sessionValues = advancedFilters.session.map((type) => type.label_desc);
    outputFilters['session'] = sessionValues.join(",");
  }
  if (advancedFilters['durations']) {
    outputFilters['durations'] = advancedFilters.durations.value;
  }
  return outputFilters;
};
