<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <spinner v-if="loading">
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications/landing',
              params: { applicationId: applicationId },
            }"
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            class="underline hover:no-underline"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
      <FormPanel
        :id="'Transcript-hard-copy'"
        :title="'Transcript'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <div id="transcript" class="mb-6 text-gray-600 space-y-6">
            <div class="mb-6 space-y-6">
              <p>
                All applicants are required to submit a transcript from each
                college or university attended. The transcript does not have to
                be the original sealed copy, but it must be an official copy
                (photocopies or web print-outs are not accepted). Students who
                apply two semesters in advance of their session abroad (e.g.
                applying in Spring 2020 for a Spring 2021 program) are advised
                that API cannot approve their application until grades from the
                current semester have been reported to API. The original
                transcript should be mailed to the API office using the
                following address:
              </p>

              <address>
                API<br />
                301 Camp Craft Road, Suite 200<br />
                Austin, TX 78746-6501
              </address>

              <CheckBox
                label="Check this off your to do list here once you send
    us your transcript."
                label-class="text-base"
                name="eTranscript"
                :checked="checked"
                :validation-errors="v$.value.checkedOption"
                @input="onFieldChange('checkedOption', $event)"
              />
              <ButtonWithSpinner
                ref="SubmitForm"
                type="submit"
                variant="primary"
                variant-type="block"
                :disabled="!allowSubmit"
                @click.prevent="submit()"
              >
                <span
                  >Submit
                  <svg
                    v-if="formSubmitted && !hasErrors"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="inline feather feather-check"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline></svg
                ></span>
              </ButtonWithSpinner>

              <p v-if="hasErrors" class="text-error-900">
                Please complete the required fields correctly.
              </p>
              <div v-if="formioErrors" class="text-error-900">
                <p v-for="error in formioErrors" :key="error">
                  {{ error }}
                </p>
              </div>
              <div v-if="missingAppId" class="text-error-900">
                <p>
                  No Application ID, please contact your API program manager to
                  help you with your application.
                </p>
              </div>
              <div v-if="formSubmitted && !hasErrors" class="text-success-900">
                <p>
                  Submission Complete, thank you!
                </p>
              </div>
            </div>
          </div>
        </template>
      </FormPanel>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms";
import FormPanel from "../SharedComponents/panel.vue";
import { mapState } from "vuex";
import formioHelpers from "../../../mixins/formioHelpers";
import Spinner from "../../helpers/Spinner.vue";
import CheckBox from "../SharedComponents/CheckBox.vue";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import { sameAs } from "@vuelidate/validators";
import formInputs from "../../../mixins/formInputs";
import formValidation from "../../../mixins/formValidation";
import formIoApi from "../../../mixins/formIoApi";
import useVuelidate from "@vuelidate/core";

export default {
  name: "PaperTranscript",
  components: { FormPanel, Spinner, CheckBox, ButtonWithSpinner },
  mixins: [forms, formInputs, formValidation, formioHelpers, formIoApi],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      applicationId: "",
      loading: true,
      message: "Getting Student applications",
      redirect: false,
      checked: false,
      formSubmitted: false,
      value: {
        checkedOption: false,
      },
    };
  },
  computed: {
    ...mapState(["formioToken", "program"]),
    allowSubmit() {
      return this.value.checkedOption;
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          application_id: this.applicationId,
          transcriptSubmitted: true,
        },
      };
      return body;
    },
    patchSubmissionDataForFormIo() {
      const body = [
        {
          op: "add",
          path: "/data/transcriptSubmitted",
          value: true,
        },
      ];
      return body;
    },
    formPath() {
      return "studentapplicationstage2step1";
    },
  },
  watch: {
    redirect: function (newVal) {
      if (newVal) {
        //redirect to my applications
        this.$router.push({
          name: "applications",
        });
      }
    },
  },
  async created() {
    //set the application ID
    this.applicationId = this.setApplicationId;
    await this.prefetchFormData();
    this.renderForm();
  },

  methods: {
    prefetchFormData() {
      this.formioSubmissionExists(this.formPath, this.applicationId).then(
        (formSubmissionId) => {
          if (formSubmissionId) {
            this.getFormioSubmission(this.formPath, formSubmissionId).then(
              (response) => {
                this.checked = response.transcriptSubmitted;
                this.loading = false;
              }
            );
          } else {
            this.loading = false;
          }
        }
      );
    },
    async submit() {
      this.submitted = false;
      //calling the startLoading method of the button, which enables the button to loading state
      this.$refs.SubmitForm.startLoading();
      try {
        await this.checkForApplicationId();
        if (this.allowSubmit) {
          if (this.submission_id) {
            this.submitToFormIo(
              this.formPath,
              this.patchSubmissionDataForFormIo,
              "PATCH",
              this.submission_id
            ).then((response) => {
              if (response) {
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          } else {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                this.submission_id = response;
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          }
        } else {
          this.$refs.SubmitForm.stopLoading();
        }
      } catch {
        this.$refs.SubmitForm.stopLoading();
      }
    },
    async renderForm() {
      //Make sure I user owns the application ID and if form is allowed in form rules
      if (
        !(await this.isValidApplication(this.applicationId, "Paper Transcript"))
      ) {
        this.redirect = true;
        return;
      }

      this.loading = false;
    },
  },
  validations: {
    value: {
      checkedOption: {
        sameAs: sameAs(true),
      },
    },
  },
};
</script>
