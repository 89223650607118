<script setup>
import { useVuelidate } from "@vuelidate/core";
import { ValidateEach } from "@vuelidate/components";
import PlusIcon from "@/components/shared/icons/PlusIcon.vue";
import { cloneDeep, debounce } from "lodash";
import {
  defineProps,
  reactive,
  computed,
  onBeforeMount,
  defineEmits,
} from "vue";
import { useVModel } from "@vueuse/core";
import AddressComponent from "../../../forms/AddressInputComponent/AddressComponent.vue";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import { useGetCityOptions } from "@/components/program-manager/sessions/composable";
import { getCityCountryOptionLabel } from "@/components/program-manager/sessions/utils";
import { helpers, requiredIf } from "@vuelidate/validators";
import { COMMUNICATION, MOBILITY, SENSORY } from "@/constants";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["update:modelValue"]);
const events = useVModel(props, "modelValue", emit);

const locations = computed(() => {
  //this just reads from the events object, not the store
  return events.value.event_locations;
});

const updateAddressInfo = (location, address) => {
  const { lat, long } = address;
  location.address = address;
  location.event_lat_lng = `${lat || ""}, ${long || ""}`;
};

const getInitialAddressInfo = computed(() => {
  return (location) => {
    return {
      street_address_1: location.address?.street_address_1 || undefined,
      street_address_2: location.address?.street_address_1 || undefined,
      city: location.address?.city || undefined,
      state: location.address?.state || undefined,
      iso: location.address?.iso || undefined,
      number: location.address?.number || undefined,
      country: location.address?.country || undefined,
      postal_code: location.address?.postal_code || undefined,
      utc_offset_minutes: location.address?.utc_offset_minutes || undefined,
    };
  };
});

const toggleMobility = (location, id) => {
  const mobility = location.mobility || [];
  if (mobility.includes(id)) {
    location.mobility = mobility.filter((i) => i !== id);
  } else {
    location.mobility = [...mobility, id];
  }
};
const toggleCommunication = (location, id) => {
  const communication = location.communication || [];
  if (communication.includes(id)) {
    location.communication = communication.filter((i) => i !== id);
  } else {
    location.communication = [...communication, id];
  }
};
const toggleSensory = (location, id) => {
  const sensory = location.sensory || [];
  if (sensory.includes(id)) {
    location.sensory = sensory.filter((i) => i !== id);
  } else {
    location.sensory = [...sensory, id];
  }
};

const deleteLocation = (index) => {
  events.value.event_locations.splice(index, 1);
};
const duplicateLocation = (index) => {
  const dup = reactive(cloneDeep(events.value.event_locations[index]));
  dup.id = undefined;
  events.value.event_locations.push(dup);
};
const addLocation = () => {
  events.value.event_locations.push({
    event_id: events?.value?.id || undefined,
    event_lat_lng: undefined,
    address: undefined,
    city: undefined,
    city_id: undefined,
    image: undefined,
    mobility: undefined,
    communication: undefined,
    sensory: undefined,
  });
};

const rules = {
  address: {},
  city: {
    required: helpers.withMessage(
      "Address is required to publish",
      requiredIf(function () {
        return events.value.status === "Active";
      })
    ),
  },
  mobility: {},
  communication: {},
  sensory: {},
};

const {
  execute: executeFetchCityOptions,
  state: cityOptions,
  isLoading: cityOptionsLoading,
  isReady: cityReady,
} = useGetCityOptions(
  { immediate: true, throwError: true },
  { city_ascii: "chi" }
);

const fetchCityOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    loading(true);
    try {
      await executeFetchCityOptions(0, {
        city_ascii: search,
        cancel: true,
      });
      loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        loading(false);
      }
    }
  }
}, 500);

let v$ = {};
onBeforeMount(() => {
  v$ = useVuelidate({}, events, {
    $registerAs: "ProgramLocationsSection",
    $lazy: true,
  });
});
</script>
<template>
  <ValidateEach
    v-for="(location, index) in locations"
    :key="index"
    :state="location"
    :rules="rules"
  >
    <template #default="{ v }">
      <div class="border-2 border-dashed border-gray-300 mb-4">
        <div class="flex p-4 bg-white items-center">
          <div class="font-semibold text-gray-700">
            Location {{ index + 1 }}
          </div>
          <div
            class="ml-auto uppercase text-xs font-semibold text-teal-900 mr-10"
            @click="() => duplicateLocation(index)"
          >
            duplicate
          </div>
          <div @click="() => deleteLocation(index)">
            <i class="fa fa-trash text-gray-650"></i>
          </div>
        </div>
        <div class="p-4">
          <div class="flex flex-col md:flex-row">
            <div class="flex-1">
              <label class="block text-sm">
                <span class="mt-8 block text-sm font-bold text-gray-700">
                  <span>Address</span>
                </span>
                <AddressComponent
                  :id="`address${index + 1}`"
                  :dont-be-null="true"
                  :class="'ring-gray-300 focus:ring-gray-300 rounded'"
                  template-name="v3"
                  :vuelidate-field="v.address"
                  placeholder="Start to enter name or address to find and select"
                  :initial-address-info="
                    location.address
                      ? getInitialAddressInfo(location)
                      : undefined
                  "
                  @update-address-info="
                    (address) => updateAddressInfo(location, address)
                  "
                />
              </label>
            </div>
          </div>
          <div class="flex flex-col md:flex-row">
            <div class="flex-1">
              <SearchableSelect
                v-model="location.city"
                class="col-span-2"
                :options="
                  location?.city
                    ? [...(cityOptions?.items || []), location?.city]
                    : cityOptions?.items || []
                "
                :vuelidate-instance="v.city"
                :filterable="false"
                placeholder="City, Country"
                label="city"
                :get-option-label="getCityCountryOptionLabel"
                :loading="cityOptionsLoading || !cityReady"
                @search="fetchCityOptions"
              >
                <template #fieldLabel>
                  <label class="common-label-text"
                    ><span class="text-red-100 pr-2">**</span>Location</label
                  >
                </template>
              </SearchableSelect>
            </div>
          </div>
          <div class="flex flex-col md:flex-row">
            <div class="flex-1">
              <div class="py-6">
                <div class="">
                  <h1 class="text-xl font-semibold leading-6 text-gray-900">
                    Accessibility
                  </h1>
                </div>
              </div>
              <div class="my-4">
                <span class="text-xs text-gray-500 font-semibold tracking-wide">
                  MOBILITY
                </span>
              </div>
              <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
                <div
                  v-for="{ id, name } in MOBILITY"
                  :key="id"
                  class="flex justify-between w-fit items-center mb-3"
                >
                  <div class="flex flex-1 items-center space-x-2">
                    <div class="flex items-center">
                      <input
                        :id="id"
                        type="checkbox"
                        :checked="location.mobility?.includes(id)"
                        class="h-5 w-5 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                        @input="() => toggleMobility(location, id)"
                      />
                    </div>
                    <div class="text-sm">
                      <label class="text-gray-700">
                        {{ name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-4">
                <span class="text-xs text-gray-500 font-semibold tracking-wide">
                  COMMUNICATION
                </span>
              </div>
              <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
                <div
                  v-for="{ id, name } in COMMUNICATION"
                  :key="id"
                  class="flex justify-between w-fit items-center mb-3"
                >
                  <div class="flex flex-1 items-center space-x-2">
                    <div class="flex items-center">
                      <input
                        :id="id"
                        type="checkbox"
                        :checked="location.communication?.includes(id)"
                        class="h-5 w-5 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                        @input="() => toggleCommunication(location, id)"
                      />
                    </div>
                    <div class="text-sm">
                      <label class="text-gray-700">
                        {{ name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-4">
                <span class="text-xs text-gray-500 font-semibold tracking-wide">
                  SENSORY
                </span>
              </div>
              <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
                <div
                  v-for="{ id, name } in SENSORY"
                  :key="id"
                  class="flex justify-between w-fit items-center mb-3"
                >
                  <div class="flex flex-1 items-center space-x-2">
                    <div class="flex items-center">
                      <input
                        :id="id"
                        type="checkbox"
                        :checked="location.sensory?.includes(id)"
                        class="h-5 w-5 form-checkbox bg-white rounded border-gray-300 text-blue-400"
                        @input="() => toggleSensory(location, id)"
                      />
                    </div>
                    <div class="text-sm">
                      <label class="text-gray-700">
                        {{ name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ValidateEach>
  <div
    class="mt-4 bg-white border-2 border-dashed border-gray-300 rounded cursor-pointer"
    @click="addLocation"
  >
    <div class="flex items-center m-5 p-1">
      <PlusIcon class="mr-2 text-teal-900 w-10 h-10" />
      <label class="common-label-text inline">Add another location</label>
    </div>
  </div>
</template>
