function isJSON(str) {
  if (typeof str !== "string") {
    return false;
  }
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}
export function getRidOfJSON(jsonObject) {
  if (typeof jsonObject === "string") {
    while (isJSON(jsonObject)) {
      if (jsonObject === null) {
        return jsonObject;
      }

      jsonObject = JSON.parse(jsonObject);
    }
  }
  return jsonObject;
}
