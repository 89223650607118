import { UMASS_OPE8, UMASS_PRICING } from "@/umassConstants.js";
import {
  SALVE_REGINA_OPE8,
  SALVE_REGINA_PRICING,
} from "@/salveReginaConstants.js";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("university", ["userDetails"]),
    isUmassUniversity() {
      return UMASS_OPE8.includes(this.userDetails?.universityId);
    },
    isSalveReginaUniversity() {
      return SALVE_REGINA_OPE8.includes(this.userDetails?.universityId);
    },
  },
  methods: {
    getPrice(session, price) {
      let newPrice = price;
      if (this.isUmassUniversity) {
        let customPrice = UMASS_PRICING.find(
          (item) => item.session === session
        );
        newPrice = customPrice ? customPrice.price : price;
      }
      if (this.isSalveReginaUniversity) {
        let salveReginaPrice = SALVE_REGINA_PRICING.find(
          (item) => item.session === session
        );
        newPrice = salveReginaPrice ? salveReginaPrice.program_fee : price;
      }
      return newPrice.toLocaleString("en-US");
    },

    getHousingFee(session, price) {
      let newPrice = price;
      if (this.isUmassUniversity) {
        let customPrice = UMASS_PRICING.find(
          (item) => item.session === session
        );
        newPrice = customPrice ? customPrice.housing_fee : price;
      }
      if (this.isSalveReginaUniversity) {
        let salveReginaHousingPrice = SALVE_REGINA_PRICING.find(
          (item) => item.session === session
        );
        newPrice = salveReginaHousingPrice
          ? salveReginaHousingPrice.housing_fee
          : price;
      }

      return newPrice.toLocaleString("en-US");
    },

    getEducationalFee(session, price) {
      let newPrice = price;
      if (this.isUmassUniversity) {
        let customPrice = UMASS_PRICING.find(
          (item) => item.session === session
        );
        newPrice = customPrice ? customPrice.educational_fees : price;
      }
      if (this.isSalveReginaUniversity) {
        let salveReginaEducationalFeePrice = SALVE_REGINA_PRICING.find(
          (item) => item.session === session
        );
        newPrice = salveReginaEducationalFeePrice
          ? salveReginaEducationalFeePrice.educational_fees
          : price;
      }
      return newPrice.toLocaleString("en-US");
    },

    getMedLifeFee(session, price) {
      let newPrice = price;

      if (this.isSalveReginaUniversity) {
        let salveReginaMedLifeFeePrice = SALVE_REGINA_PRICING.find(
          (item) => item.session === session
        );
        newPrice = salveReginaMedLifeFeePrice
          ? salveReginaMedLifeFeePrice.med_life_fee
          : price;
      }
      return newPrice.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  },
};
