<template>
  <div>
    <label :for="props.id" class="inline-flex">
      <input
        :id="props.id"
        type="checkbox"
        :checked="props.modelValue"
        :data-cy="props.id + 'Checkbox'"
        class="min-w-[20px] min-h-[20px] border-gray-300 text-indigo-base focus:ring-indigo-base"
        :class="[primaryColorClass, `${secondaryColorClassAccent}`]"
        :disabled="props.disabled"
        @change="onChange($event.target.checked)"
      />
      <span class="ml-4">
        {{ props.label }}
        <slot name="label"></slot>
      </span>
    </label>
    <p
      v-for="error of props.vuelidateField?.$errors"
      :key="error.$uid"
      class="error text-error-900 text-sm"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<script setup>
import _ from "lodash";
import { defineProps, defineEmits } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  vuelidateField: {
    type: Object,
    default: () => {},
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  vuelidateParent: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const onChange = (value) => {
  emit("update:modelValue", value);
  if (!_.isEmpty(props.vuelidateParent)) props.vuelidateParent?.$touch();
};
</script>
