<script setup>
import {
  defineProps,
  toRefs,
  defineEmits,
  useAttrs,
  computed,
  watch,
  ref,
} from "vue";
import useVuelidate from "@vuelidate/core";
import { uuid } from "vue-uuid";
import {
  numeric,
  helpers,
  requiredIf,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { parse, isAfter, isEqual } from "date-fns";
import BasicTextInput from "@/components/shared//input/BasicTextInput.vue";
import CalendarInput from "@/components/shared/CalendarInput.vue";

// Events
const emit = defineEmits(["update:modelValue", "removePrice"]);

// Props
const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  requiredToPublish: {
    type: Boolean,
    default: true,
  },
  errorMessage: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

// Variables
const attrs = useAttrs();
const { modelValue: price, index, errorMessage, disabled } = toRefs(props);

const parseDateFormat = "yyyy-MM-dd";
const flatPickerConfig = {
  dateFormat: "Y-m-d",
  altInput: true,
  altFormat: "m/d/Y",
};

// Validations
const checkStartEndDates = (
  value,
  {
    effective_start_date: effectiveStartDate,
    effective_end_date: effectiveEndDate,
  } = {}
) => {
  if (!effectiveStartDate && !effectiveEndDate) {
    return true;
  } else if (!effectiveStartDate && effectiveEndDate) {
    return false;
  }
  return (
    !isAfter(
      parse(effectiveStartDate, parseDateFormat, new Date()),
      parse(effectiveEndDate, parseDateFormat, new Date())
    ) &&
    !isEqual(
      parse(effectiveStartDate, parseDateFormat, new Date()),
      parse(effectiveEndDate, parseDateFormat, new Date())
    )
  );
};

const rules = computed(() => ({
  price_in_cents: {
    requiredToPublish: helpers.withMessage(
      "This field is required to Publish",
      requiredIf(() => props.requiredToPublish)
    ),

    numeric,
    minValue: minValue(0),
    maxValue: maxValue(9999.99),
  },
  effective_start_date: {
    requiredToPublish: helpers.withMessage(
      "This field is required to Publish",
      requiredIf(() => props.requiredToPublish)
    ),
    checkStartEndDates: helpers.withMessage(
      "Start date should be lower than End date",
      checkStartEndDates
    ),
  },
  effective_end_date: {
    checkStartEndDates: helpers.withMessage(
      "End date should be greater than Start date",
      checkStartEndDates
    ),
  },
}));

const v$ = useVuelidate(rules, price, {
  $registerAs: `PriceItem-${uuid.v4()}`,
  $lazy: true,
  $autoDirty: true,
});

// Variables
const idEffectiveStartDate = ref(`startDate-${uuid.v4()}`);
const idEffectiveEndDate = ref(`endDate-${uuid.v4()}`);

// Computed
const getEffectiveStartDateFlatPickr = computed(
  () =>
    document.querySelector(`#${idEffectiveStartDate.value}`)._flatpickr.altInput
      .classList
);

const getEffectiveEndDateFlatPickr = computed(
  () =>
    document.querySelector(`#${idEffectiveEndDate.value}`)._flatpickr.altInput
      .classList
);

// Watch
watch(v$, (vRef) => {
  const effectiveStartDateClass = getEffectiveStartDateFlatPickr.value;
  effectiveStartDateClass.remove("bg-error-100");
  if (vRef.effective_start_date?.$errors?.length) {
    effectiveStartDateClass.add("bg-error-100");
  }

  const effectiveEndDateClass = getEffectiveEndDateFlatPickr.value;
  effectiveEndDateClass.remove("bg-error-100");
  if (vRef.effective_end_date?.$errors?.length) {
    effectiveEndDateClass.add("bg-error-100");
  }
});
</script>

<template>
  <div class="mt-1" :class="attrs.class">
    <div class="grid md:grid-cols-3 mt-2 md:space-x-8">
      <div class="flex-1">
        <label class="block text-sm font-semibold text-gray-700" for="fname"
          ><span class="text-red-100 pr-2 align-sub">**</span> Price
        </label>
        <div class="flex items-center">
          <span class="text-sm w-24">$ (USD)</span>
          <BasicTextInput
            type="number"
            step=".01"
            autocomplete="off"
            :value="price.price_in_cents"
            :disabled="disabled"
            :readonly="disabled"
            :input-classes="{
              'h-10 mt-1 py-2 pl-3 w-full sm:text-sm': true,
              'form-input': !disabled,
            }"
            :outer-classes="`w-full`"
            :class="{
              'bg-error-100': v$.price_in_cents?.$errors?.length,
            }"
            @wheel.prevent
            @input="
              (evt) =>
                emit('update:modelValue', { price_in_cents: evt.target.value })
            "
          ></BasicTextInput>
          <span class="text-sm pl-2"
            ><slot name="postfixInputLabel">/day</slot></span
          >
        </div>
        <div
          v-if="v$.price_in_cents?.$errors.length"
          class="block text-sm error text-error-900"
        >
          <span v-for="error in v$.price_in_cents?.$errors" :key="error">
            {{ error.$message }}<br />
          </span>
        </div>
      </div>

      <div class="flex-1">
        <CalendarInput
          :id="idEffectiveStartDate"
          name="date"
          :model-value="price.effective_start_date"
          :disabled="disabled"
          :readonly="disabled"
          :config="flatPickerConfig"
          :input-classes="{
            'h-10 w-full sm:text-sm': disabled,
            'h-10 w-full rounded-md border-gray-300 border shadow-sm sm:text-sm pl-2 custom-date-picker': !disabled,
          }"
          :outer-classes="`mt-1 w-full flex-1`"
          :vuelidate-instance="v$.effective_start_date"
          @input="
            (evt) =>
              emit('update:modelValue', {
                effective_start_date: evt.target.value,
              })
          "
        >
          <template #fieldLabel
            ><label
              for="start"
              class="block text-sm font-semibold text-gray-700"
              ><span class="text-red-100 pr-2 align-sub">**</span>Start
              Date</label
            ></template
          >
        </CalendarInput>
      </div>

      <div class="flex-1">
        <div
          class="flex flex-grow w-full"
          :class="{ 'pr-10': index === 0 && !disabled }"
        >
          <CalendarInput
            :id="idEffectiveEndDate"
            name="date"
            :model-value="price.effective_end_date"
            :disabled="disabled"
            :readonly="disabled"
            :config="flatPickerConfig"
            :input-classes="{
              'h-10 w-full sm:text-sm': disabled,
              'h-10 w-full rounded-md border-gray-300 border shadow-sm sm:text-sm pl-2 custom-date-picker': !disabled,
            }"
            :outer-classes="`mt-1 w-full flex-1`"
            :vuelidate-instance="v$.effective_end_date"
            @input="
              (evt) =>
                emit('update:modelValue', {
                  effective_end_date: evt.target.value,
                })
            "
            ><template #fieldLabel
              ><label
                for="end"
                class="block text-sm font-semibold text-gray-700 mt-1"
                >End Date</label
              ></template
            >
            <template #extraField
              ><div
                v-if="index > 0 && !disabled"
                class="flex items-center justify-center"
              >
                <div
                  class="w-5 h-5 cursor-pointer mx-3 my-2"
                  @click="emit('removePrice', index)"
                >
                  <i class="fa fa-trash text-gray-650"></i>
                </div></div></template
          ></CalendarInput>
        </div>
      </div>
    </div>
    <div v-if="errorMessage" class="flex-1 block text-sm error text-error-900">
      <span v-for="error in errorMessage" :key="error">
        {{ error.$message }}<br />
      </span>
    </div>
  </div>
</template>
