<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';

export default {
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    },
  },
  computed: {
    searchIsLoading() {
      return this?.state?.instantSearchInstance?.status === "loading" ||
        this?.state?.instantSearchInstance?.status === "stalled"
    },
    searchIsError() {
      return this?.state?.instantSearchInstance?.status === "error";
    }
  }
};
</script>

<script setup>
import NoResultsPage from "@/components/NoResultsPage.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import InternshipCard from "../ResultCards/InternshipCard";
import { useRouter } from "vue-router";
import { vElementVisibility } from '@vueuse/components';

const router = useRouter();
const redirectToViewPage = (program) => {
  const redirectUrl = getRedirectUrl(program);
  router.push(redirectUrl);
};

const handleApplyEvent = ({program_page: programPage, session, internship} = {}) => {
  router.push({
    name: "configurator-new",
    params: { programPageId: programPage?.id },
    query: {
      session: session?.id,
      internships: [internship?.id],
    },
  });
};
</script>

<template>
  <div v-if="state">
    <div v-if="searchIsLoading && !state.hits.length > 0">
      <Spinner class="relative h-50-screen" />
    </div>
    <div v-if="state.hits.length > 0">
      <div
        class="justify-items-center grid grid-cols-1 1/2lg:grid-cols-2 1/2xl:grid-cols-3 2xl:grid-cols-4 gap-x-10 gap-y-12 lg:px-10 pb-18"
      >
        <InternshipCard
          v-for="hit in state.hits"
          :key="hit.objectID"
          class="internship-program-card flex flex-col max-w-[440px] min-w-[320px]"
          :hit="hit"
          @apply-clicked="handleApplyEvent(hit)"
          @view-clicked="redirectToViewPage(hit)"
        />
        <div v-element-visibility="[visibilityChanged]" />
      </div>
    </div>
    <Spinner v-if="searchIsLoading && state.hits.length > 0" outer-classes="" />
    <div v-else-if="!searchIsLoading && !state.hits.length > 0">
      <NoResultsPage margin-top="mt-5" />
    </div>
  </div>
</template>

<style scoped>
</style>
