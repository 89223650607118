import { getApiClient } from "./API";
import { APPLICATION_FEE_DATA } from "@/constants";
import { getDollarPriceFromCents as formattedPrice } from "@/util/formatter";
import { paymentServiceRequest } from "./utils";

const paymentService = {
  /**
   * @param applicationId optional to search for the value paid there
   * @returns the value paid or the default
   */
  async getApplicationFeeAmount(applicationId = null) {
    if (applicationId) {
      const { data } = await getApiClient().post("/get-all-payments", {
        application_id: applicationId,
      });
      const stripeItemForApplicationFee = data?.stripe_items?.find(
        (invoice) => invoice.metadata.is_application_fee === "true"
      );
      // if it has promo code
      if (stripeItemForApplicationFee?.metadata?.promo_code?.length > 0)
        return 0;

      if (stripeItemForApplicationFee?.amount_due)
        return formattedPrice(stripeItemForApplicationFee?.amount_due);

      return undefined;
    } else {
      return APPLICATION_FEE_DATA.amount;
    }
  },
  async submitGeneralEvent(payload) {
    return await getApiClient().post("/submit-to-paymentservice", payload);
  },
  async getInvoices({ order_id, application_id }) {
    return await paymentServiceRequest({
      method: "post",
      url: "/invoice/search",
      failureMessage: "Error loading program session data",
      payload: { order_id, application_id },
    });
  },
};

export default paymentService;
