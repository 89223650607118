<script setup>
import { computed, toRefs, ref, onMounted } from "vue";
import { useVModel, watchOnce } from "@vueuse/core";
import Checkbox from "@/components/forms/SharedComponents/DarkBlueInputs/Checkbox.vue";
import FeedbackIcon from "@/components/svg-icons/FeedbackIcon";
import {
  useGetRequiredMajorOptions,
  useGetSingleProfile,
} from "@/components/program-manager/sessions/composable";
import { formatDate, revertCamelCase } from "@/mixins/helpers.js";
import { isAuthorized } from "@/composables/authorization";
import { PERMISSIONS } from "@/constants";
import { generateStudentName } from "@/components/StudentFulfillment/components/MainPage/utils";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  modelValue: {
    type: Object,
    required: true,
  },
  orderLoading: {
    type: Boolean,
    required: true,
  },
});
const { data } = toRefs(props);
const emit = defineEmits(["update:modelValue"]);
const updateData = useVModel(props, "modelValue", emit);

const canUpdateAdvisingFlags = computed(() => {
  return isAuthorized(PERMISSIONS.USERS_ADVISE);
});
const canUpdateFinanciallyClear = computed(() => {
  return isAuthorized(PERMISSIONS.ORDERS_WRITE);
});

const {
  isLoading: profileLoading,
  execute: fecthProfileData,
  state: profileData,
} = useGetSingleProfile(data.value?.order?.participant_id, false);

const {
  isLoading: majorsLoading,
  execute: fecthMajors,
  state: majorsData,
} = useGetRequiredMajorOptions(false);

const isLoading = computed(
  () => profileLoading.value || majorsLoading.value || props.orderLoading
);

const majors = computed(() =>
  majorsData.value?.map(({ name }) => name)?.join(", ")
);

const YearInSchool = ref("");

watchOnce(
  profileData,
  (value) => {
    updateData.value.needs_advising = !!value?.data?.needs_advising;
    updateData.value.requires_accommodations = !!value?.data
      ?.requires_accommodations;
    let contentText = value?.data?.year_in_school_at_time_of_api_program;
    YearInSchool.value = contentText ? revertCamelCase(contentText) : "";
    if (value?.data?.majors?.length)
      fecthMajors(0, {
        extraParams: {
          cip_codes: value?.data?.majors?.map(({ cip_code }) => cip_code),
        },
      });
  },
  { deep: true }
);

onMounted(() => {
  updateData.value.financially_cleared = !!data.value?.order
    ?.financially_cleared;
  if (data.value?.order?.participant_id)
    fecthProfileData(0, { id: data.value.order.participant_id });
});
</script>

<script>
export default {
  name: "StudentInfo",
};
</script>

<template>
  <div class="sm:grid sm:grid-cols-3 space-y-4">
    <div>
      <p
        id="student-name-label"
        dataTestId="student-name-label"
        class="mb-1 font-bold"
      >
        Name
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <div v-else class="flex">
        <p id="student-name-data" dataTestId="student-name-data">
          {{ generateStudentName(data?.order) }}
        </p>
        <FeedbackIcon
          :class="[
            'relative bottom-1 ml-2 h-9 w-9',
            updateData.requires_accommodations
              ? 'fill-current text-error-900'
              : 'fill-current text-error-900 opacity-25',
          ]"
        />
      </div>
    </div>
    <div>
      <p
        id="student-name-label"
        dataTestId="student-name-label"
        class="mb-1 font-bold"
      >
        Pronouns
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p
        v-else
        class="capitalize"
        id="student-pronouns-data"
        dataTestId="student-pronouns-data"
      >
        {{ profileData?.data?.pronouns }}
      </p>
    </div>
    <div>
      <p
        id="student-email-label"
        dataTestId="student-email-label"
        class="mb-1 font-bold"
      >
        Email
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p v-else id="student-email-data" dataTestId="student-email-data">
        {{ profileData?.data?.email }}
      </p>
    </div>
    <div>
      <p
        id="student-phone-number-label"
        dataTestId="student-phone-number-label"
        class="mb-1 font-bold"
      >
        Phone #
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p
        v-else
        id="student-phone-number-data"
        dataTestId="student-phone-number-data"
      >
        {{ profileData?.data?.phone_number }}
      </p>
    </div>
    <div>
      <p
        id="birthdate-label"
        dataTestId="birthdate-label"
        class="mb-1 font-bold"
      >
        Birthday
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p v-else id="birthdate-data" dataTestId="birthdate-data">
        {{ formatDate(profileData?.data?.birthdate) }}
      </p>
    </div>
    <div class="col-start-1">
      <p
        id="home-institution-label"
        dataTestId="home-institution-label"
        class="mb-1 font-bold"
      >
        Home University
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p v-else id="home-institution-data" dataTestId="home-institution-data">
        {{ props.data?.order?.home_institution_name }}
      </p>
    </div>
    <div>
      <p
        id="student-major-label"
        dataTestId="student-major-label"
        class="mb-1 font-bold"
      >
        Major
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p v-else id="student-major-data" dataTestId="student-major-data">
        {{ majors }}
      </p>
    </div>
    <div>
      <p
        id="year-in-school-label"
        dataTestId="year-in-school-label"
        class="mb-1 font-bold"
      >
        Year In School
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p v-else id="year-in-school-data" dataTestId="year-in-school-data">
        {{ YearInSchool }}
      </p>
    </div>
    <div>
      <p
        id="application-id-label"
        dataTestId="application-id-label"
        class="mb-1 font-bold"
      >
        Application ID
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p v-else id="application-id-data" dataTestId="application-id-data">
        {{ props.data?.order?.application_id }}
      </p>
    </div>
    <div>
      <p
        id="application-version-label"
        dataTestId="application-version-label"
        class="mb-1 font-bold"
      >
        Application Version
      </p>
      <div
        v-if="isLoading"
        id="loading-component"
        dataTestId="loading-component"
        class="flex animate-pulse items-center"
      >
        <div class="h-4 w-3/6 bg-blue-400"></div>
      </div>
      <p
        v-else
        id="application-version-data"
        dataTestId="application-version-data"
        class="uppercase"
      >
        {{ props.data?.order?.application_version }}
      </p>
    </div>
    <div class="col-start-1">
      <p
        id="financially-cleared-label"
        dataTestId="financially-cleared-label"
        class="mb-1 font-bold"
      >
        Financially Clear
      </p>
      <Checkbox
        :is-disabled="!canUpdateFinanciallyClear"
        id="financially_cleared"
        v-model="updateData.financially_cleared"
      />
    </div>
    <div class="col-start-2">
      <p
        id="needs-advising-label"
        dataTestId="needs-advising-label"
        class="mb-1 font-bold"
      >
        Needs Advising
      </p>
      <Checkbox
        :is-disabled="!canUpdateAdvisingFlags"
        id="needs_advising"
        v-model="updateData.needs_advising"
      />
    </div>
    <div class="col-start-3">
      <p
        id="requires-accommodations-label"
        dataTestId="requires-accommodationsg-label"
        class="mb-1 font-bold"
      >
        Requires Accommodations
      </p>
      <Checkbox
        :is-disabled="!canUpdateAdvisingFlags"
        id="requires_accommodations"
        v-model="updateData.requires_accommodations"
      />
    </div>
  </div>
</template>
