<script setup>
import { defineProps, toRefs } from "vue";
import HousingCard from "@/components/ExperiencePage/ProgramDetailsSection/housing/components/HousingCard";

defineOptions({
  name: "HousingCards"
})

const props = defineProps({
  housings: {
    type: Object,
    required: true,
  },
});

const { housings } = toRefs(props);
</script>

<template>
  <div>
    <div
      v-if="housings?.length"
      class="mt-5 grid lg:grid-cols-3 grid-flow-row gap-8"
    >
      <div
        v-for="(housing, idx) in housings || []"
        :key="housing?.id || idx"
        class="w-full flex"
      >
        <div
          class="bg-white font-montserrat grid leading-5 max-w-351p md:blur-20 md:offset-x-0 md:offset-y-4 md:spread--2 md:text-opacity-10 min-h-[472px] not-italic overflow-hidden shadow-gray-500 shadow-xl vertical-card w-full"
        >
          <HousingCard :housing-data="housing"></HousingCard>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.grid > div:nth-child(3n-1) {
  -webkit-box-pack: center;
  justify-content: center;
}
.grid > div:nth-child(3n) {
  -webkit-box-pack: end;
  justify-content: flex-end;
}
</style>
