<template>
  <div>
    <div
      v-for="(unit, index) in units"
      :key="index"
      class="mt-4 border-2 border-dashed border-gray-300 rounded"
    >
      <div
        class="flex text-[13px] font-bold justify-between p-4 border-b-gray-300 bg-white"
      >
        <h2>UNIT {{ index + 1 }}</h2>
        <div class="flex space-x-8">
          <button
            class="font-bold text-teal-900"
            @click.stop="duplicateUnit(index)"
          >
            DUPLICATE
          </button>
          <button
            :disabled="!hasPublishPermission"
            @click.stop="deleteUnit(index)"
          >
            <img class="w-5 h-5" loading="lazy" :src="garbageIcon" />
          </button>
        </div>
      </div>
      <div class="p-3">
        <div class="grid md:grid-cols-3 mt-2 md:space-x-20">
          <div>
            <label
              :for="`unit.status${index + 1}`"
              class="block text-sm md:max-w-[348px]"
            >
              <span class="mt-8 block text-sm font-semibold text-gray-700"
                ><span class="text-red-100 pr-2 align-sub">*</span>Status</span
              >
              <v-select
                :id="`unit.status.${index + 1}`"
                :model-value="unit.status"
                :options="statuses"
                :placeholder="placeholder"
                class="mt-1"
                :class="{
                  'forced-error-background':
                    getValidatorResponseData(v$, index)?.status.length &&
                    unit.is_not_valid,
                }"
                @update:modelValue="updateStatus($event, index)"
              ></v-select>
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.status.length &&
                  unit.is_not_valid
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)?.status"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
          <div class="md:items-center flex">
            <label
              :for="`unit.name.${index + 1}`"
              class="block text-sm flex-1 md:max-w-[348px]"
            >
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span
                  ><span class="text-red-100 pr-2 align-sub">*</span>Unit
                  Name</span
                >
              </span>
              <input
                :id="`unit.name.${index + 1}`"
                :value="unit.name"
                type="text"
                class="h-10 mt-1 w-full form-input md:max-w-[348px] sm:text-sm"
                :class="{
                  'bg-error-100':
                    getValidatorResponseData(v$, index)?.name.length &&
                    unit.is_not_valid,
                }"
                @input="updateStateField($event, 'name', index)"
              />
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.name.length &&
                  unit.is_not_valid
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)?.name"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
          <div class="flex justify-end">
            <label
              :for="`unit.floor_plan.${index + 1}`"
              class="block text-sm flex-1 md:max-w-[348px]"
            >
              <span class="mt-8 block text-sm font-semibold text-gray-700"
                ><span class="text-red-100 pr-2 align-sub">*</span>Unit Floor
                Plan</span
              >
              <v-select
                :id="`unit.floor_plan.${index + 1}`"
                :model-value="unit.floor_plan"
                :options="floor_plans"
                :placeholder="placeholder"
                class="mt-1 md:max-w-[348px]"
                :class="{
                  'forced-error-background':
                    getValidatorResponseData(v$, index)?.floor_plan?.length &&
                    unit.is_not_valid,
                }"
                @update:modelValue="
                  updateStateField($event, 'floor_plan', index)
                "
              ></v-select>
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.floor_plan?.length &&
                  unit.is_not_valid
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)
                    ?.floor_plan"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
        </div>
        <div
          v-if="hideFieldIfLocalHostOrHouseOrGenericListing"
          class="grid md:grid-cols-3 mt-2 md:space-x-20"
        >
          <div>
            <label
              :for="`unit.quantity.${index + 1}`"
              class="block text-sm md:max-w-[348px]"
            >
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span
                  ><span class="text-red-100 pr-2 align-sub">**</span
                  >Quantity</span
                >
              </span>
              <input
                :id="`unit.quantity.${index + 1}`"
                :value="unit.quantity"
                type="number"
                min="0"
                step="1"
                class="h-10 mt-1 w-full form-input sm:text-sm"
                :class="{
                  'bg-error-100':
                    getValidatorResponseData(v$, index)?.quantity?.length &&
                    unit.is_not_valid,
                }"
                @input="updateStateField($event, 'quantity', index)"
              />
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.quantity?.length &&
                  unit.is_not_valid
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)?.quantity"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
          <div class="md:items-center flex">
            <label class="block text-sm flex-1 md:max-w-[348px]">
              <div class="block text-sm lg:w-full">
                <span class="mt-8 block text-sm font-semibold text-gray-700">
                  <span>Total Capacity</span>
                </span>
                <input
                  type="number"
                  disabled
                  readonly
                  class="h-10 mt-1 bg-transparent w-full sm:text-sm"
                  :value="calculateTotalCapacity(unit)"
                />
              </div>
            </label>
          </div>
          <div class="flex justify-end">
            <label
              :for="`unit.sqm.${index + 1}`"
              class="block text-sm flex-1 md:max-w-[348px]"
            >
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span>Total SQM</span>
              </span>
              <input
                :id="`unit.sqm.${index + 1}`"
                :value="unit.sqm"
                type="number"
                min="0"
                step="1"
                class="h-10 mt-1 w-full form-input sm:text-sm"
                :class="{
                  'bg-error-100': getValidatorResponseData(v$, index)?.sqm
                    .length,
                }"
                @input="updateStateField($event, 'sqm', index)"
              />
              <div
                v-if="getValidatorResponseData(v$, index)?.sqm.length"
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)?.sqm"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
        </div>
        <div class="grid md:grid-cols-3 mt-2 md:space-x-20">
          <div>
            <label
              :for="`unit.full_bath_count.${index + 1}`"
              class="block text-sm md:max-w-[348px]"
            >
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span
                  v-if="!getGenericListing"
                  class="text-red-100 pr-2 align-sub"
                  >**</span
                >
                <span>Number of full baths</span>
              </span>
              <input
                :id="`unit.full_bath_count.${index + 1}`"
                :value="unit.full_bath_count"
                type="number"
                min="0"
                step="1"
                class="h-10 mt-1 w-full form-input sm:text-sm"
                :class="{
                  'bg-error-100':
                    getValidatorResponseData(v$, index)?.full_bath_count
                      .length && shouldValidateField(unit),
                }"
                @input="updateStateField($event, 'full_bath_count', index)"
              />
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.full_bath_count.length &&
                  shouldValidateField(unit)
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)
                    ?.full_bath_count"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
          <div class="md:items-center flex">
            <label
              :for="`unit.half_bath_count.${index + 1}`"
              class="block text-sm flex-1 md:max-w-[348px]"
            >
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span
                  v-if="!getGenericListing"
                  class="text-red-100 pr-2 align-sub"
                  >**</span
                >
                <span>Number of half baths</span>
              </span>
              <input
                :id="`unit.half_bath_count.${index + 1}`"
                :value="unit.half_bath_count"
                type="number"
                min="0"
                step="1"
                class="h-10 mt-1 w-full form-input sm:text-sm"
                :class="{
                  'bg-error-100':
                    getValidatorResponseData(v$, index)?.half_bath_count
                      .length && shouldValidateField(unit),
                }"
                @input="updateStateField($event, 'half_bath_count', index)"
              />
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.half_bath_count.length &&
                  shouldValidateField(unit)
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)
                    ?.half_bath_count"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
          <div class="flex justify-end">
            <label class="block text-sm flex-1 md:max-w-[348px]">
              <span class="mt-8 block text-sm font-semibold text-gray-700"
                ><span
                  v-if="!getGenericListing"
                  class="text-red-100 pr-2 align-sub"
                  >**</span
                >Kitchen Type</span
              >
              <v-select
                :id="`unit.kitchen_type.${index + 1}`"
                :model-value="unit.kitchen_type"
                :options="kitchen_types"
                :placeholder="placeholder"
                class="mt-1"
                :class="{
                  'forced-error-background':
                    getValidatorResponseData(v$, index)?.kitchen_type.length &&
                    shouldValidateField(unit),
                }"
                @update:modelValue="
                  updateStateField($event, 'kitchen_type', index)
                "
              ></v-select>
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.kitchen_type.length &&
                  shouldValidateField(unit)
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)
                    ?.kitchen_type"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
        </div>
        <div class="grid md:grid-cols-3 mt-2 md:space-x-20">
          <div>
            <label class="block text-sm md:max-w-[348px]">
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span
                  v-if="!getGenericListing"
                  class="text-red-100 pr-2 align-sub"
                  >**</span
                >
                <span>Number of water closets</span>
              </span>
              <input
                :id="`unit.water_closet_count.${index + 1}`"
                :value="unit.water_closet_count"
                type="number"
                min="0"
                step="1"
                class="h-10 mt-1 w-full form-input sm:text-sm"
                :class="{
                  'bg-error-100':
                    getValidatorResponseData(v$, index)?.water_closet_count
                      .length && shouldValidateField(unit),
                }"
                @input="updateStateField($event, 'water_closet_count', index)"
              />
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.water_closet_count
                    .length && shouldValidateField(unit)
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)
                    ?.water_closet_count"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
          <div class="flex flex-col md:col-span-2 md:items-center">
            <label class="block text-sm flex-1 w-full">
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span>Product Line</span>
              </span>
              <input
                :id="`unit.water_closet_count.${index + 1}`"
                :value="unit.product_line_id"
                type="text"
                class="h-10 mt-1 w-full form-input sm:text-sm"
                :class="{
                  'bg-error-100':
                    getValidatorResponseData(v$, index)?.product_line_id
                      ?.length && shouldValidateField(unit),
                }"
                @input="updateStateField($event, 'product_line_id', index)"
              />
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.product_line_id
                    ?.length && shouldValidateField(unit)
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)
                    ?.product_line_id"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
        </div>
        <div class="flex flex-col md:flex-row">
          <div class="flex-1">
            <label class="block text-sm">
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span
                  ><span class="text-red-100 pr-2 align-sub">**</span
                  >Description</span
                >
              </span>
              <div>
                <textarea
                  :id="`unit.description.${index + 1}`"
                  :value="unit.description"
                  rows="12"
                  class="block w-full form-input sm:text-sm"
                  :class="{
                    'bg-error-100':
                      getValidatorResponseData(v$, index)?.description
                        ?.length && unit.is_not_valid,
                  }"
                  @input="updateStateField($event, 'description', index)"
                />
                <span class="grid grid-cols-2">
                  <span
                    class="col-start-2 text-sm text-right font-semibold text-gray-600"
                    :class="{
                      'success text-success-900':
                        unit.description?.length === maxLength,
                    }"
                    >{{ unit.description?.length || 0 }}/{{ maxLength }}</span
                  >
                </span>
              </div>
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.description?.length &&
                  unit.is_not_valid
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)
                    ?.description"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
        </div>

        <div class="flex flex-col md:flex-row">
          <div class="flex-1">
            <label class="block text-sm">
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span>Website URL</span>
              </span>
              <input
                :id="`unit.website_url.${index + 1}`"
                :value="unit.website_url"
                type="url"
                class="h-10 mt-1 w-full form-input sm:text-sm"
                @input="updateStateField($event, 'website_url', index)"
              />
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.website_url?.length &&
                  unit.is_not_valid
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)
                    ?.website_url"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
        </div>

        <div
          v-if="showFieldIfLocalHostOrHouseOrGenericListing"
          class="grid md:grid-cols-3 mt-2 md:space-x-20"
        >
          <div>
            <label
              :for="`unit.quantity.${index + 1}`"
              class="block text-sm md:max-w-[348px]"
            >
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span
                  ><span class="text-red-100 pr-2 align-sub">**</span
                  >Quantity</span
                >
              </span>
              <input
                :id="`unit.quantity.${index + 1}`"
                :value="unit.quantity"
                type="number"
                min="0"
                step="1"
                class="h-10 mt-1 w-full form-input sm:text-sm"
                :class="{
                  'bg-error-100':
                    getValidatorResponseData(v$, index)?.quantity?.length &&
                    unit.is_not_valid,
                }"
                @input="updateStateField($event, 'quantity', index)"
              />
              <div
                v-if="
                  getValidatorResponseData(v$, index)?.quantity?.length &&
                  unit.is_not_valid
                "
                class="block error text-error-900"
              >
                <div
                  v-for="error in getValidatorResponseData(v$, index)?.quantity"
                  :key="error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </label>
          </div>
        </div>

        <div class="flex flex-col md:flex-row">
          <div class="flex-1">
            <div class="block text-sm">
              <span class="mt-8 block text-sm font-semibold text-gray-700">
                <span>Housing Image Upload</span>
              </span>
              <CloudinaryWidget
                :public-id-prefix="getCurrentHousingId"
                :folder="unit_media_path"
                @uploadedMediaData="
                  (payload) => setUnitMultimedia(index, payload)
                "
              />
            </div>
            <VDraggable
              :model-value="getUnitMultimedia(index)"
              class="flex flex-wrap cursor-move"
              item-key="index"
              ghost-class="opacity-50"
              @update:modelValue="changeOrderUnitMultimediaItem($event, index)"
            >
              <template #item="{ element: src }">
                <div
                  class="w-24 h-24 mr-10 relative flex-none mt-10 hover:shadow-2xl hover:scale-110 duration-300"
                >
                  <button
                    class="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full border border-gray-400 hover:text-red-100 hover:scale-125 duration-150"
                    @click="removeUnitMultimediaItem(index, src)"
                  >
                    <TrashIcon class="w-5 h-5 mx-auto" />
                  </button>
                  <img :src="src" alt="Unit Media" class="h-full w-full" />
                </div>
              </template>
            </VDraggable>
          </div>
        </div>

        <div class="flex flex-col md:flex-row">
          <div class="flex-1">
            <span class="mt-8 block text-sm font-semibold text-gray-700">
              <span
                >Select all the amenities that apply to this unit
                accommodation</span
              >
            </span>
            <div class="flex-col md:grid md:grid-cols-3 gap-5">
              <div
                v-for="amenity in getHousingUnitAmenities"
                :key="amenity.title"
                class="flex mb-3 space-x-2 items-center"
                :class="[
                  isADropdownAmenity(amenity.category) ? 'col-span-2' : '',
                ]"
              >
                <div
                  v-if="isADropdownAmenity(amenity.category)"
                  class="flex flex-1 items-center space-x-2"
                >
                  <div class="flex items-center">
                    <input
                      :id="`unit.amenities.${amenity.category}.${index + 1}`"
                      :checked="
                        unitsAmenitiesDropdown[amenity.category][index]
                          ?.checkbox
                      "
                      type="checkbox"
                      class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                      @input="
                        updateSelectedAmenityCheckbox(
                          $event,
                          index,
                          amenity.category
                        )
                      "
                    />
                  </div>
                  <div class="text-sm w-40">
                    <label class="text-gray-700">
                      {{ amenity.category }}
                    </label>
                  </div>
                  <div class="flex flex-1 items-center space-x-2">
                    <v-select
                      :id="`unit.amenities.${amenity.category}.${index + 1}`"
                      :model-value="
                        unitsAmenitiesDropdown[amenity.category][index]
                          ?.selectedOption
                      "
                      :disabled="
                        !unitsAmenitiesDropdown[amenity.category][index]
                          ?.checkbox
                      "
                      label="title"
                      class="w-[70%]"
                      :placeholder="placeholder"
                      :options="
                        amenity.category === 'Routine Cleaning'
                          ? getHousingUnitRoutineCleaningAmenities
                          : getHousingUnitRoutineLaundryServiceAmenities
                      "
                      @update:modelValue="
                        updateSelectedAmenity($event, index, amenity.category)
                      "
                    ></v-select>
                  </div>
                </div>

                <div v-else class="flex flex-1 items-center space-x-2">
                  <div class="flex items-center">
                    <input
                      :id="`unit.amenities.${amenity.title}.${index + 1}`"
                      type="checkbox"
                      :checked="
                        isAmenityChecked(amenity.id, unit.unit_amenities)
                      "
                      :value="amenity.id"
                      class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                      @input="
                        updateStateFieldArray($event, 'unit_amenities', index)
                      "
                    />
                  </div>
                  <div class="text-sm">
                    <label class="text-gray-700">
                      {{ amenity.title }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex flex-1 items-center space-x-2">
                <div class="flex items-center">
                  <input
                    id="amenities-none-applicable"
                    type="checkbox"
                    class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                    :checked="!unit.unit_amenities.length"
                  />
                </div>
                <div class="text-sm">
                  <label class="text-gray-700">
                    None applicable
                  </label>
                </div>
              </div>
            </div>

            <span class="mt-8 block text-sm font-semibold text-gray-700">
              <span
                >Select all the accessibility & inclusivity information that
                applies to this unit accommodation</span
              >
            </span>
            <div class="flex-col md:grid md:grid-cols-3 gap-5">
              <div
                v-for="amenity in getHousingUnitAccessibilityAmenities"
                :key="amenity.title"
                class="flex space-x-2 items-center"
              >
                <div class="flex mb-3 flex-1 items-center space-x-2">
                  <div class="flex items-center">
                    <input
                      :id="`unit.amenities.${amenity.title}.${index + 1}`"
                      type="checkbox"
                      :checked="
                        isAmenityChecked(amenity.id, unit.unit_amenities)
                      "
                      :value="amenity.id"
                      class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                      @input="
                        updateStateFieldArray($event, 'unit_amenities', index)
                      "
                    />
                  </div>
                  <div class="text-sm">
                    <label class="text-gray-700">
                      {{ amenity.title }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <span class="mt-8 block text-sm font-semibold text-gray-700">
              <span
                >Select all the safety information that applies to this unit
                accommodation</span
              >
            </span>
            <div class="flex-col md:grid md:grid-cols-3 gap-5">
              <div
                v-for="amenity in getHousingUnitSafetyAmenities"
                :key="amenity.title"
                class="flex space-x-2 items-center"
              >
                <div class="flex mb-3 flex-1 items-center space-x-2">
                  <div class="flex items-center">
                    <input
                      :id="`unit.amenities.${amenity.title}.${index + 1}`"
                      type="checkbox"
                      :checked="
                        isAmenityChecked(amenity.id, unit.unit_amenities)
                      "
                      :value="amenity.id"
                      class="h-4 w-4 form-checkbox border-gray-300 mr-3"
                      @input="
                        updateStateFieldArray($event, 'unit_amenities', index)
                      "
                    />
                  </div>
                  <div class="text-sm">
                    <label class="text-gray-700">
                      {{ amenity.title }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col md:flex-row">
          <div class="flex-1">
            <RoomSection
              :unit-index="index"
              :unit-status="unit.status"
              :rooms-props="unit.rooms"
              @addUnitRoom="addUnitRoom"
              @deleteUnitRoom="deleteUnitRoom"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-4 bg-white border-2 border-dashed border-gray-300 rounded cursor-pointer"
      @click.stop="addUnit"
    >
      <button class="flex items-center m-5 p-1">
        <PlusIcon class="mr-2 text-teal-900 w-10 h-10" />
        <span class="font-medium">Add another unit</span>
      </button>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import useVuelidate from "@vuelidate/core";
import {
  numeric,
  helpers,
  requiredIf,
  required,
  url,
} from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
import RoomSection from "../RoomSection";
import CloudinaryWidget from "@/components/CloudinaryUploadWidget";
import PlusIcon from "@/components/shared/icons/PlusIcon.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";
import GarbageIcon from "@/assets/icons/garbage.svg";
import {
  STATUS,
  FLOOR_PLAN,
  KITCHEN_TYPE,
  AMENITIES_CATEGORIES,
  HOUSING_TYPES_MAP,
} from "@/constants";
import { duplicateUnit } from "@/components/program-manager/housing/components/utils";
import { hasPublishPermission } from "@/composables/authorization.js";

const requiredMessage = "The field is required";

const requiredForStatusActive = requiredIf(
  (value, unit) => unit.status === "Active"
);

export default {
  name: "UnitsSection",
  components: { RoomSection, CloudinaryWidget, PlusIcon, TrashIcon },
  props: {
    unitsProps: {
      type: Array,
      default: () => {},
    },
  },
  emits: ["addUnit", "deleteUnit", "addUnitRoom", "deleteUnitRoom"],
  setup() {
    return {
      v$: useVuelidate({
        $registerAs: `UnitsSection-${uuid.v4()}`,
        $lazy: true,
        $autoDirty: true,
      }),
    };
  },
  data() {
    return {
      unit_media_path: "unit",
      placeholder: "Please select one",
      garbageIcon: GarbageIcon,
      statuses: STATUS,
      floor_plans: FLOOR_PLAN,
      kitchen_types: KITCHEN_TYPE,
      maxLength: 1000,
      unitsAmenitiesDropdown: {
        "Routine Cleaning": [
          Array(this.unitsProps.length).fill({
            selectedOption: "",
            checkbox: false,
          }),
        ],
        "Routine Laundry Service": [
          Array(this.unitsProps.length).fill({
            selectedOption: "",
            checkbox: false,
          }),
        ],
      },
    };
  },
  computed: {
    ...mapGetters("programManager", [
      "getHousingDescriptiveInformation",
      "getCurrentHousingType",
      "getGenericListing",
      "getCurrentHousingId",
      "getHousingUnitAmenities",
      "getHousingUnitAccessibilityAmenities",
      "getHousingUnitSafetyAmenities",
      "getHousingUnitRoutineCleaningAmenities",
      "getHousingUnitRoutineLaundryServiceAmenities",
    ]),
    isLocalHostType() {
      return this.getCurrentHousingType === HOUSING_TYPES_MAP.LOCAL_HOST;
    },
    isHouseType() {
      return this.getCurrentHousingType === HOUSING_TYPES_MAP.HOUSE;
    },
    hideFieldIfLocalHostOrHouseOrGenericListing() {
      return (
        !this.isLocalHostType && !this.isHouseType && !this.getGenericListing
      );
    },
    showFieldIfLocalHostOrHouseOrGenericListing() {
      return this.isLocalHostType || this.isHouseType || this.getGenericListing;
    },
    units() {
      return this.unitsProps;
    },
    dropdownAmenitiesCategories() {
      return [
        AMENITIES_CATEGORIES.ROUTINE_CLEANING,
        AMENITIES_CATEGORIES.ROUTINE_LAUNDRY_SERVICE,
      ];
    },
    requiredForStatusActiveIfNotGenericListing() {
      return helpers.withMessage(
        "This field is required to Publish",
        requiredIf(function (value, unit) {
          return unit.status === "Active" && !this.getGenericListing;
        })
      );
    },
  },
  watch: {
    units() {
      this.updateUnitsAmenitiesDropdown();
    },
  },
  created() {
    this.updateUnitsAmenitiesDropdown();
  },
  validations() {
    return {
      units: {
        $each: helpers.forEach({
          status: {
            required: helpers.withMessage(requiredMessage, required),
          },
          name: {
            required: helpers.withMessage(requiredMessage, required),
          },
          floor_plan: {
            required: helpers.withMessage(requiredMessage, required),
          },
          quantity: {
            required: helpers.withMessage(
              requiredMessage,
              requiredForStatusActive
            ),
            numeric,
          },
          sqm: {
            numeric,
          },
          full_bath_count: {
            required: this.requiredForStatusActiveIfNotGenericListing,
            numeric,
          },
          half_bath_count: {
            required: this.requiredForStatusActiveIfNotGenericListing,
            numeric,
          },
          kitchen_type: {
            required: this.requiredForStatusActiveIfNotGenericListing,
          },
          water_closet_count: {
            required: this.requiredForStatusActiveIfNotGenericListing,
            numeric,
          },
          website_url: {
            valid: helpers.withMessage("Must be valid URL", url),
          },
          description: {
            required: helpers.withMessage(
              requiredMessage,
              requiredForStatusActive
            ),
          },
        }),
      },
    };
  },
  methods: {
    updateUnitsAmenitiesDropdown() {
      for (let unitIndex = 0; unitIndex < this.units.length; unitIndex++) {
        for (const category of this.dropdownAmenitiesCategories) {
          let selectedOption = this.getSelectedAmenity(unitIndex, category)
            ?.title;

          this.unitsAmenitiesDropdown[category][unitIndex] = {
            selectedOption: selectedOption || "",
            checkbox: !!selectedOption,
          };
        }
      }
    },
    addUnitRoom(unitIndex, room) {
      this.$emit("addUnitRoom", unitIndex, room);
    },
    deleteUnitRoom(unitIndex, roomIndex) {
      this.$emit("deleteUnitRoom", unitIndex, roomIndex);
    },
    addUnit() {
      this.$emit("addUnit", null);
    },
    deleteUnit(unitIndex) {
      this.$emit("deleteUnit", unitIndex);
    },
    duplicateUnit(index) {
      this.$emit("addUnit", duplicateUnit(this.units[index]));
    },
    getUnitMultimedia(index) {
      const unit_media_path = this.unit_media_path;
      const multimedia = this.units[index].supporting_media?.filter((el) => {
        try {
          return new URL(el).pathname.indexOf(unit_media_path) > -1;
        } catch {
          return true;
        }
      });
      return multimedia;
    },
    setUnitMultimedia(index, payload) {
      const curMedia = this.units[index].supporting_media || [];
      if (!curMedia.find((val) => val === payload.secure_url)) {
        this.setCurrentUnitStateField({
          type: "unit",
          data: {
            index: index,
            fieldname: "supporting_media",
            value: [payload.secure_url, ...curMedia],
          },
        });
      }
    },
    changeOrderUnitMultimediaItem(value = [], index) {
      if (this.units[index].supporting_media !== value) {
        this.setCurrentUnitStateField({
          type: "unit",
          data: {
            index,
            fieldname: "supporting_media",
            value,
          },
        });
      }
    },
    removeUnitMultimediaItem(index, element) {
      const updated_supporting_media = this.units[
        index
      ].supporting_media?.filter((item) => item !== element);
      if (updated_supporting_media) {
        this.setCurrentUnitStateField({
          type: "unit",
          data: {
            index,
            fieldname: "supporting_media",
            value: updated_supporting_media,
          },
        });
      }
    },
    calculateTotalCapacity(unit) {
      return (
        (unit.quantity || 0) *
        unit.rooms.reduce(
          (totalRooms, room) => totalRooms + (room.capacity || 0),
          0
        )
      );
    },
    isAmenityChecked(amenityId, amenities) {
      return amenities ? amenities.includes(amenityId) : false;
    },
    isADropdownAmenity(category) {
      return this.dropdownAmenitiesCategories.includes(category);
    },
    updateStatus(value, index) {
      this.setCurrentUnitStatus({ index: index, status: value });
    },
    updateStateField(e, fieldname, index) {
      let value = e?.target ? e.target.value : e;
      if (
        [
          "quantity",
          "sqm",
          "full_bath_count",
          "half_bath_count",
          "water_closet_count",
        ].includes(fieldname)
      ) {
        value = value ? parseInt(value) : undefined;
      } else if (fieldname === "description" && value.length > this.maxLength) {
        value = value.substring(0, this.maxLength);
      }
      this.setCurrentUnitStateField({
        type: "unit",
        data: {
          index,
          fieldname,
          value: value,
        },
      });
    },
    updateStateFieldArray(e, fieldname, index) {
      this.setCurrentUnitStateFieldArray({
        index,
        fieldname,
        checked: e.target.checked,
        value: e.target.value,
      });
    },
    getValidatorResponseData(v$, index) {
      if (v$.units.$each.$response?.$errors) {
        return v$.units.$each.$response.$errors[index];
      }
      return undefined;
    },
    removePrevSelectedAmenity(unitIndex, category) {
      let previousSelectedAmenityId = this.getSelectedAmenity(
        unitIndex,
        category
      )?.id;

      if (!previousSelectedAmenityId) return;

      this.updateStateFieldArray(
        { target: { value: previousSelectedAmenityId, checked: false } },
        "unit_amenities",
        unitIndex
      );
    },
    updateSelectedAmenityCheckbox(e, unitIndex, category) {
      let isUnchecked = !e.target.checked;

      if (isUnchecked) {
        this.removePrevSelectedAmenity(unitIndex, category);
      }

      let dropdownItem = this.unitsAmenitiesDropdown[category][unitIndex];
      this.unitsAmenitiesDropdown[category][unitIndex] = {
        selectedOption: isUnchecked ? "" : dropdownItem.selectedOption,
        checkbox: e.target.checked,
      };
    },
    updateSelectedAmenity(amenity, unitIndex, category) {
      this.unitsAmenitiesDropdown[category][unitIndex].selectedOption =
        amenity.title;
      this.removePrevSelectedAmenity(unitIndex, category);

      this.updateStateFieldArray(
        { target: { value: amenity.id, checked: true } },
        "unit_amenities",
        unitIndex
      );
    },
    getSelectedAmenity(unitIndex, category) {
      let amenitiesPool = this.getHousingUnitRoutineCleaningAmenities;

      if (category === AMENITIES_CATEGORIES.ROUTINE_LAUNDRY_SERVICE) {
        amenitiesPool = this.getHousingUnitRoutineLaundryServiceAmenities;
      }

      let amenity = amenitiesPool.filter((amenity) =>
        this.units[unitIndex].unit_amenities.includes(amenity.id)
      )[0];
      return amenity;
    },
    shouldValidateField(unit) {
      return unit.is_not_valid && !this.getGenericListing;
    },
    ...mapActions("programManager", [
      "setCurrentUnitStatus",
      "setCurrentUnitStateField",
      "setCurrentUnitStateFieldArray",
    ]),
  },
};
</script>
<style scoped>
.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
</style>
