<template>
  <header
    class="grid md:flex items-center bg-white border-gray-200"
    role="banner"
    aria-label="Site"
  >
    <div
      class="w-full grid grid-cols-3 md:flex md:flex-row md:justify-between items-center px-8 mx-auto gap-4 py-4 h-14"
    >
      <MenuButton v-if="isLoggedIn" />
      <div class="flex justify-center order-1">
        <img
          :src="logo"
          data-cy="logoApiAbroad"
          class="h-8 object-contain logo"
          alt="API"
        />
      </div>

      <UniversityChangeDesktop v-if="!studentDataAvailable" />
      <ImpersonationBanner v-if="isLoggedIn" />
      <NavBar v-if="isLoggedIn" />
    </div>
  </header>
</template>
<script>
import NavBar from "@/components/Header/NavBar.vue";
import MenuButton from "@/components/shared/MenuButton.vue";
import { mapState, mapGetters } from "vuex";
import ImpersonationBanner from "@/components/Impersonation/ImpersonationBanner.vue";
import UniversityChangeDesktop from "@/components/Header/universityChangeDesktop.vue";

export default {
  components: {
    NavBar,
    MenuButton,
    ImpersonationBanner,
    UniversityChangeDesktop,
  },
  props: {
    logo: {
      type: String,
      default: localStorage.logoUrl,
    },
    ldClient: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["userData"]),
    ...mapGetters(["isLoggedIn"]),
    studentDataAvailable() {
      return this.userData !== null && Object.entries(this.userData).length > 0;
    },
    testMode() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("test-mode") === "true";
    },
  },
  created() {
    //set feature flags to store
    if (this.ldClient && !this.testMode) {
      const ldClient = JSON.parse(this.ldClient);
      const featureFlags = Object.keys(ldClient)
        .filter((key) => !key.includes("$"))
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: ldClient[key] });
        }, {});
      this.$store.commit("setFeatureFlags", featureFlags);
    }
  },
};
</script>
