<script setup>
import { PROGRAM_SESSION_TERMS_DISPLAY_MAP } from "@/components/program-manager/sessions/constants.js/";
import {
  defineProps,
  defineEmits,
  toRefs,
  ref,
  reactive,
  watch,
  computed,
} from "vue";
import VSelect from "vue-select";
import {
  buildStringDateRangeSessionTravel,
  formatDate,
  hideSessionDeadline,
} from "@/mixins/helpers";
import PanelContainer from "../Filters/PanelContainer.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import ApplyButton from "@/components/shared/Button/ApplyButton.vue";
import PriceBreakdownSection from "@/components/ExperiencePage/SideBarMenu/PriceBreakdownSection.vue";
import { useStore } from "vuex";
import getApplicableContract from "@/composables/useBillings";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import { PERMISSIONS } from "@/constants";
import { isFacultyLed } from "@/util/isFacultyLed";
import SkeletonLoader from "@/components/shared/loaders/BaseSkeletonLoader.vue";

const emit = defineEmits([
  "preview",
  "selectSession",
  "applyClicked",
  "setMenuItems",
]);

const props = defineProps({
  sessionId: {
    type: String,
    required: true,
  },
  sessions: {
    type: Array,
    required: true,
  },
  showAllSessions: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: true,
  },
});

const scrollTarget = ref("");

const { sessionId, sessions } = toRefs(props);

const getSelectedSession = () =>
  sessions.value?.find((session) => session?.id === sessionId.value) || {};

const selectedSession = ref(getSelectedSession());

const store = useStore();
const displayNewDesigns = computed(() => {
  return store.state?.featureFlags?.['date-card-redesign'] === true;
});

const billingRules = computed(() => store.state.billingRules);
const currentUser = computed(() => store.state.currentUser);
const isParticipant = computed(() =>
  store.getters.getPermissions.includes(PERMISSIONS.PARTICIPANTS)
);

const showPrice = computed(() => {
  const contracts = billingRules.value?.agreements ?? [];
  const applicableContract = getApplicableContract(
    contracts,
    selectedSession.value
  );

  return (
    !currentUser.value ||
    contracts.length === 0 ||
    applicableContract?.direct_billing_type === "Pass through" ||
    !isParticipant.value
  );
});

watch(sessionId, () => {
  selectedSession.value = getSelectedSession();
  setMenuVisibility();
});

const displayedProficiencyLevel = computed(() => {
  const proficiency = selectedSession?.value?.min_proficiency_level?.value;
  if (!proficiency || proficiency === "No Background") {
    return "";
  }
  return proficiency.toLowerCase();
});

const getLanguages = computed(() => {
  const languages = selectedSession?.value?.required_languages;
  if (!languages || languages.length === 0) {
    return "";
  }
  return languages
    .map(
      (lang, index) => lang.value + (index < languages.length - 1 ? ", " : "")
    )
    .join("");
});
const getEducationLevels = computed(() => {
  const { education_levels } = selectedSession.value;
  return education_levels
    .map(
      (educationLevel, index) =>
        `${educationLevel.value} ${
          index !== education_levels.length - 1 ? "or" : ""
        }`
    )
    .join(" ");
});

const getMinimumAcademicLevel = computed(() => {
  return selectedSession.value?.academic_level?.academic_year ?? "";
});

const getMinimumAge = computed(() => {
  return Math.abs(selectedSession.value.minimum_age) ?? "";
});

const getMaximumAge = computed(() => {
  return Math.abs(selectedSession.value.maximum_age) ?? "";
});

const menuItems = reactive({
  dates: {
    index: 1,
    links: "dates",
    sidebarlink: "sidebar-dates",
    title: "Program dates",
    icon: "/images/program-dates-icon.svg",
    visible: false,
  },
  location: {
    index: 2,
    links: "location",
    sidebarlink: "sidebar-location",
    title: "Location",
    icon: "/images/location-icon.svg",
    visible: false,
  },
  host: {
    index: 3,
    links: "host",
    sidebarlink: "sidebar-host",
    title: "Host Institution",
    icon: "/images/institution-icon.svg",
    visible: false,
  },
  details: {
    index: 4,
    links: "academicdetails",
    title: "Academics",
    sidebarlink: "sidebar-academicdetails",
    icon: "/images/details-icon.svg",
    visible: false,
  },
  housing: {
    index: 5,
    links: "housing",
    sidebarlink: "sidebar-housing",
    title: "Housing",
    icon: "/images/housing-icon.svg",
    visible: false,
  },
  internships: {
    index: 6,
    links: "internships",
    sidebarlink: "sidebar-internships",
    title: "Internships",
    icon: "/images/internship-icon.svg",
    visible: false,
  },
  excursions: {
    index: 7,
    links: "excursions",
    sidebarlink: "sidebar-excursions",
    title: "Excursions & Events",
    icon: "/images/excursion-icon.svg",
    visible: false,
  },
  scholarship: {
    index: 8,
    links: "scholarship",
    sidebarlink: "sidebar-scholarship",
    title: "Scholarships",
    icon: "/images/scholarship-icon.svg",
    visible: false,
  },
});

const activeMenuItems = computed(() => {
  let activeItems = [];
  for (const item in menuItems) {
    if (menuItems[item]["visible"] === true) activeItems.push(menuItems[item]);
  }
  return activeItems;
});

watch(
  () => activeMenuItems.value,
  (newMenu) => {
    emit("setMenuItems", newMenu);
  }
);

const hasAcademicDescription = computed(() => {
  const academicDescription =
    selectedSession?.value?.academic_description?.content;
  return academicDescription && Object.values(academicDescription)[0]?.content;
});

const scrollToSection = (id) => {
  scrollTarget.value = document.getElementById(id);
  if (scrollTarget.value) {
    scrollTarget.value.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const formatGPA = (gpa) => {
  const parsedGPA = parseFloat(gpa);
  if (isNaN(parsedGPA)) return null;
  return parsedGPA % 1 !== 0 ? parsedGPA.toFixed(2) : parsedGPA.toFixed(1);
};

const setMenuVisibility = () => {
  for (const menu of Object.keys(menuItems)) {
    menuItems[menu].visible = false;
    switch (menuItems[menu].links) {
      case "internships":
        menuItems[menu].visible = Boolean(
          selectedSession.value.internship_filter_criteria
        );
        break;

      case "location":
        if (
          selectedSession.value.session_travel_details?.[0]?.arrival_city
            ?.reason_to_experience
        ) {
          menuItems[menu].visible = true;
        }
        break;
      case "housing":
        if (selectedSession.value?.session_units?.length > 0) {
          menuItems[menu].visible = true;
        }
        break;
      case "academicdetails":
        if (
          selectedSession.value?.class_filter_criteria ||
          hasAcademicDescription.value
        ) {
          menuItems[menu].visible = true;
        }
        break;
      case "excursions":
        if (selectedSession.value?.session_events?.length > 0) {
          menuItems[menu].visible = true;
        }
        break;
      case "scholarship":
        menuItems[menu].visible = true;
        break;
      case "host":
        if (
          selectedSession.value?.session_hosting_institutions?.length > 0 &&
          !isFacultyLed(selectedSession.value?.session_types)
        ) {
          menuItems[menu].visible = true;
        }
        break;

      default:
        menuItems[menu].visible = true;
        break;
    }
  }
};
function sessionsFutureDeadlineFilter(sessionElem) {
  const today = new Date();
  const appDeadline = new Date(sessionElem.application_deadline);
  return today <= appDeadline;
}
const getDisplayName = (terms) => {
  if (!terms || terms.length === 0) {
    return "";
  }
  const normalizedTerms = terms.map((term) => term.name).sort();
  const termKey = normalizedTerms.join(" + ");
  return (
    PROGRAM_SESSION_TERMS_DISPLAY_MAP[termKey] || normalizedTerms.join(", ")
  );
};

const filteredSessions = computed(() =>
  props.showAllSessions
    ? sessions.value
    : sessions.value.filter((session) => sessionsFutureDeadlineFilter(session))
);
</script>

<template>
  <div class="px-4 md:px-10">
    <div>
      <div class="mb-4">
        <SkeletonLoader
          v-if="isLoading"
          :from-color="'#E3E7F3'"
          :to-color="'#F9FAFB'"
          :height="'h-13'"
          class="mb-4"
          rounded
        />
        <VSelect
          v-else-if="!isLoading && !displayNewDesigns"
          id="id"
          v-model="selectedSession"
          label="name"
          class="block md:w-full text-indigo-base bg-white border-gray-600 border-[1px] session-select-component-style"
          :options="filteredSessions"
          :clearable="false"
          @option:selected="(session) => emit('selectSession', session.id)"
        >
          <template
            #selected-option="{ name, terms, academic_start_date, academic_end_date, session_travel_details }"
          >
            <div
              class="text-sm flex flex-col font-medium text-indigo-base self-center w-full justify-center sm:pr-4 md:pr-6"
            >
              <div
                class="flex font-bold md:font-base w-full xl:w-fit text-left"
              >
                {{ getDisplayName(terms) || name }}
              </div>
              <div
                v-if="
                  session_travel_details?.[0]?.arrival_date ||
                  session_travel_details?.[0]?.departure_date
                "
                class="text-left"
              >
                {{
                  buildStringDateRangeSessionTravel(
                    session_travel_details,
                    { academic_start_date, academic_end_date },
                    "long"
                  )
                }}
              </div>
            </div>
          </template>
          <template
            #option="{ name, terms, academic_end_date, academic_start_date ,session_travel_details }"
          >
            <div
              class="text-sm flex flex-col font-medium text-indigo-base self-center break-words w-full justify-center sm:pr-4 md:pr-6"
            >
              <p
                class="flex font-bold md:font-base break-words w-full xl:w-fit text-left"
              >
                {{ getDisplayName(terms) || name }}
              </p>
              <p
                v-if="
                  session_travel_details?.[0]?.arrival_date ||
                  session_travel_details?.[0]?.departure_date
                "
                class="text-left"
              >
                {{
                  buildStringDateRangeSessionTravel(
                    session_travel_details,
                    { academic_start_date, academic_end_date },
                    "long"
                  )
                }}
              </p>
            </div>
          </template>
        </VSelect>
        <SkeletonLoader
          v-if="isLoading"
          :from-color="'#E3E7F3'"
          :to-color="'#F9FAFB'"
          :height="'h-16'"
          rounded
        />
        <div v-else-if="!isLoading && !displayNewDesigns">
          <ApplyButton
            :id="selectedSession.id"
            :disabled="pastSessionDeadline(selectedSession)"
            type="button"
            class="py-2 w-full text-sm mt-2"
            event-category="Program Page Personalize CTA"
            @click="$emit('applyClicked', selectedSession.id)"
          />
        </div>
      </div>
      <PanelContainer id="city-filter" title="Application details">
        <div class="mb-4 mt-4">
          <label class="font-bold text-indigo-base text-base"
            >Requirements:</label
          >
          <li v-if="getMinimumAcademicLevel" class="text-indigo-base">
            Min Year in School: {{ getMinimumAcademicLevel }}
          </li>
          <li
            v-if="selectedSession.minimum_gpa_requirement"
            class="text-indigo-base"
          >
            Minimum
            {{ formatGPA(selectedSession.minimum_gpa_requirement) }}
            G.P.A.
          </li>
          <li v-if="getMinimumAge" class="text-indigo-base">
            Minimum age {{ getMinimumAge }}
          </li>
          <li v-if="getMaximumAge" class="text-indigo-base">
            Maximum age {{ getMaximumAge }}
          </li>
          <li
            v-if="selectedSession.education_levels?.length > 0"
            class="text-indigo-base education-levels"
          >
            Open to {{ getEducationLevels }}
          </li>
          <li
            v-if="selectedSession.required_languages?.length > 0"
            class="text-indigo-base"
          >
            Open to {{ displayedProficiencyLevel }} {{ getLanguages }} speakers
          </li>
          <li v-if="selectedSession.prerequisites" class="text-indigo-base">
            Prerequisites: {{ selectedSession.prerequisites }}
          </li>
          <li v-if="selectedSession.corequisities" class="text-indigo-base">
            Corequisites: {{ selectedSession.corequisities }}
          </li>
        </div>
        <SkeletonLoader
          v-if="isLoading"
          :from-color="'#E3E7F3'"
          :to-color="'#F9FAFB'"
          :height="'h-20'"
          rounded
        />
        <div v-if="!hideSessionDeadline(selectedSession)" class="mb-4">
          <label class="font-bold text-indigo-base text-base">
            Next session deadline:
            <SkeletonLoader
              v-if="isLoading"
              :from-color="'#E3E7F3'"
              :to-color="'#F9FAFB'"
              :height="'h-10'"
              rounded
            />
            {{ formatDate(selectedSession.application_deadline, "long") }}
          </label>
        </div>
        <SkeletonLoader
          v-if="isLoading"
          :from-color="'#E3E7F3'"
          :to-color="'#F9FAFB'"
          :height="'h-10'"
          rounded
        />
        <div v-if="selectedSession.base_price_in_cents" class="mb-4">
          <PriceBreakdownSection
            v-if="showPrice"
            :selected-session="selectedSession"
            @preview="$emit('preview', true)"
          />
        </div>
        <SkeletonLoader
          v-if="isLoading"
          :from-color="'#E3E7F3'"
          :to-color="'#F9FAFB'"
          :height="'h-10'"
          rounded
        />
        <div v-else class="mb-4">
          <label class="font-montserrat font-bold text-indigo-base text-15p">
            Visa Required:
            {{ !!selectedSession.visa_required ? "Yes" : "No" }}
          </label>
          <p
            v-if="!!selectedSession.visa_required"
            class="font-montserrat font-medium text-sm text-indigo-base"
          >
            Learn more about visas
            <a
              href="https://apiabroad.com/student-visas/"
              target="_blank"
              class="font-semibold underline"
              :class="[secondaryColor ? `${secondaryColorClass}` : 'text-university-secondary']"
              >here.</a
            >
          </p>
        </div>
        <SkeletonLoader
          v-if="isLoading"
          :from-color="'#E3E7F3'"
          :to-color="'#F9FAFB'"
          :height="'h-10'"
          rounded
        />
        <div v-if="selectedSession?.transcript_provider?.name" class="mb-4">
          <label class="font-bold text-indigo-base text-base"
            >Transcript:</label
          >
          <p class="text-sm text-indigo-base">
            API students will receive a transcript from
            {{ selectedSession?.transcript_provider?.name }}
          </p>
        </div>
      </PanelContainer>
    </div>
  </div>
  <SkeletonLoader
    v-if="!props.sessionId || isLoading"
    :from-color="'#D5E9E7'"
    :to-color="'#F9FAFB'"
    :height="'h-14'"
    class="mb-4"
    rounded
  />
  <SkeletonLoader
    v-if="!props.sessionId || isLoading"
    to="#sticky-sidebar-items"
    :from-color="'#E3E7F3'"
    :to-color="'#F9FAFB'"
    :height="'h-96'"
    class="mt-4"
    rounded
  />

  <!-- Teleport block is rendered when sessionId is present and data is not loading -->
  <Teleport v-else to="#sticky-sidebar-items">
    <div
      class="container inline-flex gap-2 mb-10 items-center min-h-65 w-full justify-center"
      :class="[
        secondaryColor ? `bg-gray-275 ${secondaryColorClass}` : `text-university-secondary bg-university-accentSecondary`,
      ]"
    >
      <span class="text-indigo-base text-base">
        Questions about this program?
      </span>
      <a
        target="_blank"
        href="https://apiabroad.com/contact-us/"
        class="underline"
        @click.stop
      >
        Click Here</a
      >
      <ArrowRight custom-class="w-5 h-5" />
    </div>
    <div
      v-for="menu in menuItems"
      :id="menu.sidebarlink"
      :key="menu.index"
      class="menu-items px-4 md:pr-[50px] md:pl-[30px] text-indigo-base font-montserrat"
    >
      <div v-if="menu.visible">
        <div
          class="border-b border-indigo-base border-opacity-20 cursor-pointer"
          @click="scrollToSection(menu.links)"
        >
          <div
            class="grid grid-cols-sidebar-experience-item pt-2.5 pb-2.5 gap-x-2.5 items-center"
          >
            <img :src="menu.icon" />
            <span class="font-bold capitalize text-lg tracking-tigh">
              {{ menu.title }}
            </span>
            <ArrowRight
              custom-class="w-5 h-5 col-start-3 col-span-1 items-center"
            />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.session-select-component-style :deep(.vs__dropdown-toggle) {
  overflow: auto;
  max-height: fit-content;
  display: flex;
  border-color: white;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.session-select-component-style :deep(.vs__selected-options) {
  display: contents;
  overflow: hidden;
}
.session-select-component-style :deep(.vs__dropdown-menu) .vs__dropdown-toggle {
  border-radius: 0;
  display: block;
  overflow: hidden;
  word-wrap: break-word;
}
.session-select-component-style :deep(.vs__dropdown-option) {
  white-space: normal;
}
.session-select-component-style :deep(.vs__dropdown-option--highlight) {
  background: #edf9f8 !important;
  cursor: auto;
}
.session-select-component-style :deep(.vs__selected) {
  display: block;
  width: 100%;
  position: relative !important;
}
</style>
