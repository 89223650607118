<script setup>
import AddOnCard from "@/components/EnrollmentAddOns/AddOnCard.vue";
import {
  GENERAL_GROUP_VISA_SERVICE,
  SPAIN_GROUP_VISA_SERVICE,
} from "@/components/program-manager/sessions/constants.js";
import { ORDER_PRODUCT_STATUS } from "@/constants.js";
import { usePaymentGeneralEvent } from "@/composables/usePaymentGeneralEvent";
import { centsToDollars, formatDate } from "@/mixins/helpers";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import ordersService from "@/services/orders.js";
import paymentService from "@/services/payment";
import { computed, defineProps, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  session: {
    type: Object,
    default: () => {},
  },
  order: {
    type: Object,
    default: () => {},
  },
  orderProductDetails: {
    type: Object,
    default: () => {},
  },
});
const { session, order, orderProductDetails } = toRefs(props);

const groupVisaProductType = "API Visa Service";
const addOnProducts = reactive([]);

const applicationId = computed(() => route.params.applicationId);
const { getDataForPaymentService } = usePaymentGeneralEvent(
  applicationId.value,
  session.value,
  order.value.billing_contract_id
);

const programCountryIsSpain = computed(() => {
  return (
    session.value?.session_travel_details?.[0]?.arrival_city?.country?.id ===
    "ES"
  );
});
const groupVisaProduct = computed(() =>
  programCountryIsSpain.value
    ? SPAIN_GROUP_VISA_SERVICE
    : GENERAL_GROUP_VISA_SERVICE
);

const hasGroupVisaFlagEnabled = computed(() =>
  Boolean(store.state.featureFlags?.["add-group-visa-service"])
);
const orderGroupVisaProduct = computed(() =>
  orderProductDetails.value?.misc_products?.find(
    (product) => groupVisaProduct.value.id === product.misc_product.id
  )
);
const canceledOrderGroupVisa = computed(
  () => orderGroupVisaProduct.value?.status === ORDER_PRODUCT_STATUS.canceled
);
const studentPaysGroupVisa = computed(
  () => order.value.value?.billing_rules?.visaServiceFee !== "Institution"
);

const addGroupVisaCard = () => {
  if (
    hasGroupVisaFlagEnabled.value &&
    !pastSessionDeadline(session.value) &&
    order.value?.eligible_for_group_visa &&
    (!orderGroupVisaProduct.value || canceledOrderGroupVisa.value)
  ) {
    addOnProducts.push({
      type: groupVisaProductType,
      image: "group-visa.png",
      title: groupVisaProduct.value.name,
      description:
        "Obtaining a visa for your program is not optional, but you are eligible to opt into our group visa service.",
      deadlineDate: formatDate(session.value.application_deadline),
      price: groupVisaProduct.value.price_in_cents,
      canceled: canceledOrderGroupVisa.value,
      testId: "group-visa",
      disabled: false,
    });
  }
};
const payGroupVisa = async (product, index) => {
  /**
   * Button should
   * - Open payment page if the student pays
   * - Trigger payment general event and update order if the institution pays
   * To make it functional we need to have set up the following:
   * - Class service: Endpoint to add post application purchases items on the orders
   * - Payment service: Trigger post application purchases event after receiving the addon payment through stripe
   *
   * Until the changes are ready, we will only display a message on the console saying who will pay.
   */
  if (studentPaysGroupVisa.value) {
    // Uncomment to open the Payment page
    // localStorage.setItem(
    //   "toPay",
    //   JSON.stringify([
    //     {
    //       name: product.title,
    //       balance: centsToDollars(product.price),
    //       due_date: new Date().toISOString().split("T")[0],
    //       paid_by: "Participant",
    //       status: "Open",
    //       sku: groupVisaProduct.value.sku,
    //       paid: "0.00",
    //     },
    //   ])
    // );
    // router.push({
    //   name: "payment",
    //   params: { applicationId: applicationId.value },
    // });
    console.log("Student pays");
  } else {
    try {
      // TODO: Update order and submit institution event to payment service
      // const eventData = await getDataForPaymentService(product);
      // await paymentService.submitGeneralEvent(eventData);
      // addOnProducts.splice(index, 1);
      console.log("Institution pays");
    } catch {
      addOnProducts[index].disabled = false;
    }
  }
};

const addProduct = async ({ product, index }) => {
  addOnProducts[index].disabled = true;

  if (product.type === groupVisaProductType) {
    await payGroupVisa(product, index);
  }
};

onMounted(async () => {
  addGroupVisaCard();
});
</script>

<template>
  <section
    v-if="addOnProducts.length"
    class="bg-white sm:mt-4 sm:rounded-lg sm:shadow-md text-indigo-base py-4 px-8 font-montserrat"
    data-testid="add-ons-section"
  >
    <header class="mb-4">
      <h2 class="text-lg font-semibold">
        What you might have missed
      </h2>
    </header>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
      <AddOnCard
        v-for="(product, index) in addOnProducts"
        :key="product.title"
        :index="index"
        :product="product"
        @add-product="addProduct"
      />
    </div>
  </section>
</template>
