import { API_RELATIONSHIPS } from "@/constants";
let initialState = () => {
  return {
    formioToken: "",
    enrollmentToken: "",
    userData: {},
    studentApplications: [],
    studentFormioSubmissions: [],
    selectedProgramId: "",
    program: {},
    isInternship: false,
    universityName: "",
    universityCity: "",
    universityImageUrl: "",
    universityWebsiteUrl: "",
    programSelected: false,
    billingRules: {},
    term: "",
    payments: [],
    enrollmentApplications: [],
    sessionExpired: false,
    redirectPath: "",
    previousApplication: {
      previousApplicationId: "",
      session: "",
      paidFee: false,
      applicationFee: 0,
      transactionId: "",
      finished: false,
      submissionId: "",
    },
    currentApplicationId: "",
    applicationPaid: false,
    customer_id: "",
    uiVersion: "",
    completedSections: {
      onboarding1: false,
      payment: false,
      onboarding2: false,
      otherPayments: false,
    },
    onboardingStep1Forms: {},
    onboardingStep2Forms: {},
    currentGpa: 0,
    directProgramData: {},
    appliedAgreement: {},
    institutionPaysApplicationFee: false,
    institutionPaysConfirmationPayment: false,
    institutionPaysAcademicFee: false,
    institutionPaysHousingFee: false,
    financialAid: false,
    certifyFAFSA: false,
    employeeUser: null,
    housingDetail: {},
    placeDetails: {},
    newAccountAndApplication: false,
    profileData: {},
    profileSelectedObjects: {
      loaded: false,
      citizenship: null,
      citizenship2: null,
      school: null,
      majors: [],
      minors: [],
      currentCountry: null,
      permanentCountry: null,
      billingCountry: null,
    },
    stepsInV3: [
      { completed: false, data: null, step: "AccountCreation" },
      { completed: false, data: null, step: "AboutYou" },
      { completed: false, data: null, step: "LearnerInfo" },
      { completed: false, data: null, step: "Review" },
      { completed: false, data: null, step: "GroupVisa" },
      { completed: false, data: null, step: "Checkout" },
      { completed: false, data: null, step: "NextSteps" },
    ],
    programSessionDataV3: {},
    entityIDs: [],
    domainEntityId: "",
    brandingDetails: {},
    currentAPIRelationship: API_RELATIONSHIPS.DEFAULT,
  };
};

let mutations = {
  SET_PLACE_DETAILS(state, payload) {
    state.placeDetails = payload;
  },
  setCurrentUser(state, user) {
    state.currentUser = user;
  },
  reset(state, payload = null) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });

    state.userData = payload?.userData || null;
  },
  setFormioToken(state) {
    state.formioToken = state.currentUser?.studentToken ?? null;
  },
  setEnrollmentToken(state, token) {
    state.enrollmentToken = token;
  },
  setFormioUser(state, formioUser = null) {
    if (formioUser) {
      state.userData = formioUser;
    } else {
      state.userData = JSON.parse(localStorage.getItem("formioUser"));
    }
  },
  setStudent(state, student) {
    state.student = student;
  },
  setStudentApplications(state, applications) {
    state.studentApplications = applications;
  },
  setCurrentApplicationSubmissions(state, submissions) {
    state.currentApplicationSubmissions = submissions;
  },
  setStudentFormioSubmissions(state, submissions) {
    state.studentFormioSubmissions = submissions;
  },
  setOnboardingFormioSubmissions(state, submissions) {
    state.onBoardingFormioSubmissions = submissions;
  },
  setSelectedProgramId(state, id) {
    state.selectedProgramId = id;
  },
  setProgram(state, program) {
    state.program = program;
  },
  setIsInternship(state, isInternship) {
    state.isInternship = isInternship;
  },
  setUniversityName(state, universityName) {
    state.universityName = universityName;
  },
  setUniversityCity(state, universityCity) {
    state.universityCity = universityCity;
  },
  setUniversityImageUrl(state, universityImageUrl) {
    state.universityImageUrl = universityImageUrl;
  },
  setUniversityWebsiteUrl(state, universityWebsiteUrl) {
    state.universityWebsiteUrl = universityWebsiteUrl;
  },
  setProgramSelected(state, programSelected) {
    state.programSelected = programSelected;
  },
  setBillingRules(state, billingRules) {
    state.billingRules = billingRules;
  },
  setPayments(state, billingData) {
    state.payments = billingData;
  },
  setCustomerId(state, customer_id) {
    state.customer_id = customer_id;
  },
  setTerm(state, term) {
    state.term = term;
  },
  setEnrollmentApplicationData(state, applications) {
    // Applications also known as progresses
    state.enrollmentApplications = applications;
  },
  clearProgram(state) {
    state.program = {};
  },
  setSessionExpired(state, value) {
    state.sessionExpired = value;
  },
  setRedirectPath(state, value) {
    state.redirectPath = value;
    localStorage.setItem("redirectPath", value);
  },
  setPreviousApplicationIdByField(state, fieldData) {
    switch (fieldData.handle) {
      case "previousApplicationId":
        state["previousApplication"]["previousApplicationId"] = fieldData.value;
        break;
      case "session":
        state["previousApplication"]["session"] = fieldData.value;
        break;
      case "paidFee":
        state["previousApplication"]["paidFee"] = fieldData.value;
        break;
      case "applicationFee":
        state["previousApplication"]["applicationFee"] = fieldData.value;
        break;
      case "transactionId":
        state["previousApplication"]["transactionId"] = fieldData.value;
        break;
      case "finished":
        state["previousApplication"]["finished"] = fieldData.value;
        break;
      case "submissionId":
        state["previousApplication"]["submissionId"] = fieldData.value;
        break;
      default:
        console.warn("The handle proportioned to the state does not exists");
    }
  },
  setUiVersion(state, version) {
    state.uiVersion = version;
  },
  setCompletedSections(state, data) {
    state.completedSections[data.field] = data.value;
  },
  setOnboarding2Forms(state, forms) {
    state.onboardingStep2Forms = forms;
  },
  resetPreviousApplicationState(state) {
    state["previousApplication"]["previousApplicationId"] = "";
    state["previousApplication"]["session"] = "";
    state["previousApplication"]["paidFee"] = false;
    state["previousApplication"]["applicationFee"] = 0;
    state["previousApplication"]["transactionId"] = "";
    state["previousApplication"]["finished"] = false;
    state["previousApplication"]["submissionId"] = "";
  },
  setCurrentApplicationId(state, applicationId) {
    state["currentApplicationId"] = applicationId;
  },
  updateChangeNotPending(state, applicationId) {
    state["studentApplications"].find((app) => {
      if (app["id"] === applicationId) {
        app["programChange"]["changeIsPending"] = false;
        return app;
      }
    });
  },
  setApplicationPaid(state, hasPaid) {
    state.applicationPaid = hasPaid;
  },
  setOnboardingForms(state, forms) {
    state.onboardingStep1Forms = JSON.parse(JSON.stringify(forms));
  },
  setAdvisorApprovalData(state, payload) {
    // payload = {field: "<String>, value: <Mixed>, applicationId: <String>}
    let application = state["studentApplications"].findIndex(
      (app) => app["id"] === payload["applicationId"]
    );
    if (application !== -1) {
      state["studentApplications"][application]["advisorApproval"][
        payload.field
      ] = payload.value;
    }
  },
  setCurrentGpa(state, payload) {
    state.currentGpa = payload;
  },
  setDirectProgramData(state, programData) {
    state.directProgramData = programData;
  },
  clearDirectProgram(state) {
    state.directProgramData = {};
  },
  setAppliedAgreement(state, payload) {
    state.appliedAgreement = Object.assign({}, payload);
  },
  setInstitutionPaysValue(state, payload) {
    // payload = {field: <String>, value: <Boolean>}
    if (
      payload !== undefined &&
      payload !== null &&
      Object.hasOwnProperty.call(payload, "field") &&
      Object.hasOwnProperty.call(payload, "value") &&
      typeof payload.field === "string" &&
      typeof payload.value === "boolean"
    ) {
      if (payload.field === "applicationFee") {
        state.institutionPaysApplicationFee = payload.value;
      }
      if (payload.field === "confirmationPayment") {
        state.institutionPaysConfirmationPayment = payload.value;
      }
      if (payload.field === "academicFee") {
        state.institutionPaysAcademicFee = payload.value;
      }
      if (payload.field === "housingFee") {
        state.institutionPaysHousingFee = payload.value;
      }
    }
  },
  setFeatureFlags(state, featureFlags) {
    state.featureFlags = featureFlags;
  },
  setItemsToPay(_, itemsToPay) {
    localStorage.setItem("toPay", JSON.stringify(itemsToPay));
  },
  cleanPaymentsToPay() {
    localStorage.removeItem("toPay");
  },
  setAddressInfo(state, addresInfo) {
    state.addressInfo = addresInfo;
  },
  /**
   * V3 Only: Flag to know if student is applying for the first time
   * @param {*} state
   * @param {Boolean} value Boolean
   */
  setNewAccountAndApplication(state, value) {
    state.newAccountAndApplication = value;
  },
  /**
   * Since V3: Sets the data of a profile returned from the profile-service repo
   * @param {*} state
   * @param {Object} value The object returned by an API call
   */
  setProfileData(state, value) {
    state.profileData = value;
  },
  setProfileSelectedObjects(state, { propertyName, value }) {
    const defaultValue = Array.isArray(value) ? [] : {};
    if (propertyName === "loaded") state.profileSelectedObjects.loaded = value;
    else
      state.profileSelectedObjects[propertyName] = Object.assign(
        defaultValue,
        value
      );
  },
  /**
   * V3: Set step completed
   * @param {*} state
   * @param {String} stepName Name of the step to complete
   */
  setStepCompletedV3(state, stepName) {
    let stepIndex = state.stepsInV3.findIndex((step) => step.step === stepName);
    if (stepIndex > -1) {
      state.stepsInV3[stepIndex]["completed"] = true;
    }
  },
  setStepDataV3(state, { stepName, data }) {
    let stepIndex = state.stepsInV3.findIndex((step) => step.step === stepName);
    if (stepIndex > -1) {
      state.stepsInV3[stepIndex]["data"] = data;
    }
  },
  /**
   * V3: Sets if a step is completed and its data
   * @param {*} state
   * @param {Array} value associative array with values
   */
  setStepsV3(state, value) {
    state.stepsInV3 = value;
  },
  /**
   * V3 Only: Sets the data of a program session from learning service
   * @param {*} state
   * @param {Object} value Data from an api call
   */
  setProgramSessionDataV3(state, value) {
    state.programSessionDataV3 = value;
  },
  setCurrentOrderProgram(state, program) {
    state.currentOrder = { ...state.currentOrder, program };
  },
  setRedirectSessionData(state, payload) {
    state.redirectSessionData.programId = payload.programId;
    state.redirectSessionData.sessionId = payload.sessionId;
  },
  setEntityIDs(state, value) {
    state.entityIDs = value;
  },
  setDomainEntityId(state, value) {
    state.domainEntityId = value;
  },
  setBrandingDetails(state, value) {
    state.brandingDetails = value;
  },
  setAPIRelationshipToCurrentUser(state, relationship) {
    state.currentAPIRelationship = relationship;
  },
};
export default mutations;
