<script setup>
import { ref, computed, defineEmits, defineProps } from "vue";
import { useVModel } from "@vueuse/core";
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import RequiredToSavePublish from "../../housing/components/RequiredToSavePublish";
import BaseInput from "../../../InternshipLibrary/BaseInput.vue";

const audienceComing = ref([]);

const props = defineProps({
  modelValue: {
    type: Object,
  },
});
const emit = defineEmits(["update:modelValue", "validator"]);
const events = useVModel(props, "modelValue", emit);

const validateTab = async () => {
  return v$.value.$validate().then(() => {
    return !v$.value.$invalid;
  });
};

emit("validator", validateTab);

const audience = [
  { id: "student", name: "Student" },
  { id: "tourist", name: "Tourist" },
  { id: "families", name: "Families" },
  { id: "industry professionals", name: "Industry Professionals" },
  { id: "other", name: "Other" },
];

const availability = ref({
  required: true,
  type: "grid_select_with_all",
  options: [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ],
  active: true,
  name: "availability",
  labelInput: "name",
});

const themes = ref({
  required: true,
  type: "searchable_select",
  options: [],
  active: true,
  labelInput: "soc_title",
  name: "career_areas",
});

const age = ref({
  required: true,
  type: "radio",
  options: ["Must be over 18", "All ages"],
  copy: "Age requirement",
  active: true,
  name: "age",
});

const toggleAudience = (id) => {
  audienceComing.value = events.value.primarily_attended_by || [];
  if (audienceComing.value.includes(id)) {
    audienceComing.value = audienceComing.value.filter((i) => i !== id);
  } else {
    audienceComing.value = [...audienceComing.value, id];
  }
  events.value.primarily_attended_by = audienceComing.value;
};

const rules = computed(() => ({
  events: {
    event_academic_theme: {
      required: {},
    },
    age_requirement: {
      required: helpers.withMessage(
        "Age requirement is required to Publish",
        requiredIf(function () {
          return (
            events.value.status === "Active" && !events.value.age_requirement
          );
        })
      ),
    },
  },
}));

const v$ = useVuelidate(rules, events, {
  $registerAs: "AvailabilityTab",
  $lazy: true,
});
</script>

<template>
  <div class="relative p-6 space-y-8">
    <div class="flex justify-between items-start flex-wrap">
      <h1 class="text-xl font-semibold leading-6 text-gray-900">
        Availability
      </h1>
      <RequiredToSavePublish />
    </div>
    <div class="grid xs:grid-cols-2 sm:grid-cols-3 mt-2 gap-8">
      <div class="mb-4">
        <label name="radius" class="block text-sm font-semibold text-gray-700"
          >Radius of Allowed Participants (Miles)</label
        >
        <div class="flex mt-1">
          <input
            id="radius"
            v-model="events.radius"
            type="text"
            name="radius"
            class="h-10 w-full form-input"
          />
        </div>
      </div>
      <div class="mb-4 col-span-2">
        <label class="block text-sm font-semibold text-gray-700"
          >Seasonal Availability</label
        >
        <BaseInput
          v-model="events.terms_available"
          :option-template="availability.options"
          :field-settings="availability"
        />
      </div>
      <div class="mb-4">
        <label name="radius" class="block text-sm font-semibold text-gray-700"
          >Relevant Academic Themes</label
        >
        <BaseInput
          v-model="events.event_academic_theme"
          :type="'searchable_select'"
          :field-settings="themes"
        />
      </div>
      <div class="mb-4">
        <div>
          <label label class="font-semibold">
            <span class="font-semibol mr-2 text-error-900">**</span>Age
            Requirement</label
          >
          <BaseInput
            v-model="events.age_requirement"
            type="radio"
            :field-settings="age"
            :custom-radio-css="'flex space-x-20'"
          />
          <div
            v-if="v$.events.age_requirement.$errors?.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.events.age_requirement.$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <label label class="font-semibold">The event is attended by</label>
      <div class="mt-4 grid xs:grid-cols-2 sm:grid-cols-3 gap-8">
        <div
          v-for="{ id, name } in audience"
          :key="id"
          class="flex justify-between w-fit items-center mb-3"
        >
          <input
            :id="id"
            :checked="events.primarily_attended_by?.includes(id)"
            type="checkbox"
            class="h-4 w-4 form-checkbox border-gray-300 mr-10"
            @input="toggleAudience(id)"
          />
          <label :for="id" class="text-sm font-semibold text-gray-700">{{
            name
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-select {
  max-width: 100% !important;
}
</style>
