<template>
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_151_2596)">
      <path
        d="M9.88 13V11.5H12.55C12.65 11.5 12.74 11.48 12.82 11.43C12.91 11.38 12.98 11.32 13.03 11.25L16.41 6.5L13.03 1.75C12.98 1.67 12.91 1.61 12.82 1.57C12.73 1.53 12.64 1.5 12.55 1.5H3.06C2.97 1.5 2.9 1.53 2.84 1.59C2.78 1.65 2.75 1.72 2.75 1.81V4.37H1.25V1.81C1.25 1.31 1.43 0.89 1.78 0.53C2.13 0.18 2.56 0 3.06 0H12.56C12.91 0 13.23 0.08 13.54 0.24C13.85 0.4 14.09 0.62 14.29 0.9L18.26 6.49L14.29 12.1C14.09 12.38 13.84 12.6 13.54 12.75C13.24 12.91 12.92 12.98 12.56 12.98H9.89L9.88 13ZM3 14.25V11.25H0V9.75H3V6.75H4.5V9.75H7.5V11.25H4.5V14.25H3Z"
        fill="#1E264C"
      />
    </g>
    <defs>
      <clipPath id="clip0_151_2596">
        <rect width="18.25" height="14.25" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
