<template>
  <section>
    <header>
      <h2 class="text-xl md:text-3xl">Confirm Your Program</h2>
    </header>
    <div class="text-base text-gray-600">
      It looks like you would like to participate in the following program.
      Please confirm by clicking the button below. If you think you reached this
      page in error and would like to select a different program offered by API,
      please feel free to
      <span
        class="text-cyan-100 font-semibold cursor-pointer"
        @click="redirectUser()"
        >Change Your Program
      </span>
      here.
    </div>
    <p class="text-xs font-semibold text-gray-500 mt-8">
      PROGRAM NAME
    </p>
    <label class="mb-4 mt-4 flex items-center">
      <input
        type="radio"
        class="form-radio"
        :class="`${primaryColorClass}`"
        checked
      />
      <div class="flex">
        <div class="flex-none ml-2">
          <span
            class="bg-blue-500 px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-nowrap rounded-full text-1xs"
          >
            Faculty-Led
          </span>
        </div>
        <span class="ml-2" data-cy="program-name">{{ programName }}</span>
      </div>
    </label>
    <form class="mt-4">
      <div class="grid gap-4 grid-cols-2">
        <div>
          <p class="text-xs font-semibold text-gray-500">LOCATION</p>
          <VSelect
            id="location"
            v-model="location"
            aria-label="Location"
            :disabled="true"
          ></VSelect>
        </div>
        <div class="flex flex-col items-end">
          <p class="text-xs font-semibold text-gray-500">PROGRAM DATES</p>
          <p class="mt-2 text-gray-800" data-cy="program-dates">
            {{ programDates }}
          </p>
        </div>
      </div>
      <div class="mt-10 block">
        <ButtonWithSpinner
          ref="customProgramSubmitButton"
          data-cy="confirmation-page-submit-button"
          variant="primary"
          variant-type="block"
          @click.prevent="submit()"
        >
          <span>Confirm Program</span>
        </ButtonWithSpinner>
        <p v-if="hasErrors" class="text-error-900">
          Please complete the required fields correctly.
        </p>
        <div v-if="missingAppId" class="text-error-900">
          <p>
            No Application ID, please contact your API program manager to help
            you with your application.
          </p>
        </div>
        <div
          v-if="v$.location.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.location.$error }"
        >
          Program location is required.
        </div>
        <div
          v-if="v$.semester.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.semester.$error }"
        >
          Program semester is required.
        </div>
        <div
          v-if="v$.experienceType.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.experienceType.$error }"
        >
          Program experience type is required.
        </div>
        <div
          v-if="v$.programSession.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.programSession.$error }"
        >
          Program session is required.
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from "vuex";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner";
import { buildStringDateRange, trailingSlash } from "../../../mixins/helpers";

import { required } from "@vuelidate/validators";
import formIoApi from "../../../mixins/formIoApi";
import formValidation from "../../../mixins/formValidation";
import { eventBus } from "../../../app";
import forms from "../../../mixins/forms";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    ButtonWithSpinner,
  },
  mixins: [formIoApi, formValidation, forms],
  emits: ["set-application-id", "showSpinner", "changeStep"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      location: {},
      semester: "",
      experienceType: "",
      formIoForm: "programselection",
      programSession: "",
    };
  },
  computed: {
    ...mapState([
      "program",
      "currentApplicationId",
      "uiVersion",
      "studentApplications",
      "selectedProgramId",
      "appliedAgreement",
    ]),
    sitesURL() {
      return (
        trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) + "sites"
      );
    },
    programName() {
      return _.isEmpty(this.program) ? "" : this.program.program.name;
    },
    programDates() {
      return _.isEmpty(this.program)
        ? ""
        : buildStringDateRange(this.program.start_date, this.program.end_date);
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          application_id: this.currentApplicationId,
          uiVersion: this.uiVersion,
          experienceType: this.experienceType,
          location: this.location,
          semester: this.semester,
          programSession: this.programSession,
          contractNumber: this.contractNumber,
        },
      };
      return body;
    },
    patchSubmissionDataForFormIo() {
      const body = [
        {
          op: "add",
          path: "/data/experienceType",
          value: this.experienceType,
        },
        {
          op: "add",
          path: "/data/location",
          value: this.location,
        },
        {
          op: "add",
          path: "/data/semester",
          value: this.semester,
        },
        {
          op: "add",
          path: "/data/programSession",
          value: this.programSession,
        },
      ];
      return body;
    },
    contractNumber() {
      return Object.hasOwnProperty.call(
        this.appliedAgreement,
        "contract_number"
      )
        ? this.appliedAgreement.contract_number
        : "";
    },
  },
  watch: {
    program: {
      handler: function () {
        if (!_.isEmpty(this.program)) {
          this.programSession = this.selectedProgramId;
          this.setLocation();
          this.semester = this.program.term;
          this.experienceType =
            this.program.site.country.toLowerCase() === "virtual"
              ? "virtual"
              : "abroad";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    eventBus.$emit("showSpinner", true);
    //only allow v2
    if (this.uiVersion !== "v2") {
      this.$router.push({
        name: "error-page",
        params: {
          message: "You are not allowed to access this program",
          code: "401",
          links: [{ path: "applications/new", label: "Select a program" }],
        },
      });
    }
    if (!this.currentApplicationId) {
      this.startNewApplication();
    }
    eventBus.$emit("showSpinner", false);
  },

  methods: {
    buildStringDateRange,
    redirectUser() {
      this.$store.commit("clearProgram");
      this.$store.commit("clearDirectProgram");
      this.$router.push({
        name: "applications/new",
        params: {
          slug: "program-selection",
        },
      });
    },
    async startNewApplication() {
      //create application ID if its a new direct program
      let appId =
        Date.now() +
        this.selectedProgramId +
        Math.random().toString().substring(3);
      appId = appId.substring(0, 32);
      this.updateApplicationId(appId);
    },
    updateApplicationId(id) {
      this.application_id = id;
      this.$emit("set-application-id", id);
    },
    async setLocation() {
      fetch(this.sitesURL)
        .then((response) => response.json())
        .then((data) => {
          this.location = data[this.program.site.city];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async submit() {
      //calling the startLoading method of the button, which enables the button loading state
      this.$refs.customProgramSubmitButton.startLoading();
      try {
        //check if a submission already exist and update if it does.
        this.submission_id = await this.formioSubmissionExists(
          this.formIoForm,
          this.currentApplicationId
        );
        await this.checkForApplicationId();
        await this.validateSingleForm();

        if (this.submission_id) {
          this.submitToFormIo(
            this.formIoForm,
            this.patchSubmissionDataForFormIo,
            "PATCH",
            this.submission_id
          ).then((response) => {
            if (response) {
              //move to next step
              eventBus.$emit("changeStep");
            }
          });
        } else {
          this.submitToFormIo(
            this.formIoForm,
            this.createSubmissionDataForFormIo,
            "post"
          ).then((response) => {
            if (response) {
              this.submission_id = response;
              //move to next step
              eventBus.$emit("changeStep");
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$refs.customProgramSubmitButton.stopLoading();
      }
    },
  },
  validations: {
    programSession: {
      required,
    },
    location: {
      required,
    },
    semester: {
      required,
    },
    experienceType: {
      required,
    },
  },
};
</script>
