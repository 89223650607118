<template>
  <div>
    <div
      :class="`${primaryColorClassBG}`"
      class="fixed inset-0 z-0 hidden opacity-50 sm:block"
    />
    <div
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
    >
      <div
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-32 sm:py-20 sm:rounded-lg sm:shadow"
        role="dialog"
        aria-modal="true"
        aria-labelledby="visa-info-modal-label"
      >
        <div class="flex flex-col items-center mb-10">
          <div class="flex justify-end w-full sm:hidden">
            <button
              class="block h-6 w-6 flex items-center justify-center"
              @click="$emit('close')"
            >
              <span class="sr-only">Close</span>
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
          <h2
            id="info-modal-modal-label"
            class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            VISA INFORMATION
          </h2>
        </div>

        <div class="text-gray-600 space-y-6">
          <p>
            If you are a U.S. citizen, you will need to obtain a student visa
            for the program to which you are applying. It is your responsibility
            as a student in this program to obtain a student visa before
            departure. Here are some common questions about the visa process:
          </p>
          <p>
            <strong>What is a visa? </strong><br />
            A visa is an official stamp or seal that is affixed to the inside of
            your passport before your departure from the United States.
          </p>
          <p>
            <strong>When do you have to apply for a visa?</strong><br />
            You cannot apply for the visa until you have been accepted into a
            program. More information about the visa process will be provided to
            you once you have been accepted.
          </p>
          <p>
            <strong>What happens after you apply for a visa?</strong><br />
            You will not be able to travel internationally between the time that
            you submit your visa application and when your visa/passport is
            returned to you. For this reason, API strongly discourages students
            from making international travel plans prior to the start of the
            program.
          </p>
          <p>
            <strong>What happens if I do not obtain a visa?</strong><br />
            Without a visa, you risk the possibility of deportation.
          </p>
          <p>
            <strong> How long does it usually take to obtain a visa? </strong
            ><br />
            Students are encouraged to allow 8 weeks for visa processing. It is
            very important that you complete your application for the API
            program quickly so that you can begin applying for your visa as soon
            as possible.
          </p>
          <p>
            <i>
              Note: API is not able to provide refunds for students who are
              unable to obtain a visa prior to their program start date. We will
              do our best to advise you when we receive your application if we
              feel that you do not have sufficient time to obtain the visa.
            </i>
          </p>
          <p>
            <strong>What are the general steps to obtaining a visa? </strong>
          </p>
          <ol>
            <li>1. Apply and gain acceptance into an API program.</li>

            <li>
              2. Review API materials to understand the steps required to obtain
              a visa for your specific destination.
            </li>

            <li>
              3. Begin gathering required visa documentation. API will provide
              guidance regarding the required documents after you have been
              accepted, and we will supply you with certain required documents.
            </li>

            <li>
              4. Submit your passport, visa application, and supporting
              documentation following the instructions provided.
            </li>

            <li>5. Wait for your passport to be returned with your visa.</li>
          </ol>
          <p>
            <strong>What if I am a minor? </strong><br />
            There may be additional requirements for minors. Contact your
            consulate for details.
          </p>
          <p>
            <strong>Do I need a passport to obtain my visa? </strong><br />

            Yes! If you do not have a passport, apply for one immediately. If
            you do have a passport, check the expiration date to ensure that the
            expiration date is at least 6 months after the end of your program.
            You will need to renew your passport immediately if it expires too
            soon after your program end date.
          </p>
        </div>

        <div class="mt-12">
          <button
            :class="[
              tertiaryColor
                ? `${tertiaryColorClass}`
                : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
            ]"
            class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-gray-700"
            @click="$emit('close')"
          >
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  emits: ["close"],
};
</script>

<style>
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
