<template>
  <div>
    <div class="mb-6">
      <p class="text-gray-600">
        The student named below has requested a letter of recommendation from
        you in support of their application to participate in an API program. We
        ask that you please fill out the requested information and respond to
        the questions below to the best of your ability and in as much detail as
        possible.
      </p>
      <div class="mb-6 space-y-6">
        <div>
          <h2
            class="mb-2 text-xs font-semibold tracking-widest text-gray-500 uppercase"
          >
            Student Name
          </h2>
          <p class="text-gray-600">
            {{ fullName }}
          </p>
        </div>
        <div>
          <h2
            class="mb-2 text-xs font-semibold tracking-widest text-gray-500 uppercase"
          >
            Student Program
          </h2>
          <p class="text-gray-600">
            {{ programName }}
          </p>
        </div>
      </div>
      <form
        role="form"
        aria-label="Abroad Letter of Recommendation"
        class="grid grid-cols-1 gap-4 account-creation-form md:grid-cols-2 md:gap-6"
        @submit.prevent="submitLetter()"
      >
        <label class="block text-sm md:col-span-2" for="name">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Your Name</span>
          </span>
          <input
            id="form-name"
            v-model="v$.formValues.name.$model"
            type="text"
            :class="{
              'form-input-error': v$.formValues.name.$error,
              [misc.inputclass]: true,
            }"
          />
          <p
            v-if="
              v$.formValues.name.$dirty && v$.formValues.name.required.$invalid
            "
            class="text-sm text-help-error font-semibold mt-2"
          >
            This field is required.
          </p>
        </label>
        <label class="block text-sm md:col-span-2" for="title">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Your Professional Title</span>
          </span>
          <input
            id="form-title"
            v-model="v$.formValues.title.$model"
            type="text"
            :class="{
              'form-input-error': v$.formValues.title.$error,
              [misc.inputclass]: true,
            }"
          />
          <p
            v-if="
              v$.formValues.title.$dirty &&
              v$.formValues.title.required.$invalid
            "
            class="text-sm text-help-error font-semibold mt-2"
          >
            This field is required.
          </p>
        </label>
        <label class="block text-sm md:col-span-2" for="institution">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Your Institution</span>
          </span>
          <input
            id="institution"
            v-model="v$.formValues.institution.$model"
            type="text"
            :class="{
              'form-input-error': v$.formValues.institution.$error,
              [misc.inputclass]: true,
            }"
          />
          <p
            v-if="
              v$.formValues.institution.$dirty &&
              v$.formValues.institution.required.$invalid
            "
            class="text-sm text-help-error font-semibold mt-2"
          >
            This field is required.
          </p>
        </label>
        <label class="block text-sm" for="email">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Your Email</span>
          </span>
          <input
            id="email"
            v-model="v$.formValues.email.$model"
            type="email"
            :class="{
              'form-input-error': v$.formValues.email.$error,
              [misc.inputclass]: true,
            }"
          />
          <p
            v-if="v$.formValues.email.$error"
            class="text-sm text-help-error font-semibold mt-2"
          >
            Please use a valid email.
          </p>
          <p
            v-if="
              v$.formValues.email.$dirty &&
              v$.formValues.email.required.$invalid
            "
            class="text-sm text-help-error font-semibold mt-2"
          >
            This field is required.
          </p>
        </label>
        <label class="block text-sm" for="telephone">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Your Telephone</span>
          </span>
          <input
            id="telephone"
            v-model="v$.formValues.phone.$model"
            type="text"
            :class="{
              'form-input-error': v$.formValues.phone.$error,
              [misc.inputclass]: true,
            }"
          />
          <p
            v-if="
              v$.formValues.phone.$dirty &&
              v$.formValues.phone.required.$invalid
            "
            class="text-sm text-help-error font-semibold mt-2"
          >
            This field is required.
          </p>
        </label>
        <label id="relation" class="block text-sm md:col-span-2">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>
              How long and in what capacity have you known the applicant?
            </span>
          </span>
          <input
            id="relation"
            v-model="v$.formValues.applicantRelationship.$model"
            type="text"
            :class="{
              'form-input-error': v$.formValues.applicantRelationship.$error,
              [misc.inputclass]: true,
            }"
          />
          <p
            v-if="
              v$.formValues.applicantRelationship.$dirty &&
              v$.formValues.applicantRelationship.required.$invalid
            "
            class="text-sm text-help-error font-semibold mt-2"
          >
            This field is required.
          </p>
        </label>
        <div class="mt-6 md:col-span-2">
          <p class="mb-4 text-gray-600">
            Living overseas requires discipline, self-confidence and the ability
            to be flexible to new living and learning environments. Based on
            your knowledge of the applicant, please give us your opinion of the
            student's ability to successfully make the necessary adjustments.
          </p>
          <label class="block" for="opinion">
            <span
              class="font-semibold text-gray-600 flex justify-between text-sm sr-only"
            >
              <span>Opinion of Student</span>
            </span>
            <textarea
              id="opinion"
              v-model="v$.formValues.comment1.$model"
              :maxlength="v$.formValues.comment1.maxLength.$params.max"
              rows="4"
              class="form-input min-h-10 block w-full"
              :class="{ 'form-input-error': v$.formValues.comment1.$error }"
            />
            <div class="grid grid-cols-2">
              <span
                class="justify-end col-start-2 text-sm text-right text-gray-500"
              >
                {{ formValues.comment1.length }}/{{
                  v$.formValues.comment1.maxLength.$params.max
                }}
              </span>
            </div>
            <p
              v-if="v$.formValues.comment1.maxLength.$invalid"
              class="text-sm text-help-error font-semibold mt-2"
            >
              Exceeding
              {{ v$.formValues.comment1.maxLength.$params.max }} character limit
            </p>
            <p
              v-if="
                v$.formValues.comment1.$dirty &&
                v$.formValues.comment1.required.$invalid
              "
              class="text-sm text-help-error font-semibold mt-2"
            >
              This field is required.
            </p>
          </label>
        </div>
        <div class="mt-6 md:col-span-2">
          <p class="mb-4 text-gray-600">
            Comment on the following topics regarding this applicant: 1)
            Interest and sensitivity to cultures other than his/her own; 2)
            Intellectual qualities and promise for the future; 3) Ability to
            handle challenges and adapt to new situations; 4) Suitability for
            chosen course of study; 5) Artistic, athletic, social, and other
            interests or achievements.
          </p>
          <label class="block" for="comments">
            <span
              class="font-semibold text-gray-600 flex justify-between text-sm sr-only"
            >
              <span>Comments</span>
            </span>
            <textarea
              id="comments"
              v-model="v$.formValues.comment2.$model"
              :maxlength="v$.formValues.comment2.maxLength.$params.max"
              rows="4"
              class="form-input min-h-10 block w-full"
              :class="{ 'form-input-error': v$.formValues.comment2.$error }"
            />
            <div class="grid grid-cols-2">
              <span
                class="justify-end col-start-2 text-sm text-right text-gray-500"
              >
                {{ formValues.comment2.length }}/{{
                  v$.formValues.comment2.maxLength.$params.max
                }}
              </span>
            </div>
            <p
              v-if="v$.formValues.comment2.maxLength.$invalid"
              class="text-sm text-help-error font-semibold mt-2"
            >
              Exceeding
              {{ v$.formValues.comment2.maxLength.$params.max }} character limit
            </p>
            <p
              v-if="
                v$.formValues.comment2.$dirty &&
                v$.formValues.comment2.required.$invalid
              "
              class="text-sm text-help-error font-semibold mt-2"
            >
              This field is required.
            </p>
          </label>
        </div>
        <label class="block text-sm">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-x-4">
            <label class="block md:col-span-3 gap-0 mb-2">
              Date
            </label>
            <div class="block">
              <select
                id="month"
                v-model="v$.dates.month.$model"
                class="form-select block w-full text-sm min-h-10"
                aria-label="month"
              >
                <option value="" selected disabled> Choose a month</option>
                <option
                  v-for="(month, index) in dates.months"
                  :key="month + '-' + index"
                  :value="index"
                >
                  {{ month }}
                </option>
              </select>
              <label class="gap-0 mb-2" for="month">
                Month
              </label>
              <p
                v-if="v$.dates.month.$dirty && v$.dates.month.required.$invalid"
                class="text-sm text-help-error font-semibold mt-2"
              >
                This field is required.
              </p>
            </div>
            <div class="block">
              <input
                id="day"
                v-model="v$.dates.day.$model"
                :class="{
                  'form-input-error': v$.dates.day.$error,
                  [misc.inputclass]: true,
                }"
                type="number"
                min="1"
                :max="maxDay"
                :disabled="dates.month === ''"
                maxlength="2"
                placeholder="day"
                aria-label="day"
              />
              <label class="gap-0 mb-2" for="day">
                Day
              </label>
              <p
                v-if="v$.dates.day.$dirty && v$.dates.day.required.$invalid"
                class="text-sm text-help-error font-semibold mt-2"
              >
                This field is required.
              </p>
              <p
                v-if="v$.dates.day.$error"
                class="text-sm text-help-error font-semibold mt-2"
              >
                <template v-if="v$.dates.day.maxValue.$invalid">
                  Day can't be higher than
                  {{ v$.dates.day.maxValue.$params.max }}
                </template>
              </p>
            </div>
            <div class="block">
              <input
                id="year"
                v-model="v$.dates.year.$model"
                :class="{
                  'form-input-error': v$.dates.year.$error,
                  [misc.inputclass]: true,
                }"
                type="number"
                min="1900"
                max="2049"
                maxlength="4"
                placeholder="Year"
                aria-label="year"
              />
              <label class="gap-0 mb-2" for="year">
                Year
              </label>
              <p
                v-if="v$.dates.year.$dirty && v$.dates.year.required.$invalid"
                class="text-sm text-help-error font-semibold mt-2"
              >
                This field is required.
              </p>
              <p
                v-if="v$.dates.year.$error"
                class="text-sm text-help-error font-semibold mt-2"
              >
                <template v-if="v$.dates.year.minLength.$invalid">
                  Year should have 4 digits
                </template>
              </p>
            </div>
          </div>
        </label>
        <label class="block text-sm">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Signature</span>
          </span>
          <input
            v-model="v$.formValues.signature.$model"
            type="text"
            :class="{
              'form-input-error': v$.formValues.signature.$error,
              [misc.inputclass]: true,
            }"
          />
          <p
            v-if="
              v$.formValues.signature.$dirty &&
              v$.formValues.signature.required.$invalid
            "
            class="text-sm text-help-error font-semibold mt-2"
          >
            This field is required.
          </p>
        </label>
        <div class="mt-6 md:col-span-2">
          <p
            v-if="v$.formValues.applicationId.$invalid"
            class="text-sm text-help-error font-semibold mt-2"
          >
            You can't submit this form without an application id, reload the
            page and try again.
          </p>
          <button
            :disabled="v$.$invalid"
            type="submit"
            :class="[
              tertiaryColor
                ? `${tertiaryColorClass}`
                : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
            ]"
            class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-gray-700"
          >
            <span>I Submit and Certify</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  maxLength,
  maxValue,
  minValue,
  minLength,
  email,
} from "@vuelidate/validators";
import { buildStringDateRange } from "../../../mixins/helpers";
import formService from "@/services/form";
import useVuelidate from "@vuelidate/core";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  name: "AbroadLetterOfRecommendation",
  props: {
    student: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
  },
  emits: ["update-component"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formValues: {
        applicationId: "",
        name: "",
        title: "",
        institution: "",
        email: "",
        phone: "",
        applicantRelationship: "",
        comment1: "",
        comment2: "",
        date: "",
        signature: "",
      },
      largeApiNames: {
        applicantKnowledge: "howLongAndInWhatCapacityHaveYouKnownTheApplicant",
        overseas:
          "livingOverseasRequiresDisciplineSelfConfidenceAndTheAbilityToBeFlexibleToNewLivingAndLearningEnvironmentsBasedOnYourKnowledgeOfTheApplicantPleaseGiveUsYourOpinionOfTheStudentsAbilityToSuccessfullyMakeTheNecessaryAdjustments",
        topics:
          "commentOnTheFollowingTopicsRegardingThisApplicant1InterestAndSensitivityToCulturesOtherThanHisHerOwn2IntellectualQualitiesAndPromiseForTheFuture3AbilityToHandleChallengesAndAdaptToNewSituations4SuitabilityForChosenCourseOfStudy5ArtisticAthleticSocialAndOtherInterestsOrAchievements",
      },
      button: {
        message: "I Submit and Certify",
        doingSubmission: false,
        finished: false,
      },
      misc: {
        inputclass: "form-input block w-full min-h-10 disabled:bg-gray-300",
      },
      dates: {
        month: "",
        day: undefined,
        year: undefined,
        months: months,
      },
    };
  },
  computed: {
    fullName() {
      let firstname = Object.hasOwnProperty.call(this.student, "firstname")
        ? this.student["firstname"]
        : "";
      let lastname = Object.hasOwnProperty.call(this.student, "lastname")
        ? this.student["lastname"]
        : "";
      return firstname + " " + lastname;
    },
    programName() {
      let startDate = Object.hasOwnProperty.call(this.program, "start_date")
        ? this.program["start_date"]
        : "";
      let endDate = Object.hasOwnProperty.call(this.program, "end_date")
        ? this.program["end_date"]
        : "";
      let dates = buildStringDateRange(startDate, endDate);
      let programName = Object.hasOwnProperty.call(this.student, "program")
        ? this.student["program"]
        : "";

      return programName + " (" + dates + ")";
    },
    leapYear() {
      let date = "";
      let year = "";
      if (!this.dates.year) date = new Date(); // Take Current Year?
      if (this.dates.year) date = new Date(this.dates.year, 1);
      year = date.getFullYear();
      return (year & 3) == 0 && (year % 25 != 0 || (year & 15) == 0);
    },
    maxDay() {
      const thirtyDayMonths = [3, 5, 8, 10]; // Months are index 0 based
      if (this.dates.month === "") return 1;
      if (this.dates.month === 1 && this.leapYear) return 29;
      if (this.dates.month === 1 && !this.leapYear) return 28;
      if (thirtyDayMonths.includes(this.dates.month)) return 30;
      return 31;
    },
    formattedMonth() {
      return this.dates.month === 0 || this.dates.month
        ? this.dates.month + 1
        : "00";
    },
    formattedYear() {
      return this.dates.year ? this.dates.year : "0000";
    },
    formattedDay() {
      return this.dates.day
        ? this.dates.day <= 9
          ? "0" + this.dates.day
          : this.dates.day
        : "00";
    },
    finalDate() {
      return (
        this.formattedMonth + "/" + this.formattedDay + "/" + this.formattedYear
      );
    },
  },
  watch: {
    "dates.month": {
      handler: function () {
        this.replaceDay();
      },
      immediate: true,
    },
    "dates.year": {
      handler: function () {
        this.replaceDay();
      },
      immediate: true,
    },
  },
  created() {
    this.formValues["applicationId"] = this.student["application_id"];
  },

  validations() {
    return {
      formValues: {
        applicationId: {
          required,
        },
        name: {
          required,
        },
        title: {
          required,
        },
        institution: {
          required,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
        },
        applicantRelationship: {
          required,
        },
        comment1: {
          required,
          maxLength: maxLength(2000),
        },
        comment2: {
          required,
          maxLength: maxLength(2000),
        },
        date: {},
        signature: {
          required,
        },
      },
      dates: {
        month: {
          required,
        },
        day: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.maxDay),
          minLength: minLength(1),
          maxLength: maxLength(2),
        },
        year: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(4),
        },
      },
    };
  },
  methods: {
    submitLetter() {
      this.setButtonBehavior("start-submission");
      this.formioSubmission()
        .then(() => {
          this.setButtonBehavior("end-submission-ok");
          this.$emit("update-component", "thank-you");
        })
        .catch(() => {
          this.setButtonBehavior("end-submission-fail");
        });
    },
    formioSubmission() {
      return formService.createSubmission("letterOfRecommendationAbroad", {
        applicationId: this.formValues["applicationId"],
        yourName: this.formValues["name"],
        yourProfessionalTitle: this.formValues["title"],
        yourInstitution: this.formValues["institution"],
        yourEmail: this.formValues["email"],
        yourTelephone: this.formValues["phone"],
        [this.largeApiNames.applicantKnowledge]: this.formValues[
          "applicantRelationship"
        ],
        [this.largeApiNames.overseas]: this.formValues["comment1"],
        [this.largeApiNames.topics]: this.formValues["comment2"],
        date: this.finalDate,
        signature: this.formValues["signature"],
      });
    },
    setButtonBehavior(action = "") {
      switch (action) {
        case "start-submission":
          this.button["doingSubmission"] = true;
          this.button["message"] = "Submitting, please wait.";
          break;
        case "end-submission-ok":
          this.button["doingSubmission"] = false;
          this.button["message"] = "Submission complete.";
          this.button["finished"] = true;
          break;
        case "end-submission-fail":
          this.button["doingSubmission"] = false;
          this.button["message"] = "Submission failed, retry.";
          break;
      }
    },
    replaceDay() {
      if (this.maxDay < this.dates.day) this.dates.day = this.maxDay;
    },
  },
};
</script>
