<template>
  <div>
    <slot name="custom">
      <fieldset
        v-for="(item, index) in value.courses"
        :key="index"
        class="mb-4"
      >
        <div class="px-4 pb-6 mt-6 -mx-4 sm:mx-0 sm:px-0 lg:pb-0 lg:mt-0">
          <div class="grid grid-cols-1 gap-y-3 lg:grid-cols-12 lg:gap-x-6">
            <div class="lg:col-span-3">
              <label
                class="text-xs font-semibold text-gray-500 uppercase"
                :for="pathName + '-course-title-' + index"
              >
                Course Title <span class="text-error-900">*</span>
              </label>
              <div :class="rowClass">
                <label
                  :class="labelClass"
                  :for="pathName + '-course-title-' + index"
                >
                  #{{ index + 1 }}
                  <span class="sr-only">Course Title</span>
                </label>
                <div class="w-full">
                  <input
                    :id="pathName + '-course-title-' + index"
                    v-model="item.title"
                    class="form-input w-full"
                    :class="{
                      'form-input-error':
                        v$.value.courses.$each.$response.$data[index].title
                          .$error,
                    }"
                    type="text"
                    :maxlength="80"
                  />
                  <p
                    v-if="
                      v$.value.courses.$each.$response.$data[index].title
                        .$invalid
                    "
                    class="text-sm text-help-error font-semibold mt-2"
                  >
                    <template
                      v-if="
                        !v$.value.courses.$each.$response.$data[index].title
                          .required &&
                        v$.value.courses.$each.$response.$data[index].title
                          .$error
                      "
                    >
                      This field is required
                    </template>
                    <template
                      v-if="
                        !v$.value.courses.$each.$response.$data[index].title
                          .maxLength
                      "
                    >
                      Title must be under 80 characters
                    </template>
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:col-span-1">
              <label
                :for="pathName + '-course-code-' + index"
                class="text-xs font-semibold text-gray-500 uppercase"
              >
                Course Code
              </label>
              <div :class="rowClass.replace('mt-6', '')" class="mt-0">
                <label
                  :class="labelClass"
                  class="sr-only"
                  :for="pathName + '-course-code-' + index"
                >
                  Course Code
                </label>
                <input
                  :id="pathName + '-course-code-' + index"
                  v-model="item.code"
                  class="form-input w-full"
                  type="text"
                />
              </div>
            </div>
            <div class="lg:col-span-2">
              <label
                :for="pathName + '-course-section-' + index"
                class="text-xs font-semibold text-gray-500 uppercase"
              >
                Section
              </label>
              <div :class="rowClass">
                <label
                  :class="labelClass"
                  class="sr-only"
                  :for="pathName + '-course-section-' + index"
                >
                  Section
                </label>
                <input
                  :id="pathName + '-course-section-' + index"
                  v-model="item.section"
                  class="form-input w-full"
                  type="text"
                />
              </div>
            </div>
            <div class="lg:col-span-2">
              <label
                :for="pathName + '-course-credits-' + index"
                class="text-xs font-semibold text-gray-500 uppercase"
              >
                Credits
              </label>
              <div :class="rowClass">
                <label
                  :class="labelClass"
                  class="sr-only"
                  :for="pathName + '-course-credits-' + index"
                >
                  Credits
                </label>
                <div class="w-full">
                  <input
                    :id="pathName + '-course-credits-' + index"
                    v-model="item.credits"
                    class="form-input w-full"
                    :class="{
                      'form-input-error':
                        v$.value.courses.$each.$response.$data[index].credits
                          .$error,
                    }"
                    type="number"
                    min="0"
                    max="99.99"
                    step="any"
                    @blur="addDecimals()"
                  />
                  <p
                    v-if="
                      v$.value.courses.$each.$response.$data[index].credits
                        .$invalid
                    "
                    class="text-sm text-help-error font-semibold mt-2"
                  >
                    <template
                      v-if="
                        !v$.value.courses.$each.$response.$data[index].credits
                          .minValue
                      "
                    >
                      Credits can't be lower than 0 <br />
                    </template>
                    <template
                      v-if="
                        !v$.value.courses.$each.$response.$data[index].credits
                          .maxValue
                      "
                    >
                      Credits can't be higher than 99.99
                      <br />
                    </template>
                    <template
                      v-if="
                        !v$.value.courses.$each.$response.$data[index].credits
                          .decimalRegex &&
                        v$.value.courses.$each.$response.$data[index].credits
                          .$error
                      "
                    >
                      Credits can't have more than 2 decimals
                    </template>
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:col-span-2">
              <label
                :for="pathName + '-course-language-of-instruction-' + index"
                class="text-xs font-semibold text-gray-500 uppercase"
              >
                Language of instruction
              </label>
              <div :class="rowClass">
                <label
                  :class="labelClass"
                  class="sr-only"
                  :for="pathName + '-course-language-of-instruction-' + index"
                >
                  Language of instruction
                </label>
                <VSelect
                  :id="pathName + '-course-language-of-instruction-' + index"
                  v-model="item.languageInstruction"
                  :options="languages"
                  :get-option-label="(option) => option.label"
                  aria-label="Language of instruction"
                  :class="{
                    'w-full': true,
                  }"
                />
              </div>
            </div>
            <div class="lg:col-span-1">
              <label
                :for="pathName + '-course-major-' + index"
                class="text-xs font-semibold text-gray-500 uppercase"
              >
                Required for major
              </label>
              <div :class="rowClass.replace('mt-6', 'mt-4')">
                <label
                  :class="labelClass"
                  class="sr-only"
                  :for="pathName + '-course-major-' + index"
                >
                  Required for major
                </label>
                <div
                  class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                >
                  <input
                    :id="pathName + '-course-major-' + index"
                    v-model="item.majorRequired"
                    class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                    type="checkbox"
                  />
                  <label
                    :for="pathName + '-course-major-' + index"
                    class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>
              </div>
            </div>
            <div class="lg:col-span-1">
              <label class="sr-only">
                Delete field
              </label>
              <button class="md:mt-12" @click="removeCourse(index)">
                <svg
                  focusable="false"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                  ></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </slot>
    <slot name="addButton">
      <div class="pt-4 mt-4 lg:border-t">
        <button
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
          ]"
          class="flex items-center"
          :disabled="value.courses.length >= limit"
          @click="addCourse"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </span>
          <span class="ml-2 text-sm font-semibold text-gray-600">
            Another Course
          </span>
        </button>
      </div>
    </slot>
    <p
      v-if="v$.value.courses.$invalid"
      class="text-sm text-help-error font-semibold mt-2"
    >
      <template v-if="!v$.value.courses.required">
        At least {{ v$.value.courses.$params.minLength.min }} item{{
          v$.value.courses.$params.minLength.min > 1 ? "s" : ""
        }}
        required
      </template>
    </p>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  helpers,
} from "@vuelidate/validators";
import nestedMixing from "@/mixins/nestedModelComponent";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";

const ROW_CLASS =
  "mt-6 flex flex-col lg:flex-row lg:items-center lg:col-span-3";
const LABEL_CLASS =
  "mb-2 text-xs font-semibold tracking-widest text-gray-500 uppercase lg:mb-0 lg:mr-4";
const decimalRegex = helpers.regex(/^(\d{0,3})(\.\d{1,2})?$/);
export default {
  name: "CourseCustom",
  mixins: [nestedMixing],
  props: {
    languages: {
      type: Array,
      default: function () {
        return [];
      },
    },
    limit: {
      type: Number,
      default: 6,
    },
    pathName: {
      type: String,
      default: "courseSelection",
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        courses: [
          {
            title: null,
            code: null,
            section: null,
            credits: 0,
            languageInstruction: null,
            majorRequired: false,
          },
        ],
      },
      rowClass: ROW_CLASS,
      labelClass: LABEL_CLASS,
    };
  },
  computed: {
    ...mapState(["program"]),
    existingIndexes() {
      return this.formData.length - 1;
    },
  },
  watch: {
    formData: {
      handler: function (newval) {
        this.setCourses(newval);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.v$.value.courses.$each.$response.$data[0].title.required = true;
  },
  validations() {
    return this.setValidations();
  },
  methods: {
    addOneIfEmpty() {
      if (!this.value.courses.length) this.addCourse();
    },
    setCourses(data) {
      if (!Object.hasOwnProperty.call(data, this.pathName)) return;
      while (this.value.courses.length > 0) {
        this.value.courses.pop();
      }
      data[this.pathName].forEach((course) => {
        let hasSection = Object.hasOwnProperty.call(course, "courseSection");
        let hasCode = Object.hasOwnProperty.call(course, "courseCode");
        let hasCredits = Object.hasOwnProperty.call(course, "credits");
        let hasLang = Object.hasOwnProperty.call(
          course,
          "languageOfInstruction"
        );
        let hasMajor = Object.hasOwnProperty.call(course, "requiredForMajor");
        this.value.courses.push({
          title: course.courseTitle,
          code: hasCode ? course.courseCode : "",
          section: hasSection ? course.courseSection : "",
          credits: hasCredits ? course.credits : 0,
          languageInstruction:
            hasLang &&
            course.languageOfInstruction &&
            Object.keys(course.languageOfInstruction).length !== 0 &&
            course.languageOfInstruction.constructor === Object
              ? course.languageOfInstruction
              : null,
          majorRequired: hasMajor ? course.requiredForMajor : false,
          op: "replace",
        });
      });
    },
    setValidations() {
      return {
        value: {
          courses: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(this.limit),
            $each: helpers.forEach({
              title: {
                required,
                maxLength: maxLength(80),
              },
              credits: {
                minLength: minLength(1),
                minValue: minValue(0),
                maxValue: maxValue(99.99),
                decimalRegex,
              },
              code: {},
              section: {},
              languageInstruction: {},
              majorRequired: {},
            }),
          },
        },
      };
    },
    addCourse() {
      if (
        this.value.courses.length === this.limit ||
        this.value.courses.length > this.limit
      )
        return;
      this.value.courses.push({
        title: "",
        code: "",
        section: "",
        credits: 0,
        languageInstruction: null,
        majorRequired: false,
      });
    },
    removeCourse(index) {
      this.value.courses.splice(index, 1);
    },
    addDecimals() {
      this.value.courses.forEach((course) => {
        if (course.credits) {
          const dec = course.credits.toString().split(".")[1];

          if (dec === undefined || dec.length <= 2)
            course.credits = parseFloat(course.credits).toFixed(2);
        }
      });
    },
  },
};
</script>
