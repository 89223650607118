import { ACTIVE_STATUS } from "@/constants";
import { checkPricingUtilIsValidPrice } from "@/services/utils";

export const checkValidRequestedRoom = (room, startDate) =>
  room?.status == ACTIVE_STATUS &&
  !!room?.product_prices?.some((price) =>
    checkPricingUtilIsValidPrice(
      price.effective_start_date,
      price.effective_end_date,
      startDate
    )
  );
