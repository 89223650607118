<template>
  <div class="space-y-6">
    <p>
      In planning your departure, it’s important our participants keep in mind
      the date up until which their housing accommodations are available. In
      addition, passport and visa / permit regulations are important in your
      planning should you wish to extend your stay for independent travel after
      your program concludes.
    </p>
    <div class="grid grid-cols-2 gap-y-2 gap-x-6">
      <div
        class="text-xs font-semibold tracking-widest text-gray-500 uppercase"
      >
        Departure Date
      </div>
      <div class="text-gray-650">
        <program-dates
          :id="'departure-dates'"
          :dates="departureDate"
          :start-is-final="isFinalDate"
          :end-is-final="isFinalDate"
        />
      </div>
    </div>
    <p v-if="rule === 'CP Departure Guidelines'">
      API housing is available up until the departure date and time. Group
      airport return is provided on the program end date. There are no academic
      or programming activities on the departure date.
    </p>
    <p v-else>
      API housing is available up until the departure date and time.
      Participants are responsible for arranging and paying for transit to the
      airport or another destination. There are no academic or programming
      activities on the departure date.
    </p>
  </div>
</template>

<script>
import programDatesComponent from "../../ProgramDates.vue";
export default {
  name: "DepartureGuidelines",
  components: {
    programDates: programDatesComponent,
  },
  props: {
    rule: {
      type: String,
      default: "",
    },
    departureDate: {
      type: String,
      default: "",
    },
    isFinalDate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
