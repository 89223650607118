<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { computed, watch, ref, unref } from "vue";
import { useGetLimitedProfiles } from "@/components/program-manager/sessions/composable";
import SimplePopup from "@/components/shared/SimplePopup.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import LoadingText from "./components/LoadingText";

const props = defineProps({
  sessionProfiles: {
    type: Array,
    required: true,
  },
});

const profileIds = computed(() =>
  props.sessionProfiles?.map((item) => item.profile_id)
);

const showSection = ref(profileIds.value?.length ? true : false);
const openModal = ref(false);
const userInfo = ref({});

const {
  execute: fetchProfile,
  state: profilesData,
  isLoading: profileLoading,
} = useGetLimitedProfiles(
  {
    immediate: showSection.value,
    throwError: false,
    resetOnExecute: true,
  },
  {
    limit: 10,
    skip: 0,
    extraParams: { profile_ids: profileIds.value },
  }
);

watch(
  () => props.sessionProfiles,
  (value) => {
    if (value.length) {
      showSection.value = true;
      fetchProfile(0, {
        cancel: true,
        extraParams: { profile_ids: profileIds.value },
      });
    }
  }
);

const profileRole = (profile_id) => {
  return (
    props.sessionProfiles?.find((item) => item.profile_id === profile_id)
      ?.role || "SUPPORT"
  )?.toUpperCase();
};

const openBioInfo = (user) => {
  openModal.value = true;
  userInfo.value = unref(user);
};
</script>
<template>
  <div v-if="showSection" class="text-indigo-base pt-20 px-4 md:px-8">
    <SimplePopup
      :open="openModal"
      custom-class="z-30 font-montserrat"
      @close="openModal = false"
    >
      <template #title>
        <div class="flex self-center">
          <div class="self-center pl-2 text-sm">
            {{ userInfo?.full_name || "" }}
          </div>
        </div>
      </template>
      <template #content>
        <p class="max-w-md">{{ userInfo?.bio || "" }}</p>
      </template>
    </SimplePopup>
    <section id="team-title" class="mb-11">
      <div class="font-bold text-indigo-base text-4xl">
        <div class="text-2xl">Your Program</div>
        <div>TEAM</div>
      </div>
    </section>
    <div>
      <section id="team-information">
        <LoadingText v-if="profileLoading || !profileIds?.length" />
        <div v-else-if="profilesData?.items">
          <div
            v-for="data in profilesData?.items"
            :key="data.id"
            class="flex mb-10"
          >
            <div class="aspect-5/4 w-28 sm:w-24 flex-none">
              <img
                v-if="data?.headshot"
                class="w-full hover:scale-110 duration-300"
                :src="data.headshot"
                loading="lazy"
              />
              <div v-else class="w-full h-full bg-blue-300 flex justify-center">
                <img :src="'/images/icon-profile.svg'" />
              </div>
            </div>
            <div class="ml-10">
              <h3 class="font-base sm:font-bold text-lg mb-4 max-w-md">
                {{ profileRole(data.id) }},
                <strong>{{ data?.full_name || "" }}</strong>
              </h3>
              <div v-if="data?.bio">
                <button
                  class="flex items-center text-teal-900 font-bold hover:opacity-80 sm:hidden"
                  @click="openBioInfo(data)"
                >
                  READ BIO <ArrowRight class="ml-1" />
                </button>
              </div>
              <p v-if="data?.bio" class="hidden sm:block max-w-md">
                {{ data?.bio || "" }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <hr class="my-1 border-b-3 md:mt-20 border-gray-200" />
  </div>
</template>
<style scoped>
.aspect-5\/4 {
  aspect-ratio: 5 / 4;
}
</style>
