import { getApiClient } from "./API";

const studentService = {
  async listStudents(page = 1, searchingCriteria = "") {
    let query = `/students?page=${page}&q=${searchingCriteria}`;
    return (await getApiClient().get(query)).data;
  },
};

export default studentService;
