<template>
  <div id="students" class="w-full mx-auto max-w-7xl md:px-8 md:mb-8">
    <div class="mx-4 my-6 md:flex md:items-center md:justify-between md:mx-0">
      <h1 data-testid="my-students-title"  class="mr-4 text-2xl font-semibold">My Students</h1>

      <div class="grid grid-cols-2 gap-4 mt-4 md:flex">
        <div id="searchbox" role="search" aria-label="Students"></div>
        <div id="filter"></div>
      </div>
    </div>
    <tabs />

    <tab-content :grand-parent-mounted="componentMounted" />
    <modal
      v-if="prospectiveModal"
      :id="'prospective'"
      :title="'Add a prospective student'"
      @closeModal="closeModal"
    >
      <template #modal-content>
        <prospective-content :in-modal="true" @close="closeModal" />
      </template>
    </modal>
  </div>
</template>

<script>
import modal from "@/university/components/modal.vue";
import prospectiveContent from "@/university/components/SiteComponents/prospectiveStudent.vue";
import tabContent from "@/university/components/dataTables/TabContentComponent";
import tabs from "@/university/components/dataTables/TabsComponent";

export default {
  components: {
    tabs,
    tabContent,
    modal,
    prospectiveContent,
  },
  data() {
    return {
      prospectiveModal: false, // show prospective modal
      priceBreakDownModal: false,
      componentMounted: false,
    };
  },
  mounted() {
    this.componentMounted = true;
  },
  created() {
    this.$store.commit("university/setApplicationId", ""); // Remove application ID when entering to table
  },
  methods: {
    closeModal() {
      this.prospectiveModal = false;
    },
    openModal() {
      this.prospectiveModal = true;
    },
    openPriceBreak() {
      this.priceBreakDownModal = true;
    },
    closePriceBreak() {
      this.priceBreakDownModal = false;
    },
  },
};
</script>
