<template>
  <div role="region" aria-label="Enrollment Landing">
    <div
      v-if="applicationWithDrawn"
      class="w-full bg-warning-900 sm:h-20 md:h-10 p-4 flex items-center justify-center rounded mb-4"
    >
      <p class="text-white text-base ml-auto">
        Your application has been withdrawn from this program. Please contact
        your program manager with any questions.
      </p>
      <p
        data-cy="travel-banner"
        class="text-white text-base ml-auto mr-4 cursor-pointer"
      >
        X
      </p>
    </div>
    <template v-if="loading || !Boolean(formioToken)">
      <spinner>
        <template v-if="Boolean(formioToken)" #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications',
            }"
            :class="[
              secondaryColor
                ? `${secondaryColorClass}`
                : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
            ]"
            class="underline hover:no-underline"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
    </template>
    <div
      v-else
      class="w-full px-4 pb-4 mx-auto applications max-w-7xl sm:px-8 sm:pb-8"
    >
      <header class="my-6 sm:my-10" role="banner" aria-labelledby="summary">
        <span id="summary" class="hidden">Program Summary</span>
        <div v-if="shouldShowViewApplicationCTA" class="mb-4">
          <a
            :href="returnToApplication"
            class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
            <span class="ml-1">View Application</span>
          </a>
        </div>
        <div class="sm:flex justify-between items-center">
          <h1
            v-if="program.program"
            class="mb-2 text-xl font-semibold md:text-2xl"
          >
            {{ program.program.name }}
          </h1>
          <div class="sm:flex justify-between items-center">
            <p
              v-if="program.start_date && program.end_date"
              class="text-xs text-gray-500 tracking-widest font-semibold"
            >
              PROGRAM DATES
            </p>
            <program-dates
              :id="selectedProgramId"
              :dates="buildStringDateRange"
              :start-is-final="program.start_date_is_final > 0"
              :end-is-final="program.end_date_is_final > 0"
              tag="span"
              class="sm:ml-4 text-sm text-black font-extralight tracking-normal"
            />
          </div>
        </div>
        <TimelineBar
          :copy="copy"
          :days-until-start="daysUntilStart"
          :application-id="$route.params.applicationId"
          :add-payment="addPaymentStep"
        />
        <div class="flex justify-end md:hidden">
          <p
            :class="`${secondaryColorClass}`"
            class="font-semibold text-xs cursor-pointer underline mt-5px"
            @click="toggleModalProgramChange(displayProgramChangeModal)"
          >
            Program Change, Deferral or Withdraw
          </p>
        </div>
        <div
          v-if="showCancellationPolicy"
          class="text-sm mt-5px text-right md:hidden"
        >
          <a
            data-cy="cancellation-policy-link"
            :class="`${secondaryColorClass}`"
            class="font-semibold text-xs cursor-pointer underline"
            :href="cancellationPolicyLink"
            target="_blank"
          >
            Withdrawal policy
          </a>
        </div>
        <ModalComponent
          :open="displayProgramChangeModal"
          :title="'Program Change, Deferral or Withdraw'"
          @close-modal="toggleModalProgramChange(displayProgramChangeModal)"
        >
          <template #modal-content>
            <ProgramChangeEnrollment
              :manager-name="
                program.program_manager ? program.program_manager : ''
              "
              :manager-mail="
                program.program_manager_email
                  ? program.program_manager_email
                  : ''
              "
            />
          </template>
          <template #modal-footer>
            <button
              :class="[
                tertiaryColor
                  ? `${tertiaryColorClass}`
                  : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
              ]"
              class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-gray-700"
              @click="toggleModalProgramChange(displayProgramChangeModal)"
            >
              <span>Close</span>
            </button>
          </template>
        </ModalComponent>
      </header>
      <div v-if="programLink" class="text-center bg-white shadow-md p-8">
        <a
          class="text-cyan-700 text-xl font-bold"
          target="_blank"
          :href="programLink"
          >Review and select your housing here.</a
        >
      </div>
      <div
        class="grid gap-6 xs:gap-10"
        :class="[displayToDo ? 'grid-cols-12' : 'grid-cols-1']"
      >
        <section :class="[displayToDo ? 'col-span-12 md:col-span-8' : '']">
          <MobileAppEnrollmentBanner v-if="showMobileAppBanner" />

          <OnboardingSection
            v-if="!applicationWithDrawn"
            :show-housing-questionnaire="showHousingQuestionnaireStep1"
            :form-submissions="formSubmissions"
          />
          <section v-if="showPaymentSection">
            <PaymentSection
              v-if="uiVersion === 'v2'"
              :sort-payment-cards="sortPaymentCards"
              :display-to-do="displayToDo"
              :show-financial-aid="showFinancialAid"
            />
            <PaymentsSectionV3
              v-else-if="uiVersion === 'v3'"
              :invoices="invoices"
              :display-to-do="displayToDo"
              :show-financial-aid="showFinancialAidV3"
              :show-confirmation-payment="showConfirmationPaymentButton"
              :date-accepted="dateAccepted"
              :financial-aid-chosen="financialAidChosen"
              :currently-financial-aid="currentlyFinancialAid"
              @trigger-confirmation-payment-modal="
                confirmationPaymentModalActivated = true
              "
              @close:modal="handleCloseModal"
              @open:tab="refreshPage"
            />
          </section>
          <PreDeparture
            v-if="showPreDepartureSection && !applicationWithDrawn"
            :program-details="programDetails"
            :show-housing-questionnaire="showHousingQuestionnaireStep2"
            :form-submissions="formSubmissions"
          />
          <AddOnsSection
            :session="session"
            :order="applicationOrder"
            :order-product-details="applicationOrderProductDetails"
          />
        </section>
        <section
          v-if="displayToDo"
          class="col-span-12 md:col-span-4"
        >
          <div class="hidden md:block mb-4">
            <div class="flex justify-end">
              <p
                :class="`${secondaryColorClass}`"
                class="font-semibold text-xs cursor-pointer underline mt-5px"
                @click="toggleModalProgramChange(displayProgramChangeModal)"
              >
                Program Change, Deferral or Withdraw
              </p>
            </div>
            <div
              v-if="showCancellationPolicy"
              class="text-sm mt-5px text-right"
            >
              <a
                data-cy="cancellation-policy-link"
                :class="`${secondaryColorClass}`"
                class="font-semibold text-xs cursor-pointer underline"
                :href="cancellationPolicyLink"
                target="_blank"
              >
                Withdrawal policy
              </a>
            </div>
          </div>
          <ToDoList
            :paid-with-promo-code="paidWithPromoCode"
            :ui-version="uiVersion"
            :show-mobile-app-info="showMobileAppBanner"
          />
          <OrderSummary
            v-if="showReviewingSelections"
            :current-order="applicationOrder"
            :is-attached-to-enrollment="true"
            class="p-5 mt-4 rounded-lg"
          >
            <template #summary-header>
              <h3
                data-testid="my-enrollment-title"
                class="font-montserrat text-base text-indigo-base font-semibold"
              >
                My Experience
              </h3>
              <h2
                data-testid="my-enrollment-program-title"
                class="mt-5 font-montserrat text-teal-900 font-bold text-[22px] text-indigo-base leading-tight cursor-pointer"
                @click="titleClick"
              >
                {{ applicationOrder?.program_name }}
              </h2>
              <hr class="my-4 border-configurator-order-summary-gray" />
            </template>
            <template #call-to-action>
              <p
                data-testid="my-enrollment-support-paragraph"
                class="mt-5 text-xs text-indigo-base font-medium font-montserrat"
              >
                * If you need to make any changes, please contact
                <a
                  href="mailto:support-other@apiexperience.com"
                  :class="`${secondaryColorClassLinks} hover:underline`"
                  >support-other@apiexperience.com</a
                >
                .
              </p>
            </template>
          </OrderSummary>
        </section>
      </div>
    </div>
    <ProfileModal v-if="!loading" />
    <ConfirmationPaymentModal
      v-if="!loading && confirmationPaymentModalVisible"
      :is-open="true"
      :order="applicationOrder"
      :application-id="applicationId"
      :show-financial-aid="showFinancialAidV3"
      :ope8-id="opeIdLearningService"
      :session="session"
      :financial-aid="financialAidChosen"
      @financial-aid-chosen="handleFinancialAidChosen"
      @close="closeConfirmationPaymentModal"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import Spinner from "./helpers/Spinner";
import postFormRules from "../mixins/postFormRules";
import Modal from "./modals/Modal";
import ProgramChangeEnrollment from "./modals/EnrollmentProgramChange";
import ProgramDates from "./ProgramDates.vue";
import { daysUntilDate, formatDate } from "../mixins/helpers";
import OnboardingSection from "./Onboarding/OnboardingSection.vue";
import ToDoList from "./ToDoEnrollment.vue";
import { eventBus } from "../app";
import PreDeparture from "./onboardingStep2/PreDeparture.vue";
import TimelineBar from "./TimelineBar.vue";
import AddOnsSection from "@/components/EnrollmentAddOns/AddOnsSection.vue";
import ProfileModal from "@/components/forms/ProfileReview/ProfileReviewModal.vue";
import PaymentSection from "@/components/paymentCards/PaymentsSection.vue";
import forms from "@/mixins/forms.js";
import PaymentsSectionV3 from "./paymentCards/PaymentsSectionV3.vue";
import ordersService from "@/services/orders";
import ConfirmationPaymentModal from "@/components/modals/ConfirmationPaymentModal";
import { housingLinks } from "@/housingLinks";
import getApplicableContract from "@/composables/useBillings";
import {
  ALLOW_FINANCIAL_AID_FOR,
  V3_ALLOW_FINANCIAL_AID_FOR,
  VERSIONS,
  ORDER_STATUS,
  INVOICE_STATUSES,
  SESSION_TYPE,
  TERMS,
} from "@/constants.js";
import programSessionService from "@/services/programSessions";
import { setIpedsId } from "@/mixins/helpers.js";
import { getApiClient } from "@/services/API";
import programsPageService from "@/services/programsPage";
import homeInstitutionService from "@/services/housings";
import { convertOpeId } from "@/util/formatter";
import { computed } from "vue";
import applicationService from "@/services/application.js";
import { channelTypeOptions } from "@/components/program-manager/sessions/constants.js";
import OrderSummary from "@/components/OrderSummary/OrderSummary.vue";
import { APPLICATION_FEE_DATA } from "@/constants";
import { compareStringsInLowerCase } from "@/util/string";
import profileService from "@/services/profile.js";
import MobileAppEnrollmentBanner from "@/components/MobileAppBanner/EnrollmentBanner.vue";

export default {
  name: "EnrollmentLanding",
  components: {
    ConfirmationPaymentModal,
    Spinner: Spinner,
    ModalComponent: Modal,
    ProgramChangeEnrollment,
    ProgramDates,
    OnboardingSection,
    ToDoList,
    PreDeparture,
    ProfileModal,
    TimelineBar,
    PaymentSection,
    PaymentsSectionV3,
    OrderSummary,
    AddOnsSection,
    MobileAppEnrollmentBanner,
  },
  mixins: [postFormRules, forms],
  provide() {
    return {
      session: computed(() => {
        return this.session;
      }),
      orderProductDetails: computed(() => {
        return this.applicationOrderProductDetails;
      }),
    };
  },
  data() {
    return {
      opeIdLearningService: "",
      copy: "",
      loading: true,
      message: "Getting Student applications",
      applicationId: "",
      programDetails: {},
      daysUntilStart: 0,
      displayProgramChangeModal: false,
      addPaymentStep: false,
      preDepartureForms: [],
      preDepartureCompleted: true,
      paidWithPromoCode: false,
      invoices: [],
      confirmationPaymentModalActivated: false,
      orders: [],
      session: undefined,
      ipedsId: null,
      financialAidChosen: false,
      programPage: {},
      generalEvents: [],
      currentlyFinancialAid: null,
      formSubmissions: [], // Submissions from profile service
      profileDevices: [],
    };
  },
  computed: {
    ...mapGetters([
      "getStudentType",
      "canSkipStep2",
      "getCurrentStudentApplication",
      "getCurrentUser",
    ]),
    ...mapState([
      "program",
      "selectedProgramId",
      "billingRules",
      "payments",
      "uiVersion",
      "completedSections",
      "formioToken",
      "studentApplications",
      "currentApplicationSubmissions",
      "profileData",
      "appliedAgreement",
      "userData",
      "featureFlags",
    ]),
    orderMatchesSponsoringInstitution() {
      // Checks that the home inst of the order matches the sponsoring institution of the session
      const sponsoringInstitutions = this.session?.sponsoring_institutions;

      // Guards
      if (!this.orderHomeInstitution || !Array.isArray(sponsoringInstitutions))
        return false;

      const institutionMatched = sponsoringInstitutions.find(
        (sponsorInstitution) =>
          sponsorInstitution?.id === this.orderHomeInstitution
      );
      return institutionMatched !== undefined;
    },
    institutionCoversFullOrderCost() {
      // Fees (application, confirmation) not taken into consideration
      const totalOrderCost = this.applicationOrder?.total_price_in_cents;
      const totalInstitutionAmount = this.applicationOrder
        ?.institution_total_price_in_cents;

      return (
        !isNaN(totalOrderCost) &&
        !isNaN(totalInstitutionAmount) &&
        totalOrderCost === totalInstitutionAmount
      );
    },
    showConfirmationPaymentButton() {
      // Used to manually add a way to trigger the Confirmation Payment Modal
      // through payment section
      //when a promo code is used no invoice is generated so we rely on the status
      return (
        !this.isConfirmationPaymentComplete &&
        this.confirmationPaymentAccessRequirementsMet &&
        !this.institutionPaysConfirmationFeeContractId &&
        this.applicationOrder?.status === ORDER_STATUS.submitted
      );
    },
    confirmationPaymentModalVisible() {
      // Blocking conditions
      const visible = this.confirmationPaymentModalActivated;
      const sessionFullySponsored =
        this.isSessionCustomized &&
        this.orderMatchesSponsoringInstitution &&
        this.institutionCoversFullOrderCost &&
        !this.isStudentPayingConfirmation;

      return visible && !sessionFullySponsored;
    },
    confirmationPaymentAccessRequirementsMet() {
      // Checks if user has the minimum requirements to see a confirmation payment modal
      const enrollmentStatus = this.currentApp?.enrollment_status;
      const uiVersion = this.uiVersion;
      const uiVersionsAllowed = [VERSIONS.v3];
      const notAllowedStatus = [
        "Application Started",
        "Ready for Review",
        "Withdrawn",
        "Not Enrolled",
        "",
      ];

      const validUIVersion = uiVersionsAllowed.includes(uiVersion);
      const validStatusType = typeof enrollmentStatus === "string";
      const validStatus = !notAllowedStatus.includes(enrollmentStatus);
      const validOrder = !_.isEmpty(this.applicationOrder); // https://lodash.com/docs/4.17.15#isEmpty

      return validUIVersion && validStatusType && validStatus && validOrder;
    },
    isConfirmationPaymentComplete() {
      const paidByStudent = this.invoices?.some((invoice) => {
        // Paid in Stripe
        return (
          (typeof invoice?.status === "string" &&
            invoice.status.toLowerCase() ===
              INVOICE_STATUSES.PAID.toLowerCase() &&
            typeof invoice?.metadata?.product_name === "string" &&
            invoice.metadata.product_name.toLowerCase() ===
              "confirmation fee") ||
          this.applicationOrder?.status === ORDER_STATUS.paidConfirmed
        );
      });

      const paidByInstitution = this.generalEvents?.some((general_event) => {
        // Paid by institution - we registered a general event for it
        return (
          typeof general_event?.event_data === "object" &&
          typeof general_event.event_data?.product_name === "string" &&
          general_event.event_data.product_name.toLowerCase() ===
            "confirmation payment"
        );
      });

      return paidByStudent || paidByInstitution;
    },
    applicationWithDrawn() {
      return this.getCurrentStudentApplication
        ? this.getCurrentStudentApplication.enrollmentStatus === "Withdrawn"
        : false;
    },
    appliableContract() {
      if (!this.session) return undefined;

      return getApplicableContract(
        this.billingRules?.agreements ?? [],
        this.session
      );
    },
    shouldShowViewApplicationCTA() {
      return (
        !this.bulkEnrollmentId &&
        (this.uiVersion === VERSIONS.v2 ||
          ["Draft", "Submitted"].includes(this.applicationOrder.status))
      );
    },
    showPaymentSection() {
      if (
        !this.loading &&
        this.uiVersion === VERSIONS.v3 &&
        (this.invoices.length > 0 || this.showConfirmationPaymentButton)
      ) {
        // Show payment section if v3 and should see confirmation fee
        return true;
      }
      return this.sortPaymentCards.length > 0;
    },
    showFinancialAid() {
      return (
        this.showPaymentSection &&
        ALLOW_FINANCIAL_AID_FOR.includes(this.getStudentType)
      );
    },
    showFinancialAidV3() {
      // Displays financial aid in payment section and confirmation payment modal
      const validStudentType = V3_ALLOW_FINANCIAL_AID_FOR.some(
        (studentType) => studentType.id === this.profileData?.learner_type_id
      );

      // Pays something in the order or confirmation fee
      const studentPaysToAPI =
        this.isParticipantPaying || this.isStudentPayingConfirmation;

      return (
        validStudentType &&
        studentPaysToAPI &&
        this.confirmationPaymentAccessRequirementsMet // is v3, has an order, enrollment status allows it
      );
    },
    showPreDepartureSection() {
      //if no post acceptance rules hide the pre departure section
      if (Object.keys(this.program.post_acceptance_form_rules).length === 0) {
        return false;
      }

      if (
        this.showAgreementSection ||
        this.showHealthAndWellnessTile ||
        this.showHousingTile ||
        this.showNetworkOfSupportTile ||
        this.showGroupVisaTile ||
        this.showPassportTile ||
        this.showVisaAppointmentTile ||
        this.showVisaAndPermitTile ||
        this.showHeadshotTile ||
        this.showCourseSelectionCard
      ) {
        return true;
      }

      return false;
    },
    returnToApplication() {
      if (this.uiVersion === VERSIONS.v3) {
        return "/application-next-steps/" + this.applicationId;
      } else {
        return (
          "/applications/" +
          this.applicationId +
          (this.uiVersion === VERSIONS.v2
            ? "/next-steps"
            : "/application-received")
        );
      }
    },
    sortPaymentCards() {
      let lesserArray = [];
      let greaterArray = [];
      this.payments.forEach((payment) => {
        if (payment.paid_by === "Participant") {
          if (
            compareStringsInLowerCase(
              payment.name,
              APPLICATION_FEE_DATA.typeLabel
            )
          ) {
            const isPromoCode = Boolean(
              payment.status === "Open" && this.paidWithPromoCode
            );

            if (!isPromoCode) greaterArray.push(payment);
          } else {
            lesserArray.push(payment);
          }
        }
      });
      return greaterArray.concat(lesserArray);
    },
    showAgreementSection() {
      let hasFormRules = Object.hasOwnProperty.call(
        this.programDetails,
        "post_acceptance_form_rules"
      );
      let rules = hasFormRules
        ? this.programDetails.post_acceptance_form_rules
        : [];
      let hasAgreement =
        rules.includes("Virtual Participant Agreement") ||
        rules.includes("Study and Intern Participant Agreement") ||
        rules.includes("Intern Code of Conduct") ||
        rules.includes("Study Code of Conduct") ||
        rules.includes("Virtual Study Code of Conduct") ||
        rules.includes("Parsons Paris Use Agreement") ||
        rules.includes("Virtual Intern Code of Conduct") ||
        rules.includes("Polimoda Student Regulations Policy");
      return hasFormRules && hasAgreement;
    },
    currentApp() {
      return this.$store.getters.currentApplication(
        this.$route.params.applicationId
      );
    },
    programLink() {
      let currentApp = this.currentApp;
      let salesforce_id = currentApp.salesforce_program_session_id;
      try {
        let houseLink = housingLinks[salesforce_id];
        return houseLink;
      } catch {
        return false;
      }
    },
    buildStringDateRange() {
      let formattedFrom = formatDate(this.program.start_date);
      let formattedTo = formatDate(this.program.end_date);
      return formattedFrom + " - " + formattedTo;
    },
    preDepartureTitle() {
      return this.uiVersion === VERSIONS.v2 || this.uiVersion === VERSIONS.v3
        ? "Onboarding Step 2"
        : "Pre-departure";
    },
    displayToDo() {
      return this.uiVersion === VERSIONS.v2 || this.uiVersion === VERSIONS.v3;
    },
    cancellationPolicyType() {
      return this.program.cancellation_policy_type
        ? this.program.cancellation_policy_type
        : "";
    },
    cancellationPolicyLink() {
      let link =
        this.cancellationPolicyType === "Customized Student Cancellation Policy"
          ? this.program.link_to_custom_cancelation_policy
          : "https://apiabroad.com/withdrawal-policy-safeguards";

      if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
        return link;
      } else {
        return "https://" + link;
      }
    },
    showCancellationPolicy() {
      // hide if custom student policy but no link supplied
      if (
        this.cancellationPolicyType ===
          "Customized Student Cancellation Policy" &&
        !this.program.link_to_custom_cancelation_policy
      ) {
        return false;
      }
      if (
        this.cancellationPolicyType !==
        "Customized University Cancellation Policy"
      ) {
        return true;
      }
      return false;
    },
    bulkEnrollmentId() {
      return this.getCurrentStudentApplication?.bulkEnrollmentId ?? "";
    },
    applicationOrder() {
      let order = this.orders.find(
        (order) => order?.order?.application_id === this.applicationId
      )?.order;
      return Object.assign(
        {
          program_name: this.programPage?.name ?? this.program.program.name,
        },
        order
      );
    },
    applicationOrderProductDetails() {
      return this.orders.find(
        (order) => order?.order?.application_id === this.applicationId
      )?.product_details;
    },
    orderHomeInstitution() {
      // TODO: Remove this computed property - order will be calculated correctly later on
      return this.applicationOrder?.home_institution_id;
    },
    orderStatus() {
      return this.applicationOrder?.status;
    },
    isParticipantPaying() {
      // Student pays anything in the scope of the order, application fee or confirmation payment are out of this scope
      return this.applicationOrder?.student_total_price_in_cents > 0;
    },
    isSessionCustomized() {
      const custom = channelTypeOptions.find((f) => f.name === "Custom");
      return this.session?.channel_id === custom.id;
    },
    isStudentPayingConfirmation() {
      return (
        this.applicationOrder?.billing_rules?.confirmationPayment !==
        "Institution"
      );
    },
    institutionPaysConfirmationFeeContractId() {
      if (!this.isStudentPayingConfirmation) {
        return this.applicationOrder?.billing_contract_id;
      }
      return undefined;
    },
    dateAccepted() {
      return this.currentApp?.date_accepted ?? "";
    },
    homeInstitutionId() {
      return this.profileData?.colleges?.[0]?.college_id ?? "";
    },
    showReviewingSelections() {
      return (
        this.featureFlags["reviewing-selections"] &&
        this.uiVersion === VERSIONS.v3
      );
    },
    showHousingQuestionnaire() {
      return Boolean(
        this.featureFlags["enrollment-page-housing-questionnaire"] &&
          this.uiVersion === VERSIONS.v3 &&
          this.applicationOrder?.room_ids?.length
      );
    },
    showHousingQuestionnaireStep1() {
      return Boolean(
        this.showHousingQuestionnaire && this.program?.form_rules?.length
      );
    },
    showHousingQuestionnaireStep2() {
      return Boolean(
        this.showHousingQuestionnaire &&
          !this.program?.form_rules?.length &&
          this.program?.post_acceptance_form_rules?.length
      );
    },
    validMobileAppSessionType() {
      let invalidSessionTypes = [
        SESSION_TYPE.virtual.id,
        ...(this.featureFlags["mobile-app-download-banner-no-custom"]
          ? [SESSION_TYPE.facultyLed.id]
          : []),
      ];

      return !this.session?.session_types?.some((type) =>
        invalidSessionTypes.includes(type.id)
      );
    },
    validMobileAppSession() {
      let valid = true;
      const invalidTerms = [
        TERMS.fallQuarter.id,
        TERMS.fallExtended.id,
        TERMS.fallEarlyStart.id,
        TERMS.fallBreak.id,
        TERMS.fall.id,
        TERMS.academicYear.id,
      ];

      const hasInvalidTerm = this.session?.terms?.some((term) =>
        invalidTerms.includes(term.id)
      );

      if (
        this.session?.academic_year == "2023-2024" ||
        (this.session?.academic_year == "2024-2025" && hasInvalidTerm)
      ) {
        valid = false;
      }

      return valid;
    },
    showMobileAppInfo() {
      return Boolean(
        (this.featureFlags["mobile-app-download-banner"] ||
          this.featureFlags["mobile-app-download-banner-no-custom"]) &&
          this.applicationOrder?.id &&
          this.validMobileAppSessionType &&
          this.validMobileAppSession
      );
    },
    showMobileAppBanner() {
      return this.showMobileAppInfo && this.profileDevices.length === 0;
    },
  },
  watch: {
    currentApp: {
      handler: function () {
        this.getEnrollmentCopy();
      },
      immediate: true,
      deep: true, // Added in case the small chance that this comes out as empty array
    },
    program: {
      handler: function () {
        this.setDaysUntilStart();
      },
      deep: true,
    },
    payments: {
      handler: function (val) {
        this.setPaymentProps(val);
      },
      immediate: true,
      deep: true,
    },
    studentApplications: {
      handler: function () {
        this.setPromoCode();
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    eventBus.$on("addPreDepartureForm", (val) => {
      this.addPreDepartureForm(val);
    });

    this.applicationId = this.$route.params.applicationId;
    const promises = [
      this.getPaymentsV3(),
      this.getFinancialAid(),
      this.$store.dispatch("getCurrentApplicationSubmissions"),
      this.$store.dispatch("getEnrollmentData"),
      this.$store.dispatch("userSetup"),
      this.$store.dispatch("getPayments", this.applicationId),
      this.$store.dispatch("setProgramDataByApplicationId", {
        applicationId: this.applicationId,
        apolloClient: this.$apollo,
      }),
      this.fetchOrders(),
      ...(this.studentApplications.length < 1
        ? [
            this.$store.dispatch("getFormioSubmissions"),
            this.$store.dispatch("getStudentApplications"),
          ]
        : []),
    ];

    await Promise.all(promises).finally(async () => {
      if (this.orderHomeInstitution) {
        // Find and pass ope_id to order summary component to match correct pricing as in configurator
        const homeInstitutionOpeId = await homeInstitutionService.getHomeInstitutionsById(
          [this.orderHomeInstitution]
        )?.[0]?.data?.items?.ope_id;

        if (
          typeof homeInstitutionOpeId === "string" &&
          homeInstitutionOpeId.length
        )
          this.opeIdLearningService = convertOpeId(homeInstitutionOpeId);
      }

      // We deliberately don't validate against billing/contract number
      //when a promo code is used no invoice is generated so we rely on the status
      if (
        this.confirmationPaymentAccessRequirementsMet &&
        !this.isConfirmationPaymentComplete &&
        this.applicationOrder.status === ORDER_STATUS.submitted
      ) {
        this.confirmationPaymentModalActivated = true;
      }
      await this.getProgramDetails().then(async () => {
        this.setDaysUntilStart();
      });

      if (
        _.isEmpty(this.profileData) &&
        Boolean(this.getCurrentUser.participantId)
      ) {
        // Fetch profile data if we don't have it and we have its id
        await this.$store.dispatch(
          "getProfile",
          this.getCurrentUser.participantId
        );
      }

      if (this.uiVersion === VERSIONS.v3) {
        this.createStripeMetadata();
      }

      if (this.showMobileAppInfo) {
        await this.getProfileDevices();
      }

      this.loading = false;
    });
  },
  methods: {
    handleCloseModal() {
      !this.featureFlags["legacy-checkout"] && this.getPaymentsV3();
    },
    sleep(seconds) {
      return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
    },
    refreshPage() {
      !this.featureFlags["legacy-checkout"] && this.$router.go();
    },
    titleClick() {
      const order = this.orders.find(
        (order) => order?.order?.application_id === this.applicationId
      );
      let to = {
        name: "program-detail-view",
        params: {
          programPageId: order?.order?.program_page_id,
        },
        query: {
          "session-id": order?.order?.session_id,
        },
      };
      const { href } = this.$router.resolve(to);
      window.open(href, "_blank");
    },
    async getPaymentsV3() {
      await this.sleep(2);
      await getApiClient()
        .post("/get-all-payments", {
          application_id: this.$route.params.applicationId,
        })
        .then((response) => {
          this.invoices = response.data.stripe_items;
          this.generalEvents = response.data.general_items;
        });
    },
    async getFinancialAid() {
      const applicationId = this.$route.params.applicationId;
      const profileId = this.getCurrentUser?.participantId;

      const financialAids = await profileService.getFinancialAid(
        applicationId,
        profileId
      );
      if (financialAids?.data?.length > 0) {
        this.handleFinancialAidChosen(
          financialAids?.data[0].using_financial_aid
        );
        this.currentlyFinancialAid = financialAids?.data[0];
      } else {
        this.currentlyFinancialAid = null;
      }
    },
    getUnixTimestampFiveDaysAhead() {
      // Get the current date
      var currentDate = new Date();

      // Add 5 days to the current date
      currentDate.setDate(currentDate.getDate() + 5);

      // Get the Unix timestamp in seconds (divided by 1000)
      var unixTimestamp = Math.floor(currentDate.getTime() / 1000);

      return unixTimestamp;
    },
    toggleModalProgramChange(value) {
      this.displayProgramChangeModal = !value;
    },
    async getProgramDetails() {
      if (this.selectedProgramId) {
        let url = process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT;
        url = this.trailingSlash(url);
        url += "session/" + this.selectedProgramId;
        let programData = await fetch(url);
        if (programData.ok) {
          let data = await programData.json();
          this.programDetails = data;
        }
      }
    },
    getEnrollmentCopy() {
      let status = "";
      if (
        Object.hasOwnProperty.call(this.currentApp, "enrollment_status") &&
        this.currentApp["enrollment_status"]
      ) {
        status = this.currentApp["enrollment_status"];
      }
      switch (status) {
        case "Ready for Review":
          this.copy =
            "Thank you for submitting your application! We are actively reviewing all the materials you submitted as part of your application. Please feel free to get a head start on the next set of information to process your enrollment. API will not begin reviewing these materials until after your acceptance.";
          break;
        case "Provisionally Accepted":
          this.copy =
            "Congratulations! You have been provisionally accepted into your program. Make sure to complete your confirmation payment to confirm your spot in the program.";
          break;
        case "Accepted":
          this.copy = "Congratulations on your admission. You are in!";
          break;
        case "Confirmed/Paid":
        case "Enrollment Forms Complete":
        case "Abroad":
        case "Completed/Post Program":
        case "Withdrawn":
          this.copy = "";
          break;
      }
    },
    setDaysUntilStart() {
      if (
        Object.hasOwnProperty.call(this.program, "start_date") &&
        this.program.start_date.length
      ) {
        this.daysUntilStart = daysUntilDate(this.program.start_date);
      }
    },
    setPaymentProps(payments) {
      let paymentsPendingBalance = false;
      let participantPayments = payments.filter(
        (payment) => payment.paid_by === "Participant"
      );
      this.addPaymentStep = participantPayments.length > 0;

      let confirmationPayment = participantPayments.find(
        (payment) => payment.name.toLowerCase() === "confirmation payment"
      );

      let pendingPayments = participantPayments.find(
        (payment) =>
          typeof payment.status !== "undefined" &&
          payment.status !== "Paid In Full"
      );

      if (confirmationPayment !== undefined) {
        paymentsPendingBalance =
          typeof confirmationPayment.balance !== "undefined" &&
          parseFloat(confirmationPayment.balance) !== 0;
      }

      this.$store.commit("setCompletedSections", {
        field: "payment",
        value: !paymentsPendingBalance,
      });

      this.$store.commit("setCompletedSections", {
        field: "otherPayments",
        value: pendingPayments === undefined,
      });
    },
    addPreDepartureForm(form) {
      this.preDepartureCompleted = this.preDepartureCompleted && form.submitted;
      this.preDepartureForms.push(form);
      this.setPreDepartureCompleted();
    },
    setPreDepartureCompleted() {
      let completed = this.preDepartureForms.filter((form) => form.submitted);

      this.$store.commit("setOnboarding2Forms", {
        forms: Object.assign([], this.preDepartureForms),
        totalForms: this.preDepartureForms.length,
        submittedForms: completed.length,
      });

      this.$store.commit("setCompletedSections", {
        field: "onboarding2",
        value: !!this.preDepartureCompleted,
      });
    },
    setPromoCode() {
      if (this.getCurrentStudentApplication) {
        let paymentSubmission = this.getCurrentStudentApplication.submissions.find(
          (submission) => {
            return this.paymentIds.includes(submission.form);
          }
        );
        this.paidWithPromoCode = !!paymentSubmission?.data.promotionCode;
      }
    },
    async fetchOrders() {
      this.orders = await ordersService.list({
        participant_id: this.getCurrentUser.participantId,
        get_withdrawn: true,
      });

      const order = this.orders.find(
        (order) => order?.order?.application_id === this.applicationId
      );

      const sessionId = order?.order?.session_id;
      if (sessionId) {
        const sessionServiceResponse = await programSessionService.getProgramSession(
          {
            id: sessionId,
            home_institution_id: this.homeInstitutionId,
          }
        );
        this.session = sessionServiceResponse.data?.data?.items;

        const response = await getApiClient().get(
          `/applications/${this.applicationId}`
        );
        const { program_id } = response?.data ?? {};
        if (program_id)
          programsPageService
            .getProgramPage(program_id, { hide_sessions: true })
            .then((val) => {
              this.programPage = val?.data?.data?.items ?? {};
            });
      }

      const orderId = order?.order?.id;
      if (orderId) {
        const submissionsData = await profileService.getSubmissionsByOrder(
          orderId
        );

        this.formSubmissions = submissionsData?.data?.items ?? [];
      }
    },
    closeConfirmationPaymentModal() {
      this.getPaymentsV3();
      this.confirmationPaymentModalActivated = false;
    },
    handleFinancialAidChosen(usingFinancialAid) {
      this.financialAidChosen = usingFinancialAid;
    },
    async createStripeMetadata() {
      this.ipedsId = await setIpedsId(this.userData.data?.schoolname?.value);
      const metadata = {
        email: this.getCurrentUser?.email,
        birth_date: this.profileData.birthdate ?? "n/a",
        contract_number: this.institutionPaysConfirmationFeeContractId ?? "n/a",
        first_name: this.getCurrentUser.firstName,
        last_name: this.getCurrentUser.lastName,
        home_institution_ipeds: this.ipedsId ?? "n/a",
        home_university_name: this.userData.data?.schoolname?.label ?? "n/a",
        host_institution:
          this.session?.session_hosting_institutions?.[0]?.entity?.name ??
          "n/a",
        is_customized: this.session?.channel_id === 2,
        is_institution_pays: !!this.institutionPaysConfirmationFeeContractId,
        is_application_fee: false,
        participant_type: this.userData.data.student_type ?? "n/a",
        billing_city: this.profileData.billing_address_city ?? "n/a",
        billing_state_province: this.profileData.billing_address_state ?? "n/a",
        billing_street: this.profileData.billing_address_line_1 ?? "n/a",
        billing_zip_postal_code:
          this.profileData.billing_address_postal_code ?? "n/a",
        permanent_city: this.profileData.permanent_address_city ?? "n/a",
        permanent_state_province:
          this.profileData.permanent_address_state ?? "n/a",
        permanent_street: this.profileData.permanent_address_line_1 ?? "n/a",
        permanent_zip_postal_code:
          this.profileData.permanent_address_postal_code ?? "n/a",
        phone: this.profileData.phone_number ?? "n/a",
        program_city:
          this.session?.session_travel_details?.[0]?.arrival_city?.city ??
          "n/a",
        program_country:
          this.session?.session_travel_details?.[0]?.arrival_city?.country
            ?.name ?? "n/a",
      };
      //save stripe metadata to db
      applicationService.updateV3Application(this.$route.params.applicationId, {
        metadata: JSON.stringify(metadata),
      });
    },
    async getProfileDevices() {
      const devicesResponse = await profileService.getProfileDevices({
        extraParams: { participant_id: this.getCurrentUser.participantId },
      });

      this.profileDevices = devicesResponse?.data?.data?.items ?? [];
    },
  },
};
</script>
