<script setup>
import {ref, unref, toRefs, computed, watch} from "vue";
import TreeSelect from "@/components/shared/TreeSelect";
import {useVModel} from "@vueuse/core/index";
import {isEqual} from "lodash";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  filterName: {
    type: String,
  },
  algoliaConfiguration: {
    type: Object,
  },
  placeholder: {
    type: String,
    required: false,
  }
});

const emit = defineEmits(["update:modelValue"]);
const filters = useVModel(props, "modelValue", emit);
const { algoliaConfiguration } = toRefs(props);

const treeOptions = ref([]);
const selectedNodes = ref([]);
const refinementListRef = ref(undefined);

const facetName = computed(() => {
  return unref(algoliaConfiguration)?.[props?.filterName]
})

const itemTransform = (items, { results }) => {
  const rootNode = items?.reduce((acc, item) => {
    const [parent, child] = item?.value?.split(" > ");
    if(!acc?.[parent]) {
      acc[parent] = {
        key: parent,
        label: parent,
        children: []
      }
    }
    acc[parent].children.push({
      key: item?.value,
      label: child,
      children: []
    })
    return acc;
  }, {})
  treeOptions.value = Object.values(rootNode);
  return items;
}

const selectOptions = (options, refine) => {
  const childOptions = options.reduce((acc, val) => {
    const parts = val?.key?.split(" > ");
    if(parts?.length === 2) {
      acc.push(val.key);
    }
    return acc;
  }, [])

  const updatedFilters = {};
  updatedFilters[props.filterName] = childOptions;
  filters.value = { ...filters.value, ...updatedFilters }
}

watch(() => refinementListRef?.value?.state?.canRefine, (newCanRefine, oldCanRefine) => {
  if(newCanRefine) {
    const algoliaState = unref(refinementListRef)?.state;
    const updatedFilterValue = filters.value?.[props.filterName];
    if(algoliaState?.canRefine && algoliaState?.items) {
      for(const item of algoliaState.items) {
        const foundEntry = updatedFilterValue?.find((element) => element === item?.value);
        if((foundEntry && !item.isRefined) || (!foundEntry && item.isRefined)) {
          unref(refinementListRef).refine(item.value);
        }
      }
    }
    selectedNodes.value = filters.value?.[props.filterName].map(val => ({key: val, checked: true}));
  }
});

watch(
  () => filters.value,
  (updatedFilter, oldFilter) => {
    if (
      !isEqual(updatedFilter?.[props.filterName], oldFilter?.[props.filterName])
    ) {
      const algoliaState = unref(refinementListRef)?.state;
      const updatedFilterValue = updatedFilter?.[props.filterName];
      if(algoliaState?.canRefine && algoliaState?.items) {
        for(const item of algoliaState.items) {
          const foundEntry = updatedFilterValue?.find((element) => element === item?.value);
          if((foundEntry && !item.isRefined) || (!foundEntry && item.isRefined)) {
            unref(refinementListRef).refine(item.value);
          }
        }
        selectedNodes.value = updatedFilter[props.filterName].map(val => ({key: val, checked: true}));
      }
    }
  },
  { immediate: true }
);
</script>
<template>
  <div>
    <ais-refinement-list
      :attribute="facetName"
      :limit="1000"
      operator="or"
      :sort-by="[`name:asc`]"
      :transform-items="itemTransform"
      ref="refinementListRef"
    >
      <template v-slot="{ items, refine, searchForItems }">
        <div v-show="items?.length === 0" class="text-1xs2 bg-gray-75 text-indigo-base rounded-md mt-1">
          <p>No options available. Clear a filter on another category.</p>
        </div>
        <div v-show="items?.length !== 0">
          <TreeSelect @update:selected-tree-nodes="(options) => selectOptions(options, refine)" :selected-tree-nodes="selectedNodes" :tree-options="treeOptions" :placeholder="placeholder" show-chips />
        </div>
      </template>
    </ais-refinement-list>
  </div>
</template>

<style scoped></style>
