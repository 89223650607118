<template>
  <div
    data-testid="confirmation-payment-modal"
    v-if="isOpen"
    class="fixed z-40 inset-0 w-full h-full bg-white md:bg-gray-transparent overflow-y-auto"
    @click.self="close"
  >
    <div
      class="h-full w-screen bg-white md:w-5/12 md:mx-auto md:my-5 md:h-auto md:pb-6"
    >
      <div
        :class="{
          'justify-between': modalTitleVisible,
          'justify-end': !modalTitleVisible,
        }"
        class="w-full bg-teal-accent flex items-center px-5 py-3"
      >
        <p
          v-if="modalTitleVisible"
          class="inline-block font-montserrat text-sm font-bold uppercase leading-5"
        >
          Congratulations on your acceptance!
        </p>
        <div
          data-testid="confirmation-modal-close"
          v-if="!institutionPays"
          class="flex w-6 h-6 justify-center items-center shrink-0 cursor-pointer"
          @click="close"
        >
          <svg
            class="w-6 h-6 shrink-0 inline-block"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#1E264C"
            />
          </svg>
        </div>
      </div>

      <p class="m-5 text-center font-montserrat text-sm md:my-5 md:mx-16">
        {{
          institutionPays
            ? "Take a moment to review and confirm your selections before beginning the enrollment process."
            : showFinancialAid
            ? "Please review your selections and answer to a preliminary question about financial aid. You will then have to make your confirmation payment to start the enrollment process."
            : "Please review your selections. You will then have to make your confirmation payment to start the enrollment process."
        }}
      </p>

      <template v-if="showFinancialAid">
        <div
          class="relative m-5 text-center font-montserrat font-semibold md:my-5 md:mx-16"
        >
          Do you currently receive financial aid at your home university and
          plan to use this to pay for your API program?
          <span
            class="inline mx-2 relative cursor-help overflow-visible"
            @mouseenter="() => (state.tooltipVisible = true)"
            @mouseleave="() => (state.tooltipVisible = false)"
            @click="() => (state.tooltipVisible = !state.tooltipVisible)"
          >
            <template v-if="state.tooltipVisible">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                class="absolute left-0 bottom-full z-50"
              >
                <g filter="url(#filter0_d_6649_47024)">
                  <path
                    d="M11.5 12L4.13878 0.75L18.8612 0.75L11.5 12Z"
                    fill="#EDF6F6"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_6649_47024"
                    x="0.140625"
                    y="0.75"
                    width="22.7188"
                    height="19.25"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_6649_47024"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_6649_47024"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </template>

            <svg
              class="inline"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.3089 10C19.3089 15.1066 15.1564 19.25 10.0294 19.25C4.90247 19.25 0.75 15.1066 0.75 10C0.75 4.89344 4.90247 0.75 10.0294 0.75C15.1564 0.75 19.3089 4.89344 19.3089 10Z"
                stroke="#007F80"
                stroke-width="1.5"
              />
              <path
                d="M10.0534 6.292C9.40944 6.292 8.93344 5.83 8.93344 5.242C8.93344 4.654 9.40944 4.192 10.0534 4.192C10.6974 4.192 11.1734 4.626 11.1734 5.2C11.1734 5.816 10.7114 6.292 10.0534 6.292ZM9.17144 15V7.524H10.9214V15H9.17144Z"
                fill="#007F80"
              />
            </svg>
          </span>
          <div
            v-if="state.tooltipVisible"
            class="absolute mb-5 bg-teal-light p-4 text-sm font-normal text-left w-full flex justify-between items-start overflow-visible z-50 bottom-1/4"
          >
            <svg
              class="absolute w-4 h-4 right-2 md:hidden"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              @click.stop="() => (state.tooltipVisible = false)"
            >
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#1E264C"
              />
            </svg>
            <div class="min-w-4">
              <svg
                class="inline-block mx-3"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.3089 10C19.3089 15.1066 15.1564 19.25 10.0294 19.25C4.90247 19.25 0.75 15.1066 0.75 10C0.75 4.89344 4.90247 0.75 10.0294 0.75C15.1564 0.75 19.3089 4.89344 19.3089 10Z"
                  stroke="#007F80"
                  stroke-width="1.5"
                />
                <path
                  d="M10.0534 6.292C9.40944 6.292 8.93344 5.83 8.93344 5.242C8.93344 4.654 9.40944 4.192 10.0534 4.192C10.6974 4.192 11.1734 4.626 11.1734 5.2C11.1734 5.816 10.7114 6.292 10.0534 6.292ZM9.17144 15V7.524H10.9214V15H9.17144Z"
                  fill="#007F80"
                />
              </svg>
            </div>
            <p class="inline-block">
              API can work with you to apply your financial aid (loans,
              scholarships, grants, etc.) to your program cost
            </p>
          </div>
        </div>
        <div class="mx-auto flex w-3/6 h-12 justify-between">
          <div
            data-testid="financial-aid-option-yes"
            class="inline-flex items-center gap-3 cursor-pointer"
            @click="
              () => {
                toggleFinancialAid(true);
              }
            "
          >
            <svg
              class="inline-block"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle cx="12" cy="12" r="9.5" stroke="black" stroke-width="2" />
              <circle
                data-testid="financial-aid-yes-option"
                v-if="state.usingFinancialAid"
                cx="12"
                cy="12"
                r="6.5"
                fill="black"
              />
            </svg>
            <p class="inline-block font-montserrat text-lg font-semibold">
              Yes
            </p>
          </div>
          <div
            data-testid="financial-aid-option-no"
            class="inline-flex items-center gap-3 cursor-pointer"
            @click="
              () => {
                toggleFinancialAid(false);
              }
            "
          >
            <svg
              class="inline-block"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle cx="12" cy="12" r="9.5" stroke="black" stroke-width="2" />
              <circle
                data-testid="financial-aid-no-option"
                v-if="!state.usingFinancialAid"
                cx="12"
                cy="12"
                r="6.5"
                fill="black"
              />
            </svg>
            <p class="inline-block font-montserrat text-lg font-semibold">No</p>
          </div>
        </div>
        <p
          v-if="state.usingFinancialAid"
          class="m-5 text-center font-montserrat text-sm md:my-5 md:mx-16"
        >
          Thank you for letting us know you plan to use financial aid! When you
          confirm enrollment we will alert our financial aid team and they will
          be in touch via email within 5 business days. Please look out for this
          email. If you are not responsive, your final invoice will be due two
          weeks after your confirmation payment is made.
        </p>
      </template>

      <order-details
        :current-order="order"
        :ope8-id="ope8Id"
        :show-confirmation-payment="isOpen"
        :institution-pays="institutionPays"
        :home-institution-id="homeInstitutionId"
        :student-pays-but-not-confirmation="studentPaysButNotConfirmation"
        class="m-5 p-2 md:my-5 md:mx-16 md:p-4"
      >
        <template #call-to-action>
          <ButtonWithSpinner
            id="continue-button"
            ref="continueButton"
            type="button"
            variant="secondary"
            :grey-disabled-class="true"
            :custom-class="'w-full px-12 py-5 gap-3 flex-shrink-0 bg-teal-900 text-white my-5 mx-auto cursor-pointer text-center'"
            @click="handleClick"
          >
            <div class="flex items-center justify-center gap-2">
              <span
                class="inline font-montserrat text-base font-bold uppercase"
              >
                {{
                  institutionPays
                    ? "Continue to enrollment"
                    : "Continue to make payment"
                }}</span
              >
              <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -7.77409e-07L-7.14641e-07 1.71183L5.38866 7.00874Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </ButtonWithSpinner>

          <p
            v-if="state.errorMessage.length > 0"
            class="m-5 text-center font-montserrat text-red-600 text-sm md:my-5 md:mx-16"
          >
            {{ state.errorMessage }}
          </p>
          <p class="w-96 font-montserrat text-sm text-center block mx-auto">
            If anything needs to be changed, please contact support at
            <a class="font-semibold" href="mailto:api@apiexperience.com"
              >api@apiexperience.com</a
            >.
          </p>
        </template>
      </order-details>
    </div>
  </div>
  <spinner v-if="state.loading">
    <template #spinnercontent>Confirming your enrollment...</template>
  </spinner>
</template>

<script setup>
import { computed, reactive, ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import OrderDetails from "@/components/OrderDetails/OrderDetails.vue";
import ordersService from "@/services/orders";
import Spinner from "@/components/helpers/Spinner";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import { channelTypeOptions } from "@/components/program-manager/sessions/constants.js";
import { ORDER_STATUS } from "@/constants.js";

const store = useStore();
const router = useRouter();
const continueButton = ref(null);

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  order: {
    type: Object,
    required: true,
  },
  applicationId: {
    type: String,
    required: true,
  },
  showFinancialAid: {
    type: Boolean,
    default: false,
  },
  financialAid: {
    type: Boolean,
    default: false,
  },
  session: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const { order, session, financialAid } = toRefs(props);

const state = reactive({
  usingFinancialAid: financialAid,
  loading: false,
  errorMessage: "",
  tooltipVisible: false,
});

const modalTitleVisible = computed(() => {
  let showTitle = true;

  // Blocking conditions
  if (isSessionCustomized.value && orderMatchesSponsoringInstitution.value) {
    // Student pays confirmation or something in the order
    showTitle =
      isStudentPayingConfirmation.value || isParticipantPaying.value
        ? false
        : true;
  }

  return showTitle;
});

const orderMatchesSponsoringInstitution = computed(() => {
  // Checks that the home inst of the order matches the sponsoring institution of the session
  const sponsoringInstitutions = session.value?.sponsoring_institutions;
  const orderHomeInstitution = order.value?.home_institution_id;

  // Guards
  if (!orderHomeInstitution || !Array.isArray(sponsoringInstitutions))
    return false;

  const institutionMatched = sponsoringInstitutions.find(
    (sponsorInstitution) => sponsorInstitution?.id === orderHomeInstitution
  );
  return institutionMatched !== undefined;
});

const isParticipantPaying = computed(() => {
  // Student pays anything in the scope of the order, application fee or confirmation payment are out of this scope
  return order.value?.student_total_price_in_cents > 0;
});

const isSessionCustomized = computed(() => {
  const custom = channelTypeOptions.find((f) => f.name === "Custom");
  return session.value?.channel_id === custom.id;
});

const isStudentPayingConfirmation = computed(() => {
  return order.value?.billing_rules?.confirmationPayment !== "Institution";
});

const studentPaysButNotConfirmation = computed(() => {
  return isParticipantPaying.value && !isStudentPayingConfirmation.value;
});

const institutionPays = computed(() => !isStudentPayingConfirmation.value);

const billingContractId = computed(() => {
  // IMPORTANT: Only send the contract if institution pays the confirmation fee
  if (!isStudentPayingConfirmation.value) {
    return order.value?.billing_contract_id;
  }
  return undefined;
});

const ope8Id = computed(() => store.state.userData?.data?.schoolname?.value);

const homeInstitutionId = computed(
  () => store.state.profileData?.colleges?.[0]?.college_id ?? ""
);

const toggleFinancialAid = (usingAid) => {
  state.usingFinancialAid = usingAid;
  emit("financial-aid-chosen", usingAid);
};

const confirmOrder = () => {
  state.loading = true;
  ordersService
    .confirmOrder({
      orderId: props.order.id,
      billingContractId: billingContractId.value,
      usingFinancialAid: state.usingFinancialAid,
    })
    .then(() => {
      close(true);
    })
    .catch(() => {
      state.errorMessage =
        "An error has occurred. Please contact API technical support.";
    })
    .finally(() => {
      state.loading = false;
      continueButton.value.startLoading();
    });
};

const emit = defineEmits(["close", "financial-aid-chosen"]);

const close = (skipValidation = false) => {
  if (institutionPays.value === false || skipValidation) emit("close");
};

const handleClick = () => {
  continueButton.value.startLoading();
  if (institutionPays.value) {
    confirmOrder();
    return;
  }

  continueToPaymentPage();
};

const continueToPaymentPage = async () => {
  await ordersService
    .getOne({
      order_id: props.order.id,
    })
    .then((data) => {
      const orderStatus = data?.data?.data?.order?.status;
      if (orderStatus === ORDER_STATUS.paidConfirmed) {
        throw new Error("The order has already been confirmed.");
      }
      localStorage.setItem(
        "toPay",
        JSON.stringify([
          {
            name: "Confirmation Payment",
            balance: "400.00",
            due_date: new Date().toISOString().split("T")[0],
            paid_by: "Participant",
            status: "Open",
            sku: "439",
            paid: "0.00",
          },
        ])
      );

      let query = {
        orderId: props.order.id,
        ...(state.usingFinancialAid
          ? { usingFinancialAid: state.usingFinancialAid }
          : {}),
      };

      router.push({
        name: "payment",
        params: { applicationId: props.applicationId },
        state: { usingFinancialAid: state.usingFinancialAid },
        query,
      });
    })
    .catch(() => {
      state.errorMessage = "The order has already been confirmed.";
      continueButton.value.stopLoading();
    });
};
</script>
