<script setup>
import EyeIcon from "@/components/forms/SharedComponents/EyeIcon.vue";
import Inputmask from "inputmask";
import { computed, defineEmits, defineProps, onMounted, ref } from "vue";

const emits = defineEmits(["update:modelValue"]);

const emitUpdateModelValue = (value) => {
  emits("update:modelValue", value);
};

const props = defineProps({
  vuelidateField: {
    type: Object,
    default: () => {},
  },
  modelValue: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  id: {
    type: String,
    default: "",
  },
  showErrors: {
    type: Boolean,
    default: false,
  },
  inputMask: {
    type: Object,
    default: () => {},
    /*
      Optional Feature: Adds input mask capabilities to an HTML element. 
      Docs: https://robinherbots.github.io/Inputmask/#/documentation
      Demo: https://robinherbots.github.io/Inputmask/#/demo
      Dev notes:
        - Customize the map you want to use in initializeInputMask method below

      Properties it could include:
      * = Required if feature will be used
        1. *maskType (String): This is our custom property to help us know how the mask should be initialized.
    */
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  labelTestId: {
    type: String,
    default: "",
  },
});

const inputType = ref(props.type);

const isPasswordInput = computed(() => {
  return props.type === "password";
});

const changeInputType = () => {
  inputType.value = inputType.value === "password" ? "text" : "password";
};

const initializeInputMask = () => {
  switch (props.inputMask?.maskType ?? "") {
    case "month-year": {
      let inputElement = document.getElementById(props.id);
      if (inputElement) {
        let inputMask = new Inputmask({
          alias: "datetime", // https://robinherbots.github.io/Inputmask/#/documentation/datetime
          inputFormat: "mm/yyyy",
          placeholder: "MM/YYYY",
          clearMaskOnLostFocus: false, // Ensure the mask remains on blur
        });
        inputMask.mask(inputElement); // Mask applied

        // Handle input event to update modelValue
        inputElement.addEventListener("input", (event) => {
          let value = event.target.value;
          emitUpdateModelValue(value);
        });

        // Handle change event for autocomplete and pasting
        inputElement.addEventListener("change", (event) => {
          let value = event.target.value;
          emitUpdateModelValue(value);
        });

        // Handle focusout event to ensure value is not lost
        inputElement.addEventListener("focusout", (event) => {
          let value = event.target.value;
          emitUpdateModelValue(value);
        });

        // Set initial value if provided
        if (props.modelValue) {
          inputElement.value = props.modelValue;
          inputMask.mask(inputElement); // Reapply the mask to update the format
        }
      }
      break;
    }
  }
};

// Lifecycle hooks
onMounted(() => {
  // Mask will be added to input if one was setup
  initializeInputMask();
});
</script>

<template>
  <div>
    <label
      :data-testid="labelTestId"
      :for="id"
      class="block text-sm font-medium text-indigo-base"
    >
      <span class="font-medium flex justify-between mb-1">
        {{ label }}
      </span>
      <div :class="{ relative: isPasswordInput }">
        <input
          :id="props.id"
          :disabled="props.disabled"
          :value="props.modelValue"
          :data-cy="props.id + 'Text'"
          :type="inputType"
          class="block outline-none w-full border-0 p-4 ring-1 ring-inset focus:ring-2 focus:ring-inset appearance-none"
          :class="
            props.vuelidateField.$error
              ? 'ring-error-900 focus:ring-error-900'
              : 'ring-indigo-base focus:ring-indigo-base'
          "
          :placeholder="props.placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
          @blur="props.vuelidateField?.$touch"
        />
        <a
          v-if="isPasswordInput"
          class="absolute inset-y-0 right-0 flex items-center px-4"
          :class="secondaryColorClass"
          @click="changeInputType"
        >
          <EyeIcon :open="inputType === 'text'" />
        </a>
      </div>
    </label>
    <template v-if="showErrors">
      <p
        v-for="error of props.vuelidateField?.$errors"
        :key="error.$uid"
        class="error text-error-900 text-sm"
      >
        {{ error.$message }}
      </p>
    </template>
    <slot name="help-content"></slot>
  </div>
</template>
