l<template>
  <div>
    <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8 mt-5">
      <div
        class="max-w-4xl px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
      >
        <div class="space-y-3">
          <h1 class="text-xl text-gray-650 md:text-2xl mt-3">
            End User License AGREEMENT
          </h1>
          <p><strong>Last Updated November 3, 2022</strong></p>
          <p>
            <span>Please read this Academic Programs International (“</span
            ><b>API</b><span>”) Software End User License Agreement (“</span
            ><b>EULA</b
            ><span
              >”) carefully before downloading or using any ;software offered by
              API(“</span
            ><b>Software</b>
          </p>
          <p>
            <span
              >BY CLICKING A BOX INDICATING ACCEPTANCE OF THIS EULA OR
              DOWNLOADING OR USING THE SOFTWARE, THIS EULA FORMS A BINDING LEGAL
              AGREEMENT BETWEEN YOU AND ANY ORGANIZATION OR OTHER ENTITY ON
              WHOSE BEHALF YOU ACCEPT THESE TERMS AND CONDITIONS (COLLECTIVELY
              “</span
            ><b>YOU</b><span>” OR “</span><b>YOUR</b
            ><span
              >”) AND API, A DELAWARE LIMITED LIABILITY COMPANY, AND WILL GOVERN
              YOUR DOWNLOAD AND USE OF THE SOFTWARE. IF YOU DO NOT AGREE TO THE
              TERMS AND CONDITIONS OF THIS EULA, DO NOT DOWNLOAD OR USE THE
              SOFTWARE.</span
            >
          </p>
          <p>
            ANY INDIVIDUAL AGREEING TO BE BOUND BY THIS EULA ON BEHALF OF AN
            ORGANIZATION OR OTHER LEGAL ENTITY REPRESENTS THAT SUCH INDIVIDUAL
            HAS THE AUTHORITY TO BIND SUCH ENTITY TO THE TERMS AND CONDITIONS
            CONTAINED HEREIN.
          </p>
          <p>
            YOU SHALL NOT DOWNLOAD OR USE THE SOFTWARE WITHOUT PRIOR WRITTEN
            CONSENT OF API IF YOU ARE OR BECOME A DIRECT COMPETITOR TO API OR
            ITS AFFILIATES.
          </p>
        </div>

        <ol class="mt-3 list-decimal space-y-5">
          <li>
            <b class="text-md"> Software</b>
            <ul class="list-disc mt-4 ml-5">
              <li>
                <span>1.1 License</span><span>API</span>
                <p>
                  grants You a non-exclusive, non-transferable, limited license
                  to download and use the Software on a device you own or
                  lawfully control for Your own business purposes. The Software,
                  including software embedded in the Software, is licensed, not
                  sold, to You. This Software may include some third-party
                  software; please see the Documentation for additional notices
                  required by certain third-party licensors.
                </p>
              </li>
              <li class="mt-4">
                <span>1.2 Documentation</span>
                <p>
                  Accompanying the Software at download or on
                </p>
                <span>API</span><span>’s website at </span
                ><span>https://apiabroad.com/</span
                ><span>
                  or any website linked from such website that is owned or
                  controlled by API, API may provide documentation that is
                  uniformly available and applicable to all API customers and
                  relates to the operation and use of the Software, including
                  directions and acknowledgements, each as updated by API from
                  time to time (“</span
                ><b>Documentation</b><span>”).</span>
              </li>
              <li class="mt-3">
                <span>1.3 Restrictions</span>
                <p>
                  . You shall use the Software strictly in compliance with this
                  EULA, the Documentation, and applicable laws and regulations,
                  and shall promptly notify API of any known unauthorized use.
                  You shall not:
                </p>
                <ul class="list-disc list-inside ml-5 space-y-3">
                  <li>
                    <span
                      >use the Software to do anything that is unlawful or
                      unethical, or that encourages another to engage in
                      anything unlawful or unethical;</span
                    >
                  </li>
                  <li>
                    <span
                      >introduce, or allow the introduction of, a virus or any
                      other similar programs or software which may damage the
                      operation of the Software;</span
                    >
                  </li>
                  <li>
                    <span
                      >use the Software in a way that violates the rights of any
                      party or infringes upon the patent, trademark, trade
                      secret, copyright, right of privacy or publicity or other
                      intellectual property right of any party;</span
                    >
                  </li>
                  <li>
                    <span
                      >use the Software in a way that is libelous, defamatory,
                      pornographic, obscene, lewd, indecent, inappropriate,
                      invasive of privacy or publicity rights, abusing,
                      harassing, threatening, or bullying;</span
                    >
                  </li>
                  <li>
                    <span
                      >modify, adapt, translate, copy, reverse engineer,
                      decompile or disassemble any portion of the
                      Software;</span
                    >
                  </li>
                  <li>
                    <span
                      >interfere with or disrupt the operation of the Software,
                      including restricting or inhibiting any other person from
                      using the Software by means of hacking or defacing;</span
                    >
                  </li>
                  <li>
                    <span
                      >attempt to probe, scan or test the vulnerability of a
                      system, a network, or the Software or to breach security
                      or authentication measures without proper
                      authorization;</span
                    >
                  </li>
                  <li>
                    <span
                      >take any action that imposes, or may impose, in our sole
                      discretion, an unreasonable or disproportionately large
                      load on our infrastructure;</span
                    >
                  </li>
                  <li aria-level="2">
                    <span
                      >harvest or collect the email address, contact
                      information, or any other personal information of other
                      users of the Software;</span
                    >
                  </li>
                  <li>
                    <span
                      >use any means to crawl, scrape or collect content or
                      personal information from the Software via automated or
                      large group means;</span
                    >
                  </li>
                  <li>
                    <span
                      >submit, post or make available false, incomplete or
                      misleading information to the Software, or otherwise
                      provide such information to us;</span
                    >
                  </li>
                  <li>
                    <span>register for more than one user account;</span>
                  </li>
                  <li>
                    <span
                      >breach, through the Software, any agreements that you
                      enter, or have entered, into with any third parties;</span
                    >
                  </li>
                  <li>
                    <span
                      >stalk, harass, injure, or harm another individual, or
                      attempt to do any of the foregoing, through the
                      Software;</span
                    >
                  </li>
                  <li>
                    <span>impersonate any other person or business; or</span>
                  </li>
                  <li>
                    <span
                      >commercially sell, resell, license, sublicense,
                      distribute, or frame the Software to a third party.</span
                    >
                  </li>
                </ul>
              </li>
              <li class="mt-3">
                <span>1.4 Updates and Support</span>
                <p>
                  . API may modify, upgrade or otherwise update the Software at
                  any time. API may provide support for the Software but has no
                  obligation to furnish such support to You and may cease to
                  offer such support at any time without notice.
                </p>
              </li>
              <li class="mt-3">
                <span>1.5 Electronic Messaging</span>
                <p>
                  By downloading and/or using the Software, You authorize API to
                  send You (including via email) information regarding the
                  Software, including: (a) notices about Your use of the
                  Software and violations of this EULA; (b) updates to the
                  Software; and (c) promotional information and materials
                  regarding API’s products and services. As may be provided in
                  the information sent or the settings or account information
                  section of the Software, You may be able to adjust Your
                  notification and messaging preferences.
                </p>
              </li>
            </ul>
          </li>

          <li>
            <span class="font-bold"> Privacy.</span>
            <p>
              In order to provide the Software, API may collect certain
              information about You and your device, including technical and
              telemetry data related to your use of the Software. API uses and
              protects that information in accordance with the&nbsp;
            </p>
            <i><span>API Privacy Policy</span></i
            ><span>available at https://apiabroad.com/privacy-policy/.</span>
          </li>
          <li class="">
            <span class="font-bold"> DISCLAIMER.</span>
            <p>
              EXCEPT FOR THE REPRESENTATIONS AND WARRANTIES EXPRESSLY STATED IN
              THIS EULA, API MAKES NO REPRESENTATIONS AND DISCLAIMS ALL
              WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
              LIMITING THE GENERALITY OF THE FOREGOING: (A) API SPECIFICALLY
              DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, COMPLIANCE WITH LAWS, NON-INFRINGEMENT, AND
              ACCURACY; (B) API DOES NOT WARRANT THAT THE SOFTWARE WILL BE
              ERROR-FREE OR OPERATE WITHOUT INTERRUPTIONS OR DOWNTIME; AND (C)
              YOUR DOWNLOAD AND/OR USE THE SOFTWARE IS AT YOUR OWN DISCRETION
              AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR
              DEVICES OR FOR LOSS OF DATA THAT RESULT FROM THE DOWNLOAD OR USE
              OF THE SOFTWARE.
            </p>
          </li>
          <li>
            <span class="font-bold"> LIMITATION OF LIABILITY.</span>
            <p>
              <span
                >TO THE EXTENT PERMITTED BY LAW, IN NO EVENT WILL API BE LIABLE
                FOR ANY LOST PROFITS, GOODWILL, OR REVENUES OR FOR ANY
                INCIDENTAL, CONSEQUENTIAL, SPECIAL, INDIRECT, COVER, BUSINESS
                INTERRUPTION, OR PUNITIVE DAMAGES IN CONNECTION WITH ANY CLAIM
                OF ANY NATURE, WHETHER IN CONTRACT, TORT, OR UNDER ANY THEORY OF
                LIABILITY, ARISING UNDER THIS EULA, EVEN IF API HAS GIVEN YOU
                ADVANCE NOTICE OF SUCH POSSIBLE DAMAGES OR IF API’S REMEDY
                OTHERWISE FAILS OF ITS ESSENTIAL PURPOSE.</span
              >
            </p>
            <p>
              TO THE EXTENT PERMITTED BY LAW, API’S ENTIRE LIABILITY UNDER THIS
              EULA WILL NOT EXCEED THE GREATER OF FIVE HUNDRED U.S. DOLLARS
              ($500.00) OR THE FEES PAID BY YOU TO API FOR THE SOFTWARE DURING
              THE TWELVE (12) MONTHS PRIOR TO THE DATE ON WHICH THE LIABILITY
              AROSE. THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS
              LIMIT.
            </p>
          </li>
          <li>
            <span class="font-bold"> Suspension and Termination. </span>
            <p>
              API reserves the right to restrict or prohibit Your download or
              use of the Software at any time, including based on the status of
              Your account under the Services Agreement. You acknowledge and
              agree that if Your account is suspended or terminated, You will no
              longer download or use the Software and your access to data,
              information, and content that is stored within the Subscription
              Services may be restricted or prohibited.
            </p>
          </li>
          <li>
            <span class="font-bold"> General</span>

            <ul class="list-disc ml-5 space-y-3">
              <li>
                <span>6.1 Compliance</span>
                <p>
                  . You represent and warrant that: (a) You are not located in a
                  country that is subject to a United States Government embargo,
                  or that is otherwise sanctioned by the United States Code of
                  Federal Regulations; (b) You are not located in a country that
                  has been designated by the United States Government as a
                  “terrorist supporting” country; and (c) You are not listed on
                  any United States Government list of prohibited or restricted
                  parties. You further agree not to download or transport the
                  Software to or use the Software in any such country.
                </p>
              </li>
              <li>
                <span>6.2 Feedback</span>
                <p>
                  . You grant API a worldwide, irrevocable, perpetual,
                  sublicensable, transferable, non-exclusive license to use and
                  incorporate into API’s products and services any feedback or
                  suggestions for enhancement that You provide to API (“
                </p>
                <span>Feedback</span
                ><span
                  >”), without any obligation of compensation. Feedback is
                  provided by You “as-is,” without representations or
                  warranties, either express or implied, including any
                  warranties of merchantability or fitness for a particular
                  purpose.</span
                >
              </li>
              <li>
                <span>6.3 Contact</span>
                <p>
                  . API may be contacted or noticed at the following address and
                  email: Attn: Legal, 301 Camp Craft, Ste 200, Austin, Texas
                  78746 or api-legal@apiexperience.com.
                </p>
              </li>
              <li>
                <span>6.4 Governing Law</span>
                <p>
                  . This EULA is governed by and construed in accordance with
                  the laws of the State of Delaware without reference to any
                  choice of law rules that would require the application of the
                  laws of any other jurisdiction.
                </p>
              </li>
              <li>
                <span>6.5 Entire Agreement</span>
                <p>
                  . This EULA represents the entire agreement between API and
                  Customer with respect to the Software. Your access to and use
                  of the services related to the Software remains subject to the
                  agreement between You and API governing such access and use
                  (the “Service Agreement”). With respect to Your use of the
                  Software, to the extent the Services Agreement conflicts with
                  this EULA, the terms of this EULA will control.
                </p>
              </li>
              <li>
                <span>6.6 Revisions</span>
                <p>
                  . API reserves the right to revise this EULA at any time and
                  for any reason. API will post the most current version of this
                  EULA at [
                </p>
                <span>LINK</span
                ><span
                  >]. If API makes material changes to this EULA, You will
                  receive notification via the Software, or by posting the new
                  version of the EULA on our website. Notwithstanding the
                  foregoing, You are responsible for complying with the updated
                  terms posted online at API’s website even if these updated
                  terms appear online at API’s website before being posted on
                  the Software. Your continued use of the Software, or other
                  method of legal acceptance, after API publishes notice of
                  changes to this EULA indicates Your consent to the updated
                  terms.</span
                >
              </li>
            </ul>
          </li>
          <li>
            <span class="font-bold"> Third Parties.</span>
            <ul class="space-y-3">
              <li>
                <span>7.1 EULA Claims</span>
                <p>
                  . This EULA is between You and API and not between you and any
                  third party. You agree that any claims brought by You arising
                  out of this EULA or Your use of the Software will not be made
                  against any third party that has licensed or authorized the
                  association or integration of its products, applications, or
                  content with the Software. Notwithstanding the foregoing, upon
                  Your acceptance of this EULA, any applicable third party may
                  enforce this EULA against You as a third-party beneficiary
                  thereof.&nbsp;For such purposes, please be advised that API’s
                  Fare Finder app is currently powered by Key Travel.
                </p>
              </li>
              <li>
                <span>7.2 Intellectual Property Rights</span>
                <p>
                  . In the event of a third-party claim that the Software, or
                  Your possession and use of the Software, infringes or
                  misappropriates a third party’s intellectual property rights,
                  API will be solely responsible for the investigation, defense,
                  settlement and discharge of any such claim.
                </p>
              </li>
              <li>
                <p>
                  <span>7.3 Products</span>
                </p>
                <p>
                  If You separately procure services, applications, or content
                  from a third party (“
                </p>
                <span>Third-Party Products</span>
                <p>
                  ”) for use with the Software, any such use is subject to the
                  end-user license or use agreement that You accept from or
                  establish with the third party. Third-Party Products are not
                  Software and, as between the parties, API has no liability
                  with respect to your procurement or use of Third-Party
                  Products.
                </p>
              </li>
            </ul>
          </li>
        </ol>

        <div
          v-if="!alreadySubmitted && !successfullyCreated"
          class="flex items-center mt-5"
        >
          <input
            id="link-checkbox"
            v-model="data.acceptance"
            type="checkbox"
            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="link-checkbox"
            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >I agree to
            <a href="#" class="text-blue-600 dark:text-blue-500 hover:underline"
              >comply with the terms of the API End User License Agreement</a
            >.</label
          >
        </div>
        <ButtonWithSpinner
          v-if="data.acceptance && !alreadySubmitted && !successfullyCreated"
          ref="SubmitForm"
          type="button"
          variant="primary"
          variant-type="block"
          @click.prevent="handleSubmission"
        >
          <span
            >Submit
            <svg
              v-if="successfullyCreated"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="inline feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline></svg
          ></span>
        </ButtonWithSpinner>

        <div class="mt-4">
          <div v-if="successfullyCreated" class="text-success-900 text-center">
            Submission Complete, thank you!
          </div>
          <div v-if="alreadySubmitted" class="text-success-900 text-center">
            You have already submitted your acceptance for the API End User
            License Agreement!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formService from "@/services/form.js";
import { mapState } from "vuex";
import ButtonWithSpinner from "../../forms/SharedComponents/ButtonWithSpinner.vue";
export default {
  name: "EULA",
  components: {
    ButtonWithSpinner,
  },
  data() {
    return {
      data: {
        acceptance: false,
      },
      formUrl: "eulaacceptance",
      successfullyCreated: false,
      alreadySubmitted: false,
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  created() {
    formService
      .submissionExists(this.formUrl, {
        "data.email": this.currentUser?.email,
      })
      .then(() => {
        this.alreadySubmitted = true;
      });
  },

  methods: {
    async handleSubmission() {
      let payload = {
        data: {
          acceptance: true,
          email: this.currentUser.email,
        },
      };
      formService.createSubmission(this.formUrl, payload).then(() => {
        this.successfullyCreated = true;
      });
    },
  },
};
</script>
