import { uuid } from "vue-uuid";
import {
  baseClassSelectionRule,
  classesTaughtTimesOptions,
  transportationProvidedOptions,
  transportationProvidedOptionIds,
  classesTaughtTimesOptionIds,
  baseEventSelectionRule,
  CLASSES_EDUCATIONAL_FEE,
  ONE_CREDIT_ADD_ON,
  THREE_CREDIT_ADD_ON,
  GENERAL_GROUP_VISA_SERVICE,
  GROUP_FLIGHT,
  PUBLIC_TRANSPORTATION_CARD,
  MEDICAL_INSURANCE,
  SPAIN_MEDICAL_INSURANCE,
  SPAIN_GROUP_VISA_SERVICE,
  UMASS_TRANSCRIPT_SERVICES,
  UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES,
} from "@/components/program-manager/sessions/constants";
import {
  addedOptionsId,
  addedOptions,
} from "@/components/program-manager/sessions/components/Academics/constants";
import { unref } from "vue";
import { centsToDollars, dollarsToCents } from "@/mixins/helpers";

import { filterNonActiveRoomsAndUnits } from "@/components/program-manager/sessions/utils";
import { cloneDeep, sortBy } from "lodash";
import { APPLICATION_FEE_DATA } from "@/constants";

const ADDITIONAL_FEE_FIELDS = [
  // Mandatory additional fees
  CLASSES_EDUCATIONAL_FEE,
  GROUP_FLIGHT,
  PUBLIC_TRANSPORTATION_CARD,
  MEDICAL_INSURANCE,
  SPAIN_MEDICAL_INSURANCE,
  // Optional add-ons to be added to session.available_products
  ONE_CREDIT_ADD_ON,
  THREE_CREDIT_ADD_ON,
  GENERAL_GROUP_VISA_SERVICE,
  SPAIN_GROUP_VISA_SERVICE,
  UMASS_TRANSCRIPT_SERVICES,
  UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES,
];

const getAvailableProductsPriceInCents = (programSession, miscProduct) => {
  const availableProduct = programSession?.available_products?.find(
    (val) => val.id === miscProduct.id
  );

  const productPrice = availableProduct?.product_prices.find(
    (val) => val.product_id === miscProduct.id
  );

  return !Number.isNaN(Number(productPrice?.price_in_cents))
    ? Number(productPrice?.price_in_cents)
    : undefined;
};

const getAdditionalFeePriceInCents = (programSession, miscProduct) => {
  const additionalFee = programSession?.additional_session_fees?.find(
    (val) => val.product_id === miscProduct.id
  );

  return !Number.isNaN(Number(additionalFee?.price_in_cents))
    ? Number(additionalFee?.price_in_cents)
    : undefined;
};

const additionalFeeDeserializer = (programSession, acc, curr) => {
  const price =
    curr?.optional || curr?.price_in_cents
      ? getAvailableProductsPriceInCents(programSession, curr)
      : getAdditionalFeePriceInCents(programSession, curr);

  if (price) {
    return {
      ...acc,
      [curr?.key]: centsToDollars(price),
    };
  } else {
    return acc;
  }
};

const deserializeAdditionalSessionFees = (programSession) =>
  ADDITIONAL_FEE_FIELDS.reduce(
    (acc, curr) => additionalFeeDeserializer(programSession, acc, curr),
    {}
  );

const additionalFeeSerializer = (programSession, acc, curr) => {
  const { id, optional, key, price_in_cents: priceInCents } = curr;

  if (programSession[key]) {
    const fee = {
      id,
      product_id: id,
      price_in_cents:
        priceInCents ?? dollarsToCents(Number(programSession[key])),
    };

    const availableProducts = acc.products.slice();
    const additionalFees = acc.fees.slice();

    if (optional) {
      availableProducts.push({ id });
    } else {
      additionalFees.push(fee);
      availableProducts.push({ id });
    }

    return {
      fees: additionalFees,
      products: availableProducts,
    };
  }

  return acc;
};

const serializeAdditionalSessionFees = (programSession) =>
  ADDITIONAL_FEE_FIELDS.reduce(
    (acc, curr) => additionalFeeSerializer(programSession, acc, curr),
    { fees: [], products: [] }
  );

export const convertProgramSession = (programSession) => {
  let session_travel_details = [
    {
      arrival_city_id: undefined,
      arrival_time_utc: undefined,
      arrival_date: undefined,
      departure_time_utc: undefined,
      departure_date: undefined,
      arrival_date_is_final: undefined,
      departure_date_is_final: undefined,
      arrival_airport: undefined,
      departure_airport: undefined,
      transportation_provided: undefined,
    },
  ];

  if (
    programSession.session_travel_details &&
    programSession.session_travel_details.length
  ) {
    session_travel_details = programSession.session_travel_details.map(
      (val) => {
        const transportation_provided = [];
        if (val.departure_transport_provided) {
          transportation_provided.push(transportationProvidedOptions[1]);
        }
        if (val.arrival_transport_provided) {
          transportation_provided.push(transportationProvidedOptions[0]);
        }
        return { ...val, transportation_provided };
      }
    );
  }

  let converted_internship_filter_criteria;
  if (programSession.internship_filter_criteria) {
    const durations = programSession?.internship_filter_criteria?.durations?.map(
      (val) => ({ id: Number(val), label: `${val} W` })
    );

    converted_internship_filter_criteria = {
      ...programSession.internship_filter_criteria,
      durations: durations && durations.length > 0 ? durations[0] : undefined,
      cities: programSession?.internship_filter_criteria?.cities?.map(
        (val) => ({ city_id: val.city_id, city_ascii: val.city.city_ascii })
      ),
      terms: programSession?.internship_filter_criteria?.terms,
      work_locations: programSession?.internship_filter_criteria
        ?.work_locations[0]?.id
        ? programSession?.internship_filter_criteria?.work_locations[0]
        : undefined,
    };
  }

  let converted_class_filter_criteria;
  if (programSession.class_filter_criteria) {
    converted_class_filter_criteria = {
      ...programSession.class_filter_criteria,
      cip_codes: programSession?.class_filter_criteria?.cip_codes
        ? programSession.class_filter_criteria.cip_codes.map((ele) => ({
            ...ele,
            id: ele.cip_code,
            name: ele.cip_title,
          }))
        : [],
    };
  }

  let converted_session_class_selection_rules;
  if (programSession?.session_class_selection_rules?.length) {
    converted_session_class_selection_rules = convertClassesRulesToTree(
      programSession.session_class_selection_rules
    );
  } else {
    converted_session_class_selection_rules = {
      value: { ...baseClassSelectionRule(), id: uuid.v4() },
    };
  }

  let converted_session_event_selection_rules;
  if (
    programSession?.session_event_selection_rules?.length &&
    programSession?.session_events?.length > 0
  ) {
    converted_session_event_selection_rules = convertEventsRulesToTree(
      programSession.session_event_selection_rules
    );
  } else {
    converted_session_event_selection_rules = {
      value: { ...baseEventSelectionRule(), id: uuid.v4() },
    };
  }

  const converted_specific_class_taught_time = [];
  if (programSession?.class_taught_morning) {
    converted_specific_class_taught_time.push(classesTaughtTimesOptions[0]);
  }

  if (programSession?.class_taught_afternoon) {
    converted_specific_class_taught_time.push(classesTaughtTimesOptions[1]);
  }

  let converted_academics_days_of_week;
  if (programSession?.academics_days_of_week) {
    converted_academics_days_of_week = programSession?.academics_days_of_week;
  }

  const exact_credits =
    programSession.academic_min_credits === programSession.academic_max_credits;

  let converted_session_hosting_institutions;
  if (
    Array.isArray(programSession.session_hosting_institutions) &&
    programSession.session_hosting_institutions.length > 0
  ) {
    converted_session_hosting_institutions = programSession.session_hosting_institutions.map(
      (hostInstitution) => ({
        ...hostInstitution,
        name: hostInstitution?.entity?.name,
      })
    );
  } else {
    converted_session_hosting_institutions = [
      {
        entity_id: undefined,
        entity: undefined,
        session_id: undefined,
        partner_text: undefined,
        product_line_id: undefined,
        name: undefined,
      },
    ];
  }

  let converted_sponsoring_institutions;
  if (
    Array.isArray(programSession.sponsoring_institutions) &&
    programSession.sponsoring_institutions.length > 0
  ) {
    converted_sponsoring_institutions = {
      entity: programSession.sponsoring_institutions[0],
      entity_id: programSession.sponsoring_institutions[0]?.id,
      name: programSession.sponsoring_institutions[0]?.name,
    };
  } else {
    converted_sponsoring_institutions = {
      entity: undefined,
      entity_id: undefined,
      session_id: undefined,
      name: undefined,
    };
  }

  const selectedHousingMap = {};
  const convertedSessionUnits = [];
  programSession?.session_units?.forEach((val) => {
    convertedSessionUnits.push({ ...val.unit, linkedId: val.id });
    if (val?.unit?.housings?.id && !selectedHousingMap[val.unit.housings.id]) {
      selectedHousingMap[val.unit.housings.id] = val.unit.housings;
    }
  });

  const filteredUnitHousing = filterNonActiveRoomsAndUnits(
    programSession?.unit_housings
  );

  const sortedFilteredUnitHousing = sortBy(filteredUnitHousing, [
    (filteredUnitHousingObject) => {
      return filteredUnitHousingObject?.name?.toLowerCase();
    },
  ]);

  const additionalFees = deserializeAdditionalSessionFees(programSession);

  return {
    ...programSession,
    owner_entity_id: programSession?.owner_entity_id,
    session_types: programSession?.session_types,
    session_profiles: programSession?.session_profiles || [],
    acceptanceDecisions: programSession?.acceptanceDecisions || [],
    gpa_exception_requirements:
      programSession?.gpa_exception_requirements || [],
    required_languages: programSession?.required_languages || [],
    session_travel_details,
    status_id: programSession?.status_id || 1,
    host_institution: programSession?.host_institution,
    tags: programSession?.tags || [],
    internship_filter_criteria: converted_internship_filter_criteria,
    class_filter_criteria: converted_class_filter_criteria,
    session_class_selection_rules: converted_session_class_selection_rules,
    session_event_selection_rules: converted_session_event_selection_rules,

    specific_class_taught_time: converted_specific_class_taught_time,
    academics_days_of_week: converted_academics_days_of_week,

    exact_credits,
    session_hosting_institutions: converted_session_hosting_institutions,
    sponsoring_institutions: converted_sponsoring_institutions,
    marketplace: programSession?.marketplace || false,

    // Academics
    one_credit_add_on: additionalFees.one_credit_add_on,
    three_credit_add_on: additionalFees.three_credit_add_on,
    classes_educational_fee: additionalFees.classes_educational_fee,

    academics_assessment_type_id: programSession?.academic_assessment_type_id,

    // Housing
    session_units: convertedSessionUnits,
    unit_housings: sortedFilteredUnitHousing,
    convertedSessionEvents: cloneDeep(
      programSession.session_events || []
    ).map((val) => ({ ...val, title: "" })),

    // Additional Program Session Add-ons
    group_flight: additionalFees.group_flight,
    public_transportation_card: additionalFees.public_transportation_card,
    medical_insurance: additionalFees.medical_insurance,
    spain_medical_insurance: additionalFees.spain_medical_insurance,

    // Product Available for Add-on
    general_group_visa_service: additionalFees.general_group_visa_service,
    spain_group_visa_service: additionalFees.spain_group_visa_service,
    umass_transcript_services: additionalFees.umass_transcript_services,
    universidad_congresso_transcript_services:
      additionalFees.universidad_congresso_transcript_services,

    //TODO: Fix non syncing

    // academic_theme: undefined,

    designated_insurance: undefined,
    can_extend_stay: undefined,
    days_can_extend: undefined,
    need_hotel: undefined,
    book_hotel_extra_stays: undefined,
  };
};

export const convertProgramSessionBeforeSave = (programSession) => {
  let converted_internship_filter_criteria;

  if (programSession.internship_filter_criteria) {
    const duration = Number(
      programSession?.internship_filter_criteria?.durations?.id
    );

    converted_internship_filter_criteria = {
      ...programSession.internship_filter_criteria,
      // TODO: Handle a full array later
      // durations: programSession?.internship_filter_criteria?.durations
      //   ?.map((val) => Number(val?.id))
      //   .filter((val) => !Number.isNaN(val)),
      durations: !Number.isNaN(duration) ? [duration] : undefined,
      cities: programSession?.internship_filter_criteria?.cities?.map(
        (val) => val.city_id
      ),
      terms: programSession?.internship_filter_criteria?.terms?.map(
        (val) => val.id
      ),
      work_locations: programSession?.internship_filter_criteria?.work_locations
        ?.id
        ? [
            {
              work_location_id:
                programSession?.internship_filter_criteria?.work_locations.id,
            },
          ]
        : undefined,
      placement_type: convertPlacementType(
        programSession?.internship_filter_criteria?.placement_type
      ),
    };
  }

  let convertedClassFilterCriteria;
  if (programSession.class_filter_criteria) {
    convertedClassFilterCriteria = {
      host_institutions: programSession?.class_filter_criteria?.host_institutions?.map(
        (val) => val.id
      ),
      cip_codes: programSession?.class_filter_criteria?.cip_codes?.map(
        (val) => val.cip_code
      ),
      required_languages: programSession?.class_filter_criteria?.required_languages?.map(
        (val) => val.id
      ),
      proficiency_levels: programSession?.class_filter_criteria?.proficiency_levels?.map(
        (val) => val.id
      ),
      education_levels: programSession?.class_filter_criteria?.education_levels?.map(
        (val) => val.id
      ),
      terms: programSession?.class_filter_criteria?.terms?.map((val) => val.id),
      // TODO: Enable with class_format is needed
      // class_format: programSession?.class_filter_criteria?.class_format,
    };
  }

  let class_taught_morning = false;
  let class_taught_afternoon = false;
  if (programSession?.specific_class_taught_time?.length > 0) {
    if (
      programSession?.specific_class_taught_time?.find(
        (val) => val.id === classesTaughtTimesOptionIds.mornings
      )
    ) {
      class_taught_morning = true;
    }
    if (
      programSession?.specific_class_taught_time?.find(
        (val) => val.id === classesTaughtTimesOptionIds.afternoons
      )
    ) {
      class_taught_afternoon = true;
    }
  }

  let converted_academics_days_of_week;
  if (programSession?.academics_days_of_week?.length > 0) {
    converted_academics_days_of_week = programSession?.academics_days_of_week?.map(
      (val) => unref(val)
    );
  }

  let converted_academic_min_credits = programSession.academic_min_credits;
  if (programSession?.exact_credits) {
    converted_academic_min_credits = programSession.academic_max_credits;
  }

  let converted_session_class_selection_rules = [];
  if (
    programSession?.session_class_selection_rules &&
    programSession?.class_filter_criteria
  ) {
    covertTreeToClassRules({
      retVal: converted_session_class_selection_rules,
      node: programSession.session_class_selection_rules,
      sessionId: programSession.id,
      parentId: undefined,
    });
  }

  let converted_session_event_selection_rules = [];
  if (
    programSession?.session_event_selection_rules &&
    programSession?.convertedSessionEvents?.length
  ) {
    covertTreeToEventRules({
      retVal: converted_session_event_selection_rules,
      node: programSession.session_event_selection_rules,
      sessionId: programSession.id,
      parentId: undefined,
    });
  }

  programSession.duration = Number(programSession.duration)
    ? Number(programSession.duration)
    : undefined;

  if (!Array.isArray(programSession.session_hosting_institutions))
    programSession.session_hosting_institutions = [
      programSession.session_hosting_institutions,
    ];

  const convertedSessionHostingInstitutions = programSession.session_hosting_institutions
    .map((hostInstitution) => ({
      ...hostInstitution,
      entity_id: hostInstitution?.entity?.id,
      session_id: programSession.id,
      partner_text: hostInstitution?.partner_text || "-",
    }))
    .filter((val) => !!val?.entity_id);

  if (!Array.isArray(programSession.sponsoring_institutions))
    programSession.sponsoring_institutions = [
      programSession.sponsoring_institutions,
    ];

  const convertedSponsoringInstitutions = programSession.sponsoring_institutions
    .map((sponsoringInstitution) => ({
      ...sponsoringInstitution?.entity,
      entity_id: sponsoringInstitution?.entity?.id,
      session_id: programSession.id,
    }))
    .filter((val) => val?.entity_id);

  const convertedSessionProfiles =
    programSession.session_profiles?.map((sessionProfile) => ({
      profile_id: sessionProfile.profile_id,
      role: sessionProfile.role,
      session_id: programSession.id,
    })) || [];

  const convertedSessionUnits = programSession?.session_units?.map((val) => {
    return {
      session_id: programSession?.id,
      unit_id: val?.id,
      id: val?.linkedId,
    };
  });

  const updatedIdEvents = programSession.convertedSessionEvents.map((val) => ({
    event_id: val?.id || val?.event_id,
  }));

  const {
    fees: additional_session_fees,
    products: available_products,
  } = serializeAdditionalSessionFees(programSession);

  return {
    ...programSession,
    owner_entity_id: programSession.owner_entity_id,
    session_profiles: convertedSessionProfiles,
    session_travel_details: programSession.session_travel_details.map((val) => {
      const new_session_travel_details = {
        ...val,
        session_id: programSession.id,
        arrival_city_id: val?.arrival_city?.id,
        duration: Number(val.duration) ? Number(val.duration) : undefined,
        departure_date: val?.departure_date ? val?.departure_date : undefined,
        arrival_date: val?.arrival_date ? val?.arrival_date : undefined,
      };
      if (val.transportation_provided) {
        new_session_travel_details.departure_transport_provided = !!val.transportation_provided.find(
          (val) => val.id === transportationProvidedOptionIds.departure
        );
        new_session_travel_details.arrival_transport_provided = !!val.transportation_provided.find(
          (val) => val.id === transportationProvidedOptionIds.arrival
        );
        val.transportation_provided = undefined;
      }
      return new_session_travel_details;
    }),

    internship_filter_criteria: converted_internship_filter_criteria,
    class_filter_criteria: convertedClassFilterCriteria,
    session_class_selection_rules: converted_session_class_selection_rules,
    session_event_selection_rules: converted_session_event_selection_rules,

    class_taught_morning,
    class_taught_afternoon,

    academics_days_of_week: converted_academics_days_of_week,
    academic_max_credits: programSession?.academic_max_credits || undefined,
    academic_min_credits: converted_academic_min_credits || undefined,
    academics_assessment_type_id: programSession?.academics_assessment_type_id,
    transcript_provider_id: programSession?.transcript_provider?.id,
    forecasted_enrollments: programSession?.forecasted_enrollments || 0,

    application_deadline: programSession?.application_deadline || undefined,
    enrollment_deadline: programSession?.enrollment_deadline || undefined,
    final_payment_deadline: programSession?.final_payment_deadline || undefined,
    academic_start_date: programSession?.academic_start_date || undefined,
    academic_end_date: programSession?.academic_end_date || undefined,
    marketplace: programSession?.marketplace || false,

    session_hosting_institutions: convertedSessionHostingInstitutions,
    sponsoring_institutions: convertedSponsoringInstitutions,
    session_units: convertedSessionUnits,

    additional_session_fees,
    available_products,
    session_events: updatedIdEvents,

    //TODO: Fix non syncing
    // academic_theme: {},

    designated_insurance: undefined,
    group_flight: undefined,
    public_transportation_card: undefined,
    medical_insurance: undefined,
    spain_medical_insurance: undefined,
    can_extend_stay: undefined,
    days_can_extend: undefined,
    need_hotel: undefined,
    book_hotel_extra_stays: undefined,
    channel: undefined,
  };
};

const convertPlacementType = (placementType) => {
  let placementTypeId;

  if (Array.isArray(placementType)) {
    placementTypeId = placementType[0]?.id;
  } else if (placementType?.id) {
    placementTypeId = placementType.id;
  }

  return placementTypeId ? [placementTypeId] : undefined;
};
const getProdcutPrice = (programSession, field) =>
  (programSession[field] || 0) / 100;

const getProdcutPriceInCents = (programSession, field) =>
  programSession?.[field] || 0;

export const getSessionPricingSummary = (programSession) => ({
  applicationDeposit: APPLICATION_FEE_DATA.amount,
  confirmationPayment: 400,
  educationalFee: getProdcutPrice(
    programSession,
    "total_academic_fees_price_in_cents"
  ),
  groupFlightFee: getProdcutPrice(
    programSession,
    "group_flight_price_in_cents"
  ),
  publicTransportationFee: getProdcutPrice(
    programSession,
    "public_transportation_price_in_cents"
  ),
  housingFee: getProdcutPrice(programSession, "housing_price_in_cents"),
  classesFee: getProdcutPrice(
    programSession,
    "class_academic_fees_price_in_cents"
  ),
  internshipFee: getProdcutPrice(programSession, "internships_price_in_cents"),
  excursionFee: getProdcutPrice(programSession, "events_price_in_cents"),
  totalBasePrice: getProdcutPrice(programSession, "base_price_in_cents"),
  insuranceFee: getProdcutPrice(programSession, "insurance_price_in_cents"),
});

export const getSessionPricingSummaryInCents = (programSession) => ({
  applicationDeposit: APPLICATION_FEE_DATA.amount,
  confirmationPayment: 400,
  educationalFee: getProdcutPriceInCents(
    programSession,
    "total_academic_fees_price_in_cents"
  ),
  groupFlightFee: getProdcutPriceInCents(
    programSession,
    "group_flight_price_in_cents"
  ),
  publicTransportationFee: getProdcutPriceInCents(
    programSession,
    "public_transportation_price_in_cents"
  ),
  housingFee: getProdcutPriceInCents(programSession, "housing_price_in_cents"),
  classesFee: getProdcutPriceInCents(
    programSession,
    "class_academic_fees_price_in_cents"
  ),
  internshipFee: getProdcutPriceInCents(
    programSession,
    "internships_price_in_cents"
  ),
  excursionFee: getProdcutPriceInCents(programSession, "events_price_in_cents"),
  totalBasePrice: getProdcutPriceInCents(programSession, "base_price_in_cents"),
  insuranceFee: getProdcutPriceInCents(
    programSession,
    "insurance_price_in_cents"
  ),
});

export const convertSingleInputNode = (node) => {
  const library_classes = node.library_class_rules.map((val) => ({
    value: { ...val?.library_class },
  }));
  if (node.any_class_from_list) {
    library_classes.push({
      value: { ...addedOptions[0] },
    });
  }
  const single_session_classes = node.single_session_class_rules.map((val) => ({
    value: { ...val?.single_session_class },
  }));
  const retVal = {
    ...node,
    rules: [],
    library_classes,
    single_session_classes,
  };
  return { value: retVal };
};

export const convertClassesRulesToTree = (session_class_selection_rules) => {
  if (session_class_selection_rules.length) {
    const rootIndex = session_class_selection_rules.findIndex(
      (node) => !node.parent_rule_id
    );
    const root = session_class_selection_rules.splice(rootIndex, 1);
    if (root.length === 1) {
      const rootNodeConvert = convertSingleInputNode(root[0]);
      rootNodeConvert.value.rules = session_class_selection_rules.map(
        convertSingleInputNode
      );
      return rootNodeConvert;
    }
  }
};

export const covertTreeToClassRules = ({
  retVal,
  node,
  sessionId,
  parentId,
}) => {
  if (node) {
    const { value } = node;
    const id = value?.id || uuid.v4();
    const rule = {
      id,
      name: value?.name,
      conjunction: value?.conjunction,
      parent_rule_id: parentId,
      any_class_from_list: !!value.any_class_from_list,
      any_class_from_library: !!value.any_class_from_library,
      sessionId,
      single_session_class_rules: Array.isArray(value?.single_session_classes)
        ? value.single_session_classes
            .filter((val) => !!val?.value?.id)
            .map((val) => ({ class_id: val?.value?.id }))
        : undefined,
    };

    rule.any_class_from_library = false;
    rule.any_class_from_list = false;
    rule.library_class_rules = Array.isArray(value?.library_classes)
      ? value.library_classes
          .map((val) => {
            if (val?.value?.id === addedOptionsId.anyList) {
              rule.any_class_from_list = true;
            } else if (val?.value?.id) {
              return { class_id: val?.value?.id };
            }
          })
          .filter((val) => !!val)
      : undefined;
    retVal.push(rule);
    value?.rules?.forEach((node) =>
      covertTreeToClassRules({ retVal, node, sessionId, parentId: id })
    );
  }
};

export const convertSingleEventRuleNode = (node) => {
  const libraries = node.library_events.map((val) => ({
    value: { id: val?.event_id },
  }));
  const retVal = {
    ...node,
    rules: [],
    libraries,
  };
  return { value: retVal };
};

export const convertEventsRulesToTree = (session_event_selection_rules) => {
  if (session_event_selection_rules.length) {
    const rootIndex = session_event_selection_rules.findIndex(
      (node) => !node.parent_rule_id
    );
    const root = session_event_selection_rules.splice(rootIndex, 1);
    if (root.length === 1) {
      const rootNodeConvert = convertSingleEventRuleNode(root[0]);
      rootNodeConvert.value.rules = session_event_selection_rules.map(
        convertSingleEventRuleNode
      );
      return rootNodeConvert;
    }
  }
};

export const covertTreeToEventRules = ({ retVal, node, eventId, parentId }) => {
  if (node) {
    const { value } = node;
    const id = value?.id || uuid.v4();
    const rule = {
      id,
      name: value?.name,
      conjunction: value?.conjunction,
      parent_rule_id: parentId,
      default_event_id: value?.default_rule_id
        ? undefined
        : value?.default_event_id,
      default_rule_id: value?.default_rule_id,
    };

    rule.library_events = Array.isArray(value?.libraries)
      ? value.libraries
          .map((val) => {
            return {
              event_id: val?.value?.id,
            };
          })
          .filter((val) => !!val)
      : undefined;
    retVal.push(rule);
    value?.rules?.forEach((node) =>
      covertTreeToEventRules({ retVal, node, eventId, parentId: id })
    );
  }
};

export const vuelidateCollectionHelper = (
  vuelidateCollection,
  index,
  fieldName
) => {
  return {
    $touch: vuelidateCollection.$touch,
    $errors: vuelidateCollection.$each?.$response?.$errors[index][fieldName],
  };
};
