<script setup>
import { computed, defineProps, toRefs, ref, inject } from "vue";
import EventsModal from "@/components/ExperiencePage/ProgramDetailsSection/excursions/Components/EventsModal";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";
import { imageTransformation } from "@/util/cloudinary";
import {
  CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
  DEFAULT_IMAGE_CARD_SIZE,
  DEFAULT_EXCURSION_IMAGE,
} from "@/constants.js";
import { getRidOfJSON } from "@/util/removeJson";
import { formatterWithoutCents } from "@/util/formatter";
import { useStore } from "vuex";

defineOptions({
  name: "EventCard"
})
const props = defineProps({
  eventData: {
    type: Object,
    required: true,
  },
  defaultEvent: {
    type: Object,
    required: true,
  },
});
const store = useStore();
const { eventData: event } = toRefs(props);
const openModal = ref(false);
const eventRules = inject("eventRules");

const openEventDescriptionModal = () => {
  openModal.value = true;
};

const cardImage = computed(() => {
  let image = DEFAULT_EXCURSION_IMAGE;
  if (event?.value?.image) {
    let eventImage;
    try {
      eventImage = getRidOfJSON(event?.value?.image);

      image = imageTransformation({
        ...CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
        ...DEFAULT_IMAGE_CARD_SIZE,
        path: eventImage[0],
        compress: true,
      });
    } catch (error) {
      image = DEFAULT_EXCURSION_IMAGE;
    }
  }
  return image;
});
const priceFlagEnabled = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["program-page-card-price"];
});

const price = computed(() => {
  const isIncluded =
    eventRules.defaultIds.includes(props.eventData?.id) ||
    eventRules.andIds.includes(props.eventData?.id); // If event is the default or is inside an AND junction

  if (isIncluded) return "Included";
  return priceFlagEnabled.value
    ? `+ ${formatterWithoutCents.format(props.eventData.event.price_student / 100)}`
    : "";
});
</script>

<template>
  <div data-testid="event-card">
    <div
      :class="[secondaryColor ? `bg-gray-275` : `bg-university-accentPrimary`]"
    >
      <div class="font-bold text-base uppercase text-indigo-base px-3 py-3">
        {{ event?.event_subcategory?.name || "Not Found" }}
      </div>
    </div>
    <div class="flex w-full h-40 relative">
      <img
        class="object-cover w-full"
        :src="cardImage"
        alt="Event image view"
      />
    </div>
    <div class="relative z-[1] px-6 mt-4">
      <div v-if="price.length" class="md:block mt-4">
        <span
          data-testid="card-price"
          class="inline-block uppercase font-bold text-sm"
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `text-university-secondary`,
          ]"
        >
          {{ price }}
        </span>
      </div>
      <div
        class="hidden md:block font-bold text-xl mb-2 uppercase text-indigo-base"
      >
        {{ event?.title || "Not Found" }}
      </div>
      <JSONtoHTML
        class="text-base text-indigo-base h-30"
        :tiptap-json="event?.description"
        custom-class="line-clamp-3"
      />
    </div>
    <div class="relative z-[1] px-6 pt-4 pb-8">
      <button
        class="flex font-semibold"
        :class="[
          secondaryColor
            ? `${secondaryColorClass}`
            : `text-university-secondary`,
        ]"
        @click="openEventDescriptionModal()"
      >
        <span class="uppercase self-center">Learn More </span>
        <ArrowRight custom-class="w-5 h-5" />
      </button>
    </div>
    <EventsModal
      :event-data="event"
      :open-modal="openModal"
      @close-modal="openModal = false"
    />
  </div>
</template>
