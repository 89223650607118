import { format } from "date-fns";
import { baseHousingDetail } from "../data/baseHousingDetail";
import {
  buildBaseHousingUnitDetail,
  buildBaseHousingUnitRoomDetail,
  buildBaseHousingUnitRoomPrice,
} from "../data/baseHousingUnitDetail";
import _ from "lodash";
import axios from "axios";
import { joinArrayItems } from "@/mixins/helpers.js";

import { isStringAValidJSON } from "@/services/utils";
import housingService from "@/services/housings";
import internshipService from "@/services/internship.js";
import programsServiceOld from "@/services/programsServiceOld";
import classesService from "@/services/classes";
import countriesService from "@/services/countries";
import amenitiesService from "@/services/amenities";
import unitService from "@/services/unit";
import programSessionsService from "@/services/programSessions";
import unitTransformation from "../transform/unit";
import eventsService from "@/services/events";
import entityVisibilityService from "@/services/entityVisibility";
import { processPayload } from "../../util/processPayload";
import getters from "../getters";

import {
  AMENITIES_CATEGORIES,
  AMENITIES_GRANULARITIES,
  GROUP_PERMISSIONS,
  HOUSING_TYPES_MAP,
  PERMISSIONS,
  API_ENTITY_INFORMATION,
} from "@/constants";

const skipArchived = (data) =>
  data.filter((item) => item.status !== "Archived");
let cancelToken = null;

const runAndResetCancelToken = (cancelTokenSource) => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Request was cancelled");
    cancelTokenSource = null;
  }
};

const programManager = {
  namespaced: true,
  state: {
    programManager: {
      cards: [
        {
          title: "PROGRAM MANAGEMENT",
          permissions: [PERMISSIONS.PRGMS_READ, PERMISSIONS.PRGMS_READ_LITE],
          subcategories: [
            {
              title: "Programs",
              route: "program-manager-programs",
              count: 0,
              permissions: [
                PERMISSIONS.PRGMS_READ,
                PERMISSIONS.PRGMS_READ_LITE,
              ],
            },
            {
              title: "Programs Sessions",
              route: "program-manager-sessions",
              count: 0,
              permissions: [
                PERMISSIONS.PRGMS_READ,
                PERMISSIONS.PRGMS_READ_LITE,
              ],
            },
          ],
        },
        {
          title: "LIBRARY",
          subcategories: [
            {
              title: "Housing",
              route: "program-manager-housing",
              count: 0,
              permissions: [
                PERMISSIONS.ITEMS_READ,
                PERMISSIONS.ITEMS_READ_LITE,
              ],
            },
            {
              title: "Internships",
              route: "program-manager-internships",
              count: 0,
              permissions: [
                PERMISSIONS.ITEMS_READ,
                PERMISSIONS.ITEMS_READ_LITE,
              ],
            },
            {
              title: "Excursion + Events",
              route: "program-manager-events",
              count: 0,
              permissions: [
                PERMISSIONS.ITEMS_READ,
                PERMISSIONS.ITEMS_READ_LITE,
              ],
            },
            {
              title: "Content",
              route: null,
              count: 0,
            },
            {
              title: "Classes",
              route: "program-manager-classes",
              permissions: [
                PERMISSIONS.ITEMS_READ,
                PERMISSIONS.ITEMS_READ_LITE,
              ],
              count: 0,
            },
            {
              title: "Badges",
              route: null,
              count: 0,
            },
          ],
        },
        {
          title: "RECOMMENDATIONS",
          subcategories: [
            {
              title: "Saved",
              route: null,
              count: 0,
            },
          ],
        },
      ],
      data: {
        currentEntity: null,
        headers: [],
        items: [],
        defaultHeaders: [],
      },
      activeFilter: {
        title: "",
        permissions: GROUP_PERMISSIONS.REGULAR,
        count: 0,
      },
      advancedFilters: {},
      entityFilters: {
        programs: {},
        sessions: {},
      },
      countries: [],
      loading: false,
      invalidForm: false,
      invalidCreatNewHouse: false,
      redirect: false,
      alert: {
        show: false,
        success: false,
        danger: false,
        message: "",
      },
      currentEditing: {},
      tableItemActionsRoutes: {
        edit: "housing-edit",
      },
      unitTabValidation: {
        checkForInvalidRoom: false,
      },
      terms: {
        isLoading: false,
        isError: false,
        errorMessage: false,
        data: undefined,
      },
      listingError: undefined,
      editHousingError: undefined,
      conflictBookingsDetails: undefined,
      unitConflictBookingsDetails: undefined,
      vendors: {
        isLoading: false,
        isError: false,
        errorMessage: false,
        data: undefined,
      },
      amenities: {
        isLoading: false,
        isError: false,
        errorMessage: false,
        data: [],
      },
      homeInstitutions: {
        isLoading: false,
        isError: false,
        errorMessage: false,
        data: undefined,
      },
      archivingData: {
        items: [],
        modalState: false,
        resource: null,
      },
      duplicateData: {
        item: {},
        modalState: false,
        resource: null,
      },
      viewData: {
        item: {},
        modalState: false,
        resource: null,
      },
      programsPage: {
        isLoading: false,
        isError: false,
        errorMessage: false,
        data: undefined,
      },
      selectedEntityView: {},
    },
  },
  mutations: {
    setTableData(state, payload) {
      state.programManager.data = Object.assign(
        state.programManager.data,
        payload
      );
    },
    setActiveFilter(state, payload) {
      state.programManager.activeFilter = payload;
    },
    setAdvancedFilters(state, payload) {
      state.programManager.advancedFilters = payload;
    },
    setEntityFilters(state, payload) {
      state.programManager.entityFilters = payload;
    },
    setHousingCount(state, payload) {
      state.programManager.cards[1].subcategories[0].count = payload.count;
    },
    setProgramsCount(state, payload) {
      state.programManager.cards[0].subcategories[0].count = payload.count;
    },
    setProgramSessionsCount(state, payload) {
      state.programManager.cards[0].subcategories[1].count = payload.count;
    },

    setInternshipsCount(state, count) {
      state.programManager.cards[1].subcategories[1].count = count;
    },
    setEventsCount(state, count) {
      state.programManager.cards[1].subcategories[2].count = count;
    },
    setClassCount(state, payload) {
      state.programManager.cards[1].subcategories[4].count = payload.count;
    },
    setLoading(state, payload) {
      state.programManager.loading = payload;
    },
    setListingError(state, payload) {
      state.programManager.listingError = String(payload || "").trim();
    },
    setEditHousingError(state, payload) {
      state.programManager.editHousingError = String(payload || "").trim();
    },
    setConflictBookingsDetails(state, payload) {
      state.programManager.conflictBookingsDetails = payload;
    },
    setUnitConflictBookingsDetails(state, payload) {
      state.programManager.unitConflictBookingsDetails = payload;
    },
    setInvalidForm(state, payload) {
      state.programManager.invalidForm = payload;
    },
    setInvalidCreatNewHouse(state, payload) {
      state.programManager.invalidCreatNewHouse = payload;
    },
    setRedirect(state, payload) {
      state.programManager.redirect = payload;
    },
    setTableItemActionsRoutes(state, payload) {
      state.programManager.tableItemActionsRoutes = payload;
    },
    createNewHousingForEditing(state, housingDetailOverrides) {
      state.programManager.currentEditing = {
        ...baseHousingDetail,
        ...housingDetailOverrides,
        ...{
          units: [
            buildBaseHousingUnitDetail(
              null,
              _.cloneDeep(
                this.getters["programManager/getHousingAmenitiesRoomUnit"]
              )
            ),
          ],
        },
      };
    },
    setLoadingProgramsPage(state, payload) {
      state.programManager.programsPage.isLoading = payload;
    },
    setProgramPageError(state, payload) {
      state.programManager.programsPage.isError = payload;
    },
    setProgramsPageErrorMessage(state, payload) {
      state.programManager.programsPage.errorMessage = payload;
    },
    setProgramsPage(state, payload) {
      state.programManager.programsPage.data = payload;
    },
    setCurrentEditing(state, payload) {
      state.programManager.currentEditing = payload;
    },
    setCurrentEditingStatusAndUpdates(state, payload) {
      state.programManager.currentEditing = {
        ...state.programManager.currentEditing,
        ...payload,
      };
    },
    setCurrentEditingName(state, payload) {
      state.programManager.currentEditing.name = payload;
    },
    setCurrentEditingActualImages(state, payload) {
      state.programManager.currentEditing.actual_images = payload;
    },
    setCurrentEditingSmoking(state, payload) {
      state.programManager.currentEditing.smoking = payload;
    },
    setCurrentEditingRules(state, payload) {
      state.programManager.currentEditing.rules = payload;
    },
    setCurrentEditingCurfew(state, payload) {
      state.programManager.currentEditing.curfew = payload;
    },
    setCurrentEditingHousingAmenities(state, payload) {
      state.programManager.currentEditing.housing_amenities = payload;
    },
    setCurrentEditingGuestAllowed(state, payload) {
      state.programManager.currentEditing.guest_allowed = payload;
    },
    setCurrentEditingStatus(state, payload) {
      state.programManager.currentEditing.status = payload;
    },
    setCurrentEditingGuestCheckin(state, payload) {
      state.programManager.currentEditing.guest_checkin = payload;
    },
    setCurrentEditingWebsiteUrl(state, payload) {
      let { url } = payload;
      state.programManager.currentEditing.website_url = url;
    },
    setCurrentEditingFields(state, payload) {
      Object.assign(state.programManager.currentEditing, payload);
    },
    setCurrentEditingTags(state, payload) {
      state.programManager.currentEditing.tags = payload;
    },
    refreshCurrentEditing(state) {
      let current = state.programManager.currentEditing;
      if (current) {
        state.programManager.currentEditing = _.cloneDeep(current);
      }
    },
    refreshCurrentEditingUnits(state) {
      state.programManager.currentEditing.units = _.cloneDeep(
        state.programManager.currentEditing.units
      );
    },
    setCurrentEditingUnits(state, payload) {
      state.programManager.currentEditing.units = payload;
    },
    addUnit(state, payload) {
      if (payload) {
        state.programManager.currentEditing.units.push(payload);
      } else {
        let unit = buildBaseHousingUnitDetail(
          state.programManager.currentEditing?.id,
          _.cloneDeep(
            this.getters["programManager/getHousingAmenitiesRoomUnit"]
          )
        );
        state.programManager.currentEditing?.units?.push(unit);
      }
    },
    deleteUnit(state, payload) {
      const unitIndex = payload;
      state.programManager.currentEditing?.units?.splice(unitIndex, 1);
    },
    addUnitRoom(state, payload) {
      let { unitIndex, room } = payload;
      if (!room) {
        room = buildBaseHousingUnitRoomDetail(
          _.cloneDeep(
            this.getters["programManager/getHousingAmenitiesRoomUnit"]
          )
        );
      }

      const unit = state.programManager.currentEditing?.units[unitIndex];
      if (unit) {
        room.status = unit.status;
        room.room_amenities.forEach((amenity) => {
          amenity.id = null;
        });
        state.programManager.currentEditing?.units[unitIndex]?.rooms?.push(
          room
        );
      }
    },
    deleteUnitRoom(state, payload) {
      const { unitIndex, roomIndex } = payload;
      state.programManager.currentEditing.units[unitIndex]?.rooms?.splice(
        roomIndex,
        1
      );
    },
    setRoomCapacity(state, payload) {
      const { unitIndex, roomIndex, capacity } = payload;
      state.programManager.currentEditing.units[unitIndex].rooms[
        roomIndex
      ].capacity = capacity;
    },
    addRoomPrice(state, payload) {
      const { unitIndex, roomIndex } = payload;
      state.programManager.currentEditing?.units[unitIndex]?.rooms[
        roomIndex
      ]?.product_prices?.push(buildBaseHousingUnitRoomPrice());
    },
    removeRoomPrice(state, payload) {
      const { unitIndex, roomIndex, roomPriceIndex } = payload;
      state.programManager.currentEditing?.units[unitIndex]?.rooms[
        roomIndex
      ]?.product_prices?.splice(roomPriceIndex, 1);
    },
    updateRoomPriceState(state, payload) {
      const { unitIndex, roomIndex, roomPriceIndex, roomPrice } = payload;
      state.programManager.currentEditing.units[unitIndex].rooms[
        roomIndex
      ].product_prices[roomPriceIndex] = { ...roomPrice };
    },
    setCurrentUnitStatus(state, payload) {
      const { index, status } = payload;
      state.programManager.currentEditing.units[index].status = status;
      state.programManager.currentEditing.units[index].rooms.forEach((room) => {
        room.status = status;
      });
    },
    setCurrentUnitStateField(state, payload) {
      let { type, data } = payload;
      if (type == "unit") {
        let { index, fieldname, value } = data;
        state.programManager.currentEditing.units[index][fieldname] = value;
      } else if (type == "room") {
        let { unitIndex, roomIndex, fieldname, value } = data;
        state.programManager.currentEditing.units[unitIndex].rooms[roomIndex][
          fieldname
        ] = value;
      }
    },
    setCurrentUnitStateFieldArray(state, payload) {
      let { index, checked, fieldname, value } = payload;
      if (checked) {
        state.programManager.currentEditing.units[index][fieldname].push(value);
      } else {
        state.programManager.currentEditing.units[index][
          fieldname
        ] = state.programManager.currentEditing.units[index][fieldname].filter(
          (e) => e != value
        );
      }
    },
    setCurrentUnitRoomAmenities(state, payload) {
      let { unitIndex, roomIndex, value, checked } = payload;
      let unit = state.programManager.currentEditing.units[unitIndex];
      value = value ? parseInt(value) : undefined;
      for (let amenity of unit.rooms[roomIndex].room_amenities) {
        if (amenity.amenity_id === value) {
          amenity.checked = checked;
          amenity.quantity = 1;
          break;
        }
      }
    },
    setCurrentUnitRoomAmenityStateField(state, payload) {
      let { unitIndex, roomIndex, fieldname, value, amenityId } = payload;
      let unit = state.programManager.currentEditing.units[unitIndex];
      const amenityIndex = unit.rooms[roomIndex].room_amenities.findIndex(
        (el) => el?.amenity_id === amenityId
      );
      if (amenityIndex > -1)
        unit.rooms[roomIndex].room_amenities[amenityIndex][fieldname] = value;
    },
    setAmenitiesData(state, payload) {
      for (let amenity of payload) {
        amenity.amenity_id = amenity.id;
      }

      state.programManager.amenities.data = payload;
    },
    validateUnitTab(state) {
      const roomAmenitiesCategories = ["Amenities", "Safety"];

      state.programManager.unitTabValidation.checkForInvalidRoom = false;
      state.programManager.currentEditing.units.forEach((unit) => {
        unit.is_not_valid = true;
        unit.rooms.forEach((room) => {
          room.is_not_valid = true;
          room.is_not_valid_amenities = roomAmenitiesCategories.reduce(
            (o, key) => ({ ...o, [key]: false }),
            {}
          );

          if (unit.status === "Active") {
            // Check if at least one amenity has been checked and has quantity
            const noAmenitiesByCategory = roomAmenitiesCategories.reduce(
              (o, key) => ({ ...o, [key]: [] }),
              {}
            );
            room.room_amenities?.forEach((amenity) => {
              if (amenity.checked && amenity.quantity > 0) {
                noAmenitiesByCategory[amenity.category].push(false);
              } else if (
                amenity.checked &&
                (!amenity.quantity || amenity.quantity < 0)
              ) {
                noAmenitiesByCategory[amenity.category].push(true);
              }
            });
            roomAmenitiesCategories.forEach((categ) => {
              const invalid = noAmenitiesByCategory[categ].some((item) => item);
              if (invalid || !noAmenitiesByCategory[categ]?.length) {
                room.is_not_valid_amenities[categ] = true;
                state.programManager.unitTabValidation.checkForInvalidRoom = true;
              }
            });
          }
        });
      });
    },
    setTermsLoading(state, payload) {
      state.programManager.terms.isLoading = payload;
    },
    setTermsError(state, payload) {
      state.programManager.terms.isError = payload;
    },
    setTermsErrorMessage(state, payload) {
      state.programManager.terms.errorMessage = payload;
    },
    setTerms(state, payload) {
      const sortedTerms = payload.sort((a, b) => a.name.localeCompare(b.name));
      state.programManager.terms.data = sortedTerms;
    },
    setVendorsLoading(state, payload) {
      state.programManager.vendors.isLoading = payload;
    },
    setVendorsError(state, payload) {
      state.programManager.vendors.isError = payload;
    },
    setVendorErrorMessage(state, payload) {
      state.programManager.vendors.errorMessage = payload;
    },
    setVendors(state, payload) {
      state.programManager.vendors.data = payload;
    },
    setAmenitiesLoading(state, payload) {
      state.programManager.amenities.isLoading = payload;
    },
    setAmenitiesError(state, payload) {
      state.programManager.amenities.isError = payload;
    },
    setAmenitiesErrorMessage(state, payload) {
      state.programManager.amenities.errorMessage = payload;
    },
    setHomeInstitutionsLoading(state, payload) {
      state.programManager.homeInstitutions.isLoading = payload;
    },
    setHomeInstitutionsError(state, payload) {
      state.programManager.homeInstitutions.isError = payload;
    },
    setHomeInstitutionsErrorMessage(state, payload) {
      state.programManager.homeInstitutions.errorMessage = payload;
    },
    setHomeInstitutions(state, payload) {
      state.programManager.homeInstitutions.data = payload;
    },
    setCountries(state, payload) {
      state.programManager.countries = payload;
    },
    setArchivingModalState(state, payload) {
      Object.assign(state.programManager.archivingData, payload);
    },
    setArchivingModalStateClose(state) {
      state.programManager.archivingData = {
        items: {},
        modalState: false,
        resource: null,
      };
    },
    setDuplicateModalState(state, payload) {
      Object.assign(state.programManager.duplicateData, payload);
    },
    setDuplicateModalStateClose(state) {
      state.programManager.duplicateData = {
        item: {},
        modalState: false,
        resource: null,
      };
    },
    setViewModalState(state, payload) {
      Object.assign(state.programManager.viewData, payload);
    },
    setViewModalStateClose(state) {
      state.programManager.viewData = {
        item: {},
        modalState: false,
        resource: null,
      };
    },
    setSelectedEntityView(state, payload) {
      state.programManager.selectedEntityView = payload;
    },
  },
  actions: {
    async fetchEventsData(context, payload = {}) {
      context.commit("setLoading", true);
      context.commit("setListingError");
      const selectedEntityView =
        context.state.programManager.selectedEntityView;
      payload = processPayload(payload, selectedEntityView, "entity_id");
      runAndResetCancelToken(cancelToken);
      const source = axios.CancelToken.source();
      cancelToken = source;
      try {
        const { limit, page, q, extraParams } = payload;
        await eventsService
          .list(limit, page, q, extraParams, source)
          .then(({ status, data }) => {
            context.commit("setLoading", false);
            context.commit("setActiveFilter", {
              title: "Excursions + Events",
              permissions: GROUP_PERMISSIONS.REGULAR,
              count: data.data?.count || 0,
            });

            if (status !== 200) {
              context.commit("setLoading", false);
              return;
            }

            let headers = [
              "Name",
              "Category",
              "Location",
              "Event Host",
              "Description",
              "Status",
              "Sub-category",
              "Duration",
              "Mode of Experience",
              "Address",
              "Radius of allowed participants",
              "Online Location",
              "Location Type",
              "Getting to the event",
              "Seasonal Availability",
              "Relevant Academic Themes",
              "Price per Student",
              "Age Requirement",
              "Tags",
              "What is included",
              "Activity level",
              "Itinerary",
              "What should participants pack",
              "What housing is available",
              "Housing Description",
              "Hours of operation",
              "This event is primarily",
              "Mobility",
              "Communication",
              "Sensory",
              "This event is attended by",
              "Booking instructions",
              "API Event Insights",
              "Vendor Name",
              "Vendor Email",
              "Vendor Phone",
              "Vendor Whatsapp",
              "Vendor URL",
              "Vendor Services",
            ]; // Make sure to update events/constants.js->HEADERS_MAP

            let defaultHeaders = [
              "Name",
              "Category",
              "Location",
              "Event Host",
              "Description",
            ];

            let items = data.data?.items?.map((item) => ({
              id: item?.id ?? "",
              Name: item?.title ?? "",
              Category: item?.event_category.name ?? "",
              Location: item?.event_type,
              "Event Host": item?.host,
              Status: item?.status,
              "Sub-category": "",
              Duration: `${item?.duration} ${item?.duration_units}` ?? "",
              "Mode of Experience": item?.type ?? "",
              Address: item?.transportation ?? "",
              "Radius of allowed participants": item?.radius ?? "",
              "Online Location": item?.online_location ?? "",
              "Location Type": item?.location_type ?? "",
              "Getting to the event": item?.transportation ?? "",
              Description: item?.description ?? {},
              "Seasonal Availability": item?.terms_available ?? "",
              "Relevant Academic Themes": item?.event_academic_theme ?? "",
              "Price per Student": (item?.price_student / 100).toFixed(2) ?? "",
              "Age Requirement": item?.age_requirement ?? "",
              Tags: item?.tags ?? "",
              "What is included": item?.includes ?? "",
              "Activity level": item?.activity_level ?? "",
              Itinerary: item?.itinerary ?? {},
              "What should participants pack": item?.packing_list ?? [],
              "What housing is available": item?.accommodation ?? "",
              "Housing description": item?.accommodation_description ?? {},
              "Hours of operation": "",
              "This event is primarily": item?.isOutdoors ?? "",
              Mobility: "",
              Communication: "",
              Sensory: "",
              "This event is attended by": item?.primarily_attended_by ?? "",
              "Booking instructions": "",
              "API Event Insights": "",
              "Vendor Name": "",
              "Vendor Email": "",
              "Vendor Phone": "",
              "Vendor Whatsapp": "",
              "Vendor URL": "",
              "Vendor Services": "",
            }));
            context.commit("events/setEvents", { items });

            let actionsRoutes = { edit: "edit-event" };
            actionsRoutes.create = "create-event";
            context.commit("setTableData", {
              currentEntity: "events",
              headers,
              items,
              defaultHeaders,
            });
            context.commit("setTableItemActionsRoutes", actionsRoutes);
            context.commit("setLoading", false);
          });
      } catch (err) {
        if (err.wasCancelled) return;
        context.commit(
          "Oops! We couldn't collect the data you're looking for."
        );
      } finally {
        context.commit("setLoading", false);
      }
    },
    async fetchHousingData(context, payload = {}) {
      runAndResetCancelToken(cancelToken);
      const source = axios.CancelToken.source();
      cancelToken = source;
      context.commit("setLoading", true);
      context.commit("setListingError");
      try {
        const { limit, page, q, extraParams } = payload;
        const { data: response } = await housingService.getHousings({
          limit,
          page,
          q,
          extraParams,
          source: cancelToken,
        });

        context.commit("setActiveFilter", {
          title: "Housing",
          permissions: GROUP_PERMISSIONS.REGULAR,
          count: response?.count || 0,
        });

        // TODO: Commented headers with complex structs (future ticket will address them)
        const headers = [
          "Housing Name",
          "Housing Type",
          "City",
          "Description",
          "Number Of Units",
          "Country",
          "Capacity",
          "Status",
          "Last Modified",
          "Last Modified By",
          // "Allowed Participants",
          // "Allowed Program Types",
          // "Terms Available",
          // "Price Range",
          "Quantity",
          "Number of Units",
        ];

        let items = [];
        if (response?.data) {
          items = response?.data.map(
            ({
              id,
              name,
              type,
              city_id_rel,
              description,
              units = [{ rooms: [] }],
              status,
              allowed_participants,
              allowed_program_types,
              terms_available,
              updated_at,
              updated_by,
            }) => ({
              id,
              "Housing Name": name,
              "Housing Type": type,
              City: city_id_rel?.city ?? "",
              Description: description,
              "Number Of Units": units?.length ?? 0,
              Country: city_id_rel?.country?.name ?? "",
              Quantity: units.reduce((acc, curr) => acc + curr.quantity, 0),
              Status: status,
              "Last Modified":
                updated_at &&
                format(new Date(updated_at), "MM/dd/yyyy hh:mm:ss"),
              "Last Modified By": updated_by,
              "Allowed Participants": allowed_participants,
              "Allowed Program Types": allowed_program_types,
              "Terms Available": terms_available,
              Capacity: units.reduce(
                (totalUnits, unit) =>
                  totalUnits +
                  unit.quantity *
                    unit.rooms.reduce(
                      (totalRooms, room) => totalRooms + room.capacity,
                      0
                    ),
                0
              ),
            })
          );
        }

        context.commit("setTableData", {
          currentEntity: "housing",
          headers,
          items,
          defaultHeaders: [],
        });
        context.commit("setTableItemActionsRoutes", {
          edit: "housing-edit",
        });
        context.commit("setLoading", false);
      } catch (err) {
        if (err.wasCancelled) return;
        context.commit(
          "setListingError",
          "Oops! We couldn't collect the data you're looking for."
        );
      } finally {
        context.commit("setLoading", false);
      }
    },
    fetchClassesData(context, payload = {}) {
      runAndResetCancelToken(cancelToken);
      const source = axios.CancelToken.source();
      cancelToken = source;
      context.commit("setLoading", true);
      const { limit, page, q } = payload;
      classesService
        .getClasses({ limit, page, q, status: "Active", source: cancelToken })
        .then(({ data: response }) => {
          let { data, count } = response;
          if (data) data = skipArchived(data);
          context.commit("setActiveFilter", {
            title: "Classes",
            permissions: GROUP_PERMISSIONS.REGULAR,
            count,
          });

          let headers = [
            "Class Title",
            "Host Institution",
            "Location",
            "Recommended US Credits",
            "Terms Offered",
            "Host University Name",
            "Host University Credits",
            "Class Format",
            "Class Description",
            "Class Level",
            "Language of Instruction",
            "Language Level Required",
            "Prerequisite Courses Required",
            "Syllabus",
            "Areas of Study",
            "Class Contact Hours",
            "Classroom Size",
            "Program(s) Associated with Course",
          ];

          let items = data.map(
            ({
              id,
              title,
              host_institution,
              recommended_us_credit_amount,
              terms,
              language_of_instruction,
              language_of_level_requirement,
              level,
              classroom_size,
              prerequisites,
              programs,
              description,
              format,
              host_institution_credit_amount,
              contact_hours,
              host_institution_url_syllabus,
              cip_codes,
            }) => ({
              id,
              "Class Title": title,
              "Host Institution": host_institution?.name,
              Location: host_institution?.shipping_address_city,
              "Recommended US Credits": recommended_us_credit_amount,
              "Terms Offered": terms.map((term) => term?.name).join(", "),
              "Host University Name": host_institution?.name,
              "Host University Credits": host_institution_credit_amount,
              "Class Format": format,
              "Class Description": description,
              "Class Level": level.map((level) => level),
              "Language of Instruction": language_of_instruction?.value,
              "Language Level Required": language_of_level_requirement,
              "Prerequisite Courses Required": prerequisites,
              Syllabus: host_institution_url_syllabus,
              "Areas of Study": cip_codes
                .map((code) => code?.cip_title)
                .join(", "),
              "Class Contact Hours": contact_hours,
              "Classroom Size": classroom_size,
              "Program(s) Associated with Course": programs.map(
                (program) => program?.name
              ),
            })
          );
          context.commit("setTableData", {
            currentEntity: "classes",
            headers,
            items,
            defaultHeaders: [],
          });
          context.commit("setLoading", false);
        });
    },
    async fetchProgramsData({ commit, state }, payload = {}) {
      runAndResetCancelToken(cancelToken);
      const source = axios.CancelToken.source();
      cancelToken = source;
      commit("setLoading", true);
      commit("setListingError");
      const selectedEntityView = state.programManager.selectedEntityView;
      payload = processPayload(payload, selectedEntityView, "owner_entity_id");

      const { limit, page, q, extraParams } = payload;

      programsServiceOld
        .getPrograms(limit, page, q, source, extraParams)
        .then(({ status, data }) => {
          commit("setLoading", false);
          commit("setActiveFilter", {
            title: "Programs",
            permissions: GROUP_PERMISSIONS.PROGRAMS,
            count: data.data?.count || 0,
          });

          if (status !== 200) {
            commit("setLoading", false);
            return;
          }

          let headers = [
            "Program Name",
            "Status",
            "Description",
            "Created Date",
            "Last Modified By",
            "Last Modified",
          ];
          let items = data.data?.items?.map(
            ({
              id,
              name,
              status,
              updated_at,
              updated_by,
              url,
              created_at,
              owner_entity_id,
              marketplace,
            }) => ({
              id,
              "Program Name": name,
              Status: status?.name ?? "",
              "Last Modified":
                updated_at &&
                format(new Date(updated_at), "MM/dd/yyyy hh:mm:ss"),
              "Last Modified By": updated_by,
              Description: url,
              "Created Date": format(
                new Date(created_at),
                "MM/dd/yyyy hh:mm:ss"
              ),
              owner_entity_id: owner_entity_id,
              marketplace: marketplace,
            })
          );

          commit("setTableData", {
            currentEntity: "programs",
            headers,
            items,
            defaultHeaders: [],
          });
          commit("setTableItemActionsRoutes", {
            edit: "programs-edit",
            create: "programs-create",
          });
          commit("setLoading", false);
        });
    },
    async createProgramsPage({ commit }, payload = {}) {
      commit("setLoadingProgramsPage", true);
      try {
        const {
          data: {
            data: { items },
          },
        } = await programsServiceOld.createProgramPage(payload);
        commit("setProgramsPage", items);
        return items;
      } catch (error) {
        commit("setProgramsPageErrorMessage", true);
        commit("setProgramsPageError", error.message);
      } finally {
        commit("setLoadingProgramsPage", false);
      }
      return undefined;
    },

    fetchProgramSessionsData(context, payload = {}) {
      runAndResetCancelToken(cancelToken);
      const source = axios.CancelToken.source();
      cancelToken = source;
      context.commit("setLoading", true);
      const selectedEntityView =
        context.state.programManager.selectedEntityView;
      payload = processPayload(payload, selectedEntityView, "owner_entity_id");
      const { limit, page, q, extraParams } = payload;

      programSessionsService
        .getProgramSessions({
          limit,
          page,
          q,
          extraParams,
          source: cancelToken,
        })
        .then(({ status, data }) => {
          context.commit("setLoading", false);
          context.commit("setActiveFilter", {
            title: "Program Sessions",
            permissions: GROUP_PERMISSIONS.PROGRAMS,
            count: data.data?.count || 0,
          });

          if (status !== 200) {
            context.commit("setLoading", false);
            return;
          }

          let headers = [
            "Program Session Name",
            "Status",
            "Term(s)",
            "Created Date",
            "Last Modified By",
            "Last Modified",
          ];

          let items = data.data?.items?.map(
            ({
              id,
              name,
              updated_at,
              updated_by,
              created_at,
              terms,
              status,
              owner_entity_id,
            }) => ({
              id,
              "Program Session Name": name,
              Status: status?.name || "",
              "Term(s)": terms.map(({ name }) => name).join(", "),
              "Last Modified":
                updated_at &&
                format(new Date(updated_at), "MM/dd/yyyy hh:mm:ss"),
              "Last Modified By": updated_by,
              "Created Date": format(
                new Date(created_at),
                "MM/dd/yyyy hh:mm:ss"
              ),
              owner_entity_id: owner_entity_id,
            })
          );

          context.commit("setTableData", {
            currentEntity: "sessions",
            headers,
            items,
            defaultHeaders: [],
          });
          context.commit("setLoading", false);
        });
    },
    fetchInternshipsData({ commit, rootState }, payload = {}) {
      const permissions = rootState?.currentUser?.permissions || [];
      let hasFullViewPermission = permissions.includes(PERMISSIONS.ITEMS_READ);
      let hasRedactedViewPermission = permissions.includes(
        PERMISSIONS.ITEMS_READ_LITE
      );
      const extraParams = payload?.extraParams ?? {};
      let hasWritePermission = permissions.includes("items.write");
      runAndResetCancelToken(cancelToken);
      const source = axios.CancelToken.source();
      cancelToken = source;

      const parameters = {
        ...payload,
        ...extraParams,
        source: cancelToken,
        get_archived: false,
      };
      if (hasRedactedViewPermission && !hasFullViewPermission)
        parameters.status = "Active";

      commit("setLoading", true);
      commit("setListingError");
      internshipService.list(parameters).then(({ data }) => {
        const { count, items: rawItems } = data;
        const responseItems = skipArchived(rawItems);
        commit("setActiveFilter", {
          title: "Internships",
          permissions: GROUP_PERMISSIONS.REGULAR,
          count: count ?? 0,
        });

        let headers = [
          "Number of Employees",
          "Industry",
          "Placement Type",
          "Work Location",
          "City",
          "Country",
          "Position/Project Title",
          "Academic Level",
          "Sessions",
          "Duration",
          "Career Interest",
          "Major Interest",
          "Language Spoken Other than English",
          "Languages Required to Speak",
          "Language Level Required",
          "Skills Needed",
          "Skills Gained",
          "Compensation",
          "Number of Credits ",
          "Schedule",
          "Position Perks",
          "Interview Required",
        ];

        let defaultHeaders = ["Status", "Career Interest", "City", "Sessions"];

        if (hasFullViewPermission) {
          headers.unshift("Company Name");
          defaultHeaders.unshift("Company Name");
        }

        let items = responseItems.map((item) => ({
          id: item?.id ?? "",
          "Company Name": item?.company?.name ?? "",
          "Number of Employees": "",
          Industry: joinArrayItems(item?.industries, "career_pathway"),
          Status: item?.status ?? "",
          "Work Location": joinArrayItems(item?.work_locations, "name"),
          City: item?.city_data?.city ?? "",
          Country: item?.city_data?.country?.name ?? "",
          "Position/Project Title": item?.title ?? "",
          "Academic Level": joinArrayItems(
            item?.academic_levels,
            "academic_year"
          ),
          Sessions: joinArrayItems(item?.terms, "name"),
          Duration: item?.duration
            ? `${item?.duration || ""} ${item?.duration_unit || "Weeks"}`
            : "",
          "Career Interest": joinArrayItems(
            item?.career_interests,
            "soc_title"
          ),
          "Major Interest": joinArrayItems(item?.majors, "cip_title"),
          "Language Spoken Other than English": item?.required_languages.some(
            (language) => language?.value !== "English"
          )
            ? "Yes"
            : "No",
          "Languages Required to Speak": joinArrayItems(
            item?.required_languages,
            "value"
          ),
          "Language Level Required": joinArrayItems(
            item?.required_languages,
            "value",
            "proficiency_level"
          ),
          "Skills Needed": joinArrayItems(item?.needed_skills, "value"),
          "Skills Gained": joinArrayItems(item?.gained_skills, "value"),
          Compensation: item?.compensation ?? "",
          "Number of Credits ": item?.credits ?? "",
          Schedule: item?.schedule ?? "",
          "Position Perks": joinArrayItems(item?.perks, "value"),
          "Interview Required": item?.interview_required ? "Yes" : "No",
        }));

        let actionsRoutes = { edit: "internship-item" };
        if (hasWritePermission) actionsRoutes.create = "create-internship";
        commit("setTableData", {
          currentEntity: "internships",
          headers,
          items,
          defaultHeaders,
        });
        commit("setTableItemActionsRoutes", actionsRoutes);
        commit("setLoading", false);
      });
    },
    async fetchHousingCount({ commit }) {
      try {
        const { data: response } = await housingService.getHousings({
          limit: 0,
        });
        commit("setHousingCount", { count: response?.count ?? 0 });
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    async fetchClassCount({ commit }) {
      try {
        const { data: response } = await classesService.getClasses({
          limit: 0,
          page: 1,
          q: "",
          status: "Active",
        });
        commit("setClassCount", { count: response?.count ?? 0 });
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },

    async fetchProgramsCount({ commit, state }) {
      try {
        const programsExtraParams = {
          ...(state?.programManager?.entityFilters?.programs || {}),
          owner_entity_id: state?.programManager?.selectedEntityView.id,
        };

        const { data: response } = await programsServiceOld.getPrograms(
          0,
          1,
          "",
          programsExtraParams
        );
        commit("setProgramsCount", { count: response?.data?.count ?? 0 });
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    fetchProgramSessionsCount({ commit, state }) {
      const sessionExtraParams = {
        ...(state?.programManager?.entityFilters?.sessions || {}),
        owner_entity_id: state.programManager?.selectedEntityView.id,
      };

      programSessionsService
        .getProgramSessions({ extraParams: sessionExtraParams })
        .then(({ data }) => {
          commit("setProgramSessionsCount", { count: data?.data?.count ?? 0 });
        });
    },
    fetchInternshipsCount({ commit, rootState }) {
      const permissions = rootState?.currentUser?.permissions || [];
      let hasFullViewPermission = permissions.includes(PERMISSIONS.ITEMS_READ);
      let hasRedactedViewPermission = permissions.includes(
        PERMISSIONS.ITEMS_READ_LITE
      );

      const parameters = {
        limit: 0,
        get_archived: false,
      };
      if (hasRedactedViewPermission && !hasFullViewPermission)
        parameters.status = "Active";

      internshipService.list(parameters).then(({ data }) => {
        commit("setInternshipsCount", data.count);
      });
    },
    fetchEventsCount({ commit, state }) {
      const selectedEntityView = state.programManager?.selectedEntityView;
      let extraParams = {};
      if (selectedEntityView) {
        extraParams = { entity_id: selectedEntityView.id };
      }
      eventsService.list(0, 1, "", extraParams).then(({ data }) => {
        commit("setEventsCount", data?.data?.count ?? 0);
      });
    },
    fetchSingleHousingObject(context, housing_id) {
      context.commit("setEditHousingError");
      context.commit("setRedirect", false);
      context.commit("setLoading", true);
      if (housing_id) {
        housingService
          .getHousingById(housing_id)
          .then(({ data: response }) => {
            //TODO: Once we move to DB JSON Blob Remove this check
            if (!isStringAValidJSON(response?.data.curfew)) {
              response.data.curfew = undefined;
            }
            response.data.units = unitTransformation.transformHousingUnits(
              response?.data?.units || [],
              this.getters["programManager/getHousingAmenitiesRoomUnit"]
            );
            context.commit("setCurrentEditing", response?.data || {});
            context.commit(
              "setCurrentEditingUnits",
              response?.data.units || []
            );
            context.commit("refreshCurrentEditing");
            context.commit("setLoading", false);
          })
          .catch((err) => {
            context.commit("setEditHousingError", err.message);
            context.commit("setRedirect", true);
            context.commit("setLoading", false);
          });
      }
    },
    fetchSingleProgramObject(context, program_id) {
      context.commit("setLoading", true);
      programsServiceOld
        .getProgramsById(program_id)
        .then(({ data: response }) => {
          context.commit("setCurrentEditing", response?.data || {});
          context.commit("setLoading", false);
        })
        .catch(() => {
          context.commit("setLoading", false);
        });
    },
    fetchAndSetUserEntityFilters({ commit, state }) {
      const ownerEntityProfile = getters.getEntityIds(this.state);
      const ownerEntityId = ownerEntityProfile.length
        ? ownerEntityProfile?.map((id) => id)[0]
        : undefined;
      const selectedEntityView = state?.programManager?.selectedEntityView;
      const entityIdView = selectedEntityView.id ?? ownerEntityId;

      const entityFilters = {
        sessions: {
          all_available_sessions: !selectedEntityView ? true : false,
          owner_entity_id: entityIdView,
        },
        programs: {
          all_available_program_pages: !selectedEntityView ? true : false,
          owner_entity_id: entityIdView,
        },
      };

      commit("setEntityFilters", entityFilters);
    },
    async archiveProgramsObject(context, program_id) {
      try {
        await programsServiceOld.archiveSingleProgram(program_id);
        return { success: true };
      } catch (err) {
        return { success: false };
      }
    },
    async archiveHousingObject(context, housing_id) {
      try {
        await housingService.archiveHousings(housing_id);
        return { success: true };
      } catch (err) {
        return { success: false };
      }
    },
    async archiveEventsObject(context, event_id) {
      try {
        await eventsService.archive(event_id);
        return { success: true };
      } catch (err) {
        return { success: false };
      }
    },
    async archiveEventsObjects(context, event_ids) {
      return eventsService
        .archiveMany(event_ids)
        .then(() => {
          return { success: true };
        })
        .catch(() => {
          return { success: false };
        });
    },
    async archiveInternshipsObject(context, internshipId) {
      return internshipService
        .archive(internshipId)
        .then(() => {
          return { success: true };
        })
        .catch(() => {
          return { success: false };
        });
    },
    async archiveInternshipsObjects(context, internshipIds) {
      return internshipService
        .archiveMany(internshipIds)
        .then(() => {
          return { success: true };
        })
        .catch(() => {
          return { success: false };
        });
    },
    async updateEntityVisibility(context, entityId, productId) {
      return entityVisibilityService
        .updateVisibility(entityId, productId)
        .then(() => {
          return { success: true };
        })
        .catch(() => {
          return { success: false };
        });
    },
    setArchivingModalStateOpen({ commit, getters }, payload) {
      let items = [];

      if (payload.item_id) {
        const item = getters.getTableItems.find(
          (item) => item.id === payload.item_id
        );
        items.push(item);
      } else if (Object.hasOwnProperty.call(payload, "item")) {
        items.push(payload.item);
      } else {
        items = getters.getTableItems.filter((item) =>
          payload.selectedItems.includes(item.id)
        );
      }

      commit("setArchivingModalState", {
        modalState: true,
        items,
      });
    },
    setDuplicateModalStateOpen({ commit, getters }, payload) {
      let item = {};

      if (payload.item_id) {
        item = getters.getTableItems.find(
          (item) => item.id === payload.item_id
        );
      } else if (Object.hasOwnProperty.call(payload, "item")) {
        item = Object.assign({}, payload.item);
      }

      commit("setDuplicateModalState", {
        modalState: true,
        item,
      });
    },

    setViewModalStateOpen({ commit, getters }, payload) {
      let item = {};

      if (payload.item_id) {
        item = getters.getTableItems.find(
          (item) => item.id === payload.item_id
        );
      } else if (Object.hasOwnProperty.call(payload, "item")) {
        item = Object.assign({}, payload.item);
      }

      commit("setViewModalState", {
        modalState: true,
        item,
      });
    },
    setAllCounts({ dispatch, rootState }) {
      const permissions = rootState?.currentUser?.permissions || [];
      let promises = [];

      if (
        permissions.includes(PERMISSIONS.ITEMS_READ) ||
        permissions.includes(PERMISSIONS.ITEMS_READ_LITE)
      ) {
        promises.push(dispatch("fetchHousingCount"));
        promises.push(dispatch("fetchInternshipsCount"));
        promises.push(dispatch("fetchClassCount"));
        promises.push(dispatch("fetchEventsCount"));
      }

      if (
        permissions.includes(PERMISSIONS.PRGMS_READ_LITE) ||
        permissions.includes(PERMISSIONS.PRGMS_READ)
      ) {
        promises.push(dispatch("fetchProgramsCount"));
        promises.push(dispatch("fetchProgramSessionsCount"));
      }
      Promise.all(promises);
    },
    async createHousingRequest({ commit }, payload) {
      commit("setInvalidCreatNewHouse", false);
      try {
        const { data } = await housingService.createHousing(payload);
        commit("createNewHousingForEditing", data?.data);
      } catch (error) {
        commit("setInvalidCreatNewHouse", true);
      }
    },
    async saveHousingRequest({ commit }, payload) {
      try {
        commit("setEditHousingError");
        commit("setConflictBookingsDetails", undefined);
        commit("setLoading", true);
        const { housing_id, housing_data } = payload;
        const { data: response } = await housingService.saveHousing(
          housing_id,
          housing_data,
        );
        response.data.units = unitTransformation.transformHousingUnits(
          response?.data?.units || [],
          this.getters["programManager/getHousingAmenitiesRoomUnit"]
        );
        commit("setCurrentEditingStatusAndUpdates", response?.data);
        commit("refreshCurrentEditing");
        return true;
      } catch (err) {
        commit("setEditHousingError", err.message);
        if (err.response?.data?.details?.detail?.bookings?.length) {
          commit(
            "setConflictBookingsDetails",
            err.response.data.details.detail,
          );
        }
        commit("setInvalidForm", true);
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async saveHousingUnitsRequest({ commit }, payload) {
      try {
        commit("setEditHousingError");
        commit("setUnitConflictBookingsDetails", undefined);
        commit("setLoading", true);
        const { housing_id, units } = payload;
        const { data: response } = await unitService.saveHousingUnits(
          housing_id,
          {
            units,
          }
        );
        commit(
          "setCurrentEditingUnits",
          unitTransformation.transformHousingUnits(
            response?.data,
            this.getters["programManager/getHousingAmenitiesRoomUnit"]
          )
        );
        commit("refreshCurrentEditing");
        return true;
      } catch (err) {
        commit("setEditHousingError", err.message);
        if (err.response?.data?.details?.detail?.bookings?.length) {
          commit(
            "setUnitConflictBookingsDetails",
            err.response.data.details.detail
          );
        }
        commit("setInvalidForm", true);
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchAmenitiesData({ commit, getters }, payload = {}) {
      if (getters.getAllAmenities.length) return;
      commit("setAmenitiesLoading", true);
      commit("setAmenitiesError", false);
      try {
        const { limit, page, q } = payload;
        const { data: response } = await amenitiesService.getAmenities(
          limit,
          page,
          q
        );
        commit("setAmenitiesData", response?.data || []);
      } catch (e) {
        commit("setAmenitiesError", true);
        commit("setAmenitiesErrorMessage", e.message);
      } finally {
        commit("setAmenitiesLoading", false);
      }
    },
    async fetchTerms(context) {
      context.commit("setTermsLoading", true);
      context.commit("setTermsError", false);
      try {
        const { data: termsResponse } = await housingService.getTerms();
        context.commit("setTerms", termsResponse?.data || []);
      } catch (e) {
        context.commit("setTermsError", true);
        context.commit("setTermsErrorMessage", e.message);
      } finally {
        context.commit("setTermsLoading", false);
      }
    },
    async fetchVendors(context) {
      context.commit("setVendorsLoading", true);
      context.commit("setVendorsError", false);
      try {
        const { data: vendorResponse } = await housingService.getVendors();
        context.commit("setVendors", vendorResponse?.data || []);
      } catch (e) {
        context.commit("setVendorsError", true);
        context.commit("setVendorErrorMessage", e.message);
      } finally {
        context.commit("setVendorsLoading", false);
      }
    },
    async fetchCountries(context) {
      const { data: countriesResponse } = await countriesService.getCountries();
      context.commit("setCountries", countriesResponse?.data || []);
    },
    async fetchHomeInstitutions(context, payload = {}) {
      context.commit("setHomeInstitutionsLoading", true);
      context.commit("setHomeInstitutionsError", false);
      try {
        const {
          data: homeInstitutionsResponse,
        } = await housingService.getHomeInstitutions(payload);
        context.commit(
          "setHomeInstitutions",
          homeInstitutionsResponse?.data || []
        );
      } catch (e) {
        context.commit("setHomeInstitutionsError", true);
        context.commit("setHomeInstitutionsErrorMessage", e.message);
      } finally {
        context.commit("setHomeInstitutionsLoading", false);
      }
    },
    clearProgramManagerData({ commit }) {
      commit("setTableData", {
        currentEntity: "",
        headers: [],
        items: {},
        defaultHeaders: [],
      });
      commit("setCurrentEditing", {});
      commit("setTableItemActionsRoutes", {});
    },
    resetControlVariables({ commit }) {
      commit("setLoading", false);
      commit("setInvalidForm", false);
      commit("setRedirect", false);
      commit("setListingError");
    },
    setLoading({ commit }, payload) {
      commit("setLoading", payload);
    },
    setInvalidForm({ commit }, payload) {
      commit("setInvalidForm", payload);
    },
    setInvalidCreatNewHouse({ commit }, payload) {
      commit("setInvalidCreatNewHouse", payload);
    },
    setRedirect({ commit }, payload) {
      commit("setRedirect", payload);
    },
    setListingError({ commit }, payload) {
      commit("setListingError", payload);
    },
    setCurrentEditingUnits({ commit }, data) {
      commit("setCurrentEditingUnits", data);
    },
    addUnit({ commit }, data) {
      commit("addUnit", data);
    },
    deleteUnit({ commit }, data) {
      commit("deleteUnit", data);
    },
    addUnitRoom({ commit }, data) {
      commit("addUnitRoom", data);
    },
    deleteUnitRoom({ commit }, data) {
      commit("deleteUnitRoom", data);
    },
    setCurrentUnitStatus({ commit }, data) {
      commit("setCurrentUnitStatus", data);
    },
    setCurrentUnitStateField({ commit }, data) {
      commit("setCurrentUnitStateField", data);
      commit("refreshCurrentEditingUnits");
    },
    setCurrentUnitStateFieldArray({ commit }, data) {
      commit("setCurrentUnitStateFieldArray", data);
      commit("refreshCurrentEditingUnits");
    },
    setRoomCapacity({ commit }, data) {
      commit("setRoomCapacity", data);
    },
    addRoomPrice({ commit }, data) {
      commit("addRoomPrice", data);
    },
    removeRoomPrice({ commit }, data) {
      commit("removeRoomPrice", data);
    },
    updateRoomPriceState({ commit }, data) {
      commit("updateRoomPriceState", data);
    },
    setCurrentUnitRoomAmenities({ commit }, data) {
      commit("setCurrentUnitRoomAmenities", data);
      commit("refreshCurrentEditingUnits");
    },
    setCurrentUnitRoomAmenityStateField({ commit }, data) {
      commit("setCurrentUnitRoomAmenityStateField", data);
      commit("refreshCurrentEditingUnits");
    },
    setAmenitiesData({ commit }, data) {
      commit("setAmenitiesData", data);
    },
    setCurrentEditing({ commit }, data) {
      commit("setCurrentEditing", data);
    },
    refreshCurrentEditing({ commit }) {
      commit("refreshCurrentEditing");
    },
    async validateUnitTab({ commit }) {
      commit("validateUnitTab");
    },
    setCurrentEditingWebsiteUrl({ commit }, data) {
      commit("setCurrentEditingWebsiteUrl", data);
    },
    setCurrentEditingSmoking({ commit }, data) {
      commit("setCurrentEditingSmoking", data == "Yes");
    },
    setCurrentEditingGuestCheckin({ commit }, data) {
      commit("setCurrentEditingGuestCheckin", data == "Yes");
    },
    setCurrentEditingRules({ commit }, data) {
      commit("setCurrentEditingRules", data);
    },
    setCurrentEditingCurfew({ commit }, data) {
      commit("setCurrentEditingCurfew", data);
    },
    setCurrentEditingGuestAllowed({ commit }, data) {
      commit("setCurrentEditingGuestAllowed", data);
    },
    setCurrentEditingTags({ commit }, data) {
      commit("setCurrentEditingTags", data);
    },
    resetCurrentEditingCurfew({ commit }) {
      let emptyCurfew = housingService.getDefaultHousingCurfew();
      commit("setCurrentEditingCurfew", emptyCurfew);
    },
  },
  getters: {
    getTableItems(state) {
      return state?.programManager?.data?.items || [];
    },
    getTableHeaders(state) {
      return state?.programManager?.data?.headers || [];
    },
    getTableSpecifHeaders: (state) => (limit) =>
      state?.programManager?.data?.headers?.slice(0, limit) || [],
    getTableDefaultHeaders(state) {
      return state?.programManager?.data?.defaultHeaders || [];
    },
    getActiveFilter(state) {
      return state?.programManager?.activeFilter || { title: "", count: 0 };
    },
    getAdvancedFilters(state) {
      return state?.programManager?.advancedFilters || {};
    },
    getCards(state) {
      return state?.programManager?.cards || [];
    },
    getCurrentHousingId(state) {
      return state?.programManager?.currentEditing?.id;
    },
    getCurrentHousingType(state) {
      return (
        state?.programManager?.currentEditing?.type ||
        HOUSING_TYPES_MAP.APARTMENT
      );
    },
    getCurrentHousingStatus(state) {
      return state.programManager.currentEditing.status;
    },
    getGenericListing(state) {
      return state.programManager.currentEditing.generic_listing;
    },
    getTableItemActionsRoutes(state) {
      return state?.programManager?.tableItemActionsRoutes;
    },
    getCountries(state) {
      return state.programManager?.countries || [];
    },
    getSessionData(state) {
      return state.programManager?.countries || [];
    },
    getCurrentEntity(state) {
      return state.programManager?.data?.currentEntity;
    },
    getCurrentEditing(state) {
      return state.programManager.currentEditing;
    },
    getCurrentEditingHousingLevelAmenities(state) {
      return state.programManager.currentEditing?.housing_amenities || [];
    },
    getCurrentEditingSmoking(state) {
      return state.programManager.currentEditing.smoking ? "Yes" : "No";
    },
    getCurrentEditingRules(state) {
      return state.programManager.currentEditing.rules;
    },
    getCurrentEditingCurfew(state) {
      return state.programManager.currentEditing.curfew;
    },
    getCurrentEditingGuestAllowed(state) {
      return state.programManager.currentEditing.guest_allowed;
    },
    getCurrentEditingGuestCheckin(state) {
      return state.programManager.currentEditing.guest_checkin ? "Yes" : "No";
    },
    getCurrentEditingUnits(state) {
      return state.programManager.currentEditing?.units || [];
    },
    getCurrentEditingTags(state) {
      return state.programManager.currentEditing?.tags || [];
    },
    getHousingSafetyAmenities(state) {
      return state.programManager?.amenities?.data.filter(
        (amenity) =>
          amenity.category === AMENITIES_CATEGORIES.SAFETY &&
          amenity.granularity_level === AMENITIES_GRANULARITIES.HOUSING_LEVEL
      );
    },
    getHousingUnitRoutineCleaningAmenities(state) {
      return state.programManager?.amenities?.data.filter(
        (amenity) =>
          amenity.category === AMENITIES_CATEGORIES.ROUTINE_CLEANING &&
          amenity.granularity_level === AMENITIES_GRANULARITIES.UNIT_LEVEL
      );
    },
    getHousingUnitRoutineLaundryServiceAmenities(state) {
      return state.programManager?.amenities?.data.filter(
        (amenity) =>
          amenity.category === AMENITIES_CATEGORIES.ROUTINE_LAUNDRY_SERVICE &&
          amenity.granularity_level === AMENITIES_GRANULARITIES.UNIT_LEVEL
      );
    },
    getAllAmenities(state) {
      return state.programManager?.amenities?.data || [];
    },
    getHousingAmenitiesUnit(state) {
      return state.programManager?.amenities.data.filter(
        (amenity) =>
          amenity.category === AMENITIES_CATEGORIES.AMENITIES &&
          amenity.granularity_level === AMENITIES_GRANULARITIES.UNIT_LEVEL
      );
    },
    getHousingUnitSafetyAmenities(state) {
      return state.programManager?.amenities?.data.filter(
        (amenity) =>
          amenity.category === AMENITIES_CATEGORIES.SAFETY &&
          amenity.granularity_level === AMENITIES_GRANULARITIES.UNIT_LEVEL
      );
    },
    getHousingUnitAmenities(_, getters) {
      let amenities = getters.getHousingAmenitiesUnit;
      amenities.splice(1, 0, {
        id: null,
        category: AMENITIES_CATEGORIES.ROUTINE_CLEANING,
        title: null,
        description: null,
        created_at: null,
      });
      amenities.splice(3, 0, {
        id: null,
        category: AMENITIES_CATEGORIES.ROUTINE_LAUNDRY_SERVICE,
        title: null,
        description: null,
        created_at: null,
      });
      return amenities;
    },
    getHousingUnitAccessibilityAmenities(state) {
      return state.programManager?.amenities?.data.filter(
        (amenity) =>
          amenity.category ===
            AMENITIES_CATEGORIES.ACCESSIBILITY_AND_INCLUSIVITY &&
          amenity.granularity_level === AMENITIES_GRANULARITIES.UNIT_LEVEL
      );
    },
    getHousingAmenitiesRoomUnit(state) {
      let amenities = _.cloneDeep(
        state.programManager?.amenities?.data.filter(
          (amenity) =>
            (amenity.category === AMENITIES_CATEGORIES.AMENITIES ||
              amenity.category === AMENITIES_CATEGORIES.SAFETY) &&
            amenity.granularity_level === AMENITIES_GRANULARITIES.ROOM_LEVEL
        )
      );
      amenities.map(function (amenity) {
        amenity.checked = false;
        amenity.id = null;
        return amenity;
      });
      return amenities;
    },
    getLoading(state) {
      return state.programManager.loading;
    },
    getEditHousingError(state) {
      return state.programManager.editHousingError;
    },
    getConflictBookingsDetails(state) {
      return state.programManager.conflictBookingsDetails;
    },
    getUnitConflictBookingsDetails(state) {
      return state.programManager.unitConflictBookingsDetails;
    },
    getListingError(state) {
      return state.programManager.listingError;
    },
    getInvalidForm(state) {
      return state.programManager.invalidForm;
    },
    getInvalidCreatNewHouse(state) {
      return state.programManager.invalidCreatNewHouse;
    },
    getRedirect(state) {
      return state.programManager.redirect;
    },
    getUnitTabValidation(state) {
      return state.programManager.unitTabValidation;
    },
    getTermsIsLoading(state) {
      return state.programManager?.terms?.isLoading;
    },
    getTermsIsError(state) {
      return state.programManager?.terms?.isError;
    },
    getTermsData(state) {
      return state.programManager?.terms?.data || [];
    },
    getVendorsIsLoading(state) {
      return state.programManager?.vendors?.isLoading;
    },
    getVendorsIsError(state) {
      return state.programManager?.vendors?.isError;
    },
    getVendorsData(state) {
      return state.programManager?.vendors?.data?.items;
    },
    getHomeInstitutionsIsLoading(state) {
      return state.programManager?.homeInstitutions?.isLoading;
    },
    getHomeInstitutionsIsError(state) {
      return state.programManager?.homeInstitutions?.isError;
    },
    getHomeInstitutionsData(state) {
      return state.programManager?.homeInstitutions?.data;
    },
    getHomeInstitutionsDataForSelect(state) {
      return state.programManager?.homeInstitutions?.data?.items || [];
    },
    getArchivingModalState(state) {
      return state.programManager?.archivingData?.modalState;
    },
    getArchivingItems(state) {
      return state.programManager?.archivingData?.items;
    },
    getDuplicateModalState(state) {
      return state.programManager?.duplicateData?.modalState;
    },
    getViewModalState(state) {
      return state.programManager?.viewData?.modalState;
    },
    getDuplicateItem(state) {
      return state.programManager?.duplicateData?.item;
    },
    getViewItem(state) {
      return state.programManager?.viewData?.item;
    },
    getLoadingProgramsPage(state) {
      return state.programManager?.programsPage?.isLoading;
    },
    getProgramsPageErrorMessage(state) {
      return state.programManager?.programsPage?.errorMessage;
    },
    getProgramsPageError(state) {
      return state.programManager?.programsPage?.isError;
    },
    getProgramsPage(state) {
      return state.programManager?.programsPage?.data;
    },
    getSelectedEntityView(state) {
      return state.programManager?.selectedEntityView;
    },
    getEntityFilters(state) {
      return state.programManager?.entityFilters;
    },
  },
};

export default programManager;
