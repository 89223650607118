<template>
  <div class="flex items-center">
    <BaseButton :on-click="openWidget" :disabled="uploadSignatureError">
      {{ placeholder }}
    </BaseButton>
    <p v-if="uploadSignatureError" class="text-error-900 text-base">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import {
  CLOUDINARY_ALLOWED_FORMATS,
  CLOUDINARY_ERRORS,
  CLOUDINARY_POSSIBLE_FOLDERS,
  CLOUDINARY_WIDGET_SOURCES as cloudinarySources,
} from "@/constants";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
import cloudinaryService from "@/services/cloudinary";

export default {
  components: { BaseButton },
  props: {
    placeholder: {
      type: String,
      default: "Upload",
    },
    folder: {
      type: String,
      default: "housing",
      validator: (value) => CLOUDINARY_POSSIBLE_FOLDERS.indexOf(value) > -1,
    },
    clientAllowedFormats: {
      type: Array,
      default: () => CLOUDINARY_ALLOWED_FORMATS,
    },
    publicIdPrefix: {
      type: String,
      default: "",
    },
  },
  emits: ["uploadedMediaData"],
  data() {
    return {
      cloudinaryWidget: undefined,
      uploadSignatureError: false,
      errorMessage: "",
      timestamp: Math.round(new Date().getTime() / 1000),
    };
  },
  async mounted() {
    if (!this.cloudinaryWidget) {
      const cloudName = process.env.MIX_UI_CLOUDINARY_CLOUD_NAME;
      const uploadPreset = process.env.MIX_UI_CLOUDINARY_UPLOAD_PRESET;
      const googleApiKey = process.env.MIX_UI_GOOGLE_SEARCH_API_KEY;
      const apiKey = process.env.MIX_UI_CLOUDINARY_API_KEY;
      const clientAllowedFormats = this.clientAllowedFormats;

      //TODO: There is probably a better solution for this but basically the the component is getting unmounted
      //right after the call to uploadSignature, the cloudinary.createUploadWidget is then called and orphaned.
      //I'm setting a special value if the component is unmounted so we know not to create the couldinary widget
      if (this.cloudinaryWidget !== "unmounted") {
        // eslint-disable-next-line no-undef
        this.cloudinaryWidget = cloudinary.createUploadWidget(
          {
            cloudName,
            clientAllowedFormats,
            uploadPreset,
            apiKey,
            googleApiKey,
            context: {
              housing_id: this.publicIdPrefix,
            },
            uploadSignature: this.uploadSignature,
            showAdvancedOptions: true,
            sources: cloudinarySources,
            folder: this.folder,
            uploadSignatureTimestamp: this.timestamp,
            public_id_prefix: `${this.folder}/${this.publicIdPrefix}`,
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.$emit("uploadedMediaData", result.info);
            }
          }
        );
      }
    }
  },
  unmounted() {
    if (this.cloudinaryWidget) {
      this.cloudinaryWidget.destroy().then(() => {
        // widget was destroyed
      });
    }
    this.cloudinaryWidget = "unmounted";
  },
  methods: {
    openWidget() {
      this.cloudinaryWidget.open();
    },
    async uploadSignature(callback, paramsToSign) {
      const userUnauthorized = CLOUDINARY_ERRORS.unauthorized;
      const defaultError = CLOUDINARY_ERRORS.default;
      try {
        const response = await cloudinaryService.getCloudinaryUploadSignary(
          paramsToSign
        );
        callback(response.data.signature);
      } catch (error) {
        this.uploadSignatureError = true;
        if (error?.response?.status === 403) {
          this.errorMessage = userUnauthorized;
        } else {
          this.errorMessage = defaultError;
        }
      }
    },
  },
};
</script>

<style></style>
