<script setup>
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import PartyPopperIcon from "@/components/svg-icons/PartyPopperIcon.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import { computed, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

const props = defineProps({
  successMessage: {
    type: String,
    required: false,
    default: "Success!",
  },
  message: {
    type: String,
    required: false,
    default: "Your account has been created.",
  },
});

const newApplication = computed(() => route.query?.new_application);

const goTo = () => {
  if (newApplication.value) router.push({ name: "applications" });
  else router.push("/results");
};
</script>

<template>
  <div
    class="px-4 pb-4 mx-auto w-full max-w-2xl text-center bg-white font-montserrat text-indigo-base sm:px-8 sm:pb-8"
  >
    <div class="flex flex-col gap-6 items-center">
      <PartyPopperIcon />
      <h1 class="font-bold text-[28px] uppercase">
        {{ props.successMessage }}
      </h1>
      <p>
        {{ props.message }}
      </p>
    </div>
    <div class="flex flex-col items-center mt-8">
      <ButtonWithSpinner
        id="returnButton"
        ref="returnButton"
        data-testid="returnButton"
        variant="secondary"
        variant-type="block"
        button-height="min-h-[58px] md:min-h-[60px] mt-4"
        :grey-disabled-class="true"
        @click="goTo"
      >
        <div class="flex justify-center items-center">
          <span class="pr-3 uppercase">
            Return
          </span>
          <ArrowRight custom-class="w-4 h-4" />
        </div>
      </ButtonWithSpinner>
      <!-- hidden for now since profile page does not exist yet AP-2342 -->
      <!-- <button class="flex mt-4" @click="$router.push('/profile')">
        <span class="pr-3 font-bold uppercase text-university-secondary">
          Go to profile
        </span>
        <ArrowRight custom-class="w-4 h-4 text-university-secondary" />
      </button> -->
    </div>
  </div>
</template>
