import { useAsyncState } from "@vueuse/core";
import profileService from "@/services/profile";

export const useGetProfile = (asyncStateOptions, defaultId) => {
  return useAsyncState(
    (id) =>
      profileService.getProfile(defaultId || id).then((profile) => {
        return profile?.data?.data || {};
      }),
    {},
    asyncStateOptions
  );
};
