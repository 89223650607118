<script setup>
import { computed } from "vue";
import Results from "./AlgoliaResults";
import Landing from "./AlgoliaLandingView.vue";
import { useRoute } from "vue-router";
const route = useRoute();

const isLandingPage = computed(() => {
  return route.path === "/";
});
</script>

<template>
  <div class="h-full bg-white">
    <div>
      <Landing v-if="isLandingPage" />
      <Results v-else />
    </div>
  </div>
</template>
