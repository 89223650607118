<template>
  <v-select
    class="md:max-w-[300px] forced-select-box-grow"
    :class="{
      'forced-error-background': displayError,
    }"
    append-to-body
    :calculate-position="withPopper"
  >
    <template
      #selected-option-container="{ option, deselect, multiple, disabled }"
    >
      <span
        class="inline-flex items-center rounded-full bg-blue-300 mb-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium text-university-primary"
      >
        {{ option.name }}
        <div
          class="ml-2 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-university-primary hover:text-blue-500 focus:bg-indigo-500 focus:text-white focus:outline-none cursor-pointer"
          @keypress.enter.prevent="deselect(option)"
          @mousedown.prevent="deselect(option)"
        >
          <span class="sr-only">Remove large option</span>
          <svg
            class="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              stroke-linecap="round"
              stroke-width="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </div>
      </span>
    </template>
    <template #option="{ name, id }">
      <div class="flex items-start my-2 focus:bg-teal-100">
        <div class="flex h-5 items-center">
          <input
            :id="id"
            aria-describedby="comments-description"
            :name="id"
            type="checkbox"
            :checked="isChecked(id)"
            class="h-4 w-4 form-checkbox border-gray-300"
          />
        </div>
        <div class="ml-3 text-sm">
          <div :for="id" class="block text-sm text-gray-700 capitalize">
            {{ name }}
          </div>
        </div>
      </div>
    </template>
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes"
        ><i class="fa fa-caret-down text-gray-500" v-bind="attributes"></i
      ></span>
    </template>
  </v-select>
</template>
<script>
import { createPopper } from "@popperjs/core";
export default {
  name: "WrappedVueSelect",
  props: {
    wrappedValues: {
      required: false,
      type:
        Array[
          {
            id: String,
            name: String,
          }
        ],
    },
    placement: {
      required: false,
      type: String,
      default: "top",
    },
    displayError: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wrappedValue: this.wrappedValues ? this.wrappedValues : [],
    };
  },
  methods: {
    isChecked(id) {
      return (
        this.wrappedValues &&
        this.wrappedValues.findIndex((val) => val === id) >= 0
      );
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;
      dropdownList.className += " wrapped_select_override";

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },
  },
};
</script>
<style scoped>
.forced-select-box-grow :deep(.vs__dropdown-toggle),
:deep(.vs__selected-options) {
  overflow: visible;
  max-height: inherit;
}
.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
</style>

<style>
.wrapped_select_override > .vs__dropdown-option--highlight {
  background-color: #edf9f8 !important;
}
.wrapped_select_override > .vs__dropdown-option--deselect {
  background-color: #c8d0ec !important;
}
</style>
