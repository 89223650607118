<template>
  <svg
    width="21"
    height="15"
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_146_2314)">
      <path
        d="M0 14.62V12.4C0 11.88 0.13 11.42 0.4 11.02C0.67 10.62 1.02 10.3 1.47 10.08C2.42 9.62 3.38 9.26 4.34 9C5.3 8.75 6.36 8.62 7.51 8.62C8.66 8.62 9.72 8.75 10.68 9C11.64 9.25 12.6 9.61 13.55 10.08C13.99 10.3 14.35 10.62 14.62 11.02C14.89 11.42 15.02 11.88 15.02 12.4V14.62H0ZM17 14.62V12.27C17 11.61 16.84 10.99 16.52 10.39C16.2 9.8 15.74 9.29 15.15 8.86C15.82 8.96 16.46 9.11 17.06 9.32C17.66 9.53 18.24 9.78 18.79 10.06C19.31 10.34 19.71 10.66 19.99 11.03C20.27 11.4 20.41 11.81 20.41 12.26V14.61H17.01L17 14.62ZM7.5 7C6.54 7 5.71 6.66 5.03 5.97C4.34 5.28 4 4.46 4 3.5C4 2.54 4.34 1.71 5.03 1.03C5.72 0.34 6.54 0 7.5 0C8.46 0 9.29 0.34 9.97 1.03C10.66 1.72 11 2.54 11 3.5C11 4.46 10.66 5.29 9.97 5.97C9.28 6.66 8.46 7 7.5 7ZM16.13 3.5C16.13 4.46 15.79 5.29 15.1 5.97C14.41 6.66 13.59 7 12.63 7C12.52 7 12.37 6.99 12.2 6.96C12.03 6.93 11.88 6.91 11.77 6.88C12.16 6.41 12.47 5.88 12.68 5.3C12.89 4.72 13 4.12 13 3.5C13 2.88 12.89 2.28 12.68 1.71C12.46 1.14 12.16 0.61 11.78 0.13C11.92 0.08 12.07 0.05 12.21 0.03C12.35 0.01 12.5 0.01 12.64 0.01C13.6 0.01 14.43 0.35 15.11 1.04C15.8 1.73 16.14 2.55 16.14 3.51L16.13 3.5ZM1.5 13.12H13.5V12.4C13.5 12.19 13.45 12.01 13.34 11.84C13.24 11.68 13.07 11.53 12.84 11.41C12.02 10.99 11.17 10.66 10.3 10.45C9.43 10.23 8.49 10.12 7.5 10.12C6.51 10.12 5.57 10.23 4.7 10.45C3.83 10.67 2.98 10.99 2.16 11.41C1.93 11.53 1.77 11.67 1.66 11.84C1.56 12 1.5 12.19 1.5 12.4V13.12ZM7.5 5.5C8.05 5.5 8.52 5.3 8.91 4.91C9.3 4.52 9.5 4.05 9.5 3.5C9.5 2.95 9.3 2.48 8.91 2.09C8.52 1.7 8.05 1.5 7.5 1.5C6.95 1.5 6.48 1.7 6.09 2.09C5.7 2.48 5.5 2.95 5.5 3.5C5.5 4.05 5.7 4.52 6.09 4.91C6.48 5.3 6.95 5.5 7.5 5.5Z"
        fill="#1E264C"
      />
    </g>
    <defs>
      <clipPath id="clip0_146_2314">
        <rect width="20.4" height="14.62" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
