<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="w-full"
    width="116"
    height="16"
  >
    <defs>
      <linearGradient
        :id="'gradient_' + uid"
        x1="0"
        y1="50%"
        x2="100%"
        y2="50%"
      >
        <stop offset="25%" stop-color="#4456a6">
          <animate
            attributeName="stop-color"
            values="#4456a6; #f5f6fa; #4456a6"
            dur="5s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="50%" stop-color="#f5f6fa">
          <animate
            attributeName="stop-color"
            values="#f5f6fa; #4456a6; #f5f6fa"
            dur="5s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="75%" stop-color="#4456a6">
          <animate
            attributeName="stop-color"
            values="#4456a6; #f5f6fa; #4456a6"
            dur="5s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
      <pattern
        :id="'pattern_' + uid"
        x="0"
        y="0"
        width="10"
        height="16"
        patternUnits="userSpaceOnUse"
      >
        <rect fill="#4354A8" width="6" height="16" rx="3" />
      </pattern>
      <mask :id="'mask_' + uid" x="0" y="0" width="1" height="1">
        <rect width="100%" height="16" :fill="'url(#pattern_' + uid + ')'" />
      </mask>
    </defs>
    <rect
      :fill="'url(#gradient_' + uid + ')'"
      width="100%"
      height="100%"
      :mask="'url(#mask_' + uid + ')'"
    />
  </svg>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from "vue";

const props = defineProps({
  maxWidth: {
    default: 100,
    type: Number,
  },
  minWidth: {
    default: 80,
    type: Number,
  },
  height: {
    default: "1em",
    type: String,
  },
  width: {
    default: null,
    type: String,
  },
});

const instance = getCurrentInstance();
const uid = ref(instance.uid); // Unique ID for gradient and pattern
</script>
