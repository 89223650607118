import { learningServiceRequest, skip } from "./utils";

const programsServiceOld = {
  async getProgramsById(programId) {
    return await learningServiceRequest({
      method: "get",
      url: `/programming/program_pages/${programId}`,
      failureMessage: "Error loading Program data",
    });
  },
  async archiveSingleProgram(programPageId) {
    return await learningServiceRequest({
      method: "put",
      url: `/programming/program_pages/archive/${programPageId}`,
    });
  },
  async getPrograms(limit = 10, page = 1, q = "", source, extraParams = {}) {
    return await learningServiceRequest({
      method: "get",
      url: "/programming/program_pages",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      source,
      failureMessage: "Error loading program pages data",
    });
  },
  async updateProgramPage(programPageId, payload) {
    return await learningServiceRequest({
      method: "put",
      url: `/programming/program_pages/${programPageId}`,
      payload: payload,
      failureMessage: "Error updating program page data",
    });
  },
  async createProgramPage(payload) {
    return await learningServiceRequest({
      method: "post",
      url: `/programming/program_pages`,
      payload: payload,
      failureMessage: "Error creating program page data",
    });
  },
};

export default programsServiceOld;
