<template>
    <div class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-32" role="dialog" aria-modal="true" id="reset-confirmation" aria-labelledby="reset-confirmation-label">
        <div class="flex flex-col items-center mb-10">
            <h2 class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl" id="reset-confirmation-label">Thank you.</h2>
            <p class="mt-4 text-center text-gray-600">Your Submission was successful!</p>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>