<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <spinner v-if="loading">
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications/landing',
              params: { applicationId: applicationId },
            }"
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            class="underline hover:no-underline"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
      <FormPanel
        v-else
        :id="'GpaStatement'"
        :title="'GPA Statement'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <div id="gpa-statement" class="mb-6 text-gray-600 space-y-6">
            <ApiTextArea
              name="gpaStatement"
              :label="getLabel"
              :max-length="2000"
              :typed-text="statement"
              :validation-errors="v$.value.textarea"
              @textUpdate="onFieldChange('textarea', $event)"
            />
          </div>
          <ButtonWithSpinner
            ref="SubmitForm"
            type="submit"
            variant="primary"
            variant-type="block"
            @click.prevent="submit()"
          >
            <span
              >Submit
              <svg
                v-if="formSubmitted && !hasErrors"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="inline feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline></svg
            ></span>
          </ButtonWithSpinner>
          <p v-if="hasErrors" class="text-error-900">
            Please complete the required fields correctly.
          </p>
          <div v-if="missingAppId" class="text-error-900">
            <p>
              No Application ID, please contact your API program manager to help
              you with your application.
            </p>
          </div>
          <div v-if="formSubmitted && !hasErrors" class="text-success-900">
            <p>
              Submission Complete, thank you!
            </p>
          </div>
        </template>
      </FormPanel>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "../../helpers/Spinner.vue";
import formInputs from "../../../mixins/formInputs";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import FormPanel from "../SharedComponents/panel.vue";
import ApiTextArea from "../SharedComponents/ApiTextArea.vue";
import formIoApi from "../../../mixins/formIoApi";
import formValidation from "../../../mixins/formValidation";
import forms from "../../../mixins/forms";
import formioHelpers from "../../../mixins/formioHelpers";
import { required, maxLength } from "@vuelidate/validators";
import letterOfRecAndGpaLogic from "../../../mixins/letterOfRecAndGpaLogic";
import useVuelidate from "@vuelidate/core";

export default {
  name: "GpaStatementForm",
  components: { ButtonWithSpinner, Spinner, FormPanel, ApiTextArea },
  mixins: [
    formIoApi,
    formInputs,
    formValidation,
    forms,
    formioHelpers,
    letterOfRecAndGpaLogic,
  ],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        textarea: "",
      },
      applicationId: "",
      formSubmitted: false,
      formPath: "studentapplicationstage2step1",
      loading: true,
      message: "Getting Student application",
      redirect: false,
      redirectTo: {},
      statement: "",
    };
  },
  computed: {
    ...mapState(["formioToken", "program", "selectedProgramId"]),
    minimumGpaWithDecimal() {
      return parseFloat(this.program.minimum_gpa).toFixed(1);
    },
    getLabel() {
      if (!this.program.program.university?.name) {
        return (
          "The GPA requirement for this program is " +
          this.minimumGpaWithDecimal +
          ". You are required to submit a statement of purpose since your current cumulative GPA is below the requirement. Please write a brief summary of why you are applying to the program. Make sure to outline your academic and cultural objectives and goals for the session. Also, please explain any extenuating circumstances surrounding your current GPA."
        );
      } else {
        return (
          "The GPA requirement for this program is " +
          this.minimumGpaWithDecimal +
          ". Since your current cumulative GPA is below " +
          this.minimumGpaWithDecimal +
          ", you are required to submit a statement of purpose to " +
          (this.program?.program?.university
            ? this.program?.program?.university?.name
            : "-") +
          ". Please write a brief summary of why you are applying to the program. Make sure to outline your academic and cultural objectives and goals for the session. Also, please explain any extenuating circumstances surrounding your current GPA."
        );
      }
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          application_id: this.applicationId,
          "gpa-statement": this.value.textarea, //this is declared in formInputs
          gpaStatementSubmitted: true,
        },
      };
      return body;
    },
    patchSubmissionDataForFormIo() {
      const body = [
        {
          op: "add",
          path: "/data/gpa-statement",
          value: this.value.textarea, //this is declared in formInputs
        },
        {
          op: "add",
          path: "/data/gpaStatementSubmitted",
          value: true,
        },
      ];
      return body;
    },
  },
  watch: {
    redirect: function (newVal) {
      if (newVal) {
        //redirect to my applications
        this.$router.push(this.redirectTo);
      }
    },
  },
  async created() {
    //set the application ID
    this.applicationId = this.setApplicationId;
    //Get student GPA from enrollment service
    if (!this.studentGpa) {
      await this.setStudentGpa();
    }
    //load the program data if not loaded
    if (Object.keys(this.program).length === 0) {
      await this.loadProgramData(this.applicationId);
    }
    this.renderForm();
  },

  methods: {
    async renderForm() {
      //Make sure I user owns the application ID
      if (!(await this.isApplicationOwner(this.applicationId))) {
        this.redirectTo = {
          name: "applications",
        };
        this.redirect = true;
        return;
      }



      //run gpa display logic from mixin
      if (!(await this.showGpaStatement(this.applicationId))) {
        this.redirectTo = {
          name: "applications/landing",
          params: {
            applicationId: this.applicationId,
          },
        };
        this.redirect = true;
        return;
      }

      //fetch already submitted data if it exist and load the form
      this.prefetchFormData();
    },
    async prefetchFormData() {
      await this.formioSubmissionExists(this.formPath, this.applicationId).then(
        (formSubmissionId) => {
          if (formSubmissionId) {
            this.getFormioSubmission(this.formPath, formSubmissionId).then(
              (response) => {
                this.statement = response["gpa-statement"];
                this.loading = false;
              }
            );
          } else {
            this.loading = false;
          }
        }
      );
    },
    async submit() {
      this.formSubmitted = false;
      //calling the startLoading method of the button, which enables the button to loading state
      this.$refs.SubmitForm.startLoading();
      try {
        await this.checkForApplicationId();
        await this.validateSingleForm();
        if (this.submission_id) {
          this.submitToFormIo(
            this.formPath,
            this.patchSubmissionDataForFormIo,
            "PATCH",
            this.submission_id
          ).then((response) => {
            if (response) {
              this.formSubmitted = true;
            }
          });
        } else {
          this.submitToFormIo(
            this.formPath,
            this.createSubmissionDataForFormIo
          ).then((response) => {
            if (response) {
              this.submission_id = response;
              this.formSubmitted = true;
            }
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$refs.SubmitForm.stopLoading();
      }
    },
  },
  validations: {
    value: {
      textarea: {
        required,
        maxLength: maxLength(2000),
      },
    },
  },
};
</script>
