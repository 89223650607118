import { learningServiceRequest } from "./utils";

const cipCodesService = {
  async list(search, field = "cip_title", order = "ASC", limit = 10) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/cip_codes",
      params: { limit: limit, q: search, field: field, order: order },
      failureMessage: "Error loading Cip Codes",
    });
    return response?.data ?? [];
  },
  async listObj({
    q,
    page,
    extraParams,
    limit,
    field = "cip_title",
    order = "ASC",
    source,
  }) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/cip_codes",
      params: { limit, q, field, order, page, ...extraParams },
      failureMessage: "Error loading Cip Codes",
      source,
    });
    return response?.data ?? [];
  },
  async getOne(cip_code = "") {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: `/cip_codes/${cip_code}/`,
      failureMessage: "Error loading Cip Code",
    });
    return response?.data ?? [];
  },
};

export default cipCodesService;
