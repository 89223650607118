<template>
  <button
    class="menu-item p-1 mr-2"
    :class="buttonClass"
    :title="title"
    :disabled="disabledButton"
    @click="action"
  >
    <component :is="icon" />
  </button>
</template>

<script>
import IconBold from "@/components/forms/SharedComponents/WYSIWYG/IconBold.vue";
import IconBulletedList from "@/components/forms/SharedComponents/WYSIWYG/IconBulletedList.vue";
import IconLink from "@/components/forms/SharedComponents/WYSIWYG/IconLink.vue";
import IconNumeredList from "@/components/forms/SharedComponents/WYSIWYG/IconNumeredList.vue";
import IconItalic from "@/components/forms/SharedComponents/WYSIWYG/IconItalic.vue";
import IconUnlink from "@/components/forms/SharedComponents/WYSIWYG/IconUnlink.vue";
import IconUnderline from "@/components/forms/SharedComponents/WYSIWYG/IconUnderline.vue";

export default {
  components: {
    IconBold,
    IconBulletedList,
    IconLink,
    IconNumeredList,
    IconItalic,
    IconUnlink,
    IconUnderline,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
    isActive: {
      type: Function,
      default: null,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    active() {
      return this.isActive ? this.isActive() : null;
    },
    buttonClass() {
      let buttonClass =
        "text-black bg-transparent border-2 border-gray-200 border-solid ";

      if (!this.disabledButton && this.active)
        buttonClass = "text-white border-none bg-black";

      if (!this.disabledButton && !this.active)
        buttonClass += "hover:text-white hover:border-none hover:bg-black";

      return buttonClass;
    },
  },
};
</script>

<style scoped>
.menu-item {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.2rem;
}
.menu-item svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
</style>
