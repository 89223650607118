export const pastSessionDeadline = (sessionObj) => {
  const newDate = sessionObj?.application_deadline + "T00:00";
  const deadline = new Date(newDate);

  return isPastDate(deadline);
};

export const pastSessionStartDate = (sessionObj) => {
  const newDate =
    (sessionObj?.session_travel_details?.[0]?.arrival_date ??
      sessionObj?.academic_start_date) + "T00:00";
  const startDate = new Date(newDate);

  return isPastDate(startDate);
};

const isPastDate = (date) => {
  const today = new Date();

  date.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  return date < today;
};
