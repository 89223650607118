import { learningServiceRequest } from "./utils";

const companiesService = {
  async list(search, limit = 10, order = "ASC", skip = 0, field = "name") {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/companies",
      params: { limit, q: search, order, skip, field },
      failureMessage: "Error loading Companies data",
    });
    return response?.data?.items ?? [];
  },
  async getCompanyById(companyId) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: `/companies/${companyId}`,
      failureMessage: "Error loading Company data",
    });
    console.log(response?.data ?? []);
    return response?.data ?? [];
  },
};

export default companiesService;
