<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 -960 960 960"
    width="48"
    fill="#1E264C"
  >
    <path
      d="M176.154-136.924q-18.981 0-32.567-13.587-13.586-13.586-13.586-32.566v-444.232q0-18.98 13.586-32.566 13.586-13.586 32.567-13.586h117.693V-790.77q0-18.98 13.437-32.567 13.437-13.586 32.716-13.586h280.385q18.98 0 32.567 13.586 13.586 13.587 13.586 32.567v281.155h117.308q18.981 0 32.567 13.586 13.586 13.586 13.586 32.566v280.386q0 18.98-13.586 32.566-13.586 13.587-32.567 13.587H539.539V-300.77H420.461v163.846H176.154Zm-.77-45.384h118.463V-300.77H175.384v118.462Zm0-163.846h118.463v-118.078H175.384v118.078Zm0-163.461h118.463v-118.463H175.384v118.463ZM339.23-346.154h118.078v-118.078H339.23v118.078Zm0-163.461h118.078v-118.463H339.23v118.463Zm0-163.846h118.078v-118.078H339.23v118.078Zm163.462 327.307h118.462v-118.078H502.692v118.078Zm0-163.461h118.462v-118.463H502.692v118.463Zm0-163.846h118.462v-118.078H502.692v118.078Zm163.846 491.153h118.078V-300.77H666.538v118.462Zm0-163.846h118.078v-118.078H666.538v118.078Z"
    />
  </svg>
</template>
