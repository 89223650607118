<template>
  <FormPanel
    :id="'supplementalPanel' + id"
    :title="title"
    class="mb-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <a
        target="_blank"
        :href="link"
        :class="[`${secondaryColorClass} font-semibold hover:underline`]"
        class="font-semibold hover:underline focus:underline"
      >
        {{ link }}
      </a>
      <div class="text-center m-6">
        <a
          :href="link"
          target="_blank"
          :class="[
            secondaryColor
              ? 'brandingSecondary'
              : 'bg-university-secondary hover:bg-teal-100 focus:bg-teal-100',
          ]"
          class="inline-flex justify-center items-center text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white hover:text-white focus:text-white"
        >
          <span>Download</span>
        </a>
      </div>
      <FileUpload
        :name="'supplemental-upload-' + id"
        :multiple-files="true"
        :file-types="['.pdf', '.docx']"
        custom-button-label="Upload Completed Form"
        custom-file-box-label="Upload the form once you complete."
        :formio-files="formioData"
        :bottom-margin="false"
        @updateProgress="updateProgress"
        @updateFiles="updateFiles"
      />
      <div
        v-if="v$.value.files.required.$invalid && v$.value.files.$error"
        class="error text-error-900 text-sm"
      >
        Field is required
      </div>
    </template>
  </FormPanel>
</template>

<script>
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import FileUpload from "@/components/forms/SharedComponents/FileUpload.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const validateFileUpload = (value) => value === false;

export default {
  name: "SupplementalUpload",
  components: {
    FormPanel,
    FileUpload,
  },
  props: {
    supplemental: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: String,
      default: "0",
    },
    title: {
      type: String,
      default: "Panel",
    },
    link: {
      type: String,
      default: "",
    },
    formioData: {
      type: Array,
      default() {
        return [];
      },
    },
    successSubmission: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["changeSuccessSubmission", "update:supplemental"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        files: [],
      },
      uploadOnProgress: false,
      uploadErrors: false,
    };
  },
  validations: {
    value: {
      files: { required },
    },
    uploadOnProgress: { validateFileUpload },
    uploadErrors: { validateFileUpload },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update:supplemental", this.value);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    updateProgress(val) {
      this.uploadOnProgress = val;
    },
    updateFiles(files, error) {
      this.value.files = files;
      this.uploadErrors = error;
    },
  },
};
</script>
