<template>
  <Listbox>
    <div class="mt-3 ml-3">{{ title }}</div>
    <div
      v-for="header in headers"
      :key="header.id"
      :value="header"
      class="text-gray-600 block px-2 py-2 my-1 text-sm hover:bg-gray-200"
    >
      <div class="flex">
        <div>
          <input
            v-model="header.selected"
            type="checkbox"
            :name="header.name"
            class="ml-2 form-checkbox text-indigo-600"
            :disabled="
              (numSelectedHeaders >= maxSelectedHeaders && !header.selected) ||
              (numSelectedHeaders <= minSelectedHeaders && header.selected)
            "
          />
        </div>
        <div class="ml-2">
          {{ header.name }}
        </div>
      </div>
    </div>
  </Listbox>
</template>


<script>
import { eventBus } from "@/app";
import { Listbox } from "@headlessui/vue";

export default {
  components: {
    Listbox,
  },
  inject: ["visibleOptions"],
  props: {
    customTitle: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
    maxSelectedHeaders: {
      type: Number,
      default: 5,
    },
    minSelectedHeaders: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      headers: [],
    };
  },
  computed: {
    numSelectedHeaders() {
      return this.headers.filter((header) => header.selected).length;
    },
    title() {
      return (
        this.customTitle ||
        "Select up to " + this.maxSelectedHeaders + " columns"
      );
    },
  },
  mounted() {
    const selectedHeaders = (newHeaders) => {
      const numSelectedHeaders = this.numSelectedHeaders;
      if (
        numSelectedHeaders >= this.minSelectedHeaders &&
        numSelectedHeaders <= this.maxSelectedHeaders
      ) {
        const selectedHeader = newHeaders
          .filter((header) => header.selected)
          .map((header) => header.name);
        eventBus.$emit("updateHeaders", selectedHeader);
      }
    };
    this.$watch("headers", selectedHeaders, { deep: true });
  },
  created() {
    this.headers = this.options.map((el) => ({
      name: el,
      id: el,
      selected: this.visibleOptions.includes(el),
    }));
  },
};
</script>
