<template>
  <div v-if="newOrValid">
    <div
      class="w-full px-4 py-6 mx-auto bg-blue-100 max-w-7xl sm:px-16 sm:py-10"
    >
      <step-tracker :forms="forms" @go-to-step="goToStep" />
    </div>
    <div class="w-full mx-auto max-w-7xl md:px-8 md:mb-8">
      <div
        class="px-4 py-6 bg-white border-t border-gray-200 dashboard sm:grid sm:grid-cols-12 sm:gap-6 md:gap-12 sm:px-8 md:p-12 md:border md:rounded"
      >
        <section class="dashboard__body sm:col-span-7 lg:col-span-8">
          <PayDepositFormComponent
            v-if="currentStep === 1 && !applicationPaid"
          />
          <formio-form
            v-else-if="inProgress && ready"
            :key="currentStep"
            :in-progress="inProgress"
            :form-url="formUrl"
            :form-notification="formNotification"
            :application-id="applicationId"
            @form-submit="formSubmit"
            @select-program="selectProgram"
            @set-application-id="setNewApplicationId"
            @set-secondary-application-id="setNewSecondaryApplicationId"
          />

          <formio-form
            v-else-if="ready"
            :key="currentStep"
            :in-progress="inProgress"
            :form-url="formUrl"
            :form-notification="formNotification"
            :application-id="newApplicationId"
            :secondary-application-id="secondaryApplicationId"
            @form-submit="formSubmit"
            @select-program="selectProgram"
            @set-application-id="setNewApplicationId"
            @set-secondary-application-id="setNewSecondaryApplicationId"
          />
        </section>
        <application-sidebar v-if="programSelected" :forms-list="forms" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import forms from "../mixins/forms.js";
import PayDepositFormComponent from "./forms/PayDeposit/FormComponent";
import studentApplication from "../mixins/studentApplication.js";

export default {
  components: { PayDepositFormComponent },
  mixins: [forms, studentApplication],
  props: {
    slug: {
      type: String,
      default: "",
    },
    applicationId: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["previousApplication"]),
    activeForm() {
      return this.forms[this.currentStep];
    },
    getNextPendingForm() {
      let submission = this.getFarthestSubmission();
      let form = this.getFarthestAlongIncompleteForm();
      if (form) {
        if (submission.state === "draft") {
          // There is an incomplete submission; load it
          let obj = {
            formId: form.id,
            formioUrl: form.url + "/submission/" + submission._id,
            formSlug: form.slug,
          };
          return obj;
        } else {
          // No incomplete submission; proceed to next blank one
          let obj = {
            formId: form.id,
            formioUrl: form.url,
            formSlug: form.slug,
          };
          return obj;
        }
      } else {
        return null;
      }
    },
    formUrl() {
      //get the forms submission if any
      let activeFormSubmissions = this.getFormSubmissions(
        this.activeForm,
        this.getApplicationId
      );

      //load the active form
      if (this.inProgress && !this.resumed) {
        if (this.activeForm) {
          switch (this.applicationPaid) {
            case true:
              if (
                (this.currentStep === 0 || this.currentStep === 1) &&
                this.getNextPendingForm?.formSlug
              ) {
                if (this.slug !== this.getNextPendingForm.formSlug) {
                  this.updateUrl(this.getNextPendingForm.formSlug);
                }
                this.updateCurrentStep(this.getNextPendingForm.formSlug);
                //first & second steps not allowed if application has been paid
                return this.getNextPendingForm.formioUrl;
              } else {
                //prevent skipping steps
                let pendingForm = this.getformWithNoSubmission();

                if (pendingForm && pendingForm.step < this.currentStep) {
                  if (this.slug !== pendingForm.slug) {
                    this.updateUrl(pendingForm.slug);
                  }
                  this.updateCurrentStep(pendingForm.slug);
                  return pendingForm.url;
                }
              }
              break;
            case false:
              if (this.currentStep > 1 && this.getNextPendingForm) {
                //prevent moving foward if no payment
                if (this.slug !== this.getNextPendingForm.formSlug) {
                  this.updateUrl(this.getNextPendingForm.formSlug);
                }
                return this.getNextPendingForm.formioUrl;
              }
              break;
          }

          //continue loading the requested form
          if (activeFormSubmissions) {
            // There is an incomplete submission; load it
            return (
              this.activeForm.url +
              "/submission/" +
              activeFormSubmissions[0]._id
            );
          } else {
            // No incomplete submission; proceed to next blank one
            return this.activeForm.url;
          }
        } else {
          let submission = this.getFarthestSubmission();
          let form = this.getFarthestAlongIncompleteForm();
          if (form) {
            if (submission.state === "draft") {
              // There is an incomplete submission; load it
              return form.url + "/submission/" + submission._id;
            } else {
              // No incomplete submission; proceed to next blank one
              return form.url;
            }
          } else {
            //load program selection form if no program selected
            if (this.slug !== "program-selection" && !this.programSelected) {
              form = this.forms[0];
            }
            // This just dumps them into the last form in the process, even though they've already submitted it.
            // Not sure we want to do that. Probably not...
            return (
              this.forms[submission.key]["url"] +
              "/submission/" +
              submission._id
            );
          }
        }
      } else {
        let form = this.forms.find((form) => {
          return form.slug === this.slug;
        });
        if (
          form &&
          Object.prototype.hasOwnProperty.call(form, "url") &&
          activeFormSubmissions.length
        ) {
          return form.url + "/submission/" + activeFormSubmissions[0]._id;
        } else if (form && Object.prototype.hasOwnProperty.call(form, "url")) {
          //load program selection form if no program selected
          if (this.slug !== "program-selection" && !this.programSelected) {
            form = this.forms[0];
          }
          return form.url;
        } else {
          return null;
        }
      }
    },
    numberOfSteps() {
      return this.forms.length;
    },
    formNotification() {
      return this.forms[this.currentStep]["notification"];
    },
  },
  watch: {
    currentStep: function (newVal) {
      if (newVal === 0) {
        this.newApplicationId = "";
      }
    },
  },
  created() {
    // somelinks dont have href or are other type of elements that simulate a link
    //added this event listener to make them clickable via keyboard enter key for screen readers
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && e.target.classList.contains("clickable")) {
        e.target.click();
      }
    });
  },
  methods: {
    updateCurrentStep(slug) {
      this.currentStep = this.forms.findIndex((form) => {
        return form.slug === slug;
      });
    },
    getformWithNoSubmission() {
      //get applications submissions
      let submissions = this.studentFormioSubmissions.filter((submission) => {
        return submission.data.application_id === this.applicationId;
      });

      //retn a new array with pending submission forms
      let diff = [];

      this.forms.forEach((form, index) => {
        let inArray = false;
        submissions.forEach((submission) => {
          if (submission.form === form.id) {
            inArray = true;
          }
        });
        if (!inArray) {
          form.step = index;
          diff.push(form);
        }
      });
      return diff.shift();
    },
    updateUrl(slug) {
      this.$router.push({
        path: "/applications/" + this.applicationId + "/" + slug,
      });
    },
    formSubmit() {
      if (this.inProgress) {
        this.resumed = true;
      }
      this.nextStep();
    },
    nextStep() {
      this.$store.dispatch("getFormioSubmissions");
      if (this.currentStep < this.forms.length - 1) {
        this.currentStep++;
        if (this.applicationId) {
          this.$router.push({
            path:
              "/applications/" +
              this.applicationId +
              "/" +
              this.forms[this.currentStep]["slug"],
          });
        } else if (this.newApplicationId) {
          this.$router.push({
            path:
              "/applications/" +
              this.newApplicationId +
              "/" +
              this.forms[this.currentStep]["slug"],
          });
        } else {
          this.$router.push({
            path: "/applications/new/" + this.forms[this.currentStep]["slug"],
          });
        }
      }
    },
  },
};
</script>
