<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
        data-cy="resumeGoBackButton"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <spinner v-if="loading">
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications/landing',
              params: { applicationId: applicationId },
            }"
            class="underline hover:no-underline"
            data-cy="resumeGoBackButton"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
      <FormPanel
        :id="'ResumeForm'"
        :title="'Resume'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <div id="resume" class="mb-6 text-gray-600 space-y-6">
            The program you are applying for requires you to submit a
            professional résumé. If you already have a résumé, you can upload it
            below. Check your resume against
            <a
              href="https://api-abroad.sfo2.cdn.digitaloceanspaces.com/documents/2020-Resume-Guidelines.pdf"
              target="_blank"
              :class="[
                secondaryColor
                  ? `${secondaryColorClass}`
                  : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
              ]"
              >our guidelines here.</a
            >
            <span class="has-tippy" data-cy="tooltip" data-tippy-template="help"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-help-circle inline-block w-4 h-4 align-baseline"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </span>
            <div style="display: none;">
              <div id="help">
                <div class="m-3">
                  <p class="mb-4">
                    If you need help writing your resume, please use the
                    <a
                      href="https://api-abroad.sfo2.cdn.digitaloceanspaces.com/documents/2020-Resume-Guidelines.pdf"
                      target="_blank"
                      :class="[
                        secondaryColor
                          ? `${secondaryColorClass}`
                          : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
                      ]"
                      >resume guidelines</a
                    >
                    and download the resume template.
                  </p>
                  <div class="text-center">
                    <a
                      href="https://api-abroad.sfo2.cdn.digitaloceanspaces.com/documents/Sample_Resume.pdf"
                      target="_blank"
                      :class="[
                        secondaryColor
                          ? 'brandingSecondary'
                          : 'bg-university-secondary hover:bg-teal-100 focus:bg-teal-100',
                      ]"
                      class="inline-flex justify-center items-center text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white hover:text-white focus:text-white"
                    >
                      <span>Download Template</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <FileUpload
              :name="'resumeUpload'"
              :file-types="['.pdf', '.docx']"
              :formio-files="files"
              @updateProgress="updateProgress"
              @updateFiles="updateFiles"
            />
          </div>
          <ButtonWithSpinner
            ref="SubmitForm"
            type="submit"
            variant="primary"
            variant-type="block"
            :disabled="!allowSubmit"
            data-cy="resumeSubmitButton"
            @click.prevent="submit()"
          >
            <span
              >Submit
              <svg
                v-if="formSubmitted && !hasErrors"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="inline feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline></svg
            ></span>
          </ButtonWithSpinner>
          <p v-if="hasErrors" class="text-error-900">
            Please complete the required fields correctly.
          </p>
          <div v-if="formioErrors" class="text-error-900">
            <p v-for="error in formioErrors" :key="error">
              {{ error }}
            </p>
          </div>
          <div v-if="missingAppId" class="text-error-900">
            <p>
              No Application ID, please contact your API program manager to help
              you with your application.
            </p>
          </div>
          <div v-if="formSubmitted && !hasErrors" class="text-success-900">
            <p>
              Submission Complete, thank you!
            </p>
          </div>
        </template>
      </FormPanel>
    </div>
  </div>
</template>
<script>
import FileUpload from "../SharedComponents/FileUpload.vue";
import forms from "../../../mixins/forms";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import FormPanel from "../SharedComponents/panel.vue";
import { mapState } from "vuex";
import formioHelpers from "../../../mixins/formioHelpers";
import fileUpload from "../../../mixins/fileUpload";
import formIoApi from "../../../mixins/formIoApi";
import Spinner from "../../helpers/Spinner.vue";
import formValidation from "../../../mixins/formValidation";

export default {
  name: "ResumeForm",
  components: { FileUpload, FormPanel, ButtonWithSpinner, Spinner },
  mixins: [forms, formioHelpers, fileUpload, formIoApi, formValidation],
  data() {
    return {
      applicationId: "",
      formSubmitted: false,
      loading: true,
      message: "Getting Student applications",
      redirect: false,
      formPath: "resume",
    };
  },
  computed: {
    ...mapState(["formioToken", "isInternship"]),
    allowSubmit() {
      if (
        !this.filesOnProgress &&
        !this.fileIputHasErrors &&
        this.files.length > 0
      ) {
        return true;
      }
      return false;
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          application_id: this.applicationId,
          resumeUpload: this.files,
          resumeSubmitted: true,
        },
      };
      return body;
    },
  },
  watch: {
    files: {
      deep: true,
      handler() {
        //reset the submitted status
        this.formSubmitted = false;
      },
    },
    redirect: function (newVal, oldVal) {
      if (newVal) {
        //redirect to my applications
        this.$router.push({
          name: "applications",
        });
      }
    },
  },
  async created() {
    //set the application ID
    this.applicationId = this.setApplicationId;

    this.renderForm();
  },

  methods: {
    async renderForm() {
      //Make sure I user owns the application ID and if form is allowed in form rules
      if (!this.isValidApplication(this.applicationId, "Resume")) {
        this.redirect = true;
        return;
      }

      //all good fetch already submitted data if it exist and load the form
      this.prefetchFormData();
    },
    prefetchFormData() {
      this.formioSubmissionExists(this.formPath, this.applicationId).then(
        (formSubmissionId) => {
          if (formSubmissionId) {
            this.getFormioSubmission(this.formPath, formSubmissionId).then(
              (response) => {
                if (response.resumeUpload) {
                  this.files = response.resumeUpload;
                }
                this.loading = false;
              }
            );
          } else {
            this.loading = false;
          }
        }
      );
    },
    async submit() {
      this.submitted = false;
      //calling the startLoading method of the button, which enables the button to loading state
      this.$refs.SubmitForm.startLoading();
      try {
        await this.checkForApplicationId();
        if (this.allowSubmit) {
          if (this.submission_id) {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo,
              "PUT",
              this.submission_id
            ).then((response) => {
              if (response) {
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          } else {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                this.submission_id = response;
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          }
        } else {
          this.$refs.SubmitForm.stopLoading();
        }
      } catch {
        this.$refs.SubmitForm.stopLoading();
      }
    },
  },
};
</script>
