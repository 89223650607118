<template>
  <ul class="inset-y-0 right-0 list-none">
    <li v-if="!hideSaved" class="text-sm">
      <span class="text-red-100 pr-2 align-sub">**</span>&nbsp;Required to
      publish
    </li>
    <li v-if="!hidePublish" class="text-sm">
      <span class="text-red-100 pr-2 align-sub">&nbsp;*</span>&nbsp;Required to
      save
    </li>
  </ul>
</template>

<script>
export default {
  name: "RequiredToSavePublish",
  props: {
    hideSaved: {
      // provide more specific type to `Object`
      type: Boolean,
      required: false,
      default: false,
    },
    hidePublish: {
      // provide more specific type to `Object`
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.align-sub {
  vertical-align: sub;
}
</style>
