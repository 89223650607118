import { trailingSlash } from "./helpers";
import throttledSearch from "./throttledSearch";
import _ from "lodash";

export default {
  created: function () {
    if (this.location.value) {
      this.initSemestersList();
    }
  },
  mixins: [throttledSearch],
  data() {
    return {
      semesters: [],
    };
  },
  computed: {
    semestersURL() {
      return (
        trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) +
        "terms/" +
        this.location.value
      );
    },
  },
  methods: {
    initSemestersList() {
      fetch(this.semestersURL)
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          return response.json();
        })
        .then((data) => {
          this.semesters = Object.values(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * Triggered when the search text changes on vSelect component for semesters list
     *
     * @param search  {String}    Current search text
     * @param loading {Function}  Toggle loading class
     */
    searchSemesters(search, loading) {
      loading(true);
      this.throttledSearch(search, loading, this, "semesters");
      this.throttledSearchSemesters(search, loading, this);
    },
    throttledSearchSemesters: _.debounce((search, loading, vm) => {
      fetch(vm.semestersURL + "?search=" + encodeURIComponent(search))
        .then((response) => response.json())
        .then((data) => {
          vm.semesters = Object.values(data);
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    }, 250),
  },
};
