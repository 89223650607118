<template>
  <div v-if="showHousingTile" id="housing-tile" class="flex flex-col">
    <router-link
      :to="{
        name: 'housing',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Housing Accommodation
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
import formIoApi from "../../mixins/formIoApi";
import postFormRules from "../../mixins/postFormRules";
import { eventBus } from "../../app";
import { POST_FORM_RULES } from "../../constants";
import { mapState } from "vuex";

export default {
  name: "HousingCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi, postFormRules],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formPath: "housing",
    };
  },
  computed: {
    ...mapState(["currentApplicationSubmissions"]),
    completed() {
      return (
        Object.hasOwn(this.currentApplicationSubmissions, this.formPath) &&
        this.showHousingTile
      );
    },
  },
  async mounted() {
    if (this.showHousingTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Housing Accommodation",
        ruleName: POST_FORM_RULES["Housing"],
        routeName: "housing",
        submitted: this.completed,
      });
    }
  },
};
</script>

<style></style>
