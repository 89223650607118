<template>
  <div class="flex md:hidden justify-start order-first">
    <button
      id="menu-open"
      aria-label="Mobile Menu Toggle"
      class="block text-gray-600 hover:text-gray-700 z-40"
      @click="toggleSidebar"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-7 h-7"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { eventBus } from "../../app";

export default {
  name: "MenuButton",
  methods: {
    toggleSidebar() {
      eventBus.$emit("toggleMobileSidebar");
    },
  },
};
</script>

<style></style>
