<template>
  <div>
    <label class="flex mt-4" :class="labelContainerClass">
      <input
        v-model="boxChecked"
        :name="name"
        type="checkbox"
        :class="[`${primaryColorClass}`, boxClass]"
        class="mt-1 form-checkbox"
      />
      <div class="flex-1 ml-2">
        <p class="text-xs" :class="labelClass">
          {{ label }}
        </p>
      </div>
    </label>
    <div v-for="(value, rule) in sortedValidationRules" :key="rule">
      <div
        v-if="validationErrors[rule].$invalid"
        class="error text-error-900"
        :class="{ hidden: !validationErrors.$error }"
      >
        {{
          rule === "sameAs"
            ? validationMessage[rule].acceptCheckbox
            : "Checkbox error"
        }}
      </div>
    </div>
  </div>
</template>

<script>
import nestedModelComponent from "../../../mixins/nestedModelComponent";
import validationMessages from "../../../mixins/validationMessages";

export default {
  mixins: [nestedModelComponent, validationMessages],
  props: {
    label: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    labelContainerClass: {
      type: String,
      default: "",
    },
    boxClass: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    ariaControls: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input"],
  data() {
    return {
      boxChecked: "",
    };
  },
  watch: {
    boxChecked: function (newVal) {
      this.$emit("input", newVal);
    },
    checked: function () {
      this.boxChecked = this.checked;
    },
  },
  created() {
    if (this.checked) {
      this.boxChecked = this.checked;
    }
  },
};
</script>
