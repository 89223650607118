<template>
  <div data-cy="spinner-loader" :class="outerClasses">
    <div class="flex flex-col items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
        <g fill="#4354A8" fill-rule="evenodd">
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -1.1s;"
            d="M33.492 3.465v8.934c0 4.62-6.982 4.62-6.982 0V3.465c0-4.62 6.982-4.62 6.982 0z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -1s;"
            d="M46.29 8.766l-4.466 7.739c-2.311 4-8.358.508-6.047-3.492l4.467-7.74c2.311-4 8.358-.508 6.047 3.493z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.9s;"
            d="M54.726 19.755l-7.737 4.467c-4 2.311-7.49-3.738-3.492-6.046l7.737-4.467c4-2.309 7.49 3.738 3.492 6.046z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.8s;"
            d="M56.535 33.492h-8.934c-4.62 0-4.62-6.982 0-6.982h8.934c4.62 0 4.62 6.982 0 6.982z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.7s;"
            d="M51.234 46.29l-7.737-4.466c-4-2.309-.508-8.358 3.492-6.047l7.737 4.467c4 2.311.509 8.358-3.492 6.047z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.6s;"
            d="M40.245 54.726l-4.467-7.737c-2.309-4 3.738-7.49 6.046-3.492l4.467 7.737c2.309 4-3.738 7.493-6.046 3.492z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.5s;"
            d="M26.508 56.535v-8.934c0-4.62 6.984-4.62 6.984 0v8.934c0 4.62-6.984 4.62-6.984 0z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.4s;"
            d="M13.71 51.234l4.466-7.737c2.311-4 8.358-.508 6.047 3.492l-4.467 7.737c-2.309 4-8.358.509-6.047-3.492z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.3s;"
            d="M5.274 40.245l7.737-4.467c4-2.309 7.49 3.738 3.492 6.046l-7.737 4.467c-4 2.309-7.49-3.738-3.492-6.046z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.2s;"
            d="M3.465 26.51h8.934c4.62 0 4.62 6.982 0 6.982H3.465c-4.62 0-4.62-6.982 0-6.982z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: -0.1s;"
            d="M16.505 18.176c4 2.309.508 8.358-3.492 6.047l-7.737-4.467c-4.001-2.309-.509-8.358 3.492-6.047l7.737 4.467z"
          />
          <path
            class="animate-spinner"
            style="opacity: 0.1; animation-delay: 0s;"
            d="M24.222 13.013c2.311 3.998-3.738 7.49-6.046 3.49l-4.467-7.738c-2.309-4 3.738-7.49 6.046-3.492l4.467 7.74z"
          />
        </g>
      </svg>
      <div
        v-show="showSpinnerContent"
        class="mt-5 text-sm text-center text-gray-600 space-y-2"
      >
        <slot name="spinnercontent"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpinnerVue",
  props: {
    outerClasses: {
      type: String,
      default:
        "absolute inset-0 flex items-center justify-center bg-white z-10",
    },
    showSpinnerContent: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.animate-spinner {
  -webkit-animation: spinner 1.1s linear infinite;
  animation: spinner 1.1s linear infinite;
}
@-webkit-keyframes spinner {
  10% {
    opacity: 100%;
  }

  100% {
    opacity: 10%;
  }
}

@keyframes spinner {
  10% {
    opacity: 100%;
  }

  100% {
    opacity: 10%;
  }
}
</style>
