import { getApiClient } from "@/services/API";

export const checkForExistingLeads = async (
  firstName,
  lastName,
  email,
  phone,
  birthdate
) => {
  return (
    await getApiClient().post("/leads/exist", {
      firstName,
      lastName,
      email,
      phone,
      birthdate,
    })
  ).data?.exists;
};
