import { _ } from "formiojs/utils/utils";
import { getDecodedData } from "@/mixins/helpers.js";

/**
 *
 * @param {*} initialKeys -> an array of keys where we will store each query param
 * it has to match the query key example:
 * if the query string look like this -> results?startDate=2023-06-05&endDate=2023-06-07
 * we need an array with the following:
 * initialKeys=[`startDate`,`endDate`]
 */
export function useQueryParamsToState(initialKeys = null, route) {
  if (_.isEmpty(initialKeys)) return;
  let decodedValue = []; //container for the decoded url param in case it is encoded
  const result = _.cloneDeep(initialKeys);

  Object.keys(route.query).forEach((urlKey) => {
    let value = route.query[urlKey];
    if (Object.prototype.hasOwnProperty.call(result, urlKey)) {
      switch (urlKey) {
        case "session_id":
          if (value) {
            const obj = {
              label: getDecodedData(value, "label"),
              value: getDecodedData(value),
            };
            result["session_id"].push(obj);
          }
          break;
        case "keyword":
          result["keyword"] = decodeURIComponent(value);
          break;
        case "classTitle":
          result["classTitle"] = getDecodedData(value);
          break;
        case "pastPrograms":
          value = getDecodedData(value);
          if (value && value === "true") {
            result["pastPrograms"] = value;
          }
          break;
        case "price":
          decodedValue = getDecodedData(decodeURIComponent(value)).split("|");
          result["price"] = decodedValue.map((element) => +element);
          break;
        case "startDate":
        case "endDate":
          decodedValue = getDecodedData(value);
          if (decodedValue) {
            let obj = {
              label:
                urlKey === "startDate"
                  ? "Start Date: " + decodedValue
                  : "End Date: " + decodedValue,
              value: decodedValue,
            };
            result[urlKey].push(obj);
          }
          break;
        case "programTypes":
          if (typeof value === "string") {
            result[urlKey].push(value);
          } else {
            value.forEach((el) => {
              result[urlKey].push(el);
            });
          }
          break;
        case "programLength":
          if (typeof value === "string") {
            result[urlKey].push(value);
          } else {
            value.forEach((el) => {
              result[urlKey].push(el);
            });
          }
          break;
        case "timeframe":
          if (typeof value === "string") {
            result[urlKey].push(value);
          } else {
            value.forEach((el) => {
              result[urlKey].push(el);
            });
          }
          break;
        case "courseLevel":
          if (typeof value === "string") {
            result[urlKey].push(value);
          } else {
            value.forEach((el) => {
              result[urlKey].push(el);
            });
          }
          break;
        case "language":
          if (typeof value === "string") {
            result[urlKey].push(value);
          } else {
            value.forEach((el) => {
              result[urlKey].push(el);
            });
          }
          break;
        default:
          if (typeof value === "string") {
            let obj = {
              label: getDecodedData(value, "label"),
              value: getDecodedData(value),
              checked: true,
            };
            result[urlKey].push(obj);
          } else {
            value.forEach((el) => {
              let obj = {
                label: getDecodedData(el, "label"),
                value: getDecodedData(el),
                checked: true,
              };
              result[urlKey].push(obj);
            });
          }
          break;
      }
    }
  });
  // Remove properties with empty arrays
  return _.pickBy(result, (arr) => arr.length > 0);
}

// generate url query string
export function useConvertToUrlParams(arrayToConvert = null) {
  if (_.isEmpty(arrayToConvert)) return;
  //query params container
  const queryString = {};
  //we will loop thru each active filter in the state to build the queryString
  for (const key in arrayToConvert) {
    if (arrayToConvert[key] && typeof arrayToConvert[key] === "string") {
      let encodedValue = encodeURIComponent(arrayToConvert[key]);
      if (encodedValue) {
        queryString[encodeURIComponent(key)] = encodedValue;
      }
    }

    if (arrayToConvert[key] && Array.isArray(arrayToConvert[key])) {
      for (let i = 0; i < arrayToConvert[key].length; i++) {
        let param = `${arrayToConvert[key][i].label}#$#${arrayToConvert[key][i].value}`;
        let encodedValue = encodeURIComponent(param);
        if (encodedValue) {
          if (key in queryString) {
            queryString[key].push(encodedValue);
          } else {
            queryString[encodeURIComponent(key)] = [encodedValue];
          }
        }
      }
    } else if (arrayToConvert[key] && typeof arrayToConvert[key] === "object") {
      let param = `${arrayToConvert[key].label}#$#${arrayToConvert[key].value}`;
      let encodedValue = encodeURIComponent(param);

      if (encodedValue) {
        if (key in queryString) {
          queryString[key].push(encodedValue);
        } else {
          queryString[encodeURIComponent(key)] = [encodedValue];
        }
      }
    }
  }
  //remove empty
  for (let key in queryString) {
    if (Object.prototype.hasOwnProperty.call(queryString, key)) {
      if (Array.isArray(queryString[key]) && queryString[key].length === 0) {
        delete queryString[key];
      } else if (queryString[key] === "") {
        delete queryString[key];
      }
    }
  }

  return queryString;
}

export function anyAppliedFilter(route) {
  let initialFilters = {
    keyword: "",
    programTypes: [],
    countries: [],
    cities: [],
    areas: [],
    startDate: [],
    endDate: [],
    courseLevel: [],
    language: [],
    price: [],
    timeframe: [],
    hostInstitution: [],
    pastPrograms: [],
    career: [],
    classTitle: "",
    programLength: [],
    session_id: [],
  };
  Object.assign(initialFilters, useQueryParamsToState(initialFilters, route));
  Object.keys(initialFilters).forEach((key) => {
    if (_.isEmpty(initialFilters[key])) {
      delete initialFilters[key];
    }
  });

  if (initialFilters) {
    let arr = {};
    for (let [key, val] of Object.entries(initialFilters)) {
      if (
        [
          "cities",
          "countries",
          "areas",
          "career",
          "hostInstitution",
          "pastPrograms",
          "session_id",
        ].includes(key)
      ) {
        arr[key] = val;
        delete initialFilters[key];
      }
    }
    Object.assign(initialFilters, useConvertToUrlParams(arr));
  }
  return initialFilters;
}
