<template>
  <div>
    <template v-for="(item, index) in items" :key="index">
      <MenuItem v-bind="item" :disabled-button="!editable" />
    </template>
    <LinkModal
      :open="openModal"
      :previous-url="previousUrl"
      @cancel="cancelModal"
      @setUrl="setUrl"
    />
  </div>
</template>

<script>
import LinkModal from "@/components/forms/SharedComponents/WYSIWYG/LinkModal.vue";
import MenuItem from "@/components/forms/SharedComponents/WYSIWYG/MenuItem.vue";

export default {
  components: {
    LinkModal,
    MenuItem,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          icon: "IconBold",
          title: "Bold",
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive("bold"),
        },
        {
          icon: "IconItalic",
          title: "Italic",
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive("italic"),
        },
        {
          icon: "IconUnderline",
          title: "Underline",
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          isActive: () => this.editor.isActive("underline"),
        },
        {
          icon: "IconBulletedList",
          title: "Bullet List",
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive("bulletList"),
        },
        {
          icon: "IconNumeredList",
          title: "Ordered List",
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive("orderedList"),
        },
        {
          icon: "IconLink",
          title: "Link",
          action: () => {
            this.previousUrl = this.editor.getAttributes("link").href;
            this.openModal = true;
          },
          isActive: () => this.editor.isActive("link"),
        },
        {
          icon: "IconUnlink",
          title: "Unlink",
          action: () => this.editor.chain().focus().unsetLink().run(),
        },
      ],
      openModal: false,
      previousUrl: "",
    };
  },
  methods: {
    cancelModal() {
      this.openModal = false;
      this.previousUrl = "";
    },
    setUrl(url) {
      if (url) {
        this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: url })
          .run();
      } else {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
      }

      this.openModal = false;
    },
  },
};
</script>
