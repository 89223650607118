<script>
export default {
  name: "StudentFulfillment",
};
</script>

<script setup>
import { useStore } from "vuex";
import { reactive, computed, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { debounce } from "lodash";
import { hasOrdersWritePermission } from "@/composables/authorization";
import {
  getCoreRowModel,
  getSortedRowModel,
  useVueTable,
} from "@tanstack/vue-table";
import FeedbackIcon from "@/components/svg-icons/FeedbackIcon";
import { createColumnHelper } from "@tanstack/vue-table";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import { FlexRender } from "@tanstack/vue-table";
import IconElips from "@/components/shared/icons/IconElips.vue";
import ErrorPage from "@/components/errorPage.vue";
import FilterSection from "@/components/StudentFulfillment/components/FilterSection";
import SearchBar from "@/components/shared/SearchBar.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import {
  useGetOrdersLite,
  useGetSingleOrder,
} from "@/components/StudentFulfillment/composables/orders";
import HousingFulfillmentModal from "@/components/StudentFulfillment/components/modals/HousingFulfillmentModal";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";
import CheckboxSelect from "@/components/shared/select/CheckboxSelect.vue";
import BadgeBar from "@/components/program-manager/sessions/components/BadgeBar";
import {
  orderStatuses,
  productNames,
  productStatuses,
  productStatusesId,
  generateStudentName,
} from "./utils";

import SimplePagination from "@/components/shared/SimplePagination.vue";

const router = useRouter();
const route = useRoute();
const store = useStore();

const featureFlags = computed(() => store.state.featureFlags);

const hasTableauEmbed = computed(() => {
  return featureFlags.value["tableau-embed"];
});

const { page: routePage, q } = route.query;

const headers = [
  "Name",
  "Email",
  "Home Institution",
  "Program Session",
  "Application Version",
  "Order Status",
];

const filterProductOptions = (textInput) =>
  productNames.filter(({ name }) => name.includes(textInput)) || [];

const state = reactive({
  modalIsOpen: false,
  order: undefined,
});

const requestParams = reactive({
  limit: 10,
  page: Number(routePage) || 1,
  search: q || "",
  extraParams: {
    session_name: undefined,
    status: undefined,
    product_name: [],
    product_status: undefined,
  },
});

const {
  isLoading: loading,
  error: fetchError,
  execute: executeFetchOrdersLite,
  state: ordersData,
} = useGetOrdersLite(
  {
    immediate: true,
    throwError: true,
    resetOnExecute: true,
  },
  { extraParams: { q: requestParams?.search, get_withdrawn: true } }
);

const {
  execute: executeFetchSingleOrder,
  state: singleOrderData,
} = useGetSingleOrder({}, false);

const activeFilter = computed(() => ({
  title: "Orders",
  count: ordersData?.value?.data?.count || 0,
}));

const showPagination = computed(
  () => activeFilter.value.count > requestParams.limit
);

const totalPages = computed(() =>
  Math.ceil(activeFilter.value.count / requestParams.limit)
);

const viewOrderDetail = (orderId) =>
  `/program-manager/operations/order-details/${orderId}`;

const openFulfillHousing = async (orderId) => {
  if (orderId) {
    await executeFetchSingleOrder(0, {
      orderId,
      get_canceled: true,
    });
    state.order = { ...singleOrderData?.value };
    state.modalIsOpen = true;
  }
};

const openFulfillInternship = (/* orderId */) => ""; // Blocked by RAG-847
const isStatusAllowed = (/* status */) => {
  return true;
};

const closeFulfillHousing = () => {
  state.order = undefined;
  state.modalIsOpen = false;
};

const canEditOrder = (status) => {
  return status !== productStatusesId.FULFILLED;
};

const clearFilters = async () => {
  requestParams.search = undefined;
  requestParams.extraParams = {
    session_name: undefined,
    status: undefined,
    product_name: [],
    product_status: undefined,
  };
  await executeSearchForOrders();
};

const disableProductStatus = computed(() => {
  return !requestParams.extraParams?.product_name?.length;
});

const executeSearchForOrders = async () => {
  const params = requestParams;
  const {
    product_name: productName,
    session_name: sessionName,
    ...extra
  } = params.extraParams;

  // Block Session Name search until we have >= 3 chars
  if (sessionName && sessionName.length < 3) {
    return;
  }
  extra.session_name = sessionName;

  const selectedProductName = productName?.map((p) => p.name) ?? [];
  if (!selectedProductName.length) {
    extra.product_status = undefined;
  }
  extra.product_name = selectedProductName?.includes("All")
    ? productNames?.map((p) => p.name)?.filter((p) => p !== "All")
    : selectedProductName;

  extra.get_withdrawn = true;
  if (extra.product_status === productStatusesId.CANCELED) {
    extra.get_canceled = true;
  }

  await executeFetchOrdersLite(0, {
    extraParams: { q: params.search, ...extra },
    page: params.page,
    limit: params.limit,
  });
};

const columnHelper = createColumnHelper();

const table = useVueTable({
  get data() {
    return (
      ordersData?.value?.data?.items?.map((item) => ({
        id: item?.order?.id,
        Name: generateStudentName(item?.order),
        Email: item?.order?.participant_email ?? "",
        "Requires Accommodation": item?.order?.requires_accommodations ?? false,
        "Home Institution": item?.order?.home_institution_name ?? "",
        "Program Session": item?.order?.session?.name,
        "Application Version":
          item?.order?.application_version?.toUpperCase() ?? "",
        "Enrollment Status": item?.order?.enrollment_status ?? "",
        "Order Status": item?.order?.status ?? "",
      })) || []
    );
  },
  columns: headers.map((header) => {
    return columnHelper.accessor(header, {
      cell: (info) => info.getValue(),
      header,
    });
  }),
  enableColumnResizing: true,
  columnResizeMode: "onChange",
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  manualPagination: true,
});

const handleSearch = async (search) => {
  if (search.length >= 3) {
    requestParams.search = search || "";
    requestParams.page = 1;
    await executeSearchForOrders();
  } else if (!search?.length) {
    requestParams.search = undefined;
    requestParams.page = 1;
    await executeSearchForOrders();
  }
};

const changePage = (newPage) => {
  requestParams.page = newPage;
  router.push({
    query: {
      ...route.query,
      page: requestParams.page,
    },
  });
};

watch(requestParams.extraParams, async (params) => {
  await executeSearchForOrders();
});

const search = debounce(handleSearch, 250);
</script>

<template>
  <div class="bg-blue-100">
    <HousingFulfillmentModal
      :order="state.order"
      :modal-is-open="state.modalIsOpen"
      @close-modal="closeFulfillHousing"
    />
    <section>
      <div class="flex mt-10 px-3 w-full flex-wrap">
        <div class="flex ml-2 w-full justify-between flex-wrap">
          <h1 class="text-2xl font-bold text-blue-900">
            Student Fulfillment
          </h1>
          <div class="flex items-center gap-8">
            <router-link
              v-if="hasTableauEmbed"
              :to="{ name: 'fulfillment-reports' }"
              class="uppercase leading-8 tracking-widest font-semibold text-xs text-university-secondary hover:opacity-80"
            >
              <h4>View Reports</h4>
            </router-link>
            <SearchBar
              data-testid="search-bar"
              class="flex sm:w-full min-w-[18.75rem] md:w-[25rem] rounded h-10"
              placeholder="Search by name or email"
              flexReverse
              hideAdvancedFilters
              :input-from-parent="requestParams.search"
              @handle-search="search"
            >
            </SearchBar>
          </div>
        </div>
        <div class="mt-5 w-full">
          <FilterSection @clear-filters="clearFilters">
            <template #filters>
              <div class="min-w-[240px] flex-1">
                <input
                  data-testid="session-name-filter-search-input"
                  v-model="requestParams.extraParams.session_name"
                  class="px-4 py-0 h-10 rounded w-full placeholder-indigo-base placeholder-opacity-60"
                  placeholder="Session Name"
                />
              </div>
              <div class="min-w-[240px] flex-1 max-w-[280px]">
                <v-select-caret
                  data-testid="order-status-filter-select"
                  v-model="requestParams.extraParams.status"
                  input-classes="placeholder-center placeholder-indigo-base"
                  placeholder="Order Status"
                  :options="orderStatuses"
                  :reduce="(val) => val.name"
                  label="name"
                  :clearable="false"
                />
              </div>
              <div class="min-w-[240px] flex-1">
                <CheckboxSelect
                  data-testid="product-name-filter-checkbox-select"
                  v-model="requestParams.extraParams.product_name"
                  input-classes="default-chevron"
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  :multiple="true"
                  :reduce="(val) => ({ id: val.id, name: val.name })"
                  :options="productNames || []"
                  label="name"
                  :placeholder="'Product Name'"
                  :enable-select-all-checkbox="true"
                  scroll-input="auto"
                  input-max-height="29px"
                  @search="filterProductOptions"
                />
              </div>

              <div
                class="min-w-[240px] flex-1 max-w-[280px]"
                :class="disableProductStatus ? 'opacity-60' : ''"
              >
                <v-select-caret
                  data-testid="product-status-filter-select"
                  v-model="requestParams.extraParams.product_status"
                  input-classes="placeholder-center placeholder-indigo-base"
                  placeholder="Product Status"
                  :options="productStatuses"
                  :reduce="(val) => val.name"
                  label="name"
                  :clearable="false"
                  :disabled="disableProductStatus"
                />
              </div>
            </template>
          </FilterSection>
          <BadgeBar v-model="requestParams.extraParams" class="col-span-11" />
        </div>
      </div>
    </section>

    <div class="flex justify-center">
      <table v-if="!loading && !fetchError && table" class="m-4 p-2 w-full">
        <thead>
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
          >
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              class="py-4 pr-3 first:pl-2 text-left text-sm items-center text-gray-900"
            >
              <div class="flex items-center">
                {{ header.column.columnDef.header }}
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr
            v-for="row in table.getRowModel().rows"
            :key="row.id"
            class="hover:bg-blue-350"
          >
            <td
              v-for="cell in row.getVisibleCells()"
              :key="cell.column.id"
              class="py-8 text-sm first:pl-4 first:text-teal-900 border-t-[1px] border-solid border-t-gray-200"
            >
              <div class="flex">
                <FlexRender
                  :render="cell.column.columnDef.cell"
                  :props="cell.getContext()"
                />
                <FeedbackIcon
                  v-if="
                    cell.column.id == 'Name' &&
                    row.original['Requires Accommodation']
                  "
                  class="ml-2 h-6 w-6 fill-current text-error-900"
                />
              </div>
            </td>
            <td class="py-8 border-t-[1px] border-solid border-gray-200">
              <Menu as="div" class="relative inline-block">
                <div>
                  <MenuButton
                    class="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900"
                  >
                    <IconElips />
                  </MenuButton>
                </div>

                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div>
                    <router-link
                      :key="`order-details-${row.original.id}`"
                      :to="viewOrderDetail(row.original.id)"
                      class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200"
                      >View order details</router-link
                    >
                    <div
                      class="text-gray-600 block text-sm hover:bg-gray-200 w-full text-left px-4 py-2 cursor-pointer"
                    >
                      <button
                        :disabled="!canEditOrder(row.original['Order Status'])"
                        @click="openFulfillHousing(row.original.id)"
                      >
                        <label
                          v-if="hasOrdersWritePermission"
                          class="cursor-pointer"
                          :key="`fulfill-housing-${row.original.id}`"
                          >Fulfill housing</label
                        >
                      </button>
                    </div>
                    <!-- To be unhidden as part of work on Internship fulfillment modal. -->
                    <!-- <router-link
                      :key="`fulfill-internship-${row.original.id}`"
                      :to="openFulfillInternship(row.original.id)"
                      class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200"
                      >Fulfill Internship</router-link
                    > -->
                  </div>
                </MenuItems>
              </Menu>
            </td>
          </tr>
        </tbody>
      </table>
      <Spinner
        v-else-if="loading && !fetchError"
        class="relative h-65vh bg-blue-100"
      />
      <ErrorPage
        v-else-if="fetchError"
        class="relative h-65vh"
        :message="fetchError"
        disable-code
      />
    </div>
    <footer>
      <SimplePagination
        v-if="showPagination && !fetchError"
        class="mb-14"
        :page="requestParams.page"
        :page-count="totalPages"
        @on-page-change="changePage"
      />
    </footer>
  </div>
</template>
