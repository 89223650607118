<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter id="cj7hmfltra">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.184314 0 0 0 0 0.376471 0 0 0 0 0.584314 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <rect
        width="52"
        height="52"
        rx="26"
        fill="#FAFBFF"
        stroke="#2F6095"
        stroke-width="2"
        transform="translate(1 1)"
      />
      <g filter="url(#cj7hmfltra)" transform="translate(1 1)">
        <path
          d="M27 3.333c-7.168 0-13 9.719-13 21.667 0 3.803.596 7.547 1.727 10.826a.72.72 0 0 0 .446.447C19.453 37.404 23.197 38 27 38c11.948 0 21.667-5.832 21.667-13C48.667 13.05 38.948 3.333 27 3.333zM47.222 25H40c0-7.168-5.832-13-13-13V4.778c11.152 0 20.222 9.07 20.222 20.222zm-26 0c0-5.268 1.876-9.836 4.334-11.158V18.5c0 .034.013.061.018.093-.996 1.289-1.463 3.913-1.463 6.407 0 .6.034 1.194.088 1.781l-2.537 2.537a21.43 21.43 0 0 1-.44-4.318zM27 26.444c-.467 0-.932-.018-1.39-.054a17.717 17.717 0 0 1-.054-1.39c0-3.792 1.072-5.778 1.444-5.778A5.783 5.783 0 0 1 32.778 25c0 .372-1.986 1.444-5.778 1.444zm-1.78 1.357c.586.054 1.18.088 1.78.088 2.494 0 5.119-.467 6.408-1.463.031.005.058.018.092.018h4.658c-1.322 2.458-5.89 4.334-11.158 4.334a21.43 21.43 0 0 1-4.317-.44l2.536-2.537zM34.221 25A7.229 7.229 0 0 0 27 17.778v-4.334c6.371 0 11.556 5.185 11.556 11.556h-4.334zm-18.778 0c0-10.294 4.42-18.81 10.112-20.06v7.31c-3.336 1.174-5.778 6.353-5.778 12.75 0 1.934.235 3.784.681 5.518l-3.72 3.72c-.845-2.854-1.295-6.018-1.295-9.238zM27 36.556c-3.22 0-6.385-.45-9.238-1.296l3.72-3.72c1.733.445 3.584.682 5.518.682 6.396 0 11.576-2.444 12.75-5.778h7.31C45.81 32.136 37.293 36.556 27 36.556z"
          fill="#FFF"
        />
      </g>
    </g>
  </svg>
</template>
