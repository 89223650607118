<script setup>
import { computed, defineProps, defineEmits, toRefs } from "vue";
import { Dialog, DialogPanel } from "@headlessui/vue";
import BaseButton from "@/components/shared/Button/BaseButton.vue";
const emit = defineEmits(["closeModal", "updatePrice", "keepOriginalPrice"]);
import { formattedPriceFromCentsWithoutCents } from "@/util/formatter";
import { usePrifeDifference } from "@/components/StudentFulfillment/composables/priceDifference.js";
const props = defineProps({
  isModalOpen: {
    type: Boolean,
    default: false,
  },
  roomsDiff: {
    type: Object,
    required: false,
  },
  ordersDiff: {
    type: Object,
    required: false,
  },
});

const { roomsDiff, ordersDiff } = toRefs(props);

const roomOriginal = computed(() => roomsDiff?.value?.original ?? {});
const roomUpdated = computed(() => roomsDiff?.value?.updated ?? {});
const originalSessionPrices = computed(() => ordersDiff?.value?.original ?? {});
const updatedOrder = computed(() => ordersDiff?.value?.updated ?? {});

const { getDiffs } = usePrifeDifference();
const diffs = computed(() => {
  if (originalSessionPrices?.value || updatedOrder?.value) {
    return getDiffs({
      left: originalSessionPrices?.value,
      right: updatedOrder?.value,
    });
  }
  return undefined;
});

const hasChangedClass = "text-warning-900";
const isTotalDifferent = computed(() => {
  return (
    originalSessionPrices?.value?.total_price_in_cents !==
    updatedOrder?.value?.total_price_in_cents
  );
});

const isSpecialPrice = computed(() => {
  updatedOrder?.value?.orig_total_price_in_cents > 0;
});

const closeModal = () => {
  emit("closeModal");
};

const updatePrice = () => {
  emit("updatePrice");
};

const keepOriginalPrice = () => {
  emit("keepOriginalPrice");
};
const goOn = () => {
  emit("goOn");
};
</script>

<template>
  <div>
    <div
      v-if="isModalOpen"
      class="fixed inset-0 z-[51] hidden bg-university-primary opacity-50 sm:block"
    ></div>
    <Dialog
      :open="isModalOpen"
      class="fixed inset-0 z-[52] py-6 overflow-auto bg-white sm:bg-transparent sm:p-8"
    >
      <div
        class="p-6 w-fit h-fit mx-auto bg-white sm:rounded-lg sm:shadow z-40 relative"
      >
        <DialogPanel>
          <section class="sm:px-6 md:px-12 sm:py-10 h-fit">
            <h1
              class="flex justify-center text-xl leading-tight text-center sm:text-2xl md:text-3xl"
            >
              Price Difference
            </h1>
            <div
              class="flex flex-col text-center mt-8 mb-8"
              v-if="roomOriginal?.roomPrice"
            >
              <p>
                The price of the bed you chose is different from the price of
                the requested room. How would you like to proceed?
              </p>
              <p class="text-blue-700">
                <span class="font-semibold">Original Room:</span>
                {{ roomOriginal?.housingName ?? "" }},
                {{ roomOriginal?.housingTier ?? "" }},
                {{ roomOriginal?.roomType ?? "" }},
                <span class="font-semibold">{{
                  roomOriginal?.roomPrice ?? ""
                }}</span>
              </p>
              <p class="text-red-700">
                <span class="font-semibold">Updated Room:</span>
                {{ roomUpdated?.housingName ?? "" }},
                {{ roomUpdated?.housingTier ?? "" }},
                {{ roomUpdated?.roomType ?? "" }},
                <span class="font-semibold">{{
                  roomUpdated?.roomPrice ?? ""
                }}</span>
              </p>
            </div>
            <div
              class="md:grid md:grid-cols-2 mt-8 mb-8 md:gap-8 md:space-y-0 space-y-2"
            >
              <div>
                <div class="space-y-2 md:w-80">
                  <div>
                    <span class="text-blue-700 mb-4 font-semibold"
                      >Program Session Base Price</span
                    >
                  </div>
                  <div
                    v-if="diffs?.educationalFee?.show"
                    class="flex justify-between pr-8"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.educationalFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.educationalFee?.original ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.internshipFee?.show"
                    class="flex justify-between pr-8"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.internshipFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.internshipFee?.original ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.housingFee?.show"
                    class="flex justify-between pr-8"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.housingFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.housingFee?.original ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.excursionsFee?.show"
                    class="flex justify-between pr-8"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.excursionsFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.excursionsFee?.original ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.insuranceFee?.show"
                    class="flex justify-between pr-8"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.insuranceFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.insuranceFee?.original ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.groupFlightFee?.show"
                    class="flex justify-between pr-8"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.groupFlightFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.groupFlightFee?.original ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.transportationFee?.show"
                    class="flex justify-between pr-8"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.transportationFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.transportationFee?.original ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.classesFee?.show"
                    class="flex justify-between pr-8"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.classesFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.classesFee?.original ?? "" }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="space-y-2">
                  <div>
                    <span class="text-red-700 mb-4 font-semibold space-y-4"
                      >Updated Session Price</span
                    >
                  </div>
                  <div
                    v-if="diffs?.educationalFee?.show"
                    class="flex justify-between pr-8"
                    :class="
                      diffs?.educationalFee?.hasChanged ? hasChangedClass : ''
                    "
                  >
                    <!-- Description -->
                    <div>{{ diffs?.educationalFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.educationalFee?.updated ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.internshipFee?.show"
                    class="flex justify-between pr-8"
                    :class="
                      diffs?.internshipFee?.hasChanged ? hasChangedClass : ''
                    "
                  >
                    <!-- Description -->
                    <div>{{ diffs?.internshipFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.internshipFee?.updated ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.housingFee?.show"
                    class="flex justify-between pr-8"
                    :class="
                      diffs?.housingFee?.hasChanged ? hasChangedClass : ''
                    "
                  >
                    <!-- Description -->
                    <div>{{ diffs?.housingFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.housingFee?.updated ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.excursionsFee?.show"
                    class="flex justify-between pr-8"
                    :class="[
                      'flex justify-between pr-8',
                      diffs?.excursionsFee?.hasChanged ? hasChangedClass : '',
                    ]"
                  >
                    <!-- Description -->
                    <div>{{ diffs?.excursionsFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.excursionsFee?.updated ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.insuranceFee?.show"
                    class="flex justify-between pr-8"
                    :class="
                      diffs?.insuranceFee?.hasChanged ? hasChangedClass : ''
                    "
                  >
                    <!-- Description -->
                    <div>{{ diffs?.insuranceFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.insuranceFee?.updated ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.groupFlightFee?.show"
                    class="flex justify-between pr-8"
                    :class="
                      diffs?.groupFlightFee?.hasChanged ? hasChangedClass : ''
                    "
                  >
                    <!-- Description -->
                    <div>{{ diffs?.groupFlightFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.groupFlightFee?.updated ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.transportationFee?.show"
                    class="flex justify-between pr-8"
                    :class="
                      diffs?.transportationFee?.hasChanged
                        ? hasChangedClass
                        : ''
                    "
                  >
                    <!-- Description -->
                    <div>{{ diffs?.transportationFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.transportationFee?.updated ?? "" }}</div>
                  </div>
                  <div
                    v-if="diffs?.classesFee?.show"
                    class="flex justify-between pr-8"
                    :class="
                      diffs?.classesFee?.hasChanged ? hasChangedClass : ''
                    "
                  >
                    <!-- Description -->
                    <div>{{ diffs?.classesFee?.name ?? "" }}</div>
                    <!-- Values -->
                    <div>{{ diffs?.classesFee?.updated ?? "" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2 mb-8 md:gap-8">
              <div class="flex justify-between pr-8">
                <div class="font-bold">Total</div>
                <div class="font-bold">
                  {{
                    formattedPriceFromCentsWithoutCents(
                      originalSessionPrices?.total_price_in_cents
                    )
                  }}
                </div>
              </div>
              <div
                class="flex justify-between pr-8"
                :class="isTotalDifferent ? hasChangedClass : ''"
              >
                <div class="font-bold">Total</div>
                <div class="font-bold">
                  {{
                    formattedPriceFromCentsWithoutCents(
                      isSpecialPrice
                        ? updatedOrder?.student_total_price_in_cents
                        : updatedOrder?.total_price_in_cents
                    )
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="roomOriginal?.roomPrice"
              class="flex align-center justify-center h-10 flex-0"
            >
              <BaseButton
                outlined
                :on-click="closeModal"
                class="px-7 font-semibold"
                >Cancel</BaseButton
              >
              <BaseButton
                :on-click="keepOriginalPrice"
                class="font-semibold"
                :disabled="true"
                >Keep the original price</BaseButton
              >
              <BaseButton :on-click="updatePrice" class="font-semibold"
                >Update</BaseButton
              >
            </div>
            <div v-else class="flex align-center justify-center h-10 flex-0">
              <BaseButton
                outlined
                :on-click="closeModal"
                class="px-7 font-semibold"
                >Cancel</BaseButton
              >
              <BaseButton :on-click="goOn" class="font-semibold"
                >Continue</BaseButton
              >
            </div>
          </section>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>
