<script>
export default {
  name: "ProgramSessionsActionMenu",
};
</script>

<script setup>
import IconElips from "@/components/shared/icons/IconElips.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  hasProgramsPublishPermission,
  hasProgramsReadLitePermission,
  hasProgramsWritePermission,
  isLibraryItemOwner
} from "@/composables/authorization";
import { computed } from "vue";

const emit = defineEmits(["openDuplicateModal", "openDetailModal"]);

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  entityId: {
    type: String,
    required: false,
  },
});

const isOwner = computed(() => {
  return isLibraryItemOwner(props.entityId);
})

const hasWritePermissions = computed(() => {
  return hasProgramsWritePermission() && isOwner.value;
});

const hasPublishPermissions = computed(() => {
  return hasProgramsPublishPermission() && isOwner.value;
})

const openDuplicate = async () => {
  emit("openDuplicateModal");
};

const openDetail = async () => {
  emit("openDetailModal");
};
</script>

<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton
      class="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-gray-700"
    >
      <IconElips />
    </MenuButton>
    <MenuItems
      class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -top-10 right-full bottom-auto"
    >
      <MenuItem v-if="hasProgramsReadLitePermission()">
        <div
          class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
          @click="openDetail"
        >
          View Detail
        </div>
      </MenuItem>
      <MenuItem v-if="hasWritePermissions">
        <router-link
          :to="{
            name: 'edit-program-sessions',
            params: { programSessionId: props.id },
          }"
          class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200"
        >
          Edit
        </router-link>
      </MenuItem>
      <MenuItem v-if="hasWritePermissions">
        <div
          class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
          @click="openDuplicate"
        >
          Duplicate
        </div>
      </MenuItem>
      <MenuItem v-if="hasPublishPermissions">
        <div class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200">
          Archive
        </div>
      </MenuItem>
    </MenuItems>
  </Menu>
</template>
