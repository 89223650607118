<template>
  <div>
    <label :class="labelClass">
      <span v-if="labelBeforeInput" :class="spanClass">
        {{ labelBeforeInput }}
      </span>
      <div class="date-input-container">
        <input
          v-model="inputValue"
          type="date"
          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
          placeholder="mm-dd-yyyy"
          :class="setStyleClass"
          :aria-labelledby="labelledby"
          :max="maxDate"
        />
        <div class="custom-calendar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            viewBox="0 0 25 23"
            fill="none"
            style="position: absolute; top: 2px; left: 10px;"
          >
            <path
              d="M3.5 3.55547H21C21.6445 3.54058 22.2697 3.73978 22.7384 4.10932C23.2071 4.47886 23.481 4.98856 23.5 5.52651V19.3228C23.481 19.8607 23.2071 20.3704 22.7384 20.74C22.2697 21.1095 21.6445 21.3087 21 21.2938H3.5C2.85552 21.3087 2.23029 21.1095 1.76158 20.74C1.29286 20.3704 1.01896 19.8607 1 19.3228V5.52651C1.01896 4.98856 1.29286 4.47886 1.76158 4.10932C2.23029 3.73978 2.85552 3.54058 3.5 3.55547V3.55547Z"
              stroke="#1E264C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.6641 1.29688V5.64467"
              stroke="#1E264C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.25 1.29688V5.64467"
              stroke="#1E264C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 8.77344H23.5"
              stroke="#1E264C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <span v-if="labelAfterInput" class="ml-2">{{ labelAfterInput }}</span>
    </label>
    <div v-for="(value, rule) in sortedValidationRules" :key="rule">
      <div
        v-if="validationErrors[rule].$invalid"
        class="error text-error-900"
        :class="{ hidden: !validationErrors.$error }"
      >
        {{ validationMessage[rule] }}
      </div>
    </div>
  </div>
</template>

<script>
import nestedModelComponent from "../../../mixins/nestedModelComponent";
import validationMessages from "../../../mixins/validationMessages";
import { isDateSupported } from "@/mixins/helpers";
import { format, isValid, parse } from "date-fns";

export default {
  mixins: [nestedModelComponent, validationMessages],
  props: {
    labelledby: {
      type: String,
      default: "",
    },
    labelBeforeInput: {
      type: String,
      default: "",
    },
    labelAfterInput: {
      type: String,
      default: "",
    },
    valueFromDatabase: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "flex items-center text-gray-600",
    },
    spanClass: {
      type: String,
      default: "mr-2",
    },
    maxYearsOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      inputValue: "",
      currentDate: new Date(),
    };
  },
  computed: {
    setStyleClass() {
      let classes = "form-input min-h-10";
      if (this.customClass) {
        classes = this.customClass;
      }

      if (this.validationErrors.$error) {
        classes = classes + " bg-error-100";
      }

      return classes;
    },
    maxDate() {
      if (
        this.maxYearsOffset &&
        typeof this.maxYearsOffset === "number" &&
        this.maxYearsOffset > 0
      ) {
        const maxYear = this.currentDate.getFullYear() + this.maxYearsOffset;
        const maxDate = new Date(maxYear, 11, 31).toISOString().split("T")[0];
        return maxDate;
      }
      return "";
    },
    minDate() {
      const minDate = this.currentDate.toISOString().split("T")[0];
      return minDate;
    },
  },
  watch: {
    inputValue: function (newVal) {
      let formatDate = "";

      if (isDateSupported()) {
        formatDate = newVal;
      } else {
        if (isValid(parse(newVal, "MM-dd-yyyy", new Date()))) {
          formatDate = format(
            parse(newVal, "MM-dd-yyyy", new Date()),
            "yyyy-MM-dd"
          );
        }
      }

      this.$emit("input", formatDate);
    },
    valueFromDatabase: function (newVal) {
      //the value for the input must be in the following format to function properly 'yyyy-MM-dd' or and empty string
      this.setDateFromDatabase(newVal);
    },
  },
  created() {
    if (this.valueFromDatabase) {
      //the value for the input must be in the following format to function properly 'yyyy-MM-dd' or and empty string
      this.setDateFromDatabase(this.valueFromDatabase);
    }
  },
  methods: {
    setDateFromDatabase(date) {
      if (date) {
        this.inputValue = isDateSupported()
          ? date
          : format(parse(date, "yyyy-MM-dd", new Date()), "MM-dd-yyyy");
      } else {
        //clear the input field if no date
        this.inputValue = "";
      }
    },
  },
};
</script>
