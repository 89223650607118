import {PROGRAM_SESSION_TERMS_DISPLAY_MAP} from "@/components/program-manager/sessions/constants";
import {buildStringDateRangeSessionTravel} from "@/mixins/helpers";
import {differenceInDays} from "date-fns";
import { DURATION_IN_DAYS_THRESHOLD, SESSION_TYPE } from "@/constants";

export const getRandomizedTags = (session, randomTags, horizontal) => {
  let tags = [];
  if (session?.tags) {
    tags = [...session.tags];
  }
  if (randomTags) {
    tags.sort(() => Math.random() - 0.5);
  }

  return horizontal ? tags.slice(0, 3) : tags;
};

export const getProgramLocationTerm = (session) => {
  const arrivalCity =
    session?.session_travel_details[0]?.arrival_city;
  if (arrivalCity) {
    return `${arrivalCity.city_ascii}, ${arrivalCity.country.name}`;
  }
  return "";
};

export const getSessionTerms = (session) => {
  return session?.terms?.map((term) => term.name) ?? [];
};

export const getDisplayNameForTerms = (sessionTerms) => {
  const normalizedTerms = [...sessionTerms].sort();
  const termKey = normalizedTerms.join(" + ");

  return (
    PROGRAM_SESSION_TERMS_DISPLAY_MAP[termKey] || normalizedTerms.join(", ")
  );
};

export const getProgramSemesterYear = (program) => {
  let term = "";
  if (program?.semester?.length > 0 && props?.program?.year > 0) {
    term = [program.semester, program.year].join(", ");
  }
  const year = program?.session?.academic_year;
  return ` | ${term} ${(year ? " " + year : "")}`
};

export const getHostUniversities = (session) => {
  let hosts = "";
  if (session?.session_hosting_institutions?.length > 0) {
    hosts = session?.session_hosting_institutions
      .map((obj) => obj.entity.name)
      .join(", ");
  }
  return hosts;
};

export const getFormattedDuration = (session) => {
  const durationInDays =
    session.session_types.length === 1 &&
    session.session_types[0].name === SESSION_TYPE.internship.name
      ? differenceInDays(
          session?.academic_end_date,
          session?.academic_start_date
        )
      : session?.session_travel_details[0]?.duration;
  if (durationInDays) {
    if (durationInDays < DURATION_IN_DAYS_THRESHOLD) {
      const days = `day${durationInDays > 1 ? "s" : ""}`;
      return `(${durationInDays} ${days})`;
    } else {
      const durationInWeeks = Math.ceil(durationInDays / 7);
      const weeks = `week${durationInWeeks > 1 ? "s" : ""}`;
      return `(${durationInWeeks} ${weeks})`;
    }
  }
  return undefined;
};


export const getProgramDates = (session) => {
    return buildStringDateRangeSessionTravel(
      session?.session_travel_details
    )
};

export const getIsStartDateFinal = (session) => {
  return session?.session_travel_details?.[0]?.arrival_date_is_final > 0
};

export const getIsEndDateFinal = (session) => {
  return session?.session_travel_details?.[0]?.departure_date_is_final > 0
};

export const durationString = (duration) => {
  let str = String(duration);
  if (!str.includes("week") && !str.includes("day")) {
    str += " weeks";
  }
  return str;
};

