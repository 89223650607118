<template>
  <fieldset>
    <legend>
      Do you regularly take any medications for a physical or mental health
      condition?
    </legend>
    <RadioButton
      name="medications"
      aria-controls="medications"
      :options="['yes', 'no']"
      :selected-option="selectedOption"
      :validation-errors="v$.value.selectedRadio"
      @input="onFieldChange('selectedRadio', $event)"
    />

    <div
      v-if="value.selectedRadio === 'yes'"
      id="medications"
      :aria-expanded="value.selectedRadio === 'yes'"
    >
      <div class="grid grid-cols-1 gap-y-8 ml-6">
        <p>
          If yes, List the medication(s) and the condition(s) they are treating.
        </p>
        <p>
          If you are taking prescription medications, please review
          <a
            href="https://downloads.apistudyabroad.com/Traveling_Abroad_With_Medications.pdf"
            target="_blank"
            >API’s guidelines and advice.</a
          >
          Also, we advise you to speak with your doctor(s) regarding medication
          safety and any planning that needs to take place prior to departure.
        </p>
        <ApiTextArea
          name="medications-textarea"
          :max-length="500"
          :typed-text="typedText"
          :validation-errors="v$.value.textarea"
          @textUpdate="onFieldChange('textarea', $event)"
        />
        <p class="italic">
          Note: Participants currently taking prescription medication should
          review the guidelines and advice
          <a
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            href="/files/Traveling_Abroad_With_Medications.pdf"
            target="_blank"
            >here</a
          >. Participants are advised to speak with their doctor(s) regarding
          their medications and any necessary arrangements that need to be made
          prior to departure.
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
import formInputs from "../../mixins/formInputs";
import RadioButton from "../forms/SharedComponents/RadioButton";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea";
import { required, requiredIf, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "MentalHealth",
  components: { RadioButton, ApiTextArea },
  mixins: [formInputs],
  props: {
    medications: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:medications"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        selectedRadio: "",
        textarea: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:medications", this.value);
      },
      deep: true,
      immediate: true,
    },
  },

  validations: {
    value: {
      selectedRadio: {
        required,
      },
      textarea: {
        required: requiredIf(function () {
          return this.value.selectedRadio === "yes";
        }),
        maxLength: maxLength(500),
      },
    },
  },
};
</script>
