<script setup>
import { useToggle } from "@vueuse/core";
import Sidebar from "./Sidebar.vue";
import SidebarReturnMobile from "./SidebarReturnMobile.vue";

const [value, toggle] = useToggle(true);
</script>

<template>
  <div class="flex items-stretch">
    <div class="flex w-full md:hidden">
      <Sidebar :is-mobile="true" :is-opened="value" @toggle-sidebar="toggle" />
      <div v-if="!value">
        <SidebarReturnMobile @toggle-sidebar="toggle" description="Menu" />
        <router-view></router-view>
      </div>
    </div>
    <div class="hidden md:flex md:w-full">
      <Sidebar :is-opened="true" />
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
</template>
