<template>
  <div
    class="w-full max-w-351p border-light-gray card-box-shadow flex flex-col h-[550px]"
    :data-testid="`room-card-${testId}`"
  >
    <div
      class="text-indigo-base py-4 px-5 flex items-stretch"
      :class="secondaryColor ? `bg-gray-275` : `bg-teal-accent`"
    >
      <p class="text-sm font-bold uppercase">
        {{ cardTitle }}
      </p>
    </div>

    <div class="p-5 text-indigo-base">
      <p
        v-if="showPrice"
        :class="`${secondaryColorClass} font-bold uppercase text-15p mb-4`"
      >
        {{ price }}
      </p>

      <p class="text-sm mb-6">{{ bedBathAmenities?.join("  |  ") }}</p>
      <ItemsList
        class="mb-6"
        title="Amenities"
        title-class="uppercase"
        :items="amenities"
      />
    </div>

    <button
      v-if="props.showRequestButton"
      class="mt-auto border-2 p-4 uppercase font-bold flex items-center justify-center m-5 relative"
      :data-testid="`room-select-button-${testId}`"
      :class="props.selected ? `${secondaryColorClassBG} text-white` : `${secondaryColorClassOutlinedNoHover}`"
      @click="setRoom(props.room?.id)"
    >
      <div class="flex items-center justify-center">
        <span class="inline-block">
          {{ unitSelection }}
        </span>
        <div class="inline-block ml-3 py-1">
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -1.01583e-06L-7.14641e-07 1.71183L5.38866 7.00874Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <div
        v-if="props.selected && !disabledDeselect"
        class="absolute -top-3 -right-3"
      >
        <MinusSignCircle />
      </div>
    </button>
  </div>
</template>

<script setup>
import { computed, defineProps, inject } from "vue";
import ItemsList from "@/components/Configurator/Housing/ItemsList.vue";
import MinusSignCircle from "../widgets/MinusSignCircle.vue";

const setRoom = inject("setRoom");
const disabledDeselect = inject("disabledDeselect");
const { configuratorOptions } = inject("configuratorOptions");
const props = defineProps({
  room: {
    type: Object,
    default: () => {},
  },
  unitType: {
    type: String,
    default: "",
  },
  defaultRoomPrice: {
    type: Number,
    default: 0,
  },
  roomPrice: {
    type: Number,
    default: 0,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  showRequestButton: {
    type: Boolean,
    default: true,
  },
  showPrice: {
    type: Boolean,
    default: true,
  },
  testId: {
    type: Number,
    default: 0,
  },
});

const capacity = computed(() => props.room?.capacity ?? 0);
const cardTitle = computed(() => {
  if (props.unitType === "Studio") return "Studio Room";
  else if (capacity.value > 1) return "Shared Room";
  else return "Private Room";
});

const price = computed(() => {
    const rooms = configuratorOptions.rooms.filter((r) => r.productId === props.room?.id);
    if (rooms && rooms.length > 0) {
      return rooms[0].roomPriceLabel()
    }
    return "";
});

const amenities = computed(
  () => props.room?.room_amenities?.map((item) => item.title) ?? []
);
const bedBathAmenities = computed(() => {
  const bedIds = [127, 128, 129, 162];
  let amenities =
    props.room?.room_amenities
      ?.filter((item) => bedIds.includes(item.amenity_id))
      .map((item) => {
        return `${item.quantity} ${item?.title}`;
      }) ?? [];

  if (props.room?.bathroom_type) {
    amenities.push(`${props.room.bathroom_type} Bathroom`);
  }

  return amenities;
});

const unitSelection = computed(() => {
  return props.selected ? "Selected" : "Select Room";
});
</script>
