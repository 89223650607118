import { ref, computed, watch } from "vue";
import { useIntersectionObserver, useResizeObserver } from "@vueuse/core";
import { debounce } from "lodash";

/**
 * A composable that manages dynamic height adjustments for elements when they come into or go out of view.
 * It supports both single and multiple elements (sections).
 *
 * @param {HTMLElement|HTMLElement[]} target - The element or an array of elements whose height will be observed.
 * @param {HTMLElement} wrapper - The wrapper element used for intersection observation.
 * @param {number} [offset=0] - An optional offset value added to the computed total height.
 * @param {number} [debounceTime=100] - The debounce time in milliseconds for the ResizeObserver and IntersectionObserver.
 * @returns {Object} Reactive properties and methods to manage dynamic heights.
 * @returns {Ref<boolean>} targetIsVisible - Indicates if the target or wrapper is currently visible in the viewport.
 * @returns {ComputedRef<string|null>} inViewHeight - The computed height for the target element(s) when in view.
 * @returns {Ref<number|null>} currentHeight - The height of the target element(s) when out of view.
 * @returns {Ref<number|null>} newContentHeight - The height of the target element(s) when in view and observed by ResizeObserver.
 */
export function useDynamicHeight(
  target,
  wrapper,
  offset = 0,
  debounceTime = 100
) {
  const targetIsVisible = ref(false);
  const currentHeight = ref(null);
  const newContentHeight = ref(null);
  const sectionHeights = Array.isArray(target) ? ref([]) : null;

  // Debounced Intersection Observer
  const debouncedIntersectionCallback = debounce(([{ isIntersecting }]) => {
    targetIsVisible.value = isIntersecting;
    if (isIntersecting) {
      if (sectionHeights && sumMenuSectionsHeight.value) {
        // Update currentHeight with the sum of sections' heights
        currentHeight.value = sumMenuSectionsHeight.value;
      } else {
        // Update currentHeight with the new height of a single section
        const newHeight = newContentHeight.value;
        if (newHeight && newHeight !== currentHeight.value) {
          currentHeight.value = newHeight;
        }
      }
    }
  }, debounceTime);

  useIntersectionObserver(wrapper, debouncedIntersectionCallback);

  // Debounced Resize Observer for single section
  const debouncedResizeCallback = debounce((entries) => {
    const entry = entries[0];
    const { height } = entry.contentRect;

    if (!currentHeight.value) {
      currentHeight.value = height;
    }
    newContentHeight.value = height;
  }, debounceTime);

  // Resize Observer with Debouncing
  if (Array.isArray(target)) {
    // Handle multiple sections
    target.forEach((t, index) => {
      useResizeObserver(
        t,
        debounce((entries) => {
          const entry = entries[0];
          const { height } = entry.contentRect;

          if (!currentHeight.value) {
            currentHeight.value = height;
          }
          sectionHeights.value[index] = height;
        }, debounceTime)
      );
    });
  } else {
    // Handle a single section
    useResizeObserver(target, debouncedResizeCallback);
  }

  // Computed for total height of multiple sections
  const sumMenuSectionsHeight = computed(() => {
    if (!sectionHeights) return undefined;
    const totalHeight = sectionHeights.value.reduce((acc, h) => acc + h, 0);
    return totalHeight ? Math.ceil(totalHeight + offset) : undefined;
  });

  // Computed for in-view height
  const inViewHeight = computed(() => {
    if (window.innerWidth >= 760) return "auto";

    if (!targetIsVisible.value) {
      return currentHeight.value ? `${currentHeight.value}px` : "auto";
    }

    // For multiple sections, return the sum of their heights
    if (sectionHeights) {
      return sumMenuSectionsHeight.value
        ? `${sumMenuSectionsHeight.value}px`
        : undefined;
    }

    // For a single section, return the new content height
    return newContentHeight.value ? `${newContentHeight.value}px` : undefined;
  });

  return {
    targetIsVisible,
    inViewHeight,
    currentHeight,
    newContentHeight,
  };
}
