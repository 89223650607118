<template>
  <div>
    <div
      v-if="items.length"
      class="border-solid rounded-md border border-blue-400 px-4 mb-4 pt-4"
    >
      <label
        v-for="(item, index) in items"
        :key="item.name + index"
        class="flex items-center justify-between text-gray-600 text-base font-semibold pb-3"
      >
        <span :data-cy="'payment-item-label-' + index">
          {{ item.name }}
        </span>
        <span
          :data-cy="'payment-item-total-label-' + index"
          class="ml-4 text-gray-650"
        >
          ${{ formatNumber(item.balance) }} USD
        </span>
      </label>
      <label
        v-if="convenienceFee"
        class="flex items-center justify-between text-gray-600 text-base font-semibold pb-3"
      >
        <span data-cy="convenience-fee-label">Convenience Fee *</span>
        <span data-cy="convenience-fee-total-label" class="ml-4 text-gray-650">
          ${{ formatNumber(convenienceFee) }} USD
        </span>
      </label>
      <label
        v-if="achFee"
        class="flex items-center justify-between text-gray-600 text-base font-semibold pb-3"
      >
        <span data-cy="ach-fee-label">ACH Fee *</span>
        <span data-cy="ach-fee-total-label" class="ml-4 text-gray-650">
          ${{ formatNumber(achFee) }} USD
        </span>
      </label>
    </div>

    <div
      v-if="paymentMethod"
      class="border-solid rounded-md border border-blue-400 px-4 mb-4 pt-4"
    >
      <label
        class="flex items-center justify-between text-gray-600 text-base font-semibold pb-3"
      >
        <span> Payment Method </span>
        <span data-cy="payment-method-label" class="ml-4 text-gray-650">
          {{ paymentMethod }}
        </span>
      </label>
    </div>

    <div class="border-solid rounded-md border border-blue-400 p-4">
      <label
        class="flex items-center justify-between text-gray-650 text-xl font-bold"
      >
        <span>Total</span>
        <span data-cy="total-payment-label" class="ml-4">
          ${{ formatNumber(total) }} USD
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { PAYMENT_TYPES } from "@/constants";
import { toFixed } from "@/mixins/helpers";

export default {
  name: "PaymentDetails",
  props: {
    items: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
    convenienceFee: {
      type: Number,
      default: 0,
    },
    achFee: {
      type: Number,
      default: 0,
    },
    itemsTotal: {
      type: Number,
      default: 0,
    },
    paymentType: {
      type: String,
      default: "",
    },
  },
  computed: {
    total() {
      return this.achFee > 0
        ? Number(this.itemsTotal) + Number(this.achFee)
        : Number(this.itemsTotal) + Number(this.convenienceFee);
    },
    paymentMethod() {
      switch (this.paymentType) {
        case PAYMENT_TYPES.US_CREDIT_CARD.value:
          return PAYMENT_TYPES.US_CREDIT_CARD.name;
        case PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.value:
          return PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.name;
        case PAYMENT_TYPES.BANK_ACCOUNT.value:
          return PAYMENT_TYPES.BANK_ACCOUNT.name;
        default:
          return "";
      }
    },
  },
  methods: {
    formatNumber(number) {
      return Number(toFixed(number / 100)).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>
