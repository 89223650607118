<template>
  <div>
    <div class="text-gray-600">
      <div class="space-y-5">
        <div class="flex items-start">
          <input
            id="default"
            v-model="selectedOption"
            value="default"
            aria-describedby="small-description"
            name="color"
            type="radio"
            checked
            class="h-4 w-4 text-university-primary form-radio"
          />
          <label
            for="default"
            class="ml-3 block text-sm font-medium text-gray-700"
            >APIConnect Default</label
          >
        </div>
        <span class="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            class="relative inline-flex items-center rounded-l-sm bg-university-secondary px-4 py-3"
          ></button>
          <button
            type="button"
            class="relative -ml-px inline-flex items-center bg-blue-900 px-4 py-3"
          ></button>
          <button
            type="button"
            class="relative -ml-px inline-flex items-center rounded-r-sm bg-university-primary px-4 py-3"
          ></button>
        </span>

        <div class="flex items-start">
          <input
            id="custom"
            v-model="selectedOption"
            value="custom"
            aria-describedby="medium-description"
            name="color"
            type="radio"
            class="h-4 w-4 text-university-primary form-radio"
          />
          <label
            for="custom"
            class="ml-3 block text-sm font-medium text-gray-700"
            >Custom</label
          >
        </div>

        <div
          v-if="selectedOption === 'custom'"
          class="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4 mb-11"
        >
          <div class="mb-4">
            <label class="text-sm font-semibold text-gray-700">Primary</label>
            <div class="color-picker absolute h-8 flex rounded-md shadow-sm">
              <div
                :class="{
                  'border-error-900': !isPrimaryValid,
                  'border-gray-200': isPrimaryValid,
                }"
                class="flex items-center pl-2 pr-2 font-medium text-gray-900 bg-transparent rounded-l border relative border-r-0"
              >
                <color-input
                  @change="getPrimaryColor"
                  v-model="primary"
                  position="top"
                />
              </div>
              <input
                v-model="primary"
                class="py-0 outline-0 px-4 w-24 text-sm font-light text-gray-900 bg-transparent rounded-r border uppercase border-l-0"
                type="text"
                :maxlength="maxLength"
                :class="{
                  'border-error-900 focus:border-error-900 focus:ring-error-900 active:border-error-900 text-error-900 focus:outline-none': !isPrimaryValid,
                  'border-gray-200': isPrimaryValid,
                }"
                @input="getPrimaryColor"
              />
            </div>
            <p v-if="!isPrimaryValid" class="mt-8 text-error-900 text-xs">
              {{ validationError }}
            </p>
          </div>

          <div class="mb-4">
            <label class="text-sm font-semibold text-gray-700">Secondary</label>
            <div class="color-picker absolute h-8 flex rounded-md shadow-sm">
              <div
                :class="{
                  'border-error-900': !isSecondaryValid,
                  'border-gray-200': isSecondaryValid,
                }"
                class="flex items-center pl-2 pr-2 font-medium text-gray-900 bg-transparent rounded-l border border-gray-200 relative border-r-0"
              >
                <color-input
                  @change="getSecondaryColor"
                  v-model="secondary"
                  position="top"
                  format="hex string"
                />
              </div>
              <input
                v-model="secondary"
                class="py-0 outline-0 px-4 w-24 text-sm font-light text-gray-900 bg-transparent rounded-r border border-gray-200 uppercase border-l-0"
                type="text"
                :maxlength="maxLength"
                :class="{
                  'border-error-900 focus:border-error-900 focus:ring-error-900 active:border-error-900 text-error-900 focus:outline-none': !isSecondaryValid,
                  'border-gray-200': isSecondaryValid,
                }"
                @input="getSecondaryColor"
              />
            </div>
            <p v-if="!isSecondaryValid" class="mt-8 text-error-900 text-xs">
              {{ validationError }}
            </p>
          </div>

          <div class="mb-4">
            <label class="text-sm font-semibold text-gray-700">Accent</label>
            <div class="color-picker absolute h-8 flex rounded-md shadow-sm">
              <div
                :class="{
                  'border-error-900': !isAccentValid,
                  'border-gray-200': isAccentValid,
                }"
                class="flex items-center pl-2 pr-2 font-medium text-gray-900 bg-transparent rounded-l border border-gray-200 relative border-r-0"
              >
                <color-input
                  @change="getAccentColor"
                  v-model="accent"
                  position="top"
                  format="hex string"
                />
              </div>
              <input
                v-model="accent"
                class="py-0 outline-0 px-4 w-24 text-sm font-light text-gray-900 bg-transparent rounded-r border border-gray-200 uppercase border-l-0"
                type="text"
                :maxlength="maxLength"
                :class="{
                  'border-error-900 focus:border-error-900 focus:ring-error-900 active:border-error-900 text-error-900 focus:outline-none': !isAccentValid,
                  'border-gray-200': isAccentValid,
                }"
                @input="getAccentColor"
              />
            </div>
            <p v-if="!isAccentValid" class="mt-8 text-error-900 text-xs">
              {{ validationError }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorInput from "vue-color-input";

export default {
  name: "BrandingColour",
  components: {
    "color-input": ColorInput,
  },
  emits: [
    "branding-primary",
    "branding-secondary",
    "branding-accent",
    "selected-option",
    "invalid-primary",
    "invalid-secondary",
    "invalid-accent",
  ],
  props: {
    defaultPrimary: { type: String },
    defaultSecondary: { type: String },
    defaultAccent: { type: String },
  },
  //["defaultPrimary","defaultSecondary","defaultAccent"],
  data() {
    return {
      maxLength: 7,
      primary: "#043544",
      secondary: "#009999",
      accent: "#42bfb3",
      selectedOption: this.value,
      isPrimaryValid: true,
      isSecondaryValid: true,
      isAccentValid: true,
      validationError: "",
    };
  },
  watch: {
    defaultPrimary(newValue, oldValue) {
      this.primary = newValue;
    },
    defaultSecondary(newValue, oldValue) {
      this.secondary = newValue;
    },
    defaultAccent(newValue, oldValue) {
      this.accent = newValue;
    },
    selectedOption(newValue, oldValue) {
      this.$emit("selected-option", newValue);
    },
  },
  methods: {
    setColorDefaults() {
      this.primary = this.defaultPrimary;
      this.secondary = this.defaultSecondary;
      this.accent = this.defaultAccent;
    },
    emitForDefaultValues() {
      this.$emit("selected-option", this.selectedOption);
    },
    //need to basically validate for rgb/rgba values and convert to hex before running this on the value?
    isValidRgbOrRgba(value) {
      const regex = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(,\s*(0|1|0?\.\d+)\s*)?\)$/;
      const match = value.match(regex);
      if (!match) {
        return false;
      }
      const r = parseInt(match[1], 10);
      const g = parseInt(match[2], 10);
      const b = parseInt(match[3], 10);
      if (r > 255 || g > 255 || b > 255) {
        return false;
      }
      if (match[4]) {
        const a = parseFloat(match[5]);
        if (a < 0 || a > 1) {
          return false;
        }
      }
      return true;
    },
    rgbToHex(rgb) {
      const regex = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(,\s*(0|1|0?\.\d+)\s*)?\)$/;
      const match = rgb.match(regex);
      if (!match) {
        return null; // Invalid input
      }
      const r = parseInt(match[1], 10);
      const g = parseInt(match[2], 10);
      const b = parseInt(match[3], 10);
      const toHex = (num) => num.toString(16).padStart(2, "0");
      const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`;
      if (match[4]) {
        const a = parseFloat(match[5]);
        const alphaHex = toHex(Math.round(a * 255));
        return `${hex}${alphaHex}`;
      }
      return hex;
    },

    validateColor(color) {
      // Match hex color codes with 6 or 8 digits
      const regex = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/;
      if (color && !regex.test(color)) {
        this.validationError = "Please enter a valid hex color code";
        return false;
      } else {
        this.validationError = "";
        return true;
      }
    },
    getPrimaryColor() {
      if (this.isValidRgbOrRgba(this.primary)) {
        this.primary = this.rgbToHex(this.primary);
      }
      if (!this.validateColor(this.primary)) {
        this.isPrimaryValid = false;
        this.$emit("invalid-primary", true);
      } else {
        this.$emit("branding-primary", this.primary);
        this.$emit("invalid-primary", false);
        this.isPrimaryValid = true;
      }
    },
    getSecondaryColor() {
      if (this.isValidRgbOrRgba(this.secondary)) {
        this.secondary = this.rgbToHex(this.secondary);
      }
      if (!this.validateColor(this.secondary)) {
        this.isSecondaryValid = false;
        this.$emit("invalid-secondary", true);
      } else {
        this.$emit("branding-secondary", this.secondary);
        this.$emit("invalid-secondary", false);
        this.isSecondaryValid = true;
      }
    },
    getAccentColor() {
      if (this.isValidRgbOrRgba(this.accent)) {
        this.accent = this.rgbToHex(this.accent);
      }
      if (!this.validateColor(this.accent)) {
        this.isAccentValid = false;
        this.$emit("invalid-accent", true);
      } else {
        this.$emit("branding-accent", this.accent);
        this.$emit("invalid-accent", false);
        this.isAccentValid = true;
      }
    },
  },
};
</script>

<style>
.color-picker.active {
  display: flex !important;
}

.color-text {
  height: 32px;
  width: 90px;
  padding: 0;
  border: none;
  background: transparent;
  border-right: 1px solid #e5e7eb;
}

.color-text:focus {
  outline: none;
}

.color-input {
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.color-input .box {
  width: 100%;
  height: 100%;
}
</style>
