<template>
  <div>
    <div>
      <!-- Original component design -->
      <template v-if="templateName === 'original'">
        <div class="relative rounded-md shadow-sm">
          <input
            id="autocomplete"
            ref="input"
            type="text"
            v-bind="$attrs"
            :defaultValue="initialAddress"
            class="block form-input w-full min-h-10"
            :class="v$.value?.addressInfo.country.$errors.length && errorClass"
            :placeholder="customPlaceholder"
            @blur="onBlur"
            @keyup.enter="validate"
          />
          <slot name="error"></slot>
        </div>
      </template>

      <!-- Tailored for V3 -->
      <template v-else-if="templateName === 'v3'">
        <label
          v-if="label"
          :for="id"
          class="block text-sm text-indigo-base font-bold"
        >
          <span class="font-medium flex justify-between mb-1">
            {{ label }}
          </span>
        </label>
        <div>
          <input
            :id="id"
            ref="input"
            v-bind="$attrs"
            :defaultValue="initialAddress"
            :data-cy="id + 'Text'"
            type="text"
            class="block outline-none w-full border-0 p-4 ring-1 ring-inset focus:ring-2 focus:ring-inset appearance-none"
            :disabled="disableInput"
            :class="
              vuelidateField?.$error
                ? 'ring-error-900 focus:ring-error-900'
                : 'ring-indigo-base focus:ring-indigo-base'
            "
            :placeholder="customPlaceholder"
            @input="$emit('update:modelValue', $event.target.value)"
            @blur="updateAddressInput"
            @keyup.enter="validate"
          />
        </div>
      </template>
    </div>
    <div class="w-full" :class="templateName === 'v3' ? '' : 'h-4'">
      <div
        v-show="
          v$.value?.addressInfo.country.$errors.length ||
          vuelidateField?.$errors
        "
        class="error text-sm text-error-900 mt-2"
      >
        <!-- Alters how errors are shown -->
        <template v-if="templateName === 'original'">
          {{ customErrorCopy }}
        </template>
        <template v-else-if="templateName === 'v3'">
          <p
            v-for="error of vuelidateField?.$errors"
            :key="error.$uid"
            class="error text-error-900 text-sm font-normal"
          >
            {{ error.$message }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import _ from "lodash";
export default {
  // Uses the Google Places API to auto-complete an address
  name: "AddressComponent",
  props: {
    initialAddress: {
      type: String,
      default: "",
    },
    dontBeNull: { type: Boolean, default: false },
    initialAddressInfo: {
      type: Object,
      default: () => {},
    },
    customClass: {
      type: String,
      default: "",
    },
    errorClass: {
      type: String,
      default: "bg-error-100",
    },
    customPlaceholder: {
      type: String,
      default: "",
    },
    customErrorCopy: {
      type: String,
      default: "This field is required.",
    },
    modelValue: {
      type: [String, Object],
      default: "",
    },
    autoCompleteTypes: {
      type: Array,
      default: () => ["address"],
    },
    autoValidation: {
      type: Boolean,
      default: true,
    },
    vuelidateField: {
      type: Object,
      default: () => {},
      // Only for V3; Validates field against an external vuelidate object
    },
    id: {
      type: String,
      default: "",
      // Part of V3 design; sets an id so input can be focused when clicking the label.
    },
    label: {
      type: String,
      default: "",
      // Part of V3 design; the text that will be displayed as label above the input.
    },
    templateName: {
      type: String,
      default: "original",
      /*
        templateName options:
        1. original = Will use UI as component was originally created
        2. v3 = Adds label and different classes to input
      */
    },
    disableInput: {
      type: Boolean,
      default: false,
      // Wether or not the input will be disabled, only for V3
    },
  },
  emits: ["updateAddressInfo", "update:modelValue"],
  data() {
    return {
      v$: useValidate(),
      value: {
        addressInfo: _.defaults(this.initialAddressInfo, {
          street_address_1: null,
          street_address_2: null,
          number: null,
          city: null,
          state: null,
          country: null,
          postal_code: null,
          lat: null,
          long: null,
          iso: null,
          utc_offset_minutes: null,
          administrative_area_level_2: null,
        }),
      },
      address: "",
      boundAddress: this.initialAddress,
      cast: {
        premise: "street_address_1",//keep first so that if route exists it will override premise
        route: "street_address_1",
        subpremise: "street_address_2",
        street_number: "number",
        postal_code: "postal_code",
        country: "country",
        locality: "city",
        administrative_area_level_1: "state",
        neighborhood: "neighborhood",
        administrative_area_level_2: "administrative_area_level_2",
      },
      autocomplete: this.$props.dontBeNull ? { value: "" } : null,
    };
  },
  computed: {
    rawData() {
      return { ...this.value.addressInfo };
    },
  },
  watch: {
    address: {
      handler: function (val, addressStr) {
        if (
          !val ||
          !Object.prototype.hasOwnProperty.call(val, "address_components")
        ) {
          this.resetAddressInfo();
        } else {
          this.mapAttributes(val);
          this.boundAddress = addressStr;
        }
        this.validate();
        this.sendToVuex();
        this.$emit("updateAddressInfo", this.rawData);
        this.$emit("update:modelValue", this.rawData);
      },
      deep: true,
    },
    initialAddressInfo: {
      handler: function (newVal) {
        this.loadInitialAddress(newVal);
      },
      deep: true,
    },
  },
  validations: {
    value: {
      addressInfo: {
        country: {
          requiredField: requiredIf(function () {
            return this.templateName === "original";
          }),
        },
      },
    },
  },
  mounted() {
    this.initGooglePlaces();
    this.loadInitialAddress(this.initialAddressInfo);
  },
  methods: {
    updateAddressInput(val) {
      this.onBlur(val);
      this.vuelidateField?.$touch();
    },
    loadInitialAddress(initialAddressInfo) {
      if (!initialAddressInfo) return "";
      this.autocomplete.value =
        [
          initialAddressInfo.street_address_1,
          initialAddressInfo.number,
          initialAddressInfo.city,
          initialAddressInfo.state,
          initialAddressInfo.country,
        ].reduce((acc, val) => {
          const sep = acc ? `${acc}, ` : "";
          return val ? `${sep}${val}` : acc;
        }) || "";
    },
    initGooglePlaces() {
      this.autocomplete = this.$refs["input"];
      const autocomplete = new google.maps.places.Autocomplete(
        this.$refs["input"],
        {
          types: this.autoCompleteTypes,
          fields: [
            "address_component",
            "geometry.location",
            "utc_offset_minutes",
          ],
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.resetAddressInfo();
        this.address = autocomplete.getPlace() || "";
      });
    },
    onBlur(val) {
      const inputAddress = val.target.value;
      if (!inputAddress) {
        this.address = undefined;
        this.resetAddressInfo();
      } else if (
        this.autocomplete.value !== inputAddress &&
        this.initialAddress !== inputAddress
      ) {
        this.address = inputAddress;
      }
      this.validate();
    },
    validate() {
      if (!this.autoValidation) return;
      this.v$.$touch();
    },
    sendToVuex() {
      if (
        this.value.addressInfo.stree_address_1 &&
        !this.v$.value.addressInfo.$error
      ) {
        this.$store.commit("setAddressInfo", this.rawData);
      } else {
        this.$store.commit("setAddressInfo", {});
      }
    },
    resetAddressInfo() {
      this.value.addressInfo = {
        street_address_1: undefined,
        street_address_2: undefined,
        number: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
        postal_code: undefined,
        lat: undefined,
        long: undefined,
        utc_offset_minutes: undefined,
        iso: undefined,
        administrative_area_level_2: undefined,
      };
    },
    mapAttributes(attributes) {
      this.value.addressInfo.lat = attributes?.geometry?.location?.lat() ?? "";
      this.value.addressInfo.long = attributes?.geometry?.location?.lng() ?? "";
      this.value.addressInfo.utc_offset_minutes =
        attributes?.utc_offset_minutes ?? undefined;
      attributes.address_components.forEach((component) => {
        component.types.forEach((type) => {
          if (Object.prototype.hasOwnProperty.call(this.cast, type)) {
            this.value.addressInfo[this.cast[type]] = component.long_name;
          }
          if (
            type === "country" &&
            Object.prototype.hasOwnProperty.call(this.cast, type)
          ) {
            this.value.addressInfo.iso = component.short_name;
          }
        });
      });

      // Alternative case, if use `(cities)` as `autoCompleteTypes`
      // We may not receive city as administrative_area_level_2: ex. Buenos Aires
      if (!this.value.addressInfo.city) {
        attributes.address_components.forEach((component) => {
          if (component.types.includes("locality")) {
            this.value.addressInfo.city = component.long_name;
          } else if (component.types.includes("postal_town")) {
            this.value.addressInfo.city = component.long_name;
          }
        });
      }
    },
  },
};
</script>
