<template>
  <div>
    <div class="modal-background"></div>
    <div class="modal-class">
      <div
        :id="id + '_model'"
        class="modal-content"
        role="dialog"
        aria-modal="true"
        :aria-labelledby="id + '-modal'"
      >
        <slot name="modal-header">
          <div class="flex justify-end w-full">
            <button
              id="add-prospect-label"
              class="block h-6 w-6 flex items-center justify-center"
              @click="$emit('closeModal')"
            >
              <span class="sr-only">Close</span>
              <i class="mdi mdi-close"></i>
            </button>
          </div>
          <!-- Logo & Title -->
          <div class="flex flex-col items-center mb-10">
            <img class="h-20" :src="logo" />
            <h2
              class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
            >
              {{ title }}
            </h2>
          </div>
        </slot>
        <slot name="modal-content" />

        <slot name="modal-footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    id: {
      type: String,
      default: "modal",
      required: true,
    },
    title: {
      type: String,
      default: "Modal Title",
    },
  },
  emits: ["closeModal"],
  computed: {
    logo() {
      return localStorage.logoUrl;
    },
  },
};
</script>

<style scoped>
.modal-background {
  @apply fixed inset-0 z-0 hidden;
  @apply bg-black opacity-33;
}
.modal-class {
  @apply fixed inset-0 z-50 py-6 overflow-auto;
  @apply bg-white;
}
.modal-content {
  @apply max-w-4xl px-6 mx-auto;
  @apply bg-white;
}

@screen sm {
  .modal-background {
    @apply block;
  }
  .modal-class {
    @apply p-6 p-12;
    @apply bg-transparent;
  }
  .modal-content {
    @apply px-16 py-20;
    @apply rounded-lg shadow;
  }
}

@screem md {
  .modal-content {
    @apply px-32;
  }
}
</style>
