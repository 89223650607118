<template>
  <div>
    <div class="flex mt-3 flex-wrap min-h-10 items-center">
      <div class="flex w-30 items-center">
        <input
          :id="dayOfWeek"
          v-model="enabled"
          type="checkbox"
          :value="enabled"
          :name="dayOfWeek"
          class="h-4 w-4 form-checkbox border-gray-300"
        />
        <label class="ml-3 capitalize" :for="dayOfWeek">{{ dayOfWeek }}</label>
      </div>
      <div class="sm:ml-5 flex items-center h-full">
        <div v-if="enabled" class="flex items-center">
          <div class="sm:flex sm:items-center">
            <label for="open">Opens at</label>
            <TimeInput
              :initial-hours="initialInputStartHour[0]"
              class="sm:ml-3 sm:w-40"
              @handleTimeInput="handleStartHour"
            />
          </div>
          <div class="ml-3 sm:flex sm:items-center">
            <label for="close">Closed at</label>
            <TimeInput
              :initial-hours="initialInputEndHour[0]"
              class="sm:ml-3 sm:w-40"
              @handleTimeInput="handleEndHour"
            />
          </div>
          <img
            v-if="!extraTimeInput"
            class="ml-4 mt-5 sm:mt-0 cursor-pointer hover:opacity-70 hover:scale-110 duration-300"
            src="/images/icon-plus-circle.svg"
            alt="Add new field"
            @click="handleOpenExtraInputTime"
          />
        </div>
        <p v-else>Closed</p>
      </div>
    </div>
    <div v-if="extraTimeInput && enabled" class="sm:ml-5 flex items-center">
      <div class="w-0 xs:w-30"></div>
      <div class="flex items-center mt-2">
        <div class="sm:flex sm:items-center">
          <label for="open">Opens at</label>
          <TimeInput
            :initial-hours="
              initialInputStartHour[1] || initialInputStartHour[0]
            "
            class="sm:ml-3 sm:w-40"
            @handleTimeInput="handleExtraStartHour"
          />
        </div>
        <div class="ml-3 sm:flex sm:items-center">
          <label for="close">Closed at</label>
          <TimeInput
            :initial-hours="initialInputEndHour[1] || initialInputEndHour[0]"
            class="sm:ml-3 sm:w-40"
            @handleTimeInput="handleExtraEndHour"
          />
        </div>
        <img
          class="ml-4 mt-5 sm:mt-0 cursor-pointer hover:scale-110 duration-300"
          src="/images/icon-trash.svg"
          alt="Remove field"
          @click="handleCloseExtraInputTime"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TimeInput from "../TimeInput";
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

export default {
  components: {
    TimeInput,
  },
  props: {
    dayOfWeek: {
      type: String,
      default: "",
    },
    defaultValues: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["updateTime"],
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      extraTimeInput: false,
      initialInputStartHour: ["08:00 AM"],
      initialInputEndHour: ["05:00 PM"],
      enabled: false,
      availability: [
        {
          start: undefined,
          end: undefined,
        },
      ],
    };
  },
  watch: {
    enabled(value) {
      if (!value) {
        this.formatInputTime();
      }
    },
  },
  created() {
    if (Object.keys(this.defaultValues).length > 0) {
      this.enabled = this.defaultValues.enabled;
      this.availability = this.defaultValues.availability;
      this.availability.forEach(({ start, end }, index) => {
        this.initialInputStartHour[index] = start;
        this.initialInputEndHour[index] = end;
        if (index > 0) this.extraTimeInput = true;
      });
    }
  },
  validations: {
    enabled: {
      required: requiredIf(() => false),
    },
    initialInputStartHour: {
      required: requiredIf(() => false),
    },
    initialInputEndHour: {
      required: requiredIf(() => false),
    },
  },
  methods: {
    handleStartHour(time) {
      this.availability[0].start = time;
      this.formatInputTime();
    },
    handleEndHour(time) {
      this.availability[0].end = time;
      this.formatInputTime();
    },
    handleExtraStartHour(time) {
      this.availability[1].start = time;
      this.formatInputTime();
    },
    handleExtraEndHour(time) {
      this.availability[1].end = time;
      this.formatInputTime();
    },
    handleOpenExtraInputTime() {
      this.extraTimeInput = true;
      this.availability.push({
        start: undefined,
        end: undefined,
      });
    },
    handleCloseExtraInputTime() {
      this.extraTimeInput = false;
      this.availability.pop();
    },
    formatInputTime() {
      this.$emit("updateTime", {
        key: this.dayOfWeek,
        value: {
          enabled: this.enabled,
          availability: this.availability,
        },
      });
    },
  },
};
</script>
