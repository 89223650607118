<template>
  <div>
    <div class="relative pt-6 sm:p-6 my-1">
      <div class="flex justify-between items-center">
        <h1 class="text-xl font-semibold leading-6 text-gray-900">
          Images & Videos
        </h1>
        <RequiredToSavePublish />
      </div>

      <div class="flex-1">
        <span class="mt-8 block text-sm font-bold text-gray-700">
          <span
            ><span class="text-red-100 pr-2 align-sub">**</span>Housing
            Multimedia Upload</span
          >
        </span>
        <CloudinaryButton
          :public-id-prefix="housing_id"
          :folder="HOUSING_MULTIMEDIA_PATH"
          @uploadedMediaData="setHousingMultimedia"
        />
        <div
          v-if="v$.housingMultimedia.$errors.length"
          class="block text-sm error text-error-900"
        >
          <span v-for="(error, idx) in v$.housingMultimedia.$errors" :key="idx">
            {{ error.$message }}
          </span>
        </div>
        <VDraggable
          :model-value="housingMultimediaOnly"
          class="flex flex-wrap cursor-move"
          item-key="index"
          ghost-class="opacity-50"
          @update:modelValue="updateHousingMultimediaOrder"
        >
          <template #item="{ element: src }">
            <div
              class="w-24 h-24 mr-10 relative flex-none mt-10 hover:shadow-2xl hover:scale-110 duration-300"
            >
              <button
                class="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full border border-gray-400 hover:text-red-100 hover:scale-125 duration-150"
                @click="removeHousingMultimediaItem(src)"
              >
                <TrashIcon class="w-5 h-5 mx-auto" />
              </button>
              <img :src="src" alt="Housing Media" class="h-full w-full" />
            </div>
          </template>
        </VDraggable>
      </div>
      <div class="flex my-4 items-center">
        <input
          data-testid="actual-images-checkbox"
          :checked="housingActualImages"
          type="checkbox"
          class="h-4 w-4 form-checkbox border-gray-300 mr-3"
          @change="setActualImages"
        />
        <label
          name="address-coming-soon-label"
          class="block text-sm font-semibold text-gray-700"
          >Actual Images</label
        >
      </div>
      <div class="flex-1">
        <label class="mt-8 block text-sm font-bold text-gray-700" for="fname">
          Housing Website URL
        </label>
        <div class="flex space-x-1 items-center">
          <input
            :value="websiteUrl"
            data-cy="url"
            type="url"
            class="mt-1 block w-full form-input sm:text-sm"
            @change.stop="setWebsiteUrlInternal($event)"
          />
        </div>
        <div
          v-if="v$.websiteUrl.$errors.length"
          class="block text-sm error text-error-900"
        >
          <span v-for="(error, idx) in v$.websiteUrl.$errors" :key="idx">
            {{ error.$message }}
          </span>
        </div>
      </div>
      <div class="flex-1">
        <span class="mt-8 block text-sm font-bold text-gray-700">
          <span>Supporting media</span>
        </span>
        <CloudinaryButton
          :public-id-prefix="housing_id"
          :folder="SUPPORTING_MEDIA_PATH"
          @uploadedMediaData="setHousingMultimedia"
        />
        <VDraggable
          v-model="supportingMedia"
          class="flex flex-wrap"
          item-key="index"
          ghost-class="opacity-50"
          @update:modelValue="updateSupportingMediaOrder"
        >
          <template #item="{ element: src }">
            <div
              class="w-24 h-24 mr-10 relative flex-none mt-10 cursor-move hover:shadow-2xl hover:scale-110 duration-300"
            >
              <button
                class="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full border border-gray-400 hover:text-red-100 hover:scale-125 duration-150"
                @click="removeHousingMultimediaItem(src)"
              >
                <TrashIcon class="w-5 h-5 mx-auto" />
              </button>
              <img :src="src" alt="Support media" class="h-full w-full" />
            </div>
          </template>
        </VDraggable>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { TrashIcon } from "@heroicons/vue/24/outline";
import CloudinaryButton from "@/components/CloudinaryUploadWidget";
import RequiredToSavePublish from "../RequiredToSavePublish/index.vue";
import { helpers, requiredIf, url } from "@vuelidate/validators";

const HOUSING_MULTIMEDIA_PATH = "housing";
const SUPPORTING_MEDIA_PATH = "support";

const store = useStore();
const housingMultimedia = computed(
  () => store.getters["programManager/getCurrentEditing"].supporting_media
);
const housingActualImages = computed(
  () => store.getters["programManager/getCurrentEditing"].actual_images
);
const websiteUrl = computed(
  () => store.getters["programManager/getCurrentEditing"].website_url
);
const housing_status = computed(
  () => store.getters["programManager/getCurrentEditing"].status
);

const housing_id = computed(
  () => store.getters["programManager/getCurrentHousingId"]
);

const housingMultimediaOnly = computed(() =>
  housingMultimedia.value?.filter((el) => {
    try {
      return new URL(el).pathname.indexOf(HOUSING_MULTIMEDIA_PATH) > -1;
    } catch {
      return true;
    }
  })
);
const supportingMedia = computed(() =>
  housingMultimedia.value?.filter((el) => {
    try {
      return new URL(el).pathname.indexOf(SUPPORTING_MEDIA_PATH) > -1;
    } catch {
      return false;
    }
  })
);

const updateHousingMultimediaOrder = (value = []) => {
  store.commit("programManager/setCurrentEditingFields", {
    supporting_media: [...value, ...(supportingMedia.value || [])],
  });
};

const updateSupportingMediaOrder = (value = []) => {
  store.commit("programManager/setCurrentEditingFields", {
    supporting_media: [...(housingMultimediaOnly.value || []), ...value],
  });
};

const setWebsiteUrlInternal = (e) => {
  store.dispatch("programManager/setCurrentEditingWebsiteUrl", {
    url: e.target.value,
  });
};
const setHousingMultimedia = (payload) => {
  const cloudinaryMedia = payload.secure_url;
  const baseHousingMultimidiaValue = housingMultimedia.value || [];
  if (!baseHousingMultimidiaValue.find((val) => val === cloudinaryMedia)) {
    store.commit("programManager/setCurrentEditingFields", {
      supporting_media: [cloudinaryMedia, ...baseHousingMultimidiaValue],
    });
  }
};
const setActualImages = (e) => {
  store.commit(
    "programManager/setCurrentEditingActualImages",
    e.target.checked
  );
};
const removeHousingMultimediaItem = (element) => {
  const updatedSupportingMedia = housingMultimedia.value.filter(
    (item) => item !== element
  );
  store.commit("programManager/setCurrentEditingFields", {
    supporting_media: updatedSupportingMedia,
  });
};

const rules = computed(() => ({
  housingMultimedia: {
    required: helpers.withMessage(
      "This field is required to Publish",
      requiredIf(function () {
        return housing_status.value === "Active";
      })
    ),
  },
  websiteUrl: {
    url: helpers.withMessage("Must be valid URL", url),
  },
}));

const v$ = useVuelidate(
  rules,
  {
    housingMultimedia,
    websiteUrl,
  },
  { $registerAs: "ImagesVideosTab", $lazy: true, $autoDirty: true }
);
</script>
