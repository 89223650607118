<template>
  <div
    class="text-gray-600 md:block"
    :class="{ 'mb-6': bottomMargin }"
    @dragover.prevent
    @drop.prevent
  >
    <div
      v-for="(file, index) in files"
      :key="(file.file ? file.file.name : file.formio.originalName) + index"
      class="text-gray-600"
    >
      <div class="flex items-center">
        <button class="mr-2" @click="remove(index)">
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-trash-2"
          >
            <polyline points="3 6 5 6 21 6" />
            <path
              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
            />
            <line x1="10" y1="11" x2="10" y2="17" />
            <line x1="14" y1="11" x2="14" y2="17" />
          </svg>
        </button>
        <span>{{ file.file ? file.file.name : file.formio.originalName }}</span>
      </div>
      <p>
        {{ file.status }}
        <a
          v-if="file.status === 'Upload failed'"
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
          ]"
          class="font-semibold underline"
          href="#"
          @click="retryUpload(file.file, index)"
        >
          Retry
        </a>
      </p>
      <template v-if="file.status !== 'Upload failed'">
        <p v-for="(error, i) in file.errors" :key="i" class="text-error-900">
          {{ error }}
        </p>
      </template>
    </div>

    <div
      v-show="!edit"
      class="p-10 text-center text-black border-2 border-gray-200 rounded-lg bg-white"
      @drop="handleFileDrop"
      @dragover="handleFileDragover"
      @dragleave="handleFileDragleave"
    >
      <input
        :id="name"
        type="file"
        :name="name"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        :accept="fileTypes.includes('*') ? '' : fileTypes"
        tabindex="-1"
        @change="handleFileInput"
      />
      <p v-if="customFileBoxLabel" class="mb-10 text-left">
        {{ customFileBoxLabel }}
      </p>
      <template v-else>
        <p class="mb-1">
          Drag and drop files here.
        </p>
      </template>
      <label :for="name" class="block cursor-pointer">
        <img
          :src="cropImg ? cropImg : ImgPlaceholder"
          class="rounded-full m-auto w-48 h-48"
        />
        <div
          tabindex="0"
          :class="[
            widthButton,
            secondaryColor
              ? 'brandingSecondary'
              : 'bg-university-secondary hover:bg-teal-100 focus:bg-teal-100',
          ]"
          class="mt-6 inline-block text-center rounded font-normal border-2 border-transparent px-4 py-1 min-h-fit w-48 text-white hover:text-white focus:text-white focus:outline-none focus:ring"
          data-cy="uploadButton"
          @keyup.enter="handleFileClick"
        >
          <span>{{ customButtonLabel }}</span>
        </div>
      </label>
    </div>

    <div
      v-show="edit"
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6"
          >
            <div>
              <div class="mx-auto flex h-96 w-96 items-center justify-center">
                <VuePictureCropper
                  :box-style="{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#f8f8f8',
                    margin: 'auto',
                  }"
                  :img="imgSrc"
                  :options="{
                    viewMode: 1,
                    dragMode: 'crop',
                    aspectRatio: 1,
                  }"
                  @ready="ready"
                />
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                type="button"
                class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white bg-teal-900 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white focus:outline-none focus:ring sm:text-sm"
                @click="cropImage"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import s3Helper from "../../../mixins/s3UniversityHelper.js";
import sentryHelper from "../../../mixins/sentryHelper.js";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import ImgPlaceholder from "@images/api-wl-icon-placeholder.png";
import { hasDotPrefix, isLowerCase } from "@/util/string";

export default {
  name: "ImageUpload",
  components: {
    VuePictureCropper,
  },
  mixins: [s3Helper, sentryHelper],
  props: {
    name: {
      type: String,
      default: "imageUpload",
    },
    fileTypes: {
      type: Array,
      default() {
        return [".jpg", ".jpeg", ".png"];
      },
      validator: function(value){
      return hasDotPrefix(value, "<ImageUpload /> prop error") && isLowerCase(value, "<ImageUpload /> prop error");
    }
    },
    maxFileSize: {
      type: Number,
      default: 3,
    },
    formioFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    customButtonLabel: {
      type: String,
      default: "Upload Files",
    },
    customFileBoxLabel: {
      type: String,
      default: "",
    },
    bottomMargin: {
      type: Boolean,
      default: true,
    },
    widthButton: {
      type: String,
      default: "",
    },
  },
  emits: ["updateProgress", "updateFiles", "s3Location"],
  data() {
    return {
      files: [],
      imgSrc: "",
      cropImg: "",
      fName: "",
      edit: false,
      ImgPlaceholder,
    };
  },
  computed: {
    imageUpload() {
      return document.getElementById(this.name);
    },
    getFileTypes() {
      return lowerCaseFileTypes.join(", ");
    },
    hasErrors() {
      return this.files.some((file) => file.errors.length);
    },
    lowerCaseFileTypes() {
      return this.fileTypes.map((fileType) => fileType.toLowerCase());
    },
  },
  watch: {
    filesOnProgress: {
      handler: function (val) {
        /*
          *****IMPORTANT***** Create updateProgress method on the parent component.
          This will return true if files still in upload progress
        */
        this.$emit("updateProgress", val > 0);
      },
    },
    files: {
      handler: function (val) {
        let formioFiles = [];
        formioFiles = val
          .filter((file) => Object.keys(file.formio).length > 0)
          .map((file) => file.formio);

        /*
          *****IMPORTANT***** Create updateFiles method on the parent component.
          This will return an array of uploaded files and
          a boolean that will tell if the file upload has files with errors
        */
        this.$emit("updateFiles", formioFiles, this.hasErrors);
      },
      deep: true,
    },
    formioFiles: {
      handler: function (val) {
        if (!Array.isArray(val)) return;
        if (!val.length) return;
        if (this.files.length !== val.length) this.addExistingFiles();
      },
      deep: true,
    },
  },
  mounted() {
    this.addExistingFiles();
  },
  methods: {
    async cropImage() {
      if (!cropper) return;
      const base64 = cropper.getDataURL();

      this.cropImg = base64;
      const file = await cropper.getFile({
        fileName: this.fName,
      });

      this.addFile(file);

      this.edit = false;
    },
    ready() {
      console.log("Cropper is ready.");
    },
    getFileName(file) {
      this.fName = file.name;
    },
    handleFileDrop(e) {
      this.remove(0);

      e.currentTarget.classList.add("bg-gray-50");

      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;

      this.readFile(droppedFiles[0]);

      this.getFileName(droppedFiles[0]);

      if (!this.isValidExtension(this.fName)) {
        this.addFile(droppedFiles[0]);
      } else {
        this.edit = true;
      }
    },
    handleFileDragover(e) {
      if (e.currentTarget.classList.contains("bg-gray-50")) {
        e.currentTarget.classList.remove("bg-gray-50");
      }
    },
    handleFileDragleave(e) {
      e.currentTarget.classList.add("bg-gray-50");
    },
    handleFileInput(e) {
      this.remove(0);

      let files = e.target.files;

      if (!files) return;

      this.readFile(files[0]);

      this.getFileName(files[0]);

      if (!this.isValidExtension(this.fName)) {
        this.addFile(files[0]);
      } else {
        this.edit = true;
      }

      //Remove all files from input
      this.imageUpload.value = "";
    },
    handleFileClick() {
      this.imageUpload.click();
    },
    remove(i) {
      this.files.splice(i, 1);
      this.cropImg = "";
      this.imgSrc = "";
    },
    readFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imgSrc = String(reader.result);
      };
    },
    addFile(file) {
      file = new File([file], this.fileName(file.name));
      let fileObject = {
        file: file,
        errors: [],
        status: "Validating file...",
        formio: {},
      };

      if (!this.isValidExtension(file.name)) {
        fileObject.status = "";
        fileObject.errors.push(
          "File is the wrong type; it must be " + this.getFileTypes
        );
      }

      if (!this.isValidSize(file.size)) {
        fileObject.status = "";
        fileObject.errors.push(
          "File is too big; it must be at most " + this.maxFileSize + "MB"
        );
      }
      this.files.push(fileObject);

      if (fileObject.errors.length === 0) {
        this.uploadFile(file, this.getFileIndex(file));
      }
    },
    retryUpload(file, index) {
      this.files[index].errors = [];
      this.files[index].status = "Starting upload...";

      this.uploadFile(file, index);
    },
    uploadFile(file, index) {
      let newFile = this.files[index];
      const newName = this.createUUIDName(file.name);

      this.filesOnProgress++;
      newFile.status = "Starting upload...";

      this.s3Upload(file, newName)
        .then((data) => {
          this.filesOnProgress--;
          newFile.status = "";
          newFile.formio = this.createFormioObject(file, newName, data);
          this.$emit("s3Location", newFile.formio);
        })
        .catch((error) => {
          this.filesOnProgress--;
          newFile.status = "Upload failed";
          newFile.errors.push(error.message);

          const sentryTags = { fieldName: this.name };
          this.throwSentryError("S3 upload: " + error, sentryTags);
        });
    },
    isValidExtension(fileName) {
      const lastIndex = fileName.lastIndexOf(".");
      const fileExtension = fileName.substring(lastIndex);
      let valid = this.fileTypes.includes("*")
        ? true
        :  this.lowerCaseFileTypes.includes(fileExtension.toLowerCase());

      return valid;
    },
    isValidSize(fileSize) {
      const size = parseFloat(fileSize / (1024 * 1024)).toFixed(2);
      return size < this.maxFileSize;
    },
    getFileIndex(fileItem) {
      return this.files.findIndex((file) => file.file === fileItem);
    },
    addExistingFiles() {
      this.formioFiles.forEach((f) => {
        const fileObject = {
          file: null,
          errors: [],
          status: "",
          formio: f,
        };

        this.files.push(fileObject);
      });
    },
    createFormioObject(file, newName, s3) {
      return {
        storage: "s3",
        name: newName,
        bucket: s3.Bucket,
        key: s3.key,
        url: s3.Location,
        acl: "private",
        size: file.size,
        type: file.type,
        originalName: file.name,
      };
    },
  },
};
</script>
