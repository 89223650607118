import { trailingSlash } from "./helpers";
import _ from "lodash";

export default {
  created: function () {
    this.initSitesList();
  },
  data() {
    return {
      sites: [],
    };
  },
  computed: {
    sitesURL() {
      return (
        trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) + "sites"
      );
    },
  },
  methods: {
    initSitesList() {
      fetch(this.sitesURL)
        .then((response) => response.json())
        .then((data) => {
          this.sites = Object.values(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * Triggered when the search text changes on vSelect component for sites list
     *
     * @param search  {String}    Current search text
     * @param loading {Function}  Toggle loading class
     */
    searchSites(search, loading) {
      loading(true);
      this.throttledSearchSites(search, loading, this);
    },
    throttledSearchSites: _.debounce((search, loading, vm) => {
      fetch(vm.sitesURL + "?search=" + encodeURIComponent(search))
        .then((response) => response.json())
        .then((data) => {
          vm.sites = Object.values(data);
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    }, 250),
  },
};
