import { learningServiceRequest } from "./utils";

const unitService = {
  async getUnits(housingId) {
    return await learningServiceRequest({
      method: "get",
      url: `/housings/${housingId}/units`,
      failureMessage: "Error loading Units data",
    });
  },
  async getUnitById(housingId, unitId) {
    return await learningServiceRequest({
      method: "get",
      url: `/housings/${housingId}/units/${unitId}`,
      failureMessage: "Error loading Unity data",
    });
  },
  async saveHousingUnits(housingId, payload) {
    return await learningServiceRequest({
      method: "put",
      url: `/housings/${housingId}/units`,
      payload: payload,
    });
  },
};

export default unitService;
