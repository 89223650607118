export const columnsConfig = [
  {
    id: "classTitle",
    header: "Class Title",
    size: 400,
    getColumnDisplayValue: ({ title = "" }) => title,
  },
  {
    id: "areasOfStudy",
    header: "Areas of Study",
    size: 280,
    getColumnDisplayValue: ({ cip_codes }) =>
      cip_codes ? cip_codes.map((code) => code?.cip_title).join(", ") : "",
  },
  {
    id: "hostUniversity",
    header: "Host University",
    size: 280,
    getColumnDisplayValue: ({ host_institution = "" }) =>
      host_institution?.name || "",
  },
  {
    id: "terms",
    header: "Term",
    size: 220,
    getColumnDisplayValue: ({ terms }) =>
      terms ? terms.map((term) => term?.name || "").join(", ") : "",
  },
  {
    id: "country",
    header: "Country",
    size: 180,
    // ?
    getColumnDisplayValue: ({ host_institution = "" }) =>
      host_institution?.shipping_address_country || "",
  },
  {
    id: "language",
    header: "Language",
    size: 180,
    // ?
    getColumnDisplayValue: ({ language_of_instruction = "" }) =>
      language_of_instruction?.value || "",
  },
];

export const miniTableColumns = [
  {
    id: "courseTitle",
    header: "Course Title",
    size: 600,
    getColumnDisplayValue: ({ title = "" }) => title,
  },
  {
    id: "credits",
    header: "Credits",
    size: 120,
    addedClasses: "text-center justify-center",
    // ?
    getColumnDisplayValue: ({ recommended_us_credit_amount = "" }) =>
      recommended_us_credit_amount,
  },
  {
    id: "languageOfInstruction",
    header: "Language of Instruction",
    size: 280,
    getColumnDisplayValue: ({ language_of_instruction = "" }) =>
      language_of_instruction?.value || "",
  },
  {
    id: "educationLevel",
    header: "Education Level",
    size: 280,
    getColumnDisplayValue: ({ level }) => (level ? level.join(", ") : ""),
  },
];

export const addedOptionsId = {
  anyList: "anyList",
};

export const addedOptions = [
  { id: addedOptionsId.anyList, title: "Any class from the list above" },
];
