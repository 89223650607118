<script setup>
import { toRefs } from "vue";
import ClassCard from "./ClassCard.vue";

const props = defineProps({
  classList: {
    type: Object,
    required: true,
    default: () => {},
  },
  track: {
    type: Object,
    required: true,
    default: () => {},
  },
  show: {
    type: Boolean,
    required: true,
    default: false,
  },
  anyClass: {
    type: Boolean,
    required: true,
    default: false,
  },
});
const { classList, track } = toRefs(props);
</script>

<template>
  <div
    v-if="show"
    class="bg-white mb-4 border shadow-lg"
    :class="[
      secondaryColor ? `border-gray-100` : `border-university-accentPrimary`,
    ]"
  >
    <div
      class="flex items-center pl-4 py-3 cursor-pointer justify-between"
      :class="[secondaryColor ? `bg-gray-275` : `bg-blue-300`]"
    >
      <div class="flex space-x-2">
        <p class="md:max-w-72 lg:max-w-none font-semibold uppercase">
          {{ track.name }}
        </p>
      </div>
    </div>
    <div class="p-4">
      <ClassCard
        v-for="(classItem, index) in classList"
        :key="index"
        class="mb-4"
        :classes="classItem"
        :hidden="classList?.data?.length !== 1"
        :rule="track"
      />
      <p v-if="anyClass">
        Plus any available course in the catalog
      </p>
    </div>
  </div>
</template>
