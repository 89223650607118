import { useAsyncState } from "@vueuse/core";
import housingService from "@/services/housings";

export const useGetHousing = (asyncStateOptions, defaultId) => {
  return useAsyncState(
    (id) =>
      housingService
        .getHousingById(defaultId || id)
        .then((val) => val?.data?.data || {}),
    {},
    asyncStateOptions
  );
};
