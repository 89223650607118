<script setup>
import { onMounted, computed } from "vue";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import feather from "feather-icons";

const props = defineProps({
    containerId: {
        type: String,
        default: "",
    },
    iconId: {
        type: String,
        default: "",
    },
    contentTemplateId: {
        type: String,
        default: "",
    }
});


const templateContent = computed(() => {
  return document.getElementById(props.contentTemplateId).innerHTML;
});


onMounted(() => {
    feather.replace();
    const containerElement = document.getElementById(props.containerId);
    const iconElement = document.getElementById(props.iconId);
    tippy(iconElement, {
      theme: "light",
      role: "tooltip",
      appendTo: containerElement,
      content: templateContent.value,
      allowHTML: true,
      maxWidth: 800,
      aria: {
        content: "describedby",
        expanded: "auto"
      }
    });
});

</script>

<template>
    <span :id="props.containerId" class="absolute difficulty-icon" data-testid="academic-pop-over">
        <i :id="props.iconId" class="mr-2 text-info-icon" data-feather="alert-circle"></i>
    </span>
    <div :id="props.contentTemplateId" class="hidden">
      <div class="grid grid-cols-24-1fr gap-x-1 font-normal normal-case xs:min-w-48 sm:min-w-80 min-w-80 p-2" data-testid="academic-pop-over-content">
        <i class="mr-2 text-info-icon" data-feather="alert-circle"></i>
        <p class="break-normal text-left ml-2">
          Determined by required GPA, essays, letters of rec, portfolio, language proficiency, and year in school.
        </p>
      </div>
    </div>
</template>

<style scoped>
  .difficulty-icon {
    transform: translate(9px, -5px)
  }
</style>
