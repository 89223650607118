<template>
  <svg
    width="20"
    height="5"
    viewBox="0 0 20 5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#6A7181" fill-rule="nonzero">
      <path
        d="M12.5 2.439c0 1.347-1.12 2.439-2.5 2.439S7.5 3.786 7.5 2.439 8.62 0 10 0s2.5 1.092 2.5 2.439zM5 2.439c0 1.347-1.12 2.439-2.5 2.439S0 3.786 0 2.439 1.12 0 2.5 0 5 1.092 5 2.439zM20 2.439c0 1.347-1.12 2.439-2.5 2.439S15 3.786 15 2.439 16.12 0 17.5 0 20 1.092 20 2.439z"
      />
    </g>
  </svg>
</template>
