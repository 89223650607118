import { useAsyncState } from "@vueuse/core";
import axios from "axios";
import ordersService from "@/services/orders";
import productPriceService from "@/services/productPrices";

export const useGetSingleOrder = (
  { order_id, get_canceled, get_withdrawn },
  immediate = true
) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();

  return useAsyncState(
    (props) => {
      const { cancel } = props || {};
      const id = order_id || props.orderId;
      const getCanceled = get_canceled || !!props?.get_canceled;
      const getWithdrawn = get_withdrawn || !!props?.get_withdrawn;
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      return ordersService
        .getOne({
          order_id: id,
          params: { get_canceled: getCanceled, get_withdrawn: getWithdrawn },
        })
        .then((val) => val?.data?.data || {});
    },
    [],
    { immediate }
  );
};

export const useGetOrdersLite = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel("cancel");
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return ordersService.getOrdersLite(params).then((response) => {
        return {
          data: response?.data?.data ?? [],
          count: response?.data?.count ?? 0,
        };
      });
    },
    [],
    asyncStateOptions
  );
};

export const useGetProductPrices = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return productPriceService.getList(params).then((response) => {
        const data = response?.data?.data;
        return {
          count: data?.count || 0,
          data: data?.items || [],
        };
      });
    },
    { count: -1, data: [] },
    asyncStateOptions
  );
};
