<template>
  <div>
    <general-container>
      <div class="flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-alert-triangle text-help-warning"
        >
          <path
            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
          ></path>
          <line x1="12" y1="9" x2="12" y2="13"></line>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>
        <div class="ml-3 mt-1 space-y-3">
          <p class="font-semibold">Important Reminder on Data Format</p>
          <p>
            <span class="font-semibold">Birth Day:</span> MM/DD/YYYY (e.g.,
            12/04/2002)
          </p>
          <p>
            <span class="font-semibold">Phone: </span>
            +CountryCode-AreaCode-XXXXXXX (If CountryCode is not provided,
            assume it’s United States. +1-512-5555555 is same as 512-5555555)
          </p>
          <p>
            <span class="font-semibold">Gender:</span> Allowed values are Male,
            Female, or Non-binary
          </p>
          <p>
            <span class="font-semibold">Citizenship:</span> Allowed values are
            <a
              class="text-teal-900 font-semibold"
              href="https://support.apiabroad.com/hc/en-us/articles/6149130150295"
              target="_blank"
            >
              here
            </a>
          </p>
        </div>
      </div>
    </general-container>
    <div class="flex justify-center space-x-6 mt-24">
      <button
        :class="[
          secondaryColor
            ? `${secondaryColorClassOutlined}`
            : `${secondaryColorClassOutlined} hover:bg-teal-100 focus:bg-teal-100`,
        ]"
        class="text-center rounded font-semibold border-2 border-transparent px-6 min-h-10 bg-white hover:text-white"
        @click="$emit('cancel-btn')"
      >
        <span>Cancel</span>
      </button>
      <button
        :class="[
          secondaryColor
            ? 'brandingSecondary'
            : `bg-teal-100 hover:bg-university-secondary focus:bg-university-secondary`,
        ]"
        class="text-center px-8 text-white rounded font-semibold border-2 border-transparent min-h-10"
        @click="$emit('next-btn')"
      >
        <span>Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import GeneralContainer from "../helpers/GeneralContainer";
export default {
  name: "FormatScreen",
  components: {
    "general-container": GeneralContainer,
  },
  emits: ["next-btn", "cancel-btn"],
};
</script>
