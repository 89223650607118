<template>
  <div>
    <p class="text-indigo-base" :class="textClass">
      {{ description }}
    </p>
    <div
      v-if="props.description.length > props.maxCharacters"
      class="uppercase font-bold pt-4 flex cursor-pointer"
      :class="`${secondaryColorClass}`"
      @click="openModal(props)"
    >
      <span class="inline-block">See more</span>
      <div class="inline-block ml-3 py-1">
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -7.77409e-07L-7.14641e-07 1.71183L5.38866 7.00874Z"
            :fill="secondaryColor ? `${secondaryColor}` : '#007F80'"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, inject } from "vue";

const props = defineProps({
  description: {
    type: String,
    default: "",
  },
  maxCharacters: {
    type: Number,
    default: 250,
  },
  textClass: {
    type: String,
    default: "",
  },
});
const openModal = inject("openModal");

const description = computed(() => {
  if (props.description.length <= props.maxCharacters) {
    return props.description;
  }
  return props.description.slice(0, props.maxCharacters) + "...";
});
</script>
