<script setup>
import { computed, defineProps, toRefs, ref } from "vue";
import HousingModal from "@/components/ExperiencePage/ProgramDetailsSection/housing/components/HousingModal";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import APIValidationBadge from "@/components/shared/icons/APIValidationBadge.vue";
import { imageTransformation } from "@/util/cloudinary";
import {
  CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
  DEFAULT_IMAGE_CARD_SIZE,
} from "@/constants.js";
import { useTier } from "@/composables/useTier";
import { useStore } from "vuex";

defineOptions({
  name: "HousingCard"
})

const props = defineProps({
  housingData: {
    type: Object,
    required: true,
  },
});

const { housingData: housing } = toRefs(props);
const openModal = ref(false);
const store = useStore();

const image = computed(() => {
  if (
    housing.value?.supporting_media &&
    Array.isArray(housing.value.supporting_media) &&
    housing.value.supporting_media.length > 0
  ) {
    return imageTransformation({
      ...CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
      ...DEFAULT_IMAGE_CARD_SIZE,
      path: housing.value.supporting_media[0],
      compress: true,
    });
  }
  return undefined;
});

const truncateHousingDescription = (description) =>
  description && description.length > 160
    ? `${description.slice(0, 160)} ...`
    : description;

const tier = useTier(housing.value?.tier?.name);

const openHousingDescriptionModal = () => {
  openModal.value = true;
};

const housingType = computed(() => {
  if (housing.value?.subcategory === "Residencia") {
    return "Residencia";
  } else {
    return housing.value?.type || "";
  }
});

const priceFlagEnabled = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["program-page-card-price"];
});

const priceLabel = computed( () => {
  if(priceFlagEnabled.value){
    return housing.value?.startingPrice;
  }else{
    return housing.value?.startingPrice === "Included" ? "Included" : "";
  }
});
</script>

<template>
  <HousingModal
    :housing-data="housing"
    :open-modal="openModal"
    @close-modal="openModal = false"
  ></HousingModal>
  <div>
    <div class="relative h-full">
      <!--<div class="absolute -z-50 -right-4 -bottom-16 object-cover">
        <APIValidationBadge custom-class="w-52 h-52" fill-color="#dfdfdf" />
      </div>-->
      <div data-testid="housing-card" class="flex flex-col relative !z-[1] h-full">
        <div
          class="flex w-full p-3 justify-between"
          :class="[
            secondaryColor ? `bg-gray-275` : `bg-university-accentPrimary`,
          ]"
        >
          <div class="font-bold text-base uppercase text-indigo-base px-3 py-3">
            {{ housingType || "Not Found" }}
          </div>
          <p
            class="uppercase text-xs font-bold border text-indigo-base py-3 px-3"
          >
            {{ tier }}
          </p>
        </div>
        <div class="flex w-full h-40">
          <img
            v-if="image"
            class="object-cover w-full"
            :src="image"
            alt="Housing image view"
          />
        </div>
        <div class="px-6 mt-4">
          <div data-testid="card-price" class="inline-block uppercase font-bold text-sm"
          :class="[
            secondaryColor ? `${secondaryColorClass}` : `text-university-secondary`,
          ]">
            {{ priceLabel}}
          </div>
        </div>
  
        <div class="px-6 mt-4">
          <p class="text-sm font-normal text-indigo-base min-h-[30] h-fit">
            {{ truncateHousingDescription(housing?.description) }}
          </p>
        </div>
        <div class="flex mt-auto px-6 pt-4 pb-8">
          <button
            class="flex font-semibold"
            :class="[
              secondaryColor ? `${secondaryColorClass}` : `text-university-secondary`,
            ]"
            @click="openHousingDescriptionModal(housing)"
          >
            <span class="uppercase self-center">Learn More </span>
            <ArrowRight custom-class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
