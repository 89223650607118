<script setup>
import IconDisplay from "@/components/shared/Card/IconDisplay.vue";
import FullTimeIcon from "@/components/shared/icons/FullTimeIcon.vue";
import HybridIcon from "@/components/shared/icons/HybridIcon.vue";
import InPersonIcon from "@/components/shared/icons/InPersonIcon.vue";
import LanguageIcon from "@/components/shared/icons/LanguageIcon.vue";
import LargeCompanyIcon from "@/components/shared/icons/LargeCompanyIcon.vue";
import MediumCompanyIcon from "@/components/shared/icons/MediumCompanyIcon.vue";
import PartTimeIcon from "@/components/shared/icons/PartTimeIcon.vue";
import PerksInternshipIcon from "@/components/shared/icons/PerksInternshipIcon.vue";
import SmallCompanyIcon from "@/components/shared/icons/SmallCompanyIcon.vue";
import VirtualIcon from "@/components/shared/icons/VirtualIcon.vue";

import moment from "moment";
import { computed } from "vue";

const props = defineProps({
  programLanguagesWithProficiency: {
    type: Array,
    default: () => [],
  },
  companySize: {
    type: String,
    default: "",
  },
  perks: {
    type: Array,
    default: () => [],
  },
  program: {
    type: Object,
    default: () => ({}),
  },
  placementTypes: {
    type: Array,
    default: () => [],
  },
});

const getIconComponent = (locationType) => {
  switch (locationType) {
    case "In-person":
      return InPersonIcon;
    case "Virtual":
      return VirtualIcon;
    case "Hybrid":
      return HybridIcon;
    default:
      return null;
  }
};
const scheduleDurationIcon = computed(() => {
  const scheduleType = props.program.schedule?.type;
  const duration = props.program.schedule?.duration;

  if (scheduleType === "Weekly Recurring") {
    const totalHours = calculateTotalHours(props.program.schedule.description);
    return totalHours >= 30 ? FullTimeIcon : PartTimeIcon;
  } else if (scheduleType === "Other") {
    if (duration === "Full time (30-40/week)") {
      return FullTimeIcon;
    } else if (duration === "Part time (less than 30 hours/week)") {
      return PartTimeIcon;
    }
  }
  return null;
});

const scheduleDurationText = computed(() => {
  const scheduleType = props.program.schedule?.type;
  const duration = props.program.schedule?.duration;

  if (scheduleType === "Weekly Recurring") {
    const totalHours = calculateTotalHours(props.program.schedule.description);
    return totalHours >= 30 ? "Full time" : "Part time";
  } else if (scheduleType === "Other") {
    if (duration === "Full time (30-40/week)") {
      return "Full time";
    } else if (duration === "Part time (less than 30 hours/week)") {
      return "Part time";
    }
  }
  return "";
});

function calculateTotalHours(schedule) {
  let totalHours = 0;
  for (const day in schedule) {
    if (schedule[day].enabled) {
      schedule[day].availability.forEach((timeSlot) => {
        const start = moment(timeSlot.start, "hh:mm A");
        const end = moment(timeSlot.end, "hh:mm A");
        totalHours += moment.duration(end.diff(start)).asHours();
      });
    }
  }
  return totalHours;
}
</script>

<template>
  <div
    data-testid="internship-icons-display"
    class="mt-2 mb-2 text-left inline-grid gap-y-2"
  >
    <IconDisplay
      v-if="programLanguagesWithProficiency.length"
      data-testid="language-icon-display"
      :icon-component="LanguageIcon"
    >
      <span
        v-for="(language, index) in programLanguagesWithProficiency"
        :id="`language-${language.id}`"
        :key="language.id"
      >
        {{ language.value }}, {{ language.proficiency }}
        <span
          v-if="index < programLanguagesWithProficiency.length - 1"
          id="language-divider-{{ index }}"
        >
          |
        </span>
      </span>
    </IconDisplay>
    <div class="inline-grid grid-cols-2 gap-y-2 gap-x-4">
      <IconDisplay
        v-if="props.companySize === 'Small'"
        data-testid="small-company-icon"
        class="flex flex-row items-center gap-1"
        :icon-component="SmallCompanyIcon"
        text="Small Company"
      />
      <IconDisplay
        v-if="props.companySize === 'Medium'"
        data-testid="medium-company-icon"
        class="flex flex-row items-center gap-1"
        :icon-component="MediumCompanyIcon"
        text="Medium Company"
      />
      <IconDisplay
        v-if="props.companySize === 'Large'"
        data-testid="large-company-icon"
        class="flex flex-row items-center gap-1"
        :icon-component="LargeCompanyIcon"
        text="Large Company"
      />
      <IconDisplay
        v-if="props.perks?.length"
        data-testid="perks-icon"
        class="flex flex-row items-center gap-1"
        :icon-component="PerksInternshipIcon"
        text="Perks Included"
      />
      <div
        v-for="(location, index) in props.program.work_locations"
        :id="`work-location-${index}`"
        :key="location.id"
        class="flex flex-row items-center gap-1"
      >
        <IconDisplay
          :id="`work-location-icon-${location.id}`"
          :icon-component="getIconComponent(location.value)"
          :text="location.value"
        />
      </div>
      <IconDisplay
        v-if="scheduleDurationIcon"
        data-testid="work-schedule-duration-icon"
        :icon-component="scheduleDurationIcon"
        :text="scheduleDurationText"
      />
    </div>
  </div>
</template>
