<template>
  <div class="flex items-center gap-x-2">
    <label class="text-base sm:text-sm md:text-base">
      {{ labelText }}
    </label>
    <div v-if="showBar">
      <div
        class="overflow-hidden h-2 w-56 md:w-64 text-xs flex rounded bg-help-success-faded"
      >
        <div
          :style="{ width: percentage + '%' }"
          class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-help-success"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormCounter",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    completed: {
      type: Number,
      default: 0,
    },
    submitted: {
      type: Number,
      default: 0,
    },
    isNotAFormProcess: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    percentage() {
      return this.sectionCompleted
        ? 100
        : parseInt((this.submitted / this.total) * 100);
    },
    sectionCompleted() {
      return this.completed === this.total;
    },
    showBar() {
      return this.submitted > 0 || this.isOpen;
    },
    labelText() {
      if (!this.isNotAFormProcess) {
        return this.sectionCompleted
          ? "Completed"
          : this.submitted > 0
          ? "Submitted " + this.submitted + " of " + this.total
          : this.submitted === 0 && this.isOpen
          ? "Submitted " + this.submitted + " of " + this.total
          : "Not Started";
      } else {
        return "Completed " + this.submitted + " of " + this.total;
      }
    },
  },
};
</script>
