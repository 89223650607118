<template>
  <InstitutionPays v-if="institutionPaysApplicationFee" />
  <PayDepositForm v-else />
</template>

<script>
import billingRules from "../../../mixins/billingRules";
import PayDepositForm from "./PayDepositForm";
import InstitutionPays from "./InstitutionPays";
export default {
  components: { PayDepositForm, InstitutionPays },
  mixins: [billingRules],
  data() {
    return {
      toggler: true,
    };
  },
};
</script>
