<template>
  <section>
    <header class="flex justify-between pb-2 mb-6 border-b border-gray-200">
      <h2 @click="controlSection()">
        <button
          :aria-controls="id"
          :aria-expanded="expanded"
          class="flex items-center"
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-down transition-transform transform"
            :class="{ '-rotate-90': !expanded }"
          >
            <polyline points="6 9 12 15 18 9" />
          </svg>
          <span
            :class="extraSpanClasses"
            class="ml-1 text-xs font-semibold text-left uppercase"
          >
            {{ title }}
          </span>
        </button>
      </h2>
    </header>
    <div
      :id="id"
      class="pb-6 text-sm text-gray-600 border-gray-200"
      :style="{ display: expanded === true ? 'block' : 'none' }"
    >
      <slot name="content" />
    </div>
  </section>
</template>
<script>
export default {
  name: "PageSection",
  props: {
    title: {
      type: String,
      default: "",
    },
    initiallyExpanded: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "someid",
      required: true,
    },
    extraSpanClasses: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      expanded: null,
    };
  },
  created() {
    this.expanded = this.initiallyExpanded;
  },
  methods: {
    controlSection() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
