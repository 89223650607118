<script setup>
import { defineEmits, computed } from "vue";
const props = defineProps({
  item: {
    type: [String, Object],
    default: "",
  },
  customClass: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: " ",
  },
  truncate: {
    type: Boolean,
    default: true,
  },
});
defineEmits(["item-removed"]);

const getLabel = computed(() => {
  if (props.item && typeof props.item === "object") {
    const keys = Object.keys(props.item);

    //return the specifie key as the label
    if (keys.includes(props.label)) {
      return props.item[props.label];
    }

    if (keys.length === 1) {
      return props.item[keys[0]];
    } else if ("label" in props.item) {
      return props.item.label;
    }
  }

  return null; // Return null for unsupported formats or invalid input
});

const labelClasses = computed(() => {
  const baseClasses = `inline-flex items-center rounded-lg text-sm font-normal gap-2 px-2 non-italic w-max border border-[#123B3B] text-[#123B3B] hover:bg-indigo-light hover:border-0 hover:cursor-pointer capitalize`;
  const truncateClass = props.truncate ? "max-w-125px" : "";
  const customClass = props.customClass ? props.customClass : "";

  return `${baseClasses} ${truncateClass} ${customClass}`.trim();
});
</script>
<template>
  <div
    v-if="getLabel"
    :title="getLabel"
    :class="labelClasses"
    @click="$emit('item-removed', props.item)"
  >
    <span :class="{ truncate: props.truncate }">{{ getLabel }}</span>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="flex-shrink-0"
    >
      <path
        d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
