import { learningServiceRequest } from "./utils";

const placementTypesService = {
  async list(search) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/placement_types",
      params: { limit: 10, q: search },
      failureMessage: "Error loading Placement Types",
    });
    return response?.data?.items ?? [];
  },
};

export default placementTypesService;
