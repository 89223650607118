<template>
  <FormPanel
    :id="'payments-panel'"
    :title="'Payments'"
    :initially-expanded="true"
    :remove-default-margins="true"
    :remove-default-paddings="true"
    main-panel-class="bg-white sm:mt-4 sm:rounded-lg sm:shadow-md"
    panel-content-class=""
    panel-button-text-class="text-lg md:text-xl capitalize xs:tracking-normal"
    main-header-class="text-lg md:text-xl pt-4 pb-2 px-8 border-none"
  >
    <template #panelHeaderCheckbox>
      <span class="italic text-sm opacity-80 normal-case"
        >Payments may take 72 hours to process.</span
      >
    </template>
    <template #panelHeaderItem>
      <FinancialAidCard
        v-if="showFinancialAid"
        :display-to-do="displayToDo"
        :using-financial-aid="financialAidChosen"
        :financial-aid="currentlyFinancialAid"
      />
      <div
        class="flex flex-col md:flex-row w-full mt-4 bg-[#edf9f8] text-base text-center items-right md:items-center p-3 rounded-lg justify-between"
      >
        <div class="flex flex-row gap-2">
          <div class="text-xs xs:text-base">
            <span class="text-red-100">Past Due: </span>
          </div>
          <span class="font-bold text-xs xs:text-base">{{
            pastDueInvoicesCount
          }}</span>
        </div>
        <div class="flex flex-row gap-2">
          <span class="text-xs xs:text-base">Current Balance:</span>
          <span class="font-bold text-xs xs:text-base">{{ balance }} USD</span>
        </div>
      </div>
    </template>

    <template #content>
      <SimpleDataTableV3
        :columns="tableHeaders"
        :items="normalizedTableRows"
        mobile-view
      />
      <Modal
        :open="displayInvoiceModal"
        :title="'Make Payment'"
        :show-logo="false"
        @close-modal="closeModal"
      >
        <template #modal-content>
          <InvoicePaymentContent
            :balance="invoiceSelected?.balance"
            :title="invoiceSelected?.title"
            :invoice-id="invoiceSelected?.invoice_id"
            @update:invoice-url="setUrlToInvoice"
            @close:modal="closeModal"
            @open:tab="openNewTab"
          />
        </template>
      </Modal>
    </template>
  </FormPanel>
</template>

<script setup>
import FinancialAidCard from "@/components/financialAid/FinancialAidCard.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import InvoicePaymentContent from "@/components/modals/InvoicePayment.vue";
import Modal from "@/components/modals/Modal";
import SimpleDataTableV3 from "@/components/paymentCards/SimpleDataTableV3.vue";
import { useCapitalizeWords } from "@/composables/stringManupulation";
import { INVOICE_STATUSES } from "@/constants.js";
import { compareStringsInLowerCase } from "@/util/string";
import {
  addDays,
  differenceInDays,
  format,
  fromUnixTime,
  isValid,
  parseISO,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { computed, defineEmits, inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const DAYS_TO_ADD = 14;
const session = inject("session");
const emit = defineEmits([
  "triggerConfirmationPaymentModal",
  "financialAidChanged",
  "close:modal",
]);
const props = defineProps({
  invoices: {
    type: Array,
    default: () => [],
  },
  displayToDo: {
    type: Boolean,
    default: false,
  },
  showFinancialAid: {
    type: Boolean,
    default: false,
  },
  showConfirmationPayment: {
    type: Boolean,
    default: false,
  },
  dateAccepted: {
    type: String,
    default: null,
  },
  financialAidChosen: {
    type: Boolean,
    default: false,
  },
  currentlyFinancialAid: {
    type: Object,
    default: false,
  },
});
const store = useStore();
const route = useRoute();
const displayInvoiceModal = ref(false);
const invoiceSelected = ref(null);
const tableHeaders = ref([
  {
    id: "invoice",
    title: "Invoice #",
    sortable: false,
    customRender: false,
  },
  {
    id: "charges",
    title: "Charges",
    sortable: false,
    customRender: false,
  },
  {
    id: "due_date",
    title: "Due Date",
    sortable: false,
    customRender: false,
  },
  {
    id: "status",
    title: "Payment Status",
    sortable: false,
    customRender: false,
  },
  {
    id: "amount",
    title: "Amount",
    sortable: false,
    customRender: false,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    customRender: false,
  },
]);

const tableRows = ref([]);

const normalizedTableRows = ref([]);

onMounted(() => {
  // Populate tableRows with prepared data
  tableRows.value = [...preparedInvoices.value];

  if (props.showConfirmationPayment) {
    // Special item - Only appears for students that must pay Confirmation Fee (400$)
    // We don't block user from paying if date has passed
    tableRows.value.push({
      invoice: "",
      charges: "Confirmation Fee",
      due_date: confirmationDueDate.value,
      status: "",
      amount: 40000,
      url: "",
    });
  }
  normalizeTableRows();
  handleAddActionsToTableRows();
});

const preparedInvoices = computed(() => {
  return props.invoices
    .filter((item) => {
      if (isLegacyCheckout.value) {
        return true; // For legacy, return all items
      }
      // For the new logic, apply the filter
      return (
        item?.status !== INVOICE_STATUSES.VOID &&
        !compareStringsInLowerCase(
          item?.metadata?.payment_method_changed ?? "",
          "True"
        )
      );
    })
    .map((item) => {
      let obj = {
        invoice: item.id,
        charges: lineItems(item),
        due_date: unixTimestampToDate(item.due_date),
        status: setStatusString(item),
        amount: item.amount_due,
        url: payOrViewInvoice(item),
      };

      if (
        compareStringsInLowerCase(
          item?.metadata?.product_name ?? "",
          "Confirmation Fee"
        )
      ) {
        // Special behavior - Add due date to confirmation fee after paying (even if we didn't set it on Stripe)
        obj.due_date = confirmationDueDate.value;
      }

      return obj;
    });
});

const confirmationDueDate = computed(() => {
  if (props.dateAccepted) {
    const acceptedDateTwoWeeks = addDays(
      new Date(parseISO(props.dateAccepted)),
      DAYS_TO_ADD
    );

    if (isValidApplicationDeadlineDate.value) {
      // Calculate the difference in days between application deadline and acceptance date
      const daysDifference = differenceInDays(
        new Date(session.value.application_deadline),
        new Date(props.dateAccepted)
      );

      // If the difference is less than or equal to 14 days, use the application deadline as confirmation fee deadline
      //else default to accepted date plus 14 days
      return daysDifference <= DAYS_TO_ADD
        ? session.value.application_deadline
        : acceptedDateTwoWeeks;
    }
    return acceptedDateTwoWeeks;
  }
  return "";
});

const isValidApplicationDeadlineDate = computed(
  () =>
    session?.value?.application_deadline &&
    isValid(new Date(session?.value?.application_deadline))
);
const pastDueInvoicesCount = computed(() => {
  let pastDueCount = 0;

  for (const tableRow of tableRows.value) {
    if (
      isPastDue(tableRow) &&
      tableRow.status?.toLowerCase() !== INVOICE_STATUSES.PAID.toLowerCase()
    ) {
      pastDueCount++;
    }
  }
  return pastDueCount;
});

const balance = computed(() => {
  let currentBalance = 0;
  for (const tableRow of tableRows.value) {
    if (
      tableRow.status === INVOICE_STATUSES.OPEN ||
      tableRow.status === INVOICE_STATUSES.DRAFT ||
      tableRow.status === INVOICE_STATUSES.PAST_DUE
    ) {
      currentBalance += tableRow.amount;
    }
  }
  return formatNumberToCurrency(currentBalance);
});

const currentApp = computed(() => {
  return store.getters.currentApplication(route.params.applicationId);
});

const featureFlags = computed(() => store.state.featureFlags);

const isLegacyCheckout = computed(() => {
  return featureFlags.value["legacy-checkout"];
});

const isPastDue = (item) => {
  const currentDate = new Date();
  let dueDate;

  // Check if item.due_date is a Unix timestamp
  if (typeof item.due_date === "number" && item.due_date > 0) {
    dueDate = new Date(item.due_date * 1000); // Convert Unix timestamp to milliseconds
  } else {
    dueDate = new Date(item.due_date); // Convert to Date object directly
  }
  return dueDate < currentDate;
};

const addActionsToTableRowsNew = () => {
  normalizedTableRows.value.forEach((item) => {
    if (item.status === INVOICE_STATUSES.PAST_DUE) {
      item.actions = {
        type: "button",
        text: "Pay",
        action: () => {
          window.open(item.url, "_blank");
        },
      };
    }
    if (
      item.status === INVOICE_STATUSES.OPEN ||
      item.status === INVOICE_STATUSES.DRAFT
    ) {
      item.actions = {
        type: "button",
        text: "Pay",
        action: () => {
          toggleInvoiceModal(displayInvoiceModal.value);
          setInvoiceSelected(item);
        },
      };
    }
    if (item.status === INVOICE_STATUSES.VOID) {
      item.actions = {
        type: "icon",
        icon: "icon-download",
        action: () => {
          window.open(item.url, "_blank");
        },
      };
    }
    if (
      item.status === INVOICE_STATUSES.PAID &&
      item.invoice.startsWith("in_")
    ) {
      item.actions = {
        type: "icon",
        icon: "icon-download",
        action: () => {
          window.open(item.url, "_blank");
        },
      };
    }
    if (item.charges === "Confirmation Fee" && props.showConfirmationPayment) {
      item.actions = {
        type: "button",
        text: "Pay",
        action: () => {
          emit("triggerConfirmationPaymentModal");
        },
      };
    }
  });
};

const addActionsToTableRowsLegacy = () => {
  // TODO: Before removing any legacy checkout code, ensure the following:
  // 1. Completely remove the behavior controlled by the "legacy-checkout" feature flag across all places in the code, as part of ticket AP-3957.
  // 2. Confirm that the changes introduced in ticket AP-3591 remain intact after the legacy code removal.
  // 3. Ensure that any future improvements, such as those from ticket AP-3947 or similar, are preserved and not inadvertently removed during the cleanup process.
  // 4. After everything has been thoroughly tested and released, verify that the "legacy-checkout" feature-flag is removed from the codebase and launchdarkly.
  // Only proceed with removing this legacy code once all these conditions have been met and validated.
  normalizedTableRows.value.forEach((item) => {
    if (
      item.status === INVOICE_STATUSES.PAST_DUE ||
      item.status === INVOICE_STATUSES.OPEN
    ) {
      item.actions = {
        type: "button",
        text: "Pay",
        action: () => {
          window.open(item.url, "_blank");
        },
      };
    }
    if (item.status === INVOICE_STATUSES.VOID) {
      item.actions = {
        type: "icon",
        icon: "icon-download",
        action: () => {
          window.open(item.url, "_blank");
        },
      };
    }
    if (
      item.status === INVOICE_STATUSES.PAID &&
      item.invoice.startsWith("in_")
    ) {
      item.actions = {
        type: "icon",
        icon: "icon-download",
        action: () => {
          window.open(item.url, "_blank");
        },
      };
    }
    if (item.status === INVOICE_STATUSES.DRAFT) {
      item.actions = {
        type: "button",
        text: "Pay",
        action: () => {
          toggleInvoiceModal(displayInvoiceModal.value);
          setInvoiceSelected(item);
        },
      };
    }
    if (item.charges === "Confirmation Fee" && props.showConfirmationPayment) {
      item.actions = {
        type: "button",
        text: "Pay",
        action: () => {
          emit("triggerConfirmationPaymentModal");
        },
      };
    }
  });
};

const handleAddActionsToTableRows = () => {
  isLegacyCheckout.value
    ? addActionsToTableRowsLegacy()
    : addActionsToTableRowsNew();
};

const normalizeTableRows = () => {
  const normalizedData = tableRows.value.map((item) => {
    const { due_date, amount, balance, ...rest } = item;

    return {
      ...rest,
      due_date: due_date
        ? new Date(due_date).toLocaleDateString("en-US", { timeZone: "UTC" })
        : "",
      amount_in_cents: amount,
      amount: formatNumberToCurrency(parseFloat(amount)),
      balance: formatNumberToCurrency(parseFloat(balance)),
    };
  });

  return (normalizedTableRows.value = normalizedData);
};

const formatNumberToCurrency = (number) => {
  const formattedNumber = (number / 100).toFixed(2);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  return formatter.format(formattedNumber);
};

const lineItems = (item) => {
  if (_.isEmpty(item)) return;
  if (item.object === "payment_intent") {
    return item?.metadata?.product_name;
  }
  return item.lines?.data.map((line) => line.description).join(", ");
};

const unixTimestampToDate = (timestamp) => {
  if (!timestamp) return;
  const date = fromUnixTime(timestamp);
  const utcDate = utcToZonedTime(date, "UTC"); // Set timezone to UTC
  return format(utcDate, "yyyy-MM-dd");
};

const payOrViewInvoice = (item) => {
  if (item.status === INVOICE_STATUSES.PAID.toLowerCase()) {
    return item.invoice_pdf;
  }
  return item.hosted_invoice_url;
};

const setStatusString = (item) => {
  if (isPastDue(item) && item.status === INVOICE_STATUSES.OPEN.toLowerCase()) {
    return INVOICE_STATUSES.PAST_DUE;
  } else if (item.status === INVOICE_STATUSES.VOID.toLowerCase()) {
    return "Voided";
  } else {
    return useCapitalizeWords(item.status);
  }
};

const toggleInvoiceModal = function (value) {
  displayInvoiceModal.value = !value;
};

const closeModal = function () {
  toggleInvoiceModal(displayInvoiceModal.value);
  setInvoiceSelected({}, true);
  !isLegacyCheckout.value && emit("close:modal");
};

const openNewTab = function () {
  !isLegacyCheckout.value && emit("open:tab");
};

const setInvoiceSelected = function (invoice = {}, reset = false) {
  if (reset) {
    invoiceSelected.value = null;
    return;
  }
  invoiceSelected.value = {
    invoice_id: invoice.invoice, // invoice id from stripe
    balance: invoice.amount_in_cents,
    title: invoice.charges, // title
  };
};

/**
 * Updates invoice in table with accurate data
 * @param {Object} invoice_data Includes properties; invoice_id (String), url (String), new_charge (String), new_total (Integer), status (String)
 */
const setUrlToInvoice = function (invoice_data) {
  let invoiceFound = normalizedTableRows.value.findIndex(
    (row) => row.invoice === invoice_data.invoice_id
  );
  if (invoiceFound > -1) {
    // Update status
    normalizedTableRows.value[invoiceFound]["status"] = invoice_data.status;

    // Update url
    normalizedTableRows.value[invoiceFound]["url"] = invoice_data.url;

    // Update price
    normalizedTableRows.value[invoiceFound]["amount"] = formatNumberToCurrency(
      parseFloat(invoice_data.new_total)
    );

    // Update description
    if (invoice_data.new_charge) {
      normalizedTableRows.value[invoiceFound][
        "charges"
      ] = `${invoice_data.new_charge}, ${normalizedTableRows.value[invoiceFound]["charges"]}`;
    }

    // Update action method to open url of invoice
    normalizedTableRows.value[invoiceFound]["actions"] = {
      type: "button",
      text: "Pay",
      action: () => {
        window.open(invoice_data.url, "_blank");
      },
    };
  }
};
</script>
