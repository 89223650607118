<template>
  <div
    class="flex h-[3.813rem] max-h-[3.813rem] justify-between items-center px-6 flex-auto"
    :class="[secondaryColor ? `bg-gray-275` : `bg-university-accentPrimary`]"
  >
    <div
      data-testid="title"
      class="font-bold text-sm text-indigo-base uppercase"
    >
      {{ title }}
    </div>
    <div>
      <button
        v-if="showBookmark"
        data-testid="bookmark-button"
        class="flex gap-2 justify-center items-center"
        @click.stop="bookmarkClicked"
      >
        <span
          class="mx-1 text-11p font-bold uppercase text-indigo-base opacity-60"
          >Save</span
        >
        <img
          src="/images/bookmark.png"
          class="object-contain"
          alt="Bookmark"
          width="24"
          height="24"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["bookmarkClicked"]);

const props = defineProps({
  title: { type: String, default: "" },
  actionId: { type: String, default: "" },
  showBookmark: { type: Boolean, default: false },
});

const bookmarkClicked = () => {
  emit("bookmarkClicked", props.actionId);
};
</script>
