import actions from "./actions";
import mutations from "./mutations";
import state from "./state";
import getters from "./getters";
import university from "./modules/university";
import programManager from "./modules/programManager";
import experience from "./modules/experience";
import configurator from "./modules/configurator";
import learnerTypes from "./modules/learnerTypes";
import tagManager from "./modules/tags";
import programSessions from "./modules/programSessions";
import events from "./modules/events";
import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";

export default createStore({
  strict: true,
  state,
  mutations,
  actions,
  getters,
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  modules: {
    university,
    programManager,
    tagManager,
    experience,
    programSessions,
    events,
    configurator,
    learnerTypes,
  },
});
