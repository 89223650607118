<script setup>
import ToolTip from "@/components/ExperiencePage/Misc/ToolTip.vue";
import ProgramDates from "@/components/ProgramDates";
import { getSingleProgramSession } from "@/components/program-manager/sessions/composable";
import SimpleBreadCrumb from "@/components/shared/SimpleBreadCrumb.vue";
import Table from "@/components/shared/Table/SimpleTable.vue";
import { DEFAULT_GOOGLE_MAP_ZOOM } from "@/constants";
import { buildStringDateRangeSessionTravel } from "@/mixins/helpers";
import internshipService from "@/services/internship";
import { computed, ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";
import _ from "lodash";

const route = useRoute();
const router = useRouter();
const store = useStore();
const internshipId = ref(route.query["internship-id"]);
const sessionId = ref(route.query["session-id"]);

const qualiDContentFilled = ref([]);
const qualifyingData = ref([
  {
    title: "Academic Levels",
    type: "list-text",
    content: [],
    filled: true,
  },
  {
    title: "Language Proficiency",
    type: "list-text",
    content: [],
    filled: false,
  },
  {
    title: "Min-Max age (If applicable)",
    type: "simple-text",
    content: "",
    filled: true,
  },
  {
    title: "Required Skills",
    type: "list-item",
    content: [],
    filled: false,
  },
  {
    title: "Level-Up Skills",
    type: "list-item",
    content: [],
    filled: true,
  },
]);

const posiDDContentFilled = ref([]);
const positionDetailsData = ref([
  {
    title: "Session Dates",
    type: "simple-text",
    content: "",
    filled: true,
  },
  {
    title: "Length",
    type: "simple-text",
    content: "",
    filled: false,
  },
  {
    title: "Number of Positions",
    type: "list-text",
    content: [],
    filled: true,
  },
  {
    title: "Placement Type",
    type: "simple-text",
    content: "",
    filled: false,
  },
  {
    title: "Paid or Unpaid",
    type: "simple-text",
    content: "",
    filled: true,
  },
  {
    title: "Position Perks",
    type: "list-join-text",
    separator: " | ",
    content: [],
    filled: false,
  },
  {
    title: "Language of Internship",
    type: "simple-text",
    content: "",
    filled: true,
  },
  {
    title: "Schedule",
    type: "list-schedule-text",
    separator: " | ",
    content: [
      {
        weekDay: "",
        time: "",
      },
      {
        weekDay: "",
        time: "",
      },
      {
        weekDay: "",
        time: "",
      },
      {
        weekDay: "",
        time: "",
      },
      {
        weekDay: "",
        time: "",
      },
    ],
    filled: false,
  },
]);

const oktaId = computed(() => store.state.currentUser?.participantId ?? "");
const profileData = computed(() => store.state.profileData);

onMounted(async () => {
  // Get profile data if we have a profile id
  if (oktaId.value && _.isEmpty(profileData.value)) {
    await store.dispatch("getProfile", oktaId.value);
  }

  if (sessionId.value)
    executeGetSingleProgramSession(0, {
      home_institution_id: profileData.value?.colleges?.[0]?.college_id ?? "",
    });
});

const internship = ref({});
const internshipLoading = ref(true);
internshipService
  .getOne(internshipId.value)
  .then((r) => {
    internship.value = r.data;
    qualifyingData.value[0].content = internship.value.academic_levels.map(
      (item) => item.academic_year
    );
    qualifyingData.value[1].content = internship.value.required_languages.map(
      (item) => {
        return `${item.value}: ${item.proficiency_level}`;
      }
    );
    if (internship.value.maximum_age && internship.value.minimum_age)
      qualifyingData.value[2].content = `Min: ${internship.value.minimum_age}, Max: ${internship.value.maximum_age}`;
    qualifyingData.value[3].content = internship.value.needed_skills.map(
      (item) => item.value
    );
    qualifyingData.value[4].content = internship.value.gained_skills.map(
      (item) => item.value
    );

    if (internship.value.duration)
      positionDetailsData.value[1].content = internship.value.duration;
    if (
      internship.value?.terms?.length &&
      positionDetailsData.value?.length > 2
    )
      positionDetailsData.value[2].content = internship.value.terms.map(
        ({ name, capacity = 0 }) => `${name}: ${capacity} available`
      );
    if (internship.value.placement_types)
      positionDetailsData.value[3].content = internship.value.placement_types
        .map((item) => item.value)
        .join(", ");
    if (internship.value.compensation)
      positionDetailsData.value[4].content = internship.value.compensation;
    if (internship.value.perks.length)
      positionDetailsData.value[5].content = internship.value.perks.map(
        (item) => item.value
      );
    if (internship.value.required_languages) {
      const language = getNativeLanguage(internship.value.required_languages);
      positionDetailsData.value[6].content =
        language === "English" ? "" : language;
    }
    if (internship.value.schedule)
      positionDetailsData.value[7].content = getScheduleForTable(
        internship.value.schedule
      );
  })
  .finally(() => {
    // Filter only rows with content for QUALIFYING AND APPLYING and POSITION DETAILS tables
    qualifyingDataWithoutEmpty();
    positionDetailsDataWithoutEmpty();

    internshipLoading.value = false;
  });

const GooglePlacesApiKey = computed(() => {
  return process.env.MIX_UI_GOOGLE_PLACES_API_KEY;
});

const latLon = computed(() => {
  if (!internship.value.latitude || !internship.value.longitude) {
    const fallBackLocation =
      internship?.value?.city_data?.lat_lng?.split(", ") ?? [];
    return {
      lat: fallBackLocation?.[0],
      lon: fallBackLocation?.[1],
    };
  }
  return {
    lat: internship?.value?.latitude,
    lon: internship?.value?.longitude,
  };
});

const generateMapUrl = (googleApiKey, latitude, longitude) => {
  return `https://maps.googleapis.com/maps/api/staticmap?key=${googleApiKey}&center=${latitude},${longitude}&zoom=${DEFAULT_GOOGLE_MAP_ZOOM}&format=png&maptype=roadmap&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&size=1200x360&markers=color:red%7C${latitude},${longitude}`;
};

const mapUrl = computed(() => {
  const location = latLon.value;
  if (!location.lat || !location.lon) return "";
  return generateMapUrl(GooglePlacesApiKey.value, location.lat, location.lon);
});

const workLocation = computed(() => {
  return (
    internship.value?.work_locations?.map((item) => item.value)?.join(", ") ??
    ""
  );
});

const getScheduleForTable = () => {
  return Object.entries(internship.value?.schedule?.description ?? {}).map(
    ([weekDay, child]) => ({
      weekDay,
      time:
        child?.availability[0]?.start && child?.availability[0]?.end
          ? `${child.availability[0].start}-${child.availability[0].end}`
          : "",
    })
  );
};

const showSaveButton = computed(() => {
  return store.state.featureFlags["saving-items"];
});
const getNativeLanguage = (languages) => {
  return (
    languages?.find((item) => item.proficiency_level === "Native")?.value ?? ""
  );
};

const {
  execute: executeGetSingleProgramSession,
  isLoading: sessionLoading,
  state: sessionData,
} = getSingleProgramSession(sessionId.value, false);

const industries = computed(() =>
  internship.value?.industries
    ?.map((item) => item?.career_pathway)
    ?.filter(Boolean)
    ?.join(" / ")
);

const dates = computed(() =>
  sessionData.value?.session_travel_details?.length
    ? buildStringDateRangeSessionTravel(
        sessionData.value?.session_travel_details
      )
    : ""
);

const weeksInInternship = ref("");
const sortWeekDays = (index) => {
  const map = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  posiDDContentFilled?.value[index]?.content?.sort((a, b) => {
    return map[a.weekDay.toLowerCase()] - map[b.weekDay.toLowerCase()];
  });
};

const insertWeeks = (index) => {
  let nWeeks = posiDDContentFilled.value?.[index]?.content;
  if (typeof nWeeks === "number") {
    posiDDContentFilled.value[index].content = `${nWeeks}  Week${
      nWeeks !== 1 ? "s" : ""
    }`;
    weeksInInternship.value = posiDDContentFilled.value[index].content;
  }
};

const positionDetailsDataWithoutEmpty = () => {
  posiDDContentFilled.value = [];
  positionDetailsData.value.forEach((pd) => {
    if (pd.content.length || (typeof pd.content === "number" && pd.content)) {
      if (Array.isArray(pd.content) && typeof pd.content[0] === "object") {
        if (pd.content[0].weekDay || pd.content[0].time) {
          posiDDContentFilled.value.push(pd);
          sortWeekDays(posiDDContentFilled.value.length - 1);
        }
      } else posiDDContentFilled.value.push(pd);
      if (pd.title === "Length") {
        insertWeeks(posiDDContentFilled.value.length - 1);
      }
    }
  });
  greyBackground(posiDDContentFilled);
};

const qualifyingDataWithoutEmpty = () => {
  qualiDContentFilled.value = [];
  qualifyingData.value.forEach((qd) => {
    if (qd.content.length) {
      qualiDContentFilled.value.push(qd);
    }
  });
  greyBackground(qualiDContentFilled);
};

const greyBackground = (arr) => {
  arr.value.forEach((e, i) => {
    //to fix the tabel background
    if (i > 0) {
      if (arr.value[i - 1].filled) arr.value[i].filled = false;
      else arr.value[i].filled = true;
    }
  });
};

watch(
  () => [dates.value, internship.value.terms],
  ([newDates, newTerms]) => {
    // Set Session Dates section
    const termsNames = newTerms?.map(({ name }) => name) || [];
    positionDetailsData.value[0].content = `${newDates}, ${termsNames.join(
      ", "
    )}`;
  }
);

const redirectToConfigurator = () => {
  const programId = route.query["program-id"];

  if (programId) {
    router.push({
      name: "configurator-new",
      params: { programPageId: programId },
      query: {
        session: sessionData.value.id,
        internships: [internship.value.id],
      },
    });
  }
};
</script>

<template>
  <div class="bg-gray-10 md:flex md:flex-col">
    <!-- <ExperienceHeader /> -->
    <SimpleBreadCrumb
      class="!ml-0 md:!ml-[85px] md:mb-2.5 md:mt-[120px]"
      :custom-active-class="`${secondaryColorClass} font-semibold`"
    />
    <div class="md:px-24 lg:px-24">
      <!--- CONTENT ---->
      <div
        v-if="internship !== undefined"
        class="text-base text-indigo-base md:mx-auto"
      >
        <div class="bg-white px-3 pt-3 md:px-24 md:py-12">
          <div class="font-montserrat">
            <!-- SECTION 01 -->
            <div
              class="mt-4 w-full sm:px-0 md:grid md:grid-cols-10 lg:grid lg:grid-cols-10"
            >
              <div class="col-span-5">
                <div>
                  <p class="font-bold uppercase">INTERNSHIP</p>
                  <div
                    v-if="internshipLoading"
                    class="mt-3 flex animate-pulse items-center"
                  >
                    <div class="h-8 w-full bg-blue-300"></div>
                  </div>
                  <p v-else class="text-4xl font-bold">
                    {{ internship?.title || "Internship Title" }}
                  </p>
                  <div class="mt-4 space-y-1">
                    <div v-if="sessionId">
                      <div
                        v-if="sessionLoading"
                        class="flex animate-pulse items-center space-x-4"
                      >
                        <div class="h-4 w-52 bg-blue-300"></div>
                        <div class="h-4 w-16 bg-blue-300"></div>
                      </div>
                      <div
                        v-else-if="sessionData?.session_travel_details"
                        class="flex"
                      >
                        <ProgramDates
                          :id="`card-program-dates-${internshipId}`"
                          :dates="dates"
                          :start-is-final="
                            (sessionData?.session_travel_details?.[0]
                              ?.arrival_date_is_final ?? 0) > 0
                          "
                          :end-is-final="
                            (
                              sessionData?.session_travel_details?.[0]
                                ?.departure_date_is_final
                            ) ?? 0 > 0
                          "
                          tag="p"
                          class="text-gray-600"
                          reverse-icon-order
                          experience-card-icon
                          text-class="font-montserrat uppercase mt-8px font-bold text-indigo-base text-xs sm:text-sm"
                        />
                        <p
                          class="mt-8px pl-1 text-xs uppercase text-indigo-base before:mr-2 before:content-['|'] sm:text-sm"
                        >
                          {{ weeksInInternship }}
                        </p>
                      </div>
                    </div>
                    <p v-if="industries" class="flex items-center text-sm">
                      <span> Industry: </span>
                      <span class="ml-1">
                        {{ industries }}
                      </span>
                    </p>
                  </div>
                </div>
                <!-- BUTTONS -->
                <div
                  class="mt-6 flex flex-col justify-start md:mt-24 md:flex md:flex-row lg:mt-20"
                >
                  <!-- SAVE BUTTON -->
                  <button
                    v-if="showSaveButton"
                    type="button"
                    :class="`order-2 mt-3 flex h-12 w-full flex-row items-center justify-center border-[3px] ${secondaryColorClassOutlinedNoHover} bg-white p-5 md:order-1 md:mt-0 md:w-64 lg:w-64`"
                  >
                    <div
                      class="flex items-center gap-1 text-base font-bold uppercase tracking-tight"
                    >
                      <span class="mr-2">SAVE</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m19 21l-7-5l-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                        />
                      </svg>
                    </div>
                  </button>
                  <!-- SELECT BUTTON -->
                  <button
                    type="button"
                    class="order-1 mt-3 box-border flex h-12 max-h-60p w-full flex-row items-center justify-center p-5 text-white md:order-2 md:mt-0 md:w-[280px] lg:mt-0 lg:w-[280px]"
                    :class="showSaveButton ? `md:ml-8 lg:ml-8 ${secondaryColorClassBG}` : `${secondaryColorClassBG}`"
                    @click="redirectToConfigurator"
                  >
                    <div
                      class="flex items-center gap-1 text-base font-bold uppercase tracking-tight"
                    >
                      SELECT INTERNSHIP
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-right"
                      >
                        <polyline points="9 18 15 12 9 6" />
                      </svg>
                    </div>
                  </button>
                </div>
                <!-- ABOUT THE COMPANY -->
                <div class="mt-12">
                  <p class="font-bold">ABOUT THE COMPANY</p>
                  <hr class="mr-3 mt-4 text-gray-450" />
                  <p class="mt-4">
                    <span class="font-semibold">Work location: </span>
                    {{ workLocation }}
                  </p>
                  <p class="mt-4">
                    <span class="font-semibold">HQ:</span>
                    {{ internship.city }},
                    {{ internship.country }}
                  </p>
                  <div class="mt-4 flex items-center">
                    <span class="font-semibold"> Organization Size </span>
                    <tool-tip />
                    <span class="mr-1">:</span>
                    <span>{{
                      internship.company ? internship.company.company_size : ""
                    }}</span>
                  </div>
                  <p class="mt-4">
                    <span>{{
                      internship.company ? internship.company.description : ""
                    }}</span>
                  </p>
                </div>
              </div>
              <!-- MAPS -->
              <div class="w-92 mt-4 md:col-span-5 md:mt-0 md:px-3 md:py-6">
                <div class="relative">
                  <img
                    src="/images/group01.png"
                    class="top-48 z-0 hidden object-fill md:absolute md:block"
                  />
                  <img
                    v-if="mapUrl"
                    :src="mapUrl"
                    class="relative z-10 w-full"
                  />
                  <p class="z-5 relative mt-3 font-bold uppercase">
                    WORK LOCATION
                  </p>
                </div>
              </div>
            </div>
            <!-- SECTION 02 -->
            <div
              class="mt-9 w-full sm:px-0 md:grid md:grid-cols-10 lg:grid lg:grid-cols-10"
            >
              <div class="col-span-5">
                <!-- QUALIFYING AND APPLYING -->
                <div v-if="qualiDContentFilled?.length" class="mt-12">
                  <p class="font-bold">QUALIFYING AND APPLYING</p>
                  <!-- table -->
                  <div class="mt-10">
                    <Table
                      id="qualifying-and-applying-table"
                      :data="qualiDContentFilled"
                    />
                  </div>
                </div>
                <!-- POSITION RESPONSIBILITES -->
                <div v-if="internship.responsibilities?.content" class="mt-12">
                  <p class="font-bold">POSITION RESPONSIBILITES</p>
                  <div class="mt-3">
                    <hr class="text-gray-450" />
                    <JSONtoHTML
                      class="ml-3 mt-3 json-to-html"
                      :tiptap-json="internship.responsibilities"
                    />
                  </div>
                </div>
                <!-- POSITION DETAILS -->
                <div v-if="posiDDContentFilled?.length" class="mt-12">
                  <p class="font-bold">POSITION DETAILS</p>
                  <div class="mt-3">
                    <hr class="text-gray-450" />
                    <div class="mt-3">
                      <Table
                        id="position-details-table"
                        :data="posiDDContentFilled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- BOTTON BUTTONS -->
          <div class="mb-20 w-full">
            <div
              class="mt-8 w-full md:mt-24 md:grid md:grid-cols-12 lg:grid lg:grid-cols-12"
            >
              <!-- SAVE BUTTON -->
              <div class="md:col-span-8"></div>
              <div
                class="col-start-7 col-end-12 flex w-full flex-col md:flex md:flex-row"
              >
                <button
                  v-if="showSaveButton"
                  type="button"
                  :class="`order-2 mt-3 flex h-12 w-full flex-row items-center justify-center border-[3px] ${secondaryColorClassOutlinedNoHover} bg-white p-5 text-sm md:order-1 md:mt-0 md:w-64 lg:w-64`"
                >
                  <div
                    class="flex items-center gap-1 text-base font-bold uppercase tracking-tight"
                  >
                    <span class="mr-2"></span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 21l-7-5l-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                      />
                    </svg>
                  </div>
                </button>
                <!-- SELECT BUTTON -->
                <button
                  type="button"
                  :class="`order-1 mt-3 box-border flex h-12 max-h-60p w-full flex-row items-center justify-center ${secondaryColorClassBG} p-5 text-white md:order-2 md:ml-8 md:mt-0 lg:ml-8 lg:mt-0`"
                  @click="redirectToConfigurator"
                >
                  <div
                    class="flex items-center gap-1 text-base font-bold uppercase tracking-tight"
                  >
                    SELECT INTERNSHIP
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevron-right"
                    >
                      <polyline points="9 18 15 12 9 6" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- END CONTENT ---->
    </div>
  </div>
</template>

<style>
.json-to-html ul {
  @apply ml-4 list-disc;
}

.json-to-html ol {
  @apply ml-4 list-decimal;
}
</style>
