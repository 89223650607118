<template>
  <div>
    <header class="flex items-start justify-between mb-5">
      <div class="sm:flex-wrap sm:flex sm:items-center">
        <h3 class="text-xl font-semibold text-gray-700 sm:mr-4">
          School Support
        </h3>
      </div>
    </header>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 sm:gap-6">
      <label for="firstName" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>First Name</span>
        </span>
        <input
          id="firstName"
          v-model.trim="v$.value.firstName.$model"
          data-cy="first-name-text"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.firstName.$error }"
        />
        <div
          v-if="
            v$.value.firstName.required.$invalid && v$.value.firstName.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.firstName.nameRegex.$invalid"
          class="error text-error-900"
        >
          Field does not match the pattern
        </div>
      </label>

      <label for="lastName" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Last Name</span>
        </span>
        <input
          id="lastName"
          v-model.trim="v$.value.lastName.$model"
          data-cy="last-name-text"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.lastName.$error }"
        />
        <div
          v-if="v$.value.lastName.required.$invalid && v$.value.lastName.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.lastName.nameRegex.$invalid"
          class="error text-error-900"
        >
          Field does not match the pattern
        </div>
      </label>

      <fieldset class="md:col-span-2">
        <legend class="font-semibold text-gray-600">Role</legend>
        <div class="mt-2 md:flex">
          <label
            v-for="role in ROLES"
            :key="role.value"
            class="flex mb-4 md:mb-0 md:flex-auto"
            :for="role.value"
          >
            <input
              :id="role.value"
              v-model="v$.value.role.$model"
              type="radio"
              name="role"
              :value="role.value"
              data-cy="role-radio"
              class="mt-1 text-university-primary form-radio"
              :class="{ 'bg-error-100': v$.value.role.$error }"
            />
            <span class="ml-2">{{ role.label }}</span>
          </label>
        </div>
        <div
          v-if="v$.value.role.required.$invalid && v$.value.role.$error"
          class="error text-error-900 text-sm"
        >
          Field is required
        </div>
      </fieldset>

      <label for="email" class="block text-sm md:col-span-2">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Email</span>
        </span>
        <input
          id="email"
          v-model.trim="v$.value.email.$model"
          type="text"
          data-cy="email-text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.email.$error }"
        />
        <div
          v-if="v$.value.email.required.$invalid && v$.value.email.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="
            v$.value.email.email.$invalid ||
            v$.value.email.validEmailAddress.$invalid
          "
          class="error text-error-900"
        >
          Invalid email address. Please try another email address.
        </div>
      </label>

      <label for="shareInformation" class="inline-flex md:col-span-2">
        <input
          id="shareInformation"
          v-model="v$.value.shareInformation.$model"
          type="checkbox"
          data-cy="share-information-checkbox"
          class="mt-1 text-university-primary form-checkbox"
        />
        <span class="ml-2">
          Share my academic information with my study abroad advisor
        </span>
      </label>

      <div class="my-5 md:col-span-2">
        <ButtonWithSpinner
          ref="universitySupportSubmit"
          data-cy="university-support-submitt-button"
          type="submit"
          variant="secondary"
          variant-type="normal"
          custom-class="text-sm"
          button-height="min-h-8"
          @click="submitForm"
        >
          <span>
            Save
          </span>
        </ButtonWithSpinner>
        <ButtonWithSpinner
          ref="universitySupportCancel"
          data-cy="university-support-cancel-button"
          type="submit"
          variant="secondary-outlined"
          variant-type="normal"
          custom-class="text-sm"
          button-height="min-h-8"
          @click="cancel"
        >
          <span>
            Cancel
          </span>
        </ButtonWithSpinner>
        <div v-if="value.submitError" class="error text-error-900 mt-2">
          {{ value.submitError }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import { nameRegex, validEmailAddress } from "@/mixins/customValidators";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const roles = [
  {
    label: "Study Abroad Advisor",
    value: "studyAbroadAdvisor",
  },
  {
    label: "Financial Advisor",
    value: "financialAdvisor",
  },
];

export default {
  name: "EditView",
  components: {
    ButtonWithSpinner,
  },
  props: {
    itemData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["cancel", "save"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        firstName: "",
        lastName: "",
        role: "",
        email: "",
        shareInformation: false,
        editView: false,
        id: "",
        submitError: "",
      },
    };
  },
  validations: {
    value: {
      firstName: { required, nameRegex },
      lastName: { required, nameRegex },
      role: { required },
      email: {
        required,
        email,
        validEmailAddress,
      },
      shareInformation: {},
      editView: {},
      id: {},
      submitError: "",
    },
  },
  watch: {
    itemData: {
      handler: function (newVal) {
        this.setData(newVal);
      },
      immediate: true,
      deep: true,
    },
    "value.submitError": {
      handler: function (newval) {
        if (newval) this.$refs.universitySupportSubmit.stopLoading();
      },
      immediate: true,
    },
  },
  created() {
    this.setConstants();
  },

  methods: {
    setConstants() {
      this.ROLES = roles;
    },
    setData(item) {
      Object.entries(item).forEach(([key, val]) => {
        this.value[key] = val;
      });
    },
    async submitForm() {
      this.$refs.universitySupportSubmit.startLoading();
      this.value.submitError = "";
      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.$emit("save", this.value);
      } else {
        this.$refs.universitySupportSubmit.stopLoading();
      }
    },
    cancel() {
      this.$emit("cancel", this.value.id);
    },
  },
};
</script>
