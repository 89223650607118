import { learningServiceRequest, skip } from "./utils";

const programsPageService = {
  async getProgramPages({
    limit = 10,
    page = 1,
    q = "",
    source,
    extraParams = {},
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/programming/program_pages",
      source,
      params: {
        limit,
        skip: skip(page, limit),
        q,
        ...extraParams,
      },
      failureMessage: "Error loading programs data",
    });
  },
  async getProgramPage(id, params = {}) {
    return await learningServiceRequest({
      method: "get",
      url: `/programming/program_pages/${id}`,
      params: params,
      failureMessage: "Error loading program page data",
    });
  },
  async updateProgramPage(programPageId, payload) {
    return await learningServiceRequest({
      method: "put",
      url: `/programming/program_pages/${programPageId}`,
      payload: payload,
      failureMessage: "Error updating program page data",
    });
  },
  async createProgramPage(payload) {
    return await learningServiceRequest({
      method: "post",
      url: `/programming/program_pages/`,
      payload: payload,
      failureMessage: "Error creating program page data",
    });
  },
};

export default programsPageService;
