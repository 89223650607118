<script setup>
import { computed, defineEmits, ref, reactive, unref } from "vue";
import { useStore } from "vuex";
import { cloneDeep, debounce } from "lodash";
import BaseSearchBarFilterModal from "@/components/shared/BaseSearchBarFilterModal.vue";
import BasicTextInput from "@/components/shared/input/BasicTextInput.vue";
import CheckboxSelect from "@/components/shared/select/CheckboxSelect.vue";
import {
  useGetCityOptions,
  useSearchCountryOptions,
} from "@/components/program-manager/sessions/composable";
import { getCityCountryOptionLabel } from "@/components/program-manager/sessions/utils";
import { baseFilters } from "./constants";

const store = useStore();

const filter = ref(cloneDeep(baseFilters));

const {
  execute: executeFetchCityOptions,
  isLoading: cityLoading,
  state: cityOptions,
  isReady: cityReady,
} = useGetCityOptions(
  { immediate: true, throwError: true },
  { city_ascii: "chi" }
);

const fetchCityOptions = debounce(async (search, loading) => {
  if (search && search?.length > 2) {
    loading(true);
    try {
      await executeFetchCityOptions(0, { city_ascii: search, cancel: true });
      loading(false);
    } catch (e) {
      if (e?.message !== "cancel") {
        loading(false);
      }
    }
  }
}, 500);

const {
  execute: executeFetchCountryOptions,
  state: countryOptions,
  isLoading: countryOptionsLoading,
} = useSearchCountryOptions({ immediate: true, throwError: true });

const fetchCountryOptions = debounce(async (search, loading) => {
  if (search) {
    loading(true);
    try {
      await executeFetchCountryOptions(0, { q: search, cancel: true });
    } finally {
      loading(false);
    }
  }
}, 250);

const checkMinMaxPrice = () => {
  const min_price = Number(filter.value?.min_price);
  const max_price = Number(filter.value?.max_price);
  if (min_price >= 0 && max_price >= 0 && min_price > max_price) {
    filter.value.max_price = min_price;
    filter.value.min_price = max_price;
  }
};

const getCategories = computed(() => {
  return store.getters["events/getCategories"];
});

const getSubCategories = computed(() => {
  return store.getters["events/getSubCategories"];
});

const emit = defineEmits(["updateFilters"]);

const applyFilters = () => {
  const advancedFilters = Object.keys(filter.value).reduce(
    (acc, cur) => (
      (acc[cur] = Array.isArray(filter.value?.[cur])
        ? filter.value?.[cur].map((item) => unref(item)?.id).filter(Boolean)
        : unref(filter.value?.[cur])),
      acc
    ),
    {}
  );

  if (!Number.isNaN(Number(advancedFilters.min_price))) {
    advancedFilters.min_price *= 100;
  }
  if (!Number.isNaN(Number(advancedFilters.max_price))) {
    advancedFilters.max_price *= 100;
  }
  emit("updateFilters", advancedFilters);
};
const clearFilters = () => {
  filter.value = reactive(cloneDeep(baseFilters));
  emit("updateFilters", filter?.value);
};
</script>

<template>
  <BaseSearchBarFilterModal
    title="Events Filter"
    @apply-filters="applyFilters"
    @clear-filters="clearFilters"
  >
    <template #default="{ isModalOpen }">
      <div>
        <CheckboxSelect
          v-model="filter.cities"
          :clear-search-on-blur="() => false"
          :close-on-select="false"
          :components="{ OpenIndicator: Magnifier }"
          :deselect-from-dropdown="true"
          :get-option-label="getCityCountryOptionLabel"
          :filterable="false"
          :multiple="true"
          :options="cityOptions?.items || []"
          :loading="cityLoading || !cityReady"
          outer-classes="col-span-3"
          field-label="City"
          placeholder="Search and add"
          @search="fetchCityOptions"
        />
        <CheckboxSelect
          v-model="filter.countries"
          :clear-search-on-blur="() => false"
          :close-on-select="false"
          :components="{ OpenIndicator: Magnifier }"
          :deselect-from-dropdown="true"
          :loading="countryOptionsLoading"
          :multiple="true"
          :options="countryOptions"
          outer-classes="col-span-3 mt-6"
          field-label="Country"
          label="name"
          placeholder="Search and add"
          @search="fetchCountryOptions"
        />
        <CheckboxSelect
          v-model="filter.category"
          :clear-search-on-blur="() => false"
          :close-on-select="false"
          :components="{ OpenIndicator: Magnifier }"
          :deselect-from-dropdown="true"
          :multiple="true"
          :options="getCategories"
          outer-classes="col-span-3 mt-6"
          field-label="Category"
          label="name"
          placeholder="Select"
        />
        <CheckboxSelect
          v-model="filter.sub_category"
          :clear-search-on-blur="() => false"
          :close-on-select="false"
          :components="{ OpenIndicator: Magnifier }"
          :deselect-from-dropdown="true"
          :multiple="true"
          :options="getSubCategories"
          outer-classes="col-span-3 mt-6"
          field-label="Sub-category"
          label="name"
          placeholder="Select"
        />
      </div>

      <label class="block font-semibold mt-6 text-sm" for="housing-room_price"
        >Price</label
      >

      <div class="flex w-full gap-4">
        <BasicTextInput
          v-model="filter.min_price"
          class="rounded-sm border-gray-300 w-[160px]"
          outer-classes="flex col-span-1"
          type="number"
          min="0.01"
          step="0.01"
          max="25000000"
          @blur="checkMinMaxPrice"
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">Min $</div>
          </template>
        </BasicTextInput>
        <BasicTextInput
          v-model="filter.max_price"
          class="rounded-sm border-gray-300 w-[160px]"
          outer-classes="flex flex-grow-1 col-span-1"
          type="number"
          min="0.01"
          step="0.01"
          max="25000000"
          @blur="checkMinMaxPrice"
        >
          <template #fieldLabel>
            <div class="mr-3 whitespace-nowrap flex items-center">Max $</div>
          </template>
        </BasicTextInput>
      </div>
    </template>
  </BaseSearchBarFilterModal>
</template>
