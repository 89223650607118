<script setup>
import { computed, defineProps, defineEmits, toRefs } from "vue";
import SimplePopup from "@/components/shared/SimplePopup.vue";
import ApartmentIcon from "@/assets/icons/apartment.svg";
import DormIcon from "@/assets/icons/dorm.svg";
import HotelIcon from "@/assets/icons/hotel.svg";
import HouseIcon from "@/assets/icons/house.svg";
import LocalHostIcon from "@/assets/icons/local-host.svg";
import { INCLUSION } from "@/components/ExperiencePage/ProgramDetailsSection/housing/constants.js";
import { useTier } from "@/composables/useTier";

const icons = {
  Apartment: ApartmentIcon,
  Dorm: DormIcon,
  Hotel: HotelIcon,
  House: HouseIcon,
  "Local Host": LocalHostIcon,
};

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  housingData: {
    type: Object,
    required: true,
  },
  openModal: {
    type: Boolean,
    default: false,
  },
});

const { housingData: housing, openModal } = toRefs(props);

const includedAmenities = computed(
  () =>
    housing.value?.housing_amenities?.filter(
      ({ label }) => label?.toLowerCase() === INCLUSION
    ) || []
);

const otherAmenities = computed(
  () =>
    housing.value?.housing_amenities?.filter(
      ({ label }) => label?.toLowerCase() !== INCLUSION
    ) || []
);

const getIncludedAmenities = computed(() =>
  (
    (includedAmenities.value?.filter(({ title }) => title) || [])?.map(
      ({ title }) => title
    ) || []
  ).join(" | ")
);

const tier = useTier(housing.value?.tier?.name);

const getIcon = (type) => icons[type] ?? ApartmentIcon;

const housingType = computed(() => {
  if (housing.value?.subcategory === "Residencia") {
    return "Residencia";
  } else {
    return housing.value?.type || "";
  }
});
</script>

<template>
  <SimplePopup
    :open="openModal"
    custom-class="z-30 font-montserrat"
    :header-color-class="secondaryColor ? 'bg-gray-275' : 'bg-university-accentPrimary'"
    :scrollable="true"
    @close="emit('closeModal')"
  >
    <template #title>
      <div class="flex">
        <div class="flex self-center">
          <div class="p-1">
            <img
              class="w-10 h-10"
              :alt="housing?.description || ''"
              :src="getIcon(housing.type)"
            />
          </div>
          <div class="self-center pl-2 text-sm">{{ housingType || "" }}</div>
        </div>
        <div class="absolute right-0 mr-6">
          <p class="uppercase text-xs font-bold border py-3 px-3 mr-8">
            {{ tier }}
          </p>
        </div>
      </div>
    </template>
    <template #content>
      <div
        class="text-indigo-base font-medium text-sm lg:max-h-35vh max-h-75vh"
      >
        <div v-if="housing?.included">
          <div class="uppercase font-semibold w-fit mt-2 py-2 text-teal-100">
            Included
          </div>
          <div class="font-bold text-left text-indigo-base text-sm">
            {{ getIncludedAmenities }}
          </div>
        </div>
        <div class="text-justify w-full mt-6">
          {{ housing?.description || "" }}
        </div>
        <div v-if="otherAmenities?.length > 0" class="mt-6 text-left">
          <div class="uppercase font-bold">Amenities</div>
          <ul class="list-disc pl-5">
            <li v-for="(amenity, idx) in otherAmenities" :key="idx">
              {{ amenity?.title || "" }}
            </li>
          </ul>
        </div>
        <div v-if="housing?.tags?.length > 0" class="mt-6 text-left">
          <div class="uppercase font-semibold">Why You'll Love It</div>
          <div class="mt-2 grid grid-cols-2 md:grid-cols-4">
            <span
              v-for="(amenity, idx) in housing?.tags || []"
              :key="idx"
              class="inline-flex items-center rounded-full bg-blue-300 mb-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium text-university-primary"
              >{{ amenity?.name || "" }}</span
            >
          </div>
        </div>
      </div>
    </template>
  </SimplePopup>
</template>
