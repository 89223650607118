<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <FormPanel
        :id="'visaAndPermits'"
        :title="'Visa & Permits'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <form
            id="visa-and-permits-form"
            class="mb-6"
            action=""
            aria-label="Visa and Permits Form"
            @submit.prevent="submitForm()"
          >
            <div class="space-y-6 mb-6">
              <p>
                This program requires you to obtain a visa/permit to enter and
                remain in the country. Click
                <a
                  href="https://apiabroad.com/student-visas/"
                  target="_blank"
                  :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
                  >here</a
                >
                for more detailed information on the visa/immigration process
                for your program. We recommend starting this process ASAP.
              </p>
              <p>
                Once you have your visa, please upload it below. API needs you
                to submit a colored copy.
              </p>
              <p>Upload instructions:</p>
              <ul class="ml-6 list-disc">
                <li>
                  Submit only the page in your passport that shows your visa or
                  permit.
                </li>
                <li>
                  Scan a color copy, ensuring the image is clear and that
                  information is fully legible.
                </li>
              </ul>
            </div>
            <FileUpload
              name="visa-and-permit-upload"
              :formio-files="formioFiles"
              :multiple-files="true"
              :file-types="['*']"
              @updateProgress="updateProgress"
              @updateFiles="updateFiles"
            />

            <ButtonWithSpinner
              ref="visaPermitSubmit"
              type="submit"
              variant="primary"
              variant-type="block"
              :disabled="v$.$invalid"
            >
              <span>
                Submit
                <svg
                  v-if="successSubmission && !v$.$invalid"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="inline feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </span>
            </ButtonWithSpinner>
            <div v-if="submitError" class="error text-error-900 mt-2">
              {{ submitError }}
            </div>
            <div
              v-if="successSubmission && !v$.$invalid"
              class="text-success-900"
            >
              Submission successful. Please watch your email for any necessary
              corrections from your Program Coordinator.
            </div>
          </form>
        </template>
      </FormPanel>
    </template>
  </PageStyle>
</template>

<script>
import FormPanel from "../SharedComponents/panel.vue";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import FileUpload from "../SharedComponents/FileUpload.vue";

import { required } from "@vuelidate/validators";
import formIoApi from "../../../mixins/formIoApi.js";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import useVuelidate from "@vuelidate/core";

const validateFileUpload = (value) => value === false;

export default {
  name: "VisaAndPermitForm",
  components: {
    FormPanel,
    PageStyle,
    FileUpload,
    ButtonWithSpinner,
  },
  mixins: [formIoApi],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        files: [],
      },
      formioFiles: [],
      uploadOnProgress: false,
      uploadErrors: false,
      submissionId: "",
      formURL: "visaandpermits",
      successSubmission: false,
      submitError: "",
    };
  },
  validations: {
    value: {
      files: { required },
    },
    uploadOnProgress: { validateFileUpload },
    uploadErrors: { validateFileUpload },
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  created() {
    this.getFormioData();
  },

  methods: {
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;
          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.formioFiles = response["visa-permit-image"]
                  ? response["visa-permit-image"]
                  : [];
              }
            );
          }
        }
      );
    },
    submitForm() {
      this.submitError = "";
      this.successSubmission = false;

      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.$refs.visaPermitSubmit.startLoading();
        if (this.submissionId) {
          this.updateSubmission();
        } else {
          this.createSubmission();
        }
      }
    },
    getJsonData() {
      return {
        data: {
          application_id: this.applicationId,
          "visa-permit-image": this.value.files,
        },
      };
    },
    updateSubmission() {
      this.submitToFormIo(
        this.formURL,
        this.getJsonData(),
        "PUT",
        this.submissionId
      )
        .then(() => {
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.visaPermitSubmit.stopLoading();
        });
    },
    createSubmission() {
      this.submitToFormIo(this.formURL, this.getJsonData())
        .then((response) => {
          this.submissionId = response;
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.visaPermitSubmit.stopLoading();
        });
    },
    updateProgress(val) {
      this.uploadOnProgress = val;
    },
    updateFiles(files, error) {
      this.value.files = files;
      this.uploadErrors = error;
      this.successSubmission = false;
    },
  },
};
</script>
