<script setup>
import IconElips from "@/components/shared/icons/IconElips.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

const emit = defineEmits([
  "openDuplicateModal",
  "openEditModal",
  "openViewModal",
]);

defineProps({
  isViewOnly: { type: Boolean, default: false },
});

const duplicate = async () => {
  emit("openDuplicateModal");
};

const edit = async () => {
  emit("openEditModal");
};
const view = async () => {
  emit("openViewModal");
};
</script>

<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton
      class="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-gray-700"
    >
      <IconElips />
    </MenuButton>
    <MenuItems
      class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -top-10 right-full bottom-auto"
    >
      <MenuItem v-if="!isViewOnly">
        <div
          class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
          @click="edit"
        >
          Edit
        </div>
      </MenuItem>
      <MenuItem v-if="!isViewOnly">
        <div
          class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
          @click="duplicate"
        >
          Duplicate
        </div>
      </MenuItem>
      <MenuItem v-if="isViewOnly">
        <div
          class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
          @click="view"
        >
          View
        </div>
      </MenuItem>
    </MenuItems>
  </Menu>
</template>
