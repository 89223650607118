<script setup>
import { trackEvent } from "@/util/eventTracker.js";
import { useRouter } from "vue-router";
import { computed, defineProps, ref, onMounted } from "vue";

import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  type: {
    type: String,
    default: "button",
  },
  id: {
    type: String,
    default: "anid",
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onCard: {
    type: Boolean,
    default: false,
  },
  full: {
    type: Boolean,
    default: false,
  },
  eventCategory: {
    type: String,
    default: "",
  },
});

const buttonRef = ref(null);
const hover = ref(false);
const router = useRouter();

const preventClick = (clickEvent) => {
  clickEvent.stopImmediatePropagation();
};

const showTooltip = () => {
  if (buttonRef.value) {
    tippy(buttonRef.value, {
      content: `<div style='display: flex; align-items:center;'>
        <svg class='icon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>  <p style='font-size:.875rem; margin-left:2px;'>Applications are closed for this session.</p>
      </div>`,
      placement: "top",
      arrow: true,
      allowHTML: true,
      theme: "api-info",
    });
  }
};

const onHoverHandler = () => {
  hover.value = true;
  if (props.disabled) {
    showTooltip();
  }
};
const onLeaveHandler = () => {
  hover.value = false;
};
onMounted(() => {
  if (props.disabled) {
    showTooltip();
  }
});

const onClick = () => {
  const shouldTrackClick = Boolean(props.eventCategory);
  if (shouldTrackClick) {
    const eventLabel = router.currentRoute.value.fullPath;
    trackEvent(
      "click",
      props.eventCategory,
      eventLabel,
      store.getters?.getCurrentUser?.participantId
    );
  }
};
const secondaryButtonClasses = computed(() => {
  let classes =
    "inline-flex md:order-1 order-2 flex-row justify-center items-center shadow-sm border-2 px-10 md:px-5 ";
  if (props.disabled) {
    classes += "cursor-not-allowed isDisabled";
  }
  return classes;
});
</script>

<template>
  <span
    v-if="props.disabled"
    ref="buttonRef"
    :class="[
      secondaryColor
        ? props.outlined
          ? `${secondaryColorClassOutlined}  ${secondaryButtonClasses}`
          : `${secondaryColorClassButton}  ${secondaryButtonClasses}`
        : props.outlined
        ? `bg-white text-teal-900 hover:bg-teal-900 hover:text-white border-teal-900 ${secondaryButtonClasses}`
        : `bg-teal-900 text-white hover:bg-white hover:text-teal-900 hover:border-teal-900 ${secondaryButtonClasses}`,
    ]"
    @mouseover="onHoverHandler"
    @mouseleave="onLeaveHandler"
    @click="preventClick"
  >
    <button :id="props.id" :aria-disabled="props.disabled">
      <div class="flex text-center gap-x-3 tracking-tight items-center">
        <span
          class="font-semibold uppercase cursor-not-allowed whitespace-nowrap leading-none"
        >
          Personalize & Apply
        </span>
      </div>
    </button>
  </span>
  <span
    v-else
    ref="buttonRef"
    :class="[
      secondaryColor
        ? props.outlined
          ? `${secondaryColorClassOutlined}  ${secondaryButtonClasses}`
          : `${secondaryColorClassButton}  ${secondaryButtonClasses}`
        : props.outlined
        ? `bg-white text-teal-900 hover:bg-teal-900 hover:text-white border-teal-900 ${secondaryButtonClasses}`
        : `bg-teal-900 text-white hover:bg-white hover:text-teal-900 hover:border-teal-900 ${secondaryButtonClasses}`,
    ]"
    @mouseover="onHoverHandler"
    @mouseleave="onLeaveHandler"
    @click="onClick"
  >
    <button :id="props.id" :aria-disabled="props.disabled">
      <div class="flex text-center gap-x-3 tracking-tight items-center">
        <span class="font-semibold uppercase whitespace-nowrap leading-none">
          Personalize & Apply
        </span>
      </div>
    </button>
  </span>
</template>

<style>
.isDisabled {
  border: 1px solid #cccdcf !important;
  color: #4e4d50 !important;
  background-color: #ffffff !important;
}

.tippy-box[data-theme~="api-info"] {
  background: #edf6f6 !important;
  color: black;
}

.tippy-box[data-theme~="api-info"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: #edf6f6 !important;
}
.tippy-box[data-theme~="api-info"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: #edf6f6 !important;
}
.tippy-box[data-theme~="api-info"][data-placement^="left"]
  > .tippy-arrow::before {
  border-left-color: #edf6f6 !important;
}
.tippy-box[data-theme~="api-info"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: #edf6f6 !important;
}
.icon {
  color: #157f80;
  width: 1.4rem !important;
  height: 1.4rem !important;
}
</style>
