<template>
  <div>
    <div
      :class="`${primaryColorClassBG}`"
      class="fixed inset-0 z-0 hidden opacity-50 sm:block"
    />
    <div
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
    >
      <div
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-32 sm:py-20 sm:rounded-lg sm:shadow"
        role="dialog"
        aria-modal="true"
        aria-labelledby="cancellation-policy-modal-label"
      >
        <div class="flex flex-col items-center mb-10">
          <div class="flex justify-end w-full sm:hidden">
            <button
              class="block h-6 w-6 flex items-center justify-center"
              @click="$emit('close')"
            >
              <span class="sr-only">Close</span>
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
          <h2
            id="info-modal-modal-label"
            class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            <slot name="modal-title" />
          </h2>
        </div>

        <div class="text-gray-600 space-y-6">
          <slot name="default" />
        </div>

        <div class="mt-12">
          <button
            :class="[
              tertiaryColor
                ? `${tertiaryColorClass}`
                : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
            ]"
            class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-gray-700"
            @click="$emit('close')"
          >
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreditHomeInstitution",
  emits: ["close"],
};
</script>
