import { useAsyncState } from "@vueuse/core";
import bedService from "@/services/beds";
import axios from "axios";

export const useGetBeds = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return bedService.getBeds(params).then((response) => {
        return {
          data: response?.data?.data ?? [],
          count: response?.data?.count ?? 0,
        };
      });
    },
    { count: 0, data: [] },
    asyncStateOptions
  );
};
