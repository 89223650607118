<template>
  <div>
    <p class="text-gray-600 mt-2">
      To change, defer, or withdraw, please contact your program manager:
    </p>
    <div class="flex flex-row mt-4 space-y-5">
      <template v-if="hasAllInfo">
        <img src="/images/icon_mailLetter.svg" />
        <div>
          <p class="font-semibold">
            {{ managerName }}
          </p>
          <p class="text-gray-600">
            {{ managerMail }}
          </p>
        </div>
      </template>
      <template v-else>
        <p class="mt-2">
          This program does not have a manager assigned, please try contacting:
          <a
            :href="`mailto:api${APICompany.companyEmail}`"
            :class="`${secondaryColorClass}`"
            class="font-semibold"
          >
            api@apiexperience.com
          </a>
        </p>
      </template>
    </div>
    <p class="mt-2">
      for more information about programs you can switch to or how to defer or
      withdraw from your program.
    </p>
  </div>
</template>

<script>
import { APICompany } from "../../constants";
export default {
  name: "EnrollmentProgramChange",
  props: {
    managerName: {
      type: String,
      default: "",
    },
    managerMail: {
      type: String,
      default: "",
    },
  },
  data() {
    return { APICompany, secondaryColor: "" };
  },
  computed: {
    hasAllInfo() {
      return this.managerName && this.managerMail;
    },
  },
};
</script>
