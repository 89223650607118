<template>
  <div class="text-indigo-base bg-white">
    <div class="m-14 font-montserrat">
      <div class="md:flex items-stretch gap-10">
        <div class="md:w-2/4 lg:w-7/12">
          <ImageSlider :image-paths="imagePaths" />
        </div>
        <div class="md:flex-1 flex flex-col">
          <div class="flex-grow">
            <div
              class="flex flex-wrap pb-3 mb-3 justify-between items-end border-b border-gray-25"
            >
              <p class="uppercase text-sm font-bold pt-4 md:mt-0">
                {{ subcategory }}
              </p>
              <div class="flex gap-2 order-first md:order-last mt-10 md:mt-0">
                <p
                  class="py-1 px-2 uppercase font-bold "
                  :class="[secondaryColor ? `text-indigo-base bg-gray-275` : 'text-teal-900 bg-teal-400 bg-opacity-10']"
                >
                  {{ price }}
                </p>
              </div>
            </div>
            <h1 class="text-4xl text-indigo-base font-bold leading-none">
              {{ title }}
            </h1>

            <div class="mt-2 text-sm">{{ address }}</div>

            <div class="mt-2 text-sm whitespace-pre-wrap">
              {{ duration }}
            </div>
          </div>

          <div class="align-baseline mt-10 flex md:justify-end">
            <button
              v-if="!isExcursionIncluded"
              :class="`flex-grow md:flex-grow-0 ${secondaryColorClassBG} text-white border-2 p-3 md:p-4 uppercase font-bold flex items-center justify-center`"
              @click="selectExcursion(props.detailExcursion.id)"
            >
              <span class="inline-block">
                {{
                  props.selectedExcursions.includes(props.detailExcursion.id)
                    ? "Selected"
                    : "Select excursion"
                }}
              </span>
              <div class="inline-block ml-3 py-1">
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -1.01583e-06L-7.14641e-07 1.71183L5.38866 7.00874Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="mt-20 gap-10">
        <JSONtoHTML
          class="w-full text-lg font-medium text-indigo-base"
          :tiptap-json="description"
        />
        <div class="md:w-2/4 lg:w-7/12">
          <div class="mt-20">
            <SubtitleSection title="Event details" />
            <Table :data="tableData" />
          </div>
        </div>
      </div>
    </div>
    <StickyFooter
      max-next-width="max-w-[260px]"
      :show-required-section="false"
      :disabled-next="props.disabled"
      @back="toSelectEvents"
      @next="goToNextStep"
    />
  </div>
</template>

<script setup>
import { computed, defineProps, inject } from "vue";
import {
  COMMUNICATION,
  MOBILITY,
  SENSORY,
  DEFAULT_EXCURSION_IMAGE,
} from "@/constants";
import ImageSlider from "@/components/Configurator/widgets/ImageSlider.vue";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";
import StickyFooter from "@/components/Configurator/StickyFooter.vue";
import SubtitleSection from "@/components/Configurator/Housing/SubtitleSection.vue";
import Table from "@/components/shared/Table/SimpleTable.vue";
import { getRidOfJSON } from "@/util/removeJson";

const GOOGLE_PLACES_API_KEY = process.env.MIX_UI_GOOGLE_PLACES_API_KEY;
const ZOOM = 15;

const toSelectEvents = inject("toSelectEvents");
const { goToNextStep } = inject("steps");

const excursionRules = inject("excursionRules");
const selectExcursion = inject("selectExcursion");
const { configuratorOptions } = inject("configuratorOptions") ?? {};

const props = defineProps({
  detailExcursion: {
    type: Object,
    default: () => {},
  },
  selectedExcursions: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: true,
  },
  defaultExcursion: {
    type: Object,
    default: () => {},
  },
});

const event = computed(() => props.detailExcursion?.event ?? {});
const location = computed(() =>
  event.value?.event_locations?.length ? event.value.event_locations[0] : {}
);
const subcategory = computed(() => event.value?.event_subcategory?.name ?? "");
const imagePaths = computed(() =>
  event.value?.image
    ? getRidOfJSON(event.value?.image)
    : [DEFAULT_EXCURSION_IMAGE]
);

const price = computed(() => {
    const excursions = configuratorOptions.event_occurrences.filter((r) => r.productId === props.detailExcursion?.id);
    if (excursions && excursions.length > 0) {
      return excursions[0].excursionPriceLabel(isExcursionIncluded.value);
    }
    return "";
});

const isExcursionIncluded = computed(() => {
  return (
    excursionRules?.value?.defaultIds?.includes(props.detailExcursion.id) ||
    excursionRules?.value?.andIds?.includes(props.detailExcursion.id)
  );
});

const title = computed(() => event.value?.title ?? "");
const address = computed(() => {
  let city = location.value?.city?.city ?? "";
  let country = location.value?.city?.country?.name ?? "";
  if (!city && !country) return "";

  return `${city}, ${country}`;
});
const duration = computed(() =>
  event.value?.duration && event.value?.duration_units
    ? `${event.value?.duration} ${event.value?.duration_units}`
    : ""
);
const includes = computed(() => event.value?.includes ?? []);
const description = computed(() => event.value?.description ?? {});

const mapSource = computed(() => {
  const latitude = location.value?.address?.lat;
  const longitude = location.value?.address?.long;

  if (latitude && longitude) {
    return `https://maps.googleapis.com/maps/api/staticmap?key=${GOOGLE_PLACES_API_KEY}&center=${latitude},${longitude}&zoom=${ZOOM}&format=png&maptype=roadmap&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&size=1200x360&markers=color:red%7C${latitude},${longitude}`;
  }
  return undefined;
});

const transformAccessibilityItem = (values, validValues) => {
  const ids = JSON.parse(values) ?? [];

  return validValues
    .filter((item) => ids.includes(item.id))
    .map((item) => item.name);
};
const accessibility = computed(() => {
  const communication = transformAccessibilityItem(
    location.value?.communication ?? "[]",
    COMMUNICATION
  );
  const mobility = transformAccessibilityItem(
    location.value?.mobility ?? "[]",
    MOBILITY
  );
  const sensory = transformAccessibilityItem(
    location.value?.sensory ?? "[]",
    SENSORY
  );

  return [...communication, ...mobility, ...sensory];
});

const tableData = computed(() => {
  let tableRows = [];
  let filled = false;

  if (includes.value.length) {
    filled = !filled;
  }

  if (event.value?.transportation) {
    filled = !filled;

    tableRows.push({
      title: "Transportation",
      type: "simple-text",
      content: event.value.transportation,
      filled: filled,
    });
  }

  if (event.value?.activity_level) {
    filled = !filled;

    tableRows.push({
      title: "Activity Level",
      type: "simple-text",
      content: event.value.activity_level,
      filled: filled,
    });
  }

  if (event.value?.packing_list?.length) {
    filled = !filled;

    tableRows.push({
      title: "What to pack",
      type: "simple-text",
      content: event.value.packing_list.join(",  "),
      filled: filled,
    });
  }

  if (accessibility.value.length) {
    filled = !filled;

    tableRows.push({
      title: "Accessibility",
      type: "simple-text",
      content: accessibility.value.join(",  "),
      filled: filled,
    });
  }

  if (event.value?.host) {
    filled = !filled;

    tableRows.push({
      title: "Organized By",
      type: "simple-text",
      content: event.value?.host,
      filled: filled,
    });
  }

  return tableRows;
});
</script>
