<template>
  <spinner
    v-if="state.loading"
    :outer-classes="
      secondaryColor
        ? `h-64 flex items-center justify-center bg-gray-275`
        : `h-64 flex items-center justify-center bg-teal-light`
    "
    :show-spinner-content="true"
    data-testid="order-summary-spinner"
  >
    <template #spinnercontent>Putting together your experience...</template>
  </spinner>
  <div
    v-else
    :class="[secondaryColor ? `bg-gray-275` : `bg-teal-light`]"
    data-testid="order-summary"
  >
    <slot name="summary-header">
      <h3
        class="font-montserrat text-base text-indigo-base"
        data-testid="configurator-order-summary"
      >
        {{ city }}, {{ country }}
      </h3>
      <h2
        class="font-bold text-2xl text-indigo-base"
        data-testid="order-program-name"
      >
        {{ programNameDisplay }}
      </h2>
      <hr class="my-4 border-configurator-order-summary-gray" />
      <table
        class="w-full text-xs text-indigo-base"
        data-testid="order-summary-table"
      >
        <tr v-if="programDuration" data-testid="program-duration-row">
          <td class="">Program Duration</td>
          <td class="text-right font-semibold">
            {{ programDuration }}
          </td>
        </tr>
        <tr v-if="terms.length > 0" data-testid="terms-row">
          <td v-if="terms.length > 1" class="flex items-start">
            <div class="h-4">Terms</div>
          </td>
          <td v-else class="flex items-start">
            <div class="h-4">Term</div>
          </td>
          <td class="text-right font-semibold">
            <ul>
              <li v-for="(term, index) in terms" :key="index" class="h-4">
                <div v-if="index < terms.length - 1" class="w-full">
                  {{ term?.name }},
                </div>
                <div v-else class="w-full">{{ term?.name }}</div>
              </li>
            </ul>
          </td>
        </tr>
        <tr
          v-if="applicationDeadline && !hideSessionDeadline"
          data-testid="application-deadline-row"
        >
          <td class="">Application Deadline</td>
          <td class="text-right font-semibold">
            {{ applicationDeadline }}
          </td>
        </tr>
        <tr v-if="programDates.length" data-testid="program-dates-row">
          <td>
            <div class="h-12 flex items-start">
              <div class="w-full h-4">Program Dates</div>
            </div>
          </td>
          <td class="text-right flex w-full gap-1 items-start justify-end">
            <DatesFinalizedIcon
              :icon-id="dateId"
              :program-dates-finalized="programDatesNotFinalized"
              class="w-4 h-6"
              data-testid="program-dates-icon"
            />
            <div class="text-right font-semibold h-12 w-fit">
              <ul>
                <li
                  v-for="(date, index) in programDates"
                  :key="index"
                  class="h-4"
                  data-testid="program-date-item"
                >
                  {{ date }}
                  <span v-if="index < programDates.length - 1">-</span>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
      <hr class="my-4 border-configurator-order-summary-gray" />
    </slot>

    <div data-testid="selections-section">
      <h2
        class="font-bold font-montserrat flex items-center justify-between text-base my-4"
      >
        <p class="text-indigo-base text-lg" data-testid="selections-title">
          Selections
        </p>
      </h2>
      <div
        v-if="showBreakdown"
        class="border-collapse gap-3"
        data-testid="breakdown-section"
      >
        <!-- COURSES-->
        <div
          v-if="showCoursesSection"
          class="pb-3"
          data-testid="courses-section"
        >
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <div class="flex justify-between px-1 py-3 items-center">
              <TitleGroupSummary :title="'Courses'" />
            </div>
            <!-- Courses credits -->
            <div
              v-if="currentOrder?.credits"
              class="px-1 flex flex-col"
              data-testid="courses-credits-section"
            >
              <div class="flex items-center">
                <p class="text-indigo-base font-semibold">
                  {{ currentOrder?.credits }} Credits
                </p>
              </div>
              <div
                v-if="shouldCalculateAdditionalCredits"
                class="text-indigo-base font-semibold text-xs p-1 leading-none"
                data-testid="additional-credits"
              >
                * Additional credit fee will be determined during course
                registration.
              </div>
            </div>
          </div>
        </div>
        <!-- INTERNSHIP-->
        <div
          v-if="showInternshipSection"
          class="pb-3"
          data-testid="internship-section"
        >
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <!-- Internship selection info -->
            <div class="flex justify-between px-1 py-3 items-center">
              <TitleGroupSummary :title="'Internships'" />
            </div>
          </div>
          <div
            v-for="(internshipProduct, index) in internshipProductListings"
            :key="internshipProduct.product_id"
            class="flex px-1 justify-between"
            data-testid="internship-item"
          >
            <ItemLinkSummary
              :name="`internship-${index}`"
              :title="internshipProduct.product_name"
              @handle-click="
                () =>
                  handleClick('internship', {
                    'internship-id': internshipProduct.product_id,
                  })
              "
            />
          </div>
        </div>
        <!-- HOUSING-->
        <div
          v-if="showHousingSection"
          class="pb-3"
          data-testid="housing-section"
        >
          <hr class="border-configurator-order-summary-gray" />
          <div class="flex justify-between px-1 py-3 items-center">
            <TitleGroupSummary
              :title="'Housing'"
              :data-testid="'housing-title-summary'"
            />
          </div>
          <div
            v-for="(housingProduct, index) in housingProductListings"
            :key="housingProduct.product_id"
            class="flex px-1 justify-between"
            data-testid="housing-item"
          >
            <ItemLinkSummary
              :name="`housing-${index}`"
              :title="housingProduct.product_name"
              @handle-click="
                () =>
                  handleClick('housing', {
                    'room-id': housingProduct.product_id,
                  })
              "
            />
          </div>
        </div>
        <!-- EXCURSIONS-->
        <div
          v-if="showExcursionSection"
          class="pb-3"
          data-testid="excursion-section"
        >
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <!-- Excursion item description -->
            <div class="flex justify-between px-1 py-3 items-center">
              <TitleGroupSummary
                :title="'Excursions'"
                :data-testid="'excursion-title-summary'"
              />
            </div>
          </div>
          <!-- EXCURSIONS LIST -->
          <div
            v-for="(excursionProductListing, index) in excursionProductListings"
            :key="excursionProductListing?.product_id"
            class="flex px-1 justify-between"
          >
            <ItemSummary
              :name="`excursion-${index}`"
              :title="excursionProductListing.product_name"
              @handle-click="
                () =>
                  handleClick('excursion', {
                    'event-id': excursionProductListing.product_id,
                  })
              "
            />
          </div>
        </div>

        <div
          v-if="addonProductListings.length > 0"
          class="pb-3"
          data-testid="addons-section"
        >
          <hr
            class="border-configurator-order-summary-gray"
            data-testid="addons-divider-summary"
          />
          <div>
            <div
              class="flex justify-between px-1 py-3 items-center"
              data-testid="addons-header-summary"
            >
              <TitleGroupSummary
                :title="'Add-ons'"
                :data-testid="'addons-title-summary'"
              />
            </div>
          </div>
          <div
            v-for="addon in addonProductListings"
            :key="addon?.product_id"
            class="flex px-1 justify-between"
            data-testid="addons-option-summary"
          >
            <ItemSummary
              name="addons"
              :title="addon?.product_name"
              @handle-click="
                () => goToConfiguratorStep(addon.configuratorStepToEdit)
              "
              data-testid="addons-item-summary"
            />
          </div>
        </div>
        <div
          v-if="groupFlightExpense"
          class="pb-3"
          data-testid="group-flight-section"
        >
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <!-- Group Flight item description -->
            <div class="flex justify-between p-1 py-3 items-center">
              <TitleGroupSummary
                :title="'Group Flight'"
                :data-testid="'group-flight-title-summary'"
              />
              <!-- <check-icon v-if="!props.isAttachedToEnrollment" :class="[`${secondaryColorClass}`]" /> -->
            </div>
          </div>
          <div class="flex px-1 justify-between">
            <ItemSummary
              name="group-flight"
              :title="groupFlightExpense?.product_name"
              :show-edit-buttons="false"
              data-testid="group-flight-item"
            />
          </div>
        </div>
        <div
          v-if="publicTransportationExpense"
          class="pb-3"
          data-testid="public-transportation-section"
        >
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <div class="flex justify-between px-1 py-3 items-center">
              <TitleGroupSummary
                :title="'Public Transportation Card'"
                data-testid="public-transportation-title"
              />
            </div>
          </div>
          <div class="flex px-1 justify-between">
            <ItemSummary
              name="public-transportation"
              :title="publicTransportationExpense?.product_name"
              :show-edit-buttons="false"
              data-testid="public-transportation-item"
            />
          </div>
        </div>
        <div
          v-if="insuranceExpenses?.length"
          class="pb-3"
          data-testid="insurance-section"
        >
          <hr class="border-configurator-order-summary-gray" />
          <div>
            <!-- insuranceExpense item description -->
            <div class="flex justify-between px-1 py-3 items-center">
              <TitleGroupSummary :title="'Insurance'" />
            </div>
          </div>
          <div
            v-for="insuranceExpense in insuranceExpenses"
            :key="insuranceExpense?.product_id"
            class="flex px-1 justify-between"
            data-testid="insurance-item"
          >
            <ItemSummary
              name="insurance"
              :title="insuranceExpense?.product_name"
              :show-edit-buttons="false"
            />
          </div>
        </div>
        <div class="pb-3" data-testid="footer-divider">
          <hr class="border-configurator-order-summary-gray" />
        </div>
      </div>
      <slot name="call-to-action"> </slot>
    </div>
  </div>
</template>

<script setup>
import Spinner from "@/components/helpers/Spinner";
import { DURATION_IN_DAYS_THRESHOLD, SESSION_TYPE } from "@/constants";
import {
  GROUP_FLIGHT,
  MEDICAL_INSURANCE,
  PUBLIC_TRANSPORTATION_CARD,
  SPAIN_MEDICAL_INSURANCE,
  UMASS_TRANSCRIPT_SERVICES,
  UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES,
} from "@/components/program-manager/sessions/constants";
import { formatDate } from "@/mixins/helpers";
import ordersService from "@/services/orders";
import { computed, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import DatesFinalizedIcon from "../Configurator/widgets/DatesFinalizedIcon.vue";
import ItemLinkSummary from "./ItemLinkSummary.vue";
import ItemSummary from "./ItemSummary.vue";
import TitleGroupSummary from "./TitleGroupSummary.vue";

const toast = useToast();
const props = defineProps({
  currentOrder: {
    type: Object,
    default: () => {},
  },
  showConfirmationPayment: {
    type: Boolean,
    default: false,
  },
  isAttachedToEnrollment: {
    type: Boolean,
    default: false,
  },
});
const router = useRouter();

const state = reactive({
  loading: true,
  expanded: true,
  pricing: {},
});

const currentOrder = computed(() => props.currentOrder);

const showCoursesSection = computed(
  () => (currentOrder.value?.credits ?? 0) > 0
);

const showInternshipSection = computed(
  () => internshipProductListings?.value?.length > 0
);

const showHousingSection = computed(
  () => housingProductListings?.value?.length > 0
);

const showExcursionSection = computed(
  () => excursionProductListings?.value?.length > 0
);

const getViewName = (slug) => {
  if (slug === "internship") return "internship-product-page-view";
  if (slug === "housing") return "housing-product-page-view";
  // Need to create the page and then enable it here for excursion,
  // have a test ready for this, when enable just need to remove the skip from
  // tests/playwright/test-enrollment.spec.ts -> Open excursion in a new tab when clicked
  // if (slug === "excursion") return "excursion-product-page-view"
};

const handleClick = (slug, additionalQueryParams = {}) => {
  const enrollmentHandledLinks = ["housing", "internship"];
  if (!enrollmentHandledLinks.includes(slug)) return;

  const name = getViewName(slug);

  const { program_page_id } = currentOrder.value;
  let to = {
    name,
    params: {
      slug,
    },
    query: {
      ...additionalQueryParams,
      "program-id": program_page_id,
      "session-id": currentOrder.value.session_id,
    },
  };
  const { href } = router.resolve(to);
  window.open(href, "_blank");
};

const applicationDeadline = computed(() =>
  formatDate(state?.pricing?.session_application_deadline, "long")
);

//PROGRAM DATES SECTION
const dateId = computed(() => {
  return "icon-date-id";
});
const programDates = computed(() => {
  const startDateString = state?.pricing?.session_start_date;
  const endDateString = state?.pricing?.session_end_date;
  if (startDateString && endDateString) {
    return [
      formatDate(startDateString, "long"),
      formatDate(endDateString, "long"),
    ];
  }
  return [];
});
const programDatesNotFinalized = computed(() => {
  return (
    !state?.pricing?.session_academic_end_date_finalized ||
    !state?.pricing?.session_academic_start_date_finalized
  );
});

//PROGRAM NAME SECTION
const programNameDisplay = computed(
  () => state?.pricing?.program_name ?? state?.pricing?.session_name ?? ""
);

const shouldCalculateAdditionalCredits = computed(() => {
  const minCredits = state?.pricing?.session_academic_min_credits ?? 0;
  const maxCredits = state?.pricing?.session_academic_max_credits ?? Infinity;
  return (
    currentOrder.value.credits &&
    !(
      currentOrder.value.credits >= minCredits &&
      currentOrder.value.credits <= maxCredits
    )
  );
});

const housingProductListings = computed(() => {
  const pricedProducts = state?.pricing?.rooms ?? [];
  const pricedProductsById = Object.fromEntries(
    pricedProducts.map((prod) => [prod?.product_id ?? "", prod])
  );
  return currentOrder.value?.room_ids?.map((id) => pricedProductsById[id])
    .filter((obj) => Boolean(obj)) ?? [];
});

const internshipProductListings = computed(() => {
  const pricedProducts = state?.pricing?.internships ?? [];
  const pricedProductsById = Object.fromEntries(
    pricedProducts.map((prod) => [prod?.product_id ?? "", prod])
  );
  return currentOrder.value?.internship_ids?.map((id) => pricedProductsById[id])
    .filter((obj) => Boolean(obj)) ?? [];
});

const excursionProductListings = computed(() => {
  const pricedProducts = state?.pricing?.event_occurrences ?? [];
  const pricedProductsById = Object.fromEntries(
    pricedProducts.map((prod) => [prod?.product_id ?? "", prod])
  );
  return currentOrder.value?.event_occurrence_ids?.map((id) => pricedProductsById[id])
    .filter((obj) => Boolean(obj)) ?? [];
});

const addonProductListings = computed(() => {
  const listings = state?.pricing?.addons ?? [];
  return listings.map((listing) => {
    const transcriptServiceIds = [
      UMASS_TRANSCRIPT_SERVICES,
      UNIVERSIDAD_CONGRESSO_TRANSCRIPT_SERVICES,
    ].map((product) => product.id);
    if (transcriptServiceIds.includes(listing.product_id)) {
      return {
        ...listing,
        configuratorStepToEdit: "credits",
      };
    }
    return listing;
  });
});

const updateProductData = async (newCurrentOrder) => {
  if (newCurrentOrder?.session_id) {
    const order_id = newCurrentOrder?.id ?? newCurrentOrder?.orderId ?? null;
    state.loading = true;

    ordersService
      .getOrdersPricing({
        payload: { ...newCurrentOrder, order_id: order_id },
      })
      .then((pricingResponse) => {
        state.pricing = pricingResponse?.data?.data ?? {};
      })
      .catch(() => {
        toast.open({
          message: "Failed to get load product information",
          type: "error",
          position: "bottom",
          duration: 5000,
        });
      })
      .finally(() => {
        state.loading = false;
      });
  }
};

const showBreakdown = computed(() => state.expanded);

watch(() => props.currentOrder, updateProductData, {
  immediate: true,
  deep: true,
});

const programDuration = computed(() => {
  const durationInDays = state?.pricing?.session_duration_in_days;
  if (!durationInDays) {
    return undefined;
  }

  if (durationInDays < DURATION_IN_DAYS_THRESHOLD) {
    return `${durationInDays} day${durationInDays > 1 ? "s" : ""}`; // Pluralize the word 'day' if necessary
  } else {
    const durationInWeeks = Math.ceil(durationInDays / 7);
    return `${durationInWeeks} week${durationInWeeks > 1 ? "s" : ""}`; // Pluralize the word 'week' if necessary
  }
});

const isFacultyLed = computed(() => {
  const sessionTypes = state?.pricing?.session_types ?? [];
  return sessionTypes
    .map((type) => type.id)
    .includes(SESSION_TYPE.facultyLed.id);
});

const hideSessionDeadline = computed(() => isFacultyLed.value);

const insuranceExpenses = computed(() => {
  const additionalFees = state?.pricing?.additional_fees ?? [];
  return additionalFees.filter((fee) => {
    return [SPAIN_MEDICAL_INSURANCE.id, MEDICAL_INSURANCE.id].includes(
      fee?.product_id
    );
  });
});

const publicTransportationExpense = computed(() =>
  state?.pricing?.additional_fees?.find(
    (val) => val?.product_id === PUBLIC_TRANSPORTATION_CARD.id
  )
);

const groupFlightExpense = computed(() =>
  state?.pricing?.additional_fees?.find(
    (val) => val?.product_id === GROUP_FLIGHT.id
  )
);

const city = computed(() => state?.pricing?.session_city ?? "");
const country = computed(() => state?.pricing?.session_country ?? "");
const terms = computed(() => state?.pricing?.session_terms ?? []);
</script>
