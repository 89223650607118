<template>
  <div>
    <div class="relative pt-6 sm:p-6 my-1">
      <div class="flex justify-between items-center">
        <h1 class="text-xl font-semibold leading-6 text-gray-900">
          House Rules
        </h1>
        <RequiredToSavePublish />
      </div>

      <div class="mt-8">
        <label
          for="house_rules"
          class="block text-sm font-semibold text-gray-700"
        >
          House Rules
        </label>
        <div class="flex flex-col items-end">
          <textarea
            :value="getCurrentEditingRules"
            rows="8"
            name="house_rules"
            maxlength="1000"
            class="mt-1 block w-full form-input sm:text-sm"
            @input="setCurrentEditingRules($event.target.value)"
          />
          <span class="text-sm mt-2 font-semibold text-gray-600">{{
            charactersLeft
          }}</span>
        </div>
      </div>

      <div>
        <label
          for="house_rules"
          class="block text-sm font-semibold text-gray-700"
        >
          Housing Curfew
        </label>
        <div
          v-for="(day, key) in curfew"
          :key="key"
          class="relative mt-1 mb-6 flex items-center space-x-6 h-6"
        >
          <div class="flex w-30 h-6 items-center">
            <div class="flex items-center">
              <input
                v-model="day.enabled"
                type="checkbox"
                class="h-4 w-4 form-checkbox bg-white rounded border-gray-300 text-blue-400"
              />
            </div>
            <div class="ml-3 text-sm">
              <label class="font-semibold text-gray-700 capitalize">
                {{ key }}
              </label>
            </div>
          </div>
          <div class="flex items-center space-x-10">
            <label
              v-if="day.enabled"
              class="flex-2 w-28 font-semibold text-sm text-gray-700"
            >
              Doors close at
            </label>
            <div v-else class="flex-2 w-28">
              &nbsp;
            </div>
            <TimeInput
              v-if="day.enabled"
              :initial-hours="day.availability[0].end"
              @handleTimeInput="updateCurfewEndTime(key, $event)"
            />
            <div v-else>
              <label class="ml-6 font-normal text-md text-gray-800">
                No curfew
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="space-y-6 sm:flex sm:items-start sm:space-y-0 sm:space-x-14">
        <div>
          <label
            for="house_rules"
            class="block text-sm font-semibold text-gray-700"
          >
            Smoking Allowed?
          </label>
          <div class="flex space-x-20 mt-2">
            <div
              v-for="smokingOption in radioOptions"
              :key="smokingOption"
              class="flex items-center space-x-2"
            >
              <div class="mt-2 flex items-center">
                <input
                  type="radio"
                  :checked="getCurrentEditingSmoking == smokingOption"
                  :value="smokingOption"
                  class="w-4 h-4 form-radio"
                  @input="setCurrentEditingSmoking($event.target.value)"
                />
                <label class="ml-3 block text-sm font-medium text-gray-700">
                  {{ smokingOption }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isHousingTypeApartment || isHousingTypeDorm">
          <label
            for="house_rules"
            class="block text-sm font-semibold text-gray-700"
          >
            <span class="text-red-100 pr-2 align-sub">**</span>Guests Allowed?
          </label>
          <div class="mt-2 block md:w-96">
            <v-select
              :model-value="getCurrentEditingGuestAllowed"
              :options="guestOptions"
              :class="{
                'forced-error-background': !!v$.getCurrentEditingGuestAllowed
                  .$errors.length,
              }"
              @update:modelValue="setGuestAllowed($event)"
            ></v-select>
          </div>
          <div
            v-if="v$.getCurrentEditingGuestAllowed.$errors.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.getCurrentEditingGuestAllowed.$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>

        <div v-if="visitorCheckInInputOpen">
          <label
            for="house_rules"
            class="block text-sm font-semibold text-gray-700"
          >
            Visitor check-in required?
          </label>
          <div class="flex space-x-20 mt-2">
            <div
              v-for="visitorOption in radioOptions"
              :key="visitorOption"
              class="flex items-center space-x-2"
            >
              <div class="mt-2 flex items-center">
                <input
                  :checked="getCurrentEditingGuestCheckin === visitorOption"
                  type="radio"
                  :value="visitorOption"
                  class="w-4 h-4 form-radio"
                  @input="setCurrentEditingGuestCheckin($event.target.value)"
                />
                <label class="ml-3 block text-sm font-medium text-gray-700">
                  {{ visitorOption }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { helpers, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import TimeInput from "../TimeInput/index.vue";
import RequiredToSavePublish from "../RequiredToSavePublish/index.vue";
import housingService from "../../../../../services/housings";

const guestAllowedRequiredConditions = helpers.withMessage(
  "This field is required to Publish",
  requiredIf(function () {
    return (
      this.getCurrentHousingStatus === "Active" && this.isHousingTypeApartment
    );
  })
);

const jsonParseAndIgnore = (val) => {
  try {
    return JSON.parse(val);
  } catch (e) {
    return housingService.getDefaultHousingCurfew();
  }
};

export default {
  name: "HouseRulesTab",
  components: {
    TimeInput,
    RequiredToSavePublish,
  },
  props: {
    originalHousingRecord: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate({
        $registerAs: "HouseRulesTab",
        $lazy: true,
        $autoDirty: true,
      }),
    };
  },
  data() {
    return {
      curfew: this.originalHousingRecord?.curfew
        ? jsonParseAndIgnore(this.originalHousingRecord?.curfew)
        : housingService.getDefaultHousingCurfew(),
      radioOptions: ["Yes", "No"],
      guestOptions: [
        "Overnight guests",
        "Day visitors",
        "No guests allowed (day or evening)",
      ],
      showVisitorCheckinInputRequired: false,
    };
  },
  validations: {
    getCurrentEditingGuestAllowed: { required: guestAllowedRequiredConditions },
    getCurrentEditingRules: { required: requiredIf(() => false) },
    curfew: { required: requiredIf(() => false) },
    getCurrentEditingSmoking: { required: requiredIf(() => false) },
    getCurrentEditingGuestCheckin: { required: requiredIf(() => false) },
  },
  computed: {
    ...mapGetters("programManager", [
      "getLoading",
      "getCurrentEditing",
      "getCurrentEditingSmoking",
      "getCurrentEditingRules",
      "getCurrentEditingGuestAllowed",
      "getCurrentEditingGuestCheckin",
      "getCurrentHousingStatus",
    ]),
    isHousingTypeApartment() {
      return this.getCurrentEditing.type === "Apartment";
    },
    isHousingTypeDorm() {
      return this.getCurrentEditing.type === "Dorm";
    },
    charactersLeft() {
      return `${this.getCurrentEditingRules?.length || 0}/1000`;
    },
    visitorCheckInInputOpen() {
      return (
        (this.isHousingTypeApartment || this.isHousingTypeDorm) &&
        this.showVisitorCheckinInputRequired
      );
    },
  },
  created() {
    this.showVisitorCheckinInputRequired =
      this.getCurrentEditingGuestAllowed !== this.guestOptions[2];

    if (!this.getCurrentEditingGuestAllowed) {
      this.showVisitorCheckinInputRequired = false;
    }
  },
  methods: {
    ...mapActions("programManager", [
      "setCurrentEditingSmoking",
      "setCurrentEditingRules",
      "setCurrentEditingGuestAllowed",
      "setCurrentEditingGuestCheckin",
      "setCurrentEditingCurfew",
    ]),
    updateCurfewEndTime(day, value) {
      this.curfew[day].availability[0].end = value.toUpperCase();
      this.setCurrentEditingCurfew(JSON.stringify(this.curfew));
    },
    setGuestAllowed(value) {
      this.setCurrentEditingGuestAllowed(value);
      if (!value) {
        this.showVisitorCheckinInputRequired = false;
      } else {
        this.showVisitorCheckinInputRequired =
          this.getCurrentEditingGuestAllowed !== this.guestOptions[2];
      }
    },
  },
  watch: {
    "originalHousingRecord.curfew": {
      handler: function (current) {
        this.curfew = current
          ? jsonParseAndIgnore(current)
          : housingService.getDefaultHousingCurfew();
      },
    },
  },
};
</script>
<style scoped>
.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
</style>
