import * as Sentry from "@sentry/vue";
export default {
  methods: {
    throwSentryError(error, errorTags) {
      Sentry.captureException(new Error(error), {
        tags: errorTags,
      });
    },
  },
};
