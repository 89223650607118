<template>
  <div v-if="alertVisible" data-cy="alert-container" class="flex mt-12">
    <div
      class="flex flex-col w-full rounded-md p-6"
      :class="[alertType, dropShadow, addAccent]"
      :style="customStyles"
    >
      <div v-if="dismissible" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            id="dismiss-alert"
            aria-label="Dismiss Alert"
            class="block h-6 w-6 flex"
            @click="hideAlert"
          >
            <span class="sr-only">Dismiss</span>
            <div id="alert-icon">
              <img src="images/icon-times.svg" />
            </div>
          </button>
        </div>
      </div>
      <div class="flex flex-row items-center">
        <div v-if="showAlertIcon" id="alert-icon" class="mr-4">
          <img src="images/icon-warning.svg" class="iconwarning" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Alert Component
 * Props:
 *  success, warning, info & danger are supported to customize the alert box color, default is a white background
 *  maxWidth: alert box will extend full space available, use this prop to limit the width
 *  height: customize the height of the alert box
 *  dropShadow: pass in a tailwind class for drop shadow, defaults to custom from zeplin design
 *  accent: shows a border in left side of alert box
 */

export default {
  name: "Alerts",
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "",
    },
    minHeight: {
      type: String,
      default: "80px",
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    dropShadow: {
      type: String,
      default: "default-drop-shadow",
    },
    accent: {
      type: Boolean,
      default: false,
    },
    showAlertIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alertVisible: true,
    };
  },
  computed: {
    alertType() {
      if (this.success) {
        return "bg-success-500 border-success-900";
      }

      if (this.info) {
        return "bg-info-500 border-info-900";
      }

      if (this.danger) {
        return "bg-error-500 border-error-900";
      }

      if (this.warning) {
        return "bg-warning-500 border-warning-900";
      }

      return "default-white border-gray-500 bg-gray-900";
    },
    addAccent() {
      return this.accent ? "border-l-4" : "";
    },
    customStyles() {
      let styles = "";
      if (this.maxWidth) {
        styles += "max-width:" + this.maxWidth + ";";
      }

      if (this.minHeight) {
        styles += "min-height:" + this.minHeight + ";";
      }

      return styles;
    },
  },
  methods: {
    hideAlert() {
      this.alertVisible = false;
    },
  },
};
</script>

<style scoped>
.default-drop-shadow {
  box-shadow: 0 4px 8px 0 #f1f2f4;
}
.default-white {
  background-color: #fff;
}
</style>
