<template>
  <div class="flex flex-col flex-grow">
    <div
      v-if="editor"
      :class="[
        vuelidateErrors.length
          ? 'block pl-2 min-h-10 w-full rounded border text-red-900 border-red-300 pr-10 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
          : '',
        editable
          ? 'editor  border border-solid border-gray-300 rounded shadow-sm'
          : '',
      ]"
      class="flex flex-col text-black bg-white"
    >
      <MenuBar
        v-if="displayHeader"
        class="editor__header flex items-center flex-wrap"
        :editor="editor"
        :editable="editable"
      />
      <EditorContent
        class="editor__content !text-indigo-base !font-montserrat flex-auto"
        :class="{ 'editor__content--resizable': isResizable }"
        :editor="editor"
      />
    </div>
    <div v-show="showLimit" v-if="editor" class="text-right text-sm">
      {{ editor.storage.characterCount.characters() }}/{{ characterLimit }}
    </div>
    <div v-if="vuelidateErrors.length">
      <div v-for="error of vuelidateErrors" :key="error.$uid">
        <p class="mt-2 text-sm text-red-600">{{ error.$message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import MenuBar from "@/components/forms/SharedComponents/WYSIWYG/MenuBar.vue";

import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import CharacterCount from "@tiptap/extension-character-count";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";

export default {
  components: {
    EditorContent,
    MenuBar,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default() {
        return {};
      },
    },

    modelValue: {
      type: [Object, String, Number],
      default() {
        return {};
      },
    },
    vuelidateErrors: {
      type: Array,
      default: () => [],
    },
    characterLimit: {
      type: Number,
      default: 350,
    },
    showLimit: {
      type: Boolean,
      default: false,
    },
    displayHeader: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["updateContent", "update:modelValue"],
  data() {
    return {
      editor: null,
      isResizable: this.resizable,
    };
  },
  computed: {
    jsonContent() {
      return this.editor ? this.editor.getJSON() : {};
    },
  },
  watch: {
    jsonContent: {
      handler: function (val) {
        /*
          *****IMPORTANT***** Create updateContent method on the parent component.
          This will return a JSON with the content, will be triggered each time the content changes
        */
        this.$emit("updateContent", val);
        this.$emit("update:modelValue", val);
      },
    },
    editable: {
      handler: function (val) {
        this.editor.setEditable(val);
      },
    },
    content: {
      handler: function (val) {
        if (JSON.stringify(this.editor.getJSON()) === JSON.stringify(val))
          return;
        this.editor.commands.setContent(val);
      },
    },
  },
  mounted() {
    this.editor = new Editor({
      editable: this.editable,
      content: !Object.entries(this.content).length ? "" : this.content,
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false,
        }),
        CharacterCount.configure({
          limit: this.characterLimit,
        }),
      ],
    });
    if (Object.prototype.hasOwnProperty.call(this.modelValue, "content")) {
      this.editor.commands.setContent(this.modelValue.content);
    }
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style>
.editor {
  max-height: 26rem;
}
.editor__header {
  flex: 0 0 auto;
  padding: 0.55rem;
  border-bottom: 1px solid #e5e7e8;
}
.editor__content--resizable {
  resize: vertical;
}
.editor__content {
  padding: 1.25rem 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 9rem;
  -webkit-overflow-scrolling: touch;
}

.ProseMirror-focused:focus,
.ProseMirror:focus {
  outline: none !important;
}
/* Styles for mobile devices */
@media (max-width: 768px) {
  .ProseMirror ul li {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 1rem; /* Adjust the spacing between each list item at the bottom */
  }
  .ProseMirror ul li::before {
    content: "\2022"; /* Use the Unicode character for a bullet point (disc) */
    position: absolute;
    left: 0;
  }
  .ProseMirror ol li {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  .ProseMirror ol li::before {
    content: counters(list-item, ".") ".";
    counter-increment: list-item;
    position: absolute;
    left: 0;
  }
}

/* Styles for desktop devices */
@media (min-width: 769px) {
  .ProseMirror ul {
    padding: 0 1rem;
    list-style: disc;
  }
  .ProseMirror ol {
    padding: 0 1rem;
    list-style: decimal;
  }
}

.ProseMirror a {
  font-weight: 600;
  text-decoration: underline;
  color: #007f80;
}
</style>
