<script setup>
import MaintenanceIcon from "@/components/shared/icons/MaintenanceIcon.vue";
import Button from "@/components/shared/Button/BaseButton.vue";

const props = defineProps({
  showApplicationTakenModal: {
    type: Boolean,
    default: false,
  },
  redirect: {
    type: Boolean,
    default: true,
  },
});
defineEmits(["close"]);
const redirectToMyApplications = () => {
  window.location.href = "/applications";
};
</script>
<template>
  <div class="m-auto mt-16 px-3 md:w-2/6 items-center flex flex-col space-y-8">
    <MaintenanceIcon />
    <div class="text-indigo-base text-center text-2.5xl font-semibold">
      Oops, you already have an application for this program session.
    </div>
    <div class="text-lg text-center">
      Please Visit <span><a href="/applications">"My Applications"</a></span> To
      Resume Your Application
    </div>
    <div v-if="!redirect" class="w-full flex justify-center">
      <Button class="!h-16 md:!w-80 uppercase w-full md:mt-6 mt-36">
        Got it
      </Button>
    </div>
    <div v-else class="w-full flex items-center justify-center">
      <button
        :id="`card-apply-btn-${setId}`"
        type="button"
        class="box-border flex items-center justify-center w-full md:mt-6 mt-36 md:w-96 max-h-60p p-5 md:max-w-260p border-2 border-teal-400 text-teal-400"
        @click="redirectToMyApplications"
      >
        <div
          class="flex gap-1 font-bold uppercase text-base tracking-tight items-center"
        >
          <span>MY APPLICATIONS </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6" />
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
