<template>
  <component
    :is="tag"
    :type="type"
    :disabled="buttonIsDisabled"
    :class="[btnClass, colorVariants, customClass, buttonHeight]"
    :variant="variant"
    :variant-type="variantType"
    :href="to"
  >
    <slot />
  </component>
</template>

<script>

export default {
  name: "ButtonWithSpinner",
  props: {
    tag: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    variantType: {
      type: String,
      default: "normal",
    },
    type: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    buttonHeight: {
      type: String,
      default: "min-h-10",
    },
    greyDisabledClass: {
      type: Boolean,
      default: false,
    },
    propLoading: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      buttonIsDisabled: this.disabled,
    };
  },
  computed: {
    btnClass() {
      return {
        "base-spinner": this.loading === true,
        "cursor-not-allowed": this.buttonIsDisabled,
        "inline-block text-center rounded font-semibold border-2 border-transparent px-4": true,
      };
    },
   
    // eslint-disable-next-line vue/return-in-computed-property
    colorVariants() {
      switch (this.variant) {
        case "secondary":
          switch (this.variantType) {
            case "normal":
              switch (this.buttonIsDisabled) {
                case true:
                  return [
                    this.secondaryColor
                      ? "opacity-33 cursor-not-allowed text-white brandingSecondary"
                      : "opacity-33 cursor-not-allowed text-white bg-university-secondary",
                  ];
                default:
                  break;
              }
              return [
                this.secondaryColor
                  ? "text-white brandingSecondary hover:text-white focus:text-white"
                  : "text-white bg-university-secondary hover:text-white focus:text-white hover:bg-teal-100 focus:bg-teal-100",
              ];
            case "block":
              if (this.buttonIsDisabled) {
                const disabledDefaultClass =
                  "cursor-not-allowed block w-full text-center rounded font-semibold border-2 border-transparent px-4 ";
                return this.greyDisabledClass
                  ? `${disabledDefaultClass} bg-gray-275 text-gray-485 border-gray-485 grey-disabled`
                  : `${disabledDefaultClass} opacity-33 text-white ${this.secondaryColorClassBG}`;
              } else {
                return [
                  this.secondaryColor
                    ? "block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white brandingSecondary hover:text-white focus:text-white"
                    : "block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white bg-university-secondary hover:text-white focus:text-white hover:bg-teal-100 focus:bg-teal-100",
                ];
              }
            default:
              break;
          }
          break;
        case "secondary-outlined":
          switch (this.variantType) {
            case "normal":
              switch (this.buttonIsDisabled) {
                case true:
                  return "opacity-33 cursor-not-allowed text-gray-400 ";
                default:
                  break;
              }
              return [
                this.secondaryColor
                  ? "brandingOutlined hover:text-white focus:text-white border-university-secondary"
                  : "text-university-secondary border-university-secondary hover:text-white focus:text-white hover:bg-teal-100 focus:bg-teal-100 hover:border-teal-100",
              ];
            case "block":
              if (this.buttonIsDisabled) {
                return [
                  this.secondaryColor
                    ? "opacity-33 cursor-not-allowed block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white brandingSecondary"
                    : "opacity-33 cursor-not-allowed block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white bg-university-secondary",
                ];
              } else {
                return [
                  this.secondaryColor
                    ? "block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white brandingSecondary hover:text-white focus:text-white"
                    : "block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white bg-university-secondary hover:text-white focus:text-white hover:bg-teal-100 focus:bg-teal-100",
                ];
              }
            default:
              break;
          }
          break;
        case "primary":
          switch (this.variantType) {
            case "normal":
              if (this.buttonIsDisabled) {
                return [
                  this.tertiaryColor
                    ? "opacity-33 cursor-not-allowed inline-block text-center rounded font-semibold border-2 border-transparent px-4 brandingTertiary text-gray-700"
                    : "opacity-33 cursor-not-allowed inline-block text-center rounded font-semibold border-2 border-transparent px-4 bg-university-tertiary text-gray-700",
                ];
              } else {
                return [
                  this.tertiaryColor
                    ? "inline-block text-center rounded font-semibold border-2 border-transparent px-4 brandingTertiary text-gray-700"
                    : "inline-block text-center rounded font-semibold border-2 border-transparent px-4 bg-university-tertiary text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900",
                ];
              }

            case "block":
              if (this.buttonIsDisabled) {
                return [
                  this.tertiaryColor
                    ? "opacity-33 cursor-not-allowed block w-full text-center rounded font-semibold border-2 border-transparent px-4 brandingTertiary text-gray-700"
                    : "opacity-33 cursor-not-allowed block w-full text-center rounded font-semibold border-2 border-transparent px-4 bg-university-tertiary text-gray-700",
                ];
              } else {
                return [
                  this.tertiaryColor
                    ? "block w-full text-center rounded font-semibold border-2 border-transparent px-4 brandingTertiary text-gray-700"
                    : "block w-full text-center rounded font-semibold border-2 border-transparent px-4 bg-university-tertiary text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900",
                ];
              }

            default:
              break;
          }
          break;
        default:
          break;
      }
    },
  },
  watch: {
    disabled: function (newVal) {
      this.buttonIsDisabled = newVal;
    },
    propLoading: {
      handler(loading) {
        if (loading !== undefined) {
          this.loading = loading;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    document.documentElement.style.setProperty(
      "--branding-secondary",
      this.secondaryColor
    );
    document.documentElement.style.setProperty(
      "--branding-tertiary",
      this.tertiaryColor
    );
  },
  methods: {
    startLoading() {
      this.loading = true;
      this.buttonIsDisabled = true;
    },
    stopLoading() {
      this.loading = false;
      this.buttonIsDisabled = false;
    },
  },
};
</script>
<style scoped>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

button:disabled {
  height: 58px; /* Override height when disabled */
}

.base-spinner {
  position: relative;
  overflow: hidden;
}

.base-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  background-color: inherit;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  top: 0;
  left: 0;
}

.base-spinner:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.45);
  border-top-color: inherit;
  animation: spinner 0.6s linear infinite;
  z-index: 2;
}

.grey-disabled:disabled {
  opacity: 1;
}
</style>
