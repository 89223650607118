<template>
  <div v-if="open">
    <div :class="elementClasses" />
    <div :class="modalClasses" @click.self="selfClose">
      <div :class="contentClasses" data-testid="modal-content">
        <div
          v-if="showCloseButton"
          class="absolute xs:top-1r sm:top-3r md:top-3r lg:top-3r right-1r sm:right-3r"
        >
          <button
            class="block h-6 w-6 flex items-center justify-center"
            data-testid="close-modal-button"
            @click="close()"
          >
            <span class="sr-only">Close</span>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#007F80"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <slot name="modalHeader">
          <div class="flex flex-col items-center justify-end">
            <img v-if="showLogo" class="w-44 object-contain h-20" :src="logo" />
            <h2
              v-if="title"
              class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
            >
              {{ title }}
            </h2>
          </div>
        </slot>
        <slot name="modal-content" />
        <div :class="footerClass ? footerClass : 'mt-6'">
          <slot name="modal-footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ElementClasses =
  "fixed inset-0 z-50 hidden bg-university-primary opacity-50 sm:block";
const ModalClasses =
  "fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12";
const ContentClasses =
  "max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-24 sm:py-20 sm:rounded-lg sm:shadow z-20 relative";
export default {
  name: "SimpleModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    footerClass: {
      type: String,
      default: "",
    },
  },
  emits: ["closeModal"],
  data() {
    return {
      modalClasses: ModalClasses,
      contentClasses: ContentClasses,
      elementClasses: ElementClasses,
      logo: localStorage.logoUrl,
    };
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    selfClose() {
      if (this.closeOnOutsideClick) this.$emit("closeModal");
    },
  },
};
</script>
