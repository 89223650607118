export const EVENTS_INCLUDE_FIELDS = [
  "event_locations",
  "host",
  "description",
  "created_at",
  "updated_at",
  "updated_by",
  "type",
];

export const HEADERS_MAP = {
  // key: must match our header (see programManager.js->fetchEventsData->headers variable)
  // value: must match a column/relationship in the event model from the events-service
  Name: "title",
  Category: "event_category",
  Location: "event_type",
  "Event Host": "host",
  Description: "description",
  Status: "status",
  "Sub-category": "",
  Duration: ["duration", "duration_units"],
  "Mode of Experience": "type",
  Address: "transportation",
  "Radius of allowed participants": "radius",
  "Online Location": "online_location",
  "Location Type": "location_type",
  "Getting to the event": "transportation",
  "Seasonal Availability": "terms_available",
  "Relevant Academic Themes": "event_academic_theme",
  "Price per Student": "price_student",
  "Age Requirement": "age_requirement",
  Tags: "tags",
  "What is included": "includes",
  "Activity level": "activity_level",
  Itinerary: "itinerary",
  "What should participants pack": "packing_list",
  "What housing is available": "accommodation",
  "Housing Description": "accommodation_description",
  "Hours of operation": "",
  "This event is primarily": "isOutdoors",
  Mobility: "",
  Communication: "",
  Sensory: "",
  "This event is attended by": "primarily_attended_by",
  "Booking instructions": "",
  "API Event Insights": "",
  "Vendor Name": "",
  "Vendor Email": "",
  "Vendor Phone": "",
  "Vendor Whatsapp": "",
  "Vendor URL": "",
  "Vendor Services": "",
};
