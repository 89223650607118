import { cloneDeep } from "lodash";

export const duplicateUnit = (unit) => {
  const clonedUnit = cloneDeep(unit);
  clonedUnit.rooms =
    clonedUnit?.rooms?.map((room) => duplicateRoom(room)) || [];
  return {
    ...clonedUnit,
    id: undefined,
    is_not_valid: false,
  };
};

export const duplicateRoom = (room) => {
  const clonedRoom = cloneDeep(room);
  clonedRoom?.product_prices?.forEach((pprice) => {
    pprice.id = undefined;
  });

  return {
    ...clonedRoom,
    id: undefined,
    is_not_valid: false,
    is_not_valid_amenities: { Amenities: false, Safety: false },
  };
};
