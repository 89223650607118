import { eventBus } from "@/app";
import {
  pastSessionDeadline,
  pastSessionStartDate,
} from "@/util/pastSessionDeadline";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ORDER_STATUS } from "@/constants";
import applicationService from "@/services/application.js";

export function usePastDeadline() {
  const store = useStore();
  const route = useRoute();

  const disabledContinueButton = ref(false);

  const profileData = computed(() => store.state.profileData);
  const userData = computed(() => store.state.userData);
  const applicationId = computed(() => route.params.applicationId);
  const currentOrder = computed(
    () => store.getters["configurator/getCurrentOrder"]
  );
  const isApplicationPastDeadline = computed(
    () => store.getters["configurator/getPastDeadline"]
  );

  const program = computed(() => store.state.programSessionDataV3);
  const isPastDeadline = computed(
    () =>
      pastSessionDeadline(program.value) &&
      currentOrder?.value?.status === ORDER_STATUS.draft
  );
  const isPastStartDate = computed(() => pastSessionStartDate(program.value));

  const validateDeadline = async () => {
    if (isPastDeadline.value) {
      disabledContinueButton.value = true;
      eventBus.$emit("showPastDeadlineBanner", isPastStartDate.value);

      // Scroll to top
      window.scrollTo(0, 0);

      const emailData = {
        session_name: currentOrder.value.session_name,
        student_name: `${profileData.value.preferred_first_name} ${profileData.value.last_name}`,
        student_email: profileData.value.email,
        home_univerity: userData.value.data?.schoolname?.label,
        is_past_start_date: isPastStartDate.value,
        program_id: currentOrder.value.program_page_id,
        session_id: currentOrder.value.session_id,
      };
      const response = await applicationService.recordDeadlineEvent(
        applicationId.value,
        emailData
      );

      store.dispatch(
        "configurator/setPastDeadline",
        Boolean(response?.data?.past_deadline)
      );
    }
  };

  onMounted(() => {
    if (
      isApplicationPastDeadline.value &&
      isPastDeadline.value & !disabledContinueButton.value
    ) {
      disabledContinueButton.value = true;
      eventBus.$emit("showPastDeadlineBanner", isPastStartDate.value);
    }
  });

  return { disabledContinueButton, isPastDeadline, validateDeadline };
}
