<template>
  <PageSection
    :id="'yravelArrangementSection'"
    :title="'Your Travel Arrangements'"
    :extra-span-classes="'tracking-widest'"
  >
    <template #content>
      <AcknowledgementForm
        v-if="checkboxForm"
        :submitted="formioSubmitted"
        @saveForm="saveForm"
      />
      <div v-else class="space-y-6">
        <p data-cy="travel-arrangement-section">
          If this form is not completed by the deadline provided, we are unable
          to guarantee airport pickup. You may be required to arrange your own
          transportation to your accommodations.
        </p>
        <p>
          Let us know your travel arrangements for your arrival, your departure,
          and any travel plans you’d like to share with us during the duration
          of the program.
        </p>

        <EditView
          ref="arrangements"
          :formio-data="formioData"
          @saveForm="saveForm"
        />
      </div>
    </template>
  </PageSection>
</template>

<script>
import PageSection from "../../forms/SharedComponents/Layout/PageSection";
import EditView from "./arrangement-view.vue";
import formIoApi from "../../../mixins/formIoApi.js";
import { eventBus } from "../../../app";
import AcknowledgementForm from "./acknowledgement-form.vue";
import { from12to24 } from "../../../mixins/helpers";

export default {
  name: "TravelArrangementForm",
  components: {
    PageSection,
    EditView,
    AcknowledgementForm,
  },
  mixins: [formIoApi],
  props: {
    formId: {
      type: String,
      default: "",
    },
    checkboxForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrangements: [],
      formURL: "travelarrangements",
      submissionId: "",
      formioData: [],
      formioSubmitted: false,
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  watch: {
    formId: {
      handler: function (newVal) {
        this.submissionId = newVal;
        this.getFormioData(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    getFormioData(id) {
      if (id) {
        this.getFormioSubmission(this.formURL, id).then((response) => {
          if (Object.hasOwnProperty.call(response, "travelgrid")) {
            this.formioData = response.travelgrid;
            this.formioSubmitted = true;
          }
        });
      }
    },
    saveForm(data) {
      const jsonData = this.getJsonData(data.arrangements);
      let resultObject = {
        eventType: data.eventType,
        id: data.id,
        hasErrors: false,
      };
      if (data.acknowledgeProgramsTravelArrangements) {
        let obj = {
          acknowledgeProgramsTravelArrangements:
            data.acknowledgeProgramsTravelArrangements,
        };
        jsonData.data.travelgrid.push(obj);
      }
      if (this.submissionId) {
        this.updateSubmission(jsonData, resultObject);
      } else {
        this.createSubmission(jsonData, resultObject);
      }
    },
    afterSubmission(data) {
      if (data.eventType === "add") {
        eventBus.$emit("afterSubmission", data);
      } else if (data.eventType === "delete") {
        eventBus.$emit("afterDeletingSubmission", data);
      } else if (data.eventType === "save") {
        eventBus.$emit("afterSaveSubmission", data);
      }
    },
    getJsonData(data) {
      let arrangements = [];
      if (data) {
        arrangements = data.map((item) => ({
          travelType: item.travelType,
          specifyArrangements: item.specifyArrangements,
          travelNumber: item.travelNumber,
          flightNumber: item.flightNumber,
          airline: item.airline === "other" ? item.otherAirline : item.airline,
          departureCity: item.departureCity,
          departureDate: item.departureDate,
          departureTime: from12to24(
            item.departureTime,
            item.DepartureTimeAmOrPm
          ),
          DepartureTimeAmOrPm: item.DepartureTimeAmOrPm,
          ArrivalTimeAmOrPm: item.ArrivalTimeAmOrPm,
          arrivalCity: item.arrivalCity,
          arrivalDate: item.arrivalDate,
          arrivalTime: from12to24(item.arrivalTime, item.ArrivalTimeAmOrPm),
          acknowledge: {
            studentResponsible: item.acknowledge.studentResponsible,
            studentFollowsGuidelines: item.acknowledge.studentFollowsGuidelines,
          },
          acknowledgeProgramsTravelArrangements:
            item.acknowledgeProgramsTravelArrangements ?? false,
        }));
      }
      return {
        data: {
          application_id: this.applicationId,
          travelgrid: arrangements,
          submitted: true,
        },
      };
    },
    createSubmission(jsonData, data) {
      this.submitToFormIo(this.formURL, jsonData)
        .then((response) => {
          this.submissionId = response;
          this.afterSubmission(data);
        })
        .catch(() => {
          data.hasErrors = true;
          this.afterSubmission(data);
        });
    },
    updateSubmission(jsonData, data) {
      this.submitToFormIo(this.formURL, jsonData, "PUT", this.submissionId)
        .then(() => {
          this.afterSubmission(data);
        })
        .catch(() => {
          data.hasErrors = true;
          this.afterSubmission(data);
        });
    },
  },
};
</script>
