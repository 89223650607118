<template>
  <div class="flex mt-1 text-sm text-gray-600 md:mt-0">
    <svg
      v-if="
        balancePending &&
        (compareStringsInLowerCase(payment.name, APPLICATION_FEE_DATA.typeLabel) ||
          payment.name === 'Confirmation Payment')
      "
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-alert-triangle flex-none w-5 h-5 mr-2 text-warning-900"
    >
      <path
        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
      />
      <line x1="12" y1="9" x2="12" y2="13" />
      <line x1="12" y1="17" x2="12.01" y2="17" />
    </svg>

    <div
      v-if="
        balancePending &&
        (compareStringsInLowerCase(payment.name, APPLICATION_FEE_DATA.typeLabel)  ||
          payment.name === 'Confirmation Payment')
      "
      class="text-gray-600 leading-tight"
    >
      You must pay this by {{ payment.due_date }} to reserve a spot.
    </div>

    <div v-else-if="balancePending" class="text-gray-600 leading-tight">
      Pay by {{ payment.due_date }}.
    </div>

    <div v-else class="text-gray-650 leading-tight">
      Paid on {{ payment.payment_date }}. Thank you!
    </div>
  </div>
</template>

<script>
import { compareStringsInLowerCase } from "@/util/string";
import { APPLICATION_FEE_DATA } from "@/constants";

export default {
  props: {
    payment: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    // We need to include APPLICATION_FEE_DATA to be able to use in ui
    return { APPLICATION_FEE_DATA, compareStringsInLowerCase };
  },
  computed: {
    balancePending() {
      if (
        typeof this.payment.balance !== "undefined" &&
        parseFloat(this.payment.balance) !== 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>
