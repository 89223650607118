<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useStore } from "vuex";
import { experiencesMenuItems, resourcesMenuItems } from "../../../constants";
import ProfileMenu from "../../Header/ProfileMenu.vue";
import ArrowRightIcon from "./ArrowRightIcon.vue";
import Destinations from "./DestinationsMegaMenu.vue";
import DestinationsMobil from "./DestinationsMobil.vue";
import MenuItem from "./MenuItem.vue";

import { computed, defineProps, ref } from "vue";
const isNavbarVisible = ref(true);
const store = useStore();

const props = defineProps({
  logo: {
    type: String,
    default: "/images/api-experience-logo.png",
  },
});

const navbarClasses = computed(() => {
  return {
    "slide-down": isNavbarVisible.value,
    "slide-up": !isNavbarVisible.value,
  };
});

const isBranded = computed(() => {
  return store?.getters?.getBrandingDetails?.secondary_color || "";
});
const signUp = () => {
  window.location.href = "/create-account";
};
const isLoggedIn = computed(() => store?.getters?.isLoggedIn);
</script>

<template>
  <Disclosure
    v-slot="{ open }"
    as="nav"
    :class="[`relative bg-white w-full border border-gray-10 z-30 top-0 left-0`,
      navbarClasses,
    ]"
  >
    <div class="mx-auto px-4 md:px-20 sm:pr-3.5 sm:pl-16">
      <div class="flex h-[84px] md:h-85p md:max-h-85p justify-between">
        <div class="flex justify-center items-center">
          <img
            class="h-auto w-auto max-w-15 md:max-w-20 max-h-11 md:max-h-28"
            :src="props.logo"
            alt="logo"
          />
        </div>
        <div class="hidden md:flex items-center w-full">
          <div class="hidden md:ml-6 md:flex md:space-x-8 w-full justify-end">
            <MenuItem
              v-if="!isBranded"
              label="Experiences"
              class="max-w-258px"
              :drop-down-options="experiencesMenuItems"
              label-class="text-indigo-base uppercase"
            />
            <Destinations
              v-if="!isBranded"
              class="max-w-258px"
              label-class="text-indigo-base uppercase"
            />
            <MenuItem
              v-if="!isBranded"
              label="Resources"
              class="max-w-258px"
              :drop-down-options="resourcesMenuItems"
              label-class="text-indigo-base uppercase"
            />
          </div>
          <div class="flex-shrink-0">
            <div
              v-if="!isLoggedIn"
              class="flex gap-1 font-bold uppercase text-base leading-5 items-center"
            >
              <a
                :id="'loginButton'"
                href="/sign-in"
                class="md:flex flex-row justify-center items-center w-auto max-h-50p y-5 px-10 ml-14 h-60p"
                :class= "[
                      isBranded ? `text-university-primary` : `text-university-secondary`,
                    ]"
                >LOGIN</a
              >
            </div>
          </div>

          <div class="flex-shrink-0">
            <div
              v-if="!isLoggedIn"
              class="flex gap-1 font-bold uppercase text-base leading-5 items-center"
            >
              <a
                :id="'signupButton'"
                href="/create-account"
                class="hidden box-border gap-x-2 md:flex flex-row justify-center items-center w-auto max-h-50p border-2 py-5 px-10 ml-14 h-60p"
                :class= "[
                      isBranded ? `text-university-primary border-university-primary` : `text-university-secondary`,
                    ]"
                >Sign Up
              </a>
            </div>
            <ProfileMenu v-if="isLoggedIn" is-experience-pages class="ml-4" />
          </div>
        </div>

        <div
          class="flex md:hidden md:ml-4 md:flex-shrink-0 md:items-center h-full"
        >
          <div class="flex items-center">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block h-10 w-12.5 text-indigo-base"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile menu items -->
    <DisclosurePanel class="md:hidden">
      <div class="space-y-1 pb-3 pt-2">
        <DisclosureButton
          as="a"
          href="#"
          class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-bold text-gray-500 hover:border-gray-300 md:hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
          ><MenuItem
            v-if="!isBranded"
            label="Experiences"
            :drop-down-options="experiencesMenuItems"
            label-class="text-indigo-base"
        /></DisclosureButton>
        <DisclosureButton
          as="a"
          href="#"
          class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 md:hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
        >
          <DestinationsMobil
            v-if="!isBranded"
            label-class="font-bold text-indigo-base"
          />
        </DisclosureButton>
        <DisclosureButton
          as="a"
          href="#"
          class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-bold text-gray-500 hover:border-gray-300 md:hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
          ><MenuItem
            v-if="!isBranded"
            label="Resources"
            :drop-down-options="resourcesMenuItems"
            label-class="text-indigo-base"
        /></DisclosureButton>
        <DisclosureButton
          v-if="!isLoggedIn"
          as="a"
          href="#"
          class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-bold text-gray-500 hover:border-gray-300 md:hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
        >
          <a
            href="/sign-in"
            class="`md:flex flex-row justify-center items-center w-auto max-h-50p bg-white text-teal-900 h-60p ${secondaryColorClassLinks}`"
            >LOGIN</a
          >
        </DisclosureButton>
        <div v-if="!isLoggedIn" class="flex-shrink-0">
          <button
            type="button"
            class="box-border md:flex flex-row justify-center items-center w-auto max-h-60p bg-white text-teal-900 border-teal-900 border-2 py-5 px-10 h-60p ml-4"
            @click="signUp()"
          >
            <div class="flex text-center gap-x-2 tracking-tight items-center">
              <span
                class="text-base font-semibold uppercase whitespace-nowrap leading-none"
                >Sign Up
              </span>
              <ArrowRightIcon />
            </div>
          </button>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<style scoped>
.slide-down {
  transform: translateY(0);
}
.slide-up {
  transform: translateY(-100%);
}
</style>
