<template>
  <div id="pagination" class="flex align-center justify-center">
    <ul class="flex">
      <li
        v-if="!isExperiencePage"
        :class="[
          calculateClasses,
          page === 1 ? 'text-teal-black' : `${secondaryColorClass}`,
        ]"
      >
        <button
          :disabled="page === 1"
          aria-label="Go to first page"
          class="font-semibold"
          @click="firstPage"
        >
          First
        </button>
      </li>
      <li
        :class="[
          'font-semibold',
          calculateClasses,
          page === 1 ? 'text-teal-black' : `${secondaryColorClass}`,
        ]"
      >
        <button
          :disabled="page === 1"
          aria-label="Go to previous page"
          @click="previousPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke="grey"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      </li>
      <li
        v-for="item in pagesArray"
        :key="item.id"
        :class="[
          item.name === page
            ? `${calculateColor} bg-turquoise-100 bg-opacity-97 border border-teal-950 border-opacity-23`
            : isExperiencePage
            ? 'text-gray-900'
            : `${secondaryColorClass}`,
          calculateClasses,
        ]"
      >
        <button class="font-semibold" @click="item.clickable && goTo(item)">
          {{ item.name }}
        </button>
      </li>
      <li
        :class="[
          page === pageCount ? 'text-teal-black' : `${secondaryColorClass}`,
          calculateClasses,
        ]"
      >
        <button
          class="font-semibold"
          :disabled="page === pageCount"
          aria-label="Go to next page"
          @click="nextPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 24 24"
            stroke="grey"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </li>
      <li
        v-if="!isExperiencePage"
        :class="[
          page === pageCount ? 'text-teal-black' : `${secondaryColorClass}`,
          calculateClasses,
        ]"
      >
        <button
          class="font-semibold"
          :disabled="page === pageCount"
          aria-label="Go to last page"
          @click="lastPage"
        >
          Last
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    pageCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
    isExperiencePage: {
      type: Boolean,
      default: false,
    },
    scrollToTop: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["onPageChange"],
  data() {
    return {
      i: 1,
    };
  },
  computed: {
    calculateClasses() {
      return this.isExperiencePage
        ? "flex justify-center items-center w-8 h-8 font-montserrat font-semibold text-sm leading-5 non-italic"
        : "p-6";
    },
    calculateColor() {
      return this.isExperiencePage ? "text-teal-950" : "text-teal-black";
    },
    pagesArray() {
      const pages = [];
      if (this.pageCount === 1) {
        pages.push({ id: 1, name: this.i, clickable: false });
      } else if (this.pageCount <= 2) {
        for (let i = 1; i <= this.pageCount; i++) {
          pages.push({ id: i, name: i, clickable: true });
        }
        return pages;
      } else if (this.page < 3) {
        pages.push(
          { id: 1, name: this.i, clickable: true },
          { id: 2, name: this.i + 1, clickable: true },
          { id: 3, name: "...", clickable: false },
          { id: 4, name: this.pageCount - 1, clickable: true },
          { id: 5, name: this.pageCount, clickable: true }
        );
      } else if (this.page >= this.pageCount - 3) {
        pages.push(
          { id: 1, name: this.i, clickable: true },
          { id: 2, name: "...", clickable: false },
          { id: 3, name: this.pageCount - 2, clickable: true },
          { id: 4, name: this.pageCount - 1, clickable: true },
          { id: 5, name: this.pageCount, clickable: true }
        );
      } else {
        pages.push(
          { id: 1, name: this.i, clickable: true },
          { id: 2, name: "...", clickable: false },
          { id: 3, name: this.page - 1, clickable: true },
          { id: 4, name: this.page, clickable: true },
          { id: 5, name: this.page + 1, clickable: true },
          { id: 6, name: "...", clickable: false },
          { id: 7, name: this.pageCount, clickable: true }
        );
      }
      return pages;
    },
  },
  created() {
    // Wrap the methods with the scroll behavior
    this.previousPage = this.withScroll(this.previousPage);
    this.nextPage = this.withScroll(this.nextPage);
    this.firstPage = this.withScroll(this.firstPage);
    this.lastPage = this.withScroll(this.lastPage);
    this.goTo = this.withScroll(this.goTo);
  },
  methods: {
    withScroll(fn) {
      return (...args) => {
        fn.apply(this, args);
        if (this.scrollToTop) {
          this.pageScroll();
        }
      };
    },

    previousPage: function () {
      this.$emit("onPageChange", this.page - 1);
    },
    nextPage: function () {
      this.$emit("onPageChange", this.page + 1);
    },
    firstPage: function () {
      this.$emit("onPageChange", 1);
    },
    lastPage: function () {
      this.$emit("onPageChange", this.pageCount);
    },
    goTo: function (item) {
      this.$emit("onPageChange", item.name);
    },
    pageScroll: function () {
      setTimeout(function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 100);
    },
  },
};
</script>
<style></style>
