import { event } from "vue-gtag";

export function trackEvent(
  eventName,
  eventCategory,
  eventLabel,
  user_id = null
) {
  event(eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
    user_id: user_id,
    logged_in: user_id ? "yes" : "no",
  });
}

/**
 * Tracks a purchase event in Google Analytics.
 * @param {string} transactionId The unique identifier for the transaction.
 * @param {number} value The value of the transaction.
 * @param {string} currency The currency used for the transaction (e.g., "USD").
 * @param {string} coupon (Optional) The coupon code used for the transaction. Defaults to "not_set" if not provided.
 * @param {Object[]} items An array of item objects representing the purchased items.
 * */
export function trackPurchase(transactionId, value, currency, coupon, items) {
  event("purchase", {
    transaction_id: transactionId,
    value: value,
    currency: currency,
    coupon: coupon,
    items: items,
  });
}

/**
 * Constructs an array of category-specific items with their details for a given category,
 * based on provided pricing, current order, and program session data.
 *
 * @param {string} category The category of items to construct the array for (e.g., "Housing", "Internship", etc.).
 * @param {Object} pricingData The pricing data from the ordersService.
 * @param {Object} currentOrder The current order object.
 * @param {Object} programSessionDataV3 The program session data object.
 * @returns {Array<Object>} An array of item objects, each containing detailed information about the item: item ID, name, category, variant, location, and price. Returns an empty array if the category does not match any known case.
 */
export function getCategoryItemArray(
  category,
  pricingData,
  currentOrder,
  programSessionDataV3
) {
  function constructItemsArray(
    itemId,
    itemName,
    category,
    category2,
    category3,
    category4,
    category5,
    variant,
    locationId,
    price
  ) {
    return [
      {
        item_id: itemId,
        item_name: itemName,
        item_category: category,
        item_category2: category2,
        item_category3: category3,
        item_category4: category4,
        item_category5: category5,
        item_variant: variant,
        location_id: locationId,
        price: price,
        quantity: 1,
      },
    ];
  }
  const programName = pricingData?.data?.data?.program_page_name;
  const programSessionName = pricingData?.data?.data?.session_name;
  const city = pricingData?.data?.data?.session_city;
  let items = [];

  switch (category) {
    case "Housing":
      currentOrder.room_ids?.forEach((roomID) => {
        programSessionDataV3.session_units.forEach((unitWrapper) => {
          const unit = unitWrapper.unit;
          unit.rooms?.forEach((room) => {
            if (room.id === roomID) {
              const pricingRoom = pricingData.data.data.rooms.find(
                (r) => r.product_id === roomID
              );
              if (pricingRoom) {
                items.push(
                  constructItemsArray(
                    pricingRoom.product_id,
                    unit.name || "not_set",
                    programName,
                    programSessionName,
                    "Housing",
                    unit.housings?.type || "not_set",
                    unit.housings?.tier?.name || "not_set",
                    pricingRoom.student_price_difference_from_default <= 0
                      ? "Included"
                      : "Not Included",
                    city,
                    pricingRoom.total_price_in_cents / 100
                  )[0]
                );
              }
            }
          });
        });
      });
      break;

    case "Internship":
      pricingData.data.data.internships.forEach((internship) => {
        items.push(
          constructItemsArray(
            internship.product_id,
            internship.product_name,
            programName,
            programSessionName,
            "Internship",
            "not set",
            "not set",
            internship.student_price_difference_from_default <= 0
              ? "Included"
              : "Not Included",
            city,
            internship.total_price_in_cents / 100
          )[0]
        );
      });
      break;

    case "Events":
      pricingData.data.data.event_occurrences.forEach((event) => {
        items.push(
          constructItemsArray(
            event.product_id,
            event.product_name,
            programName,
            programSessionName,
            "Events",
            "not set",
            "not set",
            event.student_price_difference_from_default <= 0
              ? "Included"
              : "Not Included",
            city,
            event.total_price_in_cents / 100
          )[0]
        );
      });
      break;
    case "Additional Fees": {
      pricingData?.data?.data?.additional_fees.forEach((fee) => {
        items.push(
          constructItemsArray(
            fee.product_id || "not_set",
            fee.product_name || "not_set",
            programName,
            programSessionName,
            "Additional Fees",
            "not set",
            "not set",
            fee.student_price_difference_from_default <= 0
              ? "Included"
              : "Not Included",
            city,
            fee.total_price_in_cents / 100
          )[0]
        );
      });
      break;
    }
    case "Classes Academic Fees": {
      pricingData?.data?.data?.addons.forEach((addon) => {
        items.push(
          constructItemsArray(
            addon.product_id || "not_set",
            addon.product_name || "not_set",
            programName,
            programSessionName,
            "Classes Academic Fees",
            "not set",
            "not set",
            addon.student_price_difference_from_default <= 0
              ? "Included"
              : "Not Included",
            city,
            addon.total_price_in_cents / 100
          )[0]
        );
      });
      break;
    }
    default:
      break;
  }
  return items;
}
