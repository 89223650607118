<template>
  <section class="mt-4 text-center">
    <h3 class="font-semibold">
      API can defer the due date for aid only when it has been
      <span class="uppercase">finalized</span>.<br />For this reason, please
      answer the below questions
      <span class="uppercase">as accurately as possible</span>.
    </h3>
    <div class="text-left mt-4">
      <ol class="list-decimal">
        <li class="mt-4">
          <div
            id="financial-aid-release-to"
            class="flex flex-col sm:flex-row justify-between xs:items-start sm:items-center"
          >
            <p>My financial aid will be released directly to:</p>
            <label class="flex">
              <input
                v-model="value.financialAidReleasedTo"
                type="radio"
                name="financialAidReleasedTo"
                value="Student/Parent(s)"
                class="mt-1 form-radio"
                :class="[
                  {
                    'bg-error-100': v$.value.financialAidReleasedTo.$error,
                  },
                  `${primaryColorClass}`,
                ]"
              />
              <span class="ml-2">Student/Parent(s)</span>
            </label>
            <label class="flex">
              <input
                v-model="value.financialAidReleasedTo"
                type="radio"
                name="financialAidReleasedTo"
                value="API"
                class="mt-1 form-radio"
                :class="[
                  {
                    'bg-error-100': v$.value.financialAidReleasedTo.$error,
                  },
                  `${primaryColorClass}`,
                ]"
              />
              <span class="ml-2">API</span>
            </label>
          </div>
          <p
            v-if="v$.value.financialAidReleasedTo.required.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.value.financialAidReleasedTo.$error }"
          >
            {{ validationMessage["required"] }}
          </p>
        </li>
        <li class="mt-4">
          <div
            id="financial-aid-complete-requirements"
            class="flex flex-col sm:flex-row justify-between xs:items-start sm:items-center"
          >
            <p>
              My financial aid award/loans listed above are approved and
              finalized, and I have completed all requirements to ensure the
              release of the above-listed aid.
            </p>
            <label class="flex">
              <input
                v-model="value.completedAllRequirements"
                type="radio"
                name="completedAllRequirements"
                value="yes"
                class="mt-1 form-radio"
                :class="[
                  {
                    'bg-error-100': v$.value.completedAllRequirements.$error,
                  },
                  `${primaryColorClass}`,
                ]"
              />
              <span class="ml-2">Yes</span>
            </label>
            <label class="flex sm:ml-4">
              <input
                v-model="value.completedAllRequirements"
                type="radio"
                name="completedAllRequirements"
                value="no"
                class="mt-1 form-radio"
                :class="[
                  {
                    'bg-error-100': v$.value.completedAllRequirements.$error,
                  },
                  `${primaryColorClass}`,
                ]"
              />
              <span class="ml-2">No</span>
            </label>
          </div>
          <label
            v-if="value.completedAllRequirements === 'no'"
            class="flex flex-col sm:flex-row text-gray-600 mt-3"
          >
            <span class="whitespace-nowrap"
              >If no, what requirements are left for you to complete?</span
            >
            <input
              id="requirementsLeft"
              v-model="value.requirementsLeft"
              type="text"
              class="form-input min-h-10 sm:ml-4 sm:w-full"
              :class="{
                'bg-error-100': v$.value.requirementsLeft.$error,
              }"
            />
          </label>
          <p
            v-if="v$.value.requirementsLeft.required.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.value.requirementsLeft.$error }"
          >
            {{ validationMessage["required"] }}
          </p>
          <p
            v-if="v$.value.requirementsLeft.maxLength.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.value.requirementsLeft.$error }"
          >
            {{ validationMessage["maxLength"] }} 255 characters
          </p>
          <p
            v-if="v$.value.completedAllRequirements.required.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.value.completedAllRequirements.$error }"
          >
            {{ validationMessage["required"] }}
          </p>
        </li>
        <li class="mt-4">
          <div
            id="enrollment-verification"
            class="flex flex-col sm:flex-row justify-between xs:items-start sm:items-center"
          >
            <p>
              Is enrollment verification required to release my financial aid?
            </p>
            <label class="flex">
              <input
                v-model="value.enrollmentVerification"
                type="radio"
                name="enrollmentVerification"
                value="yes"
                class="mt-1 form-radio"
                :class="[
                  {
                    'bg-error-100': v$.value.enrollmentVerification.$error,
                  },
                  `${primaryColorClass}`,
                ]"
              />
              <span class="ml-2">Yes</span>
            </label>
            <label class="flex">
              <input
                v-model="value.enrollmentVerification"
                type="radio"
                name="enrollmentVerification"
                value="no"
                class="mt-1 form-radio"
                :class="[
                  {
                    'bg-error-100': v$.value.enrollmentVerification.$error,
                  },
                  `${primaryColorClass}`,
                ]"
              />
              <span class="ml-2">No</span>
            </label>
          </div>
          <label
            v-if="value.enrollmentVerification === 'yes'"
            class="flex flex-col sm:flex-row text-gray-600 mt-3"
          >
            <span class="whitespace-nowrap"
              >If yes, where should enrollment verification be sent?</span
            >
            <input
              id="enrollmentVerificationSent"
              v-model="value.enrollmentVerificationSent"
              type="text"
              placeholder="university representative name and email"
              class="form-input min-h-10 sm:ml-4 sm:w-full"
              :class="{
                'bg-error-100': v$.value.enrollmentVerificationSent.$error,
              }"
            />
          </label>
          <p
            v-if="v$.value.enrollmentVerificationSent.required.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.value.enrollmentVerificationSent.$error }"
          >
            {{ validationMessage["required"] }}
          </p>
          <p
            v-if="v$.value.enrollmentVerificationSent.maxLength.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.value.enrollmentVerificationSent.$error }"
          >
            {{ validationMessage["maxLength"] }} 255 characters
          </p>
          <p
            v-if="v$.value.enrollmentVerification.required.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.value.enrollmentVerification.$error }"
          >
            {{ validationMessage["required"] }}
          </p>
        </li>
        <li class="mt-4">
          <label class="block">
            Please put any additional information or notes that you wish to
            include below
            <textarea
              v-model="value.additionalInfo"
              cols="20"
              rows="5"
              class="form-input min-h-10 mt-2 block w-full"
            ></textarea>
          </label>
          <p
            v-if="v$.value.additionalInfo.maxLength.$invalid"
            class="error text-error-900"
            :class="{ hidden: !v$.value.additionalInfo.$error }"
          >
            {{ validationMessage["maxLength"] }} 255 characters
          </p>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
import { required, requiredIf, maxLength } from "@vuelidate/validators";
import validationMessage from "../../mixins/validationMessages";
import useVuelidate from "@vuelidate/core";

export default {
  name: "Questionnaire",
  mixins: [validationMessage],
  props: {
    questions: {
      type: Object,
      default: function () {
        return {};
      },
    },
    submittedData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:questionnaire"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        financialAidReleasedTo: "",
        completedAllRequirements: "",
        enrollmentVerification: "",
        enrollmentVerificationSent: "",
        additionalInfo: "",
        requirementsLeft: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:questionnaire", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    this.value.financialAidReleasedTo = this.submittedData
      .financialAidReleasedTo
      ? this.submittedData.financialAidReleasedTo
      : "";
    this.value.additionalInfo = this.submittedData.additionalInformation
      ? this.submittedData.additionalInformation
      : "";
    this.value.completedAllRequirements = this.submittedData
      .completedAllRequirements
      ? this.submittedData.completedAllRequirements
      : "";
    this.value.enrollmentVerification = this.submittedData
      .enrollmentVerification
      ? this.submittedData.enrollmentVerification
      : "";
    this.value.enrollmentVerificationSent = this.submittedData
      .enrollmentVerificationSent
      ? this.submittedData.enrollmentVerificationSent
      : "";
    this.value.requirementsLeft = this.submittedData.requirementsLeft
      ? this.submittedData.requirementsLeft
      : "";
  },

  validations: {
    value: {
      financialAidReleasedTo: {
        required,
      },
      completedAllRequirements: {
        required,
      },
      requirementsLeft: {
        required: requiredIf(function () {
          return this.value.completedAllRequirements === "no";
        }),
        maxLength: maxLength(255),
      },
      enrollmentVerification: {
        required,
      },
      enrollmentVerificationSent: {
        required: requiredIf(function () {
          return this.value.enrollmentVerification === "yes";
        }),
        maxLength: maxLength(255),
      },
      additionalInfo: {
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
