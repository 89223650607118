<template>
  <section :class="mainPanelClass">
    <header
      class="flex flex-row-wrap items-baseline md:items-start justify-between border-b border-gray-200"
      :class="[
        mainHeaderClass
      ]"
    >
      <h2 :class="headingClass">
        <button
          :aria-controls="id"
          :aria-expanded="expanded"
          class="flex items-center text-lg md:text-xl"
          :class="[...panelButtonClass, disablePanel ? 'text-gray-500' : 'text-indigo-base']"
          @click="controlSection"
        >
          <SearchChevron
            :height="'9'"
            :width="'17'"
            :rotate-direction="expanded ? 'down' : 'right'"
            :fill-color="disablePanel ? '#a6abb6' : '#1e264c'"
          />
          <span class="text-red-100 ml-1 mr-1 align-bottom">{{
            required
          }}</span>
          <span
            class="text-base font-semibold tracking-widest text-left uppercase"
            :class="panelButtonTextClasses"
            data-cy="titleForm"
          >
            {{ title }}
          </span>
        </button>
      </h2>
      <div class="md:hidden basis-full"></div>
      <div
        v-if="hasPanelHeaderCheckboxSlot"
        class="flex justify-center text-gray-600"
        :class="[
          { 'md:order-none mt-4': hasPanelHeaderItemSlot },
        ]"
      >
        <slot name="panelHeaderCheckbox" />
      </div>
      <div class="basis-full hidden md:block"></div>
      <div v-show="expanded">
        <slot name="panelHeaderItem" />
      </div>
    </header>
    <div
      :id="id"
      :class="panelContentClass"
      v-show="expanded"
    >
      <slot name="content" />
    </div>
  </section>
</template>
<script>
import SearchChevron from "@/components/svg-icons/SearchChevron.vue";
export default {
  name: "FormPanel",
  components: {
    SearchChevron,
  },
  props: {
    required: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "someid",
      required: false,
    },
    panelContentClass: {
      type: String,
      default: "text-sm text-gray-600 border-gray-200",
    },
    mainPanelClass: {
      type: String,
      default: "",
    },
    panelButtonTextClasses: {
      type: Array,
      default: [],
    },
    mainHeaderClass: {
      type: String,
      default: "",
    },
    newMargin: {
      type: String,
      default: "",
    },
    panelButtonClass: {
      type: Array,
      default: [],
    },
    headingClass: {
      type: String,
      default: "",
    },
    removeContentOnHidden: {
      type: Boolean,
      default: false,
    },
    disablePanel: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    hasCardImageSlot() {
      return typeof this.$slots.cardImage === "function";
    },
    hasPanelHeaderCheckboxSlot() {
      return typeof this.$slots.panelHeaderCheckbox === "function";
    },
    hasPanelHeaderItemSlot() {
      return typeof this.$slots.panelHeaderItem === "function";
    },
  },
  methods: {
    controlSection() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped>
.flex-row-wrap {
  flex-flow: row wrap;
}
.basis-full {
  flex-basis: 100%;
}
</style>
