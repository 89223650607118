import { useStore } from "vuex";
import { computed } from "vue";
import { V3_generateApplicationId } from "@/composables/VersionHelper.js";
import _ from "lodash";

export function useProgramSelectionData() {
  const store = useStore();

  const arrivalDetails = (sessionTravelDetails) => {
    const details = [...sessionTravelDetails];
    const sortedAsc = details.sort(
      (a, b) =>
        Number(new Date(a.arrival_date)) - Number(new Date(b.arrival_date))
    );
    return sortedAsc.length ? sortedAsc[0] : {};
  };

  const location = (program) => {
    const arrivalData = arrivalDetails(program.session_travel_details);
    return {
      label:
        arrivalData.arrival_city?.city_ascii +
        ", " +
        arrivalData.arrival_city?.country?.name,
      value: arrivalData.arrival_city_id,
    };
  };

  const experienceType = (program) => {
    return program?.session_types[0]?.name === "Virtual" ? "virtual" : "abroad";
  };

  const semester = (program) => {
    return program?.terms[0]?.session ?? "-";
  };

  const contractNumber = computed(() => {
    return Object.hasOwnProperty.call(
      store.state.appliedAgreement,
      "contract_number"
    )
      ? store.state.appliedAgreement.contract_number
      : "";
  });

  const createSubmissionDataForFormIo = (program, applicationId = null) => {
    if (_.isEmpty(program)) return {};

    if (applicationId) store.commit("setCurrentApplicationId", applicationId);

    return {
      data: {
        application_id: applicationId || V3_generateApplicationId(program.id),
        uiVersion: "v3",
        experienceType: experienceType(program),
        location: location(program),
        semester: semester(program),
        programSession: program.salesforce_id,
        contractNumber: contractNumber.value,
      },
    };
  };

  return {
    createSubmissionDataForFormIo,
  };
}
