<template>
  <div>
    <label for="acknowledgement" class="flex">
      <input
        id="acknowledgement"
        v-model="v$.value.acknowledge.$model"
        type="checkbox"
        name="acknowledgements"
        class="mt-1 text-university-primary form-checkbox"
        :class="{ 'bg-error-100': v$.value.acknowledge.$error }"
      />
      <div class="flex-1 ml-2">
        <p>
          Check this off your to do list here to acknowledge that you have read
          and understand your program's travel arrangements.
        </p>
      </div>
    </label>
    <div
      v-if="v$.value.acknowledge.sameAs.$invalid && v$.value.acknowledge.$error"
      class="error text-error-900"
    >
      Field is required
    </div>

    <div class="flex justify-end mt-6">
      <ButtonWithSpinner
        ref="acknowledgementButton"
        type="button"
        variant="secondary"
        variant-type="normal"
        @click="submit"
      >
        <span>
          Save
          <svg
            v-if="successSubmission && !v$.$invalid"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="inline feather feather-check"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </ButtonWithSpinner>
    </div>
    <div
      v-if="successSubmission && !v$.$invalid"
      class="text-right text-success-900"
    >
      Submission Complete, thank you!
    </div>
    <p v-if="submitError" class="text-right error text-error-900">
      Something went wrong while submitting the form, please try later.
    </p>
  </div>
</template>

<script>
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import { sameAs } from "@vuelidate/validators";
import { eventBus } from "@/app";
import useVuelidate from "@vuelidate/core";

export default {
  name: "AcknowledgementForm",
  components: {
    ButtonWithSpinner,
  },

  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["saveForm"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        acknowledge: false,
      },
      successSubmission: false,
      submitError: false,
    };
  },
  validations: {
    value: {
      acknowledge: { sameAs: sameAs(true) },
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.successSubmission = false;
      },
      deep: true,
    },
    submitted: {
      handler: function (newval) {
        this.value.acknowledge = newval;
      },
      immediate: true,
    },
  },
  created() {
    eventBus.$on("afterSaveSubmission", (data) => {
      this.afterSubmission(data);
    });
  },

  methods: {
    async submit() {
      this.submitError = false;
      this.successSubmission = false;
      this.$refs.acknowledgementButton.startLoading();
      this.v$.$touch();

      if (!this.v$.$invalid) {
        const data = {
          arrangements: null,
          eventType: "save",
          id: null,
          acknowledgeProgramsTravelArrangements: true,
        };
        this.$emit("saveForm", data);
      } else {
        this.$refs.acknowledgementButton.stopLoading();
      }
    },
    afterSubmission(data) {
      this.successSubmission = !data.hasErrors;
      this.submitError = data.hasErrors;
      this.$refs.acknowledgementButton.stopLoading();
    },
  },
};
</script>
