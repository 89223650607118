<template>
  <section>
    <!-- Display a payment form -->
    <form id="payment-form">
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <div id="payment-message" class="hidden"></div>
    </form>
    <!-- Display a payment form -->
  </section>
</template>

<script>
export default {
  name: "StripeFormComponent",
  emits: ["submitToStripe"],
  data() {
    return {
      showBtn: false,
      disableBtn: false,
      hideBtn: false,
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("submitToStripe");
    },
    showButton(toggle) {
      this.showBtn = toggle;
    },
    disableButton(toggle) {
      this.disableBtn = toggle;
    },
    setLoading(isLoading) {
      if (isLoading) {
        this.$refs.payNowButton.startLoading();
      } else {
        this.$refs.payNowButton.stopLoading();
      }
    },
    hideButton(flag) {
      this.hideBtn = flag;
    },
  },
};
</script>
