import { getApiClient } from "./API";
import { learningServiceRequest, skip } from "./utils";

const classesService = {
  async list(params) {
    const response = await getApiClient().get(`/classes`, { params });
    return response.data;
  },
  async getClasses({
    limit = "",
    page = 1,
    q = "",
    status = "",
    source,
    ...extraParams
  }) {
    return await learningServiceRequest({
      method: "get",
      url: "/classes",
      params: {
        limit,
        skip: skip(page, limit),
        q,
        status,
        ...extraParams,
      },
      failureMessage: "Error loading Classes data",
      source,
    });
  },
  async getClassById(classId) {
    return await learningServiceRequest({
      method: "get",
      url: `/classes/${classId}`,
    });
  },
  async getProgramSessionsByClassId(classId, page = 1, limit = 10) {
    const skip = (page - 1) * limit;
    return await learningServiceRequest({
      method: "get",
      url: `/classes/${classId}/program-sessions?skip=${skip}&limit=${limit}`,
      failureMessage: "Error loading related Program Sessions data",
    });
  },
};

export default classesService;
