<script setup>
import { defineProps, defineEmits, toRefs } from "vue";
import SimplePopup from "@/components/shared/SimplePopup.vue";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  eventData: {
    type: Object,
    required: true,
  },
  openModal: {
    type: Boolean,
    default: false,
  },
});

const { eventData: event, openModal } = toRefs(props);
</script>

<template>
  <SimplePopup
    :scrollable="true"
    :open="openModal"
    :header-color-class="secondaryColor ? 'bg-gray-275' : 'bg-university-accentPrimary'"
    custom-class="z-30 font-montserrat"
    @close="emit('closeModal')"
  >
    <template #title>
      <div class="flex self-center">
        <div class="self-center pl-2">{{ event?.title || "" }}</div>
      </div>
    </template>
    <template #content>
      <div class="mt-2">
        <JSONtoHTML
          class="text-justify w-full text-base text-indigo-base max-h-25vh"
          :tiptap-json="event?.description"
        />
      </div>
    </template>
  </SimplePopup>
</template>
