<template>
  <FormPanel
    :id="id"
    :title="title"
    :disabled-panel="disabledPanel"
    :initially-expanded="!disabledPanel"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <slot name="header" />
      <div
        v-for="(item, index) in value.mates"
        :key="index"
        class="mt-6 space-y-6"
      >
        <div class="grid grid-cols-2 gap-y-6 gap-x-6">
          <div class="col-span-2 md:col-span-1">
            <label :for="id + 'FirstName' + index" class="block text-sm">
              <span
                class="font-semibold text-gray-600 flex justify-between mb-2"
              >
                <span>First Name</span>
              </span>
              <input
                :id="id + 'FirstName' + index"
                v-model.trim="item.firstName"
                type="text"
                class="form-input block w-full min-h-10"
              />
            </label>
          </div>
          <div class="col-span-2 md:col-span-1">
            <label :for="id + 'LastName' + index" class="block text-sm">
              <span
                class="font-semibold text-gray-600 flex justify-between mb-2"
              >
                <span>Last Name</span>
              </span>
              <input
                :id="id + 'LastName' + index"
                v-model.trim="item.lastName"
                type="text"
                class="form-input block w-full min-h-10"
              />
            </label>
          </div>
          <div class="col-span-2 md:col-span-2">
            <label :for="id + 'Email' + index" class="block text-sm">
              <span
                class="font-semibold text-gray-600 flex justify-between mb-2"
              >
                <span>Email</span>
              </span>
              <input
                :id="id + 'Email' + index"
                v-model.trim="item.email"
                type="email"
                class="form-input block w-full min-h-10"
                :class="{
                  'bg-error-100':
                    v$.value.mates.$each.$response.$data[index].email.$error,
                }"
              />
              <div
                v-if="!v$.value.mates.$each.$response.$data[index].email.email"
                class="error text-error-900"
              >
                Email must be a valid email
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="mt-6">
        <button
          :class="[
            secondaryColor
              ? `${secondaryColorClass}`
              : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
          ]"
          class="flex items-center"
          @click="addMate"
        >
          <span class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </span>
          <span class="ml-2 text-sm font-semibold text-gray-600">
            Add Another
          </span>
        </button>
      </div>
    </template>
  </FormPanel>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, helpers } from "@vuelidate/validators";
import housingForm from "../../../mixins/housingForm.js";

export default {
  name: "RoommateHousemate",
  mixins: [housingForm],
  props: {
    roomates: {
      type: Object,
      default: function () {
        return {};
      },
    },
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "roommateHousemateId",
    },
    formioPrefix: {
      type: String,
      default: "",
    },
  },
  emits: ["update:roomate"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        mates: [
          {
            firstName: "",
            lastName: "",
            email: "",
          },
        ],
      },
    };
  },
  validations: {
    value: {
      mates: {
        $each: helpers.forEach({
          firstName: {},
          lastName: {},
          email: {
            email,
          },
        }),
      },
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update:roomate", this.value);
      },
      deep: true,
      immediate: true,
    },
    formioData: {
      handler: function (val) {
        if (Object.hasOwnProperty.call(val, "mates") && val.mates.length) {
          let mapMates = val.mates.map((mate) => ({
            firstName: mate[this.formioPrefix + "FirstName"],
            lastName: mate[this.formioPrefix + "LastName"],
            email: mate[this.formioPrefix + "Email"],
          }));
          this.value.mates = mapMates;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    addMate() {
      this.value.mates.push({ firstName: "", lastName: "", email: "" });
    },
  },
};
</script>
