<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 -960 960 960"
    width="48"
    fill="#1E264C"
  >
    <path
      d="M191.154-789.999h579.307q9.644 0 16.168 6.58 6.524 6.58 6.524 16.307 0 9.727-6.524 16.112-6.524 6.384-16.168 6.384H191.154q-9.644 0-16.168-6.58-6.524-6.58-6.524-16.307 0-9.727 6.524-16.112 6.524-6.384 16.168-6.384Zm11.539 619.998q-12.26 0-20.553-8.293-8.293-8.293-8.293-20.553V-423h-25.769q-13.755 0-22.301-10.892-8.546-10.892-5.546-24.339l42.847-188.922q2.118-10.255 10.353-16.551 8.235-6.295 17.877-6.295h577.999q9.642 0 17.877 6.295 8.235 6.296 10.354 16.551l42.846 188.922q3.385 13.615-5.443 24.423Q826.114-423 812.538-423h-25.385v230.307q0 9.645-6.58 16.168-6.58 6.524-16.307 6.524-9.727 0-16.112-6.524-6.384-6.523-6.384-16.168V-423H540.461v224.153q0 12.26-8.293 20.553-8.293 8.293-20.553 8.293H202.693Zm16.537-45.383h275.848V-423H219.23v207.616Zm-52.308-253h626.771-626.771Zm0 0h626.771l-35.616-156.232H202.538l-35.616 156.232Z"
    />
  </svg>
</template>
