import { mapGetters, mapMutations, mapActions } from "vuex";
import { tokenValidationMixin } from "@/mixins/tokenValidationMixin.js";
import universityApproval from "@/mixins/universityApproval.js";

export const tabsMixin = {
  mixins: [tokenValidationMixin, universityApproval],
  data() {
    let columnsWhiteList = [
      { label: "Student Name and Email", name: "nameEmail", sortable: true },
      { label: "Major", name: "major", sortable: false },
      { label: "Time Frame", name: "timeFrame", sortable: false },
      { label: "Program Name", name: "programName", sortable: false },
      { label: "Program Dates", name: "programDates", sortable: false },
      { label: "Program Location", name: "programLocation", sortable: false },
      {
        label: "Application Deadline",
        name: "applicationDeadline",
        sortable: true,
      },
      {
        label: "Applied Date",
        name: "appliedDate",
        sortable: true,
      },
      { label: "Program Price", name: "programPrice", sortable: false },
      { label: "Withdrawn Reason", name: "withdrawnReason", sortable: true },
      { label: "Withdrawn Date", name: "withdrawnDate", sortable: true },
      { label: "Student Confirmation", name: "confirmed", sortable: true },
      { label: "Scholarship Award", name: "awardScholarship", sortable: false },
      { label: "Student Discount", name: "studentDiscount", sortable: false },
      { label: "Financial Aid", name: "financialAid", sortable: false },
      { label: "Academic Standing", name: "academicStanding", sortable: false },
      { label: "Approval Form", name: "approvalForm", sortable: false },
      { label: "Program Location", name: "programLocation", sortable: false },
      { label: "Courses Confirmed", name: "coursesConfirmed", sortable: false },
      { label: "Session", name: "term", sortable: true },
      { label: "Prospect Type", name: "prospectType", sortable: false },
      { label: "Created Date", name: "createdDate", sortable: true },
      { label: "Program type", name: "programType", sortable: false },
    ];

    return {
      columnsWhiteList: columnsWhiteList,
      selectedStudent: "",
      selectedStudentData: {},
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      approveUrl: "/university-approval/",
      isProspective: false,
    };
  },
  computed: {
    ...mapGetters("university", [
      "lastViewedTabUrl",
      "getLastSearchInput",
      "getLastFilters",
      "currentOrderIndex",
      "currentOrder",
      "currentTab",
    ]),
    showModal() {
      return (
        typeof this.selectedStudent === "number" &&
        Object.keys(this.selectedStudentData).length > 0
      );
    },
    //Get de column labels per tab from whitelist
    columns() {
      let columns = [];
      this.tabColumns.forEach((element) => {
        if (this.inArray(element, this.tabColumns)) {
          let index = this.getColumnIndex(
            this.columnsWhiteList,
            "name",
            element
          );
          columns.push(this.columnsWhiteList[index]);
        }
      });
      return columns;
    },
    approvedApplications() {
      if (!Array.isArray(this.students) || this.students.length === 0) {
        return [];
      }

      return this.students.reduce((appIds, progress) => {
        if (
          (Object.hasOwnProperty.call(progress, "form_status") &&
            this.isApproved(JSON.parse(progress["form_status"]))) ||
          progress.simplified_approval
        ) {
          appIds.push(progress["approval_application"]);
        }
        return appIds;
      }, []);
    },
    pendingApplications() {
      if (!Array.isArray(this.students) || this.students.length === 0) {
        return [];
      }

      return this.students.reduce((appIds, progress) => {
        if (
          Object.hasOwnProperty.call(progress, "form_status") &&
          !this.isApproved(JSON.parse(progress["form_status"])) &&
          progress["approval_hash"]
        ) {
          appIds.push(progress["approval_application"]);
        }
        return appIds;
      }, []);
    },
  },
  methods: {
    ...mapMutations("university", [
      "setLastViewedPage",
      "setLastSearchInput",
      "setLastFilters",
      "setProspectiveMajor",
    ]),
    ...mapActions("university", ["updateCounts", "alterOrder"]),
    async csvExport() {
      this.tableData.csv = true;
      await fetch(
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "students",
        {
          headers: {
            "Content-type": "application/json",
            "x-jwt-token": this.$store.state.currentUser.advisorToken,
          },
          method: "post",
          body: this.getJsonObject(),
        }
      )
        .then((response) => {
          delete this.tableData.csv;
          if (response.status === 200) {
            return response.json();
          }
          return false;
        })
        .then((json) => {
          let csvData = this.prepDataForCsvExport(json.data);
          if (csvData.length > 0) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
              Object.keys(csvData[0]).join(","),
              ...csvData.map((item) =>
                Object.values(item)
                  .map((item) => '"' + item + '"')
                  .join(",")
              ),
            ].join("\n");
            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
          }
        })
        .catch(() => {
          console.log("parsing failed");
        });
    },
    inArray(needle, haystack) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
      return false;
    },
    // Toggle the active class on action button on each row
    toggleDropdown(id) {
      this.id = this.id === id ? "" : id;
    },
    async getStudents(
      url = this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "students",
      loadLastViewedPage = false
    ) {
      let validToken = await this.isTokenValid();

      if (validToken) {
        if (loadLastViewedPage) {
          if (this.lastViewedTabUrl) {
            url = this.lastViewedTabUrl; // Sets up request url for enrollment service
          }
          if (this.getLastSearchInput) {
            this.tableData.search = this.getLastSearchInput; // Sets up the last input search
          }
          if (this.currentOrderIndex !== undefined) {
            this.tableData.column = this.currentOrder["column"]; // Sets up the order and column
            this.tableData.dir = this.currentOrder["order"];
            this.sortOrders[this.currentOrder["name"]] = this.setOrderByName(
              this.currentOrder["order"]
            );
          }

          this.tableData.filters = Object.assign({}, this.getLastFilters);
        }

        this.setLastViewedPage(url);
        this.setLastSearchInput(this.tableData.search);
        this.setAllMajors();
        this.tableData.draw++;
        this.loading = true;
        let response = await fetch(url, {
          headers: {
            "Content-type": "application/json",
            "x-jwt-token": this.$store.state.currentUser.advisorToken,
          },
          method: "post",
          body: this.getJsonObject(),
        });

        let status = await response.status;
        if (status !== 200) {
          return false;
        }

        let data = await response.json();
        if (this.tableData.draw == data.draw) {
          this.students = data.data;
          this.configPagination(data.pagination);
          if (
            Object.hasOwnProperty.call(data, "totals") &&
            Object.hasOwnProperty.call(data, "pagination")
          ) {
            this.updateCounts(data);
          }
        }

        this.loading = false;
      } else {
        this.logout();
      }
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl =
        data.last_page_url &&
        data.last_page_url.substring(0, data.last_page_url.indexOf(":")) ==
          "http"
          ? "https" + data.last_page_url.substring(4)
          : data.last_page_url;
      this.pagination.nextPageUrl =
        data.next_page_url &&
        data.next_page_url.substring(0, data.next_page_url.indexOf(":")) ==
          "http"
          ? "https" + data.next_page_url.substring(4)
          : data.next_page_url;
      this.pagination.prevPageUrl =
        data.prev_page_url &&
        data.prev_page_url.substring(0, data.prev_page_url.indexOf(":")) ==
          "http"
          ? "https" + data.prev_page_url.substring(4)
          : data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.setCustomOrder(this.sortOrders[key]);
      this.tableData.column =
        this.sortOrders[key] === 0 ? 0 : this.getIndex(this.tabColumns, key);
      this.tableData.dir = this.setOrderName(this.sortOrders[key]);
      this.resetOrders(key);
      this.alterOrder({
        column: this.tableData.column,
        order: this.tableData.dir,
        name: key,
        remove: this.sortOrders[key] === 0,
      }); // Store ordering in Vuex

      const url = this.isProspective ? "leads" : "students";
      this.getStudents(
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) + url
      );
    },
    setOrderByName(orderName) {
      return orderName === "asc" ? 1 : -1;
    },
    setOrderName(orderValue) {
      if (orderValue === 0 || orderValue === 1) return "asc";
      return "desc";
    },
    resetOrders(exceptName) {
      if (this.tableData.column === this.currentOrderIndex) return;
      Object.keys(this.sortOrders).forEach((key) => {
        if (exceptName !== key) this.sortOrders[key] = 0;
      });
    },
    setCustomOrder(currentVal) {
      /*
           1 = Meaning (asc) ascending order
          -1 = Meaning (desc) descending order
           0 = Default order (desc)
      */
      if (currentVal === 0) return 1;
      if (currentVal === 1) return -1;
      return 0;
    },
    getIndex(array, key) {
      return array.indexOf(key);
    },
    getColumnIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    selectStudent(id) {
      this.selectedStudent = this.selectedStudent === id ? "" : id;
      this.setStudentData(this.selectedStudent);
    },
    setStudentData(id) {
      this.selectedStudentData = this.students.find(function (student) {
        return student.id === this;
      }, id);
    },
    resetStudent() {
      this.selectedStudent = "";
      this.selectedStudentData = {};
    },
    setSession(sessionId) {
      this.$store.commit("university/setTabProgramSessionId", sessionId);
    },
    setApplication(applicationId) {
      this.$store.commit("university/setApplicationId", applicationId);
    },
    setTabDataAndRedirect(programId, applicationId, studentId, tab) {
      this.setSession(programId);
      this.setApplication(applicationId);
      this.$router.push({
        path: "/student-detail/" + studentId + "/" + tab,
      });
    },
    getFilterData(filters) {
      this.tableData.filters = Object.assign({}, filters);
      this.getStudents(
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "students"
      );
    },
    isApproved(formStatus) {
      if (
        Array.isArray(formStatus) ||
        formStatus === null ||
        !Object.hasOwnProperty.call(formStatus, "University Approval") ||
        !this.approvedStatuses.includes(formStatus["University Approval"])
      ) {
        return false; // Not approved
      } else {
        return true; // Approved: field is not an array, not null, an object without an university approval key, and its value is either complete or submitted
      }
    },
    setAllMajors() {
      const major = this.tableData.filters.majors.length
        ? this.tableData.filters.majors[0]
        : "";
      this.setLastFilters(this.tableData.filters);
      this.setProspectiveMajor(major);
    },
    getJsonObject() {
      const index = this.currentTab.toString();
      let newObject = Object.assign({}, this.tableData);
      //Tab indexs that do not require filters
      const noFilters = ["4", "5"];
      //Tab indexs that do not require major
      const noApproval = ["3"];

      if (noFilters.includes(index)) {
        newObject.filters.city = "";
        newObject.filters.sessions = [];
        newObject.filters.majors = [];
        newObject.filters.approvalStatus = "";
      }
      if (noApproval.includes(index)) newObject.filters.approvalStatus = "";

      return JSON.stringify(newObject);
    },
    async approveStudent(student) {
      let approvalResponse = await this.submitApproval(student);

      if (approvalResponse) {
        //set flag in enrollment service
        let flagResponse = await this.setApprovedFlag(
          student.approval_application
        );
        if (flagResponse.success) {
          this.students.forEach((item) => {
            if (item.approval_application === flagResponse.applicationId) {
              item.simplified_approval = 1;
            }
          });
        }
      }
    },
  },
};
