<script>
export default {
  name: "AlgoliaDateSearch",
};
</script>

<script setup>
import { ref, reactive, computed, defineEmits, watch, toRefs } from "vue";
import { AisConfigure } from "vue-instantsearch/vue3/es";

import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import CalendarInput from "@/components/forms/SharedComponents/CalendarInput.vue";
import useVuelidate from "@vuelidate/core";
import { requiredIf, helpers } from "@vuelidate/validators";
import BaseInput from "@/components/InternshipLibrary/BaseInput.vue";
import { getUnixTime, isWithinInterval, startOfDay } from "date-fns";

import {
  END_DATE_SETTINGS,
  START_DATE_SETTINGS,
  MAX_YEARS_OFFSET,
  IS_BETWEEN,
  IS_BETWEEN_OPTION,
  IS_OPTION,
} from "./constant";

const emits = defineEmits([
  "update-filters",
  "update:selectedStartDate",
  "update:selectedEndDate",
]);

const algoliaDateFilter = ref("");

const props = defineProps({
  selectedStartDate: {
    type: Array,
    default: () => [],
  },
  selectedEndDate: {
    type: Array,
    default: () => [],
  },
});

const { selectedStartDate, selectedEndDate } = toRefs(props);

const state = reactive({
  start_date_range: "",
  end_date_range: "",
  start_date: "",
  start_date_end: "",
  end_date: "",
  end_date_end: "",
});

const maxDate = computed(() => {
  const maxYear = new Date().getFullYear() + MAX_YEARS_OFFSET;
  const maxDate = new Date(maxYear, 11, 31).toISOString().split("T")[0];
  return maxDate;
});

const minDate = computed(() => {
  const minDate = new Date().toISOString().split("T")[0];
  return minDate;
});

const rules = computed(() => ({
  start_date: {
    required: state.start_date_range !== IS_BETWEEN,
    dateBetween: helpers.withParams({ format: "yyyy-MM-dd" }, function (value) {
      if (!helpers.req(value)) {
        return true;
      }
      const date = new Date(value);
      const newMinDate = new Date(minDate.value);
      const newMaxDate = new Date(maxDate.value);
      return date >= newMinDate && date <= newMaxDate;
    }),
  },
  start_date_range: {
    requiredIfStartDateSet: requiredIf(state.start_date),
  },
  start_date_end: {
    requiredIfStartBetween: requiredIf(state.start_date_range === IS_BETWEEN),
    dateBetween: helpers.withParams({ format: "yyyy-MM-dd" }, function (value) {
      if (!helpers.req(value)) {
        return true;
      }
      const date = new Date(value);
      const newMinDate = new Date(state.start_date);
      const newMaxDate = new Date(maxDate.value);
      return date > newMinDate && date <= newMaxDate;
    }),
  },
  end_date_range: {
    requiredIfEndDateSet: requiredIf(state.end_date),
  },
  end_date: {
    requiredIfEndDateRange: requiredIf(state.end_date_range),
    dateBetween: helpers.withParams({ format: "yyyy-MM-dd" }, function (value) {
      if (!helpers.req(value)) {
        return true;
      }
      const date = startOfDay(value);
      const today = startOfDay(new Date());
      const newMinDate = state.start_date
        ? startOfDay(state.start_date)
        : today;
      const newMaxDate = startOfDay(maxDate.value);
      return isWithinInterval(date, { start: newMinDate, end: newMaxDate });
    }),
  },
  end_date_end: {
    requiredIfEndBetween: requiredIf(state.end_date_range === IS_BETWEEN),
    dateBetween: helpers.withParams({ format: "yyyy-MM-dd" }, (value) => {
      if (!helpers.req(value)) {
        return true;
      }
      const date = new Date(value);
      const newMinDate = new Date(state.end_date);
      const newMaxDate = new Date(maxDate.value);
      return date > newMinDate && date <= newMaxDate;
    }),
  },
}));

const v$ = useVuelidate(rules, state);

const onFieldChange = (field, payload) => {
  state[field] = payload;
};

const handleSubmit = async () => {
  const isValid = await v$.value.$validate();
  if (isValid) {
    const startTimestamp = state.start_date
      ? getUnixTime(new Date(state.start_date))
      : undefined;
    const endTimestamp = state.end_date
      ? getUnixTime(new Date(state.end_date))
      : null;
    let filterString = "";
    if (startTimestamp && endTimestamp) {
      filterString = `date >= ${startTimestamp} AND date <= ${endTimestamp}`;
    } else if (startTimestamp) {
      filterString = `date >= ${startTimestamp}`;
    } else if (endTimestamp) {
      filterString = `date <= ${endTimestamp}`;
    }
    algoliaDateFilter.value = filterString;

    emits("update-filters", { filterString });

    const newSelectedStartDate = [state.start_date];
    if (state.start_date_range?.value === IS_BETWEEN) {
      newSelectedStartDate.push(state.start_date_end);
    }

    const newSelectedEndDate = [state.end_date];
    if (state.end_date_range?.value === IS_BETWEEN) {
      newSelectedEndDate.push(state.end_date_end);
    }

    emits(
      "update:selectedStartDate",
      newSelectedStartDate.filter((val) => !!val)
    );
    emits(
      "update:selectedEndDate",
      newSelectedEndDate.filter((val) => !!val)
    );
  }
};

watch(
  () => selectedStartDate,
  (newValue) => {
    // update calendar input and select box based on the prop received
    state.start_date = newValue.value?.[0] ? newValue.value?.[0] : "";
    state.start_date_end = newValue.value?.[1] ? newValue.value?.[1] : "";
    if (state.start_date && state.start_date_end) {
      state.start_date_range = IS_BETWEEN_OPTION;
    } else if (state.start_date) {
      state.start_date_range = IS_OPTION;
    } else {
      state.start_date_range = "";
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => selectedEndDate,
  (newValue) => {
    // update calendar input and select box based on the prop received
    state.end_date = newValue.value?.[0] ? newValue.value?.[0] : "";
    state.end_date_end = newValue.value?.[1] ? newValue.value?.[1] : "";
    if (state.start_date && state.start_date_end) {
      state.end_date_range = IS_BETWEEN_OPTION;
    } else if (state.start_date) {
      state.end_date_range = IS_OPTION;
    } else {
      state.end_date_range = "";
    }
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <FormPanel
    id="search-by-dates"
    title="Search By Date"
    main-panel-class="max-w-351p"
    main-header-class="border-b-0 mb-0"
    panel-button-class="w-full flex-row-reverse justify-between"
    panel-button-text-class="font-montserrat text-indigo-base font-bold uppercase sm:leading-4.5 sm:tracking-wide"
  >
    <template #content>
      <label for="start-date-range">Start Date</label>
      <BaseInput
        v-model="state.start_date_range"
        :field-settings="START_DATE_SETTINGS"
        class="exp-multi-select mb-5 outline-none uppercase max-w-351p"
        :vuelidate-errors="v$.start_date_range.$errors"
      />

      <label id="start-date-label">Date</label>
      <CalendarInput
        labelledby="start-date-label"
        :value-from-database="state.start_date"
        :validation-errors="v$.start_date"
        custom-class="font-montserrat font-bold text-base leading-6 tracking-widest bg-white block w-full border border-indigo-base outline-none h-50p text-indigo-base text-opacity-50 uppercase pl-4 max-w-351p"
        :max-years-offset="MAX_YEARS_OFFSET"
        @input="onFieldChange('start_date', $event)"
      />
      <label
        v-if="state.start_date_range?.value === IS_BETWEEN"
        id="start-date-to-range"
        >And</label
      >
      <CalendarInput
        v-if="state.start_date_range?.value === IS_BETWEEN"
        labelledby="start-date-to-range"
        :value-from-database="state.start_date_end"
        :validation-errors="v$.start_date_end"
        :max-years-offset="MAX_YEARS_OFFSET"
        custom-class="font-montserrat font-bold text-base leading-6 tracking-widest bg-white block w-full border border-indigo-base outline-none h-50p text-indigo-base text-opacity-50 uppercase pr-0 pl-4 py-1 max-w-351p"
        @input="onFieldChange('start_date_end', $event)"
      />
      <label class="mt-10" for="end-date-label">End Date</label>

      <BaseInput
        v-model="state.end_date_range"
        :field-settings="END_DATE_SETTINGS"
        class="exp-multi-select mb-5 outline-none uppercase max-w-351p"
        :vuelidate-errors="v$.end_date_range.$errors"
      />
      <label id="end-date-label">Date</label>
      <CalendarInput
        labelledby="end-date-label"
        :value-from-database="state.end_date"
        :validation-errors="v$.end_date"
        :max-years-offset="MAX_YEARS_OFFSET"
        custom-class="font-montserrat font-bold text-base leading-6 tracking-widest bg-white block w-full border border-indigo-base outline-none h-50p text-indigo-base text-opacity-50 uppercase pr-0 pl-4 py-1 max-w-351p"
        @input="onFieldChange('end_date', $event)"
      />
      <label
        v-if="state.end_date_range?.value === IS_BETWEEN"
        id="end-date-to-range"
        >And</label
      >
      <CalendarInput
        v-if="state.end_date_range?.value === IS_BETWEEN"
        labelledby="end-date-to-range"
        :value-from-database="state.end_date_end"
        :validation-errors="v$.end_date_end"
        :max-years-offset="MAX_YEARS_OFFSET"
        custom-class="font-montserrat font-bold text-base leading-6 tracking-widest bg-white block w-full border border-indigo-base outline-none h-50p text-indigo-base text-opacity-50 uppercase pr-0 pl-4 py-1 max-w-351p"
        @input="onFieldChange('end_date_end', $event)"
      />
      <button
        class="w-[148px] h-[40px] md:h-[60px] submit-btn bg-teal-400"
        @click="handleSubmit"
      >
        <span class="w-[115px] h-[20px] leading-5 text-center"
          >Apply Dates</span
        >
      </button>
    </template>
    <ais-configure :filters="algoliaDateFilter" />
  </FormPanel>
</template>

<style scoped>
.submit-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
  border: 2px solid #009898;
  margin-top: 40px;
}
</style>
