import { computed, provide, ref, watch } from "vue";
import { useStore } from "vuex";

export function useVisibleHeaders(maxHeaders) {
  const store = useStore();
  const visibleHeaders = ref([]);
  const persistedHeaders = ref([]);
  const defaultHeaders = computed(
    () => store.getters["programManager/getTableDefaultHeaders"]
  );

  const setVisibleHeaders = (headers) => {
    visibleHeaders.value = headers.length
      ? headers
      : store.getters["programManager/getTableSpecifHeaders"](maxHeaders);
  };
  const updateHeaders = (headers) => (visibleHeaders.value = headers);
  const setPersistedHeaders = (headers) => (persistedHeaders.value = headers);

  provide("visibleOptions", visibleHeaders);
  provide("maxHeaders", maxHeaders);

  watch(
    () => defaultHeaders,
    (newValue) => {
      if (persistedHeaders.value.length) {
        setVisibleHeaders(persistedHeaders.value);
      } else {
        setVisibleHeaders(newValue.value);
      }
    },
    { deep: true }
  );

  return {
    visibleHeaders,
    defaultHeaders,
    setVisibleHeaders,
    setPersistedHeaders,
    updateHeaders,
  };
}
