<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import Caret from "@/components/shared/icons/Caret.vue";
import { defineProps } from "vue";
import ErrorMessage from "../ErrorMessage.vue";

const {
  fieldLabel,
  vuelidateInstance,
  fieldLabelClasses,
  errorClasses,
  outerClasses,
  inputClasses,
} = defineProps({
  fieldLabel: {
    type: String,
  },
  vuelidateInstance: {},
  fieldLabelClasses: { default: "common-label-text" },
  errorClasses: { default: "common-error-text" },
  outerClasses: { default: "common-error-text" },
  inputClasses: { type: String },
});
</script>

<template>
  <div :class="outerClasses">
    <slot name="fieldLabel">
      <label
        v-show="fieldLabel"
        :name="fieldLabel"
        :class="fieldLabelClasses"
        >{{ fieldLabel }}</label
      >
    </slot>
    <v-select
      class="clear-align v_select_override"
      v-bind="$attrs"
      :class="[
        {
          'forced-error-background': !!vuelidateInstance?.$errors?.length,
        },
        inputClasses,
      ]"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes" class="mb-[8px] inline-block">
          <Caret width="12px" height="12px" />
        </span>
      </template>
      <template v-for="(_, name) in $slots" #[name]="slotProps">
        <slot :name="name" v-bind="slotProps || {}"></slot>
      </template>
    </v-select>
    <ErrorMessage
      :error-classes="errorClasses"
      :vuelidate-instance="vuelidateInstance"
    />
  </div>
</template>

<style scoped>
.clear-align :deep(.vs__actions) {
  cursor: default;
}
.clear-align :deep(.vs__clear) {
  margin-bottom: 6px;
}
.forced-select-box-grow :deep(.vs__dropdown-toggle),
:deep(.vs__selected-options) {
  overflow: visible;
  max-height: inherit;
}
.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
.placeholder-center :deep(.vs__search) {
  margin: 0;
  padding: 2px 0 2px;
}
.placeholder-indigo-base :deep(input::placeholder) {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 38, 76, var(--tw-placeholder-opacity));
}
</style>

<style>
.v_select_override .vs__dropdown-option {
  color: black;
}
.v_select_override .vs__search {
  color: black;
}
.v_select_override .vs__dropdown-option--highlight {
  color: white;
}
</style>
