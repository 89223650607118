<script setup>
import { onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Table from "@/components/shared/Table/SimpleTable.vue";
import SimpleBreadCrumb from "@/components/shared/SimpleBreadCrumb.vue";
import classesService from "@/services/classes.js";
import Spinner from "@/components/helpers/Spinner.vue";
import syllabusService from "@/services/syllabusService";
import RightArrowIcon from "@/components/svg-icons/RightArrowIcon.vue";
import { parseISO, format } from "date-fns";

const route = useRoute();
const router = useRouter();
const courseId = ref(route.params?.courseId);
const course = ref(null);
const hideCourseRequirements = ref(true);
const isFullDescriptionShown = ref(false);
const isExpanded = ref(false);
const hover = ref(false);
const programSessions = ref([]);
const loadingRelatedSessions = ref(false);

const courseDetails = ref([
  {
    title: "Host University",
    type: "simple-text",
    content: course?.value?.course.value.host_institution.name,
    filled: true,
    hide: !course?.value?.course.value.host_institution.name,
  },
  {
    title: "Prerequisite Courses",
    type: "simple-text",
    content: course?.value?.prerequisites ?? "",
    filled: false,
    hide: true,
  },
  {
    title: "Instruction Language",
    type: "simple-text",
    content: course?.value?.language_of_instruction.value ?? "",
    filled: false,
    hide: !course?.value?.language_of_instruction.value,
  },
  {
    title: "Required Language Level",
    type: "simple-text",
    content: course?.value?.language_of_level_requirement ?? "",
    filled: true,
    hide: true,
  },
  {
    title: "US Credits",
    type: "simple-text",
    content: course?.value?.recommended_us_credit_amount ?? "",
    filled: true,
    hide: !course?.value?.recommended_us_credit_amount,
  },
  {
    title: "Class Level",
    type: "simple-text",
    content: course?.value?.level?.length ? course.value.level.join(", ") : "",
    filled: false,
    hide: !course?.value?.level?.length,
  },
  {
    title: "Class Format",
    type: "simple-text",
    content: course?.value?.format?.value ?? "",
    filled: true,
    hide: !course?.value?.format?.value,
  },
  {
    title: "Classroom Peers",
    type: "list-text",
    content: course?.value?.classroom_peers,
    filled: true,
    hide: !course?.value?.classroom_peers,
  },
]);

const modifiedCourseDetails = computed(() => {
  let isAlternate = false;
  return courseDetails.value.map((detail) => {
    const modifiedDetail = { ...detail };

    if (course.value) {
      switch (modifiedDetail.title) {
        case "Host University":
          modifiedDetail.content = course.value.host_institution.name ?? "";
          modifiedDetail.hide = !course.value.host_institution.name;
          break;
        case "Prerequisite Courses":
          modifiedDetail.content = course.value.prerequisites ?? "";
          modifiedDetail.hide = !course.value.prerequisites;
          break;
        case "Instruction Language":
          modifiedDetail.content =
            course.value.language_of_instruction?.value ?? "";
          modifiedDetail.hide = !course.value.language_of_instruction?.value;
          break;
        case "Required Language Level":
          modifiedDetail.content =
            course.value.language_of_level_requirement?.value ?? "";
          modifiedDetail.hide = !course.value.language_of_level_requirement
            ?.value;
          break;
        case "US Credits":
          modifiedDetail.content =
            course.value.recommended_us_credit_amount ?? "";
          modifiedDetail.hide = !course.value.recommended_us_credit_amount;
          break;
        case "Class Level":
          modifiedDetail.content = course.value.level?.join(", ") ?? "";
          modifiedDetail.hide = !course.value.level?.length;
          break;
        case "Class Format":
          modifiedDetail.content = course.value.format?.value ?? "";
          modifiedDetail.hide = !course.value.format?.value;
          break;
        case "Classroom Peers":
          modifiedDetail.content = course?.value?.classroom_peers ?? "";
          modifiedDetail.hide = !course.value.classroom_peers?.length;
          break;
      }
    }
    if (!modifiedDetail.hide) {
      modifiedDetail.filled = isAlternate;
      isAlternate = !isAlternate;
    }

    return modifiedDetail;
  });
});

const getUrlSyllabus = (api, hostInstitution) => {
  return api ?? hostInstitution;
};

const failures = [null, "", undefined, [], {}];
classesService.getClassById(courseId.value).then((result) => {
  course.value = result?.data?.data;

  hideCourseRequirements.value =
    failures.includes(course?.value?.language_of_level_requirement) &&
    failures.includes(course?.value?.prerequisites) &&
    failures.includes(course?.value?.corequisites);
});

const downloadFile = async (url) => {
  try {
    const blob = await syllabusService.downloadSyllabus(url);

    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = downloadUrl;
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Download failed:", error.message);
  }
};

const onHoverHandler = () => {
  hover.value = true;
};

const onLeaveHandler = () => {
  hover.value = false;
};

const openSyllabus = () => {
  const apiUrlSyllabus = course.value.api_url_syllabus;
  const hostInstitutionUrlSyllabus = course.value.host_institution_url_syllabus;
  if (
    hostInstitutionUrlSyllabus &&
    isValidHttpUrl(hostInstitutionUrlSyllabus)
  ) {
    window.open(hostInstitutionUrlSyllabus, "_blank");
  } else if (apiUrlSyllabus) {
    downloadFile(apiUrlSyllabus);
  } else {
    console.error("No syllabus available to open or download.");
  }
};

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "https:";
}

const displayedDescription = computed(() => {
  if (isFullDescriptionShown.value || !course.value?.description) {
    return course.value.description;
  }
  return course.value.description.substring(0, 325) + "...";
});
const toggleDescription = () => {
  isFullDescriptionShown.value = !isFullDescriptionShown.value;
};

const sortedAreasOfStudy = computed(() => {
  return [...(course.value?.cip_codes ?? [])].sort((a, b) =>
    a.cip_title.localeCompare(b.cip_title)
  );
});

const displayedAreasOfStudy = computed(() => {
  const length = sortedAreasOfStudy.value?.length ?? 0;

  if (isExpanded.value || length <= 2) {
    return sortedAreasOfStudy.value;
  }
  return sortedAreasOfStudy.value.slice(0, 2);
});

const moreCount = computed(() => {
  const total = sortedAreasOfStudy.value?.length ?? 0;
  return total - 2; // Number of items beyond the first two
});

const hasLinkText = computed(() => {
  return course?.value?.link_text?.length ?? false;
});

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

const fetchProgramSessions = async (courseId) => {
  loadingRelatedSessions.value = true;
  try {
    const response = await classesService.getProgramSessionsByClassId(courseId);
    programSessions.value = response.data.data;
  } finally {
    loadingRelatedSessions.value = false;
  }
};

onMounted(() => {
  if (courseId.value) {
    fetchProgramSessions(courseId.value);
  }
});

const formatDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "MMM d, yyyy", { timeZone: "UTC" });
};
</script>
<template>
  <div class="md:flex md:flex-col bg-gray-10">
    <!-- <ExperienceHeader /> -->
    <SimpleBreadCrumb
      class="md:!ml-[50px] !ml-0 md:mb-[10px]"
      custom-active-class="text-teal-700 font-semibold"
    />
    <div>
      <!--- CONTENT ---->
      <div
        v-if="course"
        class="text-base text-indigo-base bg-white font-montserrat ml-3 mr-3"
      >
        <!-- SECTION 01 -->
        <div class="w-full sm:px-0 grid grid-cols-12">
          <!-- Left Column -->
          <div class="col-span-9 z-20">
            <div class="bg-white px-3 pt-3 font-montserrat">
              <div class="mt-10">
                <div class="md:relative md:z-20">
                  <p
                    class="font-bold mt:text-3xl text-2xl w-full md:mt-0 mt-3 title-with-line"
                  >
                    {{ course.title }}
                  </p>
                  <p class="text-base mt-8 hidden md:block">
                    {{ course.description }}
                  </p>
                  <!-- Truncated Description and Read More/Less for mobile screens -->
                  <p class="text-base mt-8 md:hidden">
                    {{ displayedDescription }}
                    <span
                      v-if="
                        !isFullDescriptionShown &&
                        course.description?.length > 325
                      "
                      class="font-bold text-teal-900 justify-center items-center gap-x-3 cursor-pointer"
                    >
                      <a href="#" @click.prevent="toggleDescription"
                        >Read more</a
                      >
                    </span>
                    <span
                      v-else-if="isFullDescriptionShown"
                      class="font-bold text-teal-900 justify-center items-center gap-x-3 cursor-pointer"
                    >
                      <a href="#" @click.prevent="toggleDescription"
                        >Read less</a
                      >
                    </span>
                  </p>
                </div>
              </div>
              <!-- DATES AVAILABLE Section -->
              <div v-if="loadingRelatedSessions" class="text-center p-4">
                <Spinner
                  class="relative h-50-screen"
                  data-testid="loading-sessions-spinner"
                />
              </div>
              <div v-else class="py-4">
                <h2
                  class="mb-3 font-bold text-indigo-base text-lg uppercase"
                  data-testid="dates-available-heading"
                >
                  Dates Available
                </h2>
                <div class="h-px bg-blue-400 my-4"></div>
                <div class="space-y-3">
                  <div
                    v-for="session in programSessions"
                    :key="session.session_id"
                    class="bg-gray-75 p-4"
                    :data-testid="`session-item-${session.session_id}`"
                  >
                    <router-link
                      :to="`/program-page/${session.program_page_id}?session-id=${session.session_id}`"
                      target="_blank"
                      class="flex flex-col md:flex-row md:items-center justify-between"
                    >
                      <span class="font-bold uppercase text-sm"
                        >{{ session.term.toUpperCase() }} {{ session.year }}
                        {{ session.program_page_name }}</span
                      >
                      <div
                        class="flex items-center justify-end space-x-2 md:justify-start"
                      >
                        <span
                          class="whitespace-nowrap font-bold uppercase text-sm"
                          >{{ formatDate(session.arrival_date) }} -
                          {{ formatDate(session.departure_date) }}</span
                        >
                        <RightArrowIcon class="shrink-0" />
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Right Column -->
          <div
            class="md:mt-10 lg:mt-10 col-span-12 md:col-span-5 lg:col-span-3 w-[376px] custom-md-grid-right"
          >
            <div class="bg-white px-3">
              <div
                v-if="
                  course.api_url_syllabus ||
                  course.host_institution_url_syllabus
                "
                class="flex items-center justify-start md:mt-4 md:mb-4x mt-12 mb-12"
              >
                <button
                  :class="[
                    'order-1 mt-3 box-border flex h-[60px] max-h-60p w-full flex-row items-center justify-center p-5 md:order-2 md:mt-0 md:w-full lg:mt-0 lg:w-full',
                    'border-2',
                    hover ? 'bg-white text-teal-900' : 'bg-teal-900 text-white',
                  ]"
                  @mouseover="onHoverHandler"
                  @mouseleave="onLeaveHandler"
                  @click="openSyllabus"
                >
                  <div
                    class="flex items-center gap-1 text-base font-bold uppercase tracking-tight"
                  >
                    <span v-if="hasLinkText">{{ course.link_text }}</span>
                    <span v-else>DOWNLOAD SYLLABUS</span>
                  </div>
                </button>
              </div>
              <!-- Course Details -->
              <p class="font-semibold">COURSE DETAILS</p>
              <hr class="mt-4 text-gray-25" />
              <div class="py-3 w-full">
                <Table :data="modifiedCourseDetails" />
              </div>
            </div>
            <!-- Areas of Study -->
            <div
              v-if="sortedAreasOfStudy.length > 0"
              class="bg-white px-3 pt-3 md:mt-10 mt-12 custom-md-grid-right"
            >
              <p class="font-bold">AREAS OF STUDY</p>
              <hr class="mt-4 text-gray-25" />
              <div class="py-3 text-lg font-normal leading-loose">
                <ul class="list-disc ml-6 space-y-3 text-base font-light">
                  <li
                    v-for="code in displayedAreasOfStudy"
                    :key="code.cip_code"
                  >
                    {{ code.cip_title }}
                  </li>
                  <li
                    v-if="!isExpanded && moreCount > 0"
                    class="custom-bullet text-teal-900 font-medium font-montserrat justify-center items-center gap-x-3 cursor-pointer"
                    @click="toggleExpand"
                  >
                    +{{ moreCount }} more
                  </li>
                  <li
                    v-else-if="isExpanded && moreCount > 0"
                    class="custom-bullet text-teal-900 font-medium font-montserrat justify-center items-center gap-x-3 cursor-pointer"
                    @click="toggleExpand"
                  >
                    -{{ moreCount }} less
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- END CONTENT ---->
      <div v-else>
        <Spinner class="relative h-50-screen" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.title-with-line {
  position: relative;
  padding-left: 15px;
}

.title-with-line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 1px;
  background-color: #1e264c;
}
.custom-bullet {
  list-style: none;
  position: relative;
  padding-left: 0.2em;
}

.custom-bullet::before {
  content: "•";
  color: #1e264c;
  display: inline-block;
  font-size: 1.7em;
  margin-left: -0.56em;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
}

@media (max-width: 767px) {
  .grid-cols-12 {
    display: block;
  }

  .col-span-9 {
    width: 100%;
  }
  .custom-md-grid-right {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 376px;
    max-width: 100%;
    width: 376px;
  }
}

@media (min-width: 768px) {
  .grid-cols-12 {
    display: flex;
  }

  .col-span-9 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 376px);
  }

  .custom-md-grid-right {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 376px;
    width: 376px;
    max-width: 376px;
  }
}

@media (min-width: 1651px) {
  .custom-md-grid-right {
    grid-column: span 3 / span 3;
  }
}
</style>
