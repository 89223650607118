<script setup>
import CloudinaryButton from "@/components/CloudinaryUploadWidget";
import formPanel from "@/components/forms/SharedComponents/panel.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";
import { useVModel } from "@vueuse/core";

const props = defineProps({
  editState: Boolean,
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);
const data = useVModel(props, "modelValue", emit);

const citiesMediaPath = "cities";

const insertSupportingMedia = (payload) => {
  const url = payload.secure_url;
  const baseCityMedia = data.value.supporting_media || [];
  if (!baseCityMedia.includes(url))
    data.value.supporting_media = [url, ...baseCityMedia];
};

const removeSupportingMedia = (index) => {
  data.value.supporting_media.splice(index, 1);
};
</script>

<template>
  <form-panel
    id="cities-media"
    title="Media Section"
    panel-content-class="grid grid-cols-1 gap-4 pb-6 mb-6 border-b border-gray-200 md:grid-cols-1 sm:gap-6"
    panel-button-text-class="tracking-normal"
  >
    <template #content>
      <div class="rounded-sm border border-gray-300 bg-white p-8">
        <CloudinaryButton
          v-if="editState"
          :folder="citiesMediaPath"
          placeholder="Upload"
          :public-id-prefix="data.id?.toString()"
          @uploaded-media-data="insertSupportingMedia"
        />
        <VDraggable
          v-model="data.supporting_media"
          class="flex flex-wrap pb-2"
          item-key="index"
          ghost-class="opacity-50"
          :disabled="!editState"
        >
          <template #item="{ element: src, index }">
            <div
              class="relative mr-10 mt-10 h-24 w-24 flex-none cursor-move duration-300 hover:scale-110 hover:shadow-2xl"
            >
              <button
                v-if="editState"
                class="absolute -right-4 -top-4 h-8 w-8 rounded-full border border-gray-400 bg-white duration-150 hover:scale-125 hover:text-red-100"
                @click="removeSupportingMedia(index)"
              >
                <TrashIcon class="mx-auto h-5 w-5" />
              </button>
              <img :src="src" alt="Support media" class="h-full w-full" />
            </div>
          </template>
        </VDraggable>
      </div>
    </template>
  </form-panel>
</template>
