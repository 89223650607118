import { learningServiceRequest } from "./utils";
import { SUCCESSFULLY_LOAD } from "../constants";

const miscProductsService = {
  async list(search) {
    const { data: response } = await learningServiceRequest({
      method: "get",
      url: "/misc_products",
      params: { limit: 10, q: search },
      message: {
        success: SUCCESSFULLY_LOAD,
        failure: "Error loading Misc Products",
      },
    });
    return response?.data?.items ?? [];
  },
  async getById(miscProductId) {
    return await learningServiceRequest({
      method: "get",
      url: `/misc_products/${miscProductId}`,
    });
  },
};

export default miscProductsService;
