<template>
  <svg
    width="19"
    height="11"
    viewBox="0 0 19 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 6.57699L2.3232 0L-5.61575e-10 2.309L9.48814 10.9847L19 2.309L16.6887 0L9.5 6.57699Z"
      fill="#1E264C"
    />
  </svg>
</template>
