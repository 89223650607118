<script setup>
import {
  defineProps,
  defineEmits,
  toRefs,
  computed,
  reactive,
  provide,
  readonly,
  ref,
  watch,
} from "vue";
import ApplyButton from "@/components/shared/Button/ApplyButton.vue";
import EventsCard from "@/components/ExperiencePage/ProgramDetailsSection/excursions/Components/EventsCards";
import eventsService from "@/services/events.js";
import programSessions from "@/services/programSessions.js";
import LoadingText from "../team/components/LoadingText";
import pLimit from "p-limit";
import { useStore } from "vuex";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";

defineOptions({
  name: "ExcursionBlock"
})

const store = useStore();
const emit = defineEmits(["personalizeAndApply", "observeSection"]);

const props = defineProps({
  sessionId: {
    type: String,
    required: true,
  },
});

const limit = pLimit(2);

const state = reactive({
  loading: false,
  excursions: [],
});

const { sessionId } = toRefs(props);
const sessions = ref(undefined);

const homeInstitutionId = computed(
  () => store.state.profileData?.colleges?.[0]?.college_id ?? ""
);

const loadProgramSession = async (programSessionId) => {
  if (programSessionId) {
    return programSessions
      .getProgramSession({
        id: programSessionId,
        rules: true,
        home_institution_id: homeInstitutionId.value,
      })
      .then((programSesssion) => {
        sessions.value = programSesssion?.data?.data?.items ?? undefined;
      });
  } else {
    sessions.value = undefined;
    return;
  }
};

watch(sessionId, () => {
  loadProgramSession(sessionId.value);
});

const sessionEvents = computed(() => state.excursions);
const goToPersonalizeAndApply = () => {
  emit("personalizeAndApply", sessionId.value);
};

const loadExcursions = async () => {
  state.excursions = [];
  state.loading = true;

  if (sessions.value?.session_events) {
    showSection.value = true;
    const occurrenceRequests = sessions.value?.session_events.map((excursion) =>
      limit(() =>
        eventsService
          .getOccurrences({ occurrence_ids: excursion.event_id })
          .catch(() => {
            return null;
          })
      )
    );
    let occurrenceResponse = await Promise.all(occurrenceRequests);
    let occurrences = occurrenceResponse
      .map((response) => {
        const resp = response?.data?.data?.items?.[0]?.associated_event ?? {};
        resp.id = response?.data?.data?.items?.[0]?.id;
        resp.event_id = response?.data?.data?.items?.[0]?.event_id;
        resp.occurrence_price_student =
          response?.data?.data?.items?.[0]?.price_student;
        resp.start_date_time = response?.data?.data?.items?.[0]?.start_date_time;
        resp.end_date_time = response?.data?.data?.items?.[0]?.end_date_time;
        return resp;
      })
      .filter((item) => item);

    if (occurrences.length) {
      const eventRequests = occurrences.map((occurrence) =>
        limit(() =>
          eventsService
            .getOne(occurrence.event_id)
            .catch(() => {
              return null;
            })
        )
      );
      let eventResponse = await Promise.all(eventRequests);
      let events = eventResponse
        .map((response) => response?.data?.data)
        .filter((item) => item)
        .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

      state.excursions = occurrences.map((occurrence) => ({
        ...occurrence,
        event: events[occurrence.event_id],
      }));
    }

    state.loading = false;
  } else {
    state.excursions = [];
    state.loading = false;
  }
};

//  PRICE IS INCLUDED? SECTION
const eventRules = reactive({
  andIds: [],
  orIds: [],
  defaultIds: [],
});

const loadRules = () => {
  eventRules.andIds = [];
  eventRules.orIds = [];
  eventRules.defaultIds = [];

  let rules = sessions.value?.session_event_selection_rules ?? [];
  for (const rule of rules) {
    const ids = rule?.library_events?.map((item) => item?.event_id);

    if (rule?.conjunction === "or") {
      if (rule?.default_event_id)
        eventRules.defaultIds.push(rule?.default_event_id);
      else eventRules.orIds = [...eventRules.orIds, ...ids];
    }

    if (rule?.conjunction === "and")
      eventRules.andIds = [...eventRules.andIds, ...ids];
  }
};

const defaultEvent = computed(() => {
  // MVP: Only one event is default
  const defaultEventId =
    sessions.value?.session_event_selection_rules?.[0]?.default_event_id;

  if ([null, undefined, "undefined"].includes(defaultEventId)) return {};
  let foundExcursion = state.excursions.find(
    (excursion) => excursion.id === defaultEventId
  );
  return foundExcursion ? foundExcursion.event : {};
  // Post-MVP: There could be multiple default events
});

provide("eventRules", readonly(eventRules));

const showSection = ref(sessions.value?.session_events.length ? true : false);

watch(
  sessions,
  () => {
    state.loading = true;
    loadExcursions();
    loadRules();
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  [() => showSection.value, () => state.excursions],
  ([newShowSection, newExcursions]) => {
    if (newShowSection && newExcursions.length)
      emit("observeSection", "excursions");
  }
);
</script>

<template>
  <div
    v-if="showSection && state.excursions.length"
    class="pt-20 px-4 md:px-8 text-indigo-base"
  >
    <div class="grid lg:grid-cols-2 grid-flow-row gap-4">
      <span class="self-center uppercase font-bold text-4xl"
        >Excursions & Activities</span
      >
    </div>
    <div class="mt-4 mb-2 text-lg">
      <p>
        API students participate in excursions designed to help familiarize them
        with the culture and surrounding areas of their host city and country.
        The following is a listing of potential excursions for this program.
        Personalize and apply to see more!
      </p>
    </div>
    <LoadingText v-if="state.loading || !sessionEvents?.length" class="mt-4" />
    <EventsCard :events="sessionEvents" :default-event="defaultEvent" />

    <div class="md:h-40 flex justify-end">
      <div
        class="w-full lg:w-96 grid md:grid-rows-2 grid-flow-rows gap-2 md:gap-4"
      >
        <div class="hidden sm:block">
          <ApplyButton
            :id="sessions?.id"
            :disabled="pastSessionDeadline(sessions)"
            type="button"
            :outlined="true"
            class="py-2 mt-3 xs:mt-0 text-sm"
            event-category="Program Page Personalize CTA"
            @click="goToPersonalizeAndApply"
          />
        </div>
      </div>
    </div>
    <hr class="my-1 border-b-3 md:mt-20 border-gray-200" />
  </div>
</template>
