<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4" data-cy="flight-tickets-banner">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: $route.params.applicationId },
        }"
      >
        <span
          class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left w-4 h-4 cursor"
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
          <span class="ml-1">Back</span>
        </span>
      </router-link>
    </div>

    <div
      v-if="!loading && showAlertData.show"
      class="w-full bg-warning-900 sm:h-20 md:h-10 p-4 flex items-center justify-center rounded mb-4"
    >
      <p class="text-white text-base ml-auto">
        {{ showAlertData.msg }}
      </p>
      <p
        data-cy="travel-banner"
        class="text-white text-base ml-auto mr-4 cursor-pointer"
        @click="hideAlert()"
      >
        X
      </p>
    </div>

    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <template v-if="loading">
        <!-- Loader -->
        <div
          class="w-full bg-gray-50 h-full absolute inset-0 z-50 bg-opacity-75"
          data-cy="travel-plans-loader"
        >
          <div class="flex flex-col items-center justify-center h-full gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
              <g fill="#4354A8" fill-rule="evenodd">
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -1.1s;"
                  d="M33.492 3.465v8.934c0 4.62-6.982 4.62-6.982 0V3.465c0-4.62 6.982-4.62 6.982 0z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -1s;"
                  d="M46.29 8.766l-4.466 7.739c-2.311 4-8.358.508-6.047-3.492l4.467-7.74c2.311-4 8.358-.508 6.047 3.493z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.9s;"
                  d="M54.726 19.755l-7.737 4.467c-4 2.311-7.49-3.738-3.492-6.046l7.737-4.467c4-2.309 7.49 3.738 3.492 6.046z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.8s;"
                  d="M56.535 33.492h-8.934c-4.62 0-4.62-6.982 0-6.982h8.934c4.62 0 4.62 6.982 0 6.982z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.7s;"
                  d="M51.234 46.29l-7.737-4.466c-4-2.309-.508-8.358 3.492-6.047l7.737 4.467c4 2.311.509 8.358-3.492 6.047z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.6s;"
                  d="M40.245 54.726l-4.467-7.737c-2.309-4 3.738-7.49 6.046-3.492l4.467 7.737c2.309 4-3.738 7.493-6.046 3.492z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.5s;"
                  d="M26.508 56.535v-8.934c0-4.62 6.984-4.62 6.984 0v8.934c0 4.62-6.984 4.62-6.984 0z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.4s;"
                  d="M13.71 51.234l4.466-7.737c2.311-4 8.358-.508 6.047 3.492l-4.467 7.737c-2.309 4-8.358.509-6.047-3.492z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.3s;"
                  d="M5.274 40.245l7.737-4.467c4-2.309 7.49 3.738 3.492 6.046l-7.737 4.467c-4 2.309-7.49-3.738-3.492-6.046z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.2s;"
                  d="M3.465 26.51h8.934c4.62 0 4.62 6.982 0 6.982H3.465c-4.62 0-4.62-6.982 0-6.982z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: -0.1s;"
                  d="M16.505 18.176c4 2.309.508 8.358-3.492 6.047l-7.737-4.467c-4.001-2.309-.509-8.358 3.492-6.047l7.737 4.467z"
                />
                <path
                  class="animate-spinner"
                  style="opacity: 0.1; animation-delay: 0s;"
                  d="M24.222 13.013c2.311 3.998-3.738 7.49-6.046 3.49l-4.467-7.738c-2.309-4 3.738-7.49 6.046-3.492l4.467 7.74z"
                />
              </g>
            </svg>
            <p>
              {{ message }}
            </p>
            <p class="text-sm">
              Something not working as expected?
              <a class="underline text-teal-100" href="/applications">
                Click here to go back.
              </a>
            </p>
          </div>
        </div>
      </template>
      <template v-if="!loading">
        <h1 class="mb-2 text-xl md:font-semibold md:text-2xl">
          Travel Plans
        </h1>
        <p class="text-gray-600">
          We ask our participants to share their travel plans so we can best
          support you in your experience abroad. If there is a delay or issue in
          your travel, by having your travel information we will be able to plan
          accordingly.
        </p>

        <div
          class="grid grid-cols-1 gap-y-6 gap-x-6 mt-6 md:gap-y-12 md:grid-cols-2"
        >
          <div class="grid grid-cols-1 gap-y-6">
            <PageSection
              v-if="components.arrival.show"
              :id="'arrival-guidelines'"
              :title="'Arrival Guidelines'"
              :extra-span-classes="'tracking-widest'"
            >
              <template #content>
                <arrival
                  role="region"
                  aria-label="Arrival Guidelines"
                  :program-id="plansData.programId"
                  :arrival-date="plansData.arrivalDate"
                  :arrival-time="plansData.arrivalTime"
                  :show="plansData.programSuccesCall"
                  :arrival-airport="plansData.arrivalAirport"
                  :is-final-date="plansData.startDateIsFinal"
                  :rule="components.arrival.rule"
                />
              </template>
            </PageSection>

            <PageSection
              v-if="components.faqs.show"
              :id="'arrival-faqs'"
              :title="'Arrival FAQs'"
              :extra-span-classes="'tracking-widest'"
            >
              <template #content>
                <faqs
                  role="region"
                  aria-label="FAQS"
                  :rule="components.faqs.rule"
                />
              </template>
            </PageSection>

            <PageSection
              v-if="components.departure.show"
              :id="'dep-guides'"
              :title="'Departure Guidelines'"
              :extra-span-classes="'tracking-widest'"
            >
              <template #content>
                <departure
                  role="region"
                  aria-label="Departure Guidelines"
                  :departure-date="plansData.departureDate"
                  :is-final-date="plansData.endDateIsFinal"
                  :rule="components.departure.rule"
                />
              </template>
            </PageSection>
          </div>

          <div class="grid grid-cols-1 gap-y-6">
            <arrangement
              v-if="components.plans.show || components.plans.checkboxForm"
              :form-id="plansData.formId"
              :checkbox-form="components.plans.checkboxForm"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script type="text/javascript">
import PageSection from "../../forms/SharedComponents/Layout/PageSection";
import arrivalGuidelines from "./arrival-guidelines";
import faqs from "./arrival-faqs";
import departureGuidelines from "./departure-guidelines";
import travelArrangementform from "./travel-arrangement-form.vue";
import formService from "@/services/form";

const lastErrorMessage =
  "The program you've applied doesn't have any sections to show, we recommend you to go back.";
export default {
  name: "TravelPlansForm",
  components: {
    PageSection,
    arrival: arrivalGuidelines,
    faqs: faqs,
    departure: departureGuidelines,
    arrangement: travelArrangementform,
  },
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: true,
      message: "",
      showAlertData: {
        show: false,
        msg: "",
      },
      components: {
        faqs: {
          show: false,
          rule: "",
        },
        arrival: {
          show: false,
          rule: "",
        },
        departure: { show: false },
        plans: {
          show: false,
          checkboxForm: false,
        },
      },
      plansData: {
        program: {},
        formId: "",
        applicationId: "",
        programId: "",
        departureDate: "",
        startDateIsFinal: false,
        arrivalDate: "",
        arrivalTime: "",
        arrivalAirport: "",
        programSuccesCall: false,
        enrollmentStatus: "",
        endDateIsFinal: false,
      },
      availableSections: 0,
    };
  },
  async created() {
    // 0. Setting application id
    this.plansData.applicationId = this.$route.params.applicationId;
    // 1. Getting token
    this.message = "Getting Form Token";
    this.$store
      .dispatch("prepareFormio")
      .then(async () => {
        // 1.2 Getting form submissions
        const queryResult = await this.getTravelPlansSubmissions();
        if (queryResult?._id) {
          this.plansData.formId = queryResult._id;
        } else {
          console.warn(
            "Could not find a previous submission for the Travel Arrangements form with this application Id."
          );
        }

        // 2. Getting Progress
        this.message = "Getting Student applications";
        let progress = await this.getProgress();
        if (progress.ok) {
          let progressData = await progress.json();
          // 2.1 get progress based on Application ID
          let currentProgress = progressData.find(
            (application) =>
              application.application_id === this.plansData.applicationId
          );
          if (currentProgress === undefined) {
            this.message =
              "Cannot continue loading this page, the application id provided does not match our records";
            return;
          } else {
            this.plansData.program = currentProgress;
            this.plansData.enrollmentStatus = currentProgress.enrollment_status;
            this.plansData.programId =
              currentProgress.salesforce_program_session_id;
          }
        }

        // 2.2 Getting Program Dates Information
        this.message = "Getting program details";
        let programDetails = await this.getGuidelines();
        if (programDetails.ok) {
          let programData = await programDetails.json();
          this.plansData.arrivalTime = this.formattedBy(
            programData["arrive_by_localtime"]
          );
          this.plansData.arrivalDate = this.formattedDate(
            programData["start_date"]
          );
          this.plansData.departureDate = this.formattedDate(
            programData["end_date"]
          );
          this.plansData.arrivalAirport = programData["arrival_airport"];
          this.plansData.startDateIsFinal =
            programData["start_date_is_final"] === 1;
          this.plansData.endDateIsFinal =
            programData["end_date_is_final"] === 1;
          this.plansData.programSuccesCall = true;
          this.showAlert(
            this.plansData.enrollmentStatus,
            this.plansData.startDateIsFinal
          );
        }

        // 2.3 Getting program form rules
        this.message = "Checking form rules";
        if (
          Object.hasOwnProperty.call(
            this.plansData.program,
            "salesforce_program_session_id"
          ) &&
          this.plansData.program.salesforce_program_session_id
        ) {
          let programData = await this.getProgram(this.plansData.programId);
          if (programData.ok) {
            let programResolved = await programData.json();
            if (
              Object.hasOwnProperty.call(
                programResolved,
                "post_acceptance_form_rules"
              ) &&
              programResolved.post_acceptance_form_rules
            ) {
              this.processFormRules(programResolved.post_acceptance_form_rules);
              if (!this.availableSections) {
                this.message = lastErrorMessage;
                return;
              }
            } else {
              this.message = lastErrorMessage;
              return;
            }
          }
        } else {
          this.message =
            "Cannot continue loading this page, there is no program information for the application id provided";
          return;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    showAlert(status, dateIsFinal) {
      const statuses = ["Application Started", "Ready for Review"];
      if (!dateIsFinal) {
        this.showAlertData.show = true;
        this.showAlertData.msg =
          "Please do not purchase flight tickets yet because your program start dates have not been finalized.";
      } else if (statuses.includes(status)) {
        this.showAlertData.show = true;
        this.showAlertData.msg =
          "Please do not purchase flight tickets until you have been accepted into your program.";
      }
    },
    hideAlert() {
      this.showAlertData.show = false;
    },
    getTravelPlansSubmissions() {
      return formService
        .submissionExists("travelarrangements", {
          "data.application_id": this.$route.params.applicationId,
        })
        .catch(() => null);
    },
    async getProgress() {
      let programEndpoint = this.trailingSlash(
        process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
      );
      programEndpoint += "progress";
      return fetch(programEndpoint, {
        headers: { "x-jwt-token": this.$store.state.formioToken },
      });
    },
    async getProgram(session) {
      let programEndpoint = this.trailingSlash(
        process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT
      );
      programEndpoint += "session/" + session;
      return fetch(programEndpoint);
    },
    processFormRules(rules) {
      let sectionRules = [
        "CP Arrival Guidelines - Group Flight",
        "CP Departure Guidelines",
        "CP Arrival FAQ",
        "Arrival Guidelines",
        "Arrival FAQ",
        "Departure Guidelines",
        "Student Travel Arrangements",
      ];
      let i = 0;
      for (i; i < rules.length; i++) {
        let rule = rules[i];
        let ruleIsPresent = sectionRules.includes(rule);
        if (ruleIsPresent) {
          switch (rule) {
            case "Arrival Guidelines":
              this.components.arrival.show = true;
              this.components.arrival.rule = rule;
              this.availableSections++;
              break;
            case "CP Arrival Guidelines - Group Flight":
              this.components.arrival.show = true;
              if (this.components.arrival.rule !== "Arrival Guidelines") {
                this.components.arrival.rule = rule;
                this.availableSections++;
              }
              break;
            case "Arrival FAQ":
              this.components.faqs.show = true;
              this.components.faqs.rule = rule;
              this.availableSections++;
              break;
            case "CP Arrival FAQ":
              this.components.faqs.show = true;
              if (this.components.faqs.rule !== "Arrival FAQ") {
                this.components.faqs.rule = rule;
                this.availableSections++;
              }
              break;
            case "Departure Guidelines":
              this.components.departure.show = true;
              this.components.departure.rule = rule;
              this.availableSections++;
              break;
            case "CP Departure Guidelines":
              this.components.departure.show = true;
              if (this.components.departure.rule !== "Departure Guidelines") {
                this.components.departure.rule = rule;
                this.availableSections++;
              }
              break;
            case "Student Travel Arrangements":
              this.components.plans.show = true;
              this.availableSections++;
              break;
          }
        }
      }

      if (!this.components.plans.show) {
        this.components.plans.checkboxForm = true;
        this.availableSections++;
      }
    },
    async getGuidelines() {
      try {
        this.message = "Loading data please wait...";
        return fetch(
          this.trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) +
            "session/" +
            this.plansData.programId
        );
      } catch (e) {
        this.message =
          "There was an error while trying to get the program data, please try again.";
        console.log(e);
      }
    },
    formattedDate(dateParam) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let dateDetails = dateParam.split("-");
      if (dateDetails.length === 3) {
        let day = dateDetails[2];
        let month = dateDetails[1] - 1;
        let year = dateDetails[0];
        let fullDate = new Date(year, month, day);
        let monthName = monthNames[fullDate.getMonth()];
        return (
          monthName.slice(0, 3) +
          " " +
          fullDate.getDate() +
          ", " +
          fullDate.getFullYear()
        );
      }
      return "";
    },
    formattedBy(time) {
      if (time && time.split(":").length === 3) {
        let timeDetails = time.split(":");
        let hours = parseInt(timeDetails[0]) % 12;
        hours = hours === 0 ? 12 : hours;
        let amOrpm =
          parseInt(timeDetails[0]) >= 0 && parseInt(timeDetails[0]) <= 11
            ? "AM"
            : "PM";
        return hours + ":" + timeDetails[1] + " " + amOrpm;
      }
      return "";
    },
  },
};
</script>
<style scoped>
.animate-spinner {
  -webkit-animation: spinner 1.1s linear infinite;
  animation: spinner 1.1s linear infinite;
}

@-webkit-keyframes spinner {
  10% {
    opacity: 100%;
  }
  100% {
    opacity: 10%;
  }
}

@keyframes spinner {
  10% {
    opacity: 100%;
  }
  100% {
    opacity: 10%;
  }
}
</style>

