import { cloneDeep } from "lodash";
import { centsToDollars } from "@/mixins/helpers";
import { buildBaseHousingUnitRoomPrice } from "../data/baseHousingUnitDetail";

const unitTransformation = {
  transformHousingUnits(unitsData, amenitiesData) {
    let units = [];
    for (let unit of unitsData) {
      if (unit.unit_amenities) {
        // We only want the ids ...
        unit.unit_amenities = unit.unit_amenities.map((amenity) =>
          typeof amenity === "object" ? amenity.id : amenity
        );
      } else {
        unit.unit_amenities = [];
      }
      unit.rooms.forEach((room) => {
        let amenities = cloneDeep(amenitiesData);
        room.is_not_valid = false;
        room.cost_in_cents = centsToDollars(room.cost_in_cents, true);
        room.product_prices = room?.product_prices?.map((price) => ({
          id: price?.id,
          price_in_cents: centsToDollars(price.price_in_cents, true),
          effective_start_date: price.effective_start_date,
          effective_end_date: price.effective_end_date,
        }));
        if (!room.product_prices?.length) {
          room.product_prices = [buildBaseHousingUnitRoomPrice()];
        }
        room.room_amenities = amenities.map(function (amenity) {
          amenity.checked = false;
          amenity.id = null;
          for (let room_amenity of room.room_amenities || []) {
            if (
              amenity.amenity_id === room_amenity.amenity_id &&
              room_amenity.quantity > 0
            ) {
              amenity.id = room.amenity_id;
              amenity.room_id = room.room_id;
              amenity.checked = true;
              amenity.quantity = room_amenity.quantity;
              break;
            }
          }
          return amenity;
        });
      });
      unit.is_not_valid = !!unit.is_not_valid;
      units.push(unit);
    }
    return units;
  },
};

export default unitTransformation;
