<script setup>
import { toRefs, defineProps, defineEmits } from "vue";

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
    default: () => {},
  },
  totalPages: {
    type: Number,
    required: true,
    default: () => {},
  },
});
const { currentPage, totalPages } = toRefs(props);
const emit = defineEmits(["page-change"]);

function gotoPage(page) {
  if (page >= 1 && page <= totalPages.value) {
    emitPageChange(page);
  }
}

const emitPageChange = (page) => {
  emit("page-change", page);
};
</script>

<template>
  <div v-show="totalPages > 1" class="pagination">
    <button
      class="text-md font-semibold text-blue-900"
      :disabled="currentPage === 1"
      @click="gotoPage(currentPage - 1)"
    >
      Previous
    </button>
    <button
      class="text-md font-semibold text-blue-900"
      :disabled="currentPage === totalPages"
      @click="gotoPage(currentPage + 1)"
    >
      Next
    </button>
  </div>
</template>

<style>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}
</style>
