<script setup>
import ProgramDates from "@/components/ProgramDates.vue";
import { PROGRAM_SESSION_TERMS_DISPLAY_MAP } from "@/components/program-manager/sessions/constants";
import InternshipIconsSection from "@/components/shared/Card/InternshipIconsSection.vue";
import {
  buildStringDateRange,
  buildStringDateRangeSessionTravel,
} from "@/mixins/helpers";
import { computed, inject } from "vue";
import { useRoute } from "vue-router";
import { proficiencyLevelsOptions } from "@/components/program-manager/sessions/constants.js";

const route = useRoute();

const props = defineProps({
  programPage: {
    type: Object,
    default() {
      return {};
    },
  },
  internship: {
    type: Object,
    default() {
      return {};
    },
  },
  session: {
    type: Object,
    default() {
      return {};
    },
  },
  uppercaseName: {
    type: Boolean,
    default: false,
  },
  perks: {
    type: Array,
    default: () => [],
  },
  placementTypes: {
    type: Array,
    default: () => [],
  },
  companySize: {
    type: String,
    default: "",
  },
});

const isAttachedToConfigurator = computed(() => {
  return ["configurator", "configurator-new"].includes(route.name);
});

const internshipLocation = computed(() => {
  const cityData = props?.session?.session_travel_details?.[0]?.arrival_city;
  if (!cityData) {
    return "";
  }
  if (isOnlyVirtual.value) {
    return "Virtual";
  }
  const city = cityData.city;
  const country = cityData.country?.name;
  return city && country ? `${city}, ${country}` : "";
});

const getSessionTerms = computed(() => {
  const terms = props.session?.terms ?? props.internship?.terms;
  return terms?.map((term) => term.name) ?? [];
});

const getDisplayNameForTerms = computed(() => {
  const normalizedTerms = [...getSessionTerms.value].sort();
  const termKey = normalizedTerms.join(" + ");

  return (
    PROGRAM_SESSION_TERMS_DISPLAY_MAP[termKey] || normalizedTerms.join(", ")
  );
});

const customProgramNameCss = computed(() => {
  let classes =
    "font-montserrat font-bold text-indigo-base text-xl leading-[1.375rem]";
  classes += !isAttachedToConfigurator.value ? " my-4" : "";
  classes += props.uppercaseName ? " uppercase" : "";
  return classes;
});

const programLanguagesWithProficiency = computed(() => {
  return (
    props.internship.required_languages
      ?.filter((language) => language.value && language.proficiency_level)
      .map((language) => {
        let proficiency = proficiencyLevelsOptions.find(
          (level) => level.id === language.proficiency_level
        )?.value;
        if (!proficiency) {
          proficiency =
            proficiencyLevelsOptions.find(
              (level) => level.value === language.proficiency_level
            )?.value || "Unknown";
        }
        return { ...language, proficiency: proficiency || "Unknown" };
      }) || []
  );
});

const getProgramDates = computed(() => {
  return isAttachedToConfigurator.value
    ? buildStringDateRange(
        props.session?.academic_start_date,
        props.session?.academic_end_date
      )
    : buildStringDateRangeSessionTravel(
        props.session?.session_travel_details
      );
});

const isStartDateFinal = computed(() => {
  return isAttachedToConfigurator.value
    ? props.session?.academic_start_date_finalized
    : props.session?.session_travel_details[0]?.arrival_date_is_final >
        0;
});

const isEndDateFinal = computed(() => {
  return isAttachedToConfigurator.value
    ? props.session?.academic_end_date_finalized
    : props.session?.session_travel_details[0]
        ?.departure_date_is_final > 0;
});

const isOnlyVirtual = computed(() => {
  return props.internship?.work_locations?.every(
    (location) => location.value === "Virtual"
  );
});

const onTooltipClick = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const randomString = (length = 5) => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};
</script>

<template>
  <div
    id="main-container"
    :class="[
      'flex flex-col gap-2',
    ]"
  >
    <p
      v-if="props.internship?.title"
      id="program-name-section"
      :class="customProgramNameCss"
    >
      <span id="program-name">{{ props.internship.title }}</span>
    </p>
    <div v-if="getSessionTerms" class="flex flex-col">
      <p
        :id="`card-session-term-${props.session?.id}`"
        class="font-montserrat font-medium text-sm text-indigo-base text-opacity-70"
      >
        <ProgramDates
          :id="`program-dates-${props.session?.id + randomString(5)}`"
          :dates="getProgramDates"
          :start-is-final="isStartDateFinal"
          :end-is-final="isEndDateFinal"
          tag="p"
          class="text-gray-600"
          reverse-icon-order
          experience-card-icon
          text-class="font-montserrat uppercase text-indigo-base text-xs sm:text-sm"
          @click.stop="onTooltipClick"
        />
        {{ getDisplayNameForTerms }}
      </p>
    </div>
    <p
      v-if="internshipLocation"
      id="internship-location-section"
      class="font-montserrat font-medium text-sm text-indigo-base"
    >
      <span
        id="internship-location"
        class="leading-[1.375rem] text-indigo-base font-medium"
      >
        {{ internshipLocation }}
      </span>
    </p>
    <InternshipIconsSection
      :program-languages-with-proficiency="programLanguagesWithProficiency"
      :company-size="companySize"
      :perks="perks"
      :program="props.internship"
      :placement-types="placementTypes"
    />
  </div>
</template>
