import pLimit from "p-limit";
import classesService from "@/services/classes.js";
import programSessionsService from "@/services/programSessions.js";

const limit = pLimit(2);

export const getRulesNameWithIds = (rules) => {
  const names = {};

  rules.forEach((rule) => {
    const {
      conjunction,
      name,
      library_class_rules,
      any_class_from_list,
      any_class_from_library,
    } = rule;

    if (!names[name]) {
      names[name] = {
        conjunction,
        ids: [],
        any_class_from_list,
        any_class_from_library,
      };
    }

    const ids = library_class_rules.map(
      (classRule) => classRule.library_class?.id
    );

    names[name].ids.push(...ids);
  });

  return Object.entries(names).map(
    ([
      name,
      { conjunction, ids, any_class_from_list, any_class_from_library },
    ]) => ({
      name,
      conjunction,
      ids,
      any_class_from_list,
      any_class_from_library,
    })
  );
};

export const getCourseTrackData = async (conj) => {
  const classRequests = conj.map((id) =>
    limit(() =>
      classesService.getClassById(id).catch(() => {
        return null;
      })
    )
  );
  let classResponse = await Promise.all(classRequests);
  let classes = classResponse
    .map((response) => response?.data?.data)
    .filter((item) => item)
    .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

  return conj.map((id) => classes[id]);
};

export const constructFilterCriteria = (newSession, notConjunction) => {
  return {
    host_institution: newSession.class_filter_criteria.host_institutions?.map(
      (hostInstitution) => hostInstitution.id
    ),
    terms: newSession.class_filter_criteria.terms?.map((term) => term.id),
    cip_code: newSession.class_filter_criteria.cip_codes?.map(
      (cip) => cip.cip_code
    ),
    languages_of_instruction: newSession.class_filter_criteria.required_languages?.map(
      (lang) => lang.id
    ),
    language_levels: newSession.class_filter_criteria.proficiency_levels?.map(
      (level) => level.id
    ),
    education_levels: newSession.class_filter_criteria.education_levels?.map(
      (level) => level.id
    ),
    class_format: newSession.class_filter_criteria.class_format,
    excluded_ids: notConjunction.map((id) => id),
    status: "Active",
  };
};

export const updateConjunctionRules = (
  sessionClassSelectionRules,
  getConjunctionIds
) => {
  let track = [];
  const conjunctionRules = getRulesNameWithIds(sessionClassSelectionRules);
  conjunctionRules
    .map((rule) => rule)
    .forEach((rule) => {
      const name = rule.name;
      track.push({
        name,
        conjunction: rule.conjunction,
        ids: conjunctionRules
          ?.filter((rule) => rule.name === name)
          .flatMap((rule) => rule.ids),
      });
    });

  const notConjunction = getConjunctionIds("not", conjunctionRules);
  const andOrConjunctionRules = conjunctionRules?.filter(
    (rule) => rule.conjunction !== "not"
  );
  const andConjunction = getConjunctionIds("and", conjunctionRules);
  const orConjunction = getConjunctionIds("or", conjunctionRules);
  const anyClassFromLibrary = conjunctionRules?.some(
    (rule) => rule.any_class_from_library === true
  );
  const anyClassFromList = conjunctionRules?.some(
    (rule) => rule.any_class_from_list === true
  );

  return {
    conjunctionRules,
    notConjunction,
    andOrConjunctionRules,
    andConjunction,
    orConjunction,
    anyClassFromLibrary,
    anyClassFromList,
    track,
  };
};

export const isEncoded = (value) => {
  return decodeURIComponent(value) !== value;
};

export const applicationAlreadyExists = async ({
  programSession,
  studentApplications,
  skipRequest,
}) => {
  if (studentApplications.length === 0) return false;
  let session = {};
  if (!skipRequest) {
    let sessionId = programSession?.session?.id ?? programSession?.id;
    const response = await programSessionsService.getProgramSession({
      id: sessionId,
    });
    session = response?.data?.data?.items;
  } else session = programSession;

  if (
    studentApplications.some(
      (app) =>
        app.programSession.id === session.id && app.uiVersion !== "v2"
    )
  ) {
    return true;
  }
  return false;
};
