<template>
  <div v-if="errorList.length > 0">
    <div
      class="fixed inset-0 z-10 hidden bg-university-primary opacity-50 sm:block"
    />
    <div
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
    >
      <div
        id="flex-date-modal"
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-32 sm:py-20 sm:rounded-lg sm:shadow"
        role="dialog"
        aria-modal="true"
        aria-labelledby="flex-date-modal-label"
      >
        <div class="flex flex-col items-center">
          <div class="flex justify-end w-full">
            <button
              class="block h-6 w-6 flex items-center justify-center"
              @click="clearErrors()"
            >
              <span class="sr-only">Close</span>
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>

          <h2
            id="flex-date-modal-label"
            class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            Uh-oh! Something went wrong.
          </h2>
          <img
            src="/images/icon-warning-60.svg"
            alt="Warning icon"
            class="mx-auto my-10"
          />
        </div>

        <div class="text-gray-600 text-center">
          Please contact
          <a class="api-link" :href="`mailto:productsupport@apiexperience.com`">
            productsupport@apiexperience.com
          </a>
          and reference error {{ errorList.length > 1 ? "codes" : "code" }}
          <span class="error-code"> {{ errorCodes }} </span>
          if this issue continues.
        </div>

        <div class="mt-12 flex justify-center">
          <button
            class="block md:inline-block w-full md:w-max text-center rounded font-semibold border-2 border-transparent px-8 text-teal-900 border-teal-900 hover:text-white focus:text-white hover:bg-teal-100 focus:bg-teal-100 hover:border-teal-100 min-h-10"
            @click="clearErrors()"
          >
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";

export default (() => {
  let errorList = reactive([]);

  const errorCodes = computed(() => {
    if (errorList.length === 1) {
      return errorList[0];
    } else if (errorList.length === 2) {
      return errorList.join(" and ");
    } else if (errorList.length > 2) {
      return errorList.slice(0, -1).join(", ") + ", and " + errorList.slice(-1);
    } else {
      return "";
    }
  });

  const registerError = (error) => {
    errorList.push(error);
  };

  const clearErrors = () => {
    errorList.splice(0);
  };

  return {
    setup: () => {
      return { registerError, clearErrors, errorList, errorCodes };
    },
    registerError,
  };
})();
</script>

<style scoped>
.error-code {
  color: #e57f54;
}
</style>
