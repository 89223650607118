import { DISCIPLINARY_RECORDS_UNIVERSITIES } from "@/constants";
import { buildStringDateRange } from "@/mixins/helpers";
import { mapState } from "vuex";
import formService from "@/services/form";

export default {
  computed: {
    ...mapState(["currentUser"]),
    approvedStatuses() {
      return ["Complete", "Submitted"];
    },
  },
  methods: {
    formioSubmissionExists(formPath, applicationId) {
      return formService
        .submissionExists(formPath, { "data.application_id": applicationId })
        .then((queryResult) => {
          this.submission_id = queryResult._id;
          return this.submission_id;
        })
        .catch(() => false);
    },
    async submitApproval(student) {
      let dateRange = "";

      if (student.start_date && student.end_date) {
        dateRange = student.formatDate
          ? ` (${student.start_date} - ${student.end_date})`
          : ` (${buildStringDateRange(student.start_date, student.end_date)})`;
      }

      let body = {
        data: {
          application_id: student.approval_application,
          program: {
            value: student.session_id,
            label: student.program_name,
          },
          institution: {
            value: student.university_ope8_id,
            label: student.university_name,
          },
          advisorname: `${this.currentUser?.firstName} ${this.currentUser?.lastName}`,
          campusOfficialEmail: "",
          comments: "",
          submit: true,
          studentname: student.name,
          programname: student.program_name + dateRange,
          institutionname: student.university_name,
          advisoremail: this.currentUser?.email,
          programType:
            student.program_type === "Internship" ? "internship" : "abroad",
        },
      };

      let disciplinaryEmail = this.getDisciplinaryEmail(
        student.university_name
      );
      if (disciplinaryEmail) {
        body.data.certifytype = "approvedNoDisciplinaryRecord";
        body.data.campusOfficialEmail = disciplinaryEmail;
      } else {
        body.data.certifytype = "approved";
      }
      //check if submission exists to avoid duplication
      let submissionId = await this.formioSubmissionExists(
        "advisorapproval",
        student.approval_application
      );
      return submissionId
        ? true
        : formService.createSubmission("advisorapproval", body);
    },
    async setApprovedFlag(applicationId) {
      let url =
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "progress/approval/" +
        applicationId;
      let response = await fetch(url, {
        headers: {
          "Content-type": "application/json",
          "x-jwt-token": this.$store.state.currentUser.advisorToken,
        },
        method: "post",
      });

      return {
        success: Boolean(response.status == 200),
        applicationId: applicationId,
      };
    },
    getDisciplinaryEmail(school) {
      const schoolObj = DISCIPLINARY_RECORDS_UNIVERSITIES.find(
        (item) => item.name === school
      );
      return schoolObj !== undefined ? schoolObj.email : "";
    },
  },
};
