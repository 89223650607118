<template>
  <div
    v-if="dynamicUniversity"
    class="md:flex items-stretch hidden z-10 order-2"
  >
    <div class="relative flex items-center ml-6">
      <button
        class="flex items-center text-base text-gray-600 hover:text-gray-700 hover:no-underline focus:outline-none"
        aria-controls="universities-menu"
        aria-label="Toggle universities Menu"
        :aria-expanded="showDropdown"
        data-toggle-indicator="flip"
        @click="toggleDropdown"
      >
        <span class="mr-2">
          {{ universityName }}
          <i
            class="mdi"
            :class="showDropdown ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          ></i>
        </span>
      </button>

      <div v-if="showDropdown" class="absolute bg-white university-desktop-div">
        <universityChange />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import universityChange from "@/components/Header/universityChange.vue";

export default {
  name: "UniversityChangeDesktop",
  components: { universityChange },
  data() {
    return {
      showDropdown: null,
      universityName: "",
    };
  },
  computed: {
    ...mapState("university", ["userDetails"]),
    ...mapGetters("university", ["dynamicUniversity"]),
  },
  watch: {
    userDetails: {
      handler: function (newval) {
        this.universityName =
          newval && newval.university ? newval.university : "";
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<style>
.university-desktop-div {
  top: 200%;
  width: 600px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 1px 10px #c6cae4;
  border-radius: 4px;
}
</style>
