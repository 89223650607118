import { useAsyncState } from "@vueuse/core";
import programsPageService from "@/services/programsPage";

export const useGetProgramPageOptions = (asyncStateOptions, startParams) =>
  useAsyncState(
    ({ program_page_id, home_institution_id = "", session_status = null, hide_sessions = false}) =>
      programsPageService
        .getProgramPage(
          program_page_id,
          {
            ...startParams?.params,
            home_institution_id: home_institution_id,
            session_status: session_status,
            hide_sessions: hide_sessions
          }
        )
        .then((val) => val?.data?.data?.items || []),
    [],
    asyncStateOptions
  );

export const useGetProgramPageOptionsLite = (asyncStateOptions, startParams) =>
  useAsyncState(
    () =>
      programsPageService
        .getProgramPage(startParams.program_page_id, startParams.params)
        .then((val) => val?.data?.data?.items || []),
    [],
    asyncStateOptions
  );
