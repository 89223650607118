<template>
  <div>
    <div
      :class="`${primaryColorClassBG}`"
      class="fixed inset-0 z-0 hidden opacity-50 sm:block"
    />
    <div
      class="fixed inset-0 z-50 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
    >
      <div
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-32 sm:py-20 sm:rounded-lg sm:shadow"
        role="dialog"
        aria-modal="true"
        aria-labelledby="cancellation-policy-modal-label"
      >
        <div class="flex flex-col items-center mb-10">
          <div class="flex justify-end w-full sm:hidden">
            <button
              class="block h-6 w-6 flex items-center justify-center"
              @click="$emit('close')"
            >
              <span class="sr-only">Close</span>
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
          <h2
            id="info-modal-modal-label"
            class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            API ONLINE APPLICATION TERMS OF USE
          </h2>
        </div>

        <div class="text-gray-600 space-y-6">
          <p>
            This API Online Application Terms of Use (the “Terms”) is entered
            into by and between Academic Programs International, LLC (“API”) and
            you, and is made effective as of the date of electronic execution.
            These Terms set forth the terms and conditions governing the
            application process into any program offered by API. By submitting
            an application to participate in a program, you acknowledge that you
            have read, understand, and agree to be bound by the Terms. All API
            policies and agreements related to your application are incorporated
            herein and made a part of these Terms by reference.
          </p>

          <p><strong>ONLINE APPLICATION REQUIREMENTS </strong></p>
          <p>
            <span class="underline">Required Components</span>. Required
            documents may include a letter of recommendation, official
            transcript from your college or university, etc. We cannot fully
            process your application or offer you acceptance without all
            required application Components.
          </p>

          <p><strong>API ADMISSION POLICY </strong></p>
          <p>
            Applications are reviewed on a rolling basis. In some cases, this
            may mean that certain courses or housing options may fill prior to
            the official application deadline. Late applications are considered
            on a space-available basis and incur an additional $180 late
            application fee, which must be paid in full before a late
            application may be submitted.
          </p>
          <p>
            The minimum G.P.A. for API programs is generally 2.5, unless
            otherwise noted on the program page. Students who apply with a lower
            G.P.A. are considered on a case-by-case basis. Preference is given
            to those students who have an upward trend in their G.P.A.,
            demonstrate considerable dedication to studying the language of the
            host country, or whose G.P.A. was negatively affected by one or two
            particularly difficult courses. All applicants with low G.P.A.s are
            required to submit two letters of recommendation and a statement of
            explanation.
          </p>

          <p><strong>API POLICIES </strong></p>
          <div>
            <p>
              You agree that you have read and agree to be bound by the
              following API Policies as well as any other applicable policies
              that we may establish from time to time.
            </p>
            <ul>
              <li
                :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
              >
                Withdrawal Policy
              </li>
              <li
                :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
              >
                Privacy Policy
              </li>
              <li
                :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
              >
                Non-Discrimination Policy
              </li>
            </ul>
          </div>

          <p><strong>PARTICIPANT DATA </strong></p>
          <div>
            <p>
              In order to provide you with the services related to each API
              program, API will process your data according to the Privacy
              Policy, which among other things provides that all data shall be
              processed exclusively for API institutional purposes in connection
              with your abroad session. In particular, the data supplied or
              collected will be processed for the following purposes:
            </p>
            <ul class="pl-6">
              <li>
                a) to facilitate your enrollment at the host university or
                internship/volunteer placement;
              </li>
              <li>
                b) to allow third parties, such as hospitals, to take the
                necessary actions following an accident or other aggression.
              </li>
            </ul>
          </div>
          <p>
            Sensitive data regarding your health conditions and food habits will
            only be processed for the purpose of identifying and securing
            appropriate housing, arranging group meals, protecting your safety,
            or fulfilling the obligations established by law, by regulations or
            by the European Union Law. Sensitive data regarding
            judicial/disciplinary measures which may have been provided to API
            by you or public bodies will be used only for purposes relating to
            your enrollment at a host institution abroad or internship/volunteer
            placement, a health or safety emergency, or to comply with any
            applicable mandatory provision of local or European Union law.
          </p>
          <p>
            In particular, your data may be communicated, in compliance with the
            rules above indicated, to public or private subjects in order to
            fulfill obligations set forth by local laws, regulations or EU laws;
            sensitive data may be communicated to the host university, API
            directors overseas, public bodies and authorities (such as public
            hospitals, public safety authorities, police offices, courts,
            magistrates and the like) and to private subjects (such as private
            hospitals and clinics, security supervisors, insurance companies,
            internship partners, etc.) only for purposes relating to
            administrative enrollment processes, health and safety emergencies
            and/or for the purposes of fulfilling obligations set forth by local
            laws, regulations and EU laws. Sensitive data for the purposes of
            these terms and conditions may include personal data revealing
            racial or ethnic origin, political opinions, religious or
            philosophical beliefs, trade union membership, genetic data,
            biometric data, data concerning health, gender identity or sexual
            orientation.
          </p>

          <p><strong>POST ACCEPTANCE </strong></p>
          <p>
            Upon being accepted to an API program, you will receive access to
            post-acceptance forms that must be completed by the payment deadline
            dates (available on the API website). Late forms are subject to a
            $30 late charge per form.
          </p>

          <p><strong>AUTHORIZATION </strong></p>
          <p>By submitting your application, you agree that:</p>
          <p>
            You certify that all information you have provided in this
            application is accurate, and you will promptly notify API should any
            information become inaccurate;
          </p>
          <p>
            Your participation in a program will be governed by a Participant
            Agreement to be shared upon acceptance, as well as other terms,
            conditions, and policies relevant to the chosen program;
          </p>
          <p>
            You authorize API to send pre-departure and program materials to
            your parent/guardian or other person at your permanent address and
            to contact the person you note on this application in case of an
            emergency or other circumstances that could affect your ability to
            participate in or complete an API program;
          </p>
          <p>
            You agree to the release of your name and contact information to
            your fellow program participants, which is typically done prior to
            departure when API sends a list of program participants to all
            students in the API program;
          </p>
          <p>
            You understand that refusal or revocation of consent to API for the
            use of your personal data (including sensitive data) for the
            purposes outlined in these terms and conditions may materially
            impact or render the services related to the API programs
            undeliverable. As such, you hereby consent to the use of your
            personal data (including sensitive data) for the purposes outlined
            in these terms and conditions, such use to include the transfer
            overseas of such personal data (including sensitive data) pursuant
            to article 49(1)(a) of the EU GDPR. Specifically, the transfer to
            the United States of America, even if this country were not
            considered a privacy safe harbor by the EU competent authorities;
          </p>
          <p>
            You understand and agree that sensitive health information, if
            provided to API, may be shared with your host institution, host
            housing facility, or other parties with a need to know in order to
            provide you with an environment suited to any conditions you may
            have (i.e. food allergies). You further acknowledge and agree that
            API does not warrant or guarantee that the host institution or host
            housing facility is willing or able to make accommodations for all
            disclosed conditions;
          </p>
          <p>
            You understand that API will attempt, but cannot guarantee, to
            accommodate any specific housing requests or considerations
            identified on the Housing Form you complete with your application.
          </p>
          <p>
            You have been in contact with your home institution's study abroad
            office (or the appropriate office on campus, if there is no study
            abroad office), and you are aware of all relevant policies and
            procedures concerning credit transfer, financial aid, withdrawal
            and/or re-admission and required pre-departure or re-entry workshops
            where applicable. You authorize API to share a copy of your
            application with your home institution for institutional review
            procedures if requested;
          </p>
          <p>
            For Study Abroad Programs only, API does not control the courses
            that are offered in any given session at the host institution, nor
            can API guarantee that you will be enrolled in particular courses
            needed to fulfill degree requirements. Some classes listed on the
            API website may change or be cancelled due to low enrollment or
            other factors.
          </p>
          <p>
            Many host countries require foreign travelers to obtain a visa to
            legally enter and reside in the country. API will provide visa
            information during the post-application process, but you are solely
            responsible for obtaining the visa if required by the host country.
          </p>
          <p>
            You acknowledge that your transcript will not be released to you or
            to the designated contact at your home institution upon completion
            of the program abroad until all API fees have been paid in full; You
            authorize API to use photographs and videos in which you appear
            during its orientation sessions and/or its marketing materials.
            Should you wish to revoke the authorization to use your image, you
            will email such revocation to optout@apiexperience.com; and
          </p>
          <p>
            You authorize API to publicly share any comments or testimonials
            that you provide to API, including but not limited to feedback
            contained within emails or videos sent to API representatives and
            program evaluations, in its marketing materials and promotional
            efforts, referencing only your first name and the site where you
            traveled abroad. Should you wish to revoke such authorization, you
            will email a revocation of authorization to
            <a
              :href="`mailto:optout${APICompany.companyEmail}`"
              :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
              >optout@apiexperience.com</a
            >.
          </p>
        </div>

        <div class="mt-12">
          <button
            :class="[
              tertiaryColor
                ? `${tertiaryColorClass}`
                : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
            ]"
            class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-gray-700"
            @click="$emit('close')"
          >
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APICompany } from "../../constants";
export default {
  name: "TermsOfUse",
  emits: ["close"],
  setup() {
    return {
      APICompany,
    };
  },
};
</script>
