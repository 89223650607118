<script>
export default {
  name: "AlgoliaSideBar",
};
</script>

<script setup>
import { isEmpty } from "lodash";

import BreadCrumb from "@/components/shared/BreadCrumb.vue";
import BaseToggle from "@/components/forms/SharedComponents/BaseToggle.vue";
import PanelContainer from "@/components/ExperiencePage/Algolia/AlgoliaPanelContainer.vue";

import AlgoliaCheckBoxFilter from "@/components/ExperiencePage/Algolia/widgets/AlgoliaCheckboxFilter.vue";
import AlgoliaKeywordInput from "@/components/ExperiencePage/Algolia/widgets/AlgoliaKeywordInput.vue";
import AlgoliaMultiSelect from "@/components/ExperiencePage/Algolia/widgets/AlgoliaMultiSelect.vue";
import AlgoliaDateSearch from "@/components/ExperiencePage/Algolia/widgets/AlgoliaDateSearch";
import AlgoliaMinMaxSlider from "@/components/ExperiencePage/Algolia/widgets/AlgoliaMinMaxSlider.vue";
import AlgoliaTreeSelect2 from "@/components/ExperiencePage/Algolia/widgets/AlgoliaTreeSelect2";

import { computed } from "vue";
import { countriesTranslationMap } from "../constants";

import {
  PRICE_MAX,
  PRICE_MIN,
  DEFAULT_PRICE_RANGE,
} from "@/components/ExperiencePage/Algolia/AlgoliaSideBar/constants";
import { useVModel } from "@vueuse/core/index";
import { useStore } from "vuex";

const props = defineProps({
  modelValue: {
    type: Object,
  },
  algoliaConfiguration: {
    type: Object,
    required: true,
  },
  currentSearchIndex: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "close-filters"]);
const filters = useVModel(props, "modelValue", emit);

const store = useStore();

const homeInstitutionId = computed(
    () => store.state.profileData?.colleges?.[0]?.college_id ?? ""
);

const updateHandler = (field, data) => {
  if (field) {
    const newFilters = { ...filters.value };
    if (isEmpty(data)) {
      newFilters[field] = [];
    } else {
      if (Array.isArray(data)) {
        newFilters[field] = [...data];
      } else {
        newFilters[field] = data;
      }
    }
    filters.value = newFilters;
  }
};

const disableFacet = (disjunctiveFacets, facetName, status) => {
  let retVal = false;
  if(status === "idle") {
    const foundFacet = disjunctiveFacets?.find(val => val.name === facetName);
    retVal = !(foundFacet?.data && Object.keys(foundFacet?.data)?.length > 0);
  }
  return retVal;
};

</script>
<template>
  <div>
    <ais-state-results v-slot="{ results, status }">
      <div class="font-montserrat non-italic text-indigo-base p-4 md:p-0">
      <BreadCrumb class="hidden md:block" custom-spacer experience-page
        >/</BreadCrumb
      >
      <!--Keyword search-->
      <AlgoliaKeywordInput
        id="keyword-search"
        label="Keyword"
        type="text"
        :facets="algoliaConfiguration.keyword"
        :keywordValue="filters.keyword"
        @update:keyword-value="updateHandler('keyword', $event)"
        class="h-50p exp-finder-input block w-full max-w-351p text-indigo-base mt-2 font-bold text-base border border-indigo-base outline-none focus:ring-blue-500 focus:border-blue-500 uppercase bg-white"
        input-class="mr-1 w-full md:max-w-260p max-h-12 h-12 bg-white pl-5 pb-6 pt-6 font-bold uppercase text-indigo-base focus:text-indigo-base placeholder-indigo-base outline-none focus:ring-0 placeholder-opacity-50 outline-none focus:ring-0"
        hide-label
        addBottomMargin
      />
      <hr
        v-if="algoliaConfiguration.courseLevel"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <PanelContainer
        v-if="algoliaConfiguration.courseLevel"
        id="course-level-filter"
        title="Learner Type"
        heading-class="w-full"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.courseLevel, status)"
      >
        <AlgoliaCheckBoxFilter
          :selected-options="filters.courseLevel"
          :algolia-configuration="algoliaConfiguration"
          filterName="courseLevel"
          order-by="count"
          :update-filter="updateHandler"
        />
      </PanelContainer>
      <hr
        v-if="algoliaConfiguration.programTypes"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
        <PanelContainer
          v-if="algoliaConfiguration.programTypes"
          id="program-types-filter"
          title="Program Type"
          heading-class="w-full"
          :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.programTypes, status)"
        >
          <AlgoliaCheckBoxFilter
            :selected-options="filters.programTypes"
            filterName="programTypes"
            :algolia-configuration="algoliaConfiguration"
            order-by="count"
            :update-filter="updateHandler"
          />
        </PanelContainer>
      <hr
        v-if="algoliaConfiguration.countries"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <!--Program Country Filter-->
      <PanelContainer
        v-if="algoliaConfiguration.countries"
        id="country-filter"
        title="Country"
        heading-class="w-full"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.countries, status)"
      >
        <AlgoliaMultiSelect
          v-model="filters"
          filterName="countries"
          :attribute="algoliaConfiguration.countries"
          :current-search-index="currentSearchIndex"
          :algolia-configuration="algoliaConfiguration"
          :translation-map="countriesTranslationMap"
          class="exp-multi-select"
        />
      </PanelContainer>
      <hr
        v-if="algoliaConfiguration.cities"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <!--Program Cities Filter-->
      <PanelContainer
        v-if="algoliaConfiguration.cities"
        id="city-filter"
        title="City"
        heading-class="w-full"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.cities, status)"
      >
        <AlgoliaMultiSelect
          v-model="filters"
          filterName="cities"
          :attribute="algoliaConfiguration.cities"
          :current-search-index="currentSearchIndex"
          :algolia-configuration="algoliaConfiguration"
          class="exp-multi-select"
        />
      </PanelContainer>
      <hr
        v-if="algoliaConfiguration.timeframe"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <PanelContainer
        v-if="algoliaConfiguration.timeframe"
        id="program-types-filter"
        title="Terms"
        heading-class="w-full"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.timeframe, status)"
      >
        <!--Time Frame / Terms Checkboxes-->
        <AlgoliaCheckBoxFilter
            :selected-options="filters.timeframe"
            :algolia-configuration="algoliaConfiguration"
            title="Terms"
            filterName="timeframe"
            :update-filter="updateHandler"
        />
      </PanelContainer>

      <hr
        v-if="algoliaConfiguration.searchByDate"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <!--Dates Filter-->
      <AlgoliaDateSearch
        v-if="algoliaConfiguration.searchByDate"
        v-model:selectedStartDate="filters.startDate"
        v-model:selectedEndDate="filters.endDate"
        heading-class="w-full"
        :attribute-start="algoliaConfiguration.searchByDate[0]"
        :attribute-end="algoliaConfiguration.searchByDate[1]"
      />
      <hr
        v-if="algoliaConfiguration.areaOfStudy"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <!--Area of Study Filter-->
      <PanelContainer
        v-if="algoliaConfiguration.areaOfStudy"
        id="area-of-study-filter"
        title="Area Of Study"
        heading-class="w-full"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.areaOfStudy, status)"
      >
        <AlgoliaTreeSelect2
          v-model="filters"
          filterName="areaOfStudy"
          :attribute="algoliaConfiguration.areaOfStudy"
          :algolia-configuration="algoliaConfiguration"
        />
      </PanelContainer>
      <hr
        v-if="algoliaConfiguration.career"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <!-- Career Interest -->
      <PanelContainer
        v-if="algoliaConfiguration.career"
        id="career-interest-filter"
        title="Career Interest"
        heading-class="w-full"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.career, status)"
      >
        <AlgoliaMultiSelect
          v-model="filters"
          filterName="career"
          :attribute="algoliaConfiguration.career"
          :current-search-index="currentSearchIndex"
          :algolia-configuration="algoliaConfiguration"
          class="exp-multi-select"
        />
      </PanelContainer>
      <hr
        v-if="algoliaConfiguration.price && !homeInstitutionId"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <!-- Price Slider -->
      <PanelContainer
        v-if="algoliaConfiguration.price && !homeInstitutionId"
        id="price"
        title="Price"
        heading-class="w-full"
        :removeContentOnHidden="true"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.price, status)"
      >
        <AlgoliaMinMaxSlider
          :price-value="filters.price"
          :attribute="algoliaConfiguration.price"
          :min="PRICE_MIN"
          :max="PRICE_MAX"
          :default-price="DEFAULT_PRICE_RANGE"
          @update:price-value="updateHandler('price', $event)"
        />
      </PanelContainer>
      <hr
        v-if="algoliaConfiguration.hostInstitution"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <!-- HOST INSTITUTION -->
      <PanelContainer
        v-if="algoliaConfiguration.hostInstitution"
        id="host-institution"
        title="Host Institution"
        heading-class="w-full"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.hostInstitution, status)"
      >
        <p v-if="!disableFacet(results?.disjunctiveFacets, algoliaConfiguration.hostInstitution, status)" class="font-montserrat font-medium text-xs text-indigo-base">
          Search and select
        </p>
        <AlgoliaMultiSelect
          v-model="filters"
          filterName="hostInstitution"
          :attribute="algoliaConfiguration.hostInstitution"
          expected-value-data-type="number"
          :current-search-index="currentSearchIndex"
          :algolia-configuration="algoliaConfiguration"
          class="exp-multi-select"
        />
      </PanelContainer>
      <hr
        v-if="algoliaConfiguration.language"
        class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p"
      />
      <!--Languages Checkboxes-->
      <PanelContainer
        v-if="algoliaConfiguration.language"
        id="languages-filter"
        title="Languages"
        heading-class="w-full"
        :disable-panel="disableFacet(results?.disjunctiveFacets, algoliaConfiguration.language, status)"
      >
        <AlgoliaMultiSelect
          v-model="filters"
          filterName="language"
          :attribute="algoliaConfiguration.language"
          :current-search-index="currentSearchIndex"
          :algolia-configuration="algoliaConfiguration"
          class="exp-multi-select"
        />
      </PanelContainer>
      <hr class="border-indigo-base opacity-20 mt-6 pb-6 max-w-351p" />
      <fieldset v-if="algoliaConfiguration.pastPrograms">
        <legend class="sr-only">Show programs with past application deadlines</legend>
        <BaseToggle
          class="flex items-center"
          :model-value="filters.pastPrograms"
          @update:model-value="
            ($event) => (filters = { ...filters, pastPrograms: $event })
          "
          label="Show programs with past application deadlines"
          width="w-13"
        />
      </fieldset>
    </div>
    </ais-state-results>
  </div>
</template>

<style scoped></style>
