<script setup>
import { onBeforeMount, ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getEntityById } from "@/services/utils.js";
import { isTenantManager } from "@/composables/authorization";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";

import {
  debounceSearchWrapper,
  useGetEntities,
} from "@/components/program-manager/sessions/composable";


const store = useStore();
const route = useRoute();
const entities = ref([store.getters["programManager/getSelectedEntityView"]]);
const selectedEntity = ref(
  store.getters["programManager/getSelectedEntityView"]
);
const ownerEntityId = computed(() => {
  const entities = store.getters.getEntityIds;
  return entities?.map((id) => id)[0];
});

const ownerEntityList = computed(() => {
  const entities = isTenantManager() ? [] : store.getters.getEntityIds;
  return entities;
});

const {
  execute: executeGetEntities,
  state: entitiesOptions,
  isLoading: entitiesOptionsLoading,
} = useGetEntities(
  { immediate: true, throwError: false, resetOnExecute: true },
  {
    extraParams: {
      entity_ids: ownerEntityList.value,
    },
  }
);

const fetchEntitiesOptions = debounceSearchWrapper(executeGetEntities, 250, {
  extraParams: {
    entity_ids: ownerEntityList.value,
  },
});

onBeforeMount(async () => {
  if (!selectedEntity?.value?.id){
    const returnedEntity = await getEntityById(ownerEntityId.value);
    store.commit("programManager/setSelectedEntityView", returnedEntity);
    selectedEntity.value = store.getters["programManager/getSelectedEntityView"];
  };
});

watch(selectedEntity, async (newEntity) => {
  store.commit("programManager/setSelectedEntityView", newEntity);
  await store.dispatch("programManager/setAllCounts");

  const subcategory = route?.meta?.subcategory?.toLowerCase();
  const path = route?.path.toLowerCase();

  try {
    switch (true) {
      case path.includes("events"):
        await store.dispatch("programManager/fetchEventsData");
        break;
      case path.includes("programs"):
        await store.dispatch("programManager/fetchProgramsData");
        break;
      case path.includes("sessions"):
        await store.dispatch("programManager/fetchProgramSessionsData");
        break;
      default:
        console.warn("Unhandled subcategory:", subcategory);
    }
  } catch (error) {
    console.error(
      `Failed to fetch data for subcategory ${subcategory}:`,
      error
    );
  }
});
</script>
<template>
  <div v-if="ownerEntityList" class="entity-select">
    <SearchableSelect
      v-model="selectedEntity"
      :clear-search-on-blur="() => false"
      :deselect-from-dropdown="true"
      :loading="entitiesOptionsLoading"
      :options="entitiesOptions.items"
      placeholder="Select Owner"
      label="name"
      @search="fetchEntitiesOptions"
    >
    </SearchableSelect>
  </div>
</template>
<style>
.entity-select .v-select .vs__dropdown-toggle {
  border-style: none;
  width: 300px;
}
</style>
