<template>
  <a
    role="link"
    tabindex="0"
    aria-disabled="true"
    :aria-label="stepLabel"
    class="step-pending flex-none px-1 relative"
  >
    <div
      class="flex items-center justify-center h-8 w-8 bg-university-primary border-2 border-university-primary rounded-full opacity-50"
      :data-cy="buildStepNumberForCypress"
    >
      <div class="text-white text-sm">{{ stepNumber }}</div>
    </div>
    <span
      class="hidden md:block text-xs mt-2 leading-tight font-semibold whitespace-nowrap block w-auto md:w-30 lg:w-48 absolute top-full truncate text-center right-1/2 transform translate-x-1/2"
      >{{ name }}</span
    >
  </a>
</template>

<script>
export default {
  props: {
    name: String,
    stepNumber: String,
  },
  data() {
    return {};
  },
  computed: {
    stepLabel() {
      return "Go to step " + this.name;
    },
    buildStepNumberForCypress() {
      //the step number relative to the forms[] index
      let step = this.stepNumber - 1;
      return "step-" + step;
    },
  },
};
</script>
