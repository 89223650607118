<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps, computed } from "vue";
import { useVModel } from "@vueuse/core";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const events = useVModel(props, "modelValue");

const includes = computed(() => {
  return events.value?.includes
    ? events.value.includes
        .filter((item) => item.id !== "includeOther")
        .map((item) =>
          item.value !== 0 ? `${item.id} - ${item.value}` : item.name
        )
        .join(", ") || ""
    : "";
});
const includesOther = computed(() => {
  return events.value?.includes_other?.length > 0
    ? events.value.includes_other.map((item) => item).join(", ") || ""
    : "";
});

const parkingList = computed(() => {
  return events.value?.packing_list
    ? events.value.packing_list
        .filter((item) => item !== "packingOther")
        .map((item) => item)
        .join(", ") || ""
    : "";
});
const parkingListOther = computed(() => {
  return events.value?.packing_other?.length > 0
    ? events.value.packing_other.map((item) => item).join(", ") || ""
    : "";
});
const showComma = (list, other) => {
  return list && other;
};
</script>
<template>
  <div class="relative mt-4">
    <div class="text-base mb-4 font-medium text-gray-700">
      What is Included:
      <span v-if="includes" class="font-semibold">{{ includes }}</span
      ><span v-if="showComma(includes, includesOther)">, </span>
      <span v-if="includesOther" class="font-semibold">
        {{ includesOther }}</span
      >
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      What should the participants pack:
      <span v-if="parkingList" class="font-semibold">{{ parkingList }}</span>
      <span v-if="showComma(parkingList, parkingListOther)">, </span>
      <span v-if="parkingListOther" class="font-semibold">
        {{ parkingListOther }}</span
      >
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      Itinerary:
      <JSONtoHTML
        v-if="events.itinerary"
        class="font-semibold"
        :tiptap-json="events.itinerary"
      />
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      Activity level:
      <span v-if="events?.activity_level" class="font-semibold">{{
        events.activity_level
      }}</span>
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      What type of housing is available?:
      <span v-if="events?.accommodation" class="font-semibold">{{
        events.accommodation
      }}</span>
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      Housing Description:
      <JSONtoHTML
        v-if="events?.accommodation_description"
        class="font-semibold"
        :tiptap-json="events.accommodation_description"
      />
    </div>
  </div>
</template>
