<template>
  <div
    v-if="featureFlagOn"
    :class="[
      isActive
        ? 'bg-white text-[#1f264c] font-bold mb-4'
        : 'hover:bg-[#494e6d] text-white font-medium mb-4',
    ]"
  >
    <img
      v-show="isActiveIcon"
      :src="icons.active"
      class="w-5 h-5 object-contain mb-1"
    />
    <img
      v-show="!isActiveIcon"
      :src="icons.default"
      class="w-5 h-5 object-contain mb-1"
    />
    <img
      v-show="nestedItems.length > 0 && showChevron"
      :src="dropdownIcon"
      class="mb-1 absolute top-[20%] right-[4px]"
      @mouseover="toggleSubNavigationList"
    />
    <div
      v-if="isSubNavigationToggled"
      class="right-[-190px] top-7 bg-[#1f264c] rounded absolute text-white drop-shadow-sm-white z-10"
      @mouseleave="toggleSubNavigationList"
      @click.stop
    >
      <router-link
        v-for="nestedItem in nestedItems"
        :key="nestedItem.id"
        :to="{ name: nestedItem.path }"
        exact
      >
        <div
          class="py-[8px] hover:bg-[#494e6d] rounded text-left min-w-[200px] min-h-[40px] font-normal pl-3 text-sm"
        >
          {{ nestedItem.name }}
        </div>
      </router-link>
    </div>
    <div :class="[!isDesktop ? 'ml-4 text-lg' : 'px-1']">
      <p class="leading-tight">{{ name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    name: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
    nestedItems: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    showChevron: {
      type: Boolean,
      default: true,
    },
    isDesktop: {
      type: Boolean,
      default: true,
    },
    featureFlagOn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubNavigationToggled: false,
    };
  },
  computed: {
    isActiveIcon() {
      return this.isActive && this.isDesktop;
    },
    dropdownIcon() {
      return this.isActive
        ? "/images/icon-dropdown-active.svg"
        : "/images/icon-dropdown-default.svg";
    },
  },
  methods: {
    toggleSubNavigationList() {
      this.isSubNavigationToggled = !this.isSubNavigationToggled;
    },
  },
};
</script>
