<template>
  <div>
    <Teleport v-if="parentMounted" to="#portal-title">
      <h2
        class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
      >
        Start your experience of a lifetime by completing the details below.
      </h2>
    </Teleport>
    <div class="grid grid-cols-1 gap-4 sm:gap-6">
      <PersonalProfile
        ref="leadData"
        @input="
          (newLead) => {
            lead = newLead;
          }
        "
      >
        <template #other-content>
          <div class="mt-6 md:col-span-2">
            <ButtonWithSpinner
              ref="leadSubmit"
              data-cy="create-account-submit-button"
              type="submit"
              variant="primary"
              variant-type="block"
              @click="submitLead"
            >
              <span>
                Start Application
              </span>
            </ButtonWithSpinner>
            <div v-if="sendSubmissionError" class="error text-error-900 mt-2">
              {{ sendSubmissionError }}
            </div>
            <p v-if="hasErrors" class="text-error-900">
              Please complete the required fields correctly.
            </p>
            <DuplicateUserError v-if="salesforceError" />
          </div>
        </template>
      </PersonalProfile>

      <div class="mt-6 m-10 text-center text-gray-600">
        <p>
          Already have an account? Log in
          <router-link
            :to="{ path: '/sign-in' }"
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
          >
            here
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/app";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import formIoApi from "@/mixins/formIoApi.js";
import { mapState } from "vuex";
import axios from "axios";
import PersonalProfile from "@/components/forms/ProfileReview/PersonalProfile/PersonalProfile.vue";
import formValidation from "@/mixins/formValidation";
import { APICompany } from "../../constants";
import useVuelidate from "@vuelidate/core";
import { checkForExistingLeads } from "@/composables/duplicateUser.js";
import DuplicateUserError from "@/components/auth/AccountCreation/DuplicateUserError.vue";

const generator = require("generate-password");

export default {
  name: "LeadForm",
  components: {
    ButtonWithSpinner,
    DuplicateUserError,
    PersonalProfile,
  },
  mixins: [formIoApi, formValidation],
  props: {
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      salesforceError: false,
      sendSubmissionError: "",
      disabledButton: false,
      studentURL: "student",
      leadURL: "leadformstudentapp",
      sectionsToValidate: ["leadData"],
      lead: {},
      APICompany,
    };
  },
  computed: {
    ...mapState(["directProgramData"]),
    randomPassword() {
      return generator.generate({
        length: 16,
        numbers: true,
        strict: true,
      });
    },
    phone() {
      let phone = this.lead.phoneCode + this.lead.phone;
      let numberPattern = /\d+/g;
      return phone.match(numberPattern).join("");
    },
  },

  methods: {
    async submitLead() {
      this.$refs.leadSubmit.startLoading();
      this.sendSubmissionError = "";
      this.salesforceError = false;
      eventBus.$emit("dateChange");

      try {
        await this.validate();

        if (!this.hasErrors) {
          checkForExistingLeads(
            this.lead.firstName,
            this.lead.lastName,
            this.lead.email,
            this.lead.phoneCode + this.lead.phone,
            this.lead.birthday
          ).then((exists) => {
            this.salesforceError = exists;
            if (!exists) this.submitForm();
            else this.$refs.leadSubmit.stopLoading();
          });
        }
      } catch {
        this.$refs.leadSubmit.stopLoading();
      }
    },
    getFormioObject() {
      return {
        email: this.lead.email.toLowerCase(),
        password: this.randomPassword,
        first_name: this.lead.firstName,
        last_name: this.lead.lastName,
        countryCode: this.lead.phoneCode,
        phoneNumber: this.lead.phone,
        phone: this.phone,
        birthdate: this.lead.birthday,
        citizenship: this.lead.citizenship,
        dual_citizenship: this.lead.dualCitizenship,
        student_type: this.lead.studentType,
        gender: this.lead.gender,
        parentName: this.lead.parentName,
        parentEmail: this.lead.parentEmail,
        school_name: this.lead.university ? this.lead.university : {},
        major: this.lead.major,
      };
    },
    async submitForm() {
      this.disabledButton = true;

      try {
        await axios.post("/register", this.getFormioObject(false));
        await this.$store.dispatch("refreshUserDataFromServer");
        await this.$store.dispatch("initialSetUp");

        if (!_.isEmpty(this.directProgramData)) {
          window.location.href =
            "/program/" + this.directProgramData.salesforce_id;
        } else {
          this.$router.push("/applications/new");
        }
      } catch (e) {
        this.$refs.leadSubmit.stopLoading();
        if (e.response.status === 500) {
          this.sendSubmissionError =
            "There was an error creating your account. Please try again.";
        } else {
          Object.values(e.response.data.errors).forEach((field) => {
            field.forEach((error) => {
              this.sendSubmissionError += error + "\n";
            });
          });
        }
      }
    },
    updateDate(date) {
      this.v$.value.birthday.$model = date;
    },
  },
};
</script>
