<template>
  <div>
    <div class="mb-6 space-y-6">
      <h4 class="mb-4 font-semibold text-gray-700">
        Receiving Credit for Your API Internship
      </h4>
      <p>
        API encourages all participants to discuss academic credit options with
        their home university advisor prior to beginning their internship
        program to determine if and how credits may be earned and/or transferred
        to their home institution. Academic credit for internships must be
        approved and arranged before the internship begins, as credit cannot be
        retroactively arranged. Please select your credit option from the
        options below.
      </p>
    </div>
    <div class="space-y-4 mb-6">
      <label class="flex" for="6Credits">
        <input
          id="6Credits"
          v-model.trim="v$.value.creditOption.$model"
          type="radio"
          value="6 Credits through University of Massachusetts Amherst"
          name="credit-option"
          class="mt-1 form-radio"
          :class="[
            { 'bg-error-100': v$.value.creditOption.$error },
            `${primaryColorClass}`,
          ]"
        />
        <span class="ml-2">
          I am participating in a
          <strong>Full Time Internship Program</strong> and
          <span class="underline">
            I want to earn 6 credits through the API school of record,
            University of Massachusetts Amherst.
          </span>
          I understand that an additional fee of $750 will be charged to my API
          account for this option and that I am responsible for ensuring that
          the credit will transfer to my home institution.
        </span>
      </label>

      <label class="flex" for="3Credits">
        <input
          id="3Credits"
          v-model.trim="v$.value.creditOption.$model"
          type="radio"
          value="3 Credits through University of Massachusetts Amherst"
          name="credit-option"
          class="mt-1 form-radio"
          :class="[
            { 'bg-error-100': v$.value.creditOption.$error },
            `${primaryColorClass}`,
          ]"
        />
        <span class="ml-2">
          I am participating in a
          <strong>Part Time Virtual Internship Program</strong> and
          <span class="underline">
            I want to earn 3 credits through the API school of record,
            University of Massachusetts Amherst.
          </span>
          I understand that an additional fee of $750 will be charged to my API
          account for this option and that I am responsible for ensuring that
          the credit will transfer to my home institution.
        </span>
      </label>

      <label class="flex" for="3CreditsCongreso">
        <input
          id="3CreditsCongreso"
          v-model.trim="v$.value.creditOption.$model"
          type="radio"
          value="3 Credits through Universidad Congreso"
          name="credit-option"
          class="mt-1 form-radio"
          :class="[
            { 'bg-error-100': v$.value.creditOption.$error },
            `${primaryColorClass}`,
          ]"
        />
        <span class="ml-2">
          I am participating in a
          <strong>
            Full OR Part Time Internship Program in a Spanish speaking location
          </strong>
          and
          <span class="underline">
            I want to earn 3 credits through the API school of record,
            Universidad Congreso.
          </span>
          I understand that an additional fee of $750 will be charged to my API
          account for this option and that I am responsible for ensuring that
          the credit will transfer to my home institution.
        </span>
      </label>

      <label class="flex" for="creditInstitution">
        <input
          id="creditInstitution"
          v-model.trim="v$.value.creditOption.$model"
          type="radio"
          value="Earn Credit directly through my institution"
          name="credit-option"
          class="mt-1 form-radio"
          :class="[
            { 'bg-error-100': v$.value.creditOption.$error },
            `${primaryColorClass}`,
          ]"
        />
        <span class="ml-2">
          I want to earn credit directly through my home institution for my
          internship. I understand that I am responsible to confirm the
          necessary procedures and assignments required by my home institution
          in order to obtain credit for my internship experience. I understand
          that I will NOT receive a transcript from University of Massachusetts
          Amherst by selecting this option.
        </span>
      </label>

      <label class="flex" for="noCredit">
        <input
          id="noCredit"
          v-model.trim="v$.value.creditOption.$model"
          type="radio"
          value="No Credit"
          name="credit-option"
          class="mt-1 form-radio"
          :class="[
            { 'bg-error-100': v$.value.creditOption.$error },
            `${primaryColorClass}`,
          ]"
        />
        <span class="ml-2">
          I do NOT want to earn academic credit for my internship. I understand
          that I will NOT receive a transcript from University of Massachusetts
          Amherst by selecting this option.
        </span>
      </label>

      <div
        v-if="
          v$.value.creditOption.required.$invalid &&
          v$.value.creditOption.$error
        "
        class="error text-error-900 text-sm"
      >
        Field is required
      </div>
    </div>
    <CreditModal
      v-if="UniversityOfMassachusettsAmherstModal"
      @close="UniversityOfMassachusettsAmherstModal = false"
    >
      <template #modal-title>
        Credit through University of Massachusetts Amherst
      </template>
      <p>
        Participants may choose to enroll with API’s accredited school of record
        (University of Massachusetts Amherst) for an additional fee of $750.
        Participants who select this option will be connected via email with an
        internship coordinator from University of Massachusetts Amherst and will
        be responsible to complete several written reflections and/or related
        assignments over the course of the internship experience. (For a
        syllabus, please contact your API Program Coordinator.) Upon completion
        of the program, participants will receive an official transcript from
        University of Massachusetts Amherst which will reflect the credit earned
        in the form of a letter grade (minimum 256 hours, 6 credits for the
        8-week, 10-week, and 12-week full-time sessions OR 160 hours, 3 credits
        for the 8-week, 10-week, and 12-week part-time session). The official
        transcript will be sent directly to the participant’s home university.*
      </p>
      <p>
        *Participants who indicate that they wish to receive credit through
        API’s School of Record (University of Massachusetts Amherst or
        Universidad Congreso) may not be able to opt out of this arrangement
        after submitting this form. Please be sure you understand all credit
        options before moving forward, and contact your API Program Coordinator
        if you have any questions.
      </p>
    </CreditModal>

    <CreditModal
      v-if="creditUniversidadCongresoModal"
      @close="creditUniversidadCongresoModal = false"
    >
      <template #modal-title>
        Credit through Universidad Congreso - Spanish
      </template>
      <p>
        Participants completing an internship in a Spanish speaking location may
        choose to enroll with API’s accredited school of record (Universidad
        Congreso in Mendoza, Argentina) for an additional fee of $750.
        Participants who select this option will be connected via email with an
        internship professor from Universidad Congreso and will be responsible
        to complete several written reflections and/or related assignments over
        the course of the internship experience. (For a syllabus, please contact
        your API Program Coordinator.) Upon completion of the program,
        participants will receive an official transcript from Universidad
        Congreso which will reflect the credit earned in the form of a letter
        grade for 160 contact hours, 3 credits for the 8-week, 10-week, and
        12-week session). The official transcript will be sent directly to the
        participant’s home university.*
      </p>
      <p>
        *Participants who indicate that they wish to receive credit through
        API’s School of Record (University of Massachusetts Amherst or
        Universidad Congreso) may not be able to opt out of this arrangement
        after submitting this form. Please be sure you understand all credit
        options before moving forward, and contact your API Program Coordinator
        if you have any questions.
      </p>
    </CreditModal>

    <CreditModal
      v-if="creditHomeInstitutionModal"
      @close="creditHomeInstitutionModal = false"
    >
      <template #modal-title>
        Credit through Home Institution
      </template>
      <p>
        Participants may petition your home university for academic credit for
        work completed during your internship. Participants who select this
        option will NOT receive an official transcript from API upon completion
        of the program, and will NOT receive credit through API’s school of
        record. Participants must confirm with their home university how credit
        is awarded for international internships and make sure to complete all
        necessary paperwork and assignments required to obtain credit.
      </p>
    </CreditModal>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import CreditModal from "../../modals/CreditModal.vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: "CreditOptions",
  components: {
    CreditModal,
  },
  props: {
    creditOption: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formioData: {
      type: String,
      default: "",
    },
  },
  emits: ["update:credit-option"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        creditOption: "",
      },
      UniversityOfMassachusettsAmherstModal: false,
      creditUniversidadCongresoModal: false,
      creditHomeInstitutionModal: false,
      showModal: true,
    };
  },
  validations: {
    value: {
      creditOption: { required },
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:credit-option", this.value);
      },
      deep: true,
      immediate: true,
    },
    "value.creditOption": {
      handler: function (newVal) {
        if (this.showModal) {
          if (newVal.includes("University of Massachusetts Amherst")) {
            this.UniversityOfMassachusettsAmherstModal = true;
          } else if (newVal.includes("Universidad Congreso")) {
            this.creditUniversidadCongresoModal = true;
          } else if (newVal.includes("my institution")) {
            this.creditHomeInstitutionModal = true;
          }
        } else {
          this.showModal = true;
        }
      },
      immediate: true,
    },
    formioData: {
      handler: function (newValue) {
        this.showModal = !newValue;
        this.value.creditOption = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
