<script setup>
import Spinner from "@/components/helpers/Spinner.vue";
import { defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const goBack = () => router.go(-1);
const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  message: {
    type: String,
    default: "Loading Internship Form",
  },
  wrongLink: {
    type: Object,
    default: () => {},
  },
  backLink: {
    type: Object,
    default: () => {
      return {
        name: "program-manager-root",
      };
    },
  },
});
</script>

<template>
  <div class="w-full mx-auto max-w-7xl md:px-8 md:mb-8">
    <div
      class="px-4 py-6 bg-white border-t border-gray-200 dashboard sm:grid sm:grid-cols-12 sm:gap-6 md:gap-12 sm:px-8 md:p-12 md:border md:rounded"
    >
      <div class="col-span-12">
        <template v-if="props.loading">
          <spinner>
            <template #spinnercontent>
              <p class="text-gray-600">
                {{ message }}
              </p>
              <p>
                Something not working as expected?
              </p>
              <span
                class="api-link underline hover:no-underline cursor-pointer"
                @click.prevent="goBack"
              >
                Click here to go back.
              </span>
            </template>
          </spinner>
        </template>
      </div>
    </div>
  </div>
</template>
