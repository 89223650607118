<template>
  <div id="programs-list">
    <h2 class="px-4 text-xl font-semibold sm:px-0 hidden">Selected Programs</h2>

    <div class="grid gap-4 mt-3">
      <div
        v-for="(program, index) in programList"
        :key="program.id"
        class="mx-4 bg-white rounded sm:rounded-lg sm:mx-0"
      >
        <template v-if="program.modalopen">
          <modal
            :id="'breakdown' + program.id"
            :title="'Price Breakdown'"
            @closeModal="close(program)"
          >
            <template #modal-content>
              <breakdown
                :price-break-down="program.priceBreakDown"
                :program-name="program.program_name"
                :program-site="program.program_site"
                :program-sessionid="program.salesforce_program_session_id"
                @close="close(program)"
              />
            </template>
          </modal>
        </template>
        <div class="p-4 border border-gray-300 rounded-b md:p-6">
          <a
            v-if="program.url"
            class="mb-4 text-xl font-semibold"
            :href="program.url"
            target="_blank"
          >
            {{ program.program_name }}
          </a>
          <h3 v-else class="mb-4 text-xl font-semibold">
            {{ program.program_name }}
          </h3>
          <p class="flex items-center lg:mb-4">
            <i class="mdi mdi-map-marker-outline mr-2"></i>
            <span> {{ program.program_site }} </span>
          </p>
          <div class="justify-between mb-4 lg:flex">
            <p class="mb-4 text-gray-500 lg:mb-0">
              <datesProgram
                :id="program.id + 'r' + index"
                :dates="program.start_date + ' - ' + program.end_date"
                :start-is-final="program.start_date_is_final > 0"
                :end-is-final="program.end_date_is_final > 0"
                :invalid-symbol="''"
              />
            </p>
            <p>Application Deadline: {{ program.application_deadline }}</p>
          </div>
          <div class="flex items-baseline justify-between hidden">
            <p class="text-xl font-semibold">
              ${{
                getPrice(
                  program.salesforce_program_session_id,
                  program.program_cost
                )
              }}
            </p>
          </div>
          <div
            v-if="getPriceBreakDownTotal(program.priceBreakDown)"
            class="mt-1 hidden"
          >
            <p>
              <button
                class="flex items-center focus:outline-none"
                @click="open(program)"
              >
                <span class="text-teal-900 font-semibold">Price Breakdown</span>
                <i class="mdi mdi-chevron-right text-2xl"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="!hasPrograms"
        class="mx-4 bg-white rounded sm:rounded-lg sm:mx-0"
      >
        <p class="p-4 border border-gray-300 rounded-b md:p-6">
          <span class="h5 mb-4 text-xl font-semibold">
            This student does not have any programs assigned yet.
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import modal from "../../modal.vue";
import breakdown from "../priceBreakDown.vue";
import programDates from "../../dataTables/programDates.vue";
import { priceBreakDownMixin } from "@/mixins/priceBreakDownMixin";
import customPrice from "@/mixins/customPrice.js";

export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: "programs-list",
  components: {
    modal: modal,
    breakdown: breakdown,
    datesProgram: programDates,
  },
  mixins: [priceBreakDownMixin, customPrice],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    programs: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      programList: [],
    };
  },
  computed: {
    hasPrograms() {
      return this.programs.length > 0;
    },
  },
  watch: {
    programs: {
      async handler(newval) {
        let vm = this;
        const newPrograms = JSON.parse(JSON.stringify(newval)); // Deep clone to avoid mutating the prop!
        const modifiedPrograms = await Promise.allSettled(
          newPrograms.map(async (program) => {
            const priceBreakDown = await vm.getPriceBreakDown(
              program.salesforce_program_session_id
            );
            program["priceBreakDown"] = priceBreakDown;
            return program;
          })
        );

        this.programList = modifiedPrograms.map(function (program) {
          return program.value;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    close(program) {
      program.modalopen = false;
    },
    open(program) {
      // Open modal
      program.modalopen = true;
    },
    formatDate(dateParam) {
      let date = new Date(dateParam);
      date =
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
      return date;
    },
    getPriceBreakDownTotal(breakDown) {
      return breakDown ? breakDown.total : 0;
    },
  },
};
</script>
