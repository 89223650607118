import hostInstitutionService from "@/services/hostInstitution";
import programSessionsService from "@/services/programSessions";

const programSessions = {
  namespaced: true,
  state: {
    programSessions: {
      isLoading: false,
      isError: false,
      errorMessage: false,
      data: undefined,
    },
    programSession: {
      isLoading: false,
      isError: false,
      errorMessage: false,
      data: undefined,
    },
    hostInstitutions: {
      isLoading: false,
      isError: false,
      errorMessage: false,
      data: undefined,
    },
  },
  mutations: {
    setLoadingProgramSession(state, payload) {
      state.programSession.isLoading = payload;
    },
    setProgramSessionError(state, payload) {
      state.programSession.isError = payload;
    },
    setProgramSessionErrorMessage(state, payload) {
      state.programSession.errorMessage = payload;
    },
    setProgramSession(state, payload) {
      state.programSession.data = payload;
    },
    setHostInstitutionsLoading(state, payload) {
      state.hostInstitutions.isLoading = payload;
    },
    setHostInstitutionsError(state, payload) {
      state.hostInstitutions.isError = payload;
    },
    setHostInstitutionsErrorMessage(state, payload) {
      state.hostInstitutions.errorMessage = payload;
    },
    setHostInstitutions(state, payload) {
      state.hostInstitutions.data = payload;
    },
    setProgramSessionsLoading(state, payload) {
      state.programSessions.isLoading = payload;
    },
    setProgramSessionsError(state, payload) {
      state.programSessions.isError = payload;
    },
    setProgramSessionsErrorMessage(state, payload) {
      state.programSessions.errorMessage = payload;
    },
    setProgramSessions(state, payload) {
      state.programSessions.data = payload;
    },
    setProgramSessionUpdate(state, payload) {
      state.programSessionUpdate.data = payload;
    },
    setProgramSessionUpdateErrorMessage(state, payload) {
      state.programSessionUpdate.errorMessage = payload;
    },
    setProgramSessionUpdateError(state, payload) {
      state.programSessionUpdate.isError = payload;
    },
    setLoadingProgramSessionUpdate(state, payload) {
      state.programSessionUpdate.isLoading = payload;
    },
  },
  actions: {
    async fetchProgramSessionsData(context, data) {
      context.commit("setProgramSessionsLoading", true);
      context.commit("setProgramSessionsError", false);
      try {
        const programSessionsResponse = await programSessionsService.getProgramSessions(
          data
        );
        context.commit(
          "setProgramSessions",
          programSessionsResponse.response.data
        );
      } catch (e) {
        context.commit("setProgramSessionsError", true);
        context.commit("setProgramSessionsErrorMessage", e.message);
      } finally {
        context.commit("setProgramSessionsLoading", false);
      }
    },
    async fetchProgramSessionData({ commit }, payload = {}) {
      let { programSessionId } = payload;
      commit("setProgramSessionErrorMessage", "");
      commit("setProgramSessionError", false);
      commit("setLoadingProgramSession", true);
      try {
        const {
          data: {
            data: { items },
          },
        } = await programSessionsService.getProgramSession({
          id: programSessionId,
          rules: "True",
          housing: "True",
        });
        commit("setProgramSession", items);
        return items;
      } catch (error) {
        commit("setProgramSessionErrorMessage", error.message);
        commit("setProgramSessionError", true);
      } finally {
        commit("setLoadingProgramSession", false);
      }
      return undefined;
    },
    async fetchHostInstitutions(context, data) {
      context.commit("setHostInstitutionsLoading", true);
      context.commit("setHostInstitutionsError", false);
      try {
        const hostInstitutionsResponse = await hostInstitutionService.getHostInstitutions(
          data
        );
        context.commit(
          "setHostInstitutions",
          hostInstitutionsResponse.data.data
        );
      } catch (e) {
        context.commit("setHostInstitutionsError", true);
        context.commit("setHostInstitutionsErrorMessage", e.message);
      } finally {
        context.commit("setHostInstitutionsLoading", false);
      }
    },
    async updateProgramSessions({ commit }, payload = {}) {
      commit("setLoadingProgramSession", true);
      try {
        const {
          data: {
            data: { items },
          },
        } = await programSessionsService.updateOrCreateProgramSession(payload);
        commit("setProgramSession", items);
        return items;
      } catch (error) {
        commit("setProgramSessionError", true);
        commit("setProgramSessionErrorMessage", error.message);
        throw error;
      } finally {
        commit("setLoadingProgramSession", false);
      }
    },
  },
  getters: {
    getLoadingProgramSession(state) {
      return state.programSession?.isLoading;
    },
    getProgramSessionErrorMessage(state) {
      return state.programSession?.errorMessage;
    },
    getProgramSessionError(state) {
      return state.programSession?.isError;
    },
    getProgramSession(state) {
      return state.programSession.data;
    },
    getHostInstitutionsIsLoading(state) {
      return state.hostInstitutions?.isLoading;
    },
    getHostInstitutionsIsError(state) {
      return state.hostInstitutions?.isError;
    },
    getHostInstitutionsData(state) {
      return state.hostInstitutions?.data?.items || [];
    },
    getProgramSessionsIsLoading(state) {
      return state.programSessions?.isLoading;
    },
    getProgramSessionsIsError(state) {
      return state.programSessions?.isError;
    },
    getProgramSessionsData(state) {
      return state.programSessions?.data?.items || [];
    },
  },
};

export default programSessions;
