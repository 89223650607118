import { unref } from "vue";
import { format as formatDate, parse, parseISO } from "date-fns";
import { cloneDeep, difference } from "lodash";
import { sessionType } from "@/components/program-manager/sessions/constants.js";

// TODO: This seems to be widely supported in browser but may need to swap for something like https://formatjs.io/docs/intl-messageformat/
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const convertLatLongDDtoDMS = (latLong = "") => {
  if (!latLong) return "";

  let splitLatLong = latLong.split(", ");
  if (splitLatLong.length < 2) {
    splitLatLong = latLong.split(" ");
  }
  const numLat = Number(splitLatLong[0]);
  const numLong = Number(splitLatLong[1]);
  let displayString = "";
  if (numLat && numLong) {
    displayString = `${Math.abs(numLat).toFixed(4)}\u00B0 ${
      numLat > 0 ? "N" : "S"
    }, ${Math.abs(numLong).toFixed(4)}\u00B0 ${numLong > 0 ? "E" : "W"}`;
  }
  return displayString;
};

export const convertDurationCost = (
  internship,
  academicStartDate,
  duration
) => {
  if (academicStartDate && internship?.product_prices) {
    const startDate = parse(academicStartDate, "yyyy-MM-dd", new Date());
    for (const pp of internship.product_prices) {
      const effectiveStartDate =
        pp?.effective_start_date &&
        parse(pp?.effective_start_date, "yyyy-MM-dd", new Date());
      const effectiveEndDate =
        pp?.effective_end_date &&
        parse(pp?.effective_end_date, "yyyy-MM-dd", new Date());
      const priceInCents = Number(pp?.price_in_cents);
      if (
        effectiveStartDate < startDate &&
        (!effectiveEndDate || startDate < effectiveEndDate) &&
        !Number.isNaN(priceInCents) &&
        !Number.isNaN(duration)
      ) {
        // TODO: Looks like there is a duration_unit it internship db but its not being filled in so come back and use
        // it here later
        return convertCost((priceInCents * duration) / 100);
      } else {
        return "";
      }
    }
  }
};

export const getRoomPrice = (room, roomStartDate) => {
  const startDate = parse(roomStartDate, "yyyy-MM-dd", new Date());
  if (!room?.product_prices || !startDate) return undefined;

  for (const pp of room.product_prices) {
    const effectiveStartDate =
      pp?.effective_start_date &&
      parse(pp?.effective_start_date, "yyyy-MM-dd", new Date());
    const effectiveEndDate =
      pp?.effective_end_date &&
      parse(pp?.effective_end_date, "yyyy-MM-dd", new Date());
    const priceInCents = Number(pp?.price_in_cents);
    if (
      effectiveStartDate <= startDate &&
      (!effectiveEndDate || startDate < effectiveEndDate) &&
      !Number.isNaN(priceInCents)
    ) {
      return priceInCents / 100;
    }
  }
  return undefined;
};

export const convertCost = (val) => {
  const num = Number(val);
  return !Number.isNaN(num) ? formatter.format(num) : "$0";
};

export const getCityCountryOptionLabel = (option) => {
  if (option && option.label) {
    return option.label;
  } else if (option) {
    const { city_ascii = "", country, admin_name = "" } = option;
    return `${city_ascii}, ${admin_name}, ${country?.iso || ""}`;
  }
  return "";
};

export const getProfileLabel = (option) => {
  if (option && option.label) {
    return option.label;
  } else if (option) {
    const {
      preferred_first_name = "",
      legal_first_name = "",
      last_name = "",
    } = option;
    return `${(preferred_first_name || legal_first_name || "").trim()} ${
      last_name || ""
    }`;
  }
  return "";
};

export const getLabel = (val) => `${val.label || ""}`;

export const convertClassFilterCriteria = (filters) => {
  const {
    host_institutions,
    cip_codes,
    required_languages,
    proficiency_levels,
    education_levels,
    terms,
  } = unref(filters) || {};
  const unrefTerms = unref(terms);

  const host_institutions_filter = []
    .concat(host_institutions?.map((val) => val.id))
    .filter((val) => val);

  return {
    status: "Active",
    terms:
      unrefTerms && unrefTerms.length > 0
        ? unrefTerms.map((val) => val.id)
        : undefined,
    host_institutions:
      host_institutions_filter && host_institutions_filter.length > 0
        ? host_institutions_filter
        : undefined,
    areas_of_study:
      cip_codes && cip_codes.length > 0
        ? cip_codes.map((val) => val.id)
        : undefined,
    languages_of_instruction:
      required_languages && required_languages.length > 0
        ? required_languages.map((val) => val.id)
        : undefined,
    language_levels:
      proficiency_levels && proficiency_levels.length > 0
        ? proficiency_levels.map((val) => val.id)
        : undefined,
    education_levels:
      education_levels && education_levels.length > 0
        ? education_levels.map((val) => val.id)
        : undefined,
  };
};

export const classFilterCriteriaNotEqual = (oldCriteria, newCriteria) => {
  let retVal = false;
  const keys = Object.keys(newCriteria);
  for (const key in keys) {
    const diff = difference(oldCriteria[key], newCriteria[key]);
    if (diff?.length > 0) {
      retVal = true;
      break;
    }
  }
  return retVal;
};

export const convertInternshipFilterCriteria = ({
  pricing_date = undefined,
  durations = undefined,
  work_locations = undefined,
  cities = [],
  terms = [],
  placement_type = undefined,
}) => {
  const unrefTerms = unref(terms);
  return {
    pricing_date,
    work_location: work_locations?.id,
    status: "Active",
    city_ids:
      cities && cities.length > 0
        ? cities.map((val) => val.city_id)
        : undefined,
    durations: durations ? [durations.id] : undefined,
    terms:
      unrefTerms && unrefTerms.length > 0
        ? unrefTerms.map((val) => val.id)
        : undefined,
    placement_type: placement_type?.value,
  };
};

export const convertHousingFilterCriteria = ({
  pricing_date = undefined,
  date = {},
  cities = [],
  terms = [],
  types = [],
  allowed_program_types = [],
}) => {
  const unrefTerms = unref(terms);
  return {
    pricing_date,
    allowed_program_types: allowed_program_types?.map((item) => item.label),
    status: "Active",
    city_ids:
      cities && cities.length > 0
        ? cities.map((val) => val.city_id)
        : undefined,
    housing_types:
      types && types.length > 0 ? types.map((val) => val.type) : undefined,
    terms_available:
      unrefTerms && unrefTerms.length > 0
        ? unrefTerms.map((val) => val.id)
        : undefined,
    housing_availability__start_date: unref(date)?.arrival_date?.toISOString(),
    housing_availability__end_date: unref(date)?.departure_date?.toISOString(),
  };
};

export const getFilterLabel = (item) => {
  if (item) {
    return `${
      item.name || item.value || item.label || item.city_ascii || item
    }`;
  }
};

export const filterNonActiveRoomsAndUnits = (housings) => {
  if (housings?.length > 0) {
    const filteredHousings = housings.map((housing) => {
      const filteredUnits = housing?.units
        ?.filter((val) => val?.status === "Active")
        .map((unit) => {
          const filteredRooms = unit?.rooms?.filter(
            (room) => room?.status === "Active"
          );
          return {
            ...unit,
            rooms: filteredRooms || [],
          };
        });
      return {
        ...housing,
        units: filteredUnits || [],
      };
    });
    return filteredHousings;
  } else {
    return [];
  }
};

export const dateLabel = ({ arrival_date, departure_date }) => {
  if (arrival_date || departure_date) {
    const parsedArrival = parseISO(arrival_date, "P");
    const parsedDepart = parseISO(departure_date, "P");
    const label = `${
      !isNaN(parsedArrival) ? formatDate(parsedArrival, "P") : ""
    } - ${!isNaN(parsedDepart) ? formatDate(parsedDepart, "P") : ""}`;
    return label;
  }
};

export const convertSTDFilters = (newTravelDetails) => {
  const newDates = [];
  const newCities = [];

  if (Array.isArray(newTravelDetails)) {
    newTravelDetails
      .filter(
        ({ arrival_date, departure_date }) => arrival_date || departure_date
      )
      .forEach((val) => {
        const { arrival_city } = val;
        const arrival_date = val?.arrival_date
          ? parse(val.arrival_date, "yyyy-MM-dd", new Date())
          : undefined;
        const departure_date = val?.departure_date
          ? parse(val.departure_date, "yyyy-MM-dd", new Date())
          : undefined;
        const label = dateLabel(val);
        newDates.push({
          label,
          arrival_date,
          departure_date,
        });
        if (arrival_city) {
          const clonedCity = cloneDeep(arrival_city);
          clonedCity.city_id = clonedCity.id;
          newCities.push(clonedCity);
        }
      });
  }
  return {
    newCities,
    newDates,
  };
};

export const checkIfProgramSessionTypeIsInternshipOnly = (programSession) =>
  programSession?.session_types?.length === 1 &&
  programSession?.session_types?.[0]?.id === sessionType.internship;

export const sessionStartAndEndDate = (programSession) => {
  {
    return {
      startDate: programSession?.session_travel_details?.[0]?.arrival_date,
      endDate: programSession?.session_travel_details?.[0]?.departure_date,
    };
  }
};