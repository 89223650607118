<template>
  <form-panel
    :id="'cardInformation'"
    :title="'Card Information'"
    panelContentClass="grid grid-cols-1 gap-4 pb-6 mb-6 border-b border-gray-200 card-form sm:gap-6"
  >
    <template #content>
      <div class="flex justify-center mb-6 text-gray-600">
        <img
          src="/images/credit-cards.png"
          srcset="
            /images/credit-cards.png    216w,
            /images/credit-cards@2x.png 432w
          "
          height="34"
          width="216"
          alt="Accepted Credit Cards include Visa, Mastercard and Discover"
        />
      </div>

      <label class="block text-sm" for="cardNumber">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Card Number</span>
        </span>
        <input
          id="cardNumber"
          v-model.trim="value.number"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.number.$error }"
        />
        <div
          v-if="v$.value.number.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.number.$error }"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.number.numeric.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.number.$error }"
        >
          Please use numbers only.
        </div>
        <div
          v-if="v$.value.number.maxLength.$invalid"
          class="error text-error-900"
        >
          Please use a maximum of
          {{ v$.value.number.maxLength.$params.max }} characters for the card
          number.
        </div>
        <div
          v-if="v$.value.number.minLength.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.number.$error }"
        >
          Please use a minimum of
          {{ v$.value.number.minLength.$params.min }} characters for the card
          number.
        </div>
        <div
          v-if="v$.value.number.validCardIssuer.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.number.$error }"
        >
          We do not accept this type of credit card. Please try again with a
          Discover, Visa or Mastercard.
        </div>
      </label>
      <div class="grid gap-4 sm:gap-6 md:flex md:justify-between">
        <div
          role="group"
          aria-labelledby="exp_date"
          class="block text-sm flex items-center"
        >
          <div id="exp_date" class="text-gray-600">
            Exp Date
          </div>
          <div class="flex items-center">
            <label class="text-gray-600" for="cardMonth">
              <span class="sr-only">Month</span>
              <input
                id="cardMonth"
                v-model.trim="value.month"
                type="text"
                placeholder="MM"
                class="form-input ml-4 w-16"
                :class="{ 'bg-error-100': v$.value.month.$error }"
                maxlength="2"
              />
              <div
                v-if="v$.value.month.required.$invalid"
                class="ml-4 error text-error-900"
                :class="{ hidden: !v$.value.month.$error }"
              >
                Field is required
              </div>
              <div
                v-if="v$.value.month.minLength.$invalid"
                class="ml-4 error text-error-900"
                :class="{ hidden: !v$.value.month.$error }"
              >
                Please use
                {{ v$.value.month.minLength.$params.min }} digits for the month.
              </div>
              <div
                v-if="v$.value.month.numeric.$invalid"
                class="ml-4 error text-error-900"
                :class="{ hidden: !v$.value.month.$error }"
              >
                Please use numbers only.
              </div>
              <div
                v-if="v$.value.month.between.$invalid"
                class="ml-4 error text-error-900"
                :class="{ hidden: !v$.value.month.$error }"
              >
                Must be between {{ v$.value.month.between.$params.min }} and
                {{ v$.value.month.between.$params.max }}
              </div>
            </label>
            <label class="text-gray-600" for="cardYear">
              <span class="sr-only">Year</span>
              <input
                id="cardYear"
                v-model.trim="value.year"
                type="text"
                placeholder="YY"
                class="form-input ml-4 w-16"
                :class="{ 'bg-error-100': v$.value.year.$error }"
                maxlength="2"
              />
              <div
                v-if="v$.value.year.required.$invalid"
                class="ml-4 error text-error-900"
                :class="{ hidden: !v$.value.year.$error }"
              >
                Field is required
              </div>
              <div
                v-if="v$.value.year.minLength.$invalid"
                class="ml-4 error text-error-900"
                :class="{ hidden: !v$.value.year.$error }"
              >
                Please use
                {{ v$.value.year.minLength.$params.min }} digits for the year.
              </div>
              <div
                v-if="v$.value.year.numeric.$invalid"
                class="ml-4 error text-error-900"
                :class="{ hidden: !v$.value.year.$error }"
              >
                Please use numbers only.
              </div>
            </label>
          </div>
        </div>
        <label class="flex items-center text-gray-600 text-sm" for="cardCvCode">
          <span>CV Code</span>
          <div>
            <input
              id="cardCvCode"
              v-model.trim="value.cvCode"
              type="text"
              placeholder=""
              class="form-input min-h-10 ml-4 w-20"
              :class="{ 'bg-error-100': v$.value.cvCode.$error }"
              maxlength="3"
            />
            <div
              v-if="v$.value.cvCode.required.$invalid"
              class="error text-error-900"
              :class="{ hidden: !v$.value.cvCode.$error }"
            >
              Field is required
            </div>
            <div
              v-if="v$.value.cvCode.numeric.$invalid"
              class="error text-error-900"
              :class="{ hidden: !v$.value.cvCode.$error }"
            >
              Please use numbers only.
            </div>
          </div>
        </label>
      </div>
    </template>
  </form-panel>
</template>
<script>
import formPanel from "../SharedComponents/panel";

import {
  required,
  numeric,
  minLength,
  maxLength,
  between,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const validCardIssuer = (value) => {
  return (
    value.startsWith("2") ||
    value.startsWith("4") ||
    value.startsWith("5") ||
    value.startsWith("6")
  );
};

export default {
  name: "CardInformation",
  components: { formPanel },
  props: {
    cardInformation: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:card-information"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        number: "",
        month: "",
        year: "",
        cvCode: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:card-information", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      number: {
        required,
        numeric,
        validCardIssuer,
        maxLength: maxLength(16),
        minLength: minLength(13),
      },
      month: {
        required,
        numeric,
        minLength: minLength(2),
        between: between(1, 12),
      },
      year: {
        required,
        numeric,
        minLength: minLength(2),
      },
      cvCode: {
        required,
        numeric,
      },
    },
  },
};
</script>
