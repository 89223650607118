<template>
  <label class="block">
    <span :id="name + '-label'" :class="labelSpanCustomClass">{{ label }}</span>
    <textarea
      v-model="textarea"
      :aria-labelledby="name + '-label'"
      :maxlength="maxLength"
      rows="4"
      :name="name"
      class="form-input min-h-10 mt-2 block w-full"
      :class="{
        'bg-error-100': validationErrors.$error,
      }"
      :disabled="disabled"
      @blur="validationErrors?.$touch"
    />
    <span v-if="maxLength" class="grid grid-cols-2">
      <span
        class="justify-end col-start-2 text-sm text-right text-gray-600"
        :class="
          charCount === parseInt(maxLength) ? 'success text-success-900' : ''
        "
        >{{ charCount }}/{{ maxLength }}</span
      >
    </span>
    <span v-for="(value, rule) in sortedValidationRules" :key="rule">
      <span
        v-if="validationErrors[rule].$invalid"
        :data-cy="name + '-validation'"
        class="error text-error-900"
        :class="[customErrorClass, { hidden: !validationErrors.$error }]"
      >
        <p v-if="rule === 'maxLength'">
          {{ validationMessage[rule] + " " + maxLength }}
        </p>
        <p v-else>{{ validationMessage[rule] }}</p>
      </span>
    </span>
  </label>
</template>

<script>
import validationMessages from "../../../mixins/validationMessages";
export default {
  mixins: [validationMessages],
  props: {
    name: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    ariaLabel: {
      type: String,
      default: "",
    },
    typedText: {
      type: String,
      default: "",
    },
    customErrorClass: {
      type: String,
      default: "",
    },
    labelSpanCustomClass: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["textUpdate"],
  data() {
    return {
      textarea: "",
    };
  },
  computed: {
    charCount() {
      return this.textarea.length;
    },
  },
  watch: {
    textarea: function (newVal) {
      this.$emit("textUpdate", newVal);
    },
    typedText: function (newVal) {
      if (newVal) this.textarea = newVal;
    },
  },
  created() {
    if (this.typedText) {
      this.textarea = this.typedText.trim();
    }
  },
  methods: {
    clear() {
      this.textarea = "";
    },
  },
};
</script>
