<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <spinner v-if="loading">
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications/landing',
              params: { applicationId: applicationId },
            }"
            :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
            class="underline hover:no-underline"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
      <FormPanel
        :id="'AbroadLetter'"
        :title="'Letter(s) of recommendation'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <div class="mb-6 space-y-4">
            <p>
              The letter of recommendation should be written by an individual
              who has taught the applicant at the university level in an
              academic setting (i.e. professor, teaching assistant). Letters
              from academic advisors or coaches will not be accepted.
            </p>
            <p>
              Please insert the email of the person you would like to request a
              recommendation from. Adding their email here will send them our
              Letter of Recommendation form to complete.
            </p>
            <EmailSender
              ref="AbroadRecommendationData1"
              :email-information="professors.first"
              :disable-button="false"
              :button-label="buttonLabel"
              :email-data="senderData"
              :email-u-r-l="emailSenderURL"
              :application-id="applicationId"
              :selected-name="formioData.first.name"
              :selected-email="formioData.first.email"
              label-prefix="abroadLetter"
              placeholder-name="Teacher or Professor Name"
              placeholder-email="Teacher or Professor Email"
              :success-submission="formSubmitted"
              @validateForm="clickSubmit"
              @submitForm="handleSubmit"
              @update:email-sender="professors.first = $event"
            >
            </EmailSender>
            <EmailSender
              ref="AbroadRecommendationData2"
              :email-information="professors.second"
              :disable-button="false"
              :button-label="secondButtonLabel"
              :email-data="secondSenderData"
              :email-u-r-l="emailSenderURL"
              :application-id="applicationId"
              :selected-name="formioData.second.name"
              :selected-email="formioData.second.email"
              label-prefix="secondAbroadLetter"
              placeholder-name="Teacher or Professor Name"
              placeholder-email="Teacher or Professor Email"
              :success-submission="secondFormSubmitted"
              @validateForm="clickSubmitSecondForm"
              @submitForm="handleSubmitSecondForm"
              @update:email-sender="professors.second = $event"
            >
            </EmailSender>

            <div v-if="formioErrors" class="text-error-900">
              <p v-for="error in formioErrors" :key="error">
                {{ error }}
              </p>
            </div>
          </div>
          <template v-if="errors">
            <p>{{ errors }}</p>
          </template>
        </template>
      </FormPanel>
    </template>
  </PageStyle>
</template>

<script>
import { mapState } from "vuex";
import FormPanel from "../SharedComponents/panel.vue";
import Spinner from "../../helpers/Spinner.vue";
import EmailSender from "../SharedComponents/EmailSender.vue";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import forms from "../../../mixins/forms.js";
import formioHelpers from "../../../mixins/formioHelpers";
import letterOfRecAndGpaLogic from "../../../mixins/letterOfRecAndGpaLogic";
export default {
  name: "AbroadLetter",
  components: {
    FormPanel,
    EmailSender,
    PageStyle,
    Spinner,
  },
  mixins: [forms, formioHelpers, letterOfRecAndGpaLogic],
  data() {
    return {
      professors: {
        first: {
          name: "",
          email: "",
        },
        second: {
          name: "",
          email: "",
        },
      },
      formioData: {
        first: {
          name: "",
          email: "",
        },
        second: {
          name: "",
          email: "",
        },
      },
      formPath: "studentapplicationstage2step1",
      formSubmitted: false,
      secondFormSubmitted: false,
      applicationId: "",
      redirect: false,
      redirectPath: "applications",
      loading: true,
      message: "Getting Student application",
      errors: "",
      formRuleName: "Web Form Letter of Recommendation",
      valid: true,
    };
  },
  computed: {
    ...mapState(["selectedProgramId"]),
    buttonLabel() {
      return this.formSubmitted ? "Resend" : "Send Request";
    },
    secondButtonLabel() {
      return this.secondFormSubmitted ? "Resend" : "Send Request";
    },
    senderData() {
      let body = new FormData();
      body.append("program_session_id", this.selectedProgramId);
      body.append("application_id", this.applicationId);
      body.append("email", this.professors.first.email);
      body.append("name", this.professors.first.name);
      return body;
    },
    secondSenderData() {
      let body = new FormData();
      body.append("program_session_id", this.selectedProgramId);
      body.append("application_id", this.applicationId);
      body.append("email", this.professors.second.email);
      body.append("name", this.professors.second.name);
      return body;
    },
    emailSenderURL() {
      return (
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "recommendation"
      );
    },
  },
  watch: {
    redirect: function (newVal) {
      if (newVal) {
        //redirect to my applications
        this.$router.push({
          name: this.redirectPath,
          params: {
            applicationId: this.applicationId,
          },
        });
      }
    },
    professors: {
      handler(val) {
        this.formSubmitted =
          val.first.name === this.formioData.first.name &&
          val.first.email === this.formioData.first.email;
        this.secondFormSubmitted =
          val.second.name === this.formioData.second.name &&
          val.second.email === this.formioData.second.email;
      },
      deep: true,
    },
  },
  async created() {
    //set the application ID
    this.applicationId = this.setApplicationId;

    //Get student GPA from enrollment service
    if (!this.studentGpa) {
      await this.setStudentGpa();
    }

    //check if user owns application
    if (!(await this.isApplicationOwner(this.applicationId))) {
      //clear the application ID so that it does not pass as param to the router
      this.applicationId = "";
      this.redirect = true;
      return;
    }

    //set selected program session id
    await this.$store.dispatch("setProgramDataByApplicationId", {
      applicationId: this.applicationId,
      apolloClient: this.$apollo,
    });


    //check if form is allowed in form rules
    if (
      !(await this.isFormAllowedByFormRules(
        this.formRuleName,
        this.applicationId
      ))
    ) {
      //if not required by form rules run the logic to determine if we should display it
      this.valid = await this.showLetterOfRecommendation();
    }
    if (this.valid) {
      this.getFormioData();
    } else {
      //redirect to enrollment page
      this.redirectPath = "applications/landing";
      this.redirect = true;
    }
  },

  methods: {
    createSubmissionDataForFormIo(section) {
      let body = {};
      switch (section) {
        case "second":
          body = {
            data: {
              application_id: this.applicationId,
              "recommendation-email-02": this.professors.second.email,
              "recommendation-name-02": this.professors.second.name,
              letterOfRecommendationSubmitted2: Boolean(
                this.professors.second.email && this.professors.second.name
              ),
            },
          };
          break;

        default:
          body = {
            data: {
              application_id: this.applicationId,
              "recommendation-email-01": this.professors.first.email,
              "recommendation-name-01": this.professors.first.name,
              letterOfRecommendationSubmitted1: Boolean(
                this.professors.first.email && this.professors.first.name
              ),
            },
          };
          break;
      }

      return body;
    },
    patchSubmissionDataForFormIo(section) {
      let body = [];
      switch (section) {
        case "second":
          body = [
            {
              op: "add",
              path: "/data/recommendation-email-02",
              value: this.professors.second.email,
            },
            {
              op: "add",
              path: "/data/recommendation-name-02",
              value: this.professors.second.name,
            },
            {
              op: "add",
              path: "/data/letterOfRecommendationSubmitted2",
              value: Boolean(
                this.professors.second.email && this.professors.second.name
              ),
            },
          ];
          break;

        default:
          body = [
            {
              op: "add",
              path: "/data/recommendation-email-01",
              value: this.professors.first.email,
            },
            {
              op: "add",
              path: "/data/recommendation-name-01",
              value: this.professors.first.name,
            },
            {
              op: "add",
              path: "/data/letterOfRecommendationSubmitted1",
              value: Boolean(
                this.professors.first.email && this.professors.first.name
              ),
            },
          ];
          break;
      }
      return body;
    },
    getFormioData() {
      this.formioSubmissionExists(this.formPath, this.applicationId).then(
        (formSubmissionId) => {
          if (formSubmissionId) {
            this.getFormioSubmission(this.formPath, formSubmissionId).then(
              (response) => {
                this.formioData.first.email =
                  response["recommendation-email-01"];
                this.formioData.first.name = response["recommendation-name-01"];
                this.formioData.second.email =
                  response["recommendation-email-02"];
                this.formioData.second.name =
                  response["recommendation-name-02"];
                this.loading = false;
              }
            );
          } else {
            this.loading = false;
          }
        }
      );
    },
    async handleSubmit() {
      this.formSubmitted = false;
      try {
        if (this.submission_id) {
          this.submitToFormIo(
            this.formPath,
            this.patchSubmissionDataForFormIo(),
            "PATCH",
            this.submission_id
          ).then((response) => {
            if (response) {
              this.formSubmitted = true;
            }
          });
        } else {
          this.submitToFormIo(
            this.formPath,
            this.createSubmissionDataForFormIo()
          ).then((response) => {
            if (response) {
              this.submission_id = response;
              this.formSubmitted = true;
            }
          });
        }
      } catch (error) {
        this.errors = error;
      } finally {
        this.$refs.AbroadRecommendationData1.$refs.abroadLetterButton.stopLoading();
      }
    },
    async handleSubmitSecondForm() {
      this.secondFormSubmitted = false;
      try {
        if (this.submission_id) {
          this.submitToFormIo(
            this.formPath,
            this.patchSubmissionDataForFormIo("second"),
            "PATCH",
            this.submission_id
          ).then((response) => {
            if (response) {
              this.secondFormSubmitted = true;
            }
          });
        } else {
          this.submitToFormIo(
            this.formPath,
            this.createSubmissionDataForFormIo("second")
          ).then((response) => {
            if (response) {
              this.submission_id = response;
              this.secondFormSubmitted = true;
            }
          });
        }
      } catch (error) {
        this.errors = error;
      } finally {
        this.$refs.AbroadRecommendationData2.$refs.secondAbroadLetterButton.stopLoading();
      }
    },
    clickSubmit() {
      this.formSubmitted = false;
      this.$refs.AbroadRecommendationData1.submit();
    },
    clickSubmitSecondForm() {
      this.secondFormSubmitted = false;
      this.$refs.AbroadRecommendationData2.submit();
    },
  },
};
</script>
