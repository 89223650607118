<template>
  <div class="flex flex-col w-full ml-7">
    <div class="flex w-full py-2"></div>
    <div
      v-for="(ranges, day) in timeRanges"
      :key="day"
      class="flex w-full py-2"
    >
      <div class="w-1/4 text-left pr-2 font-bold capitalize">{{ day }}</div>
      <div class="w-3/4 text-left">
        <div
          v-for="range in ranges"
          v-if="ranges.length > 0"
          :key="range"
          class="mb-1"
        >
          {{ range }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    scheduledTime: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const timeRanges = ref({});

    if (props.scheduledTime) {
      Object.keys(props.scheduledTime).forEach((day) => {
        const availability = props.scheduledTime[day].availability;
        const enabled = props.scheduledTime[day].enabled;

        if (enabled && availability.length > 0) {
          timeRanges.value[day] = availability.map(
            (range) => `${range.start} - ${range.end}`
          );
        } else {
          delete timeRanges.value[day];
        }
      });
    }

    return {
      timeRanges,
    };
  },
};
</script>
