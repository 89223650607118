<template>
  <div class="flex items-center justify-center h-screen px-12 py-12">
    <div class="w-full max-w-2xl md:w-2/3 h-full">
      <div class="flex flex-col items-center mb-10">
        <img class="h-20" :src="logo" alt="API Abroad" />
        <h2
          class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
        >
          Register your API employee account
        </h2>
      </div>
      <div class="grid grid-cols-1 gap-4 sm:gap-6">
        <form
          id="create-employee-account"
          class="account-creation-form grid grid-cols-1 gap-4 md:grid-cols-2 sm:gap-6"
          action=""
          aria-label="Create Account"
          @submit.prevent="submit()"
        >
          <label for="firstName" class="block text-sm">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>First name</span>
            </span>
            <input
              id="firstName"
              v-model.trim="v$.value.firstName.$model"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.firstName.$error }"
            />
            <span
              v-if="
                v$.value.firstName.required.$invalid &&
                v$.value.firstName.$error
              "
              class="block error text-error-900"
            >
              Field is required
            </span>
            <span
              v-if="v$.value.firstName.maxLength.$invalid"
              class="block error text-error-900"
            >
              First name must have no more than
              {{ v$.value.firstName.maxLength.$params.max }} characters.
            </span>
          </label>
          <label for="lastName" class="block text-sm">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Last name</span>
            </span>
            <input
              id="lastName"
              v-model.trim="v$.value.lastName.$model"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.lastName.$error }"
            />
            <span
              v-if="
                v$.value.lastName.required.$invalid && v$.value.lastName.$error
              "
              class="block error text-error-900"
            >
              Field is required
            </span>
            <span
              v-if="v$.value.lastName.maxLength.$invalid"
              class="block error text-error-900"
            >
              Last name must have no more than
              {{ v$.value.lastName.maxLength.$params.max }} characters.
            </span>
          </label>
          <label for="email" class="block text-sm md:col-span-2">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Email</span>
            </span>
            <input
              id="email"
              v-model.trim="v$.value.email.$model"
              type="text"
              class="form-input block w-full min-h-10 lowercase"
              :class="{ 'bg-error-100': v$.value.email.$error }"
            />
            <span
              v-if="v$.value.email.required.$invalid && v$.value.email.$error"
              class="block error text-error-900"
            >
              Field is required
            </span>
            <span
              v-if="v$.value.email.email.$invalid"
              class="block error text-error-900"
            >
              Email must be a valid email
            </span>
            <span
              v-if="v$.value.email.maxLength.$invalid"
              class="block error text-error-900"
            >
              Email must have no more than
              {{ v$.value.email.maxLength.$params.max }} characters.
            </span>
          </label>
          <label for="password" class="block text-sm md:col-span-2">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Password</span>
            </span>
            <input
              id="password"
              v-model.trim="v$.value.password.$model"
              type="text"
              class="form-input block w-full min-h-10 lowercase"
              :class="{ 'bg-error-100': v$.value.password.$error }"
            />
            <span
              v-if="
                v$.value.password.required.$invalid && v$.value.password.$error
              "
              class="block error text-error-900"
            >
              Field is required
            </span>
          </label>
          <div class="mt-6 md:col-span-2">
            <button
              id="submit"
              type="submit"
              :disabled="v$.$invalid || disabledButton"
              :class="[
                tertiaryColor
                  ? `${tertiaryColorClass}`
                  : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
              ]"
              class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-gray-700"
            >
              <span>Start Application</span>
            </button>
            <div v-if="sendSubmissionError" class="error text-error-900 mt-2">
              {{ sendSubmissionError }}
            </div>
          </div>
        </form>
        <div class="mt-6 m-10 text-center text-gray-600">
          <p>
            Already have an account? Log in
            <router-link
              :to="{ path: '/sign-in' }"
              :class="[
                secondaryColor
                  ? `${secondaryColorClass}`
                  : `${secondaryColorClass} hover:text-teal-900 focus:text-teal-900`,
              ]"
            >
              here
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, maxLength, required } from "@vuelidate/validators";
import axios from "axios";
import useVuelidate from "@vuelidate/core";

const generator = require("generate-password");

export default {
  name: "EmployeeRegister",
  components: {},
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
      logo: localStorage.logoUrl,
      disabledButton: false,
      sendSubmissionError: "",
    };
  },
  validations: {
    value: {
      firstName: {
        required,
        maxLength: maxLength(32),
      },
      lastName: {
        required,
        maxLength: maxLength(32),
      },
      email: {
        required,
        maxLength: maxLength(120),
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    randomPassword() {
      return generator.generate({
        length: 16,
        numbers: true,
        strict: true,
      });
    },
  },
  watch: {},

  methods: {
    submit() {
      this.sendSubmissionError = "";
      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.submitForm();
      }
    },
    async submitForm() {
      this.disabledButton = true;

      try {
        await axios.post("/register", this.value);
      } catch (e) {
        console.log(e);
        this.disabledButton = false;
        if (e.response.status === 500) {
          this.sendSubmissionError =
            "There was an error creating your account. Please try again.";
        } else {
          Object.values(e.response.data.errors).forEach((field) => {
            field.forEach((error) => {
              this.sendSubmissionError += error + "\n";
            });
          });
        }
      }
    },
  },
};
</script>

<style></style>
