<script setup>
import { computed, ref, reactive, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";
import FilterModal from "@/components/modals/AdvancedFilterModal";
import BaseInput from "@/components/InternshipLibrary/BaseInput.vue";
import { formSettings } from "@/components/InternshipLibrary/formSettings.js";
import { isAuthorized } from "@/composables/authorization.js";

const store = useStore();

const options = reactive(formSettings);
const filter = ref({
  company: undefined,
  placement_type: undefined,
  status: undefined,
  career_area: undefined,
  session: undefined,
  major: undefined,
  durations: undefined,
  cities: null,
  countries: null,
});
const advancedFilters = computed(
  () => store.getters["programManager/getAdvancedFilters"]
);

const unwatch = watch(
  advancedFilters,
  (newFilters) => {
    filter.value = {
      ...filter.value,
      ...newFilters,
    };
  },
  { immediate: true }
);
const readLiteOnly =
  isAuthorized("items.read.lite") && !isAuthorized("items.read");

onBeforeUnmount(() => {
  unwatch();
});

const changeAdvancedFilters = (value, key) => {
  store.commit("programManager/setAdvancedFilters", {
    ...advancedFilters.value,
    [key]: value,
  });
};
</script>

<template>
  <FilterModal>
    <div>
      <!--COMPANY NAME-->
      <div v-if="!readLiteOnly">
        <label class="block font-semibold mt-6" for="internship-company_name"
          >Company Name</label
        >
        <BaseInput
          id="internship-company_name"
          v-model="filter.company"
          :field-settings="options.company"
          @update:model-value="
            (value) => changeAdvancedFilters(value, 'company')
          "
        />
      </div>
      <!--PLACEMENT TYPE-->
      <label class="block font-semibold mt-6" for="internship-placement_type"
        >Status</label
      >
      <BaseInput
        id="internship-status"
        v-model="filter.status"
        :field-settings="options.status"
        @update:model-value="(value) => changeAdvancedFilters(value, 'status')"
      />
      <!-- Internship Areas -->
      <label class="block font-semibold mt-6" for="internship-career_areas"
        >Internship Areas</label
      >
      <BaseInput
        id="internship-career_areas"
        v-model="filter.career_area"
        :field-settings="options.careerAreas"
        @update:model-value="
          (value) => changeAdvancedFilters(value, 'career_area')
        "
      />
      <!-- Major Fit -->
      <label class="block font-semibold mt-6" for="internship-major"
        >Major</label
      >
      <BaseInput
        id="internship-major"
        v-model="filter.major"
        :field-settings="options.majorFit"
        @update:model-value="(value) => changeAdvancedFilters(value, 'major')"
      />
      <!-- Duration  -->
      <label class="block font-semibold mt-6" for="internship-durations"
        >Duration</label
      >
      <BaseInput
        id="internship-durations"
        v-model="filter.durations"
        :field-settings="options.duration"
        @update:model-value="
          (value) => changeAdvancedFilters(value, 'durations')
        "
      />
      <!-- Sessions -->
      <label class="block font-semibold mt-6" for="internship-session"
        >Session</label
      >
      <BaseInput
        id="internship-session"
        v-model="filter.session"
        :field-settings="options.whenStarts"
        @update:model-value="(value) => changeAdvancedFilters(value, 'session')"
      />
      <!-- cities -->
      <label class="block font-semibold mt-6" for="internship-cities"
        >Cities</label
      >
      <BaseInput
        id="internship-cities"
        v-model="filter.cities"
        :field-settings="options.cities"
        @update:model-value="(value) => changeAdvancedFilters(value, 'cities')"
      />
      <!-- Countries -->
      <label class="block font-semibold mt-6" for="internship-countries"
        >Countries</label
      >
      <BaseInput
        id="internship-countries"
        v-model="filter.countries"
        :field-settings="options.countries"
        :updated-by-other-component="true"
        @update:model-value="
          (value) => changeAdvancedFilters(value, 'countries')
        "
      />
    </div>
  </FilterModal>
</template>
