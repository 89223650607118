<script setup>
import MobileIcon from "@/components/svg-icons/MobileIcon.vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import PartyPopperIcon from "@/components/svg-icons/PartyPopperIcon.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import v3FormTracker from "@/services/V3FormTracker.js";
import { defineProps, computed, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { V3_stepCanBeReturned } from "@/composables/VersionHelper.js";

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "You did it! 🥳🎉",
  },
  subtitle: {
    type: String,
    required: false,
    default:
      "Expect to hear back from us via email at [STUDENT EMAIL] within a week with more information. We can’t wait to start this journey together!",
  },
  message: {
    type: String,
    required: false,
    default: "",
  },
  infoMessage: {
    type: String,
    required: false,
    default: "If you have any questions contact your Program Coordinator.",
  },
  variant: {
    type: String,
    required: false,
    default: "studyAbroad",
    options: ["studyAbroad", "internship"],
  },
  designTheme: {
    type: String,
    required: false,
    default: "default",
    options: ["default", "mobileScreen"],
  },
});

const router = useRouter();
const store = useStore();
const currentUser = computed(() => store.state.currentUser);
const program = computed(() => store.state.programSessionDataV3);
const applicationId = computed(() => store.state.currentApplicationId);
const continueButton = ref(null);
const redirectIOSButton = ref(null);
const redirectAndroidButton = ref(null);
const submissionError = ref("");

const isInternship = computed(() => {
  return props.variant === "internship";
});

const isMobileTheme = computed(() => {
  return props.designTheme === "mobileScreen";
});

const subtitleText = computed(() => {
  if (isMobileTheme.value) {
    return isInternship.value
      ? "You will hear back from us within a week to set up your Career Coaching Session!"
      : "You will hear back from us within a week on your acceptance!"; // abroad, other
  }

  // default
  return props.subtitle.replace(
    "[STUDENT EMAIL]",
    userEmail.value ?? "student email"
  );
});

const userEmail = computed(() => {
  return currentUser?.value.email;
});

const getManagerName = computed(() => {
  return program.value.program_manager &&
    program.value.program_manager.length > 1
    ? program.value.program_manager
    : "API Enrollment Management Team";
});

const getMessage = computed(() => {
  if (props.message) {
    return props.message;
  }
  if (isMobileTheme.value) {
    // Both internship and other options
    return "In the meantime, get started with onboarding in the APIConnect mobile app.\n\nWe can’t wait to start this journey together!";
  }

  return isInternship.value
    ? "Get a head start with your onboarding materials such as your resume and cover letter by clicking below."
    : "Get a head start with your onboarding materials by clicking below."; // abroad, other
});

const submitNextSteps = async () => {
  continueButton.value.startLoading();
  try {
    const response = await v3FormTracker.setAsCompleted(
      "NextSteps",
      applicationId.value
    );

    // All ok, redirect
    if (response?.id !== undefined) {
      store.commit("setStepCompletedV3", "NextSteps");
      const url = `/enrollment/${applicationId.value}`;
      window.location.href = url;
    }
  } catch (e) {
    submissionError.value = e;
    continueButton.value.stopLoading();
  }
};

async function canViewForm() {
  // View form if it's next step or was completed previously
  let canViewForm = await V3_stepCanBeReturned("NextSteps");
  if (!canViewForm) {
    // Go to About You
    router.push({
      name: "application-about-you",
      params: { applicationId: applicationId.value },
    });
  }
}

onBeforeMount(async () => {
  await canViewForm();
});
</script>

<template>
  <div
    class="font-montserrat text-indigo-base text-center bg-white w-full px-4 pb-4 mx-auto max-w-2xl sm:px-8 sm:pb-8 mt-4"
  >
    <div class="flex flex-col items-center gap-6">
      <template v-if="isMobileTheme">
        <PartyPopperIcon class="xs:hidden md:flex" />
        <MobileIcon class="md:hidden" />
      </template>
      <template v-else>
        <!-- Default -->
        <PartyPopperIcon />
      </template>

      <h1 class="font-bold text-3xl uppercase">
        {{ props.title }}
      </h1>
      <h2 class="font-semibold text-xl">
        {{ subtitleText }}
      </h2>
      <p class="whitespace-pre-line">
        {{ getMessage }}
      </p>
    </div>
    <div class="flex flex-col mt-8 items-center">
      <template v-if="isMobileTheme">
        <ButtonWithSpinner
          id="redirectIOSButton"
          ref="redirectIOSButton"
          class="mb-4"
          data-cy="redirectIOSButton"
          tag="a"
          href="https://apps.apple.com/us/app/api-abroad/id6448320549"
          variant="secondary"
          variant-type="block"
          :grey-disabled-class="true"
        >
          <div
            class="flex items-center justify-center min-h-[58px] md:min-h-[60px]"
          >
            <span class="pr-3 uppercase">
              Download <span class="lowercase">i</span>OS APP
            </span>
            <ArrowRight custom-class="w-4 h-4" />
          </div>
        </ButtonWithSpinner>
        <ButtonWithSpinner
          id="redirectAndroidButton"
          ref="redirectAndroidButton"
          class="mt-4"
          data-cy="redirectAndroidButton"
          tag="a"
          href="https://play.google.com/store/apps/?hl=en_US&gl=US"
          variant="secondary"
          variant-type="block"
          :grey-disabled-class="true"
        >
          <div
            class="flex items-center justify-center min-h-[58px] md:min-h-[60px]"
          >
            <span class="pr-3 uppercase">
              Download Android App
            </span>
            <ArrowRight custom-class="w-4 h-4" />
          </div>
        </ButtonWithSpinner>
      </template>
      <template v-else>
        <!-- Default -->
        <ButtonWithSpinner
          id="continueButton"
          ref="continueButton"
          data-cy="continueButton"
          variant="secondary"
          variant-type="block"
          button-height="min-h-[58px] md:min-h-[60px] mt-4"
          :grey-disabled-class="true"
          @click="submitNextSteps"
        >
          <div class="flex items-center justify-center">
            <span class="pr-3 uppercase">
              Continue
            </span>
            <ArrowRight custom-class="w-4 h-4" />
          </div>
        </ButtonWithSpinner>
      </template>
      <div v-if="submissionError" class="error text-error-900 mt-2">
        {{ submissionError }}
      </div>
    </div>
    <div class="flex flex-col p-4 gap-2 mt-8 bg-[#E3F8F8]">
      <p>{{ props.infoMessage }}</p>
      <p>
        <span>{{ getManagerName }}: </span>
        <a
          href="mailto:support-other@apiexperience.com"
          :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
          >support-other@apiexperience.com</a
        >
      </p>
    </div>
  </div>
</template>
