import programSessionsService from "@/services/programSessions";
import { useAsyncState } from "@vueuse/core";

export const useGetProgramSession = (asyncStateOptions, defaultId) => {
  return useAsyncState(
    (id, extraParams = {}) =>
      programSessionsService
        .getProgramSession({ id: defaultId || id, params: extraParams })
        .then((val) => {
          return val?.data?.data || {};
        }),
    {},
    asyncStateOptions
  );
};
