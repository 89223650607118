<script setup>
import * as Sentry from "@sentry/vue";
import AccountCreationForm from "@/components/auth/AccountCreation/AccountCreationForm.vue";
import { computed, inject, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useOrder } from "@/composables/useOrder";
import { useHousingQuestionnaire } from "@/composables/useHousingQuestionnaire";
import ordersService from "@/services/orders.js";
import _ from "lodash";
import applicationService from "@/services/application.js";
import { useProgramSelectionData } from "@/composables/useProgramSelectionData.js";
import formService from "@/services/form";
import { useToast } from "vue-toast-notification";
import { ERROR_TIMEOUT } from "@/constants.js";

const updateStepAndRedirect = inject("updateStepAndRedirect", {});

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const submittedProgramSelectionFormId = ref(null);
const submittedOrderId = ref(null);

const { createSubmissionDataForFormIo } = useProgramSelectionData();
const directProgramData = computed(() => store.state.directProgramData);
const firstTimeApplying = computed(() => store.state.newAccountAndApplication);
const applicationId = computed(() => store.state.currentApplicationId);
const program = computed(() => store.getters["configurator/getCurrentProgram"]);
const housing = computed(
  () => store.getters["configurator/getHousingQuestionnaire"]
);
const currentOrder = computed(() => store.state.configurator.currentOrder);
const oktaId = computed(() => {
  return store.state.currentUser?.participantId ?? "";
});

const configuratorIncomplete = computed(
  () => store.getters["configurator/getConfiguratorIncomplete"]
);

const { prepOrderData } = useOrder();
const { createOrUpdatedHousingQuestionnaire } = useHousingQuestionnaire();
const logo = ref(process.env.MIX_CDN_ENDPOINT + "/images/api-connect-logo.png");
const orderFailed = ref(false);

const redirectSessionData = computed(
  () => store.getters.getRedirectSessionData
);
const hasRedirectData = computed(
  () =>
    redirectSessionData.value.programId && redirectSessionData.value.sessionId
);

const isFacultyLed = computed(() => {
  return (
    program.value?.programSession?.channel?.name?.toLowerCase() === "custom"
  );
});

const showPersonalizedAccountCreationMessage = computed(() => {
  return (
    route.path.startsWith("/application-create-account") && !isFacultyLed.value
  );
});

const sessionV3Data = computed(() => {
  return store.state.programSessionDataV3;
});

onMounted(() => {
  if (
    !localStorage.getItem("primaryColor") &&
    localStorage.getItem("branding")
  ) {
    toast.open({
      message:
        "Custom branding failed to load. Please try refreshing the page or contact support for assistance at productsupport@apiexperience.com.",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  }
});

const submitProgramSelectionForm = async (formioData) => {
  // Submit Program Selection Form
  const formSubmission = await formService.createOrUpdateSubmission(
    "programselection",
    formioData,
    submittedProgramSelectionFormId.value
  );

  submittedProgramSelectionFormId.value = formSubmission._id;
};

const afterCreationAction = async () => {
  if (!_.isEmpty(directProgramData.value)) {
    // Customized Program
    window.location.href = "/program/" + directProgramData.value.salesforce_id;
  } else if (firstTimeApplying.value) {
    try {
      let orderResponse = null;
      orderFailed.value = false;
      // V3: Create account by trying to apply through Experience landing page
      // Student should have application id at this point

      //create draft order
      const orderData = await prepOrderData(
        applicationId.value,
        program.value.programSession,
        currentOrder.value
      );

      if (submittedOrderId.value) {
        orderResponse = await ordersService.updateOrder({
          order_id: submittedOrderId.value,
          payload: orderData,
          session: program.value?.programSession,
          callingLocation: "Account Creation"
        });
      } else {
        orderResponse = await ordersService.createOrder({
          payload: orderData,
        });
      }

      const orderID = orderResponse?.data?.data?.order?.id ?? "";

      if (!orderID) {
        throw "Missing order id for application creation";
      }
      submittedOrderId.value = orderID;

      let formioData = createSubmissionDataForFormIo(
        sessionV3Data.value,
        applicationId.value
      );

      // update contract number with contract found in order
      if (orderResponse?.data?.data?.order?.billing_contract_id)
        formioData.data.contractNumber =
          orderResponse?.data?.data?.order?.billing_contract_id;

      await submitProgramSelectionForm(formioData);

      await store.dispatch(
        "configurator/setCurrentOrder",
        orderResponse.data.data.order
      );

      //save order ID to connect db
      try {
        await applicationService.createV3Application({
          application_id: applicationId.value,
          order_id: orderID,
          program_id: currentOrder.value.program_page_id,
          configurator_completed: !configuratorIncomplete.value,
        });
      } catch (error) {
        Sentry.captureException(
          new Error(
            "Account creation step in configurator error (Connect Application)."
          ),
          {
            tags: {
              error: error.message,
              order_id: orderID,
            },
          }
        );
      }

      //Send housing questionnaire to profile service
      try {
        await createOrUpdatedHousingQuestionnaire(
          oktaId.value,
          orderID,
          applicationId.value,
          housing.value
        );
      } catch (error) {
        Sentry.captureException(
          new Error(
            "Account creation step in configurator error (Housing Questionnaire)."
          ),
          {
            tags: {
              error: error.message,
              order_id: orderID,
            },
          }
        );
      }

      //clear housing in vuex
      await store.dispatch("configurator/resetHousingQuestionnaire");
      await store.dispatch("configurator/resetConfiguratorIncomplete");
      await store.dispatch("configurator/setSaveForLater", false);

      // Set profile data in vuex
      if (oktaId.value) {
        await store.dispatch("getProfile", oktaId.value);
      }

      if (typeof updateStepAndRedirect === "function") {
        updateStepAndRedirect("application-about-you", applicationId.value);
      } else {
        router
          .push({
            name: "create-account-success",
            query: { new_application: true },
          })
          .then(() => router.go());
      }
    } catch (error) {
      orderFailed.value = true;
      Sentry.captureException(
        new Error("Account creation step in configurator error."),
        {
          tags: {
            error: error.message,
          },
        }
      );
    }
  } else if (hasRedirectData.value) {
    const redirectURL = `/program-page/${redirectSessionData.value.programId}?session-id=${redirectSessionData.value.sessionId}`;
    store.commit("setRedirectSessionData", {
      programId: null,
      sessionId: null,
    });
    window.location.href = redirectURL;
  } else {
    // V3: Create account
    router.push("/create-account-success").then(() => router.go());
  }
};
</script>
<template>
  <div
    class="font-montserrat text-indigo-base text-left bg-white w-full p-8 pt-4 pb-4 md:px-0 lg:px-0 mb-24"
    :class="{
      'max-w-4xl': $route.path !== '/create-account',
      'max-w-2xl': $route.path === '/create-account',
    }"
  >
    <div
      v-if="$route.path === '/create-account'"
      class="flex justify-center py-10"
    >
      <img :src="logo" class="h-8 object-contain logo" alt="API" />
    </div>
    <p v-else-if="showPersonalizedAccountCreationMessage" class="pb-4">
      You personalized your experience! Now it's time to create an account,
      apply, and your information will be saved.
    </p>
    <p>
      Already have an account?
      <a
        :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
        href="/sign-in"
      >
        Log in here.
      </a>
    </p>

    <AccountCreationForm
      :order-failed="orderFailed"
      @after-creation-action="afterCreationAction"
    />
  </div>
</template>
