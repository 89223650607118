<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { defineProps } from "vue";

const {
  fieldLabel,
  fieldLabelClasses,
  inputClasses,
  expandedByDefault,
  disclosureButtonClasses,
  unmount,
} = defineProps({
  disclosureButtonClasses: {
    type: String,
    default: "",
  },
  fieldLabel: {
    type: String,
    default: "",
  },
  fieldLabelClasses: {
    type: String,
    default: "",
  },
  inputClasses: {
    type: [String, Array],
  },
  expandedByDefault: {
    type: Boolean,
    default: true,
  },
  unmount: {
    type: Boolean,
    default: true,
  },
});
</script>
<template>
  <Disclosure as="div" :default-open="expandedByDefault" v-bind="$attrs">
    <div class="w-full">
      <div class="flex w-full">
        <DisclosureButton
          v-slot="{ open }"
          :class="[
            'text-sm font-semibold text-gray-900 uppercase flex flex-1 items-center cursor-pointer',
            disclosureButtonClasses,
          ]"
        >
          <ChevronRightIcon
            :class="[{ 'rotate-90 transform': open }, 'h-6 w-6 inline-block']"
          />
          <slot name="fieldLabel" :open="open">
            <div class="flex-1">
              <div class="flex justify-start items-center">
                <span :class="fieldLabelClasses">{{ fieldLabel ?? "" }}</span>
              </div>
            </div>
          </slot>
        </DisclosureButton>
        <slot name="button"></slot>
      </div>
    </div>
    <DisclosurePanel
      :class="[
        'border border-gray-300 rounded-sm p-8 bg-blue-300',
        inputClasses,
      ]"
      :unmount="unmount"
    >
      <slot name="content"></slot>
    </DisclosurePanel>
  </Disclosure>
</template>
