<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-gray-700"
      >
        <slot />
      </MenuButton>
    </div>
    <MenuItems
      class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -top-10 right-full bottom-auto"
    >
      <div>
        <Checkbox
          v-if="renderCheckboxList"
          :options="options"
          :max-selected-headers="maxHeaders"
        />
        <MenuItem v-for="option in options" v-else :key="option.name">
          <router-link
            v-if="option.type === 'navigateTo'"
            :to="option.routeTo"
            class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200"
          >
            {{ option.name }}
          </router-link>
          <div
            v-else-if="option.type === 'dispatch'"
            class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
            @click="
              $store.dispatch(`programManager/${option.event}`, option.payload)
            "
          >
            {{ option.name }}
          </div>
          <div
            v-else
            class="text-gray-600 block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
          >
            {{ option.name }}
          </div>
        </MenuItem>
      </div>
    </MenuItems>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import Checkbox from "@/components/shared/Table/Checkbox.vue";

export default {
  name: "DropdownList",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Checkbox,
  },
  inject: ["maxHeaders"],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    event: {
      type: String,
      default: "",
    },
    renderCheckboxList: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
