<template class="grid grid-cols-1 gap-6 xs:gap-10">
  <div class="min-h-screen items-center">
    <template v-if="isLoading">
      <spinner>
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
        </template>
      </spinner>
    </template>
    <div class="dashboard">
      <div
        class="w-full px-4 pb-4 mx-auto applications max-w-7xl sm:px-8 sm:pb-8"
      >
        <header
          class="my-6 xs:flex xs:flex-col"
          role="banner"
          aria-labelledby="viewtitle"
        >
          <section
            class="w-full xs:flex xs:justify-between xs:items-center mt-3"
          >
            <h1 id="viewtitle" class="text-xl md:text-2xl">
              My Applications
            </h1>
            <button
              :class="[
                secondaryColor
                  ? 'brandingSecondary'
                  : 'bg-university-secondary hover:bg-teal-100 focus:bg-teal-100',
              ]"
              class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white hover:text-white focus:text-white flex items-center justify-center w-full mt-4 xs:w-auto xs:mt-0"
              data-cy="new-application-button"
              @click="redirectToResults"
            >
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus-circle mr-2"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="8" x2="12" y2="16" />
                <line x1="8" y1="12" x2="16" y2="12" />
              </svg>
              {{ applicationLabel }}
            </button>
          </section>
        </header>
        <div
          class="grid grid-cols-1 gap-6 xs:gap-10"
          role="region"
          aria-labelledby="appsregion"
        >
          <span id="appsregion" class="hidden">Applied Applications</span>
          <div
            v-for="application in validApplications"
            :key="application.id"
            class="sm:flex sm:items-stretch"
          >
            <div
              class="w-full overflow-hidden bg-gray-100 bg-gray-300 bg-center bg-no-repeat rounded-t-lg sm:flex-none sm:rounded-l-lg sm:rounded-tr-none sm:w-1/2 md:w-2/5"
              style="background-image: url(../../img/image-lg.svg);"
            >
              <img
                class="sm:object-cover sm:w-full sm:h-full"
                loading="lazy"
                :src="
                  getProgramImage(
                    application.programSession.program?.site?.city
                  )
                "
                alt="[SHORT IMAGE DESCRIPTION]"
              />
            </div>
            <div
              class="flex-auto p-6 bg-white border border-t-0 border-gray-300 rounded-bl-lg rounded-br-lg md:p-8 lg:p-12 sm:border-t sm:border-l-0 sm:rounded-bl-none sm:rounded-tr-lg"
            >
              <h2
                class="mb-2 text-base font-semibold xs:text-lg sm:text-xl md:mb-6"
              >
                {{ application.programSession.program?.name }}
              </h2>
              <p
                v-if="application.programSession.program?.site"
                class="flex items-center text-gray-600 md:mb-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-map-pin"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                <span class="ml-2">
                  {{ application.programSession.program?.site?.city }},
                  {{ application.programSession.program?.site?.country }}
                </span>
              </p>
              <program-dates
                :id="application.id"
                :dates="
                  buildStringDateRange(
                    application.programSession.start_date,
                    application.programSession.end_date
                  )
                "
                :start-is-final="
                  application.programSession.start_date_is_final > 0
                "
                :end-is-final="application.programSession.end_date_is_final > 0"
                tag="p"
                class="text-gray-600"
              />
              <div
                v-if="shouldShowV2AppDeprecationMessage(application)"
                class="flex flex-row items-start gap-2 mt-6 md:mt-16"
              >
                <img src="/images/icon-attention.svg" />
                <span>
                  APIConnect has undergone an update to modernize and streamline
                  the student application experience. You’ll need to start a new
                  application – please click
                  <a
                    href="/results/programs"
                    class="text-teal-500 cursor-pointer"
                    >here</a
                  >
                  to begin.
                </span>
              </div>
              <div v-else class="mt-6 md:flex md:items-center md:mt-16">
                <div class="flex items-center justify-between sm:flex-auto">
                  <p class="text-xl font-semibold" />
                  <div
                    class="flex flex-col md:flex-row md:justify-end md:items-center"
                  >
                    <p class="text-sm text-gray-600 md:mb-0 md:mx-6">
                      Last saved
                      {{
                        formatDateShort(lastModified(application.submissions))
                      }}
                    </p>
                  </div>
                </div>
                <!-- TODO: Delete this, program change feature isn't used -->
                <template
                  v-if="
                    application.programChange.requestedChange &&
                    !application.programChange.isNewProgram
                  "
                >
                  <button
                    v-if="
                      application.programChange.changeIsPending &&
                      !application.programChange.isNewProgramSelected
                    "
                    :class="[
                      tertiaryColor
                        ? `${tertiaryColorClass}`
                        : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
                    ]"
                    class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-gray-700 w-full mt-2 md:mt-0 md:w-auto"
                    @click="
                      redirectProgramChanges(
                        application.programChange,
                        application.uiVersion
                      )
                    "
                  >
                    Resume Program Change
                  </button>
                  <p
                    v-else
                    class="text-error-900"
                    @click="
                      redirectContinueApplication(
                        application.id,
                        application.programSession.salesforce_id,
                        application.programChange,
                        application.uiVersion,
                        application.bulkEnrollmentId,
                        application.configuratorCompleted,
                        application.programPageId,
                        application.orderId
                      )
                    "
                  >
                    Application Withdrawn
                  </p>
                </template>
                <template
                  v-else-if="application.enrollmentStatus === 'Withdrawn'"
                >
                  <p
                    class="text-error-900"
                    @click="
                      redirectContinueApplication(
                        application.id,
                        application.programSession.salesforce_id,
                        application.programChange,
                        application.uiVersion,
                        application.bulkEnrollmentId,
                        application.configuratorCompleted,
                        application.programPageId,
                        application.orderId
                      )
                    "
                  >
                    Application Withdrawn
                  </p>
                </template>
                <template v-else>
                  <button
                    :data-cy="application.id"
                    :class="[
                      tertiaryColor
                        ? `${tertiaryColorClass}`
                        : `${tertiaryColorClass} hover:bg-yellow-900 focus:bg-yellow-900`,
                    ]"
                    class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-gray-700 w-full mt-2 md:mt-0 md:w-auto"
                    @click="
                      redirectContinueApplication(
                        application.id,
                        application.programSession.salesforce_id,
                        application.programChange,
                        application.uiVersion,
                        application.bulkEnrollmentId,
                        application.configuratorCompleted,
                        application.programPageId,
                        application.orderId
                      )
                    "
                  >
                    {{
                      continueButton(
                        application.id,
                        application.uiVersion,
                        application.bulkEnrollmentId
                      )
                    }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { compareStringsInLowerCase } from "@/util/string";
import { mapGetters, mapState } from "vuex";
import programImages from "../assets/programImages.json";
import { CONSTANT_KEYS } from "../constants.js";
import forms from "../mixins/forms.js";
import { buildStringDateRange } from "../mixins/helpers";
import ProgramDates from "./ProgramDates";
import Spinner from "./helpers/Spinner";
import ordersService from "../services/orders";
import * as Sentry from "@sentry/vue";
import { datadogLogs } from "@datadog/browser-logs";
import { ORDER_STATUS } from "@/constants";

export default {
  name: "MyApplications",
  components: {
    "program-dates": ProgramDates,
    Spinner,
  },
  mixins: [forms],
  data() {
    return {
      isLoading: true, //show loaders
      count: 0, //keep track of number of times watch has runned
      message: "",
      orders: [],
    };
  },
  computed: {
    ...mapState([
      "formioToken",
      "studentApplications",
      "studentFormioSubmissions",
      "currentApplicationId",
      "userData",
      "currentUser",
    ]),
    ...mapGetters({
      permissions: "getPermissions",
    }),
    applicationLabel() {
      if (CONSTANT_KEYS?.UI_VERSION === "v3") {
        return "Search for a New Program";
      } else {
        return "Start New Application";
      }
    },
    participantId() {
      return this.currentUser.participantId;
    },
    v2Applications() {
      return this.studentApplications.filter(
        (application) => application?.uiVersion === "v2"
      );
    },
    v3Applications() {
      return this.studentApplications.filter(
        (application) => application?.uiVersion === "v3"
      );
    },
    applicationsWithOrders() {
      return this.orders
        .map((order) => {
          const matchingApp = this.v3Applications.find(
            (app) => app.id === order.application_id
          );
          return {
            ...matchingApp,
            orderId: order?.id,
            programPageId: order?.program_page_id,
            configuratorCompleted:
              matchingApp?.configuratorCompleted ??
              order?.status !== ORDER_STATUS.draft,
          };
        })
        .filter((app) => Boolean(app?.id));
    },
    validApplications() {
      return [...this.applicationsWithOrders, ...this.v2Applications];
    },
  },
  async created() {
    // if (!Object.keys(this.userData).length) {
    //   this.$router.push({
    //     name: "landing-page",
    //   });
    // }
    let resetPayload = {
      userData: this.userData,
      currentUser: this.currentUser,
    };

    this.$store.commit("reset", resetPayload);
    this.$store.commit("resetPreviousApplicationState");
    this.message = "Loading student applications.";

    //Prepare initial data
    const asyncResults = await Promise.allSettled([
      this.$store.dispatch("initialSetUp"),
      this.$store.dispatch("getEnrollmentData").then(() =>
        this.$store
          .dispatch("getStudentApplications")
          .then(() => this.fetchOrders())),
      this.$store.dispatch("getFormioSubmissions"),
    ]);

    for (const result of asyncResults) {
      if (!result.status === "fulfilled") {
        console.error(result.reason);
        Sentry.captureException(result.reason);
        datadogLogs.logger.error(
          result.reason?.message ?? "Error while fetching My Applications data",
          { user: this.currentUser },
          result.reason
        );
      }
    }

    this.isLoading = false;
  },

  methods: {
    async fetchOrders() {
      this.orders = (
        await ordersService.list({
          participant_id: this.participantId,
          get_withdrawn: true,
        })
      ).map((res) => res.order);
    },
    exitImpersonation() {
      this.$store.dispatch("exitImpersonation");
    },
    setPreviousApplicationData(submission) {
      // Setting up data
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "previousApplicationId",
        value: submission.data.data["application_id"],
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "session",
        value: submission.data.data["session"],
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "paidFee",
        value:
          Object.hasOwnProperty.call(submission.data.data, "applicationPaid") &&
          submission.data.data["applicationPaid"],
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "applicationFee",
        value: Object.hasOwnProperty.call(
          submission.data.data,
          "applicationFee"
        )
          ? submission.data.data["applicationFee"]
          : 0,
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "transactionId",
        value: Object.hasOwnProperty.call(submission.data.data, "transactionId")
          ? submission.data.data["transactionId"]
          : "",
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "submissionId",
        value: submission.data["_id"],
      });
    },
    redirectProgramChanges(submission, version) {
      this.$store.commit("setUiVersion", version);
      this.setPreviousApplicationData(submission);

      // Redirect
      this.$router.push({
        name: "applications/new",
        params: {
          slug: "program-selection",
        },
      });
    },
    buildStringDateRange,
    shouldShowV2AppDeprecationMessage(application) {
      return (
        compareStringsInLowerCase(application.uiVersion, "v2") &&
        !application.programSession?.program?.customized &&
        !application.applicationPaid &&
        !compareStringsInLowerCase(application.enrollmentStatus, "Withdrawn")
      );
    },
    continueButton(applicationId, uiVersion, bulkId) {
      if (this.formIsComplete(applicationId, uiVersion) || bulkId) {
        return "Continue Enrollment";
      } else {
        return "Continue Application";
      }
    },
    redirectContinueApplication(
      applicationId,
      programId,
      programChange,
      version,
      bulkId,
      configuratorCompleted,
      programPageId,
      orderId
    ) {
      this.$store.commit("setSelectedProgramId", programId);
      this.$store.dispatch("getProgramData", this.$apollo);
      this.$store.commit("setCurrentApplicationId", applicationId);
      this.$store.commit("setUiVersion", version);

      if (programChange.requestedChange) {
        this.setPreviousApplicationData(programChange);
      }

      if (this.formIsComplete(applicationId, version) || bulkId) {
        this.$router.push({
          name: "applications/landing",
          params: {
            applicationId: applicationId,
          },
        });
      } else if (!configuratorCompleted && programPageId && orderId) {
        this.$router.push({
          name: "configurator",
          params: { programPageId: programPageId, orderId: orderId },
        });
      } else if (version === "v3") {
        this.$router.push({
          name: "application-resume",
          params: {
            applicationId: applicationId,
          },
        });
      } else {
        this.$router.push({
          name: "applications/resume",
          params: {
            applicationId: applicationId,
          },
        });
      }
    },
    formatDateShort(dateParam) {
      let date = new Date(dateParam);
      date =
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
      return date;
    },
    getProgramImage(city) {
      let programImage = this.fallbackImage();
      programImages.forEach((item) => {
        if (item.image && city === item.city) {
          programImage = item.image;
        }
      });
      return programImage;
    },
    fallbackImage() {
      return "https://api-abroad.sfo2.cdn.digitaloceanspaces.com/images/_1200x675_crop_center-center_82_line/england-london-big-ben-parliament-river-sunset-230637613.jpg";
    },
    lastModified(submissions) {
      return submissions
        .map((o) => o["modified"])
        .reduce((accumulator, current) => {
          return current > accumulator ? current : accumulator;
        });
    },
    async redirectToResults() {
      await this.$store.commit("setUiVersion", CONSTANT_KEYS.UI_VERSION);
      if (CONSTANT_KEYS.UI_VERSION === "v3") {
        // Simulate the behavior of an anchor tag's redirect to load experecience page in its own layuout
        window.location.href = "/results";
      } else {
        this.$router.push({ path: "/applications/new" });
      }
    },
  },
};
</script>
