<template>
  <div class="font-semibold">
    <p class="mb-4">
      <span class="text-red-100">**</span>
      {{ AMENITIES_TEXT }}
    </p>
    <div
      v-for="{ id, title } in housingLevelAmenities"
      :key="id"
      class="flex justify-between w-fit items-center mb-3"
    >
      <input
        :id="id"
        :checked="stateCheckbox(id)"
        type="checkbox"
        class="h-4 w-4 form-checkbox border-gray-300 mr-10"
        @input="updateHousingAmenitiesValue(id, $event)"
      />
      <label :for="id">{{ title }}</label>
      <div v-if="stateCheckbox(id)" class="ml-16">
        <label :for="`price_${id}`" class="mr-2">Price: $</label>
        <input
          :id="`price_${id}`"
          type="text"
          :value="defaultPriceInput(id)"
          class="form-input"
          maxlength="10"
          @input="updatePriceInput(id, $event.target.value)"
        />
      </div>
    </div>
    <div
      class="flex justify-left w-fit items-center flex-wrap mb-3 sm:justify-between"
    >
      <input
        id="meal_plan"
        :checked="mealPlanCheckBox"
        type="checkbox"
        class="h-4 w-4 form-checkbox border-gray-300 mr-10"
        @input="closeMealPlanCheckBox($event)"
      />
      <label for="meal_plan" class="mr-5">Meal Plan</label>
      <v-select
        v-if="openMealPlanSelect"
        id="meal_plan_select"
        :model-value="mealPlanDefaultSelected"
        :options="mealPlanAmenities"
        label="title"
        item-value="id"
        class="styled-v-select w-72 xs:w-382px font-normal"
        @update:modelValue="changeSelectedMealPlan($event)"
      ></v-select>
      <div v-if="openMealPlanSelect" class="ml-16">
        <label class="mr-2">Price: $</label>
        <input
          type="text"
          :value="defaultPriceInput(selectedMealPlan)"
          :disabled="!selectedMealPlan"
          class="form-input"
          :class="{ 'hover:cursor-not-allowed bg-blue-100': !selectedMealPlan }"
          maxlength="10"
          @input="updatePriceInputMealPlan($event.target.value)"
        />
      </div>
    </div>
    <div class="flex justify-between w-fit items-center">
      <input
        id="none"
        type="checkbox"
        :checked="isCurrentEditingAmenitiesEmpty"
        class="h-4 w-4 form-checkbox border-gray-300 mr-10"
        @input="clearCurrentAmenities($event)"
      />
      <label for="none">None applicable</label>
    </div>
    <p class="font-semibold my-4">
      {{ ACCESSIBILITY_TEXT }}
    </p>
    <div
      v-for="{ title, id } in accessibilityAmenities"
      :key="id"
      class="flex justify-between w-fit items-center mb-3"
    >
      <input
        :id="id"
        :checked="stateCheckbox(id)"
        type="checkbox"
        class="h-4 w-4 form-checkbox border-gray-300 mr-10"
        @input="updateHousingAmenitiesValue(id, $event)"
      />
      <label :for="id">{{ title }}</label>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import { AMENITIES_CATEGORIES, AMENITIES_GRANULARITIES } from "@/constants";

const ACCESSIBILITY_TEXT =
  "Select all the accessibility & inclusivity information that applies to this building/housing option";
const AMENITIES_TEXT =
  "Select all the building Amenities that applies to this building/housing options";

const store = useStore();

const amenitiesList = store.getters["programManager/getAllAmenities"];

const housingLevelAmenities = [];
const accessibilityAmenities = [];
const mealPlanAmenities = [];
amenitiesList.forEach((amenity) => {
  if (amenity.granularity_level === AMENITIES_GRANULARITIES.HOUSING_LEVEL) {
    if (amenity.category === AMENITIES_CATEGORIES.AMENITIES)
      housingLevelAmenities.push(amenity);
    if (amenity.category === AMENITIES_CATEGORIES.ACCESSIBILITY_AND_INCLUSIVITY)
      accessibilityAmenities.push(amenity);
    if (amenity.category === AMENITIES_CATEGORIES.MEAL_PLAN)
      mealPlanAmenities.push(amenity);
  }
});

const currentEditingHousingAmenities = computed(
  () => store.getters["programManager/getCurrentEditingHousingLevelAmenities"]
);

const isCurrentEditingAmenitiesEmpty = computed(
  () =>
    !(currentEditingHousingAmenities.value.length || openMealPlanSelect.value)
);

const stateCheckbox = (id) => {
  return currentEditingHousingAmenities.value.some(
    (el) => el.amenities_id === id
  );
};

const formatDecimalNumber = (newVal) => {
  const formatedValue = `${newVal}`.padStart(4, "0");
  return newVal ? formatedValue.replace(/([0-9]{2})$/g, ".$1") : null;
};

const defaultPriceInput = (id) => {
  return formatDecimalNumber(
    currentEditingHousingAmenities.value.find((el) => el.amenities_id === id)
      ?.cost
  );
};

const mealPlanDefaultSelected = computed(() =>
  mealPlanAmenities.find(({ id }) =>
    currentEditingHousingAmenities.value.some(
      (el) => el.amenities_id === parseInt(id)
    )
  )
);
const selectedMealPlan = ref(mealPlanDefaultSelected.value?.id);

const mealPlanCheckBox = computed(() => !!mealPlanDefaultSelected.value);
const openMealPlanSelect = ref(mealPlanCheckBox.value);

const changeSelectedMealPlan = (value) => {
  const payload = [
    ...currentEditingHousingAmenities.value.filter(
      (el) => el.amenities_id !== selectedMealPlan.value
    ),
    ...(value?.id ? [{ amenities_id: value.id }] : []),
  ];
  store.commit("programManager/setCurrentEditingHousingAmenities", payload);
  if (!value?.id) {
    openMealPlanSelect.value = false;
  }
  selectedMealPlan.value = value?.id;
};

const closeMealPlanCheckBox = ($event) => {
  openMealPlanSelect.value = $event.target.checked;
  if (!$event.target.checked) {
    const payload = [
      ...currentEditingHousingAmenities.value.filter(
        (el) => el.amenities_id !== selectedMealPlan.value
      ),
    ];
    store.commit("programManager/setCurrentEditingHousingAmenities", payload);
    selectedMealPlan.value = undefined;
  }
};

const updatePriceInput = (amenities_id, value) => {
  const cost = value ? parseInt(`0${value.replace(/[\D]+/g, "")}`) : null;
  const payload = currentEditingHousingAmenities.value.map((el) =>
    el.amenities_id === amenities_id ? { ...el, cost } : el
  );
  store.commit("programManager/setCurrentEditingHousingAmenities", payload);
};

const updatePriceInputMealPlan = (cost) => {
  const amenities_id = selectedMealPlan.value;
  updatePriceInput(amenities_id, cost);
};

const updateHousingAmenitiesValue = (id, event) => {
  const payload = event.target.checked
    ? [...currentEditingHousingAmenities.value, { amenities_id: id }]
    : currentEditingHousingAmenities.value.filter(
        (el) => el.amenities_id !== id
      );
  store.commit("programManager/setCurrentEditingHousingAmenities", payload);
};
const clearCurrentAmenities = (event) => {
  if (event.target.checked) {
    const filteredAmenities = currentEditingHousingAmenities.value.filter(
      (el) => !housingLevelAmenities.some(({ id }) => id === el.amenities_id)
    );
    store.commit(
      "programManager/setCurrentEditingHousingAmenities",
      filteredAmenities
    );
  }
};
</script>
<style>
#meal_plan_select .vs__dropdown-option {
  width: max-content;
}
</style>
