<template>
  <div>
    <p>
      Already have an account?
      <router-link
        id="sign-in-link"
        :to="{ path: '/sign-in' }"
        :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
      >
        <span> Log in here</span>
      </router-link>
    </p>
    <p>
      <a
        id="create-account-link"
        href="/create-account"
        :class="`${secondaryColorClassLinks} font-semibold hover:underline`"
      >
        Create an account
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "AlreadyHaveAccount",
};
</script>
