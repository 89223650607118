<template>
  <FormPanel
    :id="'payment-type'"
    :title="'Payment Type'"
    class="mt-8"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <div class="flex flex-col md:flex-row md:justify-between">
        <div
          v-for="(paymentType, index) in paymentTypes"
          :key="paymentType.value"
          class="mt-3 w-full md:w-1/3"
          :class="[
            { 'mr-3': index !== paymentTypes.length - 1 },
            index ? 'md:w-1/3' : 'md:w-1/4',
          ]"
        >
          <input
            :id="paymentType.value"
            v-model="value.paymentType"
            type="radio"
            name="paymentType"
            :value="paymentType"
            class="hidden"
          />
          <label
            class="flex flex-col p-5 rounded-md cursor-pointer w-full h-full"
            :class="
              paymentType.value === value.paymentType.value
                ? 'bg-university-primary text-white '
                : 'bg-blue-400 text-gray-650'
            "
            :for="paymentType.value"
          >
            <component :is="paymentType.icon" />
            <span class="text-sm mt-2">
              {{ paymentType.name }}
            </span>
          </label>
        </div>
      </div>
      <div v-if="showStateSelect" class="mt-4 block md:flex">
        <label for="citizenship" class="text-sm w-2/4">
          <span class="text-gray-600 inline-flex justify-between mb-2">
            <span class="font-semibold mr-1">Select Billing State</span>
          </span>
          <VSelect
            id="country"
            v-model="v$.value.state.$model"
            :value="v$.value.state.$model"
            :reduce="(state) => state.label"
            :options="states"
            :get-option-label="(option) => option.label"
            aria-label="States"
            :class="{ 'bg-error-100': v$.value.state.$error }"
            @search="fetchStates"
          ></VSelect>
          <div
            v-if="v$.value.state.required.$invalid && v$.value.state.$error"
            class="error text-error-900"
          >
            Field is required
          </div>
        </label>
      </div>
      <p
        v-if="paymentMessage && !personalizedMessage"
        class="text-sm text-gray-650 mt-4"
      >
        {{ paymentMessage }}
      </p>
      <p v-if="personalizedMessage" class="text-sm text-gray-650 mt-4">
        {{ personalizedMessage }}
      </p>
      <div
        v-if="
          v$.value.paymentType.required.$invalid && v$.value.paymentType.$error
        "
        class="error text-error-900 mt-4"
      >
        Field is required
      </div>
    </template>
  </FormPanel>
</template>

<script>
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import IconBankAccount from "@/components/PaymentRequest/Icons/IconBankAccount.vue";
import IconCreditCard from "@/components/PaymentRequest/Icons/IconCreditCard.vue";
import { PAYMENT_TYPES, SKUS, STATES, STRIPE_TYPES } from "@/constants";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { shallowRef } from "vue";
const exceptionState = [
  "Colorado",
  "Connecticut",
  "Kansas",
  "Maine",
  "Massachusetts",
];

export default {
  name: "PaymentType",
  components: {
    FormPanel,
  },
  props: {
    selectedType: {
      type: Object,
      default: function () {
        return {};
      },
    },
    currentPaymentType: {
      type: [Object, Boolean],
      default: null,
    },
    currentState: {
      type: String,
      default: null,
    },
  },
  emits: ["updateState", "turnFeeOff", "update:payment-type"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: true,
      states: STATES,
      personalizedMessage: false,
      value: {
        paymentType: {},
        state: null,
      },
      paymentTypes: [
        {
          name: PAYMENT_TYPES.US_CREDIT_CARD.name,
          value: PAYMENT_TYPES.US_CREDIT_CARD.value,
          stripe: STRIPE_TYPES.CARD,
          feeFactor: PAYMENT_TYPES.US_CREDIT_CARD.feeFactor,
          sku: SKUS.cc,
          icon: shallowRef(IconCreditCard),
        },
        {
          name: PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.name,
          value: PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.value,
          stripe: STRIPE_TYPES.CARD,
          feeFactor: PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.feeFactor,
          sku: SKUS.cc,
          icon: shallowRef(IconCreditCard),
        },
        {
          name: PAYMENT_TYPES.BANK_ACCOUNT.name,
          value: PAYMENT_TYPES.BANK_ACCOUNT.value,
          stripe: STRIPE_TYPES.US_BANK_ACCOUNT,
          feeFactor: PAYMENT_TYPES.BANK_ACCOUNT.feeFactor,
          sku: SKUS.ach,
          icon: shallowRef(IconBankAccount),
          achFee: PAYMENT_TYPES.BANK_ACCOUNT.achFee,
        },
      ],
    };
  },
  validations: {
    value: {
      paymentType: { required },
      state: {
        required: requiredIf(function () {
          return (
            this.value.paymentType.value === PAYMENT_TYPES.US_CREDIT_CARD.value
          );
        }),
      },
    },
  },
  computed: {
    paymentMessage() {
      let message = "";

      if (
        this.value.paymentType.value === PAYMENT_TYPES.US_CREDIT_CARD.value &&
        typeof this.value.state === "string"
      ) {
        message =
          "* US Credit/debit card payments are subject to a 3% convenience fee. Visa, MasterCard, American Express and Discover are accepted.";
      } else if (
        this.value.paymentType.value ===
        PAYMENT_TYPES.INTERNATIONAL_CREDIT_CARD.value
      ) {
        message =
          "* International Credit/debit card payments are subject to a 4% convenience fee. Visa, MasterCard, American Express and Discover are accepted.";
      } else if (
        this.value.paymentType.value === PAYMENT_TYPES.BANK_ACCOUNT.value
      ) {
        message = "*Paying by ACH is subject to a $3 fee per transaction.";
      }
      return message;
    },
    showStateSelect() {
      return (
        this.value.paymentType.value === PAYMENT_TYPES.US_CREDIT_CARD.value
      );
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:payment-type", this.value);
      },
      deep: true,
      immediate: true,
    },
    "value.state": {
      handler: function (newval) {
        this.$emit("updateState", newval);
        if (newval) {
          this.personalizedMessage = false;
          if (exceptionState.some((state) => state === newval)) {
            this.personalizedMessage =
              "* Visa, MasterCard, American Express and Discover are accepted.";
            this.$emit("turnFeeOff", true);
          } else {
            this.$emit("turnFeeOff", false);
          }
        } else if (typeof newval === "object") {
          // Value should be a string, if not it's undefined
          this.personalizedMessage = false;
          this.$emit("turnFeeOff", true); // won't show fee until state is selected
        }
      },
    },
  },
  created() {
    this.value.paymentType = this.currentPaymentType ?? {};
    this.value.state = this.currentState ?? null;
  },
  methods: {
    fetchStates(state) {
      this.states = STATES.filter((st) =>
        st.label.toLowerCase().includes(state.toLowerCase())
      );
    },
    cleanDropDown() {
      this.value.state = null;
    },
  },
};
</script>
