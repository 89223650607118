<template>
  <v-select
    v-model="currentVal"
    class="md:max-w-full forced-select-box-grow border border-indigo-base"
    :class="[selectClass]"
    :dropdown-should-open="({ open }) => open"
    :placeholder="placeholder"
    :calculate-position="withPopper"
    v-bind="$attrs"
    append-to-body
    :options="convertedOptions"
    :get-option-label="(option) => getLabel(option)"
    :clear-search-on-blur="
      ({ clearSearchOnSelect, multiple }) => multiple ?? false
    "
    :autoscroll="false"
    @option:selected="selectOption"
  >
    <template #selected-option-container="{ option, deselect }">
      <span> </span>
    </template>
    <template #option="item">
      <div
        class="my-2 relative focus:bg-teal-100 grid grid-cols-2 grid-cols-size p-4"
        @click.stop="selectOption(item)"
      >
        <div class="h-5 z-0">
          <input
            v-if="
              getLabel(item) !== 'Select all' &&
              getLabel(item) !== 'Deselect all'
            "
            type="checkbox"
            :checked="item?.isRefined"
            class="h-4 w-4 form-checkbox border-gray-300 cursor-pointer z-0 relative"
          />
        </div>
        <div
          class="text-sm top-0 z-40"
          :class="{ 'dropdown-line-break': wrapDropdown }"
        >
          <div
            :for="getLabel(item)"
            class="pt-[3px] text-sm"
            :class="getOptionClass(item)"
          >
            {{ getLabel(item) }}
          </div>
        </div>
      </div>
    </template>
    <template #open-indicator="{ attributes }">
      <slot name="customCaret" :attributes="attributes">
        <span v-bind="attributes">
          <ExperiencePageCustomCaret />
        </span>
      </slot>
    </template>
    <template #no-options="{ searching, loading }">
      <em v-if="loading" class="opacity-50">Loading...</em>
      <em v-else-if="!searching" class="opacity-50">Start typing...</em>
      <em v-else class="opacity-50">No Results Found</em>
    </template>
  </v-select>
</template>
<script>
import { createPopper } from "@popperjs/core";
import ExperiencePageCustomCaret from "@/components/shared/icons/ExperiencePageCustomCaret.vue";
import { reLabel} from "../utils";

export default {
  name: "AlgoliaBaseMultiSelect",
  components: {
    ExperiencePageCustomCaret,
  },
  props: {
    placement: {
      required: false,
      type: String,
      default: "bottom",
    },
    label: {
      type: String,
      default: "label",
    },
    modelValue: {
      type: [Array, Object],
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectClass: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxSelected: {
      type: Number,
      default: 1000,
    },
    labelId: {
      type: String,
      default: "id",
    },
    wrapDropdown: {
      required: false,
      type: Boolean,
      default: false,
    },
    translationMap: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:onOptionClicked"],
  data() {
    return {
      selectedOptions: {},
      currentVal: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      handler: function (value) {
        if(value) {
          this.currentVal = value;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getOptionClass(item) {
      const label = this.getLabel(item);
      if (label === "Select all" || label === "Deselect all") {
        return "!text-teal-900";
      } else {
        return "ml-3 text-gray-700";
      }
    },
    getLabel(item) {
      return item[this.label];
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      dropdownList.className += " wrapped_select_override";
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          { name: "flip", enabled: false },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement !== "Bottom"
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
    selectOption(option) {
      option.isRefined = !option.isRefined;
      this.$emit("update:onOptionClicked", option);
    },
  },
  computed: {
    convertedOptions() {
      const converted = this.options.map(option => {
        const newLabel = reLabel(option, this.translationMap);
        return {
          ...option,
          label: newLabel,
        }
      });
      return converted;
    }
  },
};
</script>
<style scoped>
.dropdown-line-break {
  white-space: normal;
  vertical-align: top;
  display: inline-block;
  line-break: loose;
  overflow-wrap: anywhere;
}

.grid-cols-size {
  grid-template-columns: fit-content(5%) 1fr;
}

.forced-select-box-grow :deep(.vs__dropdown-toggle),
:deep(.vs__selected-options) {
  overflow: visible;
  max-height: inherit;
  background-color: white !important;
  border-radius: 0 !important;
  border: 1px solid transparent !important;
}

:deep(.vs__clear) {
  display: none;
}

.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}

.wrapped_select_override > .vs__dropdown-option {
  padding: 0 !important;
}

.wrapped_select_override > .vs__dropdown-option--highlight {
  @apply bg-blue-350;
}

.wrapped_select_override > .vs__dropdown-option--deselect {
  @apply bg-indigo-light;
}

:deep(.vs__dropdown-toggle),
:deep(.vs__search::placeholder) {
  color: rgba(30, 38, 76, var(--tw-text-opacity));
}
</style>
