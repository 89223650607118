<script setup>
import ProgramDates from "@/components/ProgramDates";
import { computed, defineProps, toRefs, unref } from "vue";
import {
  getFormattedDuration,
  getIsEndDateFinal,
  getIsStartDateFinal,
  getProgramDates,
  getHostUniversities,
  getProgramSemesterYear,
  getSessionTerms,
  getProgramLocationTerm,
  getDisplayNameForTerms,
} from "../CardContent/utils.js";
import {randomString} from "@/util/string";

const props = defineProps({
  program: {
    type: Object,
    default: () => ({}),
  },
});
const { program } = toRefs(props);

const session = computed(() => unref(program)?.session);
const sessionId = computed(() => unref(session)?.id);
const program_page = computed(() => unref(program)?.program_page);
const programLocationTerm = computed(() =>
  getProgramLocationTerm(unref(session))
);
const sessionTerms = computed(() => getSessionTerms(unref(session)));
const displayNameForTerms = computed(() =>
  getDisplayNameForTerms(unref(sessionTerms))
);
const programSemesterYear = computed(() =>
  getProgramSemesterYear(unref(program))
);
const hostUniversities = computed(() => getHostUniversities(unref(session)));
const formattedDuration = computed(() => getFormattedDuration(unref(session)));
const programDates = computed(() => getProgramDates(unref(session)));
const isStartDateFinal = computed(() => getIsStartDateFinal(unref(session)));
const isEndDateFinal = computed(() => getIsEndDateFinal(unref(session)));

const onTooltipClick = (event) => {
  event.preventDefault();
  event.stopPropagation();
};
</script>

<template>
  <div
    data-testid="main-container"
    class="flex flex-col pt-[1.375rem] lg:pt-8 pb-6"
  >
    <p
      data-testid="info-section"
      class="font-montserrat font-medium text-sm text-indigo-base text-opacity-70"
    >
      <span data-testid="program-location-term">
        {{ programLocationTerm }}
      </span>
      <span data-testid="program-semester-year">
        {{ programSemesterYear }}
      </span>
    </p>
    <p
      v-if="program_page?.name"
      data-testid="program-name-section"
      class="font-montserrat font-bold text-indigo-base text-xl leading-[1.375rem] flex flex-col flex-grow"
    >
      <span class="flex flex-wrap">
        <span data-testid="program-name" class="mr-1">
          {{ program_page.name }}
          <span v-if="formattedDuration" data-testid="program-duration">{{
            formattedDuration
          }}</span>
        </span>
      </span>
    </p>
    <div v-if="sessionTerms">
      <p
        data-testid="session-term"
        class="font-montserrat font-medium text-sm text-indigo-base text-opacity-70 mt-4"
      >
        {{ displayNameForTerms }}
      </p>
    </div>
    <div data-testid="dates-container" class="flex flex-wrap">
      <ProgramDates
        :data-testid="`program-dates-${sessionId + randomString(5)}`"
        :dates="programDates"
        :start-is-final="isStartDateFinal"
        :end-is-final="isEndDateFinal"
        tag="p"
        class="text-gray-600"
        reverse-icon-order
        experience-card-icon
        text-class="font-montserrat uppercase font-bold text-indigo-base text-xs sm:text-sm mt-[0.5rem]"
        @click.stop="onTooltipClick"
      />
    </div>
    <div data-testid="additional-info">
      <p
        data-testid="host-universities"
        class="font-medium text-sm text-indigo-base"
      >
        {{ hostUniversities }}
      </p>
    </div>
  </div>
</template>
