export default {
  methods: {
    componentExists(form, componentName) {
      return !!form.getComponent(componentName);
    },
  },
  computed: {
    setApplicationId() {
      if (this.currentApplicationId) {
        return this.currentApplicationId;
      } else {
        return this.$route.params.applicationId;
      }
    },
  },
};
