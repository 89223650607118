<template>
  <div>
    <div class="mt-6 font-sans mt-7">
      <span class="font-medium text-sm leading-normal font-sans">
        Which of these best describes the type of housing?
      </span>
    </div>
    <div class="flex flex-col font-sans mt-5">
      <a
        v-for="item in items"
        :key="item.type"
        class="flex items-center mt-6 cursor-pointer hover:bg-gray-200 rounded-md"
        @click="next(item.type)"
      >
        <div class="p-1">
          <img :alt="item.description" :src="getIcon(item.type)" />
        </div>
        <div class="flex-1 ml-4 text-sm">
          <h2 class="font-bold">{{ item.type }}</h2>
          <span class="mt-2">{{ item.description }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { HOUSING_TYPES } from "../../../constants";
import ApartmentIcon from "@/assets/icons/apartment.svg";
import DormIcon from "@/assets/icons/dorm.svg";
import HotelIcon from "@/assets/icons/hotel.svg";
import HouseIcon from "@/assets/icons/house.svg";
import LocalHostIcon from "@/assets/icons/local-host.svg";

export default {
  name: "CreateHousing",
  emits: ["next"],
  data: () => ({
    items: [...HOUSING_TYPES],
    icons: {
      Apartment: ApartmentIcon,
      Dorm: DormIcon,
      Hotel: HotelIcon,
      House: HouseIcon,
      "Local Host": LocalHostIcon,
    },
  }),
  methods: {
    next(type) {
      this.$emit("next", { operation: "Create ", type: type });
    },
    getIcon(type) {
      return this.icons[type] ?? ApartmentIcon;
    },
  },
};
</script>
