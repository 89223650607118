<template>
  <DuplicateModalBase
    title="Duplicate Session"
    name="session"
    :description="`${DUPLICATE_MSG} this session`"
    :display-error="displayError"
    :disabled-buttons="disabledButtons"
    :modal-state="props.data?.state ?? false"
    @close-modal="closeModal"
    @duplicate-record="duplicateRecord"
  />
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import { DUPLICATE_MSG } from "@/constants.js";
import DuplicateModalBase from "../DuplicateModalBase";
import sessionService from "@/services/programSessions";

const emit = defineEmits(["closeModal"]);

const displayError = ref(false);
const router = useRouter();
const disabledButtons = ref(false);

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      state: false,
      id: undefined,
    }),
  },
});

const duplicateRecord = async () => {
  try {
    disabledButtons.value = true;
    const { data: response } = await sessionService.duplicateProgramSession({
      id: props.data.id,
    });
    router.push({
      name: "edit-program-sessions",
      params: {
        programSessionId: response?.data?.items?.id,
      },
    });
    closeModal();
  } catch (err) {
    displayError.value = true;
  }
};

const closeModal = () => {
  displayError.value = false;
  disabledButtons.value = false;
  emit("closeModal");
};
</script>
