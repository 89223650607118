<script setup>
import { useVModel } from "@vueuse/core";
import LabeledEditableField from "@/components/shared/LabeledEditableField.vue";
import ErrorMessage from "@/components/shared/ErrorMessage.vue";
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  labelName: {
    type: String,
    default: "",
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 250,
  },
  vuelidateInstance: {
    type: Object,
    default: () => {},
  },
  dataTestId: {
    type: String,
    default: "simpleTextareaId",
  },
  outerClasses: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update:modelValue"]);
const fieldValue = useVModel(props, "modelValue", emit);
</script>

<template>
  <div :class="outerClasses">
    <textarea
      v-model="fieldValue"
      rows="5"
      class="block w-full form-input sm:text-sm"
      :class="{
        'bg-error-100': vuelidateInstance?.$errors?.length,
      }"
      :name="dataTestId"
      :disabled="disabled"
      :maxlength="maxLength"
      @input="limitCancellationReasonSize"
    ></textarea>
    <span class="grid grid-cols-2">
      <ErrorMessage :vuelidate-instance="vuelidateInstance" />
      <span
        class="col-start-2 text-sm text-right font-semibold text-gray-600"
        :class="{
          'success text-success-900': fieldValue?.length === maxLength,
        }"
        >{{ fieldValue?.length || 0 }}/{{ maxLength }}</span
      >
    </span>
  </div>
</template>
