<script setup>
import "flatpickr/dist/flatpickr.css";
import { defineProps, ref } from "vue";
import { useVModel } from "@vueuse/core";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";

const props = defineProps({
  modelValue: {
    type: Object,
  },
});

const events = useVModel(props, "modelValue");

const includes = ref(events.value["What is included"]);
const packingList = ref(events.value["What should participants pack"]);
const activity_level = ref(events.value["Activity level"]);
const housing = ref(events.value["What housing is available"]);
const housing_description = ref(events.value["Housing description"]);
</script>
<template>
  <div class="relative mt-4">
    <div class="text-base mb-4 font-medium text-gray-700">
      What is Included:
      <span v-if="includes" class="font-semibold">{{
        includes?.map((item) => item).join(", ") || ""
      }}</span>
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      What should the participants pack:
      <span v-if="packingList" class="font-semibold">{{
        packingList?.map((item) => item).join(", ") || ""
      }}</span>
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      Itinerary:
      <JSONtoHTML
        v-if="events.Itinerary"
        class="font-semibold"
        :tiptap-json="events.Itinerary"
      />
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      Activity level:
      <span v-if="activity_level" class="font-semibold">{{
        activity_level
      }}</span>
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      What type of housing is available?:
      <span v-if="housing" class="font-semibold">{{ housing }}</span>
    </div>
    <div class="text-base mb-4 font-medium text-gray-700">
      Housing Description:
      <JSONtoHTML
        v-if="housing_description"
        class="font-semibold"
        :tiptap-json="housing_description"
      />
    </div>
  </div>
</template>
