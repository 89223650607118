<script setup>
import { MOBILE_APP_LINKS } from "@/constants.js";

const openLink = (url) => {
  window.open(url, "_blank");
};
</script>

<template>
  <div
    class="bg-red-light mobile-app-banner rounded-lg md:flex mb-4 md:mb-0"
    data-testid="mobile-app-download-banner"
  >
    <div class="mobile-images flex-none flex gap-0.5">
      <img
        class="rounded-tl-lg md:rounded-l-lg flex-1 md:flex-none"
        src="/images/mobile-app/mobile-app-screenshot-1.png"
      />
      <img
        class="flex-1 md:flex-none"
        src="/images/mobile-app/mobile-app-screenshot-2.png"
      />
      <img
        class="flex-1 md:flex-none"
        src="/images/mobile-app/mobile-app-screenshot-3.png"
      />
      <img
        class="rounded-tr-lg md:rounded-none flex-1 md:flex-none"
        src="/images/mobile-app/mobile-app-screenshot-4.png"
      />
    </div>
    <div class="flex-1 p-4 md:flex">
      <div class="font-montserrat text-indigo-base md:mr-2">
        <p class="font-medium text-sm leading-5">
          Heads up! Downloading the APIConnect mobile app is required to finish
          your onboarding and get important details about your program. It will
          be your personal guide through every phase of your student journey
          even when you are on site with us! We noticed you haven't done it
          yet—download it now!
        </p>
      </div>
      <img
        class="hidden md:block qr-img"
        src="/images/mobile-app/qr-code.png"
      />
      <div class="md:hidden flex justify-between mt-3">
        <div
          class="cursor-pointer"
          @click="openLink(MOBILE_APP_LINKS.APP_STORE)"
        >
          <img src="/images/mobile-app/app-store.png" />
        </div>
        <div
          class="cursor-pointer"
          @click="openLink(MOBILE_APP_LINKS.GOOGLE_PLAY)"
        >
          <img src="/images/mobile-app/google-play.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 1350px) {
  .mobile-app-banner {
    max-height: 140px;
  }
}

@media (min-width: 500px) and (max-width: 1350px) {
  .mobile-images {
    display: none;
  }
}

.qr-img {
  max-width: 140px;
  max-height: 140px;
}
</style>
