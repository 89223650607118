<script setup>
import { toRefs, computed, ref } from "vue";
import ArrowRight from "@/components/shared/icons/ArrowRight.vue";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps({
  classes: {
    type: Object,
    required: true,
    default: () => {},
  },
  hidden: {
    type: Boolean,
    required: true,
  },
  rule: {
    type: Object,
    default: () => {},
  },
});

const { classes, rule } = toRefs(props);
const contentHidden = ref(props.hidden);

const getLevel = computed(() => {
  const level = classes.value?.level;
  return Array.isArray(level)
    ? level.map((individualLevel) => individualLevel).join(", ")
    : "";
});

const getPeers = computed(() => {
  const peers = classes.value?.classroom_peers;
  return Array.isArray(peers)
    ? peers.map((individualPeers) => individualPeers).join(", ")
    : "";
});

const dropdownIcon = computed(() => {
  return contentHidden.value
    ? "/images/icon-chevron-down.svg"
    : "/images/icon-chevron-up.svg";
});

function toggleHidden() {
  contentHidden.value = !contentHidden.value;
}

const redirectToViewPage = (courseId) => {
  const detailsPage = router.resolve({
    name: "course-product-page-view",
    params: {
      courseId: courseId,
    },
  });
  window.open(detailsPage.href, "_blank");
};
</script>

<template>
  <div class="bg-white mb-4 border border-blue-100 shadow-sm">
    <div
      class="flex items-center text-indigo-base pl-4 py-3 cursor-pointer justify-between"
      :class="[
        secondaryColor
          ? `bg-gray-275 border-gray-100`
          : `bg-university-accentPrimary border-blue-100`,
      ]"
      @click="toggleHidden"
    >
      <div class="flex space-x-4">
        <p class="font-bold">
          {{ classes?.source_system_class_id }}
        </p>
        <p
          v-if="classes?.title?.length < 90"
          class="md:max-w-72 lg:max-w-none font-semibold"
        >
          {{ classes?.title }}
        </p>
        <p v-else class="md:max-w-72 lg:max-w-none font-medium">
          {{
            contentHidden ? classes?.title?.substring(0, 90) : classes?.title
          }}
        </p>
        <p
          v-if="rule?.conjunction === 'and'"
          class="font-bold italic uppercase text-sm"
        >
          *required
        </p>
      </div>

      <div class="flex space-x-4 mr-4">
        <p class="font-bold uppercase">
          Host Credits:
          <span class="font-medium">{{
            classes?.host_institution_credit_amount
          }}</span>
        </p>
        <p class="hidden lg:block font-bold uppercase">
          Language:
          <span class="font-medium">{{
            classes?.language_of_instruction?.value
          }}</span>
        </p>
        <img :src="dropdownIcon" />
      </div>
    </div>
    <div
      v-if="!contentHidden"
      class="grid pl-4 max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2"
    >
      <div
        class="mt-4 sm:mx-0 lg:border-r lg:row-end-1 lg:col-span-1 lg:row-span-1"
      >
        <p class="mb-2 pr-2 whitespace-pre-line">
          {{ classes?.description }}
        </p>
      </div>
      <div class="mt-4 sm:mx-0 lg:row-end-1 lg:col-span-1 lg:row-span-1">
        <div
          class="grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-4 gap-y-4 lg:mx-0 lg:max-w-none lg:grid-cols-2"
        >
          <div>
            <p class="font-semibold text-indigo-base">HOST UNIVERSITY</p>
            <p>{{ classes?.host_institution?.name }}</p>
          </div>
          <div>
            <p class="font-semibold text-indigo-base">CLASS LEVEL</p>
            <p>{{ getLevel }}</p>
          </div>
          <div>
            <p class="font-semibold text-indigo-base">
              LANGUAGE OF INSTRUCTION
            </p>
            <p>{{ classes?.language_of_instruction?.value }}</p>
          </div>
          <div>
            <p class="font-semibold text-indigo-base">
              LANGUAGE LEVEL REQUIRED
            </p>
            <p>{{ classes?.language_level_requirement?.value }}</p>
          </div>
          <div>
            <p class="font-semibold text-indigo-base">
              REC. US SEMESTER CREDITS
            </p>
            <p>{{ classes?.recommended_us_credit_amount }}</p>
          </div>
          <div>
            <p class="font-semibold text-indigo-base">CLASSROOM PEERS</p>
            <p>{{ getPeers }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!contentHidden" class="flex justify-end">
      <div
        v-if="classes?.api_url_syllabus"
        :class="`flex ${secondaryColorClass} font-bold justify-end pr-5 py-2`"
      >
        <a
          :href="classes?.api_url_syllabus"
          :class="`${secondaryColorClass} font-bold`"
        >
          View Syllabus
        </a>
        <ArrowRight custom-class="w-5 h-5" />
      </div>
      <div>
        <button
          type="button"
          :class="`mb-1 mr-5 inline-flex items-center ${secondaryColorClassOutlined} border-solid border px-8 py-2 text-md font-medium shadow-sm`"
          @click="redirectToViewPage(classes?.id)"
        >
          <div
            class="flex gap-1 font-bold text-base tracking-tight items-center whitespace-nowrap"
          >
            <span>View Details </span>
            <ArrowRight custom-class="w-5 h-5" />
          </div>
        </button>
        <p class="text-sm mb-4 italic tracking-tight">
          Details will open in a new tab
        </p>
      </div>
    </div>
  </div>
</template>
