<template>
  <div>
    <div class="relative pt-6 sm:p-6 my-1">
      <div class="flex justify-between items-center">
        <h1 class="text-xl font-semibold leading-6 text-gray-900">
          Safety Security
        </h1>
        <RequiredToSavePublish />
      </div>
      <section
        class="flex flex-col sm:flex-row sm:justify-between sm:max-w-7xl"
      >
        <div class="block sm:w-40p">
          <span class="mt-8 block text-sm font-semibold text-gray-700"
            ><span class="text-red-100 pr-2 align-sub">*</span>Building
            Access</span
          >
          <v-select
            :model-value="buildingAccess"
            :options="buildingAccessOptions"
            :placeholder="placeholder"
            :class="{
              'forced-error-background': !!v$.buildingAccess.$errors.length,
            }"
            @update:modelValue="updateBuildingAccess"
          ></v-select>
          <div
            v-if="v$.buildingAccess.$errors.length"
            class="block text-sm error text-error-900"
          >
            <span v-for="(error, idx) in v$.buildingAccess.$errors" :key="idx">
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div class="block sm:w-40p">
          <span class="mt-8 block text-sm font-semibold text-gray-700"
            ><span class="text-red-100 pr-2 align-sub">*</span>&nbsp;Staffed
            Reception?</span
          >
          <v-select
            :model-value="staffedReception"
            :options="staffedReceptionOptions"
            :placeholder="placeholder"
            :class="{
              'forced-error-background': !!v$.staffedReception.$errors.length,
            }"
            @update:modelValue="updateStaffedReception"
          ></v-select>
          <div
            v-if="v$.staffedReception.$errors.length"
            class="block text-sm error text-error-900"
          >
            <span
              v-for="(error, idx) in v$.staffedReception.$errors"
              :key="idx"
            >
              {{ error.$message }}
            </span>
          </div>
        </div>
      </section>
      <section v-if="openStaffReceptionHours" class="mt-4">
        <span class="text-sm font-semibold text-gray-700">
          If building is staffed, what hours are Staff Available
        </span>
        <StaffReceptionInput
          enabled
          :default-availability="staffAvailability"
          @updateStaffField="updateStaffField"
        />
      </section>
      <section class="mt-8">
        <SafetyAmenitiesList />
      </section>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import RequiredToSavePublish from "../RequiredToSavePublish";
import SafetyAmenitiesList from "../SafetyAmenitiesList";
import StaffReceptionInput from "../StaffReceptionInput";

import { DAYS_OF_WEEK } from "@/constants.js";

const store = useStore();

const staffedReceptionOptions = ref(["Yes", "No", "Unknown"]);
const buildingAccessOptions = ref(["Key", "Electronic system", "Unknown"]);
const placeholder = "Please select one";

const rules = computed(() => ({
  staffedReception: {
    required,
  },
  buildingAccess: {
    required,
  },
}));

const staffHours = computed(
  () => store.getters["programManager/getCurrentEditing"]?.staff_hours
);

const getDaysOfWeek = () =>
  DAYS_OF_WEEK.map((day) => ({
    [day]: {
      enabled: false,
      availability: [{ start: "09:00", end: "17:00" }],
    },
  }));

const staffAvailability = computed(() => {
  try {
    return staffHours?.value ? JSON.parse(staffHours.value) : getDaysOfWeek();
  } catch (err) {
    return getDaysOfWeek();
  }
});

function setCurrentEditingFields(payload) {
  store.commit("programManager/setCurrentEditingFields", payload);
}

function updateStaffField(payload) {
  setCurrentEditingFields({
    staff_hours: JSON.stringify(payload),
  });
}

const staffedReception = computed(
  () => store.getters["programManager/getCurrentEditing"]?.staffed_reception
);

const updateStaffedReception = (value) => {
  setCurrentEditingFields({
    staffed_reception: value,
  });
};

const buildingAccess = computed(
  () => store.getters["programManager/getCurrentEditing"]?.building_access
);

const updateBuildingAccess = (value) => {
  setCurrentEditingFields({
    building_access: value,
  });
};

const v$ = useVuelidate(
  rules,
  {
    staffedReception,
    buildingAccess,
  },
  { $registerAs: "SafetySecurityTab", $lazy: true, $autoDirty: true }
);

const openStaffReceptionHours = computed(
  () => staffedReception.value === "Yes"
);
</script>
<style scoped>
.forced-error-background :deep(.vs__search),
.forced-error-background :deep(.vs__dropdown-toggle) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 238, 237, var(--tw-bg-opacity)) !important;
}
</style>
