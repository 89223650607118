<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="#1E264C"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0006 23.2923C11.5881 23.2923 10.2574 23.0222 9.00876 22.4819C7.76009 21.9417 6.66812 21.2041 5.73284 20.269C4.79756 19.3338 4.05977 18.2421 3.51945 16.9936C2.97914 15.7451 2.70898 14.4147 2.70898 13.0024C2.70898 11.579 2.9791 10.2411 3.51932 8.98858C4.05954 7.73608 4.7972 6.6466 5.7323 5.72011C6.66741 4.79361 7.7592 4.06013 9.00765 3.51967C10.2561 2.97921 11.5865 2.70898 12.9988 2.70898C14.4222 2.70898 15.7602 2.97914 17.0127 3.51945C18.2652 4.05977 19.3547 4.79305 20.2811 5.7193C21.2076 6.64555 21.9411 7.73475 22.4816 8.9869C23.022 10.239 23.2923 11.5769 23.2923 13.0006C23.2923 13.5173 23.2544 14.0287 23.1787 14.535C23.103 15.0412 22.993 15.5346 22.8485 16.0152C22.6916 15.8791 22.5225 15.7579 22.3412 15.6517C22.16 15.5454 21.9631 15.4597 21.7506 15.3944C21.8548 15.0124 21.9329 14.6242 21.985 14.2298C22.0371 13.8354 22.0631 13.4256 22.0631 13.0006C22.0631 10.4784 21.1833 8.33744 19.4235 6.5777C17.6638 4.81799 15.5228 3.93814 13.0006 3.93814C10.4895 3.93814 8.35132 4.81799 6.58605 6.5777C4.82077 8.33744 3.93813 10.4784 3.93813 13.0006C3.93813 15.5117 4.82077 17.6499 6.58605 19.4152C8.35132 21.1805 10.4895 22.0631 13.0006 22.0631C14.0409 22.0631 15.0269 21.8975 15.9586 21.5662C16.8903 21.2349 17.7411 20.7776 18.511 20.1944C18.643 20.3555 18.7878 20.5055 18.9454 20.6444C19.103 20.7832 19.2735 20.8999 19.4569 20.9944C18.5666 21.7152 17.5741 22.2784 16.4795 22.6839C15.3849 23.0895 14.2252 23.2923 13.0006 23.2923ZM20.897 19.2131C20.6522 19.2131 20.4433 19.1268 20.2704 18.9543C20.0975 18.7818 20.011 18.5731 20.011 18.3283C20.011 18.0765 20.0973 17.8659 20.2698 17.6965C20.4423 17.527 20.651 17.4423 20.8959 17.4423C21.1407 17.4423 21.3496 17.5268 21.5225 17.6959C21.6954 17.8649 21.7819 18.0753 21.7819 18.3271C21.7819 18.5719 21.6956 18.7808 21.523 18.9537C21.3505 19.1266 21.1419 19.2131 20.897 19.2131ZM17.0652 17.9985L12.4465 13.2525V7.32564H13.6756V12.7486L17.9818 17.0819L17.0652 17.9985Z"
    />
  </svg>
</template>
