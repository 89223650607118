import * as Sentry from "@sentry/vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { trailingSlash } from "../mixins/helpers";

export function useNotifications() {
  const store = useStore();
  const route = useRoute();

  const sendNotifications = (notificationType, programId) => {
    if (getCurrentStudentApplication.value?.bulkEnrollmentId) {
      return;
    }
    let isFirstSubmition = route.path.indexOf("/submission/") === -1;
    if (notificationType && isFirstSubmition) {
      let postData = {
        event: notificationType,
        program_session_id: programId,
      };
      fetch(
        trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "advisor/notification",
        {
          method: "post",
          body: JSON.stringify(postData),
          headers: {
            "Content-type": "application/json",
            "x-jwt-token": getFormioToken.value,
          },
          mode: "cors",
        }
      ).catch((e) => {
        console.warn(e);
        Sentry.captureException(new Error("Advisor Notification"), {
          tags: {
            error: e,
          },
        });
      });
    }
  };

  const getCurrentStudentApplication = computed(
    () => store.getters["getProgramLengthOptions"]
  );
  const getFormioToken = computed(() => store.getters["getStudentToken"]);

  return {
    sendNotifications,
  };
}
