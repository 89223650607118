<script setup>
import ActionButtonsCard from "@/components/shared/Card/ActionButtonsCard.vue";
import CareerInterestsCard from "@/components/shared/Card/CareerInterestsCard.vue";
import IndustriesCard from "@/components/shared/Card/IndustriesCard.vue";
import InternshipCardContent from "@/components/shared/Card/InternshipCardContent.vue";
import ProgramPriceCard from "@/components/shared/Card/ProgramPriceCard.vue";
import TitleCard from "@/components/shared/Card/TitleCard.vue";
import getApplicableContract from "@/composables/useBillings";
import {
  CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
  DEFAULT_IMAGE_CARD_SIZE,
  DURATION_IN_DAYS_THRESHOLD,
} from "@/constants.js";
import { imageTransformation } from "@/util/cloudinary";
import { formattedPriceFromCentsWithoutCents as formattedPrice } from "@/util/formatter";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import { computed, inject } from "vue";
import { useStore } from "vuex";

defineEmits(["bookmarkClicked", "viewClicked", "applyClicked"]);

const store = useStore();

const billingRules = computed(() => store.state.billingRules);

const hidePrice = computed(() => {
  const contracts = billingRules.value?.agreements ?? [];
  if (contracts.length < 1) {
    return false;
  }
  const applicableContract = getApplicableContract(
    contracts,
    props?.program?.session
  );
  return (
    Boolean(applicableContract) &&
    applicableContract?.direct_billing_type !== "Pass through"
  );
});

const cardType = inject("cardType", "default");

const isPastAppDeadline = computed(() => {
  return pastSessionDeadline(props?.program?.session);
});

const programCardClasses = computed(() => {
  const baseClasses =
    "font-montserrat not-italic leading-5 bg-white shadow-md md:offset-y-4 md:offset-x-0 md:blur-20 md:text-opacity-10 overflow-hidden w-full";
  const isCursorPointer = cardType !== "internshipConfigurator";
  const isFlexLayout = cardType === "internshipConfigurator";
  let conditionalClasses = isCursorPointer ? "cursor-pointer" : "";
  conditionalClasses += isFlexLayout
    ? " flex flex-col md:grid md:grid-cols-2 md:auto-rows-min 1/2lg:flex 1/2lg:flex-col lg:grid lg:grid-cols-2 lg:auto-rows-min xl:flex xl:flex-col xl:auto-rows-min 2xl:grid 2xl:grid-cols-2 2xl:auto-rows-min"
    : "";
  return `${baseClasses} ${conditionalClasses}`.trim();
});

const contentWrapperClasses = computed(() => {
  const baseClasses = "px-4";
  if (cardType === "internship") {
    return `${baseClasses} flex flex-col ${
      props.detailsPageInternshipBool ? "h-full" : ""
    }`.trim();
  }
  if (cardType === "internshipConfigurator") {
    return `${baseClasses} flex justify-end mb-6 px-6 xs:mb-6 xs:my-0 sm:mb-6 sm:my-0 md:my-6 1/2lg:mb-6 1/2lg:my-0 lg:my-6 1/2xl:my-6 xl:mb-6 xl:my-0 2xl:my-6 xs:border-0 sm:border-0 md:border-l 1/2lg:border-0 lg:border-l 1/2xl:border-l xl:border-0 2xl:border-l border-[#d6d6d6]`.trim();
  }
  return `${baseClasses} 1/2xl:col-span-2 1/2xl:grid 1/2xl:grid-cols-2`.trim();
});

const formattedInternshipDuration = computed(() => {
  const startDate = props.program?.session?.academic_start_date;
  const endDate = props.program?.session?.academic_end_date;
  if (!startDate || !endDate) {
    return null;
  }
  const durationInDays = Math.ceil(
    (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
  );
  if (durationInDays < DURATION_IN_DAYS_THRESHOLD) {
    const days = `day${durationInDays > 1 ? "s" : ""}`;
    return `${durationInDays} ${days}`;
  } else {
    const durationInWeeks = Math.ceil(durationInDays / 7);
    const weeks = `week${durationInWeeks > 1 ? "s" : ""}`;
    return `${durationInWeeks} ${weeks}`;
  }
});

const props = defineProps({
  program: {
    type: Object,
    default() {
      return {};
    },
  },
  showViewLink: {
    type: Boolean,
    default: true,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  randomTags: {
    type: Boolean,
    default: false,
  },
  showBookmark: {
    type: Boolean,
    default: false,
  },
  showImage: {
    type: Boolean,
    default: true,
  },
  uppercaseName: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  flexWrap: {
    type: Boolean,
    default: true,
  },
  disabledApply: {
    type: Boolean,
    default: false,
  },
  perks: {
    type: Array,
    default: () => [],
  },
  detailsPageInternshipBool: {
    type: Boolean,
    default: true,
  },
  companySize: {
    type: String,
    default: "",
  },
  placementTypes: {
    type: Array,
    default: () => [],
  },
  eventCategory: {
    type: String,
    default: "",
  },
  imageUrl: {
    type: String,
    default: "",
  },
});

const sessionId = computed(() => {
  return props.program?.session_id;
});

const redirectUrl = computed(() => {
  return `/results/internships?internship-id=${props.program?.internship_id}&program-id=${props.program?.id}&session-id=${props.program?.session?.id}`;
});

const program = computed(() => props.program);

const transformImage = (imagePath) => {
  return imageTransformation({
    ...CLOUDINARY_DEFAULT_IMAGE_TRANSFORMATION,
    ...DEFAULT_IMAGE_CARD_SIZE,
    path: imagePath,
    compress: true,
  });
};

const cardImage = computed(() => {
  if (props.imageUrl) {
    return transformImage(props.imageUrl);
  }
  const leadMedia = props.program?.program_page?.lead_media;
  const mediaList = leadMedia ? JSON.parse(leadMedia) : [];
  const firstImage = mediaList.find((media) =>
    /\.(jpg|jpeg|png|gif)$/i.test(media)
  );
  return firstImage ? transformImage(firstImage) : "/images/api-card-logo.png";
});

const customApplyClass = computed(() => {
  let classes =
    "box-border flex flex-row justify-center items-center w-auto h-auto max-h-60p max-w-260p border-2 py-3 text-sm";
  if (props.selected) {
    classes +=
      " bg-university-secondary text-white border-university-secondary";
  } else if (props.disabledApply) {
    classes += " cursor-not-allowed bg-gray-275 text-gray-485 border-gray-485";
  } else {
    classes +=
      " bg-white text-university-secondary border-university-secondary";
  }
  return classes;
});

const industries = computed(() => {
  if (props.program?.industries?.length > 0) {
    const uniqueCareerPathways = new Set(
      props.program.industries.map((industry) => industry.career_pathway)
    );
    return Array.from(uniqueCareerPathways).join(", ");
  }
  return "";
});

const priceInDollars = computed(() => {
  if (!props.program?.session?.base_price_in_cents) {
    return null;
  }
  return formattedPrice(props.program?.session?.base_price_in_cents);
});

const careerInterests = computed(() => {
  const interests = props.program?.career_interests ?? [];
  let result = "";
  let totalLength = 0;
  for (let i = 0; i < interests.length; i++) {
    const title = interests[i].soc_title;
    const additionLength = (result ? 2 : 0) + title.length;
    if (totalLength + additionLength <= 80) {
      result += (result ? ", " : "") + title;
      totalLength += additionLength;
    } else if (totalLength < 80) {
      const availableSpace = 80 - totalLength - (result ? 2 : 0);
      if (availableSpace > 0) {
        let truncatedTitle = title.slice(0, availableSpace - 3);
        result += (result ? ", " : "") + truncatedTitle + "...";
      } else if (!result) {
        result = title.slice(0, 77) + "...";
      }
      break;
    }
  }
  return {
    text: result,
    total: interests.length,
    notShown:
      interests.length -
      (result.match(/, /g)?.length ? result.match(/, /g).length + 1 : 1),
  };
});
</script>

<template>
  <div
    :id="`internship-card-${sessionId}`"
    :class="programCardClasses"
    @click="$emit('viewClicked', program?.session?.id)"
  >
    <TitleCard
      v-if="cardType === 'internshipConfigurator'"
      :class="{ 'col-span-2': cardType === 'internshipConfigurator' }"
      :title="formattedInternshipDuration"
      :action-id="sessionId"
      :show-bookmark="showBookmark"
      @bookmark-clicked="(id) => $emit('bookmarkClicked', id)"
    />
    <div class="flex flex-col">
      <slot name="card-title">
        <TitleCard
          v-if="cardType !== 'internshipConfigurator'"
          :title="formattedInternshipDuration"
          :action-id="sessionId"
          :show-bookmark="showBookmark"
          @bookmark-clicked="(id) => $emit('bookmarkClicked', id)"
        />
      </slot>
      <slot name="horizontal-card-head">
        <InternshipCardContent
          v-if="!showImage && horizontal"
          :program="program"
          :horizontal="horizontal"
          :random-tags="randomTags"
          :uppercase-name="uppercaseName"
        >
        </InternshipCardContent>
        <div v-if="showImage" class="flex-auto min-h-125p">
          <div class="overflow-hidden relative h-full">
            <img
              :id="`card-image-${program?.program_page?.id}`"
              :src="cardImage"
              :alt="program?.program_page?.name"
              class="object-cover absolute inset-0 w-full h-full bg-gray-75"
            />
          </div>
        </div>
      </slot>
    </div>
    <div :class="contentWrapperClasses">
      <slot name="card-content">
        <InternshipCardContent
          v-if="!horizontal || (horizontal && showImage)"
          :program="program"
          :horizontal="horizontal"
          :random-tags="randomTags"
          :uppercase-name="uppercaseName"
          :perks="perks"
          :company-size="companySize"
          :placement-types="placementTypes"
        />
      </slot>
      <div class="flex flex-col flex-grow gap-y-11 justify-between">
        <slot name="card-description">
          <div id="api-card-description">
            <ProgramPriceCard
              v-if="priceInDollars && !hidePrice"
              :price-in-dollars="priceInDollars"
              :hide-price="hidePrice"
            />
            <IndustriesCard v-if="industries" :industries="industries" />
            <CareerInterestsCard
              v-if="careerInterests"
              :career-interests="careerInterests"
              :session-id="sessionId"
            />
          </div>
        </slot>
        <div
          class="flex flex-col gap-4 justify-center items-center self-end w-full sm:flex-row sm:justify-around"
          :class="{ 'mb-6': cardType !== 'internshipConfigurator' }"
        >
          <slot name="buttons-lg">
            <ActionButtonsCard
              :show-view-link="showViewLink"
              :redirect-url="redirectUrl"
              :program="program"
              :custom-apply-class="customApplyClass"
              :is-past-app-deadline="isPastAppDeadline"
              :disabled-apply="disabledApply"
              :selected="selected"
              :event-category="eventCategory"
              @apply-clicked="(program) => $emit('applyClicked', program)"
            />
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
