<script setup>
import SidebarOptions from "./SidebarOptions.vue";
import SidebarReturnMobile from "./SidebarReturnMobile.vue";
import { computed, toRefs, defineEmits } from "vue";
import { useStore } from "vuex";
import { leftSidebarItems } from "@/constants";
import {
  hasItemsWritePermission,
  hasItemsReadPermission,
} from "@/composables/authorization.js";

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
  isOpened: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["toggle-sidebar"]);
const store = useStore();

const hasSettingsEnabled = computed(() => {
  const featureFlags = store.state.featureFlags;
  return featureFlags["settings"];
});
const hasUserManagementEnabled = computed(() => {
  const featureFlags = store.state.featureFlags;
  return (
    featureFlags["user-management"] &&
    (hasItemsWritePermission.value || hasItemsReadPermission.value)
  );
});

const userName = computed(() => {
  const { isImpersonating, userData } = store.state;
  const { getCurrentUser } = store.getters;
  return isImpersonating
    ? `Student View: ${userData.data.firstname ?? ""} ${
        userData.data.lastname ?? ""
      }`
    : `${getCurrentUser?.firstName ?? ""} ${getCurrentUser?.lastName ?? ""}`;
});

const menuItems = computed(() => {
  const menu = { account: {}, settings: {} };

  if (hasSettingsEnabled.value) {
    menu.settings.brand = leftSidebarItems.settings.brand;
    menu.account.aboutYou = leftSidebarItems.account.aboutYou;
    if (hasItemsWritePermission.value) {
      menu.settings.hostInstitutions =
        leftSidebarItems.settings.hostInstitutions;
      menu.settings.cities = leftSidebarItems.settings.cities;
    }
  }
  if (hasUserManagementEnabled.value) {
    menu.settings.userManagement = leftSidebarItems.settings.userManagement;
  }

  return menu;
});

const toggle = () => {
  emit("toggle-sidebar");
};

const { isOpened, isMobile } = toRefs(props);

const showSideBar = computed(() => {
  return !isMobile || isOpened;
});
</script>
<template>
  <div v-if="showSideBar" class="bg-gray-30 w-full md:max-w-sm md:pr-6 h-full">
    <SidebarReturnMobile route="/" description="Return" class="md:hidden" />
    <div class="text-indigo-base pl-4">
      <h2 class="font-bold text-xl pb-4 pt-4">Hi, {{ userName }}!</h2>
      <div v-if="Object.keys(menuItems.account).length">
        <h3 class="text-lg font-bold md:base">MY ACCOUNT</h3>
        <div class="flex flex-col pt-4">
          <div v-for="(option, index) of menuItems.account">
            <SidebarOptions
              :key="index"
              :icon="option.icon"
              :title="option.title"
              :description="option.description"
              :route="option.route"
              @click="toggle"
            />
          </div>
        </div>
      </div>
      <div v-if="Object.keys(menuItems.settings).length" class="pt-6">
        <h3 class="text-xl font-bold">SETTINGS</h3>
        <div class="flex flex-col pt-4">
          <div v-for="(option, index) of menuItems.settings">
            <SidebarOptions
              :key="index"
              :title="option.title"
              :description="option.description"
              :route="option.route"
              :icon="option.icon"
              @click="toggle"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
