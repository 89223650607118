<template>
  <div class="grid mb-2 sm:gap-4 sm:grid-cols-3">
    <div class="flex flex-col justify-center">
      <label class="grid xs:grid-cols-2 sm:grid-cols-1">
        <span
          class="flex items-center sm:sr-only bg-teal-100 text-white font-semibold whitespace-nowrap capitalize xs:border-b-2 px-2"
        >
          <span>{{ "award type / name" }}</span>
        </span>
        <input
          :id="'award-type-' + row"
          v-model="value.award"
          type="text"
          class="form-input max-h-8 w-full mb-2"
          :class="{ 'bg-error-100': v$.value.award.$error }"
          @blur="v$.value.award.$touch"
        />
      </label>
      <p
        v-if="v$.value.award.required.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.award.$error }"
      >
        {{ validationMessage["required"] }}
      </p>
      <p
        v-if="v$.value.award.maxLength.$invalid"
        class="block error text-error-900"
      >
        {{ validationMessage["maxLength"] }}
        {{ v$.value.award.maxLength.$params.max }}
      </p>
    </div>
    <div class="flex flex-col justify-center">
      <label class="grid xs:grid-cols-2 sm:grid-cols-1">
        <span
          class="flex items-center sm:sr-only bg-teal-100 text-white font-semibold whitespace-nowrap capitalize xs:border-b-2 px-2"
        >
          <span>{{ "disbursement date" }}</span>
        </span>
        <input
          :id="'disbursement-date-' + row"
          v-model="date"
          type="date"
          :name="'disbursement-date-' + row"
          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
          placeholder="mm-dd-yyyy"
          class="form-input max-h-8 w-full mb-2"
          :class="{
            'bg-error-100': v$.value.date.$error,
          }"
          @blur="v$.value.date.$touch"
        />
      </label>
      <p
        v-if="v$.value.date.required.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.date.$error }"
      >
        {{ validationMessage["required"] }}
      </p>
      <p
        v-if="v$.value.date.isValidDate.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.date.$error }"
      >
        {{ validationMessage["isValidDate"] }}
      </p>
      <p
        v-if="v$.value.date.isValidMinDate.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.date.$error }"
      >
        {{ validationMessage["isValidMinDate"] }}
      </p>
    </div>
    <div class="flex flex-col justify-center">
      <label class="grid xs:grid-cols-2 sm:grid-cols-1">
        <span
          class="flex items-center sm:sr-only bg-teal-100 text-white font-semibold whitespace-nowrap capitalize xs:border-b-2 px-2"
        >
          <span>{{ "award amount" }}</span>
        </span>
        <input
          :id="'award-amount-' + row"
          v-model="value.amount"
          type="text"
          class="form-input max-h-8 w-full mb-2"
          :class="{
            'bg-error-100': v$.value.amount.$error,
          }"
          @blur="v$.value.amount.$touch"
        />
      </label>
      <p
        v-if="v$.value.amount.required.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.amount.$error }"
      >
        {{ validationMessage["required"] }}
      </p>
      <p
        v-if="v$.value.amount.decimal.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.amount.$error }"
      >
        {{ validationMessage["decimal"] }}
      </p>
      <p
        v-if="
          v$.value.amount.mustNotHaveHyphen.$invalid &&
          !v$.value.amount.decimal.$invalid
        "
        class="error text-error-900"
        :class="{ hidden: !v$.value.amount.$error }"
      >
        {{ validationMessage["mustNotHaveHyphen"] }}
      </p>
    </div>
  </div>
</template>

<script>
import validationMessages from "../../mixins/validationMessages";
import { format, isValid, parse } from "date-fns";
import { isDateSupported } from "@/mixins/helpers";
import useVuelidate from "@vuelidate/core";
import { requiredIf, decimal, maxLength } from "@vuelidate/validators";
import {
  validatorDateFormat,
  minDateValidator,
  mustNotHaveHyphen,
} from "@/mixins/customValidators";

export default {
  name: "AwardItem",
  mixins: [validationMessages],
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    row: {
      type: Number,
      default: 0,
    },
    submittedData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:single-award"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        award: "",
        amount: "",
        date: "",
      },
      date: "",
    };
  },
  validations: {
    value: {
      award: {
        maxLength: maxLength(255),
        required: requiredIf(function () {
          return this.row === 0;
        }),
      },
      amount: {
        required: requiredIf(function () {
          return this.row === 0;
        }),
        decimal,
        mustNotHaveHyphen,
      },
      date: {
        required: requiredIf(function () {
          return this.row === 0;
        }),
        isValidDate: validatorDateFormat("yyyy-mm-dd"),
        isValidMinDate: minDateValidator("1970"),
      },
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:single-award", this.value);
      },
      deep: true,
      immediate: true,
    },
    date: {
      handler: function (newVal) {
        if (isDateSupported()) {
          this.value.date = newVal;
        } else {
          if (isValid(parse(newVal, "MM-dd-yyyy", new Date()))) {
            this.value.date = format(
              parse(newVal, "MM-dd-yyyy", new Date()),
              "yyyy-MM-dd"
            );
          } else {
            this.value.date = "";
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.submittedData.award) {
      this.value.award = this.submittedData.award.trim();
    }
    if (this.submittedData.amount) {
      this.value.amount = this.submittedData.amount;
    }
    if (this.submittedData.date) {
      let formatDate = isDateSupported() ? "yyyy-MM-dd" : "MM-dd-yyyy";
      this.date = format(
        parse(this.submittedData.date, "MM/dd/yyyy", new Date()),
        formatDate
      );
    }
  },
};
</script>
