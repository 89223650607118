import { mapState } from "vuex";
import { buildStringDateRange } from "../mixins/helpers";

export default {
  data() {
    return {
      paymentDone: false,
      chargeErrors: [],
    };
  },
  computed: {
    ...mapState(["program", "currentUser"]),
    ownerId() {
      const { formioStudentId, formioAdvisorId } = this.currentUser;
      return formioStudentId || formioAdvisorId;
    },
    orderDescription() {
      return (
        this.program.program.name +
        " (" +
        buildStringDateRange(this.program.start_date, this.program.end_date) +
        ")"
      );
    },
  },
  methods: {
    async checkTransactionExistsForApplicationId() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let vm = this;
      let options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      return await fetch("/api/payments/" + vm.currentApplicationId, options)
        .then((response) => {
          if (response.status === 200) {
            return true;
          } else if (response.status === 404) {
            return false;
          } else {
            throw new Error("Invalid response");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setErrorMessage: function (errors) {
      // We got an array of errors back
      if (Array.isArray(errors) && errors.length) {
        this.processErrorArray(errors);
      } else if (
        // We got an object, with an error property, which is an array of errors
        Object.prototype.hasOwnProperty.call(errors, "error") &&
        Array.isArray(errors.error)
      ) {
        this.processErrorArray(errors.error);
      }
    },
    processErrorArray(errors) {
      errors.forEach((err) => {
        if (Object.prototype.hasOwnProperty.call(err, "errorText")) {
          this.chargeErrors.push(err.errorText);
        }
        if (Object.prototype.hasOwnProperty.call(err, "message")) {
          this.chargeErrors.push(err.message);
        }
      });
    },
    responseHandler(response) {
      let errors = [];
      return new Promise(function (resolve, reject) {
        if (response.messages.resultCode === "Error") {
          response.messages.message.forEach(function (message) {
            errors.push({
              message: message.code + ": " + message.text,
              path: ["submit"],
            });
            reject(errors);
          });
        }
        resolve(response);
      });
    },
  },
};
