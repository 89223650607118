import _ from "lodash";

export default {
  methods: {
    throttledSearch: _.debounce((search, loading, vm, searchType) => {
      fetch(vm[searchType + "URL"] + "?search=" + encodeURIComponent(search))
        .then((response) => response.json())
        .then((data) => {
          vm[searchType] = Object.values(data);
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    }, 250),
  },
};
