<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87174 0.0236132C9.66726 0.0917757 9.56844 0.174037 8.95498 0.78995L8.32031 1.42695L9.45566 2.55997L10.5888 3.69531L11.2329 3.0512C11.9875 2.29663 12.011 2.26378 12.011 1.92051C12.011 1.57492 11.9898 1.5444 11.2165 0.775729C10.65 0.21388 10.5347 0.110533 10.4243 0.0634565C10.2667 -0.00225401 10.0082 -0.0210111 9.87174 0.0236132Z"
      fill="#009999"
    />
    <path
      d="M4.34883 5.40385L1.12375 8.63127L0.566556 10.3003C0.260928 11.217 0.00470629 11.981 4.7708e-05 11.9975C-0.00694017 12.0116 0.754616 11.7694 1.69246 11.4568L3.3991 10.888L6.62419 7.66289L9.85173 4.43535L8.72337 3.307C8.1028 2.68642 7.59035 2.17864 7.58324 2.17864C7.57625 2.17876 6.12118 3.63138 4.34883 5.40385Z"
      fill="#009999"
    />
  </svg>
</template>
