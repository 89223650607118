<script setup>
import { computed, ref, toRefs, unref } from "vue";
import CoursesChevronIcon from "@/components/svg-icons/CoursesChevronIcon.vue";
import { getAreaOfStudy } from "../utils";
import { formatCreditAmount } from "./utils";

import ClassLevelIcon from "@/components/svg-icons/ClassLevelIcon.vue";
import CreditsIcon from "@/components/svg-icons/CreditsIcon.vue";
import InstitutionIcon from "@/components/svg-icons/InstitutionIcon.vue";
import PeersIcon from "@/components/svg-icons/PeersIcon.vue";
import PsychologyIcon from "@/components/svg-icons/PsychologyIcon.vue";
import PunchClockIcon from "@/components/svg-icons/PunchClockIcon.vue";
import VectorIcon from "@/components/svg-icons/VectorIcon.vue";
import LanguagesIcon from "@/components/svg-icons/LanguagesIcon.vue";
import LocationIcon from "@/components/svg-icons/LocationIcon.vue";

const props = defineProps({
  course: {
    type: Object,
    required: true,
  },
});

const { course } = toRefs(props);
const expanded = ref(false);

const isVirtual = computed(() => {
  return unref(course)?.class?.format?.value.toLowerCase() === "virtual";
});

const termLabel = computed(() => {
  let retVal = "";
  if (unref(course)?.class?.terms) {
    retVal = unref(course)
      ?.class?.terms?.map((term) => term.name)
      .join(", ");
  }
  return retVal;
});

const languageAndProficiency = computed(() => {
  let retVal = "";
  if (unref(course)?.class?.language_of_instruction) {
    const languageOfInstructionStr =
      unref(course)?.class?.language_of_instruction?.value || "";
    retVal = `${languageOfInstructionStr}`;
  }
  return retVal;
});

const recommendedUsCreditAmount = computed(() => {
  return formatCreditAmount(
    unref(course)?.class?.recommended_us_credit_amount || 0
  );
});

const hostInstitutionCreditAmount = computed(() => {
  return formatCreditAmount(
    unref(course)?.class?.host_institution_credit_amount || 0
  );
});

const shippingAddressCity = computed(() => {
  return unref(course)?.class?.host_institution?.shipping_address_city || "";
});

const countryName = computed(() => {
  return unref(course)?.class?.host_institution?.country?.name || "";
});

const summarizedAreaOfStudy = computed(() => {
  const summarized = true;
  return getAreaOfStudy(
    unref(course)?.class?.subject_area_sublevels || [],
    summarized
  );
});

const areaOfStudy = computed(() => {
  const summarized = false;
  return getAreaOfStudy(
    unref(course)?.class?.subject_area_sublevels || [],
    summarized
  );
});

const location = computed(() => {
  const city = unref(shippingAddressCity);
  const country = unref(countryName);
  return city && country ? `${city}, ${country}` : city || country || "";
});
</script>

<template>
  <div
    id="courses-card"
    class="program-card-base relative injected-courses-card cursor-pointer"
    @click.stop="expanded = !expanded"
  >
    <!-- Non-Expanded State -->
    <div
      class="flex flex-col sm:flex-row justify-between items-start sm:items-center px-4 py-2"
      :class="`${secondaryColor}` ? 'bg-gray-275' : 'bg-gray-10' "
    >
      <div class="flex flex-col w-full sm:w-auto sm:flex-1">
        <div class="flex items-center justify-between w-full sm:w-auto">
          <div
            v-if="!isVirtual"
            class="flex items-center font-bold text-sm text-indigo-base"
          >
            <LocationIcon class="inline-block mr-2" />
            <span class="capitalize">{{ location }}</span>
          </div>
          <div v-else class="font-bold text-sm text-indigo-base">
            <span class="capitalize">Virtual</span>
          </div>
          <div class="flex sm:hidden justify-end ml-2">
            <CoursesChevronIcon :class="{ 'rotate-90': expanded }" />
          </div>
        </div>
        <div
          v-if="recommendedUsCreditAmount"
          class="block sm:hidden text-sm text-indigo-base text-gray-600 mt-2"
        >
          <CreditsIcon class="inline-block mr-2" />
          <span class="font-semibold mr-2">Rec. US Semester Credit: </span>
          <span>
            {{ recommendedUsCreditAmount }}
          </span>
        </div>
      </div>
      <div
        class="hidden sm:flex text-sm text-indigo-base items-center sm:flex-1 justify-between"
      >
        <div class="flex-grow">
          <span v-if="recommendedUsCreditAmount">
            <CreditsIcon class="inline-block mr-2" />
            <span class="font-semibold">Rec. US Semester Credit: </span>
            <span>
              {{ recommendedUsCreditAmount }}
            </span>
          </span>
        </div>
        <div class="flex-shrink-0">
          <CoursesChevronIcon :class="{ 'rotate-90': expanded }" />
        </div>
      </div>
    </div>
    <div v-if="!expanded" class="p-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div class="text-lg font-bold text-indigo-base">
        {{ course?.class?.title }}
      </div>
      <div class="text-sm text-indigo-base flex flex-col space-y-2">
        <div v-if="areaOfStudy">
          <PsychologyIcon class="inline-block mr-2" />
          <span class="font-semibold">Area of Study: </span
          >{{ summarizedAreaOfStudy }}
        </div>
        <div v-if="course?.class?.format?.value">
          <VectorIcon class="inline-block mr-2" />
          <span class="font-semibold">Format: </span
          >{{ course?.class?.format?.value }}
        </div>
        <div v-if="termLabel">
          <PunchClockIcon class="inline-block mr-2" />
          <span class="font-semibold">Session: </span>{{ termLabel }}
        </div>
      </div>
    </div>
    <div
      v-if="expanded"
      class="p-4 flex flex-col lg:flex-row gap-2 text-indigo-base"
    >
      <div class="flex flex-col lg:basis-1/2 lg:w-full">
        <div class="text-lg font-bold">
          {{ course?.class?.title }}
        </div>
        <div class="mt-4 text-sm">
          {{ course?.class?.description }}
        </div>
      </div>
      <div class="flex flex-col lg:basis-1/2 lg:w-full justify-between">
        <div class="grid grid-cols-1 gap-4 text-sm text-indigo-base">
          <div v-if="areaOfStudy">
            <PsychologyIcon class="inline-block mr-2" />
            <span class="font-semibold">Area of Study: </span>{{ areaOfStudy }}
          </div>
          <div v-if="termLabel">
            <PunchClockIcon class="inline-block mr-2" />
            <span class="font-semibold">Session: </span>{{ termLabel }}
          </div>
          <div v-if="course?.class?.format?.value">
            <VectorIcon class="inline-block mr-2" />
            <span class="font-semibold">Format: </span
            >{{ course?.class?.format?.value }}
          </div>
          <div v-if="course?.class?.host_institution?.name">
            <InstitutionIcon class="inline-block mr-2" />
            <span class="font-semibold">Host Institution: </span
            >{{ course?.class?.host_institution?.name }}
          </div>
          <div v-if="languageAndProficiency">
            <LanguagesIcon class="inline-block mr-2" />
            <span class="font-semibold">Language of Instruction: </span
            >{{ languageAndProficiency }}
          </div>
          <div v-if="course?.class?.level?.[0]?.value">
            <ClassLevelIcon class="inline-block mr-2" />
            <span class="font-semibold">Class Level: </span
            >{{ course?.class?.level?.[0]?.value }}
          </div>
          <div v-if="course?.class?.classroom_peers">
            <PeersIcon class="inline-block mr-2" />
            <span class="font-semibold">Classroom Peers: </span
            >{{ course?.class?.classroom_peers?.join(", ") }}
          </div>
          <div v-if="hostInstitutionCreditAmount">
            <CreditsIcon class="inline-block mr-2" />
            <span class="font-semibold">Host Institution Credits: </span
            >{{ hostInstitutionCreditAmount }}
          </div>
        </div>
        <div class="mt-4">
          <div class="text-xs text-indigo-base mb-2 opacity-50">
            Click view detail to see more details and available program sessions
            for this course.
          </div>
          <div class="sm:flex sm:justify-end">
            <RouterLink :to="`/results/courses/${course?.class?.id}`">
              <button
                :class="`box-border ${secondaryColorClassOutlinedNoHover} flex flex-row justify-center items-center p-5 border-2 mt-4 w-full sm:w-auto`"
              >
                <div
                  class="flex gap-1 font-bold uppercase text-base tracking-tight items-center"
                >
                  <span>View Detail</span>
                  <CoursesChevronIcon />
                </div>
              </button>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
