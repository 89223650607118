<template>
  <!--- CONTENT ---->
  <div v-if="program" class="text-base text-indigo-base md:mx-auto">
    <div class="bg-white px-3 pt-3 md:px-24 md:py-12">
      <div class="font-montserrat">
        <!-- SECTION 01 -->
        <div
          class="mt-4 w-full sm:px-0 md:grid md:grid-cols-10 lg:grid lg:grid-cols-10"
        >
          <div class="col-span-6">
            <div>
              <p class="font-bold uppercase">INTERNSHIP</p>
              <div
                v-if="internshipLoading"
                class="mt-3 flex animate-pulse items-center"
              >
                <div class="h-8 w-full bg-blue-300"></div>
              </div>
              <p v-else class="text-4xl font-bold">
                {{ program?.title || "Internship Title" }}
              </p>
              <div class="mt-4 space-y-1">
                <div>
                  <div
                    v-if="program?.session?.session_travel_details"
                    class="flex"
                  >
                    <ProgramDates
                      :id="`card-program-dates-${program.id}`"
                      :dates="dates"
                      :start-is-final="
                        program?.session?.session_travel_details?.[0]
                          ?.arrival_date_is_final > 0
                      "
                      :end-is-final="
                        program?.session?.session_travel_details?.[0]
                          ?.departure_date_is_final > 0
                      "
                      tag="p"
                      class="text-gray-600"
                      reverse-icon-order
                      experience-card-icon
                      text-class="font-montserrat uppercase mt-8px font-bold text-indigo-base text-xs sm:text-sm"
                    />
                    <p
                      class="mt-8px pl-1 text-xs uppercase text-indigo-base before:mr-2 before:content-['|'] sm:text-sm"
                    >
                      {{ numOfWeeks }}
                    </p>
                  </div>
                </div>
                <p v-if="industries" class="flex items-center text-sm">
                  <span> Industry: </span>
                  <span class="ml-1">
                    {{ industries }}
                  </span>
                </p>
              </div>
            </div>
            <!-- BUTTONS -->
            <div
              class="mt-6 flex flex-col justify-start md:mt-24 md:flex md:flex-row lg:mt-20"
            >
              <!-- SELECT BUTTON -->
              <button
                type="button"
                class="order-1 mt-3 box-border flex h-12 max-h-60p w-full flex-row items-center justify-center border-teal-400 bg-teal-700 p-5 text-white md:order-2 md:mt-0 md:w-[280px] lg:mt-0 lg:w-[280px]"
                :disabled="
                  !selectedInternshipIds.includes(program.id) &&
                  selectedInternshipIds.length === internship.maxItems
                "
                :class="[
                  !selectedInternshipIds.includes(program.id) &&
                  selectedInternshipIds.length === internship.maxItems
                    ? 'cursor-not-allowed bg-gray-275 text-gray-485 border-gray-485'
                    : '',
                ]"
                @click="selectInternship"
              >
                <div
                  class="flex items-center gap-1 text-base font-bold uppercase tracking-tight"
                >
                  <span>
                    {{ buttonLabel }}
                  </span>
                  <RightArrowIcon />
                </div>
              </button>
            </div>
            <!-- ABOUT THE COMPANY -->
            <div class="mt-12">
              <p class="font-bold">ABOUT THE COMPANY</p>
              <hr class="mr-3 mt-4 text-gray-450" />
              <p class="mt-4">
                <span class="font-semibold">Work location: </span>
                {{ workLocation }}
              </p>
              <p class="mt-4">
                <span class="font-semibold">HQ:</span>
                {{ program.city }},
                {{ program.country }}
              </p>
              <div class="mt-4 flex items-center">
                <span class="font-semibold"> Organization Size </span>
                <tool-tip />
                <span class="mr-1">:</span>
                <span>{{ program.company?.company_size || "" }}</span>
              </div>
              <p class="mt-4">
                <span>{{ program.company?.description || "" }}</span>
              </p>
            </div>
          </div>
          <!-- MAPS -->
          <div class="w-92 mt-4 md:col-span-4 md:mt-0 md:pl-3 md:py-6">
            <div class="relative">
              <img
                src="/images/group01.png"
                class="top-48 z-0 hidden object-fill md:absolute md:block"
              />
              <img
                v-if="mapUrl"
                :src="mapUrl"
                class="relative z-10 w-full"
              />
              <p class="z-5 relative mt-3 font-bold uppercase">
                WORK LOCATION
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- SECTION 02 -->
      <div
        class="mt-9 w-full sm:px-0 md:grid md:grid-cols-10 lg:grid lg:grid-cols-10"
      >
        <div class="col-span-10">
          <!-- QUALIFYING AND APPLYING -->
          <div class="mt-12">
            <p class="font-bold">QUALIFYING AND APPLYING</p>
            <!-- table -->
            <div class="mt-10">
              <Table :data="qualifyingData" />
            </div>
          </div>
          <!-- POSITION RESPONSIBILITIES -->
          <div class="mt-12 font-montserrat text-indigo-base">
            <p class="font-bold">POSITION RESPONSIBILITES</p>
            <div v-if="program?.responsibilities?.content" class="mt-3">
              <hr class="text-gray-450" />
                <JSONtoHTML
                  class="ml-3 mt-3 json-to-html"
                  :tiptap-json="program.responsibilities"
                />
            </div>
          </div>
          <!-- POSITION DETAILS -->
          <div class="mt-12">
            <p class="font-bold">POSITION DETAILS</p>
            <div class="mt-3">
              <hr class="text-gray-450" />
              <div class="mt-3">
                <Table :data="positionDetailsData" />
                <div
                  v-if="scheduleTypeDoc"
                  class="px-4 py-4 mt-0 font-montserrat md:text-base lg:text-base text-xs text-indigo-base flex"
                  :class="{ 'bg-gray-30': scheduleDocRowFilled }"
                >
                  <div class="text-left font-semibold md:w-52">Schedule</div>
                  <WYSIWYG
                    class="wysiwyg-borders-none"
                    :content="program.schedule.description"
                    :editable="false"
                    :display-header="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- BOTTOM BUTTONS -->
      <div class="mb-20 w-full">
        <div
          class="mt-8 w-full md:mt-24 md:grid md:grid-cols-12 lg:grid lg:grid-cols-12"
        >
          <!-- BACK BUTTON -->
          <div class="md:col-span-8"></div>
          <div
            class="col-start-7 col-end-12 flex w-full flex-col md:flex md:flex-row"
          >
            <button
              type="button"
              class="order-2 mt-3 flex h-12 w-full flex-row items-center justify-center border-[3px] border-teal-600 bg-white p-5 text-sm text-teal-600 md:order-1 md:mt-0 md:w-64 lg:w-64"
              @click="backToInternships"
            >
              <div
                class="flex items-center gap-1 text-base font-bold uppercase tracking-tight"
              >
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.61134 6.99126L9 1.71183L7.10819 -1.30562e-07L-3.05598e-07 6.99126L7.10819 14L9 12.2882L3.61134 6.99126Z"
                    fill="currentColor"
                  />
                </svg>
                <span class="mr-2"> Back </span>
              </div>
            </button>
            <!-- SELECT BUTTON -->
            <button
              type="button"
              class="order-1 mt-3 box-border flex h-12 max-h-60p w-full flex-row items-center justify-center border-teal-400 bg-teal-700 p-5 text-white md:order-2 md:ml-8 md:mt-0 lg:ml-8 lg:mt-0"
              :disabled="
                !selectedInternshipIds.includes(program.id) &&
                selectedInternshipIds.length === internship.maxItems
              "
              :class="[
                !selectedInternshipIds.includes(program.id) &&
                selectedInternshipIds.length === internship.maxItems
                  ? 'cursor-not-allowed bg-gray-275 text-gray-485 border-gray-485'
                  : '',
              ]"
              @click="selectInternship"
            >
              <div
                class="flex items-center gap-1 text-base font-bold uppercase tracking-tight"
              >
                <span>
                  {{ buttonLabel }}
                </span>
                <RightArrowIcon />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--- END CONTENT ---->
</template>

<script setup>
import { ref, computed, onMounted, inject, toRefs } from "vue";
import Table from "@/components/shared/Table/SimpleTable.vue";
import { buildStringDateRangeSessionTravel } from "@/mixins/helpers";
import ProgramDates from "@/components/ProgramDates";
import { differenceInWeeks } from "date-fns";
import WYSIWYG from "@/components/forms/SharedComponents/WYSIWYG/WYSIWYG.vue";
import RightArrowIcon from "../../svg-icons/RightArrowIcon.vue";
import JSONtoHTML from "@/components/forms/SharedComponents/WYSIWYG/JSONtoHTML.vue";
import { DEFAULT_GOOGLE_MAP_ZOOM } from "@/constants";

// Provided by Configurator/Index.vue
const { internship, selectedInternshipIds } = inject("internship");

const props = defineProps({
  program: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const { program } = toRefs(props);

const qualifyingData = ref([
  {
    title: "Academic Levels",
    type: "list-text",
    content: [],
    filled: true,
  },
  {
    title: "Language Proficiency",
    type: "list-text",
    content: [],
    filled: false,
  },
  {
    title: "Min-Max age (If applicable)",
    type: "simple-text",
    content: "",
    filled: true,
  },
  {
    title: "Required Skills",
    type: "list-item",
    content: [],
    filled: false,
  },
  {
    title: "Level-Up Skills",
    type: "list-item",
    content: [],
    filled: true,
  },
]);

const positionDetailsData = ref([
  {
    title: "Session Dates",
    type: "simple-text",
    content: "Summer: June 1-August 1",
    filled: true,
  },
  {
    title: "Length",
    type: "simple-text",
    content: "",
    filled: false,
  },
  {
    title: "Number of Positions",
    type: "list-text",
    content: [],
    filled: true,
  },
  {
    title: "Placement Type",
    type: "simple-text",
    content: "",
    filled: false,
  },
  {
    title: "Paid or Unpaid",
    type: "simple-text",
    content: "",
    filled: true,
  },
  {
    title: "Position Perks",
    type: "list-join-text",
    separator: " | ",
    content: [],
    filled: false,
  },
  {
    title: "Language of Internship",
    type: "simple-text",
    content: "",
    filled: true,
  },
  {
    title: "Schedule",
    type: "list-schedule-text",
    separator: " | ",
    content: [
      {
        weekDay: "",
        time: "",
      },
      {
        weekDay: "",
        time: "",
      },
      {
        weekDay: "",
        time: "",
      },
      {
        weekDay: "",
        time: "",
      },
      {
        weekDay: "",
        time: "",
      },
    ],
    filled: false,
  },
]);

const emit = defineEmits(["backButton", "selectButton"]);
const selectInternship = () => {
  emit("selectButton", program.value?.id);
};
const backToInternships = () => {
  emit("backButton");
};

const GooglePlacesApiKey = computed(() => {
  return process.env.MIX_UI_GOOGLE_PLACES_API_KEY;
});

const latLon = computed(() => {
  if (!program.value.latitude || !program.value.longitude) {
    const fallBackLocation =
      program?.value?.city_data?.lat_lng?.split(", ") ?? [];
    return {
      lat: fallBackLocation?.[0],
      lon: fallBackLocation?.[1],
    };
  }
  return {
    lat: program?.value?.latitude,
    lon: program?.value?.longitude,
  };
});

const generateMapUrl = (googleApiKey, latitude, longitude) => {
  return `https://maps.googleapis.com/maps/api/staticmap?key=${googleApiKey}&center=${latitude},${longitude}&zoom=${DEFAULT_GOOGLE_MAP_ZOOM}&format=png&maptype=roadmap&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&size=1200x360&markers=color:red%7C${latitude},${longitude}`;
};

const mapUrl = computed(() => {
  const location = latLon.value;
  if (!location.lat || !location.lon) return "";
  return generateMapUrl(GooglePlacesApiKey.value, location.lat, location.lon);
});

const workLocation = computed(() => {
  return (
    program.value?.work_locations?.map((item) => item.value)?.join(", ") ?? ""
  );
});

const industries = computed(() =>
  program.value?.industries
    ?.map((item) => item?.career_pathway)
    ?.filter(Boolean)
    ?.join(" / ")
);

const internshipLoading = computed(() => false);
const getTypePosition = (content) => {
  if (!Object.prototype.hasOwnProperty.call(content, "content")) return [];
  return content.type;
};
const hasContent = (meta) => {
  return Object.prototype.hasOwnProperty.call(meta, "content");
};

const term = computed(() => program.value?.terms?.[0]?.name);

const dateString = computed(() => {
  let start = program.value?.session?.academic_start_date;
  let end = program.value?.session?.academic_end_date;

  // Adjust the date strings to ensure they're interpreted as local dates
  const startDate = new Date(start + "T00:00");
  const endDate = new Date(end + "T00:00");

  const startMonth = startDate.toLocaleDateString("en-US", { month: "long" });
  const endMonth = endDate.toLocaleDateString("en-US", { month: "long" });

  const startDay = startDate.toLocaleDateString("en-US", { day: "numeric" });
  const endDay = endDate.toLocaleDateString("en-US", { day: "numeric" });

  return `${startMonth} ${startDay}-${endMonth} ${endDay}`;
});

const dates = computed(() =>
  buildStringDateRangeSessionTravel(
    program.value?.session?.session_travel_details,
    {
      academic_start_date: program.value?.session?.academic_start_date,
      academic_end_date: program.value?.session?.academic_end_date,
    }
  )
);

const numOfWeeks = computed(() => {
  const { arrival_date = 0, departure_date = 0 } =
    program.value.session?.session_travel_details?.[0] || {};
  if (arrival_date && departure_date) {
    const diff = differenceInWeeks(
      new Date(departure_date),
      new Date(arrival_date)
    );
    return `${diff} week${diff !== 1 ? "s" : ""}`;
  }
  return "";
});

const getNativeLanguage = (languages) => {
  return (
    languages?.find((item) => item.proficiency_level === "Native")?.value ?? ""
  );
};

const scheduleDocRowFilled = computed(() => {
  return positionDetailsData.value[positionDetailsData.value.length - 2].filled;
});

const scheduleTypeDoc = computed(() => {
  return program.value?.schedule?.description?.type === "doc";
});

const buttonLabel = computed(() => {
  return selectedInternshipIds.value?.includes(program.value?.id)
    ? "Selected"
    : "Select Placement";
});

const getScheduleForTable = () => {
  if (!scheduleTypeDoc.value) {
    return Object.entries(program.value?.schedule?.description ?? {}).map(
      ([weekDay, child]) => ({
        weekDay,
        time:
          child?.availability[0]?.start && child?.availability[0]?.end
            ? `${child.availability[0].start}-${child.availability[0].end}`
            : "",
      })
    );
  }
};

const loadInternshipData = () => {
  qualifyingData.value[0].content = program.value.academic_levels.map(
    (item) => item.academic_year
  );
  qualifyingData.value[1].content = program.value.required_languages.map(
    (item) => {
      return `${item.value}: ${item.proficiency_level}`;
    }
  );
  if (program.value.maximum_age && program.value.minimum_age)
    qualifyingData.value[2].content = `Min: ${program.value.minimum_age}, Max: ${program.value.maximum_age}`;
  qualifyingData.value[3].content = program.value.needed_skills.map(
    (item) => item.value
  );
  qualifyingData.value[4].content = program.value.gained_skills.map(
    (item) => item.value
  );
  if (program.value.duration)
    positionDetailsData.value[1].content = program.value.duration;
  if (program.value?.terms?.length && positionDetailsData.value?.length > 2)
    positionDetailsData.value[2].content = program.value.terms.map(
      ({ name, capacity = 0 }) => `${name}: ${capacity} available`
    );
  if (program.value.placement_types)
    positionDetailsData.value[3].content = program.value.placement_types
      .map((item) => item.value)
      .join(", ");
  if (program.value.compensation)
    positionDetailsData.value[4].content = program.value.compensation;
  if (program.value.perks.length)
    positionDetailsData.value[5].content = program.value.perks.map(
      (item) => item.value
    );
  if (program.value.required_languages) {
    const language = getNativeLanguage(program.value.required_languages);
    positionDetailsData.value[6].content =
      language === "English" ? "" : language;
  }
  if (program.value.schedule && !scheduleTypeDoc.value)
    positionDetailsData.value[7].content = getScheduleForTable(
      program.value.schedule
    );
  qualifyingDataWithoutEmpty();
  positionDetailsDataWithoutEmpty();
};

const qualifyingDataWithoutEmpty = () => {
  let qualiDContentFilled = ref([]);
  qualifyingData.value.forEach((qd) => {
    if (qd.content.length) {
      qualiDContentFilled.value.push(qd);
    }
  });
  qualifyingData.value = qualiDContentFilled.value;
  greyBackground(qualifyingData);
};

const positionDetailsDataWithoutEmpty = () => {
  let posiDDContentFilled = ref([]);
  let weekDaysIndex = 0;
  let insertWeeksIndex = 0;
  positionDetailsData.value.forEach((pd) => {
    if (pd.content.length || (typeof pd.content === "number" && pd.content)) {
      if (Array.isArray(pd.content) && typeof pd.content[0] === "object") {
        if (pd.content[0].weekDay || pd.content[0].time) {
          posiDDContentFilled.value.push(pd);
          weekDaysIndex = posiDDContentFilled.value.length - 1;
        }
      } else posiDDContentFilled.value.push(pd);
      if (pd.title === "Length") {
        insertWeeksIndex = posiDDContentFilled.value.length - 1;
      }
    }
    if (pd.title === "Session Dates") {
      posiDDContentFilled.value[0][
        "content"
      ] = `${term.value}: ${dateString.value}`;
    }
    if (pd.title === "Length") {
      posiDDContentFilled.value[1]["content"] = numOfWeeks;
    }
  });
  positionDetailsData.value = posiDDContentFilled.value;
  if (positionDetailsData.value.map((e) => e.title).includes("Schedule"))
    sortWeekDays(weekDaysIndex);
  insertWeeks(insertWeeksIndex);
  greyBackground(positionDetailsData);
};

const sortWeekDays = (index) => {
  if (scheduleTypeDoc.value) return;
  const map = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  positionDetailsData?.value[index]?.content?.sort((a, b) => {
    return map[a.weekDay.toLowerCase()] - map[b.weekDay.toLowerCase()];
  });
};

const insertWeeks = (index) => {
  let nWeeks = positionDetailsData.value?.[index]?.content;
  if (typeof nWeeks === "number")
    positionDetailsData.value[index].content = `${nWeeks}  Week${
      nWeeks !== 1 ? "s" : ""
    }`;
};

const greyBackground = (arr) => {
  arr.value.forEach((e, i) => {
    //to fix the table background
    if (i > 0) {
      if (arr.value[i - 1].filled) arr.value[i].filled = false;
      else arr.value[i].filled = true;
    }
  });
};

onMounted(() => {
  loadInternshipData();
});
</script>

<style scoped>
.wysiwyg-borders-none,
.wysiwyg-borders-none .editor {
  border: none;
  box-shadow: none;
}
.wysiwyg-borders-none :deep(.editor) {
  background-color: transparent !important;
}
.wysiwyg-borders-none :deep(.editor__content) {
  height: 100% !important;
  padding: 0.25rem;
  overflow-y: inherit !important;
  background-color: transparent;
}
</style>
