import { mapState } from "vuex";
import formIoApi from "./formIoApi.js";

export default {
  mixins: [formIoApi],
  data() {
    return {
      codeRules: {
        inter: {
          rule: "Intern Code of Conduct",
          url: "codeofconductinternships",
          exist: false,
          submission: "",
        },
        study: {
          rule: "Study Code of Conduct",
          url: "codeofconductstudyabroad",
          exist: false,
          submission: "",
        },
        virtualStudy: {
          rule: "Virtual Study Code of Conduct",
          url: "codeofconductvirtualstudy",
          exist: false,
          submission: "",
        },
        virtualInter: {
          rule: "Virtual Intern Code of Conduct",
          url: "codeofconductvirtualinternships",
          exist: false,
          submission: "",
        },
      },
    };
  },
  computed: {
    ...mapState([
      "program",
      "formioToken",
      "studentApplications",
      "currentApplicationSubmissions",
    ]),
    enrollmentApplicationId() {
      return this.$route.params.applicationId;
    },
    validRules() {
      return Object.values(this.codeRules).filter((rule) => rule.exist);
    },
  },
  methods: {
    getSubmissionId(formPath) {
      if (!Object.hasOwn(this.currentApplicationSubmissions, formPath)) {
        return "";
      }

      return this.currentApplicationSubmissions[formPath][0]?._id ?? "";
    },
    validateApplication() {
      return this.studentApplications.some(
        (app) => app.id === this.enrollmentApplicationId
      );
    },
    setAllForm(program) {
      const formRules = program.post_acceptance_form_rules;

      if (formRules && formRules.length) {
        Object.entries(this.codeRules).forEach(([key, val]) => {
          this.codeRules[key].exist = formRules.includes(val.rule);
        });
      } else {
        Object.assign(this.$data, this.$options.data());
      }
    },
    getAllSubmission() {
      for (const [key, val] of Object.entries(this.codeRules)) {
        if (val.exist) {
          const submissionId = this.getSubmissionId(val.url);
          this.codeRules[key].submission = submissionId ? submissionId : "";
        }
      }
    },
  },
};
